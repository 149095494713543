import { FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ChannelAppViewer, $ChannelAppViewerAnimationService } from '@knuddelsModules/Apps';
import { observer } from '@knuddels-app/mobx';
import { getSplitViewHeightPercentage } from './getSplitViewHeightPercentage';
import { ViewerState } from '@knuddelsModules/Apps/bundle/services';
import { getMinimizedHeightPercentage } from './getMinimizedHeightPercentage';
export const ChannelAppViewerBackgroundSpacer: React.FC<{
  maxWidth: number;
  maxHeight: number;
  maxSplitViewPercentage: number;
}> = observer('ChannelAppViewerBackgroundSpacer', ({
  maxSplitViewPercentage,
  maxWidth,
  maxHeight
}) => {
  const animationService = useService($ChannelAppViewerAnimationService);
  const heightPercentage = useHeightPercentage(maxSplitViewPercentage, maxWidth, maxHeight, animationService.state);
  if (animationService.state === 'hidden') {
    return null;
  }
  return <div style={{
    height: resolveThemingValue(heightPercentage * 100 + '%', "sizes", useTheme())
  }} className={_c0} />;
});
const useHeightPercentage = (maxSplitViewPercentage: number, maxWidth: number, maxHeight: number, state: ViewerState) => {
  const appViewer = useService($ChannelAppViewer);
  const app = appViewer.activeApp;
  if (state === 'minimized') {
    return getMinimizedHeightPercentage(maxHeight);
  } else {
    return getSplitViewHeightPercentage(maxSplitViewPercentage, maxWidth, maxHeight, app);
  }
};
const _c0 = " Knu-FlexCol ";