import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $PaymentModule = newServiceId<services.PaymentModule>(
	'$PaymentModule'
);

export const $PaymentHandler = newServiceId<services.PaymentHandler>(
	'$PaymentHandler'
);

export const $PaymentErrorDisplay = newServiceId<services.PaymentErrorDisplay>(
	'$PaymentErrorDisplay'
);

export const $PaymentSuccessDisplay = newServiceId<
	services.PaymentSuccessDisplay
>('$PaymentSuccessDisplay');

export const $PaymentSubscriptionHandler = newServiceId<
	services.PaymentSubscriptionHandler
>('$PaymentSubscriptionHandler');
