import * as React from 'react';
import { DecoratorNode, LexicalNode } from 'lexical';

// tslint:disable-next-line: no-module-bleeding
import { SmileyImage } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/SmileyImage';
export type SmileyData = {
  id: number;
  name: string;
  code: string;
  rpl: string;
  url: string;
  feature: boolean;
  multi?: boolean;
  owned: boolean;
};
export type SmileyDetails = {
  multiPics?: string[];
  features?: {
    name: string;
    description: string;
    commands: string[];
  }[];
  multiSyntax?: string[];
  description?: string;
};
export type MultiSmiley = {
  multiPics?: string[];
  multiSyntax?: string[];
};
export class SmileyNode extends DecoratorNode<any> {
  static getType(): string {
    return 'smiley';
  }
  static clone(node: SmileyNode): SmileyNode {
    return new SmileyNode({
      ...node.data
    });
  }
  constructor(public data: SmileyData) {
    super();
  }
  isKeyboardSelectable(): boolean {
    return false;
  }
  createDOM(): HTMLElement {
    const container = document.createElement('div');
    container.style.position = 'relative';
    container.style.display = 'inline-block';
    container.style.userSelect = 'none';
    return container;
  }
  updateDOM(): boolean {
    return false;
  }
  toMessage(): string {
    return this.data.rpl;
  }
  decorate(): any {
    return <SmileyImage imageUrl={this.data.url} />;
  }
}
export function $createSmileyNode(data: SmileyData): SmileyNode {
  return new SmileyNode(data);
}
export function $isSmileyNode(node: LexicalNode | null | undefined): node is SmileyNode {
  return node instanceof SmileyNode;
}