import * as React from 'react';
import { ConversationKnuddelTransferMessageContentFragment, User } from '@generated/graphql';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { Flex, Text } from '@knuddels/component-library';
import { MessengerMessageProps } from './MessengerMessageProps';
import { useFormatAmount } from '@knuddels-app/tools/formatAmount';
import { Knuddel } from '@shared/components';
export const KnuddelTransferMessageContent: React.FC<{
  content: ConversationKnuddelTransferMessageContentFragment;
  receiverNick: string;
  activeUserId: User['id'];
  message: MessengerMessageProps['message'];
}> = ({
  receiverNick,
  content,
  activeUserId,
  message
}) => {
  const senderIsActiveUser = activeUserId === message.sender.id;
  const formatAmount = useFormatAmount('millionOnly');
  return <div className={_c0}>
			<Text className={_c1}>
				{senderIsActiveUser ? <FormattedMessage id={declareFormat({
        id: 'channel.message.knuddelsent',
        defaultFormat: 'You sent {amount} {image} to {nick}.'
      })} values={{
        nick: <Text bold={true} className={_c2}>{receiverNick}</Text>,
        amount: formatAmount(content.knuddelAmount),
        image: <Knuddel />
      }} /> : <FormattedMessage id={declareFormat({
        id: 'channel.message.knuddelreceived',
        defaultFormat: '{nick} knuddeled you. You receive {amount} {image}'
      })} values={{
        nick: <Text bold={true} className={_c3}>{message.sender.nick}</Text>,
        amount: formatAmount(content.knuddelAmount),
        image: <Knuddel />
      }} />}
			</Text>
		</div>;
};
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";