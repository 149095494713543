import { newServiceId } from '@knuddels-app/DependencyInjection';
import { ViewService } from './ViewService';
import { LocationToViewsMapping } from './LocationToViewsMapping';
import { ViewOverlayProvider } from './ViewOverlayProvider';

export const $ViewService = newServiceId<ViewService>('$ViewService');
export const $LocationToViewsMapping = newServiceId<LocationToViewsMapping>(
	'$LocationToViewsMapping'
);
export const $ViewOverlayProvider = newServiceId<ViewOverlayProvider>(
	'$ViewOverlayProvider'
);
