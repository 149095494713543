import { CommandDefinition } from '@knuddels-app/Commands';
import { Result } from '@knuddels/std';

const JAVA_MAX_LONG = BigInt('9223372036854775807');
const JAVA_MIN_LONG = BigInt('-9223372036854775808');

const JAVA_LONG_RANGE = JAVA_MAX_LONG - JAVA_MIN_LONG + BigInt(1);
const SEVEN = BigInt(7);
const ZERO = BigInt(0);

export class ChallengeCommand implements CommandDefinition {
	commandName = 'challenge';

	constructor(
		private currentUserNick: () => string,
		private invokeResponseCommand: (
			command: string
		) => Promise<
			Result<
				void,
				| 'NoCommandDefinitionFound'
				| 'CommandNotSuccessful'
				| 'CommandNotParsable'
			>
		>
	) {}

	async invoke(command: string): Promise<void> {
		// e.g. /challenge 6641877993205387291 DailyLogin
		// Reference implementation in HTMLChat https://github.com/knuddelsgmbh/htmlchat/blob/master/src/js/encryption/Challenge.js
		// Secondary reference implementation in ChatApplet `base.ChallengeResponseCommandInterceptor#visitMe`

		const [challengeString, actualCommand] = command.split(' ');

		const challenge = BigInt(challengeString);
		const nickHash = BigInt(this.nickHash());

		// The Java backend arithmetic depends on 64-bit long numeric overflow which JavaScript does not support out of the box. We emulate that behaviour below.
		let response = (challenge * nickHash * SEVEN) % JAVA_LONG_RANGE;

		if (response < ZERO) {
			response = JAVA_LONG_RANGE + response;
		}

		if (response > JAVA_MAX_LONG) {
			response = JAVA_LONG_RANGE - response;
			response = -response;
		}

		await this.invokeResponseCommand(
			`/${actualCommand} ${response.toString(10)}`
		);
	}

	private nickHash(): number {
		const nick = this.currentUserNick();

		let nickHash = 0;
		for (let i = 0; i < nick.length; i++) {
			const character = nick.charCodeAt(i);
			nickHash = (nickHash << 5) - nickHash + character;
			nickHash = nickHash & nickHash;
		}
		return nickHash;
	}
}
