import * as React from 'react';
import { ConversationKnuddelTransferMessageContentFragment } from '@generated/graphql';
import { FlexCol, Text } from '@knuddels/component-library';
import { Knuddel, Spacer } from '@shared/components';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PrivateMessageFooter } from './PrivateMessageFooter';
import { NickLink } from '../../../NickLink';
import { useFormatAmount } from '@knuddels-app/tools/formatAmount';
export const KnuddelSentMessage: React.FC<{
  receiverId: string;
  receiverNick: string;
  content: ConversationKnuddelTransferMessageContentFragment;
}> = ({
  receiverId,
  receiverNick,
  content
}) => {
  const formatAmount = useFormatAmount('millionOnly');
  return <div className={_c0}>
			<Text state={'secondary'} className={_c1}>
				<FormattedMessage id={declareFormat({
        id: 'conversation.message.knuddelsent',
        defaultFormat: 'You sent {amount}'
      })} values={{
        nick: <NickLink userId={receiverId} nick={receiverNick} />,
        amount: formatAmount(content.knuddelAmount),
        image: <Knuddel />
      }} />
			</Text>
			<Spacer size={'small'} />
			<PrivateMessageFooter />
		</div>;
};
const _c0 = " Knu-FlexCol alignItems-center px-xlarge py-small ";
const _c1 = " textAlign-center ";