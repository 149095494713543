import * as React from 'react';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action, computed, observable, reaction, reactionOnceWhen, runInAction } from '@knuddels-app/mobx';
import { InputField } from '@shared/components';
import { FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { ContactsSearchInput } from './ContactsSearchInput';
import { $ContactsService, $FriendRequestsService } from '../../../providedServices';
import { User } from '@generated/graphql';
import { $UserService } from '@knuddelsModules/UserData';
import { ContactsViewContent } from './ContactsViewContent';
import { Disposable, expectUnreachable } from '@knuddels/std';
import { AllContactsTabs } from './ContactTabs';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';
import { isMobileOrNative } from '@knuddels-app/tools/isMobileOrNative';
import { ContactsUser } from '../../ContactsUser';
interface ContactsProps {
  initialTab?: AllContactsTabs;
  onContactClicked(id: User['id']): void;
}
export type ContactsViewState = {
  kind: 'loading';
} | {
  kind: 'allContactsDisabled';
} | {
  kind: 'search';
  searchValue: string;
  searchValueMatchesCurrentUser: boolean;
  allContacts: readonly ContactsUser[];
} | {
  kind: 'categorized';
  activeTabIndex: number;
  setActiveTabIndex(newIndex: number): void;
};
@injectable()
class ContactsViewModel implements IModel {
  public readonly dispose = Disposable.fn();
  @observable
  searchValue = '';
  searchbarRef = React.createRef<InputField>();
  @observable
  private hasFriendRequestsTabBeenShown = false;
  @observable
  private activeTab: AllContactsTabs = this.initialTab;
  @observable
  private loading = true;
  get activeTabIndex(): number {
    return this.visibleTabs.findIndex(tab => tab === this.activeTab);
  }
  private get initialTab(): AllContactsTabs {
    const visibleTabs = this.visibleTabs;
    return this.props.initialTab && visibleTabs.indexOf(this.props.initialTab) >= 0 ? this.props.initialTab : visibleTabs[0];
  }
  constructor(@injectProps()
  private readonly props: ContactsProps, @inject($ContactsService)
  private readonly contactsService: typeof $ContactsService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($FriendRequestsService)
  private readonly friendRequestsService: typeof $FriendRequestsService.T) {
    this.contactsService.fetchContacts('all');
    this.dispose.track(reactionOnceWhen({
      name: 'track contacts loaded',
      fireImmediately: true
    }, () => this.contactsService.allTabsLoaded && this.friendRequestsService.isLoaded, () => {
      runInAction('loading done', () => {
        this.loading = false;
        this.activeTab = this.initialTab;
      });
      this.firebaseAnalyticsService.logEvent('Contacts_Contactoverview', this.allContacts.length > 0 ? 'ContactsAvailable' : 'NoContactShown');
      if (this.allContacts.some(contact => contact.isOnline)) {
        this.firebaseAnalyticsService.logEvent('Contacts_Contactoverview', 'OnlineContactsAvailable');
      }
    }));
    this.dispose.track(reactionOnceWhen({
      name: 'track search entered',
      fireImmediately: true
    }, () => !!this.searchValue, () => {
      this.firebaseAnalyticsService.logEvent('Contacts_Searchbar', 'SearchEntered');
    }));
    this.dispose.track(reaction({
      name: 'close non-active tab'
    }, () => this.visibleTabs, tabs => {
      if (!tabs.some(it => it === this.activeTab)) {
        runInAction('Set active tab after current one got inactive', () => this.activeTab = this.visibleTabs[0]);
      }
    }));
    this.dispose.track(reactionOnceWhen({
      name: 'set hasFriendRequestsTabBeenShown',
      fireImmediately: true
    }, () => this.showFriendRequestsTab, () => {
      runInAction('set hasFriendRequestsTabBeenShown to true', () => {
        this.hasFriendRequestsTabBeenShown = true;
      });
    }));
  }
  @computed
  public get visibleTabs(): AllContactsTabs[] {
    const visibleTabs: AllContactsTabs[] = [...this.contactsService.activeTabs];
    if (visibleTabs.length > 1) {
      visibleTabs.unshift('all');
    }
    if (this.showFriendRequestsTab) {
      visibleTabs.unshift('friendrequests');
    }
    return visibleTabs;
  }
  @computed
  private get showFriendRequestsTab(): boolean {
    // We don't want the tab to be removed when all requests are resolved
    if (this.hasFriendRequestsTabBeenShown) {
      return true;
    }
    return this.friendRequestsService.sentFriendRequests.length > 0 || this.friendRequestsService.receivedFriendRequests.length > 0;
  }
  @computed
  public get allContacts(): readonly ContactsUser[] {
    return this.contactsService.getContactsForTab('all');
  }
  componentDidMount(): void {
    if (!isMobileOrNative()) {
      /*
                Timeout, so that animation can finish before focusing
                in order to prevent jumping
               */
      onMainThreadIdle(() => {
        setTimeout(() => {
          this.searchbarRef.current?.focus();
        }, 100);
      });
    }
    this.firebaseAnalyticsService.logEvent('Contacts_Contactoverview', 'Opened');
  }
  readonly handleSubmit = (): boolean => {
    if (this.searchValue && !this.isCurrentUserSearched) {
      const exactMatch = this.contactsService.getContactByNick(this.searchValue);
      if (exactMatch) {
        this.onContactClicked(exactMatch, 0);
      }
    }
    return true;
  };
  readonly onContactClicked = async (contact: Pick<User, 'id'>, index: number) => {
    this.firebaseAnalyticsService.logEvent(this.searchValue ? 'Contacts_SearchResult' : 'Contacts_Contact', `ContactClicked_${index + 1}`);
    this.props.onContactClicked(contact.id);
  };
  @action
  readonly updateSearchValue = (newText: string): void => {
    this.searchValue = newText;
  };
  @action
  readonly resetSearchValue = () => {
    this.searchValue = '';
    this.searchbarRef.current.clear();
    this.firebaseAnalyticsService.logEvent('Contacts_Searchbar', 'SearchResetted');
  };
  private get isCurrentUserSearched(): boolean {
    return (
      // ignore if userService is not initialized (should not happen)
      !this.userService.currentUser || this.userService.currentUser.nick.toLowerCase() === this.searchValue.toLowerCase()
    );
  }
  readonly handleSearchBarFocused = (): void => {
    this.firebaseAnalyticsService.logEvent('Contacts_Searchbar', 'Focused');
  };
  @action.bound
  public setActiveTabIndex(index: number, preventTrack = false): void {
    const tab = this.visibleTabs[index];
    this.activeTab = tab;
    if (!preventTrack) {
      this.firebaseAnalyticsService.logEvent('Contacts_Tabbar', this.getTabClickedTrackingItemId(tab));
    }
  }
  private getTabClickedTrackingItemId(tab: AllContactsTabs): string {
    switch (tab) {
      case 'friendrequests':
        return 'Friendrequests_Clicked';
      case 'all':
        return 'All_Clicked';
      case 'friends':
        return 'Friends_Clicked';
      case 'watchlist':
        return 'Watchlist_Clicked';
      case 'fotomeet':
        return 'Fotomeet_Clicked';
      case 'mentees':
        return 'Mentees_Clicked';
      case 'latest':
        return 'LastContacts_Clicked';
      default:
        expectUnreachable(tab);
    }
  }
  @computed
  public get state(): ContactsViewState {
    if (this.loading) {
      return {
        kind: 'loading'
      };
    } else if (this.searchValue) {
      return {
        kind: 'search',
        searchValue: this.searchValue,
        searchValueMatchesCurrentUser: this.isCurrentUserSearched,
        allContacts: this.allContacts
      };
    } else if (this.visibleTabs.length === 0) {
      return {
        kind: 'allContactsDisabled'
      };
    } else {
      return {
        kind: 'categorized',
        activeTabIndex: this.activeTabIndex,
        setActiveTabIndex: this.setActiveTabIndex
      };
    }
  }
}
export const ContactsView = injectedComponent({
  name: 'ContactsView',
  model: ContactsViewModel,
  props: declareProps<ContactsProps>()
}, ({
  // Explicit destructuring for mobx...
  model: {
    updateSearchValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetSearchValue,
    state,
    onContactClicked,
    handleSearchBarFocused,
    visibleTabs,
    ...model
  }
}) => {
  return <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1 + (state.kind === 'categorized' ? _c2 : _c3)}>
					<ContactsSearchInput searchValue={model.searchValue} onSubmit={model.handleSubmit} handleSearchbarFocused={handleSearchBarFocused} updateSearchValue={updateSearchValue} searchbarRef={model.searchbarRef} />
				</div>
				<ContactsViewContent state={state} visibleTabs={visibleTabs} onContactClicked={onContactClicked} />
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 bg-contentBg overflow-hidden ";
const _c1 = " Knu-FlexCol ";
const _c2 = " shadow-none ";
const _c3 = " shadow-Shadow2 ";