import * as providedServices from './providedServices';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { autocompleteInputBarModule } from '@knuddelsModules/AutocompleteInputBar/module';

export const smileyboxModule = new Module({
	name: 'Smileybox module',
	dependencies: [autocompleteInputBarModule],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
