import { RefreshSessionToken, Scalars } from '@generated/graphql';
import { observable, runInAction, when } from '@knuddels-app/mobx';
import { createGraphQLSessionInfo } from '@knuddels-app/tools/clientInformation';
import { ClientInfoInterface } from '@knuddels-app/tools/clientInformation/ClientInfo.interface';
import { BugIndicatingError, Disposable, startInterval } from '@knuddels/std';
import { LinkError } from './AuthService';
import { AuthTokenProvider } from './client/AuthTokenProvider';
import { K3ApolloClient } from './client/K3ApolloClient';
import { Endpoint } from './Endpoints';
import { $K3ApolloClientFactory } from './serviceIds';
import { onInternetAvailable } from './client/onInternetAvailable';
import { dangerouslyGetK3Container } from '../ModuleSystem';
import { $FirebaseAnalyticsService } from '../analytics/firebase';
import { $UserService } from '@knuddelsModules/UserData';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { isNative } from '../tools/isNative';

interface SessionTokenInfo {
	token: Scalars['SessionToken'];
	expiry: Date;
}

type SessionState =
	| {
			kind: 'requestingSessionToken';
			lastSessionTokenInfo: SessionTokenInfo | undefined;
			initial: boolean;
	  }
	| {
			kind: 'loggedIn';
			sessionTokenInfo: SessionTokenInfo;
	  }
	| { kind: 'error'; error: LinkError };

function getCurrentSessionToken(state: SessionState): string | undefined {
	if (state.kind === 'loggedIn') {
		return state.sessionTokenInfo.token;
	} else if (
		state.kind === 'requestingSessionToken' &&
		state.lastSessionTokenInfo
	) {
		return state.lastSessionTokenInfo.token;
	}
	return undefined;
}

/**
 * Given a device token, it keeps a session token up to date.
 */
export class AuthSession implements AuthTokenProvider {
	public readonly dispose = Disposable.fn();

	@observable private _state: SessionState | undefined;
	public get state(): SessionState {
		if (!this._state) {
			throw new BugIndicatingError('State is not yet initialized!');
		}
		return this._state;
	}

	/**
	 * Returns the current auth token. Returns undefined if not authenticated or while refreshing the token.
	 */
	public get currentAuthToken(): string | undefined {
		return getCurrentSessionToken(this.state);
	}

	private readonly client: K3ApolloClient;

	private readonly refreshSessionIntervalMs = 30 * 1000;
	private readonly failOnNonAuthErrorTimeMs =
		2 * this.refreshSessionIntervalMs;
	private readonly refreshSessionTimeMs = 5 * 60 * 1000;

	constructor(
		public readonly deviceToken: string,
		clientFactory: typeof $K3ApolloClientFactory.T,
		public readonly endpoint: Endpoint,
		public readonly clientInfo: ClientInfoInterface
	) {
		this.client = this.dispose.track(
			clientFactory.createClient({
				endpoint,
				authTokenProvider: AuthTokenProvider.constant(deviceToken),
				withSubscriptionClient: false,
			})
		);

		this.dispose.track(
			startInterval(this.refreshSessionIntervalMs, () =>
				this.requestSessionTokenIfNecessary()
			)
		);

		this.requestSessionTokenAndUpdateState();

		if (!this._state) {
			throw new BugIndicatingError('State is not yet initialized!');
		}
	}

	public async getOrRetrieveAuthToken(): Promise<string> {
		this.requestSessionTokenIfNecessary();

		return await when(
			{ name: 'Waiting for being logged in' },
			() => this.state,
			{
				resolveTest: s => getCurrentSessionToken(s),
				rejectTest: s =>
					s.kind === 'error' && new Error('Authentication Error'),
			}
		);
	}

	public forceRefreshSession(): void {
		if (this._state?.kind === 'requestingSessionToken') {
			return;
		}

		this.requestSessionTokenAndUpdateState({
			shouldForceFreshSession: true,
		});
	}

	private async requestSessionTokenIfNecessary(): Promise<void> {
		if (this.state.kind === 'requestingSessionToken') {
			// A new session token is already being requested.
			// Nothing to do here.
			return;
		}

		if (
			this.state.kind === 'loggedIn' &&
			!isTimeDueInTimeSpanMs(
				this.state.sessionTokenInfo.expiry,
				this.refreshSessionTimeMs
			)
		) {
			// We are logged in and the token will not expire soon.
			// Nothing to do here.
			return;
		}

		await this.requestSessionTokenAndUpdateState();
	}

	private async requestSessionTokenAndUpdateState(
		options: { shouldForceFreshSession?: boolean } = {}
	): Promise<void> {
		if (this._state?.kind === 'error') {
			return;
		}
		// An auth error would be a critical error.
		let localState:
			| {
					ignoreNonCriticalError: true;
					lastSessionTokenStillValid: true;
					lastSessionTokenInfo: SessionTokenInfo;
			  }
			| {
					ignoreNonCriticalError: false;
					lastSessionTokenStillValid: false;
					lastSessionTokenInfo: SessionTokenInfo | undefined;
			  } = {
			ignoreNonCriticalError: false,
			lastSessionTokenStillValid: false,
			lastSessionTokenInfo: undefined,
		};

		const state = this._state;
		if (
			!options.shouldForceFreshSession &&
			state &&
			state.kind === 'loggedIn'
		) {
			if (
				!isTimeDueInTimeSpanMs(
					state.sessionTokenInfo.expiry,
					this.failOnNonAuthErrorTimeMs
				)
			) {
				// token expiry is far enough away so that we can still use it,
				// even if a non critical error occurs.

				localState = {
					ignoreNonCriticalError: true,
					lastSessionTokenStillValid: true,
					lastSessionTokenInfo: state.sessionTokenInfo,
				};
			} else {
				localState.lastSessionTokenInfo = state.sessionTokenInfo;
			}
		}

		const initial = state === undefined;
		this.updateState({
			kind: 'requestingSessionToken',
			lastSessionTokenInfo: localState.lastSessionTokenStillValid
				? localState.lastSessionTokenInfo
				: undefined,
			initial,
		});

		const value = await requestSessionToken(
			this.client,
			this.clientInfo,
			localState.lastSessionTokenInfo
				? localState.lastSessionTokenInfo.token
				: undefined
		);

		if (value.kind === 'success') {
			this.updateState({
				kind: 'loggedIn',
				sessionTokenInfo: {
					token: value.token,
					expiry: new Date(+value.expiry),
				},
			});
		} else if (
			value.kind !== 'SessionConnectionError' &&
			value.kind !== 'AuthError'
		) {
			// handle non-critical errors

			if (localState.ignoreNonCriticalError) {
				this.updateState({
					kind: 'loggedIn',
					sessionTokenInfo: localState.lastSessionTokenInfo,
				});
			} else if (options.shouldForceFreshSession) {
				// retry (maybe chatserver is restarting?)
				// In a following task later we will trigger a global disconnect UX to show the user
				// that there are technical problems with the connection.
				setTimeout(() => {
					onInternetAvailable(() => {
						this.requestSessionTokenAndUpdateState(options);
					});
				}, 1000);
			} else {
				this.logSessionError(value);
				this.updateState({ kind: 'error', error: value });
			}
		} else {
			// critical errors should trigger error screen/logout.
			this.logSessionError(value);
			this.updateState({ kind: 'error', error: value });
		}
	}

	private logSessionError(value: LinkError): void {
		try {
			const userService =
				dangerouslyGetK3Container().getService($UserService);
			const message = `error%${value.kind}%token%${this.deviceToken.substring(0, 8)}userId%${userService.currentUser?.id}%`;
			dangerouslyGetK3Container()
				.getService($FirebaseAnalyticsService)
				.logEvent(`session_error`, message);

			if (isNative()) {
				FirebaseCrashlytics.recordException({ message }).catch(
					() => {}
				);
			}
		} catch (e: unknown) {
			/* empty */
		}
	}

	private updateState(newState: SessionState): void {
		runInAction(`Set auth session state to ${newState.kind}`, () => {
			this._state = newState;
		});
	}
}

async function requestSessionToken(
	client: K3ApolloClient,
	clientInfo: ClientInfoInterface,
	oldSessionToken?: Scalars['SessionToken']
): Promise<
	| {
			kind: 'success';
			token: Scalars['SessionToken'];
			expiry: Scalars['UtcTimestamp'];
	  }
	| LinkError
> {
	const response = await client.query(
		RefreshSessionToken,
		{
			sessionInfo: await createGraphQLSessionInfo(clientInfo),
			oldSessionToken,
		},
		'no-cache'
	);

	return response.match({
		ok: data => {
			if (data.primaryData.__typename === 'RefreshSessionSuccess') {
				return {
					kind: 'success',
					...data.primaryData,
				};
			} else if (data.primaryData.__typename === 'RefreshSessionError') {
				return {
					kind: 'SessionConnectionError',
					data: data.primaryData,
				};
			} else {
				// fallback which shouldn't happen
				return { kind: 'InternalError' };
			}
		},
		error: error => {
			if (error.kind === 'NetworkError') {
				const e = error.error as any;
				if (e.networkError && e.networkError.statusError === 401) {
					return {
						kind: 'AuthError',
						details: 'InvalidToken',
						error,
					};
				}
			}

			return { kind: 'CouldNotReachServer', error };
		},
	});
}

/**
 * Tests whether an event at `time` will happen in the next `timeSpanMs` milliseconds.
 */
function isTimeDueInTimeSpanMs(time: Date, timeSpanMs: number): boolean {
	return Date.now() + timeSpanMs >= time.getTime();
}
