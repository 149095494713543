import * as React from 'react';
import { Spacer } from '@shared/components';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { BasicUser } from '../../utils/BasicUser';
import { Checkbox, createAccessiblePointerEventHandler, Flex, FlexCol, LineDivider, Text, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
export type OnChangeHandler = (user: BasicUser, checked: boolean) => void;
interface ChooseContactEntryProps {
  user: BasicUser;
  selected: boolean;
  onChange: OnChangeHandler;
}
export const ChooseContactEntry: React.FC<ChooseContactEntryProps> = props => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => props.onChange(props.user, !props.selected)
  })} className={_c0}>
			<Checkbox onChange={({
      value
    }) => props.onChange(props.user, value)} value={props.selected} />

			<Spacer size={'large'} />

			<UserImage uid={props.user.id} type={UserImageType.CHAT} />

			<Spacer size={'small'} />
			<Spacer size={'tiny'} />

			<div className={_c1}>
				<Text type={'subtitle'} state={'secondary'} className={_c2}>
					{props.user.nick}
				</Text>
				<LineDivider className={_c3} />
			</div>
		</div>;
};
const _c0 = " Knu-Flex cursor-pointer alignItems-center px-large ";
const _c1 = " Knu-FlexCol flexGrow-1 pt-base ";
const _c2 = " mb-base ";
const _c3 = "  ";