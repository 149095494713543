import * as React from 'react';
import { useEffect } from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { ContactsUser } from '../../ContactsUser';
import { Flex, FlexCol, Image, ScrollView, Text, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Button, Spacer } from '@shared/components';
import { useMutation } from '@knuddels-app/Connection';
import { ActivateMentorSystem } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, FormattedMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { observer } from '@knuddels-app/mobx';
import { ContactsTabView } from './ContactsTabView';
import { $MentorService } from '../../../../MentorSystem';
import { useNavigate } from '../ContactsRouter';
export const MentorSystem: React.FC<{
  onContactClicked: (contact: ContactsUser, index: number) => void;
}> = observer('MentorSystem', ({
  onContactClicked
}) => {
  const navigate = useNavigate();
  const service = useService($MentorService);
  const status = service.mentorStatus;
  if (!status) {
    return null;
  }
  switch (status.__typename) {
    case 'MentorStatusAvailable':
      return <MentorSystemAvailable />;
    case 'MentorStatusActive':
      return <div className={_c0}>
					<service.MentorStatusBar activeStatus={status} onPressMentorStatus={() => navigate('mentorsystem-about', {
          params: {
            tab: 1
          }
        })} onPressNextReward={() => navigate('mentorsystem-rewards')} onPressProgressBar={() => navigate('mentorsystem-rewards', {
          params: {
            tab: 1
          }
        })} />
					<ContactsTabView tab={'mentees'} onContactClicked={onContactClicked} />
				</div>;
    default:
      return null;
  }
});
const MentorSystemAvailable: React.FC = () => {
  const mentorService = useService($MentorService);
  const activate = useActivateMentorSystem();
  const track = mentorService.useTrackContactsMentorList();
  const i18n = useService($I18n);
  useEffect(() => {
    track('FirstOpenShown');
  }, []);
  const activateMentorSystem = () => {
    track('FirstOpenStart');
    activate();
  };
  const onMentorSystemLinkClick = () => {
    track('FirstOpenMoreInformation');
  };
  return <div className={_c1}>
			<Text type={'title'} bold={true} className={_c2}>
				<FormattedMessage id={declareFormat({
        id: 'mentorsystem.mentor-available.title',
        defaultFormat: 'Welcome to the mentor system!'
      })} />
			</Text>
			<Spacer size={'xlarge'} />
			<div className={_c3}>
				<Description icon={require('@shared/icons/sm_abo_18-02_heartforyou-boy_200.gif')} iconWidth={39} iconHeight={32} text={i18n.format(declareFormat({
        id: 'mentorsystem.mentor-available.description1',
        defaultFormat: 'Become a mentor and collect points, smileys and knuddel.'
      }))} />
				<Spacer size={'large'} />
				<Description icon={require('../assets/sm_abo_11-02_hi5.gif')} iconWidth={35} iconHeight={26} text={i18n.format(declareFormat({
        id: 'mentorsystem.mentor-available.description2',
        defaultFormat: 'Strike up a conversation with your potential mentee and become a mentor candidate.'
      }))} />
				<Spacer size={'large'} />
				<Description icon={require('../assets/sm_abo_20-03_blumenfuerdich_blume4-ani.gif')} iconWidth={61} iconHeight={39} text={i18n.format(declareFormat({
        id: 'mentorsystem.mentor-available.description3',
        defaultFormat: 'The more active you are, the more rewards you can collect, up to and including honorary membership.'
      }))} />
			</div>
			<Spacer size={'xlarge'} />
			<div className={_c4}>
				<Button text={i18n.format(declareFormat({
        id: 'mentorsystem.mentor-available.start',
        defaultFormat: 'Start as a mentor now'
      }))} onClick={activateMentorSystem} />
			</div>
			<Spacer size={'base'} />
			<mentorService.MentorSystemLink onPress={onMentorSystemLinkClick} />
		</div>;
};
const Description: React.FC<{
  icon: any;
  iconWidth: number;
  iconHeight: number;
  text: string;
}> = ({
  icon,
  iconWidth,
  iconHeight,
  text
}) => {
  return <div className={_c5}>
			<div className={_c6}>
				<Image src={icon} alt={''} style={{
        width: resolveThemingValue(iconWidth, "sizes", useTheme()),
        height: resolveThemingValue(iconHeight, "sizes", useTheme())
      }} className={_c7} />
			</div>
			<Spacer size={'base'} />
			<div className={_c8}>
				<Text className={_c9}>{text}</Text>
			</div>
		</div>;
};
const useActivateMentorSystem = () => {
  const [activate] = useMutation(ActivateMentorSystem);
  const snackbarService = useService($SnackbarService);
  return () => {
    activate({}).then(result => {
      if (result.data?.__typename !== 'Success') {
        snackbarService.showGenericError();
      }
    });
  };
};
const _c0 = " Knu-FlexCol width-100-percent ";
const _c1 = " Knu-ScrollView width-100-percent alignItems-center p-xlarge ";
const _c2 = "  ";
const _c3 = " Knu-FlexCol maxWidth-350px alignItems-center ";
const _c4 = " Knu-FlexCol minWidth-0-px alignItems-center ";
const _c5 = " Knu-Flex ";
const _c6 = " Knu-FlexCol width-61px minWidth-61px alignItems-center ";
const _c7 = "  ";
const _c8 = " Knu-FlexCol flex-1 ";
const _c9 = "  ";