import { ScrollView, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
export const MacroboxScrollContainer: React.FC<{
  pb: number;
}> = ({
  children,
  pb
}) => {
  return <div style={{
    paddingBottom: resolveThemingValue((pb as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>{children}</div>;
};
const _c0 = " Knu-ScrollView ";