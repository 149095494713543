import * as React from 'react';
import { useUpdateProfileUser } from '../profileQueryHelper';
import { mergeDeepPartial } from '@knuddels-app/tools/mergeDeepPartial';
import { useService } from '@knuddels-app/DependencyInjection';
import { $IgnoreService } from '@knuddelsModules/UserData';

// use a component instead of a hook so we can use the context via `useUpdateProfile`
// while also keeping it in the root component `ProfileRouting`
export const SubscribeAndUpdateIgnoreState: React.FC<{
  userId: string;
}> = props => {
  const updateProfileUser = useUpdateProfileUser();
  const ignoreService = useService($IgnoreService);
  React.useEffect(() => {
    return ignoreService.onIgnoreStateChanged.sub(userChanges => {
      if (userChanges.id === props.userId) {
        updateProfileUser(oldData => {
          return mergeDeepPartial(oldData, {
            user: {
              user: userChanges
            }
          });
        });
      }
    }).dispose;
  }, [updateProfileUser]);
  return null;
};