import * as React from 'react';
import { SnackbarButtonData } from '../molecules/Bars/Snackbar/Snackbar';
import { useContext, useRef, useState } from 'react';
import { SnackbarContainer, SnackbarDisplayProps, SnackbarPropsWithType } from '../molecules/Bars/Snackbar/SnackbarContainer';
import { generateId } from '@knuddels/component-library';
const SnackbarContext = React.createContext<(snackbar: SnackbarData) => void>(() => {});
const DEFAULT_LIFE_TIME = 4000;
const LIFE_TIME_INCREMENT = 4000;
const MAX_LIFE_TIME = 10000;
type BaseSnackbarData = {
  text: string;
  subtext?: string | React.ReactElement | undefined;
  adornment?: string | React.ReactElement;
  button?: SnackbarButtonData;
};
type SnackbarData = (BaseSnackbarData & {
  kind: 'lifetime';
  lifeTime: number;
  type: string;
}) | (BaseSnackbarData & {
  kind: 'custom';
  type: string;
  registerFinishObserver: (observer: () => void) => () => void;
});
export type SnackbarProviderProps = SnackbarDisplayProps;
export function useSnackbar(): (snackbar: BaseSnackbarData & {
  type: string;
}) => void {
  const context = useContext(SnackbarContext);
  return snackbar => {
    context({
      ...snackbar,
      kind: 'lifetime',
      lifeTime: DEFAULT_LIFE_TIME
    });
  };
}
export function useCustomSnackbar(): (snackbar: BaseSnackbarData & {
  type?: string;
}) => {
  close: () => void;
} {
  const context = useContext(SnackbarContext);
  return snackbar => {
    const observers: (() => void)[] = [];
    let closed = false;
    context({
      ...snackbar,
      kind: 'custom',
      type: snackbar.type || generateId('snackbar'),
      registerFinishObserver: (observer: () => void) => {
        // Snackbar might have been closed while it wasn't mounted
        if (closed) {
          observer();
          return () => {};
        }
        observers.push(observer);
        return () => {
          observers.splice(observers.indexOf(observer), 1);
        };
      }
    });
    return {
      close: () => {
        observers.forEach(it => it());
        closed = true;
      }
    };
  };
}
export const SnackbarProvider: React.FC<SnackbarProviderProps> = props => {
  const [currentSnackbar, setCurrentSnackbar] = useState<SnackbarPropsWithType>();
  const snackbars = useRef<SnackbarPropsWithType[]>([]);
  const dataToProps = (data: SnackbarData): SnackbarPropsWithType => {
    const removeSnackbar = () => {
      snackbars.current = snackbars.current.slice(1);
      setCurrentSnackbar(snackbars.current[0]);
    };
    const snackbarProps: SnackbarPropsWithType = {
      ...data,
      onFinished: removeSnackbar
    };
    return insertRemoveOnButtonClick(snackbarProps, removeSnackbar);
  };
  const pushSnackbar = (snackbar: SnackbarData) => {
    const snackbarProps = dataToProps(snackbar);
    const lastSnackbar = snackbars.current[snackbars.current.length - 1];
    if (!lastSnackbar) {
      snackbars.current = [snackbarProps];
      setCurrentSnackbar(snackbarProps);
      return;
    }
    if (lastSnackbar.type !== snackbarProps.type) {
      snackbars.current = [...snackbars.current, snackbarProps];
      return;
    }
    const newLastSnackbar = lastSnackbar.kind === 'lifetime' ? {
      ...lastSnackbar,
      lifeTime: Math.min(lastSnackbar.lifeTime + LIFE_TIME_INCREMENT, MAX_LIFE_TIME)
    } : snackbarProps;
    snackbars.current = [...snackbars.current.slice(0, snackbars.current.length - 1), newLastSnackbar];
    if (snackbars.current.length === 1) {
      setCurrentSnackbar(newLastSnackbar);
    }
  };
  return <SnackbarContext.Provider value={pushSnackbar}>
			<SnackbarContainer snackbar={currentSnackbar} isStackedLayout={props.isStackedLayout} bottomSpacing={props.bottomSpacing}>
				{props.children}
			</SnackbarContainer>
		</SnackbarContext.Provider>;
};
function insertRemoveOnButtonClick(snackbar: SnackbarPropsWithType, removeSnackbar: () => void): SnackbarPropsWithType {
  if (snackbar.button && snackbar.button.onPress) {
    const onPress = snackbar.button.onPress;
    return {
      ...snackbar,
      button: {
        ...snackbar.button,
        onPress: () => {
          removeSnackbar();
          onPress();
        }
      }
    };
  } else {
    return snackbar;
  }
}