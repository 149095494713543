import * as React from 'react';
import { ModalWrapperProps } from './ModalWrapperInterface';
import styles from './ModalWrapper.module.scss';
export class ModalWrapper extends React.PureComponent<ModalWrapperProps> {
  private handleClick = (e: any): void => {
    if (e.target && (e.target as HTMLElement).classList.contains(styles.ModalWidthGrow)) {
      // we should only stop propagation if it comes from within the modal, not the wrapper elements
      return;
    }
    e.stopPropagation();
  };
  clickCancel = (): void => {
    this.props.cancelModal();
  };
  render(): JSX.Element {
    const {
      fillHeight = false,
      maxHeight = fillHeight ? '100%' : '82%',
      disablePadding,
      testId
    } = this.props;
    return <div className={styles.ModalWrapperBackground} onClick={this.clickCancel} data-test-id={testId}>
				<div className={disablePadding ? styles.ModalWrapperNoPadding : styles.ModalWrapperPadding}>
					<div className={fillHeight ? styles.ModalHeightStretch : styles.ModalHeight} style={{
          maxHeight
        }}>
						<div className={styles.ModalWidthGrow} onClick={this.handleClick} style={this.props.maxWidth ? {
            maxWidth: this.props.maxWidth
          } : {}}>
							{this.props.children}
						</div>
					</div>
				</div>
			</div>;
  }
}