import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { profileModule } from '@knuddelsModules/Profile/module';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { systemAppsModule } from '@knuddelsModules/SystemApps/module';

export const fotoMeetModule = new Module({
	name: 'FotoMeetModule',
	dependencies: [
		userDataModule,
		profileModule,
		notificationsModule,
		systemAppsModule,
	],
	loadEvent: 'onLogin',
	load: {
		from: () =>
			import(
				/* webpackChunkName: "fotoMeet" */ './bundle/registerServices'
			),
	},
	providedServices,
});
