import * as React from 'react';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Button, Spacer, Z_INDEX } from '@shared/components';
import { InfoBox, InfoBoxItem } from './InfoBox';
import { action, observable, observer } from '@knuddels-app/mobx';
import { Indicators } from './Indicators';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { $ScreenService, ScreenWidth } from '@knuddels-app/Screen';
import { FlexCol, Link, rgb, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { FormatId } from '@knuddels/i18n';
interface Props {
  // Link will be shown on the last screen, if it was set up
  link?: {
    text: FormatId;
    url: string;
  };
  infoItems: ReadonlyArray<InfoBoxItem>;
}
@injectable()
class OnboardingModalModel {
  @observable
  public hasSeenLastItem = false;
  @observable
  public activeInfoIndex = 0;
  constructor(@injectProps()
  private readonly props: Props, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  public get infoItems(): Props['infoItems'] {
    return this.props.infoItems;
  }
  public get link(): Props['link'] {
    return this.props.link;
  }
  @action.bound
  public hideModal(): void {
    this.thisVisibleOverlay.dispose();
  }
  @action.bound
  public handleActiveInfoIndexChange(index: number): void {
    this.activeInfoIndex = index;
    if (!this.hasSeenLastItem) {
      this.hasSeenLastItem = this.activeInfoIndex === this.infoItems.length - 1;
    }
  }
}
export const OnboardingModal = injectedComponent({
  name: 'OnboardingModal',
  model: OnboardingModalModel,
  inject: {
    i18n: $I18n,
    screenService: $ScreenService
  },
  props: declareProps<Props>()
}, ({
  i18n,
  screenService,
  model
}) => screenService.screenWidth === ScreenWidth.XS ? <div className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<OnboardingModalContent model={model} i18n={i18n} />
			</div> : <div style={{
  background: resolveThemingValue(rgb(40, 34, 45, 0.8), "colors", useTheme()),
  zIndex: resolveThemingValue(Z_INDEX.MODAL, "theme", useTheme())
}} className={_c1 + (rgb(40, 34, 45, 0.8) ? resolveIsDarkColor(rgb(40, 34, 45, 0.8), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c2 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<OnboardingModalContent model={model} i18n={i18n} />
				</div>
			</div>);
const OnboardingModalContent = observer('OnboardingModalContent', ({
  model,
  i18n
}: {
  i18n: typeof $I18n.T;
} & typeof OnboardingModal.TPropsWithModel) => {
  const snapToNextRef = React.useRef<(() => void) | null>();
  const isLastItem = (): boolean => {
    return model.activeInfoIndex === model.infoItems.length - 1;
  };
  const onlyOneItem = (): boolean => {
    return model.infoItems.length === 1;
  };
  const hasLink = (): boolean => {
    return !!model.link?.text && !!model.link?.url;
  };
  const renderModalFooter = (): React.ReactNode => {
    // We show link for last item and only if it was set up in model
    if (isLastItem() && hasLink()) {
      return <>
						<Spacer size={'base'} />

						<Link to={model.link!.url} state={'tertiary'}>
							{model.link!.text!.format(i18n)}
						</Link>
					</>;
    }

    // We don't show step progress indicators if we have only one item
    if (onlyOneItem()) {
      return null;
    }

    // Otherwise, we will show step progress with indicators
    return <>
					<Spacer size={'xlarge'} />

					<Indicators items={model.infoItems} activeIndex={model.activeInfoIndex} />
				</>;
  };
  return <>
				<InfoBox snapToNextRef={snapToNextRef} items={model.infoItems} initialActiveItemIndex={model.activeInfoIndex} i18n={i18n} onActiveIndexChanged={model.handleActiveInfoIndexChange} />
				<Button onClick={model.hasSeenLastItem || onlyOneItem() ? model.hideModal : () => snapToNextRef.current?.()} text={model.hasSeenLastItem || onlyOneItem() ? declareFormat({
      id: 'onboarding.modal.button-close.text',
      defaultFormat: "Let's go!"
    }).format(i18n) : declareFormat({
      id: 'onboarding.modal.button-next.text',
      defaultFormat: 'Next'
    }).format(i18n)} kind={'primary'} style={{
      width: 156
    }} />

				<div className={_c3}>
					{renderModalFooter()}
				</div>
			</>;
});
const _c0 = " Knu-FlexCol position-absolute inset-none bg-contentLightBg alignItems-center pb-xlarge ";
const _c1 = " Knu-FlexCol position-absolute inset-none placeItems-center ";
const _c2 = " Knu-FlexCol bg-contentLightBg alignItems-center overflow-hidden width-416px height-660px pb-xlarge borderRadius-5px ";
const _c3 = " Knu-FlexCol height-40px alignItems-center ";