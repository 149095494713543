import { FlexCol, LineDivider } from '@knuddels/component-library';
import * as React from 'react';
import { useIsStAppSidebarOverview } from '@knuddels-app/Environment/useIsStAppSidebarOverview';
export const ContextMenuDivider: React.FC = () => {
  const condensed = useIsStAppSidebarOverview();
  return <div className={_c0 + (condensed ? _c1 : _c2)}>
			<LineDivider className={_c3} />
		</div>;
};
const _c0 = " Knu-FlexCol ";
const _c1 = " py-tiny ";
const _c2 = " py-small ";
const _c3 = "  ";