import { generateId as t } from "../../../a11y/generateId.js";
class n {
  constructor() {
    this.subscriptions = [];
  }
  subscribe(s) {
    return this.subscriptions.push(s), () => {
      this.subscriptions = this.subscriptions.filter((r) => r !== s);
    };
  }
  unsubscribeAll() {
    this.subscriptions = [];
  }
  notifyObservers(s) {
    this.subscriptions.forEach((r) => r(s));
  }
}
class o {
  constructor() {
    this.subscriptionManger = new n();
  }
  subscribe(s) {
    return this.subscriptionManger.subscribe(s);
  }
  generateKey() {
    return t("history_plugin");
  }
  notifyObservers(s) {
    return this.subscriptionManger.notifyObservers(s);
  }
}
export {
  o as AbstractHistory,
  n as HistorySubscriptionManager
};
