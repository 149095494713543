import { createK3Router } from '@knuddels-app/layout/k3Router';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { ChannelListRouteConfig } from '../../ChannelListRouteConfig';

export const {
	Router,
	useParams,
	useRouter,
	useNavigate,
	useConfigureNavBar,
} = createK3Router<ChannelListRouteConfig>(channelListViewId);
