import * as React from 'react';
import { FeatureFlag } from './FeatureFlag';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $FeatureService } from '@knuddels-app/featureFlags/services';
type FlagComponents = {
  OnActive: React.FC;
  OnInactive: React.FC;
};
export const createFlagComponents = (flag: FeatureFlag): FlagComponents => {
  return {
    OnActive: ({
      children,
      ...props
    }) => {
      return <ConditionalFeature featureFlag={flag}>
					{React.cloneElement((children as any), props)}
				</ConditionalFeature>;
    },
    OnInactive: ({
      children,
      ...props
    }) => {
      return <ConditionalFeature featureFlag={flag} invert>
					{React.cloneElement((children as any), props)}
				</ConditionalFeature>;
    }
  };
};

/**
 * Conditionally renders a react element, depending on whether the feature flag is enabled or not.
 * Will reload if feature flag is enabled/disabled.
 * Example:
 * ```tsx
 * <ConditionalFeature featureFlag={myFeatureFlag}>
 *   <View>My Feature is enabled!</View>
 * </ConditionalFeature>
 * ```
 */
const ConditionalFeature = injectedComponent({
  name: 'ConditionalFeature',
  props: declareProps<{
    featureFlag: FeatureFlag;
    invert?: boolean;
    children: React.ReactNode;
  }>(),
  inject: {
    featureFlags: $FeatureService
  }
}, ({
  featureFlag,
  invert,
  featureFlags,
  children
}) => {
  const state = featureFlags.getState(featureFlag);
  const targetState = !invert;
  if (!!state.isEnabled === targetState) {
    return <>{children}</>;
  }
  return <React.Fragment />;
});