import * as React from 'react';
import { DEFAULT_IMAGE } from '../../../constants';
import { useService } from '@knuddels-app/DependencyInjection';
import { declareFormat } from '@knuddels/i18n';
import { $I18n, FormattedMessage } from '@knuddels-app/i18n';
import { nickSwitchFlag } from '@knuddelsModules/FeatureFlags';
import { Knuddel } from '@shared/components';
import { $Environment } from '@knuddels-app/Environment';
import { EditProfileSystemAppEntry } from './EditProfileSystemAppEntry';
import { SystemAppOptions } from './EditProfileEntries';
import { NickSwitchKnuddelCostContext } from '../../NickSwitchKnuddelCostContext';
export const EditProfileNicknameEntry: React.FC = () => {
  const i18n = useService($I18n);
  const environment = useService($Environment);
  const cost = React.useContext(NickSwitchKnuddelCostContext);
  const systemAppOptions: SystemAppOptions = {
    userProperty: 'nick',
    trackingId: 'ChangeNickname_Clicked',
    navigateTo: 'nickSwitch',
    flag: nickSwitchFlag
  };
  const description: JSX.Element = <FormattedMessage id={declareFormat({
    id: 'EDIT_NICKNAME_HELPER_TEXT',
    defaultFormat: 'For {cost} {knuddel} you can change your nickname'
  })} values={{
    knuddel: <Knuddel optimizeForStapp={environment.isStappSidebarOverview} />,
    cost
  }} />;
  return <EditProfileSystemAppEntry label={i18n.format(declareFormat({
    id: 'EDIT_PROFILE_ENTRY_NICKNAME',
    defaultFormat: 'Nickname'
  }))} image={DEFAULT_IMAGE} description={description} systemAppOptions={systemAppOptions} />;
};