import { clamp as m } from "../../../utils/clamp.js";
import { millisecondsToSeconds as b, secondsToMilliseconds as N } from "../../../utils/time-conversion.js";
const x = 1e-3, S = 0.01, T = 10, A = 0.05, G = 1;
function B({ duration: t = 800, bounce: l = 0.25, velocity: e = 0, mass: o = 1 }) {
  let a, p, s = 1 - l;
  s = m(A, G, s), t = m(S, T, b(t)), s < 1 ? (a = (n) => {
    const i = n * s, c = i * t, f = i - e, r = g(n, s), M = Math.exp(-c);
    return x - f / r * M;
  }, p = (n) => {
    const c = n * s * t, f = c * e + e, r = Math.pow(s, 2) * Math.pow(n, 2) * t, M = Math.exp(-c), w = g(Math.pow(n, 2), s);
    return (-a(n) + x > 0 ? -1 : 1) * ((f - r) * M) / w;
  }) : (a = (n) => {
    const i = Math.exp(-n * t), c = (n - e) * t + 1;
    return -x + i * c;
  }, p = (n) => {
    const i = Math.exp(-n * t), c = (e - n) * (t * t);
    return i * c;
  });
  const D = 5 / t, h = R(a, p, D);
  if (t = N(t), isNaN(h))
    return {
      stiffness: 100,
      damping: 10,
      duration: t
    };
  {
    const n = Math.pow(h, 2) * o;
    return {
      stiffness: n,
      damping: s * 2 * Math.sqrt(o * n),
      duration: t
    };
  }
}
const I = 12;
function R(t, l, e) {
  let o = e;
  for (let a = 1; a < I; a++)
    o = o - t(o) / l(o);
  return o;
}
function g(t, l) {
  return t * Math.sqrt(1 - l * l);
}
export {
  g as calcAngularFreq,
  B as findSpring,
  G as maxDamping,
  T as maxDuration,
  A as minDamping,
  S as minDuration
};
