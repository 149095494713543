import { Scalars } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { BoxProps, Flex, FlexCol, LineDivider, Ripple, TextProps, Theme, ThemeOverride, isTouchDevice, useTheme, resolveThemingValue, toPointerHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { UserImageType } from '@knuddelsModules/UserData';
import { ContextMenuTrigger, FormattedText, Spacer } from '@shared/components';
import * as React from 'react';
import { ConversationTitleData } from '../../../utils/getConversationTitle';
import { EntryImage } from './EntryImage';
import { EntryNotificationBadge } from './EntryNotificationBadge';
import { EntrySubtitle } from './EntrySubtitle';
import { EntryTime } from './EntryTime';
import { EntryTitle } from './EntryTitle';
import { MiniListEntry } from './MiniListEntry';
interface Props {
  index: number;
  title: ConversationTitleData;
  subtitle: FormattedText | JSX.Element;
  time?: Scalars['UtcTimestamp'];
  userImageUserId?: string;
  userImageUserNick?: string;
  textState: TextProps['state'];
  timeTextState: TextProps['state'];
  isActive?: boolean;
  isContextMenuOpen: boolean;
  isMinifiedOverview: boolean;
  showUnreadMarker?: boolean;
  hideContextMenuChevron?: boolean;
  notificationBadgeText?: string;
  notificationBadgeType?: 'normal' | 'warning';
  subtitleTextPrefix?: React.ReactElement | false;
  subtitleViewPrefix?: React.ReactElement | false;
  hideDivider?: boolean;
  testId?: string;
  onClick(): void;
  showContextMenu(x: number, y: number, width: number, height: number): void;
  onUserImageClick?(): void;
}
const GenericListEntryBase2: React.FC<Props> = props => {
  const isStAppMessengerOverview = useService($Environment).isStappSidebarOverview;
  const theme = useTheme();
  return <GenericListEntryBase theme={theme} isStAppMessengerOverview={isStAppMessengerOverview} {...props} />;
};
const GenericListEntryBase: React.FC<Props & {
  isStAppMessengerOverview: boolean;
  theme: Theme;
}> = ({
  isStAppMessengerOverview,
  isMinifiedOverview,
  showUnreadMarker,
  notificationBadgeText,
  notificationBadgeType,
  title,
  subtitle,
  subtitleTextPrefix: subtitlePrefixText,
  subtitleViewPrefix: subtitlePrefixView,
  userImageUserId,
  userImageUserNick,
  onClick,
  onUserImageClick,
  time,
  showContextMenu,
  hideContextMenuChevron,
  textState,
  timeTextState,
  hideDivider,
  isContextMenuOpen,
  isActive,
  theme
}) => {
  const [hovered, setHovered] = React.useState(false);
  const getBackgroundColor = (color: string, hoverBgColor: string): string => {
    if (isTouchDevice()) {
      return color;
    }
    return isActive ? theme.colors.basic.listActiveAtom : hovered ? hoverBgColor : color;
  };
  const bgColor = getBackgroundColor('contentBg', 'listHoverAtom');
  const defaultPadding = isStAppMessengerOverview ? 8 : 16;
  const onMouseEnter = (): void => {
    setHovered(true);
  };
  const onMouseLeave = (): void => {
    setHovered(false);
  };
  const content = isMinifiedOverview ? <MiniListEntry uid={userImageUserId} username={userImageUserNick} backgroundColor={bgColor} unreadBadgeText={notificationBadgeText} onPress={onClick} /> : <div style={{
    background: resolveThemingValue((bgColor as ThemeOverride), "colors", useTheme())
  }} className={_c0 + ((bgColor as ThemeOverride) ? resolveIsDarkColor((bgColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Ripple mode={'full'} />
			<div className={_c1}>
				{showUnreadMarker && <div
      // @ts-expect-error Not exposed
      style={{
        ...styles.unreadMarker()
      }} className={_c2 + ((isStAppMessengerOverview ? "rgb(238, 0, 0)" : "accent") ? resolveIsDarkColor(isStAppMessengerOverview ? "rgb(238, 0, 0)" : "accent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isStAppMessengerOverview ? _c3 : _c4)} />}
				<div
      // @ts-expect-error Not exposed
      style={{
        ...styles.wrapper(defaultPadding)
      }} className={_c5}>
					<EntryImage isStAppMessengerOverview={isStAppMessengerOverview} userImageUserId={userImageUserId} onClick={onUserImageClick} bg={bgColor} />

					<Spacer size={isStAppMessengerOverview ? 'small' : 'base'} />
					<div
        // @ts-expect-error Not exposed
        style={{
          ...styles.textWrapper(defaultPadding)
        }} className={_c6}>
						<div className={_c7}>
							<div className={_c8}>
								<EntryTitle textState={textState} isStAppMessengerOverview={isStAppMessengerOverview} title={title} />
							</div>
							{time && !hovered && <EntryTime time={time} isStAppMessengerOverview={isStAppMessengerOverview} hasNotificationBadge={!!notificationBadgeText} textState={timeTextState} />}
						</div>
						{!isStAppMessengerOverview && <Spacer size={'tiny'} />}
						<EntrySubtitle text={subtitle} isStAppMessengerOverview={isStAppMessengerOverview} textState={textState} textPrefix={subtitlePrefixText} viewPrefix={subtitlePrefixView} />
					</div>
					<div className={_c9}>
						{notificationBadgeText && <EntryNotificationBadge text={notificationBadgeText} isWarn={notificationBadgeType === 'warning'} paddingBottom={defaultPadding} isStAppMessengerOverview={isStAppMessengerOverview} />}
					</div>
				</div>
				<div
      // @ts-expect-error Not exposed
      style={{
        ...styles.separatorWrapper(isStAppMessengerOverview, defaultPadding)
      }} className={_c10}>
					{!hideDivider && <LineDivider className={_c11} />}
				</div>
			</div>
		</div>;
  return <div onMouseEnter={toPointerHandler(onMouseEnter)} onMouseLeave={toPointerHandler(onMouseLeave)} onClick={toPointerHandler(hideContextMenuChevron ? onClick : noop)} className={_c12}>
			{hideContextMenuChevron ? content : <ContextMenuTrigger onContentWrapperClick={onClick} showContextMenu={showContextMenu} chevronBackgroundColor={bgColor} position={'top'} overrideTop={8} isMenuActive={isContextMenuOpen} testId={'context-menu-trigger-chevron'}>
					{content}
				</ContextMenuTrigger>}
		</div>;
};
export const GenericListEntry = React.memo(GenericListEntryBase2);
function noop(): void {}
const styles = {
  wrapper: (defaultPadding: number) => ({
    paddingLeft: (defaultPadding as ThemeOverride),
    paddingRight: (defaultPadding as ThemeOverride),
    cursor: 'pointer'
  } as BoxProps),
  unreadMarker: () => ({
    width: 4,
    position: 'absolute',
    left: (0 as any),
    top: (0 as any),
    bottom: (0 as any)
  } as BoxProps),
  textWrapper: (defaultPadding: number) => ({
    flex: 1,
    paddingTop: (defaultPadding as ThemeOverride),
    paddingBottom: (defaultPadding as ThemeOverride),
    justifyContent: 'center',
    alignItems: 'stretch',
    minWidth: 0,
    minHeight: 0
  } as BoxProps),
  separatorWrapper: (isStAppMessengerOverview: boolean, defaultPadding: number) => isStAppMessengerOverview ? ({
    // user image width + padding left + padding right
    marginLeft: (UserImageType.STAPP_SIDEBAR_BIG + defaultPadding * 2 as ThemeOverride),
    marginRight: (defaultPadding as ThemeOverride)
  } as BoxProps) : ({
    // user image width + padding left + padding right
    marginLeft: (UserImageType.MESSENGER + defaultPadding * 2 as ThemeOverride),
    marginRight: (defaultPadding as ThemeOverride)
  } as BoxProps)
};
const _c0 = " Knu-FlexCol overflow-hidden position-relative ";
const _c1 = " Knu-FlexCol ";
const _c2 = " Knu-FlexCol ";
const _c3 = " bg-rgb-238-0- 0- ";
const _c4 = " bg-accent ";
const _c5 = " Knu-Flex ";
const _c6 = " Knu-FlexCol ";
const _c7 = " Knu-Flex ";
const _c8 = " Knu-FlexCol flex-1 position-relative ";
const _c9 = " Knu-Flex position-relative ";
const _c10 = " Knu-FlexCol ";
const _c11 = "  ";
const _c12 = " Knu-FlexCol ";