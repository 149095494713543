const PICS_FOLDER = 'pics/';
export const rewriteAssetUrl = (url: string): string => {
	if (
		url.startsWith('http://') ||
		url.startsWith('https://') ||
		url.startsWith('//')
	) {
		return url;
	}

	url = url.indexOf(PICS_FOLDER) === 0 ? url : PICS_FOLDER + url;
	return 'https://cdnc.knuddelscom.de/' + url;
};
