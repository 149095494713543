import { ServiceId } from '../../ServiceId';
import {
	ServiceIdMap,
	TranslateServiceIds,
	getServices,
} from '../../getServices';
import { $autostart } from '../ids';
import { BindingWithoutTarget, Ctor, PublicProperties } from './ServiceBinder';
import { BindingTarget } from './BindingTarget';

export class BindingWithoutTargetImpl<T> implements BindingWithoutTarget<T> {
	constructor(
		private readonly serviceId: ServiceId<T>,
		private readonly bindingTarget: BindingTarget,
		private readonly rebind: boolean
	) {}

	public toSingleton(clazz: Ctor<T>): void {
		this.bindingTarget.add({
			addToContainer: inversifyContainer => {
				if (this.rebind) {
					inversifyContainer.unbind(this.serviceId.id);
				}
				inversifyContainer
					.bind(this.serviceId.id)
					.to(clazz)
					.inSingletonScope();
			},
		});
	}

	public toSingletonAutostart(clazz: Ctor<T>): void {
		this.bindingTarget.add({
			addToContainer: inversifyContainer => {
				if (this.rebind) {
					inversifyContainer.unbind(this.serviceId.id);
				}
				inversifyContainer
					.bind(this.serviceId.id)
					.to(clazz)
					.inSingletonScope();
				inversifyContainer
					.bind($autostart.id)
					.toService(this.serviceId.id);
			},
		});
	}

	public toConstant(constant: PublicProperties<T>): void {
		this.bindingTarget.add({
			addToContainer: inversifyContainer => {
				if (this.rebind) {
					inversifyContainer.unbind(this.serviceId.id);
				}
				inversifyContainer
					.bind(this.serviceId.id)
					.toConstantValue(constant);
			},
		});
	}

	public toDynamicSingleton<TServices extends ServiceIdMap>(
		dependencies: TServices,
		factory: (services: TranslateServiceIds<TServices>) => T
	): void {
		this.bindingTarget.add({
			addToContainer: inversifyContainer => {
				if (this.rebind) {
					inversifyContainer.unbind(this.serviceId.id);
				}

				inversifyContainer
					.bind(this.serviceId.id)
					.toDynamicValue(context => {
						const deps = getServices(
							dependencies,
							context.container
						);
						const service = factory(deps);
						return service;
					})
					.inSingletonScope();
			},
		});
	}
}
