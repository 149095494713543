import { resolveElements as A } from "../render/dom/utils/resolve-element.js";
import { visualElementStore as l } from "../render/store.js";
import { GroupPlaybackControls as c } from "./GroupPlaybackControls.js";
import { isDOMKeyframes as h } from "./utils/is-dom-keyframes.js";
import { animateTarget as y } from "./interfaces/visual-element-target.js";
import { createVisualElement as g } from "./utils/create-visual-element.js";
import { animateSingleValue as u } from "./interfaces/single-value.js";
import { createAnimationsFromSequence as E } from "./sequence/create.js";
import { isMotionValue as D } from "../value/utils/is-motion-value.js";
function p(t, r, o, i) {
  const m = A(t, i), n = m.length, s = [];
  for (let e = 0; e < n; e++) {
    const a = m[e];
    l.has(a) || g(a);
    const d = l.get(a), f = { ...o };
    typeof f.delay == "function" && (f.delay = f.delay(e, n)), s.push(...y(d, { ...r, transition: f }, {}));
  }
  return new c(s);
}
const S = (t) => Array.isArray(t) && Array.isArray(t[0]);
function V(t, r, o) {
  const i = [];
  return E(t, r, o).forEach(({ keyframes: n, transition: s }, e) => {
    let a;
    D(e) ? a = u(e, n.default, s.default) : a = p(e, n, s), i.push(a);
  }), new c(i);
}
const w = (t) => {
  function r(o, i, m) {
    let n;
    return S(o) ? n = V(o, i, t) : h(i) ? n = p(o, i, m, t) : n = u(o, i, m), t && t.animations.push(n), n;
  }
  return r;
}, z = w();
export {
  z as animate,
  w as createScopedAnimate
};
