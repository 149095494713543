import {
	AutocompleteChannelGroupFragment,
	ChannelGroup,
	ChannelGroupInfo,
	ChannelGroupsByPrefix,
	InitialChannelAutocomplete,
	User,
} from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, DisposableLike, distinct } from '@knuddels/std';
import { action, observable } from '@knuddels-app/mobx';
// tslint:disable-next-line: no-module-bleeding

type AutocompleteUserProvider = () => readonly Pick<
	User,
	'id' | 'nick' | 'isOnline'
>[];

export type AutocompleteChannel = Pick<ChannelGroup, 'id' | 'name'> & {
	info: Pick<ChannelGroupInfo, 'backgroundColor' | 'previewImageUrl'>;
	onlineUserCount: number;
};

const storedAutocompleteSlashCommandsKey = 'autocompleteSlashCommands';

const store = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value));
};
const getStoredValue = <T>(key: string, defaultValue: T): T => {
	const storedValue = localStorage.getItem(key);
	return storedValue ? JSON.parse(storedValue) : defaultValue;
};

@injectable()
export class AutocompleteProviderService {
	public readonly dispose = Disposable.fn();
	private get allUsers(): Pick<User, 'id' | 'nick' | 'isOnline'>[] {
		const users = this.userProviders.reduce(
			(result, provider) => [...result, ...provider()],
			[] as Pick<User, 'id' | 'nick' | 'isOnline'>[]
		);

		return distinct(users, user => user.id);
	}

	@observable
	public autocompleteSlashCommands = getStoredValue(
		storedAutocompleteSlashCommandsKey,
		true
	);

	private readonly userProviders: AutocompleteUserProvider[] = [];

	private readonly initialQuery = new PeriodicCacheUpdatingQuery(
		async fetchPolicy => this.fetchInitialAutocompleteChannels(fetchPolicy)
	);
	private readonly prefixQueries: {
		[key in string]: PeriodicCacheUpdatingQuery<
			readonly AutocompleteChannel[]
		>;
	} = {};

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {
		// Preload initial channel dropup data because it's quite slow at the moment
		this.initialQuery.query();
	}

	@action setAutocompleteSlashCommands(value: boolean): void {
		this.autocompleteSlashCommands = value;
		store(storedAutocompleteSlashCommandsKey, value);
	}

	public registerUserProvider(
		provider: AutocompleteUserProvider
	): DisposableLike {
		this.userProviders.push(provider);

		return () => {
			const index = this.userProviders.indexOf(provider);
			if (index !== -1) {
				delete this.userProviders[index];
			}
		};
	}

	public getUsers(
		prefixFilter: string
	): Pick<User, 'id' | 'nick' | 'isOnline'>[] {
		return this.allUsers
			.filter(
				user => !!user.nick && doesUserMatchFilter(user, prefixFilter)
			)
			.sort((a, b) => a.nick.localeCompare(b.nick));
	}

	public async getChannels(
		prefixFilter?: string
	): Promise<readonly AutocompleteChannel[]> {
		if (!prefixFilter) {
			return await this.initialQuery.query();
		} else {
			const prefixFirstChar = prefixFilter.charAt(0);
			if (!this.prefixQueries[prefixFirstChar]) {
				this.prefixQueries[prefixFirstChar] =
					this.createPrefixQuery(prefixFirstChar);
			}

			const query = this.prefixQueries[prefixFirstChar];
			const result = await query.query();
			return result.filter(channel =>
				channel.name
					.toLowerCase()
					.startsWith(prefixFilter.toLowerCase())
			);
		}
	}

	private createPrefixQuery(
		prefixFirstChar: string
	): PeriodicCacheUpdatingQuery<readonly AutocompleteChannel[]> {
		return new PeriodicCacheUpdatingQuery<readonly AutocompleteChannel[]>(
			async fetchPolicy => {
				return await this.authenticatedClientService.currentK3Client
					.queryWithResultPromise(
						ChannelGroupsByPrefix,
						// We only use the first char as the search prefix and do further filtering client side (see the 'ok' handler below)
						{ prefix: prefixFirstChar },
						fetchPolicy
					)
					.match({
						ok: it => this.createAutocompleteChannels(it),
						error: it => {
							console.error(
								'Error fetching channel category',
								it
							);
							return [];
						},
					});
			},
			true
		);
	}

	private async fetchInitialAutocompleteChannels(
		fetchPolicy: 'cache-first' | 'network-only'
	): Promise<readonly AutocompleteChannel[]> {
		return await this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(InitialChannelAutocomplete, {}, fetchPolicy)
			.match({
				ok: it => this.createAutocompleteChannels(it),
				error: it => {
					console.error('Error fetching channel category', it);
					return [];
				},
			});
	}

	private createAutocompleteChannels = (
		groups: readonly AutocompleteChannelGroupFragment[]
	): readonly AutocompleteChannel[] => {
		const result: AutocompleteChannel[] = [];

		groups.forEach(group => {
			result.push({
				id: group.id,
				name: group.name,
				info: group.info,
				onlineUserCount: group.channels.reduce(
					(acc, current) => acc + current.onlineUserCount,
					0
				),
			});

			if (group.channels.length > 1) {
				group.channels.forEach(channel => {
					if (channel.name !== group.name) {
						result.push({
							id: channel.id,
							name: channel.name,
							info: group.info,
							onlineUserCount: channel.onlineUserCount,
						});
					}
				});
			}
		});

		return result;
	};
}

function doesUserMatchFilter(
	user: { nick: string },
	prefixFilter: string
): boolean {
	return (
		!prefixFilter ||
		user.nick.toLowerCase().startsWith(prefixFilter.toLowerCase())
	);
}

class PeriodicCacheUpdatingQuery<T> {
	private lastNetworkCallTime = Date.now();
	private networkCallInterval = 5 * 60 * 1000;

	constructor(
		private readonly doQuery: (
			fetchPolicy: 'cache-first' | 'network-only'
		) => Promise<T>,
		private readonly waitsForNetworkQuery?: boolean
	) {}

	async query(): Promise<T> {
		const now = Date.now();
		const shouldDoNetworkQuery =
			now - this.lastNetworkCallTime > this.networkCallInterval;

		if (!shouldDoNetworkQuery) {
			return await this.doQuery('cache-first');
		}

		this.lastNetworkCallTime = now;
		if (this.waitsForNetworkQuery) {
			return await this.doQuery('network-only');
		} else {
			const result = await this.doQuery('cache-first');
			this.doQuery('network-only');
			return result;
		}
	}
}
