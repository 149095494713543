function a(e, t, s, u = 1) {
  return l(e, t, u, s);
}
function l(e, t, s, u) {
  if (o(e, t))
    return !0;
  if (s <= 0 && !u || typeof e != "object" || e === null || typeof t != "object" || t === null)
    return !1;
  const n = Object.keys(e), i = Object.keys(t);
  if (n.length !== i.length)
    return !1;
  for (let r = 0; r < n.length; r++) {
    const f = u && u[n[r]], c = f !== void 0 ? f : s - 1;
    if (!Object.hasOwnProperty.call(t, n[r]) || // variation from react code... using recursion to check deeper
    !l(e[n[r]], t[n[r]], c))
      return !1;
  }
  return !0;
}
function o(e, t) {
  return e === t ? e !== 0 || 1 / e === 1 / t : e !== e && t !== t;
}
export {
  a as equalUpToDepth
};
