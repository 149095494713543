import * as React from 'react';
import { FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
export const TabContentPlaceholder: React.FC<{
  fullHeight: boolean;
  children: React.ReactNode;
}> = ({
  fullHeight,
  children
}) => <div style={{
  flex: resolveThemingValue(fullHeight ? 1 : undefined, "theme", useTheme()),
  paddingTop: resolveThemingValue(fullHeight ? undefined : 'large', "spacing", useTheme())
}} className={_c0}>
		{children}
	</div>;
const _c0 = " Knu-FlexCol ";