import * as React from 'react';
import { PlaceholderContent } from '@shared/components';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export function EmptyFriendRequestsPlaceholder(): JSX.Element {
  return <PlaceholderContent description={<FormattedMessage id={declareFormat({
    id: 'friendrequests.empty.description',
    defaultFormat: 'You have answered all friend requests.'
  })} />} title={<FormattedMessage id={declareFormat({
    id: 'friendrequests.empty.title',
    defaultFormat: 'Yay!'
  })} />} image={require('./sm_abo_15-10_goodfriends.gif')} imageWidth={128} imageHeight={39} />;
}