import { useQuery, useSubscription } from '@knuddels-app/Connection';
import {
	PrivacySettings,
	PrivacySettingsQuery,
	PrivacySettingsSubscription,
} from '@generated/graphql';
import { useEffect, useState } from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { ERROR_SAVING_SETTINGS } from '../../shared-i18n';

export const usePrivacySettingState = () => {
	const { data, loading } = useQuery(PrivacySettingsQuery, {}, 'no-cache');

	const [privacyState, setPrivacyState] = useState<
		PrivacySettings | undefined
	>(data);

	useEffect(() => {
		if (data) {
			setPrivacyState(data);
		}
	}, [data]);

	useSubscription(PrivacySettingsSubscription, {}, updatedSettings => {
		setPrivacyState(updatedSettings);
	});

	return {
		loading,
		data: privacyState,
		updateData: (newData: Partial<PrivacySettings>) => {
			const saveData = privacyState;

			setPrivacyState(oldData => ({
				...oldData,
				...newData,
			}));

			return {
				revertUpdate: () => {
					setPrivacyState(saveData);
				},
			};
		},
	};
};

export const useFailedSaveSnackbar = () => {
	const i18n = useService($I18n);
	const snackbarService = useService($SnackbarService);
	return () => {
		snackbarService.showGenericError(ERROR_SAVING_SETTINGS.format(i18n));
	};
};
