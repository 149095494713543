import * as React from 'react';
import { Spacer, TextBadge } from '@shared/components';
import { Flex, TabText, useTheme } from '@knuddels/component-library';
export const TabBarElement: React.FC<{
  active?: boolean;
  title: string;
  icon?: any;
  badge?: string;
}> = ({
  icon: TabIcon,
  active,
  title,
  badge
}) => {
  const theme = useTheme();
  return <>
			{TabIcon && <div className={_c0}>
					<TabIcon size={'base'} active={active} />
				</div>}
			<TabText active={active}>{title}</TabText>
			{typeof badge === 'string' && <>
					<Spacer size={'tiny'} />
					<TextBadge text={badge} rawColor={active ? theme.colors.basic.iconStaticAtom : undefined} />
				</>}
		</>;
};
const _c0 = " Knu-Flex mr-tiny ";