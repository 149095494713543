import { analyseComplexValue as l } from "../../../value/types/complex/index.js";
import { getAnimatableNone as m } from "../../dom/value-types/animatable-none.js";
const f = /* @__PURE__ */ new Set(["auto", "none", "0"]);
function g(e, i, o) {
  let n = 0, a;
  for (; n < e.length && !a; ) {
    const t = e[n];
    typeof t == "string" && !f.has(t) && l(t).values.length && (a = e[n]), n++;
  }
  if (a && o)
    for (const t of i)
      e[t] = m(o, a);
}
export {
  g as makeNoneKeyframesAnimatable
};
