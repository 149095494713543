import * as React from 'react';
import { Spacer } from '@shared/components';
import { FormattedMessage } from '@knuddels-app/i18n';
import { NEW_FRIENDS } from '../../i18n/shared-formats';
import { Box, Flex, Image, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useMutation } from '@knuddels-app/Connection';
import { MarkProfileSeenAfterFriendshipStarted } from '@generated/graphql';
export const NewFriendsBanner: React.FC<{
  userId: string;
  size: 'small' | 'large';
}> = ({
  userId,
  size
}) => {
  const [markSeen] = useMutation(MarkProfileSeenAfterFriendshipStarted);
  React.useEffect(() => {
    markSeen({
      userId
    });
  }, []);
  const confettis = size === 'large' ? confettisLarge : confettisSmall;
  return <div className={_c0 + ("rgba(71,209,179,0.88)" ? resolveIsDarkColor("rgba(71,209,179,0.88)", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (size === 'large' ? _c1 : _c2) + (size === 'large' ? _c3 : _c4) + (size === 'large' ? _c5 : _c6)}>
			<Spacer size={size === 'large' ? 'xxlarge' : 'small'} />
			{confettis.map((confetti, index) => <Confetti key={'confetti-' + index} {...confetti} />)}
			<Image src={require('@shared/images/sm_xmas_17_knallbonbon-ani.gif')} alt={''} className={_c7 + (size === 'large' ? _c8 : _c9) + (size === 'large' ? _c10 : _c11)} />
			<Spacer size={size === 'large' ? 'small' : 'tiny'} />
			<div className={_c12 + (size === 'large' ? _c13 : _c14)}>
				<Text bold={true} className={_c15}>
					<FormattedMessage id={NEW_FRIENDS} />
				</Text>
			</div>
		</div>;
};
type ConfettiProps = {
  size: number;
  left: number;
  top: number;
  color: string;
};
const Confetti: React.FC<ConfettiProps> = ({
  size,
  left,
  color,
  top
}) => {
  return <div style={{
    background: resolveThemingValue((color as any), "colors", useTheme()),
    left: resolveThemingValue((left as any), "spacing", useTheme()),
    top: resolveThemingValue((top as any), "spacing", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme()),
    height: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c16 + ((color as any) ? resolveIsDarkColor((color as any), useTheme()) ? " content-is-dark" : " content-is-light" : "")} />;
};
const colors = {
  grey: 'rgba(231,254,249, 0.6)',
  red: 'rgb(251,106,142)',
  orange: 'rgb(251,176,106)'
};
const confettisLarge: ConfettiProps[] = [{
  left: 8,
  top: 26,
  size: 6,
  color: colors.grey
}, {
  left: 24,
  top: 16,
  size: 6,
  color: colors.red
}, {
  left: 22,
  top: 32,
  size: 9,
  color: colors.orange
}, {
  left: 33,
  top: 46,
  size: 6,
  color: colors.grey
}, {
  left: 287,
  top: 45,
  size: 6,
  color: colors.red
}, {
  left: 293,
  top: 14,
  size: 6,
  color: colors.grey
}, {
  left: 301,
  top: 32,
  size: 9,
  color: colors.orange
}, {
  left: 318,
  top: 14,
  size: 6,
  color: colors.grey
}, {
  left: 322,
  top: 29,
  size: 6,
  color: colors.red
}];
const confettisSmall: ConfettiProps[] = [{
  left: 8,
  top: 36,
  size: 6,
  color: colors.grey
}, {
  left: 10,
  top: 10,
  size: 6,
  color: colors.red
}, {
  left: 2,
  top: 16,
  size: 6,
  color: colors.orange
}, {
  left: 206,
  top: 8,
  size: 6,
  color: colors.grey
}, {
  left: 212,
  top: 28,
  size: 6,
  color: colors.orange
}];
const _c0 = " Knu-Flex bg-rgba-71-209-179-0-88- borderRadius-base ";
const _c1 = " height-64px ";
const _c2 = " height-48px ";
const _c3 = " width-340px ";
const _c4 = " width-228px ";
const _c5 = " pt-small ";
const _c6 = " pt-tiny ";
const _c7 = "  ";
const _c8 = " width-91px ";
const _c9 = " width-80px ";
const _c10 = " height-38px ";
const _c11 = " height-33px ";
const _c12 = " Knu-Box ";
const _c13 = " pt-base ";
const _c14 = " pt-minor ";
const _c15 = "  ";
const _c16 = " Knu-Box position-absolute borderRadius-circle ";