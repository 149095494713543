import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { Flex, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { userIsJames } from '@shared/helper/user';
import * as React from 'react';
import { ConversationBackground } from '../../Conversation/ConversationBackground';
import { ConversationBody } from '../../Conversation/ConversationBody/ConversationBody';
import { WithConversationWithMessages } from '../../Conversation/WithConversationWithMessages';
import { BotConversationBody } from '../BotConversationBody';
import { MiniChatContext } from '../MiniChatContext';
import MiniChatHeader from './MiniChatHeader';
const CHAT_HEIGHT = 387;
export const MiniChat: React.FC<{
  conversationId: string;
  onPress?: (e: any) => void;
  onClose: () => void;
}> = observer('MiniChat', ({
  conversationId,
  onPress,
  onClose
}) => {
  const thisVisibleOverlay = useService($ThisVisibleOverlay);
  const [isTyping, setIsTyping] = React.useState(false);
  return <div onClick={toPointerHandler(onPress)} style={{
    // workaround for safari to make rounded corners work
    // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
    transform: 'translateZ(0)',
    height: resolveThemingValue(CHAT_HEIGHT, "sizes", useTheme())
  }} className={_c0 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<MiniChatContext.Provider value>
				<WithConversationWithMessages id={conversationId}>
					{(conversation, loading, fetchMoreMessages, initialLoading) => {
          return <>
								<MiniChatHeader isTyping={isTyping} conversation={conversation} onClose={() => {
              thisVisibleOverlay.dispose();
              onClose();
            }} />
								{conversation ? conversation.otherParticipants[0].isAppBot || userIsJames(conversation.otherParticipants[0]) ? <BotConversationBody otherParticipant={conversation.otherParticipants[0]} /> : <ConversationBackground>
											<ConversationBody key={conversation.id} onOtherParticipantTypingChange={setIsTyping} conversation={conversation} loading={loading} initialLoading={initialLoading} scrollToMessageId={''} indentInput fetchMoreMessages={fetchMoreMessages} />
										</ConversationBackground> : null}
							</>;
        }}
				</WithConversationWithMessages>
			</MiniChatContext.Provider>
		</div>;
});
const _c0 = " Knu-Flex mx-base bg-transparent flexDirection-column maxHeight-full borderBottomRadius-minor overflow-hidden ";