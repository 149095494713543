import * as React from 'react';
import { $PromotionService } from '../../providedServices';
import { useService } from '@knuddels-app/DependencyInjection';
import { HappyHourBanner } from '@knuddelsModules/Promotions/bundle/components/HappyHourBanner';
import { KnuddelsPlusBanner } from '@knuddelsModules/UserData';
import { observer } from '@knuddels-app/mobx';
export const KnuddelsPlusOrHappyHourBanner: React.FC = observer('KnuddelsPlusOrHappyHourBanner', () => {
  const promotionService = useService($PromotionService);
  if (promotionService.activeHappyHour && parseInt(promotionService.activeHappyHour.endTimestamp, 10) > Date.now()) {
    return <HappyHourBanner happyHour={promotionService.activeHappyHour} />;
  } else {
    return <KnuddelsPlusBanner />;
  }
});