import { GetAlbumInfoForProfile, GetUserForProfile } from '@generated/graphql';
import { QueryWithSyncedCacheReturnValue } from '@knuddels-app/Connection';
import * as React from 'react';
import { BugIndicatingError } from '@knuddels/std';

export type ProfileUser = typeof GetUserForProfile['TPrimaryResult']['user']['user'];
export type ProfileAlbumInfo = typeof GetAlbumInfoForProfile['TPrimaryResult'];

export type ProfileUserQueryUpdater = QueryWithSyncedCacheReturnValue<
	typeof GetUserForProfile['TPrimaryResult']
>['updateCache'];

export type ProfileAlbumInfoQueryUpdater = QueryWithSyncedCacheReturnValue<
	typeof GetAlbumInfoForProfile['TPrimaryResult']
>['updateCache'];

export const UpdateProfileUserQueryContext = React.createContext<
	ProfileUserQueryUpdater
>(() => {
	throw new BugIndicatingError(
		'UpdateProfileUserQueryContext.Provider is missing.'
	);
});

export const useUpdateProfileUser = () => {
	return React.useContext(UpdateProfileUserQueryContext);
};

export const UpdateProfileAlbumInfoQueryContext = React.createContext<
	ProfileAlbumInfoQueryUpdater
>(() => {
	throw new BugIndicatingError(
		'UpdateProfileAlbumInfoQueryContext.Provider is missing.'
	);
});

export const useUpdateProfileAlbums = () => {
	return React.useContext(UpdateProfileAlbumInfoQueryContext);
};

export const RefetchProfileContext = React.createContext<() => void>(
	() => new Error('Not implemented')
);

export const useRefetchProfile = () => {
	return React.useContext(RefetchProfileContext);
};
