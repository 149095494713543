import { BoxProps, FlexProps } from '@knuddels/component-library';
import { navBarWidthCollapsed } from '@shared/constants';

interface BaseNavBarConfiguration {
	/**
	 * If true, then nav will be hidden if the native keyboard is visible.
	 */
	hideIfKeyboardIsShown?: boolean;

	hideItemTitle?: boolean;

	/**
	 * The type of shadow to show around the nav.
	 */
	shadow: BoxProps['shadow'] | ((collapsed: boolean) => BoxProps['shadow']);

	/**
	 * The style to apply to the shadow component that wraps the nav.
	 */
	shadowStyle: FlexProps;

	/**
	 * Whether the nav is shown above or below the content.
	 */
	zIndex: 'belowContent' | 'aboveContent';

	/**
	 * If specified, the left margin that is applied to the content.
	 */
	contentMarginLeft?: number;

	/**
	 * The content that is shown in the nav.
	 * If 'minimal', only a few nav icons will be shown.
	 * If 'full', everything will be shown (profile picture, nav icons, logout button, etc.). This can only be used if
	 * 'navLocation' is 'left'.
	 */
	shownItems: 'minimal' | 'full';

	extendOptions: {
		/**
		 * The action that should be performed when the nav type changes to this type.
		 */
		typeChangeAction?:
			| 'none'
			| 'collapse'
			| 'extendUnlessPreviouslyCollapsed';

		/**
		 * If true, persists and restores whether the nav was extended or collapsed.
		 */
		useStoredState?: boolean;

		/**
		 * If true, clicking on an nav item will collapse the nav.
		 */
		collapseOnItemClick?: boolean;

		/**
		 * If true and the nav is extended, a semi-transparent overlay will be shown above the content.
		 */
		overlayContentIfExtended?: boolean;
	};
}

const nonExtendingExtendOptions: BaseNavBarConfiguration['extendOptions'] = {
	typeChangeAction: 'none',
};

interface LeftNavBarConfiguration extends BaseNavBarConfiguration {
	navLocation: 'left';

	extendOptions: BottomNavBarConfiguration['extendOptions'] & {
		/**
		 * Whether the nav can be extended.
		 */
		canBeExtended?: boolean;
	};
}

interface BottomNavBarConfiguration extends BaseNavBarConfiguration {
	navLocation: 'bottom';

	shownItems: 'minimal';
}

export type NavBarConfiguration =
	| LeftNavBarConfiguration
	| BottomNavBarConfiguration;

export type NavBarConfigurations =
	| 'leftAbsolute'
	| 'leftRelative'
	| 'minimalBottom'
	| 'minimalLeft';

export const navBarConfigurations: {
	[key in NavBarConfigurations]: NavBarConfiguration;
} = {
	leftAbsolute: {
		shadow: collapsed => (collapsed ? 'Shadow2' : 'Shadow4'),
		shadowStyle: {
			flexDirection: 'column',
			position: 'absolute',
			left: '0px' as any,
			top: '0px' as any,
			bottom: '0px' as any,
		},
		navLocation: 'left',
		shownItems: 'full',
		zIndex: 'aboveContent',
		contentMarginLeft: navBarWidthCollapsed,
		extendOptions: {
			typeChangeAction: 'collapse',
			canBeExtended: true,
			collapseOnItemClick: true,
			overlayContentIfExtended: true,
		},
	},
	leftRelative: {
		shadow: collapsed => (collapsed ? 'Shadow2' : 'Shadow4'),
		shadowStyle: {},
		navLocation: 'left',
		shownItems: 'full',
		zIndex: 'belowContent',
		extendOptions: {
			typeChangeAction: 'extendUnlessPreviouslyCollapsed',
			canBeExtended: true,
			useStoredState: true,
		},
	},
	minimalBottom: {
		hideIfKeyboardIsShown: true,
		shadow: 'none',
		shadowStyle: {},
		navLocation: 'bottom',
		shownItems: 'minimal',
		zIndex: 'aboveContent',
		extendOptions: nonExtendingExtendOptions,
	},
	minimalLeft: {
		hideItemTitle: true,
		shadow: 'Shadow2',
		shadowStyle: {
			width: navBarWidthCollapsed,
		},
		navLocation: 'left',
		shownItems: 'minimal',
		zIndex: 'belowContent',
		extendOptions: nonExtendingExtendOptions,
	},
};
