import * as ids from '../providedServices';
import { foreachProp } from '@knuddels/std';
import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as services from './services';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$SaveProfileService: ctx.loggedInScope.toSingleton(
			services.SaveProfileService
		),
		$ProfileNavigationService: ctx.loggedInScope.toSingleton(
			services.ProfileNavigationService
		),
		$AlbumPicturesService: ctx.loggedInScope.toSingleton(
			services.AlbumPicturesService
		),
		$ProfilePictureUploadService: ctx.loggedInScope.toSingleton(
			services.ProfilePictureUploadService
		),
		$ProfileService: ctx.loggedInScope.toSingletonAutostart(
			services.ProfileService
		),
		$LoggedOutProfileService: ctx.globalScope.toSingletonAutostart(
			services.LoggedOutProfileService
		),
		$AlbumPhotoUploadService: ctx.loggedInScope.toSingleton(
			services.AlbumPhotoUploadService
		),
	});
}
