import { isString as n } from "../utils.js";
const s = (t) => ({
  test: (e) => n(e) && e.endsWith(t) && e.split(" ").length === 1,
  parse: parseFloat,
  transform: (e) => `${e}${t}`
}), p = s("deg"), r = s("%"), a = s("px"), c = s("vh"), g = s("vw"), i = {
  ...r,
  parse: (t) => r.parse(t) / 100,
  transform: (t) => r.transform(t * 100)
};
export {
  p as degrees,
  r as percent,
  i as progressPercentage,
  a as px,
  c as vh,
  g as vw
};
