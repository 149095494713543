interface MinScreenSizeRange {
	min: number;
	max?: number;
}
interface MaxScreenSizeRange {
	min?: number;
	max: number;
}
type ScreenSizeType = MinScreenSizeRange | MaxScreenSizeRange;

enum ScreenWidth {
	XS = 'XS',
	S = 'S',
	M = 'M',
	L = 'L',
	XL = 'XL',
}

const registeredScreenWidths: {
	[screenName in ScreenWidth]: ScreenSizeType;
} = {
	[ScreenWidth.XS]: {
		max: 740,
	},
	[ScreenWidth.S]: {
		min: 741,
		max: 991,
	},
	[ScreenWidth.M]: {
		min: 992,
		max: 1357,
	},
	[ScreenWidth.L]: {
		min: 1358,
		max: 1635,
	},
	[ScreenWidth.XL]: {
		min: 1636,
	},
};

enum ScreenHeight {
	XS = 'XS',
	S = 'S',
	L = 'L',
	XL = 'XL',
}

const registeredScreenHeights: {
	[screenName in ScreenHeight]: ScreenSizeType;
} = {
	[ScreenHeight.XS]: {
		max: 500,
	},
	[ScreenHeight.S]: {
		min: 501,
		max: 700,
	},
	[ScreenHeight.L]: {
		min: 701,
		max: 800,
	},
	[ScreenHeight.XL]: {
		min: 801,
	},
};

export {
	ScreenWidth,
	registeredScreenWidths,
	ScreenHeight,
	registeredScreenHeights,
};
export type { ScreenSizeType };
