import UrlParse from 'url-parse';
import {
	todoUseDI_authService,
	todoUseDI_currentEndpointStore,
} from '@knuddels-app/Connection';
import { SelectFileResult } from '@knuddels-app/tools/filePicking';

export async function uploadProfilePicture(
	image: SelectFileResult,
	nick: string,
	endpoint: string,
	otp?: string
): Promise<UploadProfilePictureResult> {
	const result = await requestUploadUrl(nick, endpoint, otp);

	if ('error' in result) {
		switch (result.error) {
			case RequestUploadUrlError.BLOCKED:
				return UploadProfilePictureResult.BLOCKED;
			case RequestUploadUrlError.FAKE_SUSPECT:
				return UploadProfilePictureResult.FAKE_SUSPECT;
			default:
				return UploadProfilePictureResult.UNKNOWN_ERROR;
		}
	} else {
		return doUploadProfilePictureRequest(image, result.url);
	}
}

export enum UploadProfilePictureResult {
	SUCCESS = 0,
	BLOCKED = 1,
	FAKE_SUSPECT = 2,
	UNKNOWN_ERROR = 3,
}

type RequestUploadUrlResult =
	| { url: UrlParse }
	| { error: RequestUploadUrlError };

enum RequestUploadUrlError {
	UNKNOWN = 0,
	BLOCKED = 1,
	FAKE_SUSPECT = 2,
}

async function requestUploadUrl(
	nick: string,
	endpoint: string,
	otp?: string
): Promise<RequestUploadUrlResult> {
	const photoServerUrl =
		todoUseDI_currentEndpointStore.currentEndpoint.urls.photoServer;
	const { url, requestConfig } = configureUploadUrlRequest(
		photoServerUrl,
		nick,
		endpoint,
		otp
	);

	let response: Response;
	try {
		const encodedUri = encodeURI(url.href);
		response = await fetch(encodedUri, requestConfig);
	} catch (e) {
		console.error(e);
		return { error: RequestUploadUrlError.UNKNOWN };
	}

	if (!response.ok) {
		return { error: RequestUploadUrlError.UNKNOWN };
	}

	const responseText = await response.text();

	if (!responseText) {
		return { error: RequestUploadUrlError.UNKNOWN };
	}

	if (responseText.startsWith('FALSE|FakeSuspect')) {
		return { error: RequestUploadUrlError.FAKE_SUSPECT };
	}
	if (responseText.startsWith('FALSE|Blocked')) {
		return { error: RequestUploadUrlError.BLOCKED };
	}

	if (
		responseText.startsWith('FALSE') ||
		responseText.startsWith('This document does not exist')
	) {
		return { error: RequestUploadUrlError.UNKNOWN };
	}

	try {
		const uploadUrl = new UrlParse(responseText);
		// Avoid x-origin issues
		const corsSafeHostName = uploadUrl.hostname.replace(
			'upload.knuddels.de',
			'photo.knuddels.de'
		);
		uploadUrl.set('hostname', corsSafeHostName);
		return { url: uploadUrl };
	} catch (e) {
		console.error(e);
		return { error: RequestUploadUrlError.UNKNOWN };
	}
}

function configureUploadUrlRequest(
	photoServerUrl: string,
	nick: string,
	endpoint: string,
	otp?: string
): { url: UrlParse; requestConfig: RequestInit } {
	const url: UrlParse = new UrlParse(endpoint, photoServerUrl, true);
	const query: { [key: string]: string } = {
		n: encodeURIComponent(nick),
	};

	const requestConfig: RequestInit = { method: 'get' };

	// tech debt: Don't do this. Provide different functions for different endpoints
	if (typeof otp === 'string') {
		query.pwd = encodeURIComponent(otp);
	} else {
		requestConfig.headers = {
			Authorization: `bearer ${encodeURIComponent(
				todoUseDI_authService.expectLoggedInState().sessionTokenProvider
					.currentAuthToken
			)}`,
		};
	}

	url.set('query', query);

	return { url, requestConfig };
}

async function doUploadProfilePictureRequest(
	image: SelectFileResult,
	uploadUrl: UrlParse
): Promise<UploadProfilePictureResult> {
	const formData = new FormData();
	formData.append('submitElement', 'nojava');
	if ('blob' in image.uploadObject) {
		formData.append(
			'uploadFile',
			(image.uploadObject as any).blob,
			(image.uploadObject as any).name
		);
	} else {
		formData.append('uploadFile', image.uploadObject as any);
	}

	try {
		const encodedUri = encodeURI(uploadUrl.href);
		const fetchResult: Response = await fetch(encodedUri, {
			method: 'POST',
			body: formData,
		});
		return fetchResult.ok
			? UploadProfilePictureResult.SUCCESS
			: UploadProfilePictureResult.UNKNOWN_ERROR;
	} catch (e) {
		console.error(e);
		return UploadProfilePictureResult.UNKNOWN_ERROR;
	}
}
