import { Keyboard } from '@capacitor/keyboard';
import { FullConversationWithoutMessagesFragment, GetConversationWithoutMessagesDocument, User } from '@generated/graphql';
import { $CommandService } from '@knuddels-app/Commands';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { action, computed, observable, reaction, runInAction } from '@knuddels-app/mobx';
import { $OverlayService } from '@knuddels-app/overlays';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { isNative } from '@knuddels-app/tools/isNative';
import { Disposable } from '@knuddels/std';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { $MessengerConversationService, $MessengerListService, $MessengerNavigationService } from '@knuddelsModules/Messenger/providedServices';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $UserService } from '@knuddelsModules/UserData';
import { userIsJames } from '@shared/helper/user';
import * as React from 'react';
import { MiniChatOverlay } from '../components/MiniChat/MiniChatOverlay/MiniChatOverlay';
import { MessengerOverviewConversationItem, MessengerOverviewItem } from './messengerOverviewHelper';
const MAX_CONVERSATION_COUNT = 10;
@injectable()
export class MessengerMiniChatService {
  public readonly dispose = Disposable.fn();
  @observable
  public activeConversationId: string | undefined = undefined;
  @observable
  public activeConversation: MessengerOverviewItem | undefined = undefined;
  @observable
  public conversations = ([] as MessengerOverviewItem[]);
  @observable
  public unlistedConversation = ({} as FullConversationWithoutMessagesFragment);
  @observable
  public hasUnreadMessages = false;
  @observable
  public isOverlayVisible = false;
  @observable
  private unlistedConversationId: string | undefined = undefined;
  @observable
  private autoFocus: boolean;
  @observable
  private lastOpenedConversationId: string | undefined = undefined;
  constructor(@inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($MessengerListService)
  private readonly messengerListService: typeof $MessengerListService.T, @inject($MessengerNavigationService)
  private readonly messengerNavigationService: typeof $MessengerNavigationService.T, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($FeatureService)
  private readonly featureService: typeof $FeatureService.T, @inject.lazy($ActiveChannelService)
  private readonly getActiveChannelService: typeof $ActiveChannelService.TLazy, @inject($KeyboardService)
  private readonly keyboardService: typeof $KeyboardService.T, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T) {
    this.dispose.track(commandService.registerCommand({
      commandName: 'pp',
      invoke: parameter => this.handlePPCommand(parameter)
    }));
    this.dispose.track(reaction({
      name: 'update MessengerMiniChatService.conversations from MessengerListService.overviewConversationsAndMatches'
    }, () => this.messengerListService.overviewConversations, () => {
      const conversations = this.getRecentConversations();
      const conversationIncludesUnlistedConversation = conversations.map(({
        conversationId
      }) => conversationId).includes(this.unlistedConversationId);
      if (conversationIncludesUnlistedConversation) {
        this.unlistedConversationId = undefined;
        this.unlistedConversation = ({} as FullConversationWithoutMessagesFragment);
      }
      this.conversations = conversations;
      const conversationsIncludeUnread = conversations.some(({
        conversation
      }) => conversation.readState.unreadMessageCount > 0);
      const isActiveConversationInList = this.conversations.some(({
        conversationId
      }) => conversationId === this.activeConversationId);
      const isActiveConversationUnlisted = this.unlistedConversationId === this.activeConversationId;
      if (!isActiveConversationInList && !isActiveConversationUnlisted && this.activeConversationId) {
        this.setActiveConversation(conversations[0]?.conversationId);
      }
      runInAction('update hasUnreadMessages', () => {
        this.hasUnreadMessages = conversationsIncludeUnread;
      });
    }));
    this.dispose.track(reaction({
      name: 'update unlistedConversation'
    }, () => this.unlistedConversationId, async () => {
      if (!this.unlistedConversationId) {
        return;
      }
      const conversation = await this.authenticatedClientService.currentClient.query({
        query: GetConversationWithoutMessagesDocument,
        variables: {
          id: this.unlistedConversationId,
          pixelDensity: getPixelRatio()
        },
        fetchPolicy: 'cache-first'
      });
      if (conversation.data) {
        this.updateUnlistedConversation(conversation.data.messenger.conversation);
      }
    }));
    this.dispose.track(reaction({
      name: 'update activeConversation'
    }, () => this.activeConversationId, () => {
      this.activeConversation = this.conversations.find(({
        conversationId
      }) => conversationId === this.activeConversationId);
    }));
  }
  public get isMiniChatEnabled(): boolean {
    return this.clientSettingsService.privateMessageReplyBehavior === this.clientSettingsService.MiniChatReplyBehavior;
  }
  private getRecentConversations = () => {
    const conversations = (Array.from(this.messengerListService.unarchivedConversationsOverviewItems.values()).filter(conversation => conversation.type === 'conversation' && conversation.conversation.otherParticipants[0].canReceiveMessages) as MessengerOverviewConversationItem[]);
    return conversations.slice(0, MAX_CONVERSATION_COUNT);
  };
  @action
  private handlePPCommand = async (parameter: string) => {
    if (!parameter) {
      return;
    }
    const userId = await this.userService.getUserIdFromNick(parameter);
    if (!userId) {
      return;
    }
    const conversationId = await this.messengerConversationService.getConversationId(userId);
    if (!conversationId) {
      return;
    }
    this.setAutoFocus(true);
    runInAction('open conversation', () => {
      this.openConversation(conversationId);
    });
  };
  @action
  public readonly setAutoFocus = (autoFocus: boolean) => {
    this.autoFocus = autoFocus;
  };
  @computed
  public get shouldAutoFocus(): boolean {
    return this.autoFocus;
  }
  @action
  private readonly updateUnlistedConversation = (conversationData: any) => {
    this.unlistedConversation = conversationData;
  };
  public readonly openConversationByUserId = (userId: User['id']) => {
    if (this.isMiniChatEnabled) {
      this.messengerNavigationService.getConversationByUserId(userId, conversationId => {
        this.openConversation(conversationId);
      });
      return;
    }
    this.messengerNavigationService.openConversationByUserId(userId);
  };
  @action
  private readonly setActiveConversation = (conversationId: string | undefined): void => {
    this.activeConversationId = conversationId;
    if (conversationId) {
      this.lastOpenedConversationId = conversationId;
    }
  };
  @action
  private readonly closeOverlay = (closeOverlay: () => void): void => {
    this.keyboardService.closeAll();
    this.unlistedConversationId = undefined;
    this.unlistedConversation = ({} as FullConversationWithoutMessagesFragment);
    this.setActiveConversation(undefined);
    closeOverlay();
  };
  @action
  private readonly showOverlay = async () => {
    if (this.isOverlayVisible) {
      return;
    }
    this.isOverlayVisible = true;
    const overlay = this.overlayService.showOverlay({
      view: <MiniChatOverlay onClose={() => this.closeOverlay(overlay.dispose)} />,
      keepOnLocationChange: true
    });
    await overlay.onClosed;
    runInAction('close overlay', () => {
      this.isOverlayVisible = false;
    });
  };
  private isConversationInList = (conversationId: string) => {
    return this.conversations.some(({
      conversationId: id
    }) => id === conversationId);
  };
  @action
  public readonly openConversation = async (conversationId?: string) => {
    if (!this.isMiniChatEnabled) {
      this.messengerNavigationService.openConversation(conversationId, true);
      return;
    }
    if (!conversationId) {
      const conversationToOpen = this.lastOpenedConversationId ? this.isConversationInList(this.lastOpenedConversationId) ? this.lastOpenedConversationId : this.conversations[0].conversationId : this.conversations[0].conversationId;
      this.setActiveConversation(conversationToOpen);
    } else if (this.isConversationInList(conversationId)) {
      this.setActiveConversation(conversationId);
    } else {
      this.unlistedConversationId = conversationId;
      this.setActiveConversation(conversationId);
    }

    // TODO: Remove when chat is completely migrated to new keyboard handling
    if (isNative()) {
      await Keyboard.hide();
    }
    this.showOverlay();
  };
  public readonly handleChatHeadClick = (conversationId: string | undefined) => {
    this.setActiveConversation(conversationId);
  };
  public readonly canConversationBeOpenedInMiniChat = async (userId: string, userNick: string): Promise<boolean> => {
    const activeChannelService = await this.getActiveChannelService();
    const userIsAppBot = await activeChannelService.isAppBot(userId);
    const userIsBotAndNoActiveChannel = activeChannelService.state.kind === 'noChannel' && (userIsAppBot || userIsJames({
      nick: userNick
    }));
    return this.isMiniChatEnabled && !userIsBotAndNoActiveChannel;
  };
}