import { Flex, ThemeOverride, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { motion } from 'framer-motion';
import * as React from 'react';
import { ActivityIndicator, Column, Spacer } from '../../atoms';
import { MessageBubble } from './MessageBubble';
export interface MessageProps extends React.PropsWithChildren<unknown> {
  align: 'left' | 'right';
  bubbleColor?: string;
  isStackedLayout?: boolean;
  shadow?: boolean;
  additionalMargin?: number;
  showImage?: boolean;
  hasArrow?: boolean;
  showLoadingIndicator?: boolean;
  header?: JSX.Element;
  noBubblePadding?: boolean;
}
export function createAbstractMessage<TProps>(renderImage: (props: MessageProps & TProps) => JSX.Element): React.FC<MessageProps & TProps> {
  return function AbstractMessage(props: MessageProps & TProps): JSX.Element {
    const {
      align,
      isStackedLayout,
      showImage
    } = props;
    const alignLeft = align === 'left';
    const shouldRenderUserImage = showImage && alignLeft;
    const paddingRightAligned = 16;
    const bigScreenPadding = !isStackedLayout ? 8 : 0;
    const paddingLeft = (alignLeft ? 0 : paddingRightAligned) + bigScreenPadding;
    const paddingRight = bigScreenPadding;
    const message = props.bubbleColor ? <MessageBubble align={align} color={props.bubbleColor} hasArrow={props.hasArrow} shadow={props.shadow} additionalMargin={props.additionalMargin} noPadding={props.noBubblePadding}>
				{props.children}
			</MessageBubble> : props.children;
    const header = props.header ? <div className={_c0 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (alignLeft ? _c1 : _c2)}>
				{props.header}
				<Spacer size={'small'} />
			</div> : null;
    return <div style={{
      paddingLeft: resolveThemingValue((paddingLeft as ThemeOverride), "spacing", useTheme()),
      paddingRight: resolveThemingValue((paddingRight as ThemeOverride), "spacing", useTheme())
    }} className={_c3}>
				{<Spacer size={'small'} />}
				{shouldRenderUserImage ? renderImage(props) : <>
						<Spacer size={'xlarge'} />
						<Spacer size={'small'} />
					</>}
				{<Spacer size={'tiny'} />}
				<Column fluid>
					{header && <>
							<Spacer size={'small'} />
							{header}
							<Spacer size={'small'} />
						</>}
					{message}
				</Column>
				{!!props.showLoadingIndicator && <LoadingIndicator />}
			</div>;
  };
}
function LoadingIndicator(): React.ReactElement {
  const theme = useTheme();
  return <div className={_c4}>
			<motion.div initial={{
      width: 0
    }} animate={{
      width: 20
    }} transition={{
      duration: 0.833,
      ease: [0, 0, 0.58, 1]
    }} style={{
      height: 20,
      overflow: 'hidden'
    }}>
				<ActivityIndicator color={theme.colors.basic.accent} size={'small'} />
			</motion.div>
		</div>;
}
const _c0 = " Knu-Flex justifyContent-flex-end bg-transparent ";
const _c1 = " flexDirection-row-reverse ";
const _c2 = " flexDirection-row ";
const _c3 = " Knu-Flex overflow-visible position-relative ";
const _c4 = " Knu-Flex alignSelf-center ";