import { OverflowMenuTrigger } from '@shared/components';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { QuickWhoisOverflowMenuOverlay } from './QuickWhoisOverflowMenu';
import { UserForQuickWhoisFragment } from '@generated/graphql';
export const QuickWhoisOverflowMenuTrigger: React.FC<{
  user: UserForQuickWhoisFragment;
}> = ({
  user
}) => {
  const overlayService = useService($OverlayService);
  const showOverflowMenu = (x: number, y: number, width: number, height: number) => {
    overlayService.showOverlayIfNotVisible(QuickWhoisOverflowMenuOverlay, {
      user,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  return <OverflowMenuTrigger showOverflowMenu={showOverflowMenu} />;
};