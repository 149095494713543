import { useService } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { useEffect, useRef } from 'react';
import { ProfileAlbumInfo } from '../profileQueryHelper';
export const useTrackAlbumImagesAvailable = (albumInfo: ProfileAlbumInfo) => {
  const firebaseAnalyticsService = useService($FirebaseAnalyticsService);
  const alreadyTracked = useRef(false);
  useEffect(() => {
    const photos = albumInfo?.albumPhotos;
    if (!photos || alreadyTracked.current) {
      return;
    }
    if (photos.length > 0) {
      firebaseAnalyticsService.logEvent('Profile_Profileview', 'Images_AlbumImagesAvailable');
    }
    alreadyTracked.current = true;
  }, [albumInfo?.albumPhotos]);
};