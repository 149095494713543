import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { observer, when } from '@knuddels-app/mobx';
import { isNative } from '@knuddels-app/tools/isNative';
import { Box, FlexCol, Image, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { BlurredBackgroundImage, Spacer } from '@shared/components';
import { usePrivacyScreen } from '@shared/components/contexts/PrivacyScreenContext';
import * as React from 'react';
import icon from '../../../../assets/icon-snap-active.png';
import { ClientSnapState } from '../../../../services/conversationServices/SnapService';
import { MessageLightboxSource } from '../../Lightbox/Lightbox';
import { HoldToViewWrapper } from './HoldToViewWrapper';
import { SnapSize } from './SnapSizes';
import { defaultBlurredImage } from './defaultBlurredImage';
export const HoldToViewSnap: React.FC<{
  snap: ClientSnapState;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
}> = observer('HoldToViewSnap', ({
  snap,
  setLightboxSrc
}) => {
  const PrivacyScreen = usePrivacyScreen('HoldToViewSnap');
  const openSnap = React.useCallback(async () => {
    PrivacyScreen.enable();
    if (setLightboxSrc) {
      setLightboxSrc({
        type: 'snap',
        snap: snap
      });
      snap.maybeDownloadSnap();
      snap.startDecrementTimer();
    }
  }, [PrivacyScreen, setLightboxSrc, snap]);
  const closeSnap = React.useCallback(async () => {
    PrivacyScreen.disable();
    snap.stopDecrementTimer();
    if (setLightboxSrc) {
      setLightboxSrc(undefined);
    }
  }, [PrivacyScreen, setLightboxSrc, snap]);
  React.useEffect(() => {
    when({
      name: 'Close snap when snap is not available'
    }, () => snap.isAvailable, {
      resolveTest: () => closeSnap()
    });
  }, [snap, closeSnap]);
  return <HoldToViewWrapper onStart={openSnap} onEnd={closeSnap}>
			<BlurredBackgroundImage source={defaultBlurredImage} blurRadius={isNative() ? 100 : 40} />
			<div style={{
      height: resolveThemingValue(SnapSize.MESSAGE, "sizes", useTheme()),
      maxWidth: resolveThemingValue(SnapSize.MESSAGE, "sizes", useTheme())
    }} className={_c0 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div style={{
        width: resolveThemingValue(SnapSize.MESSAGE, "sizes", useTheme())
      }} className={_c1} />
				<Image src={icon} alt={''} resizeMode={'contain'} className={_c2} />
				<Spacer size={'small'} />
				<Text type={'body1'} bold={true} className={_c3}>
					{formatMessage(declareFormat({
          id: 'SNAP_TIME',
          defaultFormat: '{seconds} {seconds, plural, one {second} other {seconds}}'
        }), {
          seconds: (snap.remainingTime || 0).toString()
        })}
				</Text>
				<Text type={'body2'} state={'tertiary'} className={_c4}>
					{formatMessage(declareFormat({
          id: 'SNAP_HOLD_TO_VIEW',
          defaultFormat: 'Click and hold\n to view image.'
        }))}
				</Text>
			</div>
		</HoldToViewWrapper>;
});
const _c0 = " Knu-FlexCol bg-transparentDark position-relative p-30px justifyContent-flex-end alignItems-center ";
const _c1 = " Knu-Box ";
const _c2 = " width-48px height-48px ";
const _c3 = " textAlign-center ";
const _c4 = " textAlign-center mt-55px ";