import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { TransitionView } from './TransitionView';
import { isTouchDevice } from '@knuddels/component-library';
export const OverlayView = observer('OverlayView', ({
  hide,
  overlay,
  isTopOverlay,
  onHidden,
  onSwipeDown,
  zIndex,
  isSystemApp
}: {
  hide: boolean;
  overlay: React.ReactElement;
  isTopOverlay: boolean;
  zIndex: number;
  isSystemApp: boolean;
  onHidden(): void;
  onSwipeDown(): void;
}) => {
  const isStackedLayout = useIsStackedLayout();
  const keyboardService = useService($KeyboardService);
  const currentInputRef = React.useRef<typeof $KeyboardService.T['focusable'] | null>(null);
  React.useEffect(() => {
    // We want to hide keyboard when the overlay opens and closes
    // to prevent weird keyboard when there is no input left.
    keyboardService.dismissKeyboard();
    setTimeout(() => {
      keyboardService.dismissKeyboard();
    });
  }, [hide]);
  React.useEffect(() => {
    currentInputRef.current = keyboardService.focusable;
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onSwipeDown();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
      if (!isTouchDevice() && currentInputRef.current) {
        currentInputRef.current.focus();
      }
    };
  }, []);
  return <TransitionView zIndex={zIndex} bg={isStackedLayout || !isTopOverlay ? 'transparent' : 'backgroundScrim'} transition={isStackedLayout ? 'slideUp' : 'fade'} hide={hide} leave={{
    onFinished: onHidden
  }} onSwipeDown={onSwipeDown} isSystemApp={isSystemApp} allowSwipe={isStackedLayout}>
				{overlay}
			</TransitionView>;
});