import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { LastLoginStorage } from './LastLoginStorage';
import { $AuthService } from '@knuddels-app/Connection/serviceIds';
import { $LocalStorage } from '@knuddels-app/local-storage';

@injectable()
export class LastLoginStorageImpl extends LastLoginStorage {
	constructor(
		@inject($AuthService) authService: typeof $AuthService.T,
		@inject($LocalStorage) localStorage: typeof $LocalStorage.T
	) {
		super(authService, localStorage);
	}
}
