import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useMutation } from '@knuddels-app/Connection';
import { DeleteAlbumPhotoComment } from '@generated/graphql';

export const useRemoveComment = (removeCommentFromCache: () => void) => {
	const snackbarService = useService($SnackbarService);
	const [deleteComment] = useMutation(DeleteAlbumPhotoComment);

	return async (photoId: string, commentId: string) => {
		const result = await deleteComment({
			albumPhotoId: photoId,
			albumPhotoCommentId: commentId,
		});

		if (result.data?.__typename === 'DeleteAlbumPhotoCommentSuccess') {
			removeCommentFromCache();
			return true;
		} else {
			snackbarService.showGenericError();
			return false;
		}
	};
};
