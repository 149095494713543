import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Flex, FlexCol, Image, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { Gender, User } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { Button, Spacer } from '@shared/components';
import { FriendRecommendationData } from '../../../../services/conversationServices/FriendRecommendationsState';
import { PrivateMessageFooter } from './PrivateMessageFooter';
import { useTrackConversation } from '../../useTrackConversation';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
export interface FriendRecommendationProps {
  isNewestMessage: boolean;
  user: Pick<User, 'id' | 'nick' | 'gender'>;
  recommendationData: FriendRecommendationData;
}
export const FriendRecommendation = (props: FriendRecommendationProps) => {
  const track = useTrackConversation();
  const wasTracked = useRef(false);
  useEffect(() => {
    if (!wasTracked.current && !props.recommendationData.wasSent && props.isNewestMessage) {
      wasTracked.current = true;
      track('SystemMessages_FriendshipRequestProposed');
    }
  }, [props.isNewestMessage, props.recommendationData.wasSent]);
  if (props.recommendationData.wasSent) {
    return <FriendRequestSentMessage user={props.user} />;
  }
  return <div className={_c0}>
			<div className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c2}>
					<Image src={require('@shared/images/clique.gif')} alt={''} className={_c3} />
				</div>
				<Text type={'body1'} state={'secondary'} className={_c4}>
					<Text bold={true} type={'body1'} state={'secondary'} className={_c5}>
						<FormattedMessage id={declareFormat({
            id: 'conversation.friend-recommendation.text-bold',
            defaultFormat: 'You and {nick} seem to get along well. '
          })} values={{
            nick: props.user.nick
          }} />
					</Text>
					<FormattedMessage id={declareFormat({
          id: 'conversation.friend-recommendation.text',
          defaultFormat: 'Would you like to send {gender, select, male {him} female {her} other {him}} a friend request?'
        })} values={{
          gender: props.user.gender === Gender.Male ? 'male' : 'female'
        }} />
				</Text>
				<FriendRecommendationButtons {...props} />
			</div>
			<PrivateMessageFooter />
		</div>;
};
const FriendRecommendationButtons: React.FC<FriendRecommendationProps> = props => {
  const track = useTrackConversation();
  const i18n = useService($I18n);
  const friendRequestsService = useService($FriendRequestsService);
  return <div className={_c6}>
			<Button kind={'outline'} text={i18n.format(declareFormat({
      id: 'conversation.friend-recommendation.secondary-button',
      defaultFormat: 'Later'
    }))} onClick={() => {
      track('SystemMessages_FriendshipRecommendationRemovedWithoutSending');
      props.recommendationData.removeRecommendation();
    }} />
			<Spacer size={'minor'} />
			<Button fluid text={i18n.format(declareFormat({
      id: 'conversation.friend-recommendation.primary-button',
      defaultFormat: 'Yes, send!'
    }))} onClick={() => {
      track('SystemMessages_FriendshipRequestSend');
      friendRequestsService.sendFriendRequest(props.user.id).then(success => {
        if (success) {
          props.recommendationData.markSent();
        }
      });
    }} />
		</div>;
};
const FriendRequestSentMessage: React.FC<{
  user: FriendRecommendationProps['user'];
}> = props => {
  return <div className={_c7}>
			<div className={_c8}>
				<Image src={require('@shared/images/clique.gif')} alt={''} className={_c9} />
			</div>
			<Text type={'body1'} state={'secondary'} className={_c10}>
				<Text bold={true} type={'body1'} state={'secondary'} className={_c11}>
					{props.user.nick + ' '}
				</Text>
				<FormattedMessage id={declareFormat({
        id: 'conversation.friend-request-sent.text',
        defaultFormat: ' has been sent a friend request.'
      })} />
			</Text>
			<PrivateMessageFooter />
		</div>;
};
const _c0 = " Knu-FlexCol alignItems-center my-large ";
const _c1 = " Knu-FlexCol position-relative overflow-visible shadow-Shadow2 bg-contentLightBg borderRadius-large mx-xlarge maxWidth-288px p-base mb-minor ";
const _c2 = " Knu-FlexCol alignItems-center mb-tiny ";
const _c3 = " height-26px width-57px ";
const _c4 = " textAlign-center mb-base ";
const _c5 = "  ";
const _c6 = " Knu-Flex ";
const _c7 = " Knu-FlexCol alignItems-center my-large ";
const _c8 = " Knu-FlexCol alignItems-center mb-tiny ";
const _c9 = " height-26px width-57px ";
const _c10 = " textAlign-center mb-minor ";
const _c11 = "  ";