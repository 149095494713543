import * as React from 'react';
import { ReactNode } from 'react';
import { Flex, Smiley, Text } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export const MentorSystemHint: React.FC<{
  hasNoMessages: boolean;
  menteeNick: string;
}> = ({
  hasNoMessages,
  menteeNick
}) => {
  const message = useMentorSystemHint(hasNoMessages, menteeNick);
  return <div className={_c0}>
			<Smiley src={require('@shared/images/sm_abo_15-05_schulabschluss.gif')} height={18} width={20} mr={'tiny'} />
			<Text state={'tertiary'} italic={true} className={_c1}>
				{message}
			</Text>
		</div>;
};
const useMentorSystemHint = (hasNoMessages: boolean, menteeNick: string): ReactNode => {
  const hintMessages: ReactNode[] = [];
  if (hasNoMessages) {
    return <FormattedMessage id={declareFormat({
      id: 'conversation.message.mentorsystem-hint.first-message',
      defaultFormat: 'Write a message to get your first mentor point.'
    })} />;
  }

  // If didn't receive max (35) points for messages yet
  hintMessages.push(<FormattedMessage id={declareFormat({
    id: 'conversation.message.mentorsystem-hint.more-messages',
    defaultFormat: 'Write some messages with {nick} to get more points.'
  })} values={{
    nick: <Text bold={true} className={_c2}>{menteeNick}</Text>
  }} />);

  // TODO: implement:
  // If no friends yet
  // If don't have profile picture yet
  // If profile empty
  // If profile not full yet
  // If not family member yet

  // return random hint
  return hintMessages[Math.floor(Math.random() * hintMessages.length)];
};
const _c0 = " Knu-Flex px-xlarge py-small justifyContent-center ";
const _c1 = " textAlign-center ";
const _c2 = "  ";