import * as React from 'react';
import { injectedComponent } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { $ContactsService } from '@knuddelsModules/Contacts';
import { $MessengerNavigationService } from '@knuddelsModules/Messenger/providedServices';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { IconSettings } from '@knuddels/component-library';
import { useConfigureNavBar, useNavigate } from '../MessengerRouter';
export const NewConversation = injectedComponent({
  name: 'NewConversation',
  inject: {
    i18n: $I18n,
    contactsService: $ContactsService,
    firebaseAnalyticsService: $FirebaseAnalyticsService,
    messengerNavigationService: $MessengerNavigationService
  }
}, ({
  contactsService,
  messengerNavigationService,
  firebaseAnalyticsService
}) => {
  const navigate = useNavigate();
  useConfigureNavBar({
    iconList: [<IconSettings key={'overflowMenu'} size={'large'} onPress={() => {
      firebaseAnalyticsService.logEvent('Contacts_Titlebar', 'Settings_Clicked');
      navigate('newConversationSettings');
    }} />],
    hideShadow: true
  });
  return <contactsService.ContactsView onContactClicked={userId => {
    messengerNavigationService.getConversationByUserId(userId, conversationId => {
      navigate('conversation', {
        params: {
          conversationId
        }
      });
    });
  }} />;
});