/**
 * Returns an array with only the selected range being real data.
 * This helps with the carousel since it doesn't have to render all carousel data at once.
 *
 * Note:
 *  This returns a mostly empty array and those empty slots only count towards `array.length`
 *  while being ignored by methods like `array.map`.
 *  I will still keep the `undefined` in the types because it is returned when directly accessing
 *  the members (a[2], e.g. via for loop).
 */
export function clearElementsOutsideOfRange<T>(
	array: readonly T[],
	selectedIndex: number,
	plusMinusRange = 2
): (T | undefined)[] {
	const optimizedArray: (T | undefined)[] = Array(array.length);
	for (let i = -plusMinusRange; i <= plusMinusRange; i++) {
		// Need to add optimizedArray.length to precache circularly (e.g. urls[-1] => urls[length - 1])
		const index =
			(selectedIndex + i + optimizedArray.length) % optimizedArray.length;
		optimizedArray[index] = array[index];
	}
	return optimizedArray;
}
