import { useService } from '@knuddels-app/DependencyInjection';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { IconMessage } from '@knuddels-app/shared-components/IconMessage';
import { IconChevronRight, IconShow, ListGroup, createSvgIcon } from '@knuddels/component-library';
import { IconChannel } from '@shared/icons/IconChannel';
import { IconChannels } from '@shared/icons/IconChannels';
import { IconContacts } from '@shared/icons/IconContacts';
import { IconFotomeet } from '@shared/icons/IconFotomeet';
import { IconMentorSystem } from '@shared/icons/IconMentorSystem';
import * as React from 'react';
import { SettingsNavigateFunction } from '../../../SettingsRouterConfig';
import { useMentorSystemStatusActive } from '../MentorSystemSettings';
import { SettingsListItem } from '../SettingsListItem';
import { mentorSystemSettingsFormat, messengerSettingsFormat, privacySettingsFormat } from '../formats';
const IconSafe = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M 4 4 C 2.9069372 4 2 4.9069372 2 6 L 2 18 C 2 19.093063 2.9069372 20 4 20 L 4 21 L 6 21 L 6 20 L 18 20 L 18 21 L 20 21 L 20 20 C 21.093063 20 22 19.093063 22 18 L 22 6 C 22 4.9069372 21.093063 4 20 4 L 4 4 z M 4 6 L 20 6 L 20 18 L 4 18 L 4 6 z M 11.457031 8.0429688 L 10.042969 9.4570312 L 11.302734 10.716797 C 11.114225 11.108061 11 11.540143 11 12 C 11 12.459857 11.114225 12.891939 11.302734 13.283203 L 10.042969 14.542969 L 11.457031 15.957031 L 12.716797 14.697266 C 13.108061 14.885775 13.540143 15 14 15 C 14.459857 15 14.891939 14.885775 15.283203 14.697266 L 16.542969 15.957031 L 17.957031 14.542969 L 16.697266 13.283203 C 16.885775 12.891939 17 12.459857 17 12 C 17 11.540143 16.885775 11.108061 16.697266 10.716797 L 17.957031 9.4570312 L 16.542969 8.0429688 L 15.283203 9.3027344 C 14.891939 9.1142246 14.459857 9 14 9 C 13.540143 9 13.108061 9.1142246 12.716797 9.3027344 L 11.457031 8.0429688 z M 6 9 L 6 15 L 8 15 L 8 9 L 6 9 z M 14 11 C 14.564129 11 15 11.435871 15 12 C 15 12.564129 14.564129 13 14 13 C 13.435871 13 13 12.564129 13 12 C 13 11.435871 13.435871 11 14 11 z" fill="currentColor" />
	</svg>);
export const SectionsSettings: React.FC<{
  shouldShowChannelSetting: boolean;
  navigate: SettingsNavigateFunction;
}> = observer('SectionsSettings', ({
  shouldShowChannelSetting,
  navigate
}) => {
  const activeStatus = useMentorSystemStatusActive();
  const i18n = useService($I18n);
  const track = useLogEvent();
  const openContactListSettings = () => {
    navigate('ContactListSettings');
    track('Settings_Overview', 'Contactsettings_Clicked');
  };
  const openChannelListSettings = () => {
    navigate('ChannelListSettings');
    track('Settings_Overview', 'ChannelListSettings_Clicked');
  };
  const openChannelSettings = () => {
    navigate('ChannelSettings');
    track('Settings_Overview', 'Channelsettings_Clicked');
  };
  const openMentorSystemSettings = () => {
    navigate('MentorSystemSettings');
    track('Settings_Overview', 'MentorSystemSettings_Clicked');
  };
  const openMessengerSettings = () => {
    navigate('MessengerSettings');
    track('Settings_Overview', 'Messenger_Clicked');
  };
  const openKnuddelAccessSettings = () => {
    navigate('KnuddelAccessSettings');
    track('Settings_Overview', 'KnuddelAccess_Clicked');
  };
  const openFotomeetSettings = () => {
    navigate('FotomeetSettings');
    track('Settings_Overview', 'Fotomeet_Clicked');
  };
  const openPrivacySettings = () => {
    navigate('PrivacySettings');
    track('Settings_Overview', 'Privacy_Clicked');
  };
  return <ListGroup title={declareFormat({
    id: 'settings.group.sections',
    defaultFormat: 'Sections'
  }).format(i18n)}>
			{shouldShowChannelSetting && <SettingsListItem iconLeft={<IconChannels />} iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.channelList.title',
      defaultFormat: 'Channel list'
    }).format(i18n)} onPress={openChannelListSettings} />}
			{shouldShowChannelSetting && <SettingsListItem iconLeft={<IconChannel />} iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.channel.title',
      defaultFormat: 'Channel'
    }).format(i18n)} onPress={openChannelSettings} />}
			<SettingsListItem iconLeft={<IconMessage />} iconRight={<IconChevronRight />} caption={messengerSettingsFormat.format(i18n)} onPress={openMessengerSettings} />
			{!(globalEnv.product === 'stapp-messenger') && <SettingsListItem iconLeft={<IconFotomeet />} iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.fotomeet.title',
      defaultFormat: 'Fotomeet'
    }).format(i18n)} onPress={openFotomeetSettings} />}
			<SettingsListItem iconLeft={<IconContacts />} iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.contactList.title',
      defaultFormat: 'Contact list'
    }).format(i18n)} onPress={openContactListSettings} />
			<SettingsListItem iconLeft={<IconShow />} iconRight={<IconChevronRight />} caption={privacySettingsFormat.format(i18n)} onPress={openPrivacySettings} />
			{activeStatus && <SettingsListItem iconLeft={<IconMentorSystem />} iconRight={<IconChevronRight />} caption={mentorSystemSettingsFormat.format(i18n)} onPress={openMentorSystemSettings} />}

			{globalEnv.product === 'app' && <SettingsListItem iconLeft={<IconSafe />} iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.knuddelAccess.title',
      defaultFormat: 'Knuddelaccess'
    }).format(i18n)} onPress={openKnuddelAccessSettings} separator={'none'} />}
		</ListGroup>;
});