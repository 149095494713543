import * as React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getRoot, $getSelection, $isTextNode, ParagraphNode } from 'lexical';
import { TypeaheadMenu } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/TypeaheadMenu';
import { Flex, FlexCol, isDarkColor, isTouchDevice, Text, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { COMMANDS, getPossibleAutocompleteCommands, SlashTypeaheadOption } from './SlashCommandsAutocompleteData';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { getNickTypeAheadOptions, NickTypeAheadOption, NickTypeaheadOptionRenderer } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/NickPlugin';
import { CssAnimatePresence } from '@shared/components/RichText/CSSAnimatePrecense';
import { useOptionSelection } from '@shared/components/RichText/plugins/useOptionSelection';
import { ChannelTypeAheadOption, ChannelTypeAheadOptionRenderer, DynamicChannelTypeAheadOption, getChannelsFromQuery } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/ChannelPlugin';
import { $createAutocompleteNode } from '@shared/components/RichText/nodes/AutocompleteNode';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
const FREE_PARAMS_MARKER = ':';
const CONTROL_CHARS = ['+'];
const getCommand = (text: string) => {
  const potentialCommand = text.substring(1).split(' ')[0].toLowerCase().trim();
  return {
    command: COMMANDS[(potentialCommand as keyof typeof COMMANDS)],
    trigger: potentialCommand
  };
};
const getSearcher = (command: (typeof COMMANDS)[0]): typeof getNickTypeAheadOptions | typeof getChannelsFromQuery | null => {
  switch (command?.autocompleteType) {
    case 'channel':
      return getChannelsFromQuery;
    case 'user':
      return getNickTypeAheadOptions;
    default:
      return null;
  }
};
export const SlashPlugin = React.memo((props: {
  anchor: React.MutableRefObject<HTMLElement>;
}) => {
  const userService = useService($UserService);
  const activeNodeRef = React.useRef<HTMLElement | null>(null);
  const previewNodeRef = React.useRef<HTMLElement | null>(null);
  const theme = useTheme();
  const autocompleteService = useService($AutocompleteProviderService);
  const firebaseAnalytics = useService($FirebaseAnalyticsService);
  const {
    options,
    highlightedIndex,
    setHighlightedIndex,
    selectedIndex,
    setOptions,
    unsetOptions
  } = useOptionSelection<SlashTypeaheadOption | NickTypeAheadOption | ChannelTypeAheadOption | DynamicChannelTypeAheadOption>({
    onOptionSelected: (option, key) => {
      handleOptionClick(option, key);
    },
    retriggerEnter: selectedOption => {
      return (selectedOption instanceof SlashTypeaheadOption || !lastSlashCommand.current.command?.hasFreeParams) && (!lastSlashCommand.current.command?.parameterRequired || !(selectedOption instanceof SlashTypeaheadOption));
    }
  });
  const updatePreview = (forOptions: (NickTypeAheadOption | ChannelTypeAheadOption | SlashTypeaheadOption | DynamicChannelTypeAheadOption)[], index: number) => {
    if (!activeNodeRef.current) {
      return;
    }
    if (!previewNodeRef.current) {
      const span = document.createElement('span');
      span.className = 'slash-preview';
      span.style.opacity = '1';
      span.style.position = 'absolute';
      span.style.pointerEvents = 'none';
      span.style.zIndex = '999999';
      span.style.display = 'inline-block';
      span.style.fontFamily = theme.fontFamily.base;
      span.style.color = isDarkColor(theme.colors.basic.contentBg) ? theme.colors.texts.alternate.tertiary : theme.colors.texts.default.tertiary;
      previewNodeRef.current = span;
      document.body.appendChild(span);
    }
    previewNodeRef.current!.textContent = forOptions[index].toString().substring(query.current?.withoutControlKeys.length ?? 0);
    const rect = activeNodeRef.current!.getBoundingClientRect();
    previewNodeRef.current.style.top = rect.top + 'px';
    previewNodeRef.current.style.bottom = rect.bottom + 'px';
    previewNodeRef.current.style.left = rect.left + rect.width + 'px';
    previewNodeRef.current.style.height = rect.height + 'px';
  };
  React.useLayoutEffect(() => {
    if (options?.length) {
      updatePreview(options, Math.max(0, selectedIndex));
      return;
    }
    previewNodeRef.current?.remove();
  }, [options, selectedIndex]);
  const query = React.useRef<{
    raw: string;
    withoutControlKeys: string;
  } | null>(null);
  const autocompleteCommands = React.useMemo(() => {
    return getPossibleAutocompleteCommands(userService.currentUser.status);
  }, [userService.currentUser.status]);
  const lastSlashCommand = React.useRef<{
    trigger: string;
    command: (typeof COMMANDS)[0];
  } | null>(null);
  const [editor] = useLexicalComposerContext();
  const findControlChar = (text: string) => {
    return CONTROL_CHARS.find(char => text.startsWith(char));
  };
  const cleanUp = () => {
    unsetOptions();
    previewNodeRef.current?.remove();
    previewNodeRef.current = null;
  };
  const handlePlaceholderOptionClick = (option: NickTypeAheadOption | ChannelTypeAheadOption | DynamicChannelTypeAheadOption) => {
    editor.update(() => {
      const selection = $getSelection();
      const selectedNode = selection?.getNodes()?.[0];
      if (!selectedNode) {
        return;
      }
      const controlChar = findControlChar(query.current.raw ?? '');
      const slashCommandNode = $createTextNode(`${lastSlashCommand.current.trigger} ${controlChar ?? ''}`);
      selectedNode.replace(slashCommandNode);
      const placeholderNode = $createAutocompleteNode({
        text: option.toString(),
        className: option instanceof NickTypeAheadOption ? 'user-mention' : 'channel-mention'
      });
      slashCommandNode.insertAfter(placeholderNode);
      if (lastSlashCommand.current.command.hasFreeParams) {
        const paramNode = $createTextNode(FREE_PARAMS_MARKER);
        placeholderNode.insertAfter(paramNode);
        paramNode.selectEnd();
      } else {
        placeholderNode.selectEnd();
      }
    });
    cleanUp();
  };
  const handleOptionClick = (option: SlashTypeaheadOption | NickTypeAheadOption | ChannelTypeAheadOption | DynamicChannelTypeAheadOption, key: 'Tab' | 'Enter' | 'Click' = 'Click') => {
    if (!(option instanceof SlashTypeaheadOption)) {
      handlePlaceholderOptionClick(option);
      return;
    }
    firebaseAnalytics.logEvent('Autocomplete_SlashAutocomplete', 'Command_Selected');
    editor.update(() => {
      const root = ($getRoot().getChildren()[0]! as ParagraphNode);
      const suffix = key === 'Enter' && !lastSlashCommand.current.command?.parameterRequired ? '' : ' ';
      const replaceWith = $createTextNode('/' + option.command + suffix);
      root.getChildren()[0]!.replace(replaceWith);
      replaceWith.selectEnd();
    });
    const command = getCommand('/' + option.command);
    lastSlashCommand.current = {
      command: command.command,
      trigger: '/' + command.trigger
    };
    cleanUp();
  };
  const updateQuery = (text: string) => {
    query.current = {
      raw: text,
      withoutControlKeys: CONTROL_CHARS.reduce((acc, char) => {
        if (text.startsWith(char)) {
          return acc.substring(1);
        }
        return acc;
      }, text)
    };
  };
  React.useEffect(() => {
    const listener = [editor.registerUpdateListener(({
      editorState
    }) => {
      editorState.read(() => {
        const selection = $getSelection();
        const selectedNode = selection?.getNodes()?.[0];
        if (!selectedNode) {
          cleanUp();
          return;
        }
        activeNodeRef.current = editor.getElementByKey(selectedNode.getKey());
        if (!$isTextNode(selectedNode)) {
          lastSlashCommand.current = null;
          cleanUp();
          return;
        }
        const text = selectedNode.getTextContent();
        if (text === lastSlashCommand.current?.trigger) {
          return;
        }
        if (!text.startsWith('/')) {
          lastSlashCommand.current = null;
          cleanUp();
          return;
        }
        if (!text.endsWith(' ') && !text.includes(' ')) {
          lastSlashCommand.current = null;
        }
        if (!text.includes(' ') && lastSlashCommand.current?.trigger !== text) {
          cleanUp();
          updateQuery(text.substring(1).split(' ')[0]);
          let hasExactMatch = false;
          const commands = autocompleteCommands.filter(command => {
            if (command === query.current?.withoutControlKeys) {
              hasExactMatch = true;
              return true;
            }
            return command.startsWith(query.current.withoutControlKeys ?? '');
          }).map(command => {
            return new SlashTypeaheadOption(command, COMMANDS[command]);
          });
          if (hasExactMatch || !query.current.withoutControlKeys.length) {
            setOptions(commands);
          } else {
            const c = new SlashTypeaheadOption(query.current.raw, {
              hasFreeParams: false,
              autocompleteType: 'none',
              description: 'Aktuelle Eingabe'
            });
            setOptions([c, ...commands]);
          }
          return;
        }
        if (!lastSlashCommand.current?.command && text.endsWith(' ')) {
          const command = getCommand(selectedNode.getTextContent());
          if (!command) {
            unsetOptions();
            return;
          }
          lastSlashCommand.current = {
            command: command.command,
            trigger: '/' + command.trigger
          };
        }
        updateQuery(text.toLowerCase().replace(lastSlashCommand.current?.trigger, '').trimStart());
        const searcher = getSearcher(lastSlashCommand.current?.command);
        if (!searcher) {
          cleanUp();
          return;
        }
        (async () => {
          if (query.current.withoutControlKeys.includes(FREE_PARAMS_MARKER)) {
            unsetOptions();
            return;
          }
          const newOptions = await searcher(autocompleteService, query.current.withoutControlKeys);
          if (newOptions[0]?.toString().toLowerCase() === query.current.withoutControlKeys.toLowerCase()) {
            setOptions(newOptions);
            return;
          }
          if (lastSlashCommand.current.command && query.current.withoutControlKeys.length) {
            setOptions([lastSlashCommand.current.command.autocompleteType === 'channel' ? new DynamicChannelTypeAheadOption(query.current.withoutControlKeys) : new NickTypeAheadOption({
              id: '-1',
              nick: query.current.withoutControlKeys
            }), ...newOptions]);
          } else {
            setOptions(newOptions);
          }
        })();
      });
    })];
    return () => {
      listener.forEach(l => l());
      previewNodeRef.current?.remove();
    };
  }, [editor]);
  return <>
				<CssAnimatePresence>
					{options?.length > 0 && <TypeaheadMenu anchor={props.anchor} options={options} onClose={() => {
        cleanUp();
      }} itemProps={{
        selectedIndex,
        highlightedIndex,
        setHighlightedIndex,
        selectOptionAndCleanUp: handleOptionClick
      }} renderItem={(option, i) => {
        if (option instanceof NickTypeAheadOption) {
          return <NickTypeaheadOptionRenderer option={option} query={query.current.withoutControlKeys ?? ''} />;
        }
        if (option instanceof ChannelTypeAheadOption || option instanceof DynamicChannelTypeAheadOption) {
          return <ChannelTypeAheadOptionRenderer option={option} query={query.current.withoutControlKeys ?? ''} />;
        }
        return <div className={_c0}>
										<div className={_c1}>
											<Text className={_c2}>/{option.command}</Text>
											{option.data.autocompleteType !== 'none' && <div className={_c3 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
													<Text type={'tiny'} className={_c4}>
														{option.data.autocompleteType.toUpperCase()}
													</Text>
												</div>}
											{i === selectedIndex && option.data.autocompleteType !== 'none' && <Text type={'tiny'} className={_c5}>
														{isTouchDevice() ? 'Klicken ' : 'Tab drücken '}
														um Parameter zu
														vervollständigen
													</Text>}
										</div>
										<Text numberOfLines={1} type={'tiny'} state={'secondary'} className={_c6}>
											{option.data.description}
										</Text>
									</div>;
      }} />}
				</CssAnimatePresence>
			</>;
});
SlashPlugin.displayName = 'SlashPlugin';
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex gap-tiny alignItems-center ";
const _c2 = "  ";
const _c3 = " Knu-Flex placeItems-center px-small py-tiny bg-contentBg borderRadius-large ";
const _c4 = "  ";
const _c5 = " ml-base ";
const _c6 = "  ";