import { $AuthenticatedClientService, useQuery } from '@knuddels-app/Connection';
import { ExactMatchChannelSearch, FilteredChannelGroupsByPrefixAndCategory, SearchChannelGroupFragment } from '@generated/graphql';
import { useSearchListData } from './useSearchListData';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { debounce } from '@knuddels-app/tools/debounce';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export const useFilteredChannels = (searchText: string) => {
  // run queries only for the first entered character
  const filterFirstChar = searchText.charAt(0);
  const exactMatch = useExactMatchResult(searchText);
  const {
    data: cacheData
  } = useQuery(FilteredChannelGroupsByPrefixAndCategory, {
    prefix: filterFirstChar,
    pixelDensity: getPixelRatio()
  }, 'cache-only', {
    skip: searchText.length === 0
  });
  const {
    data: networkData,
    loading
  } = useQuery(FilteredChannelGroupsByPrefixAndCategory, {
    prefix: filterFirstChar,
    pixelDensity: getPixelRatio()
  }, 'network-only', {
    skip: searchText.length === 0
  });
  const filteredData = (networkData || cacheData || []).filter(channelGroup => filterChannelGroup(channelGroup, searchText));
  const data = useSearchListData(filteredData, exactMatch);
  return {
    data,
    loading
  };
};
const filterChannelGroup = (channelGroup: SearchChannelGroupFragment, searchText: string) => {
  const lowerCaseSearchText = searchText.toLowerCase();
  if (channelGroup.name.toLocaleLowerCase().startsWith(lowerCaseSearchText)) {
    return true;
  }
  if (lowerCaseSearchText.length < 2) {
    return false;
  }
  return channelGroup.info.categoryNames.some(name => name.toLocaleLowerCase().startsWith(lowerCaseSearchText)) || channelGroup.info.searchKeywords.some(keyword => keyword.toLocaleLowerCase().startsWith(lowerCaseSearchText));
};
const useExactMatchResult = (searchText: string) => {
  const [match, setMatch] = React.useState<SearchChannelGroupFragment>(null);
  const track = useLogEvent();
  const authenticatedClientService = useService($AuthenticatedClientService);
  const debouncedFunc = React.useCallback(debounce((search: string) => {
    track('ChannelSelection_Search', `${search}_Entered`);
    authenticatedClientService.currentK3Client.queryWithResultPromise(ExactMatchChannelSearch, {
      searchText: search,
      pixelDensity: getPixelRatio()
    }, 'network-only').onOk(result => {
      if (result) {
        setMatch(result);
      }
    });
  }, 500), [authenticatedClientService]);
  React.useEffect(() => {
    if (searchText.length > 0) {
      setMatch(null);
      debouncedFunc(searchText);
    } else {
      setMatch(null);
    }
  }, [searchText, debouncedFunc]);
  return match;
};