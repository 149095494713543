import * as React from 'react';
import { RadialHeaderMessage } from './RadialHeaderMessage';
import { FlexCol, Text } from '@knuddels/component-library';
import { FormattedMessage } from '@knuddels-app/i18n';
import { HeaderIcon } from './HeaderIcon';
import { BIRTHDAY_1, BIRTHDAY_2 } from '../../../../i18n/shared-formats';
import { useTrackConversation } from '../../useTrackConversation';
export const BirthdayMessage: React.FC<{
  otherParticipantNick: string;
}> = ({
  otherParticipantNick
}) => {
  const track = useTrackConversation();
  React.useEffect(() => {
    track('SystemMessages_BirthdayMessageShown');
  }, []);
  return <RadialHeaderMessage px={'base'} mb={'xlarge'} radialInnerColor={'#F3ECFD'} radialOuterColor={'#C7A0FF'} showConfetti headerIcon={<HeaderIcon width={37} height={35} left={17} top={18} src={require('./sm_abo_011B_bdaycake.gif')} />}>
			<div className={_c0}>
				<Text className={_c1}>
					<FormattedMessage id={BIRTHDAY_1} values={{
          nick: <Text bold={true} className={_c2}>{otherParticipantNick}</Text>
        }} />
				</Text>
				<Text className={_c3}>
					<FormattedMessage id={BIRTHDAY_2} />
				</Text>
			</div>
		</RadialHeaderMessage>;
};
const _c0 = " Knu-FlexCol alignItems-center ";
const _c1 = " textAlign-center ";
const _c2 = "  ";
const _c3 = " textAlign-center ";