import { jsx as o } from "react/jsx-runtime";
import * as e from "react";
import { Input as n } from "./Input.js";
import { IconHide as m } from "../Icons/Hide.js";
import { IconShow as a } from "../Icons/Show.js";
const u = (s) => {
  const [t, r] = e.useState(!1);
  return /* @__PURE__ */ o(
    n,
    {
      ...s,
      type: t ? "text" : "password",
      adornmentEnd: t ? /* @__PURE__ */ o(m, { onPress: () => r(!1) }) : /* @__PURE__ */ o(a, { onPress: () => r(!0) })
    }
  );
};
export {
  u as PasswordInput
};
