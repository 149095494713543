import * as React from 'react';
import { FormattedText } from '@shared/components';
import { Quote } from './Quote';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { FlexCol, Theme, ThemeContext } from '@knuddels/component-library';
type NestedMessageProps = {
  text: FormattedText;
  senderNick: string;
  onDark?: boolean;
  forceBoldLinks?: boolean;
  getHighlightedLinkColor?(): string | undefined;
};
export class NestedMessage extends React.PureComponent<NestedMessageProps> {
  static contextType = ThemeContext;
  render(): JSX.Element {
    if (!this.props.text && !this.props.children) {
      return null;
    }
    return <div className={_c0}>
				<Quote onDark={this.props.onDark}>
					{this.props.children}
					<div className={_c1}>
						<FormattedTextDisplayWithCommandHandling textProps={{
            type: 'body1',
            state: 'tertiary',
            selectable: true
          }} text={this.props.text} getHighlightedLinkColor={this.getHighlightedLinkColor} forceBoldLinks={this.props.forceBoldLinks} />
					</div>
				</Quote>
			</div>;
  }
  private getHighlightedLinkColor = (): string | undefined => {
    const theme: Theme = this.context;
    return this.props.getHighlightedLinkColor ? this.props.getHighlightedLinkColor() : theme.colors.basic.accent;
  };
}
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-FlexCol ";