import { jsxs as y, jsx as n } from "react/jsx-runtime";
import * as i from "react";
import { IconPlusBig as D } from "../Icons/PlusBig.js";
import { useDisabledOpacity as N } from "../Inputs/useDisabledOpacity.js";
import { useHoverEvent as O } from "../Layout/helper/useHoverCallback.js";
import { Scale as C, Fade as S, Rotate as h } from "../Animation/Primitives.js";
import { CrossFade as z } from "../Animation/CrossFade.js";
import { Fab as A } from "./Fab.js";
import { useTheme as w } from "../../themes/useTheme.js";
import { useAnchor as B } from "./Anchor.js";
import { clsx as x } from "../../node_modules/clsx/dist/clsx.m.js";
import { useBgClassName as K } from "../Layout/Box/useBg.js";
import { createAccessiblePointerEventHandler as T } from "../../a11y/createAccessiblePointerEventHandler.js";
import { FlexCol as P } from "../Layout/FlexCol.js";
import { Flex as c } from "../Layout/Flex.js";
const F = 25, p = /* @__PURE__ */ n(D, { size: "large", color: "white-solid-white" }), j = (e) => {
  const a = i.useRef(!1), [l, d] = i.useState(!1), o = i.useRef(!1), [s, u] = i.useState(
    e.open ? void 0 : 0
  ), E = w(), t = e.open ?? l, m = e.disabled ? () => {
  } : e.onChange ?? d, f = i.Children.count(e.children), b = () => {
    o.current || u(0);
  };
  i.useLayoutEffect(() => {
    t ? (o.current = !0, u(void 0)) : o.current = !1;
  }, [t]);
  const v = e.openIcon ?? p, L = e.closeIcon ?? p, g = O({
    onHover: () => {
      a.current = !0, m(!0);
    },
    onHoverEnd: () => {
      a.current = !1, setTimeout(() => {
        m(a.current);
      });
    }
  });
  return /* @__PURE__ */ y(P, { ...B(e), ...e, alignItems: "center", children: [
    /* @__PURE__ */ n(
      c,
      {
        overflow: s === 0 ? "hidden" : void 0,
        maxHeight: s,
        width: E.sizes.fab.default,
        flexDirection: "column-reverse",
        alignItems: "center",
        onHover: t ? g.onHover : void 0,
        justifyContent: "center",
        ariaRole: "menu",
        ariaOrientation: "vertical",
        ariaHidden: !t,
        children: i.Children.map(
          e.children,
          (H, r) => {
            const I = t ? F * (r + 1) : (f - r) * F, R = t ? r === f - 1 ? b : void 0 : r === 0 ? b : void 0;
            return /* @__PURE__ */ n(
              "div",
              {
                className: "Knu-Flex mb-base",
                children: /* @__PURE__ */ n(
                  C,
                  {
                    scaled: t,
                    delay: I,
                    onFinished: R,
                    children: /* @__PURE__ */ n(S, { visible: t, delay: I, children: i.cloneElement(
                      H,
                      t ? {} : {
                        disabled: !0
                      }
                    ) })
                  }
                )
              },
              H.props.key ?? r
            );
          }
        )
      }
    ),
    /* @__PURE__ */ n(c, { ...g, children: /* @__PURE__ */ n(
      A,
      {
        ariaLabel: e.ariaLabel,
        disabled: e.disabled,
        onPress: () => {
          m(!t);
        },
        children: e.openIcon === e.closeIcon ? /* @__PURE__ */ n(h, { to: 45, rotated: t, children: v }) : /* @__PURE__ */ n("div", { className: "Knu-Flex size-full position-relative", children: /* @__PURE__ */ n(
          z,
          {
            initialVisible: !t,
            initial: /* @__PURE__ */ n(h, { to: 45, rotated: t, children: v }),
            end: /* @__PURE__ */ n(h, { to: 45, rotated: t, children: L })
          }
        ) })
      }
    ) })
  ] });
};
j.Option = (e) => {
  const [a, l] = i.useState(!1), d = N({ disabled: e.disabled }), o = w(), s = O({
    onHover: () => {
      l(!0);
    },
    onHoverEnd: () => {
      l(!1);
    }
  });
  return /* @__PURE__ */ n(c, { position: "relative", placeItems: "center", ...s, children: /* @__PURE__ */ n(C, { scaled: a && !e.disabled, from: 1, to: 1.05, children: /* @__PURE__ */ y("div", { className: "Knu-Flex flex-1 placeItems-center", children: [
    /* @__PURE__ */ n(
      "div",
      {
        className: x(
          "Knu-Flex position-absolute bg-speedDialOptionLabelBg borderRadius-base py-tiny px-small textAlign-center",
          K("speedDialOptionLabelBg")
        ),
        style: {
          right: o.sizes.fab.default
        },
        children: /* @__PURE__ */ n(
          "span",
          {
            className: x(
              "Knu-Text body1 singleLine text-primary"
            ),
            style: {
              whiteSpace: "nowrap"
            },
            children: e.label
          }
        )
      }
    ),
    /* @__PURE__ */ n(
      c,
      {
        ...d,
        ...T({
          pointerCallback: e.onPress,
          disabled: e.disabled
        }),
        width: o.sizes.fab.condensed,
        height: o.sizes.fab.condensed,
        ariaLabel: e.label,
        bg: "speedDialIconBg",
        placeItems: "center",
        borderRadius: "circle",
        shadow: "Shadow2",
        children: e.icon
      }
    )
  ] }) }) });
};
export {
  j as SpeedDial
};
