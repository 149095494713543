import * as React from 'react';
import { Flex, Image, toPointerHandler } from '@knuddels/component-library';
import * as Reactdom from 'react-dom';
import { ProfilePictureModalContentProps } from './ProfilePictureModalContentProps';

// @ts-expect-error No types available for this package
import loadImage from 'blueimp-load-image';
import styles from './ProfilePictureStyles.module.scss';
import defaultPic from '@shared/images/nopic.png';
export class ProfilePictureModalContent extends React.PureComponent<ProfilePictureModalContentProps> {
  private imageCanvas: HTMLDivElement;
  componentDidMount(): void {
    this.updatePicture();
  }
  componentDidUpdate(prevProps: Readonly<ProfilePictureModalContentProps>): void {
    if (this.props.picture !== prevProps.picture) {
      if (this.imageCanvas) {
        // eslint-disable-next-line react/no-find-dom-node
        const node = Reactdom.findDOMNode(this.imageCanvas);
        while (node.hasChildNodes()) {
          node.removeChild(node.lastChild);
        }
      }
      this.updatePicture();
    }
  }
  private readonly updatePicture = (): void => {
    const picture = this.props.picture;
    if (picture) {
      loadImage(picture, (img: HTMLImageElement) => {
        img.className = styles.profilePicModalImg;
        // eslint-disable-next-line react/no-find-dom-node
        Reactdom.findDOMNode(this.imageCanvas).appendChild(img);
      }, {
        orientation: true,
        cover: true,
        aspectRatio: 1
      });
    }
  };
  render(): React.ReactNode {
    return this.props.picture ? <div className={styles.profilePicModalDiv} ref={ref => this.imageCanvas = ref} onClick={this.props.onImageClicked} /> : <div onClick={toPointerHandler(this.props.onImageClicked)} className={_c0}>
				<Image src={defaultPic} resizeMode={'cover'} alt={''} className={_c1} />
			</div>;
  }
}
const _c0 = " Knu-Flex ";
const _c1 = " width-250px height-250px borderRadius-circle ";