import * as React from 'react';
import { useUpdateProfileUser } from '../profileQueryHelper';
import { mergeDeepPartial } from '@knuddels-app/tools/mergeDeepPartial';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';

// use a component instead of a hook so we can use the context via `useUpdateProfile`
// while also keeping it in the root component `ProfileRouting`
export const SubscribeAndUpdateProfilePicture: React.FC<{
  userId: string;
}> = props => {
  const updateProfileUser = useUpdateProfileUser();
  const userService = useService($UserService);
  React.useEffect(() => {
    return userService.onProfilePictureChanged.sub(userChanges => {
      if (userChanges.id === props.userId) {
        // Delay updating the profile picture to ensure we don't cache the old image
        setTimeout(() => {
          updateProfileUser(oldData => {
            return mergeDeepPartial(oldData, {
              user: {
                user: userChanges
              }
            });
          });
        }, 500);
      }
    }).dispose;
  }, [updateProfileUser]);
  return null;
};