import * as React from 'react';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { Flex, Image, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { $KnuddelDisplayAnimationService } from '../../providedServices';
import { AnimatedIconData } from '../services/KnuddelDisplayAnimationService';
import { OS, os } from '@shared/components/tools/os';
export const KnuddelIcon = injectedComponent({
  name: 'KnuddelIcon',
  props: declareProps<{
    staticIcon?: boolean;
  }>(),
  inject: {
    knuddelDisplayAnimationService: $KnuddelDisplayAnimationService
  }
}, ({
  knuddelDisplayAnimationService,
  staticIcon
}) => {
  const icon: AnimatedIconData = staticIcon ? {
    icon: require('../assets/sm_classic_yellow.gif'),
    width: 17,
    height: 13
  } : knuddelDisplayAnimationService.currentIcon;

  // Need to block pointer events, so smiley can show in front of other elements but doesn't interfere with clicks
  return <div style={{
    bottom: resolveThemingValue(!icon.translateY || os === OS.web ? icon.translateY : (-icon.translateY as ThemeOverride), "spacing", useTheme()),
    left: resolveThemingValue((icon.translateX as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
				<Image src={icon.icon} resizeMode={'contain'} alt={''} style={{
      width: resolveThemingValue(icon.width + 'px', "sizes", useTheme()),
      height: resolveThemingValue(icon.height + 'px', "sizes", useTheme())
    }} className={_c1} />
			</div>;
});
const _c0 = " Knu-Flex flexDirection-column-reverse width-16px height-14px overflow-visible alignItems-center pointerEvents-none ";
const _c1 = "  ";