import { AppViewer } from './AppViewer';
import { AppInstance, AppInstanceData } from './AppInstance';
import { action, observable } from '@knuddels-app/mobx';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { globalAppViewId } from '@knuddelsModules/SystemApps';
import { $ViewService } from '@knuddels-app/layout';
@injectable()
export class GlobalAppsAppViewer implements AppViewer {
  @observable
  public app: AppInstance | null;
  @observable
  public overlayApp: AppInstance | null;
  public globalAppViewId = globalAppViewId;
  constructor(@inject($ViewService)
  private readonly viewService: typeof $ViewService.T) {}
  @action.bound
  addApp(app: AppInstance): void {
    if (this.viewService.isViewInOverlay(globalAppViewId)) {
      this.overlayApp = app;
      return;
    }
    if (this.app) {
      this.app.close();
    }
    this.app = app;
  }
  @action.bound
  closeAllApps(): void {
    this.app?.close();
    this.overlayApp?.close();
  }
  @action.bound
  closeAllAppsExceptForChannel(): void {}
  @action.bound
  closeApp(appId: string): void {
    if (this.overlayApp?.appId === appId) {
      this.overlayApp?.close();
      return;
    }
    if (this.app?.appId === appId) {
      this.app?.close();
    }
  }
  @action.bound
  removeApp(appId: string): void {
    if (this.overlayApp?.appId === appId) {
      this.overlayApp = null;
      return;
    }
    if (this.app?.appId === appId) {
      this.app = null;
    }
  }
  getApp(appId: string): AppInstance | undefined {
    if (this.overlayApp?.appId === appId) {
      return this.overlayApp;
    }
    if (this.app?.appId === appId) {
      return this.app;
    }
  }
  canHandleAppEvent(app: AppInstanceData & {
    global: boolean;
    globalAppViewOpen: boolean;
  }): boolean {
    if (!app.globalAppViewOpen) {
      return false;
    }
    return app.global || app.display.__typename === 'AppDisplaySidebar';
  }
  getAllApps(): AppInstance[] {
    return this.app ? [this.app] : [];
  }
}