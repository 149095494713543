import { jsx as c, jsxs as C } from "react/jsx-runtime";
import { createAccessiblePointerEventHandler as k } from "../a11y/createAccessiblePointerEventHandler.js";
import { clsx as a } from "../node_modules/clsx/dist/clsx.m.js";
import { Flex as y } from "./Layout/Flex.js";
import { resolveIsDarkColor as T } from "./Layout/isDarkColor.js";
import { useTheme as j } from "../themes/useTheme.js";
import { Ripple as w } from "./Layout/Box/Ripple.js";
const D = ({
  children: e,
  disabled: r,
  onPress: m,
  type: t = "primary",
  ariaLabel: d,
  ariaValueNow: p,
  ariaValueMin: b,
  ariaValueMax: h,
  title: g,
  onFocus: u,
  onBlur: f,
  innerRef: x
}) => {
  const s = j(), o = s.buttons[t], n = o.colors.disabled, l = o.border.colors.disabled, v = typeof e == "string" ? /* @__PURE__ */ c(
    "span",
    {
      className: a(
        "Knu-Text bold singleLine body1",
        r ? "text-tertiary" : `text-${t}`
      ),
      children: e
    }
  ) : e, i = o.colors.background, B = r ? n : i;
  return /* @__PURE__ */ C(
    y,
    {
      as: "button",
      title: g,
      className: a(
        T(i, s) ? "content-is-dark" : "content-is-light"
      ),
      ...k({
        disabled: r,
        pointerCallback: m,
        pointerEventType: "onPress"
      }),
      onFocus: u,
      onBlur: f,
      ariaLabel: d,
      ariaValueMax: h,
      ariaValueMin: b,
      ariaValueNow: p,
      width: "100%",
      bg: B,
      hoverBg: r ? n : o.colors.hover,
      borderStyle: "solid",
      borderColor: r ? l : o.border.colors.normal,
      hoverBorderColor: r ? l : o.border.colors.hover,
      borderWidth: o.border.width,
      borderRadius: "large",
      height: s.sizes.buttons.default,
      textAlign: "center",
      placeItems: "center",
      p: "none",
      px: "large",
      innerRef: x,
      position: "relative",
      children: [
        /* @__PURE__ */ c(w, {}),
        v
      ]
    }
  );
};
export {
  D as Button
};
