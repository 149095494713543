import * as o from "react";
const a = () => {
  const [s, e] = o.useState(!1);
  return {
    backdropProps: {
      open: s,
      onHover: () => {
        e(!1);
      },
      onPress: () => {
        e(!1);
      }
    },
    speedDialProps: {
      open: s,
      onChange: e
    }
  };
};
export {
  a as useSpeedDial
};
