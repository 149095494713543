import { ViewId } from '@knuddels-app/layout';
import { RouterParamsConfig } from '@knuddels/component-library';
import { GenericSystemAppViewState } from '@knuddelsModules/SystemApps/GenericSystemAppViewProvider';

const viewIdCache = new Map<string, ViewId<GenericSystemAppViewState<any>>>();

export function viewIdForSystemApp<T extends RouterParamsConfig<any> = {}>(
	appId: string
): ViewId<GenericSystemAppViewState<T>> {
	if (viewIdCache.has(appId)) {
		return viewIdCache.get(appId)!;
	}

	const viewId = new ViewId<GenericSystemAppViewState<T>>(`app-${appId}`);
	viewIdCache.set(appId, viewId);
	return viewId;
}
