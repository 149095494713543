import { declareFormat, FormatId } from '@knuddels-app/i18n';
import { OsInfo } from '@knuddels-app/tools/clientInformation/osInfo.interface';
import entryNotifications from '../assets/benefits-entry-notifications.png';
import entryOneClick from '../assets/benefits-entry-one-click.png';
import entryMultitasking from '../assets/benefits-entry-multitasking.png';
import entryAllFeatures from '../assets/benefits-entry-all-features.png';
import entryFavoriteSmileys from '../assets/benefits-entry-favorite-smileys.png';
import entryExchangeSmileys from '../assets/benefits-entry-exchange-smileys.png';
export type AppBenefit = {
  icon?: string;
  textFormatId: FormatId;
};
const mobileAppBenefits: AppBenefit[] = [{
  icon: entryNotifications,
  textFormatId: declareFormat({
    id: 'download.benefits.notifications',
    defaultFormat: 'Never miss a message again!\nGet notifications when someone texts you!'
  })
}, {
  icon: entryOneClick,
  textFormatId: declareFormat({
    id: 'download.benefits.oneClick',
    defaultFormat: 'Be with all your friends with just one click!'
  })
}];
const stAppBenefits: AppBenefit[] = [{
  icon: entryMultitasking,
  textFormatId: declareFormat({
    id: 'download.benefits.multitasking',
    defaultFormat: 'Chat and play in multiple chat rooms at the same time'
  })
}, {
  icon: entryAllFeatures,
  textFormatId: declareFormat({
    id: 'download.benefits.allFeatures',
    defaultFormat: 'Access to all the features Knuddels has to offer'
  })
}, {
  icon: entryFavoriteSmileys,
  textFormatId: declareFormat({
    id: 'download.benefits.favoriteSmileys',
    defaultFormat: 'Use the features of your favorite smileys'
  })
}, {
  icon: entryExchangeSmileys,
  textFormatId: declareFormat({
    id: 'download.benefits.exchangeSmileys',
    defaultFormat: 'Exchange your smileys with other users'
  })
}];
export const getAppBenefits = (os: OsInfo): ReadonlyArray<AppBenefit> => {
  return getForOs<ReadonlyArray<AppBenefit>>(os, mobileAppBenefits, stAppBenefits);
};
export const getAppBenefitsTitle = (os: OsInfo): FormatId => {
  return (getForOs<FormatId>(os, declareFormat({
    id: 'download.benefits.title.mobile',
    defaultFormat: 'Knuddels to go!'
  }), declareFormat({
    id: 'download.benefits.title.desktop',
    defaultFormat: 'Use Knuddels like a pro!'
  })) as FormatId);
};
const getForOs = <T,>(os: OsInfo, forMobile: T, forDesktop: T): T => {
  switch (os.type) {
    case 'ios':
    case 'android':
      return forMobile;
    case 'windows':
    case 'macos':
    case 'linux':
    default:
      return forDesktop;
  }
};