import { addPointerEvent as c } from "../events/add-pointer-event.js";
import { pipe as u } from "../utils/pipe.js";
import { isDragActive as f } from "./drag/utils/lock.js";
import { Feature as v } from "../motion/features/Feature.js";
import { frame as l } from "../frameloop/frame.js";
function i(t, e) {
  const s = e ? "pointerenter" : "pointerleave", r = e ? "onHoverStart" : "onHoverEnd", m = (o, p) => {
    if (o.pointerType === "touch" || f())
      return;
    const n = t.getProps();
    t.animationState && n.whileHover && t.animationState.setActive("whileHover", e);
    const a = n[r];
    a && l.postRender(() => a(o, p));
  };
  return c(t.current, s, m, {
    passive: !t.getProps()[r]
  });
}
class P extends v {
  mount() {
    this.unmount = u(i(this.node, !0), i(this.node, !1));
  }
  unmount() {
  }
}
export {
  P as HoverGesture
};
