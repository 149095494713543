export const FAQ_URL = 'https://hilfe.knuddels.de/';
export const LEGAL_URL = 'https://www.knuddels.de/impressum';
export const PRIVACY_POLICY_URL = 'https://www.knuddels.de/datenschutz';
export const TERMS_AND_CONDITIONS_URL = 'https://www.knuddels.de/agb';
export const PAYMENTS_TERMS_URL = 'https://www.knuddels.de/payment/terms';

export enum NotificationStatusType {
	Friends = 'Friends',
	Watchlist = 'Watchlist',
}
