import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { $CommandService } from '@knuddels-app/Commands';
import { Disposable } from '@knuddels/std';
import { smileyTradeAppViewId } from '@knuddelsModules/SystemApps';

export const $SmileyTradeAppView = newServiceId<SmileyTradeAppView>(
	'$SmileyTradeAppView'
);

@injectable()
export class SmileyTradeAppView {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($ViewService)
		viewService: typeof $ViewService.T,
		@inject($CommandService)
		commandService: typeof $CommandService.T
	) {
		this.dispose.track(
			commandService.registerCommand({
				commandName: 'smileyauctionsearch',
				invoke: async (): Promise<void> => {
					viewService.openViewAsOverlay(
						smileyTradeAppViewId.with(state =>
							state.withConfig({
								path: 'home',
								params: {
									activeFilter: [
										{
											id: 'K3Compatible',
										},
										{
											id: 'Feature',
											filter: [{ id: 'Foto-Overlay' }],
										},
									],
								},
							})
						),
						'push',
						true
					);
				},
			})
		);
	}
}
