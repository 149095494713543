import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';

// @deprecated Use startTrace and stopTrace in FirebaseAnalyticsService
export function createUserTimingTracker(
	eventLabel: string,
	name: string
): { start(): void; stop(): void } {
	let startTracePromise:
		| ReturnType<typeof $K3Firebase.T['perf']['startTrace']>
		| undefined = undefined;

	return {
		async start(): Promise<void> {
			// cannot call `dangerouslyGetK3Container().getService($K3Firebase)` in `createUserTimingTracker`
			// because the service is not registered yet when calling the function.
			const perf = dangerouslyGetK3Container().getService($K3Firebase)
				.perf;
			startTracePromise = perf.startTrace(`${eventLabel}_${name}`);
			await startTracePromise;
		},
		async stop(): Promise<void> {
			if (startTracePromise) {
				const stopPromise = startTracePromise.then(trace =>
					trace.stop()
				);
				// first clear promise to prevent multiple stop calls.
				startTracePromise = undefined;
				await stopPromise;
			}
		},
	};
}
