import * as React from 'react';
import { FadingView } from '@knuddels/component-library';
export class MessageFadeInView extends React.PureComponent<{
  animated: boolean;
}> {
  private readonly animated = this.props.animated;
  render(): React.ReactNode {
    return this.animated ? <FadingView duration={250} delay={100} className={_c0}>
				{this.props.children}
			</FadingView> : this.props.children;
  }
}
const _c0 = "  ";