import { injectable } from '@knuddels-app/DependencyInjection';
import { autorun } from '@knuddels-app/mobx';
import { Disposable, expectUnreachable } from '@knuddels/std';
import {
	FeatureEnum,
	FeatureEnumState,
	FeatureEnumStateImpl,
} from './FeatureEnum';
import {
	FeatureFlag,
	FeatureFlagState,
	FeatureFlagStateImpl,
} from './FeatureFlag';
import { FeatureSetting } from './FeatureSetting';

/**
 * Maintains feature flags and enums (= feature settings).
 * Feature flags can be used to dynamically enable or disable certain parts of a product.
 * Feature flags are designed in a way that they can be configured statically at compile time or dynamically at runtime.
 * Dynamic feature configuration through the backend is not yet implemented.
 * For development, you can use the global `featureSettings` object in the dev console to configure feature settings.
 */
@injectable()
export class FeatureService {
	public readonly dispose = Disposable.fn();

	private readonly flagStates = new Map<FeatureFlag, FeatureFlagStateImpl>();
	private readonly enumStates = new Map<
		FeatureEnum<any>,
		FeatureEnumStateImpl<any>
	>();

	constructor() {
		if (import.meta.env.MODE === 'development') {
			this.installFeatureFlagDevTools();
		}
	}

	private installFeatureFlagDevTools(): void {
		if (import.meta.env.MODE === 'development') {
			/**
			 * This sets up `window.featureSettings` for a convenient access
			 * to change feature settings in the dev console.
			 */
			this.dispose.track(
				autorun({ name: 'Update feature flag dev object' }, () => {
					if (typeof window !== 'undefined') {
						const featureSettingsObj: Record<string, any> = {};
						(window as any).featureSettings = featureSettingsObj;

						for (const setting of FeatureSetting.settings.values()) {
							const obj: any = {};
							if (setting.kind === 'FeatureEnum') {
								for (const val of setting.values) {
									obj[
										`setTo${
											val[0].toUpperCase() + val.substr(1)
										}`
									] = () =>
										this.getFeatureEnumState(
											setting
										).setValue(val);
								}
							} else if (setting.kind === 'FeatureFlag') {
								obj.enable = () =>
									this.getFeatureFlagState(setting).setEnable(
										true
									);
								obj.disable = () =>
									this.getFeatureFlagState(setting).setEnable(
										false
									);
							}
							featureSettingsObj[setting.id] = obj;
						}
					}
				})
			);
		}
	}

	public setFeatureFlagEnabled(flag: FeatureFlag, enabled: boolean): void {
		const state = this.getFeatureFlagState(flag);
		state.setEnable(enabled);
	}

	public resetFeatureFlag(flag: FeatureFlag): void {
		this.flagStates.delete(flag);
	}

	public getState<T extends string>(
		featureEnum: FeatureEnum<T>
	): FeatureEnumState<T>;
	public getState(flag: FeatureFlag): FeatureFlagState;
	public getState(
		setting: FeatureFlag | FeatureEnum<any>
	): FeatureFlagState | FeatureEnumState<any> {
		if (setting.kind === 'FeatureEnum') {
			return this.getFeatureEnumState(setting);
		} else if (setting.kind === 'FeatureFlag') {
			return this.getFeatureFlagState(setting);
		} else {
			return expectUnreachable(setting);
		}
	}

	public hasSomeFlagsEnabled(flags: FeatureFlag[]): boolean {
		return flags.some(flag => this.getFeatureFlagState(flag).isEnabled);
	}

	private getFeatureFlagState(
		featureFlag: FeatureFlag
	): FeatureFlagStateImpl {
		let result = this.flagStates.get(featureFlag);
		if (!result) {
			result = new FeatureFlagStateImpl(featureFlag);
			this.flagStates.set(featureFlag, result);

			if (featureFlag.default !== undefined) {
				result.setEnable(featureFlag.default);
			}
		}
		return result;
	}

	private getFeatureEnumState<T extends string>(
		featureEnum: FeatureEnum<T>
	): FeatureEnumStateImpl<T> {
		let result = this.enumStates.get(featureEnum);
		if (!result) {
			result = new FeatureEnumStateImpl(featureEnum);
			this.enumStates.set(featureEnum, result);

			if (featureEnum.default !== undefined) {
				result.setValue(featureEnum.default);
			}
		}
		return result;
	}
}
