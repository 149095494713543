import * as React from 'react';
import { MessageBubble } from './MessageBubble';
import { Column, Spacer } from '../../atoms';
import { Flex, FlexCol, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
export interface MessageGroupProps extends React.PropsWithChildren<unknown> {
  align: 'left' | 'right';
  bubbleColor?: string;
  contents: React.ReactNode[];
  isStackedLayout?: boolean;
  shadow?: boolean;
  additionalMarginOnLastBubble?: number;
}

/**
 * Represents a sequence of messages that all share the same sender.
 * A single avatar is shown for that sender.
 */
export function createAbstractMessageGroup<TProps>(renderImage: (props: MessageGroupProps & TProps) => JSX.Element): React.FC<MessageGroupProps & TProps> {
  return function AbstractMessageGroup(props: MessageGroupProps & TProps): JSX.Element {
    const {
      align,
      isStackedLayout
    } = props;
    const messages: React.ReactNode[] = [];
    props.contents.forEach((content, index) => {
      if (index !== 0) {
        messages.push(<div key={2 * index - 1} className={_c0} />);
      }
      if (props.bubbleColor) {
        messages.push(<MessageBubble key={2 * index} align={align} color={props.bubbleColor} hasArrow={index === 0} shadow={props.shadow} additionalMargin={index === props.contents.length - 1 ? props.additionalMarginOnLastBubble : undefined}>
						{content}
					</MessageBubble>);
      } else {
        messages.push(content);
      }
    });
    const alignLeft = align === 'left';
    const renderUserImage = alignLeft;

    // 40 image + 4 to arrow + 8 arrow + 16
    const paddingRightAligned = 40 + 4 + 8 + 16;
    const bigScreenPadding = !isStackedLayout ? 8 : 0;
    const paddingLeft = (alignLeft ? 0 : paddingRightAligned) + bigScreenPadding;
    const paddingRight = bigScreenPadding;
    return <div style={{
      paddingLeft: resolveThemingValue((paddingLeft as ThemeOverride), "spacing", useTheme()),
      paddingRight: resolveThemingValue((paddingRight as ThemeOverride), "spacing", useTheme())
    }} className={_c1}>
				{renderUserImage && <Spacer size={'small'} />}
				{renderUserImage && renderImage(props)}
				{renderUserImage && <Spacer size={'tiny'} />}
				<Column fluid>{messages}</Column>
			</div>;
  };
}
const _c0 = " Knu-FlexCol height-2px ";
const _c1 = " Knu-Flex position-relative overflow-visible ";