import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $AutocompleteProviderService =
	newServiceId<services.AutocompleteProviderService>(
		'$AutocompleteProviderService'
	);

export const $SmileyService =
	newServiceId<services.SmileyService>('$SmileyService');
