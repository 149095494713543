import * as React from 'react';
export const useSlideInRow = (config: {
  duration: number;
  interval: number;
}) => {
  const domRef1 = React.useRef<HTMLDivElement | null>(null);
  const domRef2 = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    let toggle = false;
    let anim1: Animation | null = null;
    let anim2: Animation | null = null;
    domRef1.current!.style.transform = `translateX(100%)`;
    const interval = setInterval(() => {
      if (!domRef1.current || !domRef2.current) {
        return;
      }
      toggle = !toggle;
      anim1?.cancel();
      domRef1.current!.style.transform = `translateX(${toggle ? '100%' : '0'})`;
      anim1 = domRef1.current!.animate({
        transform: `translateX(${toggle ? '0' : '-100%'})`
      }, {
        duration: config.duration,
        fill: 'forwards'
      });
      anim2?.cancel();
      domRef2.current!.style.transform = `translateX(${!toggle ? '100%' : '0'})`;
      anim2 = domRef2.current!.animate({
        transform: `translateX(${!toggle ? '0' : '-100%'})`
      }, {
        duration: config.duration,
        fill: 'forwards'
      });
    }, config.interval);
    return () => {
      clearInterval(interval);
    };
  }, [config.interval, config.duration]);
  return {
    domRef1,
    domRef2
  };
};