import * as React from 'react';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action, computed, observable } from '@knuddels-app/mobx';
import { PreloadAllSmileys, SmileyDetails } from '@generated/graphql';
import { Disposable, expectUnreachable } from '@knuddels/std';
import { SmileyListRow, SmileyListRowSkeleton } from './SmileyListRow';
import { SmileyListHeader } from './SmileyListHeader';
import { listGap, listPadding, smileyDimensions } from './listConstants';
import { ItemInfo, SmileyRowSection } from './SmileyRowSection';
import { FlexCol, ThemeOverride, PointerEvent, VirtualList, VirtualListRenderItemInfo, FallbackBox, toPointerHandler } from '@knuddels/component-library';
import { SmileyTradeButton } from './SmileyTradeButton';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { smileyTradeFlag } from '@knuddelsModules/FeatureFlags';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $SmileyboxService } from '@knuddelsModules/Smileybox';
import { smileyboxEvent } from '../analytics';
import { SmileyId } from '../services';
import { useQuery } from '@knuddels-app/Connection';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { SmileyData } from '@shared/components/RichText/nodes/SmileyNode';
interface Props {
  items: readonly SmileyRowSection[];
  isLoading: boolean;
  smileyCellFetchPolicy?: 'cache-only' | 'cache-first';
  onSmileyClick(smileyDetails: SmileyDetails): void;
}
@injectable()
export class SmileyListModel {
  @observable
  private _containerWidth: number | undefined;
  @observable
  private _listWidth: number | undefined;
  @observable
  private smileyCountPerRow: number | undefined;
  constructor(@injectProps()
  private readonly props: Props, @inject($FeatureService)
  private readonly featureService: typeof $FeatureService.T) {}
  @computed
  get containerWidth(): number | undefined {
    return this._containerWidth;
  }
  get listWidth(): number | undefined {
    return this._listWidth;
  }
  @computed
  get itemList(): ItemInfo[] {
    const itemList = this.props.items.reduce((items, item) => {
      items.push(...item.toItemInfoList(this.props.isLoading, this.listWidth!, this.containerWidth!, this.smileyCountPerRow!, this.props.onSmileyClick));
      return items;
    }, ([] as ItemInfo[]));
    if (this.featureService.hasSomeFlagsEnabled([smileyTradeFlag])) {
      itemList.push({
        type: 'SmileyTradeButton',
        height: 40 + 12 + 12
      });
    }
    return itemList;
  }
  @action.bound
  handleLayout(width: number): void {
    if (width === 0) {
      return;
    }
    const containerWidth = width - listPadding.horizontal * 2;
    const count = Math.floor(containerWidth / (smileyDimensions.width + listGap));
    const smileyRowWidthWithOneMoreSmiley = (count + 1) * (smileyDimensions.width + listGap) - listGap * 2;
    if (smileyRowWidthWithOneMoreSmiley <= containerWidth) {
      this.smileyCountPerRow = count + 1;
    } else {
      this.smileyCountPerRow = count;
    }
    this._listWidth = (smileyDimensions.width + listGap) * this.smileyCountPerRow - listGap;
    this._containerWidth = containerWidth;
  }
  renderItem = (renderInfo: VirtualListRenderItemInfo<ItemInfo>): React.ReactElement => {
    switch (renderInfo.item.type) {
      case 'Header':
        return <SmileyListHeader text={renderInfo.item.text} width={renderInfo.item.width} />;
      case 'SmileyRow':
        return <SmileyListRow smileyIds={renderInfo.item.smileyIds} onSmileyClick={renderInfo.item.onSmileyClick} width={renderInfo.item.width} smileyCellFetchPolicy={this.props.smileyCellFetchPolicy} />;
      case 'SmileyRowSkeleton':
        return <SmileyListRowSkeleton smileyCount={renderInfo.item.smileyCount} width={renderInfo.item.width} />;
      case 'SmileyTradeButton':
        return <SmileyTradeButton />;
      default:
        expectUnreachable(renderInfo.item);
    }
  };
}
export const SmileyList = injectedComponent({
  model: SmileyListModel,
  name: 'SmileyList'
}, ({
  model
}) => {
  return <FallbackBox onLayout={e => model.handleLayout(e.width)} className={_c0}>
				<VirtualList data={model.itemList} renderItem={model.renderItem} gapTop={(listPadding.vertical as ThemeOverride)} showOverflow={true} className={_c1} />
			</FallbackBox>;
});
@injectable()
class SmileyboxModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: {}, @inject($I18n)
  private i18n: typeof $I18n.T, @inject($SmileyboxService)
  private smileyboxService: typeof $SmileyboxService.T) {
    smileyboxEvent.track('Opened');
    this.dispose.track(() => {
      smileyboxEvent.track('Closed');
    });
  }
  get isLoading(): boolean {
    return this.smileyboxService.isLoading;
  }
  @computed
  get itemList(): readonly SmileyRowSection[] {
    return [SmileyRowSection.create(this.i18n.format(declareFormat({
      id: 'smileybox.section.recentlyUsed.header',
      defaultFormat: 'RECENTLY USED'
    })), this.recentSmileyIds), SmileyRowSection.create(this.i18n.format(declareFormat({
      id: 'smileybox.section.allUsable.header',
      defaultFormat: 'ALL'
    })), this.allSmileyIds)];
  }
  private get recentSmileyIds(): readonly SmileyId[] {
    return this.smileyboxService.recentSmileyIds;
  }
  private get allSmileyIds(): readonly SmileyId[] {
    return this.smileyboxService.allSmileyIds;
  }
}
const adaptToSmileyData = (data: SmileyDetails) => {
  const objToString = (pseudoCss: Record<any, any>) => {
    let str = '';
    for (const key of Object.keys(pseudoCss).sort()) {
      if (parseInt(pseudoCss[key]) === 1) {
        str += `${key}}.`;
      }
      str += `${key}_${pseudoCss[key]}.`;
    }
    return str;
  };
  const url = JSON.parse(JSON.parse(data.image).smiley.json).map((x: any) => {
    const pseudoCSS = x.pseudoCSS;
    const extensionIndex = x.url.lastIndexOf('.');
    return x.url.substring(0, extensionIndex) + (Object.keys(pseudoCSS).length ? '...' + objToString(pseudoCSS) : '') + x.url.substring(extensionIndex);
  }).join('<>');
  return (({
    rpl: data.textRepresentation,
    url
  } as any) satisfies SmileyData);
};
export const StappSmileybox = injectedComponent({
  model: SmileyboxModel,
  name: 'Smileybox',
  props: declareProps<{
    editorRef: React.MutableRefObject<RichTextEditorRef | null>;
  }>()
}, ({
  model,
  editorRef
}) => {
  const preloadAllSmileys = useQuery(PreloadAllSmileys, {});
  const handle = (e: PointerEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return <div onPointerDown={toPointerHandler(handle)} onClick={toPointerHandler(handle)} className={_c2}>
				<SmileyList isLoading={model.isLoading} items={model.itemList} onSmileyClick={d => {
      editorRef.current.insertSmiley(adaptToSmileyData(d));
    }}
    // first use cache-only while preload is happening. Then also execute normal queries once if necessary (e.g. because the preload query is old)
    smileyCellFetchPolicy={preloadAllSmileys.loading ? 'cache-only' : 'cache-first'} />
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol flex-1 ";