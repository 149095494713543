import * as React from 'react';
import { FormProps } from './FormInterface';
export class Form extends React.PureComponent<FormProps> {
  private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSubmit(event);
  };
  render(): JSX.Element {
    return <form onSubmit={this.onSubmit}>{this.props.children}</form>;
  }
}