import * as React from 'react';
import { useRouter } from './ProfileContent/ProfileContentRouter';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
export const NickHistoryApp: React.FC<{
  nick: string;
}> = ({
  nick
}) => {
  const {
    pop
  } = useRouter();
  return <GenericSystemApp onAppClose={() => {
    pop();
  }} appId={'NickHistoryApp'} viewType={'subpath'} customSlashCommand={'/nickswitch history:' + nick} />;
};