import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { TanSystemOverview } from './TanSystemSettings/TanSystemOverview';
import { ListGroup } from '@knuddels/component-library';
export const SecuritySettings: React.FC = observer('SecuritySettings', () => {
  const i18n = useService($I18n);
  return <ListGroup title={declareFormat({
    id: 'settings.accountSettings.securityGroup.title',
    defaultFormat: 'Security'
  }).format(i18n)}>
			<TanSystemOverview />
		</ListGroup>;
});