export type DeeplinkHandler = (type: DeepLinkType, target: string) => void;

export enum DeepLinkType {
	Default = 'Default',
	Conversation = 'Conversation',
	Channel = 'Channel',
	ChannelWithSmileyBox = 'ChannelWithSmileyBox',
	App = 'App',
	Profile = 'Profile',
	Permission = 'Permission',
	OpenAppReview = 'OpenAppReview',
}
