import * as React from 'react';
import { Button, Spacer } from '@shared/components';
import { declareFormat, FormatId, formatMessage } from '@knuddels-app/i18n';
import { expectUnreachable } from '@knuddels/std';
import { conversationStateEvent } from '../../../../analytics';
import { FullConversationFragment, MessengerConversationVisibility } from '@generated/graphql';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $MessengerService } from '@knuddelsModules/Messenger/providedServices';
import { $CommandWithoutChannelService } from '@knuddels-app/Commands';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { SnackbarDefinitionAutomaticComplaintSuccess } from '../../../../snackbars/AuomaticComplaint';
import { $IgnoreService } from '@knuddelsModules/UserData';
import { Flex, FlexCol, Text } from '@knuddels/component-library';
interface Props {
  conversation: FullConversationFragment;
  kind: IgnoreKind;
}
export type IgnoreKind = 'ignore' | 'privateIgnore' | 'block' | 'receiver' | 'automaticComplaintWithButton' | 'automaticComplaintWithoutButton' | 'contactFilter';
type ButtonTypes = 'block' | 'unblock' | 'unignore' | 'unprivateignore' | 'archive' | 'complain';
type ButtonData = {
  title: FormatId;
  onClick: () => void;
  testId?: string;
};
const UNIGNORE_BUTTON = declareFormat({
  id: 'UNIGNORE_BUTTON',
  defaultFormat: 'Undo'
});
@injectable()
class IgnorePanelModel implements IModel {
  readonly buttons: { [key in ButtonTypes]: ButtonData } = {
    block: {
      title: declareFormat({
        id: 'BLOCK_BUTTON',
        defaultFormat: 'Block forever'
      }),
      onClick: (): void => this.block()
    },
    unblock: {
      title: UNIGNORE_BUTTON,
      onClick: (): void => this.unblock(),
      testId: 'ignore-panel-forgive'
    },
    unignore: {
      title: UNIGNORE_BUTTON,
      onClick: (): void => this.unignore(),
      testId: 'ignore-panel-forgive'
    },
    unprivateignore: {
      title: UNIGNORE_BUTTON,
      onClick: (): void => this.unprivateignore(),
      testId: 'ignore-panel-forgive'
    },
    archive: {
      title: declareFormat({
        id: 'IGNORE_RECEIVER_ARCHIVE_BUTTON',
        defaultFormat: 'Archive conversation'
      }),
      onClick: (): void => this.archive(),
      testId: 'ignore-panel-archive'
    },
    complain: {
      title: declareFormat({
        id: 'automaticComplaint.button',
        defaultFormat: 'Submit complaint'
      }),
      onClick: (): void => this.makeComplaint()
    }
  };
  constructor(@injectProps()
  public readonly props: Props, @inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($CommandWithoutChannelService)
  private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($IgnoreService)
  private readonly ignoreService: typeof $IgnoreService.T) {}
  public get kind(): IgnoreKind {
    return this.props.kind;
  }
  public get title(): string {
    const kind = this.kind;
    const otherParticipant = this.props.conversation.otherParticipants[0];
    return formatMessage(messageKeys[kind], {
      name: otherParticipant.nick,
      gender: otherParticipant.gender.toLowerCase()
    });
  }
  get hasButtons(): boolean {
    return this.kind !== 'automaticComplaintWithoutButton';
  }
  componentDidMount(): void {
    switch (this.props.kind) {
      case 'receiver':
        conversationStateEvent.track('Conversationstate_ShowHasBeenIgnored');
        break;
      case 'block':
        conversationStateEvent.track('Conversationstate_ShowBlocked');
        break;
      case 'ignore':
        conversationStateEvent.track('Conversationstate_ShowIgnored');
        break;
      case 'privateIgnore':
        conversationStateEvent.track('Conversationstate_ShowPrivateIgnored');
        break;
      case 'automaticComplaintWithButton':
      case 'automaticComplaintWithoutButton':
        this.firebaseAnalyticsService.logEvent('Messenger_Conversation', 'AutoReportMessage_Shown');
        break;
      default:
    }
  }
  unprivateignore = (): void => {
    conversationStateEvent.track('Conversationstate_UnPrivateIgnoreMessages');
    const otherParticipant = this.props.conversation.otherParticipants[0];
    this.ignoreService.unprivateIgnore(otherParticipant.id);
    this.messengerService.restoreConversation(this.props.conversation.id);
  };
  unignore = (): void => {
    conversationStateEvent.track('Conversationstate_UnIgnoreMessages');
    const otherParticipant = this.props.conversation.otherParticipants[0];
    this.ignoreService.unignore(otherParticipant.id);
  };
  block = (): void => {
    conversationStateEvent.track('Conversationstate_BlockMessages');
    const otherParticipant = this.props.conversation.otherParticipants[0];
    this.ignoreService.block(otherParticipant.id);
    this.messengerService.archiveConversation(this.props.conversation.id);
  };
  unblock = (): void => {
    conversationStateEvent.track('Conversationstate_UnBlockMessages');
    const otherParticipant = this.props.conversation.otherParticipants[0];
    this.ignoreService.unblock(otherParticipant.id);
    this.messengerService.restoreConversation(this.props.conversation.id);
  };
  archive = (): void => {
    conversationStateEvent.track('Conversationstate_ArchiveChat');
    this.messengerService.archiveConversation(this.props.conversation.id);
  };
  makeComplaint = (): void => {
    this.firebaseAnalyticsService.logEvent('Messenger_Conversation', 'AutoReportMessage_ReportedUser');
    const command = this.props.conversation.otherParticipants[0].automaticComplaintCommand;
    if (command) {
      this.commandWithoutChannelService.invokeCommand(command).then(() => {
        this.snackbarService.showSnackbar(SnackbarDefinitionAutomaticComplaintSuccess);
      }).catch(() => this.snackbarService.showGenericError());
      this.messengerService.archiveConversation(this.props.conversation.id, {
        showSnackbar: false
      });
    }
  };
}
export const IgnorePanel = injectedComponent({
  name: 'IgnorePanel',
  model: IgnorePanelModel,
  props: declareProps<Props>()
}, ({
  model
}) => {
  return <div className={_c0}>
				<Text type={'body2'} state={'secondary'} bold={true} className={_c1}>
					{model.title}
				</Text>
				{model.hasButtons && <>
						<Spacer size={'base'} />
						<div className={_c2}>
							<Buttons model={model} />
						</div>
					</>}
			</div>;
});
function Buttons({
  model
}: typeof IgnorePanel.TPropsWithModel): JSX.Element {
  const kind = model.kind;
  switch (kind) {
    case 'receiver':
      return model.props.conversation.visibility !== MessengerConversationVisibility.Visible ? null : <IgnorePanelButton data={model.buttons.archive} />;
    case 'block':
      return <IgnorePanelButton data={model.buttons.unblock} />;
    case 'ignore': // fallthrough
    case 'privateIgnore':
      return <>
					{kind === 'ignore' && <IgnorePanelButton data={model.buttons.unignore} />}
					{kind === 'privateIgnore' && <IgnorePanelButton data={model.buttons.unprivateignore} />}
					<Spacer size={'base'} key={'spacer'} />
					<IgnorePanelButton data={model.buttons.block} />
				</>;
    case 'automaticComplaintWithButton':
      return <IgnorePanelButton data={model.buttons.complain} />;
    case 'automaticComplaintWithoutButton':
      return null;
    case 'contactFilter':
      return model.props.conversation.visibility !== MessengerConversationVisibility.Visible || !model.props.conversation.latestConversationMessage ? null : <IgnorePanelButton data={model.buttons.archive} />;
    default:
      expectUnreachable(kind);
  }
}
function IgnorePanelButton({
  data
}: {
  data: ButtonData;
}): JSX.Element {
  return <Button text={formatMessage(data.title)} onClick={data.onClick} testId={data.testId} kind={'outline'} />;
}
const messageKeys: { [key in IgnoreKind]: FormatId } = {
  ignore: declareFormat({
    id: 'IGNORE_SENDER_MESSAGE',
    defaultFormat: 'Further messages from {name} will be ignored.'
  }),
  block: declareFormat({
    id: 'BLOCK_SENDER_MESSAGE',
    defaultFormat: "You've blocked {name}"
  }),
  receiver: declareFormat({
    id: 'IGNORE_RECEIVER_MESSAGE',
    defaultFormat: '{name} is currently not interested in chatting with you :('
  }),
  privateIgnore: declareFormat({
    id: 'PRIVATE_IGNORE_SENDER_MESSAGE',
    defaultFormat: "We've removed {name} from your requests."
  }),
  automaticComplaintWithButton: declareFormat({
    id: 'automaticComplaint.withButton.message',
    defaultFormat: 'We noticed possible inappropriate content in this conversation. Submit a complaint if you felt uncomfortable'
  }),
  automaticComplaintWithoutButton: declareFormat({
    id: 'automaticComplaint.withoutButton.message',
    defaultFormat: 'We noticed possible inappropriate content in this conversation. Therefore this conversation can no longer be continued.'
  }),
  contactFilter: declareFormat({
    id: 'IGNORE_CONTACT_FILTER_MESSAGE',
    defaultFormat: 'We are sorry, but currently you are not able to contact {name} because you do not meet all the criteria of their contact filter.'
  })
};
const _c0 = " Knu-FlexCol alignItems-center pt-xlarge pb-small ";
const _c1 = " textAlign-center ";
const _c2 = " Knu-Flex ";