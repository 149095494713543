import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { Disposable } from '@knuddels/std';
import { ChannelRoot } from '../components/ChannelRoot';
import { ChatHistory } from '../components/Chat/ChatContent/ChatHistory';
import { ChannelBackground } from '../components/Chat/ChatContent/ChannelBackground';
import { $AuthService } from '@knuddels-app/Connection';
import { clearChatHistory } from '../components/Chat/ChatInput/HistoryPlugin';
@injectable()
export class ChannelComponents {
  ChatHistory: typeof ChatHistory = ChatHistory;
  ChannelBackground: typeof ChannelBackground = ChannelBackground;
  public readonly dispose = Disposable.fn();
  public readonly MainView = ChannelRoot;
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T, @inject($AuthService)
  private readonly authService: typeof $AuthService.T) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de: require('../i18n/formats.de.json'),
      en: require('../i18n/formats.en.json')
    } as any)[locale.language]);
    this.dispose.track(this.authService.onBeforeVoluntaryLogout.sub(() => {
      clearChatHistory();
    }).dispose);
  }
}