export const easeInOut = (
	currentTime: number,
	start: number,
	change: number,
	duration: number
): number => {
	currentTime /= duration / 2;
	if (currentTime < 1) {
		return (change / 2) * currentTime * currentTime + start;
	}
	currentTime -= 1;
	return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
};
