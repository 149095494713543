import * as React from 'react';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Image, Text, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { FormattedText, FormattedTextDisplay } from '../FormattedText';
export const SuggestionButton: React.FC<{
  children: React.ReactNode;
  minWidth?: number | string;
  onPress(): void;
}> = props => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: props.onPress
  })} style={{
    minWidth: resolveThemingValue(props.minWidth, "sizes", useTheme())
  }} className={_c0 + ("black-transparent-550" ? resolveIsDarkColor("black-transparent-550", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{props.children}
		</div>;
};
export const CloseSuggestionButton: React.FC<{
  onPress(): void;
}> = props => {
  return <SuggestionButton onPress={props.onPress}>
			<div className={_c1}>
				<Image resizeMode={'contain'} src={require('@shared/icons/icon-close-circular-white.png')} alt={''} className={_c2} />
				<Text type={'body2'} bold={true} className={_c3}>
					<FormattedMessage id={declareFormat({
          id: 'messenger.suggestions.cancel',
          defaultFormat: 'Cancel'
        })} />
				</Text>
			</div>
		</SuggestionButton>;
};
export const TextSuggestionButton: React.FC<{
  formattedText: FormattedText;
  minWidth?: number | string;
  onPress(): void;
}> = ({
  formattedText,
  onPress,
  minWidth
}) => {
  return <SuggestionButton minWidth={minWidth} onPress={onPress}>
			<FormattedTextDisplay textProps={{}} text={formattedText} />
		</SuggestionButton>;
};
const _c0 = " Knu-FlexCol cursor-pointer flexShrink-0 minHeight-40px maxWidth-215px placeItems-center borderRadius-small bg-black-transparent-550 hoverBg-black-transparent-770 cursor-pointer px-base py-small ml-small ";
const _c1 = " Knu-Flex alignItems-center ";
const _c2 = " height-16px width-16px mr-tiny ";
const _c3 = "  ";