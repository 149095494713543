import * as React from 'react';
import { useEffect } from 'react';
import { observer } from '@knuddels-app/mobx';
import { FlexCol, ScrollView } from '@knuddels/component-library';
import { useMentorSystemInfos } from './mentorSystemInfoData';
import { AccordionBlock } from '@shared/components';
import { useTrackMentorSystemInfo } from './useTrackMentorSystemInfo';
import { MentorSystemLink } from '../shared/MentorSystemLink';
export const MentorSystemInfo: React.FC = observer('MentorSystemInfo', () => {
  const track = useTrackMentorSystemInfo();
  const items = useMentorSystemInfos();
  useEffect(() => {
    track('Infos_Shown');
  }, []);
  return <div className={_c0}>
			<div className={_c1}>
				<AccordionBlock items={items} />
			</div>
			<div className={_c2}>
				<MentorSystemLink />
			</div>
		</div>;
});
const _c0 = " Knu-ScrollView ";
const _c1 = " Knu-FlexCol pt-xlarge ";
const _c2 = " Knu-FlexCol my-xlarge mx-base ";