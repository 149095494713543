import { FullConversationWithoutMessagesFragment } from '@generated/graphql';
import { $CommandService } from '@knuddels-app/Commands';
import { useService } from '@knuddels-app/DependencyInjection';
export const useCommandOrPMessageSubmit = (otherParticipant: FullConversationWithoutMessagesFragment['otherParticipants'][0]) => {
  const commandService = useService($CommandService);
  const submit = async (originalText: string) => {
    const text = originalText.trim().replace(/\n/g, '#');
    const command = commandService.tryParseCommandCall(originalText) || {
      commandName: 'p',
      parameter: `${otherParticipant.nick}: ${text}`
    };
    await commandService.invokeCommand(command, 'channel');
  };
  return {
    submit
  };
};