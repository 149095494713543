import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '@knuddels-app/AppLoadingService';
import { $UserService } from './providedServices';

export const userDataModule = new Module({
	name: 'UserData',
	dependencies: [],
	loadEvent: 'onLogin',
	load: {
		from: async () => {
			return await import(
				/* webpackChunkName: "app" */ './bundle/registerServices'
			);
		},
	},
	loadStatic: ctx => {
		ctx.globalScope
			.bind($AppLoadingProvider)
			.toConstant(
				simpleAppLoadingProvider($UserService, service =>
					service.getCurrentUser().then()
				)
			);
	},
	providedServices,
});
