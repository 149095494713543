import * as React from 'react';
import { SnackbarProvider, SnackbarProviderProps } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { SnackbarContextConnector } from './SnackbarContextConnector';
export const K3SnackbarProvider: React.FC<SnackbarProviderProps> = props => {
  const environment = useService($Environment);
  if (environment.isStappSidebarOverview) {
    return <>{props.children}</>;
  } else {
    return <SnackbarProvider {...props}>
				<SnackbarContextConnector />
				{props.children}
			</SnackbarProvider>;
  }
};