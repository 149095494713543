import Cookies from 'js-cookie';
import { StorageProvider, StorageOptions } from './StorageProvider';

export const storageProvider: StorageProvider = {
	get(key: string, options: StorageOptions): string | undefined {
		const steps: ('local' | 'cookie')[] = options.preferLocalStorage
			? ['local', 'cookie']
			: ['cookie', 'local'];

		for (const step of steps) {
			if (step === 'local') {
				const result = localStorage.getItem(key);
				if (result !== null) {
					return result;
				}
			} else {
				const result = Cookies.get(key);
				if (result !== undefined) {
					return result;
				}
			}
		}

		return undefined;
	},

	set(key: string, value: string | undefined, options: StorageOptions): void {
		if (options.preferLocalStorage) {
			if (value !== undefined) {
				localStorage.setItem(key, value);
			} else {
				localStorage.removeItem(key);
			}
			// Delete stale cookies
			Cookies.remove(key, {
				domain: options.cookieDomain,
				expires: options.cookieExpires,
			});
		} else {
			if (value !== undefined) {
				Cookies.set(key, value, {
					domain: options.cookieDomain,
					expires: options.cookieExpires,
				});
			} else {
				Cookies.remove(key, {
					domain: options.cookieDomain,
					expires: options.cookieExpires,
				});
			}
			// Delete stale Local storage entries
			localStorage.removeItem(key);
		}
	},
};
