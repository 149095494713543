import * as React from 'react';
import { useTheme } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { AppInstance } from '../services/AppInstance';
import { useFontScale } from '@knuddels-app/tools/getFontScale';
import { $ScreenService } from '@knuddels-app/Screen';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';

export const useAppContext = (appInstance: Readonly<AppInstance>) => {
	const theme = useTheme();
	const i18n = useService($I18n);
	const initialized = React.useRef(false);
	const fontScale = useFontScale();
	const screenService = useService($ScreenService);
	const evergreenDataSerivce = useService($EvergreenDataService);
	const soundsDisabled = useSoundsDisabled(appInstance.appId);

	const context = React.useMemo(() => {
		return {
			fontScale,
			theme:
				theme.id === 'light' ? 'light' : ('dark' as 'light' | 'dark'),
			accentColor: theme.colors.basic.accent,
			language: i18n.currentLocale.language,
			contentBg: theme.colors.basic.contentBg,
			initialAppState: 'active',
			initialOrientation: screenService.orientation,
			hostSupports: ['Snackbars', 'Overlays', 'AsyncStorage'],
			evergreenData: evergreenDataSerivce.data,
			soundsDisabled,
		};
	}, [theme, i18n.currentLocale.language, fontScale, soundsDisabled]);

	React.useEffect(() => {
		if (!appInstance) {
			return;
		}

		if (!initialized.current) {
			initialized.current = true;
			return;
		}

		appInstance.sendEventToWebview('CONTEXT_CHANGE', {
			type: 'CONTEXT_CHANGE',
			context,
		});
	}, [context, appInstance]);

	return context;
};

const useSoundsDisabled = (appId: string) => {
	const clientSettingsService = useService($ClientSettingsService);
	return useReactiveState(
		() => !clientSettingsService.isSoundEnabledForApp(appId),
		[clientSettingsService, appId]
	);
};
