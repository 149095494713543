import * as React from 'react';
import { useContext } from 'react';
import { MessageClickableSender } from '../MessageClickableSender';
import { PrivateGroupMessage, PrivateGroupMessageFragment, PrivateGroupMessageTitle } from '../Messages/PrivateGroupMessage';
import { ChannelGroupInfo } from '@generated/graphql';
import { ANSWER_BUTTON_SIZE } from '../Messages/AnswerButtonWrapper';
import { MiniChatContext } from '@knuddelsModules/Messenger';
import { ChatRenderConfig } from '../../ChatRenderConfig';
import { CondensedMessage } from '../Messages/CondensedMessage';
import { useService } from '@knuddels-app/DependencyInjection';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { Text, ThemeOverride, useIsDarkColor, useTheme } from '@knuddels/component-library';
import { colorToRgbString } from '@knuddels-app/tools/colorToRgbString';
import { FormattedText } from '@shared/components';
import { $UserService } from '@knuddelsModules/UserData';
import { ChannelBackgroundColorContext } from '../ChannelBackground';
import { AnswerButton } from './CondensedAnswerButton';
import { colorToRgb } from '@knuddels-app/tools/colorToRgb';
type Props = {
  renderConfig: ChatRenderConfig;
  message: PrivateGroupMessageFragment;
  firstOfSet: boolean;
  lastOfSet: boolean;
  animated: boolean;
  isStackedLayout: boolean;
  channelGroupInfo: Pick<ChannelGroupInfo, 'backgroundColor' | 'highlightColor'>;
};
export const PrivateGroupMessageGroup: React.FC<Props> = ({
  isStackedLayout,
  message,
  channelGroupInfo,
  firstOfSet,
  lastOfSet,
  renderConfig
}) => {
  const theme = useTheme();
  const isParentDark = useIsDarkColor((useContext(ChannelBackgroundColorContext) as ThemeOverride));
  const renderMode = renderConfig.layoutService.renderMode;
  const isMiniChatContext = useContext(MiniChatContext);
  if (renderMode === 'condensed') {
    return <CondensedMessage>
				<Condensed renderConfig={renderConfig} message={message} showAnswerButton={!isMiniChatContext} />
			</CondensedMessage>;
  }
  const {
    red,
    green,
    blue
  } = channelGroupInfo.highlightColor;
  const bubbleColor = `rgba(${red}, ${green}, ${blue}, .66)`;
  const buttonColor = `rgba(${red}, ${green}, ${blue}, 1.0)`;
  return <MessageClickableSender uid={message.sender.id} bubbleColor={renderMode === 'modernWithoutBubbles' ? isParentDark ? theme.colors.basic.transparentDark : theme.colors.basic.transparentLight : bubbleColor} noBubblePadding={renderMode === 'modernWithoutBubbles'} align={'left'} isStackedLayout={isStackedLayout} showImage={firstOfSet} hasArrow={firstOfSet} additionalMargin={lastOfSet ? ANSWER_BUTTON_SIZE / 2 : undefined}>
			<PrivateGroupMessage isFirst={firstOfSet} isLast={lastOfSet} message={message} answerButtonColor={buttonColor} shouldShowAnswerButton={!isMiniChatContext} />
		</MessageClickableSender>;
};
const Condensed: React.FC<{
  renderConfig: ChatRenderConfig;
  message: PrivateGroupMessageFragment;
  showAnswerButton?: boolean;
}> = ({
  renderConfig,
  message,
  showAnswerButton
}) => {
  const activeUser = useService($UserService).currentUser;
  if (!activeUser) {
    return null;
  }
  return <FormattedTextDisplayWithCommandHandling renderIndent getHighlightedLinkColor={() => {
    return colorToRgb(renderConfig.channelGroupInfo.highlightColor);
  }} prefix={<Text bold={true} hoverUnderline={true} color={(colorToRgbString(renderConfig.channelGroupInfo.highlightColorCondensedPrivateMessage) as ThemeOverride)} className={_c0}>
					<PrivateGroupMessageTitle message={message} activeUser={activeUser} mode={'condensed'} />
					:{' '}
				</Text>} suffix={showAnswerButton && <AnswerButton answerTo={message.receiver.filter(it => it.id !== activeUser.id)} channelGroupInfo={renderConfig.channelGroupInfo} />} text={FormattedText.fromJsonString(message.formattedText)} />;
};
const _c0 = "  ";