import { useService } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { $ViewService } from '@knuddels-app/layout';
import { observer } from '@knuddels-app/mobx';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { FlexCol, ScrollView, useElementWidth, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { $AdsService } from '@knuddelsModules/Ads';
import { channelViewId } from '@knuddelsModules/Channel';
import { $ChannelListService } from '@knuddelsModules/ChannelList';
import { $MessengerComponents } from '@knuddelsModules/Messenger';
import { $ProfileVisitorsPanelService, ProfileVisitorsOverview } from '@knuddelsModules/ProfileVisitors';
import { KnuddelsPlusOrHappyHourBanner } from '@knuddelsModules/Promotions';
import { Spacer } from '@shared/components';
import * as React from 'react';
import { DashboardHeader } from './DashboardHeader';
import { DashboardTipsElement } from './DashboardTipsElement';
const TOP_ADZONE_WIDTH = 728;
const SIDE_ADZONE_SPACING = 24;
const SIDE_ADZONE_WIDTH = 300 + SIDE_ADZONE_SPACING;
const SCROLL_THUMB_WIDTH = 16;
export const NoChannelDashboard: React.FC = observer('NoCannelDashboard', () => {
  const visitorPanelService = useService($ProfileVisitorsPanelService);
  const isInBackground = useIsInBackground();
  const isStackedLayout = useIsStackedLayout();
  const {
    width,
    bind
  } = useElementWidth();
  const layout = isStackedLayout || width < TOP_ADZONE_WIDTH + 32 ? 'mobile' : 'desktop';
  if (isInBackground) {
    return null;
  }
  return <FlexCol width={'full'} height={'100%'} flex={1} {...bind} bg={'contentBg'}>
				{width > 0 && <>
						<DashboardHeader layout={layout} />
						<KnuddelsPlusOrHappyHourBanner />
						<div className={_c0}>
							{width - TOP_ADZONE_WIDTH - SCROLL_THUMB_WIDTH >= SIDE_ADZONE_WIDTH * 2 && <SideAdzone />}
							<div style={{
          alignSelf: resolveThemingValue(layout === 'desktop' ? 'center' : undefined, "theme", useTheme())
        }} className={_c1}>
								{layout === 'desktop' && <TopAdzone />}
								<div style={{
            alignSelf: resolveThemingValue(layout === 'desktop' ? 'center' : undefined, "theme", useTheme()),
            maxWidth: resolveThemingValue(layout === 'desktop' ? 668 : undefined, "sizes", useTheme())
          }} className={_c2}>
									<DashboardTipsElement layout={layout} />
									<div className={_c3}>
										<LoadService service={$ChannelListService}>
											{components => <components.DashboardChannelListElement layout={layout} />}
										</LoadService>
									</div>
									<Spacer size={'base'} />
									<LoadService service={$MessengerComponents}>
										{components => <components.DashboardMessengerListElement layout={layout} />}
									</LoadService>
									{visitorPanelService.shouldShow() && <>
											<Spacer size={'base'} />
											<ProfileVisitorsOverview layout={layout} type={'dashboard'} showTopSpacer={false} />
										</>}
									<Spacer size={'xxlarge'} />
								</div>
							</div>
						</div>
					</>}
			</FlexCol>;
});
const useIsInBackground = () => {
  const viewService = useService($ViewService);
  return useReactiveState(() => {
    return viewService.isViewInBackground(channelViewId);
  }, [viewService]);
};
const TopAdzone: React.FC = observer('TopAdzone', () => {
  const adsService = useService($AdsService);
  const Adzone = adsService.Adzone;
  if (!adsService.areWebAdsVisible || !Adzone) {
    return null;
  }
  return <div style={{
    width: resolveThemingValue(TOP_ADZONE_WIDTH, "sizes", useTheme())
  }} className={_c4}>
			<Adzone adzone={'channelNoConnectionLeaderboard'} fixedSize={90 + 24} />
		</div>;
});
const SideAdzone: React.FC = observer('SideAdzone', () => {
  const adsService = useService($AdsService);
  const Adzone = adsService.Adzone;
  if (!adsService.areWebAdsVisible || !Adzone) {
    return null;
  }
  return <div className={_c5}>
			<Adzone adzone={'channelNoConnectionOffContent'} reloadAd verticalAd />
		</div>;
});
const _c0 = " Knu-ScrollView position-relative flex-1 flexBasis-0-px ";
const _c1 = " Knu-FlexCol ";
const _c2 = " Knu-FlexCol ";
const _c3 = " Knu-FlexCol minHeight-288px ";
const _c4 = " Knu-FlexCol py-base ";
const _c5 = " Knu-FlexCol position-absolute right-large insetY-none alignItems-center ";