const r = (e, t) => 100 / (e * t), a = ({
  containerWidth: e,
  numberOfSlides: t,
  visibleSlides: n,
  translationX: c,
  offset: o
}) => o + c / e * r(t, n), i = (e) => {
  const t = e.translationX / e.containerWidth * 100;
  return Math.abs(t) <= 20 ? e.activeIndex : 0 > t ? e.activeIndex + 1 : e.activeIndex - 1;
}, d = ({
  numberOfSlides: e,
  visibleSlides: t,
  activeIndex: n
}) => -n * r(e, t);
export {
  i as getNewIndex,
  a as getNewOffset,
  r as getPercentagePerChild,
  d as getTranslateX
};
