import { TextModal } from '@shared/components';
import { FlexCol, IconBack } from '@knuddels/component-library';
import * as React from 'react';
import { PhotoDescriptionHasChangesContext, SetEditingPhotoDescriptionContext } from './EditPhotoDescriptionContext';
import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { IconWithShadow } from '@knuddelsModules/Profile/bundle/components/shared/IconWithShadow';
export const LeavePhotoDescriptionEditModeButton: React.FC = () => {
  const leaveEditMode = useLeaveEditMode();
  return <div className={_c0}>
			<IconWithShadow InnerIcon={IconBack} onPress={leaveEditMode} />
		</div>;
};
const useLeaveEditMode = () => {
  const {
    stopEditing
  } = React.useContext(SetEditingPhotoDescriptionContext);
  const openConfirmOverlay = useOpenConfirmOverlay(() => {
    stopEditing();
  });
  const hasChanges = React.useContext(PhotoDescriptionHasChangesContext);
  return () => {
    if (hasChanges) {
      openConfirmOverlay();
    } else {
      stopEditing();
    }
  };
};
const useOpenConfirmOverlay = (onConfirm: () => void) => {
  const overlayService = useService($OverlayService);
  const i18n = useService($I18n);
  return () => {
    const modal = overlayService.showOverlay({
      view: <TextModal pictureProps={{
        source: require('../../assets/sm_abo_12-09_knock-knock.gif'),
        size: 20
      }} headline={i18n.format(declareFormat({
        id: 'album.details.leave-edit-description.confirm.headline',
        defaultFormat: 'Watch out! Your changes will be lost.'
      }))} text={i18n.format(declareFormat({
        id: 'album.details.leave-edit-description.confirm.text',
        defaultFormat: 'Go back and click "Save" to apply your changes.'
      }))} primaryButton={{
        text: i18n.format(declareFormat({
          id: 'album.details.leave-edit-description.confirm.back',
          defaultFormat: 'Back'
        })),
        onClick: () => modal.dispose()
      }} secondaryButton={{
        text: i18n.format(declareFormat({
          id: 'album.details.leave-edit-description.confirm.discard',
          defaultFormat: 'Discard'
        })),
        onClick: () => {
          onConfirm();
          modal.dispose();
        }
      }} cancelModal={() => modal.dispose()} />
    });
  };
};
const _c0 = " Knu-FlexCol position-absolute top-none left-none ";