import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import bigHello from '@shared/icons/icon-bighello.gif';

const textFormat = declareFormat({
	id: 'SNACKBAR_FORWARD_MESSAGE_SUCCESS_TEXT',
	defaultFormat: 'The message has been forwarded.',
});

export const SnackbarDefinitionForwardOneMessageSuccess: SnackbarData = {
	type: 'forwardOneMessageSuccess',
	text: () => formatMessage(textFormat),
	subtext: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_FORWARD_MESSAGE_ONE_SUCCESS_SUBTEXT',
				defaultFormat: "You've sent the message to one person.",
			})
		),
	adornment: bigHello,
};

export function createForwardManyMessageSuccessSnackbar(
	successfulCount: number,
	totalCount: number
): SnackbarData {
	return {
		type: 'forwardManyMessageSuccess_' + successfulCount + '_' + totalCount,
		text: () => formatMessage(textFormat),
		subtext: () =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_FORWARD_MESSAGE_MANY_SUCCESS_SUBTEXT',
					defaultFormat:
						'The message has been sent to {successCount} / {totalCount} people.',
				}),
				{
					successCount: successfulCount,
					totalCount: totalCount,
				}
			),
		adornment: bigHello,
	};
}
