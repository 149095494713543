import { spring as L } from "./spring/index.js";
import { calcGeneratorVelocity as R } from "./utils/velocity.js";
function $({ keyframes: g, velocity: B = 0, power: M = 0.8, timeConstant: b = 325, bounceDamping: k = 10, bounceStiffness: F = 500, modifyTarget: l, min: t, max: a, restDelta: f = 0.5, restSpeed: O }) {
  const s = g[0], n = {
    done: !1,
    value: s
  }, C = (e) => t !== void 0 && e < t || a !== void 0 && e > a, D = (e) => t === void 0 ? a : a === void 0 || Math.abs(t - e) < Math.abs(a - e) ? t : a;
  let c = M * B;
  const d = s + c, u = l === void 0 ? d : l(d);
  u !== d && (c = u - s);
  const p = (e) => -c * Math.exp(-e / b), h = (e) => u + p(e), y = (e) => {
    const o = p(e), G = h(e);
    n.done = Math.abs(o) <= f, n.value = n.done ? u : G;
  };
  let r, i;
  const v = (e) => {
    C(n.value) && (r = e, i = L({
      keyframes: [n.value, D(n.value)],
      velocity: R(h, e, n.value),
      // TODO: This should be passing * 1000
      damping: k,
      stiffness: F,
      restDelta: f,
      restSpeed: O
    }));
  };
  return v(0), {
    calculatedDuration: null,
    next: (e) => {
      let o = !1;
      return !i && r === void 0 && (o = !0, y(e), v(e)), r !== void 0 && e >= r ? i.next(e - r) : (!o && y(e), n);
    }
  };
}
export {
  $ as inertia
};
