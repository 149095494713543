import * as React from 'react';
export const SwipeContext = React.createContext<{
  currentSwipeId: string | null;
  setCurrentSwipeId: (id: string | null) => void;
}>({
  currentSwipeId: null,
  setCurrentSwipeId: () => {}
});
export const SwipeContextProvider: React.FC = ({
  children
}) => {
  const [currentSwipeId, setCurrentSwipeId] = React.useState<string | null>(null);
  const value = React.useMemo(() => ({
    currentSwipeId,
    setCurrentSwipeId
  }), [currentSwipeId]);
  return <SwipeContext.Provider value={value}>{children}</SwipeContext.Provider>;
};