import { jsxs as n, jsx as t } from "react/jsx-runtime";
import * as a from "react";
import { useIsDarkColor as o } from "../Layout/Box/useBg.js";
const d = (e) => {
  const r = a.Children.toArray(e.children);
  return o("transparent"), r.some((l) => !!l) ? /* @__PURE__ */ n(
    "div",
    {
      className: "Knu-FlexCol px-base overflow-visible pt-xlarge",
      "aria-label": e.title,
      role: "group",
      children: [
        e.title && /* @__PURE__ */ t("div", { className: "Knu-Flex ml-base mb-minor", children: /* @__PURE__ */ t("span", { className: "Knu-Text text-body2 text-tertiary bold", children: e.title.toUpperCase() }) }),
        e.subtitle && /* @__PURE__ */ t("div", { className: "Knu-Flex ml-base mb-minor", children: /* @__PURE__ */ t("span", { className: "Knu-Text text-body2 text-secondary", children: e.subtitle }) }),
        /* @__PURE__ */ t(
          "div",
          {
            className: "Knu-FlexCol borderRadius-base overflow-hidden bg-listGroupBg shadow-Shadow1-onLightOnly",
            children: r.map(
              (l, s) => a.cloneElement(l, {
                separator: s < r.length - 1 ? l.props.separator : "none"
              })
            )
          }
        )
      ]
    }
  ) : null;
};
export {
  d as ListGroup
};
