import * as React from 'react';
import { FormattedText, PreviewFormattedTextDisplay, Spacer } from '@shared/components';
import { createSvgIcon, Flex, FlexCol, LineDivider, Text, createAccessiblePointerEventHandler, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import { MentorReward, MentorStatusActiveFragment } from '@generated/graphql';
import { FormattedMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { CenterContentBar } from './shared/CenterContentBar';
import { MentorImage } from './shared/MentorImage';
import { ProgressDisplay } from './shared/ProgressDisplay';
import { IconMentorPoints } from './shared/IconMentorPoints';
import { useTrackContactsMentorList } from './useTrackContactsMentorList';
export const MentorSystemStatusBar: React.FC<{
  activeStatus: MentorStatusActiveFragment;
  onPressMentorStatus(): void;
  onPressNextReward(): void;
  onPressProgressBar(): void;
}> = observer('MentorSystemStatusBar', props => {
  const status = props.activeStatus;
  const track = useTrackContactsMentorList();
  const handlePressMentorStatus = () => {
    track('MentorsystemBarStatusInfoClicked');
    props.onPressMentorStatus();
  };
  const handlePressNextReward = () => {
    track('MentorsystemBarNextRewardClicked');
    props.onPressNextReward();
  };
  const handlePressProgressBar = () => {
    track('MentorsystemBarMentorpointsClicked');
    props.onPressProgressBar();
  };
  return <CenterContentBar {...createAccessiblePointerEventHandler({
    pointerCallback: handlePressMentorStatus
  })} rightAlignedElement={status.nextMentorReward && <NextMentorReward nextMentorReward={status.nextMentorReward} onPress={handlePressNextReward} />}>
			<MentorImage bgSize={48} imageHeight={25} imageWidth={28} />
			<div className={_c0}>
				<div className={_c1}>
					<Text bold={true} className={_c2}>
						<FormattedMessage id={declareFormat({
            id: 'mentorsystem.bar.title',
            defaultFormat: 'Mentorstatus'
          })} />
					</Text>
					<IconInfo size={'base'} />
				</div>
				<Spacer size={'minor'} />
				<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: handlePressProgressBar
      })} className={_c3}>
					<ProgressDisplay value={status.mentorPoints} maxValue={status.mentorLevelBoundary} prevMaxValue={status.previousMentorLevelBoundary} numericValueIcon={IconMentorPoints} />
				</div>
			</div>
		</CenterContentBar>;
});
const IconInfo = createSvgIcon(<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.99984 0.666626C4.40733 0.666626 0.666504 4.40746 0.666504 8.99996C0.666504 13.5925 4.40733 17.3333 8.99984 17.3333C13.5923 17.3333 17.3332 13.5925 17.3332 8.99996C17.3332 4.40746 13.5923 0.666626 8.99984 0.666626ZM8.99984 2.33329C12.6916 2.33329 15.6665 5.30819 15.6665 8.99996C15.6665 12.6917 12.6916 15.6666 8.99984 15.6666C5.30807 15.6666 2.33317 12.6917 2.33317 8.99996C2.33317 5.30819 5.30807 2.33329 8.99984 2.33329ZM8.1665 4.83329V6.49996H9.83317V4.83329H8.1665ZM8.1665 8.16663V13.1666H9.83317V8.16663H8.1665Z" fill="currentColor" />
	</svg>);
IconInfo.displayName = 'InfoIcon';
const NextMentorReward: React.FC<{
  nextMentorReward: Pick<MentorReward, 'icon'>;
  onPress(): void;
}> = props => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: props.onPress
  })} className={_c4}>
			<div className={_c5}>
				<LineDivider className={_c6} />
			</div>
			<div className={_c7}>
				<Text type={'tiny'} state={'tertiary'} className={_c8}>
					<FormattedMessage id={declareFormat({
          id: 'mentorsystem.bar.reward-title',
          defaultFormat: 'Next reward'
        })} />
				</Text>
				<Spacer size={'base'} />
				<div className={_c9}>
					<PreviewFormattedTextDisplay textProps={{}} text={FormattedText.fromJsonString(props.nextMentorReward.icon)} />
				</div>
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol flex-1 alignItems-center ";
const _c1 = " Knu-Flex placeItems-center ";
const _c2 = " mr-tiny ";
const _c3 = " Knu-FlexCol cursor-pointer alignSelf-stretch ";
const _c4 = " Knu-Flex cursor-pointer flex-1 maxWidth-200px minWidth-120px ";
const _c5 = " Knu-FlexCol my-small ";
const _c6 = "  ";
const _c7 = " Knu-FlexCol flex-1 mx-tiny my-small ";
const _c8 = " textAlign-center ";
const _c9 = " Knu-FlexCol alignItems-center ";