import { jsx as d } from "react/jsx-runtime";
import * as I from "react";
import { Virtuoso as x } from "../../deps/react-virtuoso/dist/index.js";
import { clsx as C } from "../../node_modules/clsx/dist/clsx.m.js";
import { useCalculatedEstimatedSize as v } from "./calculateEstimatedSize.js";
import { Box as g } from "../Layout/Box/Box.js";
import { useTheme as S } from "../../themes/useTheme.js";
function y(t) {
  return {
    scrollTo(n) {
      t.scrollTo({ top: n });
    },
    scrollToItem(n, i) {
      t.scrollToIndex({ index: n, align: i });
    }
  };
}
const E = (t) => {
  const n = S(), i = t.gapTop ?? "none", o = t.gapBottom ?? "none";
  return {
    paddingTop: typeof i == "number" ? i : n.spacings[i],
    paddingBottom: typeof o == "number" ? o : n.spacings[o]
  };
};
function H(t) {
  const n = I.useRef(!0), i = v(t.data), { paddingTop: o, paddingBottom: a } = E(t), c = o ? 1 : 0;
  let m = 0;
  return o && m++, a && m++, /* @__PURE__ */ d(
    "div",
    {
      className: C(
        "Knu-FlexCol Knu-VirtualList minHeight-0 minWidth-0 flex-1",
        t.className
      ),
      children: /* @__PURE__ */ d(
        x,
        {
          ...t.virtuosoProps,
          style: {
            touchAction: "pan-y",
            overflowY: t.disableScroll ? "hidden" : "auto",
            overflowX: "hidden"
          },
          ref: (e) => {
            if (t.listRef) {
              const l = e && y(e);
              typeof t.listRef == "function" ? t.listRef(l) : t.listRef.current = l;
            }
          },
          overscan: t.overscanCount || 2,
          totalCount: t.data.length + m,
          itemContent: (e) => o && e === 0 ? /* @__PURE__ */ d(g, { height: o }) : a && e === t.data.length + c ? /* @__PURE__ */ d(g, { height: a }) : t.renderItem({
            item: t.data[e - c],
            index: e - c
          }),
          defaultItemHeight: t.estimatedItemSize ?? i,
          initialTopMostItemIndex: t.initialScrollIndex || 0,
          rangeChanged: t.onRenderedItemsChanged ? (e) => {
            t.onRenderedItemsChanged({
              startIndex: e.startIndex,
              endIndex: e.endIndex
            });
          } : void 0,
          onScroll: (e) => {
            var u, h;
            const l = e.target, r = l.scrollTop;
            (u = t.onScroll) == null || u.call(t, r);
            const f = l.offsetHeight, T = l.scrollHeight, R = (t.onEndReachedThreshold || 0) * f, s = r + f >= T - R;
            s !== n.current && (n.current = s, s && ((h = t.onEndReached) == null || h.call(t)));
          }
        }
      )
    }
  );
}
const V = I.memo(
  H
);
V.displayName = "VirtualList";
export {
  V as VirtualList
};
