import * as React from 'react';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { IconBack, IconCloseCircular, TitleBar } from '@knuddels/component-library';
import { EditProfilePictureOverflowMenuTrigger } from './EditProfilePicture/EditProfilePictureContextMenu';
import { ProfileEditor } from './logic/ProfileEditor';
import { runInAction } from '@knuddels-app/mobx';
import { DeletePhotosIcon } from './DeletePhotosIcon';
interface Props {
  showProfilePictureOverflowIcon?: boolean;
  editor: ProfileEditor;
  fillBackground?: boolean;
  goBack(): void;
}
export const EditProfileTitleBar = injectedComponent({
  name: 'EditProfileTitleBar',
  props: declareProps<Props>(),
  inject: {
    i18n: $I18n
  }
}, ({
  i18n,
  goBack,
  editor,
  showProfilePictureOverflowIcon,
  fillBackground
}) => {
  const editing = editor.selectedPhotoIds.size > 0;
  return <TitleBar editMode={editing} fillBackground={fillBackground} title={editing ? i18n.format(declareFormat({
    id: 'profile.edit.titlebar.photo-selection',
    defaultFormat: '{count} {count, plural, one {photo} other {photos}} selected'
  }), {
    count: editor.selectedPhotoIds.size
  }) : declareFormat({
    id: 'EDIT_PROFILE_TITLE_BAR',
    defaultFormat: 'Edit profile'
  }).format(i18n)} navIcon={editing ? <IconCloseCircular size={'large'} onPress={() => {
    runInAction('clear selected photos', () => editor.selectedPhotoIds.clear());
  }} /> : <IconBack size={'large'} onPress={goBack} />} iconList={editing ? [<DeletePhotosIcon key={'delete-photos'} editor={editor} />] : [showProfilePictureOverflowIcon && <EditProfilePictureOverflowMenuTrigger key={'overflow'} />]} />;
});