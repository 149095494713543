import { isSuccessfulSwipe, useDrag } from '@shared/components';
import { FlexCol, LayoutEvent } from '@knuddels/component-library';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ChannelAppViewer } from '@knuddelsModules/Apps';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { calculateAppScale } from './calculateAppScale';
import { ViewerSharedValues } from './ViewerSharedValues';
import { NativeWebview } from '@knuddels/native-webview-capacitor-plugin';
type GestureStart = {
  x: number;
  y: number;
  time: number;
};
export const AppBackgroundGestureView: React.FC<{
  viewerSharedValues: ViewerSharedValues;
}> = ({
  children,
  viewerSharedValues
}) => {
  const appViewer = useService($ChannelAppViewer);
  const layoutRef = React.useRef<LayoutEvent>(null);
  const handleSuccessfulSwipe = useHandleSuccessfulSwipe(layoutRef, viewerSharedValues);
  const ref = React.useRef<HTMLDivElement>(null);
  const start = React.useRef<GestureStart>();
  const dragState = React.useRef<'x'>();
  const bindDrag = useDrag({
    canStartDrag: ({
      sx,
      sy,
      dx
    }) => {
      const pos = {
        x: sx - layoutRef.current!.pageX,
        y: sy - layoutRef.current!.pageY
      };
      return (
        // prevent interaction in area used for dragging the nicklist because the nicklist dragging is more important
        pos.x < layoutRef.current!.width * 0.9 && Math.abs(dx) > 10 && !isWithinAppBoundary(layoutRef.current.width, layoutRef.current.height, viewerSharedValues, appViewer, pos)
      );
    },
    onStart: ({
      sx,
      sy
    }) => {
      start.current = {
        x: sx - layoutRef.current!.pageX,
        y: sy - layoutRef.current!.pageY,
        time: Date.now()
      };
      dragState.current = undefined;
    },
    onMove: ({
      dx
    }) => {
      if (!dragState.current) {
        dragState.current = decideDragState(dx);
      }
    },
    onFinish: ({
      dx
    }) => {
      if (dragState.current === 'x') {
        handleSuccessfulSwipe(start.current, dx, layoutRef.current!.width);
      }
      dragState.current = undefined;
    }
  });
  return <FlexCol {...bindDrag} innerRef={ref} onLayout={e => {
    layoutRef.current = e;
    if (ref.current) {
      NativeWebview.updateContainerLayout({
        coords: {
          x: ref.current.getBoundingClientRect().x,
          y: ref.current.getBoundingClientRect().y,
          height: ref.current.getBoundingClientRect().height,
          width: ref.current.getBoundingClientRect().width
        }
      });
    }
  }} position={'relative'} flex={1} overflow={'hidden'}>
			{children}
		</FlexCol>;
};
const useHandleSuccessfulSwipe = (layoutRef: React.MutableRefObject<LayoutEvent>, viewerSharedValues: ViewerSharedValues) => {
  const appViewer = useService($ChannelAppViewer);
  const logEvent = useLogEvent();
  return (start: GestureStart, dx: number, width: number) => {
    const deltaTime = Date.now() - start.time;
    if (start.x < width * 0.9 && !isWithinAppBoundary(layoutRef.current.width, layoutRef.current.height, viewerSharedValues, appViewer, start) && isSuccessfulSwipe(dx, deltaTime)) {
      if (dx < 0) {
        appViewer.nextApp();
      } else {
        appViewer.prevApp();
      }
      logEvent('Chat_AppView', 'BackgroundGestures_ChangeApp');
    }
  };
};
function isWithinAppBoundary(containerWidth: number, containerHeight: number, viewerSharedValues: ViewerSharedValues, appViewer: typeof $ChannelAppViewer.T, {
  x,
  y
}: {
  x: number;
  y: number;
}): boolean {
  const app = appViewer.activeApp;
  if (!app) {
    return false;
  }
  if (app.displayType === 'fill') {
    return true;
  }
  const preferredAppWidth = app.displayWidth;
  const preferredAppHeight = app.displayHeight;
  const scale = calculateAppScale(viewerSharedValues, preferredAppWidth, preferredAppHeight);
  const appWidth = preferredAppWidth * scale;
  const appHeight = preferredAppHeight * scale;
  const appX = (containerWidth - appWidth) / 2;
  const appY = (containerHeight - appHeight) / 2;
  return x >= appX && x <= appX + appWidth && y >= appY && y <= appY + appHeight;
}
function decideDragState(diffX: number): 'x' | undefined {
  if (Math.abs(diffX) > 10) {
    return 'x';
  } else {
    return undefined;
  }
}