import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';

export const mentorSystemModule = new Module({
	name: 'Mentor System Module',
	dependencies: [],
	providedServices,
	loadEvent: 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
