import * as React from 'react';
import { ChannelListItem, useRenderChannelListItem } from '../../../../shared/ChannelList/ChannelListVirtualItems';
import { FlexCol, VirtualList } from '@knuddels/component-library';
import { LoadingEntry } from '../../../../shared/LoadingEntry';
import { EmptySearchPlaceholder } from './EmptySearchPlaceholder';
import { NoSearchResultsPlaceholder } from './NoSearchResultsPlaceholder';
import { RecentSearches } from './RecentSearches';
import { useSaveSearchText } from './useSaveSearchText';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export const SearchResults: React.FC<{
  searchText: string;
  data: ChannelListItem[];
  loading: boolean;
  setSearchText: (searchText: string) => void;
}> = ({
  searchText,
  setSearchText,
  data,
  loading
}) => {
  // We need it to create a mutable reference object which always
  // contains the current (latest) value of `searchText`
  const searchTextRef = React.useRef(searchText);
  React.useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);
  const saveSearch = useSaveSearchText();
  const renderChannelListItem = useRenderChannelListItem({
    shouldUseShortDescription: false,
    trackingContentType: 'ChannelSelection_Search',
    onChannelSelected: channelName => {
      // Store the channel name if it contains the search string; otherwise, stores the search string.
      const text = channelName.toLocaleLowerCase().startsWith(searchTextRef.current?.toLocaleLowerCase()) ? channelName : searchTextRef.current;
      saveSearch(text);
    }
  });
  const track = useLogEvent();
  if (loading) {
    return <LoadingEntry />;
  }
  if (data.length === 0) {
    if (searchText.length === 0) {
      return <div className={_c0}>
					<RecentSearches setSearchText={setSearchText} />
					<EmptySearchPlaceholder />
				</div>;
    } else {
      track('ChannelSelection_Search', 'Searchresult_NoResultsShown');
      return <NoSearchResultsPlaceholder />;
    }
  }
  return <VirtualList data={data} renderItem={renderChannelListItem} gapBottom={'xlarge'} className={_c1} />;
};
const _c0 = " Knu-FlexCol ";
const _c1 = "  ";