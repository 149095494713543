import { easeInOut } from '@shared/components/helper/easeInOut';

export function setScrollLeft(component: any, position: 'start' | 'end'): void {
	const newScrollLeft =
		position === 'start'
			? 0
			: component.scrollWidth - component.clientWidth;

	if (component.scrollLeft === newScrollLeft) {
		return;
	}

	const start = component.scrollLeft;
	const change = newScrollLeft - start;
	const increment = 20;
	const duration = 200;

	const animateScroll = (elapsedTime: number) => {
		component.scrollLeft = easeInOut(
			elapsedTime + increment,
			start,
			change,
			duration
		);
		if (elapsedTime < duration) {
			setTimeout(() => {
				animateScroll(elapsedTime + increment);
			}, increment);
		}
	};

	animateScroll(0);
}
