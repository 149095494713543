import * as React from 'react';
import { createAccessiblePointerEventHandler, FlexCol, Image, isTouchDevice, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
interface Props {
  imageUrl: string;
  alt?: string;
  active?: boolean;
  onPress(): void;
}
export const HoverableImage: React.FC<Props> = ({
  onPress,
  alt,
  imageUrl,
  active
}) => {
  const [hovered, setHovered] = React.useState(false);
  const opacity = active ? 1 : isTouchDevice() ? 0.66 : hovered ? 0.66 : 0.33;
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{
    opacity: resolveThemingValue(opacity, "theme", useTheme())
  }} className={_c0}>
			<Image src={imageUrl} alt={alt!} className={_c1} />
		</div>;
};
const _c0 = " Knu-FlexCol cursor-pointer p-base ";
const _c1 = " size-24px ";