import {
	injectable,
	inject,
	$K3Container,
} from '@knuddels-app/DependencyInjection';
import { autorunWhen } from '@knuddels-app/mobx';
import { $AuthService } from './serviceIds';
import { Disposable } from '@knuddels/std';

@injectable()
export class LoggedInContainerBridge implements Disposable {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthService) authService: typeof $AuthService.T,
		@inject($K3Container) k3Container: typeof $K3Container.T
	) {
		this.dispose.track(
			autorunWhen(
				{ name: 'Activate loggedIn container scope on login' },
				() => authService.state.kind === 'loggedIn',
				() => k3Container.setLoggedInState(true)
			)
		);
		this.dispose.track(
			autorunWhen(
				{ name: 'Deactivate loggedIn container scope on logout' },
				() => authService.state.kind === 'loggedOut',
				() => k3Container.setLoggedInState(false)
			)
		);
	}
}
