import { declareFormat } from '@knuddels/i18n';

export const ADD_FRIEND_SUCCESS = declareFormat({
	id: 'add-friend.success',
	defaultFormat: 'Friendship request sent!',
});

export const ADD_FRIEND_NOT_ALLOWED = declareFormat({
	id: 'add-friend.not-allowed',
	defaultFormat: 'You cannot send this user a friend request',
});

export const ADD_FRIEND_SENDER_LIMIT = declareFormat({
	id: 'add-friend.sender-limit',
	defaultFormat: 'Maximum number of friends reached',
});

export const ADD_FRIEND_CONVERSATION_DEPTH_TEXT = declareFormat({
	id: 'add-friend.conversation-depth.text',
	defaultFormat: 'No conversation available',
});

export const ADD_FRIEND_CONVERSATION_DEPTH_SUBTEXT = declareFormat({
	id: 'add-friend.conversation-depth.subtext',
	defaultFormat: 'Start a conversation with this user',
});
