import { rgba as i } from "./rgba.js";
import { isColorString as g } from "./utils.js";
function a(r) {
  let t = "", e = "", n = "", s = "";
  return r.length > 5 ? (t = r.substring(1, 3), e = r.substring(3, 5), n = r.substring(5, 7), s = r.substring(7, 9)) : (t = r.substring(1, 2), e = r.substring(2, 3), n = r.substring(3, 4), s = r.substring(4, 5), t += t, e += e, n += n, s += s), {
    red: parseInt(t, 16),
    green: parseInt(e, 16),
    blue: parseInt(n, 16),
    alpha: s ? parseInt(s, 16) / 255 : 1
  };
}
const u = {
  test: g("#"),
  parse: a,
  transform: i.transform
};
export {
  u as hex
};
