import * as React from 'react';
import { last } from '@shared/components';
import { FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { StarredMessages as StarredMessagesQueryOperation, StarredMessagesQuery, User } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $UserService } from '@knuddelsModules/UserData';
import { StarredMessagesVirtualList } from './StarredMessagesVirtualList';
import { useQuery } from '@knuddels-app/Connection';
@injectable()
class StarredMessagesModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: unknown, @inject($UserService)
  private readonly userService: typeof $UserService.T) {}
  public get currentUser(): Pick<User, 'id' | 'nick'> | undefined {
    return this.userService.currentUser;
  }
}
export const StarredMessages = injectedComponent({
  name: 'StarredMessages',
  model: StarredMessagesModel
}, () => {
  const snackbarService = useService($SnackbarService);
  const {
    data,
    loading,
    error,
    fetchMore
  } = useQuery(StarredMessagesQueryOperation, {
    limit: 20,
    pixelDensity: getPixelRatio()
  });
  React.useEffect(() => {
    if (error) {
      snackbarService.showGenericError();
    }
  }, [error]);
  const doFetchMore = () => {
    if (!data.hasMore) {
      return;
    }
    fetchMore({
      variables: {
        limit: 20,
        before: last(data.messages).message.timestamp
      },
      updateQuery: (previousQueryResult, options) => {
        return mergeQueries(previousQueryResult, options.fetchMoreResult);
      }
    });
  };
  return <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{!error && (!loading || data && data.messages.length > 0) && <StarredMessagesVirtualList messages={data.messages} fetchMore={doFetchMore} />}
			</div>;
});
function mergeQueries(current: StarredMessagesQuery, fetchMoreResult: StarredMessagesQuery | undefined): StarredMessagesQuery {
  const newResult = fetchMoreResult && fetchMoreResult.messenger.starredConversationMessages;
  if (!newResult) {
    return current;
  }
  return {
    ...current,
    messenger: {
      ...current.messenger,
      starredConversationMessages: {
        ...current.messenger.starredConversationMessages,
        messages: [...current.messenger.starredConversationMessages.messages, ...newResult.messages],
        hasMore: fetchMoreResult.messenger.starredConversationMessages.hasMore
      }
    }
  };
}
const _c0 = " Knu-FlexCol position-relative bg-contentBg height-full ";