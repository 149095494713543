import * as React from 'react';
import { ReactNode } from 'react';
import { Flex, FlexCol, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export interface InfoCardItem {
  highlightContent: ReactNode;
  header?: string;
  textContent: ReactNode;
}
export const InfoCard: React.FC<{
  item: InfoCardItem;
}> = ({
  item
}) => {
  return <div className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c1}>
				{item.highlightContent}
			</div>
			<div className={_c2}>
				{item.header && <Text bold={true} className={_c3}>
						{item.header}
					</Text>}

				{item.textContent}
			</div>
		</div>;
};
const _c0 = " Knu-Flex p-minor my-minor alignItems-center borderRadius-minor shadow-Shadow2 bg-contentLightBg ";
const _c1 = " Knu-FlexCol width-40px mr-base ";
const _c2 = " Knu-FlexCol flex-1 ";
const _c3 = " mb-tiny ";