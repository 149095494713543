export interface AuthTokenProvider {
	currentAuthToken?: string | undefined;
	getOrRetrieveAuthToken(): Promise<string | undefined>;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AuthTokenProvider {
	export const empty = constant(undefined);
	export function constant(token: string | undefined): AuthTokenProvider {
		return {
			currentAuthToken: undefined,
			getOrRetrieveAuthToken: async () => token,
		};
	}
}
