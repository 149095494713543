import * as React from 'react';
import { useTrackProfileTabChanged } from '../shared/useTrackProfileTabChanged';
import { ProfileTabsMapping, TabType } from './ProfileTabsMapping';
import { useRef } from 'react';
export const SelectedProfileTabContext = React.createContext<{
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}>({
  activeTabIndex: 0,
  setActiveTabIndex: () => {}
});
export const SelectedProfileTabContextProvider: React.FC<{
  userId: string;
  initialTab?: TabType;
}> = ({
  userId,
  initialTab,
  children
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(ProfileTabsMapping[initialTab] ?? 0);
  const trackTabChange = useTrackProfileTabChanged(userId);
  const prevActiveTabIndex = useRef(activeTabIndex);
  React.useEffect(() => {
    if (prevActiveTabIndex.current !== activeTabIndex) {
      trackTabChange(activeTabIndex);
      prevActiveTabIndex.current = activeTabIndex;
    }
  }, [activeTabIndex]);
  const value = React.useMemo<React.ContextType<typeof SelectedProfileTabContext>>(() => ({
    activeTabIndex,
    setActiveTabIndex
  }), [activeTabIndex, setActiveTabIndex, userId]);
  return <SelectedProfileTabContext.Provider value={value}>
			{children}
		</SelectedProfileTabContext.Provider>;
};