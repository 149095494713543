import { jsx as d } from "react/jsx-runtime";
import { OPTION_HEIGHT as h, OPTION_VERTICAL_PADDING as f } from "./Option.js";
import { ScrollView as g } from "../Layout/Box/ScrollView.js";
import { useAutoScrollToOption as b } from "./useAutoscrollToOption.js";
import { Modal as O } from "../Layout/Modal.js";
import { useScreenSize as u } from "../Layout/hooks/useScreenSize.js";
import { FlexCol as w } from "../Layout/FlexCol.js";
import { useTheme as C } from "../../themes/useTheme.js";
import { Flex as H } from "../Layout/Flex.js";
const v = (o) => {
  const { y: i, x: n, height: e, width: t } = o.anchorCoords, {
    dropdownHeight: l,
    visibleOptions: r
  } = m(
    o.screenSize.height - (i + e),
    o.preferredOptionCount,
    o.containerPadding
  ), s = {
    position: { top: i + e, left: n, width: t },
    visibleOptions: r,
    dropdownHeight: l
  };
  if (r >= o.preferredOptionCount)
    return s;
  const {
    dropdownHeight: p,
    visibleOptions: c
  } = m(
    i,
    o.preferredOptionCount,
    o.containerPadding
  ), a = {
    position: {
      bottom: o.screenSize.height - i,
      left: n,
      width: t
    },
    visibleOptions: c,
    dropdownHeight: p
  };
  return c >= o.preferredOptionCount ? a : Math.max(
    r,
    c
  ) === r ? s : a;
}, m = (o, i, n) => {
  for (let e = i; e > 0; e--) {
    const t = e * (h + f * 2) + n * 2;
    if (t < o)
      return { dropdownHeight: t, visibleOptions: e };
  }
  return {
    dropdownHeight: h + f * 2 + n * 2,
    visibleOptions: 1
  };
}, B = (o) => {
  const i = C(), n = u(), e = i.spacings.minor, t = Math.min(
    o.totalOptions,
    o.maxVisibleOptions
  ), { dropdownHeight: l, position: r, visibleOptions: s } = v({
    anchorCoords: o.anchorCoords,
    preferredOptionCount: t,
    containerPadding: e,
    screenSize: n
  }), p = b({
    optionHeight: h,
    visibleOptions: s,
    optionIndex: o.scrollToIndex
  });
  return /* @__PURE__ */ d(
    O,
    {
      backgroundStyle: "transparent",
      onPress: o.onBackdropPressed,
      closeCallbackType: "onPress",
      children: /* @__PURE__ */ d(
        H,
        {
          my: "tiny",
          position: "absolute",
          maxHeight: l,
          overflow: "hidden",
          shadow: "Shadow2",
          borderRadius: "base",
          ...r,
          children: /* @__PURE__ */ d(
            g,
            {
              innerRef: p,
              flex: 1,
              borderRadius: "base",
              bg: "inputFieldBg",
              children: /* @__PURE__ */ d(w, { py: e, children: o.children })
            }
          )
        }
      )
    }
  );
};
export {
  B as DropdownContent,
  v as useDropdownPosition
};
