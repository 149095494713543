import * as React from 'react';
import { Column } from '../../../atoms/Layouts/Column/Column';
import { ModalContentWrapperCenterStyle, ModalContentWrapperStyle } from './Modal_styles';
export class ModalContentWrapper extends React.PureComponent<{
  alignCenter?: boolean;
}> {
  render(): JSX.Element {
    const styles = this.props.alignCenter ? ModalContentWrapperCenterStyle : ModalContentWrapperStyle;
    return <Column {...styles}>{this.props.children}</Column>;
  }
}