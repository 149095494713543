import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { Adzone } from '../components/Adzone';
import { loginInit } from '../adSetup';
import { $UserService } from '@knuddelsModules/UserData';
import { CapacitorAdzone } from '../components/CapacitorAdzone';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

@injectable()
export class InternalAdsService {
	public readonly Adzone = Adzone;
	public readonly CapacitorAdzone = CapacitorAdzone;

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject.lazy($UserService)
		getUserService: typeof $UserService.TLazy
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		getUserService().then(service => {
			service.getCurrentUser().then(user => loginInit(user));
		});
	}
}
