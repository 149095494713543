import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService, $K3Firebase } from './serviceId';
import { K3Firebase } from './K3Firebase';
import { FirebaseAnalyticsService } from './FirebaseAnalyticsService';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope.bind($K3Firebase).toSingletonAutostart(K3Firebase);
	ctx.globalScope
		.bind($FirebaseAnalyticsService)
		.toSingleton(FirebaseAnalyticsService);
}
