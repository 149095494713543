import * as React from 'react';
import { FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { AdsLabel } from './shared/AdsLabel';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import { observer } from '@knuddels-app/mobx';
import { CAPACITOR_BADGE_HEIGHT, CAPACITOR_LABEL_HEIGHT, CapacitorBanners, CapacitorBannerType } from '@knuddelsModules/Ads';
import { getSafeAreaBottomHeight } from '@shared/helper/getSafeArea';
export const CapacitorAdzone: React.FC<{
  bannerId: CapacitorBannerType;
  hideRemoveAdsBadge?: true;
  location?: 'top' | 'bottom';
  adVisible: boolean;
}> = observer('CapacitorAdzone', props => {
  const AATKitCordova = ((window as any).AATKitCordova as any);
  const screenService = useService($ScreenService);
  const placementName = props.bannerId;
  const alignment = props.location === 'bottom' ? 'BottomCenter' : 'TopCenter';
  React.useEffect(() => {
    return () => {
      if (props.adVisible) {
        AATKitCordova.setPlacementAlignment(placementName, alignment);
      }
    };
  }, [screenService.isLandscape]);
  React.useEffect(() => {
    if (props.adVisible) {
      AATKitCordova.addPlacementToView(placementName);
      AATKitCordova.setPlacementAlignment(placementName, alignment);
      AATKitCordova.setPlacementContentGravity(placementName, 'Center');
      AATKitCordova.reloadPlacementForced(placementName, true, () => {});
    }
    return () => {
      if (props.adVisible) {
        AATKitCordova.removePlacementFromView(placementName);
      }
    };
  }, [props.adVisible]);
  const additionalHeight = props.hideRemoveAdsBadge ? CAPACITOR_LABEL_HEIGHT : CAPACITOR_BADGE_HEIGHT;
  const safeAreaHeight = props.location === 'bottom' ? getSafeAreaBottomHeight() : 0;
  return <div style={{
    width: resolveThemingValue(CapacitorBanners[props.bannerId][0], "sizes", useTheme()),
    height: resolveThemingValue(CapacitorBanners[props.bannerId][1] + additionalHeight + safeAreaHeight, "sizes", useTheme())
  }} className={_c0 + (props.location === 'bottom' ? _c1 : _c2)}>
			<div style={{
      height: resolveThemingValue(additionalHeight, "sizes", useTheme())
    }} className={_c3}>
				<AdsLabel isBottomAd={props.location === 'bottom'} />
			</div>
		</div>;
});
const _c0 = " Knu-FlexCol position-relative alignSelf-center minHeight-0-px ";
const _c1 = " justifyContent-flex-start ";
const _c2 = " justifyContent-flex-end ";
const _c3 = " Knu-FlexCol width-full ";