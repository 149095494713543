import * as inversify from 'inversify';
import { ServiceId } from './ServiceId';

export type ServiceIdMap = Record<string, ServiceId<any>>;

export type TranslateServiceIds<TServiceIds extends ServiceIdMap> = {
	[TKey in keyof TServiceIds]: TServiceIds[TKey]['T'];
};

export function getServices<TServiceIds extends ServiceIdMap>(
	serviceIds: TServiceIds,
	container: inversify.interfaces.Container
): TranslateServiceIds<TServiceIds> {
	const services: Record<string, any> = {};

	for (const [key, value] of Object.entries(serviceIds)) {
		const service = container.get(value.id);
		services[key] = service;
	}
	return services as any;
}
