// TODO: move to a separate file
import * as React from 'react';
import { Spacer } from '@shared/components';
import { ChildThemeProvider, Flex, FlexCol, LineDivider, Text, useChildTheme, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { NormalizedNavBarCategory } from '@knuddelsModules/LoggedInArea/bundle/services';
const LINE_HEIGHT = 16;
export const CategoryWrapper: React.FC<{
  children: React.ReactNode;
  category: NormalizedNavBarCategory;
  collapsed?: boolean;
}> = ({
  children,
  category,
  collapsed
}) => {
  return <div className={_c0}>
			<div className={_c1}>
				{/*TODO: add animation to make transition smoother */}
				{collapsed ? <CategoryDivider /> : <CategoryLabel text={category.name} />}
			</div>
			{children}
		</div>;
};
const CategoryLabel: React.FC<{
  text: string;
}> = ({
  text
}) => {
  const customTheme = useChildTheme({
    sizes: {
      texts: {
        subtitle: {
          fontFamily: 'Source Sans Pro',
          fontSize: 12,
          lineHeight: LINE_HEIGHT,
          letterSpacing: 1
        }
      }
    }
  }, []);
  return <ChildThemeProvider theme={customTheme}>
			<div className={_c2}>
				<Spacer size={'base'} />
				<Text type={'subtitle'} state={'disabled'} selectable={false} className={_c3}>
					{text.toUpperCase()}
				</Text>
				<Spacer size={'base'} />
			</div>
		</ChildThemeProvider>;
};
const CategoryDivider: React.FC = () => {
  return <div style={{
    height: resolveThemingValue(LINE_HEIGHT, "sizes", useTheme())
  }} className={_c4}>
			<LineDivider color={'white-transparent-110'} className={_c5} />
		</div>;
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex py-small ";
const _c2 = " Knu-Flex ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol width-full px-base alignSelf-stretch ";
const _c5 = "  ";