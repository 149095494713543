import { Flex, TITLE_BAR_HEIGHT, ThemeColors, Z_INDEX, isTouchDevice, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { useDrag } from '@use-gesture/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import styles from './TransitionView.module.scss';
import { getSafeAreaTopHeight } from '@shared/helper/getSafeArea';
export interface TransitionViewProps {
  zIndex?: number;
  bg?: ThemeColors;
  transition: 'slideUp' | 'fade';
  hide?: boolean;
  leave?: {
    onFinished?(): void;
  };
  onSwipeDown: () => void;
  allowSwipe: boolean;
  isSystemApp?: boolean;
}
export const TransitionView: React.FC<TransitionViewProps> = props => {
  // outer view is needed to prevent scrollbar from appearing when moving the animated view out
  const AnimatedView = props.transition === 'fade' ? OpacityView : SlidingView;
  return <div style={{
    background: resolveThemingValue(props.bg, "colors", useTheme()),
    zIndex: resolveThemingValue(props.zIndex, "theme", useTheme())
  }} className={_c0 + (props.bg ? resolveIsDarkColor(props.bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<AnimatedView {...props} />
		</div>;
};
const SlidingView: React.FC<TransitionViewProps> = props => {
  const domRef = React.useRef<HTMLDivElement | null>(null);
  const dragActiveRef = React.useRef(false);
  const hide = props.hide;
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (hide && domRef.current) {
      domRef.current!.style.animation = 'none';
      const animation = domRef.current.animate({
        transform: `translateY(100%)`
      }, {
        duration: Math.min(domRef.current.offsetHeight, 300),
        fill: 'forwards',
        easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
      });
      animation.addEventListener('finish', () => {
        props.leave?.onFinished?.();
      }, {
        once: true
      });
    } else if (!hide && domRef.current && !isFirstRender.current) {
      domRef.current.style.animation = 'none';
      domRef.current.animate({
        transform: `translateY(0%)`
      }, {
        duration: Math.min(domRef.current.offsetHeight, 300),
        fill: 'forwards',
        easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
      });
    }
    isFirstRender.current = false;
  }, [hide]);
  const bind = useDrag(({
    xy,
    velocity,
    movement: [, my],
    dragging,
    first,
    target
  }) => {
    const domElement = domRef.current;
    if (!domElement) {
      return;
    }
    const translateY = Math.max(0, my);
    if (first) {
      dragActiveRef.current = xy[1] - getSafeAreaTopHeight() < TITLE_BAR_HEIGHT || (target as HTMLDivElement)?.className?.includes?.('transition-view-target');
      domElement.style.animation = 'none';
    }
    if (!dragActiveRef.current) {
      return;
    }
    domElement.style.transform = `translateY(${translateY}px)`;
    if (!dragging) {
      if (translateY > 92) {
        const animation = domElement.animate({
          transform: `translateY(100%)`
        }, {
          duration: Math.min((domElement.offsetHeight - translateY) / Math.abs(velocity[1]), 300),
          fill: 'forwards',
          easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
        });
        animation.addEventListener('finish', () => {
          props.onSwipeDown();
          props.leave?.onFinished?.();
        }, {
          once: true
        });
      } else {
        const animation = domElement.animate({
          transform: 'translateY(0%)'
        }, {
          duration: 200,
          fill: 'forwards',
          easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
        });
        animation.addEventListener('finish', () => {
          if (domRef.current) {
            domRef.current.style.transform = 'translateY(0%)';
            domRef.current.style.animation = 'none';
          }
          animation.cancel();
        }, {
          once: true
        });
      }
    }
  }, {
    axis: 'y',
    enabled: props.allowSwipe && isTouchDevice()
  });
  return <div ref={domRef} {...props.isSystemApp ? {} : bind()} className={clsx(styles['Knu-SlideUpView'])} style={{
    touchAction: props.isSystemApp ? undefined : 'none'
  }}>
			{props.children}
			{props.isSystemApp && <div {...bind()} style={{
      position: 'absolute',
      top: 0,
      left: 48,
      width: 150,
      height: TITLE_BAR_HEIGHT,
      touchAction: 'none',
      zIndex: Z_INDEX.ABOVE_MODAL
    }} />}
		</div>;
};
const OpacityView: React.FC<TransitionViewProps> = props => {
  const animationInitializedRef = React.useRef(false);
  const opacityVariant = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        ease: [1, 0, 0.8, 1]
      }
    }
  };
  React.useLayoutEffect(() => {
    if (!animationInitializedRef.current) {
      animationInitializedRef.current = true;
    }
  }, []);
  const initialVariant = animationInitializedRef.current ? 'visible' : 'hidden';
  const animateVariant = props.hide ? 'hidden' : 'visible';
  return <motion.div style={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }} variants={opacityVariant} initial={initialVariant} animate={animateVariant} onAnimationComplete={() => {
    if (props.hide && props.leave?.onFinished) {
      props.leave.onFinished();
    }
  }}>
			{props.children}
		</motion.div>;
};
const _c0 = " Knu-Flex position-absolute top-none left-none right-none bottom-none ";