import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';
import sorry from '@shared/images/sorry.gif';

export function createChangeIgnoreStateProtectedUserSnackbar(
	action: 'block' | 'ignore'
): SnackbarData {
	return {
		type: 'changeIgnoreStateProtectedUser_' + action,
		text: () => {
			return formatMessage(
				action === 'block'
					? declareFormat({
							id: 'SNACKBAR_BLOCK_PROTECTED_USER_TEXT',
							defaultFormat: "You can't block this user",
						})
					: declareFormat({
							id: 'SNACKBAR_IGNORE_PROTECTED_USER_TEXT',
							defaultFormat: "You can't ignore this user",
						})
			);
		},
		adornment: sorry,
	};
}
