import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { mergeStyles } from '../../../helper';
import { Button } from '../Button/Button';
import { Column } from '../../Layouts';
import { SpinnerButtonProps } from './SpinnerButtonProps';
import { center } from '../../../sharedComponentStyles';
import { TouchableEvent } from '../Touchables/TouchableProps';
import { Spinner } from '../../Spinners/Spinner';
interface State {
  autoSpinning?: boolean;
  height?: number;
  width?: number;
}
export class SpinnerButton extends React.PureComponent<SpinnerButtonProps, State> {
  private timer: any;
  private buttonRef = React.createRef<Button>();
  constructor(props: SpinnerButtonProps) {
    super(props);
    this.state = {};
  }
  private handleClick = (event: TouchableEvent): void => {
    if (this.props.autoSpinFor !== undefined) {
      // don't reset spin if <= 0
      this.setSize();
      this.setState({
        autoSpinning: true
      });
      if (this.props.autoSpinFor > 0) {
        // don't reset spin if <= 0
        this.timer = setTimeout(() => this.setState({
          autoSpinning: false
        }), this.props.autoSpinFor);
      }
    }
    this.props.onClick(event);
  };
  componentWillUnmount(): void {
    clearTimeout(this.timer);
  }
  UNSAFE_componentWillReceiveProps(newProps: SpinnerButtonProps): void {
    if (!this.props.spinning && newProps.spinning) {
      this.setSize();
    }
  }
  public setSize = (): void => {
    // might return a Text node that doesn't have clientHeight/clientWidth
    // eslint-disable-next-line react/no-find-dom-node
    const node = (ReactDOM.findDOMNode(this.buttonRef.current) as Element);
    if (node) {
      const {
        clientHeight,
        clientWidth
      } = node;
      this.setState({
        height: clientHeight,
        width: clientWidth
      });
    }
  };
  public focus = (): void => {
    if (this.buttonRef.current) {
      this.buttonRef.current.focus();
    }
  };
  render(): JSX.Element {
    const {
      spinning,
      ...restProps
    } = this.props;
    if (!spinning && !this.state.autoSpinning) {
      return <Button ref={this.buttonRef} {...restProps} onClick={this.handleClick} />;
    } else {
      // default 16 in case the button is initially spinning
      // TODO correctly set default spinner size according to Button size prop...
      const defaultSize = 16;
      const size = this.state.height !== undefined ? this.state.height / 2 : defaultSize;
      // TODO get it to work on Internet Explorer & native
      return <Column {...mergeStyles([center], {
        width: this.state.width,
        height: this.state.height
      })}>
					<Spinner size={size} />
				</Column>;
    }
  }
}