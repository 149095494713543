import { jsx as s } from "react/jsx-runtime";
import { toKeyboardHandler as c } from "../Layout/CommonTypes/toKeyboardHandler.js";
import "./StyledInput.scss.js";
import { clsx as f } from "../../node_modules/clsx/dist/clsx.m.js";
const k = (e) => {
  const {
    onPress: r,
    label: i,
    readonly: u,
    keyboardType: y,
    onKeyPress: b,
    size: C,
    hideCursor: m,
    labelStyle: g,
    adornmentStart: h,
    adornmentEnd: K,
    innerRef: S,
    pl: l,
    pr: o,
    focused: p,
    ...n
  } = e;
  return /* @__PURE__ */ s(
    "input",
    {
      ...n,
      className: f(
        "Knu-StyledInput",
        m && "hideCursor",
        l && `pl-${l}`,
        o && `pr-${o}`,
        n == null ? void 0 : n.className
      ),
      onKeyDown: c(b),
      "aria-label": i,
      "aria-labelledby": e.ariaLabelledBy,
      "aria-disabled": e.disabled,
      onChange: (a) => {
        var t;
        return (t = e.onChange) == null ? void 0 : t.call(e, { value: a.target.value });
      },
      readOnly: u,
      inputMode: y,
      onClick: (a) => {
        a.stopPropagation(), r == null || r();
      },
      onPointerMove: (a) => {
        var t, d;
        a.stopPropagation(), (d = (t = a.target).setPointerCapture) == null || d.call(t, a.pointerId);
      },
      ref: e.innerRef
    }
  );
};
export {
  k as StyledInput
};
