import { Flex, FlexCol, IconClose, LineDivider, Text } from '@knuddels/component-library';
import * as React from 'react';
import { PhotoComments } from './PhotoComments';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PhotoCommentsInputBar } from './PhotoCommentsInputBar';
export const MobilePhotoCommentsWrapper: React.FC<{
  onClose: () => void;
}> = ({
  onClose
}) => {
  return <div className={_c0}>
			<div className={_c1}>
				<Text type={'body2'} state={'secondary'} bold={true} className={_c2}>
					<FormattedMessage id={declareFormat({
          id: 'album.details.comments',
          defaultFormat: 'Photo comments'
        })} />
				</Text>
				<IconClose onPress={onClose} />
			</div>
			<LineDivider className={_c3} />
			<div className={_c4}>
				<PhotoComments />
			</div>
			<PhotoCommentsInputBar />
		</div>;
};
const _c0 = " Knu-FlexCol position-relative width-100-percent height-100-percent borderTopRadius-xlarge ";
const _c1 = " Knu-Flex py-small pl-base pr-minor justifyContent-space-between alignItems-center ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = " Knu-Flex position-relative flex-1 maxHeight-200px minHeight-0-px ";