import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const n = r(
  /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", children: /* @__PURE__ */ o(
    "path",
    {
      fill: "currentColor",
      d: "M 20.261719 12.949219 L 13.109375 8.183594 C 12.4375 7.734375 11.5625 7.734375 10.890625 8.183594 L 3.742188 12.949219 C 3.277344 13.257812 3 13.777344 3 14.332031 C 3 15.660156 4.480469 16.453125 5.585938 15.71875 L 12 11.441406 L 18.414062 15.71875 C 19.519531 16.453125 21 15.660156 21 14.332031 C 21 13.777344 20.722656 13.257812 20.261719 12.949219 Z M 20.261719 12.949219 "
    }
  ) })
);
export {
  n as IconChevronUp
};
