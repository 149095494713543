import { ErrorWithDocsReference as v } from "../../../docs/helper.js";
import { enhanceWithModifier as R } from "./enhanceWithModifier.js";
import { AriaPropMap as k } from "../../../a11y/accessibilityProps.js";
const u = [
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
  "focus",
  "hover",
  "dark"
], b = {
  /**
   * Sets the margin on all sides.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  m: "margin",
  /**
   * Sets the margin on the top.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin-top
   */
  mt: "margin-top",
  /**
   * Sets the margin on the bottom.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin-bottom
   */
  mb: "margin-bottom",
  /**
   * Sets the margin on the right.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin-right
   */
  mr: "margin-right",
  /**
   * Sets the margin on the left.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin-left
   */
  ml: "margin-left",
  /**
   * Sets the margin on the left and right.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  mx: ["margin-left", "margin-right"],
  /**
   * Sets the margin on the top and bottom.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  my: ["margin-top", "margin-bottom"]
}, C = {
  /**
   * Sets the padding on all sides.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  p: "padding",
  /**
   * Sets the padding on the top.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding-top
   */
  pt: "padding-top",
  /**
   * Sets the padding on the bottom.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding-bottom
   */
  pb: "padding-bottom",
  /**
   * Sets the padding on the right.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding-right
   */
  pr: "padding-right",
  /**
   * Sets the padding on the left.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding-left
   */
  pl: "padding-left",
  /**
   * Sets the padding on the left and right.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  px: ["padding-left", "padding-right"],
  /**
   * Sets the padding on the top and bottom.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  py: ["padding-top", "padding-bottom"]
}, S = {
  ...C,
  ...b
}, j = {
  /**
   * The background color of the component.
   * Text and icon components will calculate their color based on this value.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-color
   */
  bg: "background"
}, A = {
  /**
   * The flex CSS shorthand property sets how a flex item will grow or shrink to fit the space available in its flex container.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex
   */
  flex: "flex",
  /**
   * The flex-grow CSS property sets the flex grow factor of a flex item's main size.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow
   */
  flexGrow: "flex-grow",
  /**
   * The flex-shrink CSS property sets the flex shrink factor of a flex item. If the size of all flex items is larger than the flex container, items shrink to fit according to flex-shrink.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex-shrink
   */
  flexShrink: "flex-shrink"
}, B = {
  /**
   * The flex-basis CSS property sets the initial main size of a flex item. It sets the size of the content box unless otherwise set with box-sizing.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex-basis
   */
  flexBasis: "flex-basis"
}, W = {
  /**
   * The flex-direction CSS property sets how flex items are placed in the flex container defining the main axis and the direction (normal or reversed).
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction
   */
  flexDirection: "flex-direction"
}, z = {
  /**
   * The align-items CSS property sets the align-self value on all direct children as a group. In Flexbox, it controls the alignment of items on the Cross Axis. In Grid Layout, it controls the alignment of items on the Block Axis within their grid area.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/align-items
   */
  alignItems: "align-items",
  /**
   * The CSS justify-self property sets the way a box is justified inside its alignment container along the appropriate axis.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/justify-self
   */
  justifySelf: "justify-self",
  /**
   * The align-self CSS property overrides a grid or flex item's align-items value. In Grid, it aligns the item inside the grid area. In Flexbox, it aligns the item on the cross axis.
   */
  alignSelf: "align-self",
  /**
   * The CSS justify-content property defines how the browser distributes space between and around content items along the main-axis of a flex container, and the inline axis of a grid container.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content
   */
  justifyContent: "justify-content",
  /**
   * The CSS justify-items property defines the default justify-self for all items of the box, giving them all a default way of justifying each box along the appropriate axis.
   */
  justifyItems: "justify-items",
  /**
   * The CSS align-content property sets the distribution of space between and around content items along a flexbox's cross-axis or a grid's block axis.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/align-content
   */
  alignContent: "align-content",
  /**
   * Applies flex layout on both axes. E.g. placeItems="center" will apply justifyContent="center" and alignItems="center"
   */
  placeItems: ["align-items", "justify-content"]
}, I = {
  /**
   * The display CSS property sets whether an element is treated as a block or inline element and the layout used for its children, such as flow layout, grid or flex.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/display
   */
  display: "display"
}, T = {
  /**
   * The visibility CSS property shows or hides an element without changing the layout of a document.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/visibility
   */
  visibility: "visibility"
}, $ = {
  /**
   * The position CSS property sets how an element is positioned in a document. The top, right, bottom, and left properties determine the final location of positioned elements.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/position
   */
  position: "position"
}, L = {
  /**
   * The overflow CSS property sets what to do when an element's content is too big to fit in its block formatting context.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/overflow
   */
  overflow: "overflow"
}, P = {
  /**
   * @deprecated For theming purposes, prefer to use borderWidth, borderStyle, and borderColor instead.
   * The border CSS property is a shorthand that sets the values of border-width, border-style, and border-color.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border
   */
  border: "border",
  /**
   * The border-left shorthand CSS property sets all the properties of an element's left border.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-left
   */
  borderLeft: "border-left",
  /**
   * The border-right shorthand CSS property sets all the properties of an element's right border.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-right
   */
  borderRight: "border-right",
  /**
   * The border-top shorthand CSS property sets all the properties of an element's top border.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top
   */
  borderBottom: "border-bottom",
  /**
   * The border-bottom shorthand CSS property sets all the properties of an element's bottom border.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom
   */
  borderTop: "border-top"
}, D = {
  /**
   * The border-width CSS property sets the width of the border of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-width
   */
  borderWidth: "border-width"
}, O = {
  /**
   * The border-color CSS property sets the color of an element's border.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-color
   */
  borderColor: "border-color"
}, E = {
  /**
   * The border-style CSS property sets the line style of an element's four borders.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-style
   */
  borderStyle: "border-style"
}, G = {
  /**
   * The border-radius CSS property rounds the corners of an element's outer border edge.
   *
   * bordeRadius="circle" will only work if the element's width and height are equal.
   * Use the size property for this purpose.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius
   */
  borderRadius: "border-radius",
  /**
   * The border-top-left-radius CSS property rounds the top-left corner of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-left-radius
   */
  borderTopLeftRadius: "border-top-left-radius",
  /**
   * The border-top-right-radius CSS property rounds the top-right corner of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-right-radius
   */
  borderTopRightRadius: "border-top-right-radius",
  /**
   * The border-bottom-right-radius CSS property rounds the bottom-right corner of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-right-radius
   */
  borderBottomLeftRadius: "border-bottom-left-radius",
  /**
   * The border-bottom-left-radius CSS property rounds the bottom-left corner of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-left-radius
   */
  borderBottomRightRadius: "border-bottom-right-radius",
  /**
   * Sets both border-top-left-radius and border-top-right-radius.
   */
  borderTopRadius: ["border-top-left-radius", "border-top-right-radius"],
  /**
   * Sets both border-bottom-left-radius and border-bottom-right-radius.
   */
  borderBottomRadius: [
    "border-bottom-left-radius",
    "border-bottom-right-radius"
  ]
}, F = {
  /**
   * The transform CSS property lets you rotate, scale, skew, or translate an element. It modifies the coordinate space of the CSS visual formatting model.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform
   */
  transform: "transform",
  /**
   * The transform-origin CSS property lets you modify the origin for transformations of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin
   */
  transformOrigin: "transform-origin"
}, K = {
  /**
   * The cursor CSS property sets the type of mouse cursor, if any, to show when the mouse pointer is over an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
   */
  cursor: "cursor"
}, H = {
  /**
   * The z-index CSS property sets the z-order of a positioned element and its descendants or flex items. Overlapping elements with a larger z-index cover those with a smaller one.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/z-index
   */
  zIndex: "z-index"
}, U = {
  /**
   * The text-align CSS property sets the horizontal alignment of a block element or table-cell box.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/text-align
   */
  textAlign: "text-align"
}, V = {
  /**
   * The height CSS property specifies the height of an element.
   * Prefer defined keys such as "full" for 100% or values without units, e.g. 100 instead of 100px.
   *
   * If width and height are equal prefer the size property.
   */
  height: "height",
  /**
   * The width CSS property specifies the width of an element.
   * Prefer defined keys such as "full" for 100% or values without units, e.g. 100 instead of 100px.
   *
   * If width and height are equal prefer the size property.
   */
  width: "width",
  /**
   * The min-height CSS property specifies the minimum height of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/min-height
   */
  minHeight: "min-height",
  /**
   * The max-height CSS property specifies the maximum height of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/max-height
   */
  maxHeight: "max-height",
  /**
   * The min-width CSS property specifies the minimum width of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/min-width
   */
  minWidth: "min-width",
  /**
   * The max-width CSS property specifies the maximum width of an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/max-width
   */
  maxWidth: "max-width",
  /**
   * Sets the height and width to the same value. E.g. size="100" will apply height="100" and width="100"
   * Prefer defined keys such as "full" for 100% or values without units, e.g. 100 instead of 100px.
   */
  size: ["height", "width"],
  /**
   * Sets the min-height and min-width to the same value. E.g. minSize="100" will apply min-height="100" and min-width="100"
   * Prefer defined keys such as "full" for 100% or values without units, e.g. 100 instead of 100px.
   */
  minSize: ["min-height", "min-width"],
  /**
   * Sets the max-height and max-width to the same value. E.g. maxSize="100" will apply max-height="100" and max-width="100"
   * Prefer defined keys such as "full" for 100% or values without units, e.g. 100 instead of 100px.
   */
  maxSize: ["max-height", "max-width"]
}, X = {
  /**
   * The top CSS property participates in specifying the vertical position of a positioned element. It has no effect on non-positioned elements.
   * If all positions are set, prefer to use the inset property.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/top
   */
  top: "top",
  /**
   * The bottom CSS property participates in specifying the vertical position of a positioned element. It has no effect on non-positioned elements.
   * If all positions are set, prefer to use the inset property.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/bottom
   */
  bottom: "bottom",
  /**
   * The left CSS property participates in specifying the horizontal position of a positioned element. It has no effect on non-positioned elements.
   * If all positions are set, prefer to use the inset property.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/left
   */
  left: "left",
  /**
   * The right CSS property participates in specifying the horizontal position of a positioned element. It has no effect on non-positioned elements.
   * If all positions are set, prefer to use the inset property.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/right
   */
  right: "right"
}, Y = {
  /**
   * Will apply top, bottom, left and right
   * It has no effect on non-positioned elements.
   */
  inset: ["top", "bottom", "left", "right"],
  /**
   * Will apply top and bottom
   * It has no effect on non-positioned elements.
   */
  insetY: ["top", "bottom"],
  /**
   * Will apply left and right
   * It has no effect on non-positioned elements.
   */
  insetX: ["left", "right"]
}, q = {
  /**
   * The box-shadow CSS property adds shadow effects around an element's frame.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow
   */
  shadow: "box-shadow"
}, J = {
  /**
   * The background-image CSS property sets one or more background images on an element.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-image
   */
  backgroundImage: "background-image"
}, Q = {
  /**
   * The touch-action CSS property sets how an element's region can be manipulated by a touchscreen user (for example, by zooming features built into the browser).
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action
   */
  touchAction: "touch-action"
}, Z = {
  /**
   * The pointer-events CSS property sets under what circumstances (if any) a particular graphic element can become the target of pointer events.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
   */
  pointerEvents: "pointer-events"
}, _ = {
  /**
   * The opacity CSS property sets the transparency of an element or the degree to which content behind an element is hidden.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/opacity
   */
  opacity: "opacity"
}, N = {
  /**
   * The grid-template-columns CSS property defines the line names and track sizing functions of the grid columns.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
   */
  gridTemplateCols: "grid-template-columns",
  /**
   * The grid-template-rows CSS property defines the line names and track sizing functions of the grid rows.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows
   */
  gridTemplateRows: "grid-template-rows",
  col: "grid-column",
  row: "grid-row"
}, tt = {
  colSpan: "grid-column",
  rowSpan: "grid-row"
}, ot = {
  /**
   * The aspect-ratio CSS property sets a preferred aspect ratio for the box, which will be used in the calculation of auto sizes and some other layout functions.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio
   */
  aspectRatio: "aspect-ratio"
}, et = {
  /**
   * The row-gap CSS property sets the size of the gap (gutter) between an element's grid rows.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-row-gap
   */
  rowGap: "grid-row-gap",
  /**
   * The column-gap CSS property sets the size of the gap (gutter) between an element's columns.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column-gap
   */
  columnGap: "grid-column-gap",
  /**
   * The gap CSS property sets the gaps (gutters) between rows and columns. It is a shorthand for row-gap and column-gap.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/gap
   */
  gap: "gap"
}, st = {
  /**
   * The flex-wrap CSS property sets whether flex items are forced onto one line or can wrap onto multiple lines. If wrapping is allowed, it sets the direction that lines are stacked.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex-wrap
   */
  flexWrap: "flex-wrap"
}, nt = [
  "px",
  "%",
  "auto",
  "vh",
  "vw",
  "min-content",
  "max-content",
  "em",
  "rem"
], it = (t) => typeof t == "string" ? nt.some((s) => t.indexOf(s) >= 0) : !1, rt = (t, s, i, r, a, c) => {
  if (!t[i])
    return null;
  let d;
  return Array.isArray(t[i]) ? d = t[i] : d = [t[i]], d.map((n) => {
    const p = s[r] !== void 0 ? s[r] : r, m = !it(p);
    return `${n}: ${c || ""}${p}${m && a ? a : ""};`;
  }).join(";");
}, x = (t, s = !0, i = !1) => {
  const r = u.find((a) => t.startsWith(a));
  return r ? r === "dark" ? x(
    dt(t.slice(4)),
    s,
    !0
  ) : [
    s ? t.replace(r, "").toLowerCase() : t.replace(r, ""),
    r,
    i
  ] : [s ? t.toLowerCase() : t, null, i];
}, at = /* @__PURE__ */ new Set([
  "onclick",
  "oncontextmenu",
  "onpointerdown",
  "onpointerup",
  "onpointermove",
  "onpointercancel",
  "onkeydown",
  "onkeyup",
  "onkeypress",
  "onmouseenter",
  "onmouseleave",
  "onmousemove",
  "ontouchstart",
  "ontouchend",
  "ontouchmove",
  "onfocus",
  "onblur",
  "onscroll"
]), qt = (t) => t.startsWith("aria") || t === "id" || t === "role" || t === "children" || t === "tabIndex" || t === "style" || t === "title" || at.has(t.toLowerCase()), ct = (t) => t in k, Jt = (...t) => (s) => {
  const i = s.theme.$$Cache, r = [];
  for (const a of Object.keys(s)) {
    const c = s[a];
    if (c === void 0 || typeof c == "function" || a === "style" || a === "theme" || a === "parentBg" || ct(a))
      continue;
    const d = a + c + s.parentBg;
    if (i.has(d)) {
      r.push(i.get(d));
      continue;
    }
    let n;
    for (const p of t)
      if (p.handles.has(a) && (n = p({
        [a]: c,
        theme: s.theme,
        parentBg: s.parentBg
      }), n)) {
        r.push(n), i.set(d, n);
        break;
      }
    n || i.set(d, void 0);
  }
  return r.join("");
}, f = (t) => t[0].toUpperCase() + t.slice(1), dt = (t) => t[0].toLowerCase() + t.slice(1), pt = u.filter((t) => t !== "dark"), lt = (t) => {
  const s = pt.flatMap((i) => t.map((r) => `${i}${f(r)}`)).concat(t);
  return s.concat(
    s.flatMap((i) => `dark${f(i)}`)
  );
}, o = (t, s, i, r) => {
  const a = {};
  for (const n of Object.keys(t))
    a[n.toLowerCase()] = t[n];
  const c = (n) => {
    try {
      const p = s(n.theme);
      return Object.keys(n).filter((l) => n[l] !== void 0).map((l) => {
        const [M, y, w] = x(l), h = n.theme;
        return R(
          h,
          rt(
            a,
            p,
            M,
            n[l],
            i,
            r
          ),
          y,
          w,
          h.colors.basic[n.parentBg] ?? n.parentBg
        );
      }).filter(Boolean).join(";");
    } catch {
      throw new v(
        "Could not read from theme. Did you render a <ThemeProvider/> at the root of your app?",
        "styling#theming"
      );
    }
  }, d = lt(Object.keys(t));
  c.handles = /* @__PURE__ */ new Map();
  for (const n of d)
    c.handles.set(n, !0);
  return c;
}, gt = o(b, (t) => t.spacings, "px"), mt = o(S, (t) => t.spacings, "px"), ht = o(V, (t) => t.sizes.box, "px"), ft = o(
  j,
  (t) => t.colors.basic
), ut = o(J, (t) => t), bt = o(A, (t) => t), xt = o(B, (t) => t, "px"), Mt = o(st, (t) => t), yt = o(W, (t) => t), wt = o(z, (t) => t), vt = o(I, (t) => t), Rt = o($, (t) => t), kt = o(F, (t) => t), Ct = o(
  X,
  (t) => t.spacings,
  "px"
), St = o(Y, (t) => t.spacings, "px"), jt = o(P, (t) => t), At = o(
  O,
  (t) => t.colors.basic
), Bt = o(E, (t) => t), Wt = o(K, (t) => t), zt = o(
  G,
  (t) => t.sizes.borderRadius,
  "px"
), It = o(
  D,
  (t) => t.sizes.border,
  "px"
), Tt = o(H, (t) => t), $t = o(L, (t) => t), Lt = o(T, (t) => t), Pt = o(U, (t) => t), Dt = o(Q, (t) => t), Ot = o(Z, (t) => t), Et = o(_, (t) => t), Gt = o(N, (t) => t), Ft = o(et, (t) => t.spacings, "px"), Kt = o(
  tt,
  (t) => t,
  void 0,
  "span "
), Ht = o(
  ot,
  (t) => t.aspectRatio,
  void 0
), Ut = o(
  q,
  (t) => t.colors.Shadow.onLight,
  void 0
), e = /* @__PURE__ */ new Map();
e.set(gt, "spacing");
e.set(mt, "spacing");
e.set(ht, "sizes");
e.set(ft, "colors");
e.set(ut, "theme");
e.set(bt, "theme");
e.set(xt, "theme");
e.set(Mt, "theme");
e.set(yt, "theme");
e.set(wt, "theme");
e.set(vt, "theme");
e.set(Rt, "theme");
e.set(kt, "theme");
e.set(Ct, "spacing");
e.set(St, "spacing");
e.set(jt, "theme");
e.set(At, "colors");
e.set(Bt, "theme");
e.set(Wt, "theme");
e.set(zt, "borderRadius");
e.set(It, "borderWidth");
e.set(Tt, "theme");
e.set($t, "theme");
e.set(Lt, "theme");
e.set(Pt, "theme");
e.set(Dt, "theme");
e.set(Ot, "theme");
e.set(Et, "theme");
e.set(Gt, "theme");
e.set(Ft, "spacing");
e.set(Kt, "theme");
e.set(Ht, "aspectRatio");
e.set(Ut, "shadows");
const g = /* @__PURE__ */ new Map();
g.set("hideScrollIndicator", "hide-scroll-indicator");
g.set("animated", "animated-all");
g.set("scrollDirection", {
  horizontal: "scroll-direction-horizontal"
});
export {
  u as Modifiers,
  e as allMappings,
  Ht as aspectRatio,
  ot as aspectRatioMappings,
  ft as background,
  ut as backgroundImage,
  J as backgroundImageMappings,
  j as backgroundMappings,
  jt as border,
  At as borderColor,
  O as borderColorMappings,
  P as borderMappings,
  zt as borderRadius,
  G as borderRadiusMappings,
  Bt as borderStyle,
  E as borderStyleMappings,
  It as borderWidth,
  D as borderWidthMappings,
  g as classNameProps,
  Jt as compose,
  Wt as cursor,
  K as cursorMappings,
  vt as display,
  I as displayMappings,
  bt as flex,
  wt as flexAlignment,
  z as flexAlignmentMappings,
  xt as flexBasis,
  B as flexBasisMappings,
  yt as flexDirection,
  W as flexDirectionMappings,
  A as flexMappings,
  Mt as flexWrap,
  st as flexWrapMappings,
  Gt as grid,
  Ft as gridGap,
  et as gridGapMappings,
  N as gridMappings,
  Kt as gridSpan,
  tt as gridSpanMappings,
  St as inset,
  Y as insetMappings,
  ct as isAriaAttribute,
  gt as margin,
  b as marginMapping,
  H as numberValueMappings,
  Tt as numberValues,
  Et as opacity,
  _ as opacityMappings,
  $t as overflow,
  L as overflowMappings,
  C as paddingMapping,
  Ot as pointerEvents,
  Z as pointerEventsMappings,
  Rt as position,
  Ct as positionCoords,
  X as positionCoordsMappings,
  $ as positionMappings,
  Ut as shadow,
  q as shadowMappings,
  qt as shouldForwardPropToNativeElement,
  V as sizeMappings,
  ht as sizes,
  mt as spacing,
  S as spacingMapping,
  x as stripModifierFromKey,
  Pt as textAlign,
  U as textAlignMappings,
  Dt as touchAction,
  Q as touchActionMappings,
  kt as transform,
  F as transformMappings,
  nt as units,
  it as valueIncludesUnit,
  Lt as visibility,
  T as visibilityMappings
};
