import * as ids from '../providedServices';
import { foreachProp } from '@knuddels/std';
import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import {
	MessengerService,
	MessengerConversationService,
	SnapService,
	MessengerNavigationService,
	MessengerComponents,
	MessengerOverviewService,
	ReadSystemService,
	StarMessageService,
	MessengerImageService,
	NewMessageService,
	SendMessageService,
	MessengerListService,
	MessengerMiniChatService,
} from './services';
import { StAppMessenger } from './stappmessenger';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$MessengerService: ctx.loggedInScope.toSingletonAutostart(
			MessengerService
		),
		$MessengerConversationService: ctx.loggedInScope.toSingletonAutostart(
			MessengerConversationService
		),
		$SnapService: ctx.loggedInScope.toSingletonAutostart(SnapService),
		$MessengerNavigationService: ctx.loggedInScope.toSingletonAutostart(
			MessengerNavigationService
		),
		$MessengerComponents: ctx.loggedInScope.toSingletonAutostart(
			MessengerComponents
		),
		$MessengerOverviewService: ctx.loggedInScope.toSingletonAutostart(
			MessengerOverviewService
		),
		$ReadSystemService: ctx.loggedInScope.toSingletonAutostart(
			ReadSystemService
		),
		$StarMessageService: ctx.loggedInScope.toSingletonAutostart(
			StarMessageService
		),
		$MessengerImageService: ctx.loggedInScope.toSingletonAutostart(
			MessengerImageService
		),
		$NewMessageService: ctx.loggedInScope.toSingletonAutostart(
			NewMessageService
		),
		$SendMessageService: ctx.loggedInScope.toSingleton(SendMessageService),
		$MessengerListService: ctx.loggedInScope.toSingletonAutostart(
			MessengerListService
		),
		$MessengerMiniChatService: ctx.loggedInScope.toSingletonAutostart(
			MessengerMiniChatService
		),
	});
	if (StAppMessenger) {
		StAppMessenger.registerServices(ctx);
	}
}
