import * as React from 'react';
import { BackgroundContainerProps } from '../../../atoms/Containers';
import { Column } from '../../../atoms/Layouts/Column/Column';
import { ScrollContainer } from '../../../atoms/Containers/ScrollContainer/ScrollContainer';
import { Spacer } from '../../../atoms/Spacers/Spacer';
import { ModalContentWrapper } from './ModalContentWrapper';
import { ModalHeader, ModalHeaderProps } from './ModalHeader';
import { ShrinkStyle } from './Modal_styles';
export interface ModalContentProps extends ModalHeaderProps {
  tinyScreen?: boolean;
  background?: BackgroundContainerProps;
  alignCenter?: boolean;
}
export class ModalContent extends React.PureComponent<ModalContentProps> {
  render(): JSX.Element {
    const {
      tinyScreen,
      background,
      ...props
    } = this.props;
    return <Column {...ShrinkStyle} {...background}>
				{!tinyScreen ? <ModalContentWrapper alignCenter={this.props.alignCenter}>
						<ModalHeader {...props} />
					</ModalContentWrapper> : <Spacer size={'large'} />}
				<ScrollContainer {...ShrinkStyle}>
					<ModalContentWrapper alignCenter={this.props.alignCenter}>
						{this.props.children}
					</ModalContentWrapper>
				</ScrollContainer>
			</Column>;
  }
}