import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { Button, ModalCard, ModalHeader, ModalWrapper, Spacer } from '@shared/components';
import { FlexCol, Image, Text } from '@knuddels/component-library';
import { $ScreenService } from '@knuddels-app/Screen';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import pic from '../assets/payment_error.gif';
export interface PaymentFailureModalProps {
  onRetry(): void;
  onCancel(): void;
}
@injectable()
class PaymentFailureModalModel {
  constructor(@injectProps()
  private readonly props: PaymentFailureModalProps, @inject($ScreenService)
  private screenService: typeof $ScreenService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  public get maxWidth(): number {
    return this.screenService.isStackedLayout ? 312 : 360;
  }
  public cancel = () => {
    this.props.onCancel();
    this.thisVisibleOverlay.dispose();
  };
  public retry = () => {
    this.props.onRetry();
    this.thisVisibleOverlay.dispose();
  };
}
export const PaymentFailureModal = injectedComponent({
  name: 'PaymentFailureModal',
  model: PaymentFailureModalModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<PaymentFailureModalProps>()
}, ({
  model,
  i18n
}) => <ModalWrapper cancelModal={model.cancel}>
			<ModalCard>
				<div className={_c0}>
					<Image src={pic} alt={''} resizeMode={'contain'} className={_c1} />
					<ModalHeader headline={i18n.format(declareFormat({
        id: 'payment.failuremodal.header',
        defaultFormat: 'Snap, something went wrong.'
      }))} />
					<Spacer size={'base'} />
					<Text state={'tertiary'} className={_c2}>
						{i18n.format(declareFormat({
          id: 'payment.failuremodal.subheader',
          defaultFormat: 'Just start the process again.'
        }))}
					</Text>
					<Spacer size={'xxlarge'} />
					<Button text={i18n.format(declareFormat({
        id: 'payment.failuremodal.button',
        defaultFormat: 'Try again'
      }))} onClick={model.retry} />
				</div>
			</ModalCard>
		</ModalWrapper>);
const _c0 = " Knu-FlexCol px-base py-xlarge alignItems-center ";
const _c1 = " width-53px height-43px ";
const _c2 = "  ";