export const Confetti = `<svg viewBox="0 0 387 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.57692" cy="94.5769" r="6.57692" fill="#FBB06A"/>
    <circle opacity="0.6" cx="27.5769" cy="52.5769" r="6.57692" fill="#D0FBF2"/>
    <circle cx="62.3846" cy="42.3846" r="4.38462" fill="#FBB06A"/>
    <circle cx="338.385" cy="56.3846" r="4.38462" fill="#FBB06A"/>
    <circle opacity="0.6" cx="379.577" cy="69.5769" r="6.57692" fill="#D0FBF2"/>
    <circle opacity="0.6" cx="263.577" cy="6.57692" r="6.57692" fill="#D0FBF2"/>
    <circle opacity="0.6" cx="138.577" cy="22.5769" r="6.57692" fill="#D0FBF2"/>
    <circle cx="342.385" cy="21.3846" r="4.38462" fill="#FB6A8E"/>
    <circle cx="242.385" cy="38.3846" r="4.38462" fill="#FB6A8E"/>
    <circle cx="305.259" cy="37.2593" r="6.57692" transform="rotate(129.564 305.259 37.2593)" fill="#FB6A8E"/>
    <circle cx="289.259" cy="71.2593" r="6.57692" transform="rotate(129.564 289.259 71.2593)" fill="#FBB06A"/>
    <circle cx="44.3846" cy="9.38461" r="4.38462" fill="#FB6A8E"/>
    <circle cx="111.385" cy="51.3846" r="4.38462" fill="#FB6A8E"/>
</svg>`;