import { Maybe, Scalars } from '@generated/graphql';

export const getDaysFromNow = (
	utcTimestamp: Maybe<Scalars['UtcTimestamp'] | number>
): number => {
	if (utcTimestamp === null || !+utcTimestamp) {
		return 0;
	}

	const date = new Date(+utcTimestamp);
	const now = new Date(Date.now());
	const diff = date.valueOf() - now.valueOf();
	const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

	return diffDays > 0 ? diffDays : 0;
};
