import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Box, createSvgIcon, Flex, FlexCol, FlexProps, Image, Rotate, Text, ThemeColors, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import { MINIFIED_BAR_HEIGHT } from './constants';
export const ProgressDisplay: React.FC<{
  value: number;
  maxValue: number;
  prevMaxValue?: number;
  onProgressChangeAnimated?: () => void;
  minified?: boolean;
  colorType?: 'blue' | 'orange';
  numericValueDisplay?: 'on-top' | 'below';
  numericValueSuffixText?: string;
  numericValueIcon?: ReturnType<typeof createSvgIcon>;
}> = props => {
  const {
    animatedValue,
    animatedMaxValue,
    displayedDiff,
    progress
  } = useStateTransition(props.value, props.maxValue, props.prevMaxValue, props.onProgressChangeAnimated);
  const color: ThemeColors = props.colorType === 'blue' ? 'blue-400' : 'orange-400';
  return <div className={_c0}>
			<div style={{
      height: resolveThemingValue(props.minified ? MINIFIED_BAR_HEIGHT : 16, "sizes", useTheme())
    }} className={_c1 + ("black-solid-110" ? resolveIsDarkColor("black-solid-110", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (props.minified ? _c2 : _c3)}>
				<AnimatedBar progress={progress} bg={color} />
			</div>
			{!props.minified && <CurrentNumericalProgress progress={progress} value={animatedValue} maxValue={animatedMaxValue} color={color} display={props.numericValueDisplay} suffixText={props.numericValueSuffixText} icon={props.numericValueIcon} />}
			{displayedDiff > 0 && <div className={_c4}>
					<Rotate rotated={true} to={-20} className={_c5}>
						<Text type={'body1'} bold={true} color={color} className={_c6}>
							+{displayedDiff}
						</Text>
					</Rotate>
				</div>}
			{animatedValue === animatedMaxValue && <div className={_c7}>
					<Image src={require('./icons-party.png')} alt={''} className={_c8} />
				</div>}
		</div>;
};
export const CurrentNumericalProgress: React.FC<{
  progress: number;
  value: number;
  maxValue: number;
  color?: ThemeColors;
  display?: 'on-top' | 'below';
  suffixText?: string;
  icon?: ReturnType<typeof createSvgIcon>;
}> = props => {
  const isOnTop = props.display === 'on-top';
  const isAtLeastHalfProgressed = props.progress > 0.5;
  return <div style={{
    position: resolveThemingValue(isOnTop ? 'absolute' : undefined, "theme", useTheme())
  }} className={_c9 + (!isOnTop ? _c10 : _c11)}>
			<Text type={isOnTop ? 'tiny' : 'body1'} color={isOnTop ? isAtLeastHalfProgressed ? 'white-solid-white' : 'black-transparent-660' : props.color ?? 'orange-400'} className={_c12}>
				{`${props.value}/${props.maxValue}`}
				{props.suffixText ? ' ' + props.suffixText : ''}
			</Text>
			{props.icon && <>
					<Spacer size={'tiny'} />
					<props.icon size={'base'} />
				</>}
		</div>;
};
const useStateTransition = (value: number, maxValue: number, prevMaxValue?: number, onAnimationDone?: () => void) => {
  const [animatedValue, setAnimatedValue] = useState(value);
  const [animatedMaxValue, setAnimatedMaxValue] = useState(maxValue);
  const [animatedPrevMaxValue, setAnimatedPrevMaxValue] = useState(prevMaxValue);
  const [displayedDiff, setDisplayedDiff] = useState(0);
  useLayoutEffect(() => {
    const isLevelUpWithValueReset = value < animatedValue;
    const isLevelUpWithIncreasingValue = animatedMaxValue < maxValue;
    if (isLevelUpWithValueReset || isLevelUpWithIncreasingValue) {
      const diffToEnd = animatedMaxValue - animatedValue;
      setDisplayedDiff(isLevelUpWithValueReset ? diffToEnd + value : value - animatedValue);
      // animate to old end
      setAnimatedValue(animatedMaxValue);

      // change level
      setTimeout(() => {
        if (isLevelUpWithValueReset) {
          setAnimatedValue(0);
        }
        setAnimatedPrevMaxValue(prevMaxValue);
        setAnimatedMaxValue(maxValue);
        if (value === 0) {
          setDisplayedDiff(0);
          onAnimationDone?.();
        } else {
          // animate rest
          setTimeout(() => {
            setAnimatedValue(value);
            setTimeout(() => {
              setDisplayedDiff(0);
              onAnimationDone?.();
            }, 500);
          }, 200);
        }
      }, 1500);
      return;
    }
    const diff = value - animatedValue;
    setDisplayedDiff(diff);
    setAnimatedValue(value);
    setTimeout(() => {
      setDisplayedDiff(0);
      onAnimationDone?.();
    }, 1000);
  }, [value, maxValue, prevMaxValue]);
  return {
    animatedValue,
    animatedMaxValue,
    displayedDiff,
    progress: animatedPrevMaxValue > 0 ? (animatedValue - animatedPrevMaxValue) / (animatedMaxValue - animatedPrevMaxValue) : animatedValue / animatedMaxValue
  };
};
const AnimatedBar: React.FC<{
  progress: number;
} & FlexProps> = ({
  progress,
  ...props
}) => {
  const domRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (!domRef.current) {
      return;
    }
    domRef.current!.style.animation = 'none';
    if (progress === 0) {
      // don't animate if reset to 0
      domRef.current!.style.transform = `translateX(-100%)`;
    } else {
      domRef.current.animate({
        transform: `translateX(${-100 + progress * 100}%)`
      }, {
        duration: 300,
        fill: 'forwards',
        easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
      });
    }
  }, [progress]);
  return <Flex innerRef={domRef} transform={'translateX(-100%)'} position={'absolute'} inset={'none'} {...props} />;
};
const _c0 = " Knu-FlexCol position-relative alignSelf-stretch placeItems-center ";
const _c1 = " Knu-Box alignSelf-stretch bg-black-solid-110 overflow-hidden position-relative ";
const _c2 = " borderRadius-none ";
const _c3 = " borderRadius-20px ";
const _c4 = " Knu-FlexCol position-absolute top--18px right-none left-none overflow-visible alignItems-center ";
const _c5 = " overflow-visible ";
const _c6 = "  ";
const _c7 = " Knu-FlexCol position-absolute right-none bottom-none ";
const _c8 = " width-20px height-20px ";
const _c9 = " Knu-Flex alignItems-center ";
const _c10 = " mt-tiny ";
const _c11 = " mt-none ";
const _c12 = "  ";