import { jsx as t } from "react/jsx-runtime";
import * as B from "react";
import { StyledInput as C } from "./StyledInput.js";
import { InputContainer as I } from "./InputContainer.js";
import { ClearButton as F } from "./ClearButton.js";
import { useTheme as P } from "../../themes/useTheme.js";
const E = (e) => {
  var c;
  const l = P().sizes.input, [i, o] = B.useState(!1), {
    placeholder: m,
    readonly: f,
    onPress: d,
    onKeyPress: s,
    ...u
  } = e, a = e.focused ?? i, h = () => {
    var n;
    (n = e.onChange) == null || n.call(e, { value: "" });
  }, r = !!((c = e.value) != null && c.length), g = e.showClearButton && r ? /* @__PURE__ */ t(F, { onPress: h }) : e.adornmentEnd;
  return /* @__PURE__ */ t(
    I,
    {
      ...u,
      adornmentEnd: g,
      placeholder: m,
      focused: a,
      label: e.label,
      height: l[e.size ?? "default"],
      children: /* @__PURE__ */ t(
        C,
        {
          ...u,
          onKeyPress: s,
          innerRef: e.innerRef,
          focused: a,
          onFocus: () => {
            var n;
            o(!0), (n = e.onFocus) == null || n.call(e);
          },
          onBlur: () => {
            var n;
            o(!1), (n = e.onBlur) == null || n.call(e);
          },
          readonly: f,
          onPress: d
        }
      )
    }
  );
};
export {
  E as Input
};
