import * as React from 'react';
import { ContentBox } from '../../../atoms/Boxes/ContentBox/ContentBox';
import { FullHeightStyle, ShrinkStyle } from './Modal_styles';

/**
 * renders its children stacked vertically and captures clicks to prevent them from
 * being delegated to the modal wrapper which would trigger the close action.
 * Also defines a horizontal margin around the card and the white background.
 */

interface Props {
  fillHeight?: boolean;
  children: React.ReactNode;
}
export class ModalCard extends React.PureComponent<Props> {
  render(): JSX.Element {
    // TODO Make full height modals less complicated
    const {
      fillHeight = false
    } = this.props;
    return <ContentBox {...!fillHeight ? ShrinkStyle : FullHeightStyle}>
				{this.props.children}
			</ContentBox>;
  }
}