import { BugIndicatingError } from '@knuddels/std';
import * as React from 'react';
import { ServiceId } from '.';
import { InternalContainerContext } from './ContainerContext';

/**
 * This react hook can be used to get a reference to a service.
 * It can be used instead of an `InjectedComponent`.
 * If you don't use an injected component, be sure to use `observer` if
 * you want to react to changes to observable values.
 */
export function useService<T extends ServiceId<any>>(id: T): T['T'] {
	const container = React.useContext(InternalContainerContext);
	if (!container) {
		throw new BugIndicatingError('There is no Container Context!');
	}

	return React.useMemo(() => {
		return container.getServices({ service: id }).service;
	}, [container, id]);
}
