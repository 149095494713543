import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $CommandWithoutChannelService } from '@knuddels-app/Commands';
import { when } from '@knuddels-app/mobx';
import {
	$DeepLinkingService,
	DeepLinkType,
} from '@knuddelsModules/DeepLinking';

@injectable()
export class AppsDeeplinkHandler {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($DeepLinkingService)
		private readonly deepLinkingService: typeof $DeepLinkingService.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($CommandWithoutChannelService)
		private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T
	) {
		this.dispose.track(
			deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (type === DeepLinkType.App) {
					const targetApp = target.toLowerCase();
					if (targetApp === 'knuddelbuy') {
						this.openAppWithCommand('buyknuddel');
					} else if (targetApp === 'weltreise') {
						this.openAppWithCommand('weltreise');
					} else if (targetApp === 'kalender') {
						this.openAppWithCommand('kalender', 'buy');
					}
				}
			})
		);
	}

	openAppWithCommand = (command: string, parameter?: string) => {
		when(
			{
				name: 'Waiting for subscription connected',
			},
			() =>
				this.authenticatedClientService.currentK3Client
					.subscriptionClientState,
			{
				resolveTest: s => s.kind === 'connected',
			}
		).then(() => {
			this.commandWithoutChannelService.invokeCommand(command, parameter);
		});
	};
}
