import { EditorConfig, LexicalNode, NodeKey, TextNode } from 'lexical';

export class MentionNode extends TextNode {
	static getType(): string {
		return 'mention';
	}

	static clone(node: MentionNode): MentionNode {
		return new MentionNode({ ...node.args, key: node.getKey() });
	}

	constructor(
		private args: {
			text: string;
			trigger: string;
			key?: NodeKey;
			class?: string;
			toMessageFormat?: (v: string) => string;
		}
	) {
		super(`${args.trigger}${args.text}`, args.key);
	}

	createDOM(config: EditorConfig): HTMLElement {
		const dom = super.createDOM(config);
		dom.className = 'mention ' + this.args.class;
		return dom;
	}

	isTextEntity(): true {
		return true;
	}

	canInsertTextBefore(): boolean {
		return false;
	}

	canInsertTextAfter(): boolean {
		return false;
	}

	toMessage(): string {
		if (this.args.toMessageFormat) {
			return this.args.toMessageFormat(this.args.text);
		}

		return `$$${this.args.trigger.replace('#', '^')}${this.args.text}$$`;
	}

	getTrigger(): string {
		return this.args.trigger;
	}
}

export function $createMentionNode(args: {
	text: string;
	key?: NodeKey;
	trigger: string;
	class?: string;
	toMessageFormat?: (v: string) => string;
}): MentionNode {
	const mentionNode = new MentionNode(args);
	mentionNode.setMode('segmented').toggleDirectionless();
	return mentionNode;
}

export function $isMentionNode(node: LexicalNode): node is MentionNode {
	return node instanceof MentionNode;
}
