import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useCustomSnackbar } from '@shared/components';
import { Box } from '@knuddels/component-library';
import { CircularProgress } from '../EditProfile/EditProfileContent/ProfileFillBar/CircularProgress';
import * as React from 'react';
export const useProgressSnackbar = () => {
  const showCustomSnackbar = useCustomSnackbar();
  const i18n = useService($I18n);
  return (photosUploaded: number, totalPhotos: number, snackbarType: string, onCancel?: () => void) => {
    return showCustomSnackbar({
      text: declareFormat({
        id: 'profile.edit.upload-album-photo.progress.text',
        defaultFormat: 'Upload photo {photosUploading} / {totalPhotos}'
      }).format(i18n, {
        photosUploading: Math.min(photosUploaded + 1, totalPhotos),
        totalPhotos
      }),
      type: snackbarType,
      adornment: <div className={_c0}>
					<CircularProgress size={26} percentage={photosUploaded / totalPhotos * 100} fillColor={'rgba(255, 255, 255, 1.0)'} backgroundColor={'#A8A8A8'} strokeWidth={'thin'}>
						{() => null}
					</CircularProgress>
				</div>,
      button: onCancel ? {
        text: declareFormat({
          id: 'profile.edit.upload-album-photo.cancel.text',
          defaultFormat: 'Cancel'
        }).format(i18n),
        onPress: onCancel
      } : undefined
    });
  };
};
const _c0 = " Knu-Box mr-minor ";