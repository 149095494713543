import * as React from 'react';
import { ContextMenuWrapper } from './Wrapper/ContextMenuWrapper';
import { positionContextMenu } from './positionContextMenu';
import { measureWindow } from '@knuddels-app/tools/measureWindow';
import { FlexCol, Scale, ThemeOverride, useMeasureElement, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { useIsStAppSidebarOverview } from '@knuddels-app/Environment/useIsStAppSidebarOverview';
export interface ContextMenuProps {
  chevronX: number;
  chevronY: number;
  chevronWidth: number;
  chevronHeight: number;
}
interface Props extends ContextMenuProps {
  closeContextMenu(): void;
}
export const ContextMenu: React.FC<Props> = props => {
  const [{
    x,
    y
  }, setPosition] = React.useState({
    x: 0,
    y: 0
  });
  const [measured, setMeasured] = React.useState(false);
  const {
    width,
    height,
    bind
  } = useMeasureElement();
  React.useEffect(() => {
    if (width && height) {
      const {
        width: windowWidth,
        height: windowHeight
      } = measureWindow();
      const position = positionContextMenu(props, width, height, windowWidth, windowHeight);
      setPosition(position);
      setMeasured(true);
    }
  }, [width, height]);
  const condensed = useIsStAppSidebarOverview();
  return <ContextMenuWrapper x={x} y={y} cancelModal={props.closeContextMenu}>
			<FlexCol position={'relative'} overflow={'visible'} pb={'small'} {...bind}>
				<Scale scaled={measured} from={0} to={1} style={{
        transformOrigin: 'top right'
      }} className={_c0}>
					<div className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (condensed ? _c2 : _c3)}>
						{props.children}
					</div>
				</Scale>
			</FlexCol>
		</ContextMenuWrapper>;
};
const _c0 = "  ";
const _c1 = " Knu-FlexCol position-relative borderColor-black-transparent-220 borderWidth-base borderRadius-5px borderStyle-solid bg-contentLightBg overflow-visible shadow-Shadow4 ";
const _c2 = " py-tiny ";
const _c3 = " py-small ";