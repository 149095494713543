import { jsx as r } from "react/jsx-runtime";
import { Box as t } from "./Box/Box.js";
const i = (o) => /* @__PURE__ */ r(
  t,
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    ...o
  }
);
export {
  i as FlexCol
};
