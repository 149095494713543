const asciiDecimalForA = 97; // 'a'

export function escapeForPhotoID(str: string): string {
	str = str.toLowerCase();
	let result = '';
	for (let i = 0; i < str.length; i++) {
		const c = str.charCodeAt(i);
		if (
			(c >= 97 && c <= 122) ||
			(c >= 65 && c <= 90) ||
			(c >= 48 && c <= 57)
		) {
			result = result + String.fromCharCode(c);
		} else if (c === 32) {
			result = result + '_-';
		} else if (c < 256) {
			result =
				result +
				'_' +
				String.fromCharCode(((c >>> 4) & 15) + asciiDecimalForA) +
				String.fromCharCode((c & 15) + asciiDecimalForA);
		} else {
			result =
				result +
				'__' +
				String.fromCharCode(((c >>> 12) & 15) + asciiDecimalForA) +
				String.fromCharCode(((c >>> 8) & 15) + asciiDecimalForA) +
				String.fromCharCode(((c >>> 4) & 15) + asciiDecimalForA) +
				String.fromCharCode((c & 15) + asciiDecimalForA);
		}
	}
	return result;
}
