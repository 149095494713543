import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const t = r(
  /* @__PURE__ */ o("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ o(
    "path",
    {
      d: "M18.4141 2C18.1582 2 17.902 2.09747 17.707 2.29297L16 4L20 8L21.707 6.29297C22.098 5.90197 22.098 5.26891 21.707 4.87891L19.1211 2.29297C18.9256 2.09747 18.6699 2 18.4141 2ZM14.5 5.5L3 17V21H7L18.5 9.5L14.5 5.5Z",
      fill: "currentColor"
    }
  ) })
);
t.displayName = "IconEdit";
export {
  t as IconEdit
};
