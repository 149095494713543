import * as React from 'react';
import { BoxProps, createAccessiblePointerEventHandler, Flex, FlexCol, Text, ThemeOverride, useTheme, resolveThemingValue, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
export const MacroBoxEntry: React.FC<{
  ml?: BoxProps['ml'];
  title: string;
  onPress: () => void;
}> = ({
  title,
  ml,
  children,
  onPress
}) => {
  const theme = useTheme();
  return <div style={{
    marginLeft: resolveThemingValue(ml, "spacing", useTheme())
  }} className={_c0}>
			<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: onPress
    })} className={_c1 + ((theme.id === 'light' ? "black-solid-040" : "white-solid-070") ? resolveIsDarkColor(theme.id === 'light' ? "black-solid-040" : "white-solid-070", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (theme.id === 'light' ? _c2 : _c3)}>
				{children}
			</div>
			<Text type={'tiny'} state={'secondary'} className={_c4}>
				{title}
			</Text>
		</div>;
};
const _c0 = " Knu-FlexCol alignItems-center ";
const _c1 = " Knu-Flex cursor-pointer width-72px height-64px borderRadius-minor mb-2px placeItems-center ";
const _c2 = " bg-black-solid-040 ";
const _c3 = " bg-white-solid-070 ";
const _c4 = "  ";