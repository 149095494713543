import { Disposable, DisposableLike } from '@knuddels/std';
import { $CommandService } from '@knuddels-app/Commands';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { LexicalNode } from 'lexical';
import { Keyboard } from '@capacitor/keyboard';

// https://bitbucket.org/knuddels/user-apps/wiki/KCode/User-Eingaben

export function registerTextFieldCommands(
	commandService: typeof $CommandService.T,
	getInputBar: () => RichTextEditorRef,
	textToNodes: (nodes: string[]) => LexicalNode[]
): DisposableLike {
	const withInputBar: (
		func: (inputBar: RichTextEditorRef) => void
	) => void = func => {
		const inputBar = getInputBar();
		if (inputBar) {
			inputBar.focus();
			Keyboard.show();
			setTimeout(() => {
				func(inputBar);
			});
		}
	};

	return Disposable.create([
		...['tf-override', 'tf-overrideb'].map(commandName =>
			commandService.registerCommand({
				commandName,
				async invoke(parameter: string): Promise<void> {
					withInputBar(inputBar => {
						inputBar.setText(parameter);
					});
				},
			})
		),

		...['tf-override-nodes'].map(commandName =>
			commandService.registerCommand({
				commandName,
				async invoke(parameter: string): Promise<void> {
					withInputBar(inputBar => {
						const nodes = parameter.split('|');
						inputBar.setNodes(() => textToNodes(nodes));
					});
				},
			})
		),

		...['tf-overrides', 'tf-overridesb'].map(commandName =>
			commandService.registerCommand({
				commandName,
				async invoke(parameter: string): Promise<void> {
					const result = processSelectionMarker(parameter);
					withInputBar(inputBar => {
						inputBar.setText(result.text, result.selection);
					});
				},
			})
		),

		...['tf-insert', 'tf-insertb', 'tf-inserta'].map(commandName =>
			commandService.registerCommand({
				commandName,
				async invoke(parameter: string): Promise<void> {
					withInputBar(inputBar => {
						inputBar.appendText(parameter);
					});
				},
			})
		),

		...['tf-inserts', 'tf-insertsb'].map(commandName =>
			commandService.registerCommand({
				commandName,
				async invoke(parameter: string): Promise<void> {
					withInputBar(inputBar => {
						const processedInput = processSelectionMarker(
							parameter
						);
						inputBar.appendText(
							processedInput.text,
							processedInput.selection
						);
					});
				},
			})
		),
	]);
}

type Result = {
	text: string;
	selection: { start: number; end: number };
};

function processSelectionMarker(input: string): Result {
	let result = input;
	const openBracketIndex = result.indexOf('[');
	if (openBracketIndex > -1) {
		result =
			result.substring(0, openBracketIndex) +
			result.substring(openBracketIndex + 1);
	}

	const closeBracketIndex =
		openBracketIndex > -1 ? result.indexOf(']', openBracketIndex) : -1;
	if (closeBracketIndex > -1) {
		result =
			result.substring(0, closeBracketIndex) +
			result.substring(closeBracketIndex + 1);
	}

	const hasSelection = openBracketIndex > -1 && closeBracketIndex > -1;

	return {
		text: result,
		selection: {
			start: hasSelection ? openBracketIndex : result.length,
			end: hasSelection ? closeBracketIndex : result.length,
		},
	};
}
