import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $PushNotificationsService = newServiceId<
	services.PushNotificationsService
>('$PushNotificationsService');
export const $NotificationsComponents = newServiceId<
	services.NotificationsComponents
>('$NotificationsComponents');
export const $NotificationBarService = newServiceId<
	services.NotificationBarService
>('$NotificationBarService');
