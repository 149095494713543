import { RefreshSessionErrorFragment } from '@generated/graphql';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $AuthService } from '@knuddels-app/Connection/serviceIds';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { GenericErrorMessageScreen } from './GenericErrorMessageScreen';
import { LockInfoScreen } from './LockInfoScreen';
export const RefreshSessionErrorScreen = injectedComponent({
  name: 'RefreshSessionErrorScreen',
  inject: {
    authService: $AuthService,
    firebaseAnalyticsService: $FirebaseAnalyticsService
  },
  props: declareProps<{
    refreshSessionError: RefreshSessionErrorFragment;
  }>()
}, ({
  authService,
  firebaseAnalyticsService,
  refreshSessionError
}) => {
  React.useEffect(() => {
    // had to add tracking here and not directly in AuthSession to prevent circular dependecy authservice -> firebase -> authservice
    if (refreshSessionError.user.lockInfo.__typename === 'UnlockedLockInfo') {
      firebaseAnalyticsService.logEvent('Client_Connection', 'Error_ConnectionCouldNotBeEstabilshed');
    } else {
      firebaseAnalyticsService.logEvent('Client_Connection', 'Error_UserLocked');
    }
  }, []);
  if (refreshSessionError.user.lockInfo.__typename !== 'UnlockedLockInfo') {
    return <LockInfoScreen logout={authService.clearError}
    // This makes typescript correctly exclude the 'UnlockedLockInfo' which simplifies the `LockInfoScreen` component.
    // It shouldn't be a performance issue because this component shouldn't really update until it is closed.
    userWithLockInfo={{
      ...refreshSessionError.user,
      lockInfo: refreshSessionError.user.lockInfo
    }} />;
  } else {
    return <GenericErrorMessageScreen logout={authService.clearError} errorMessage={refreshSessionError.formattedErrorMessage} />;
  }
});