import * as React from 'react';
import { observer } from 'mobx-react';
import { AdzoneProps } from './AdzoneProps';
import { AdzoneWrapper } from './shared/AdzoneWrapper';
import { AdzoneConfig, adzoneToConfigMap } from '../web/adzoneConfig';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
let lastReloadTime = 0;
@observer
export class Adzone extends React.PureComponent<AdzoneProps> {
  get adzoneConfig(): AdzoneConfig {
    return adzoneToConfigMap[this.props.adzone];
  }
  componentDidMount() {
    if (Date.now() - lastReloadTime > 1000) {
      lastReloadTime = Date.now();
      try {
        const ADNPM = (window as any).ADNPM;
        if (ADNPM.pageLoad) {
          (window as any).ADNPM.pageLoad();
        }
      } catch (e) {
        dangerouslyGetK3Container().getService($FirebaseAnalyticsService).logEvent('adzone_error', 'adzone_reload_error');
        console.error(e);
      }
    }
  }
  render(): JSX.Element {
    return <AdzoneWrapper adVisible placeholder={'none'} fixedSize={this.props.fixedSize} verticalAd={this.props.verticalAd} hideRemoveAdsBadge={this.props.hideRemoveAdsBadge} adsBadgeLocation={this.props.adsBadgeLocation}>
				<div className={this.adzoneConfig.netpointClassname} />
			</AdzoneWrapper>;
  }
}