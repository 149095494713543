import { Flex, FlexCol, IconUser, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
// tslint:disable-next-line: no-module-bleeding
import { TextWithPrefixHighlight } from '@knuddelsModules/AutocompleteInputBar/bundle/components/Dropup/TextWithPrefixHighlight';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $createMentionNode } from '@shared/components/RichText/nodes/MentionNode';
import { MentionPlugin } from '@shared/components/RichText/plugins/MentionPlugin';
import { TypeaheadMenu } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/TypeaheadMenu';
// tslint:disable-next-line: no-module-bleeding
import { AutocompleteChannel } from '@knuddelsModules/AutocompleteInputBar/bundle/services';
import { ChannelPicture } from '@knuddels-app/shared-components/ChannelPicture';
import { withSearchCache } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/withSearchCache';
export class ChannelTypeAheadOption extends MenuOption {
  constructor(public channel: AutocompleteChannel) {
    super(channel.id);
  }
  toString(): string {
    return this.channel.name;
  }
}
export class DynamicChannelTypeAheadOption extends MenuOption {
  constructor(public channelName: string) {
    super(channelName);
  }
  toString(): string {
    return this.channelName;
  }
}
export const ChannelTypeAheadOptionRenderer: React.FC<{
  option: ChannelTypeAheadOption | DynamicChannelTypeAheadOption;
  query: string;
}> = ({
  option,
  query
}) => {
  return <>
			{option instanceof ChannelTypeAheadOption ? <ChannelPicture backgroundColor={option.channel.info.backgroundColor!} size={32} previewPicUrl={option.channel.info.previewImageUrl!} /> : <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />}

			<Spacer size={'minor'} />
			<div className={_c1 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<TextWithPrefixHighlight text={option instanceof ChannelTypeAheadOption ? option.channel.name : option.channelName} highlightedPrefix={query} />
				{option instanceof ChannelTypeAheadOption ? <div className={_c2}>
						<IconUser size={'minor'} disabled />

						<Spacer size={'tiny'} />
						<Text type={'tiny'} state={'disabled'} className={_c3}>
							{option.channel.onlineUserCount}
						</Text>
					</div> : <Text type={'tiny'} className={_c4}>
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						Neuen Channel "{option.channelName}" erstellen
					</Text>}
			</div>
		</>;
};
export const getChannelsFromQuery = withSearchCache(async (autocompleteService: typeof $AutocompleteProviderService.T, queryText: string) => {
  const channels = await autocompleteService.getChannels(queryText);
  return channels.map(c => {
    return new ChannelTypeAheadOption(c);
  });
});
const InnerChannelPlugin: React.FC<{
  anchor: React.MutableRefObject<HTMLElement>;
}> = ({
  anchor
}) => {
  const autocompleteService = useService($AutocompleteProviderService);
  return <MentionPlugin<ChannelTypeAheadOption> getOptions={async queryText => getChannelsFromQuery(autocompleteService, queryText)} renderMenu={({
    options,
    itemProps,
    query,
    onClose
  }) => {
    return <TypeaheadMenu onClose={onClose} options={options} itemProps={itemProps} anchor={anchor} renderItem={option => {
      return <ChannelTypeAheadOptionRenderer option={option} query={query} />;
    }} />;
  }} trigger={`#`} createNode={option => $createMentionNode({
    trigger: `#`,
    text: option.channel.name,
    class: 'channel-mention'
  })} />;
};
export const ChannelPlugin = React.memo(InnerChannelPlugin);
ChannelPlugin.displayName = 'ChannelPlugin';
const _c0 = " Knu-Flex bg-contentBg borderRadius-large size-32px ";
const _c1 = " Knu-FlexCol position-relative justifyContent-space-between overflow-hidden height-32px bg-transparent ";
const _c2 = " Knu-Flex alignItems-center overflow-hidden ";
const _c3 = "  ";
const _c4 = "  ";