import * as React from 'react';
import { ChannelCategory, ChannelGroupFragment, GetChannelListCategory } from '@generated/graphql';
import { VirtualList } from '@knuddels/component-library';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { useRenderChannelListItem } from '../shared/ChannelList/ChannelListVirtualItems';
import { useFullListData } from './useFullListData';
import { useCacheAndNetworkQuery } from '../Overview/ChannelSelection/useCacheAndNetworkQuery';
import { LoadingEntry } from '../shared/LoadingEntry';
import { equalUpToDepth } from '@shared/components';
import { useChannelGroupUpdater } from '../Overview/ChannelSelection/useChannelGroupUpdater';
import { extractChannelGroupIds } from '../Overview/ChannelSelection/extractChannelGroupIds';
import { useIsViewOnTopOfStack } from '../Overview/ChannelSelection/useIsViewOnTopOfStack';
import { mergePreviewMembers } from '@knuddelsModules/ChannelList/bundle/components/Overview/ChannelSelection/mergePreviewMembers';
export interface OwnProps {
  categoryId: ChannelCategory['id'];
  grouped?: boolean;
}
export const FullCategoryContent: React.FC<OwnProps> = props => {
  const {
    data,
    error,
    loading,
    setData
  } = useCacheAndNetworkQuery(GetChannelListCategory, {
    categoryId: props.categoryId,
    groupAmount: 100,
    pixelDensity: getPixelRatio()
  }, {
    categoryId: props.categoryId,
    groupAmount: 5,
    pixelDensity: getPixelRatio()
  });
  const setVisibleChannels = useUpdater(setData);
  const listData = useFullListData(data, props.grouped);
  const renderChannelListItem = useRenderChannelListItem({
    shouldUseShortDescription: data?.id === 'games',
    trackingContentType: 'ChannelSelection_ChannelCategories'
  });
  if (error) {
    return null;
  }
  if (loading && !data) {
    return <LoadingEntry />;
  }
  return <VirtualList data={listData} renderItem={renderChannelListItem} gapBottom={'xlarge'} onRenderedItemsChanged={range => {
    const visibleChannels = extractChannelGroupIds(listData, range);
    setVisibleChannels(visibleChannels);
  }} className={_c0} />;
};
type QueryResult = typeof GetChannelListCategory.TPrimaryResult;
type DataUpdater = (prevData: QueryResult) => QueryResult;
const useUpdater = (setData: (updater: DataUpdater) => void) => {
  const updateChannelData = React.useCallback((newChannel: ChannelGroupFragment) => {
    const groupId = newChannel.id;
    setData(prevData => {
      const oldChannel = prevData.channelGroups.find(it => it.id === groupId);
      if (!oldChannel) {
        return prevData;
      }
      if (equalUpToDepth(oldChannel, newChannel, undefined, 10)) {
        return prevData;
      }
      const channelToInsert = mergePreviewMembers(oldChannel, newChannel);
      return {
        ...prevData,
        channelGroups: prevData.channelGroups.map(group => {
          if (group.id === groupId) {
            return channelToInsert;
          }
          return group;
        })
      };
    });
  }, [setData]);
  const isVisible = useIsViewOnTopOfStack('category');
  return useChannelGroupUpdater(isVisible, updateChannelData);
};
const _c0 = "  ";