import { transform as m } from "../utils/transform.js";
import { useCombineMotionValues as u } from "./use-combine-values.js";
import { useConstant as i } from "../utils/use-constant.js";
import { useComputed as c } from "./use-computed.js";
function h(o, r, e, n) {
  if (typeof o == "function")
    return c(o);
  const t = typeof r == "function" ? r : m(r, e, n);
  return Array.isArray(o) ? f(o, t) : f([o], ([s]) => t(s));
}
function f(o, r) {
  const e = i(() => []);
  return u(o, () => {
    e.length = 0;
    const n = o.length;
    for (let t = 0; t < n; t++)
      e[t] = o[t].get();
    return r(e);
  });
}
export {
  h as useTransform
};
