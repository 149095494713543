import {
	FullConversationWithoutMessagesFragment,
	MessengerConversationState,
	MessengerOverviewDocument,
	MessengerOverviewQuery,
	MessengerOverviewQueryVariables,
	Scalars,
} from '@generated/graphql';
import { observable, runInAction } from '@knuddels-app/mobx';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { removeDuplicatesAndFilterSelf } from '../removeDuplicatesAndFilterSlef';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { Disposable, last } from '@knuddels/std';
import { ObservableQuery } from '@apollo/client';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { moreConversationsLoadedTracker } from '@knuddelsModules/Messenger/bundle/analytics';

export type FetchStatus =
	| 'initiallyFetching'
	| 'fetchingMore'
	| 'doneFetching'
	| 'error';

const INITIAL_MESSENGER_OVERVIEW_LOAD_LIMIT = 20;
const MESSENGER_OVERVIEW_LOAD_LIMIT = 50;

export class MessengerListQuery implements Disposable {
	public readonly dispose = Disposable.fn();

	private readonly query = this.createWatchQuery(this.filterByState);

	@observable.ref
	private _conversations: readonly FullConversationWithoutMessagesFragment[] = [];

	@observable
	private _hasMore = true;

	@observable private _fetchStatus: FetchStatus = 'initiallyFetching';

	constructor(
		private filterByState: MessengerConversationState,
		private authenticatedClientService: typeof $AuthenticatedClientService.T,
		private snackbarService: typeof $SnackbarService.T
	) {
		const subscription = this.query.subscribe(data => {
			runInAction('Messenger list query update', () => {
				if (!data || data.errors) {
					this._fetchStatus = 'error';
					this.snackbarService.showGenericError();
					return;
				}

				if (this._fetchStatus === 'initiallyFetching') {
					this._fetchStatus = 'doneFetching';
				}
				/*
					data.data.messenger.conversations.conversations seems to contain duplicates
					sometimes, e.g. when switching from normal view to archived view.
				*/
				this._conversations = removeDuplicatesAndFilterSelf<
					FullConversationWithoutMessagesFragment
				>(data.data.messenger.conversations.conversations).sort(
					(a, b) => {
						const ts1 = getConversationTimestamp(a);
						const ts2 = getConversationTimestamp(b);
						return ts2 - ts1;
					}
				);
				this._hasMore = data.data.messenger.conversations.hasMore;
			});
		});
		this.dispose.track(subscription.unsubscribe);
	}

	private createWatchQuery(
		filterByState: MessengerConversationState
	): ObservableQuery<
		MessengerOverviewQuery,
		MessengerOverviewQueryVariables
	> {
		return this.authenticatedClientService.currentClient.watchQuery<
			MessengerOverviewQuery,
			MessengerOverviewQueryVariables
		>({
			query: MessengerOverviewDocument,
			variables: {
				limit: INITIAL_MESSENGER_OVERVIEW_LOAD_LIMIT,
				pixelDensity: getPixelRatio(),
				filterByState,
			},
		});
	}

	public get fetchStatus(): FetchStatus {
		return this._fetchStatus;
	}

	public get conversations(): readonly FullConversationWithoutMessagesFragment[] {
		return this._conversations;
	}

	public get hasMore(): boolean {
		return this._hasMore;
	}

	public refetch = async () => this.query.refetch();

	public readonly fetchMoreConversations = async (): Promise<void> => {
		if (this.fetchStatus !== 'doneFetching' || !this._hasMore) {
			return;
		}

		return new Promise<void>(resolve => {
			moreConversationsLoadedTracker.start();

			runInAction('Start fetching more conversations', () => {
				this._fetchStatus = 'fetchingMore';
			});
			const lastConversation = last(this._conversations);
			const before = lastConversation
				? (lastConversation.latestConversationMessage
						.timestamp as Scalars['UtcTimestamp'])
				: undefined;
			this.query
				.fetchMore({
					query: MessengerOverviewDocument,
					variables: {
						limit: MESSENGER_OVERVIEW_LOAD_LIMIT,
						before,
						filterByState: this.filterByState,
						pixelDensity: getPixelRatio(),
					},
					updateQuery: (previousQueryResult, options) => {
						return mergeQueries(
							previousQueryResult,
							options.fetchMoreResult
						);
					},
				})
				.catch(() => {
					runInAction('Set fetch error', () => {
						this._fetchStatus = 'error';
					});
				})
				.finally(() => {
					moreConversationsLoadedTracker.stop();
					runInAction('Stop fetching more conversations', () => {
						this._fetchStatus = 'doneFetching';
					});
					resolve();
				});
		});
	};
}

function mergeQueries(
	current: MessengerOverviewQuery,
	fetchMoreResult: MessengerOverviewQuery | undefined
): MessengerOverviewQuery {
	if (!fetchMoreResult) {
		return current;
	}
	return {
		...current,
		messenger: {
			...current.messenger,
			conversations: {
				...current.messenger.conversations,
				conversations: [
					...current.messenger.conversations.conversations,
					...fetchMoreResult.messenger.conversations.conversations,
				],
				hasMore: fetchMoreResult.messenger.conversations.hasMore,
			},
		},
	};
}

function getConversationTimestamp(
	conversation: FullConversationWithoutMessagesFragment
): number {
	const ts = conversation.latestConversationMessage?.timestamp;
	if (ts) {
		return +ts;
	}

	return Infinity;
}
