import * as React from 'react';
import { Button, Dialog } from '@knuddels/component-library';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';

// TODO: move to shared and use also for AdultChannelContent
export const ConfirmAdultDialog: React.FC<{
  onAccept?: () => void;
  onCancel?: () => void;
}> = ({
  onAccept,
  onCancel
}) => {
  const i18n = useService($I18n);
  const thisOverlay = useService($ThisVisibleOverlay);
  const cancel = () => {
    onCancel?.();
    thisOverlay.dispose();
  };
  const accept = () => {
    onAccept?.();
    thisOverlay.dispose();
  };
  return <Dialog onClose={cancel} open>
			<Dialog.Content align="center">
				<Dialog.Icon src={require('@shared/components/assets/shared/sm_advent-2013_kissenschlacht.gif')} alt={''} />
				<Dialog.Title>
					{i18n.format(declareFormat({
          id: 'settings.channelListSettings.dialog.adult.title',
          defaultFormat: 'Show channel with erotic context?'
        }))}
				</Dialog.Title>
				<Dialog.Body>
					{i18n.format(declareFormat({
          id: 'settings.channelListSettings.dialog.adult.text',
          defaultFormat: 'In these channels, you may receive requests that are considered inappropriate.'
        }))}
				</Dialog.Body>
			</Dialog.Content>
			<Dialog.Footer>
				<Button type="secondary" onPress={cancel}>
					{i18n.format(declareFormat({
          id: 'settings.channelListSettings.dialog.adult.decline',
          defaultFormat: 'Cancel'
        }))}
				</Button>
				<Button onPress={accept}>
					{i18n.format(declareFormat({
          id: 'settings.channelListSettings.dialog.adult.confirm',
          defaultFormat: 'Show'
        }))}
				</Button>
			</Dialog.Footer>
		</Dialog>;
};