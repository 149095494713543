import * as React from 'react';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { Gender, GetMentorStatus, MentorEvents, MentorStatusFragment, PotentialMenteeAddedEventFragment } from '@generated/graphql';
import { $NotificationBarService } from '@knuddelsModules/Notifications';
import { observable, runInAction } from '@knuddels-app/mobx';
import { ConversationMentorStatusBar, handlePress, MentorSystemAbout, MentorSystemLink, MentorSystemRewards, MentorSystemStatusBar, NEW_MENTEE_FOUND_FORMAT, PotentialMenteeAddedNotificationContent, useTrackContactsMentorList, WRITE_MESSAGE_FORMAT } from '../components';
import { $I18n, $MessageFormatProvider } from '@knuddels-app/i18n';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { $MessengerNavigationService } from '@knuddelsModules/Messenger';
import { $AppService } from '@knuddelsModules/Apps';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';
const mapGender = (gender: Gender): Parameters<typeof $NativeWebViewService.T.renderMenteeNotification>[0]['gender'] => {
  switch (gender) {
    case Gender.Male:
      return 'Male';
    case Gender.Female:
      return 'Female';
    case Gender.NonbinaryHe:
    case Gender.NonbinaryShe:
      return 'NonBinary';
    default:
      return 'Unknown';
  }
};
@injectable()
export class MentorService {
  public MentorStatusBar = MentorSystemStatusBar;
  public ConversationMentorStatusBar = ConversationMentorStatusBar;
  public MentorSystemRewards = MentorSystemRewards;
  public MentorSystemAbout = MentorSystemAbout;
  public MentorSystemLink = MentorSystemLink;
  public useTrackContactsMentorList = useTrackContactsMentorList;
  public readonly dispose = Disposable.fn();
  public get mentorStatus(): MentorStatusFragment | null {
    return this._mentorStatus;
  }
  @observable
  private _mentorStatus: MentorStatusFragment | null = null;
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($NativeWebViewService)
  private readonly nativeWebViewService: typeof $NativeWebViewService.T, @inject.lazy($NotificationBarService)
  private readonly getNotificationBarService: typeof $NotificationBarService.TLazy, @inject.lazy($MessengerNavigationService)
  private readonly getMessengerNavigationService: typeof $MessengerNavigationService.TLazy, @inject($I18n)
  private readonly i18n: typeof $I18n.T, @inject.lazy($AppService)
  private readonly getAppService: typeof $AppService.TLazy) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de,
      en
    } as any)[locale.language]);
    this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(MentorEvents, {}, {
      next: event => {
        switch (event.__typename) {
          case 'PotentialMenteeAddedEvent':
            this.handlePotentialMenteeAddedEvent(event);
            break;
          case 'MentorStatusChanged':
            runInAction('update mentor status', () => {
              this._mentorStatus = event.newStatus;
            });
            break;
          case 'PotentialMenteeRemovedEvent':
          case 'MentorAchievedEvent':
            // Don't need to do anything.
            // They update the user's menteeStatus directly in cache.
            break;
          default:
            break;
        }
      }
    });
    this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetMentorStatus, {}, 'cache-first').then(result => {
      result.match({
        ok: data => {
          runInAction('set initial mentor status', () => {
            this._mentorStatus = data;
          });
        },
        error: err => {
          // ignore error
          console.error(err);
        }
      });
    });
  }
  private handlePotentialMenteeAddedEvent = async (event: PotentialMenteeAddedEventFragment): Promise<void> => {
    const service = await this.getNotificationBarService();
    if (this.nativeWebViewService.isEnabled) {
      await this.nativeWebViewService.renderMenteeNotification({
        title: this.i18n.format(NEW_MENTEE_FOUND_FORMAT),
        ctaText: this.i18n.format(WRITE_MESSAGE_FORMAT),
        gender: mapGender(event.user.gender),
        age: event.user.age,
        nick: event.user.nick,
        onlineChannel: event.user.currentOnlineChannelName,
        onPress: async () => {
          handlePress(await this.getAppService(), await this.getMessengerNavigationService(), event.user.conversationId);
        }
      });
      return;
    }
    service.showNotification({
      renderContent: close => <PotentialMenteeAddedNotificationContent user={event.user} close={close} />
    });
  };
}