import { $ViewProvider } from '@knuddels-app/layout';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { settingsModule } from '@knuddelsModules/Settings/module';
import { ContactsViewProvider } from './ContactsViewProvider';
import * as providedServices from './providedServices';
import { autocompleteInputBarModule } from '@knuddelsModules/AutocompleteInputBar/module';
import { ContactsNavBarItemProvider } from './ContactsNavBarItemProvider';
import { mentorSystemModule } from '@knuddelsModules/MentorSystem/module';

export const contactsModule = new Module({
	name: 'Contacts',
	dependencies: [
		userDataModule,
		settingsModule,
		autocompleteInputBarModule,
		mentorSystemModule,
	],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(ContactsViewProvider);
		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toSingleton(ContactsNavBarItemProvider);
	},
});
