import * as React from 'react';
import { useHovered } from '@knuddels-app/tools/useHovered';
import { Box, Text, TextProps } from '@knuddels/component-library';
export const HighlightTextLink: React.FC<Omit<TextProps, 'darkColor'> & {
  onPress: () => void;
  hoverState: TextProps['state'];
}> = ({
  onPress,
  state,
  color,
  hoverState,
  ...textProps
}) => {
  const {
    bind,
    hovered
  } = useHovered();
  const defaultState = state || 'highlighted';
  const hoveredState = hoverState || defaultState;
  const usedState = hovered ? hoveredState : defaultState;
  const styleProps = color ? {
    color
  } : {
    state: usedState
  };
  return <Box as={'span'} {...bind} onPress={onPress} cursor={'pointer'}>
			<Text {...textProps} {...(styleProps as any)} />
		</Box>;
};