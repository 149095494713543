import * as u from "react";
import { ErrorWithDocsReference as o } from "../../docs/helper.js";
import { createAccessiblePointerEventHandler as i } from "../../a11y/createAccessiblePointerEventHandler.js";
const d = u.createContext(
  null
), s = () => {
  const e = u.useContext(d);
  if (!e)
    throw new o(
      "The RadioButton component needs to be wrapped inside of a RadioGroup",
      "api/radiobutton"
    );
  return e;
}, R = (e) => {
  const n = s(), c = n.disabled || e.disabled || !1;
  return {
    accessibilityProps: i({
      ariaRole: "radio",
      disabled: c,
      pointerCallback() {
        var t, a, l;
        (t = n.onChange) == null || t.call(n, { value: e.value }), (a = n.onBlur) == null || a.call(n), (l = e.onBlur) == null || l.call(e);
      }
    }),
    onFocus: () => {
      var t, a;
      (t = n.onFocus) == null || t.call(n), (a = e.onFocus) == null || a.call(e);
    },
    onBlur: () => {
      var t, a;
      (t = n.onBlur) == null || t.call(n), (a = e.onBlur) == null || a.call(e);
    },
    selected: e.value === n.value,
    disabled: c
  };
};
export {
  d as RadioContext,
  R as useRadioButton,
  s as useRadioContext
};
