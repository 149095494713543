import { createGlobalStyle as o } from "styled-components";
const t = o`
	html {
		overflow: hidden;
	}
  html, body {
    margin: 0;
    padding: 0;
		user-select: none;
		-webkit-user-select: none;
	    -webkit-tap-highlight-color: transparent;
  }
  *, *:before, *:after { box-sizing: border-box; }
`, a = o`
	*::-webkit-scrollbar {
		background-color: ${(r) => r.scrollbarBackgroundColor};
		width: 16px;
	}

	*::-webkit-scrollbar-track {
		background-color: transparent;
	}

	*::-webkit-scrollbar-thumb {
		background-color: ${(r) => r.scrollBarThumbColor};
		border-radius: 16px;
		border: 4px solid transparent;
	  	background-clip: padding-box;
	}

	*::-webkit-scrollbar-button {
		display:none;
	}
	
	* {
	  scrollbar-color: ${(r) => r.scrollBarThumbColor} ${(r) => r.scrollbarBackgroundColor};
	  scrollbar-width: thin;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
`;
export {
  a as CustomScrollBarStyles,
  t as GlobalStyle
};
