import { jsx as c } from "react/jsx-runtime";
import { clsx as h } from "../../node_modules/clsx/dist/clsx.m.js";
import "./Link.scss.js";
import { resolveThemeColor as s } from "../Layout/isDarkColor.js";
import { useTheme as d } from "../../themes/useTheme.js";
const u = {
  same: "_self",
  new: "_blank"
}, v = ({
  children: o,
  to: i,
  type: r = "body2",
  openInLocation: a = "new",
  disabled: m,
  onPress: e,
  color: t,
  state: l = "highlighted",
  bold: f = !1,
  italic: n = !1
}) => /* @__PURE__ */ c(
  "a",
  {
    className: h(
      "Knu-Text Knu-Link",
      l,
      r,
      f && "bold",
      n && "italic"
    ),
    "aria-disabled": m,
    target: u[a],
    href: i,
    onClick: (p) => {
      i || p.preventDefault(), e == null || e();
    },
    style: t ? {
      [`--text-colors-${l}`]: s(
        t,
        d()
      )
    } : {},
    children: o
  }
);
export {
  v as Link
};
