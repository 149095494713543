import * as providedServices from './providedServices';
import { Module } from '@knuddels-app/ModuleSystem';
import { $ViewProvider } from '@knuddels-app/layout';
import { ProfileViewProvider } from '@knuddelsModules/Profile/ProfileViewProvider';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { adsModule } from '@knuddelsModules/Ads/module';
import { contactsModule } from '@knuddelsModules/Contacts/module';

export const profileModule = new Module({
	name: 'ProfileModule',
	dependencies: [userDataModule, adsModule, contactsModule],
	loadEvent: 'onDemand',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	providedServices,
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(ProfileViewProvider);
	},
});
