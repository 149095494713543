const DEFAULT_SWIPE_THRESHOLD_VELOCITY = 0.1;
const DEFAULT_X_POSITION_DELTA_THRESHOLD = 10;

export function isSuccessfulSwipe(
	deltaX: number,
	deltaTime: number,
	velocityThreshold = DEFAULT_SWIPE_THRESHOLD_VELOCITY,
	xPosDeltaThreshold = DEFAULT_X_POSITION_DELTA_THRESHOLD
): boolean {
	if (Math.abs(deltaX) < xPosDeltaThreshold) {
		return false;
	}

	const velocity = Math.abs(deltaX / deltaTime);

	return velocity >= velocityThreshold;
}
