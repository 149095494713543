export type DeepPartial<T> = {
	[K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export function deepCopy<T extends Record<string, any>>(
	obj: T,
	copy: DeepPartial<T>
): T {
	const newObj: Record<string, any> = {} as Record<string, any>;
	for (const key in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (!obj.hasOwnProperty(key)) {
			continue;
		}

		const valueToCopy = copy[key];

		if (valueToCopy === undefined || valueToCopy === null) {
			newObj[key] = obj[key];
			continue;
		}

		if (Array.isArray(valueToCopy)) {
			newObj[key] = valueToCopy;
			continue;
		}

		if (typeof valueToCopy !== 'object') {
			newObj[key] = copy[key];
			continue;
		}

		newObj[key] = deepCopy(obj[key], copy[key]!);
	}

	for (const key in copy) {
		// eslint-disable-next-line no-prototype-builtins
		if (!obj.hasOwnProperty(key)) {
			newObj[key] = copy[key];
		}
	}

	return newObj as T;
}
