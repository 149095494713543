import {
	inject,
	injectable,
	injectProps,
} from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { $UserService } from '@knuddelsModules/UserData';

@injectable()
export class ChannelHighlightedLinkColorModel {
	constructor(
		@injectProps()
		private readonly props: unknown,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($ActiveChannelService)
		private readonly activeChannelService: typeof $ActiveChannelService.T
	) {}

	public readonly getHighlightedLinkColor = (
		text: string,
		href: string
	): string | undefined => {
		const currentUser = this.userService.currentUser;
		const linkUser = getUserFromHref(href);

		return (
			currentUser &&
			currentUser.nick === linkUser &&
			this.activeChannelService.activeChannel &&
			colorToRgbaString({
				...this.activeChannelService.activeChannel.groupInfo
					.highlightColor,
				alpha: 0.88,
			})
		);
	};
}

function getUserFromHref(href: string): string | undefined {
	if (href.startsWith('/w ')) {
		return href.split('/w ')[1];
	}
}
