import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import {
	ConversationListFilterType,
	MessengerConversation,
	MessengerConversationReadStateDocument,
	MessengerConversationReadStateQuery,
	MessengerConversationReadStateQueryVariables,
	MessengerMarkConversationAsUnread,
	MessengerMarkConversationsAsRead,
} from '@generated/graphql';
import { isConversationUnread } from '../utils/conversationReadStateHelper';
import {
	$MessengerConversationService,
	$MessengerListService,
	$MessengerMiniChatService,
	$MessengerOverviewService,
	$MessengerService,
	$NewMessageService,
} from '@knuddelsModules/Messenger/providedServices';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $UserService } from '@knuddelsModules/UserData';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $Environment } from '@knuddels-app/Environment';
import { reactionWhen } from '@knuddels-app/mobx';
import { $ClientSettingsService } from '@knuddelsModules/Settings';

@injectable()
export class ReadSystemService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($MessengerService)
		private readonly messengerService: typeof $MessengerService.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($MessengerConversationService)
		private readonly messengerConversationService: typeof $MessengerConversationService.T,
		@inject($MessengerListService)
		private readonly messengerListService: typeof $MessengerListService.T,
		@inject($ClientSettingsService)
		private readonly clientSettingsService: typeof $ClientSettingsService.T,
		@inject($MessengerOverviewService)
		private readonly messengerOverviewService: typeof $MessengerOverviewService.T,
		@inject($MessengerMiniChatService)
		private readonly messengerMiniChatService: typeof $MessengerMiniChatService.T
	) {
		this.dispose.track(
			reactionWhen(
				{
					name: 'Mark Current Conversation Active when app has focus',
				},
				() => this.environment.hasFocus,
				() => this.markCurrentConversationActive()
			)
		);
	}

	public markAllConversationsAsRead(): void {
		const shownConversations =
			this.clientSettingsService.conversationListFilterType ===
			ConversationListFilterType.UnreadMessages
				? this.messengerListService.unreadConversations
				: this.messengerListService.unarchivedConversations;

		const unreadConversationIds = shownConversations
			.filter(isConversationUnread)
			.map(conv => conv.id);
		if (unreadConversationIds.length > 0) {
			this.markConversationsAsRead(unreadConversationIds);
		}
	}

	public handleConversationOpened(
		conversationId: MessengerConversation['id']
	): void {
		const clientConversationState =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversationId
			);

		const result = this.authenticatedClientService.currentClient.readQuery<
			MessengerConversationReadStateQuery,
			MessengerConversationReadStateQueryVariables
		>({
			query: MessengerConversationReadStateDocument,
			variables: {
				id: conversationId,
			},
		});
		if (!result || !result.messenger.conversation) {
			return;
		}
		const currentConversation = result.messenger.conversation;

		const lastReadMessage =
			currentConversation.readState.lastReadConversationMessage;
		clientConversationState.setMessageIdBeforeUnreadDivider(
			isConversationUnread(currentConversation) && lastReadMessage
				? lastReadMessage.id
				: undefined
		);
	}

	markUnreadConversationAsRead(
		conversationId: MessengerConversation['id']
	): void {
		if (this.isConversationUnread(conversationId)) {
			this.markConversationsAsRead([conversationId]);
		}
	}

	markConversationsAsRead(
		conversationIds: MessengerConversation['id'][]
	): void {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(MessengerMarkConversationsAsRead, {
				ids: conversationIds,
			})
			.onErr(() => {
				this.snackbarService.showGenericError();
			});
	}

	markConversationAsUnread(
		conversationId: MessengerConversation['id']
	): void {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(MessengerMarkConversationAsUnread, {
				id: conversationId,
			})
			.onErr(() => {
				this.snackbarService.showGenericError();
			});
	}

	private isConversationUnread(
		conversationId: MessengerConversation['id']
	): boolean {
		try {
			const result =
				this.authenticatedClientService.currentClient.readQuery<
					MessengerConversationReadStateQuery,
					MessengerConversationReadStateQueryVariables
				>({
					query: MessengerConversationReadStateDocument,
					variables: {
						id: conversationId,
					},
				});

			return isConversationUnread(
				result && result.messenger && result.messenger.conversation
			);
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	private markCurrentConversationActive(): void {
		const currentConversationId =
			this.messengerService.getOpenConversationId();
		if (!currentConversationId) {
			return;
		}
		const cachedConversation =
			this.messengerConversationService.getCachedOverviewConversation(
				currentConversationId
			);
		if (cachedConversation && cachedConversation.readState.markedAsUnread) {
			return;
		}

		this.markUnreadConversationAsRead(currentConversationId);
	}

	public async handleNewMessage({
		conversationMessage,
		conversation,
	}: typeof $NewMessageService.T.onNewMessage.TArgs): Promise<void> {
		let cachedConversation =
			this.messengerConversationService.getCachedOverviewConversation(
				conversation.id
			);
		let increaseUnreadCount =
			this.shouldMessageIncreaseUnreadCount(conversationMessage);
		if (!cachedConversation) {
			const queriedConversation =
				await this.messengerOverviewService.queryConversation(
					conversation.id
				);
			if (
				queriedConversation.latestConversationMessage?.id ===
				conversationMessage.id
			) {
				increaseUnreadCount = false;
			}

			cachedConversation =
				this.messengerConversationService.getCachedOverviewConversation(
					conversation.id
				);
			if (!cachedConversation) {
				// no cached conversation => don't update anything
				return;
			}
		}

		const clientConversationState =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversation.id
			);

		if (this.shouldMessageMarkConversationAsRead(conversationMessage)) {
			clientConversationState.setMessageIdBeforeUnreadDivider(undefined);
			this.markUnreadConversationAsRead(conversation.id);

			// own messages => mark as read, remove divider
			return;
		} else {
			const newUnreadCount =
				(cachedConversation.readState.unreadMessageCount || 0) +
				(increaseUnreadCount ? 1 : 0);

			this.messengerConversationService.updateOverviewConversation({
				...cachedConversation,
				readState: {
					...cachedConversation.readState,
					unreadMessageCount: newUnreadCount,
				},
			});
		}

		const isConversationOpen = this.isConversationOpen(conversation.id);

		if (isConversationOpen) {
			const focused = this.environment.hasFocus;

			if (focused && !cachedConversation.readState.markedAsUnread) {
				this.markUnreadConversationAsRead(conversation.id);
			} else if (!clientConversationState.messageIdBeforeUnreadDivider) {
				const cachedFullConversation =
					this.messengerConversationService.getCachedFullConversation(
						conversation.id
					);
				const lastReadMessage =
					cachedFullConversation &&
					cachedFullConversation.conversationMessages.messages[
						// -2 because the new message was already added to conversation
						cachedFullConversation.conversationMessages.messages
							.length - 2
					];
				if (lastReadMessage) {
					clientConversationState.setMessageIdBeforeUnreadDivider(
						lastReadMessage.id
					);
				}
			}
		}
	}

	private shouldMessageIncreaseUnreadCount = (
		message: (typeof $NewMessageService.T.onNewMessage.TArgs)['conversationMessage']
	) => {
		const currentUser = this.userService.currentUser;
		if (!currentUser) {
			return true;
		}

		if (currentUser.id !== message.sender.id) {
			return true;
		}

		const typename = message.content.__typename;
		return (
			typename !== 'ConversationDeletedPhotoCommentMessageContent' &&
			typename !== 'ConversationHiddenPhotoCommentMessageContent' &&
			typename !== 'ConversationVisiblePhotoCommentMessageContent'
		);
	};

	private shouldMessageMarkConversationAsRead = (
		message: (typeof $NewMessageService.T.onNewMessage.TArgs)['conversationMessage']
	) => {
		const currentUser = this.userService.currentUser;
		if (!currentUser) {
			return false;
		}

		if (currentUser.id !== message.sender.id) {
			return false;
		}

		const typename = message.content.__typename;
		return (
			typename === 'ConversationTextMessageContent' ||
			typename === 'ConversationForwardedMessageContent' ||
			typename === 'ConversationImageMessageContent' ||
			typename === 'ConversationQuotedMessageContent' ||
			typename === 'ConversationSnapMessageContent'
		);
	};

	private isConversationOpen(convId: MessengerConversation['id']): boolean {
		const currentConversationId =
			this.messengerService.getOpenConversationId();
		const miniChatConversationId =
			this.messengerMiniChatService.activeConversationId;

		return (
			(!!currentConversationId &&
				currentConversationId.toString() === convId) ||
			(!!miniChatConversationId?.toString() &&
				miniChatConversationId === convId)
		);
	}
}
