import { Checkbox, Flex, FlexCol, NumberInput, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import React, { useEffect, useState } from 'react';
import { TanEnabled, TanEnabledInput } from '@generated/graphql';
import { observer } from '@knuddels-app/mobx';
import { useTrackUpdateTanSettings } from './useTrackTanSettings';
import { $TanSystemService } from '../../../../providedServiceIds';
import { NickSwitch } from '@knuddelsModules/FeatureFlags';
export const TanSystemSettings: React.FC<{
  status: TanEnabled;
}> = observer('TanSystemSettings', ({
  status
}) => {
  const i18n = useService($I18n);
  const tanService = useService($TanSystemService);
  const [settings, setSettings] = useState(status);
  const track = useTrackUpdateTanSettings();
  useEffect(() => {
    setSettings(status);
  }, [status, tanService.status]);
  const updateSettings = async (input: TanEnabledInput): Promise<void> => {
    const oldSettings = settings;
    setSettings({
      ...settings,
      ...input
    });
    const ok = await tanService.update(input);
    if (!ok) {
      setSettings(oldSettings);
      return;
    }
    track(input);
  };
  if (!settings) {
    return null;
  }
  return <div className={_c0 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Text type={'subtitle'} bold={true} className={_c1}>
				<FormattedMessage id={declareFormat({
        id: 'settings.tanSettings.title',
        defaultFormat: 'One-time SMS confirmation for'
      })} />
			</Text>
			<div className={_c2}>
				<Checkbox label={declareFormat({
        id: 'settings.tanSettings.mobileNumberChange',
        defaultFormat: 'Change of mobile number'
      }).format(i18n)} value={settings.confirmMobileNumberChange} onChange={({
        value
      }) => updateSettings({
        confirmMobileNumberChange: value
      })} />
				<Checkbox label={declareFormat({
        id: 'settings.tanSettings.emailChange',
        defaultFormat: 'Change of email address'
      }).format(i18n)} value={settings.confirmEmailChange} onChange={({
        value
      }) => updateSettings({
        confirmEmailChange: value
      })} />
				<Checkbox label={declareFormat({
        id: 'settings.tanSettings.passwordChange',
        defaultFormat: 'Change of password'
      }).format(i18n)} value={settings.confirmPasswordChange} onChange={({
        value
      }) => updateSettings({
        confirmPasswordChange: value
      })} />
			</div>

			<div className={_c3}>
				<Checkbox label={declareFormat({
        id: 'settings.tanSettings.smileyTrade',
        defaultFormat: 'Trade of smileys'
      }).format(i18n)} value={settings.confirmSmileyTrade} onChange={({
        value
      }) => updateSettings({
        confirmSmileyTrade: value
      })} />
				<Checkbox label={declareFormat({
        id: 'settings.tanSettings.smileyCodeActivation',
        defaultFormat: 'Activation of smiley-codes'
      }).format(i18n)} value={settings.confirmSmileyCodeUse} onChange={({
        value
      }) => updateSettings({
        confirmSmileyCodeUse: value
      })} />
			</div>

			<TransferKnuddelSetting settings={settings} updateSettings={updateSettings} />
			<NickSwitch.OnActive>
				<Checkbox label={declareFormat({
        id: 'settings.tanSettings.nickSwitch',
        defaultFormat: 'Change of nickname'
      }).format(i18n)} value={settings.confirmNickSwitch} onChange={({
        value
      }) => updateSettings({
        confirmNickSwitch: value
      })} />
			</NickSwitch.OnActive>
		</div>;
});
const TransferKnuddelSetting: React.FC<{
  settings: TanEnabled;
  updateSettings: (newSettings: TanEnabledInput) => void;
}> = ({
  settings,
  updateSettings
}) => {
  const i18n = useService($I18n);
  return <div className={_c4}>
			<Checkbox label={declareFormat({
      id: 'settings.tanSettings.knuddelTransfer',
      defaultFormat: 'Transfer of Knuddel'
    }).format(i18n)} value={settings.confirmKnuddelTransfer} onChange={({
      value
    }) => updateSettings({
      confirmKnuddelTransfer: value
    })} />
			{settings.confirmKnuddelTransfer && <div className={_c5}>
					<NumberInput size={'condensed'} value={settings.confirmKnuddelTransferAmount} min={1} max={32000} onChange={({
        value
      }) => updateSettings({
        confirmKnuddelTransferAmount: value
      })} />
				</div>}
		</div>;
};
const _c0 = " Knu-FlexCol p-base bg-transparent ";
const _c1 = " mb-base ";
const _c2 = " Knu-FlexCol mb-base ";
const _c3 = " Knu-FlexCol mb-base ";
const _c4 = " Knu-FlexCol ";
const _c5 = " Knu-Flex pl-xxlarge ";