import * as React from 'react';
import { isMobileSafari } from './isMobileSafari';

export const useHovered = () => {
	const [hovered, setHovered] = React.useState(false);

	return {
		hovered,
		bind: isMobileSafari()
			? {
					onHover: undefined,
					onHoverEnd: undefined,
			  }
			: {
					onHover: () => setHovered(true),
					onHoverEnd: () => setHovered(false),
			  },
	};
};
