import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { ClientInfoInterface } from '@knuddels-app/tools/clientInformation/ClientInfo.interface';
import { ClientInfo } from '@knuddels-app/tools/clientInformation/ClientInfo';
import {
	fromPromise,
	IPromiseBasedObservable,
	observable,
} from '@knuddels-app/mobx';
import {
	getVersion,
	Version,
} from '@knuddels-app/tools/clientInformation/version';
import { $Environment } from '@knuddels-app/Environment';
import { Device } from '@capacitor/device';

@injectable()
export class ClientInfoStore {
	public readonly dispose = Disposable.fn();

	@observable
	private deviceIdentifier: string | undefined;
	private clientInfoObservable:
		| IPromiseBasedObservable<ClientInfoInterface>
		| undefined;

	constructor(
		@inject($Environment)
		private readonly environment: typeof $Environment.T
	) {}

	private async getOrCreateDeviceIdentifier(): Promise<string> {
		if (!this.deviceIdentifier) {
			this.deviceIdentifier = (await Device.getId()).identifier;
		}

		return this.deviceIdentifier;
	}

	private async createClientInfo(): Promise<ClientInfoInterface> {
		if (this.environment.messengerSystemAppInterface) {
			// use stapp uids
			const deviceIdentifiers = this.environment
				.messengerSystemAppInterface.client.pageData.deviceIdentifiers;
			return new ClientInfo(deviceIdentifiers);
		}

		const deviceIdentifier = await this.getOrCreateDeviceIdentifier();
		return new ClientInfo([deviceIdentifier]);
	}

	private getOrCreateClientInfoObservable(): IPromiseBasedObservable<
		ClientInfoInterface
	> {
		if (!this.clientInfoObservable) {
			this.clientInfoObservable = fromPromise(this.createClientInfo());
		}
		return this.clientInfoObservable;
	}

	public async getOrCreateClientInfo(): Promise<ClientInfoInterface> {
		return this.getOrCreateClientInfoObservable().then(it => it);
	}

	public get clientInfo(): ClientInfoInterface | undefined {
		return this.getOrCreateClientInfoObservable().case({
			fulfilled: info => info,
			pending: () => undefined,
			rejected: () => undefined,
		});
	}

	public get clientVersion(): Version {
		if (this.clientInfo) {
			return this.clientInfo.version;
		} else {
			return getVersion();
		}
	}
}
