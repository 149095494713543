import { createMotionComponent as i } from "../../motion/index.js";
import { createMotionProxy as n } from "./motion-proxy.js";
import { createDomMotionConfig as r } from "./utils/create-config.js";
import { gestureAnimations as a } from "../../motion/features/gestures.js";
import { animations as p } from "../../motion/features/animations.js";
import { drag as f } from "../../motion/features/drag.js";
import { createDomVisualElement as t } from "./create-visual-element.js";
import { layout as s } from "../../motion/features/layout.js";
const m = {
  ...p,
  ...a,
  ...f,
  ...s
}, x = /* @__PURE__ */ n((o, e) => r(o, e, m, t));
function y(o) {
  return i(r(o, { forwardMotionProps: !1 }, m, t));
}
export {
  y as createDomMotionComponent,
  x as motion
};
