import { Flex, IconClose, createAccessiblePointerEventHandler, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import * as React from 'react';
export const DeclineRequestButton: React.FC<{
  onPress: () => void;
}> = ({
  onPress
}) => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} className={_c0 + ("rgb(250,250,250)" ? resolveIsDarkColor("rgb(250,250,250)", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<IconClose size={'base'} />
		</div>;
};
const _c0 = " Knu-Flex cursor-pointer height-44px borderRadius-22px borderStyle-solid borderWidth-xlarge borderColor-rgb-224-224-224- bg-rgb-250-250-250- placeItems-center px-large ";