import { jsx as m } from "react/jsx-runtime";
import * as l from "react";
import "./SvgRenderer.scss.js";
import { useColor as c } from "../Layout/hooks/useColor.js";
const d = ({
  width: r,
  height: o,
  svg: t,
  ...n
}) => {
  const e = l.useRef(null), s = c(n, e);
  return /* @__PURE__ */ m(
    "div",
    {
      ref: e,
      className: "Knu-SvgRenderer",
      style: {
        width: r,
        height: o,
        color: s
      },
      dangerouslySetInnerHTML: { __html: t }
    }
  );
};
export {
  d as SvgRenderer
};
