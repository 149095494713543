// Unfortunately not really doing much on web
// (only checks if a network/LAN is available and not if there is an internet connection
// see https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine)
export function onInternetAvailable(cb: () => void): void {
	if (window.navigator.onLine) {
		cb();
	} else {
		const listener = () => {
			cb();
			window.removeEventListener('online', listener);
		};
		window.addEventListener('online', listener);
	}
}
