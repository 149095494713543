import * as React from 'react';
import { Box, Flex, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
interface Props {
  onDark?: boolean;
  testId?: string;
}
export const Quote: React.FC<Props> = React.memo(({
  children
}) => {
  return <div className={_c0}>
			<div className={_c1 + ("hairlineAtom" ? resolveIsDarkColor("hairlineAtom", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			{children}
		</div>;
});
Quote.displayName = 'Quote';
const _c0 = " Knu-Flex ml-small position-relative overflow-hidden ";
const _c1 = " Knu-Box height-100-percent width-2px bg-hairlineAtom mr-minor ";