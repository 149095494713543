import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { action, observable } from '@knuddels-app/mobx';
import { $NavHidingService } from '@knuddelsModules/LoggedInArea';

@injectable()
export class ChannelAppViewerAnimationService implements Disposable {
	public readonly dispose = Disposable.fn();

	@observable
	state: ViewerState = 'hidden';

	private readonly toggleListeners: ((state: ViewerState) => void)[] = [];

	constructor(
		@inject($NavHidingService)
		private readonly navHidingService: typeof $NavHidingService.T
	) {}

	@action.bound
	setState(newState: ViewerState): void {
		this.state = newState;
		this.toggleListeners.forEach(listener => listener(newState));
		this.navHidingService.setHidden(newState === 'full');
	}

	registerToggleListener(listener: (state: ViewerState) => void): () => void {
		this.toggleListeners.push(listener);
		return () => {
			this.toggleListeners.splice(
				this.toggleListeners.indexOf(listener),
				1
			);
		};
	}
}

export type ViewerState = 'hidden' | 'minimized' | 'split' | 'full';
