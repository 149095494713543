import { buildHTMLStyles as b } from "../../html/utils/build-styles.js";
import { calcSVGTransformOrigin as p } from "./transform-origin.js";
import { buildSVGPath as v } from "./path.js";
function S(f, {
  attrX: o,
  attrY: s,
  attrScale: m,
  originX: i,
  originY: e,
  pathLength: u,
  pathSpacing: l = 1,
  pathOffset: t = 0,
  // This is object creation, which we try to avoid per-frame.
  ...y
}, x, c) {
  if (b(f, y, c), x) {
    f.style.viewBox && (f.attrs.viewBox = f.style.viewBox);
    return;
  }
  f.attrs = f.style, f.style = {};
  const { attrs: n, style: r, dimensions: d } = f;
  n.transform && (d && (r.transform = n.transform), delete n.transform), d && (i !== void 0 || e !== void 0 || r.transform) && (r.transformOrigin = p(d, i !== void 0 ? i : 0.5, e !== void 0 ? e : 0.5)), o !== void 0 && (n.x = o), s !== void 0 && (n.y = s), m !== void 0 && (n.scale = m), u !== void 0 && v(n, u, l, t, !1);
}
export {
  S as buildSVGAttrs
};
