import { ConversationMessageFragment } from '@generated/graphql';

export function isPhotoCommentMessage(
	message: ConversationMessageFragment
): boolean {
	const contentTypename = message.content.__typename;
	return (
		contentTypename === 'ConversationDeletedPhotoCommentMessageContent' ||
		contentTypename === 'ConversationVisiblePhotoCommentMessageContent' ||
		contentTypename === 'ConversationHiddenPhotoCommentMessageContent'
	);
}
