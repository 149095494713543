import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { autorun, computed, observable, runInAction } from '@knuddels-app/mobx';
import { Overlay, OverlayProvider } from '@knuddels-app/overlays/OverlayService';
import { $OverlayService } from '@knuddels-app/overlays/serviceIds';
import { Optional } from '@knuddels-app/tools/types';
import { Disposable } from '@knuddels/std';
import { Z_INDEX } from '@shared/components';
import * as React from 'react';
import { OverlayView } from './OverlayView';
import { VisibleView } from './ViewService';
import { $ViewService } from './serviceIds';
@injectable()
export class ViewOverlayProvider implements OverlayProvider {
  public readonly dispose = Disposable.fn();
  @observable.ref
  private displayedOverlays: DisplayOverlay[] = [];
  constructor(@inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($OverlayService)
  overlayService: typeof $OverlayService.T) {
    this.dispose.track(overlayService.registerOverlayProvider(this));
    this.dispose.track(autorun({
      name: 'update displayed overlays'
    }, () => {
      const newOverlays = [...this.viewService.layout.overlays, ...this.viewService.layout.additionalOverlays];
      runInAction('set displayed overlays', () => {
        if (this.displayedOverlays.length === 0 && newOverlays.length === 0) {
          return;
        }
        this.displayedOverlays = mergeOldAndNewOverlays(this.displayedOverlays, newOverlays);
      });
    }));
  }
  @computed
  get overlays(): Overlay[] {
    return this.displayedOverlays.map((o, i) => {
      const isSystemApp = !!o.visibleView.viewConfig.isSystemApp;
      return {
        id: `${i}-${o.addedAt}`,
        view: <OverlayView isSystemApp={isSystemApp} zIndex={Z_INDEX.OVERLAY + i} overlay={o.visibleView.renderedView.mainView} isTopOverlay={i === this.displayedOverlays.length - 1} hide={o.hide} onSwipeDown={() => {
          o.visibleView.close();
        }} onHidden={() => {
          runInAction('remove displayed overlay', () => {
            this.displayedOverlays = this.displayedOverlays.filter(p => !p.visibleView.equals(o.visibleView));
          });
        }} />,
        tag: o.visibleView.viewConfig.viewId.id,
        addedAt: o.addedAt,
        backgroundType: 'transparent',
        dispose: () => {
          o.visibleView.close();
        }
      };
    });
  }
}
function mergeOldAndNewOverlays(prevOverlays: DisplayOverlay[], newOverlays: VisibleView[]): DisplayOverlay[] {
  const newDisplayOverlays: DisplayOverlay[] = [];
  const isVisibleViewEqual = (v1: VisibleView, v2: VisibleView): boolean => {
    /*
    We have to separate to cases/visibleViews:
    	1. only allow a single overlay (like album, profile...)
    		=> we should only compare viewIds so they can update their viewState without closing/opening the overlay.
    	2. allow multiple overlays (like system apps)
    		=> they always have the same viewId so we should also compare the viewState.
    */
    return v1.viewConfig.allowMultipleOverlays ? v1.equals(v2) : v1.viewConfig.viewId.equals(v2.viewConfig.viewId);
  };
  const actualNewOverlays = [...newOverlays];
  prevOverlays.forEach(oldOverlay => {
    const newOverlayIndex = actualNewOverlays.findIndex(v => isVisibleViewEqual(v, oldOverlay.visibleView));
    const newOverlay = actualNewOverlays[newOverlayIndex];
    if (newOverlay) {
      newDisplayOverlays.push(createDisplayOverlay({
        visibleView: newOverlay,
        addedAt: oldOverlay.addedAt
      }));
      actualNewOverlays.splice(newOverlayIndex, 1);
    } else {
      newDisplayOverlays.push(createDisplayOverlay({
        visibleView: oldOverlay.visibleView,
        hide: true,
        addedAt: oldOverlay.addedAt
      }));
    }
  });
  actualNewOverlays.forEach(newOverlay => {
    newDisplayOverlays.push(createDisplayOverlay({
      visibleView: newOverlay
    }));
  });
  return newDisplayOverlays;
}
type DisplayOverlay = {
  visibleView: VisibleView;
  hide: boolean;
  addedAt: number;
};
function createDisplayOverlay({
  visibleView,
  hide = false,
  addedAt
}: Optional<DisplayOverlay, 'hide' | 'addedAt'>): DisplayOverlay {
  return {
    visibleView,
    hide,
    addedAt: addedAt ?? Date.now()
  };
}