import { BoxProps, FlexCol, FlexProps, ThemeOverride } from '@knuddels/component-library';
import * as React from 'react';
import { Container, Row, Spacer } from '../../atoms';
interface Props {
  hasArrow?: boolean;
  color?: string;
  align?: 'left' | 'right';
  shadow?: boolean;
  additionalMargin?: number;
  noPadding?: boolean;
  children: React.ReactNode;
}
export const MESSAGE_BUBBLE_PADDING_HORIZONTAL = 8;
export class MessageBubble extends React.PureComponent<Props> {
  render(): JSX.Element {
    const {
      align = 'left',
      color = 'rgba(0, 0, 0, 0.42)',
      shadow,
      noPadding
    } = this.props;
    const arrowStyles = align === 'left' ? {
      ...styles.arrow,
      ...styles.arrowLeft,
      borderRightColor: color
    } : {
      ...styles.arrow,
      ...styles.arrowRight,
      borderLeftColor: color
    };
    const elevation = 1;
    const arrow = <Spacer size="small" style={{
      elevation: elevation,
      position: 'relative'
    }}>
				{this.props.hasArrow && <Container style={arrowStyles} />}
			</Spacer>;
    const paddingsStyles = noPadding ? {} : ({
      pt: (8 as ThemeOverride),
      pb: (9 as ThemeOverride),
      pl: (MESSAGE_BUBBLE_PADDING_HORIZONTAL as ThemeOverride),
      pr: (MESSAGE_BUBBLE_PADDING_HORIZONTAL as ThemeOverride)
    } as BoxProps);
    const withOrWithoutShadow = <FlexCol position={'relative'} overflow={'hidden'} shadow={shadow ? 'Shadow1' : 'none'} {...styles.bubble} bg={(color as ThemeOverride)} {...paddingsStyles}>
				{this.props.children}
			</FlexCol>;
    if (align === 'left') {
      return <Row>
					{arrow}
					<Row fluid style={{
          marginRight: this.props.additionalMargin,
          marginBottom: shadow ? 2 : undefined
        }}>
						{withOrWithoutShadow}
					</Row>
					<Spacer size="small" />
				</Row>;
    } else {
      return <Row>
					<Spacer size="small" />
					<Row fluid style={{
          marginLeft: this.props.additionalMargin,
          marginBottom: shadow ? 2 : undefined
        }}>
						<Container style={{
            flex: 1
          }} />
						{withOrWithoutShadow}
					</Row>
					{arrow}
				</Row>;
    }
  }
}
const arrowWidth = 4;
const arrowHeight = 10;
const arrowOffset = 15; // 16/17? (image height/2 - arrowHeight/2)?
const styles = {
  bubble: ({
    borderRadius: (8 as ThemeOverride),
    minHeight: 36,
    flexShrink: 1,
    maxWidth: '100%',
    overflow: 'visible'
  } as FlexProps),
  arrow: {
    position: 'absolute',
    top: arrowOffset,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: arrowHeight / 2,
    borderBottomWidth: arrowHeight / 2,
    // need all 3 for android, can't just do borderColor
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  arrowLeft: {
    right: 0,
    borderLeftColor: 'transparent',
    borderRightWidth: arrowWidth,
    borderLeftWidth: 0
  },
  arrowRight: {
    left: 0,
    borderRightColor: 'transparent',
    borderLeftWidth: arrowWidth,
    borderRightWidth: 0
  }
};