import { action, observable } from '@knuddels-app/mobx';
import { Endpoint, EndpointUrls } from './Endpoint';
import { $Environment } from '@knuddels-app/Environment';
import { idToKnownEndpointId } from './KnownEndpoints';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $EndpointProvider } from '../serviceIds';
import {
	$LocalStorage,
	LocalStorageKey,
	stringOrUndefinedSerializer,
} from '@knuddels-app/local-storage';
import { OS, os } from '@shared/components/tools/os';
// tslint:disable: member-ordering

const lastEndpointStorageKey = new LocalStorageKey({
	name: 'LastEndpoint',
	serializer: stringOrUndefinedSerializer,
	cookieExpires: { inDays: 365 },
});

const localOverrideDomainStorageKey = new LocalStorageKey({
	name: 'LocalOverrideDomain',
	serializer: stringOrUndefinedSerializer,
	cookieExpires: { inDays: 365 },
});

// This class might not be needed if this state is stored in the View Model and must not be accessed by any sagas.
@injectable()
export class CurrentEndpointStore {
	private _localOverrideDomain: string | undefined = undefined;

	constructor(
		@inject($EndpointProvider)
		private readonly endpointProvider: typeof $EndpointProvider.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		this.loadLastUsedEndpointInternal();
	}

	public get localOverrideDomain(): string | undefined {
		return this._localOverrideDomain;
	}

	private loadLastUsedEndpointInternal(): void {
		if (os !== OS.web) {
			const overrideDomain = this.localStorage
				.getEntry(localOverrideDomainStorageKey)
				.get();
			const endpointId = this.localStorage
				.getEntry(lastEndpointStorageKey)
				.get();

			this._localOverrideDomain = overrideDomain;

			const endpoint = this.endpointProvider
				.getEndpoints()
				.find(it => it.id === endpointId);
			if (endpoint) {
				this.setEndpoint(endpoint);
			}
		}
	}

	public get currentEndpoint(): Endpoint {
		return this._currentEndpoint;
	}

	public get origin(): string {
		if (this.environment.browserInterface) {
			return this.environment.browserInterface.getBrowserLocation()
				.origin;
		}
		return this.currentEndpoint.urls.defaultOrigin;
	}

	@observable
	private _currentEndpoint = this.endpointProvider.getDefaultEndpoint();

	public setLocalOverrideDomain(domain: string): void {
		this._localOverrideDomain = domain;
		this.localStorage.getEntry(localOverrideDomainStorageKey).set(domain);
	}

	@action('Update current endpoint')
	public setEndpoint(endpoint: Endpoint): void {
		if (this.environment.nativeInterface) {
			this.environment.nativeInterface.setServer(
				idToKnownEndpointId(endpoint.id)
			);
		}
		this._currentEndpoint = endpoint;

		if (endpoint.id === 'Local' && this.localOverrideDomain) {
			const isEndpointIp = this.localOverrideDomain.match(
				/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
			);

			const urls: Partial<EndpointUrls> = isEndpointIp
				? {
						graphQl: `http://${this.localOverrideDomain}:8806/graphql`,
						graphQlSubscription: `ws://${this.localOverrideDomain}:8806/subscriptions`,
				  }
				: {
						graphQl: `https://${this.localOverrideDomain}/graphql`,
						graphQlSubscription: `wss://${this.localOverrideDomain}/subscriptions`,
				  };

			this._currentEndpoint = this._currentEndpoint
				.withName(`${endpoint.name} (Override)`)
				.withUrls(urls);
		}

		if (os !== OS.web) {
			this.localStorage.getEntry(lastEndpointStorageKey).set(endpoint.id);
		}
	}
}
