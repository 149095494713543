import * as React from 'react';
import { User } from '@generated/graphql';
import { Flex } from '@knuddels/component-library';
import { ThumbnailPhoto } from '../PhotoDisplays/ThumbnailPhoto';
import { useOpenAlbumDetails } from '../../../useOpenAlbumDetails';
import { UploadAlbumPhotoButton } from './PhotoUpload/UploadAlbumPhotoButton';
import { expectUnreachable } from '@knuddels/std';
import { UploadingThumbnailPhoto } from '../PhotoDisplays/UploadingThumbnailPhoto';
import { AlbumsEditor } from './EditProfile/EditProfileContent/logic/AlbumsEditor';
import { ChangeProfilePhotoButton } from './PhotoUpload/ChangeProfilePhotoButton';
export interface PhotoRowPhoto {
  type: 'photo';
  photoId: string;
  url: string;
}
export interface PhotoRowAddPhoto {
  type: 'addPhoto';
  albumId: string;
}
export interface PhotoRowChangeProfilePhoto {
  type: 'changeProfilePhoto';
}
export interface PhotoRowUploadingPhoto {
  type: 'uploadingPhoto';
  id: string;
  base64Image: string;
}
export type PhotoRowElement = PhotoRowPhoto | PhotoRowAddPhoto | PhotoRowUploadingPhoto | PhotoRowChangeProfilePhoto;
export const PhotoRow: React.FC<{
  editor?: AlbumsEditor;
  userId: User['id'];
  elements: readonly PhotoRowElement[];
  size: number;
  spacing: number;
  // This component already opens details view on click but can be used to add tracking
  onPhotoClicked?(): void;
}> = React.memo(({
  userId,
  elements,
  size,
  spacing,
  editor,
  onPhotoClicked
}) => {
  const openDetails = useOpenAlbumDetails();
  const onPhotoPress = React.useCallback((photoId: string) => {
    onPhotoClicked?.();
    openDetails({
      userId,
      photoId: photoId
    });
  }, [onPhotoClicked, openDetails, userId]);
  return <div onPointerDown={() => {
    /* this is required for scrolling on ipads. otherwise no touch events are processed */
  }} className={_c0}>
				{elements.map((element, elementIndex) => {
      const ml = elementIndex === 0 ? 'none' : (`${spacing}px` as any);
      switch (element.type) {
        case 'photo':
          return <ThumbnailPhoto key={element.photoId} photoId={element.photoId} editor={editor} url={element.url} size={size} ml={ml} onPress={onPhotoPress} />;
        case 'addPhoto':
          return <div key={'upload' + element.albumId} className={_c1}>
									<UploadAlbumPhotoButton albumId={element.albumId} size={size} ml={ml} />
								</div>;
        case 'uploadingPhoto':
          return <UploadingThumbnailPhoto key={element.id} base64Image={element.base64Image} size={size} ml={ml} />;
        case 'changeProfilePhoto':
          return <ChangeProfilePhotoButton key={'changeProfilePic'} size={size} ml={ml} />;
        default:
          expectUnreachable(element);
      }
    })}
			</div>;
});
PhotoRow.displayName = 'PhotoRow';
const _c0 = " Knu-Flex px-base ";
const _c1 = " Knu-Flex flex-1 ";