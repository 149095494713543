import { Box, CategoryHeader, FlexCol, PersistedVirtualList, ThemeOverride, VirtualListItem, LineDivider, VirtualListRenderItemInfo, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { expectUnreachable } from '@knuddels/std';
import { FriendRequestUserFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $FriendRequestsService } from '../../../providedServices';
import { observer } from '@knuddels-app/mobx';
import { EmptyFriendRequestsPlaceholder } from './EmptyFriendRequestsPlaceholder';
import { SentFriendRequest } from './SentFriendRequest';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { ReceivedFriendRequest } from './ReceivedFriendRequest';
import { useTrackAvailableFriendRequests, useTrackFriendRequestsView } from './useTrackFriendRequestsView';
import { FriendRequest } from '../../services';
export interface GroupHeaderItemInfo extends VirtualListItem {
  type: typeof typeGroupHeader;
  title: string;
}
export interface DividerItemInfo extends VirtualListItem {
  type: typeof typeDivider;
}
export interface SentRequestItemInfo extends VirtualListItem {
  type: typeof typeSentRequest;
  user: FriendRequestUserFragment;
}
export interface ReceivedRequestItemInfo extends VirtualListItem {
  type: typeof typeReceivedRequest;
  user: FriendRequestUserFragment;
  accepted: boolean;
  onAccepted: (user: FriendRequestUserFragment) => void;
  onAcceptError: (user: FriendRequestUserFragment) => void;
  onDeclined: () => void;
}
export interface SpacerItemInfo extends VirtualListItem {
  type: typeof typeSpacer;
}
export type FriendRequestsItemInfo = GroupHeaderItemInfo | DividerItemInfo | SentRequestItemInfo | ReceivedRequestItemInfo | SpacerItemInfo;
export const typeGroupHeader = 'group-header';
export const typeDivider = 'divider';
export const typeSentRequest = 'sentRequest';
export const typeReceivedRequest = 'typeReceivedRequest';
export const typeSpacer = 'typeSpacer';
export const FriendRequests: React.FC<{
  isTabActive: boolean;
}> = observer('FriendRequests', props => {
  const friendRequestsService = useService($FriendRequestsService);
  useTrackAvailableFriendRequests(friendRequestsService);
  const data = useListData(friendRequestsService.receivedFriendRequests, friendRequestsService.sentFriendRequests, props.isTabActive);
  return data.length === 0 ? <div className={_c0}>
				<EmptyFriendRequestsPlaceholder />
			</div> : <PersistedVirtualList gapTop={'base'} renderItem={renderItem} data={data} id="messengerContact" className={_c1} />;
});
const useListData = (receivedRequests: readonly FriendRequest[], sentRequests: readonly FriendRequest[], isActiveTab: boolean): FriendRequestsItemInfo[] => {
  const [acceptedAndReceivedRequests, setRequestAccepted] = useAcceptedAndReceivedRequests(receivedRequests, isActiveTab);
  const i18n = useService($I18n);
  const track = useTrackFriendRequestsView();
  const onAccepted = React.useCallback((u: FriendRequestUserFragment) => {
    track('ReceivedRequest_Accepted');
    setRequestAccepted(u, true);
  }, [setRequestAccepted]);
  const onAcceptError = React.useCallback((u: FriendRequestUserFragment) => {
    setRequestAccepted(u, false);
  }, [setRequestAccepted]);
  const onDeclined = React.useCallback(() => {
    track('ReceivedRequest_Denied');
  }, [track]);
  return React.useMemo(() => {
    const result: FriendRequestsItemInfo[] = [];
    if (acceptedAndReceivedRequests.length > 0) {
      result.push({
        type: typeGroupHeader,
        title: i18n.format(declareFormat({
          id: 'friendrequests.received',
          defaultFormat: 'Received requests ({count})'
        }), {
          count: acceptedAndReceivedRequests.length
        }),
        height: 40
      });
      result.push({
        type: typeSpacer,
        height: 16
      });
      acceptedAndReceivedRequests.forEach((request, index) => {
        const user = request.user;
        if (index > 0) {
          result.push({
            type: typeSpacer,
            height: 20
          });
        }
        result.push({
          type: typeReceivedRequest,
          height: 220,
          user,
          accepted: request.isAccepted,
          onAccepted,
          onAcceptError,
          onDeclined
        });
      });
      result.push({
        type: typeSpacer,
        height: 28
      });
    }
    if (sentRequests.length > 0) {
      result.push({
        type: typeGroupHeader,
        title: i18n.format(declareFormat({
          id: 'friendrequests.sent',
          defaultFormat: 'Sent requests ({count})'
        }), {
          count: sentRequests.length
        }),
        height: 40
      });
      sentRequests.forEach((request, index) => {
        if (index > 0) {
          result.push({
            type: typeDivider,
            height: 1
          });
        }
        result.push({
          type: typeSentRequest,
          height: 88,
          user: request.user
        });
      });
    }
    return result;
  }, [sentRequests, acceptedAndReceivedRequests]);
};
type RequestWithAcceptState = {
  user: FriendRequestUserFragment;
  isAccepted?: boolean;
};
const useAcceptedAndReceivedRequests = (receivedRequests: readonly FriendRequest[], isActiveTab: boolean): [readonly RequestWithAcceptState[], (user: FriendRequestUserFragment, isAccepted: boolean) => void] => {
  const friendRequestService = useService($FriendRequestsService);
  const [resultRequests, setResultRequests] = React.useState<readonly RequestWithAcceptState[]>(receivedRequests);
  React.useEffect(() => {
    if (!isActiveTab) {
      setResultRequests(current => current.filter(request => request.isAccepted !== true));
    }
  }, [isActiveTab]);
  const setRequestAccepted = React.useCallback((user: FriendRequestUserFragment, isAccepted: boolean) => {
    friendRequestService.setFriendRequestAccepted(user, isAccepted);
    setResultRequests(current => current.map(request => {
      if (request.user.id === user.id) {
        return {
          user,
          isAccepted
        };
      } else {
        return request;
      }
    }));
  }, [friendRequestService, setResultRequests]);
  React.useEffect(() => {
    const newRequests = receivedRequests.filter(request => !isRequestInList(request, resultRequests));
    const removedRequests = resultRequests.filter(request => !isRequestInList(request, receivedRequests) && !isRequestInList(request, friendRequestService.acceptedFriendRequests));
    setResultRequests(current => {
      const afterRemove = current.filter(request => !isRequestInList(request, removedRequests));
      return [...newRequests, ...afterRemove];
    });
  }, [receivedRequests, friendRequestService.acceptedFriendRequests]);
  return [resultRequests, setRequestAccepted];
};
function isRequestInList(request: {
  user: {
    id: string;
  };
}, list: readonly {
  user: {
    id: string;
  };
}[]): boolean {
  return list.some(it => it.user.id === request.user.id);
}
function renderItem({
  item
}: VirtualListRenderItemInfo<FriendRequestsItemInfo>): JSX.Element {
  switch (item.type) {
    case typeGroupHeader:
      return <CategoryHeader title={item.title} />;
    case typeDivider:
      return <div className={_c2}>
					<LineDivider className={_c3} />
				</div>;
    case typeSentRequest:
      {
        const user = item.user;
        return <MemoizedSentFriendRequest userId={user.id} nick={user.nick} gender={user.gender} age={user.age} />;
      }
    case typeReceivedRequest:
      return <MemoizedReceivedFriendRequest user={item.user} isAccepted={item.accepted} onAccepted={item.onAccepted} onAcceptError={item.onAcceptError} onDeclined={item.onDeclined} />;
    case typeSpacer:
      return <div style={{
        height: resolveThemingValue(`${item.height}px`, "sizes", useTheme())
      }} className={_c4} />;
    default:
      expectUnreachable(item);
  }
}
const MemoizedSentFriendRequest = React.memo(SentFriendRequest);
const MemoizedReceivedFriendRequest = React.memo(ReceivedFriendRequest);
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol ml-84px mr-base ";
const _c3 = "  ";
const _c4 = " Knu-Box ";