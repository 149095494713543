import * as React from 'react';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { createAccessiblePointerEventHandler, FlexCol, Text, ThemeOverride, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { $UserService } from '@knuddelsModules/UserData';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
export const ChangeProfilePictureButton: React.FC<{}> = () => {
  const i18n = useService($I18n);
  const [hovered, setHovered] = React.useState(false);
  const execute = useSlashCommandWithoutChannel();
  const hasProfilePicture = useService($UserService).currentUser?.profilePicture?.exists ?? false;
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => execute('/opensystemapp ' + PROFILE_CUSTOMIZATION_APP_ID)
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c0}>
			<div style={{
      backdropFilter: 'blur(10px)'
    }} className={_c1 + ((hovered ? "black-transparent-660" : "black-transparent-440") ? resolveIsDarkColor(hovered ? "black-transparent-660" : "black-transparent-440", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (hovered ? _c2 : _c3)}>
				<Text type={'body2'} bold={true} state={'primary'} className={_c4}>
					{hasProfilePicture ? declareFormat({
          id: 'EDIT_PICTURE_AREA',
          defaultFormat: 'EDIT PICTURE AREA'
        }).format(i18n) : declareFormat({
          id: 'CHANGE_PROFILE_PICTURE',
          defaultFormat: 'CHANGE PROFILE PICTURE'
        }).format(i18n)}
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol cursor-pointer height-100-percent justifyContent-flex-end alignItems-center p-small ";
const _c1 = " Knu-FlexCol py-5px px-minor borderRadius-tiny ";
const _c2 = " bg-black-transparent-660 ";
const _c3 = " bg-black-transparent-440 ";
const _c4 = "  ";