import { jsxs as M, jsx as p } from "react/jsx-runtime";
import { forwardRef as x, useContext as c } from "react";
import { MotionConfigContext as C } from "../context/MotionConfigContext.js";
import { MotionContext as E } from "../context/MotionContext/index.js";
import { useVisualElement as P } from "./utils/use-visual-element.js";
import { useMotionRef as j } from "./utils/use-motion-ref.js";
import { useCreateMotionContext as L } from "../context/MotionContext/create.js";
import { loadFeatures as g } from "./features/load-features.js";
import { isBrowser as b } from "../utils/is-browser.js";
import { LayoutGroupContext as S } from "../context/LayoutGroupContext.js";
import { LazyContext as w } from "../context/LazyContext.js";
import { motionComponentSymbol as F } from "./utils/symbol.js";
import { featureDefinitions as N } from "./features/definitions.js";
function T({ preloadedFeatures: t, createVisualElement: o, useRender: e, useVisualState: r, Component: m }) {
  t && g(t);
  function v(i, y) {
    let a;
    const u = {
      ...c(C),
      ...i,
      layoutId: R(i)
    }, { isStatic: s } = u, n = L(i), l = r(i, s);
    if (!s && b) {
      A();
      const d = G(u);
      a = d.MeasureLayout, n.visualElement = P(m, l, u, o, d.ProjectionNode);
    }
    return M(E.Provider, { value: n, children: [a && n.visualElement ? p(a, { visualElement: n.visualElement, ...u }) : null, e(m, i, j(l, n.visualElement, y), l, s, n.visualElement)] });
  }
  const f = x(v);
  return f[F] = m, f;
}
function R({ layoutId: t }) {
  const o = c(S).id;
  return o && t !== void 0 ? o + "-" + t : t;
}
function A(t, o) {
  c(w).strict;
}
function G(t) {
  const { drag: o, layout: e } = N;
  if (!o && !e)
    return {};
  const r = { ...o, ...e };
  return {
    MeasureLayout: o != null && o.isEnabled(t) || e != null && e.isEnabled(t) ? r.MeasureLayout : void 0,
    ProjectionNode: r.ProjectionNode
  };
}
export {
  T as createMotionComponent
};
