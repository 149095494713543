import * as React from 'react';
import { IconPng, NotificationBadge, Row, Spacer } from '@shared/components';
import { BoxProps, Flex, FlexCol, rgb, Ripple, Text, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { openInNewTab } from '@shared/helper/openUrl';
import { isNative } from '@knuddels-app/tools/isNative';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
export interface NavBarItemProps {
  active?: boolean;
  url?: string;
  collapsed?: boolean;
  onClick?: () => void;
  type: 'extendedLeftAligned' | 'minimalCentered';
  icon: string | React.ComponentType<any>;
  title: string | JSX.Element;
  hideTitle?: boolean;
  testId?: string;
  badge?: string;
  badgeColor?: string;
  verticalSpacerSize?: BoxProps['py'];
}
export const NavBarItem: React.FC<NavBarItemProps> = props => {
  const {
    active,
    collapsed,
    icon,
    onClick,
    type: navType
  } = props;
  const extendedLeftAligned = navType === 'extendedLeftAligned';
  const alignment = extendedLeftAligned ? undefined : 'center';
  const flex = extendedLeftAligned ? undefined : 1;
  const [hovered, setHovered] = React.useState(false);
  const verticalPadding = useVerticalPadding(props);
  const htmlRef = React.useRef<HTMLDivElement>(null);
  const isStackedLayout = useIsStackedLayout();
  return <div onClick={() => {
    onClick();
    if (props.url) {
      openInNewTab(props.url);
    }
  }} onMouseEnter={toPointerHandler(!isNative() ? () => setHovered(true) : null)} onMouseLeave={toPointerHandler(!isNative() ? () => setHovered(false) : null)} ref={htmlRef} style={{
    flex: resolveThemingValue(flex, "theme", useTheme()),
    alignItems: resolveThemingValue(alignment, "theme", useTheme()),
    justifyContent: resolveThemingValue(alignment, "theme", useTheme()),
    paddingTop: resolveThemingValue(verticalPadding, "spacing", useTheme()),
    paddingBottom: resolveThemingValue(verticalPadding, "spacing", useTheme())
  }} className={_c0}>
			<Row style={{
      position: 'relative',
      alignItems: 'center'
    }}>
				{extendedLeftAligned && <Spacer size={'base'} />}
				<div className={_c1}>
					<div className={_c2}>
						<NavIcon icon={icon} active={active} hovered={hovered} />
						{collapsed && <Badge badge={props.badge} color={props.badgeColor} />}
					</div>
					{!extendedLeftAligned && <Title {...props} hovered={hovered} />}
				</div>
				{extendedLeftAligned && <Spacer size={'base'} />}
				{extendedLeftAligned && !collapsed && <div className={_c3}>
						<Title {...props} hovered={hovered} />
						{props.badge && <NotificationBadge number={props.badge} rawColor={props.badgeColor} />}
					</div>}
				{extendedLeftAligned && !collapsed && <Spacer size={'base'} />}
			</Row>
			<div style={{
      marginTop: resolveThemingValue(isStackedLayout ? (-20 as ThemeOverride) : -4, "spacing", useTheme())
    }} className={_c4 + (isStackedLayout ? _c5 : _c6) + (isStackedLayout ? _c7 : _c8)}>
				<Ripple containerRef={htmlRef} pointerCallbackType={'onClick'} />
			</div>
		</div>;
};
const useVerticalPadding = ({
  active,
  type,
  verticalSpacerSize
}: {
  active?: boolean;
  type: 'extendedLeftAligned' | 'minimalCentered';
  verticalSpacerSize?: BoxProps['py'];
}) => {
  if (verticalSpacerSize) {
    return verticalSpacerSize;
  }
  if (type === 'extendedLeftAligned') {
    return 'minor';
  } else if (active) {
    return 'small';
  } else {
    return 'base';
  }
};
export const NavIcon: React.FC<{
  active?: boolean;
  icon: string | React.ComponentType<any>;
  hovered?: boolean;
}> = ({
  icon,
  active,
  hovered
}) => {
  if (typeof icon === 'string') {
    return <IconPng iconUrl={icon} active={active} hovered={hovered} />;
  }
  return React.createElement(icon, {
    size: 'large',
    color: active ? 'white-solid-white' : hovered ? rgb(177, 177, 177) : rgb(101, 101, 101)
  });
};
const Title: React.FC<{
  active?: boolean;
  type: 'extendedLeftAligned' | 'minimalCentered';
  title: string | JSX.Element;
  hideTitle?: boolean;
  hovered: boolean;
}> = ({
  active,
  title,
  type,
  hideTitle,
  hovered
}) => {
  const extended = type === 'extendedLeftAligned';
  if (hideTitle) {
    return null; // TODO do we need this? This method is never called if hideTitle is true.
  }

  return <Text type={extended ? 'body1' : 'tiny'} state={((active ? 'primary' : hovered ? 'secondary' : 'tertiary') as TextStates)} bold={!extended} numberOfLines={1} className={_c9}>
			{title}
		</Text>;
};
const Badge: React.FC<{
  badge?: string;
  color?: string;
}> = ({
  badge,
  color
}) => {
  if (!badge) {
    return null;
  }
  return <div style={{
    right: resolveThemingValue((-4 as ThemeOverride), "spacing", useTheme()),
    top: resolveThemingValue((-4 as ThemeOverride), "spacing", useTheme())
  }} className={_c10}>
			<NotificationBadge number={badge} rawColor={color} />
		</div>;
};
const _c0 = " Knu-FlexCol position-relative cursor-pointer ";
const _c1 = " Knu-FlexCol alignItems-center ";
const _c2 = " Knu-FlexCol overflow-visible position-relative mb-tiny ";
const _c3 = " Knu-Flex flex-1 justifyContent-space-between ";
const _c4 = " Knu-Flex position-absolute height-32px overflow-hidden borderRadius-100px ";
const _c5 = " width-56px ";
const _c6 = " width-48px ";
const _c7 = " ml-0-px ";
const _c8 = " ml-4px ";
const _c9 = "  ";
const _c10 = " Knu-FlexCol position-absolute ";