import * as history from 'history';
import { BugIndicatingError } from '@knuddels/std';
import { match } from 'path-to-regexp';
import { History } from 'history';
type PoorMansUnknown = History.PoorMansUnknown;

export class LocationInfo {
	public static parse(url: string): LocationInfo {
		const p = history.parsePath(url);
		return LocationInfo.fromHistoryLocation(p);
	}

	private static parsePath(path: string): string[] {
		if (!path.startsWith('/')) {
			throw new BugIndicatingError(
				`Expected pathname "${path}" to start with "/"`
			);
		}
		path = path.substr(1);
		const items = path ? path.split('/') : [];
		return items;
	}

	public static fromHistoryLocation(
		location: history.Location
	): LocationInfo {
		const path = LocationInfo.parsePath(location.pathname);
		return new LocationInfo(
			path,
			location.search,
			location.hash,
			location.state
		);
	}

	public static deserialize(data: string): LocationInfo {
		const obj = JSON.parse(data);
		return new LocationInfo(obj.path, obj.search, obj.hash, obj.state);
	}

	constructor(
		public readonly path: string[],
		// TODO use `Record<string, string>,` for search
		public readonly search: string,
		public readonly hash: string,
		/** Has to serialize/deserialize to/from json */
		public readonly state: PoorMansUnknown
	) {}

	public getPathString(): string {
		return '/' + this.path.join('/');
	}

	public toHistoryLocation(): history.Location {
		return {
			pathname: this.getPathString(),
			hash: this.hash,
			state: this.state,
			search: this.search,
		};
	}

	public equals(lastLocationInfo: LocationInfo): boolean {
		return (
			JSON.stringify(this.toHistoryLocation()) ===
			JSON.stringify(lastLocationInfo.toHistoryLocation())
		);
	}

	public withPath(path: string): LocationInfo {
		return new LocationInfo(
			LocationInfo.parsePath(path),
			this.search,
			this.hash,
			this.state
		);
	}

	/**
	 * Matches a path pattern against this location info.
	 * See [here](https://github.com/pillarjs/path-to-regexp/blob/0c466b1b0944e8d0022b5b15069364a8483bf9c5/Readme.md)
	 * for the syntax of the pattern.
	 * @param options
	 * * strict: Whether the pattern must match the full path or only a prefix.
	 */
	public match(
		pathPattern: string,
		options: { strict?: boolean } = {}
	): LocationMatch | undefined {
		// See https://github.com/ReactTraining/react-router/blob/926d7a4a0709d9028a2297f6dae7403761189a5f/packages/react-router/modules/matchPath.js
		// for another implementation

		const matcher = match(pathPattern, { end: !!options.strict });
		const result = matcher(this.getPathString());
		if (!result) {
			return undefined;
		}
		return {
			params: result.params as Record<string, string>,
		};
	}

	public serialize(): string {
		return JSON.stringify({
			path: this.path,
			search: this.search,
			hash: this.hash,
			state: this.state,
		});
	}
}

export interface LocationMatch {
	params: Record<string, string>;
}
