import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { AppViewer } from './AppViewer';
import { AppInstance } from './AppInstance';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';

@injectable()
export class ChannelAppViewer implements Disposable, AppViewer {
	public readonly dispose = Disposable.fn();

	@observable.shallow
	private appInstances: AppInstance[] = [];

	@observable
	private appIndex = -1;

	private mayTrackMultiAppAvailable = true;

	constructor(
		@inject($FirebaseAnalyticsService)
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {}

	public get apps(): readonly AppInstance[] {
		return this.appInstances;
	}

	public get activeAppIndex(): number {
		return this.appIndex;
	}

	public get openAppInstances(): ReadonlyArray<Readonly<AppInstance>> {
		return this.appInstances;
	}

	closeChannelBoundApps(channelName: string): void {
		Object.values(this.appInstances).forEach(appInstance => {
			if (appInstance.channelName === channelName) {
				appInstance.close();
			}
		});
	}

	@action.bound
	addApp(appInstance: AppInstance): void {
		this.appInstances = [...this.appInstances, appInstance];

		if (this.appInstances.length === 1) {
			this.appIndex = 0;
		} else if (appInstance.isUserApp) {
			setTimeout(() => {
				runInAction('Update app index', () => {
					this.appIndex = this.appInstances.length - 1;
				});
			}, 50);
		}

		if (this.appInstances.length === 1) {
			this.firebaseAnalyticsService.logEvent('Chat_AppView', 'Opened');
		} else if (
			this.appInstances.length === 2 &&
			this.mayTrackMultiAppAvailable
		) {
			this.firebaseAnalyticsService.logEvent(
				'Chat_AppView',
				'AppSwitcher_MultiAppAvailable'
			);
			this.mayTrackMultiAppAvailable = false;
		}
	}

	canHandleAppEvent(): boolean {
		return true;
	}

	closeAllApps(): void {
		Object.values(this.appInstances).forEach(appInstance => {
			appInstance.close();
		});
	}

	closeAllAppsExceptForChannel(channelName?: string): void {
		if (!channelName) {
			this.closeAllApps();
			return;
		}

		Object.values(this.appInstances).forEach(appInstance => {
			if (appInstance.data.channelName !== channelName) {
				appInstance.close();
			}
		});
	}

	closeApp(appId: string): void {
		this.getApp(appId)?.close();
	}

	getApp(appId: string): AppInstance | undefined {
		return this.appInstances.find(app => app.appId === appId);
	}

	@action.bound
	removeApp(appId: string): void {
		const appToRemoveIndex = this.appInstances.findIndex(
			appInstance => appInstance.appId === appId
		);

		if (appToRemoveIndex === -1) {
			return;
		}

		this.appInstances = [
			...this.appInstances.slice(0, appToRemoveIndex),
			...this.appInstances.slice(appToRemoveIndex + 1),
		];

		if (this.appInstances.length === 0) {
			this.appIndex = -1;
			this.mayTrackMultiAppAvailable = true;
			this.firebaseAnalyticsService.logEvent('Chat_AppView', 'Closed');
		} else if (this.activeAppIndex >= appToRemoveIndex) {
			this.appIndex = Math.min(
				this.appIndex,
				this.appInstances.length - 1
			);
		}
	}

	public get activeApp(): AppInstance | undefined {
		return this.appInstances.length > 0 && this.activeAppIndex >= 0
			? this.appInstances[this.activeAppIndex]
			: undefined;
	}

	@action.bound
	public setActiveAppIndex(newIndex: number): void {
		if (0 <= newIndex && newIndex <= this.appInstances.length - 1) {
			this.appIndex = newIndex;
		}
	}

	@action.bound
	public prevApp(): void {
		this.setActiveAppIndex(this.appIndex - 1);
	}

	@action.bound
	public nextApp(): void {
		this.setActiveAppIndex(this.appIndex + 1);
	}

	getAllApps(): AppInstance[] {
		return this.appInstances;
	}
}
