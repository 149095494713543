import { useContext as r, useId as c, useEffect as f, useCallback as l } from "react";
import { PresenceContext as s } from "../../context/PresenceContext.js";
function p() {
  const e = r(s);
  if (e === null)
    return [!0, null];
  const { isPresent: u, onExitComplete: t, register: o } = e, n = c();
  f(() => o(n), []);
  const i = l(() => t && t(n), [n, t]);
  return !u && t ? [!1, i] : [!0];
}
function x() {
  return m(r(s));
}
function m(e) {
  return e === null ? !0 : e.isPresent;
}
export {
  m as isPresent,
  x as useIsPresent,
  p as usePresence
};
