import * as React from 'react';
import { useState } from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { ListGroup, SwitchListItem } from '@knuddels/component-library';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useMutation, useQuery } from '@knuddels-app/Connection';
import { GetNSFWSearchEntriesVisible, UpdateNsfwSearchEntriesVisible } from '@generated/graphql';
export const NotSafeForWorkEntry: React.FC = () => {
  const i18n = useService($I18n);
  const {
    checked,
    onChange
  } = useUpdateNSFWSearchEntriesVisible();
  if (typeof checked !== 'boolean') {
    return null;
  }
  return <ListGroup>
			<SwitchListItem caption={declareFormat({
      id: 'settings.channelListSettings.notSafeForWork',
      defaultFormat: 'Show NSFW content in search results'
    }).format(i18n)} checked={checked} onChange={onChange} separator={'none'} />
		</ListGroup>;
};
const useUpdateNSFWSearchEntriesVisible = () => {
  const snackbarService = useService($SnackbarService);
  const [checked, setChecked] = useState<boolean | null>(null);
  const {
    data
  } = useQuery(GetNSFWSearchEntriesVisible, {}, 'network-only');
  const [mutation] = useMutation(UpdateNsfwSearchEntriesVisible);
  React.useLayoutEffect(() => {
    setChecked(data);
  }, [data]);
  const onChange = (newValue: boolean) => {
    const oldValue = checked;
    setChecked(newValue);
    mutation({
      visible: newValue
    }).then(result => {
      if (result.data?.__typename !== 'Success') {
        setChecked(oldValue);
        snackbarService.showGenericError();
      }
    }).catch(() => {
      setChecked(oldValue);
      snackbarService.showGenericError();
    });
  };
  return {
    checked,
    onChange
  };
};