import * as React from 'react';
import { ConversationImageMessageContentFragment, ConversationMessageFragment, ConversationSnapMessageContent, ConversationSnapMessageContentFragment } from '@generated/graphql';
import { SnapSize } from './SnapSizes';
import { Star } from '../Star';
import { ImageDisplay } from './ImageDisplay';
import { SnapDisplay } from './SnapDisplay';
import { BasicUser } from '../../../../utils/BasicUser';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnapService } from '@knuddelsModules/Messenger/providedServices';
import { MessageLightboxSource } from '../../Lightbox/Lightbox';
import { MessageTime } from '../MessageTime';
import { Flex, FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { useShowImages } from '../useShowImages';
export const ImageAndSnapMessage: React.FC<{
  message: Omit<ConversationMessageFragment, 'sender'>;
  sentByActiveUser: boolean;
  forceShowImages?: boolean;
  sender: BasicUser;
  content: ConversationImageMessageContentFragment | ConversationSnapMessageContentFragment;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
}> = ({
  message,
  content,
  sender,
  sentByActiveUser,
  forceShowImages,
  setLightboxSrc
}) => {
  const isStarred = 'starred' in content && content.starred;
  const showImages = useShowImages(forceShowImages, sentByActiveUser, sender.id);
  return <div style={{
    maxWidth: resolveThemingValue(SnapSize.MESSAGE, "sizes", useTheme())
  }} className={_c0}>
			{'image' in content && <ImageDisplay snapsAllowed={showImages} content={content} sender={sender} setLightboxSrc={setLightboxSrc} size={SnapSize.MESSAGE} messageId={message.id} />}
			{'snap' in content && <SnapMessage showImages={showImages} sender={sender} sentByActiveUser={sentByActiveUser} setLightboxSrc={setLightboxSrc} content={content} messageId={message.id} />}
			<div className={_c1}>
				<MessageTime timestamp={+message.timestamp} />
				{isStarred && <Star />}
			</div>
		</div>;
};
export const SnapMessage: React.FC<{
  sentByActiveUser: boolean;
  showImages: boolean;
  sender: BasicUser;
  content: ConversationSnapMessageContent;
  messageId: string;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
}> = ({
  setLightboxSrc,
  messageId,
  content,
  sentByActiveUser,
  showImages,
  sender
}) => {
  const snapService = useService($SnapService);
  const snapState = snapService.getOrCreateClientSnapState(messageId, content);
  return <SnapDisplay snapsAllowed={showImages} sender={sender} sentByActiveUser={sentByActiveUser} snap={snapState} setLightboxSrc={setLightboxSrc} imageAccepted={content.imageAccepted} size={SnapSize.MESSAGE} />;
};
const _c0 = " Knu-FlexCol overflow-visible position-relative ";
const _c1 = " Knu-Flex justifyContent-flex-end pl-base pr-tiny overflow-visible ";