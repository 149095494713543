import { extractEventInfo as w } from "../../events/event-info.js";
import { secondsToMilliseconds as S, millisecondsToSeconds as D } from "../../utils/time-conversion.js";
import { addPointerEvent as d } from "../../events/add-pointer-event.js";
import { pipe as T } from "../../utils/pipe.js";
import { distance2D as W } from "../../utils/distance.js";
import { isPrimaryPointer as L } from "../../events/utils/is-primary-pointer.js";
import { frame as U, cancelFrame as O, frameData as x } from "../../frameloop/frame.js";
class B {
  constructor(n, o, { transformPagePoint: e, contextWindow: r, dragSnapToOrigin: h = !1 } = {}) {
    if (this.startEvent = null, this.lastMoveEvent = null, this.lastMoveEventInfo = null, this.handlers = {}, this.contextWindow = window, this.updatePoint = () => {
      if (!(this.lastMoveEvent && this.lastMoveEventInfo))
        return;
      const i = p(this.lastMoveEventInfo, this.history), a = this.startEvent !== null, c = W(i.offset, { x: 0, y: 0 }) >= 3;
      if (!a && !c)
        return;
      const { point: f } = i, { timestamp: m } = x;
      this.history.push({ ...f, timestamp: m });
      const { onStart: l, onMove: E } = this.handlers;
      a || (l && l(this.lastMoveEvent, i), this.startEvent = this.lastMoveEvent), E && E(this.lastMoveEvent, i);
    }, this.handlePointerMove = (i, a) => {
      this.lastMoveEvent = i, this.lastMoveEventInfo = v(a, this.transformPagePoint), U.update(this.updatePoint, !0);
    }, this.handlePointerUp = (i, a) => {
      this.end();
      const { onEnd: c, onSessionEnd: f, resumeAnimation: m } = this.handlers;
      if (this.dragSnapToOrigin && m && m(), !(this.lastMoveEvent && this.lastMoveEventInfo))
        return;
      const l = p(i.type === "pointercancel" ? this.lastMoveEventInfo : v(a, this.transformPagePoint), this.history);
      this.startEvent && c && c(i, l), f && f(i, l);
    }, !L(n))
      return;
    this.dragSnapToOrigin = h, this.handlers = o, this.transformPagePoint = e, this.contextWindow = r || window;
    const s = w(n), u = v(s, this.transformPagePoint), { point: g } = u, { timestamp: I } = x;
    this.history = [{ ...g, timestamp: I }];
    const { onSessionStart: P } = o;
    P && P(n, p(u, this.history)), this.removeListeners = T(d(this.contextWindow, "pointermove", this.handlePointerMove), d(this.contextWindow, "pointerup", this.handlePointerUp), d(this.contextWindow, "pointercancel", this.handlePointerUp));
  }
  updateHandlers(n) {
    this.handlers = n;
  }
  end() {
    this.removeListeners && this.removeListeners(), O(this.updatePoint);
  }
}
function v(t, n) {
  return n ? { point: n(t.point) } : t;
}
function y(t, n) {
  return { x: t.x - n.x, y: t.y - n.y };
}
function p({ point: t }, n) {
  return {
    point: t,
    delta: y(t, M(n)),
    offset: y(t, V(n)),
    velocity: b(n, 0.1)
  };
}
function V(t) {
  return t[0];
}
function M(t) {
  return t[t.length - 1];
}
function b(t, n) {
  if (t.length < 2)
    return { x: 0, y: 0 };
  let o = t.length - 1, e = null;
  const r = M(t);
  for (; o >= 0 && (e = t[o], !(r.timestamp - e.timestamp > S(n))); )
    o--;
  if (!e)
    return { x: 0, y: 0 };
  const h = D(r.timestamp - e.timestamp);
  if (h === 0)
    return { x: 0, y: 0 };
  const s = {
    x: (r.x - e.x) / h,
    y: (r.y - e.y) / h
  };
  return s.x === 1 / 0 && (s.x = 0), s.y === 1 / 0 && (s.y = 0), s;
}
export {
  B as PanSession
};
