import { createK3Router } from '@knuddels-app/layout/k3Router';
import { globalAppViewId } from './viewId';
export type RouterConfig = {
  overview: {
    params: undefined;
  };
  apps: {
    params: undefined;
  };
};
export const {
  Router,
  useRouter
} = createK3Router<RouterConfig>(globalAppViewId);