import * as React from 'react';
import { useRouter } from './ProfileContent/ProfileContentRouter';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import { $ViewService } from '@knuddels-app/layout';
export const AuthenticityOverviewApp: React.FC<{
  closeProfileOnAppClose: boolean;
}> = ({
  closeProfileOnAppClose
}) => {
  const {
    pop
  } = useRouter();
  const viewService = useService($ViewService);
  const nick = useService($UserService).currentUser?.nick;
  if (!nick) {
    return null;
  }
  return <GenericSystemApp onAppClose={() => {
    if (closeProfileOnAppClose) {
      viewService.closeView(profileViewId);
    } else {
      pop();
    }
  }} appId={'AuthenticityOverviewApp'} viewType={'subpath'} customSlashCommand={'/newauthenticity info:' + nick} />;
};