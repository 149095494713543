import { invariant as g } from "./errors.js";
import { clamp as x } from "./clamp.js";
import { pipe as h } from "./pipe.js";
import { progress as y } from "./progress.js";
import { noop as M } from "./noop.js";
import { mix as v } from "./mix/index.js";
function A(r, e, t) {
  const s = [], l = t || v, n = r.length - 1;
  for (let o = 0; o < n; o++) {
    let m = l(r[o], r[o + 1]);
    if (e) {
      const c = Array.isArray(e) ? e[o] || M : e;
      m = h(c, m);
    }
    s.push(m);
  }
  return s;
}
function d(r, e, { clamp: t = !0, ease: s, mixer: l } = {}) {
  const n = r.length;
  if (g(n === e.length), n === 1)
    return () => e[0];
  if (n === 2 && r[0] === r[1])
    return () => e[1];
  r[0] > r[n - 1] && (r = [...r].reverse(), e = [...e].reverse());
  const o = A(e, s, l), m = o.length, c = (f) => {
    let i = 0;
    if (m > 1)
      for (; i < r.length - 2 && !(f < r[i + 1]); i++)
        ;
    const a = y(r[i], r[i + 1], f);
    return o[i](a);
  };
  return t ? (f) => c(x(r[0], r[n - 1], f)) : c;
}
export {
  d as interpolate
};
