import * as inversify from 'inversify';
import { ServiceId } from './ServiceId';
import { $props } from './injectedComponent';
import { BugIndicatingError } from '@knuddels/std';

export function inject<T>(
	id: ServiceId<T>
): ReturnType<typeof inversify.inject> {
	if (!id) {
		throw new BugIndicatingError(
			'Id is not defined. Maybe a cyclic dependency bug?'
		);
	}
	return inversify.inject(id.id);
}

/**
 * Injects many services (0 to n).
 */
inject.many = function multiInject<T>(
	id: ServiceId<T>
): ReturnType<typeof inversify.inject> {
	if (!id) {
		throw new BugIndicatingError(
			'Id is not defined. Maybe a cyclic dependency bug?'
		);
	}
	const m1 = inversify.multiInject(id.id);
	const m2 = inversify.optional();
	return (...args) => {
		m1(...args);
		return m2(...args);
	};
};

/**
 * Injects a service lazily.
 * The module containing the service is loaded on lazily.
 * Lazy injection can be used to break cyclic dependencies.
 */
inject.lazy = function injectLazy<T>(
	id: ServiceId<T>
): ReturnType<typeof inversify.inject> {
	if (!id) {
		throw new BugIndicatingError(
			'Id is not defined. Maybe a cyclic dependency bug?'
		);
	}
	const lazy = inversify.inject(lazyServiceName);
	const tagged = inversify.tagged(lazyServiceServiceIdTagName, id);
	return (...args) => {
		lazy(...args);
		tagged(...args);
	};
};

export const lazyServiceName = 'lazy';
export const lazyServiceServiceIdTagName = 'serviceId';

export function injectProps(): ReturnType<typeof inversify.inject> {
	return inversify.inject($props.id);
}

export function injectable(): ReturnType<typeof inversify.injectable> {
	return inversify.injectable();
}
