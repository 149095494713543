import { px as t } from "../../../value/types/numbers/units.js";
const c = {
  offset: "stroke-dashoffset",
  array: "stroke-dasharray"
}, y = {
  offset: "strokeDashoffset",
  array: "strokeDasharray"
};
function m(s, r, o = 1, e = 0, f = !0) {
  s.pathLength = 1;
  const a = f ? c : y;
  s[a.offset] = t.transform(-e);
  const n = t.transform(r), h = t.transform(o);
  s[a.array] = `${n} ${h}`;
}
export {
  m as buildSVGPath
};
