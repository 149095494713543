import { MobxConsoleLogger } from '@knuddels/mobx-logger';
// tslint:disable-next-line: import-blacklist
import * as mobx from 'mobx';
import { OS, os } from '@shared/components/tools/os';

type Simplify<T> = {
	[TKey in keyof T]: TKey extends 'dispose' ? () => void : T[TKey];
};

let globalLogger: Simplify<MobxConsoleLogger> | undefined;

export function getOrActivateMobxConsoleLogger(): Simplify<MobxConsoleLogger> {
	if (!globalLogger) {
		if (import.meta.env.MODE === 'development') {
			if (os === OS.web && typeof window !== 'undefined') {
				const searchParams = new URL(window.location.href).searchParams;
				const enableMoxLogger = searchParams.has('mobxLogger');
				if (enableMoxLogger) {
					// This has a huge impact on performance.
					// Disabling it until performance can be improved.
					globalLogger = new MobxConsoleLogger(mobx, {
						useGroups: searchParams.has('mobxLoggerUseGroups'),
						groupsDefaultOpen: false,
					});
				}
			}
		}

		if (!globalLogger) {
			globalLogger = {
				dispose(): void {
					// no op
				},
				log(): void {
					// no op
				},
			};
		}
	}
	return globalLogger;
}
