import * as React from 'react';
import { FormattedMessage } from '@knuddels-app/i18n';
import { PlaceholderContent } from '@shared/components';
import { declareFormat } from '@knuddels-app/i18n';
interface Props {
  isArchivedView: boolean;
}
export class NoConversationsView extends React.PureComponent<Props> {
  render(): React.ReactNode {
    const titleMessageId = this.props.isArchivedView ? declareFormat({
      id: 'NO_ARCHIVED_CONVERSATIONS_TITLE',
      defaultFormat: 'No archived messages.'
    }) : declareFormat({
      id: 'NO_CONVERSATIONS_TITLE',
      defaultFormat: 'No messages in sight.'
    });
    return <PlaceholderContent image={require('@shared/icons/sm_17-04-Columbus_ani.gif')} imageWidth={69} imageHeight={63} imageSpacing={'tiny'} title={<FormattedMessage id={titleMessageId} />} description={!this.props.isArchivedView ? <FormattedMessage id={declareFormat({
      id: 'NO_CONVERSATIONS_MESSAGE',
      defaultFormat: 'You can switch to your archived messages via the overflow menu in the top right corner.'
    })} /> : undefined} />;
  }
}