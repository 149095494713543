import * as React from 'react';
import { creditsFormat, LEGAL_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@knuddelsModules/Settings';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $Environment } from '@knuddels-app/Environment';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { SettingsListItem } from '../SettingsListItem';
import { IconChevronRight, IconExternal, ListGroup } from '@knuddels/component-library';
import { SettingsNavigateFunction } from '../../../SettingsRouterConfig';
export const LegalSettings: React.FC<{
  navigate: SettingsNavigateFunction;
}> = ({
  navigate
}) => {
  const i18n = useService($I18n);
  const environment = useService($Environment);
  const track = useLogEvent();
  const openCredits = () => {
    navigate('Credits');
    track('Settings_Overview', 'Credits_Clicked');
  };
  const onLegalClicked = (): void => {
    track('Settings_Overview', 'Imprint_Clicked');
    environment.openUrl(LEGAL_URL);
  };
  const onPrivacyPolicyClicked = (): void => {
    track('Settings_Overview', 'Dataprotection_Clicked');
    environment.openUrl(PRIVACY_POLICY_URL);
  };
  const onTermsAndConditionsClicked = (): void => {
    track('Settings_Overview', 'AGB_Clicked');
    environment.openUrl(TERMS_AND_CONDITIONS_URL);
  };
  return <ListGroup title={declareFormat({
    id: 'settings.group.legal',
    defaultFormat: 'Legal'
  }).format(i18n)}>
			<SettingsListItem iconRight={<IconExternal />} caption={declareFormat({
      id: 'LEGAL',
      defaultFormat: 'Legal Notice'
    }).format(i18n)} onPress={onLegalClicked} />
			<SettingsListItem iconRight={<IconExternal />} caption={declareFormat({
      id: 'PRIVACY_POLICY',
      defaultFormat: 'Data Privacy'
    }).format(i18n)} onPress={onPrivacyPolicyClicked} />
			<SettingsListItem iconRight={<IconExternal />} caption={declareFormat({
      id: 'settings.termsAndConditions.title',
      defaultFormat: 'Terms and Conditions'
    }).format(i18n)} onPress={onTermsAndConditionsClicked} />
			<SettingsListItem iconRight={<IconChevronRight />} caption={creditsFormat.format(i18n)} onPress={openCredits} separator={'none'} />
		</ListGroup>;
};