import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import {
	ActivePromotions,
	HappyHourFragment,
	PromotionEvents,
} from '@generated/graphql';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

@injectable()
export class PromotionService implements Disposable {
	public dispose = Disposable.fn();

	@observable
	private happyHour: HappyHourFragment | undefined;
	private happyHourTimeout: any | undefined;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($MessageFormatProvider)
		private readonly messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				PromotionEvents,
				{},
				{
					next: this.handleEvent,
				}
			)
		);
		this.dispose.track(() => {
			this.clearHappyHourTimeout();
		});

		this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(ActivePromotions, {})
			.onOk(result => {
				result.forEach(promotion => {
					switch (promotion.__typename) {
						case 'HappyHourPromotion':
							this.startHappyHour(promotion);
							break;
						default:
					}
				});
			});
	}

	public get activeHappyHour(): HappyHourFragment | undefined {
		return this.happyHour;
	}

	@action.bound
	private startHappyHour(happyHour: HappyHourFragment): void {
		this.clearHappyHourTimeout();

		this.happyHour = happyHour;

		const millisUntilEnd =
			parseInt(happyHour.endTimestamp, 10) - Date.now();

		this.happyHourTimeout = setTimeout(
			() => {
				runInAction('reset happyhour after it ended', () => {
					this.happyHour = undefined;
				});
				this.happyHourTimeout = undefined;
			},
			Math.min(millisUntilEnd, 0x7fffffff)
		); // Prevent integer overflow for large endTimestamps
	}

	@action.bound
	private handleEvent(data: typeof PromotionEvents.TPrimaryResult): void {
		switch (data.__typename) {
			case 'PromotionStartedEvent':
				if (data.newPromotion.__typename === 'HappyHourPromotion') {
					this.startHappyHour(data.newPromotion);
				}
				break;
			case 'PromotionEndedEvent':
				if (data.promotion.__typename === 'HappyHourPromotion') {
					this.happyHour = undefined;
				}
				break;
			default:
				break;
		}
	}

	private clearHappyHourTimeout(): void {
		if (this.happyHourTimeout) {
			clearTimeout(this.happyHourTimeout);
			this.happyHourTimeout = undefined;
		}
	}
}
