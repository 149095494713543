import * as React from 'react';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
import { Box } from '@knuddels/component-library';
import { useRouter } from '@knuddelsModules/Settings/bundle/components/SettingsRouter';
import { useSnackbar } from '@shared/components';
import { IconGreenCheckMark } from '@knuddels-app/shared-components/IconGreenCheckMark';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { CHANGE_PASSWORD_APP_ID } from '@shared/constants';
export const ChangePasswordApp = () => {
  const {
    pop
  } = useRouter();
  const snackbar = useSnackbar();
  const i18n = useService($I18n);
  const showSuccessSnackbar = () => {
    snackbar({
      text: declareFormat({
        id: 'changePassword.success',
        defaultFormat: 'Your password has been changed.'
      }).format(i18n),
      type: 'changePwSuccess',
      adornment: <div className={_c0}>
					<IconGreenCheckMark size={'large'} />
				</div>
    });
  };
  return <GenericSystemApp viewType={'subpath'} onAppClose={() => {
    pop();
  }} onAppEvent={(e: any) => {
    if (e.type === 'changePasswordSuccess') {
      if (!e.tanNeeded) {
        showSuccessSnackbar();
      }
      pop();
    }
  }} appId={CHANGE_PASSWORD_APP_ID} />;
};
const _c0 = " Knu-Box mr-minor ";