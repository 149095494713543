import * as React from 'react';
export const PrivateMessageContext = React.createContext<boolean>(false);
interface PrivateMessageProviderProps {
  children: React.ReactNode;
}
export const PrivateMessageProvider: React.FC<PrivateMessageProviderProps> = ({
  children
}) => {
  return <PrivateMessageContext.Provider value={true}>
			{children}
		</PrivateMessageContext.Provider>;
};
export const usePrivateMessageContext = (): boolean => {
  return React.useContext(PrivateMessageContext);
};