import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $AuthService } from '@knuddels-app/Connection/serviceIds';
import { $Environment } from '@knuddels-app/Environment';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';
import { action, observable } from '@knuddels-app/mobx';
import { RegisterFirebaseId } from '@generated/graphql';
import { resetAppNotificationBadge } from './resetAppNotificationBadge';
import {
	$DeepLinkingService,
	DeepLinkType,
} from '@knuddelsModules/DeepLinking';
import { promiseTimeout } from '@knuddels-app/tools/promiseTimeout';
import { removeNotificationsWithTag } from './removeNotificationsWithTag';
import { $PushTokenService } from '@knuddelsModules/Notifications/PushTokenService';
import { RateApp } from 'capacitor-rate-app';
import { queueNativeDialog } from '@knuddels-app/tools/queueNativeDialog';

interface NotificationMessageData {
	DeeplinkType?: DeepLinkType;
	LinkTarget?: string;
}

type NotificationStatus =
	| 'denied'
	| 'granted'
	| 'undecided'
	| 'dontAskThisSession';

@injectable()
export class PushNotificationsService implements Disposable {
	public readonly dispose = Disposable.fn();

	@observable
	private _status: NotificationStatus = 'undecided';
	public get status(): NotificationStatus {
		return this._status;
	}

	constructor(
		@inject($AuthenticatedClientService)
		protected readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($Environment)
		environment: typeof $Environment.T,
		@inject($K3Firebase)
		private readonly k3Firebase: typeof $K3Firebase.T,
		@inject($PushTokenService)
		private readonly pushTokenService: typeof $PushTokenService.T,
		@inject($DeepLinkingService)
		private readonly deepLinkingService: typeof $DeepLinkingService.T
	) {
		this.dispose.track(() => {
			k3Firebase.analytics.setUserProperties({
				['Pushstatus']: null,
			});
		});

		resetAppNotificationBadge();
		if (
			// disable for stapp
			environment.messengerSystemAppInterface
		) {
			this.dontAskThisSession();
			return;
		}

		this.setupIfPermission().then(() => {
			k3Firebase.analytics.setUserProperties({
				['Pushstatus']:
					this.status === 'granted'
						? 'Granted'
						: this.status === 'denied'
							? 'Rejected'
							: 'Pending',
			});

			if (this.status === 'undecided') {
				this.requestPermission();
			}
		});

		this.dispose.track(
			this.deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.Permission &&
					target === 'PushNotification'
				) {
					this.requestPermission();
				} else if (type === DeepLinkType.OpenAppReview) {
					RateApp.requestReview();
				}
			})
		);
	}

	public dontAskThisSession(): void {
		this.setStatus('dontAskThisSession');
	}

	public clearNotificationsWithTag = () => {
		removeNotificationsWithTag();
	};

	@action
	protected setStatus(status: NotificationStatus): void {
		this._status = status;
	}

	public async requestPermission(): Promise<NotificationStatus> {
		if (this.status !== 'undecided') {
			// only ask if undecided
			return this.status;
		}
		const messaging = await this.k3Firebase.messaging;

		return queueNativeDialog(async () => {
			await messaging?.requestPermission();
			await this.setupIfPermission();
			return this.status;
		});
	}

	private async setupIfPermission(): Promise<void> {
		if (this.status === 'granted') {
			return;
		}
		const messaging = await this.k3Firebase.messaging;
		if (!messaging) {
			// API not available
			this.dontAskThisSession();
			return;
		}
		const permission = await messaging.getPermission();

		switch (permission) {
			case 'granted':
				this.setStatus('granted');
				try {
					this.setupFirebase();
				} catch (error) {
					// e.g. not run with https => sw don't work
					// handle that => if it crashes here it might crash the app
					console.error('Error setting up firebase', error);
					this.setStatus('dontAskThisSession');
				}
				break;
			case 'denied':
				this.setStatus('denied');
				break;
			case 'undecided':
			default:
				this.setStatus('undecided');
				break;
		}
	}

	private async setupFirebase(): Promise<void> {
		this.updateToken();
		const messaging = await this.k3Firebase.messaging;
		this.dispose.track(
			messaging!.onTokenRefresh(() => {
				this.updateToken();
			})
		);
		this.dispose.track(
			messaging!.onNotificationOpened(notification => {
				this.onMessageReceived(notification.data as any);
			})
		);

		this.dispose.track(
			this.authService.onBeforeVoluntaryLogout.sub(() => {
				messaging!.clearNotifications();
			})
		);
	}

	protected updateToken(): void {
		this.k3Firebase.messaging.then(messaging =>
			messaging!.getToken().then(async token => {
				if (!token) {
					await this.pushTokenService.removeLastToken();
					console.error(
						'Notification messaging token is unavailable.'
					);
					return;
				}

				await this.pushTokenService.updateToken(token);

				// register token in backend. fire and forget
				await this.authenticatedClientService.currentK3Client.mutateWithResultPromise(
					RegisterFirebaseId,
					{ instanceId: token }
				);
			})
		);
	}

	protected async onMessageReceived(data?: unknown): Promise<void> {
		// TODO: find a better way to handle this issue
		// ANDROID HOTFIX: We need this to guarantee that all services are initialized
		await promiseTimeout(100);

		if (isNotificationData(data)) {
			this.deepLinkingService.handleDeepLink(
				data.DeeplinkType,
				data.LinkTarget
			);
		}
	}
}

function isNotificationData(
	data?: unknown
): data is Required<NotificationMessageData> {
	return (
		typeof data === 'object' &&
		data !== null &&
		'DeeplinkType' in data &&
		'LinkTarget' in data
	);
}
