import * as React from 'react';
interface PersistedScrollProps {
  // group multiple ids (probably unique per component or view)
  groupId?: string;
  // id for scroll position (probably something like channel or a view id)
  id: string;
  // does only work with a groupId
  resetGroupOnIdChange?: boolean;
}

// implementations can persist der scrollTop with `persistScrollTop` (e.g. in onScroll)
// and they have to implement a `setScrollTop` function that sets the scroll position.
export abstract class PersistedScrollComponent<P> extends React.Component<P & PersistedScrollProps> {
  // groupId may be undefined.
  // for views that aren't in a group they will be saved in `persistedScrollTops[undefined]`
  // this 'group' is never reset (but values can be overridden)
  private static persistedScrollTops: {
    [groupId: string]: {
      [id: string]: number;
    };
  } = {};
  private get groupId(): string {
    return typeof this.props.groupId === 'string' ? this.props.groupId : '';
  }
  componentDidUpdate(prevProps: P & PersistedScrollProps): void {
    if (prevProps.id !== this.props.id || prevProps.groupId !== this.props.groupId) {
      this.setPersistedScrollTop();
    }
  }
  componentDidMount(): void {
    setTimeout(() => this.setPersistedScrollTop(), 1);
  }
  private setPersistedScrollTop(): void {
    this.setScrollTop(this.getPersistedScrollTop() || 0);
  }
  private getPersistedScrollTop(): number | undefined {
    const scrollTopsPerType = PersistedScrollComponent.persistedScrollTops[this.groupId];
    if (scrollTopsPerType) {
      return scrollTopsPerType[this.props.id];
    }
    return undefined;
  }

  // maybe debounce this
  protected persistScrollTop(scrollTop: number): void {
    if (
    // set initial group
    !PersistedScrollComponent.persistedScrollTops[this.groupId] ||
    // reset group (keep only one id per group)
    this.props.resetGroupOnIdChange && this.props.groupId !== undefined) {
      PersistedScrollComponent.persistedScrollTops[this.groupId] = {};
    }
    PersistedScrollComponent.persistedScrollTops[this.groupId][this.props.id] = scrollTop;
  }
  abstract render(): React.ReactNode;
  abstract setScrollTop(scrollTop: number): void;
}