import { GetCurrentUserNickProps, withGetCurrentUserNick } from '@generated/graphql';
import { Spacer } from '@shared/components';
import { declareProps, IModel, inject, injectable, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $AdsService } from '@knuddelsModules/Ads';
import * as React from 'react';
import { $NavBarRegistry } from '../../../providedServiceIds';
import { bottomNavMoreViewEvent } from '../../analytics';
import { NormalizedNavBarCategory } from '../../services';
import { NavigationViewEntry } from './NavigationViewEntry';
import { NavigationViewTitlebar } from './NavigationViewTitlebar';
import { NativeBackgroundWrapper } from '@knuddels-app/NativeBackground';
import { FlexCol, rgb, ScrollView, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { CategoryWrapper } from '../NavBar/NavBarCategory';
import { KnuddelsPlusOrHappyHourBanner } from '@knuddelsModules/Promotions';
@injectable()
class NavigationViewBaseModel implements IModel {
  constructor(@inject($NavBarRegistry)
  private readonly navBarRegistry: typeof $NavBarRegistry.T) {}
  componentDidMount(): void {
    this.navBarRegistry.setLastViewFromMoreMenu(undefined);
    bottomNavMoreViewEvent.track('MoreView_Opened');
  }
  public get categories(): NormalizedNavBarCategory[] {
    return this.navBarRegistry.getNormalizedCategories('menu');
  }
}
export const NavigationViewBase = injectedComponent({
  name: 'NavigationViewBase',
  model: NavigationViewBaseModel,
  inject: {
    adsService: $AdsService
  },
  props: declareProps<GetCurrentUserNickProps>()
}, ({
  data,
  model,
  adsService
}) => {
  if (!data || !data.user) {
    return null;
  }
  const {
    id,
    nick
  } = data.user.currentUser;
  const Adzone = adsService.Adzone;
  return <NativeBackgroundWrapper color={'#222222'} type={'dark'}>
				<div style={{
      background: resolveThemingValue(rgb(34, 34, 34, 1.0), "colors", useTheme())
    }} className={_c0 + (rgb(34, 34, 34, 1.0) ? resolveIsDarkColor(rgb(34, 34, 34, 1.0), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<NavigationViewTitlebar uid={id} nick={nick} />
					<KnuddelsPlusOrHappyHourBanner />
					<div className={_c1}>
						<Spacer size={'minor'} />
						{model.categories.map(category => <CategoryWrapper key={`category-${category.id}`} category={category}>
								{category.entries.map((item, index) => <NavigationViewEntry key={index} icon={item.icon} text={item.menuTitle ?? item.title} onClick={item.onClick} badge={item.badge} url={item.url} active={item.active} />)}
							</CategoryWrapper>)}
						{adsService.areWebAdsVisible && <>
								<div className={_c2}>
									<Adzone adzone="navigationView" />
								</div>
								<Spacer size={'large'} />
							</>}
					</div>
				</div>
			</NativeBackgroundWrapper>;
});
export const NavigationView = withGetCurrentUserNick({
  options: {
    fetchPolicy: 'cache-first'
  }
})(NavigationViewBase);
const _c0 = " Knu-FlexCol flex-1 minHeight-0-px ";
const _c1 = " Knu-ScrollView flex-1 flexBasis-0-px px-small ";
const _c2 = " Knu-FlexCol py-small ";