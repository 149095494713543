import { SmileyId } from '@knuddelsModules/Smileybox/bundle/services';
import { SmileyDetails } from '@generated/graphql';
import { initialSmileyCountPerRow, listGap, rowGap, smileyDimensions } from './listConstants';
import { VirtualListItem } from '@knuddels/component-library';
export type ItemInfo = HeaderItemInfo | SmileyRowItemInfo | SmileyRowSkeletonItemInfo | SmileyTradeButtonItemInfo;
export type HeaderItemInfo = VirtualListItem & {
  type: 'Header';
  text: string;
  width: number;
};
export type SmileyRowItemInfo = VirtualListItem & {
  type: 'SmileyRow';
  smileyIds: readonly SmileyId[];
  width: number;
  onSmileyClick(smileyDetails: SmileyDetails): void;
};
export type SmileyRowSkeletonItemInfo = VirtualListItem & {
  type: 'SmileyRowSkeleton';
  smileyCount: number;
  width: number;
};
export type SmileyTradeButtonItemInfo = VirtualListItem & {
  type: 'SmileyTradeButton';
};
export class SmileyRowSection {
  private constructor(private readonly header: string, private readonly smileyIds: readonly SmileyId[]) {}
  static create(header: string, smileyIds: readonly SmileyId[]): SmileyRowSection {
    return new SmileyRowSection(header, smileyIds);
  }
  toItemInfoList(isLoading: boolean, listWidth: number, containerWidth: number, smileyCountPerRow: number, onSmileyClick: (smileyDetails: SmileyDetails) => void): ItemInfo[] {
    const infos: ItemInfo[] = [];
    infos.push({
      type: 'Header',
      text: this.header,
      height: 16 + rowGap,
      width: listWidth
      // isNavigable: false,
      // disableTouchOpacityAnimation: true,
    });

    if (!isLoading && containerWidth !== undefined) {
      const smileyRows = this.createRows(smileyCountPerRow);
      infos.push(...smileyRows.map(smileyIds => ({
        type: 'SmileyRow',
        smileyIds,
        onSmileyClick: onSmileyClick,
        height: smileyDimensions.height + listGap,
        width: listWidth,
        isNavigable: false,
        disableTouchOpacityAnimation: true
      } as SmileyRowItemInfo)));
    } else {
      const skeleton = this.createSkeleton(initialSmileyCountPerRow, listWidth);
      infos.push(...skeleton);
    }
    return infos;
  }
  private createRows(smileyCountPerRow: number | undefined): readonly SmileyId[][] {
    const rows: SmileyId[][] = [];
    if (smileyCountPerRow === undefined) {
      console.error('Trying to calculate smiley rows without value for smiley count per row');
      return rows;
    }
    for (let i = 0; i < this.smileyIds.length; i = i + smileyCountPerRow) {
      rows.push(this.smileyIds.slice(i, i + smileyCountPerRow));
    }
    return rows;
  }
  private createSkeleton(smileyCountPerRow: number, listWidth: number): readonly ItemInfo[] {
    const items: ItemInfo[] = [];
    for (let i = 0; i < smileyCountPerRow; i++) {
      items.push({
        type: 'SmileyRowSkeleton',
        smileyCount: smileyCountPerRow,
        height: smileyDimensions.height + listGap,
        width: listWidth
        // isNavigable: false,
        // disableTouchOpacityAnimation: true,
      });
    }

    return items;
  }
}