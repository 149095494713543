import { Scale, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
export const ExpandingBackgroundCircle: React.FC<{
  active: boolean;
  size: number;
  color: string;
}> = ({
  active,
  size,
  color
}) => {
  return <Scale scaled={active}
  // dividing scale by 100 make the circle appear round while scaling in safari
  from={0.01} to={size * 2 / 100} duration={500} style={{
    top: resolveThemingValue((size / 2 as ThemeOverride), "spacing", useTheme()),
    left: resolveThemingValue((size / 2 as ThemeOverride), "spacing", useTheme()),
    background: resolveThemingValue((color as any), "colors", useTheme())
  }} className={_c0 + ((color as any) ? resolveIsDarkColor((color as any), useTheme()) ? " content-is-dark" : " content-is-light" : "")} />;
};
const _c0 = " position-absolute borderRadius-circle width-100px height-100px ";