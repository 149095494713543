import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useMutation } from '@knuddels-app/Connection/client/operationHooks';
import { DeletePhoto } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { ProfileAlbumInfoQueryUpdater } from '../../profileQueryHelper';
import { useConfirmDeleteDialog } from '@knuddels-app/shared-hooks/useConfirmDeleteDialog';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
export type DeletePhotoData = {
  photoId: string;
  updateProfileAlbumInfo: ProfileAlbumInfoQueryUpdater;
};
export const useDeletePhoto = ({
  photoId,
  updateProfileAlbumInfo
}: DeletePhotoData) => {
  const snackbarService = useService($SnackbarService);
  const [deletePhoto] = useMutation(DeletePhoto);
  const track = useTrackAlbumDetails();
  return useOpenConfirmOverlay({
    onConfirm: () => {
      track('DeleteImage_Confirmed');
      deletePhoto({
        photoId
      }).then(result => {
        if (result.error || result.data?.__typename !== 'DeleteAlbumPhotoSuccess') {
          snackbarService.showGenericError();
          return;
        }
        updateProfileAlbumInfo(oldData => ({
          ...oldData,
          albumPhotos: oldData.albumPhotos.filter(p => p.id !== photoId),
          albums: [...oldData.albums.map(album => ({
            ...album,
            albumPhotos: album.albumPhotos.filter(p => p.id !== photoId)
          }))]
        }));
      });
    }
  });
};
const useOpenConfirmOverlay = ({
  onConfirm
}: {
  onConfirm(): void;
}) => {
  const i18n = useService($I18n);
  const closeMenu = useCloseThisOverlay();
  const showConfirmDialog = useConfirmDeleteDialog();
  return () => {
    showConfirmDialog({
      headline: i18n.format(declareFormat({
        id: 'album.details.delete-photo.confirm.headline',
        defaultFormat: 'Delete photo'
      })),
      text: i18n.format(declareFormat({
        id: 'album.details.delete-photo.confirm.text',
        defaultFormat: 'Do you really want to delete the photo from your albums?'
      })),
      onConfirm: onConfirm
    });
    closeMenu();
  };
};