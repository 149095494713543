import { PaymentWebsitePopupManagerInterface } from './PaymentWebsitePopupManagerInterface';

export class PaymentWebsitePopupManager
	implements PaymentWebsitePopupManagerInterface {
	private window: Window | undefined;

	/**
	 * Pre-opens a pop up because Safari only allows this as direct
	 * response to a user action.
	 * https://stackoverflow.com/a/39387533/12058228
	 *
	 * openPaymentWebsite then sets the desired URL on that pop up window.
	 */
	constructor() {
		this.window = window.open('', '_blank');
	}

	openPaymentWebsite(url: string): void {
		this.window.location.href = url;
	}

	closePaymentWebsite(): void {
		this.window.close();
	}
}
