import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { GetChannelGroups } from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { disablePeriodicChannelSelectionUpdatesFlag } from '@knuddelsModules/FeatureFlags';

export const useChannelGroupUpdater = (
	isActive: boolean,
	updateChannelData: (
		data: (typeof GetChannelGroups.TPrimaryResult)[0]
	) => void
) => {
	const visibleChannels = React.useRef<string[]>([]);
	const { newData, fetchData } = usePeriodicUpdatedData(visibleChannels);
	const updateDataOfChannel = useUpdateDataOfChannel(
		newData,
		updateChannelData
	);
	const { setVisibleChannels, stop } = useSetVisibleChannels(
		isActive,
		visibleChannels,
		updateDataOfChannel,
		fetchData
	);

	React.useEffect(() => {
		if (isActive) {
			setVisibleChannels(visibleChannels.current);
		} else {
			stop();
		}
	}, [isActive]);

	React.useEffect(() => {
		return () => {
			stop();
		};
	}, []);

	return setVisibleChannels;
};

const usePeriodicUpdatedData = (
	visibleChannels: React.MutableRefObject<string[]>
) => {
	const authenticatedClientService = useService($AuthenticatedClientService);
	const newData =
		React.useRef<typeof GetChannelGroups.TPrimaryResult>(undefined);

	const fetchData = React.useCallback(async () => {
		if (visibleChannels.current.length === 0) {
			return;
		}

		await authenticatedClientService.currentK3Client
			.queryWithResultPromise(
				GetChannelGroups,
				{
					ids: visibleChannels.current,
					pixelDensity: getPixelRatio(),
				},
				'no-cache'
			)
			.match({
				ok: r => {
					newData.current = r;
				},
				error: () => {},
			});
	}, []);

	return { newData, fetchData };
};

const useUpdateDataOfChannel = (
	newData: React.MutableRefObject<typeof GetChannelGroups.TPrimaryResult>,
	updateChannelData: (
		data: (typeof GetChannelGroups.TPrimaryResult)[0]
	) => void
) => {
	return React.useCallback(
		(groupId: string) => {
			if (!newData.current) {
				return;
			}

			const newChannel = newData.current.find(it => it.id === groupId);
			if (!newChannel) {
				return;
			}

			updateChannelData(newChannel);
		},
		[updateChannelData]
	);
};

const useSetVisibleChannels = (
	isActive: boolean,
	visibleChannels: React.MutableRefObject<string[]>,
	updateDataOfChannel: (groupId: string) => void,
	fetchData: () => Promise<void>
) => {
	const featureService = useService($FeatureService);
	const isActiveRef = React.useRef(isActive);
	const updateTimeout = React.useRef<any>();
	const nextUpdateIndex = React.useRef<number>(0);
	const iterationCounter = React.useRef(0);

	React.useEffect(() => {
		isActiveRef.current = isActive;

		if (isActive) {
			iterationCounter.current = 0;
		}
	}, [isActive]);

	const startIteration = () => {
		if (
			featureService.hasSomeFlagsEnabled([
				disablePeriodicChannelSelectionUpdatesFlag,
			])
		) {
			return;
		}

		const updateFrequency = calculateUpdateFrequency(
			iterationCounter.current
		);
		updateTimeout.current = setTimeout(
			updateChannel,
			updateFrequency / visibleChannels.current.length
		);
	};

	const updateChannel = async () => {
		const updateIndex =
			nextUpdateIndex.current % visibleChannels.current.length;

		if (updateIndex === 0) {
			iterationCounter.current++;
			await fetchData();
		}

		updateDataOfChannel(visibleChannels.current[updateIndex]);
		nextUpdateIndex.current =
			(updateIndex + 1) % visibleChannels.current.length;

		startIteration();
	};

	const setVisibleChannels = React.useCallback((groupIds: string[]) => {
		visibleChannels.current = shuffleArray(groupIds);

		clearTimeout(updateTimeout.current);
		if (groupIds.length > 0 && isActiveRef.current) {
			startIteration();
		}
	}, []);

	return {
		setVisibleChannels,
		stop: () => {
			clearTimeout(updateTimeout.current);
		},
	};
};

function shuffleArray<T>(array: T[]): T[] {
	const shuffledArray = [...array];
	for (let i = shuffledArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[shuffledArray[i], shuffledArray[j]] = [
			shuffledArray[j],
			shuffledArray[i],
		];
	}
	return shuffledArray;
}

function calculateUpdateFrequency(iterationCounter: number): number {
	if (iterationCounter <= 5) {
		return 12000;
	}

	if (iterationCounter <= 10) {
		return 18000;
	}

	if (iterationCounter <= 15) {
		return 24000;
	}

	return 60000;
}
