import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $LocationService } from '@knuddels-app/location/serviceIds';
import { $Environment } from '@knuddels-app/Environment';
import { LocalStorageKey, $LocalStorage } from '@knuddels-app/local-storage';

interface PersistedEntry {
	location: string;
	/** Time at which location info was persisted */
	persistedTime: number;
}

@injectable()
export class LocationPersistor {
	public readonly dispose = Disposable.fn();

	private readonly locationStorageKey = LocalStorageKey.withJsonSerializer<
		PersistedEntry
	>({
		name: 'lastLocation',
		cookieExpires: { inDays: 365 },
	});

	constructor(
		@inject($LocationService)
		private readonly locationService: typeof $LocationService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		if (!environment.messengerSystemAppInterface) {
			// Delete storage data to prepare removal of this code in next release
			this.localStorage.getEntry(this.locationStorageKey).reset();
		}
	}
}
