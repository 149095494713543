import { User } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Flex, Text, toPointerHandler } from '@knuddels/component-library';
import { IconReply } from '@shared/icons/IconReply';
import * as React from 'react';
import type { CondensedChannelGroupInfo } from './MessengerMessageGroup';
import { useHandleAnswerButton } from './useHandleAnswerButton';
import { colorToRgb } from '@knuddels-app/tools/colorToRgb';
export const AnswerButton: React.FC<{
  channelGroupInfo: CondensedChannelGroupInfo;
  answerTo: readonly Pick<User, 'id' | 'nick'>[];
}> = ({
  answerTo,
  channelGroupInfo
}) => {
  const handleAnswerButton = useHandleAnswerButton(answerTo);
  const i18n = useService($I18n);
  const highlightColor = colorToRgb(channelGroupInfo.highlightColor);
  return <div onClick={toPointerHandler(handleAnswerButton)} className={_c0}>
			<IconReply size={'minor'} color={highlightColor} />
			<Text color={highlightColor} className={_c1}>
				{i18n.format(declareFormat({
        id: 'PRIVATE_MESSAGE_REPLY',
        defaultFormat: 'Reply'
      }))}
			</Text>
		</div>;
};
const _c0 = " Knu-Flex mt-small alignItems-center cursor-pointer alignSelf-center ";
const _c1 = " ml-tiny ";