import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { $NativeBackgroundStore } from './serviceIds';
import { NativeBackgroundStore } from './services/NativeBackgroundStore';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope
		.bind($NativeBackgroundStore)
		.toSingleton(NativeBackgroundStore);
}

export { IPhoneXWrapper } from './components/IPhoneXWrapper';
export { IPhoneWrapperSimple } from './components/IPhoneXWrapperSimple';
export { NativeTitleBar } from './components/NativeTitleBar';
export { NativeBackgroundTitleBar } from './components/NativeBackgroundTitleBar';
export { NativeBackgroundWrapper } from './components/NativeBackgroundWrapper';
export { useNativeBackground } from './hooks/useNativeBackground';
export { useThemedNativeBackground } from './hooks/useThemedNativeBackground';
