import { jsxs as O, jsx as i } from "react/jsx-runtime";
import { useDrag as K } from "../../deps/@use-gesture/react/dist/use-gesture-react.esm.js";
import { clsx as R } from "../../node_modules/clsx/dist/clsx.m.js";
import * as p from "react";
import { getTranslateX as b, getPercentagePerChild as A, getNewIndex as L, getNewOffset as B } from "./calc.js";
import { useElementWidth as F } from "../Layout/hooks/useElementWidth.js";
import { IconBack as H } from "../Icons/Back.js";
import { IconNext as U } from "../Icons/Next.js";
import { useAnimate as V } from "../../deps/framer-motion/dist/es/animation/hooks/use-animate.js";
import { useMotionValue as j } from "../../deps/framer-motion/dist/es/value/use-motion-value.js";
import { motion as _ } from "../../deps/framer-motion/dist/es/render/dom/motion.js";
import { Flex as k } from "../Layout/Flex.js";
import { Z_INDEX as T } from "../configs/zindex.js";
import { useBgClassName as C } from "../Layout/Box/useBg.js";
const G = (e) => e.index < e.wrapItemCount || e.index > e.numberOfSlides - e.wrapItemCount - 1 || e.index >= e.activeIndex - e.prerenderItemCount && e.index <= e.activeIndex + e.prerenderItemCount, M = (e, r, l) => e >= l - r ? 0 : e < r ? l - r - 2 : e - 1, v = (e, r, l) => e + r > l + r - 1 ? r : e + r, S = (e) => /* @__PURE__ */ i("div", { className: "Knu-Flex size-full", children: e.slide && p.cloneElement(e.slide, {
  width: "100%",
  key: 0,
  renderContent: !0
}) }), Z = ({
  children: e,
  fullSize: r = !0,
  visibleSlides: l = 1,
  infinite: c = !0,
  prevAdornment: s,
  nextAdornment: d,
  disabled: w = !1,
  initialIndex: N,
  keyboardNavigation: P = !1,
  onChange: m
}) => {
  const { width: h, bind: f } = F(), u = p.useRef(0), y = p.Children.toArray(e), E = y.length > 1, g = E && !w, n = c && E ? l : 0, a = [
    ...n !== 0 ? y.slice(-n) : [],
    ...y,
    ...n !== 0 ? y.slice(0, n) : []
  ], X = a.length, [t, I] = p.useState(
    typeof N == "number" ? v(N, n, X) : c && E ? l : 0
  ), x = (o) => {
    I(o), o !== t && (m == null || m(M(o, n, X)));
  }, z = l === 1 && g ? (o) => {
    Math.abs(u.current - o.pageX) > 0 || x(
      o.locationX / h > 0.5 ? t + 1 : t - 1
    );
  } : void 0, D = (o) => {
    switch (o.key) {
      case "ArrowLeft":
        x(t - 1), o.preventDefault();
        break;
      case "ArrowRight":
        x(t + 1), o.preventDefault();
        break;
    }
  };
  return p.useEffect(() => (P && g && window.addEventListener("keydown", D), () => {
    P && g && window.removeEventListener("keydown", D);
  }), [P, D]), /* @__PURE__ */ O(
    k,
    {
      ...f,
      width: "100%",
      height: "100%",
      position: "relative",
      onPointerDown: (o) => {
        u.current = o.pageX;
      },
      onPointerUp: z,
      px: r ? "none" : "xxxlarge",
      children: [
        g && /* @__PURE__ */ i(
          k,
          {
            zIndex: T.HIGHEST_CONTENT,
            position: "absolute",
            alignSelf: "center",
            left: "base",
            onPointerUp: (o) => o.stopPropagation(),
            children: s ? s(() => x(t - 1)) : /* @__PURE__ */ i(
              H,
              {
                disabled: !c && t === 0,
                onPress: () => {
                  x(t - 1);
                },
                size: "large"
              }
            )
          }
        ),
        /* @__PURE__ */ i("div", { className: "Knu-Flex flex-1 height-full overflow-hidden", children: h > 0 ? /* @__PURE__ */ i(
          q,
          {
            activeIndex: t,
            setActiveIndex: I,
            updateActiveIndex: x,
            slides: a,
            containerWidth: h,
            visibleSlides: l,
            wrapItemCount: n,
            infinite: c,
            allowNavigation: g
          }
        ) : /* @__PURE__ */ i(S, { slide: a[t] }) }),
        g && /* @__PURE__ */ i(
          k,
          {
            zIndex: T.HIGHEST_CONTENT,
            position: "absolute",
            alignSelf: "center",
            right: "base",
            onPointerUp: (o) => o.stopPropagation(),
            children: d ? d(() => x(t + 1)) : /* @__PURE__ */ i(
              U,
              {
                disabled: !c && t === a.length - l,
                onPress: () => {
                  x(t + 1);
                },
                size: "large"
              }
            )
          }
        )
      ]
    }
  );
};
Z.Slide = p.memo((e) => /* @__PURE__ */ i(
  "div",
  {
    className: R(
      "Knu-Flex height-full bg-contentBg",
      C("contentBg")
    ),
    style: { width: e.width },
    children: e.renderContent && e.children
  }
));
const q = ({
  activeIndex: e,
  setActiveIndex: r,
  updateActiveIndex: l,
  slides: c,
  visibleSlides: s,
  containerWidth: d,
  wrapItemCount: w,
  infinite: N,
  allowNavigation: P
}) => {
  const [m, h] = V(), f = j(
    b({
      containerWidth: d,
      numberOfSlides: c.length,
      activeIndex: e,
      visibleSlides: s
    })
  ), u = c.length, y = s * 2;
  p.useEffect(() => {
    h(
      m.current,
      {
        translateX: f.get() + "%"
      },
      { duration: 0 }
    );
  }, []);
  const E = K(
    (n) => {
      if (n.last) {
        const a = L({
          containerWidth: d,
          numberOfSlides: u,
          offset: f.get(),
          translationX: n.movement[0],
          activeIndex: e,
          visibleSlides: s
        });
        f.set(
          b({
            containerWidth: d,
            numberOfSlides: u,
            activeIndex: a,
            visibleSlides: s
          })
        ), h(
          m.current,
          {
            translateX: f.get() + "%"
          },
          { type: "spring", duration: 0.3, bounce: 0 }
        ).then(() => {
          l(a);
        });
      } else
        f.set(
          B({
            containerWidth: d,
            numberOfSlides: u,
            offset: f.get(),
            translationX: n.delta[0],
            activeIndex: e,
            visibleSlides: s
          })
        ), h(
          m.current,
          {
            translateX: f.get() + "%"
          },
          { duration: 0 }
        );
    },
    { axis: "x", enabled: P }
  ), g = A(
    c.length,
    s
  );
  return p.useLayoutEffect(() => {
    const n = b({
      visibleSlides: s,
      numberOfSlides: u,
      containerWidth: d,
      activeIndex: e
    }), a = () => {
      if (!N)
        return;
      const X = (t) => {
        const I = b({
          visibleSlides: s,
          numberOfSlides: u,
          containerWidth: d,
          activeIndex: t
        });
        f.set(I), h(
          m.current,
          { translateX: I + "%" },
          { duration: 0 }
        ), r(t);
      };
      if (e === w - 1) {
        const t = u - w - 1;
        X(t);
      } else
        e === u - w && X(w);
    };
    f.get() !== n ? (f.jump(n), h(
      m.current,
      { translateX: n + "%" },
      { type: "spring", duration: 0.3, bounce: 0 }
    ).then(() => {
      a();
    })) : a();
  }, [e, g, d]), /* @__PURE__ */ i("div", { ...E(), className: "width-full", children: /* @__PURE__ */ i(
    _.div,
    {
      ref: m,
      style: {
        display: "flex",
        width: c.length * 100 + "%",
        height: "100%",
        alignItems: "stretch",
        flexShrink: 0
      },
      children: /* @__PURE__ */ i(k, { height: "full", width: "full", children: c.map(
        (n, a) => p.cloneElement(n, {
          width: g + "%",
          key: J(
            n,
            a,
            w,
            c.length
          ),
          renderContent: G({
            prerenderItemCount: y,
            wrapItemCount: w,
            index: a,
            activeIndex: e,
            numberOfSlides: u
          })
        })
      ) })
    }
  ) });
};
function J(e, r, l, c) {
  const s = e.key ?? r;
  return r < l ? "wrap-front-" + s : r > c - 1 - l ? "wrap-behind-" + s : s;
}
export {
  Z as Carousel
};
