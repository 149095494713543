import { isVariantLabel as e } from "../../render/utils/is-variant-label.js";
import { isControllingVariants as r } from "../../render/utils/is-controlling-variants.js";
function o(i, a) {
  if (r(i)) {
    const { initial: n, animate: t } = i;
    return {
      initial: n === !1 || e(n) ? n : void 0,
      animate: e(t) ? t : void 0
    };
  }
  return i.inherit !== !1 ? a : {};
}
export {
  o as getCurrentTreeVariants
};
