import { newServiceId } from '@knuddels-app/DependencyInjection';
import {
	MessengerService,
	MessengerConversationService,
	SnapService,
	MessengerNavigationService,
	MessengerComponents,
	MessengerOverviewService,
	ReadSystemService,
	StarMessageService,
	MessengerImageService,
	NewMessageService,
	SendMessageService,
	MessengerListService,
	MessengerMiniChatService,
} from './bundle/services';

export const $MessengerService = newServiceId<MessengerService>(
	'$MessengerService'
);

export const $MessengerConversationService = newServiceId<
	MessengerConversationService
>('$MessengerConversationService');

export const $SnapService = newServiceId<SnapService>('$SnapService');

export const $MessengerNavigationService = newServiceId<
	MessengerNavigationService
>('$MessengerNavigationService');

export const $MessengerComponents = newServiceId<MessengerComponents>(
	'$MessengerComponents'
);

export const $MessengerOverviewService = newServiceId<MessengerOverviewService>(
	'$MessengerOverviewService'
);

export const $MessengerListService = newServiceId<MessengerListService>(
	'$MessengerListService'
);

export const $ReadSystemService = newServiceId<ReadSystemService>(
	'$ReadSystemService'
);

export const $StarMessageService = newServiceId<StarMessageService>(
	'$StarMessageService'
);

export const $MessengerImageService = newServiceId<MessengerImageService>(
	'$MessengerImageService'
);

export const $NewMessageService = newServiceId<NewMessageService>(
	'$NewMessageService'
);

export const $SendMessageService = newServiceId<SendMessageService>(
	'$SendMessageService'
);

export const $MessengerMiniChatService = newServiceId<MessengerMiniChatService>(
	'$MessengerMiniChatService'
);
