import { createK3Router } from '@knuddels-app/layout/k3Router';
import { messengerViewId } from '@knuddelsModules/Messenger';

export type MessengerRouterConfig = {
	overview: { params: undefined };
	newConversation: { params: undefined };
	starredMessages: { params: undefined };
	settings: { params: undefined };
	credits: { params: undefined };
	archive: { params: undefined };
	newConversationSettings: { params: undefined };
	contactListSettings: { params: undefined };
	profileVisitors: { params: undefined };
	privacySettings: { params: undefined };
	mentorSystemSettings: { params: undefined };
	soundSettings: { params: undefined };
	messengerSettings: { params: undefined };
	conversation: {
		params: {
			conversationId: string;
			scrollToMessageId?: string;
			autoFocus?: boolean;
			initialView?: boolean; // TODO fix generic routing behavior
		};
	};
	'mentorsystem-rewards': { params: { tab?: number } };
	'mentorsystem-about': { params: { tab?: number } };
};

export const { Router, useRouter, useConfigureNavBar, useNavigate, useParams } =
	createK3Router<MessengerRouterConfig>(messengerViewId);
