import { isBezierDefinition as t } from "../../../easing/utils/is-bezier-definition.js";
function o(e) {
  return !!(!e || typeof e == "string" && e in i || t(e) || Array.isArray(e) && e.every(o));
}
const r = ([e, n, a, u]) => `cubic-bezier(${e}, ${n}, ${a}, ${u})`, i = {
  linear: "linear",
  ease: "ease",
  easeIn: "ease-in",
  easeOut: "ease-out",
  easeInOut: "ease-in-out",
  circIn: r([0, 0.65, 0.55, 1]),
  circOut: r([0.55, 0, 1, 0.45]),
  backIn: r([0.31, 0.01, 0.66, -0.59]),
  backOut: r([0.33, 1.53, 0.69, 0.99])
};
function s(e) {
  return c(e) || i.easeOut;
}
function c(e) {
  if (e)
    return t(e) ? r(e) : Array.isArray(e) ? e.map(s) : i[e];
}
export {
  r as cubicBezierAsString,
  o as isWaapiSupportedEasing,
  c as mapEasingToNativeEasing,
  i as supportedWaapiEasing
};
