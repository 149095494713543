import { $AuthService } from '@knuddels-app/Connection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $ActiveChannelService } from '../../../providedServiceIds';
import { $AppService } from '@knuddelsModules/Apps';
import { $CommandWithoutChannelService } from '@knuddels-app/Commands';

@injectable()
export class ReconnectedService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($ActiveChannelService)
		private readonly activeChannelService: typeof $ActiveChannelService.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($AppService)
		private readonly appService: typeof $AppService.T,
		@inject($CommandWithoutChannelService)
		private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T
	) {
		this.appService.onDirectConnectionLost = (
			appType,
			globalAppKey,
			channelName
		) => {
			if (appType === 'channel') {
				// We're already going to refresh the session somewhere else
				// so we don't need to do it here. Otherwise apps would reopen
				// multiple times.
				if (
					this.authenticatedClientService.connectionState ===
					'Offline'
				) {
					return;
				}

				setTimeout(() => {
					if (
						(!channelName ||
							channelName ===
								this.activeChannelService.activeChannel
									?.name) &&
						Date.now() - this.authService.lastForceRefreshSession >
							2000 &&
						this.activeChannelService.state.kind !==
							'disconnected-by-server'
					) {
						this.authService.forceRefreshSession();
					}
				}, 1000);
			} else {
				setTimeout(() => {
					this.commandWithoutChannelService.invokeCommand(
						'/apps',
						'sid~globalAppsOverview:open:' + globalAppKey
					);
				}, 1000);
			}
		};
	}
}
