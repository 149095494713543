import { foreachProp } from '@knuddels/std';
import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as services from './services';
import { FeatureService } from './FeatureService';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(services, {
		$FeatureService: ctx.globalScope.toSingleton(FeatureService),
	});
}
