import { useService } from '@knuddels-app/DependencyInjection';
import {
	$CommandService,
	$CommandWithoutChannelService,
} from '@knuddels-app/Commands/serviceIds';
import { $Environment } from '@knuddels-app/Environment';

export const useSlashCommandWithoutChannel = (options?: {
	shouldUseStappIfAvailable?: boolean;
	disableClientSideCommandHandling?: boolean;
}) => {
	const environment = useService($Environment);
	const commandServiceWithoutChannel = useService(
		$CommandWithoutChannelService
	);
	const commandService = useService($CommandService);
	return async (command: string) => {
		if (
			options?.shouldUseStappIfAvailable &&
			environment.messengerSystemAppInterface
		) {
			environment.messengerSystemAppInterface.client.executeSlashCommand(
				command
			);
			return 'SendSlashCommandSuccess';
		}

		const commandCall = commandService.tryParseCommandCall(command);
		if (!commandCall) {
			return 'SendSlashCommandNotFoundError';
		}

		return await commandServiceWithoutChannel.invokeCommand(
			commandCall.commandName,
			commandCall.parameter,
			{
				disableClientSideCommandHandling:
					options?.disableClientSideCommandHandling,
			}
		);
	};
};
