import * as React from 'react';
import { GenericMessage } from './GenericMessage';
import { FormattedText, Spacer } from '@shared/components';
import { ClickableUserText } from '../ClickableUser';
import { messageEvent } from '@knuddelsModules/Channel/analytics';
import { ChannelMessageFragment, MenteeStatus } from '@generated/graphql';
import { Flex } from '@knuddels/component-library';
import { SheepIcon } from '@shared/icons/Sheep';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
export interface PublicMessageProps {
  isFirst?: boolean;
  sender: ChannelMessageFragment['sender'];
  text: FormattedText;
}
export const PublicMessage: React.FC<PublicMessageProps> = React.memo(({
  sender,
  text,
  isFirst
}) => {
  const handleClick = React.useCallback(() => {
    messageEvent.track('Message_NicknameClicked');
  }, []);
  const activeChannelService = useService($ActiveChannelService);
  const participant = activeChannelService.activeChannel?.participants?.get(sender.id);
  return <GenericMessage title={isFirst && <div className={_c0}>
							<ClickableUserText uid={sender.id} onClick={handleClick}>
								{sender.nick}
							</ClickableUserText>
							{participant?.user?.menteeStatus === MenteeStatus.Mentee && <>
									<Spacer size={'small'} />
									<SheepIcon />
								</>}
						</div>} content={text} />;
});
PublicMessage.displayName = 'PublicMessage';
const _c0 = " Knu-Flex alignItems-center ";