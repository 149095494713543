import * as React from 'react';
import { TouchableProps } from './TouchableProps';
import { Column } from '../../Layouts';
import styles from './Touchable.module.scss';
export class OldTouchable extends React.PureComponent<TouchableProps> {
  private buttonRef = React.createRef<HTMLButtonElement>();
  public focus = (): void => {
    if (this.buttonRef.current) {
      this.buttonRef.current.focus();
    }
  };
  render(): JSX.Element {
    const {
      onClick,
      fluid,
      addTouchheight,
      disableOutline,
      className,
      children,
      label,
      colorStyles,
      testId,
      ...props
    } = this.props;
    const classes = [styles.Touchable, className].join(' ');
    const a11y: {
      [key: string]: string | boolean | number;
    } = {};
    if (label) {
      a11y['aria-label'] = label;
    }
    const style = props.style || {};
    if (disableOutline) {
      style.outline = 'none';
    }
    return <Column fluid={fluid} style={{
      maxWidth: '100%',
      ...props.style,
      ...colorStyles
    }} testId={testId}>
				{addTouchheight}
				<button title={props.title} ref={this.buttonRef} onClick={onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} className={classes} {...props} {...a11y} style={style}>
					{children}
				</button>
				{addTouchheight}
			</Column>;
  }
}