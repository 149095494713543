import { jsx as r, jsxs as s } from "react/jsx-runtime";
import { useDisabledOpacity as n } from "./useDisabledOpacity.js";
import { HitArea as u, Label as m } from "./CommonRadioCheckbox.js";
import { RadioContext as h, useRadioButton as b } from "./useRadioButton.js";
import { FlexCol as p } from "../Layout/FlexCol.js";
import { useTheme as f } from "../../themes/useTheme.js";
import { Flex as a } from "../Layout/Flex.js";
const v = (e) => /* @__PURE__ */ r(h.Provider, { value: e, children: /* @__PURE__ */ r(p, { ariaRole: "radiogroup", children: e.children }) }), C = (e) => {
  const o = b(e), c = n(o), t = f(), i = t.sizes.radio.default, l = t.sizes.radio.selectionMarker, d = o.selected ? 1 : 0;
  return /* @__PURE__ */ s(
    a,
    {
      ...o.accessibilityProps,
      ...c,
      onFocus: o.onFocus,
      onBlur: o.onBlur,
      children: [
        /* @__PURE__ */ r(u, { labelStyle: e.labelStyle, children: /* @__PURE__ */ r(
          "div",
          {
            className: "Knu-FlexCol placeItems-center borderWidth-large borderStyle-solid borderColor-inputFieldBorder borderRadius-circle hoverBorderColor-inputFieldBorderHover",
            style: { height: i, width: i },
            children: /* @__PURE__ */ r(
              a,
              {
                height: l + "px",
                width: l + "px",
                bg: "accent",
                placeItems: "center",
                borderRadius: "circle",
                transform: `scale(${d})`,
                animated: !0
              }
            )
          }
        ) }),
        /* @__PURE__ */ r(m, { labelStyle: e.labelStyle, children: e.label })
      ]
    }
  );
};
export {
  C as RadioButton,
  v as RadioGroup
};
