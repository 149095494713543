import {
	CommunityStatus,
	EngagementSystemStage,
	GetCurrentUserNick,
	GetUserIdFromNick,
	HasKnuddelsPlusChanged,
	OwnProfilePictureChanged,
	OwnProfilePictureChangedSubscription,
	OwnProfilePictureOverlaysChanged,
	OwnProfilePictureOverlaysChangedSubscription,
	User,
	UserKnuddelUpdated,
} from '@generated/graphql';
import {
	$AuthenticatedClientService,
	$AuthService,
	$LastLoginStorage,
} from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, EventEmitter } from '@knuddels/std';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';
import { crashlyticsClient } from '@knuddels-app/analytics/crashlytics';
import { StaticUserData } from '../../interfaces';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';

@injectable()
export class UserService implements Disposable {
	private knuddelAmountChangedListeners: Array<(newAmount: number) => void> =
		[];

	public readonly dispose = Disposable.fn();

	private readonly userLoadedEmitter = new EventEmitter<StaticUserData>();
	private readonly onUserLoaded = this.userLoadedEmitter.asEvent();

	private readonly profilePictureChangedEmitter = new EventEmitter<
		OwnProfilePictureChangedSubscription['userChanged']
	>();
	private readonly profilePictureOverlaysChangedEmitter = new EventEmitter<
		OwnProfilePictureOverlaysChangedSubscription['userChanged']
	>();

	public onProfilePictureChanged =
		this.profilePictureChangedEmitter.asEvent();
	public onProfilePictureOverlaysChanged =
		this.profilePictureOverlaysChangedEmitter.asEvent();

	private readonly currentUserQuery =
		this.authenticatedClientService.currentK3Client.watchQuery(
			GetCurrentUserNick,
			{}
		);

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($K3Firebase)
		private readonly k3Firebase: typeof $K3Firebase.T,
		@inject($LastLoginStorage)
		lastLoginStorage: typeof $LastLoginStorage.T,
		@inject($AuthService)
		authService: typeof $AuthService.T
	) {
		this.currentUserQuery.observableQuery
			.result()
			.then(result => {
				const user = result.data.user.currentUser;

				k3Firebase.analytics.setUserId(`${user.id}`);
				k3Firebase.analytics.setUserProperties({
					['CommunityStatus']: getCommunityStatusUserProperty(
						user.status
					),
				});
				crashlyticsClient.setUser(user.id, user.nick);

				this.setupCurrentUserKnuddelSubscription(
					user.id,
					user.knuddelAmount
				);
				this.userLoadedEmitter.emit(user);
			})
			.catch(err => {
				console.error(
					'Failed loading current user which might result in the app not working.',
					err
				);
				// handle query fail, maybe retry
				// this.currentUserQuery.observableQuery.refetch();
			});

		this.dispose.track(() => {
			k3Firebase.analytics.setUserId(null);
			k3Firebase.analytics.setUserProperties({
				['CommunityStatus']: null,
			});
			crashlyticsClient.clearUser();
		});

		this.dispose.track(
			authService.onBeforeVoluntaryLogout.sub(({ logoutOptions }) => {
				if (logoutOptions.newNick) {
					lastLoginStorage.setLastUsedNick(logoutOptions.newNick);
				}
			})
		);

		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				HasKnuddelsPlusChanged,
				{},
				{}
			)
		);

		this.getCurrentUser().then(user => {
			lastLoginStorage.setLastUsedNick(user.nick);

			this.dispose.track(
				this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
					OwnProfilePictureChanged,
					{ id: user.id, pixelDensity: getPixelRatio() },
					{
						next: event => {
							this.profilePictureChangedEmitter.emit(event);

							const cache =
								this.authenticatedClientService.currentK3Client
									.client.cache;
							cache.modify({
								id: cache.identify(event),
								fields: {
									profilePicture: () => event.profilePicture,
								},
							});
						},
					}
				)
			);

			this.dispose.track(
				this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
					OwnProfilePictureOverlaysChanged,
					{ id: user.id },
					{
						next: event => {
							this.profilePictureOverlaysChangedEmitter.emit(
								event
							);
						},
					}
				)
			);
		});
	}

	protected setupCurrentUserKnuddelSubscription = (
		id: User['id'],
		initialKnuddelAmount: number | undefined | null
	) => {
		let previousKnuddelAmount = initialKnuddelAmount;

		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				UserKnuddelUpdated,
				{ id },
				{
					next: event => {
						const newKnuddelAmount = event.knuddelAmount;

						// skip in case of an invalid value
						if (typeof newKnuddelAmount !== 'number') {
							return;
						}

						this.knuddelAmountChangedListeners.forEach(listener =>
							listener(newKnuddelAmount)
						);

						// skip and fix invalid value
						if (typeof previousKnuddelAmount !== 'number') {
							previousKnuddelAmount = newKnuddelAmount;
							return;
						}

						const diff = newKnuddelAmount - previousKnuddelAmount;
						if (diff > 0) {
							this.k3Firebase.analytics.logEarnVirtualCurrency({
								virtual_currency_name: 'Knuddel',
								value: diff,
							});
						}
						previousKnuddelAmount = event.knuddelAmount;
					},
				}
			)
		);
	};

	public addKnuddelAmountChangedListener(
		listener: (newAmount: number) => void
	): () => void {
		this.knuddelAmountChangedListeners.push(listener);
		return () => {
			const index = this.knuddelAmountChangedListeners.indexOf(listener);
			if (index !== -1) {
				this.knuddelAmountChangedListeners.splice(index, 1);
			}
		};
	}

	public async getCurrentUser(): Promise<StaticUserData> {
		if (this.currentUser) {
			return this.currentUser;
		}

		return this.onUserLoaded.waitOne();
	}

	// TECHDEBT Only load app after `currentUser` is set (=> remove all undefined checks)
	public get currentUser(): StaticUserData | undefined {
		const value = this.currentUserQuery.value;
		return value === 'loading' || value === 'error'
			? undefined
			: value.user.currentUser;
	}

	public isCurrentUser(id: string): boolean {
		const user = this.currentUser;
		return user !== undefined && user.id === id;
	}

	public isOnboarding(): boolean {
		const user = this.currentUser;
		return (
			user !== undefined &&
			user.engagementSystemStage === EngagementSystemStage.Onboarding
		);
	}

	public async getUserIdFromNick(
		nick: string,
		accountForNickSwitch?: boolean
	): Promise<string | null> {
		return this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(GetUserIdFromNick, {
				nick,
				accountForNickSwitch: accountForNickSwitch || false,
			})
			.match({
				ok: user => {
					if (user) {
						return user.id;
					} else {
						return null;
					}
				},
				error: err => {
					console.error(
						`Failed to get user id for user with nick=${nick}:`,
						err
					);
					return null;
				},
			});
	}
}

function getCommunityStatusUserProperty(
	status: CommunityStatus | null | undefined
): string | null {
	if (!status) {
		return null;
	}

	switch (status) {
		case CommunityStatus.Normal:
			return 'Member';
		case CommunityStatus.Family:
			return 'Family';
		case CommunityStatus.Stammi:
			return 'Stammi';
		case CommunityStatus.Admin:
			return 'Admin';
		case CommunityStatus.Honor:
			return 'HonourableMember';
		case CommunityStatus.Sysadmin:
			return 'Sysadmin';
		case CommunityStatus.UnofficialAdmin:
			return 'UnofficialAdmin';
		default:
			return null;
	}
}
