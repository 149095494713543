import { EditProfileEntriesProps, GetUserForProfileQuery, ProfileEditEntry, User, withEditProfileEntries } from '@generated/graphql';
import { declareProps, IModel, inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $ViewService } from '@knuddels-app/layout';
import { Disposable } from '@knuddels/std';
import { OwnProfileViewProfileEditEvent } from '@knuddelsModules/Profile/analytics';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import * as React from 'react';
import { EditProfileLogic } from './EditProfileLogic';
import { ProfileEditor } from './EditProfileContent/logic/ProfileEditor';
import { ProfileAlbumInfo } from '../../profileQueryHelper';
import { NickSwitchKnuddelCostContext } from './NickSwitchKnuddelCostContext';
type OwnProps = {
  currentUser: Pick<User, 'id'>;
  profileUserData: GetUserForProfileQuery;
  profileAlbumInfoData: ProfileAlbumInfo;
  onEditor(editor: ProfileEditor | undefined): void;
};
type Props = EditProfileEntriesProps & OwnProps;
@injectable()
class EditProfileModel implements IModel {
  public dispose = Disposable.fn();
  constructor(@injectProps()
  public readonly props: Props, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T) {}
  componentDidMount(): void {
    OwnProfileViewProfileEditEvent.track('Opened');
  }
  public close = (): void => {
    this.viewService.closeView(profileViewId);
  };
  public get user(): Pick<User, 'id'> | undefined {
    return this.props.currentUser;
  }
  public get entries(): ReadonlyArray<ProfileEditEntry> {
    return this.props.data && this.props.data.user && this.props.data.user.profileEditEntries || [];
  }
  public get nickSwitchKnuddelCost(): number | undefined {
    return this.props.data && this.props.data.payment && this.props.data.payment.nickSwitchKnuddelCost;
  }
}
const EditProfileBase = injectedComponent({
  name: 'EditProfileBase',
  model: EditProfileModel,
  props: declareProps<Props>()
}, ({
  model,
  profileUserData,
  profileAlbumInfoData
}) => {
  if (model.entries.length === 0 || typeof model.nickSwitchKnuddelCost === 'undefined' || !profileUserData || !profileUserData.user || !profileUserData.user.user) {
    return null;
  }
  return <NickSwitchKnuddelCostContext.Provider value={model.nickSwitchKnuddelCost}>
				<EditProfileLogic entries={model.entries} currentUser={model.user} user={profileUserData.user.user} albumInfo={profileAlbumInfoData} onClose={model.close} onEditor={model.props.onEditor} />
			</NickSwitchKnuddelCostContext.Provider>;
});
export const EditProfile = withEditProfileEntries<OwnProps>({
  options: () => ({
    fetchPolicy: 'network-only'
  })
})(EditProfileBase);