import * as React from 'react';
import { Container } from '../../Containers';
import { LayoutProps } from '../LayoutProps';
import styles from './Column.module.scss';
export class Column extends React.PureComponent<LayoutProps> {
  render(): JSX.Element {
    const {
      className,
      fluid,
      testId,
      ...newProps
    } = this.props;
    const classes = [fluid ? styles.ColumnFluid : styles.Column, className].join(' ');
    return <Container className={classes} {...newProps} data-test-id={testId} />;
  }
}