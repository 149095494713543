import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import * as React from 'react';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { useService } from '@knuddels-app/DependencyInjection';
import { runInAction } from '@knuddels-app/mobx';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
import { IconTrash } from '@knuddels/component-library';
import { IconReport } from '@shared/icons/IconReport';
import { useReportComment } from './useReportComment';
import { useRemoveComment } from './useRemoveComment';
import { ContextMenuDivider } from '@shared/components/molecules/Modal/ContextMenuDivider';
interface Props extends ContextMenuProps {
  photoId: string;
  commentId: string;
  isOwnComment: boolean;
  isOwner: boolean;
  canAdministrate: boolean;
  removeCommentFromCache: () => void;
}
export const PhotoCommentContextMenu: React.FC<Props> = ({
  photoId,
  commentId,
  removeCommentFromCache,
  isOwner,
  canAdministrate,
  isOwnComment,
  ...props
}) => {
  const closeContextMenu = useCloseContextMenu();
  return <ContextMenu {...props} closeContextMenu={closeContextMenu}>
			{isOwnComment ? <RemoveEntry photoId={photoId} commentId={commentId} removeCommentFromCache={removeCommentFromCache} /> : canAdministrate ? <>
					<ReportEntry photoId={photoId} commentId={commentId} />
					<ContextMenuDivider />
					<RemoveEntry photoId={photoId} commentId={commentId} removeCommentFromCache={removeCommentFromCache} />
				</> : isOwner ? <>
					<RemoveEntry photoId={photoId} commentId={commentId} removeCommentFromCache={removeCommentFromCache} />
					<ContextMenuDivider />
					<ReportEntry photoId={photoId} commentId={commentId} />
				</> : <ReportEntry photoId={photoId} commentId={commentId} />}
		</ContextMenu>;
};
export const ReportEntry: React.FC<{
  photoId: string;
  commentId: string;
}> = ({
  photoId,
  commentId
}) => {
  const track = useTrackAlbumDetails();
  const closeContextMenu = useCloseContextMenu();
  const reportComment = useReportComment();
  return <ContextMenuEntry icon={IconReport} text={formatMessage(declareFormat({
    id: 'album.details.comment.report',
    defaultFormat: 'Report comment'
  }))} onClick={() => {
    reportComment(photoId, commentId);
    track('ReportComment_Clicked');
    closeContextMenu();
  }} />;
};
const RemoveEntry: React.FC<{
  photoId: string;
  commentId: string;
  removeCommentFromCache: () => void;
}> = ({
  photoId,
  commentId,
  removeCommentFromCache
}) => {
  const track = useTrackAlbumDetails();
  const closeContextMenu = useCloseContextMenu();
  const removeComment = useRemoveComment(removeCommentFromCache);
  return <ContextMenuEntry icon={IconTrash} text={formatMessage(declareFormat({
    id: 'album.details.comment.delete',
    defaultFormat: 'Remove comment'
  }))} onClick={() => {
    removeComment(photoId, commentId);
    track('DeleteComment_Clicked');
    closeContextMenu();
  }} />;
};
export const PhotoCommentContextMenuOverlay = new OverlayFactory(PhotoCommentContextMenu, props => ({
  photoId: props.photoId,
  commentId: props.commentId
}));
const useCloseContextMenu = () => {
  const thisVisibleOverlay = useService($ThisVisibleOverlay);
  return () => {
    runInAction('close context menu', () => {
      thisVisibleOverlay.dispose();
    });
  };
};