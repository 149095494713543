import { action, observable } from '@knuddels-app/mobx';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	BackgroundType,
	BottomBackgroundStyle,
	TopBackgroundStyle,
} from '../interfaces';
import { $ScreenService } from '@knuddels-app/Screen';
import {
	modifyStylesForSafeAreaBottom,
	modifyStylesForSafeAreaTop,
} from './modifyStylesForSafeArea';

@injectable()
export class NativeBackgroundStore {
	@observable
	private _topBackgroundStyle: TopBackgroundStyle | undefined;
	@observable
	private _bottomBackgroundStyle: BottomBackgroundStyle | undefined;

	constructor(
		@inject($ScreenService)
		private readonly screenService: typeof $ScreenService.T
	) {}

	public get topBackgroundStyle(): TopBackgroundStyle {
		return (
			(this.screenService.isStackedLayout && this._topBackgroundStyle) ||
			defaultTopBackgroundStyle
		);
	}

	public get bottomBackgroundStyle(): BottomBackgroundStyle {
		return (
			(this.screenService.isStackedLayout &&
				this._bottomBackgroundStyle) ||
			defaultBottomBackgroundStyle
		);
	}

	@action.bound
	public setTopBackground(color: string, type: BackgroundType): void {
		this._topBackgroundStyle = { color, type };
		modifyStylesForSafeAreaTop(color, type);
	}

	@action.bound
	public setBottomBackground(color: string): void {
		this._bottomBackgroundStyle = { color };
		modifyStylesForSafeAreaBottom(color);
	}

	@action.bound
	public unsetTopBackgroundStyle(): void {
		this._topBackgroundStyle = undefined;
	}

	@action.bound
	public unsetBottomBackgroundStyle(): void {
		this._bottomBackgroundStyle = undefined;
	}
}

export const defaultTopBackgroundStyle: TopBackgroundStyle = {
	color: 'rgb(0, 0, 0)',
	type: 'dark',
};

export const defaultBottomBackgroundStyle: BottomBackgroundStyle = {
	color: 'rgb(25, 25, 25)',
};
