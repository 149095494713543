import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const n = r(
  /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", children: /* @__PURE__ */ o(
    "path",
    {
      fill: "currentColor",
      d: "M 21 11.109375 C 21 9.78125 19.519531 8.988281 18.414062 9.722656 L 12 14 L 5.585938 9.726562 C 4.480469 8.988281 3 9.78125 3 11.109375 C 3 11.664062 3.277344 12.183594 3.742188 12.492188 L 10.890625 17.261719 C 11.5625 17.707031 12.4375 17.707031 13.109375 17.261719 L 20.261719 12.492188 C 20.722656 12.183594 21 11.664062 21 11.109375 Z M 21 11.109375 "
    }
  ) })
);
n.displayName = "IconChevronDown";
export {
  n as IconChevronDown
};
