import { BlurredBackgroundImage, MessageBubble, Spacer } from '@shared/components';
import { SnapSize } from '../../Messages/ImageAndSnap/SnapSizes';
import { FormattedDateTime } from '@knuddels-app/i18n';
import * as React from 'react';
import { SendingImage } from '../../../../services/conversationServices/MessengerConversationService';
import { Flex, FlexCol, ThemeOverride, Text, useTheme, resolveThemingValue } from '@knuddels/component-library';
export interface SendImageBubbleProps {
  isStackedLayout: boolean;
  withArrow: boolean;
  sendingImage: SendingImage;
}
export const SendImageBubble = ({
  isStackedLayout,
  withArrow,
  sendingImage
}: SendImageBubbleProps) => {
  const theme = useTheme();
  return <div style={{
    marginTop: resolveThemingValue((-4 as ThemeOverride), "spacing", useTheme())
  }} className={_c0 + (isStackedLayout ? _c1 : _c2)}>
			<MessageBubble align={'right'} color={theme.colors.Messenger.outgoingMessage} hasArrow={withArrow}>
				<div style={{
        height: resolveThemingValue(SnapSize.MESSAGE, "sizes", useTheme()),
        width: resolveThemingValue(SnapSize.MESSAGE, "sizes", useTheme())
      }} className={_c3}>
					<BlurredBackgroundImage source={sendingImage.image} />
				</div>
				<div className={_c4}>
					<Spacer size={'auto'} />
					<div className={_c5}>
						<Text type={'tiny'} state={'tertiary'} className={_c6}>
							<FormattedDateTime value={sendingImage.timestamp} hour={'numeric'} minute={'numeric'} />
						</Text>
					</div>
				</div>
			</MessageBubble>
		</div>;
};
const _c0 = " Knu-FlexCol mb-small ";
const _c1 = " mr-none ";
const _c2 = " mr-small ";
const _c3 = " Knu-FlexCol position-relative overflow-hidden ";
const _c4 = " Knu-Flex flex-1 position-relative ";
const _c5 = " Knu-FlexCol position-relative pl-minor pr-tiny mb-1px ";
const _c6 = "  ";