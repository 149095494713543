import { ConversationMessageFragment, ConversationTextMessageContentFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { Flex, FlexCol } from '@knuddels/component-library';
import { PrivateMessageProvider } from '@knuddelsModules/Messenger/bundle/contexts/PrivateMessageContext';
import { ClipboardExtraInfo } from '@shared/components';
import * as React from 'react';
import { BasicUser } from '../../../utils/BasicUser';
import { MessageTime } from './MessageTime';
import { OriginalMessage } from './OriginalMessage';
import { Star } from './Star';
import { useMessageText } from './useMessageText';
import { usePlaceholderTime } from './usePlaceholderTime';
export const TextMessage: React.FC<{
  renderTime?: boolean;
  addPadding: boolean;
  message: Omit<ConversationMessageFragment, 'sender'>;
  forceBoldLinks?: boolean;
  content: ConversationTextMessageContentFragment;
  sender: BasicUser;
  getHighlightedLinkColor?(): string | undefined;
}> = ({
  content,
  message,
  forceBoldLinks,
  sender,
  getHighlightedLinkColor,
  addPadding = true,
  renderTime = true
}) => {
  const placeholderTime = usePlaceholderTime(message.timestamp, content.starred);
  const clipboardExtraInfo = useClipboardExtraInfo(sender.nick, +message.timestamp);
  const messageText = useMessageText(content);
  return <div className={_c0}>
			<div className={_c1 + (addPadding ? _c2 : _c3)}>
				<div className={_c4}>
					<ClipboardExtraInfo prefix={clipboardExtraInfo}>
						<PrivateMessageProvider>
							<OriginalMessage text={messageText} getHighlightedLinkColor={getHighlightedLinkColor} forceBoldLinks={forceBoldLinks} placeholderTime={renderTime ? placeholderTime : undefined} />
						</PrivateMessageProvider>
					</ClipboardExtraInfo>
				</div>
				<div className={_c5}>
					{renderTime && <MessageTime timestamp={+message.timestamp} />}
					{content.starred && <Star />}
				</div>
			</div>
		</div>;
};
const useClipboardExtraInfo = (senderNick: string, timestamp: number) => {
  const i18n = useService($I18n);
  return React.useMemo(() => {
    const date = i18n.formatDateTime(timestamp, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const time = i18n.formatDateTime(timestamp, {
      hour: 'numeric',
      minute: 'numeric'
    });
    return senderNick + ` (${date}, ${time}): `;
  }, [i18n, senderNick, timestamp]);
};
const _c0 = " Knu-FlexCol overflow-visible position-relative ";
const _c1 = " Knu-Flex overflow-visible position-relative ";
const _c2 = " px-tiny ";
const _c3 = " px-none ";
const _c4 = " Knu-FlexCol flexShrink-1 minWidth-0-px overflow-visible position-relative ";
const _c5 = " Knu-Flex position-absolute bottom-none right-none ";