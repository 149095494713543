import { FeatureEnum } from './FeatureEnum';
import { FeatureFlag } from './FeatureFlag';
import { BugIndicatingError } from '@knuddels/std';
import { ObservableMap, runInAction } from '@knuddels-app/mobx';

/**
 * The base class for FeatureFlag and FeatureEnum.
 */
export class FeatureSetting {
	public static readonly settings = new ObservableMap<
		string,
		FeatureFlag | FeatureEnum<string>
	>();

	constructor(
		/** The unique id of this setting. */ public readonly id: string
	) {
		if (FeatureSetting.settings.has(id)) {
			throw new BugIndicatingError(
				`Tried to define the feature setting with "${id}" multiple times! The id must be unique.`
			);
		}

		runInAction('Add feature flag', () => {
			FeatureSetting.settings.set(id, this as any);
		});
	}
}
