import * as React from 'react';
import { declareFormat, formatMessage, FormattedMessage } from '@knuddels-app/i18n';
import { ModalButtons, ModalCard, ModalWrapper, Spacer } from '@shared/components';
import { ChooseContactVirtualList } from './ChooseContactVirtualList';
import { forwardMessageEvent } from '../../analytics';
import { BasicUser } from '../../utils/BasicUser';
import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action, computed, observable, reaction } from '@knuddels-app/mobx';
import { $SendMessageService } from '@knuddelsModules/Messenger';
import { runOnce } from '@knuddels-app/tools/runOnce';
import { $ContactsService, ContactsUser } from '@knuddelsModules/Contacts';
import { ConversationMessageFragment } from '@generated/graphql';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { debounce } from '@knuddels-app/tools/debounce';
import { Disposable } from '@knuddels/std';
import { Flex, FlexCol, IconCloseCircular, LineDivider, SearchInput, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
interface Props {
  message: ConversationMessageFragment;
}
@injectable()
class ChooseContactModalModel implements IModel {
  public readonly dispose = Disposable.fn();
  @observable
  private _chosenUsers: ContactsUser[] = [];
  @observable
  private exactUserMatch: ContactsUser | undefined = undefined;
  @observable
  private filterText = '';
  private readonly trackSearched: () => void = runOnce(() => forwardMessageEvent.track('ForwardMessage_Search'));
  private readonly debouncedExactMatchSearch = debounce(async (searchValue: string): Promise<void> => {
    if (searchValue) {
      await this.contactsService.fetchContactByNick(searchValue);
      this.updateExactUserMatchFromCache();
    }
  }, 400);
  constructor(@injectProps()
  private readonly props: Props, @inject($SendMessageService)
  private readonly sendMessageService: typeof $SendMessageService.T, @inject($ContactsService)
  private readonly contactsService: typeof $ContactsService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {
    this.contactsService.fetchContacts('all');
    this.dispose.track(reaction({
      name: 'Trigger debounced exact match search'
    }, () => this.filterText, async searchValue => {
      this.updateExactUserMatchFromCache();
      await this.debouncedExactMatchSearch(searchValue);
    }));
  }
  @action.bound
  private updateExactUserMatchFromCache(): void {
    this.exactUserMatch = this.contactsService.getContactByNick(this.filterText);
  }
  public get chosenUsers(): ContactsUser[] {
    return this._chosenUsers;
  }
  @computed
  private get availableContacts(): BasicUser[] {
    const contacts: ContactsUser[] = [...this.contactsService.allContacts];

    // We need to add chosenUsers because users chosen using the exact search might not
    // be included in allContacts
    [...this._chosenUsers, this.exactUserMatch].forEach(user => {
      if (user && !contacts.some(it => it.id === user.id)) {
        contacts.push(user);
      }
    });
    return contacts.filter(user => user.canReceiveMessages);
  }
  @computed
  public get shownContacts(): BasicUser[] {
    const filtered = this.availableContacts.filter(user => !!user.nick && user.nick.toLowerCase().includes(this.filterText));
    return filtered.sort(compareByNick);
  }
  componentDidMount(): void {
    forwardMessageEvent.track('ForwardMessage_Open');
  }
  @action
  public handleFilterTextChange = (newFilter: string): void => {
    if (newFilter) {
      this.trackSearched();
    }
    this.filterText = newFilter.toLowerCase();
  };
  @action
  public handleChange = (user: ContactsUser, checked: boolean): void => {
    const foundIndex = this.chosenUsers.findIndex(u => u.id === user.id);
    if (!checked && foundIndex !== -1) {
      forwardMessageEvent.track('ForwardMessage_ContactUnchosen');
      const newChosenNicks = [...this.chosenUsers];
      newChosenNicks.splice(foundIndex, 1);
      this._chosenUsers = newChosenNicks;
    } else if (checked && foundIndex === -1) {
      forwardMessageEvent.track('ForwardMessage_ContactChosen');
      const newChosenNicks = [...this.chosenUsers];
      newChosenNicks.push(user);
      this._chosenUsers = newChosenNicks;
    }
  };
  public handleClick = (): void => {
    forwardMessageEvent.track('ForwardMessage_ExecuteForwarding');
    const chosenUsers = this.chosenUsers;
    if (chosenUsers && chosenUsers.length > 0) {
      this.sendMessageService.forwardMessage(this.props.message, chosenUsers.map(user => user.id));
    }
    this.thisVisibleOverlay.dispose();
  };
  public handleClose = (): void => {
    forwardMessageEvent.track('ForwardMessage_Close');
    this.thisVisibleOverlay.dispose();
  };
}
export const ChooseContactModal = injectedComponent({
  name: 'ChooseContactModal',
  model: ChooseContactModalModel
}, ({
  model
}) => {
  const [inputValue, setInputValue] = React.useState('');
  return <ModalWrapper cancelModal={model.handleClose} fillHeight>
				<ModalCard fillHeight>
					<Spacer size={'minor'} />

					<div className={_c0}>
						<Spacer size={'minor'} />
						<IconCloseCircular size={'large'} onPress={model.handleClose} />
						<Spacer size={'minor'} />
						<Text type={'title'} className={_c1}>
							<FormattedMessage id={declareFormat({
            id: 'CHOOSE_CONTACT_MODAL_TITLE',
            defaultFormat: 'Forward to...'
          })} />
						</Text>
						<Spacer size={'base'} />
					</div>

					<Spacer size={'minor'} />

					<LineDivider className={_c2} />

					<div className={_c3 + ("black-transparent-020" ? resolveIsDarkColor("black-transparent-020", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<Spacer size={'tiny'} />
						<SearchInput value={inputValue} onChange={({
          value
        }) => {
          setInputValue(value);
          model.handleFilterTextChange(value);
        }} placeholder={formatMessage(declareFormat({
          id: 'CHOOSE_CONTACT_MODAL_INPUT_PLACEHOLDER',
          defaultFormat: 'Search contact'
        }))} />
					</div>

					<LineDivider className={_c4} />

					<Spacer size={'small'} />

					<div className={_c5}>
						<ChooseContactVirtualList contacts={model.shownContacts} selectedContacts={model.chosenUsers} onChange={model.handleChange} />
					</div>

					<Spacer size={'base'} />

					<LineDivider className={_c6} />

					<ModalButtons primaryButton={{
        text: formatMessage(declareFormat({
          id: 'CHOOSE_CONTACT_MODAL_PRIMARY_BUTTON',
          defaultFormat: 'Forward'
        })),
        onClick: model.handleClick,
        disabled: model.chosenUsers.length === 0
      }} />
				</ModalCard>
			</ModalWrapper>;
});
function compareByNick(contactA: BasicUser, contactB: BasicUser): -1 | 1 | 0 {
  const nickA = contactA.nick.toLowerCase();
  const nickB = contactB.nick.toLowerCase();
  if (nickA > nickB) {
    return 1;
  } else if (nickB > nickA) {
    return -1;
  } else {
    return 0;
  }
}
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = " Knu-FlexCol py-small px-minor bg-black-transparent-020 ";
const _c4 = "  ";
const _c5 = " Knu-FlexCol flex-1 position-relative ";
const _c6 = "  ";