import { fromPromise } from '@knuddels-app/mobx';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	NavBarItemData,
	NavBarItemProvider,
	NavBarLocation,
} from '@knuddelsModules/LoggedInArea';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { channelViewId } from './ChannelViewProvider';
import { $ActiveChannelService } from './providedServiceIds';

@injectable()
export class ChannelNavBarItemProvider implements NavBarItemProvider {
	private readonly service = fromPromise(this.getActiveChannelService());

	private fallbackText = formatMessage(
		declareFormat({
			id: 'NAVBAR_ITEM_CHAT',
			defaultFormat: 'Chat',
		})
	);

	private activeChannelText = formatMessage(
		declareFormat({
			id: 'NAVBAR_ITEM_CHAT_ACTIVE_CHANNEL',
			defaultFormat: '(Active Channel)',
		})
	);

	constructor(
		@inject.lazy($ActiveChannelService)
		private readonly getActiveChannelService: typeof $ActiveChannelService.TLazy
	) {}

	private get isDisconnected(): boolean {
		return this.service.case(
			{
				fulfilled: s => s.state.kind !== 'active' || !s.activeChannel,
			},
			false
		);
	}

	private get title(): string {
		return this.service.case(
			{
				fulfilled: s =>
					s.activeChannel ? s.activeChannel.name : this.fallbackText,
				pending: () => this.fallbackText,
			},
			false
		);
	}

	private get menuTitle(): string {
		const title = this.title;
		if (title === this.fallbackText) {
			return title;
		}

		return title + ' ' + this.activeChannelText;
	}

	getItems(navPos: NavBarLocation): NavBarItemData[] {
		if (navPos === 'left') {
			// Only the channel list is shown in the left nav bar, not the current channel.
			return [];
		}

		return [
			{
				id: 'chat',
				kind: 'view',
				title: this.title,
				menuTitle: this.menuTitle,
				view: channelViewId,
				trackingId: 'Chat',
			},
		];
	}
}
