import { K3Container } from '@knuddels-app/DependencyInjection';
import { BugIndicatingError } from '@knuddels/std';
import { createCoreK3Container } from './createCoreK3Container';

/**
 * The current container which hosts all services.
 */
let currentContainer: K3Container | undefined;

export function dangerouslySetCurrentContainer(container: K3Container): void {
	if (currentContainer) {
		throw new BugIndicatingError('Only call this method once.');
	}
	currentContainer = container;
}

/**
 * Only use this when you know what you are doing!
 * Prefer injectedComponent, plain dependency injection or the useService hook!!
 * Using this function is *not* recommended, as it works around dependency injection.
 * @deprecated
 */
export function dangerouslyGetK3Container(): K3Container {
	if (!currentContainer) {
		if (import.meta.env.MODE === 'test') {
			currentContainer = createCoreK3Container();
			currentContainer.activateAutostartServices();
		} else {
			throw new BugIndicatingError(
				'No container available. You should call dangerouslyInitK3Container first.'
			);
		}
	}
	return currentContainer!;
}
