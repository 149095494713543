import { GetAlbumInfoForProfile, GetUserForProfile, User } from '@generated/graphql';
import { useQueryWithSyncedCache } from '@knuddels-app/Connection';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { observer } from '@knuddels-app/mobx';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { Flex, FlexCol, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useOpenAlbumDetails } from '../../../useOpenAlbumDetails';
import { ProfileAlbumInfo, ProfileUser, UpdateProfileAlbumInfoQueryContext, UpdateProfileUserQueryContext } from '../profileQueryHelper';
import { AlbumDetailsCarousel, AlbumDetailsCarouselProps } from './AlbumDetailsCarousel/AlbumDetailsCarousel';
import { AlbumDetailsUserContext, SelectedAlbumPhotoContext } from './AlbumDetailsUserContext';
import { CloseAlbumDetailsButton, useCloseAlbumDetails } from './CloseAlbumDetails';
import { EditPhotoDescriptionContextProvider, IsEditingPhotoDescriptionContext } from './EditPhotoDescriptionContext';
import { LeavePhotoDescriptionEditModeButton } from './LeavePhotoDescriptionEditModeButton';
import { OpenCommentsButton } from './OpenCommentsButton';
import { MobileCommentsBottomDrawer } from './PhotoComments/MobileCommentsBottomDrawer';
import { PhotoComments, PhotoCommentsContext, usePhotoComments } from './PhotoComments/PhotoComments';
import { PhotoCommentsInputBar } from './PhotoComments/PhotoCommentsInputBar';
import { useTrackAlbumDetails } from './useTrackAlbumDetails';
import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { getAnimationConfig } from '@shared/components/RichInput';
import { PhotoCommentsInput } from '@knuddelsModules/Profile/bundle/components/AlbumDetails/PhotoComments/PhotoCommentsInput';
import { getSafeAreaBottomHeight } from '@shared/helper/getSafeArea';
import { usePrivacyScreen } from '@shared/components/contexts/PrivacyScreenContext';
export type AlbumDetailsOverlayProps = {
  userId?: User['id'];
  photoId?: any;
};
const useUpdateAlbumDetailsViewState = () => {
  return useOpenAlbumDetails({
    shouldReplace: true
  });
};
const useAlbumDetailsContext = (initialPhotoId: string, userData: ProfileUser, albumInfo: ProfileAlbumInfo): [React.ContextType<typeof AlbumDetailsUserContext>, React.ContextType<typeof SelectedAlbumPhotoContext>, (photoId: string) => void] => {
  const [currentPhotoId, setCurrentPhotoId] = React.useState(initialPhotoId);
  const userContextValue = React.useMemo(() => {
    return {
      id: userData?.id,
      nick: userData?.nick,
      canReceivePhotoComments: userData?.canReceivePhotoComments
    };
  }, [userData?.id, userData?.canReceivePhotoComments]);
  const selectedPhotoContextValue = React.useMemo(() => {
    const allPhotos = [];
    if (albumInfo?.albums) {
      albumInfo.albums.forEach(album => allPhotos.push(...album.albumPhotos));
    }
    const profilePhoto = albumInfo?.albumProfilePhoto;
    if (profilePhoto) {
      allPhotos.push(albumInfo.albumProfilePhoto);
    }
    const currentPhoto = allPhotos.find(p => p.id === currentPhotoId);
    return ({
      photoId: currentPhotoId,
      isOwner: currentPhoto?.isOwner,
      photoKind: currentPhotoId === profilePhoto?.id ? 'profile' : 'album',
      administrationUrl: currentPhoto?.administrationUrl
    } as React.ContextType<typeof SelectedAlbumPhotoContext>);
  }, [currentPhotoId, albumInfo?.albums, albumInfo?.albumProfilePhoto]);
  return [userContextValue, selectedPhotoContextValue, setCurrentPhotoId];
};
const useTrackOpenAndClose = () => {
  const track = useTrackAlbumDetails();
  useEffect(() => {
    track('Opened');
    return () => track('Closed');
  }, []);
};
export const AlbumDetailsOverlay: React.FC<AlbumDetailsOverlayProps> = observer('AlbumDetailsOverlay', ({
  userId,
  photoId
}) => {
  const updateAlbumDetailsViewState = useUpdateAlbumDetailsViewState();
  const closeAlbumDetails = useCloseAlbumDetails();
  const isStackedLayout = useIsStackedLayout();
  const PrivacyScreen = usePrivacyScreen('AlbumDetailsOverlay');
  useEffect(() => {
    PrivacyScreen.enable();
    return () => {
      PrivacyScreen.disable();
    };
  }, [PrivacyScreen]);
  useEffect(() => {
    if (!userId) {
      closeAlbumDetails();
    }
  }, [userId]);
  useTrackOpenAndClose();
  const profileUserQuery = useQueryWithSyncedCache(GetUserForProfile, {
    userId,
    pixelDensity: getPixelRatio()
  });
  const profileAlbumInfoQuery = useQueryWithSyncedCache(GetAlbumInfoForProfile, {
    userId
  });
  const userData = profileUserQuery.data?.user.user;
  const albumInfo = profileAlbumInfoQuery.data;
  const [userContextValue, selectedPhotoContextValue, setCurrentPhotoId] = useAlbumDetailsContext(photoId, userData, albumInfo);
  if (profileAlbumInfoQuery.loading || profileAlbumInfoQuery.error || !userData || !albumInfo || !albumInfo.albums) {
    return null;
  }
  const AlbumDetailsComponent = isStackedLayout ? AlbumDetailsMobile : AlbumDetailsDesktop;
  return <AlbumDetailsUserContext.Provider value={userContextValue}>
				<SelectedAlbumPhotoContext.Provider value={selectedPhotoContextValue}>
					<UpdateProfileUserQueryContext.Provider value={profileUserQuery.updateCache}>
						<UpdateProfileAlbumInfoQueryContext.Provider value={profileAlbumInfoQuery.updateCache}>
							<EditPhotoDescriptionContextProvider>
								<AlbumDetailsComponent initialPhotoId={photoId} albumInfo={albumInfo} onSelectedAlbumPhotoChanged={newPhotoId => {
              updateAlbumDetailsViewState({
                userId,
                photoId: newPhotoId
              });
              setCurrentPhotoId(newPhotoId);
            }} />
							</EditPhotoDescriptionContextProvider>
						</UpdateProfileAlbumInfoQueryContext.Provider>
					</UpdateProfileUserQueryContext.Provider>
				</SelectedAlbumPhotoContext.Provider>
			</AlbumDetailsUserContext.Provider>;
});
type AlbumDetailsProps = AlbumDetailsCarouselProps;
const AlbumDetailsDesktop: React.FC<AlbumDetailsProps> = props => {
  const {
    photoId
  } = React.useContext(SelectedAlbumPhotoContext);
  const {
    data,
    loading,
    updateCache
  } = usePhotoComments(photoId);
  const closeAlbumDetails = useCloseAlbumDetails();
  const [isInputbarFocused] = React.useState(false);
  return <PhotoCommentsContext.Provider value={React.useMemo(() => ({
    data,
    loading,
    updateCache
  }), [data, loading, updateCache])}>
			<div onClick={toPointerHandler(closeAlbumDetails)} className={_c0}>
				<div onClick={toPointerHandler(e => e.stopPropagation())} className={_c1 + ("dialogBoxBg" ? resolveIsDarkColor("dialogBoxBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<AlbumDetailsCarousel enableKeyboardNavigation={!isInputbarFocused} {...props} />
					<div className={_c2}>
						<PhotoComments />
						<PhotoCommentsInputBar />
					</div>
					<CloseOrLeaveEditModeButton />
				</div>
			</div>
		</PhotoCommentsContext.Provider>;
};
const InputBar: React.FC = observer('InputBar', () => {
  const keyboardService = useService($KeyboardService);
  /**
   * !keyboardService.focusable and keyboard open can occur when the user is using
   * the photo description input (which does not set focusable)
   */
  if (!keyboardService.anyKeyboardVisible || !keyboardService.supportsVirtualKeyboard || !keyboardService.focusable) {
    return null;
  }
  return <motion.div style={{
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 9999
  }} transition={getAnimationConfig(keyboardService.anyKeyboardVisible)} initial={{
    y: keyboardService.keyboardHeight - getSafeAreaBottomHeight(),
    opacity: 0
  }} animate={{
    y: 0,
    opacity: 1
  }}>
			<div style={{
      bottom: resolveThemingValue((keyboardService.keyboardHeight - getSafeAreaBottomHeight() as ThemeOverride), "spacing", useTheme())
    }} className={_c3}>
				<PhotoCommentsInput autoFocus />
			</div>
		</motion.div>;
});
const AlbumDetailsMobile: React.FC<AlbumDetailsProps> = props => {
  const {
    photoId
  } = React.useContext(SelectedAlbumPhotoContext);
  const {
    data,
    loading,
    updateCache
  } = usePhotoComments(photoId);
  const [skipAnimation, setSkipAnimation] = React.useState(true);
  const [isShowingComments, setShowingComments] = React.useState(true);
  const isEditingPhotoDescription = React.useContext(IsEditingPhotoDescriptionContext);
  const changeShowingComment = React.useCallback((value: boolean) => {
    setShowingComments(value);
    if (!value) {
      setSkipAnimation(false);
    }
  }, [skipAnimation, setShowingComments, setSkipAnimation]);
  React.useEffect(() => {
    if (isEditingPhotoDescription) {
      changeShowingComment(false);
    }
  }, [isEditingPhotoDescription, changeShowingComment]);
  return <PhotoCommentsContext.Provider value={React.useMemo(() => ({
    data,
    loading,
    updateCache
  }), [data, loading, updateCache])}>
			<div className={_c4}>
				<InputBar />

				<AlbumDetailsCarousel {...props} hideDescriptionBar={isShowingComments} />
				{isShowingComments && <MobileCommentsBottomDrawer initialState={skipAnimation ? 'extended' : 'hidden'} onSwipeDown={() => {
        changeShowingComment(false);
      }} />}
				<CloseOrLeaveEditModeButton />
				{!isShowingComments && !isEditingPhotoDescription && <OpenCommentsButton onPress={() => changeShowingComment(true)} />}
			</div>
		</PhotoCommentsContext.Provider>;
};
const CloseOrLeaveEditModeButton: React.FC = () => {
  const isEditingPhotoDescription = React.useContext(IsEditingPhotoDescriptionContext);
  return isEditingPhotoDescription ? <LeavePhotoDescriptionEditModeButton /> : <CloseAlbumDetailsButton />;
};
const _c0 = " Knu-Flex position-absolute top-none right-none bottom-none left-none ";
const _c1 = " Knu-Flex shadow-Shadow2 bg-dialogBoxBg borderRadius-base overflow-hidden position-absolute top-xxlarge right-xxlarge bottom-xxlarge left-xxlarge ";
const _c2 = " Knu-FlexCol minWidth-324px maxWidth-324px ";
const _c3 = " Knu-Flex position-absolute width-full left-none ";
const _c4 = " Knu-FlexCol overflow-hidden position-absolute top-none right-none bottom-none left-none ";