export interface InputFieldProps {
	icon?: React.ReactElement;
	invalidInput?: boolean;
	label?: string;
	onBlur?: () => void;
	onChangeText?: (newText: string) => void;
	onSubmit?: (text: string) => void;
	placeholder?: string;
	value?: string;
	autoFocus?: boolean;
	bordered?: boolean;
	onFocus?: any;
	textContentType?: 'username' | 'password';
	returnKeyType?: 'done' | 'go' | 'next' | 'search' | 'send';
	autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
	styleOverride?: any;

	testId?: string;
}

export const defaultProps = {
	invalidInput: false,
	label: '',
	placeholder: '',
	type: 'text',
	value: '',
	autoFocus: false,
	bordered: false,
};
