import { makeUseVisualState as e } from "../../motion/utils/use-visual-state.js";
import { scrapeMotionValuesFromProps as t } from "./utils/scrape-motion-values.js";
import { createHtmlRenderState as o } from "./utils/create-render-state.js";
const i = {
  useVisualState: e({
    applyWillChange: !0,
    scrapeMotionValuesFromProps: t,
    createRenderState: o
  })
};
export {
  i as htmlMotionConfig
};
