import { BrowserInfo } from '@shared/helper/browserInfo';
import { isTouchDevice } from '@knuddels/component-library';

export function isSafari(): boolean {
	return BrowserInfo.name === 'Safari';
}

export function isSafariMobile(): boolean {
	return isSafari() && isTouchDevice();
}
