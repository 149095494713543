import * as React from 'react';
import { Button, ModalWrapper, Spacer } from '@shared/components';
import { FlexCol, Image, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $Environment } from '@knuddels-app/Environment';
import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';

// TODO prefetch spinner. If user is disconnected bc no internet, the spinner isn't displayed now
import spinner from '../assets/Preloader_Messenger_Detailview_v1-2x.gif';
const timeout = 5000;
@injectable()
class StAppDisconnectedModalModel implements IModel {
  public readonly dispose = Disposable.fn();
  @observable
  private _reconnecting = false;
  private reconnectTimeout: any;
  constructor(@injectProps()
  private readonly props: unknown, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {
    this.dispose.track(() => {
      clearTimeout(this.reconnectTimeout);
      this.environment.messengerSystemAppInterface.client.removeEventListener('connected', this.close);
    });
  }
  public get reconnecting(): boolean {
    return this._reconnecting;
  }
  componentDidMount(): void {
    this.environment.messengerSystemAppInterface.client.addEventListener('connected', this.close);
  }
  private readonly close = (): void => {
    clearTimeout(this.reconnectTimeout);
    this.thisVisibleOverlay.dispose();
  };
  public readonly cancelModal = (): void => {
    // noop, we only close the modal after a successful reconnect
  };
  @action.bound
  public reconnect(): void {
    this.environment.messengerSystemAppInterface.client.executeSlashCommand('/reconnect');
    this._reconnecting = true;
    this.reconnectTimeout = setTimeout(() => runInAction('Stopp reconnecting', () => {
      this._reconnecting = false;
    }), timeout);
  }
}
export const StAppDisconnectedModal = injectedComponent({
  name: 'StAppDisconnectedModal',
  model: StAppDisconnectedModalModel
}, ({
  model
}) => {
  return <ModalWrapper cancelModal={model.cancelModal}>
				<div className={_c0 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Text type={'body1'} state={'primary'} bold={true} className={_c1}>
						Die Verbindung zum Messenger wurde unterbrochen.
					</Text>

					<Spacer size={'large'} />

					{model.reconnecting ? <div className={_c2}>
							<Image src={spinner} alt={'Loading Spinner'} className={_c3} />
						</div> : <Button onClick={model.reconnect} text={'Neu verbinden'} />}
				</div>
			</ModalWrapper>;
});
const _c0 = " Knu-FlexCol position-relative placeItems-center alignSelf-center width-260px bg-transparentDark ";
const _c1 = " textAlign-center ";
const _c2 = " Knu-FlexCol mt-small mb-tiny ";
const _c3 = " width-32px height-32px ";