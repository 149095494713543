import { App } from '@capacitor/app';
import {
	KeepOnlineError,
	KeepOnlineErrorReason,
	KeepOnlineV2,
	SetOffline,
} from '@generated/graphql';
import {
	$AuthService,
	$AuthenticatedClientService,
} from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { getClientState } from '@knuddels-app/tools/getClientState';
import { Disposable } from '@knuddels/std';
import { OS, os } from '@shared/components/tools/os';

const HEALTH_PING_INTERVAL = 1000 * 60; // 1 minutes

/**
 * Is responsible for periodically sending online status pings to the server in order
 */
@injectable()
export class OnlineStatusService implements Disposable {
	public readonly dispose = Disposable.fn();

	private disposed = false;
	private healthPingTimeout: any;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T
	) {
		this.sendHealthPing();
		this.dispose.track(() => {
			this.disposed = true;
			clearTimeout(this.healthPingTimeout);
		});

		this.dispose.track(
			authService.onBeforeVoluntaryLogout.sub(({ waitOnPromise }) => {
				waitOnPromise(this.sendWentOffline());
			})
		);

		App.addListener('appStateChange', () => {
			this.executeHealthPing();
		}).then(({ remove }) => this.dispose.track(remove));

		if (os === OS.web) {
			window.addEventListener('beforeunload', this.sendWentOffline);
			this.dispose.track(() => {
				window.removeEventListener(
					'beforeunload',
					this.sendWentOffline
				);
			});
		}
	}

	sendHealthPing(): void {
		// fire and forget health ping
		this.executeHealthPing().then(() => {
			// if the api-gateway-service is unavailable, then this promise will resolve only after it is available
			// and then it shouldn't start a new timeout if this service was disposed.
			if (this.disposed) {
				return;
			}
			// set timeout is more reliable in case the browser tab is in the background
			this.healthPingTimeout = setTimeout(
				() => this.sendHealthPing(),
				HEALTH_PING_INTERVAL
			);
		});
	}

	private executeHealthPing = async () => {
		return this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(KeepOnlineV2, {
				clientState: await getClientState(),
			})
			.match({
				ok: data => {
					switch (data.__typename) {
						case 'KeepOnlineError':
							this.handleKeepOnlineError(data);
							break;
						case 'KeepOnlineSuccess':
							break;
						default:
							break;
					}
				},
				error: () => {},
			});
	};

	private handleKeepOnlineError = (data: KeepOnlineError): void => {
		if (
			data.reason === KeepOnlineErrorReason.SessionInvalid ||
			data.reason === KeepOnlineErrorReason.InternalError
		) {
			// user session is invalid
			// (e.g. because no virtual connection available)
			this.authService.forceRefreshSession();
		}
	};

	private sendWentOffline = async (): Promise<void> => {
		try {
			await this.authenticatedClientService.currentK3Client.mutate(
				SetOffline,
				{}
			);
		} catch (error) {
			console.error(error);
		}
	};
}
