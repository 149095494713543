export const CAPACITOR_LABEL_HEIGHT = 15;
export const CAPACITOR_BADGE_HEIGHT = 24;

export const CapacitorBanners = {
	K3DiscoverBanner: [320, 53],
	K3MoreBanner: [320, 53],
	K3ProfileBanner: [320, 53],
	K3MacrosheetBanner: [300, 250],
};

export type CapacitorBannerType = keyof typeof CapacitorBanners;
