import { isForcedMotionValue as a } from "../../../motion/utils/is-forced-motion-value.js";
import { isMotionValue as f } from "../../../value/utils/is-motion-value.js";
function y(t, l, i) {
  var n;
  const { style: e } = t, r = {};
  for (const o in e)
    (f(e[o]) || l.style && f(l.style[o]) || a(o, t) || ((n = i == null ? void 0 : i.getValue(o)) === null || n === void 0 ? void 0 : n.liveStyle) !== void 0) && (r[o] = e[o]);
  return i && e && typeof e.willChange == "string" && (i.applyWillChange = !1), r;
}
export {
  y as scrapeMotionValuesFromProps
};
