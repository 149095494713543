import {
	GroupHeaderItemInfo,
	ContactItemInfo,
	NoUserItemInfo,
	typeGroupHeader,
	typeNoUsers,
	typeUserEntry,
	typeUserEntryDivider,
	UserEntryDividerItemInfo,
} from './ContactsVirtualListBase';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';
import { User } from '@generated/graphql';

export function filterAndSortContacts<T extends Pick<User, 'nick'>>(
	contacts: readonly T[],
	exactUserMatch: T | undefined,
	searchValue: string | undefined
): readonly T[] {
	const filteredContacts = searchValue
		? contacts.filter(
				contact =>
					contact.nick === exactUserMatch?.nick ||
					doesUserMatchSearchValue(contact, searchValue)
			)
		: contacts;

	return sortContacts(filteredContacts);
}

function doesUserMatchSearchValue(
	user: Pick<User, 'nick'>,
	searchValue: string
): boolean {
	return user.nick.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
}

export function sortContacts<T extends Pick<User, 'nick' | 'isOnline'>>(
	contacts: readonly T[]
): T[] {
	return contacts
		.filter(contact => contact.nick)
		.sort(sortContactsByOnlineStatusThenNick);
}

function sortContactsByOnlineStatusThenNick(
	contactA: Pick<User, 'nick' | 'isOnline'>,
	contactB: Pick<User, 'nick' | 'isOnline'>
): -1 | 1 | 0 {
	const onlineComparison = sortOnlineStatus(contactA, contactB);
	if (onlineComparison === 0) {
		return sortNicks(contactA, contactB);
	}
	return onlineComparison;
}

function sortOnlineStatus(
	contactA: Pick<User, 'isOnline'>,
	contactB: Pick<User, 'isOnline'>
): -1 | 1 | 0 {
	const contactAIsOnline = contactA.isOnline;
	const contactBIsOnline = contactB.isOnline;

	if (contactAIsOnline && !contactBIsOnline) {
		return -1;
	} else if (!contactAIsOnline && contactBIsOnline) {
		return 1;
	} else {
		return 0;
	}
}

function sortNicks(
	contactA: Pick<User, 'nick'>,
	contactB: Pick<User, 'nick'>
): -1 | 1 | 0 {
	const nickA = contactA.nick.toLowerCase();
	const nickB = contactB.nick.toLowerCase();

	if (nickA > nickB) {
		return 1;
	} else if (nickB > nickA) {
		return -1;
	} else {
		return 0;
	}
}

const SEARCH_RESULTS = declareFormat({
	id: 'contacts.list.searchResults',
	defaultFormat: 'Search results',
});

export function createItemsForVirtualListView(
	sortedContacts: readonly Pick<User, 'id' | 'nick' | 'isOnline'>[],
	searchText: string | undefined,
	noSubscription: boolean,
	onClick: (contact: Pick<User, 'id' | 'isOnline'>, index: number) => void
): ContactItemInfo[] {
	if (!!searchText && sortedContacts.length === 0) {
		return [
			createHeader(formatMessage(SEARCH_RESULTS)),
			createNoUsersFound(),
		];
	}

	let prevCategory: string = undefined;

	return sortedContacts.reduce((result, contact, index) => {
		if (searchText) {
			if (index === 0) {
				result.push(createHeader(formatMessage(SEARCH_RESULTS)));
			} else {
				result.push(createDivider());
			}
		} else {
			const isContactOnline = contact.isOnline;
			if (isContactOnline && !prevCategory) {
				prevCategory = 'online';
				result.push(
					createHeader(
						formatMessage(
							declareFormat({
								id: 'contacts.list.currentlyOnline',
								defaultFormat: 'Currently online',
							})
						)
					)
				);
			} else if (!isContactOnline && prevCategory !== 'offline') {
				prevCategory = 'offline';
				result.push(
					createHeader(
						formatMessage(
							declareFormat({
								id: 'contacts.list.offline',
								defaultFormat: 'Offline',
							})
						)
					)
				);
			} else if (prevCategory) {
				result.push(createDivider());
			}
		}

		const showNickSwitchIcon =
			!!searchText &&
			contact.nick.toLowerCase().indexOf(searchText.toLowerCase()) === -1;

		result.push({
			type: typeUserEntry,
			height: 96,
			user: contact,
			noSubscription,
			showNickSwitchIcon,
			onClick: (): void => onClick(contact, index),
		});

		return result;
	}, []);
}

function createHeader(title: string): GroupHeaderItemInfo {
	return {
		type: typeGroupHeader,
		height: 40,
		title: title,
	};
}

function createDivider(): UserEntryDividerItemInfo {
	return {
		type: typeUserEntryDivider,
		height: 1,
	};
}

function createNoUsersFound(): NoUserItemInfo {
	return {
		type: typeNoUsers,
		height: 68,
		error: formatMessage(
			declareFormat({
				id: 'contacts.list.noUsersFound',
				defaultFormat: 'No user found.',
			})
		),
	};
}
