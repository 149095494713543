import * as React from 'react';
import { useId, ListItem, FlexCol, Dropdown, Option } from '@knuddels/component-library';
import { injectedComponent, declareProps } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
export const DropdownSettings = injectedComponent({
  name: 'DropdownSettingsComponent',
  inject: {
    i18n: $I18n
  },
  props: declareProps<{
    title: string;
    value: string;
    onChange: (value: string) => void;
  } & {
    items: {
      value: string | undefined;
      label: string;
    }[];
  }>()
}, ({
  title,
  items,
  value,
  onChange,
  i18n,
  ...props
}) => {
  const {
    id
  } = useId('dropdown-settings');
  return <ListItem caption={title} captionId={id} flexElement={'adornmentRight'} adornmentRight={<div className={_c0}>
						<Dropdown ariaLabelledBy={id} placeholder={declareFormat({
      id: 'settings.contactFilterSettings.contactFilterGroup.gender.placeholder',
      defaultFormat: 'Choose an option'
    }).format(i18n)} value={value} onChange={({
      value: newValue
    }) => onChange(newValue)} {...{
      style: {
        width: '100%'
      }
    }}>
							{items.map(item => <Option key={item.label} value={item.value}>
									{item.label}
								</Option>)}
						</Dropdown>
					</div>} {...props} />;
});
const _c0 = " Knu-FlexCol flex-1 ml-xlarge my-tiny ";