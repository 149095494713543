import { IModel, injectProps, injectable, injectedComponent } from '@knuddels-app/DependencyInjection';
import { FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import * as React from 'react';
import { EditProfileHeader } from './EditProfileContent/EditProfileHeader';
import { EditProfileTitleBar } from './EditProfileContent/EditProfileTitleBar';
import { ProfileEditor } from './EditProfileContent/logic/ProfileEditor';
import { EditProfileContentRouter } from './EditProfileContentRouter';
import { EditProfileContentTabs } from './EditProfileContentTabs';
import { EditProfileCommonProps } from './EditProfileLogic';
interface Props extends EditProfileCommonProps {
  editor: ProfileEditor;
  activeTabIndex: number;
  setActiveTabIndex: (newTabIndex: number) => void;
  goBack(): void;
}
@injectable()
class EditProfileMobileModel implements IModel {
  public dispose = Disposable.fn();
  public readonly editor: ProfileEditor;
  constructor(@injectProps()
  public readonly props: Props) {
    this.editor = this.props.editor;
  }
  public readonly goBack = (): void => {
    this.props.goBack();
  };
}
export const EditProfileMobile = injectedComponent({
  model: EditProfileMobileModel,
  name: 'EditProfileMobile'
}, ({
  model
}) => {
  return <EditProfileContentRouter>
				<div className={_c0}>
					<div className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<EditProfileTitleBar goBack={model.goBack} editor={model.editor} showProfilePictureOverflowIcon fillBackground />
						<EditProfileHeader onTabClicked={model.props.setActiveTabIndex} activeTabIndex={model.props.activeTabIndex} />
					</div>
					<EditProfileContentTabs {...model.props} />
				</div>
			</EditProfileContentRouter>;
});
const _c0 = " Knu-FlexCol position-relative height-100-percent overflow-hidden ";
const _c1 = " Knu-FlexCol bg-contentLightBg shadow-Shadow2 zIndex-1 ";