import * as s from "react";
import { generateId as c } from "./generateId.js";
const m = (t) => {
  const [e] = s.useState(() => c(t));
  return { id: e, composeId: (r) => {
    const o = e + "_" + r;
    return {
      id: o,
      selector: e + " " + o
    };
  } };
};
export {
  m as useId
};
