import * as React from 'react';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { useEffect, useRef } from 'react';
import { $FriendRequestsService } from '../../../providedServices';

export const useTrackFriendRequestsView = () => {
	const logEvent = useLogEvent();

	return React.useCallback(
		(itemId: string) => {
			logEvent('Contacts_Friendrequests', itemId);
		},
		[logEvent]
	);
};

export const useTrackAvailableFriendRequests = (
	friendRequestService: typeof $FriendRequestsService.T
) => {
	const track = useTrackFriendRequestsView();
	const alreadyTracked = useRef(false);

	useEffect(() => {
		if (!alreadyTracked.current && friendRequestService.isLoaded) {
			alreadyTracked.current = true;
			track(
				`OpenFriendrequestAvailable_${friendRequestService.receivedFriendRequests.length}`
			);
		}
	}, [
		friendRequestService.isLoaded,
		friendRequestService.receivedFriendRequests.length,
	]);
};
