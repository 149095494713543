export function equalUpToDepth<Obj extends object>(
	objA: Obj,
	objB: Obj,
	depthPerKey?: { [key in keyof Obj]?: number },
	// default shallow equal. set to undefined to skip comparision
	defaultDepth = 1
): boolean {
	return isEqual(objA, objB, defaultDepth, depthPerKey);
}

// parts from:
// https://github.com/facebook/react/blob/master/packages/shared/shallowEqual.js
function isEqual(
	objA: any,
	objB: any,
	/** 0 => basically objA === objB */
	defaultDepth: number,
	/** basically if objA !== objB also check the specified keys more deeply */
	depthPerKey?: { [key: string]: number | undefined }
): boolean {
	if (is(objA, objB)) {
		return true;
	}
	if (defaultDepth <= 0 && !depthPerKey) {
		// they should return true in previous statement if they are equal
		return false;
	}
	if (
		typeof objA !== 'object' ||
		objA === null ||
		typeof objB !== 'object' ||
		objB === null
	) {
		return false;
	}

	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		return false;
	}

	// Test for A's keys different from B.
	for (let i = 0; i < keysA.length; i++) {
		const depthForCurrentKey = depthPerKey && depthPerKey[keysA[i]];
		const specifiedDepth =
			depthForCurrentKey !== undefined
				? depthForCurrentKey
				: defaultDepth - 1;
		if (
			!Object.hasOwnProperty.call(objB, keysA[i]) ||
			// variation from react code... using recursion to check deeper
			!isEqual(objA[keysA[i]], objB[keysA[i]], specifiedDepth)
		) {
			return false;
		}
	}

	return true;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
function is(x: any, y: any): boolean {
	// SameValue algorithm
	if (x === y) {
		// Steps 1-5, 7-10
		// Steps 6.b-6.e: +0 != -0
		return x !== 0 || 1 / x === 1 / y;
	} else {
		// Step 6.a: NaN == NaN
		return x !== x && y !== y;
	}
}
