export function last<T>(arr: readonly T[]): T | undefined {
	return lastOrDefault(arr, undefined);
}

export function lastOrDefault<T, TDefault>(
	arr: readonly T[],
	defaultValue: TDefault
): T | TDefault {
	if (arr.length === 0) {
		return defaultValue;
	}
	return arr[arr.length - 1];
}
