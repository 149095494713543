import { jsx as N } from "react/jsx-runtime";
import * as g from "react";
import { Input as S } from "./Input.js";
import { createKeyboardHandler as L } from "../../a11y/KeyboardManager.js";
const T = [
  "Tab",
  "ArrowLeft",
  "ArrowRight",
  "Backspace",
  "Delete",
  "-"
], E = () => 1.1.toLocaleString(void 0, {
  maximumFractionDigits: 1
}).substr(1, 1), G = () => 1e3.toLocaleString(void 0, {
  maximumFractionDigits: 0
}).substr(1, 1), V = [E()], _ = (e) => e.match(/[0-9]/), y = (e, u = !1) => T.concat(u ? V : []).includes(
  e
), b = 10, A = 1, s = (e) => {
  var u;
  return ((u = e.value) == null ? void 0 : u.toLocaleString(void 0, {
    maximumFractionDigits: e.maximumFractionDigits,
    useGrouping: e.useGrouping
  })) ?? "";
}, M = (e) => {
  var u;
  return ((u = e.value) == null ? void 0 : u.length) === 0 ? void 0 : +Number(e.value.replace(",", ".")).toFixed(
    e.maximumFractionDigits
  );
}, U = (e) => {
  const [u, v] = g.useState(!0), i = e.maximumFractionDigits ?? 0, o = e.numberGrouping ?? !0, x = g.useRef(null), [r, c] = g.useState(
    () => s({
      value: e.value,
      maximumFractionDigits: i,
      useGrouping: u && o
    })
  );
  g.useLayoutEffect(() => {
    u && c(
      s({
        value: e.value,
        maximumFractionDigits: i,
        useGrouping: u && o
      })
    );
  }, [e.value, u, i, o]);
  const I = e.updateOn ? e.updateOn : "blur", D = (t) => {
    const a = e.min !== void 0 ? e.min : Number.MIN_SAFE_INTEGER, n = e.max !== void 0 ? e.max : Number.MAX_SAFE_INTEGER;
    return Math.max(a, Math.min(t, n));
  }, m = (t, a = !1) => {
    let n = D(t);
    d(n, a), c(
      s({
        value: n,
        maximumFractionDigits: i,
        useGrouping: u && o
      })
    );
  }, d = (t, a = !1) => {
    var n;
    t !== x.current && (x.current = t, a && t === e.value && c(
      s({
        value: e.value,
        maximumFractionDigits: i,
        useGrouping: u && o
      })
    ), (n = e.onChange) == null || n.call(e, { value: t }));
  }, h = () => e.min !== void 0 ? e.min : 0, f = (t) => {
    const a = t.replace(
      G(),
      ""
    ), n = M({
      value: a,
      maximumFractionDigits: i
    });
    return n || h();
  }, l = f(r), F = L().map("ArrowUp").to(() => {
    m(l + A);
  }).map("ArrowDown").to(() => {
    m(l - A);
  }).map("PageUp").to(() => {
    m(l + b);
  }).map("PageDown").to(() => {
    m(l - b);
  }).map("End").to(() => {
    e.max !== void 0 && m(e.max);
  }).map("Home").to(() => {
    e.min !== void 0 && m(e.min);
  });
  return /* @__PURE__ */ N(
    S,
    {
      ...e,
      value: r,
      onChange: ({ value: t }) => {
        const a = t.substr(0, e.maxLength);
        c(a);
        const n = f(a);
        I === "change" && n && n === D(n) && d(n);
      },
      onFocus: () => {
        var t;
        (t = e.onFocus) == null || t.call(e), v(!1);
      },
      onBlur: () => {
        var t;
        (t = e.onBlur) == null || t.call(e), v(!0), r === "" ? d(void 0, !0) : m(f(r), !0);
      },
      onKeyPress: (t) => {
        var a;
        (a = e.onKeyPress) == null || a.call(e, t), F.handle(t), !_(t.key) && !y(
          t.key,
          i > 0 && r.indexOf(E()) === -1
        ) && t.preventDefault();
      }
    }
  );
};
export {
  U as NumberInput
};
