import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $LoggedInAreaService = newServiceId<services.LoggedInAreaService>(
	'$LoggedInAreaService'
);
export const $NavBarRegistry = newServiceId<services.NavBarItemRegistry>(
	'$NavBarRegistry'
);
export const $NavBarStateStore = newServiceId<services.NavBarStateStore>(
	'$NavBarStateStore'
);

export const $NavHidingService = newServiceId<services.NavHidingService>(
	'$NavHidingService'
);

export const $NavBarService = newServiceId<services.NavBarService>(
	'$NavBarService'
);
