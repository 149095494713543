import { jsx as o } from "react/jsx-runtime";
import { ListItem as I } from "./ListItem.js";
import { useId as a } from "../../a11y/useId.js";
import { Switch as f } from "../Inputs/Switch.js";
const n = ({
  checked: m,
  disabled: i,
  badge: s,
  onChange: t,
  ...c
}) => {
  const { id: r, composeId: e } = a("switch-list-items"), d = e("description");
  return /* @__PURE__ */ o(
    I,
    {
      ...c,
      disabled: i,
      captionId: r,
      badge: s,
      adornmentRight: /* @__PURE__ */ o(
        f,
        {
          ariaLabelledBy: r,
          ariaDescribedBy: d.id,
          value: m,
          disabled: i,
          onChange: ({ value: p }) => t == null ? void 0 : t(p)
        }
      )
    }
  );
};
export {
  n as SwitchListItem
};
