import { millisecondsToSeconds as F } from "../../../utils/time-conversion.js";
import { calcGeneratorVelocity as R } from "../utils/velocity.js";
import { findSpring as S, calcAngularFreq as V } from "./find.js";
const A = ["duration", "bounce"], w = ["stiffness", "damping", "mass"];
function x(e, t) {
  return t.some((r) => e[r] !== void 0);
}
function B(e) {
  let t = {
    velocity: 0,
    stiffness: 100,
    damping: 10,
    mass: 1,
    isResolvedFromDuration: !1,
    ...e
  };
  if (!x(e, w) && x(e, A)) {
    const r = S(e);
    t = {
      ...t,
      ...r,
      mass: 1
    }, t.isResolvedFromDuration = !0;
  }
  return t;
}
function j({ keyframes: e, restDelta: t, restSpeed: r, ...m }) {
  const p = e[0], c = e[e.length - 1], u = { done: !1, value: p }, { stiffness: g, damping: T, mass: M, duration: v, velocity: b, isResolvedFromDuration: y } = B({
    ...m,
    velocity: -F(m.velocity || 0)
  }), h = b || 0, s = T / (2 * Math.sqrt(g * M)), a = c - p, i = F(Math.sqrt(g / M)), q = Math.abs(a) < 5;
  r || (r = q ? 0.01 : 2), t || (t = q ? 5e-3 : 0.5);
  let d;
  if (s < 1) {
    const n = V(i, s);
    d = (o) => {
      const l = Math.exp(-s * i * o);
      return c - l * ((h + s * i * a) / n * Math.sin(n * o) + a * Math.cos(n * o));
    };
  } else if (s === 1)
    d = (n) => c - Math.exp(-i * n) * (a + (h + i * a) * n);
  else {
    const n = i * Math.sqrt(s * s - 1);
    d = (o) => {
      const l = Math.exp(-s * i * o), f = Math.min(n * o, 300);
      return c - l * ((h + s * i * a) * Math.sinh(f) + n * a * Math.cosh(f)) / n;
    };
  }
  return {
    calculatedDuration: y && v || null,
    next: (n) => {
      const o = d(n);
      if (y)
        u.done = n >= v;
      else {
        let l = h;
        n !== 0 && (s < 1 ? l = R(d, n, o) : l = 0);
        const f = Math.abs(l) <= r, D = Math.abs(c - o) <= t;
        u.done = f && D;
      }
      return u.value = u.done ? c : o, u;
    }
  };
}
export {
  j as spring
};
