import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $SettingsComponents = newServiceId<services.SettingsComponents>(
	'$SettingsComponents'
);

export const $ClientSettingsService =
	newServiceId<services.ClientSettingsService>('$ClientSettingsService');

export const $TanSystemService =
	newServiceId<services.TanSystemService>('$TanSystemService');

export const $MessageLayoutSettingsService =
	newServiceId<services.MessageLayoutSettingsService>(
		'$MessageLayoutSettingsService'
	);

export const $ThemeSettingsService =
	newServiceId<services.ThemeSettingsService>('$ThemeSettingsService');

export const $MiniChatSettingsService =
	newServiceId<services.MiniChatSettingsService>('$MiniChatSettingsService');
