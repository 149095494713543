import { Teal as n } from "../../themes/colors.js";
const a = /* @__PURE__ */ new Map(), b = (r, s) => i(r, "colors", s);
function i(r, s, t) {
  let e;
  switch (s) {
    case "colors":
      e = t.colors.basic;
      break;
    case "spacing":
      e = t.spacings;
      break;
    case "borderRadius":
      e = t.sizes.borderRadius;
      break;
    case "sizes":
      e = t.sizes.box;
      break;
    case "aspectRatio":
      e = t.aspectRatio;
      break;
  }
  return e ? e[r] ?? r : r;
}
function f(r, s) {
  if (a.has(r))
    return a.get(r);
  if (!r)
    return !1;
  if (r === "accent" || r === s.colors.basic.accent || r.indexOf(s.colors.basic.accent) !== -1)
    return !0;
  Object.keys(n).forEach((e) => {
    const c = n[e];
    if (r === c)
      return a.set(r, !0), !0;
  });
  const t = s.colors.basic[r] ?? r;
  return o(t);
}
function o(r) {
  if (a.has(r))
    return a.get(r);
  try {
    const s = r.match(/\d+/g), e = (s[0] * 299 + s[1] * 587 + s[2] * 114) / 1e3 < 128;
    return a.set(r, e), e;
  } catch {
    throw new Error(
      `Could not process color. Your color needs to be rgb. Is it? Color: ${r}`
    );
  }
}
export {
  o as isDarkColor,
  f as resolveIsDarkColor,
  b as resolveThemeColor,
  i as resolveThemingValue
};
