import { useMutation } from '@knuddels-app/Connection';
import { AcceptFriendRequest } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $SoundService, SoundEvent } from '@knuddelsModules/Sound';

export const useAcceptFriendRequest = (
	trackSnackbar: (message: string) => void = () => {}
) => {
	const [acceptFriendRequest] = useMutation(AcceptFriendRequest);
	const snackbarService = useService($SnackbarService);
	const soundService = useService($SoundService);
	const firebaseAnalyticsService = useService($FirebaseAnalyticsService);

	return (userId: string) => {
		soundService.playSound(SoundEvent.FriendRequestAccepted);

		acceptFriendRequest({ userId }).then(result => {
			switch (result.data?.__typename) {
				case 'Success':
					firebaseAnalyticsService.logHappyMoment(
						'FriendRequestAccepted'
					);
					// state is updated through event
					break;
				case 'InternalError':
				default:
					trackSnackbar('AcceptFriendRequestError');
					snackbarService.showGenericError();
			}
		});
	};
};
