import { useService } from '@knuddels-app/DependencyInjection';
import {
	$AuthenticatedClientService,
	TypedQueryOperation,
} from '@knuddels-app/Connection';
import * as React from 'react';
import { useState } from 'react';

// Similar to useQuery with 'cache-and-network' fetch policy, but this won't
// trigger any updates when the cache is updated.
export const useCacheAndNetworkQuery = <TVars, TResult, TPrimaryResult>(
	operation: TypedQueryOperation<TVars, TResult, TPrimaryResult>,
	networkQueryVariables: TVars,
	cacheQueryVariables?: TVars
) => {
	const authenticatedClientService = useService($AuthenticatedClientService);

	const [data, setData] = useState<TPrimaryResult>();
	const [error, setError] = React.useState<any>();
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		authenticatedClientService.currentK3Client
			.queryWithResultPromise(
				operation,
				cacheQueryVariables ?? networkQueryVariables,
				'cache-only'
			)
			.onOk(r => setData(r as any));

		authenticatedClientService.currentK3Client
			.queryWithResultPromise(
				operation,
				networkQueryVariables,
				'network-only'
			)
			.match({
				ok: r => setData(r as any),
				error: e => setError(e as any),
			})
			.then(() => setLoading(false));
	}, []);

	const refetch = React.useCallback(() => {
		setLoading(true);

		authenticatedClientService.currentK3Client
			.queryWithResultPromise(
				operation,
				networkQueryVariables,
				'network-only'
			)
			.match({
				ok: r => setData(r as any),
				error: e => setError(e as any),
			})
			.finally(() => setLoading(false));
	}, []);

	return {
		data,
		setData,
		error,
		loading,
		refetch,
	};
};
