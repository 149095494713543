import { Flex, IconChevronLeft, IconChevronRight, ThemeColors } from '@knuddels/component-library';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $NativeBackgroundStore } from '@knuddels-app/NativeBackground/serviceIds';
export const HorizontalScrollIndicator: React.FC<{
  direction: 'left' | 'right';
  highlighted?: boolean;
  onPress: () => void;
}> = ({
  direction,
  highlighted,
  onPress
}) => {
  const nativeBackgroundStore = useService($NativeBackgroundStore);
  const bgColor = nativeBackgroundStore.topBackgroundStyle.color;
  const bgRotation = direction === 'left' ? '90deg' : '-90deg';
  const background = `linear-gradient(${bgRotation},${bgColor},rgba(0,0,0,0))`;
  return <Flex onPress={e => {
    e.stopPropagation();
    onPress();
  }} cursor={'pointer'} position={'absolute'} top={'none'} right={direction === 'left' ? undefined : 'none'} left={direction === 'left' ? 'none' : undefined} height={70} width={40} justifyContent={'center'} pt={'base'} zIndex={2} {...{
    style: {
      background
    }
  }}>
			{direction === 'left' ? <IconChevronLeft size="large" color={((highlighted ? 'accent' : 'white-solid-white') as ThemeColors)} /> : <IconChevronRight size="large" color={((highlighted ? 'accent' : 'white-solid-white') as ThemeColors)} />}
		</Flex>;
};