import { ProfileTabsMapping } from '../ProfileOverlay/ProfileTabsMapping';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import {
	OwnProfileViewTabbarEvent,
	ProfileViewTabbarEvent,
} from '@knuddelsModules/Profile/analytics';

export const useTrackProfileTabChanged = (userId: string) => {
	const userService = useService($UserService);

	return (newTabIndex: number) => {
		const eventLabel =
			newTabIndex === ProfileTabsMapping.details
				? 'Tabbar_SwitchedToDetails'
				: newTabIndex === ProfileTabsMapping.friends
				? 'Tabbar_SwitchedToFriends'
				: 'Tabbar_SwitchedToAlbums';

		if (userService.isCurrentUser(userId)) {
			OwnProfileViewTabbarEvent.track(eventLabel);
		} else {
			ProfileViewTabbarEvent.track(eventLabel);
		}
	};
};
