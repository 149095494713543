import * as React from 'react';
import { Flex, FlexCol, Link, Smiley, Text } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
export const TanSystemInfo: React.FC = () => {
  const executeSlashCommand = useSlashCommandWithoutChannel();
  const mobileNumber = useService($UserService).currentUser?.verifiedPhoneNumber;
  return <FlexCol mx={'base'} mb={'base'} p={'base'} borderRadius={'minor'} borderWidth={'base'} borderColor={'orange-500'} darkBorderColor={'orange-500'} borderStyle={'solid'} bg={'orange-100'} darkBg={'orange-100'} handleDarkProps>
			<div className={_c0}>
				<Smiley src={require('../../../assets/sm_knight.png')} width={37} height={30} mr={'minor'} />
				<Text type={'body2'} state={'secondary'} bold={true} className={_c1}>
					<FormattedMessage id={declareFormat({
          id: 'settings.tanInfo.title',
          defaultFormat: 'THE TAN-SYSTEM'
        })} />
				</Text>
			</div>
			<Text type={'body2'} state={'secondary'} className={_c2}>
				{mobileNumber ? <FormattedMessage id={declareFormat({
        id: 'settings.tanInfo.text.hasNumber',
        defaultFormat: 'Protect your account by confirming certain actions via an SMS code.'
      })} /> : <FormattedMessage id={declareFormat({
        id: 'settings.tanInfo.text',
        defaultFormat: 'Protect your account by verifying your mobile phone number and additionally confirming certain actions via an SMS code.'
      })} />}
			</Text>
			<Link state={'attention'} type={'body2'} onPress={() => executeSlashCommand('/helpcenter https://hilfe.knuddels.de/de/articles/4234140')}>
				<FormattedMessage id={declareFormat({
        id: 'settings.tanInfo.linkText',
        defaultFormat: 'More infos'
      })} />
			</Link>
		</FlexCol>;
};
const _c0 = " Knu-Flex alignItems-center height-40px mb-minor ";
const _c1 = "  ";
const _c2 = " mb-small ";