import { Badge } from '@capawesome/capacitor-badge';

// Resets app badge. See browser compatibility: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/setAppBadge#browser_compatibility
export async function resetAppNotificationBadge(): Promise<void> {
	const result = await Badge.isSupported();
	if (result.isSupported) {
		await Badge.set({ count: 0 });
	} else {
		console.log(
			'Badge functionality is not supported in this environment.'
		);
	}
}
