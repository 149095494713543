import { $ModuleService as CreateK3ContainerModule } from './providedServices';
import { ModuleService } from './ModuleService';
import {
	K3Container,
	lazyServiceName,
	lazyServiceServiceIdTagName,
	ServiceId,
} from '@knuddels-app/DependencyInjection';
import { Module } from './Module';
import { BugIndicatingError } from '@knuddels/std';

/**
 * Creates the container and registers all static services and known modules.
 * This function should be called only once and only from one place.
 */
export function createK3Container(knownModules: Module[]): K3Container {
	const container = new K3Container();
	container.globalScope
		.bind(CreateK3ContainerModule)
		.toSingleton(ModuleService);

	registerLazyActivator(container, sId => {
		// This lazily loads the service with id `sId`.
		const ms = container.getService(CreateK3ContainerModule);
		return ms.loadServiceAndModule(sId);
	});

	const moduleService = container.getService(CreateK3ContainerModule);

	for (const module of knownModules) {
		moduleService.addKnownModule(module);
	}

	return container;
}

function registerLazyActivator(
	container: K3Container,
	serviceLoader: <T>(id: ServiceId<T>) => Promise<T>
): void {
	container.globalInversifyContainer
		.bind(lazyServiceName)
		.toConstantValue(undefined)
		.onActivation(context => {
			// This intercepts the injection of the `lazy` service.
			const serviceId = context.currentRequest.target.metadata.find(
				m => m.key === lazyServiceServiceIdTagName
			);
			if (!serviceId) {
				throw new BugIndicatingError('Impossible');
			}
			const sId = serviceId.value as ServiceId<any>;
			return () => {
				return serviceLoader(sId);
			};
		});
}
