import { GtagGenericFields, GtagPageViewFields } from './gtag.interface';
import { GtagPurchaseFields } from './gtag.interface';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { $K3Firebase } from './firebase';

type Fields<TAction extends string> = TAction extends 'pageview'
	? GtagPageViewFields
	: TAction extends 'purchase'
		? GtagPurchaseFields
		: GtagGenericFields;

export function gtag<const TAction extends string>(
	subject: 'event',
	action: TAction,
	fields: Fields<TAction>
): void {
	if ('event_label' in fields && typeof fields.event_label === 'object') {
		fields.event_label = JSON.stringify(fields.event_label);
	}

	const analytics =
		dangerouslyGetK3Container().getService($K3Firebase).analytics;
	switch (action) {
		case 'timing_complete': {
			if ('name' in fields && fields.name) {
				analytics.logEvent('timing_complete', {
					name: fields.name,
					value: fields.value || 0,
					event_category: fields.event_category,
					event_label: fields.event_label,
				});
			}
			break;
		}
		case 'purchase': {
			const purchaseFields = fields as Fields<'purchase'>;

			// currently only purchasing one item is possible...
			const item = purchaseFields.items[0];
			analytics.logPurchase({
				items: [
					{
						item_id: item.id,
						item_name: item.name,
						item_category: item.category,
						price: item.price,
					},
				],
				value: purchaseFields.value,
				transaction_id: item.transactionId,
				currency: item.currency,
			});
			break;
		}
		case 'pageview': {
			const pageviewFields = fields as Fields<'pageview'>;
			analytics.logEvent('page_view', pageviewFields);
			break;
		}
		default: {
			analytics.logSelectContent({
				content_type:
					(fields as GtagGenericFields).event_category || '',
				item_id:
					((fields as GtagGenericFields).event_label as string) || '',
			});
		}
	}

	if ('event_callback' in fields && fields.event_callback) {
		fields.event_callback();
	}
}
