// tslint:disable-next-line: import-blacklist
import * as mobx from 'mobx';
import { getOrActivateMobxConsoleLogger } from './logger';
export * from './mobx-facade';
export * from './mobx-react';

export function configureMobx(): void {
	// Don't allow to modify observables outside of actions.
	mobx.configure({ enforceActions: 'observed' });

	getOrActivateMobxConsoleLogger();
}

export { fromPromise } from 'mobx-utils';
export type { IPromiseBasedObservable } from 'mobx-utils';
