import * as React from 'react';
import { Flex, Text, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { listPadding } from '@knuddelsModules/Smileybox/bundle/components/listConstants';
export function SmileyListHeader(props: {
  text: string;
  width: number;
}): React.ReactElement {
  return <div style={{
    width: resolveThemingValue(props.width, "sizes", useTheme()),
    paddingLeft: resolveThemingValue((listPadding.horizontal as ThemeOverride), "spacing", useTheme()),
    paddingRight: resolveThemingValue((listPadding.horizontal as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			<Text type={'tiny'} state={'tertiary'} bold={true} className={_c1}>
				{props.text}
			</Text>
		</div>;
}
const _c0 = " Knu-Flex alignSelf-center ";
const _c1 = " mb-tiny ";