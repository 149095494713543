import { injectable } from '@knuddels-app/DependencyInjection';
import { reaction } from '@knuddels-app/mobx';
import { ApolloClient, NormalizedCacheObject, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { Endpoint } from '../Endpoints';
import { AuthTokenProvider } from './AuthTokenProvider';
import { createApolloClientCache } from './createApolloClientCache';
import { K3ApolloClient } from './K3ApolloClient';
import { createHttpLink } from './createLinks';

export interface CreateClientOptions {
	endpoint: Endpoint;
	authTokenProvider: AuthTokenProvider;
	withSubscriptionClient: boolean;
}

@injectable()
export class K3ApolloClientFactory {
	public createClient({
		endpoint,
		authTokenProvider,
		withSubscriptionClient,
	}: CreateClientOptions): K3ApolloClient {
		let link = createHttpLink(endpoint, authTokenProvider);

		let subscriptionClient: SubscriptionClient | undefined;
		if (withSubscriptionClient) {
			subscriptionClient = createSubscriptionClient(
				endpoint,
				authTokenProvider
			);

			const wsLink = new WebSocketLink(subscriptionClient);

			link = split(
				// split based on operation type
				({ query }) => {
					const definition = getMainDefinition(query);
					return (
						definition.kind === 'OperationDefinition' &&
						definition.operation === 'subscription'
					);
				},
				wsLink,
				link
			);
		}

		const client = new ApolloClient({
			link,
			assumeImmutableResults: true,
			cache: createApolloClientCache(),
			resolvers: {},
			// might want to add defaultOptions...
		});

		return new K3ApolloClientImpl(
			endpoint,
			authTokenProvider,
			client,
			subscriptionClient
		);
	}
}

export class K3ApolloClientImpl extends K3ApolloClient {
	constructor(
		private readonly endpoint: Endpoint,
		private readonly authTokenProvider: AuthTokenProvider,
		client: ApolloClient<NormalizedCacheObject>,
		subscriptionClient: SubscriptionClient | undefined
	) {
		super(client, subscriptionClient);

		if (subscriptionClient) {
			this.keepSubscriptionTokenUpToDate(subscriptionClient);
			this.dispose.track(() => {
				// prevents trying to reconnect after dispose
				subscriptionClient.close(true);
			});
		}

		this.dispose.track(() => {
			// TODO correctly dispose client (e.g. client.clearStore(), client.stop(), ...?)
			this.client.cache.reset();
		});
	}

	private keepSubscriptionTokenUpToDate(
		subscriptionClient: SubscriptionClient
	): void {
		this.dispose.track(
			reaction(
				{ name: 'Update subscription session token' },
				() => this.authTokenProvider.currentAuthToken,
				async token => {
					if (
						// `token` is set to undefined while refreshing which triggers the reaction,
						// but we don't want to send this in the socket.
						token !== undefined &&
						// This is important so we don't send a message while the socket is not ready
						// because this results in `INVALID_STATE_ERR` crashes on native.

						// `subscriptionClient.status` returns the current socket's `readyState`.
						// `status=1` means the connection is OPEN and can send messages
						// (see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState).
						// Using the `status` is necessary because `subscriptionClientState.kind` might still be `connected`
						// as `subscription.onDisconnected` is not called instantly when closing the socket.
						subscriptionClient.status === 1 &&
						// status=1 only means the socket is open, but the subscription client is not necessarily initialized yet.
						// E.g. `connecting` state also has status=1 but still needs to send the initframe which already
						// sends the token and we don't need to do it here (and it might even trigger errors in Backend).
						this.subscriptionClientState.kind === 'connected'
					) {
						subscriptionClient.client.send(
							JSON.stringify(
								expectValueOfType<SubscriptionAuthTokenUpdate>({
									type: 'update_token',
									payload: {
										token: token,
									},
								})
							)
						);
					}
				}
			)
		);
	}
}

function createSubscriptionClient(
	endpoint: Endpoint,
	tokenProvider: AuthTokenProvider
): SubscriptionClient {
	return new SubscriptionClient(endpoint.urls.graphQlSubscription, {
		reconnect: true,
		lazy: true,
		connectionParams: async () =>
			expectValueOfType<{ authToken: string | undefined }>({
				authToken: await tokenProvider.getOrRetrieveAuthToken(),
			}),
		connectionCallback: error => {
			if (error) {
				// TODO validate error handling
				console.log('subscription client error:', error);
				if (error.toString() === 'Authentication failed') {
					// maybe we need to do sth here
					// see subscriptionClient.onError
					// in AuthenticationClientService
				}
			}
		},
	});
}

// for better typing instead of using `as`.
function expectValueOfType<T>(obj: T): T {
	return obj;
}

// fixed message structure for updating token in graphql endpoint (api-gateway).
interface SubscriptionAuthTokenUpdate {
	type: 'update_token';
	payload: {
		token: string;
	};
}
