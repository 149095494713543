import { Flex, FlexCol, FlexProps } from '@knuddels/component-library';
import * as React from 'react';
const shrinkViewStyle: FlexProps = {
  flexShrink: 1,
  minHeight: 0,
  minWidth: 0,
  maxWidth: '100%',
  maxHeight: '100%'
};
const styles = {
  flexColumnCenter: ({
    flexGrow: 1,
    ...shrinkViewStyle,
    placeItems: 'center'
  } as FlexProps),
  shrinkRowCenter: ({
    ...shrinkViewStyle,
    alignItems: 'center'
  } as FlexProps),
  shrinkView: shrinkViewStyle
};
export function CenterShrinkView(props: React.PropsWithChildren<unknown>): JSX.Element {
  return <div className={_c0}>
			<div className={_c1}>
				<div className={_c2}>
					{props.children}
				</div>
			</div>
		</div>;
}
CenterShrinkView.childViewStyle = styles.shrinkView;
const _c0 = " Knu-FlexCol position-relative flex-1 placeItems-center flexShrink-1 minHeight-0-px minWidth-0-px maxSize-full ";
const _c1 = " Knu-Flex position-relative flexShrink-1 minHeight-0-px minWidth-0-px maxSize-full alignItems-center ";
const _c2 = " Knu-FlexCol position-relative flexShrink-1 minHeight-0-px minWidth-0-px maxSize-full ";