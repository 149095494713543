import { getCachedSingletons } from '../../getCachedSingletons';
import { Disposable } from '@knuddels/std';
import * as inversify from 'inversify';
import { Binding, BindingTarget } from '../ServiceBinder';

/**
 * A container that can be turned on and off while remembering all bindings.
 * When turning off, all services that have a dispose function are disposed.
 * When turning on, all bindings are reregistered.
 */
export class DynamicContainer implements BindingTarget {
	private bindings = new Array<Binding>();
	private _container: inversify.Container | null = null;
	public get container(): inversify.Container | null {
		return this._container;
	}

	private readonly containerFactory: () => inversify.Container;

	constructor(options: {
		containerFactory?: () => inversify.Container;
		enabled?: boolean;
	}) {
		this.containerFactory =
			options.containerFactory || (() => new inversify.Container());
		if (options.enabled) {
			this.setContainerState(true);
		}
	}

	public setContainerState(enabled: boolean): void {
		if (enabled && !this._container) {
			this._container = this.containerFactory();

			for (const b of this.bindings) {
				b.addToContainer(this._container);
			}
		} else if (!enabled && this._container) {
			const singletons = getCachedSingletons(this._container);
			for (const s of singletons) {
				if (Disposable.is(s)) {
					s.dispose();
				}
			}
			this._container = null;
		}
	}

	/**
	 * Adds a binding and remembers it.
	 * If the container is enabled, the binding is immediately registered to the
	 * underlying inversify container.
	 * Otherwise, it will be registered once the container is activated.
	 */
	public add(binding: Binding): void {
		this.bindings.push(binding);

		if (this._container) {
			binding.addToContainer(this._container);
		}
	}
}
