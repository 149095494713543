export type Version = Readonly<{
	major: number;
	minor: number;
	patch: number;
	gitRevision: string;
	// unused. needed for alpha/beta builds?
	preReleaseVersion?: string;
	/**
	 * Gets the current version of the app as human readable string.
	 */
	toString(): string;
}>;

export const getVersion: () => Version = () => {
	const packageJsonVersion = globalEnv.version;
	const gitRevision = globalEnv.gitRevision;
	const [major, minor, patch] = packageJsonVersion
		.split('.')
		.map(numString => parseInt(numString, 10));

	return {
		major,
		minor,
		patch,
		gitRevision,
		toString(): string {
			return `${major}.${minor}.${patch} (${gitRevision.substr(0, 8)})`;
		},
	};
};
