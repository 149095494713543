import { progress as r } from "../../../utils/progress.js";
import { calcLength as t } from "../../../projection/geometry/delta-calc.js";
import { clamp as C } from "../../../utils/clamp.js";
import { mixNumber as u } from "../../../utils/mix/number.js";
function a(n, { min: e, max: m }, i) {
  return e !== void 0 && n < e ? n = i ? u(e, n, i.min) : Math.max(n, e) : m !== void 0 && n > m && (n = i ? u(m, n, i.max) : Math.min(n, m)), n;
}
function o(n, e, m) {
  return {
    min: e !== void 0 ? n.min + e : void 0,
    max: m !== void 0 ? n.max + m - (n.max - n.min) : void 0
  };
}
function L(n, { top: e, left: m, bottom: i, right: f }) {
  return {
    x: o(n.x, m, f),
    y: o(n.y, e, i)
  };
}
function x(n, e) {
  let m = e.min - n.min, i = e.max - n.max;
  return e.max - e.min < n.max - n.min && ([m, i] = [i, m]), { min: m, max: i };
}
function w(n, e) {
  return {
    x: x(n.x, e.x),
    y: x(n.y, e.y)
  };
}
function y(n, e) {
  let m = 0.5;
  const i = t(n), f = t(e);
  return f > i ? m = r(e.min, e.max - i, n.min) : i > f && (m = r(n.min, n.max - f, e.min)), C(0, 1, m);
}
function M(n, e) {
  const m = {};
  return e.min !== void 0 && (m.min = e.min - n.min), e.max !== void 0 && (m.max = e.max - n.min), m;
}
const c = 0.35;
function R(n = c) {
  return n === !1 ? n = 0 : n === !0 && (n = c), {
    x: l(n, "left", "right"),
    y: l(n, "top", "bottom")
  };
}
function l(n, e, m) {
  return {
    min: d(n, e),
    max: d(n, m)
  };
}
function d(n, e) {
  return typeof n == "number" ? n : n[e] || 0;
}
export {
  a as applyConstraints,
  y as calcOrigin,
  o as calcRelativeAxisConstraints,
  L as calcRelativeConstraints,
  x as calcViewportAxisConstraints,
  w as calcViewportConstraints,
  c as defaultElastic,
  M as rebaseAxisConstraints,
  l as resolveAxisElastic,
  R as resolveDragElastic,
  d as resolvePointElastic
};
