import * as React from 'react';
import { IconChevronRight, ListGroup } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { SettingsListItem } from '../SettingsListItem';
import { $UserService } from '@knuddelsModules/UserData';
import { useRouter } from '../SettingsRouter';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { SettingsValueItem } from '../SettingsValueItem';
import { nickSwitchFlag } from '@knuddelsModules/FeatureFlags';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { observer } from '@knuddels-app/mobx';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
export const ProfileSettings: React.FC = observer('ProfileSettings', () => {
  const {
    navigate
  } = useRouter();
  const i18n = useService($I18n);
  const featureService = useService($FeatureService);
  const userService = useService($UserService);
  const logEvent = useLogEvent();
  const execute = useSlashCommandWithoutChannel();
  const nicknameChangeAllowed = featureService.hasSomeFlagsEnabled([nickSwitchFlag]);
  const changeProfilePicture = (): void => {
    logEvent('Settings_AccountAndSecurity', 'ChangeProfileImage_Clicked');
    execute('/opensystemapp ' + PROFILE_CUSTOMIZATION_APP_ID);
  };
  const changePassword = (): void => {
    logEvent('Settings_AccountAndSecurity', 'ChangePassword_Clicked');
    navigate('ChangePassword');
  };
  const changeNickname = (): void => {
    logEvent('Settings_AccountAndSecurity', 'ChangeNickname_Clicked');
    navigate('ChangeNickname');
  };
  if (!userService.currentUser) {
    return null;
  }
  return <ListGroup title={declareFormat({
    id: 'settings.accountSettings.profileGroup.title',
    defaultFormat: 'Your account'
  }).format(i18n)}>
			<SettingsValueItem value={userService.currentUser.nick} caption={declareFormat({
      id: 'settings.accountSettings.profileGroup.nickname',
      defaultFormat: 'Nickname'
    }).format(i18n)} onEdit={nicknameChangeAllowed ? changeNickname : undefined} />
			<SettingsListItem iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.accountSettings.profileGroup.picture',
      defaultFormat: 'Change profile picture'
    }).format(i18n)} onPress={changeProfilePicture} />
			<SettingsListItem iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.accountSettings.profileGroup.password',
      defaultFormat: 'Change password'
    }).format(i18n)} onPress={changePassword} />
			<SettingsListItem iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.accountSettings.profileGroup.subscriptions',
      defaultFormat: 'Subscriptions'
    }).format(i18n)} onPress={() => navigate('Subscriptions')} separator={'none'} />
		</ListGroup>;
});