import { FullConversationWithoutMessagesFragment } from '@generated/graphql';
import { isSafari } from '@knuddels-app/tools/isSafari';
import { MessengerOverviewItem } from '@knuddelsModules/Messenger/bundle/services/messengerOverviewHelper';
import { userIsJames } from '@shared/helper/user';

const isValidParticipant = (
	participant: FullConversationWithoutMessagesFragment['otherParticipants'][0]
) => {
	return (
		participant.canReceiveMessages ||
		participant.isAppBot ||
		userIsJames(participant)
	);
};

export const canOtherParticipantsReceiveMessages = (
	conversationItem:
		| MessengerOverviewItem
		| FullConversationWithoutMessagesFragment
) => {
	if ('type' in conversationItem) {
		return (
			conversationItem.type === 'conversation' &&
			isValidParticipant(
				conversationItem.conversation.otherParticipants[0]
			)
		);
	}

	return isValidParticipant(conversationItem.otherParticipants[0]);
};

export const blurBackground = (blurRadius = 10) => {
	return {
		backdropFilter: `blur(${blurRadius}px)`,
		WebkitBackdropFilter: `blur(${blurRadius}px)`,
		/* Fix for Safari performance issue: */
		/* forces the browser use GPU acceleration for that particular element instead of the CPU */
		WebkitBackfaceVisibility: 'hidden',
		MozBackfaceVisibility: 'hidden',
		WebkitTransform: isSafari() ? 'translate3d(0, 0, 0)' : undefined,
		MozTransform: isSafari() ? 'translate3d(0, 0, 0)' : undefined,
	};
};
