import * as e from "react";
import { useTheme as f } from "../../../themes/useTheme.js";
const k = (a, t = !0) => {
  const [c, i] = e.useState(!1), u = f(), s = () => {
    var n, o;
    const r = (o = (n = a.current) == null ? void 0 : n.parentElement) == null ? void 0 : o.closest(
      ".content-is-dark, .content-is-light"
    );
    r && i(r.classList.contains("content-is-dark"));
  };
  return e.useLayoutEffect(() => {
    t && s();
  }), e.useEffect(() => {
    t && setTimeout(s);
  }, [u, t]), c;
};
export {
  k as useIsParentDark
};
