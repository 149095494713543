import * as React from 'react';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { OverlayFactory, useCloseThisOverlay } from '@knuddels-app/overlays';
import { Flex, IconProps, Image } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
export const ProfileOverflowMenu: React.FC<ContextMenuProps & {
  openHistory: () => void;
}> = ({
  openHistory,
  ...menuProps
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  return <ContextMenu {...menuProps} closeContextMenu={closeOverlay}>
			<ContextMenuEntry icon={IconNickSwitch} text={i18n.format(declareFormat({
      id: 'profile.overflow.nickhistory',
      defaultFormat: 'Nickname history'
    }))} onClick={() => {
      closeOverlay();
      openHistory();
    }} />
		</ContextMenu>;
};
export const ProfileOverflowMenuOverlay = new OverlayFactory(ProfileOverflowMenu);
export const IconNickSwitch: React.FC<IconProps> = () => {
  return <div className={_c0}>
			<Image src={require('@shared/icons/nickswitch.png')} alt={''} className={_c1} />
		</div>;
};
const _c0 = " Knu-Flex placeItems-center ";
const _c1 = " width-19px height-13px ";