import { StappMessengerInitService } from './StappMessengerInitService';
import {
	K3ServiceBinder,
	newServiceId,
} from '@knuddels-app/DependencyInjection';

export const $StappMessengerInitService =
	newServiceId<StappMessengerInitService>('$StappMessengerInitService');
export function registerServices(ctx: K3ServiceBinder): void {
	ctx.loggedInScope
		.bind($StappMessengerInitService)
		.toSingletonAutostart(StappMessengerInitService);
}

export * from './StAppOverview/StAppOverviewListWrapper';
export * from './ConversationDashboardContent';
