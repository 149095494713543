import * as React from 'react';
import { NotificationBadge } from '@shared/components';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { User } from '@generated/graphql';
import { FlexCol, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
export interface Props {
  backgroundColor: string;
  username?: string;
  uid?: User['id'];
  unreadBadgeText: string | undefined;
  onPress(): void;
}
export const MiniListEntry: React.FC<Props> = React.memo(({
  backgroundColor,
  username,
  uid,
  unreadBadgeText,
  onPress
}) => {
  return <div style={{
    background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
  }} className={_c0 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div style={{
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row'
    }} onClick={onPress} title={username}>
					<UserImage uid={uid} type={UserImageType.STAPP_SIDEBAR_SMALL} highlight={'fancy'} />
					{!!unreadBadgeText && <div className={_c1}>
							<NotificationBadge colorPreset={'stapp'} number={unreadBadgeText} />
						</div>}
				</div>
			</div>;
});
MiniListEntry.displayName = 'MiniListEntry';
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-FlexCol position-absolute right-tiny top-none ";