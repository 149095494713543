import * as React from 'react';
import { StarredConversationMessageFragment } from '@generated/graphql';
import { InfiniteItem, InfiniteScrollView, LineDividerWithText } from '@shared/components';
import { StarredMessageListEntry } from './StarredMessageListEntry';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import { getMessageDayText } from '@knuddels-app/tools/getMessageDayText';
type ListItem = MessageListItem | DividerListItem;
interface MessageListItem extends InfiniteItem {
  message: StarredConversationMessageFragment;
  messageIndex: number;
  type: 'message';
}
interface DividerListItem extends InfiniteItem {
  text: string;
  type: 'divider';
}
export const StarredMessagesVirtualList: React.FC<{
  messages: readonly StarredConversationMessageFragment[];
  fetchMore: () => void;
}> = ({
  messages,
  fetchMore
}) => {
  const items = useListItems(messages);
  const renderItem = useRenderItem();
  return <InfiniteScrollView dock={'top'} items={items} renderItem={renderItem} onEndReached={fetchMore} contentContainerStyle={{
    paddingLeft: 0,
    paddingRight: 0
  }} />;
};
const useListItems = (messages: readonly StarredConversationMessageFragment[]): ListItem[] => {
  return React.useMemo(() => {
    const result: ListItem[] = [];
    let prevMessageTime: string | undefined;
    messages.forEach((message, index) => {
      const messageTime = getMessageDayText(+message.message.timestamp);
      if (!prevMessageTime || prevMessageTime !== messageTime) {
        result.push({
          uniqueKey: messageTime,
          type: 'divider',
          text: messageTime
        });
        prevMessageTime = messageTime;
      }
      result.push({
        uniqueKey: message.message.id,
        type: 'message',
        message,
        messageIndex: index
      });
    });
    return result;
  }, [messages, messages.length]);
};
const useRenderItem = () => {
  const activeUser = useService($UserService).currentUser;
  return React.useCallback((item: ListItem) => {
    if (item.type === 'divider') {
      return <LineDividerWithText text={item.text} lineStyle={'none'} />;
    } else {
      const receiver = item.message.message.sender.id === activeUser.id ? item.message.conversation.otherParticipants[0] : activeUser;
      return <StarredMessageListEntry conversationId={item.message.conversation.id} message={item.message.message} messageIndex={item.messageIndex} activeUserId={activeUser.id} sender={item.message.message.sender} receiver={receiver} />;
    }
  }, [activeUser]);
};