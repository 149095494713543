import * as React from 'react';
import { ModalButtons, ModalButtonsProps, ModalCard, ModalContent, ModalContentProps } from './Content';
import { ModalWrapper, ModalWrapperProps } from './Wrapper';
import { LineDivider } from '@knuddels/component-library';
export interface GenericModalProps extends ModalContentProps, ModalButtonsProps, ModalWrapperProps {}
export class GenericModal extends React.PureComponent<GenericModalProps> {
  render(): JSX.Element {
    const {
      cancelModal,
      disablePadding,
      maxHeight,
      maxWidth,
      testId,
      primaryButton,
      secondaryButton,
      buttonsBackground,
      noAutofocus,
      ...props
    } = this.props;
    return <ModalWrapper cancelModal={cancelModal} disablePadding={disablePadding} maxHeight={maxHeight} maxWidth={maxWidth} testId={testId}>
				<ModalCard>
					<ModalContent {...props} />
					<LineDivider className={_c0} />
					<ModalButtons noAutofocus={noAutofocus} primaryButton={primaryButton} secondaryButton={secondaryButton} buttonsBackground={buttonsBackground} />
				</ModalCard>
			</ModalWrapper>;
  }
}
const _c0 = "  ";