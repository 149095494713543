export function gtagInit(): void {
	{
		const useSSL = 'https:' == document.location.protocol;
		const src =
			(useSSL ? 'https:' : 'http:') +
			'//www.googletagmanager.com/gtag/js?id=G-8BBV4PTM26';
		const scriptNode = document.createElement('script');
		scriptNode.type = 'text/plain';
		scriptNode.className = 'cmplazyload';
		scriptNode.setAttribute('data-cmp-vendor', 's26');
		scriptNode.setAttribute('data-cmp-src', src);

		const node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(scriptNode, node);
	}

	{
		const scriptNode = document.createElement('script');
		scriptNode.async = false;
		scriptNode.type = 'text/javascript';
		scriptNode.text = `
			window.dataLayer = window.dataLayer || [];

			function gtag() {
				dataLayer.push(arguments);
			}

			gtag('js', new Date());
			gtag('config', 'G-8BBV4PTM26');
		`;

		const node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(scriptNode, node);
	}
}
