import * as React from 'react';
import { ProfileContentRouterConfig, Router, useNavigate } from './ProfileContent/ProfileContentRouter';
import { FlexCol } from '@knuddels/component-library';
import { AuthenticityInfoApp } from './AuthenticityInfoApp';
import { AuthenticityOverviewApp } from './AuthenticityOverviewApp';
import { HideFAB } from './FABs/ProfileFAB';
import { AuthenticityMarkOverviewApp } from './AuthenticityMarkOverviewApp';
import { useService } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands';
import { NickHistoryApp } from './NickHistoryApp';
import { useIsStackedLayout } from '@knuddels-app/Screen';
export const InitialProfileContentRouteContext = React.createContext<keyof ProfileContentRouterConfig>('overview');
export const ProfileContentRouter: React.FC<{
  nick: string;
}> = ({
  children,
  nick
}) => {
  const initialRoute = React.useContext(InitialProfileContentRouteContext);
  const isStackedLayout = useIsStackedLayout();
  const rootPath = isStackedLayout ? initialRoute : 'overview';
  const initialPath = isStackedLayout || initialRoute === rootPath ? undefined : initialRoute;
  return <Router rootPath={rootPath} initialPath={initialPath}>
			<Router.Route path={'overview'} hideTitleBar>
				{children}
			</Router.Route>

			<Router.Route path={'authenticityInfoOther'} hideTitleBar>
				<HideFAB>
					<div className={_c0}>
						<AuthenticityInfoApp nick={nick} closeProfileOnAppClose={rootPath === 'authenticityInfoOther'} />
					</div>
				</HideFAB>
			</Router.Route>

			<Router.Route path={'authenticityOverview'} hideTitleBar>
				<CommandRegistrator />
				<HideFAB>
					<div className={_c1}>
						<AuthenticityOverviewApp closeProfileOnAppClose={rootPath === 'authenticityOverview'} />
					</div>
				</HideFAB>
			</Router.Route>

			<Router.Route path={'authenticityMarkOverview'} hideTitleBar>
				<HideFAB>
					<div className={_c2}>
						<AuthenticityMarkOverviewApp closeProfileOnAppClose={rootPath === 'authenticityMarkOverview'} />
					</div>
				</HideFAB>
			</Router.Route>

			<Router.Route path={'nickHistory'} hideTitleBar>
				<HideFAB>
					<div className={_c3}>
						<NickHistoryApp nick={nick} />
					</div>
				</HideFAB>
			</Router.Route>
		</Router>;
};
const CommandRegistrator: React.FC = () => {
  const commandService = useService($CommandService);
  const navigate = useNavigate();
  React.useEffect(() => {
    return commandService.registerCommand({
      commandName: 'newauthenticity',
      invoke: async (): Promise<void> => {
        navigate('authenticityMarkOverview');
      },
      shouldInvoke(parameter: string): boolean {
        return parameter === 'marks';
      }
    }).dispose;
  }, []);
  return null;
};
const _c0 = " Knu-FlexCol height-full ";
const _c1 = " Knu-FlexCol height-full ";
const _c2 = " Knu-FlexCol height-full ";
const _c3 = " Knu-FlexCol height-full ";