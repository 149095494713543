import { AnimationFeature as t } from "./animation/index.js";
import { ExitAnimationFeature as i } from "./animation/exit.js";
const o = {
  animation: {
    Feature: t
  },
  exit: {
    Feature: i
  }
};
export {
  o as animations
};
