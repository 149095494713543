import * as React from 'react';
import { Z_INDEX } from '@shared/components';
import { FlexCol, Tab, Tabs, TabsList, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { $I18n } from '@knuddels-app/i18n';
import { UserForProfileFragment } from '@generated/graphql';
import { InfoPanel } from './InfoPanel';
import { declareProps, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { userIsJames } from '@shared/helper/user';
import { TabType } from '../ProfileTabsMapping';
import { ADMIN_INFO_TAB_TITLE, DETAILS_TAB_TITLE, FRIENDS_TAB_TITLE, PHOTOS_TAB_TITLE } from '../../../i18n/shared-formats';
import { TabBarElement } from '../../shared/TabBarElement';
import { expectUnreachable } from '@knuddels/std';
import { observer } from '@knuddels-app/mobx';
import { useFriendsState } from './Friends/useFriendsState';
import { ProfileAlbumInfo } from '../../profileQueryHelper';
interface Props {
  isCurrentUser: boolean;
  user: UserForProfileFragment;
  onTabClicked: (newTabIndex: number) => void;
  onClose?: () => void;
  activeTabIndex?: number;
  photoData?: ProfileAlbumInfo;
}
@injectable()
class ProfileHeaderModel {
  constructor(@injectProps()
  private readonly props: Props) {}
}
const TAB_BAR_HEIGHT = '48px';
const useTabTitle = (tab: TabType) => {
  const i18n = useService($I18n);
  switch (tab) {
    case 'details':
      return i18n.format(DETAILS_TAB_TITLE);
    case 'photos':
      return i18n.format(PHOTOS_TAB_TITLE);
    case 'friends':
      return i18n.format(FRIENDS_TAB_TITLE);
    case 'admin':
      return i18n.format(ADMIN_INFO_TAB_TITLE);
    default:
      expectUnreachable(tab);
  }
};
export const ProfileHeader = injectedComponent({
  name: 'ProfileHeader',
  model: ProfileHeaderModel,
  props: declareProps<Props>()
}, ({
  user,
  onClose,
  onTabClicked,
  activeTabIndex,
  isCurrentUser,
  photoData
}) => {
  const variant = user.canSeeAdminInfo ? 'condensed' : 'default';
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
  }} className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<InfoPanel onClose={onClose} user={user} showReportIcon={!isCurrentUser && !userIsJames(user)} />

				{/* we need to set the height because otherwise it collapses on native */}
				<div style={{
      height: resolveThemingValue(TAB_BAR_HEIGHT, "sizes", useTheme())
    }} className={_c1}>
					<Tabs value={activeTabIndex} onChange={onTabClicked} variant={'fixed'}>
						<TabsList>
							<Tab variant={variant} className={_c2}>
								<DetailsTabBarElement />
							</Tab>
							<Tab variant={variant} className={_c3}>
								<PhotoTabBarElement data={photoData} />
							</Tab>
							<Tab variant={variant} className={_c4}>
								<FriendsTabBarElement isCurrentUser={isCurrentUser} />
							</Tab>
							{user.canSeeAdminInfo && <Tab variant={variant} className={_c5}>
									<AdminInfoTabBarElement />
								</Tab>}
						</TabsList>
					</Tabs>
				</div>
			</div>;
});
const FriendsTabBarElement: React.FC<{
  active?: boolean;
  isCurrentUser: boolean;
}> = observer('FriendsTabBarElement', ({
  isCurrentUser,
  active
}) => {
  const title = useTabTitle('friends');
  const friendsState = useFriendsState(isCurrentUser);
  const badgeText = friendsState.friends ? friendsState.friends.length.toString() : undefined;
  return <TabBarElement badge={badgeText} title={title} active={active} />;
});
const DetailsTabBarElement: React.FC<{
  active?: boolean;
}> = ({
  active
}) => {
  const title = useTabTitle('details');
  return <TabBarElement active={active} title={title} />;
};
const PhotoTabBarElement: React.FC<{
  active?: boolean;
  data?: ProfileAlbumInfo;
}> = ({
  active,
  data
}) => {
  const title = useTabTitle('photos');
  const photosCount = data ? data.albums.reduce((sum, album) => sum + album.albumPhotos.length, data.albumProfilePhoto ? 1 : 0).toString() : undefined;
  return <TabBarElement active={active} title={title} badge={photosCount} />;
};
const AdminInfoTabBarElement: React.FC<{
  active?: boolean;
}> = ({
  active
}) => {
  const title = useTabTitle('admin');
  return <TabBarElement active={active} title={title} />;
};
const _c0 = " Knu-FlexCol shadow-Shadow2 bg-contentLightBg ";
const _c1 = " Knu-FlexCol ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = "  ";