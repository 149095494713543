import { isString as o } from "../utils.js";
import { hex as s } from "./hex.js";
import { hsla as t } from "./hsla.js";
import { rgba as e } from "./rgba.js";
const f = {
  test: (r) => e.test(r) || s.test(r) || t.test(r),
  parse: (r) => e.test(r) ? e.parse(r) : t.test(r) ? t.parse(r) : s.parse(r),
  transform: (r) => o(r) ? r : r.hasOwnProperty("red") ? e.transform(r) : t.transform(r)
};
export {
  f as color
};
