import * as React from 'react';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { declareFormat, FormatId } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { Spacer, SPACING } from '@shared/components';
import { FlexCol, ScrollView } from '@knuddels/component-library';
import { action, computed } from '@knuddels-app/mobx';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { shouldBeUnreachable } from '@knuddels/std';
import { reorder } from '@knuddels-app/tools/reorder';
import { ListGroupDraggableList, ListGroupDraggableListItem } from '../shared/ListGroupDraggableList';
import { ChannelListCategoriesEntry, ChannelListCategory } from '@generated/graphql';
import { $OverlayService } from '@knuddels-app/overlays';
import { ConfirmAdultDialog } from '../ConfirmAdultDialog';
import { NotSafeForWorkEntry } from './NotSafeForWorkEntry';
import { AdultChannelsShownInLastVisitedEntry } from './AdultChannelsShownInLastVisistedEntry';
interface CategoryData extends ListGroupDraggableListItem {
  title: string;
  checked: boolean;
  toggleable: true;
  onChanged(checked: boolean): void;
}
@injectable()
class ChannelListSettingsModel {
  private scrollTop = 0;
  constructor(@injectProps()
  private readonly props: unknown, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T) {}
  public get channelListCategories(): readonly ChannelListCategoriesEntry[] {
    return this.clientSettingsService.channelListCategories;
  }
  @action.bound
  private setCategoryEnabled(category: ChannelListCategory, checked: boolean): void {
    this.firebaseAnalyticsService.logEvent('ChannelList_Settings', getCategoryClickedTrackingItemId(category, checked));
    const categoryIndex = this.channelListCategories.findIndex(it => it.category === category);
    if (categoryIndex < 0) {
      return;
    }
    const newCategories = [...this.channelListCategories];
    newCategories[categoryIndex] = {
      category,
      active: checked
    };
    this.clientSettingsService.setChannelListCategories({
      categories: newCategories
    }).catch(() => this.snackbarService.showGenericError());
  }
  @computed
  public get categoryData(): CategoryData[] {
    return this.channelListCategories.map(category => this.createCategoryData(category)).filter(data => !!data);
  }
  private createCategoryData(category: ChannelListCategoriesEntry): CategoryData | undefined {
    const titleFormat = getCategoryTitleFormat(category.category);
    if (!titleFormat) {
      return undefined;
    }
    return {
      uniqueId: category.category,
      title: titleFormat.format(this.i18n),
      checked: category.active,
      toggleable: true,
      onChanged: checked => {
        const shouldShowAdultDialog = checked && category.category === ChannelListCategory.Erotic;
        shouldShowAdultDialog ? this.overlayService.showOverlay({
          view: <ConfirmAdultDialog onAccept={() => this.setCategoryEnabled(category.category, checked)} />,
          keepOnLocationChange: true
        }) : this.setCategoryEnabled(category.category, checked);
      }
    };
  }
  @action.bound
  public onDropEnd(from: number, to: number): void {
    this.firebaseAnalyticsService.logEvent('ChannelList_Settings', 'ChangedListOrder');
    const newCategories = reorder([...this.channelListCategories], from, to);
    this.clientSettingsService.setChannelListCategories({
      categories: newCategories
    }).catch(() => this.snackbarService.showGenericError());
  }
  public onScroll = (e: React.UIEvent): void => {
    this.scrollTop = e.currentTarget.scrollTop;
  };
  public getDragItemOffset = (): number => {
    const textHeight = 18;
    return SPACING.XLARGE + textHeight + SPACING.MINOR - this.scrollTop - 56;
  };
}
export const ChannelListSettings = injectedComponent({
  name: 'ChannelListSettings',
  model: ChannelListSettingsModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => {
  const titleFormat = declareFormat({
    id: 'settings.channelListSettings.visible.title',
    defaultFormat: 'Displayed channels'
  });
  return <div className={_c0}>
				<div onScroll={model.onScroll} className={_c1}>
					<ListGroupDraggableList title={titleFormat.format(i18n)} items={model.categoryData} onItemOrderChanged={model.onDropEnd} dragItemOffset={model.getDragItemOffset} />
					<NotSafeForWorkEntry />
					<AdultChannelsShownInLastVisitedEntry />
					<Spacer size={'xxxlarge'} />
				</div>
			</div>;
});
function getCategoryTitleFormat(category: ChannelListCategory): FormatId | undefined {
  switch (category) {
    case ChannelListCategory.Games:
      return declareFormat({
        id: 'settings.channelListSettings.categories.games',
        defaultFormat: 'Games'
      });
    case ChannelListCategory.Above_20:
      return declareFormat({
        id: 'settings.channelListSettings.categories.above_20',
        defaultFormat: 'Over 20'
      });
    case ChannelListCategory.Flirt:
      return declareFormat({
        id: 'settings.channelListSettings.categories.flirt',
        defaultFormat: 'Flirt'
      });
    case ChannelListCategory.Local:
      return declareFormat({
        id: 'settings.channelListSettings.categories.local',
        defaultFormat: 'Local Channels'
      });
    case ChannelListCategory.Music:
      return declareFormat({
        id: 'settings.channelListSettings.categories.music',
        defaultFormat: 'Music'
      });
    case ChannelListCategory.Sports:
      return declareFormat({
        id: 'settings.channelListSettings.categories.sports',
        defaultFormat: 'Sports'
      });
    case ChannelListCategory.ThemeTalk:
      return declareFormat({
        id: 'settings.channelListSettings.categories.themeTalk',
        defaultFormat: 'Theme Talk'
      });
    case ChannelListCategory.UserChannel:
      return declareFormat({
        id: 'settings.channelListSettings.categories.userChannel',
        defaultFormat: 'User Channels'
      });
    case ChannelListCategory.Erotic:
      return declareFormat({
        id: 'settings.channelListSettings.categories.erotic',
        defaultFormat: 'Erotic'
      });
    case ChannelListCategory.Misc:
      return declareFormat({
        id: 'settings.channelListSettings.categories.misc',
        defaultFormat: 'Topics'
      });
    default:
      shouldBeUnreachable(category);
      return undefined;
  }
}
function getCategoryClickedTrackingItemId(category: ChannelListCategory, checked: boolean): string {
  const suffix = checked ? '_Activated' : '_Deactivated';
  return `${category}${suffix}`;
}
const _c0 = " Knu-FlexCol height-100-percent width-100-percent ";
const _c1 = " Knu-ScrollView ";