import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { Spacer, Z_INDEX } from '@shared/components';
import { Flex, Image, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import * as React from 'react';
import { ConnectionState } from '../client/ConnectionState';
import { $AuthenticatedClientService } from '../serviceIds';
import spinner from './assets/spinner.gif';
@injectable()
class DisconnectedBannerModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private props: unknown, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T) {}
  public get isAuthorized(): boolean {
    return this.authenticatedClientService.connectionState === ConnectionState.Authorized;
  }
}
export const DisconnectedBanner = injectedComponent({
  name: 'DisconnectedBanner',
  model: DisconnectedBannerModel
}, ({
  model
}) => {
  return !model.isAuthorized ? <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVER_TITLE_BAR, "theme", useTheme())
  }} className={_c0 + ("dialogBoxBg" ? resolveIsDarkColor("dialogBoxBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Image src={spinner} alt={'Loading Spinner'} className={_c1} />
				<Spacer size="tiny" />
				<Text type="body2" state="primary" className={_c2}>
					{formatMessage(declareFormat({
        id: 'DISCONNECTED_BANNER',
        defaultFormat: 'Connection lost - reconnecting...'
      }))}
				</Text>
			</div> : null;
});
const _c0 = " Knu-Flex position-absolute placeItems-center px-base py-3px top-none insetX-none bg-dialogBoxBg ";
const _c1 = " width-14px height-14px borderRadius-circle ";
const _c2 = " textAlign-center ";