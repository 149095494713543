import { AdzoneProps } from '@knuddelsModules/Ads/bundle/components/AdzoneProps';

export type AdzoneTypes =
	| 'netpoint_leaderboard'
	| 'netpoint_billboard'
	| 'netpoint_rectangle'
	| 'netpoint_skyscraper';

export type AdzoneSize = {
	width: number;
	height: number;
};

export type AdzoneSizes = AdzoneSize[];

const maxSizes: Record<
	AdzoneTypes,
	{ desktop: [number, number]; mobile: [number, number] }
> = {
	netpoint_leaderboard: { desktop: [728, 90], mobile: [320, 50] },
	netpoint_billboard: { desktop: [970, 250], mobile: [970, 250] },
	netpoint_rectangle: { desktop: [300, 250], mobile: [336, 280] },
	netpoint_skyscraper: { desktop: [300, 600], mobile: [300, 600] },
};

export interface AdzoneConfig {
	maxSize: AdzoneSizes;
	mobileMaxSize: AdzoneSizes;
	netpointClassname: AdzoneTypes;
}

function createConfig(type: AdzoneTypes): AdzoneConfig {
	const [width, height] = maxSizes[type].desktop;
	const [mobileWidth, mobileHeight] = maxSizes[type].mobile;
	return {
		maxSize: [{ width, height }],
		mobileMaxSize: [{ width: mobileWidth, height: mobileHeight }],
		netpointClassname: type,
	};
}

const stappConversationOverviewAdConfig = createConfig('netpoint_rectangle');

const k3ConversationOverviewAdConfig = createConfig('netpoint_rectangle');

const bottomNavigationAdConfig: AdzoneConfig =
	createConfig('netpoint_rectangle');

const channelNickListAdConfig = createConfig('netpoint_rectangle');

const channelListOverviewAdConfig = createConfig('netpoint_rectangle');

const chatroomAdConfig = createConfig('netpoint_billboard');

const profileAdConfig = createConfig('netpoint_skyscraper');

const conversationOverviewAdConfig =
	globalEnv.product === 'stapp-messenger'
		? stappConversationOverviewAdConfig
		: k3ConversationOverviewAdConfig;

const chatroomNoConnectionOffContentAdConfig = createConfig(
	'netpoint_skyscraper'
);

const chatroomNoConnectionMobileBannerAdConfig = createConfig(
	'netpoint_leaderboard'
);

const chatroomNoConnectionLeaderboardAdConfig = createConfig(
	'netpoint_leaderboard'
);

const macrosheetAdConfig = createConfig('netpoint_leaderboard');

export const adzoneToConfigMap: {
	[key in AdzoneProps['adzone']]: AdzoneConfig;
} = {
	channel: chatroomAdConfig,
	channelNickList: channelNickListAdConfig,
	channelList: channelListOverviewAdConfig,
	channelNoConnectionOffContent: chatroomNoConnectionOffContentAdConfig,
	channelNoConnectionMobileBanner: chatroomNoConnectionMobileBannerAdConfig,
	channelNoConnectionLeaderboard: chatroomNoConnectionLeaderboardAdConfig,
	messengerOverview: conversationOverviewAdConfig,
	navigationView: bottomNavigationAdConfig,
	profile: profileAdConfig,
	macrosheet: macrosheetAdConfig,
};
