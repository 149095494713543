import { MessengerOverviewItem } from '../../services/messengerOverviewHelper';
import {
	ConversationVirtualListViewItemInfo,
	templateConversation,
} from './MessengerOverviewVirtualListTypes';
import { expectUnreachable } from '@knuddels/std';
import { $MessengerConversationService } from '@knuddelsModules/Messenger';

export function createConversationListItem(
	messengerConversationService: typeof $MessengerConversationService.T,
	item: MessengerOverviewItem,
	index: number,
	height: number,
	openConversationId?: string
): ConversationVirtualListViewItemInfo {
	if (item.type === 'conversation') {
		return {
			height,
			index,
			type: templateConversation,
			conversation: item.conversation,
			isInvalidConversation: false,
			isActive: item.conversationId === openConversationId,
			clientConversationState: messengerConversationService.getOrCreateClientConversationState(
				item.conversationId
			),
		};
	} else if (item.type === 'invalid-conversation') {
		return {
			height,
			index,
			type: templateConversation,
			conversation: item.conversation,
			isInvalidConversation: true,
			isActive: item.conversationId === openConversationId,
			clientConversationState: messengerConversationService.getOrCreateClientConversationState(
				item.conversationId
			),
		};
	} else {
		// need to use return or throw directly to fix types of array
		return expectUnreachable(item);
	}
}
