import { jsxs as r, jsx as s } from "react/jsx-runtime";
import "./TitleBar.scss.js";
import { clsx as d } from "../node_modules/clsx/dist/clsx.m.js";
import { useIsDarkColor as x } from "./Layout/Box/useBg.js";
import { resolveThemeColor as f } from "./Layout/isDarkColor.js";
import { useTheme as u } from "../themes/useTheme.js";
const N = 56, v = ({
  hideShadow: g = !1,
  navIcon: i,
  iconList: l = [],
  title: e,
  subtitle: n,
  subtitleAdornment: a,
  editMode: o = !1,
  fillBackground: m = !1,
  ...t
}) => {
  const c = x(t.bg ?? "titleBarBg");
  return /* @__PURE__ */ r(
    "div",
    {
      style: t.bg ? {
        "--colors-titleBarBg": f(
          t.bg,
          u()
        )
      } : void 0,
      className: d(
        "Knu-Titlebar Knu-Flex",
        c ? "content-is-dark" : "content-is-light",
        o && "editing",
        i && "hasNavIcon",
        m && "bg-titleBarBg"
      ),
      children: [
        i,
        /* @__PURE__ */ r("div", { className: "Knu-FlexCol flex-1", children: [
          typeof e == "string" ? /* @__PURE__ */ s(
            "span",
            {
              className: "Knu-Text numberOfLines-1 text-title text-primary",
              children: e
            }
          ) : e,
          /* @__PURE__ */ r("div", { className: "Knu-Flex alignItems-center", children: [
            a,
            /* @__PURE__ */ s(
              "span",
              {
                className: "Knu-Text numberOfLines-1 text-tiny text-tertiary",
                children: n
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ s(
          "div",
          {
            className: "Knu-Flex alignItems-center justifyContent-flexEnd mr-small",
            children: l
          }
        )
      ]
    }
  );
};
export {
  N as TITLE_BAR_HEIGHT,
  v as TitleBar
};
