import { Module } from './Module';
import { injectedComponent, declareProps } from '../DependencyInjection/injectedComponent';
import { injectProps, inject, injectable } from '../DependencyInjection/decorators';
import { observable, runInAction } from '@knuddels-app/mobx';
import { $ModuleService } from './providedServices';
@injectable()
class LoadModuleModel {
  @observable
  isLoaded = false;
  constructor(@injectProps()
  props: {
    module: Module;
  }, @inject($ModuleService)
  moduleService: typeof $ModuleService.T) {
    moduleService.loadModule(props.module).then(() => {
      runInAction('Update loading state', () => {
        this.isLoaded = true;
      });
    });
  }
}
export const LoadModule = injectedComponent({
  name: 'LoadModule',
  model: LoadModuleModel,
  props: declareProps<{
    children: () => JSX.Element;
    loading: () => JSX.Element;
  }>()
}, ({
  model,
  children,
  loading
}) => !model.isLoaded ? loading() : children());