import * as React from 'react';
export function Tooltip({
  children,
  text,
  enabled
}: React.PropsWithChildren<{
  text: string;
  enabled: boolean;
}>): JSX.Element {
  if (enabled) {
    return <div title={text}>{children}</div>;
  } else {
    return <>{children}</>;
  }
}