import { isAnimatable as e } from "../../utils/is-animatable.js";
function c(t) {
  const i = t[0];
  if (t.length === 1)
    return !0;
  for (let n = 0; n < t.length; n++)
    if (t[n] !== i)
      return !0;
}
function s(t, i, n, l) {
  const r = t[0];
  if (r === null)
    return !1;
  if (i === "display" || i === "visibility")
    return !0;
  const u = t[t.length - 1], o = e(r, i), a = e(u, i);
  return !o || !a ? !1 : c(t) || n === "spring" && l;
}
export {
  s as canAnimate
};
