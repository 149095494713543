import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import * as React from 'react';
import { ConversationMessage } from '../Conversation/Messages/ConversationMessage';
import { StarredMessageGroup } from '../StarredMessages/StarredMessageGroup';
import { MessengerConversation, StarredMessagesConversationMessageFragment, User } from '@generated/graphql';
import { createAccessiblePointerEventHandler, FlexCol, Text, useTheme, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { useRouter } from '../MessengerRouter';
import { markedMessagesOverviewEvent } from '../../analytics';
import { ContextMenuTrigger } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { StarredMessageContextMenuOverlay } from './StarredMessageContextMenu';
import { observer } from '@knuddels-app/mobx';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
interface Props {
  conversationId: MessengerConversation['id'];
  message: StarredMessagesConversationMessageFragment;
  messageIndex: number;
  activeUserId: User['id'];
  sender: Pick<User, 'id' | 'nick'>;
  receiver: Pick<User, 'id' | 'nick'>;
  testId?: string;
}
export const StarredMessageListEntry: React.FC<Props> = observer('StarredMessageListEntry', props => {
  const {
    message: msg,
    activeUserId,
    receiver,
    sender,
    messageIndex,
    conversationId
  } = props;
  const theme = useTheme();
  const [hovered, setHovered] = React.useState(false);
  const senderNick = sender.nick || msg.sender.nick;
  const senderUid = sender && sender.id;
  const receiverId = receiver && receiver.id || '';
  const receiverNick = receiver && receiver.nick || '';
  const isActiveUser = msg.sender.id === activeUserId;
  const align = isActiveUser ? 'right' : 'left';
  const color = isActiveUser ? theme.colors.Messenger.outgoingMessage : theme.colors.Messenger.incomingMessage;
  const messageTitle = isActiveUser ? formatMessage(declareFormat({
    id: 'OWN_STARRED_MESSAGE_TITLE',
    defaultFormat: 'You to {receiver}'
  }), {
    receiver: receiverNick
  }) : senderNick;
  const {
    openContextMenu,
    isContextMenuOpen
  } = useOpenContextMenu(msg);
  const message = [<ContextMenuTrigger key={'content'} showContextMenu={openContextMenu} chevronBackgroundColor={color} isMenuActive={isContextMenuOpen} position={'top'}>
				<Text type={'body2'} state={((isActiveUser ? 'tertiary' : 'primary') as TextStates)} bold={true} className={_c0}>
					{messageTitle}
				</Text>
				<ConversationMessage message={msg} receiverId={receiverId} conversationId={conversationId} type={isActiveUser ? 'sender' : 'receiver'} noContextMenu />
			</ContextMenuTrigger>];
  const openConversation = useOpenConversation();
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      openConversation(messageIndex, msg.id, conversationId);
    }
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c1 + ((hovered ? "listHoverAtom" : "transparent") ? resolveIsDarkColor(hovered ? "listHoverAtom" : "transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (hovered ? _c2 : _c3)}>
				<StarredMessageGroup uid={senderUid} contents={message} align={align} bubbleColor={color} shadow />
			</div>;
});
const useOpenConversation = () => {
  const {
    navigate
  } = useRouter();
  return (index: number, messageId: string, conversationId: string) => {
    markedMessagesOverviewEvent.track('MarkedMessagesOverview_ClickMessage' + (index + 1));
    navigate('conversation', {
      params: {
        scrollToMessageId: messageId,
        conversationId: conversationId
      }
    });
  };
};
const useOpenContextMenu = (message: StarredMessagesConversationMessageFragment) => {
  const overlayService = useService($OverlayService);
  const openContextMenu = (x: number, y: number, width: number, height: number) => {
    overlayService.showOverlayIfNotVisible(StarredMessageContextMenuOverlay, {
      message,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  const isContextMenuOpen = !!overlayService.findOverlay(StarredMessageContextMenuOverlay.getFilter(t => t.messageId === message.id));
  return {
    openContextMenu,
    isContextMenuOpen
  };
};
const _c0 = "  ";
const _c1 = " Knu-FlexCol cursor-pointer py-small ";
const _c2 = " bg-listHoverAtom ";
const _c3 = " bg-transparent ";