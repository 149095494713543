import { viewIdForSystemApp } from '@knuddelsModules/SystemApps';

type EngagementAppRouterConfig = {
	home: {
		params: { activeTab: 'streaks' | 'tasks' | 'bonus' };
	};
	taskDetail: { params: { taskId: string } };
};

export const engagementAppViewId = () => {
	return viewIdForSystemApp<EngagementAppRouterConfig>('EngagementSystemApp');
};
