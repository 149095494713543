import * as React from 'react';
import { Scalars } from '@generated/graphql';
import { getMessageDayText } from '@knuddels-app/tools/getMessageDayText';
import { FlexCol, Text, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
export function EntryTime(props: {
  time: Scalars['UtcTimestamp'];
  isStAppMessengerOverview: boolean;
  hasNotificationBadge: boolean;
  textState: React.ComponentProps<typeof Text>['state'];
}): React.ReactElement {
  const type = props.isStAppMessengerOverview ? 'tiny' : 'body2';
  const mt = props.isStAppMessengerOverview ? -4 : -12;
  return <div style={{
    marginTop: resolveThemingValue((mt as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			<Text type={type} state={props.textState} className={_c1}>
				{getMessageDayText(+props.time, {
        showTodayMoreSpecific: true
      })}
			</Text>
		</div>;
}
const _c0 = " Knu-FlexCol textAlign-right ml-tiny ";
const _c1 = "  ";