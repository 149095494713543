import { useContext as s, useState as r, useEffect as u } from "react";
import { motionValue as f } from "./index.js";
import { MotionConfigContext as i } from "../context/MotionConfigContext.js";
import { useConstant as m } from "../utils/use-constant.js";
function x(t) {
  const o = m(() => f(t)), { isStatic: e } = s(i);
  if (e) {
    const [, n] = r(t);
    u(() => o.on("change", n), []);
  }
  return o;
}
export {
  x as useMotionValue
};
