import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { IpDetectionUrl } from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { Disposable } from '@knuddels/std';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';

@injectable()
export class IpDetectionService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthenticatedClientService)
		private authenticatedClientService: typeof $AuthenticatedClientService.T
	) {
		this.reportToDetectionUrl();

		this.dispose.track(
			authenticatedClientService.onReconnected.sub(() => {
				this.reportToDetectionUrl();
			})
		);
	}

	private reportToDetectionUrl(): void {
		onMainThreadIdle(() => {
			this.authenticatedClientService.currentK3Client
				.queryWithResultPromise(IpDetectionUrl, {}, 'network-only')
				.onOk(ipDetectionUrl => {
					if (ipDetectionUrl) {
						this.hitDetectionUrl(ipDetectionUrl);
					}
				});
		});
	}

	private async hitDetectionUrl(url: string): Promise<void> {
		await fetch(url).catch(() => {});
	}
}
