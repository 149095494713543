import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { PaymentSubscription } from '@generated/graphql';
import { Disposable } from '@knuddels/std';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';

export type PaymentHandler = {
	handleSuccess: () => void;
	handleError: (error: any) => void;
};

@injectable()
export class PaymentSubscriptionHandler {
	private readonly dispose = Disposable.fn();

	private paymentHandlers: Map<string, PaymentHandler> = new Map();

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {
		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				PaymentSubscription,
				{},
				{
					next: event => {
						const handler = this.paymentHandlers.get(
							event.productId
						);
						if (!handler) {
							return;
						}

						this.paymentHandlers.delete(event.productId);

						switch (event.__typename) {
							case 'PaymentSucceeded':
								handler.handleSuccess();
								break;
							case 'PaymentFailed':
								handler.handleError({
									type: 'PaymentFailedEvent',
								});
								break;
							default:
						}
					},
				}
			)
		);
	}

	registerHandlerForProductId(
		productId: string,
		handler: PaymentHandler
	): void {
		this.paymentHandlers.set(productId, handler);
	}

	removeHandlerForProductId(productId: string): void {
		this.paymentHandlers.delete(productId);
	}
}
