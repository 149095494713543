import { secondsToMilliseconds as d } from "../../utils/time-conversion.js";
import { getDefaultTransition as A } from "../utils/default-transitions.js";
import { getValueTransition as k, isTransitionDefined as C } from "../utils/transitions.js";
import { MotionGlobalConfig as D } from "../../utils/GlobalConfig.js";
import { instantAnimationState as T } from "../../utils/use-instant-transition-state.js";
import { getFinalKeyframe as g } from "../animators/waapi/utils/get-final-keyframe.js";
import { frame as M } from "../../frameloop/frame.js";
import { AcceleratedAnimation as s } from "../animators/AcceleratedAnimation.js";
import { MainThreadAnimation as U } from "../animators/MainThreadAnimation.js";
import { GroupPlaybackControls as V } from "../GroupPlaybackControls.js";
const j = (r, i, n, a = {}, u, l, m) => (y) => {
  const o = k(a, r) || {}, c = o.delay || a.delay || 0;
  let { elapsed: f = 0 } = a;
  f = f - d(c);
  let e = {
    keyframes: Array.isArray(n) ? n : [null, n],
    ease: "easeOut",
    velocity: i.getVelocity(),
    ...o,
    delay: -f,
    onUpdate: (t) => {
      i.set(t), o.onUpdate && o.onUpdate(t);
    },
    onComplete: () => {
      y(), o.onComplete && o.onComplete(), m && m();
    },
    onStop: m,
    name: r,
    motionValue: i,
    element: l ? void 0 : u
  };
  C(o) || (e = {
    ...e,
    ...A(r, e)
  }), e.duration && (e.duration = d(e.duration)), e.repeatDelay && (e.repeatDelay = d(e.repeatDelay)), e.from !== void 0 && (e.keyframes[0] = e.from);
  let p = !1;
  if ((e.type === !1 || e.duration === 0 && !e.repeatDelay) && (e.duration = 0, e.delay === 0 && (p = !0)), (T.current || D.skipAnimations) && (p = !0, e.duration = 0, e.delay = 0), p && !l && i.get() !== void 0) {
    const t = g(e.keyframes, o);
    if (t !== void 0)
      return M.update(() => {
        e.onUpdate(t), e.onComplete();
      }), new V([]);
  }
  return !l && s.supports(e) ? new s(e) : new U(e);
};
export {
  j as animateMotionValue
};
