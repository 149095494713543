import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerConversationService, $SendMessageService } from '@knuddelsModules/Messenger/providedServices';
import { Flex, Smiley, Text } from '@knuddels/component-library';
import { FormattedText, FormattedTextDisplay, SuggestionButton, SuggestionsContainer } from '@shared/components';
import { observer } from '@knuddels-app/mobx';
import { SmileyDetails } from '@generated/graphql';
import { useConversationId } from '../ConversationIdContext';
import { useTrackConversation } from '../../useTrackConversation';
import { useDoKnuddel } from '@knuddels-app/shared-hooks/useDoKnuddel';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export const ThankSuggestions: React.FC<{
  otherParticipantNick: string;
  onMessageSent(): void;
}> = observer('ThankSuggestions', props => {
  const sendMessageService = useService($SendMessageService);
  const messengerConversationService = useService($MessengerConversationService);
  const conversationId = useConversationId();
  const track = useTrackConversation();
  if (sendMessageService.reactionSmileys.length === 0 || !conversationId) {
    return null;
  }
  const clientConversationState = messengerConversationService.getOrCreateClientConversationState(conversationId);
  const closeSuggestions = () => {
    clientConversationState.clearSelectedMessageIdForSuggestions();
  };
  return <SuggestionsContainer onClickOutside={closeSuggestions} onCloseSuggestions={closeSuggestions}>
			<KnuddelSuggestionButton otherParticipantNick={props.otherParticipantNick} closeSuggestions={closeSuggestions} />
			{sendMessageService.reactionSmileys.map(smiley => <SmileySuggestionButton key={smiley.id} smiley={smiley} onPress={async () => {
      const messageId = clientConversationState.selectedMessageIdForSuggestions;
      closeSuggestions();
      track('PhotocommentMessages_ReactedWith' + smiley.textRepresentation);
      await sendMessageService.sendSmileyReaction(conversationId, messageId, smiley);
      props.onMessageSent();
    }} />)}
		</SuggestionsContainer>;
});
const KnuddelSuggestionButton: React.FC<{
  otherParticipantNick: string;
  closeSuggestions(): void;
}> = props => {
  const track = useTrackConversation();
  const doKnuddel = useDoKnuddel((label: string) => track('PhotocommentMessages_ReactedWithKnuddel_' + label));
  return <SuggestionButton onPress={async () => {
    track('PhotocommentMessages_ReactedWithKnuddel');
    props.closeSuggestions();
    await doKnuddel(props.otherParticipantNick);
  }}>
			<div className={_c0}>
				<Smiley height={16} width={16} src={require('@shared/icons/knuddel.png')} mr={'tiny'} />
				<Text type={'body2'} bold={true} className={_c1}>
					<FormattedMessage id={declareFormat({
          id: 'messenger.thank-suggestions.knuddel',
          defaultFormat: 'knuddeln'
        })} />
				</Text>
			</div>
		</SuggestionButton>;
};
const SmileySuggestionButton: React.FC<{
  smiley: Pick<SmileyDetails, 'image' | 'textRepresentation'>;
  onPress(): void;
}> = props => {
  return <SuggestionButton onPress={props.onPress}>
			<FormattedTextDisplay text={FormattedText.fromJsonString(props.smiley.image)} textProps={{}} />
		</SuggestionButton>;
};
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";