import { time as o } from "../../../frameloop/sync-time.js";
import { frame as a, cancelFrame as s, frameData as t } from "../../../frameloop/frame.js";
const n = (e) => {
  const r = ({ timestamp: m }) => e(m);
  return {
    start: () => a.update(r, !0),
    stop: () => s(r),
    /**
     * If we're processing this frame we can use the
     * framelocked timestamp to keep things in sync.
     */
    now: () => t.isProcessing ? t.timestamp : o.now()
  };
};
export {
  n as frameloopDriver
};
