import { Flex, isTouchDevice } from '@knuddels/component-library';
import { motion, MotionValue, PanInfo, useAnimate, useMotionValue, useSpring, useTransform } from 'framer-motion';
import * as React from 'react';
interface SwipeAction {
  component: (progress: MotionValue<number>, x: MotionValue<number>) => React.ReactNode;
  backgroundColor?: string;
  onAction?: (cb: (animated: boolean, staggered?: boolean) => void) => void;
}
interface SwipeableProps {
  children: React.ReactNode;
  sticky?: boolean;
  threshold?: number;
  leftAction?: SwipeAction;
  rightAction?: SwipeAction;
}
type Direction = 'left' | 'right';
const ICON_THRESHOLD = 70;
const ICON_INSET = 20;
export const MessageSwipeable: React.FC<SwipeableProps> = ({
  children,
  sticky = false,
  leftAction,
  rightAction,
  threshold = 100
}) => {
  const progress = useSpring(0, {
    bounce: 0
  });
  const x = useMotionValue(0);
  const left = useMotionValue(('none' as 'flex' | 'none'));
  const right = useMotionValue(('none' as 'flex' | 'none'));
  const paddingLeft = useTransform(x, value => {
    return Math.max(value - ICON_THRESHOLD + ICON_INSET, ICON_INSET);
  });
  const paddingRight = useTransform(x, value => {
    const rightValue = Math.abs(value);
    return Math.max(rightValue - ICON_THRESHOLD + ICON_INSET, ICON_INSET);
  });
  const [scope, animate] = useAnimate();
  const handleAction = async (action: SwipeAction, directionVal: Direction) => {
    action.onAction!(async (animated, staggered) => {
      if (staggered) {
        const xVal = directionVal === 'right' ? -threshold / 2 - 20 : threshold / 2 + 20;
        await animate([[scope.current, {
          x: xVal
        }, animated ? {
          ease: 'easeInOut'
        } : {
          duration: 0
        }], [scope.current, {
          x: xVal
        }, {
          duration: animated ? 0.2 : 0
        }], [scope.current, {
          x: 0
        }, animated ? {
          ease: 'easeInOut'
        } : {
          duration: 0
        }]]);
      } else {
        await animate(scope.current, {
          x: 0
        }, {
          ease: 'easeInOut'
        });
      }
      if (directionVal === 'right') {
        right.set('none');
      } else {
        left.set('none');
      }
    });
  };
  const handleDragEnd = async (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const dragOffset = info.offset.x;
    if (rightAction && rightAction.onAction && dragOffset < -threshold) {
      handleAction(rightAction, 'right');
    } else if (leftAction && leftAction.onAction && dragOffset > threshold) {
      handleAction(leftAction, 'left');
    }
    progress.set(0);
  };
  const hasAction = !!leftAction || !!rightAction;
  return <div className={_c0}>
			<div className={_c1}>
				{leftAction && <Panel direction={'left'} display={left} padding={sticky ? paddingLeft : undefined} backgroundColor={leftAction.backgroundColor}>
						{leftAction.component(progress, x)}
					</Panel>}
			</div>
			<motion.div ref={scope} drag={isTouchDevice() && hasAction ? 'x' : false} dragConstraints={{
      left: 0,
      right: 0
    }} dragElastic={{
      right: leftAction ? 0.5 : 0,
      left: rightAction ? 0.5 : 0
    }} style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      flex: 1,
      flexShrink: 0,
      zIndex: 2,
      maxWidth: '100%'
    }} onDrag={(e, {
      offset
    }) => {
      progress.jump(threshold ? Math.min(Math.abs(offset.x) / threshold, 1) : 0);
      x.set(offset.x / 2);
      if (offset.x < 0) {
        left.set('none');
        right.set('flex');
      } else {
        left.set('flex');
        right.set('none');
      }
    }} onDragEnd={handleDragEnd}>
				{children}
			</motion.div>
			<div className={_c2}>
				{rightAction && <Panel direction={'right'} display={right} padding={sticky ? paddingRight : undefined} backgroundColor={rightAction.backgroundColor}>
						{rightAction.component(progress, x)}
					</Panel>}
			</div>
		</div>;
};
const Panel: React.FC<{
  display: MotionValue<'none' | 'flex'>;
  direction: Direction;
  padding?: MotionValue<number>;
  backgroundColor?: string;
}> = ({
  display,
  direction,
  children,
  padding,
  backgroundColor
}) => {
  return <motion.div style={{
    display: display,
    alignItems: 'center',
    backgroundColor: backgroundColor,
    position: 'absolute',
    justifyContent: direction === 'left' ? 'flex-start' : 'flex-end',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    paddingLeft: direction === 'left' && padding ? padding : 0,
    paddingRight: direction === 'right' && padding ? padding : 0
  }}>
			{children}
		</motion.div>;
};
const _c0 = " Knu-Flex position-relative ";
const _c1 = " Knu-Flex ";
const _c2 = " Knu-Flex ";