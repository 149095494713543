import * as React from 'react';
import { Flex, FlexCol, IconCheckmark, Smiley, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { ProfileEditField } from '@generated/graphql';
import { DEFAULT_IMAGE, IMAGE_SIZE, VALID_ICON_SIZE } from '../../../constants';
export interface EditProfileEntryBaseProps {
  field: ProfileEditField;
  image?: string;
  title: string;
  helperText?: string;
  validity: ValidityState;
  onValueChanged(field: ProfileEditField, newValue: string): void;
}
interface Props {
  image?: string;
  valid?: boolean;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
}
export type ValidityStateType = 'empty' | 'invalid' | 'valid';
export interface ValidityState {
  type: ValidityStateType;
  errorText?: string;
}
export const EditProfileEntry: React.FC<Props> = ({
  image = DEFAULT_IMAGE,
  children,
  valid,
  innerRef
}) => {
  return <div ref={innerRef} className={_c0}>
			<div className={_c1}>
				<Smiley size={IMAGE_SIZE} src={image} />
			</div>
			<div className={_c2}>
				{children}
			</div>
			{valid ? <div className={_c3}>
					<IconCheckmark type={'success'} />
				</div> : <div style={{
      height: resolveThemingValue(VALID_ICON_SIZE, "sizes", useTheme()),
      width: resolveThemingValue(VALID_ICON_SIZE, "sizes", useTheme())
    }} className={_c4} />}
		</div>;
};
const _c0 = " Knu-Flex py-tiny px-minor ";
const _c1 = " Knu-FlexCol mt-14px ";
const _c2 = " Knu-FlexCol ml-14px mt-tiny flex-1 mr-minor minWidth-0-px ";
const _c3 = " Knu-FlexCol mt-20px ";
const _c4 = " Knu-FlexCol ";