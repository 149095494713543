import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import bigHello from '@shared/icons/icon-bighello.gif';

export const SnackbarDefinitionDeleteConversation: SnackbarData = {
	type: 'deleteConversation',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_DELETE_CONVERSATION_TEXT',
				defaultFormat: 'The conversation has been removed.',
			})
		),
	adornment: bigHello,
};
