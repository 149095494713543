import { jsx as i } from "react/jsx-runtime";
import { clsx as m } from "../../node_modules/clsx/dist/clsx.m.js";
import "./LineDivider.scss.js";
import { resolveThemeColor as s } from "./isDarkColor.js";
import { useTheme as t } from "../../themes/useTheme.js";
const c = ({ color: o, orientation: r = "horizontal", ...e }) => /* @__PURE__ */ i(
  "div",
  {
    style: o ? {
      "--colors-hairlineAtom": s(
        o,
        t()
      )
    } : void 0,
    className: m(
      "Knu-LineDivider",
      r,
      e.className
    )
  }
);
export {
  c as LineDivider
};
