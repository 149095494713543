import * as React from 'react';
import { ProfileCommonFriendUserFragment, User } from '@generated/graphql';
import { Flex, FlexCol, createAccessiblePointerEventHandler, useTheme, Text, Image, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import { UserImage } from '@knuddelsModules/UserData';
import { FormattedMessage, declareFormat } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { OS, os } from '@shared/components/tools/os';
const SPACER_WIDTH = 12;
const USER_IMAGE_SIZE = 56;
function useNumberOfImagesPerRow(listWidth: number): number {
  const theme = useTheme();
  const availableWidth = listWidth - 2 * theme.spacings.base - 2 * theme.spacings.xlarge;
  return Math.floor((availableWidth + SPACER_WIDTH) / (USER_IMAGE_SIZE + SPACER_WIDTH)) || 1;
}
export const CommonFriends: React.FC<{
  listWidth: number;
  nick: string;
  friends: readonly ProfileCommonFriendUserFragment[];
}> = ({
  listWidth,
  nick,
  friends
}) => {
  const track = useLogEvent();
  const imagesPerRow = useNumberOfImagesPerRow(listWidth);
  const rows = chunk(friends, imagesPerRow);
  React.useEffect(() => {
    track('Profile_Profileview', 'Friendstab_CommonFriendsAvailable');
  }, []);
  return <div className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c1}>
				<Image src={require('@shared/images/clique.gif')} alt={''} className={_c2} />
				<Spacer size={'tiny'} />
				<Text type={'body2'} bold={true} className={_c3}>
					<FormattedMessage id={declareFormat({
          id: 'profile.friends.commonFriends.title',
          defaultFormat: 'You have {count} common {count, plural, one {friend} other {friends}} with {nick}!'
        })} values={{
          count: friends.length,
          nick: nick
        }} />
				</Text>
			</div>
			<Spacer size={'small'} />
			<div className={_c4 + (rows.length > 1 ? _c5 : _c6)}>
				{rows.map((row, index) => <React.Fragment key={'row-' + index}>
						<Spacer size={'base'} />
						<ImageRow friends={row} />
					</React.Fragment>)}
			</div>
		</div>;
};
const ImageRow: React.FC<{
  friends: readonly ProfileCommonFriendUserFragment[];
}> = ({
  friends
}) => {
  return <div className={_c7}>
			{friends.map((friend, index) => <React.Fragment key={friend.id + '-' + index}>
					{index !== 0 && <Spacer size={'minor'} />}
					<UserImageWithNick user={friend} />
				</React.Fragment>)}
		</div>;
};
const UserImageWithNick: React.FC<{
  user: Pick<User, 'id' | 'nick'>;
}> = ({
  user
}) => {
  const track = useLogEvent();
  const profileNavigationService = useService($ProfileNavigationService);
  const openProfile = () => {
    track('Profile_Profileview', 'Friendstab_CommonFriendClicked');
    profileNavigationService.showProfile(user.id);
  };
  const textStyle = os === OS.web ? {
    overflowWrap: 'anywhere'
  } : {};
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: openProfile
  })} style={{
    width: resolveThemingValue(USER_IMAGE_SIZE, "sizes", useTheme())
  }} className={_c8}>
			<UserImage highlight={'disabled'} userId={user.id} type={USER_IMAGE_SIZE} />
			<Spacer size={'tiny'} />
			<Text type={'tiny'} state={'secondary'} numberOfLines={1} style={{
      ...textStyle
    }} className={_c9}>
				{user.nick}
			</Text>
		</div>;
};
function chunk<T>(array: readonly T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  array.forEach((value, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (result[chunkIndex]) {
      result[chunkIndex].push(value);
    } else {
      result[chunkIndex] = [value];
    }
  });
  return result;
}
const _c0 = " Knu-FlexCol mx-base my-small py-large px-xlarge bg-contentLightBg borderRadius-minor shadow-Shadow1 ";
const _c1 = " Knu-FlexCol alignItems-center textAlign-center ";
const _c2 = " width-57px height-26px ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol ";
const _c5 = " alignItems-flex-start ";
const _c6 = " alignItems-center ";
const _c7 = " Knu-Flex ";
const _c8 = " Knu-FlexCol cursor-pointer ";
const _c9 = " textAlign-center ";