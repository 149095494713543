import { clamp as a } from "../../../utils/clamp.js";
const r = {
  test: (t) => typeof t == "number",
  parse: parseFloat,
  transform: (t) => t
}, e = {
  ...r,
  transform: (t) => a(0, 1, t)
}, s = {
  ...r,
  default: 1
};
export {
  e as alpha,
  r as number,
  s as scale
};
