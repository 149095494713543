const c = /* @__PURE__ */ new WeakMap(), n = /* @__PURE__ */ new WeakMap(), a = (e) => {
  const r = c.get(e.target);
  r && r(e);
}, b = (e) => {
  e.forEach(a);
};
function i({ root: e, ...r }) {
  const t = e || document;
  n.has(t) || n.set(t, {});
  const s = n.get(t), o = JSON.stringify(r);
  return s[o] || (s[o] = new IntersectionObserver(b, { root: e, ...r })), s[o];
}
function v(e, r, t) {
  const s = i(r);
  return c.set(e, t), s.observe(e), () => {
    c.delete(e), s.unobserve(e);
  };
}
export {
  v as observeIntersection
};
