import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { $UserService } from '@knuddelsModules/UserData';
import { observer } from '@knuddels-app/mobx';
import { SettingsValueItem } from '../SettingsValueItem';
import { useSubscription } from '@knuddels-app/Connection';
import { VerifiedDataSubscription } from '@generated/graphql';
import { Authenticity, TanSystemEnabled } from '@knuddelsModules/FeatureFlags';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { ListGroup } from '@knuddels/component-library';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { EMAIL_APP_SLASH_COMMAND } from '@shared/constants';
export const VerifiedDataSettings: React.FC = observer('VerifiedDataSettings', () => {
  const i18n = useService($I18n);
  const userService = useService($UserService);
  const snackbarService = useService($SnackbarService);
  const execute = useSlashCommandWithoutChannel();
  const logEvent = useLogEvent();
  if (!userService.currentUser) {
    return null;
  }
  const verifyMobileNumber = async (): Promise<void> => {
    logEvent('Settings_AccountAndSecurity', userService.currentUser.verifiedPhoneNumber ? 'ChangePhonenumber_Clicked' : 'VerifyPhonenumber_Clicked');
    const result = await execute('/handyverify');
    if (result !== 'SendSlashCommandSuccess') {
      snackbarService.showGenericError();
    }
  };
  const changeEmail = async (): Promise<void> => {
    logEvent('Settings_AccountAndSecurity', 'ChangeEmail_Clicked');
    const result = await execute(EMAIL_APP_SLASH_COMMAND);
    if (result !== 'SendSlashCommandSuccess') {
      snackbarService.showGenericError();
    }
  };
  const maskedPhoneNumber = maskPhoneNumber(userService.currentUser.verifiedPhoneNumber);
  return <>
				{/* Use separate component for subscription to make sure currentUser is available */}
				<SettingsSubscription currentUserId={userService.currentUser.id} />
				<ListGroup title={declareFormat({
      id: 'settings.accountSettings.verifiedDataGroup.title',
      defaultFormat: 'Your account'
    }).format(i18n)} subtitle={declareFormat({
      id: 'settings.accountSettings.verifiedDataGroup.subtitle',
      defaultFormat: 'This information is not publicly available.'
    }).format(i18n)}>
					<Authenticity.OnActive>
						<SettingsValueItem value={userService.currentUser.email} caption={declareFormat({
          id: 'settings.accountSettings.verifiedDataGroup.email',
          defaultFormat: 'Email address'
        }).format(i18n)} fallbackText={declareFormat({
          id: 'settings.accountSettings.verifiedDataGroup.email.fallback',
          defaultFormat: 'Verify now'
        }).format(i18n)} onEdit={changeEmail} />
					</Authenticity.OnActive>

					<TanSystemEnabled.OnActive>
						<SettingsValueItem value={maskedPhoneNumber} caption={declareFormat({
          id: 'settings.accountSettings.verifiedDataGroup.mobile',
          defaultFormat: 'Mobile number'
        }).format(i18n)} fallbackText={declareFormat({
          id: 'settings.accountSettings.verifiedDataGroup.mobile.fallback',
          defaultFormat: 'Verify now'
        }).format(i18n)} onEdit={verifyMobileNumber} separator={'none'} />
					</TanSystemEnabled.OnActive>
				</ListGroup>
			</>;
});
const SettingsSubscription: React.FC<{
  currentUserId: string;
}> = observer('SettingsSubscription', ({
  currentUserId
}) => {
  useSubscription(VerifiedDataSubscription, {
    id: currentUserId
  }, () => {});
  return null;
});

// Replace all but last 3 symbols with asterisk (*)
function maskPhoneNumber(phoneNumber?: string, mask = '*'): string {
  if (!phoneNumber || phoneNumber.length <= 3) {
    return phoneNumber;
  }
  return phoneNumber.replace(/\d(?=\d{3})/g, mask);
}