import { useCallback as r } from "react";
import { isRefObject as p } from "../../utils/is-ref-object.js";
function j(m, u, f) {
  return r(
    (o) => {
      o && m.mount && m.mount(o), u && (o ? u.mount(o) : u.unmount()), f && (typeof f == "function" ? f(o) : p(f) && (f.current = o));
    },
    /**
     * Only pass a new ref callback to React if we've received a visual element
     * factory. Otherwise we'll be mounting/remounting every time externalRef
     * or other dependencies change.
     */
    [u]
  );
}
export {
  j as useMotionRef
};
