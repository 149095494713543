import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import {
	ContactsUserFragment,
	FriendRequestEvents,
	FriendRequests,
	FriendRequestsDocument,
	FriendRequestsQuery,
	FriendRequestsQueryVariables,
	FriendRequestUserFragment,
	FriendStateChangedEvent,
	Gender,
	SendFriendRequest,
} from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { action, computed, observable } from '@knuddels-app/mobx';
import { $Environment } from '@knuddels-app/Environment';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n } from '@knuddels-app/i18n';
import {
	ADD_FRIEND_CONVERSATION_DEPTH_SUBTEXT,
	ADD_FRIEND_CONVERSATION_DEPTH_TEXT,
	ADD_FRIEND_NOT_ALLOWED,
	ADD_FRIEND_SENDER_LIMIT,
	ADD_FRIEND_SUCCESS,
} from '../i18n/shared';

import clique from '@shared/images/clique.gif';
import sorry from '@shared/images/sorry.gif';

export type FriendRequest = {
	user: FriendRequestUserFragment;
};

export type ReceivedFriendRequest = {
	user: FriendRequestUserFragment;
	isAccepted: boolean;
	timestamp: number;
	// This looks this way to make it compatible with how read state
	// is stored in conversations
	readState: {
		unreadMessageCount: number;
	};
};

@injectable()
export class FriendRequestsService {
	public readonly dispose = Disposable.fn();

	public readonly onReceivedFriendRequestAdded: EventSource<FriendRequest>;
	protected readonly onReceivedFriendRequestAddedEmitter =
		new EventEmitter<FriendRequest>();

	@observable
	private acceptedRequests: readonly FriendRequest[] = [];

	@observable
	private requestsForConversation: readonly ReceivedFriendRequest[] = [];

	private readonly watchQuery =
		this.authenticatedClientService.currentK3Client.watchQuery(
			FriendRequests,
			{ pixelDensity: getPixelRatio() }
		);

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {
		this.onReceivedFriendRequestAdded =
			this.onReceivedFriendRequestAddedEmitter.asEvent();
		this.dispose.track(this.watchQuery);

		this.dispose.track(
			authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				FriendRequestEvents,
				{ pixelDensity: getPixelRatio() },
				{
					next: event => {
						switch (event.__typename) {
							case 'SentFriendRequestAddedEvent':
								this.addSentFriendRequest(event.user);
								break;
							case 'SentFriendRequestRemovedEvent':
								this.removeSentFriendRequest(event.user);
								break;
							case 'ReceivedFriendRequestAddedEvent':
								this.addReceivedFriendRequest(event.user);
								break;
							case 'ReceivedFriendRequestRemovedEvent':
								this.removeReceivedFriendRequest(event.user);
								break;
							default:
								break;
						}
					},
				}
			)
		);

		this.dispose.track(
			authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				FriendStateChangedEvent,
				{},
				{}
			)
		);
	}

	@action.bound
	public onFriendAdded(friend: ContactsUserFragment): void {
		// Gender is not needed anyway when request is accepted
		const user = { ...friend, gender: Gender.Unknown };

		// If the user has requests then he was the one who accepted the request
		const hasProbablyAcceptedTheRequest =
			this.requestsForConversation.filter(
				request => request.user.id === user.id
			).length > 0 ||
			this.acceptedRequests.filter(request => request.user.id === user.id)
				.length > 0;

		const requestForThisFriendIsAccepted =
			this.requestsForConversation.some(
				request => request.user.id === user.id && request.isAccepted
			);
		if (requestForThisFriendIsAccepted) {
			// already has messenger notification
			return;
		}
		this.requestsForConversation = [
			// Remove request that have not been accepted in for this conversation
			...this.requestsForConversation.filter(
				request => request.user.id !== user.id
			),
			{
				user,
				timestamp: Date.now(),
				isAccepted: true,
				readState: {
					// Don't show as unread for the one who accepted the request
					// Also don't mark unread in stapp sidebar because there you can't remove the badge
					unreadMessageCount:
						hasProbablyAcceptedTheRequest ||
						this.environment.isStappSidebarOverview
							? 0
							: 1,
				},
			},
		];
	}

	private addSentFriendRequest = (user: FriendRequestUserFragment) => {
		this.updateFriendRequests((sentRequests, receivedRequests) => {
			return { sentRequests: [user, ...sentRequests], receivedRequests };
		});
	};

	private removeSentFriendRequest = (user: FriendRequestUserFragment) => {
		this.updateFriendRequests((sentRequests, receivedRequests) => {
			return {
				sentRequests: sentRequests.filter(it => it.id !== user.id),
				receivedRequests,
			};
		});
	};

	@action.bound
	private addReceivedFriendRequest(user: FriendRequestUserFragment): void {
		this.updateFriendRequests((sentRequests, receivedRequests) => {
			return {
				sentRequests,
				receivedRequests: [user, ...receivedRequests],
			};
		});

		this.requestsForConversation = [
			...this.requestsForConversation,
			{
				user,
				timestamp: Date.now(),
				isAccepted: false,
				readState: {
					// Don't mark unread in stapp sidebar because there you can't remove the badge
					unreadMessageCount: this.environment.isStappSidebarOverview
						? 0
						: 1,
				},
			},
		];

		this.onReceivedFriendRequestAddedEmitter.emit({ user });
	}

	@action.bound
	private removeReceivedFriendRequest(user: FriendRequestUserFragment): void {
		this.updateFriendRequests((sentRequests, receivedRequests) => {
			return {
				sentRequests,
				receivedRequests: receivedRequests.filter(
					it => it.id !== user.id
				),
			};
		});

		this.requestsForConversation = this.requestsForConversation.filter(
			request => request.user.id !== user.id || request.isAccepted
		);
	}

	public get acceptedFriendRequests(): readonly FriendRequest[] {
		return this.acceptedRequests;
	}

	public get friendRequestsForConversation(): readonly ReceivedFriendRequest[] {
		return this.requestsForConversation;
	}

	@action.bound
	public setFriendRequestAccepted(
		user: FriendRequestUserFragment,
		isAccepted: boolean
	): void {
		if (isAccepted) {
			this.acceptedRequests = [{ user }, ...this.acceptedRequests];
		} else {
			this.acceptedRequests = this.acceptedRequests.filter(
				it => it.user.id !== user.id
			);
		}
	}

	@action.bound
	public setRequestForConversationAccepted(
		userId: string,
		isAccepted: boolean
	): void {
		this.requestsForConversation = this.requestsForConversation.map(it => {
			if (it.user.id === userId) {
				return { ...it, isAccepted };
			} else {
				return it;
			}
		});
	}

	@action.bound
	public markFriendRequestAsRead(id: string): void {
		this.requestsForConversation = this.requestsForConversation.map(it => {
			if (it.user.id === id) {
				return { ...it, readState: { unreadMessageCount: 0 } };
			} else {
				return it;
			}
		});
	}

	@action.bound
	public sendFriendRequest(
		id: string,
		track?: (message: string) => void
	): Promise<boolean> {
		return this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(SendFriendRequest, { userId: id })
			.then(result => {
				return result.match({
					ok: r => {
						switch (r.__typename) {
							case 'Success':
								track?.('SendFriendRequestSuccess');

								this.snackbarService.showSnackbar({
									type: 'friend-request-sent',
									adornment: clique,
									text: this.i18n.format(ADD_FRIEND_SUCCESS),
								});
								// state is updated through event
								break;
							case 'ReceiverFriendLimitReachedError':
							case 'NotAllowedByReceiverError':
								track?.('SendFriendRequestCantSendError');

								this.snackbarService.showErrorSnackbarWithDefaults(
									{
										type: 'friend-request-error',
										text: this.i18n.format(
											ADD_FRIEND_NOT_ALLOWED
										),
										subtext: undefined,
									}
								);
								break;
							case 'SenderFriendLimitReachedError':
								track?.('SendFriendRequestLimitReachedError');

								this.snackbarService.showGenericError(
									this.i18n.format(ADD_FRIEND_SENDER_LIMIT)
								);
								break;
							case 'ConversationDepthNotReachedError':
								this.snackbarService.showSnackbar({
									type: 'AddFriendConversationDepth',
									text: this.i18n.format(
										ADD_FRIEND_CONVERSATION_DEPTH_TEXT
									),
									subtext: this.i18n.format(
										ADD_FRIEND_CONVERSATION_DEPTH_SUBTEXT
									),

									adornment: sorry,
								});
								break;
							case 'TooManyRequestsError':
							// just send the generic error with "try again later".
							// eslint-disable-next-line no-fallthrough
							default:
								track?.('SendFriendRequestError');

								this.snackbarService.showGenericError();
						}

						return r.__typename === 'Success';
					},
					error: () => {
						track?.('SendFriendRequestError');

						this.snackbarService.showGenericError();

						return false;
					},
				});
			});
	}

	@computed
	public get sentFriendRequests(): readonly FriendRequest[] {
		const users =
			typeof this.watchQuery.value !== 'string'
				? this.watchQuery.value.contacts.sentFriendRequests
				: [];

		return users.map(user => ({ user }));
	}

	@computed
	public get receivedFriendRequests(): readonly FriendRequest[] {
		const users =
			typeof this.watchQuery.value !== 'string'
				? this.watchQuery.value.contacts.receivedFriendRequests
				: [];

		return users.map(user => ({ user }));
	}

	@computed
	public get isLoaded(): boolean {
		return this.watchQuery.value !== 'loading';
	}

	private updateFriendRequests(
		mapper: (
			currentSentRequests: readonly FriendRequestUserFragment[],
			currentReceivedRequests: readonly FriendRequestUserFragment[]
		) => {
			sentRequests: readonly FriendRequestUserFragment[];
			receivedRequests: readonly FriendRequestUserFragment[];
		}
	): void {
		const result = this.readFriendRequestsQuery();
		const currentSentRequests = result?.contacts?.sentFriendRequests;
		const currentReceivedRequests =
			result?.contacts?.receivedFriendRequests;
		if (!currentSentRequests || !currentReceivedRequests) {
			return;
		}

		const newRequests = mapper(
			currentSentRequests,
			currentReceivedRequests
		);
		this.writeFriendRequestsQuery(
			result,
			newRequests.sentRequests,
			newRequests.receivedRequests
		);
	}

	private readFriendRequestsQuery(): FriendRequestsQuery | undefined {
		try {
			return this.authenticatedClientService.currentClient.readQuery<
				FriendRequestsQuery,
				FriendRequestsQueryVariables
			>({
				query: FriendRequestsDocument,
				variables: {
					pixelDensity: getPixelRatio(),
				},
			});
		} catch (e) {
			return undefined;
		}
	}

	private writeFriendRequestsQuery(
		currentQuery: FriendRequestsQuery,
		sentRequests: readonly FriendRequestUserFragment[],
		receivedRequests: readonly FriendRequestUserFragment[]
	): void {
		this.authenticatedClientService.currentClient.writeQuery<
			FriendRequestsQuery,
			FriendRequestsQueryVariables
		>({
			query: FriendRequestsDocument,
			variables: {
				pixelDensity: getPixelRatio(),
			},
			data: {
				...currentQuery,
				contacts: {
					...currentQuery.contacts,
					sentFriendRequests: sentRequests,
					receivedFriendRequests: receivedRequests,
				},
			},
		});
	}
}
