import { observable, when, action } from '@knuddels-app/mobx';
import { BugIndicatingError } from '@knuddels/std';
import { FeatureSetting } from './FeatureSetting';

/**
 * Represents a binary feature setting.
 */
export class FeatureFlag extends FeatureSetting {
	public static create(options: {
		/**
		 * A unique id. Must be unique among all feature settings.
		 */
		id: string;
		/**
		 * Default value for production.
		 * If not specified, it falls back to `default`.
		 */
		productionDefault?: boolean;
		/**
		 * Default value for development.
		 * If not specified, it falls back to `default`.
		 */
		devDefault?: boolean;
		/**
		 * Default value.
		 * If not specified, the promise will stay pending until a value is set.
		 */
		default?: boolean;
	}): FeatureFlag {
		return new FeatureFlag(
			options.id,
			options.default,
			options.productionDefault,
			options.devDefault
		);
	}

	public readonly kind = 'FeatureFlag';

	public readonly default: boolean | undefined;

	private constructor(
		id: string,
		defaultValue: boolean | undefined,
		productionDefault: boolean | undefined,
		devDefault: boolean | undefined
	) {
		super(id);

		if (import.meta.env.MODE === 'development') {
			this.default = devDefault !== undefined ? devDefault : defaultValue;
		} else if (import.meta.env.MODE === 'production') {
			this.default =
				productionDefault !== undefined
					? productionDefault
					: defaultValue;
		}
	}
}

export interface FeatureFlagState {
	/**
	 * Is `undefined` if still loading.
	 */
	readonly isEnabled: boolean | undefined;
	/**
	 * Throws an exception if it has not been loaded.
	 */
	readonly isEnabledAssertLoaded: boolean;
	readonly isEnabledPromise: Promise<boolean>;
}

export class FeatureFlagStateImpl implements FeatureFlagState {
	public readonly isEnabledPromise: Promise<boolean>;

	@observable
	private _isEnabled: boolean | undefined;

	public get isEnabled(): boolean | undefined {
		return this._isEnabled;
	}

	public get isEnabledAssertLoaded(): boolean {
		const e = this.isEnabled;
		if (e === undefined) {
			throw new BugIndicatingError(
				`Flag "${this.flag.id}" was expected to be loaded, but was not!`
			);
		}
		return e;
	}

	constructor(public readonly flag: FeatureFlag) {
		this.isEnabledPromise = when(
			{ name: `Wait for flag "${this.flag.id}" to load` },
			() => this._isEnabled,
			{
				resolveTest: value =>
					value !== undefined ? { value } : undefined,
			}
		).then(v => v.value);
	}

	@action
	public setEnable(enabled: boolean): void {
		this._isEnabled = enabled;
	}
}
