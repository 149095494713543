import * as React from 'react';
import { useAcceptFriendRequest } from './useAcceptFriendRequest';
import { Spacer } from '@shared/components';
import { FormattedMessage } from '@knuddels-app/i18n';
import { ACCEPT_FRIEND_REQUEST } from '../../i18n/shared-formats';
import { Flex, IconHeart, createAccessiblePointerEventHandler, Text, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export const AcceptFriendRequestButton: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const acceptFriendRequest = useAcceptFriendRequest();
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      acceptFriendRequest(userId);
    }
  })} className={_c0 + ("rgb(71,209,179)" ? resolveIsDarkColor("rgb(71,209,179)", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c1 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<IconHeart size={'base'} />
				<Spacer size={'small'} />
				<Text type={'body2'} bold={true} className={_c2}>
					<FormattedMessage id={ACCEPT_FRIEND_REQUEST} />
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-Flex cursor-pointer px-large py-small bg-rgb-71-209-179- borderRadius-small ";
const _c1 = " Knu-Flex bg-transparentDark alignItems-center ";
const _c2 = "  ";