// https://stackoverflow.com/a/44602566
const supportsTouch = typeof window !== 'undefined' && 'ontouchstart' in window;
let isTouch = false;

// `touchstart`, `pointerdown`
const touchHandler = () => {
	isTouch = true;
	document.addEventListener('mousemove', mousemoveHandler);
};

// use a simple closure to store previous time as internal state
const mousemoveHandler = (() => {
	let time: number;

	return () => {
		const now = performance.now();

		if (now - time < 20) {
			isTouch = false;
			document.removeEventListener('mousemove', mousemoveHandler);
		}

		time = now;
	};
})();

// add listeners
if (supportsTouch) {
	document.addEventListener('touchstart', touchHandler);
} else if (
	typeof navigator !== 'undefined' &&
	(navigator.maxTouchPoints || 'msMaxTouchPoints' in navigator)
) {
	document.addEventListener('pointerdown', touchHandler);
}

export function isUsingTouch(): boolean {
	return isTouch;
}
