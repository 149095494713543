import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache';
import possibleTypes from '@generated/possibleTypes.json';

export function createApolloClientCache(): InMemoryCache {
	return new InMemoryCache({
		possibleTypes,
		typePolicies: {
			ChannelQuery: {
				fields: {
					category: (existingData, { args, toReference }) => {
						return (
							existingData ||
							toReference({
								__typename: 'ChannelCategory',
								id: args!.id,
							})
						);
					},
				},
				keyFields: [],
			},
			UserQuery: {
				fields: {
					user: (existingData, { args, toReference }) => {
						return (
							existingData ||
							toReference({
								__typename: 'User',
								id: args!.id,
							})
						);
					},
				},
				keyFields: [],
			},
			MessengerQuery: {
				fields: {
					conversation: (existingData, { args, toReference }) => {
						return (
							existingData ||
							toReference({
								__typename: 'MessengerConversation',
								id: args!.id,
							})
						);
					},
				},
				keyFields: [],
			},
			SmileyboxQuery: {
				fields: {
					smileyDetails: (existingData, { args, toReference }) => {
						return (
							existingData ||
							toReference({
								__typename: 'SmileyDetails',
								id: args!.id,
							})
						);
					},
				},
				keyFields: [],
			},
			FotomeetQuery: {
				keyFields: [],
			},
			ContactsQuery: {
				keyFields: [],
				fields: {
					receivedFriendRequests: {
						merge: false,
					},
					sentFriendRequests: {
						merge: false,
					},
				},
			},
			ProfilePicture: {
				// ProfilePicture contains multiple different urls that are
				// queried in separate queries. Thus we need to merge a newly
				// fetched url into the existing cache object.
				merge: true,
			},
			ChannelEvent: {
				merge: false,
			},
			SystemEvent: {
				merge: false,
			},
			MessengerEvent: {
				merge: false,
			},
			FotomeetEvent: {
				merge: false,
			},
			PaymentEvent: {
				merge: false,
			},
			SmileyBoxEvent: {
				merge: false,
			},
			ProfileVisitorsEvent: {
				merge: false,
			},
			ClientSettingsEvent: {
				merge: false,
			},
			HappyMomentEvent: {
				merge: false,
			},
			ContactFilterSettingsEvent: {
				merge: false,
			},
			AppEvent: {
				merge: false,
			},
			ComplaintsEvent: {
				merge: false,
			},
			PromotionEvent: {
				merge: false,
			},
			MentorEvent: {
				merge: false,
			},
			ConversationForwardedMessageContent: {
				merge: true,
			},
			ConversationQuotedMessageContent: {
				merge: true,
			},
			ConversationImageMessageContent: {
				merge: true,
			},
			ConversationTextMessageContent: {
				merge: true,
			},
			ConversationVisiblePhotoCommentMessageContent: {
				merge: true,
			},
			ConversationHiddenPhotoCommentMessageContent: {
				merge: true,
			},
			ConversationDeletedPhotoCommentMessageContent: {
				merge: true,
			},
		},
		dataIdFromObject: value => {
			if (value.__typename === 'MessengerNestedMessage') {
				// default if __typename and id is set => new cache entry with `dataId/key = __typename + id`
				// now for nested messages we don't want such an entry as there can be multiple
				// (quotes/forwards of same message => are all nested messages with the same id and will override itself)
				// => we manually deny making such a cache entry and it will add it as a part of the parent query/type
				// => $MessengerMessage:..id...nestedMessage
				return undefined;
			}
			return defaultDataIdFromObject(value);
		},
	});
}
