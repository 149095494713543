import * as React from 'react';
import { Text } from '@knuddels/component-library';
export function TextWithPrefixHighlight({
  text,
  highlightedPrefix
}: {
  text: string;
  highlightedPrefix?: string;
}): React.ReactElement {
  return <Text type={'body1'} state={'primary'} className={_c0}>
			<Text bold={true} className={_c1}>
				{highlightedPrefix ? text.substr(0, highlightedPrefix.length) : ''}
			</Text>
			{highlightedPrefix ? text.substring(highlightedPrefix.length) : text}
		</Text>;
}
const _c0 = "  ";
const _c1 = "  ";