import { ProfileTagFragment } from '@generated/graphql';
import { Flex, Text, ThemeOverride, useTheme, createAccessiblePointerEventHandler } from '@knuddels/component-library';
import * as React from 'react';
import styles from './Tag.module.scss';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
export const UserTagList: React.FC<{
  tags: readonly ProfileTagFragment[];
}> = ({
  tags
}) => {
  return <div className={_c0}>
			{tags.map(({
      displayName,
      isMatching,
      slashCommand
    }) => <Tag key={displayName} displayName={displayName} isMatching={isMatching} slashCommand={slashCommand} />)}
		</div>;
};
const Tag: React.FC<{
  displayName: string;
  isMatching: boolean;
  slashCommand: string | null | undefined;
}> = ({
  displayName,
  isMatching,
  slashCommand
}) => {
  const theme = useTheme();
  const gradient = 'linear-gradient(90deg, rgba(145,129,245,1) 0%, rgba(198,126,242,1) 100%)';
  const execute = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  return <Flex className={styles.gradientBorderMask} alignItems={'center'} px={(7 as ThemeOverride)} py={((!isMatching ? 3 : 4) as ThemeOverride)} bg={'cardBg'} style={{
    borderImage: !isMatching ? 'none' : undefined,
    border: !isMatching ? `1px solid ${theme.colors.basic.hairlineAtom}` : undefined
  }} {...slashCommand ? createAccessiblePointerEventHandler({
    pointerCallback: () => {
      if (slashCommand) {
        execute(slashCommand);
      }
    }
  }) : {}}>
			<Text bold={true} state={'secondary'} type={'tiny'} style={{
      background: isMatching ? gradient : undefined,
      WebkitBackgroundClip: isMatching ? 'text' : undefined,
      WebkitTextFillColor: isMatching ? 'transparent' : undefined
    }} className={_c1}>
				{displayName}
			</Text>
		</Flex>;
};
const _c0 = " Knu-Flex flexWrap-wrap gap-tiny ";
const _c1 = "  ";