export class Endpoint {
	public readonly id: string;
	public readonly name: string;
	public readonly urls: EndpointUrls;
	public readonly supportEmail: string;

	constructor(data: {
		id: string;
		name: string;
		urls: EndpointUrls;
		supportEmail: string;
	}) {
		this.id = data.id;
		this.name = data.name;
		this.urls = data.urls;
		this.supportEmail = data.supportEmail;
	}

	public withName(newName: string): Endpoint {
		return new Endpoint({
			id: this.id,
			name: newName,
			urls: this.urls,
			supportEmail: this.supportEmail,
		});
	}

	public withUrls(overrides: Partial<EndpointUrls>): Endpoint {
		const urls = Object.assign({}, this.urls, overrides);
		return new Endpoint({
			id: this.id,
			name: this.name,
			urls,
			supportEmail: this.supportEmail,
		});
	}
}

export interface EndpointUrls {
	defaultOrigin: string;
	profilePic: string;
	login: string;
	chatServer: string;
	photoServer: string;
	registration: string;
	graphQl: string;
	graphQlSubscription: string;
}
