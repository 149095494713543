import { FlexCol, Smiley, ThemeColors, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
export const MentorImage: React.FC<{
  bgSize: number;
  bgColor?: ThemeColors;
  imageWidth: number;
  imageHeight: number;
}> = ({
  bgSize,
  imageWidth,
  imageHeight,
  bgColor = 'orange-100'
}) => {
  return <div style={{
    width: resolveThemingValue(bgSize, "sizes", useTheme()),
    height: resolveThemingValue(bgSize, "sizes", useTheme()),
    background: resolveThemingValue(bgColor, "colors", useTheme())
  }} className={_c0 + (bgColor ? resolveIsDarkColor(bgColor, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Smiley src={require('@shared/images/sm_abo_15-05_schulabschluss.gif')} height={imageHeight} width={imageWidth} />
		</div>;
};
const _c0 = " Knu-FlexCol borderRadius-circle placeItems-center mr-small overflow-hidden ";