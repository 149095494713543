import styles from './container.module.scss';

export const center = {
	className: styles.center,
};

export const alignItemsCenter = {
	className: styles.alignItemsCenter,
};

export const justifyContentCenter = {
	className: styles.justifyContentCenter,
};

export const justifyContentEnd = {
	className: styles.justifyContentEnd,
};

export const flex1 = {
	className: styles.flex1,
};

export const flexAuto = {
	className: styles.flexAuto,
};

export const heightAuto = {
	style: { height: 'auto' },
};
