import * as React from 'react';
import { ModalCard, ModalWrapper, Spacer } from '@shared/components';
import { FlexCol, Smiley, Text } from '@knuddels/component-library';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import pic from '@shared/images/loading.gif';
export class UploadProfilePictureProgressModal extends React.PureComponent {
  render(): React.ReactNode {
    return <ModalWrapper cancelModal={this.handleClose} fillHeight renderNativeModal={false}>
				<ModalCard fillHeight>
					<div className={_c0}>
						<Smiley src={pic} width={'69px'} height={'62px'} />
						<Spacer size={'base'} />
						<Text bold={true} type={'body1'} state={'secondary'} className={_c1}>
							{formatMessage(declareFormat({
              id: 'UPLOADING_PROFILE_PICTURE',
              defaultFormat: 'Uploading picture...'
            }))}
						</Text>
					</div>
				</ModalCard>
			</ModalWrapper>;
  }
  private handleClose = (): void => {};
}
const _c0 = " Knu-FlexCol flex-1 placeItems-center ";
const _c1 = "  ";