import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as ids from '../providedServices';
import * as s from './services';
import { foreachProp } from '@knuddels/std';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$KnuddelDisplayComponents: ctx.globalScope.toSingleton(
			s.KnuddelDisplayComponents
		),
		$KnuddelDisplayAnimationService: ctx.loggedInScope.toSingleton(
			s.KnuddelDisplayAnimationService
		),
	});
}
