import * as React from 'react';
import { LineDivider } from './LineDivider';
import { XOR } from '../../tools';
import { Flex, FlexCol, rgb, Text, ThemeColors, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
export type LineDividerWithTextColors = 'default' | 'accent' | 'attention';
export type LineDividerWithTextStyle = 'solid' | 'dashed' | 'none';
interface Props {
  text: string;
  lineStyle?: LineDividerWithTextStyle;
  testId?: string;
  transparentBadge?: boolean;
}
interface PresetProps extends Props {
  colorPreset?: LineDividerWithTextColors;
}
interface RawProps extends Props {
  rawColor?: string;
}
const colors: { [key in LineDividerWithTextColors]: ThemeColors } = {
  accent: 'accent',
  attention: rgb(236, 132, 64),
  default: 'black-transparent-220'
};
export const LineDividerWithText = React.memo(({
  text,
  lineStyle = 'solid',
  transparentBadge,
  colorPreset = 'default',
  rawColor
}: XOR<PresetProps, RawProps>) => {
  const color = rawColor || colors[colorPreset];
  const divider = lineStyle !== 'none' ? <LineDivider rawColor={color} fluid={true} lineStyle={lineStyle} className={_c0} /> : <div className={_c1} />;
  return <div className={_c2}>
				{divider}
				<div style={{
      background: resolveThemingValue(transparentBadge ? 'transparent' : (color as ThemeOverride), "colors", useTheme())
    }} className={_c3 + ((transparentBadge ? 'transparent' : (color as ThemeOverride)) ? resolveIsDarkColor(transparentBadge ? 'transparent' : (color as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Text type="tiny" bold={true} color={transparentBadge ? (color as ThemeOverride) : 'white-solid-white'} className={_c4}>
						{text}
					</Text>
				</div>
				{divider}
			</div>;
});
LineDividerWithText.displayName = 'LineDividerWithText';
const _c0 = "  ";
const _c1 = " Knu-FlexCol flex-1 height-1px ";
const _c2 = " Knu-Flex alignItems-center my-tiny mx-small ";
const _c3 = " Knu-Flex borderRadius-5px px-small py-1px ";
const _c4 = "  ";