const a = () => {
  let n = window.navigator.userAgent, r, e = n.match(
    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
  ) || [];
  return /trident/i.test(e[1]) ? (r = /\brv[ :]+(\d+)/g.exec(n) || [], { name: "IE", version: r[1] || "" }) : e[1] === "Chrome" && (r = n.match(/\bOPR|Edge\/(\d+)/), r != null) ? { name: "Opera", version: r[1] } : (e = e[2] ? [e[1], e[2]] : [window.navigator.appName, window.navigator.appVersion, "-?"], (r = n.match(/version\/(\d+)/i)) != null && e.splice(1, 1, r[1]), {
    name: e[0],
    version: e[1]
  });
}, i = a(), o = () => i.name === "Safari" && Number(i.version) < 15;
export {
  o as isSafari14OrLower
};
