import { time as m } from "../frameloop/sync-time.js";
import { frame as s, cancelFrame as o } from "../frameloop/frame.js";
function d(a, r) {
  const c = m.now(), e = ({ timestamp: n }) => {
    const t = n - c;
    t >= r && (o(e), a(t - r));
  };
  return s.read(e, !0), () => o(e);
}
export {
  d as delay
};
