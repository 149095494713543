import { Color } from '@generated/graphql';

export function colorToRgbaString(
	color: Color | undefined
): string | undefined {
	if (!color) {
		return undefined;
	}
	const { red, green, blue, alpha } = color;
	return `rgba(${red}, ${green},${blue},${alpha})`;
}
