import { inject, injectable, newServiceId } from '@knuddels-app/DependencyInjection';
import { observable, reactionOnceWhen, runInAction } from '@knuddels-app/mobx';
import { $ModuleService, Module } from '@knuddels-app/ModuleSystem';
import { $InternalAdsService } from './bundle/providedServices';
import { Disposable } from '@knuddels/std';
import { AdFreeAcquired, UserIsAdFree } from '@generated/graphql';
import { $AuthenticatedClientService, $AuthService } from '@knuddels-app/Connection';
import { showFullscreenAd } from './bundle/showFullscreenAd';
import { OS, os } from '@shared/components/tools/os';
import { isNative } from '@knuddels-app/tools/isNative';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';
export const $AdsService = newServiceId<AdsService>('$AdsService');
@injectable()
export class AdsService implements Disposable {
  public readonly dispose = Disposable.fn();
  public get Adzone(): undefined | (typeof $InternalAdsService.T)['Adzone'] {
    return this._Adzone;
  }
  public get CapacitorAdzone(): undefined | (typeof $InternalAdsService.T)['CapacitorAdzone'] {
    return this._CapacitorAdzone;
  }
  public get shouldShowAds(): boolean {
    return this.adsEnabled && this.Adzone !== undefined && this.isAdFree === false;
  }
  public get areWebAdsVisible(): boolean {
    return !isNative() && this.shouldShowAds;
  }
  public get areCapacitorAdsVisible(): boolean {
    return isNative() && this.CapacitorAdzone !== undefined && this.shouldShowAds;
  }
  @observable.ref
  private _Adzone: undefined | (typeof $InternalAdsService.T)['Adzone'];
  @observable.ref
  private _CapacitorAdzone: undefined | (typeof $InternalAdsService.T)['CapacitorAdzone'];
  private get adsEnabled(): boolean {
    if (os === OS.web && import.meta.env.MODE === 'development') {
      if (typeof window !== 'undefined') {
        const searchParams = new URL(window.location.href).searchParams;
        if (searchParams.has('dev.disableAds')) {
          return false;
        }
      }
    }
    return globalEnv.product === 'app';
  }
  @observable
  /**
   * Is undefined if isAdFree has not been requested yet.
   */private isAdFree: boolean | undefined = undefined;
  constructor(@inject($ModuleService)
  private readonly moduleService: typeof $ModuleService.T, @inject($AuthenticatedClientService)
  authenticatedClientService: typeof $AuthenticatedClientService.T, @inject.lazy($InternalAdsService)
  private readonly getInternalAdsService: typeof $InternalAdsService.TLazy, @inject($AuthService)
  private readonly authService: typeof $AuthService.T, @inject($K3Firebase)
  private readonly k3Firebase: typeof $K3Firebase.T) {
    if (this.adsEnabled) {
      const queryWatcher = authenticatedClientService.currentClient.watchQuery<(typeof UserIsAdFree)['TResult'], (typeof UserIsAdFree)['TVariables']>({
        query: UserIsAdFree.document
      }).subscribe(data => {
        runInAction('set adFree', () => {
          this.isAdFree = data.data.user.currentUser.isAdFree;
          k3Firebase.analytics.setUserProperties({
            ['AdFree']: this.isAdFree ? 'Yes' : 'No'
          });
        });
      });
      this.dispose.track(() => queryWatcher.unsubscribe());
      this.dispose.track(authenticatedClientService.currentK3Client.subscribeToPrimaryData(AdFreeAcquired, {}, {}));
      this.dispose.track(reactionOnceWhen({
        name: 'initialize ads if not adFree'
      }, () => this.isAdFree === false, () => this.loadAdsModule()));
      this.dispose.track(this.authService.onBeforeVoluntaryLogout.sub(() => {
        this.showInterstitial();
      }));
    } else {
      k3Firebase.analytics.setUserProperties({
        ['AdFree']: 'Yes'
      });
    }
    this.dispose.track(() => {
      k3Firebase.analytics.setUserProperties({
        ['AdFree']: null
      });
    });
  }
  private async loadAdsModule(): Promise<void> {
    // first register module to register $InternalAdsService
    this.moduleService.addKnownModule(internalAdsModule);
    try {
      // start loading internalAdsModule (ads bundle)
      const internalAdsService = await this.getInternalAdsService();
      runInAction('set Adzone component', () => {
        this._Adzone = internalAdsService.Adzone;
        this._CapacitorAdzone = internalAdsService.CapacitorAdzone;
      });
    } catch (e) {
      // loading the ads bundle might fail if ads are blocked
    }
  }
  showInterstitial(): void {
    if (!this.shouldShowAds) {
      return;
    }
    showFullscreenAd();
  }
}

// create an internal module to use dependency injection more easily
const internalAdsModule = new Module({
  name: 'InternalAdsModule',
  dependencies: [],
  providedServices: {
    $InternalAdsService
  },
  load: {
    // bundle loading fails/crashes if adblocker is active.
    // currently module is only loaded by AdsService which catches/handles the error
    from: () => import( /* webpackChunkName: "ads" */'./bundle/registerServices')
  }
});