import { collectMotionValues as e } from "./index.js";
import { useCombineMotionValues as n } from "./use-combine-values.js";
function i(o) {
  e.current = [], o();
  const r = n(e.current, o);
  return e.current = void 0, r;
}
export {
  i as useComputed
};
