import { action, observable } from '@knuddels-app/mobx';
import { SendingMessage } from './SendingMessage';
import { ConversationMessageFragment } from '@generated/graphql';

export class SendingMessagesState {
	@observable
	private _messages: SendingMessage[] = [];

	public get messages(): readonly SendingMessage[] {
		return this._messages;
	}

	@action.bound
	public add(message: SendingMessage): void {
		this._messages.push(message);
	}

	@action.bound
	public enableRetry(messageCorrelationId: string): void {
		const index = this._messages.findIndex(
			it => it.messageCorrelationId === messageCorrelationId
		);
		if (index > -1) {
			this._messages[index] = {
				...this._messages[index],
				canRetry: true,
				timestamp: Date.now().toString() as ConversationMessageFragment['timestamp'],
			};
		}
	}

	@action.bound
	public disableRetry(messageCorrelationId: string): void {
		const index = this._messages.findIndex(
			it => it.messageCorrelationId === messageCorrelationId
		);
		if (index > -1) {
			this._messages[index] = {
				...this._messages[index],
				canRetry: false,
			};
		}
	}

	@action.bound
	public removeByMessageCorrelationId(messageCorrelationId: string): void {
		const index = this._messages.findIndex(
			it => it.messageCorrelationId === messageCorrelationId
		);
		if (index > -1) {
			this._messages.splice(index, 1);
		}
	}

	@action.bound
	public markAllAsUnread(): void {
		this._messages = this._messages.map(message => {
			if (message.isUnread) {
				return {
					...message,
					isUnread: false,
				};
			} else {
				return message;
			}
		});
	}

	@action.bound
	public markAsUnread(messageCorrelationId: string): void {
		const index = this._messages.findIndex(
			it => it.messageCorrelationId === messageCorrelationId
		);
		if (index > -1) {
			this._messages[index] = {
				...this._messages[index],
				isUnread: true,
			};
		}
	}
}
