import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const c = r(
  /* @__PURE__ */ o(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      children: /* @__PURE__ */ o(
        "path",
        {
          d: "M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M15,17h-3l-4-5l4-5h3l-4,5 L15,17z M20,12c0,4.418-3.582,8-8,8s-8-3.582-8-8s3.582-8,8-8S20,7.582,20,12z",
          fill: "currentColor"
        }
      )
    }
  )
);
c.displayName = "IconBack";
export {
  c as IconBack
};
