import { easeInOut as c } from "../../easing/ease.js";
import { isEasingArray as p } from "../../easing/utils/is-easing-array.js";
import { easingDefinitionToFunction as m } from "../../easing/utils/map.js";
import { interpolate as u } from "../../utils/interpolate.js";
import { defaultOffset as l } from "../../utils/offsets/default.js";
import { convertOffsetToTimes as g } from "../../utils/offsets/time.js";
function T(n, t) {
  return n.map(() => t || c).splice(0, n.length - 1);
}
function y({ duration: n = 300, keyframes: t, times: o, ease: r = "easeInOut" }) {
  const e = p(r) ? r.map(m) : m(r), i = {
    done: !1,
    value: t[0]
  }, s = g(
    // Only use the provided offsets if they're the correct length
    // TODO Maybe we should warn here if there's a length mismatch
    o && o.length === t.length ? o : l(t),
    n
  ), a = u(s, t, {
    ease: Array.isArray(e) ? e : T(t, e)
  });
  return {
    calculatedDuration: n,
    next: (f) => (i.value = a(f), i.done = f >= n, i)
  };
}
export {
  T as defaultEasing,
  y as keyframes
};
