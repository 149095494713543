import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { User } from '@generated/graphql';
import { AlbumPicture } from '@knuddelsModules/Profile/interfaces';

type AlbumResponse = {
	albums: {
		photos: readonly AlbumPicture[];
	}[];
};

@injectable()
export class AlbumPicturesService {
	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {}

	/**
	 * Retrieves album photos URLs from a specified album URL.
	 * The URL can be retrieved from a user object.
	 * The promise always resolves with an array. If an error occurs,
	 * a warning is logged.
	 * @param albumPhotosUrl Promise with the album photos URLs
	 */
	getAlbumPictureUrls(
		albumPhotosUrl: User['albumPhotosUrl']
	): Promise<readonly AlbumPicture[]> {
		return fetch(albumPhotosUrl, { method: 'get' })
			.then(resp => resp.json())
			.then(json => {
				const response = json as AlbumResponse;
				if (!response || !response.albums) {
					return [];
				}

				const photos = []
					.concat(...response.albums.map(album => album.photos))
					.map(photo => {
						return {
							url: photo.url,
							type: 'albumPicture',
							photoId: photo.id,
						} as AlbumPicture;
					});

				return photos;
			})
			.catch(e => {
				console.warn(e);
				return [];
			});
	}
}
