import { createMotionComponent as c } from "../../motion/index.js";
function u(r) {
  function n(e, t = {}) {
    return c(r(e, t));
  }
  if (typeof Proxy > "u")
    return n;
  const o = /* @__PURE__ */ new Map();
  return new Proxy(n, {
    /**
     * Called when `motion` is referenced with a prop: `motion.div`, `motion.input` etc.
     * The prop name is passed through as `key` and we can use that to generate a `motion`
     * DOM component with that name.
     */
    get: (e, t) => (o.has(t) || o.set(t, n(t)), o.get(t))
  });
}
export {
  u as createMotionProxy
};
