import * as React from 'react';
import { Network } from '@capacitor/network';
import { useService } from '@knuddels-app/DependencyInjection';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';

const getConnectionSpeed = () => {
	// only available in chromium based browsers
	return (navigator as any)?.connection?.effectiveType ?? 'unknown';
};

export const useTrackNetworkCondition = () => {
	const firebase = useService($K3Firebase);

	React.useEffect(() => {
		(async () => {
			const status = await Network.getStatus();

			const ConnectionType = `${
				status.connectionType
			} - ${getConnectionSpeed()}`;

			firebase.analytics.setUserProperties({
				ConnectionType,
			});
		})();

		return () => {
			firebase.analytics.setUserProperties({
				ConnectionType: null,
			});
		};
	}, []);
};
