import * as providedServices from './providedServices';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { featureFlagsModule } from '@knuddelsModules/FeatureFlags/module';

export const onboardingModule = new Module({
	name: 'Onboarding module',
	dependencies: [featureFlagsModule],
	providedServices,
	load: {
		from: () =>
			import(
				/* webpackChunkName: "onboarding" */ './bundle/registerServices'
			),
	},
	loadEvent: 'onLogin',
});
