import { jsx as t } from "react/jsx-runtime";
import * as a from "react";
import { useContext as s } from "react";
import { Modal as c } from "../Layout/Modal.js";
import { ButtonBarContainer as d, GenericButtonBar as m, ButtonBarContent as h } from "../Layout/ButtonBar.js";
import { clsx as i } from "../../node_modules/clsx/dist/clsx.m.js";
import { Image as x } from "../Image/Image.js";
import { useTheme as u } from "../../themes/useTheme.js";
import { FlexCol as g } from "../Layout/FlexCol.js";
const l = a.createContext("center"), p = ({
  align: e = "center",
  children: n
}) => /* @__PURE__ */ t(
  "div",
  {
    className: "Knu-FlexCol p-base py-large minHeight-0 maxHeight-full",
    children: /* @__PURE__ */ t(h, { shrinkHeight: !0, children: /* @__PURE__ */ t(
      "div",
      {
        className: i(
          "Knu-FlexCol",
          e === "center" && "alignItems-center",
          e === "center" && "textAlign-center"
        ),
        children: /* @__PURE__ */ t(l.Provider, { value: e, children: n })
      }
    ) })
  }
), C = ({ src: e, alt: n, resizeMode: r }) => /* @__PURE__ */ t("div", { className: "Knu-FlexCol mb-base", children: /* @__PURE__ */ t(
  x,
  {
    src: e,
    width: "50px",
    height: "50px",
    resizeMode: r,
    alt: n
  }
) }), o = (e) => {
  const n = u();
  return e.open ? /* @__PURE__ */ t(c, { onPress: e.onClose, closeCallbackType: "onPress", children: /* @__PURE__ */ t(
    "div",
    {
      className: "Knu-Flex size-full alignItems-center justifyContent-center p-large",
      children: /* @__PURE__ */ t(
        g,
        {
          onPress: (r) => {
            r.stopPropagation();
          },
          onPointerDown: (r) => {
            r.stopPropagation();
          },
          onPointerUp: (r) => {
            r.stopPropagation();
          },
          shadow: "Shadow2",
          overflow: "hidden",
          width: "100%",
          maxHeight: "100%",
          maxWidth: n.sizes.dialogs.maxWidth + "px",
          bg: "dialogBoxBg",
          borderRadius: "base",
          children: /* @__PURE__ */ t(
            d,
            {
              shrinkHeight: !0,
              borderBottomRadius: "base",
              children: e.children
            }
          )
        }
      )
    }
  ) }) : null;
};
o.Content = p;
o.Icon = C;
o.Footer = (e) => /* @__PURE__ */ t(m, { bg: "dialogBoxFooterBg", children: e.children });
o.Title = (e) => /* @__PURE__ */ t("div", { className: "Knu-FlexCol mb-large", children: /* @__PURE__ */ t("span", { className: "Knu-Text text-primary text-title bold", children: e.children }) });
o.Body = (e) => {
  const n = s(l);
  return /* @__PURE__ */ t("div", { className: "Knu-FlexCol", children: /* @__PURE__ */ t(
    "span",
    {
      className: i(
        "Knu-Text text-body1 text-secondary",
        n === "center" && "textAlign-center"
      ),
      children: e.children
    }
  ) });
};
export {
  o as Dialog
};
