import { InfiniteItem, InfiniteScrollViewProps } from './InfiniteScrollViewProps';
import * as React from 'react';
import { last } from '../../../tools';
import { FlexCol, ScrollView } from '@knuddels/component-library';
import { debounce } from '@knuddels-app/tools/debounce';
const INITIAL_RENDER_COUNT = 30;
const ON_DEMAND_INCREASE = 30;
const SCROLL_TO_BOTTOM_THRESHOLD = 50;
type State = {
  renderCount: number;
};
export class InfiniteScrollView<T extends InfiniteItem> extends React.PureComponent<InfiniteScrollViewProps<T>, State> {
  private get mountedScrollContainerDiv(): HTMLDivElement | undefined | null {
    return this.scrollContainer.current;
  }
  handleScrollToBottomState = debounce(() => {
    const isAtBottom = this.isAtBottom();
    if (this.isScrolledToEndState !== isAtBottom) {
      this.isScrolledToEndState = isAtBottom;
      this.props.onScrollToBottomStateChange?.(isAtBottom);
    }
  }, 0);
  private currentScrollBottom: number | undefined = undefined;
  private scrollContainer = React.createRef<HTMLDivElement>();
  private scrollToItemRef = React.createRef<HTMLDivElement>();
  private contentContainerRef = React.createRef<HTMLDivElement>();

  /**
   * This is used to always render new items added to the end of the list. See getItemsToRender for more details.
   */
  private readonly initialLastUniqueKey: string | undefined;
  private isScrolledToEndState = false;
  constructor(props: InfiniteScrollViewProps<T>) {
    super(props);
    const lastItem = last(props.items);
    this.initialLastUniqueKey = lastItem ? lastItem.uniqueKey : undefined;
    let initialCount = INITIAL_RENDER_COUNT;
    if (props.initialScrollToUniqueKey) {
      const reversedItems = [...props.items].reverse();
      const index = reversedItems.findIndex(item => item.uniqueKey === props.initialScrollToUniqueKey);
      if (index > -1 && index > initialCount) {
        initialCount = Math.min(index + ON_DEMAND_INCREASE, props.items.length);
      }
    }
    this.state = {
      renderCount: initialCount
    };
  }
  public get isScrolledToBottom(): boolean {
    return this.isScrolledToEndState;
  }
  componentDidMount(): void {
    // If the initial number of rendered items is not enough to fill the container,
    // immediately render more. Otherwise you can not scroll to the top and will not
    // be able to render more.
    if (this.shouldAutoRenderMore()) {
      this.onEndReached();
    }
    if (this.props.dock !== 'top') {
      this.restoreScrollPosition();
    }
  }
  private calculateScrollBottom(container: HTMLDivElement, scrollTop: number): number {
    return container.scrollHeight - container.getBoundingClientRect().height - scrollTop;
  }
  componentDidUpdate(prevProps: InfiniteScrollViewProps<T>, prevState: State): void {
    if (this.state.renderCount !== prevState.renderCount) {
      this.restoreScrollPosition();
    }
    if (this.isScrolledToEndState && this.props.dock !== 'top') {
      this.scrollToBottom(true);
    }
    const container = this.mountedScrollContainerDiv;
    if (container && prevProps.items.length !== this.props.items.length) {
      if (this.state.renderCount > this.props.items.length) {
        this.setState({
          renderCount: this.props.items.length
        });
      } else if (this.props.items.length > prevProps.items.length) {
        if (this.isScrolledToEnd(container.scrollTop)) {
          this.increaseRenderCount();
        }
        this.handleScrollToBottomState();
      }
    }
    if (prevState.renderCount < this.state.renderCount && this.shouldAutoRenderMore()) {
      this.increaseRenderCount();
    }
  }
  private restoreScrollPosition(): void {
    if (this.currentScrollBottom !== undefined || !this.props.initialScrollToUniqueKey) {
      this.setScrollBottom(this.currentScrollBottom || 0);
    }
  }
  scrollToBottom(smooth?: boolean): void {
    setTimeout(() => {
      this.setScrollBottom(0, smooth);
    });
  }
  private setScrollBottom(value: number, smooth?: boolean): void {
    const container = this.scrollContainer.current;
    if (!container) {
      return;
    }
    const height = container.getBoundingClientRect().height;
    const scrollTop = container.scrollHeight - height - value;
    if (smooth) {
      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
      });
    } else {
      container.scrollTop = scrollTop;
    }
  }
  render(): React.ReactNode {
    const renderedItems = this.getItemsToRender();
    const isRenderingAllItems = renderedItems.length === this.props.items.length;
    return <div onScroll={this.handleScroll} ref={(this.scrollContainer as any)} style={{
      ...(this.props.wrapperStyle || {}),
      overflowX: this.props.disableHorizontalScroll ? 'hidden' : 'visible'
    }} className={_c0}>
				<div ref={this.contentContainerRef} style={({
        ...contentContainerStyles,
        ...(this.props.contentContainerStyle || {}),
        justifyContent: this.props.dock === 'top' ? 'flex-start' : 'flex-start'
      } as any)}>
					{isRenderingAllItems && this.props.header}
					{this.props.dock !== 'top' && <div className={_c1} />}
					{renderedItems.map((item, index) => <div ref={item.uniqueKey === this.props.initialScrollToUniqueKey ? this.scrollToItemRef : undefined} key={item.uniqueKey}>
							<WithMountCallback onMounted={item.uniqueKey === this.props.initialScrollToUniqueKey ? this.handleInitialScroll : index === 0 ? this.handleFirstMounted : undefined}>
								{this.props.renderItem(item, index)}
							</WithMountCallback>
						</div>)}
					{this.props.footer}
				</div>
			</div>;
  }
  private getItemsToRender = (): T[] => {
    if (this.props.dock === 'top') {
      return this.props.items.slice(0, this.state.renderCount);
    }

    // The list was empty at the point of creation. We assume all new items will only be added to the end of list
    // and are rendered instantly which means we can just render all items at any time.
    // WARNING: If the list does in fact contain items, but are not available at mount (e.g. because they are being
    //  fetched from the server), this will result in incorrect behavior.
    if (this.initialLastUniqueKey === undefined) {
      return this.props.items;
    }

    // If we can't find the item whose key matches initialLastUniqueKey, we assume it has been moved out of the set
    // of visible items due to enough new items being added to the end of the list, which means that all items
    // in the list have now been added after mount and can all be rendered.
    const initialLastIndex = this.props.items.findIndex(item => item.uniqueKey === this.initialLastUniqueKey);
    if (initialLastIndex === -1) {
      return this.props.items;
    }

    // We render all items that appear after the item specified through initialLastUniqueKey and renderCount items before
    // said item (including itself).
    // For example:
    //  - items: [1, 2, 3, 4, 5]
    //  - initialLastUniqueKey: 4
    //  - renderCount: 3
    //  => we render [2, 3, 4, 5]
    const reversedIndex = this.props.items.length - initialLastIndex - 1;
    return this.props.items.slice(-this.state.renderCount - reversedIndex);
  };
  private handleFirstMounted = (): void => {
    // We do this here to render more items if the current ones don't fill the scrollable area
    if (this.shouldAutoRenderMore()) {
      this.onEndReached();
    }
  };
  private handleInitialScroll = (): void => {
    setTimeout(() => {
      this.scrollToItemRef.current?.scrollIntoView({
        block: 'center',
        inline: 'center'
      });
    });
  };
  private shouldAutoRenderMore = (): boolean => {
    if (!this.contentContainerRef.current || !this.mountedScrollContainerDiv) {
      return false;
    }
    const contentHeight = this.mountedScrollContainerDiv.scrollHeight;
    const scrollHeight = this.mountedScrollContainerDiv.offsetHeight;
    return contentHeight <= scrollHeight;
  };
  private handleScroll = (e: React.UIEvent) => {
    const container = this.mountedScrollContainerDiv;
    if (container) {
      this.currentScrollBottom = this.calculateScrollBottom(container, container.scrollTop);
      if (this.isScrolledToEnd(e.currentTarget.scrollTop)) {
        this.onEndReached();
      }
      this.handleScrollToBottomState();
    }
  };
  private isScrolledToEnd = (scrollY: number): boolean => {
    const container = this.mountedScrollContainerDiv;
    if (!container) {
      return false;
    }
    if (this.props.dock === 'top') {
      return scrollY === container.scrollHeight - container.offsetHeight;
    } else {
      return scrollY === 0;
    }
  };
  private isAtBottom = (): boolean => {
    const container = this.mountedScrollContainerDiv;
    if (!container) {
      return false;
    }
    return container.scrollTop >= container.scrollHeight - container.offsetHeight - SCROLL_TO_BOTTOM_THRESHOLD;
  };
  private onEndReached = (): void => {
    if (this.state.renderCount < this.props.items.length) {
      this.increaseRenderCount();
    } else if (this.props.onEndReached) {
      this.props.onEndReached();
    }
  };
  private increaseRenderCount = (): void => {
    this.setState(oldState => {
      const newRenderCount = Math.min(oldState.renderCount + ON_DEMAND_INCREASE, this.props.items.length);
      return {
        renderCount: newRenderCount
      };
    });
  };
}
const contentContainerStyles: React.CSSProperties = {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 8,
  paddingRight: 8
};
class WithMountCallback extends React.PureComponent<{
  onMounted?: () => void;
}> {
  componentDidMount(): void {
    if (this.props.onMounted) {
      this.props.onMounted();
    }
  }
  render(): React.ReactNode {
    return this.props.children;
  }
}
const _c0 = " Knu-ScrollView position-absolute inset-none alignSelf-stretch flexGrow-0 flexShrink-0 ";
const _c1 = " Knu-FlexCol flexGrow-1 ";