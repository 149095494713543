import * as React from 'react';
import { useContext } from 'react';
import { createRouter, generateId, History, HistoryInitParams, HistoryObserver, HistoryPushRoute, HistorySubscriptionManager, HistoryUpdate, RouterParamsConfig, RouterType } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import { $ViewService } from '@knuddels-app/layout/serviceIds';
import { autorun } from '@knuddels-app/mobx';
import { PersistedViewState, ViewId, ViewIdWithStateEffect, ViewState } from '@knuddels-app/layout/ViewId';
import { useService } from '@knuddels-app/DependencyInjection';
import { equalUpToDepth } from '@knuddels-app/tools/equalUpToDepth';
import { useThemedNativeBackground } from '@knuddels-app/NativeBackground';
import { InsideOverlayViewContext, VisibleView } from '@knuddels-app/layout/ViewService';
import { $NavBarRegistry } from '@knuddelsModules/LoggedInArea';
type RouterViewStateConstructor<T extends RouterParamsConfig<any>, State extends RouterViewState<T>> = new (stack: StackRouteConfig<T>[], pathItems: string[]) => State;
type InnerRouteConfig<T extends RouterParamsConfig<any>, K extends keyof T> = {
  path: K;
  params: T[K]['params'];
};
export type RouteConfig<T extends RouterParamsConfig<any>> = InnerRouteConfig<T, keyof T>;
export type StackRouteConfig<T extends RouterParamsConfig<any>> = RouteConfig<T> & {
  key: string;
};
export type RouterViewStateData<T extends RouterParamsConfig<any>> = {
  stack: StackRouteConfig<T>[];
  dynamicSlot?: boolean;
};
export const getRouterStateFromPersistedViewState = <T extends RouterParamsConfig<any>, State extends RouterViewState<T>>(ViewStateImpl: RouterViewStateConstructor<T, State>) => {
  return (state: PersistedViewState) => {
    const routerViewState = ((state.state as unknown) as RouterViewStateData<T>);
    return new ViewStateImpl(routerViewState?.stack ?? [], state.pathItems);
  };
};
export class RouterViewState<T extends RouterParamsConfig<any>> implements RouterViewStateData<T>, ViewState {
  static generateId(): string {
    return generateId('RouteViewState');
  }
  constructor(public stack: StackRouteConfig<T>[], public pathItems: string[], public dynamicSlot?: boolean) {}
  withPath(path: keyof T): this {
    return this.withConfig({
      path,
      params: undefined
    });
  }
  withConfig(config: RouteConfig<T>, options = {
    mayReplaceTopOfStack: false
  }): this {
    const topOfStack = this.topOfStack();
    if (!options.mayReplaceTopOfStack && topOfStack && topOfStack.path === config.path && equalUpToDepth(topOfStack.params, config.params)) {
      return this;
    }
    const newStack = options.mayReplaceTopOfStack && topOfStack && topOfStack.path === config.path ? [...this.stack.slice(0, -1), {
      ...config,
      key: RouterViewState.generateId()
    }] : [...this.stack, {
      ...config,
      key: RouterViewState.generateId()
    }];
    return this.withStack(newStack);
  }
  withStack(stack: StackRouteConfig<T>[]): this {
    const routerViewStateClass = this.getClass();
    return new routerViewStateClass(stack, this.pathItems ?? []);
  }

  /**
   * Returns the root route of the stack animating out the routes on top
   * instead of creating a new route on top of the stack.
   */
  root(): this {
    const rootOfStack = this.stack[0];
    if (!rootOfStack) {
      // no root set yet => do nothing
      return this;
    }
    const topOfStack = this.topOfStack();
    if (topOfStack && topOfStack.path === rootOfStack.path) {
      // root is already active
      return this;
    }
    const overviewIndex = this.stack.findIndex(r => r.path === rootOfStack.path);
    if (overviewIndex >= 0) {
      const newStack = this.stack.slice(0, overviewIndex + 1);
      return this.withStack(newStack);
    } else {
      return this.default();
    }
  }
  withOnlyRootViewOpened(): ViewState {
    return this.root();
  }
  default(): this {
    const routerViewStateClass = this.getClass();
    return new routerViewStateClass([], this.pathItems ?? []);
  }
  persist(): PersistedViewState {
    return {
      pathItems: this.getPathItems(),
      state: {
        stack: this.stack
      }
    };
  }
  topOfStack(): StackRouteConfig<T> {
    return this.stack[this.stack.length - 1];
  }
  getStackFromPathItems(): StackRouteConfig<T>[] {
    return this.pathItems?.map(p => this.mapPathItemToRoute(p)).filter(Boolean) ?? [];
  }
  mapPathItemToRoute(pathItem: keyof T): StackRouteConfig<T> {
    return {
      key: RouterViewState.generateId(),
      path: pathItem,
      params: undefined
    };
  }
  getPathItems(): string[] {
    return [(this.topOfStack()?.path as string)].filter(Boolean);
  }
  onOpenByMenu(): ViewState {
    return this.withStack(this.root().stack);
  }
  private getClass(): RouterViewStateConstructor<T, this> {
    return (this.constructor as RouterViewStateConstructor<T, this>);
  }
}
type InitReason = 'MissingStack' | 'MissingRoot' | 'StartedInBackground';
const stackContainsRootView = <T extends RouterParamsConfig<any>,>(stack: StackRouteConfig<T>[], params: HistoryInitParams) => {
  return stack[0]?.path === (params.initialPath ?? params.rootPath);
};
abstract class RouterPlugin<T extends RouterParamsConfig<any>> implements History {
  public readonly dispose = Disposable.fn();
  private initParams!: HistoryInitParams;
  private readonly subscriptionManager = new HistorySubscriptionManager();
  private stack: StackRouteConfig<T>[] = [];
  private initialized = false;
  constructor(protected viewId: ViewId<RouterViewState<T>>, protected viewService: typeof $ViewService.T) {}
  abstract findVisibleView(): VisibleView<RouterViewState<T>>;
  abstract openView(updater: (state: RouterViewState<T>) => RouterViewState<T>, locationUpdateMode?: 'replace' | 'push', keepInBackground?: boolean): void;
  init(params: HistoryInitParams): HistoryUpdate {
    this.initParams = params;
    this.dispose.track(autorun({
      name: 'View Updates'
    }, () => {
      const view = this.findVisibleView();
      if (!view && !this.initialized) {
        this.initialized = true;
        this.stack = [{
          path: params.rootPath,
          params: undefined,
          key: RouterViewState.generateId()
        }];
        this.notifyObservers();
        return;
      }
      if (!view) {
        return;
      }
      const routerState = view.state;
      if (this.stack.length > 0 && routerState.stack?.length === 0) {
        this.initPlugin(params, routerState, 'StartedInBackground');
        return;
      }
      if (routerState.stack?.length === 0) {
        this.initPlugin(params, routerState, 'MissingStack');
        return;
      }
      if (!stackContainsRootView(routerState.stack, params)) {
        this.initPlugin(params, routerState, 'MissingRoot');
        return;
      }
      this.stack = routerState.stack;
      this.notifyObservers();
    }));
    return this.getState();
  }
  getState(): HistoryUpdate {
    return ({
      rootViewVisible: this.topOfStack()?.path === this.initParams.rootPath,
      stack: this.stack
    } as HistoryUpdate);
  }
  pop(): void {
    const latestKey = this.topOfStack().key;
    this.popRouteWithKey(latestKey);
  }
  popRouteWithKey(key: string): void {
    this.openView(s => s.withStack(this.stack.filter(route => route.key !== key)), 'push', true);
  }
  push(route: HistoryPushRoute): void {
    if (this.findVisibleView()?.viewConfig.reuseEqualRoutes) {
      const reuseRoute = this.stack.find(stackRoute => stackRoute.path === route.path);
      if (reuseRoute) {
        this.openView(s => s.withStack([...this.stack.filter(stackRoute => stackRoute.key !== reuseRoute.key), {
          ...reuseRoute,
          params: route.params
        }]));
        return;
      }
    }
    this.openView(s => s.withConfig({
      path: route.path,
      params: route.params
    }));
  }
  subscribe(observer: HistoryObserver): () => void {
    const unsubscribe = this.subscriptionManager.subscribe(observer);
    this.dispose.track(unsubscribe);
    return unsubscribe;
  }
  topOfStack(): StackRouteConfig<T> {
    return this.stack[this.stack.length - 1];
  }
  private initPlugin(params: HistoryInitParams, viewState: RouterViewState<T>, initReason: InitReason): void {
    this.initialized = true;
    const initialStackFromPathItems = viewState.getStackFromPathItems();
    if (initialStackFromPathItems.length > 0 && initReason === 'MissingStack') {
      this.openView(s => s.withStack([{
        path: params.initialPath ?? params.rootPath,
        params: undefined,
        key: RouterViewState.generateId()
      }, {
        path: initialStackFromPathItems[0].path,
        params: initialStackFromPathItems[0].params,
        key: RouterViewState.generateId()
      }]), 'replace');
      return;
    }
    if (initReason === 'MissingStack') {
      this.openView(s => s.withPath(params.rootPath), 'replace');
      return;
    }
    if (initReason === 'StartedInBackground') {
      this.openView(s => s.withStack(this.stack), 'replace');
      return;
    }
    if (initReason === 'MissingRoot') {
      this.openView(s => s.withStack([{
        path: params.initialPath ?? params.rootPath,
        params: undefined,
        key: RouterViewState.generateId()
      }, ...viewState.stack]), 'replace');
    }
  }
  private notifyObservers(): void {
    this.subscriptionManager.notifyObservers(this.getState());
  }
}
export class K3OverlayPlugin extends RouterPlugin<any> {
  findVisibleView(): VisibleView<RouterViewState<any>> {
    return this.viewService.findViewInOverlay(this.viewId);
  }
  openView(updater: (state: RouterViewState<any>) => RouterViewState<any>, locationUpdateMode: 'replace' | 'push' | undefined): void {
    this.viewService.openViewAsOverlay(this.viewId.with(updater), locationUpdateMode ?? 'push');
  }
}
class K3RouterPlugin extends RouterPlugin<any> {
  findVisibleView(): VisibleView<RouterViewState<any>> {
    return this.viewService.findView(this.viewId)?.visibleView;
  }
  openView(updater: (state: RouterViewState<any>) => RouterViewState<any>, locationUpdateMode: 'replace' | 'push' | undefined, keepInBackground = false): void {
    this.viewService.openView(this.viewId.with(updater), {
      locationUpdateMode,
      keepInBackground
    });
  }
}
const usePlugin = (viewId: ViewId<RouterViewState<any>>, insideOverlay: boolean) => {
  const viewService = useService($ViewService);
  const k3RouterPlugin = React.useMemo(() => {
    return insideOverlay ? new K3OverlayPlugin(viewId, viewService) : new K3RouterPlugin(viewId, viewService);
  }, [viewId, viewService, insideOverlay]);

  // dispose on unmount or if the plugin is replaced
  React.useEffect(() => {
    const oldPlugin = k3RouterPlugin;
    return () => {
      oldPlugin.dispose();
    };
  }, [k3RouterPlugin]);
  return k3RouterPlugin;
};
const getOverlay = <TState extends ViewState,>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>): {
  overlay: {
    close(): void;
  };
  state: RouterViewState<any>;
} | null => {
  try {
    const overlay = useContext(InsideOverlayViewContext);
    if (!overlay) {
      return null;
    }
    const viewService = useService($ViewService);
    const state = (viewService.getStateForOverlay(viewIdWithOptionalStateEffect) as any);
    return {
      overlay,
      state
    };
  } catch (e) {
    return null;
  }
};
const withPlugin = <T extends RouterParamsConfig<any>,>(Component: RouterType<T>, viewId: ViewId<RouterViewState<T>>): RouterType<T> => {
  const Router: RouterType<T> = props => {
    const data = getOverlay(viewId);
    useThemedNativeBackground();
    if (globalEnv.product === 'stapp-messenger') {
      return <Component historyPlugin={usePlugin(viewId, !!data)} {...props} />;
    }
    const vs = useService($NavBarRegistry);
    const {
      onClose
    } = props;
    const hideCloseButton = vs.viewInsideBottomNav((viewId as ViewId<any>)) && !data;
    return <Component historyPlugin={usePlugin(viewId, !!data)} {...props} onClose={hideCloseButton ? undefined : data ? () => data.overlay.close() : onClose} />;
  };
  Router.Route = Component.Route;
  return Router;
};
export const createK3Router = <T extends RouterParamsConfig<any>,>(viewId: ViewId<RouterViewState<T>>) => {
  const routerElements = createRouter<T>();
  return {
    ...routerElements,
    Router: withPlugin(routerElements.Router, viewId)
  };
  // tslint:disable-next-line: max-file-line-count
};