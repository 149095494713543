import { LoadService } from '@knuddels-app/ModuleSystem';
import { useRouter } from '@knuddelsModules/Settings/bundle/components/SettingsRouter';
import { $SystemAppService, GenericSystemApp } from '@knuddelsModules/SystemApps';
import * as React from 'react';
import { CONTACT_FILTER_SETTINGS_APP_ID } from '@shared/constants';
export const ContactFilterSettingsApp = () => {
  const {
    pop
  } = useRouter();
  return <LoadService service={$SystemAppService}>
			{() => <GenericSystemApp viewType={'subpath'} onAppClose={() => {
      pop();
    }} appId={CONTACT_FILTER_SETTINGS_APP_ID} />}
		</LoadService>;
};