import * as React from 'react';
import { BlurredBackgroundImageProps } from './BlurredBackgroundImageProps';
import { FlexCol, Image, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
export const BlurredBackgroundImage: React.FC<BlurredBackgroundImageProps> = ({
  source,
  blurRadius = 20,
  borderDistance = -60,
  fallbackSource
}) => {
  return <FlexCol position={'absolute'} inset={(borderDistance as ThemeOverride)} style={({
    filter: `blur(${blurRadius}px)`,
    WebkitFilter: `blur(${blurRadius}px)`,
    /* Fix for Safari performance issue: */
    /* forces the browser use GPU acceleration for that particular element instead of the CPU */
    WebkitBackfaceVisibility: 'hidden',
    MozBackfaceVisibility: 'hidden',
    backfaceVisibility: 'hidden',
    WebkitTransform: 'translate3d(0, 0, 0)',
    MozTransform: 'translate3d(0, 0, 0)',
    transform: 'translate3d(0, 0, 0)'
  } as any)}>
			<Image resizeMode={'cover'} src={source} fallbackSrc={fallbackSource} alt={''} className={_c1} />
		</FlexCol>;
};
const _c0 = " Knu-FlexCol position-absolute ";
const _c1 = " size-full ";