import { UserWithLockInfoFragment } from '@generated/graphql';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { Text } from '@knuddels/component-library';
import { FormattedText, FormattedTextDisplay, Spacer } from '@shared/components';
import * as React from 'react';
import { ErrorScreenWrapper } from './ErrorScreenWrapper';
export const LockInfoScreen = injectedComponent({
  name: 'LockInfoScreen',
  props: declareProps<{
    logout: () => void;
    // Makes the type enforce that the prop only works with the user actually being locked
    userWithLockInfo: Omit<UserWithLockInfoFragment, 'lockInfo'> & {
      lockInfo: Exclude<UserWithLockInfoFragment['lockInfo'], {
        __typename: 'UnlockedLockInfo';
      }>;
    };
  }>()
}, ({
  logout,
  userWithLockInfo
}) => {
  const user = userWithLockInfo;
  return <ErrorScreenWrapper logout={logout}>
				<Text type={'title'} state={'primary'} bold={true} className={_c0}>
					<FormattedMessage id={declareFormat({
        id: 'refresh-session-error.locked.headline',
        defaultFormat: '"{target}" is locked.'
      })} values={{
        target: user.nick
      }} />
				</Text>
				<Spacer size="large" />
				<TimeLockedText dateTimestamp={user.lockInfo.__typename === 'TemporaryLockInfo' ? +user.lockInfo.lockedUntilDate : undefined} />
				<Spacer size="large" />
				<Text type="body1" bold={true} className={_c1}>
					<FormattedMessage id={declareFormat({
        id: 'refresh-session-error.locked.reason',
        defaultFormat: 'Reason: '
      })} />
				</Text>
				<FormattedTextDisplay textProps={{
      type: 'body1'
    }} text={FormattedText.fromJsonString(user.lockInfo.lockReason)} />
				<Spacer size="large" />
				{user.lockInfo.lockedBy?.nick && <>
						<Text type="body1" bold={true} className={_c2}>
							<FormattedMessage id={declareFormat({
          id: 'refresh-session-error.locked.contact',
          defaultFormat: 'Contact: '
        })} />
						</Text>
						<Text type={'body1'} className={_c3}>
							{user.lockInfo.lockedBy?.nick ?? ''}
						</Text>
					</>}
			</ErrorScreenWrapper>;
});
function TimeLockedText({
  dateTimestamp
}: {
  dateTimestamp: number | undefined;
}): JSX.Element {
  return <Text type="body1" className={_c4}>
			<FormattedMessage id={declareFormat({
      id: 'refresh-session-error.locked.nickname-is',
      defaultFormat: 'This nickname is '
    })} />
			{dateTimestamp === undefined ? <Text type="body1" bold={true} className={_c5}>
					<FormattedMessage id={declareFormat({
        id: 'refresh-session-error.locked.permanently-locked',
        defaultFormat: 'permanently locked.'
      })} />
				</Text> : <>
					<Text type="body1" bold={true} className={_c6}>
						<FormattedMessage id={declareFormat({
          id: 'refresh-session-error.locked.temporary.lockeduntil',
          defaultFormat: 'locked until {lockedUntil, date}'
        })} values={{
          lockedUntil: dateTimestamp
        }} />
					</Text>
					<FormattedMessage id={declareFormat({
        id: 'refresh-session-error.locked.temporarily.unlock-next-day',
        defaultFormat: ' and will be unlocked automatically during the following day.'
      })} />
				</>}
		</Text>;
}
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = "  ";
const _c6 = "  ";