import * as React from 'react';
import { Spacer } from '@shared/components';
import { Flex, FlexCol, FlexProps, Switch, Text, useId } from '@knuddels/component-library';
export const SWITCH_SETTING_HEIGHT = 60;
interface SwitchSettingProps {
  iconComponent?: React.ReactNode;
  title: string;
  subTitle?: string;
  checked: boolean;
  onChange(checked: boolean): void;
}
export const SwitchSetting: React.FC<SwitchSettingProps> = ({
  iconComponent,
  title,
  checked,
  subTitle,
  onChange
}) => {
  const {
    id,
    composeId
  } = useId('switch-settings');
  const descriptorId = composeId('description');
  return <Flex {...switchSettingWrapperStyle}>
			{iconComponent}

			<Spacer size={'base'} />
			<div className={_c0}>
				<Text type={'subtitle'} numberOfLines={1} bold id={id}>
					{title}
				</Text>
				{subTitle && <Text id={descriptorId.id} type="tiny" state="tertiary" numberOfLines={1}>
						{subTitle}
					</Text>}
			</div>
			<Switch ariaLabelledBy={id} ariaDescribedBy={descriptorId.id} value={checked} onChange={value => onChange(value.value)} />
		</Flex>;
};
const switchSettingWrapperStyle: FlexProps = {
  flex: 1,
  pl: 'small',
  pr: 'large',
  height: SWITCH_SETTING_HEIGHT,
  alignItems: 'center'
};
const _c0 = " Knu-FlexCol flex-1 ";