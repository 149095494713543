import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { Flex, FlexCol, createAccessiblePointerEventHandler, Text, ThemeOverride, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger/providedServices';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { Container, NotificationBadge } from '@shared/components';
import clsx from 'clsx';
import * as React from 'react';
import styles from './Indicator.module.scss';
import headStyles from './ChatHead.module.scss';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
export const MiniChatHead: React.FC<{
  first: boolean;
  last: boolean;
  conversationId: string;
  userId: string;
  nick: string;
  unreadMessageCount: number;
  active: boolean;
  onClick?: () => void;
  innerRef?: React.LegacyRef<HTMLDivElement | null>;
}> = observer('MiniChatHead', ({
  first,
  last,
  conversationId,
  unreadMessageCount,
  userId,
  nick,
  active,
  onClick,
  innerRef
}) => {
  const messengerMiniChatService = useService($MessengerMiniChatService);
  return <div ref={innerRef} style={{
    scrollSnapAlign: 'start',
    marginLeft: first ? 16 : 0,
    marginRight: last ? 16 : 0,
    scrollMarginLeft: 16,
    maxWidth: UserImageType.MINI_CHAT_HEAD
  }}>
				<div className={_c0}>
					<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: () => {
          onClick?.();
          messengerMiniChatService.handleChatHeadClick(conversationId);
        }
      })} className={_c1 + " " + headStyles.ChatHead + " "}>
						<div className={_c2 + ("black-solid-black" ? resolveIsDarkColor("black-solid-black", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<div className={_c3 + (active ? _c4 : _c5)}>
								<UserImage type={UserImageType.MINI_CHAT_HEAD} uid={userId} highlight="disabled" />
							</div>
							{unreadMessageCount > 0 && <div className={_c6}>
									<NotificationBadge number={unreadMessageCount} />
								</div>}
						</div>
						<Text type={'tiny'} state={((active ? 'primary' : 'tertiary') as TextStates)} numberOfLines={1} className={_c7}>
							{nick}
						</Text>
					</div>
					<ActiveChatIndicator active={active} />
				</div>
			</div>;
});
const ActiveChatIndicator: React.FC<{
  active: boolean;
}> = ({
  active
}) => {
  return <Container className={clsx(styles.indicator, {
    [styles.active]: active
  })} />;
};
const _c0 = " Knu-FlexCol placeItems-center gap-small ";
const _c1 = " Knu-FlexCol cursor-pointer ";
const _c2 = " Knu-Flex shadow-Shadow4 borderRadius-minor bg-black-solid-black position-relative ";
const _c3 = " Knu-Flex ";
const _c4 = " opacity-1 ";
const _c5 = " opacity-0-5 ";
const _c6 = " Knu-Flex position-absolute right-none top-none shadow-Shadow1 borderRadius-tiny ";
const _c7 = " textAlign-center mt-2px ";