import * as React from 'react';
import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $PushNotificationsService } from '../../providedServices';
import { BannerBox, Spacer } from '@shared/components';
import { Flex, FlexCol, Smiley, Text } from '@knuddels/component-library';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
export interface TrackingCallbacks {
  trackShown(): void;
  trackActiveClicked(): void;
  trackNotNowClicked(): void;
  trackRequestGranted(): void;
  trackRequestDenied(): void;
}
function noop(): void {}
const trackingNoops: TrackingCallbacks = {
  trackShown: noop,
  trackActiveClicked: noop,
  trackNotNowClicked: noop,
  trackRequestGranted: noop,
  trackRequestDenied: noop
};
@injectable()
class NotificationConfirmBoxModel implements IModel {
  private get tracking(): TrackingCallbacks {
    return this.props.tracking ?? trackingNoops;
  }
  constructor(@injectProps()
  private readonly props: {
    tracking?: TrackingCallbacks;
  }, @inject($PushNotificationsService)
  private readonly pushNotificationsService: typeof $PushNotificationsService.T) {}
  componentDidMount(): void {
    this.tracking.trackShown();
  }
  public dontAskThisSession = (): void => {
    this.tracking.trackNotNowClicked();
    this.pushNotificationsService.dontAskThisSession();
  };
  public activateNotifications = (): void => {
    this.tracking.trackActiveClicked();
    this.pushNotificationsService.requestPermission().then(value => {
      if (value === 'granted') {
        this.tracking.trackRequestGranted();
      } else if (value === 'denied') {
        this.tracking.trackRequestDenied();
      }
    });
  };
}
export const NotificationConfirmBox = injectedComponent({
  name: 'NotificationConfirmBox',
  model: NotificationConfirmBoxModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => <div className={_c0}>
			<div className={_c1}>
				<BannerBox primaryButton={{
      text: i18n.format(declareFormat({
        id: 'notifications.box.button.accept',
        defaultFormat: 'Activate'
      })),
      onClick: model.activateNotifications
    }} secondaryButton={{
      text: i18n.format(declareFormat({
        id: 'notifications.box.button.later',
        defaultFormat: 'Maybe later'
      })),
      onClick: model.dontAskThisSession
    }}>
					<div className={_c2}>
						<Smiley src={require('./sm_abo_12-05_rrring.gif')} width={'44px'} height={'38px'} />
						<Spacer size={'base'} />
						<div className={_c3}>
							<div className={_c4}>
								<Text type={'subtitle'} state={'primary'} className={_c5}>
									{i18n.format(declareFormat({
                id: 'notifications.box.title',
                defaultFormat: 'Enable notifications'
              }))}
								</Text>
							</div>
							<Spacer size={'tiny'} />
							<div className={_c6}>
								<Text type={'body1'} state={'secondary'} className={_c7}>
									{i18n.format(declareFormat({
                id: 'notifications.box.subtitle',
                defaultFormat: 'Activate and never miss a private message again.'
              }))}
								</Text>
							</div>
						</div>
					</div>
				</BannerBox>
			</div>
		</div>);
const _c0 = " Knu-FlexCol width-full ";
const _c1 = " Knu-FlexCol pt-base pb-minor px-minor alignSelf-center width-344px ";
const _c2 = " Knu-Flex ";
const _c3 = " Knu-FlexCol flex-1 ";
const _c4 = " Knu-FlexCol ";
const _c5 = "  ";
const _c6 = " Knu-FlexCol ";
const _c7 = "  ";