import { addUniqueItem as e, removeItem as u } from "./array.js";
class h {
  constructor() {
    this.subscriptions = [];
  }
  add(s) {
    return e(this.subscriptions, s), () => u(this.subscriptions, s);
  }
  notify(s, r, n) {
    const i = this.subscriptions.length;
    if (i)
      if (i === 1)
        this.subscriptions[0](s, r, n);
      else
        for (let t = 0; t < i; t++) {
          const o = this.subscriptions[t];
          o && o(s, r, n);
        }
  }
  getSize() {
    return this.subscriptions.length;
  }
  clear() {
    this.subscriptions.length = 0;
  }
}
export {
  h as SubscriptionManager
};
