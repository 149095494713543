import * as React from 'react';
import { Flex, FlexCol, Text, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useQuery } from '@knuddels-app/Connection';
import { GetPreviousSearches } from '@generated/graphql';
import { CategoryHeader } from '../CategoryHeader';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { OS, os } from '@shared/components/tools/os';
export const RecentSearches: React.FC<{
  setSearchText: (searchText: string) => void;
}> = ({
  setSearchText
}) => {
  const i18n = useService($I18n);
  const {
    data: recentSearches
  } = useQuery(GetPreviousSearches, {}, 'no-cache');
  if (!recentSearches?.length) {
    return null;
  }
  return <div className={_c0}>
			<CategoryHeader title={i18n.format(declareFormat({
      id: 'channellist.search.recentSearchResults',
      defaultFormat: 'Your recent search results'
    }))} />

			<div className={_c1}>
				{recentSearches.map((searchText, index) => <RecentSearchResultsItem key={`recent-search-${index}`} searchText={searchText} lastItem={index === 0} onPress={() => setSearchText(searchText)} />)}
			</div>
		</div>;
};
const styles = os === OS.web ? {
  overflowWrap: 'anywhere'
} : {};
const RecentSearchResultsItem: React.FC<{
  searchText: string;
  lastItem: boolean;
  onPress: () => void;
}> = ({
  searchText,
  lastItem,
  onPress
}) => <div onClick={toPointerHandler(onPress)} className={_c2 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
		<div style={{
    borderBottomWidth: lastItem ? 0 : 1,
    borderStyle: resolveThemingValue(lastItem ? undefined : 'solid', "theme", useTheme())
  }} className={_c3}>
			<Text type="subtitle" numberOfLines={1}
    // @ts-expect-error Not exposed
    style={{
      ...styles
    }} className={_c4}>
				{searchText}
			</Text>
		</div>
	</div>;
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex position-relative mx-base overflow-hidden borderWidth-base borderStyle-solid borderColor-lineDivider borderRadius-xlarge flexDirection-column-reverse ";
const _c2 = " Knu-Flex px-base bg-contentLightBg hoverBg-listHoverAtom cursor-pointer ";
const _c3 = " Knu-Flex flex-1 pt-base pb-small borderWidth-none borderColor-lineDivider ";
const _c4 = "  ";