import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { PaymentSuccessModal } from '../components/PaymentSuccessModal/PaymentSuccessModal';
import * as React from 'react';
import { $OverlayService } from '@knuddels-app/overlays';
@injectable()
export class PaymentSuccessDisplay {
  constructor(@inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {}
  display(): void {
    this.overlayService.showOverlay({
      view: <PaymentSuccessModal />
    });
  }
}