import {
	ClientPlatform,
	ClientType,
	OsType,
	SessionInfo,
} from '@generated/graphql';
import { ClientInfoInterface } from './ClientInfo.interface';
import { getClientState } from '@knuddels-app/tools/getClientState';
import { getDeviceInfo } from '@shared/helper/getDeviceInfo';
import { Deferred } from '@knuddels/std';

const platformMap: {
	[key in ClientInfoInterface['platform']]: SessionInfo['platform'];
} = {
	android: ClientPlatform.Native,
	ios: ClientPlatform.Native,
	macos: ClientPlatform.Native,
	windows: ClientPlatform.Native,
	web:
		globalEnv.product === 'stapp-messenger'
			? ClientPlatform.Embedded
			: ClientPlatform.Web,
};

type DeferredType<T extends Deferred<any>> =
	T extends Deferred<infer U> ? U : never;

const osTypeMap: {
	[key in DeferredType<
		ClientInfoInterface['osInfo']
	>['type']]: SessionInfo['osInfo']['type'];
} = {
	unknown: OsType.Unknown,
	android: OsType.Android,
	ios: OsType.Ios,
	macos: OsType.MacOs,
	windows: OsType.Windows,
	linux: OsType.Linux,
};

export async function createGraphQLSessionInfo(
	clientInfo: ClientInfoInterface
): Promise<SessionInfo> {
	const deviceInfo = await getDeviceInfo();
	const osInfo = clientInfo.osInfo.value ?? (await clientInfo.osInfo.promise);

	return {
		type: ClientType.K3GraphQl,
		clientVersion: {
			major: clientInfo.version.major,
			minor: clientInfo.version.minor,
			patch: clientInfo.version.patch,
			preReleaseVersion: clientInfo.version.preReleaseVersion,
			buildInfo: clientInfo.version.gitRevision,
		},
		platform: platformMap[clientInfo.platform],
		osInfo: {
			type: osTypeMap[osInfo.type],
			version: osInfo.version,
		},
		deviceInfo: {
			manufacturer: deviceInfo.manufacturer,
			model: deviceInfo.model,
		},
		deviceIdentifiers: clientInfo.deviceIdentifiers,
		clientState: await getClientState(),
	};
}
