import { Gender } from '@generated/graphql';
import { Flex, FlexCol, Image, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { declareFormat, formatMessage, FormattedMessage } from '@knuddels-app/i18n';
import * as React from 'react';
import { ReactNode } from 'react';
import { CommunicationRequestBox } from './CommunicationRequestBox';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
interface UserInfoItem {
  imgSrc: string;
  content: ReactNode;
}
const infoNotAvailable = <FormattedMessage id={declareFormat({
  id: 'newContact.mentorsystem.unknown',
  defaultFormat: 'unknown'
})} />;
export function CommunicationRequestBoxInfo({
  model
}: typeof CommunicationRequestBox.TPropsWithModel): JSX.Element {
  if (!model.user) {
    return null;
  }
  const {
    nick,
    age,
    distance,
    city,
    gender = Gender.Unknown,
    onlineMinutes,
    latestClient,
    interest
  } = model.user;
  const userInfoItems: ReadonlyArray<UserInfoItem> = [{
    imgSrc: require('@shared/images/profile-entry-hobbies.png'),
    content: <>
					<Text type={'body2'} bold={true} className={_c0}>
						<FormattedMessage id={declareFormat({
          id: 'newContact.mentorsystem.interest',
          defaultFormat: 'Interest:'
        })} />
					</Text>
					<Text type={'body2'} numberOfLines={1} className={_c1}>
						{interest || infoNotAvailable}
					</Text>
				</>
  }, {
    imgSrc: require('@shared/images/profile-entry-last-channel.png'),
    content: <>
					<Text type={'body2'} bold={true} className={_c2}>
						<FormattedMessage id={declareFormat({
          id: 'newContact.mentorsystem.latestClient',
          defaultFormat: 'Client:'
        })} />
					</Text>
					<Text type={'body2'} numberOfLines={1} className={_c3}>
						{latestClient || infoNotAvailable}
					</Text>
				</>
  }];
  return <div className={_c4}>
			<Text type="body1" state="secondary" className={_c5}>
				{nick}
				<AgeAndGender gender={gender} age={age} />
				{!!city && <City city={city} />}
				<Distance distance={distance} gender={gender} />
				<OnlineMinutes onlineMinutes={onlineMinutes} />
			</Text>

			{model.hasMenteeStatus && <UserInfoBlock items={userInfoItems} />}
		</div>;
}
const AgeAndGender: React.FC<{
  gender: Gender;
  age?: number;
}> = props => {
  if (props.gender === Gender.Unknown && !props.age) {
    return null;
  }
  return <>
			{' ('}
			{props.gender !== Gender.Unknown && <GenderIcon gender={props.gender} />}
			{!!props.age && '' + props.age}
			{')'}
		</>;
};
const OnlineMinutes: React.FC<{
  onlineMinutes: number;
}> = props => {
  const minutesBefore = formatMessage(declareFormat({
    id: 'NEW_CONTACT_ONLINE_MINUTES_BEFORE',
    defaultFormat: 'has been online for'
  }));
  const minutes = formatMessage(declareFormat({
    id: 'NEW_CONTACT_ONLINE_MINUTES',
    defaultFormat: ' {minutes, number} mins'
  }), {
    minutes: props.onlineMinutes
  });
  const minutesAfter = formatMessage(declareFormat({
    id: 'NEW_CONTACT_ONLINE_MINUTES_AFTER',
    defaultFormat: '.'
  }));
  return <>
			{' ' + minutesBefore}
			<Text bold={true} state={'primary'} className={_c6}>
				{minutes}
			</Text>
			{minutesAfter}
		</>;
};
const City: React.FC<{
  city: string;
}> = props => {
  const cityBefore = formatMessage(declareFormat({
    id: 'messenger.requestbox.city-from',
    defaultFormat: ' from '
  }));
  return <>
			{cityBefore}
			<Text bold={true} state={'primary'} className={_c7}>
				{props.city}
			</Text>
		</>;
};
const Distance: React.FC<{
  distance: number;
  gender: Gender;
}> = props => {
  if (props.distance === null || props.distance < 0) {
    return null;
  }
  const distanceBefore = formatMessage(declareFormat({
    id: 'NEW_CONTACT_DISTANCE_BEFORE',
    defaultFormat: ' is'
  }));
  const distanceText = formatMessage(declareFormat({
    id: 'NEW_CONTACT_DISTANCE',
    defaultFormat: ' {distance, number} km '
  }), {
    distance: props.distance
  });
  const distanceAfter = formatMessage(declareFormat({
    id: 'NEW_CONTACT_DISTANCE_AFTER',
    defaultFormat: 'away from from you.'
  }));
  const genderName = props.gender === Gender.Female || props.gender === Gender.NonbinaryShe ? 'female' : 'male';
  const pronoun = formatMessage(declareFormat({
    id: 'NEW_CONTACT_ONLINE_MINUTES_PRONOUN',
    defaultFormat: '{gender, select, male {He} female {She} other {He}}'
  }), {
    gender: genderName
  });
  return <>
			{distanceBefore}
			<Text bold={true} state={'primary'} className={_c8}>
				{distanceText}
			</Text>
			{distanceAfter}
			{' ' + pronoun}
		</>;
};
const UserInfoBlock: React.FC<{
  items: ReadonlyArray<UserInfoItem>;
}> = ({
  items
}) => {
  return <div className={_c9}>
			{items && items.map((item, index) => <UserInfo key={`userInfo-${index}`} item={item} isLastItem={index === items.length - 1} />)}
		</div>;
};
const UserInfo: React.FC<{
  item: UserInfoItem;
  isLastItem?: boolean;
}> = ({
  item,
  isLastItem = false
}) => {
  return <div className={_c10 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isLastItem ? _c11 : _c12)}>
			<Image src={item.imgSrc} alt={''} className={_c13} />
			<div className={_c14}>{item.content}</div>
		</div>;
};
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol ";
const _c5 = "  ";
const _c6 = "  ";
const _c7 = "  ";
const _c8 = "  ";
const _c9 = " Knu-Flex placeItems-center mt-large ";
const _c10 = " Knu-Flex flex-1 alignItems-center borderRadius-base height-100-percent p-minor bg-contentBg ";
const _c11 = " mr-none ";
const _c12 = " mr-minor ";
const _c13 = " width-32px height-32px mr-minor ";
const _c14 = " Knu-FlexCol flex-1 ";