import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { SendSlashCommand } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import {
	$I18n,
	$MessageFormatProvider,
	declareFormat,
} from '@knuddels-app/i18n';
import { $CommandService } from './serviceIds';

import de from './i18n/formats.de.json';
import en from './i18n/formats.en.json';

@injectable()
export class CommandWithoutChannelService {
	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject.lazy($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.TLazy,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
	}

	async invokeCommand(
		command: string,
		parameter = '',
		options?: {
			disableClientSideCommandHandling?: boolean;
		}
	): Promise<(typeof SendSlashCommand)['TPrimaryResult']['__typename']> {
		const commandCall = {
			commandName: command,
			parameter,
		};

		if (
			!options?.disableClientSideCommandHandling &&
			this.commandService.shouldInvoke(commandCall)
		) {
			await this.commandService.invokeCommand(commandCall);
			return 'SendSlashCommandSuccess';
		}

		this.commandService.invokeListeners(commandCall);

		const authenticatedClientService =
			await this.authenticatedClientService();
		const prefix = command.startsWith('/') ? '' : '/';
		const result =
			await authenticatedClientService.currentK3Client.mutateWithResultPromise(
				SendSlashCommand,
				{
					event: {
						command: `${prefix}${command} ${parameter}`.trim(),
					},
				}
			);

		const typename = result.unwrap().__typename;
		switch (typename) {
			case 'SendSlashCommandSuccess':
				break; // everything fine :)
			case 'SendSlashCommandNotFoundError':
				this.snackbarService.showGenericError(
					this.i18n.format(
						declareFormat({
							id: 'commands.without-channel.not-found',
							defaultFormat:
								'Unfortunately, the command is not yet available.',
						})
					)
				);
				break;
			case 'SendSlashCommandNotSupportedError':
				this.snackbarService.showGenericError(
					this.i18n.format(
						declareFormat({
							id: 'commands.without-channel.not-supported',
							defaultFormat:
								'You must be connected to a channel to execute the command.',
						})
					)
				);
				break;
			case 'SendSlashCommandInternalError':
			default:
				this.snackbarService.showGenericError();
		}
		return typename;
	}
}
