import * as React from 'react';
import { FormattedText, PreviewFormattedTextDisplay } from '@shared/components';
import { Flex, FlexCol, TextProps, ThemeOverride } from '@knuddels/component-library';
export const EntrySubtitle = React.memo(EntrySubtitleBase);
function EntrySubtitleBase(props: {
  text: FormattedText | JSX.Element;
  viewPrefix?: React.ReactElement | false;
  textPrefix?: React.ReactElement | false;
  isStAppMessengerOverview: boolean;
  textState: TextProps['state'];
}): React.ReactElement {
  return <div
  // cut to two lines
  className={_c0 + (props.isStAppMessengerOverview ? _c1 : _c2)}>
			{props.viewPrefix}
			<div className={_c3 + (props.isStAppMessengerOverview ? _c4 : _c5)}>
				{FormattedText.isFormattedText(props.text) ? <PreviewFormattedTextDisplay text={props.text} textProps={{
        type: props.isStAppMessengerOverview ? 'tiny' : 'body1',
        state: props.textState,
        selectable: false
      }} prefix={props.textPrefix} /> : props.text}
			</div>
		</div>;
}
const _c0 = " Knu-Flex overflow-hidden position-relative ";
const _c1 = " maxHeight-32px ";
const _c2 = " maxHeight-40px ";
const _c3 = " Knu-FlexCol flex-1 position-relative overflow-hidden ";
const _c4 = " mr-20px ";
const _c5 = " mr-30px ";