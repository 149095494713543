import { complex as c } from "./index.js";
import { floatRegex as i } from "../utils.js";
const s = /* @__PURE__ */ new Set(["brightness", "contrast", "saturate", "opacity"]);
function u(t) {
  const [e, n] = t.slice(0, -1).split("(");
  if (e === "drop-shadow")
    return t;
  const [o] = n.match(i) || [];
  if (!o)
    return t;
  const r = n.replace(o, "");
  let a = s.has(e) ? 1 : 0;
  return o !== n && (a *= 100), e + "(" + a + r + ")";
}
const l = /\b([a-z-]*)\(.*?\)/gu, p = {
  ...c,
  getAnimatableNone: (t) => {
    const e = t.match(l);
    return e ? e.map(u).join(" ") : t;
  }
};
export {
  p as filter
};
