import * as React from 'react';
import { Spacer, Z_INDEX } from '@shared/components';
import { Flex, IconBack, IconMenu, Image, resolveThemingValue, useTheme } from '@knuddels/component-library';
interface NavBarHeaderProps {
  collapsed?: boolean;
  onToggleCollapsed: (collapsed: boolean) => void;
}
import knuddelsBrand from '@shared/images/logo-knuddels-plain.png';
export const NavBarHeader: React.FC<NavBarHeaderProps> = ({
  collapsed,
  onToggleCollapsed
}) => {
  const handleOnClick = React.useCallback(() => {
    onToggleCollapsed(!collapsed);
  }, [collapsed]);
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
  }} className={_c0}>
			<div className={_c1}>
				{collapsed ? <IconMenu size={'large'} onPress={handleOnClick} /> : <IconBack size={'large'} onPress={handleOnClick} />}

				{!collapsed && <React.Fragment>
						<Spacer size={'base'} style={{
          minHeight: 1
        }} />
						<Image src={knuddelsBrand} alt={'Knuddels Brand'} className={_c2} />
					</React.Fragment>}
			</div>
		</div>;
};
const _c0 = " Knu-Flex p-tiny ";
const _c1 = " Knu-Flex alignItems-center ";
const _c2 = " width-100px height-24px ";