const queue: (() => Promise<unknown>)[] = [];
let isRunning = false;

const TIMEOUT_BETWEEN_PROMISES = 500;

/**
 * Queues a callback that returns a Promise to be executed.
 * If the queue is empty, the callback is executed immediately.
 * Once a callback finishes, the next callback in the queue is started.
 * Returns a Promise that resolves with the result of the finishPromise.
 *
 * @param {() => Promise<unknown>} finishPromise - The callback function that returns a Promise.
 * @returns {Promise<unknown>} - A Promise that resolves with the result of the finishPromise.
 */
export function queueNativeDialog<T>(
	finishPromise: () => Promise<T>
): Promise<T> {
	return new Promise((resolve, reject) => {
		queue.push(async () => {
			try {
				const result = await finishPromise();
				resolve(result);
			} catch (error) {
				reject(error);
			}
		});

		if (!isRunning) {
			isRunning = true;
			runQueue();
		}
	});
}

async function runQueue(): Promise<void> {
	if (queue.length === 0) {
		isRunning = false;
		return;
	}

	const finishPromise = queue.shift();
	if (finishPromise) {
		try {
			await finishPromise();
		} finally {
			setTimeout(() => runQueue(), TIMEOUT_BETWEEN_PROMISES);
		}
	}
}
