export function insertSeparators<T, G>(
	array: T[],
	elementFactory: (index: number) => G
): (T | G)[] {
	return array.reduce(
		(acc, curVal, idx) =>
			idx !== 0 ? acc.concat(elementFactory(idx), curVal) : [curVal],
		[] as (T | G)[]
	);
}
