import type * as firebaseAppType from 'firebase/app';
import type { FirebaseApp, FirebaseOptions } from 'firebase/app';
import * as firebaseAppImport from 'firebase/app';
import type * as firebaseMessagingType from 'firebase/messaging';
import * as firebaseMessaging from 'firebase/messaging';
import type * as firebaseAnalyticsType from 'firebase/analytics';
import * as firebaseAnalytics from 'firebase/analytics';
import { Disposable } from '@knuddels/std';
import {
	K3FirebaseInterface,
	K3FirebaseMessagingInterface,
	MessagingPermission,
	NotificationData,
} from './K3FirebaseInterface';
import { injectable } from '@knuddels-app/DependencyInjection';
import * as noops from './noops';

const firebaseConfig: FirebaseOptions =
	globalEnv.product === 'stapp-messenger'
		? {
				apiKey: 'AIzaSyCF5oJz_61xdeWbXWLibzniUgxuw48snAo',
				authDomain: 'android-react-8e0eb.firebaseapp.com',
				databaseURL: 'https://android-react-8e0eb.firebaseio.com',
				projectId: 'android-react-8e0eb',
				storageBucket: 'android-react-8e0eb.appspot.com',
				messagingSenderId: '728124511052',
				appId: '1:728124511052:web:35873a0d8a915f5310b5e9',
				measurementId: 'G-SM10S2WKYE',
			}
		: {
				// K3-web
				apiKey: 'AIzaSyCF5oJz_61xdeWbXWLibzniUgxuw48snAo',
				authDomain: 'android-react-8e0eb.firebaseapp.com',
				databaseURL: 'https://android-react-8e0eb.firebaseio.com',
				projectId: 'android-react-8e0eb',
				storageBucket: 'android-react-8e0eb.appspot.com',
				messagingSenderId: '728124511052',
				appId: '1:728124511052:web:4eb2fb4a0259b11110b5e9',
			};

@injectable()
export class K3Firebase implements K3FirebaseInterface {
	public readonly dispose = Disposable.fn();
	private readonly app: FirebaseApp | undefined;
	private _messaging: K3FirebaseInterface['messaging'] | undefined;
	private _analytics: K3FirebaseInterface['analytics'] | undefined;
	private readonly _perf: K3FirebaseInterface['perf'];

	constructor() {
		if (import.meta.env.MODE === 'test') {
			// don't init in test environment
			this._perf = noops.noopPerf;
			this._analytics = noops.noopAnalytics;
			return;
		}

		const firebaseApp: typeof firebaseAppType = firebaseAppImport;
		const app = firebaseApp.initializeApp(firebaseConfig);
		this.app = app;

		/*
			IMPORTANT!
			Performance tracing is currently disabled because it blocks the main thread and slows down
			the application, especially animations and if we have lots of http requests like in the smileybox.

			If we need this we should evaluate the possibility to move this in a worker thread.

		 */
		// init performance tracking.
		// should be started immediately to automatically trace events like pageloads and input delays.
		// require('firebase/performance').initializePerformance(app);

		// const perf = app.performance();
		this._perf = {
			startTrace: async () => {
				// const trace = perf.trace(traceName);
				// trace.start();
				return {
					stop: async () => {
						// trace.stop();
					},
				};
			},
		};

		// delete/cleanup firebase app instance if service is disposed (basically on logout)
		// or else it will throw error if initializeApp is called multiple times
		this.dispose.track(() => firebaseApp.deleteApp(app));
	}

	/**
	 * lazy initializes on first call.
	 */
	get messaging(): K3FirebaseInterface['messaging'] {
		if (import.meta.env.MODE === 'test' || !this.app) {
			return Promise.resolve(undefined);
		}
		if (!this._messaging) {
			const messaging: typeof firebaseMessagingType = firebaseMessaging;
			this._messaging = messaging
				.isSupported()
				.then((isSupported: boolean) => {
					if (isSupported) {
						return new FirebaseMessaging(this.app!);
					}
					return undefined;
				});
		}
		return this._messaging;
	}

	/**
	 * lazy initializes on first call.
	 */
	get analytics(): K3FirebaseInterface['analytics'] {
		if (!this._analytics) {
			const analytics: typeof firebaseAnalyticsType = firebaseAnalytics;
			const analyticsInstance = analytics.initializeAnalytics(this.app!);
			this._analytics = {
				setAnalyticsCollectionEnabled: enabled =>
					analytics.setAnalyticsCollectionEnabled(
						analyticsInstance,
						enabled
					),
				setUserId: id =>
					id && analytics.setUserId(analyticsInstance, id),
				setUserProperties: properties =>
					analytics.setUserProperties(analyticsInstance, properties),
				logEvent: (name, params) =>
					analytics.logEvent(analyticsInstance, name, params),
				logLogin: params =>
					analytics.logEvent(analyticsInstance, 'login', params),
				logSignUp: params =>
					analytics.logEvent(analyticsInstance, 'sign_up', params),
				logPurchase: params =>
					analytics.logEvent(
						analyticsInstance,
						'purchase' as any /*fixes weird typescript overload issues :(*/,
						params
					),
				logEarnVirtualCurrency: params =>
					analytics.logEvent(
						analyticsInstance,
						'earn_virtual_currency',
						params
					),
				logSelectContent: params =>
					analytics.logEvent(
						analyticsInstance,
						'select_content',
						params
					),
			};
		}
		return this._analytics;
	}

	get perf(): K3FirebaseInterface['perf'] {
		return this._perf;
	}
}

class FirebaseMessaging implements K3FirebaseMessagingInterface {
	private readonly messaging: typeof firebaseMessagingType;
	private readonly messagingInstance: ReturnType<
		(typeof firebaseMessagingType)['getMessaging']
	>;

	constructor(private readonly firebaseApp: FirebaseApp) {
		this.messaging = firebaseMessaging;
		this.messagingInstance = this.messaging.getMessaging(this.firebaseApp);
	}

	public getPermission(): Promise<MessagingPermission> {
		return Promise.resolve(
			Notification.permission === 'default'
				? 'undecided'
				: Notification.permission
		);
	}

	public requestPermission(): Promise<MessagingPermission> {
		return Notification.requestPermission().then(() => {
			return this.getPermission();
		});
	}

	public getToken(): Promise<string | null> {
		return this.messaging.getToken(this.messagingInstance, {
			vapidKey:
				'BErIeJeBuK9DYq_PrDTtJKBTXMMoZ2mYRrxGNVAppkH3yyux9DIsRJjcqLW15C4bmUPJdyFdGMnR_c7f7C6VBSY',
		});
	}

	public onTokenRefresh(): Disposable {
		// noop for web as this is not part of their web api anymore
		// see: https://github.com/firebase/firebase-js-sdk/issues/4132
		return Disposable.create();
	}

	public onNotificationOpened(
		callback: (message: NotificationData) => void
	): Disposable {
		return Disposable.create(
			this.messaging.onMessage(this.messagingInstance, callback)
		);
	}

	public async clearNotifications(): Promise<void> {
		// Firefox only
		if (typeof (window as any).notifications !== 'undefined') {
			(window as any).notifications.clear();
		}
	}
}
