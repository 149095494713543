function g(i, t, o) {
  let n = "";
  const s = i.x.translate / t.x, e = i.y.translate / t.y, $ = (o == null ? void 0 : o.z) || 0;
  if ((s || e || $) && (n = `translate3d(${s}px, ${e}px, ${$}px) `), (t.x !== 1 || t.y !== 1) && (n += `scale(${1 / t.x}, ${1 / t.y}) `), o) {
    const { transformPerspective: a, rotate: c, rotateX: r, rotateY: p, skewX: y, skewY: d } = o;
    a && (n = `perspective(${a}px) ${n}`), c && (n += `rotate(${c}deg) `), r && (n += `rotateX(${r}deg) `), p && (n += `rotateY(${p}deg) `), y && (n += `skewX(${y}deg) `), d && (n += `skewY(${d}deg) `);
  }
  const x = i.x.scale * t.x, f = i.y.scale * t.y;
  return (x !== 1 || f !== 1) && (n += `scale(${x}, ${f})`), n || "none";
}
export {
  g as buildProjectionTransform
};
