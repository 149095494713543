import {
	inject,
	injectable,
	K3ServiceBinder,
	newServiceId,
} from '../DependencyInjection';
import { observable } from 'mobx';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '../AppLoadingService';
import { $UserService } from '@knuddelsModules/UserData';
import { $AppService } from '@knuddelsModules/Apps';
import { deepCopy, DeepPartial } from './deepCopy';
import { Disposable } from '@knuddels/std';
import { debounce } from '../tools/debounce';
import { runInAction } from '../mobx';
import { $AuthenticatedClientService } from '../Connection';
import { EvergreenDataChanged } from '@generated/graphql';
import { MenuEvergreenData } from '@knuddels-app/evergreenData/MenuEvergreenData';

export type EvergreenData = {
	loyalty: {
		currentLevel: number;
		state: 'OK' | 'POINT_LOSS' | 'LEVEL_LOSS';
		card:
			| 'NONE'
			| 'POINT_LOSS'
			| 'LEVEL_LOSS'
			| 'ONBOARDING'
			| 'COLLECT_LEVEL';
	};
	user: {
		nick: string;
		knuddelAmount: number;
	};
	menu: MenuEvergreenData;
};

@injectable()
export class EvergreenDataService {
	public readonly dispose = Disposable.fn();

	@observable.ref
	public data: EvergreenData = {
		loyalty: {
			currentLevel: 0,
			state: 'OK',
			card: 'NONE',
		},
		user: {
			nick: '',
			knuddelAmount: 0,
		},
		menu: {
			categories: [],
			badges: {},
		},
	};

	constructor(
		@inject($AuthenticatedClientService)
		private readonly clientService: typeof $AuthenticatedClientService.T,
		@inject.lazy($UserService)
		private getUserService: typeof $UserService.TLazy,
		@inject.lazy($AppService)
		private getAppService: typeof $AppService.TLazy
	) {
		(window as any).update = () => {
			this.handleUpdate({ loyalty: { currentLevel: 1 } });
		};
	}

	async init(): Promise<void> {
		const userService = await this.getUserService();

		this.dispose.track(
			userService.addKnuddelAmountChangedListener(newAmount => {
				this.handleUpdate({
					user: {
						knuddelAmount: newAmount,
					},
				});
			})
		);

		const user = await userService.getCurrentUser();

		this.dispose.track(
			this.clientService.currentK3Client.subscribeToPrimaryData(
				EvergreenDataChanged,
				{
					userId: user.id,
				},
				{
					next: data => {
						this.handleUpdate(
							JSON.parse(data.evergreenData ?? '{}')
						);
					},
				}
			)
		);

		this.handleUpdate({
			...JSON.parse(user.evergreenData ?? '{}'),
			user: {
				nick: user.nick,
				knuddelAmount: user.knuddelAmount ?? 0,
			},
		});
	}

	private handleUpdate = debounce((update: DeepPartial<EvergreenData>) => {
		runInAction('Update evergreen data', () => {
			this.data = deepCopy(this.data, update);

			if (globalEnv.product === 'app') {
				this.getAppService().then(appService => {
					const apps = appService.appViewers
						.flatMap(viewer => viewer.getAllApps())
						.filter(Boolean);
					apps.forEach(app => {
						app.updateEvergreenData(this.data);
					});
				});
			}
		});
	});
}

export const $EvergreenDataService = newServiceId<EvergreenDataService>(
	'$EvergreenDataService'
);

export const registerServices = (ctx: K3ServiceBinder) => {
	ctx.loggedInScope
		.bind($EvergreenDataService)
		.toSingleton(EvergreenDataService);

	ctx.globalScope
		.bind($AppLoadingProvider)
		.toConstant(
			simpleAppLoadingProvider($EvergreenDataService, service =>
				service.init()
			)
		);
};
