import { declareProps, inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { action, computed, observable } from '@knuddels-app/mobx';
import { Flex, FlexCol, LineDivider, Text, FallbackBox } from '@knuddels/component-library';
import { $ProfileVisitorsService } from '@knuddelsModules/ProfileVisitors';
import { ProfileVisitor } from '@knuddelsModules/ProfileVisitors/bundle/services';
import { UserImageType } from '@knuddelsModules/UserData';
import { SPACING } from '@shared/components';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { useNickSubstituteForGender } from '../useNickSubstituteForGender';
import { OverflowItem } from './OverflowItem';
import { OverviewType } from './ProfileVisitorsOverview';
import { VisitorItem } from './VisitorItem';
interface ModelProps {
  onOverflowClicked(type: OverviewType): void;
  onVisitorClick(userId: ProfileVisitor['id'], nick: string, isUnlocked: boolean): void;
  type: OverviewType;
}
interface ViewProps {
  title: string;
  overflowButtonText: string;
  type: OverviewType;
  showTopSpacer?: boolean;
  layout: 'desktop' | 'mobile';
}
type PanelItemType = {
  type: 'visitor';
  visitor: ProfileVisitor;
  onClick(): void;
} | {
  type: 'overflow';
  onClick(): void;
};
@injectable()
class VisitorsPanelModel {
  @observable
  private maxItemRenderCount = 0;
  @computed
  public get renderedItems(): PanelItemType[] {
    const maxItemRenderCount = this.maxItemRenderCount;
    if (maxItemRenderCount === 0) {
      return [];
    }
    const maxMatchesRenderCount = maxItemRenderCount - 1;
    const items: PanelItemType[] = this.visitors.map(visitor => ({
      type: 'visitor',
      visitor,
      onClick: () => this.handleVisitorClick(visitor)
    }));
    items.length = maxMatchesRenderCount;
    items.push({
      type: 'overflow',
      onClick: () => this.showVisitorsView()
    });
    return items;
  }
  public get visitors(): ReadonlyArray<ProfileVisitor> {
    return this.profileVisitorsService.visitors;
  }
  constructor(@injectProps()
  private readonly props: ModelProps, @inject($ProfileVisitorsService)
  private readonly profileVisitorsService: typeof $ProfileVisitorsService.T) {}
  public readonly showVisitorsView = (): void => {
    this.props.onOverflowClicked(this.props.type);
  };
  public readonly handleVisitorClick = (user: ProfileVisitor): void => {
    this.props.onVisitorClick(user.id, user.nick, user.isUnlocked);
  };
  @action.bound
  public onLayout(width: number): void {
    this.maxItemRenderCount = Math.floor((width + SPACING.MINOR) / (UserImageType.MESSENGER + SPACING.MINOR));
  }
}
export const VisitorsPanel = injectedComponent({
  name: 'VisitorsPanel',
  model: VisitorsPanelModel,
  props: declareProps<ViewProps>()
}, ({
  showTopSpacer = true,
  model,
  type,
  layout,
  ...props
}) => {
  return <div className={_c0 + (showTopSpacer ? _c1 : _c2)}>
				<div className={_c3}>
					<Text type={'body2'} state={'tertiary'} bold={true} className={_c4}>
						{props.title.toUpperCase()}
					</Text>
				</div>
				<div className={_c5 + (layout === 'mobile' ? _c6 : _c7) + (layout === 'mobile' ? _c8 : _c9)}>
					<FallbackBox onLayout={e => model.onLayout(e.width)} className={_c10}>
						<VisitorList overflowButtonText={props.overflowButtonText} entries={model.renderedItems} />
					</FallbackBox>
				</div>
				{type === 'messenger' && <div className={_c11}>
						<LineDivider className={_c12} />
					</div>}
			</div>;
});
const VisitorList: React.FC<{
  overflowButtonText: string;
  entries: PanelItemType[];
}> = ({
  overflowButtonText,
  entries
}) => {
  if (entries.length === 0) {
    return null;
  }
  return <div className={_c13}>
			<AnimatePresence initial={false} mode={'popLayout'}>
				{entries.map(entry => {
        if (entry.type === 'overflow') {
          return <motion.div layout={'position'} key={':overflow'}>
								<OverflowItem onClick={entry.onClick} text={overflowButtonText} />
							</motion.div>;
        }
        const user = entry.visitor;
        return <motion.div layout={'position'} key={user.id} initial={{
          opacity: 0,
          scale: 0.9
        }} animate={{
          opacity: 1,
          scale: [0.9, 1.1, 1],
          transition: {
            opacity: {
              duration: 0.3,
              delay: 0.3
            },
            scale: {
              type: 'tween',
              ease: 'easeInOut',
              delay: 0.3
            }
          }
        }} exit={{
          opacity: 0,
          scale: 0.8
        }}>
							<VisitorEntry entry={entry} blurred={!entry.visitor.isUnlocked} />
						</motion.div>;
      })}
			</AnimatePresence>
		</div>;
};
const VisitorEntry: React.FC<{
  blurred: boolean;
  entry: PanelItemType & {
    type: 'visitor';
  };
}> = ({
  entry,
  blurred
}) => {
  const user = entry.visitor;
  const nickSubstitute = useNickSubstituteForGender(user.gender);
  return <VisitorItem key={user.id} onClick={entry.onClick} id={user.id} nick={blurred ? nickSubstitute : user.nick} blurred={blurred} />;
};
const _c0 = " Knu-FlexCol ";
const _c1 = " pt-minor ";
const _c2 = " pt-tiny ";
const _c3 = " Knu-Flex ml-base mb-minor mt-base ";
const _c4 = "  ";
const _c5 = " Knu-Flex overflow-visible mb-base ";
const _c6 = " pl-base ";
const _c7 = " pl-none ";
const _c8 = " pr-tiny ";
const _c9 = " pr-none ";
const _c10 = " Knu-Flex maxWidth-100-percent flex-1 overflow-visible ";
const _c11 = " Knu-FlexCol px-base ";
const _c12 = "  ";
const _c13 = " Knu-Flex gap-minor ";