import * as React from 'react';
import { FlexCol, Smiley, Text, ThemeOverride, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export const Card = React.memo(({
  header,
  title,
  text,
  imageSource,
  children
}: {
  header: string;
  title: string;
  text: string;
  imageSource: string;
  children?: React.ReactNode;
}) => {
  return <div className={_c0}>
				<Text type={'body2'} state={'tertiary'} bold={true} className={_c1}>
					{header}
				</Text>
				<div className={_c2 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Smiley src={imageSource} width={'70px'} height={'70px'} />
					<Text type={'subtitle'} state={'primary'} className={_c3}>
						{title}
					</Text>
					<Text type={'body2'} state={'tertiary'} className={_c4 + (children ? _c5 : _c6)}>
						{text}
					</Text>
					{children}
				</div>
			</div>;
});
Card.displayName = 'Card';
const _c0 = " Knu-FlexCol mt-22px pb-tiny ";
const _c1 = " m-none ml-base ";
const _c2 = " Knu-FlexCol alignContent-flex-start justifyContent-flex-start mt-small pt-base pb-xlarge px-large bg-contentLightBg shadow-Shadow1 ";
const _c3 = " mt-base ";
const _c4 = " mt-base ";
const _c5 = " mb-base ";
const _c6 = " mb-none ";