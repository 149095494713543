import { User } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserOnlineStatusService } from '@knuddelsModules/UserData';
import { useEffect, useState } from 'react';
import { autorun } from '@knuddels-app/mobx';

type OnlineStatusUser = Pick<User, 'currentOnlineChannelName' | 'isOnline'>;

export const useUserOnlineStatusWithChannel = (id: string | undefined) => {
	const service = useService($UserOnlineStatusService);
	const [user, setUser] = useState<OnlineStatusUser>();

	useEffect(() => {
		return autorun({ name: 'Update online status user' }, () => {
			setUser(
				id
					? service.getUserWithOnlineChannel(id)
							.userWithOnlineChannelName
					: undefined
			);
		});
	}, [id, setUser, service]);

	return user;
};
