import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const C = r(
  /* @__PURE__ */ o("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ o(
    "path",
    {
      d: "M12 3C10.8861 3 9.8178 3.4425 9.03015 4.23015C8.2425 5.0178 7.8 6.08609 7.8 7.2C7.8 8.31391 8.2425 9.3822 9.03015 10.1698C9.8178 10.9575 10.8861 11.4 12 11.4C13.1139 11.4 14.1822 10.9575 14.9698 10.1698C15.7575 9.3822 16.2 8.31391 16.2 7.2C16.2 6.08609 15.7575 5.0178 14.9698 4.23015C14.1822 3.4425 13.1139 3 12 3ZM12 13.8C8.418 13.8 3 15.954 3 19.2V22.2H21V19.2C21 15.954 15.582 13.8 12 13.8Z",
      fill: "currentColor"
    }
  ) })
);
C.displayName = "IconUser";
export {
  C as IconUser
};
