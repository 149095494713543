import { invariant as a } from "../../utils/errors.js";
import { cubicBezier as n } from "../cubic-bezier.js";
import { noop as m } from "../../utils/noop.js";
import { easeIn as i, easeInOut as p, easeOut as u } from "../ease.js";
import { circIn as s, circInOut as f, circOut as I } from "../circ.js";
import { backIn as O, backInOut as y, backOut as b } from "../back.js";
import { anticipate as g } from "../anticipate.js";
const k = {
  linear: m,
  easeIn: i,
  easeInOut: p,
  easeOut: u,
  circIn: s,
  circInOut: f,
  circOut: I,
  backIn: O,
  backInOut: y,
  backOut: b,
  anticipate: g
}, D = (r) => {
  if (Array.isArray(r)) {
    a(r.length === 4);
    const [t, o, c, e] = r;
    return n(t, o, c, e);
  } else if (typeof r == "string")
    return k[r];
  return r;
};
export {
  D as easingDefinitionToFunction
};
