import { FullConversationFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { $OverlayService } from '@knuddels-app/overlays';
import { OverflowMenuTrigger } from '@shared/components';
import * as React from 'react';
import { conversationTitleBarEvent } from '../../analytics';
import { ConversationOverflowContextMenu } from '../ContextMenus';
export const ConversationOverflowMenu: React.FC<{
  conversation: FullConversationFragment;
  onConversationDeleted?: () => void;
}> = observer('ConversationOverflowMenu', ({
  conversation,
  onConversationDeleted
}) => {
  const overlayService = useService($OverlayService);
  const showOverflowContextMenu = (x: number, y: number, width: number, height: number): void => {
    conversationTitleBarEvent.track('Titlebar_OpenMenu');
    overlayService.showOverlay({
      view: <ConversationOverflowContextMenu chevronX={x} chevronY={y} chevronWidth={width} chevronHeight={height} conversation={conversation} onConversationDeleted={onConversationDeleted} />
    });
  };
  return <OverflowMenuTrigger key={'overflow'} showOverflowMenu={showOverflowContextMenu} testId={'conversation-overflow'} />;
});