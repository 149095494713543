import * as t from "react";
const n = (r) => {
  const e = t.useRef();
  return {
    innerRef: e,
    onLayout: () => {
      e.current && r(e.current.scrollHeight > e.current.offsetHeight);
    }
  };
};
export {
  n as useIsOverflowing
};
