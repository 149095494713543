import { declareFormat } from '@knuddels-app/i18n';

export const CITY_AND_DISTANCE = declareFormat({
	id: 'CITY_AND_DISTANCE',
	defaultFormat: '{city} ({distance}km)',
});

export const DETAILS_TAB_TITLE = declareFormat({
	id: 'TAB_PROFILE_DETAILS',
	defaultFormat: 'Details',
});

export const PHOTOS_TAB_TITLE = declareFormat({
	id: 'TAB_PROFILE_PHOTOS',
	defaultFormat: 'Photos',
});

export const FRIENDS_TAB_TITLE = declareFormat({
	id: 'TAB_PROFILE_FRIENDS',
	defaultFormat: 'Friends',
});

export const ADMIN_INFO_TAB_TITLE = declareFormat({
	id: 'TAB_PROFILE_ADMIN_INFO',
	defaultFormat: 'Admin',
});

export const PROFILE_PICTURE = declareFormat({
	id: 'profile.profile-picture',
	defaultFormat: 'Profile picture',
});

export const ADD_TO_WATCHLIST = declareFormat({
	id: 'profile.add-to-watchlist',
	defaultFormat: 'Add to watchlist',
});

export const REMOVE_FROM_WATCHLIST = declareFormat({
	id: 'profile.remove-from-watchlist',
	defaultFormat: 'Remove from watchlist',
});

export const ADD_FRIEND_REQUEST = declareFormat({
	id: 'profile.request-friend',
	defaultFormat: 'Request friendship',
});

export const CANCEL_FRIEND_REQUEST = declareFormat({
	id: 'profile.cancel-friend-request',
	defaultFormat: 'Withdraw friend request',
});

export const ACCEPT_FRIEND_REQUEST = declareFormat({
	id: 'profile.accept-friend-request',
	defaultFormat: 'Accept friendship',
});

export const DO_KNUDDEL = declareFormat({
	id: 'profile.do-knuddel',
	defaultFormat: 'Knuddeln',
});

export const DO_KISS = declareFormat({
	id: 'profile.do-kiss',
	defaultFormat: 'Kiss',
});

export const NEW_FRIENDS = declareFormat({
	id: 'profile.new-friends-text',
	defaultFormat: 'You are friends!',
});

export const CHANGE_PROFILE_PICTURE_NO_PERMISSION = declareFormat({
	id: 'profile.changePicture.no-permission',
	defaultFormat:
		'You have to grant the permission for your camera to change your profile picture.',
});

export const UPLOAD_IMAGE_ERROR_TEXT = declareFormat({
	id: 'profile.profile-picture.upload.failed.text',
	defaultFormat: 'The image could not be uploaded.',
});
