import { K3ServiceBinder, ServiceId } from '../DependencyInjection';

type LoadFunc = (ctx: K3ServiceBinder) => Promise<void> | void;

type LoadEventKind = 'onDemand' | 'onLogin';

/**
 * A module represents a collection of services that can be loaded immediately or lazily.
 * Static services are loaded synchronously and immediately when the module is registered (see `loadStatic`).
 * Dynamic services are loaded asynchronously and only when the module is loaded explicitly (see `load`).
 * Dynamic services are usually bundled in a different js file and loaded lazily.
 * This reduces the file size of the main bundle.
 */
export class Module {
	public readonly name: string;
	public readonly dependencies: ReadonlyArray<Module>;
	public readonly load: LoadFunc;
	public readonly providedServices: ServiceId<any>[];
	public readonly activationMode: LoadEventKind;
	public readonly loadStatic: (ctx: K3ServiceBinder) => void;

	constructor(args: {
		name: string;
		dependencies: Module[];
		load:
			| LoadFunc
			| { from: () => Promise<{ registerServices: LoadFunc }> };
		providedServices: Record<string, ServiceId<any>>;
		loadStatic?: (ctx: K3ServiceBinder) => void;
		loadEvent?: LoadEventKind;
	}) {
		this.name = args.name;

		if (args.dependencies.some(d => !d)) {
			console.warn(
				'Nullish modules were given as dependency, are dependency mocked? Should not happen outside of tests.'
			);
			args.dependencies = args.dependencies.filter(d => !!d);
		}

		this.dependencies = args.dependencies;

		this.activationMode = args.loadEvent || 'onDemand';
		this.loadStatic = args.loadStatic || (() => {});

		this.providedServices = Object.values(args.providedServices);
		if ('from' in args.load) {
			const from = args.load.from;
			this.load = async ctx => {
				const { registerServices } = await from();
				registerServices(ctx);
			};
		} else {
			this.load = args.load;
		}
	}
}
