import * as React from 'react';
import { TitleBar } from '@knuddels/component-library';
import { NativeBackgroundWrapper } from './NativeBackgroundWrapper';
import { TopBackgroundStyle } from '../interfaces';
export function NativeBackgroundTitleBar({
  type,
  color,
  isInBackground,
  ...titleBarProps
}: React.ComponentProps<typeof TitleBar> & {
  color: string;
} & TopBackgroundStyle & {
  isInBackground: boolean;
}): JSX.Element {
  return <NativeBackgroundWrapper type={type} color={color} isInBackground={isInBackground}>
			<TitleBar {...titleBarProps}
    // @ts-expect-error Not exposed
    bg={color} />
		</NativeBackgroundWrapper>;
}