import { ConversationForwardedMessageContentFragment, ConversationMessageFragment, ConversationQuotedMessageContentFragment } from '@generated/graphql';
import { Flex, FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { FormattedText, Spacer } from '@shared/components';
import { IconForward } from '@shared/icons/IconForward';
import { IconReply } from '@shared/icons/IconReply';
import * as React from 'react';
import { BasicUser } from '../../../utils/BasicUser';
import { MessageLightboxSource } from '../Lightbox/Lightbox';
import { ImageDisplay } from './ImageAndSnap/ImageDisplay';
import { SnapSize } from './ImageAndSnap/SnapSizes';
import { MessageTime } from './MessageTime';
import { NestedMessage } from './NestedMessage';
import { NestedMessageHeader } from './NestedMessageHeader';
import { OriginalMessage } from './OriginalMessage';
import { Quote } from './Quote';
import { Star } from './Star';
import { useMessageText } from './useMessageText';
import { usePlaceholderTime } from './usePlaceholderTime';
import { useShowImages } from './useShowImages';
export const QuoteAndForwardMessage: React.FC<{
  message: Omit<ConversationMessageFragment, 'sender'>;
  forceBoldLinks?: boolean;
  content: ConversationQuotedMessageContentFragment | ConversationForwardedMessageContentFragment;
  sender: BasicUser;
  forceShowImages: boolean;
  sentByActiveUser: boolean;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
  getHighlightedLinkColor?(): string | undefined;
}> = ({
  content,
  message,
  sender,
  forceShowImages,
  sentByActiveUser,
  setLightboxSrc,
  forceBoldLinks,
  getHighlightedLinkColor
}) => {
  const nestedMessage = content.nestedMessage;
  const nestedSenderNick = nestedMessage && nestedMessage.sender.nick;
  const hasNestedImage = nestedMessage && nestedMessage.content.__typename === 'ConversationImageMessageContent';
  const isStarred = 'starred' in content && content.starred;
  const placeholderTime = usePlaceholderTime(message.timestamp, isStarred);
  const nestedMessageText = useMessageText(nestedMessage && nestedMessage.content);
  const messageText = useMessageText(content);
  const headerType = content.__typename === 'ConversationQuotedMessageContent' ? 'quote' : 'forward';
  const showImages = useShowImages(forceShowImages, sentByActiveUser, sender.id);
  if (!nestedMessage) {
    return null;
  }
  return <div style={{
    maxWidth: resolveThemingValue(hasNestedImage ? SnapSize.MESSAGE_QUOTED + 18 : undefined, "sizes", useTheme())
  }} className={_c0}>
			<NestedMessageHeader type={headerType === 'forward' ? IconForward : IconReply} timestamp={nestedMessage.timestamp} senderNick={nestedSenderNick} />
			<Spacer size={'small'} />
			{'image' in nestedMessage.content && <Quote>
					<ImageDisplay snapsAllowed={showImages} sender={sender} setLightboxSrc={setLightboxSrc} content={nestedMessage.content} size={SnapSize.MESSAGE_QUOTED} messageId={message.id} />
				</Quote>}
			<div className={_c1}>
				<div className={_c2}>
					{!FormattedText.isEmpty(nestedMessageText) && <>
							{hasNestedImage && <Quote>
									<Spacer size={'small'} />
								</Quote>}
							<NestedMessage text={nestedMessageText} senderNick={nestedSenderNick} getHighlightedLinkColor={getHighlightedLinkColor} forceBoldLinks={forceBoldLinks} />
						</>}
					<Spacer size={'small'} />
					{content.__typename === 'ConversationQuotedMessageContent' && <OriginalMessage text={messageText} getHighlightedLinkColor={getHighlightedLinkColor} forceBoldLinks={forceBoldLinks} placeholderTime={placeholderTime} />}
				</div>
				<div className={_c3}>
					<MessageTime timestamp={+message.timestamp} />
					{isStarred && <Star />}
				</div>
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol position-relative overflow-visible ";
const _c1 = " Knu-Flex px-tiny overflow-visible ";
const _c2 = " Knu-FlexCol minWidth-0-px overflow-visible ";
const _c3 = " Knu-Flex position-absolute bottom-none right-none ";