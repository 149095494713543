import * as React from 'react';
import { useRouter } from './ProfileContent/ProfileContentRouter';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
export const AuthenticityInfoApp: React.FC<{
  nick: string;
  closeProfileOnAppClose: boolean;
}> = ({
  nick,
  closeProfileOnAppClose
}) => {
  const {
    pop
  } = useRouter();
  const viewService = useService($ViewService);
  return <GenericSystemApp onAppClose={() => {
    if (closeProfileOnAppClose) {
      viewService.closeView(profileViewId);
    } else {
      pop();
    }
  }} appId={'AuthenticityInfoApp'} viewType={'subpath'} customSlashCommand={'/newauthenticity info:' + nick} />;
};