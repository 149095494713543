import { background as o, backgroundImage as r, spacing as e, sizes as i, flex as s, flexBasis as t, aspectRatio as n, flexDirection as a, flexAlignment as l, display as d, position as p, positionCoords as b, transform as f, border as m, borderColor as c, borderWidth as x, borderStyle as g, borderRadius as u, numberValues as y, overflow as C, visibility as k, textAlign as v, opacity as A, inset as R, flexWrap as W } from "./BoxMappings.js";
const w = [
  o,
  r,
  e,
  i,
  s,
  t,
  n,
  a,
  l,
  d,
  p,
  b,
  f,
  m,
  c,
  x,
  g,
  u,
  y,
  C,
  k,
  v,
  A,
  R,
  W
];
export {
  w as CommonProps
};
