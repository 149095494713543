import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { settingsModule } from '@knuddelsModules/Settings/module';

export const soundModule = new Module({
	name: 'Sound Module',
	dependencies: [settingsModule],
	providedServices,
	loadEvent: 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
