import * as React from 'react';
import { FormattedText, PreviewFormattedTextDisplay, Spacer } from '@shared/components';
import { Box, createSvgIcon, Flex, FlexCol, Image, rgb, Text, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { AlbumDetailPhotoFragment, AlbumFragment } from '@generated/graphql';
import { IsEditingPhotoDescriptionContext } from '../EditPhotoDescriptionContext';
import { EditDescriptionBar } from './EditDescriptionBar';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
const IconPhotoDescription = createSvgIcon(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.5 4H14V3.5C14 2.67156 13.3284 2 12.5 2H12V1.5C12 0.671562 11.3284 0 10.5 0H1.5C0.671562 0 0 0.671562 0 1.5V10.5C0 11.3284 0.671562 12 1.5 12H2V12.5C2 13.3284 2.67156 14 3.5 14H4V14.5C4 15.3284 4.67156 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67156 15.3284 4 14.5 4ZM1 8.295V1.5C1 1.22384 1.22384 1 1.5 1H10.5C10.7762 1 11 1.22384 11 1.5V6.295L9.855 5.15C9.65997 4.95613 9.34503 4.95613 9.15 5.15L7 7.295L4.855 5.145C4.65997 4.95113 4.34503 4.95113 4.15 5.145L1 8.295ZM3.5 13C3.22384 13 3 12.7762 3 12.5V12H10.5C11.3284 12 12 11.3284 12 10.5V3H12.5C12.7762 3 13 3.22384 13 3.5V12.5C13 12.7762 12.7762 13 12.5 13H3.5ZM15 14.5C15 14.7762 14.7762 15 14.5 15H5.5C5.22384 15 5 14.7762 5 14.5V14H12.5C13.3284 14 14 13.3284 14 12.5V5H14.5C14.7762 5 15 5.22384 15 5.5V14.5Z" fill="currentColor" fillOpacity="0.66" />
	</svg>);
IconPhotoDescription.displayName = 'IconPhotoDescription';
export type AlbumPhotoCarouselItemData = AlbumDetailPhotoFragment & {
  albumTitle: AlbumFragment['title'];
  photoIndex?: number;
  albumLength?: number;
};
export const AlbumCarouselItem: React.FC<{
  item: AlbumPhotoCarouselItemData | undefined;
  width: string;
  height: string;
  hideDescriptionBar?: boolean;
}> = ({
  item,
  width,
  height,
  hideDescriptionBar
}) => {
  const isEditing = React.useContext(IsEditingPhotoDescriptionContext);
  if (!item) {
    return <div style={{
      width: resolveThemingValue(width, "sizes", useTheme()),
      height: resolveThemingValue(height, "sizes", useTheme())
    }} className={_c0} />;
  }
  return <KeyboardAvoidanceView type={'shrink'} enabled={isEditing} style={{
    position: 'relative',
    width,
    height
  }}>
			<div
    // prevents overlapping of blurred image
    style={{
      width: resolveThemingValue(width, "sizes", useTheme()),
      height: resolveThemingValue(height, "sizes", useTheme())
    }} className={_c1 + ("black-solid-black" ? resolveIsDarkColor("black-solid-black", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div style={{
        width: resolveThemingValue(width, "sizes", useTheme()),
        height: resolveThemingValue(height, "sizes", useTheme())
      }} className={_c2}>
					<Image resizeMode={'contain'} src={item.thumbnailUrl} alt={''} style={{
          width: resolveThemingValue(width, "sizes", useTheme()),
          height: resolveThemingValue(height, "sizes", useTheme())
        }} className={_c3} />
				</div>
				<Image resizeMode={'contain'} src={item.photoUrl} alt={''} style={{
        width: resolveThemingValue(width, "sizes", useTheme()),
        height: resolveThemingValue(height, "sizes", useTheme())
      }} className={_c4} />
				{!hideDescriptionBar && <DescriptionBar item={item} />}
			</div>
		</KeyboardAvoidanceView>;
};
const DescriptionBar: React.FC<{
  item: AlbumPhotoCarouselItemData;
}> = ({
  item
}) => {
  return <div style={{
    background: resolveThemingValue(rgb(0, 0, 0, 0.46), "colors", useTheme())
  }} className={_c5 + (rgb(0, 0, 0, 0.46) ? resolveIsDarkColor(rgb(0, 0, 0, 0.46), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c6}>
				<IconPhotoDescription size={'base'} />
				<Spacer size={'small'} />
				<Text type={'tiny'} bold={true} className={_c7}>
					{item.albumTitle}
				</Text>
				<Spacer size={'small'} />
				{typeof item.photoIndex === 'number' && typeof item.albumLength === 'number' && <Text type={'tiny'} bold={true} className={_c8}>
							{`${item.photoIndex + 1}/${item.albumLength}`}
						</Text>}
			</div>
			<Spacer size={'tiny'} />
			<PhotoDescription item={item} />
		</div>;
};
const PhotoDescription: React.FC<{
  item: AlbumPhotoCarouselItemData;
}> = ({
  item
}) => {
  const isEditing = React.useContext(IsEditingPhotoDescriptionContext);
  return !isEditing ? item.description && <PreviewFormattedTextDisplay textProps={{
    type: 'body2'
  }} text={FormattedText.fromJsonString(item.description.formattedText)} /> : <>
			<Spacer size={'tiny'} />
			<EditDescriptionBar photoId={item.id} initialRawText={item.description?.rawText} />
		</>;
};
const _c0 = " Knu-Box ";
const _c1 = " Knu-FlexCol overflow-hidden position-relative alignItems-center justifyContent-center bg-black-solid-black ";
const _c2 = " Knu-FlexCol position-absolute ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = " Knu-FlexCol position-absolute bottom-none left-none right-none shadow-Shadow1 px-large py-small textAlign-left ";
const _c6 = " Knu-Flex alignItems-center ";
const _c7 = "  ";
const _c8 = "  ";