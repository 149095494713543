import * as React from 'react';
import { DarkTheme, LightTheme } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $LocalStorage } from '@knuddels-app/local-storage';
import { accentKey, themeKey } from '@shared/themeKeys';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';
import { DropdownSettings } from '@knuddelsModules/Settings/bundle/components/DropdownSettings';
import { useThemeSwitch } from '@knuddels-app/ThemeSwitchProvider';
import { $ThemeSettingsService } from '@knuddelsModules/Settings';
import { usePrefersDarkMode } from '@knuddels-app/tools/usePrefersDarkMode';
import { createThemeWithFontScale } from '@knuddels-app/tools/createThemeWithFontScale';
export const SettingDarkModeSwitch: React.FC = props => {
  const updateTheme = useThemeSwitch();
  const themeSettingsService = useService($ThemeSettingsService);
  const prefersDarkMode = usePrefersDarkMode();
  const [themeSettings, setThemeSettings] = React.useState<string | null>();
  const localStorage = useService($LocalStorage);
  const track = useLogEvent();
  const themeEntry = localStorage.getEntry(themeKey);
  const accentEntry = localStorage.getEntry(accentKey);
  React.useEffect(() => {
    setThemeSettings(themeEntry.get() ?? 'system');
  }, []);
  if (!themeSettings) {
    return null;
  }
  return <DropdownSettings title={'Farbschema'} items={[{
    value: 'light',
    label: 'Hell'
  }, {
    value: 'dark',
    label: 'Dunkel'
  }, {
    value: 'system',
    label: 'System'
  }]} value={themeSettings} onChange={async value => {
    const theme = value === 'dark' ? DarkTheme : value === 'system' ? prefersDarkMode ? DarkTheme : LightTheme : LightTheme;
    const currentAccent = accentEntry.get();
    const newAccent = currentAccent ?? theme.colors.basic.accent;
    setThemeSettings(value);
    updateTheme(await createThemeWithFontScale(newAccent, theme));
    themeSettingsService.setTheme(value);
    onMainThreadIdle(() => {
      track('Settings_Theming', value === 'dark' ? 'DarkMode_Activated' : value === 'system' ? 'SystemMode_Activated' : 'LightMode_Activated');
    });
  }} {...props} />;
};