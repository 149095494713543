import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { userDataModule } from '@knuddelsModules/UserData/module';

export const promotionModule = new Module({
	name: 'Promotion module',
	dependencies: [userDataModule],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
