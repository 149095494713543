import { jsx as C } from "react/jsx-runtime";
import { createSvgIcon as o } from "./createSvgIcon.js";
const r = o(
  /* @__PURE__ */ C("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ C(
    "path",
    {
      d: "M0.683308 10.6084C0.40244 10.89 0.211883 11.2489 0.135981 11.6393C0.0600778 12.0297 0.102275 12.4339 0.257182 12.8001C0.412089 13.1664 0.672672 13.4782 1.00564 13.6957C1.33862 13.9132 1.72886 14.0265 2.12652 14.0211L10.0262 14.0211L10.0262 21.9208C10.0208 22.1868 10.0685 22.4513 10.1666 22.6986C10.2647 22.946 10.4111 23.1713 10.5973 23.3614C10.7836 23.5514 11.0059 23.7024 11.2512 23.8055C11.4965 23.9086 11.7599 23.9617 12.026 23.9617C12.2921 23.9617 12.5555 23.9086 12.8009 23.8055C13.0462 23.7024 13.2685 23.5514 13.4547 23.3614C13.6409 23.1713 13.7874 22.946 13.8854 22.6986C13.9835 22.4513 14.0312 22.1868 14.0258 21.9208V14.0211H21.9255C22.1916 14.0265 22.456 13.9788 22.7034 13.8807C22.9507 13.7826 23.176 13.6362 23.3661 13.45C23.5562 13.2637 23.7072 13.0414 23.8102 12.7961C23.9133 12.5508 23.9664 12.2874 23.9664 12.0213C23.9664 11.7552 23.9133 11.4918 23.8102 11.2464C23.7072 11.0011 23.5562 10.7788 23.3661 10.5926C23.176 10.4064 22.9507 10.2599 22.7034 10.1619C22.456 10.0638 22.1916 10.0161 21.9255 10.0215H14.0258L14.0258 2.12179C14.0315 1.71878 13.9154 1.32346 13.6925 0.987611C13.4697 0.651764 13.1506 0.391068 12.7771 0.239685C12.4036 0.0883013 11.993 0.0532923 11.5992 0.139248C11.2055 0.225203 10.8468 0.428112 10.5704 0.721384C10.2139 1.09974 10.0187 1.60201 10.0262 2.12179L10.0262 10.0215L2.12652 10.0215C1.85893 10.0178 1.59332 10.0679 1.34542 10.1687C1.09752 10.2695 0.872369 10.419 0.683308 10.6084V10.6084Z",
      fill: "currentColor"
    }
  ) })
);
r.displayName = "IconPlusBig";
export {
  r as IconPlusBig
};
