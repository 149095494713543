function y({ when: e, delay: r, delayChildren: a, staggerChildren: n, staggerDirection: l, repeat: d, repeatType: i, repeatDelay: g, from: p, elapsed: u, ...t }) {
  return !!Object.keys(t).length;
}
function f(e, r) {
  return e[r] || e.default || e;
}
export {
  f as getValueTransition,
  y as isTransitionDefined
};
