import { FlexCol, ThemeColors, ThemeOverride, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { calculateCircleBorderRadius } from '../../helper/calculateCircleBorderRadius';
export enum StatusBadgeSizes {
  SMALL = 8,
  NORMAL = 12,
}
export type StatusBadgeColors = 'online' | 'away' | 'dnd';
export type StatusBadgeBorders = 'none' | 'standard';
interface Props {
  size: StatusBadgeSizes;
  color?: StatusBadgeColors;
  parentBg?: string | ThemeColors;
}
interface PresetProps extends Props {
  borderPreset?: StatusBadgeBorders;
}
export type StatusBadgeProps = PresetProps;
export const StatusBadge: React.FC<StatusBadgeProps> = props => {
  const theme = useTheme();
  const {
    size,
    borderPreset = 'standard',
    color = 'online'
  } = props;
  const borderWidth = borderWidths[borderPreset];
  const innerSize = size + borderWidth * 2;
  const borderColor = props.parentBg ?? theme.colors.basic.contentBg;
  return <div style={{
    height: resolveThemingValue(innerSize, "sizes", useTheme()),
    width: resolveThemingValue(innerSize, "sizes", useTheme()),
    minWidth: resolveThemingValue(innerSize, "sizes", useTheme()),
    minHeight: resolveThemingValue(innerSize, "sizes", useTheme()),
    borderRadius: resolveThemingValue((calculateCircleBorderRadius(innerSize) as ThemeOverride), "borderRadius", useTheme()),
    borderWidth: resolveThemingValue((borderWidth as ThemeOverride) ?? 'none', "borderWidth", useTheme()),
    borderColor: resolveThemingValue((borderColor as ThemeOverride), "colors", useTheme()),
    background: resolveThemingValue(colors[color], "colors", useTheme())
  }} className={_c0 + (colors[color] ? resolveIsDarkColor(colors[color], useTheme()) ? " content-is-dark" : " content-is-light" : "")} />;
};
const borderWidths: { [key in StatusBadgeBorders]: number } = {
  none: 0,
  standard: 3
};
const colors: { [key in StatusBadgeColors]: ThemeColors } = {
  online: 'green-500',
  away: 'orange-500',
  dnd: 'red-500'
};
const _c0 = " Knu-FlexCol borderStyle-solid ";