import * as React from 'react';
import { useLayoutEffect } from 'react';
import { OverflowMenuTrigger } from '@shared/components';
import { conversationOverviewTitleBarEvent, newConversationVisibleTracker } from '../../analytics';
import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { OverviewOverflowContextMenuOverlay } from '../ContextMenus/OverviewOverflowContextMenu';
import { MessengerRouterConfig, useRouter } from '../MessengerRouter';
import { useOpenConversation } from '../../useOpenConversation';
import { OverviewMessengerList } from '@knuddelsModules/Messenger/bundle/components/MessengerList/OverviewMessengerList';
import { FlexCol, IconPlus, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $UserService, KnuddelsPlusBanner } from '@knuddelsModules/UserData';
import { $ProfileVisitorsService } from '@knuddelsModules/ProfileVisitors';
@injectable()
class MessengerOverviewModel implements IModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($ProfileVisitorsService)
  private readonly profileVisitorsService: typeof $ProfileVisitorsService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T) {}
  public get isKnuddelsPlusBannerVisible(): boolean {
    const user = this.userService.currentUser;
    if (!user) {
      return false;
    }
    if (!this.profileVisitorsService.visitorsEnabled) {
      return false;
    }
    return user.hasKnuddelsPlus;
  }
  public get isOverflowMenuOpen(): boolean {
    return !!this.overlayService.findOverlay(OverviewOverflowContextMenuOverlay.getFilter());
  }
  public readonly showOverflowMenu = (x: number, y: number, width: number, height: number, navigate: (path: keyof MessengerRouterConfig) => void): void => {
    conversationOverviewTitleBarEvent.track('Titlebar_OpenMenu');
    this.overlayService.showOverlayIfNotVisible(OverviewOverflowContextMenuOverlay, {
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height,
      navigate
    });
  };
  dispose(): void {}
}
export const MessengerOverview = injectedComponent({
  model: MessengerOverviewModel,
  name: 'MessengerOverview'
}, ({
  model
}) => {
  const {
    navigate,
    configureNavBar
  } = useRouter();
  const openConversation = useOpenConversation(false, conversationId => {
    navigate('conversation', {
      params: {
        conversationId
      }
    });
  });
  const newConversation = <IconPlus size={'large'} onPress={() => {
    newConversationVisibleTracker.start();
    conversationOverviewTitleBarEvent.track('Titlebar_StartNewConversation');
    navigate('newConversation');
  }} key="newConversation" />;
  const overFlowMenu = <OverflowMenuTrigger key={`overflowMenuTrigger`} showOverflowMenu={(...props) => {
    model.showOverflowMenu(...props, navigate);
  }} isMenuActive={model.isOverflowMenuOpen} testId={'messenger-overflow'} />;
  const iconList = [newConversation, overFlowMenu];
  useLayoutEffect(() => {
    configureNavBar({
      iconList
    });
  }, []);
  return <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{model.isKnuddelsPlusBannerVisible && <KnuddelsPlusBanner alwaysVisibleWithoutKnuddelsPlus />}
				<OverviewMessengerList isMinifiedOverview={false} openConversation={openConversation} />
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 bg-contentBg ";