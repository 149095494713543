/* eslint-disable react/display-name */
import * as React from 'react';
import { DraggableListItem, DraggableListProps } from '@knuddelsModules/Settings/bundle/components/DraggableList/DraggableListProps';
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, Droppable, DropResult } from 'react-beautiful-dnd';
export const DraggableList = <T extends DraggableListItem,>(props: DraggableListProps<T>): JSX.Element => {
  const onDragEnd = (result: DropResult): void => {
    if (result.destination) {
      props.onDropEnd(result.source.index, result.destination.index);
    }
  };
  return <DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={'droppable'}>
				{provided => <div {...provided.droppableProps} style={{
        borderRadius: 16,
        overflow: 'hidden'
      }} ref={provided.innerRef}>
						{props.items.map((item, index) => <DraggableItem key={item.uniqueId} item={item} index={index} renderItem={props.renderItem} itemOffsetTop={props.itemOffsetTop} itemOffsetLeft={props.itemOffsetLeft ?? 0} />)}

						{provided.placeholder}
					</div>}
			</Droppable>
		</DragDropContext>;
};
function DraggableItem<T extends DraggableListItem>({
  item,
  index,
  renderItem,
  itemOffsetTop,
  itemOffsetLeft
}: {
  item: T;
  index: number;
  renderItem: DraggableListProps<T>['renderItem'];
  itemOffsetTop?: () => number;
  itemOffsetLeft: number;
}): JSX.Element {
  const renderDragHandle = (dragHandleProps: DraggableProvidedDragHandleProps) => (children: React.ReactNode): React.ReactNode => {
    return <div {...dragHandleProps}>{children}</div>;
  };
  return <Draggable draggableId={item.uniqueId} index={index}>
			{(innerProvided, innerSnapshot) => {
      const transform = removeHorizontalTransform(innerProvided.draggableProps.style.transform);
      const providedTop = (innerProvided.draggableProps.style as any).top;
      return <div ref={innerProvided.innerRef} {...innerProvided.draggableProps} style={{
        ...innerProvided.draggableProps.style,
        transform,
        left: itemOffsetLeft,
        top: providedTop !== undefined ? providedTop - (itemOffsetTop && itemOffsetTop() || 0) : undefined
      }}>
						{renderItem(item, index, innerSnapshot.isDragging, renderDragHandle(innerProvided.dragHandleProps))}
					</div>;
    }}
		</Draggable>;
}
function removeHorizontalTransform(transform: string | undefined): string | undefined {
  return transform ? transform.replace(/translate\(-?[0-9]+/, 'translate(0') : undefined;
}