import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { ScreenHeight, ScreenWidth } from '@knuddels-app/Screen/screens';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { measureWindow } from '@knuddels-app/tools/measureWindow';
import { measureLayoutRelativeToWindow } from '@knuddels-app/tools/measureLayoutRelativeToWindow';
import { LayoutInfo } from '@knuddels-app/tools/LayoutInfo';
import { measureLayoutRelativeToAncestor } from '@knuddels-app/tools/measureLayoutRelativeToAncestor';
import { Disposable } from '@knuddels/std';
import { ScreenOrientation } from '@capacitor/screen-orientation';

interface Size {
	width: number;
	height: number;
}

@injectable()
export class ScreenService {
	readonly dispose = Disposable.fn();

	public readonly globalMeasureAncestorRef = React.createRef<any>();

	get orientation(): 'portrait' | 'landscape' {
		return this._orientation;
	}

	@observable
	private _screenWidth: ScreenWidth = ScreenWidth.S;

	@observable
	private _screenHeight: ScreenHeight = ScreenHeight.S;

	@observable
	private _orientation: 'portrait' | 'landscape' = 'portrait';

	private ancestorLayout: Size | undefined;

	constructor() {
		ScreenOrientation.addListener('screenOrientationChange', event => {
			runInAction('set orientation', () => {
				this._orientation = event.type.includes('landscape')
					? 'landscape'
					: 'portrait';
			});
		}).then(orientationSub => {
			this.dispose.track(() => orientationSub.remove());
		});
	}

	@action.bound
	public setAncestorLayout(layout: Size): void {
		this.ancestorLayout = layout;
	}

	public get isLandscape(): boolean {
		return this.orientation === 'landscape';
	}

	public get screenWidth(): ScreenWidth {
		return this._screenWidth;
	}

	@action.bound
	setScreenWidth(width: ScreenWidth): void {
		this._screenWidth = width;
	}

	public get screenHeight(): ScreenHeight {
		return this._screenHeight;
	}

	@action.bound
	setScreenHeight(height: ScreenHeight): void {
		this._screenHeight = height;
	}

	public get isStackedLayout(): boolean {
		return (
			this._screenWidth === ScreenWidth.XS ||
			this._screenWidth === ScreenWidth.S
		);
	}

	public get isMediumSize(): boolean {
		return this._screenWidth === ScreenWidth.M;
	}

	public async measureLayoutRelativeToScreen(
		ref: React.Component<any>
	): Promise<LayoutInfo & { screenWidth: number; screenHeight: number }> {
		const ancestor = this.globalMeasureAncestorRef.current;

		// We need special handling because of problems with native components like virtual keyboard or statusbar...
		if (ancestor && this.ancestorLayout) {
			const refLayout = await measureLayoutRelativeToAncestor(
				ref,
				ancestor
			);
			return {
				...refLayout,
				screenWidth: this.ancestorLayout.width,
				screenHeight: this.ancestorLayout.height,
			};
		}

		// fallback
		const layout = await measureLayoutRelativeToWindow(ref);
		const windowLayout = measureWindow();
		return {
			...layout,
			screenWidth: windowLayout.width,
			screenHeight: windowLayout.height,
		};
	}
}
