import { IconExternal, Link, Text } from '@knuddels/component-library';
import { MORE_ABOUT_URL } from '../../constants';
import { declareFormat } from '@knuddels/i18n';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
export const MentorSystemLink: React.FC<{
  onPress?: () => void;
}> = ({
  onPress
}) => {
  const i18n = useService($I18n);
  return <Text className={_c0}>
			<Link to={MORE_ABOUT_URL} openInLocation={'new'} onPress={onPress}>
				<Text state={'highlighted'} type={'body1'} className={_c1}>
					{declareFormat({
          id: 'mentorsystem.about.info.link-text',
          defaultFormat: 'Read more about the mentor system'
        }).format(i18n)}
				</Text>{' '}
				<IconExternal size={'base'} />
			</Link>
		</Text>;
};
const _c0 = " textAlign-center ";
const _c1 = "  ";