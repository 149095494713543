import * as React from 'react';
import { FlexCol } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ContactsService, $FriendRequestsService, ReceivedFriendRequest as FriendRequest } from '@knuddelsModules/Contacts/index';
import { PrivateMessageFooter } from './PrivateMessageFooter';
import { useTrackConversation } from '../../useTrackConversation';
export const ReceivedFriendRequest: React.FC<{
  request: FriendRequest;
  onAccepted: () => void;
  onAcceptError: () => void;
}> = ({
  request,
  onAccepted,
  onAcceptError
}) => {
  const contactsService = useService($ContactsService);
  const friendRequestService = useService($FriendRequestsService);
  const track = useTrackConversation();
  React.useEffect(() => {
    if (request.readState.unreadMessageCount > 0) {
      track('SystemMessages_FriendshipRequestReceived');
    }
    friendRequestService.markFriendRequestAsRead(request.user.id);
  }, []);
  return <div className={_c0}>
			<Spacer size={'large'} />
			<contactsService.ReceivedFriendRequest user={request.user} isAccepted={request.isAccepted} onAccepted={() => {
      track('SystemMessages_FriendshipRequestAccepted');
      onAccepted();
    }} onAcceptError={onAcceptError} hideDeclineButton />
			<Spacer size={'minor'} />
			<PrivateMessageFooter />
			<Spacer size={'base'} />
		</div>;
};
const _c0 = " Knu-FlexCol alignItems-center ";