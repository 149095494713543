import { APP_DRAWER_BAR_HEIGHT } from '@shared/constants';
import { AppInstance } from '../../services/AppInstance';

export function getSplitViewHeightPercentage(
	maxSplitViewPercentage: number,
	maxWidth: number,
	maxHeight: number,
	app?: AppInstance
): number {
	const maxPercentage = maxSplitViewPercentage;

	if (app && app.displayHeight) {
		let minScale = 1.0;
		if (app.displayWidth) {
			const widthScale = maxWidth / app.displayWidth;
			const heightScale = maxHeight / app.displayHeight;
			minScale = Math.min(widthScale, heightScale, 1.0);
		}

		return Math.min(
			(app.displayHeight * minScale + APP_DRAWER_BAR_HEIGHT) / maxHeight,
			maxPercentage
		);
	}

	return maxPercentage;
}
