import * as React from 'react';
import { Carousel } from '@shared/components';
import { User } from '@generated/graphql';
import { $CurrentEndpointStore, KnownEndpoints } from '@knuddels-app/Connection';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action, autorun, computed, observable } from '@knuddels-app/mobx';
import { $AlbumPicturesService } from '@knuddelsModules/Profile/providedServices';
import { Disposable } from '@knuddels/std';
import { AlbumPicture, Picture } from '@knuddelsModules/Profile/interfaces';
import { AlbumPhoto } from './AlbumPhoto';
import { clearElementsOutsideOfRange } from './clearElementsOutsideOfRange';
import { OS, os } from '@shared/components/tools/os';
import { Flex, FlexCol, Image, PointerEvent, rgb, Text, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { measureLayoutRelativeToWindow } from '@knuddels-app/tools/measureLayoutRelativeToWindow';
import { LayoutInfo } from '@knuddels-app/tools/LayoutInfo';
import { HoverableImage } from '@shared/components/atoms/HoverableImage';
import defaultPic from '@shared/images/nopic.png';
import leftArrowIcon from '../../assets/icon-chevron_left-white_shadow.png';
import rightArrowIcon from '../../assets/icon-chevron_right-white_shadow.png';
export type ProfileCarouselTrackingKind = 'leftArrow' | 'rightArrow' | 'albumImagesAvailable';
interface Props {
  user: Pick<User, 'id' | 'albumPhotosUrl' | 'profilePictureOverlayUrls'> & {
    profilePicture: Pick<User['profilePicture'], 'urlLargeSquare' | 'urlVeryLarge'>;
  };
  size: number;
  centerArrows?: boolean;
  hideArrows?: boolean;
  onSelectedAlbumPictureChanged?(picture: Picture): void;
  trackingCallback?(kind: ProfileCarouselTrackingKind): void;
}
type CarouselItem = Picture & {
  url: string;
  blurUrl?: string;
};
@injectable()
class ProfileCarouselModel {
  carouselRef = React.createRef<Carousel<any>>();
  viewRef = React.createRef<any>();
  bounds: LayoutInfo | undefined;
  private readonly dispose = Disposable.fn();
  @observable
  private _albumPictureUrls: readonly AlbumPicture[] = [];
  @observable
  private _selectedPictureIndex = 0;
  constructor(@injectProps()
  private readonly props: Props, @inject($AlbumPicturesService)
  private readonly albumPicturesService: typeof $AlbumPicturesService.T, @inject($CurrentEndpointStore)
  private readonly currentEndpointStore: typeof $CurrentEndpointStore.T) {
    this.dispose.track(autorun({
      name: 'Update album photos'
    }, this.updateAlbumPictureUrls));
  }
  get selectedPictureIndex(): number {
    return this._selectedPictureIndex;
  }
  get albumPictureUrls(): readonly AlbumPicture[] {
    return this._albumPictureUrls;
  }
  @action
  private setAlbumPictures(albumPictureUrls: readonly AlbumPicture[]): void {
    this._albumPictureUrls = albumPictureUrls;
  }
  get newItems(): CarouselItem[] {
    return clearElementsOutsideOfRange(this.items, this.selectedPictureIndex);
  }
  get shouldRenderLeftArrow(): boolean {
    return !this.props.hideArrows && (os === OS.web || this.selectedPictureIndex > 0);
  }
  get shouldRenderRightArrow(): boolean {
    return !this.props.hideArrows && (os === OS.web || this.selectedPictureIndex < this.items.length - 1);
  }
  updateAlbumPictureUrls = async (): Promise<void> => {
    if (this.props.user.albumPhotosUrl) {
      const albumPictures = await this.albumPicturesService.getAlbumPictureUrls(this.props.user.albumPhotosUrl);
      this.setAlbumPictures(albumPictures);
      if (albumPictures.length > 0 && this.props.trackingCallback) {
        this.props.trackingCallback('albumImagesAvailable');
      }
    }
    if (this.props.onSelectedAlbumPictureChanged) {
      this.props.onSelectedAlbumPictureChanged(this.items[this.selectedPictureIndex]);
    }
  };
  @action
  selectedPictureChanged = (newIndex: number): void => {
    this._selectedPictureIndex = newIndex;
    if (this.props.onSelectedAlbumPictureChanged) {
      this.props.onSelectedAlbumPictureChanged(this.items[newIndex]);
    }
  };
  @computed
  get items(): CarouselItem[] {
    const {
      profilePicture
    } = this.props.user;
    const profileUrl = this.currentEndpointStore.currentEndpoint.id === KnownEndpoints.Test.id ? profilePicture.urlLargeSquare : profilePicture.urlVeryLarge;
    const profilePic = profileUrl ? {
      url: profileUrl,
      blurUrl: profilePicture.urlLargeSquare
    } : {
      url: defaultPic,
      blurUrl: defaultPic
    };
    return [{
      ...profilePic,
      type: 'profilePicture',
      userId: this.props.user.id
    }, ...this.albumPictureUrls.map(source => ({
      type: source.type,
      url: source.url,
      photoId: source.photoId
    }))];
  }
  onLeftArrowClicked = (): void => {
    if (this.props.trackingCallback) {
      this.props.trackingCallback('leftArrow');
    }
    this.carouselRef.current.snapToPrev();
  };
  onRightArrowClicked = (): void => {
    if (this.props.trackingCallback) {
      this.props.trackingCallback('rightArrow');
    }
    this.carouselRef.current.snapToNext();
  };
  onCarouselPress = (e: PointerEvent): void => {
    const mouseX = e.pageX;
    if (this.bounds) {
      this.handleCarouselPress(mouseX);
    } else {
      // Have to calculate layout here because quick whois is initially displayed at (0,0) before moving to
      // the correct location
      measureLayoutRelativeToWindow(this.viewRef.current).then(layout => {
        this.bounds = layout;
        this.handleCarouselPress(mouseX);
      });
    }
  };
  private readonly handleCarouselPress = (mouseX: number): void => {
    const {
      x,
      width
    } = this.bounds;
    if (mouseX - x <= width / 2) {
      this.carouselRef.current.snapToPrev();
    } else {
      this.carouselRef.current.snapToNext();
    }
  };
  renderAlbumPhoto = (item: CarouselItem | undefined, index: number): React.ReactElement => {
    const overlays = this.props.user.profilePictureOverlayUrls.urlsLarge;
    if (item.type === 'profilePicture' && overlays.length > 0) {
      return <div className={_c0}>
					<AlbumPhoto key={'pic-' + index} size={this.props.size} url={item?.url} blurUrl={item?.blurUrl} />
					{overlays.map((overlayUrl, overlayIndex) => <div key={overlayIndex} className={_c1}>
							<Image src={overlayUrl} alt={''} imageRendering={'pixelated'} className={_c2} />
						</div>)}
				</div>;
    }
    return <AlbumPhoto key={'pic-' + index} size={this.props.size} url={item?.url} blurUrl={item?.blurUrl} />;
  };
}
export const ProfileCarousel = injectedComponent({
  name: 'ProfileCarousel',
  model: ProfileCarouselModel,
  props: declareProps<Props>()
}, ({
  model,
  centerArrows,
  size
}): React.ReactElement => {
  const arrowTop = centerArrows ? (size - 24) / 2 : size * 0.55;
  return <div onClick={toPointerHandler(model.onCarouselPress)} ref={model.viewRef} style={{
    height: resolveThemingValue(size, "sizes", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme()),
    background: resolveThemingValue(rgb(68, 68, 68), "colors", useTheme())
  }} className={_c3 + (rgb(68, 68, 68) ? resolveIsDarkColor(rgb(68, 68, 68), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Carousel size={size} ref={model.carouselRef} items={model.newItems} renderItem={model.renderAlbumPhoto} onSelectedItemChanged={model.selectedPictureChanged} />
				{model.items.length > 1 && <>
						{model.shouldRenderLeftArrow && <Arrow top={arrowTop} location={'left'} iconRawSource={leftArrowIcon} onClick={model.onLeftArrowClicked} />}
						{model.shouldRenderRightArrow && <Arrow top={arrowTop} location={'right'} iconRawSource={rightArrowIcon} onClick={model.onRightArrowClicked} />}
						<div className={_c4 + ("black-transparent-220" ? resolveIsDarkColor("black-transparent-220", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<Text type={'body2'} bold={true} className={_c5}>
								{model.selectedPictureIndex + 1 + '/' + model.items.length}
							</Text>
						</div>
					</>}
			</div>;
});
function Arrow(props: {
  top: number;
  location: 'left' | 'right';
  iconRawSource: any;
  onClick(): void;
}): React.ReactElement {
  return <div style={{
    top: resolveThemingValue((props.top as ThemeOverride), "spacing", useTheme()),
    left: resolveThemingValue(props.location === 'left' ? 'minor' : undefined, "spacing", useTheme()),
    right: resolveThemingValue(props.location === 'right' ? 'minor' : undefined, "spacing", useTheme())
  }} className={_c6}>
			<HoverableImage imageUrl={props.iconRawSource} onPress={props.onClick} />
		</div>;
}
const _c0 = " Knu-Flex position-relative ";
const _c1 = " Knu-Flex position-absolute inset-none ";
const _c2 = " size-full ";
const _c3 = " Knu-Flex position-relative flexDirection-column ";
const _c4 = " Knu-FlexCol bg-black-transparent-220 borderTopLeftRadius-5px borderBottomLeftRadius-5px py-tiny px-small position-absolute right-none bottom-small ";
const _c5 = "  ";
const _c6 = " Knu-FlexCol position-absolute ";