import { resolveVariant as h } from "../../render/utils/resolve-dynamic-variants.js";
import { animateTarget as g } from "./visual-element-target.js";
function C(r, t, n = {}) {
  var i;
  const a = h(r, t, n.type === "exit" ? (i = r.presenceContext) === null || i === void 0 ? void 0 : i.custom : void 0);
  let { transition: s = r.getDefaultTransition() || {} } = a || {};
  n.transitionOverride && (s = n.transitionOverride);
  const o = a ? () => Promise.all(g(r, a, n)) : () => Promise.resolve(), d = r.variantChildren && r.variantChildren.size ? (e = 0) => {
    const { delayChildren: f = 0, staggerChildren: m, staggerDirection: l } = s;
    return u(r, t, f + e, m, l, n);
  } : () => Promise.resolve(), { when: c } = s;
  if (c) {
    const [e, f] = c === "beforeChildren" ? [o, d] : [d, o];
    return e().then(() => f());
  } else
    return Promise.all([o(), d(n.delay)]);
}
function u(r, t, n = 0, i = 0, a = 1, s) {
  const o = [], d = (r.variantChildren.size - 1) * i, c = a === 1 ? (e = 0) => e * i : (e = 0) => d - e * i;
  return Array.from(r.variantChildren).sort(y).forEach((e, f) => {
    e.notify("AnimationStart", t), o.push(C(e, t, {
      ...s,
      delay: n + c(f)
    }).then(() => e.notify("AnimationComplete", t)));
  }), Promise.all(o);
}
function y(r, t) {
  return r.sortNodePosition(t);
}
export {
  C as animateVariant,
  y as sortByTreeOrder
};
