import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import * as React from 'react';
import { ChildThemeProvider, Flex, FlexCol, isTouchDevice, LineDivider, useTheme, VirtualList, VirtualListRef, Z_INDEX, toPointerHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { ActionSheet } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/Components';
import { MentionPluginProps } from '@shared/components/RichText/plugins/MentionPlugin';
import * as ReactDOM from 'react-dom';
import { useMenuPosition } from './useMenuPosition';
const MENU_ITEM_HEIGHT = 48;
type MenuProps<T extends MenuOption> = {
  anchor: React.MutableRefObject<HTMLElement>;
  options: T[];
  onClose: () => void;
  itemProps: Parameters<MentionPluginProps<T>['renderMenu']>[0]['itemProps'];
  maxNumberOfOptions?: number;
  renderItem: (option: T, i: number) => React.ReactNode;
};
export const TypeaheadMenu = <T extends MenuOption,>({
  anchor,
  options,
  itemProps,
  renderItem,
  onClose,
  maxNumberOfOptions = 4
}: MenuProps<T>) => {
  const position = useMenuPosition(anchor, onClose);
  const listRef = React.useRef<VirtualListRef | null>(null);
  React.useEffect(() => {
    // initial scroll scroll to selected index
    setTimeout(() => {
      listRef.current?.scrollToItem(itemProps.selectedIndex, 'center');
    });
  }, []);
  React.useEffect(() => {
    listRef.current?.scrollToItem(itemProps.selectedIndex, 'center');
  }, [itemProps.selectedIndex]);
  const renderItems = options.map(option => {
    return {
      key: option.key,
      option,
      height: MENU_ITEM_HEIGHT + 1
    };
  });
  const currentlyVisibleOptions = Math.min(options.length, maxNumberOfOptions);
  if (!position) {
    return null;
  }
  return <MenuWrapper bottom={(position.bottom as number)}>
			<div onClick={toPointerHandler(onClose)} className={_c0}>
				<div onClick={e => e.stopPropagation()} style={{
        position: 'absolute',
        transition: 'height 0.2s cubic-bezier(0.05, 0.7, 0.1, 1) ',
        height: currentlyVisibleOptions * (MENU_ITEM_HEIGHT + 1),
        overflow: 'hidden',
        width: anchor.current.offsetWidth,
        bottom: 0,
        left: position.left
      }}>
					<ActionSheet>
						<VirtualList listRef={listRef} estimatedItemSize={MENU_ITEM_HEIGHT + 1} data={renderItems} renderItem={({
            index,
            item
          }) => {
            return <React.Fragment key={index}>
										<div onMouseLeave={() => {
                itemProps.setHighlightedIndex(-1);
              }} onMouseEnter={toPointerHandler(!isTouchDevice() ? () => {
                itemProps.setHighlightedIndex(index);
              } : undefined)} onClick={() => {
                itemProps.selectOptionAndCleanUp(item.option);
              }} key={index} style={{
                height: resolveThemingValue(MENU_ITEM_HEIGHT, "sizes", useTheme())
              }} className={_c2 + ((itemProps.selectedIndex === index || itemProps.highlightedIndex === index ? "listHoverAtom" : "cardBg") ? resolveIsDarkColor(itemProps.selectedIndex === index || itemProps.highlightedIndex === index ? "listHoverAtom" : "cardBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (itemProps.selectedIndex === index || itemProps.highlightedIndex === index ? _c3 : _c4)}>
											{renderItem(item.option, index)}
										</div>
										{index < renderItems.length - 1 && <div className={_c5}>
												<LineDivider className={_c6} />
											</div>}
									</React.Fragment>;
          }} className={_c1} />
					</ActionSheet>
				</div>
			</div>
		</MenuWrapper>;
};
export const MenuWrapper: React.FC<{
  bottom: number;
  zIndex?: number;
}> = React.memo(props => {
  const [container, setContainer] = React.useState<HTMLElement | null>(null);
  React.useLayoutEffect(() => {
    const backdropContainer = document.createElement('div');
    backdropContainer.style.position = 'fixed';
    backdropContainer.style.top = 0 + 'px';
    backdropContainer.style.left = 0 + 'px';
    backdropContainer.style.bottom = props.bottom + 'px';
    backdropContainer.style.width = '100vw';
    backdropContainer.style.zIndex = `${props.zIndex ?? Z_INDEX.ABOVE_MODAL /* if the backdrop is in modal */}`;
    document.body.appendChild(backdropContainer);
    setContainer(backdropContainer);
    return () => {
      document.body.removeChild(backdropContainer);
    };
  }, []);
  return container ? ReactDOM.createPortal(<ChildThemeProvider theme={useTheme()}>
					{props.children}
				</ChildThemeProvider>, container) : null;
});
MenuWrapper.displayName = 'MenuWrapper';
const _c0 = " Knu-FlexCol position-absolute inset-none ";
const _c1 = "  ";
const _c2 = " Knu-Flex flexShrink-0 cursor-pointer px-small alignItems-center ";
const _c3 = " bg-listHoverAtom ";
const _c4 = " bg-cardBg ";
const _c5 = " Knu-Flex pl-xxlarge ";
const _c6 = "  ";