const a = 2e4;
function o(n) {
  let t = 0;
  const r = 50;
  let e = n.next(t);
  for (; !e.done && t < 2e4; )
    t += r, e = n.next(t);
  return t >= 2e4 ? 1 / 0 : t;
}
export {
  o as calcGeneratorDuration,
  a as maxGeneratorDuration
};
