import { ChannelMessageFragment } from '@generated/graphql';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { useService } from '@knuddels-app/DependencyInjection';
import { colorToRgbString } from '@knuddels-app/tools/colorToRgbString';
import { Flex, rgb, Text, ThemeOverride } from '@knuddels/component-library';
import { ActionMessageFragment } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/Messages/ActionMessage';
import { PrivateGroupMessageFragment } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/Messages/PrivateGroupMessage';
import { ChatRenderConfig } from '@knuddelsModules/Channel/bundle/components/Chat/ChatRenderConfig';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { FormattedText } from '@shared/components';
import { CHANNEL_MESSAGE_COMMAND_CONTEXT } from '@shared/constants';
import * as React from 'react';
import { $MacroBoxService } from '../../../../../providedServiceIds';
export const CondensedMessage: React.FC = ({
  children
}) => {
  return <div className={_c0}>
			{children}
		</div>;
};
type NormalChannelMessage = Exclude<ChannelMessageFragment, ActionMessageFragment | PrivateGroupMessageFragment>;
export const CondensedFormattedText: React.FC<{
  message: NormalChannelMessage;
  renderConfig: ChatRenderConfig;
  isSystemMessage?: boolean;
}> = ({
  message,
  renderConfig,
  isSystemMessage
}) => {
  const profileNavigationService = useService($ProfileNavigationService);
  const macroBoxService = useService($MacroBoxService);
  const handlePress = () => macroBoxService.mayShowMacroBox(message.sender.id) ? macroBoxService.showMacroBox(message.sender.id) : profileNavigationService.showProfile(message.sender.id);
  return <FormattedTextDisplayWithCommandHandling renderIndent getHighlightedLinkColor={() => {
    return rgb(renderConfig.channelGroupInfo.highlightColor.red, renderConfig.channelGroupInfo.highlightColor.green, renderConfig.channelGroupInfo.highlightColor.blue);
  }} commandContext={CHANNEL_MESSAGE_COMMAND_CONTEXT} prefix={<Text bold={true} hoverUnderline={true} onPress={handlePress} color={isSystemMessage ? (colorToRgbString(renderConfig.channelGroupInfo.highlightColorCondensedPrivateMessage) as ThemeOverride) : undefined} className={_c1}>
					{message.sender.nick}:{' '}
				</Text>} text={FormattedText.fromJsonString(message.formattedText)} />;
};
const _c0 = " Knu-Flex px-small flex-1 ";
const _c1 = "  ";