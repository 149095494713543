import * as React from 'react';
import { Column } from '@shared/components';
import { StAppOverviewTitleBar } from './StAppOverviewTitleBar';
import { MessengerConversation } from '@generated/graphql';
import { OverviewMessengerList } from '../../components/MessengerList/OverviewMessengerList';
import { FlexCol } from '@knuddels/component-library';
import { StAppMessengerThemeReadyContext } from '@knuddels-app/StAppMessenger/StAppMessengerThemeReadyContext';
interface Props {
  title: string | React.ReactNode;
  openConversation: (conversationId: MessengerConversation['id'], index: number) => void;
}
export const StAppOverviewListWrapper = React.memo(({
  title,
  openConversation
}: Props) => {
  const [isMinifiedOverview, setIsMinifiedOverview] = React.useState<boolean>();
  const onLayout = (ev: {
    width: number;
  }): void => {
    setIsMinifiedOverview(ev.width < 120);
  };
  const themeReady = React.useContext(StAppMessengerThemeReadyContext);
  return <FlexCol flex={1} bg={themeReady ? 'contentBg' : 'transparent'} borderWidth={'none'} borderColor={themeReady ? 'hairlineAtom' : 'transparent'} borderStyle={'solid'} {...{
    style: {
      borderRightWidth: 1
    }
  }} onLayout={onLayout}>
				{themeReady && <Column fluid>
						<StAppOverviewTitleBar title={title} isMinifiedOverview={isMinifiedOverview} />
						<OverviewMessengerList isMinifiedOverview={isMinifiedOverview} openConversation={openConversation} />
					</Column>}
			</FlexCol>;
});
StAppOverviewListWrapper.displayName = 'StAppOverviewListWrapper';