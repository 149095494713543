import * as React from 'react';
import { FormattedText } from '@shared/components';
import { ChannelMessageFragment, User } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { GenericMessage } from './GenericMessage';
import { observer } from '@knuddels-app/mobx';
import { $UserService } from '@knuddelsModules/UserData';
import { FormattedMessage } from '@knuddels-app/i18n';
import { Text } from '@knuddels/component-library';
import { PRIVATE_MESSAGE_TITLE, TO, YOU } from '../../../../i18n/shared-formats';
import { ClickableUserText } from '../ClickableUser';
import { insertSeparators } from '@knuddels-app/tools/insertSeparators';
import { declareFormat } from '@knuddels/i18n';
import { AnswerButtonWrapper } from './AnswerButtonWrapper';
import { useHandleAnswerButton } from '../ChatItems/useHandleAnswerButton';
export type PrivateGroupMessageFragment = Extract<ChannelMessageFragment, {
  readonly __typename?: 'ChannelMsgPrivateGroup';
}>;
export const PrivateGroupMessage: React.FC<{
  isFirst: boolean;
  isLast: boolean;
  message: PrivateGroupMessageFragment;
  answerButtonColor?: string;
  shouldShowAnswerButton?: boolean;
}> = observer('PrivateGroupMessage', ({
  message,
  isFirst,
  isLast,
  answerButtonColor,
  shouldShowAnswerButton
}) => {
  const activeUser = useService($UserService).currentUser;
  if (!activeUser) {
    return null;
  }
  const handleAnswerButtonPress = useHandleAnswerButton(message.receiver.filter(it => it.id !== activeUser.id));
  const msgNode = <GenericMessage content={FormattedText.fromJsonString(message.formattedText)} title={isFirst && <PrivateGroupMessageTitle message={message} activeUser={activeUser} mode={'normal'} />} />;
  const hasAnswerButton = isLast && message.sender.id !== activeUser.id && shouldShowAnswerButton;
  return <>
				{hasAnswerButton ? <AnswerButtonWrapper onButtonPress={handleAnswerButtonPress} color={answerButtonColor}>
						{msgNode}
					</AnswerButtonWrapper> : msgNode}
			</>;
});
export const PrivateGroupMessageTitle = ({
  message,
  activeUser,
  mode
}: {
  message: PrivateGroupMessageFragment;
  activeUser: Pick<User, 'id' | 'nick'>;
  mode: 'condensed' | 'normal';
}) => {
  const textState = mode === 'condensed' ? 'primary' : 'secondary';
  return <>
			<SenderText mode={mode} sender={message.sender} activeUser={activeUser} />
			<Text bold={true} state={textState} className={_c0}>
				{' '}
				<FormattedMessage id={TO} />{' '}
			</Text>
			<ReceiverText mode={mode} message={message} activeUser={activeUser} />
		</>;
};
const SenderText: React.FC<{
  sender: PrivateGroupMessageFragment['sender'];
  activeUser: Pick<User, 'id' | 'nick'>;
  mode: 'condensed' | 'normal';
}> = ({
  sender,
  mode,
  activeUser
}) => {
  return sender.id === activeUser.id ? <FormattedMessage id={YOU} /> : <ClickableUserText overrideStyles={mode === 'condensed' ? {
    state: 'primary'
  } : undefined} uid={sender.id}>
			{sender.nick}
		</ClickableUserText>;
};
const ReceiverText: React.FC<{
  activeUser: Pick<User, 'id' | 'nick'>;
  message: PrivateGroupMessageFragment;
  mode: 'condensed' | 'normal';
}> = ({
  message,
  activeUser,
  mode
}) => {
  const activeUserId = activeUser.id;
  const receivers = extractActualReceivers(message, activeUser);
  const receiverText = insertSeparators(receivers.map(user => mapReceiver(activeUserId, user, mode)), () => ', ');
  return <FormattedMessage id={PRIVATE_MESSAGE_TITLE} values={{
    name: receiverText
  }} />;
};
function mapReceiver(activeUserId: User['id'], receiver: Pick<User, 'id' | 'nick'>, mode: 'condensed' | 'normal'): JSX.Element {
  return receiver.id === activeUserId ? <FormattedMessage key={receiver.id} id={declareFormat({
    id: 'YOU_PRONOUN',
    defaultFormat: 'you'
  })} /> : <ClickableUserText overrideStyles={mode === 'condensed' ? {
    state: 'primary'
  } : undefined} key={receiver.id} uid={receiver.id}>
			{receiver.nick}
		</ClickableUserText>;
}
function extractActualReceivers(message: PrivateGroupMessageFragment, activeUser: Pick<User, 'id' | 'nick'>): Pick<User, 'id' | 'nick'>[] {
  // receiver contains the sender too, who we don't want to display here
  const receivers: Pick<User, 'id' | 'nick'>[] = message.receiver.filter(it => it.id !== message.sender.id && it.id !== activeUser.id);
  if (message.sender.id !== activeUser.id) {
    receivers.unshift(activeUser);
  }
  return receivers;
}
const _c0 = "  ";