import * as React from 'react';
import { OverlayFactory, useCloseThisOverlay } from '@knuddels-app/overlays';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { IconFriendRemove } from '@knuddels-app/shared-components/IconFriendRemove';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { createMenuContextProvider } from './createMenuContextProvider';
import { endFriendshipFormat, fotomeetRemoveFormat, removeFromWatchlistFormat } from '../formats';
import { $ContactsService, $WatchlistService, ContactsTab, ContactsUser } from '@knuddelsModules/Contacts';
import { useTrackContactView } from '../useTrackContactView';
import { useCancelFriendship } from '../useCancelFriendship';
import { useRemoveMatch } from '../useRemoveMatch';
import { IconRemoveFromWatchlist } from '@knuddels-app/shared-components/IconRemoveFromWatchlist';
const AllContextMenu: React.FC<ContextMenuProps & {
  userId: string;
  menuEntriesToShow: ContactsTab[];
}> = ({
  userId,
  menuEntriesToShow,
  ...menuProps
}) => {
  const closeThisOverlay = useCloseThisOverlay();
  const cancelFriendship = useCancelFriendship();
  const removeFotomeeetMatch = useRemoveMatch();
  const track = useTrackContactView();
  const i18n = useService($I18n);
  return <ContextMenu {...menuProps} closeContextMenu={closeThisOverlay}>
			{menuEntriesToShow?.map(entry => {
      switch (entry) {
        case 'friends':
          return <ContextMenuEntry key={entry} icon={IconFriendRemove} text={i18n.format(endFriendshipFormat)} onClick={() => {
            track('Friendstab_EndFriendship');
            closeThisOverlay();
            cancelFriendship(userId);
          }} />;
        case 'fotomeet':
          return <ContextMenuEntry key={entry} icon={IconFriendRemove} text={i18n.format(fotomeetRemoveFormat)} onClick={() => {
            track('RemoveFotomeetMatch');
            closeThisOverlay();
            removeFotomeeetMatch(userId);
          }} />;
        case 'watchlist':
          return <WatchlistContextMenuEntry key={entry} userId={userId} text={removeFromWatchlistFormat.format(i18n)} onClick={() => {
            track('RemoveFromWatchlist');
            closeThisOverlay();
          }} />;
        default:
          return null;
      }
    })}
		</ContextMenu>;
};
const WatchlistContextMenuEntry: React.FC<{
  userId: string;
  text: string;
  onClick: () => void;
}> = ({
  userId,
  text,
  onClick
}) => {
  const watchlistService = useService($WatchlistService);
  return <ContextMenuEntry icon={IconRemoveFromWatchlist} text={text} onClick={() => {
    watchlistService.removeFromWatchlist(userId);
    onClick();
  }} />;
};
const getMenuEntriesToShow = (user: ContactsUser, contactService: typeof $ContactsService.T) => {
  const hasFotomeet = contactService.getContactsForTab('fotomeet').some(contact => contact.id === user.id);
  const menuEntries: ContactsTab[] = [];
  if (user.friendState === 'FRIENDS') {
    menuEntries.push('friends');
  }
  if (user.isOnWatchlist) {
    menuEntries.push('watchlist');
  }
  if (hasFotomeet) {
    menuEntries.push('fotomeet');
  }
  return menuEntries;
};
export const AllContextMenuOverlay = new OverlayFactory(AllContextMenu, props => ({
  userId: props.userId,
  menuEntriesToShow: props.menuEntriesToShow
}));
export const FotomeetMenuContextProvider = createMenuContextProvider(AllContextMenuOverlay, () => ['fotomeet']);
export const WatchlistMenuContextProvider = createMenuContextProvider(AllContextMenuOverlay, () => ['watchlist']);
export const FriendsMenuContextProvider = createMenuContextProvider(AllContextMenuOverlay, () => ['friends']);
export const AllMenuContextProvider = createMenuContextProvider(AllContextMenuOverlay, getMenuEntriesToShow);