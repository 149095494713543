import { createTrackingEventType } from '@knuddels-app/analytics/eventCreators';
import { Category } from '@knuddels-app/analytics/Category';
import { createUserTimingTracker } from '@knuddels-app/analytics/userTimingTracking';

export const channellistEvent = createTrackingEventType(
	Category.ChannelSelectionAll,
	'All_Channellist'
);

export const categoryOverviewEvent = createTrackingEventType(
	Category.ChannelSelectionAll,
	'All_CategoryOverview'
);

export const allChannelsShownTimingTracker = createUserTimingTracker(
	'ChannelSelection',
	'AllChannelsShown'
);
