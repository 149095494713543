import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import * as React from 'react';
import { AmountFormat, formatAmount } from '@knuddels-app/tools/formatAmount';
import { Text, ThemeColors } from '@knuddels/component-library';
export const Amount = injectedComponent({
  name: 'Amount',
  inject: {
    i18n: $I18n
  },
  props: declareProps<{
    amount: number | undefined | null;
    textType: 'body1' | 'body2';
    numberFormat: AmountFormat;
    textColor?: ThemeColors;
  }>()
}, ({
  i18n,
  amount,
  textType,
  numberFormat,
  textColor
}) => {
  if (amount === undefined || amount === null) {
    return null;
  }
  return <Text type={textType} color={textColor} darkColor={textColor} handleDarkProps>
				{formatAmount(amount, numberFormat, i18n)}
			</Text>;
});