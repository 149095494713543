import { newServiceId } from '../ServiceId';
import { K3Container } from './K3Container';

/**
 * The container binds itself to this id.
 */
export const $K3Container = newServiceId<K3Container>('$K3Container');

/**
 * This service id is used to automatically start services after binding.
 */
export const $autostart = newServiceId<unknown>('$autostart');
