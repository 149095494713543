import { Feature as s } from "../Feature.js";
let o = 0;
class u extends s {
  constructor() {
    super(...arguments), this.id = o++;
  }
  update() {
    if (!this.node.presenceContext)
      return;
    const { isPresent: t, onExitComplete: e } = this.node.presenceContext, { isPresent: n } = this.node.prevPresenceContext || {};
    if (!this.node.animationState || t === n)
      return;
    const i = this.node.animationState.setActive("exit", !t);
    e && !t && i.then(() => e(this.id));
  }
  mount() {
    const { register: t } = this.node.presenceContext || {};
    t && (this.unmount = t(this.id));
  }
  unmount() {
  }
}
export {
  u as ExitAnimationFeature
};
