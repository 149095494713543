import { Module } from '@knuddels-app/ModuleSystem';
import { channelModule } from '@knuddelsModules/Channel/module';
import * as providedServices from './providedServices';
import { promotionModule } from '@knuddelsModules/Promotions/module';

export const knuddelDisplayModule = new Module({
	name: 'KnuddelDisplay module',
	dependencies: [channelModule, promotionModule],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
