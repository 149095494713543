import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase/serviceId';

export const useLogEvent = () => {
	const analyticsService = useService($FirebaseAnalyticsService);

	return React.useCallback(
		(contentType: string, itemId: string) => {
			analyticsService.logEvent(contentType, itemId);
		},
		[analyticsService]
	);
};
