import { useService } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { useSnackbar } from '@shared/components';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { IconKiss } from '@knuddelsModules/Profile/bundle/components/shared/IconKiss';
import * as React from 'react';
import { Box } from '@knuddels/component-library';
export const useDoKiss = (trackSnackbar: (message: string) => void = () => {}) => {
  const commandService = useService($CommandService);
  const getActiveChannelService = useLazyService($ActiveChannelService);
  const showNotInSameChannelSnackbar = useNotInSameChannelSnackbar();
  const showSuccessSnackbar = useSuccessSnackbar();
  return async (userId: string, nick: string, shouldShowSuccessSnackbar = false) => {
    const activeChannel = (await getActiveChannelService()).activeChannel;
    if (!activeChannel || !activeChannel.participants.has(userId)) {
      trackSnackbar('KissNotInSameChannelError');
      showNotInSameChannelSnackbar();
      return;
    }
    await commandService.parseAndInvokeCommand(`/kiss ${nick}`);
    if (shouldShowSuccessSnackbar) {
      showSuccessSnackbar(nick);
    }
  };
};
const useNotInSameChannelSnackbar = () => {
  const showSnackbar = useSnackbar();
  const i18n = useService($I18n);
  return () => {
    showSnackbar({
      type: 'NotInSameChannelKiss',
      text: declareFormat({
        id: 'profile.do-kiss.not-in-same-channel.text',
        defaultFormat: "You're not in the same channel"
      }).format(i18n),
      subtext: declareFormat({
        id: 'profile.do-kiss.not-in-same-channel.subtext',
        defaultFormat: 'You need to be in the same channel to kiss someone'
      }).format(i18n),
      adornment: require('@shared/images/sorry.gif')
    });
  };
};
const useSuccessSnackbar = () => {
  const showSnackbar = useSnackbar();
  const i18n = useService($I18n);
  return (nick: string) => {
    showSnackbar({
      type: 'NotInSameChannelKiss',
      text: declareFormat({
        id: 'profile.do-kiss.success.text',
        defaultFormat: 'You sent a kiss to {nick}'
      }).format(i18n, {
        nick
      }),
      adornment: <div className={_c0}>
					<IconKiss size={'large'} />
				</div>
    });
  };
};
const _c0 = " Knu-Box mr-minor ";