import * as React from 'react';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { Spacer } from '@shared/components';
import { FlexCol, ListGroup, SwitchListItem } from '@knuddels/component-library';
import { action } from '@knuddels-app/mobx';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { SoundEvent } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
@injectable()
class SoundSettingsModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  @action.bound
  public async updateSoundEvents(checked: boolean, event: SoundEvent): Promise<void> {
    this.firebaseAnalyticsService.logEvent('Settings_Sounds', checked ? `${event}_Activated` : `${event}_Deactivated`);
    try {
      checked ? await this.clientSettingsService.enableSoundEvent(event) : await this.clientSettingsService.disableSoundEvent(event);
    } catch (e) {
      this.snackbarService.showGenericError();
    }
  }
  public get isSoundOnFriendRequestEnabled(): boolean | undefined {
    return this.clientSettingsService.enabledSoundEvents.some(event => event === SoundEvent.FriendRequestAccepted);
  }
  public get isSoundOnNewMessageEnabled(): boolean | undefined {
    return this.clientSettingsService.enabledSoundEvents.some(event => event === SoundEvent.NewMessageReceived);
  }
  public get isSoundOnEngagementSystemAppEnabled(): boolean | undefined {
    return this.clientSettingsService.enabledSoundEvents.some(event => event === SoundEvent.EngagementSystemApp);
  }
  public get isSoundOnVipAppEnabled(): boolean | undefined {
    return this.clientSettingsService.enabledSoundEvents.some(event => event === SoundEvent.VipApp);
  }
  public get isSoundOnLoyaltySystemEnabled(): boolean | undefined {
    return this.clientSettingsService.enabledSoundEvents.some(event => event === SoundEvent.LoyaltyApp);
  }
  public get isGlobalSoundsEnabled(): boolean | undefined {
    return this.clientSettingsService.enabledSoundEvents.some(event => event === SoundEvent.Global);
  }
}
export const SoundSettings = injectedComponent({
  name: 'SoundSettings',
  model: SoundSettingsModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => {
  return <div className={_c0}>
				<ListGroup title={declareFormat({
      id: 'settings.soundsSettings.settingsGroup.title',
      defaultFormat: 'Sounds'
    }).format(i18n)}>
					<SwitchListItem caption={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.global',
        defaultFormat: 'All sounds'
      }).format(i18n)} checked={model.isGlobalSoundsEnabled} onChange={checked => model.updateSoundEvents(checked, SoundEvent.Global)} separator={'none'} />
				</ListGroup>
				{model.isGlobalSoundsEnabled && <ListGroup>
						<SwitchListItem caption={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.friendRequestAccepted',
        defaultFormat: 'Friendship request accepted'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.friendRequestAccepted.subtitle',
        defaultFormat: 'Sounds when a friendship is accepted'
      }).format(i18n)} checked={model.isSoundOnFriendRequestEnabled} onChange={checked => model.updateSoundEvents(checked, SoundEvent.FriendRequestAccepted)} />
						<SwitchListItem caption={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.newMessage',
        defaultFormat: 'New Messages'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.newMessage.subtitle',
        defaultFormat: 'Sounds for new messages in Messenger'
      }).format(i18n)} checked={model.isSoundOnNewMessageEnabled} onChange={checked => model.updateSoundEvents(checked, SoundEvent.NewMessageReceived)} />
						<SwitchListItem caption={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.engagementSystemApp',
        defaultFormat: 'Quests'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.engagementSystemApp.subtitle',
        defaultFormat: 'Sounds for Quests'
      }).format(i18n)} checked={model.isSoundOnEngagementSystemAppEnabled} onChange={checked => model.updateSoundEvents(checked, SoundEvent.EngagementSystemApp)} />
						<SwitchListItem caption={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.vipApp',
        defaultFormat: 'VIP'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.vipApp.subtitle',
        defaultFormat: 'Sounds for VIP'
      }).format(i18n)} checked={model.isSoundOnVipAppEnabled} onChange={checked => model.updateSoundEvents(checked, SoundEvent.VipApp)} />
						<SwitchListItem caption={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.loyaltyApp',
        defaultFormat: 'Loyalty System'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.soundsSettings.settingsGroup.loyaltyApp.subtitle',
        defaultFormat: 'Sounds for Loyalty System'
      }).format(i18n)} checked={model.isSoundOnLoyaltySystemEnabled} onChange={checked => model.updateSoundEvents(checked, SoundEvent.LoyaltyApp)} />
					</ListGroup>}
				<Spacer size={'xxxlarge'} />
			</div>;
});
const _c0 = " Knu-FlexCol height-100-percent ";