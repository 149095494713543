import { newServiceId } from '@knuddels-app/DependencyInjection';
import { CommandService } from './CommandService';
import { CommandWithoutChannelService } from './CommandWithoutChannelService';
import { CoreCommands } from './CoreCommands';

export const $CommandService = newServiceId<CommandService>('$CommandService');
export const $CommandWithoutChannelService = newServiceId<
	CommandWithoutChannelService
>('$CommandWithoutChannelService');

export const $CoreCommands = newServiceId<CoreCommands>('$CoreCommands');
