import * as React from 'react';
import { CircularProgress } from './CircularProgress';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { Flex, FlexCol, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
interface Props {
  progress: number;
}
@injectable()
class ProfileFillBarModel {
  constructor(@injectProps()
  public readonly props: Props) {}
  public get percentage(): number {
    return Math.round(this.props.progress * 100);
  }
}
export const ProfileFillBar = injectedComponent({
  name: 'ProfileFillBar',
  model: ProfileFillBarModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<Props>()
}, ({
  i18n,
  model
}) => <div className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c1}>
				<Text type={'body1'} bold={true} className={_c2}>
					{declareFormat({
        id: 'EDIT_PROFILE_PROGRESS_TITLE',
        defaultFormat: 'Complete your profile!'
      }).format(i18n)}
				</Text>
				<Text type={'body1'} state={'tertiary'} className={_c3}>
					{declareFormat({
        id: 'EDIT_PROFILE_PROGRESS_TEXT',
        defaultFormat: 'Make yourself interesting und increase your chances of getting messaged.'
      }).format(i18n)}
				</Text>
			</div>
			<CircularProgress percentage={model.percentage} size={56}>
				{p => <Text type={'body2'} state={'secondary'} bold={true} className={_c4}>
						{`${Math.round(p)}%`}
					</Text>}
			</CircularProgress>
		</div>);
const _c0 = " Knu-Flex position-relative flexGrow-1 p-base bg-contentLightBg alignItems-center ";
const _c1 = " Knu-FlexCol flex-1 ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";