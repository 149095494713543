import { AppViewer } from './AppViewer';
import { AppInstance, AppInstanceData } from './AppInstance';
import { action, observable } from '@knuddels-app/mobx';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { ViewId } from '@knuddels-app/layout';
@injectable()
export class CustomPositionAppViewer implements AppViewer {
  @observable
  public apps: Map<string, AppInstance> = new Map();
  public appViewIds: Map<string, ViewId<any>> = new Map();
  constructor(@inject($FirebaseAnalyticsService)
  private firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  @action.bound
  addApp(app: AppInstance): void {
    // We don't want to track the meta data
    const appIdWithoutMetaData = stripMetaDataFromAppId(app.appId);
    this.firebaseAnalyticsService.logEvent('SystemApps_ModalView', `${appIdWithoutMetaData}_Opened`);
    this.apps.set(app.appId, app);
  }
  @action.bound
  closeAllApps(): void {
    this.apps.forEach(app => app?.close());
  }
  @action.bound
  closeAllAppsExceptForChannel(): void {}
  @action.bound
  closeApp(appId: string): void {
    // We don't want to track the meta data
    const appIdWithoutMetaData = stripMetaDataFromAppId(appId);
    this.firebaseAnalyticsService.logEvent('SystemApps_ModalView', `${appIdWithoutMetaData}_Closed`);
    const app = this.apps.get(appId);
    if (app) {
      app.close();
    }
  }
  @action.bound
  removeApp(appId: string): void {
    const app = this.apps.get(appId);
    if (!app) {
      return;
    }
    this.apps.delete(appId);
    this.appViewIds.delete(appId);
  }
  getApp(appId: string): AppInstance | undefined {
    return this.apps.get(appId);
  }
  @action.bound
  registerApp(appId: string, viewId?: ViewId<any>): void {
    this.apps.set(appId, null);
    if (viewId) {
      this.appViewIds.set(appId, viewId);
    }
  }
  canHandleAppEvent(app: AppInstanceData): boolean {
    return this.apps.has(app.appId);
  }
  getAllApps(): AppInstance[] {
    return Array.from(this.apps.values());
  }
}
function stripMetaDataFromAppId(appId: string): string {
  // see in chatplatform: SystemApp.META_SEPARATOR
  const separator = ':';
  const appIdWithoutMetaData = appId.split(separator)[0];
  return appIdWithoutMetaData;
}