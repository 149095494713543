import { initPrefersReducedMotion as S } from "../utils/reduced-motion/index.js";
import { hasReducedMotionListener as b, prefersReducedMotion as P } from "../utils/reduced-motion/state.js";
import { SubscriptionManager as T } from "../utils/subscription-manager.js";
import { motionValue as R } from "../value/index.js";
import { isMotionValue as l } from "../value/utils/is-motion-value.js";
import { transformProps as F } from "./html/utils/transform.js";
import { isControllingVariants as x, isVariantNode as w } from "./utils/is-controlling-variants.js";
import { isVariantLabel as B } from "./utils/is-variant-label.js";
import { updateMotionValuesFromProps as N } from "./utils/motion-values.js";
import { resolveVariantFromProps as E } from "./utils/resolve-variants.js";
import { featureDefinitions as p } from "../motion/features/definitions.js";
import { variantProps as v } from "./utils/variant-props.js";
import { visualElementStore as d } from "./store.js";
import { KeyframeResolver as j } from "./utils/KeyframesResolver.js";
import { isNumericalString as y } from "../utils/is-numerical-string.js";
import { isZeroValueString as I } from "../utils/is-zero-value-string.js";
import { findValueType as A } from "./dom/value-types/find.js";
import { complex as L } from "../value/types/complex/index.js";
import { getAnimatableNone as U } from "./dom/value-types/animatable-none.js";
import { createBox as _ } from "../projection/geometry/models.js";
import { frame as f, cancelFrame as c } from "../frameloop/frame.js";
const m = [
  "AnimationStart",
  "AnimationComplete",
  "Update",
  "BeforeLayoutMeasure",
  "LayoutMeasure",
  "LayoutAnimationStart",
  "LayoutAnimationComplete"
], D = v.length;
class lt {
  /**
   * This method takes React props and returns found MotionValues. For example, HTML
   * MotionValues will be found within the style prop, whereas for Three.js within attribute arrays.
   *
   * This isn't an abstract method as it needs calling in the constructor, but it is
   * intended to be one.
   */
  scrapeMotionValuesFromProps(t, e, s) {
    return {};
  }
  constructor({ parent: t, props: e, presenceContext: s, reducedMotionConfig: i, blockInitialAnimation: r, visualState: o }, V = {}) {
    this.applyWillChange = !1, this.resolveKeyframes = (a, h, C, M) => new this.KeyframeResolver(a, h, C, M, this), this.current = null, this.children = /* @__PURE__ */ new Set(), this.isVariantNode = !1, this.isControllingVariants = !1, this.shouldReduceMotion = null, this.values = /* @__PURE__ */ new Map(), this.KeyframeResolver = j, this.features = {}, this.valueSubscriptions = /* @__PURE__ */ new Map(), this.prevMotionValues = {}, this.events = {}, this.propEventSubscriptions = {}, this.notifyUpdate = () => this.notify("Update", this.latestValues), this.render = () => {
      this.isRenderScheduled = !1, this.current && (this.triggerBuild(), this.renderInstance(this.current, this.renderState, this.props.style, this.projection));
    }, this.isRenderScheduled = !1, this.scheduleRender = () => {
      this.isRenderScheduled || (this.isRenderScheduled = !0, f.render(this.render, !1, !0));
    };
    const { latestValues: n, renderState: g } = o;
    this.latestValues = n, this.baseTarget = { ...n }, this.initialValues = e.initial ? { ...n } : {}, this.renderState = g, this.parent = t, this.props = e, this.presenceContext = s, this.depth = t ? t.depth + 1 : 0, this.reducedMotionConfig = i, this.options = V, this.blockInitialAnimation = !!r, this.isControllingVariants = x(e), this.isVariantNode = w(e), this.isVariantNode && (this.variantChildren = /* @__PURE__ */ new Set()), this.manuallyAnimateOnMount = !!(t && t.current);
    const { willChange: O, ...u } = this.scrapeMotionValuesFromProps(e, {}, this);
    for (const a in u) {
      const h = u[a];
      n[a] !== void 0 && l(h) && h.set(n[a], !1);
    }
  }
  mount(t) {
    this.current = t, d.set(t, this), this.projection && !this.projection.instance && this.projection.mount(t), this.parent && this.isVariantNode && !this.isControllingVariants && (this.removeFromVariantTree = this.parent.addVariantChild(this)), this.values.forEach((e, s) => this.bindToMotionValue(s, e)), b.current || S(), this.shouldReduceMotion = this.reducedMotionConfig === "never" ? !1 : this.reducedMotionConfig === "always" ? !0 : P.current, this.parent && this.parent.children.add(this), this.update(this.props, this.presenceContext);
  }
  unmount() {
    d.delete(this.current), this.projection && this.projection.unmount(), c(this.notifyUpdate), c(this.render), this.valueSubscriptions.forEach((t) => t()), this.removeFromVariantTree && this.removeFromVariantTree(), this.parent && this.parent.children.delete(this);
    for (const t in this.events)
      this.events[t].clear();
    for (const t in this.features) {
      const e = this.features[t];
      e && (e.unmount(), e.isMounted = !1);
    }
    this.current = null;
  }
  bindToMotionValue(t, e) {
    const s = F.has(t), i = e.on("change", (o) => {
      this.latestValues[t] = o, this.props.onUpdate && f.preRender(this.notifyUpdate), s && this.projection && (this.projection.isTransformDirty = !0);
    }), r = e.on("renderRequest", this.scheduleRender);
    this.valueSubscriptions.set(t, () => {
      i(), r(), e.owner && e.stop();
    });
  }
  sortNodePosition(t) {
    return !this.current || !this.sortInstanceNodePosition || this.type !== t.type ? 0 : this.sortInstanceNodePosition(this.current, t.current);
  }
  updateFeatures() {
    let t = "animation";
    for (t in p) {
      const e = p[t];
      if (!e)
        continue;
      const { isEnabled: s, Feature: i } = e;
      if (!this.features[t] && i && s(this.props) && (this.features[t] = new i(this)), this.features[t]) {
        const r = this.features[t];
        r.isMounted ? r.update() : (r.mount(), r.isMounted = !0);
      }
    }
  }
  triggerBuild() {
    this.build(this.renderState, this.latestValues, this.props);
  }
  /**
   * Measure the current viewport box with or without transforms.
   * Only measures axis-aligned boxes, rotate and skew must be manually
   * removed with a re-render to work.
   */
  measureViewportBox() {
    return this.current ? this.measureInstanceViewportBox(this.current, this.props) : _();
  }
  getStaticValue(t) {
    return this.latestValues[t];
  }
  setStaticValue(t, e) {
    this.latestValues[t] = e;
  }
  /**
   * Update the provided props. Ensure any newly-added motion values are
   * added to our map, old ones removed, and listeners updated.
   */
  update(t, e) {
    (t.transformTemplate || this.props.transformTemplate) && this.scheduleRender(), this.prevProps = this.props, this.props = t, this.prevPresenceContext = this.presenceContext, this.presenceContext = e;
    for (let s = 0; s < m.length; s++) {
      const i = m[s];
      this.propEventSubscriptions[i] && (this.propEventSubscriptions[i](), delete this.propEventSubscriptions[i]);
      const r = "on" + i, o = t[r];
      o && (this.propEventSubscriptions[i] = this.on(i, o));
    }
    this.prevMotionValues = N(this, this.scrapeMotionValuesFromProps(t, this.prevProps, this), this.prevMotionValues), this.handleChildMotionValue && this.handleChildMotionValue();
  }
  getProps() {
    return this.props;
  }
  /**
   * Returns the variant definition with a given name.
   */
  getVariant(t) {
    return this.props.variants ? this.props.variants[t] : void 0;
  }
  /**
   * Returns the defined default transition on this component.
   */
  getDefaultTransition() {
    return this.props.transition;
  }
  getTransformPagePoint() {
    return this.props.transformPagePoint;
  }
  getClosestVariantNode() {
    return this.isVariantNode ? this : this.parent ? this.parent.getClosestVariantNode() : void 0;
  }
  getVariantContext(t = !1) {
    if (t)
      return this.parent ? this.parent.getVariantContext() : void 0;
    if (!this.isControllingVariants) {
      const s = this.parent ? this.parent.getVariantContext() || {} : {};
      return this.props.initial !== void 0 && (s.initial = this.props.initial), s;
    }
    const e = {};
    for (let s = 0; s < D; s++) {
      const i = v[s], r = this.props[i];
      (B(r) || r === !1) && (e[i] = r);
    }
    return e;
  }
  /**
   * Add a child visual element to our set of children.
   */
  addVariantChild(t) {
    const e = this.getClosestVariantNode();
    if (e)
      return e.variantChildren && e.variantChildren.add(t), () => e.variantChildren.delete(t);
  }
  /**
   * Add a motion value and bind it to this visual element.
   */
  addValue(t, e) {
    const s = this.values.get(t);
    e !== s && (s && this.removeValue(t), this.bindToMotionValue(t, e), this.values.set(t, e), this.latestValues[t] = e.get());
  }
  /**
   * Remove a motion value and unbind any active subscriptions.
   */
  removeValue(t) {
    this.values.delete(t);
    const e = this.valueSubscriptions.get(t);
    e && (e(), this.valueSubscriptions.delete(t)), delete this.latestValues[t], this.removeValueFromRenderState(t, this.renderState);
  }
  /**
   * Check whether we have a motion value for this key
   */
  hasValue(t) {
    return this.values.has(t);
  }
  getValue(t, e) {
    if (this.props.values && this.props.values[t])
      return this.props.values[t];
    let s = this.values.get(t);
    return s === void 0 && e !== void 0 && (s = R(e === null ? void 0 : e, { owner: this }), this.addValue(t, s)), s;
  }
  /**
   * If we're trying to animate to a previously unencountered value,
   * we need to check for it in our state and as a last resort read it
   * directly from the instance (which might have performance implications).
   */
  readValue(t, e) {
    var s;
    let i = this.latestValues[t] !== void 0 || !this.current ? this.latestValues[t] : (s = this.getBaseTargetFromProps(this.props, t)) !== null && s !== void 0 ? s : this.readValueFromInstance(this.current, t, this.options);
    return i != null && (typeof i == "string" && (y(i) || I(i)) ? i = parseFloat(i) : !A(i) && L.test(e) && (i = U(t, e)), this.setBaseTarget(t, l(i) ? i.get() : i)), l(i) ? i.get() : i;
  }
  /**
   * Set the base target to later animate back to. This is currently
   * only hydrated on creation and when we first read a value.
   */
  setBaseTarget(t, e) {
    this.baseTarget[t] = e;
  }
  /**
   * Find the base target for a value thats been removed from all animation
   * props.
   */
  getBaseTarget(t) {
    var e;
    const { initial: s } = this.props;
    let i;
    if (typeof s == "string" || typeof s == "object") {
      const o = E(this.props, s, (e = this.presenceContext) === null || e === void 0 ? void 0 : e.custom);
      o && (i = o[t]);
    }
    if (s && i !== void 0)
      return i;
    const r = this.getBaseTargetFromProps(this.props, t);
    return r !== void 0 && !l(r) ? r : this.initialValues[t] !== void 0 && i === void 0 ? void 0 : this.baseTarget[t];
  }
  on(t, e) {
    return this.events[t] || (this.events[t] = new T()), this.events[t].add(e);
  }
  notify(t, ...e) {
    this.events[t] && this.events[t].notify(...e);
  }
}
export {
  lt as VisualElement
};
