import * as React from 'react';
import { ContextMenuEntry } from '@shared/components';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
import { openInNewTab } from '@shared/helper/openUrl';
import { IconSettings } from '@knuddels/component-library';
export type PhotoAdministrationContextMenuProps = {
  administrationUrl: string;
};
export const PhotoAdministrationContextMenu: React.FC<PhotoAdministrationContextMenuProps> = props => {
  const track = useTrackAlbumDetails();
  return <ContextMenuEntry icon={IconSettings} text={formatMessage(declareFormat({
    id: 'album.details.administrate-photo',
    defaultFormat: 'Administrate photo'
  }))} onClick={() => {
    openInNewTab(props.administrationUrl);
    track('AdministrateImage_Clicked');
  }} />;
};