import * as React from 'react';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { ContextMenu, ContextMenuProps } from '@shared/components';
import { DeleteAlbumContextMenuEntry } from './DeleteAlbumContextMenuEntry';
export const EditAlbumContextMenu: React.FC<ContextMenuProps & {
  albumId: string;
  refetchProfile: () => void;
}> = ({
  albumId,
  refetchProfile,
  ...props
}) => {
  const closeContextMenu = useCloseThisOverlay();
  return <ContextMenu {...props} closeContextMenu={closeContextMenu}>
			<DeleteAlbumContextMenuEntry albumId={albumId} refetchProfile={refetchProfile} />
		</ContextMenu>;
};