import * as React from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Carousel, ChildThemeProvider, DarkTheme, FlexCol, IconChevronLeft, IconChevronRight } from '@knuddels/component-library';
import { AlbumDetailPhotoFragment, NotifyAlbumPhotosViewed, NotifyProfilePicturedViewed } from '@generated/graphql';
import { AlbumCarouselItem, AlbumPhotoCarouselItemData } from './AlbumCarouselItem';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { OpenAlbumDetailsContextMenu } from '../AlbumDetailsContextMenu/OpenAlbumDetailsContextMenu';
import { ProfileAlbumInfo } from '../../profileQueryHelper';
import { useCloseAlbumDetails } from '../CloseAlbumDetails';
import { IsEditingPhotoDescriptionContext } from '../EditPhotoDescriptionContext';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { PROFILE_PICTURE } from '../../../i18n/shared-formats';
import { IconWithShadow } from '@knuddelsModules/Profile/bundle/components/shared/IconWithShadow';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { AlbumDetailsUserContext } from '@knuddelsModules/Profile/bundle/components/AlbumDetails/AlbumDetailsUserContext';
import { $UserService } from '@knuddelsModules/UserData';
export interface AlbumDetailsCarouselProps {
  initialPhotoId?: any;
  albumInfo: ProfileAlbumInfo;
  hideDescriptionBar?: boolean;
  enableKeyboardNavigation?: boolean;
  onSelectedAlbumPhotoChanged?(photoId: AlbumDetailPhotoFragment['id']): void;
}
export const AlbumDetailsCarousel: React.FC<AlbumDetailsCarouselProps> = props => {
  const {
    photos,
    selectedIndex,
    onSelectedIndexChanged
  } = useAlbumDetailsCarouselPhotos(props);
  const isStackedLayout = useIsStackedLayout();
  const isEditingDescription = React.useContext(IsEditingPhotoDescriptionContext);
  return <ChildThemeProvider theme={DarkTheme} styles={{
    width: '100%',
    height: '100%',
    // This is needed so the rounded corners of the mobile bottom drawer
    // overlay the carousel
    marginBottom: isStackedLayout ? '-16px' : 0,
    minHeight: 0
  }}>
			<div className={_c0}>
				<Carousel key={photos.length} initialIndex={selectedIndex % photos.length} onChange={onSelectedIndexChanged} prevAdornment={onPress => <Arrow type={'left'} onPress={onPress} />} nextAdornment={onPress => <Arrow type={'right'} onPress={onPress} />} disabled={isEditingDescription} keyboardNavigation={props.enableKeyboardNavigation}>
					{photos.map((item, index) => <Carousel.Slide key={item?.id || index}>
							<AlbumCarouselItem item={item} width={'100%'} height={'100%'} hideDescriptionBar={props.hideDescriptionBar} />
						</Carousel.Slide>)}
				</Carousel>

				{!isEditingDescription && <OpenAlbumDetailsContextMenu />}
			</div>
		</ChildThemeProvider>;
};
const useAlbumDetailsCarouselPhotos = ({
  albumInfo,
  initialPhotoId,
  onSelectedAlbumPhotoChanged
}: AlbumDetailsCarouselProps) => {
  const closeAlbumDetails = useCloseAlbumDetails();
  const i18n = useService($I18n);
  const photos = useMemo(() => {
    return albumInfo?.albums.reduce<AlbumPhotoCarouselItemData[]>((acc, currentAlbum) => [...acc, ...currentAlbum.albumPhotos.map((photo, index) => ({
      ...photo,
      albumTitle: currentAlbum.title,
      albumLength: currentAlbum.albumPhotos.length,
      photoIndex: index
    }))], albumInfo?.albumProfilePhoto ? [{
      ...albumInfo.albumProfilePhoto,
      albumTitle: i18n.format(PROFILE_PICTURE)
    }] : []);
  }, [albumInfo?.albums]);
  const [selectedIndex, setSelectedIndex] = useState(() => photos.findIndex(p => p.id === initialPhotoId));
  const {
    notifyProfilePictureViewed,
    notifyAlbumPhotoViewed
  } = useNotifyPhotoViewed();
  useEffect(() => {
    if (photos.length === 0) {
      closeAlbumDetails();
      return;
    }

    // update selected photo id to keep it in sync if photos are deleted.
    const selectedPhoto = photos[(selectedIndex + photos.length) % photos.length];
    if (selectedPhoto) {
      onSelectedAlbumPhotoChanged(selectedPhoto.id);
      const isProfilePicture = typeof selectedPhoto.albumLength === 'undefined';
      if (isProfilePicture) {
        notifyProfilePictureViewed();
      } else {
        notifyAlbumPhotoViewed();
      }
    }
  }, [photos, selectedIndex]);
  return {
    photos,
    selectedIndex,
    onSelectedIndexChanged: (index: number) => {
      setSelectedIndex(index);
    }
  };
};
const useNotifyPhotoViewed = () => {
  const authenticatedClientService = useService($AuthenticatedClientService);
  const viewedProfilePicture = useRef(false);
  const viewedAlbumPhoto = useRef(false);
  const {
    id
  } = useContext(AlbumDetailsUserContext);
  const userService = useService($UserService);
  return {
    notifyProfilePictureViewed: React.useCallback(() => {
      if (viewedProfilePicture.current || userService.isCurrentUser(id)) {
        return;
      }
      viewedProfilePicture.current = true;
      authenticatedClientService.currentK3Client.mutate(NotifyProfilePicturedViewed, {
        userId: id
      });
    }, [authenticatedClientService, viewedProfilePicture, id]),
    notifyAlbumPhotoViewed: React.useCallback(() => {
      if (viewedAlbumPhoto.current || userService.isCurrentUser(id)) {
        return;
      }
      viewedAlbumPhoto.current = true;
      authenticatedClientService.currentK3Client.mutate(NotifyAlbumPhotosViewed, {
        userId: id
      });
    }, [authenticatedClientService, viewedAlbumPhoto, id])
  };
};
function Arrow(props: {
  type: 'left' | 'right';
  onPress(): void;
}): React.ReactElement {
  return <IconWithShadow InnerIcon={props.type === 'left' ? IconChevronLeft : IconChevronRight} onPress={props.onPress} />;
}
const _c0 = " Knu-FlexCol flex-1 minWidth-0-px minHeight-0-px maxHeight-100-percent maxWidth-100-percent position-relative ";