import * as React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	BLUR_COMMAND,
	COMMAND_PRIORITY_NORMAL,
	FOCUS_COMMAND,
	KEY_ARROW_DOWN_COMMAND,
	KEY_ARROW_UP_COMMAND,
	KEY_ENTER_COMMAND,
	KEY_ESCAPE_COMMAND,
	KEY_TAB_COMMAND,
} from 'lexical';
import { useEvent } from '@knuddels/component-library';

export const useOptionSelection = <T>(args: {
	initialOptions?: T[];
	onOptionSelected: (option: T, key: 'Tab' | 'Enter') => void;
	onOptionsCleared?: () => void;
	retriggerEnter?: (selectedOption: T) => boolean;
	autoSelectFirst?: boolean;
}) => {
	const optionsRef = React.useRef<T[]>(args.initialOptions ?? []);
	const selectedIndexRef = React.useRef(-1);
	const highlightedIndexRef = React.useRef(-1);
	const blurTimeoutRef = React.useRef<number | null>(null);

	const rerender = React.useState({})[1];

	const [editor] = useLexicalComposerContext();

	const handleConfirmKey = (e: KeyboardEvent) => {
		if (
			!optionsRef.current ||
			optionsRef.current.length === 0 ||
			selectedIndexRef.current === -1
		) {
			return false;
		}

		const selectedOption = optionsRef.current[selectedIndexRef.current];
		args.onOptionSelected(selectedOption, e.key as 'Tab' | 'Enter');

		if (e.key === 'Enter' && args.retriggerEnter?.(selectedOption)) {
			setTimeout(() => {
				editor.dispatchCommand(KEY_ENTER_COMMAND, e);
			});
		}

		e!.preventDefault();
		return true;
	};

	const unsetOptions = useEvent(() => {
		optionsRef.current = [];
		selectedIndexRef.current = -1;
		highlightedIndexRef.current = -1;
		rerender({});
	});

	const setOptions = useEvent((options: T[]) => {
		clearTimeout(blurTimeoutRef.current!);
		blurTimeoutRef.current = null;
		optionsRef.current = options;
		if (args.autoSelectFirst !== false) {
			selectedIndexRef.current = 0;
		}
		highlightedIndexRef.current = -1;
		rerender({});
	});

	const setHighlightedIndex = useEvent((index: number) => {
		highlightedIndexRef.current = index;
		rerender({});
	});

	React.useEffect(() => {
		const commandUnregister = [
			editor.registerCommand(
				FOCUS_COMMAND,
				() => {
					if (blurTimeoutRef.current) {
						clearTimeout(blurTimeoutRef.current!);
						blurTimeoutRef.current = null;
					}
					return false;
				},
				COMMAND_PRIORITY_NORMAL
			),
			editor.registerCommand(
				BLUR_COMMAND,
				() => {
					blurTimeoutRef.current = setTimeout(() => {
						unsetOptions();
					}, 100) as unknown as number;
					return false;
				},
				COMMAND_PRIORITY_NORMAL
			),
			editor.registerCommand(
				KEY_ARROW_DOWN_COMMAND,
				e => {
					if (
						!optionsRef.current ||
						optionsRef.current.length === 0
					) {
						return false;
					}

					selectedIndexRef.current =
						(selectedIndexRef.current + 1) %
						optionsRef.current.length;

					rerender({});

					e.preventDefault();
					return true;
				},
				COMMAND_PRIORITY_NORMAL
			),
			editor.registerCommand(
				KEY_ESCAPE_COMMAND,
				() => {
					unsetOptions();
					return false;
				},
				COMMAND_PRIORITY_NORMAL
			),
			editor.registerCommand(
				KEY_ARROW_UP_COMMAND,
				e => {
					if (
						!optionsRef.current ||
						optionsRef.current.length === 0
					) {
						return false;
					}

					selectedIndexRef.current =
						selectedIndexRef.current - 1 >= 0
							? selectedIndexRef.current - 1
							: optionsRef.current.length - 1;

					rerender({});

					e.preventDefault();
					return true;
				},
				COMMAND_PRIORITY_NORMAL
			),
			editor.registerCommand(
				KEY_ENTER_COMMAND,
				handleConfirmKey,
				COMMAND_PRIORITY_NORMAL
			),
			editor.registerCommand(
				KEY_TAB_COMMAND,
				handleConfirmKey,
				COMMAND_PRIORITY_NORMAL
			),
		];

		return () => {
			commandUnregister.forEach(u => u());
		};
	}, [editor]);

	return {
		options: optionsRef.current,
		selectedIndex: selectedIndexRef.current,
		highlightedIndex: highlightedIndexRef.current,
		setHighlightedIndex: setHighlightedIndex,
		unsetOptions,
		setOptions,
	};
};
