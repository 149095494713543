import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import { ChannelEvents, GetProfilePictureByUserId } from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
@injectable()
export class ChannelSubscriptionService {
  public readonly dispose = Disposable.fn();
  public readonly onChannelEvent: EventSource<typeof ChannelEvents.TPrimaryResult>;
  private readonly channelEventEmitter = new EventEmitter<typeof ChannelEvents.TPrimaryResult>();
  constructor(@inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T) {
    this.onChannelEvent = this.channelEventEmitter.asEvent();
    this.dispose.track(this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(ChannelEvents, {
      pixelDensity: getPixelRatio()
    }, {
      next: event => {
        this.handleChannelEvents(event);
        this.channelEventEmitter.emit(event);
      },
      error: console.error
    }));
  }
  private async handleChannelEvents(event: typeof ChannelEvents.TPrimaryResult): Promise<void> {
    switch (event.__typename) {
      case 'ProfilePictureChanged':
        // TODO: find a better way to have all sizes of profile picture up to date
        // Quick fix: We should also update profile picture for other sizes
        // Delay updating the profile picture to ensure we don't cache the old image
        setTimeout(() => {
          this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetProfilePictureByUserId, {
            userId: event.user.id,
            pixelDensity: getPixelRatio(),
            size: 60
          }, 'network-only');
        }, 500);
        break;
      default:
      // Don't throw here or we will block backend updates that extend those events.
    }
  }
}