import { mapEasingToNativeEasing as y } from "./easing.js";
function p(e, n, t, { delay: o = 0, duration: s = 300, repeat: f = 0, repeatType: l = "loop", ease: m, times: i } = {}) {
  const r = { [n]: t };
  i && (r.offset = i);
  const a = y(m);
  return Array.isArray(a) && (r.easing = a), e.animate(r, {
    delay: o,
    duration: s,
    easing: Array.isArray(a) ? "linear" : a,
    fill: "both",
    iterations: f + 1,
    direction: l === "reverse" ? "alternate" : "normal"
  });
}
export {
  p as animateStyle
};
