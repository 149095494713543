import { jsx as i } from "react/jsx-runtime";
import { ErrorWithDocsReference as o } from "../../docs/helper.js";
import { Flex as a } from "./Flex.js";
import { themedOrRaw as n } from "./LinearGradientProps.js";
import { useTheme as m } from "../../themes/useTheme.js";
const s = (r) => r * 100 + "%", d = (r, e) => `linear-gradient(0deg, ${r.map((t) => t.color ? `${n(t.color, e)} ${s(
  t.stop
)}` : n(t, e)).join(",")})`, h = ({
  colors: r,
  ...e
}) => {
  if (r.length < 2)
    throw new o(
      "Please pass at least two colors",
      "api/linear-gradient"
    );
  return /* @__PURE__ */ i(a, { ...e, bg: d(r, m()), children: e.children });
};
export {
  h as LinearGradient
};
