import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { useTheme } from '@knuddels/component-library';
import { FormattedText } from '@shared/components';
import * as React from 'react';
import { isSingleEmoji } from '../../../utils/isSingleEmoji';
export interface OriginalMessageProps {
  text: FormattedText;
  forceBoldLinks?: boolean;
  /**
   * Appends a invisible placeholder string to the end of the text.
   * This is used to prevent the text from overlapping the time in the message.
   **/
  placeholderTime?: string;
  getHighlightedLinkColor?(): string | undefined;
}
type Props = OriginalMessageProps;
export const OriginalMessage: React.FC<Props> = React.memo(props => {
  const theme = useTheme();
  const shouldScaleUpEmoji = props.text.kind === 'Text' ? isSingleEmoji(props.text.text) : false;
  const getHighlightedLinkColor = (): string | undefined => {
    return props.getHighlightedLinkColor ? props.getHighlightedLinkColor() : theme.colors.basic.accent;
  };
  return <FormattedTextDisplayWithCommandHandling textProps={{
    type: 'body1',
    selectable: true,
    state: 'primary',
    ...(shouldScaleUpEmoji && {
      style: {
        fontSize: '3em',
        lineHeight: '1em'
      }
    })
  }} containerProps={{
    mb: shouldScaleUpEmoji ? 'large' : 'none'
  }} text={props.text} getHighlightedLinkColor={getHighlightedLinkColor} forceBoldLinks={props.forceBoldLinks} placeholderTime={shouldScaleUpEmoji ? undefined : props.placeholderTime} />;
});
OriginalMessage.displayName = 'OriginalMessage';