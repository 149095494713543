import { FlexCol, Smiley, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
export const HeaderIcon: React.FC<{
  src: any;
  width: number;
  height: number;
  top: number;
  left: number;
}> = props => {
  return <div style={{
    paddingLeft: resolveThemingValue((props.left + 'px' as any), "spacing", useTheme()),
    paddingTop: resolveThemingValue((props.top + 'px' as any), "spacing", useTheme())
  }} className={_c0}>
			<Smiley src={props.src} width={props.width} height={props.height} />
		</div>;
};
const _c0 = " Knu-FlexCol ";