import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $CurrentEndpointStore } from '../serviceIds';
import { Endpoint } from '../Endpoints';
import {
	$LocalStorage,
	LocalStorageEntry,
	LocalStorageKey,
	stringOrUndefinedSerializer,
} from '@knuddels-app/local-storage';
import {
	getNamespaceIfWeb,
	getScopeIfWeb,
} from '@knuddels-app/local-storage/sessionCookieHelper';
import { getMigrationFunctionForSharedKeys } from '@knuddels-app/local-storage/getMigrationFunctionForSharedKeys';

const deviceTokenEntry = new LocalStorageKey({
	name: 'knJwt',
	getNamespace: getNamespaceIfWeb,
	tryMigrateFromOld: getMigrationFunctionForSharedKeys('shared___knJwt'),
	scope: getScopeIfWeb(),
	cookieExpires: { inDays: 365 },
	serializer: stringOrUndefinedSerializer,
});

@injectable()
export class DeviceTokenStore {
	private readonly storageEntry: LocalStorageEntry<string | undefined>;

	private deviceToken: string | undefined;

	constructor(
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($CurrentEndpointStore)
		private readonly currentEndpointStore: typeof $CurrentEndpointStore.T,
		@inject($LocalStorage)
		localStorage: typeof $LocalStorage.T
	) {
		this.storageEntry = localStorage.getEntry(deviceTokenEntry);
	}

	public async getDeviceTokenInfo(): Promise<
		| {
				deviceToken: string;
				endpoint: Endpoint;
		  }
		| undefined
	> {
		const endpoint = this.currentEndpointStore.currentEndpoint;

		if (!this.deviceToken) {
			if (this.environment.messengerSystemAppInterface) {
				this.deviceToken =
					this.environment.messengerSystemAppInterface.client.pageData.deviceToken;
			} else {
				this.deviceToken = this.storageEntry.get();
			}
		}

		if (this.deviceToken) {
			return { deviceToken: this.deviceToken, endpoint };
		} else {
			return undefined;
		}
	}

	// Endpoint not used at the moment. Should be saved together at some point.
	public setDeviceToken(deviceToken: string): void {
		this.deviceToken = deviceToken;
		return this.storageEntry.set(deviceToken);
	}

	public clearDeviceToken(): void {
		this.deviceToken = undefined;
		return this.storageEntry.reset();
	}
}
