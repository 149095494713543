import { ChannelGroupInfo } from '@generated/graphql';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { action, autorun, observable, runInAction } from '@knuddels-app/mobx';
import { $ScreenService } from '@knuddels-app/Screen';
import { Disposable, SimpleCache } from '@knuddels/std';
import { $AdsService } from '@knuddelsModules/Ads';
import { chatroomShownTimingTracker, nicklistClosedTimingTracker } from '@knuddelsModules/Channel/analytics';
import { MessengerMessageType } from '@knuddelsModules/Messenger';
import * as React from 'react';
import { ActiveChannelMessage, ChannelInfo } from '../../../services';
import { defaultGroupInfo } from '../defaultActiveChannel';
import { AggregatedMessage, ChatItem } from './ChatItem';
import { ChatItemFactory, ChatItemFactoryArgs } from './ChatItemFactory';
import { arrayEquals } from '@knuddels-app/tools/arrayEquals';
import { usersSortedByNick } from '@knuddels-app/tools/usersSortedByNick';
import { Box, ChildThemeProvider, FlexCol, generateId, isDarkColor, rgb, useChildTheme } from '@knuddels/component-library';
import { $MessageLayoutSettingsService } from '@knuddelsModules/Settings';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { ChatVirtualList, getSnapshot } from './ChatVirtualList';
import { $CommandService } from '@knuddels-app/Commands';
import { MAX_MESSAGES_IN_MEMORY_COUNT } from '../../../services/channel/ChannelInfo';
import { debounce } from '@shared/components';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { IconJumpLatest, IconJumpUnread, JumpToBadge, JumpToBadgeParent, UnreadMessageIndicator } from '@shared/components/JumpToControls';
interface Props {
  channel: ChannelInfo | undefined;
  getMessages: (channel: ChannelInfo) => readonly ActiveChannelMessage[];
  scrollViewRef?: React.RefObject<ChatVirtualList<ChatItem>>;
}
function findLastIndex<T>(array: readonly T[], predicate: (elem: T) => boolean): number {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return i;
    }
  }
  return -1;
}
@injectable()
class ChatHistoryModel implements IModel {
  public readonly dispose = Disposable.fn();
  public isScrolledToEnd: boolean;
  @observable
  public jumpToBadgeVisible: boolean;
  public latestUnreadMessageIndicatorId: string | null = null;
  setInitialRenderFalse = debounce(() => {
    this.initialRender = false;
  }, 500);
  private isScrolling = false;
  private intersectionObserver = new IntersectionObserver(entries => {
    const idsToRemove: string[] = [];
    entries.forEach(entry => {
      const id = (entry.target as HTMLElement).dataset.id;
      if (!entry.isIntersecting && id !== this.latestUnreadMessageIndicatorId) {
        idsToRemove.push(id);
      }
      if (entry.isIntersecting && id === this.latestUnreadMessageIndicatorId) {
        this.clearUnreadState();
      }
      if (idsToRemove.length) {
        runInAction('Remove unread message indicators', () => {
          this.renderItems = this.renderItems.filter(item => !idsToRemove.includes(item.uniqueKey));
        });
      }
    });
  }, {
    threshold: 0.2
  });
  private readonly chatItemCache = new SimpleCache<ChatItemFactoryArgs, ChatItem>(i => JSON.stringify(i), args => ChatItemFactory.create(args, {
    layoutService: this.messageLayoutSettingsService,
    channelGroupInfo: this.activeChannelService.activeChannel?.groupInfo
  }));
  @observable.shallow
  private renderItems: ChatItem[] = [];
  private lastAddedMessage: ActiveChannelMessage | null = null;
  private pendingMessageQueue: ChatItem[] = [];
  private initialRender = true;
  constructor(@injectProps()
  private readonly props: Props, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($MessageLayoutSettingsService)
  private readonly messageLayoutSettingsService: typeof $MessageLayoutSettingsService.T, @inject($ActiveChannelService)
  private readonly activeChannelService: typeof $ActiveChannelService.T, @inject($CommandService)
  commandService: typeof $CommandService.T, @inject($AdsService)
  private readonly adsService: typeof $AdsService.T) {
    const scrollViewSnapshot = getSnapshot(props.channel.id);
    this.isScrolledToEnd = scrollViewSnapshot?.scrolledToEnd ?? true;
    this.jumpToBadgeVisible = !this.isScrolledToEnd;
    chatroomShownTimingTracker.stop();
    nicklistClosedTimingTracker.stop();
    this.dispose.track(() => {
      if (props.channel) {
        props.channel.markMessagesRendered();
      }
    });
    this.dispose.track(() => {
      this.intersectionObserver.disconnect();
    });
    this.dispose.track(commandService.registerCommandListener({
      commandName: 'clear',
      onInvoked: () => {
        runInAction('clear messages', () => {
          this.renderItems = [];
          this.lastAddedMessage = null;
          this.pendingMessageQueue = [];
          this.isScrolledToEnd = true;
          this.jumpToBadgeVisible = false;
        });
      }
    }));
    this.dispose.track(autorun({
      name: 'New message arrived'
    }, () => {
      const messages = props.getMessages(this.props.channel);
      this.setInitialRenderFalse();
      const startIndex = (this.lastAddedMessage ? findLastIndex(messages, el => this.lastAddedMessage === el) : -1) + 1;
      for (let i = startIndex; i < messages.length; i++) {
        const newMessage = messages[i];
        if (!newMessage) {
          return;
        }
        const entry = this.chatItemCache.getEntry({
          message: newMessage,
          firstOfSet: !this.lastAddedMessage || !ChatHistoryModel.doMessagesBelongToSameSet(this.lastAddedMessage, newMessage),
          lastOfSet: true,
          animated: true,
          isStackedLayout: this.isStackedLayout,
          channelGroupInfo: this.channelGroupInfo
        });
        if (startIndex === 10 && this.adsService.areWebAdsVisible) {
          this.renderAdzone();
        }

        /**
         * We will later decide if we want this feature or not
         */

        // if (
        // 	newMessage ===
        // 		this.props.channel.firstMessageInBackground &&
        // 	!this.props.channel.firstUnreadMessage
        // ) {
        // 	this.renderNewMessageIndicator();
        // 	this.props.channel.clearFirstMessageInBackground();
        // 	runInAction('Update first unread message', () => {
        // 		this.props.channel.firstUnreadMessage = entry;
        // 	});
        // }

        this.lastAddedMessage = newMessage;
        if (!this.isScrolling) {
          runInAction('Update render items', () => {
            if (this.isScrolledToEnd) {
              while (this.renderItems.length >= MAX_MESSAGES_IN_MEMORY_COUNT) {
                this.renderItems.shift();
              }
            } else if (!this.props.channel.firstUnreadMessage && !this.initialRender) {
              /**
               * We will later decide if we want this feature or not
               */
              // this.renderNewMessageIndicator();
              // this.props.channel.firstUnreadMessage = entry;
            }
            this.renderItems.push(entry);

            // if (
            // 	this.props.channel.firstUnreadMessage &&
            // 	!this.latestUnreadMessageIndicatorId
            // ) {
            // 	this.renderNewMessageIndicator(
            // 		'findUnreadMessage'
            // 	);
            // }
          });
        } else {
          this.pendingMessageQueue.push(entry);
        }
      }
    }));
  }
  @action.bound
  public scrollToUnreadMessage = () => {
    if (this.props.channel.firstUnreadMessage) {
      const index = findLastIndex(this.renderItems, item => this.props.channel.firstUnreadMessage.uniqueKey === item.uniqueKey);
      if (index === -1) {
        this.scrollToBottom();
        this.props.channel.firstUnreadMessage = null;
      } else {
        this.props.scrollViewRef.current.scrollToIndex(index);
      }
    }
  };
  public initView = () => {
    this.activeChannelService.setViewInitialized();
    const snapshot = getSnapshot(this.props.channel.id);
    if (snapshot?.scrolledToEnd || !snapshot) {
      this.scrollToBottom();
    }
  };
  public scrollToBottom = () => {
    this.props.scrollViewRef.current.scrollToBottom();
  };
  private updateJumpBadgeVisibilityTimeout: any = undefined;
  public onScrolledToEndChanged = (isAtEnd: boolean) => {
    this.isScrolledToEnd = isAtEnd;
    if (isAtEnd) {
      this.flushPendingQueue();
      this.clearUnreadState();
    }
    this.updateJumpBadgeVisibility(!isAtEnd);
  };
  private updateJumpBadgeVisibility = (newValue: boolean) => {
    if (typeof this.updateJumpBadgeVisibilityTimeout !== 'undefined') {
      clearTimeout(this.updateJumpBadgeVisibilityTimeout);
      this.updateJumpBadgeVisibilityTimeout = undefined;
    }
    if (!newValue) {
      runInAction('Update jump badge visibility', () => {
        this.jumpToBadgeVisible = false;
      });
      return;
    }
    const doTheUpdate = (value: boolean) => {
      if (this.isScrolling) {
        this.updateJumpBadgeVisibilityTimeout = setTimeout(() => {
          doTheUpdate(value);
        }, 100);
      } else {
        this.updateJumpBadgeVisibilityTimeout = undefined;
        runInAction('Update jump badge visibility', () => {
          this.jumpToBadgeVisible = value;
        });
      }
    };
    this.updateJumpBadgeVisibilityTimeout = setTimeout(() => {
      doTheUpdate(newValue);
    }, 100);
  };
  public onIsScrollingChanged = (isScrolling: boolean) => {
    if (isScrolling) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
      this.flushPendingQueue();
    }
  };
  @action
  private clearUnreadState = () => {
    this.props.channel.firstUnreadMessage = null;
    this.latestUnreadMessageIndicatorId = null;
  };
  private flushPendingQueue = () => {
    runInAction('Update render items', () => {
      if (this.pendingMessageQueue.length > 0) {
        this.renderItems.push(...this.pendingMessageQueue);
        this.pendingMessageQueue = [];
      }
    });
  };
  public get renderedItems(): ChatItem[] {
    return [{
      type: 'spacer',
      uniqueKey: 'top-spacer',
      node: <Box height={window.innerHeight} />
    }, ...this.renderItems];
  }
  private static doMessagesBelongToSameSet(message1: AggregatedMessage, message2: AggregatedMessage): boolean {
    if (message1.__typename !== message2.__typename) {
      return false;
    }
    if (message1.__typename === 'ChannelMsgAction') {
      return false;
    }
    if (message1.__typename === 'ChannelMsgPrivateGroup' && message2.__typename === 'ChannelMsgPrivateGroup') {
      const isSameSender = message1.sender.id === message2.sender.id;
      const areSameReceivers = arrayEquals(usersSortedByNick(message1.receiver), usersSortedByNick(message2.receiver), (a, b) => a.id === b.id);
      return isSameSender && areSameReceivers;
    }
    if (message1.__typename === 'ChannelMsgPublic' && message2.__typename === 'ChannelMsgPublic' || message1.__typename === 'ChannelMsgSystem' && message2.__typename === 'ChannelMsgSystem') {
      return message1.sender.id === message2.sender.id;
    }
    if (message1.__typename === MessengerMessageType && message2.__typename === MessengerMessageType) {
      return message1.message.sender.id === message2.message.sender.id && message1.conversation.id === message2.conversation.id;
    }
    return false;
  }
  @action.bound
  private renderAdzone(): void {
    const id = generateId('adzone');
    const adzone = {
      uniqueKey: id,
      node: <ChannelAdzone adsService={this.adsService} />,
      type: 'adzone'
    };
    this.renderItems.push(adzone);
  }
  @action.bound
  private renderNewMessageIndicator(type: 'push' | 'findUnreadMessage' = 'push'): void {
    const id = generateId('indicator');
    const indicator = {
      uniqueKey: id,
      node: <UnreadMessageIndicator observer={this.intersectionObserver} id={id} />,
      type: 'unreadMessageIndicator'
    };
    if (type === 'push') {
      this.renderItems.push(indicator);
      this.latestUnreadMessageIndicatorId = id;
    } else if (type === 'findUnreadMessage') {
      const index = this.renderItems.findIndex(item => item.uniqueKey === this.props.channel.firstUnreadMessage.uniqueKey);
      if (index === -1) {
        return;
      }
      this.latestUnreadMessageIndicatorId = id;
      setTimeout(() => {
        runInAction('Update render items', () => {
          this.renderItems.splice(index, 0, indicator);
        });
      });
    }
  }
  private get channelGroupInfo(): Pick<ChannelGroupInfo, 'backgroundColor' | 'highlightColor'> {
    return this.props.channel ? this.props.channel.groupInfo : defaultGroupInfo;
  }
  private get isStackedLayout(): boolean {
    return this.screenService.isStackedLayout;
  }
}
export const ChatHistory = injectedComponent({
  name: 'ChatHistory',
  model: ChatHistoryModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<Props>()
}, ({
  model,
  channel,
  scrollViewRef,
  i18n
}) => {
  const layoutService = useService($MessageLayoutSettingsService);
  const childTheme = useChildTheme({
    sizes: {
      texts: {
        body1: {
          fontSize: layoutService.fontSizePx,
          lineHeight: layoutService.lineHeightPx
        }
      }
    }
  }, [layoutService.fontSizePx]);
  React.useLayoutEffect(() => {
    model.initView();
  }, [channel.id]);
  const isChannelBackgroundColorDark = React.useMemo(() => {
    const color = rgb(channel.groupInfo.backgroundColor.red, channel.groupInfo.backgroundColor.green, channel.groupInfo.backgroundColor.blue);
    return isDarkColor(color);
  }, [channel.groupInfo.backgroundColor.red, channel.groupInfo.backgroundColor.green, channel.groupInfo.backgroundColor.blue]);
  return <ChildThemeProvider theme={childTheme} styles={{
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%'
  }} overrideVariables={{
    sizes: {
      texts: {
        body1: {
          fontSize: layoutService.fontSizePx,
          lineHeight: layoutService.lineHeightPx
        }
      }
    }
  }}>
				<JumpToBadgeParent isDarkParent={isChannelBackgroundColorDark}>
					{channel.firstUnreadMessage && <JumpToBadge key={'animated-scroll-to-unread'} onPress={model.scrollToUnreadMessage} icon={<IconJumpUnread />} text={i18n.format(declareFormat({
        id: 'channel.jumpToUnread'
      }))} />}

					{model.jumpToBadgeVisible && !channel.firstUnreadMessage && <JumpToBadge key={'animated-scroll-to-bottom'} onPress={model.scrollToBottom} icon={<IconJumpLatest />} text={i18n.format(declareFormat({
        id: 'channel.jumpToLatest'
      }))} />}
				</JumpToBadgeParent>
				<ChatVirtualList key={layoutService.renderMode} estimatedItemSize={48} channelId={channel?.id} ref={scrollViewRef} renderItem={renderItem} items={model.renderedItems} scrolledToEndChanged={model.onScrolledToEndChanged} isScrolling={model.onIsScrollingChanged} />
			</ChildThemeProvider>;
});
const renderItem = (item: ChatItem): React.ReactElement => {
  return item.node;
};
const ChannelAdzone = React.memo(function ({
  adsService
}: {
  adsService: typeof $AdsService.T;
}): React.ReactElement {
  const Adzone = adsService.Adzone;
  return <div className={_c1}>
			<Adzone adzone="channel" hideLoadingIndicator />
		</div>;
});
ChannelAdzone.displayName = 'ChannelAdzone';
const _c0 = " Knu-Box ";
const _c1 = " Knu-FlexCol position-relative py-base ";