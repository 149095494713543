import { ConversationMessageFragment, FullConversationFragment, GetConversationDocument, GetConversationQuery, GetConversationQueryVariables, MessengerConversation, MessengerMessageReceivedFragment } from '@generated/graphql';
import { $AuthenticatedClientService, todoUseDI_currentEndpointStore } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { action, observable } from '@knuddels-app/mobx';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { isNative } from '@knuddels-app/tools/isNative';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import { $MessengerConversationService, $MessengerMiniChatService, $MessengerNavigationService, $MessengerOverviewService, $MessengerService, $ReadSystemService } from '@knuddelsModules/Messenger/providedServices';
import { $NotificationBarService } from '@knuddelsModules/Notifications';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $SoundService, SoundEvent } from '@knuddelsModules/Sound';
import { $UserService } from '@knuddelsModules/UserData';
import * as React from 'react';
import MessengerNotification, { isFormattedJsonString } from '../components/Notifications/MessengerNotification';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { getProfilePicUrlForUsername, ProfilePicMode } from '@knuddels-app/tools/getProfilePicUrlForUsername';
import { FormattedText } from '@shared/components';
interface NotificationData {
  senderId: string;
  senderNick: string;
  conversationId: string;
  messages: string[];
}
@injectable()
export class NewMessageService {
  public readonly dispose = Disposable.fn();
  @observable
  public activeNotifications: Map<string, NotificationData> = new Map();
  public readonly onNewMessage: EventSource<MessengerMessageReceivedFragment>;
  private readonly newMessageEmitter = new EventEmitter<MessengerMessageReceivedFragment>();
  constructor(@inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($MessengerOverviewService)
  private readonly messengerOverviewService: typeof $MessengerOverviewService.T, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($SoundService)
  private readonly soundService: typeof $SoundService.T, @inject($ReadSystemService)
  private readonly readSystemService: typeof $ReadSystemService.T, @inject($NotificationBarService)
  private readonly notificationBarService: typeof $NotificationBarService.T, @inject($MessengerMiniChatService)
  private readonly messengerMiniChatService: typeof $MessengerMiniChatService.T, @inject($MessengerNavigationService)
  private readonly messengerNavigationService: typeof $MessengerNavigationService.T, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($NativeWebViewService)
  private readonly nativeWebViewService: typeof $NativeWebViewService.T) {
    this.onNewMessage = this.newMessageEmitter.asEvent();
    this.dispose.track(this.onNewMessage.sub(event => {
      if (!this.userService.isCurrentUser(event.conversationMessage.sender.id)) {
        this.messengerConversationService.getOrCreateClientConversationState(event.conversation.id).clearOtherParticipantTyping();
      }
    }));
    this.dispose.track(messengerService.onNewMessage.sub(this.handleNewMessageEvent));
  }
  public handleNewMessageEvent = async (event: MessengerMessageReceivedFragment) => {
    const {
      conversationMessage,
      conversation
    } = event;
    this.maybeRemoveSendingImage(conversationMessage, conversation.id);
    this.maybePlaySound(conversationMessage, conversation);
    this.maybeShowNotification(conversationMessage, conversation);
    this.addMessageToConversation(conversationMessage, conversation);
    await this.readSystemService.handleNewMessage(event);

    // first finish our query updates, then emit event.
    this.newMessageEmitter.emit(event);

    // Has to be called after event, so all conversation changes (esp. read state) are reflected
    // in the overview queries
    await this.messengerOverviewService.handleNewMessage(event);
  };
  private addMessageToConversation(message: MessengerMessageReceivedFragment['conversationMessage'], conversation: MessengerMessageReceivedFragment['conversation']): void {
    const queriedConversation = this.messengerConversationService.getCachedFullConversation(conversation.id);
    if (!queriedConversation) {
      // don't have full conversation => don't need to update it
      return;
    }
    const queriedMessages = queriedConversation.conversationMessages.messages;
    const latestQueriedMessage = queriedMessages[queriedMessages.length - 1];
    const newConversation: Readonly<FullConversationFragment> = {
      ...queriedConversation,
      conversationMessages: {
        ...queriedConversation.conversationMessages,
        messages: latestQueriedMessage && latestQueriedMessage.id === message.id ? queriedMessages : ([...removeDuplicateEventNotification(queriedMessages, message), message] as Readonly<ConversationMessageFragment[]>),
        hasMore: queriedConversation.conversationMessages.hasMore
      }
    };
    this.authenticatedClientService.currentClient.writeQuery<GetConversationQuery, GetConversationQueryVariables>({
      query: GetConversationDocument,
      data: {
        messenger: {
          __typename: 'MessengerQuery',
          conversation: newConversation
        }
      },
      // with the @connection directive we always get all the messages
      // no matter the messageCount
      variables: {
        id: conversation.id,
        messageCount: 0,
        pixelDensity: getPixelRatio()
      }
    });
  }

  // At the moment we just remove the sending image if we receive any image or snap from the current user, even if
  // that image or snap is not the one being sent
  private maybeRemoveSendingImage = (message: ConversationMessageFragment, conversationId: MessengerConversation['id']): void => {
    if (!this.userService.isCurrentUser(message.sender.id)) {
      return;
    }
    if (message.content.__typename === 'ConversationImageMessageContent' || message.content.__typename === 'ConversationSnapMessageContent') {
      this.messengerConversationService.getOrCreateClientConversationState(conversationId).setSendingImage(undefined);
    }
  };
  private maybePlaySound(message: MessengerMessageReceivedFragment['conversationMessage'], conversation: MessengerMessageReceivedFragment['conversation']): void {
    if (isNative()) {
      // don't play sound on mobile devices
      return;
    }
    if (this.userService.isCurrentUser(message.sender.id)) {
      // don't play sound if the message is from current user
      return;
    }
    const currentConversationId = this.messengerService.getOpenConversationId();
    const currentMiniChatConversationId = this.messengerMiniChatService.activeConversationId;
    if (currentConversationId === conversation.id || currentMiniChatConversationId === conversation.id) {
      // don't play sound if we have an opened conversation
      return;
    }
    if (this.environment.hasFocus) {
      // don't play sound if the app is in focus
      return;
    }
    this.soundService.playSound(SoundEvent.NewMessageReceived);
  }
  @action
  private readonly addNotification = (conversationId: string, newMessage: string, messageSenderId: string, messageSenderNick: string) => {
    this.activeNotifications.set(conversationId, {
      conversationId,
      messages: [newMessage],
      senderId: messageSenderId,
      senderNick: messageSenderNick
    });
  };
  @action
  private readonly pushMessageToExistingNotification = (conversationId: string, newMessage: string) => {
    const conversation = this.activeNotifications.get(conversationId);
    this.activeNotifications.set(conversationId, {
      conversationId,
      messages: [...conversation.messages, newMessage],
      senderId: conversation.senderId,
      senderNick: conversation.senderNick
    });
  };
  private maybeShowNotification(message: MessengerMessageReceivedFragment['conversationMessage'], conversation: MessengerMessageReceivedFragment['conversation']): void {
    if (this.userService.isCurrentUser(message.sender.id)) {
      // don't show notification if the message is from current user
      return;
    }
    if (!this.clientSettingsService.inappMessengerNotificationEnabled) {
      return;
    }
    const currentConversationId = this.messengerService.getOpenConversationId();
    const currentMiniChatConversationId = this.messengerMiniChatService.activeConversationId;
    if (currentConversationId === conversation.id || currentMiniChatConversationId === conversation.id) {
      // don't show notification if we have an opened conversation
      return;
    }
    let textMessage: string | undefined;
    switch (message.content.__typename) {
      case 'ConversationTextMessageContent':
        textMessage = message.content.formattedText;
        break;
      case 'ConversationForwardedMessageContent':
        textMessage = formatMessage(declareFormat({
          id: 'notification.messenger.message.forwarded',
          defaultFormat: 'forwarded a message to you'
        }));
        break;
      case 'ConversationImageMessageContent':
        textMessage = formatMessage(declareFormat({
          id: 'notification.messenger.message.image',
          defaultFormat: 'sent you a photo'
        }));
        break;
      case 'ConversationSnapMessageContent':
        textMessage = formatMessage(declareFormat({
          id: 'notification.messenger.message.snap',
          defaultFormat: 'sent you a snap'
        }));
        break;
      case 'ConversationQuotedMessageContent':
        textMessage = message.content.formattedText;
        break;
      default:
        textMessage = undefined;
    }
    if (!textMessage) {
      return;
    }
    if (this.activeNotifications.has(conversation.id)) {
      this.pushMessageToExistingNotification(conversation.id, textMessage);
    } else {
      this.addNotification(conversation.id, textMessage, message.sender.id, message.sender.nick);
      this.showNotification(message.sender.id, message.sender.nick, conversation.id);
    }
  }
  private readonly renderNativeNotification = (senderId: string, senderNick: string, conversationId: string) => {
    const message = this.activeNotifications.get(conversationId)?.messages.slice(-1)[0];
    this.nativeWebViewService.renderNotification({
      title: senderNick,
      body: isFormattedJsonString(message) ? FormattedText.asText(FormattedText.fromJsonString(message), false) : message,
      iconUrl: getProfilePicUrlForUsername(senderNick, todoUseDI_currentEndpointStore.currentEndpoint.urls.profilePic, true, ProfilePicMode.mediumSquare),
      onPress: () => {
        this.handleNotificationClick(senderId, senderNick, conversationId);
      },
      onError: () => {
        this.activeNotifications.delete(conversationId);
      },
      onHidden: () => {
        this.activeNotifications.delete(conversationId);
      }
    });
  };
  private handleNotificationClick = async (senderId: string, senderNick: string, conversationId: string) => {
    if (await this.messengerMiniChatService.canConversationBeOpenedInMiniChat(senderId, senderNick)) {
      this.messengerMiniChatService.openConversation(conversationId);
      return;
    }
    this.messengerNavigationService.openConversation(conversationId);
  };
  private readonly showNotification = (senderId: string, senderNick: string, conversationId: string) => {
    if (this.nativeWebViewService.isEnabled) {
      this.renderNativeNotification(senderId, senderNick, conversationId);
      return;
    }
    this.notificationBarService.showNotification({
      renderContent: close => <MessengerNotification userId={senderId} username={senderNick} conversationId={conversationId} activeNotifications={this.activeNotifications} onClick={async () => {
        close();
        this.handleNotificationClick(senderId, senderNick, conversationId);
      }} />,
      onHidden: () => {
        this.activeNotifications.delete(conversationId);
      }
    });
  };
}
function removeDuplicateEventNotification(queriedMessages: readonly ConversationMessageFragment[], newMessage: ConversationMessageFragment): readonly ConversationMessageFragment[] {
  const previousMessage = queriedMessages[queriedMessages.length - 1];
  if (!previousMessage) {
    return queriedMessages;
  }
  const previousType = previousMessage.content.__typename;
  const newType = newMessage.content.__typename;
  if (previousType !== newType) {
    return queriedMessages;
  }
  if (previousType === 'ConversationBirthdayMessageContent' || previousType === 'ConversationNicknameChangeMessageContent') {
    return queriedMessages.slice(0, queriedMessages.length - 1);
  }
  if (newMessage.content.__typename === 'ConversationPrivateSystemMessageContent' && newMessage.content.collapse && previousMessage.content.__typename === 'ConversationPrivateSystemMessageContent' && previousMessage.content.formattedText === newMessage.content.formattedText) {
    return queriedMessages.slice(0, queriedMessages.length - 1);
  }
  return queriedMessages;
}