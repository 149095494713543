import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Box, Flex, FlexCol, IconMoreHorizontal, LayoutEvent, Text, createAccessiblePointerEventHandler, FallbackBox, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { useQuery } from '@knuddels-app/Connection';
import { CommonFriends, User } from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { UserImage } from '@knuddelsModules/UserData';
import { Spacer } from '@shared/components';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { useTrackConversation } from '../../useTrackConversation';
const USER_IMAGE_SIZE = 40;
type UserIdWithNick = Pick<User, 'id' | 'nick'>;
export const CommunicationRequestBoxCommonFriends: React.FC<{
  conversationUser: UserIdWithNick;
}> = ({
  conversationUser
}) => {
  const getProfileNavigationService = useLazyService($ProfileNavigationService);
  const {
    data
  } = useQuery(CommonFriends, {
    userId: conversationUser.id,
    pixelDensity: getPixelRatio()
  }, 'cache-first');
  const [renderedCount, setRenderedCount] = useState(0);
  const track = useTrackConversation();
  useTrackCommonFriendsAvailable(data);
  if (!data || data.__typename !== 'FriendList' || data.friends.length === 0) {
    return null;
  }
  const showProfile: typeof $ProfileNavigationService.T.showProfileForUser = async (user, options) => {
    track('CommunicationRequestBox_CommonFriendsClicked');
    const profileNavigationService = await getProfileNavigationService();
    profileNavigationService.showProfileForUser(user, options);
  };
  const handleLayout = ({
    width
  }: LayoutEvent) => {
    const SPACER_SIZE = 8;
    const imageAndSpacerWidth = USER_IMAGE_SIZE + SPACER_SIZE;
    const imageFitCount = Math.floor((width + SPACER_SIZE) / imageAndSpacerWidth);
    setRenderedCount(imageFitCount);
  };
  const friends = data.friends;
  const shouldShowOverflow = friends.length > renderedCount;
  const renderedFriends = friends.slice(0, shouldShowOverflow ? renderedCount - 1 : renderedCount);
  return <div className={_c0}>
			<Spacer size={'minor'} />
			<Text type={'body2'} state={'tertiary'} className={_c1}>
				<FormattedMessage id={declareFormat({
        id: 'conversation.new-contact-box.common-friends',
        defaultFormat: 'You have {count} mutual {count, plural, one {friend} other {friends}}:'
      })} values={{
        count: friends.length
      }} />
			</Text>
			<Spacer size={'small'} />
			<FallbackBox onLayout={handleLayout} className={_c2}>
				{renderedFriends.map((friend, index) => <React.Fragment key={friend.id}>
						{index !== 0 && <Spacer size={'small'} />}
						<OnPressBox onPress={() => showProfile(friend)}>
							<UserImage highlight={'disabled'} type={USER_IMAGE_SIZE} userId={friend.id} />
						</OnPressBox>
					</React.Fragment>)}
				{shouldShowOverflow && <>
						<Spacer size={'small'} />
						<OverflowItem size={USER_IMAGE_SIZE} onPress={() => showProfile(conversationUser, {
          initialTab: 'friends'
        })} />
					</>}
			</FallbackBox>
		</div>;
};
const OnPressBox: React.FC<{
  onPress(): void;
}> = props => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: props.onPress
  })} className={_c3}>
			{props.children}
		</div>;
};
const OverflowItem: React.FC<{
  size: number;
  onPress?(): void;
}> = ({
  size,
  onPress
}) => {
  return <div {...createNativeAccessiblePointerEventHandler({
    disabled: !onPress,
    pointerCallback: onPress
  })} style={{
    width: resolveThemingValue(size, "sizes", useTheme()),
    height: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c4}>
			<IconMoreHorizontal />
		</div>;
};
const useTrackCommonFriendsAvailable = (data?: typeof CommonFriends.TPrimaryResult) => {
  const track = useTrackConversation();
  const wasTracked = useRef(false);
  useEffect(() => {
    if (!wasTracked.current && data?.__typename === 'FriendList' && data.friends.length > 0) {
      wasTracked.current = true;
      track('CommunicationRequestBox_CommonFriendsAvailable');
    }
  }, [data]);
};
const _c0 = " Knu-FlexCol ";
const _c1 = "  ";
const _c2 = " Knu-Flex ";
const _c3 = " Knu-Box cursor-pointer ";
const _c4 = " Knu-FlexCol cursor-pointer borderRadius-circle borderStyle-solid borderWidth-large borderColor-inputFieldBorder placeItems-center ";