import { FlexCol, toPointerHandler } from '@knuddels/component-library';
import * as React from 'react';
export const HoldToViewWrapper: React.FC<{
  onStart: () => void;
  onEnd: () => void;
}> = ({
  children,
  onEnd,
  onStart
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleButtonPress = (): void => {
    if (isOpen) {
      return;
    }
    setIsOpen(true);
    const eventHandler = (evt: Event): void => {
      if (evt.type === 'mouseup' || evt.type === 'pointerup' || evt.type === 'touchend') {
        window.removeEventListener('mouseup', eventHandler);
        window.removeEventListener('pointerup', eventHandler);
        window.removeEventListener('mousemove', eventHandler);
        window.removeEventListener('pointermove', eventHandler);
        window.removeEventListener('touchend', eventHandler);
        onEnd();
        setIsOpen(false);
      }
      evt.stopPropagation();
    };
    window.addEventListener('mouseup', eventHandler);
    window.addEventListener('pointerup', eventHandler);
    window.addEventListener('mousemove', eventHandler);
    window.addEventListener('pointermove', eventHandler);
    window.addEventListener('touchend', eventHandler);
    onStart();
  };
  return <div onPointerDown={toPointerHandler(handleButtonPress)} className={_c0}>
			{children}
		</div>;
};
const _c0 = " Knu-FlexCol position-relative overflow-hidden cursor-pointer ";