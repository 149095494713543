import * as React from 'react';
import { FormattedDateTime } from '@knuddels-app/i18n';
import { FlexCol, Text, ThemeOverride } from '@knuddels/component-library';
export const MessageTime: React.FC<{
  timestamp: number;
}> = ({
  timestamp
}) => {
  return <div className={_c0}>
			<Text type={'tiny'} state={'tertiary'} className={_c1}>
				<FormattedDateTime value={timestamp} hour={'numeric'} minute={'numeric'} />
			</Text>
		</div>;
};
const _c0 = " Knu-FlexCol alignSelf-flex-end mb-1px ";
const _c1 = "  ";