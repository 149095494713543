import { $LocationPersistor, $LocationService } from './serviceIds';
import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { LocationService } from './LocationService';
import { LocationPersistor } from './LocationPersistor';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope.bind($LocationService).toSingleton(LocationService);
	ctx.globalScope
		.bind($LocationPersistor)
		.toSingletonAutostart(LocationPersistor);
}
