import * as React from 'react';
import { IconNotificationImage, NotificationFragment, NotificationIcon } from '@generated/graphql';
import { Flex, FlexCol, Image, Text, ThemeOverride } from '@knuddels/component-library';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
export const NotificationContentIcon: React.FC<{
  icon: NotificationIcon;
}> = ({
  icon
}) => {
  return <div className={_c0}>
			<Image resizeMode={'contain'} src={icon.url} alt={''} className={_c1} />
			{icon.subtitle && <Text bold {...getIconSubtitleTextColorProps(icon)}>
					{icon.subtitle.text}
				</Text>}
		</div>;
};
export const NotificationContentImage: React.FC<{
  image: NotificationFragment['image'];
}> = ({
  image
}) => {
  const isProfilePicture = image?.__typename === 'ProfilePictureNotificationImage';
  const isIcon = image?.__typename === 'IconNotificationImage';
  const imgSrc = isProfilePicture ? image.user.profilePicture.urlCustomSizeSquare : image?.__typename === 'IconNotificationImage' ? image.url : '';
  const overlays = isProfilePicture ? image.user.profilePictureOverlayUrls.urlsSmall : [];
  return <div className={_c2}>
			<Image resizeMode={'contain'} src={imgSrc} alt={''} className={_c3} />
			{isProfilePicture && image.isBlurred && <div style={{
      backdropFilter: 'blur(3px)'
    }} className={_c4} />}
			{isIcon && image.subtitle && <Text bold {...getIconSubtitleTextColorProps(image)}>
					{image.subtitle.text}
				</Text>}
			{overlays.length > 0 && overlays.map((overlayUrl, index) => <div key={index} className={_c5}>
						<Image src={overlayUrl} alt={''} className={_c6} />
					</div>)}
		</div>;
};
const getIconSubtitleTextColorProps = (notificationIcon: NotificationFragment['icon'] | IconNotificationImage): Pick<React.ComponentProps<typeof Text>, 'color' | 'darkColor'> => {
  if (!notificationIcon || !notificationIcon.subtitle || !notificationIcon.subtitle.color) {
    return {};
  }
  return {
    color: (colorToRgbaString(notificationIcon.subtitle.color.lightThemeColor) as ThemeOverride),
    darkColor: (colorToRgbaString(notificationIcon.subtitle.color.darkThemeColor) as ThemeOverride)
  };
};
const _c0 = " Knu-FlexCol ";
const _c1 = " width-40px height-35px ";
const _c2 = " Knu-FlexCol position-relative overflow-visible ";
const _c3 = " width-40px height-40px borderRadius-minor ";
const _c4 = " Knu-Flex position-absolute borderRadius-minor width-full height-full ";
const _c5 = " Knu-Flex position-absolute inset-none borderRadius-minor overflow-hidden ";
const _c6 = " size-full ";