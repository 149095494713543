/**
 * Splits an array into chunks of a specified size.
 * @param arr The array to split.
 * @param size The size of each chunk.
 * @returns An array of arrays, where each inner array contains `size` elements from the original array.
 * @template T The type of the elements in the input array.
 */
export function chunkArray<T>(arr: readonly T[], size: number): T[][] {
	const result = [];
	for (let i = 0; i < arr.length; i += size) {
		result.push(arr.slice(i, i + size));
	}
	return result;
}
