import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $ScreenService } from '@knuddels-app/Screen';
import { NavBarConfigurations } from '../components/NavBar/configuration';
import { isMobileOrNative } from '@knuddels-app/tools/isMobileOrNative';
@injectable()
export class NavBarService {
  public readonly dispose = Disposable.fn();
  constructor(@inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T) {}
  private get navLeft(): boolean {
    const screenWidth = this.screenService.screenWidth;
    return !(screenWidth === 'XS' || screenWidth === 'S');
  }
  private get navLeftOverlay(): boolean {
    const screenWidth = this.screenService.screenWidth;
    return screenWidth === 'M';
  }
  public get config(): NavBarConfigurations {
    if (!this.navLeft) {
      return isMobileOrNative() && this.screenService.isLandscape ? 'minimalLeft' : 'minimalBottom';
    }
    return this.navLeftOverlay ? 'leftAbsolute' : 'leftRelative';
  }
  public get isBottom(): boolean {
    return this.config === 'minimalBottom';
  }
}