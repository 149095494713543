import * as React from 'react';
interface Props {
  prefix: string;
  children: React.ReactNode;
}
export class ClipboardExtraInfo extends React.PureComponent<Props> {
  private ref = React.createRef<HTMLDivElement>();
  private readonly onCopy = (event: React.ClipboardEvent) => {
    const selection = document.getSelection();
    if (!selection) {
      return;
    }
    const selectedText = selection.toString();
    if (selectedText.trim().length > 0 && this.ref.current && selectedText.indexOf(this.ref.current.textContent || '') === 0) {
      event.clipboardData.setData('text/plain', this.props.prefix + selectedText);
      event.preventDefault();
    }
  };
  render(): React.ReactNode {
    return <div ref={this.ref} onCopy={this.onCopy}>
				{this.props.children}
			</div>;
  }
}