import * as React from 'react';
import { IconEdit, Input } from '@knuddels/component-library';
import { useNavigate } from '../../EditProfileRouter';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { SystemAppOptions } from './EditProfileEntries';
import { useService } from '@knuddels-app/DependencyInjection';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { observer } from '@knuddels-app/mobx';
import { $UserService } from '../../../../../../../UserData';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { EditProfileEntry } from './EditProfileEntry';
type Props = {
  label: string;
  image?: string;
  description?: string | JSX.Element;
  systemAppOptions: SystemAppOptions;
};
export const EditProfileSystemAppEntry: React.FC<Props> = observer('EditProfileSystemAppEntry', ({
  label,
  image,
  description,
  systemAppOptions
}) => {
  const userService = useService($UserService);
  const featureService = useService($FeatureService);
  const snackbarService = useService($SnackbarService);
  const execute = useSlashCommandWithoutChannel();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  if (systemAppOptions.flag && !featureService.hasSomeFlagsEnabled([systemAppOptions.flag])) {
    return null;
  }
  const value = userService.currentUser ? userService.currentUser[systemAppOptions.userProperty].toString() : '';
  const openApp = async (): Promise<void> => {
    logEvent('ProfileEdit', systemAppOptions.trackingId);
    if (systemAppOptions.navigateTo) {
      navigate(systemAppOptions.navigateTo);
    } else if (systemAppOptions.openAppSlashCommand) {
      const result = await execute(systemAppOptions.openAppSlashCommand);
      if (result !== 'SendSlashCommandSuccess') {
        snackbarService.showGenericError();
      }
    }
  };
  return <EditProfileEntry image={image}>
				<Input adornmentEnd={<IconEdit onPress={openApp} />} value={value} label={label} description={description} onPress={openApp} onFocus={dismissKeyboard} />
			</EditProfileEntry>;
});