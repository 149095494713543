import * as React from 'react';
import { OS, os } from '../tools/os';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
export const PrivacyScreenContext = React.createContext<{
  enablePrivacyScreen: (componentId: string) => void;
  disablePrivacyScreen: (componentId: string) => void;
} | undefined>(undefined);
export const usePrivacyScreen = (componentId: string) => {
  const context = React.useContext(PrivacyScreenContext);
  if (!context) {
    throw new Error('usePrivacyScreen must be used within a PrivacyScreenProvider');
  }
  const enable = React.useCallback(() => {
    context.enablePrivacyScreen(componentId);
  }, [context, componentId]);
  const disable = React.useCallback(() => {
    context.disablePrivacyScreen(componentId);
  }, [context, componentId]);
  return React.useMemo(() => {
    return {
      enable,
      disable
    };
  }, [enable, disable]);
};
export const PrivacyScreenProvider: React.FC = ({
  children
}) => {
  const activeComponentsRef = React.useRef<Set<string>>(new Set());
  const enablePrivacyScreen = (componentId: string) => {
    const isMobile = os === OS.android || os === OS.ios;
    if (isMobile && activeComponentsRef.current.size === 0) {
      PrivacyScreen.enable().catch(() => {});
    }
    activeComponentsRef.current.add(componentId);
  };
  const disablePrivacyScreen = (componentId: string) => {
    const isMobile = os === OS.android || os === OS.ios;
    activeComponentsRef.current.delete(componentId);
    if (isMobile && activeComponentsRef.current.size === 0) {
      PrivacyScreen.disable().catch(() => {});
    }
  };
  const context = React.useMemo(() => {
    return {
      enablePrivacyScreen,
      disablePrivacyScreen
    };
  }, []);
  return <PrivacyScreenContext.Provider value={context}>
			{children}
		</PrivacyScreenContext.Provider>;
};