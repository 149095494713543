import { createSvgIcon, Flex, FlexCol, Text, Z_INDEX, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $KeyboardService } from '@knuddels-app/Keyboard';
export const IconJumpLatest = createSvgIcon(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.4207 20.3077C14.7903 19.065 17.4854 16.416 19.3846 13.5685C19.5685 13.2007 19.6622 12.8293 19.4783 12.5553C19.2908 12.1839 19.0168 12 18.6454 12H15.2307C15.2307 12 14.6755 3.96995 14.4014 3.59855C14.03 3.13702 13.0168 2.76923 12 2.76923C10.9832 2.76923 9.87618 3.13702 9.50839 3.59855C9.23075 3.96995 8.76921 12 8.76921 12H5.35454C4.98315 12 4.70911 12.1839 4.43147 12.5553C4.33772 12.9231 4.33772 13.2909 4.52161 13.5685C6.42787 16.3601 9.13786 19.0497 10.5037 20.3077H5.99997C5.23527 20.3077 4.61536 20.9276 4.61536 21.6923C4.61536 22.457 5.23527 23.0769 5.99997 23.0769H18C18.7647 23.0769 19.3846 22.457 19.3846 21.6923C19.3846 20.9276 18.7647 20.3077 18 20.3077H13.4207Z" fill="currentColor" />
	</svg>);
export const IconJumpUnread = createSvgIcon(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M19.3846 13.5685C16.7993 17.4447 12.7392 20.9531 12.5553 21.0469C12.4615 21.137 12.1839 21.2308 12 21.2308C11.8161 21.2308 11.5384 21.137 11.3545 21.0469C11.1707 20.9531 7.10695 17.3546 4.52161 13.5685C4.33772 13.2909 4.33772 12.9231 4.43147 12.5553C4.70911 12.1839 4.98315 12 5.35454 12H8.76921C8.76921 12 9.23075 3.96995 9.50839 3.59855C9.87618 3.13702 10.9832 2.76923 12 2.76923C13.0168 2.76923 14.03 3.13702 14.4014 3.59855C14.6755 3.96995 15.2307 12 15.2307 12H18.6454C19.0168 12 19.2908 12.1839 19.4783 12.5553C19.6622 12.8293 19.5685 13.2007 19.3846 13.5685Z" fill="currentColor" />
	</svg>);
const IsDarkColorContext = React.createContext(false);
export const JumpToBadgeParent: React.FC<{
  isDarkParent: boolean;
}> = ({
  isDarkParent,
  children
}) => {
  return <IsDarkColorContext.Provider value={isDarkParent}>
			<AnimatePresence>{children}</AnimatePresence>
		</IsDarkColorContext.Provider>;
};
export const JumpToBadge: React.FC<{
  onPress?: () => void;
  icon: JSX.Element;
  text: string | JSX.Element;
}> = ({
  onPress,
  icon,
  text
}) => {
  const darkParent = React.useContext(IsDarkColorContext);
  const keyboardService = useService($KeyboardService);
  return <motion.div style={{
    pointerEvents: 'none',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: Z_INDEX.HIGHEST_CONTENT,
    right: 0
  }} initial={{
    opacity: 0,
    transform: 'translate3d(0,100px,0)'
  }} animate={{
    opacity: 1,
    transform: 'translate3d(0,0,0)'
  }} exit={{
    opacity: 0,
    transform: 'translate3d(0,100px,0)'
  }}>
			<div onClick={() => {
      keyboardService.focusable?.focus();
      onPress?.();
    }} style={{
      backdropFilter: 'blur(15px)',
      WebkitBackdropFilter: 'blur(15px)',
      transform: 'translate3d(0, 0, 0)' // force hardware acceleration
    }} className={_c0 + ("white-transparent-160" ? resolveIsDarkColor("white-transparent-160", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1 + ((darkParent ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(darkParent ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (darkParent ? _c2 : _c3)}>
					{icon}
					{typeof text === 'string' ? <Text type={'body2'} bold={true} className={_c4}>
							{text}
						</Text> : text}
				</div>
			</div>
		</motion.div>;
};
export const UnreadMessageIndicator: React.FC<{
  observer: IntersectionObserver;
  id: string;
}> = ({
  observer,
  id
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const i18n = useService($I18n);
  React.useEffect(() => {
    observer.observe(ref.current!);
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [observer]);
  return <div data-id={id} ref={ref} className={_c5}>
			<div className={_c6 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			<Text bold={true} state={'highlighted'} className={_c7}>
				{i18n.format(declareFormat({
        id: 'channel.newMessagesIndicator'
      }))}
			</Text>
			<div className={_c8 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
		</div>;
};
const _c0 = " Knu-FlexCol pointerEvents-all bg-white-transparent-160 mb-base cursor-pointer borderRadius-large p-small px-base shadow-Shadow4 mx-auto ";
const _c1 = " Knu-Flex gap-tiny alignItems-center ";
const _c2 = " bg-transparentDark ";
const _c3 = " bg-transparentLight ";
const _c4 = "  ";
const _c5 = " Knu-Flex gap-tiny py-base textAlign-center alignItems-center ";
const _c6 = " Knu-FlexCol bg-accent height-2px flex-1 ";
const _c7 = "  ";
const _c8 = " Knu-FlexCol bg-accent height-2px flex-1 ";