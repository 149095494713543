import { CurrentServerTime } from '@generated/graphql';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from './serviceIds';

@injectable()
export class CheckSystemTimeService {
	constructor(
		@inject($AuthenticatedClientService)
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($FirebaseAnalyticsService)
		firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {
		// check if client system time is invalid (as it creates problems with session token validity)
		// currently we only track it to be able to estimate the impact and how we handle it.
		authenticatedClientService.currentK3Client
			.queryWithResultPromise(CurrentServerTime, {})
			.onOk(currentServerTimeInMs => {
				const diff = +currentServerTimeInMs - Date.now();
				const threshold = 5 * 60 * 1000;
				if (Math.abs(diff) > threshold) {
					const diffInMin = Math.round(diff / 1000 / 60) + 'min';
					const timezoneOffset = new Date().getTimezoneOffset() / -60;
					const timezone =
						'GMT' +
						(timezoneOffset < 0
							? timezoneOffset
							: '+' + timezoneOffset);
					firebaseAnalyticsService.logEvent(
						'Error_General',
						`UserSystemTimeOutOfSync_${diffInMin}_${timezone}`
					);
				}
			});
	}
}
