import * as React from 'react';
import { ScrollView } from '@knuddels/component-library';
import { ChangeNicknameSettings } from './ChangeNicknameSettings';
import { ProfilePrivacySettings } from './ProfilePrivacySettings';
import { usePrivacySettingState } from './usePrivacySettingState';
import { observer } from '@knuddels-app/mobx';
import { nickSwitchFlag } from '@knuddelsModules/FeatureFlags';
import { useService } from '@knuddels-app/DependencyInjection';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { ProfileVisitorSettings } from './ProfileVisitorSettings';
import { useVisitorNotificationSettingState } from './useVisitorNotificationSettingState';
export const PrivacySettings: React.FC = observer('PrivacySettings', () => {
  const {
    data,
    updateData
  } = usePrivacySettingState();
  const {
    data: visitorData,
    updateData: updateVisitorData
  } = useVisitorNotificationSettingState();
  const featureService = useService($FeatureService);
  const nickSwitchEnabled = featureService.hasSomeFlagsEnabled([nickSwitchFlag]);
  if (!data) {
    return null;
  }
  return <div className={_c0}>
			<ProfileVisitorSettings data={visitorData} updateData={updateVisitorData} />
			<ProfilePrivacySettings data={data} updateData={updateData} nickSwitchEnabled={nickSwitchEnabled} />
			<ChangeNicknameSettings data={data} updateData={updateData} nickSwitchEnabled={nickSwitchEnabled} />
		</div>;
});
const _c0 = " Knu-ScrollView pb-large height-full ";