import * as ids from '../providedServices';
import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { OnlineStatusService } from './services/OnlineStatusService';
import { foreachProp } from '@knuddels/std';
import { UserService } from './services/UserService';
import { UserOnlineStatusService } from './services/UserOnlineStatusService';
import { UserDataComponents } from './services/UserDataComponents';
import { IgnoreService } from './services/IgnoreService';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$UserDataComponents: ctx.loggedInScope.toSingletonAutostart(
			UserDataComponents
		),
		$OnlineStatusService: ctx.loggedInScope.toSingletonAutostart(
			OnlineStatusService
		),
		$UserService: ctx.loggedInScope.toSingleton(UserService),
		$UserOnlineStatusService: ctx.loggedInScope.toSingleton(
			UserOnlineStatusService
		),
		$IgnoreService: ctx.loggedInScope.toSingleton(IgnoreService),
	});
}
