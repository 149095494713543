import * as React from 'react';
import { FullConversationFragment, InitiateMessageHistoryExport } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerService } from '@knuddelsModules/Messenger';
import { ModalCard, ModalWrapper, Spacer } from '@shared/components';
import { Button, Flex, FlexCol, Smiley, Text } from '@knuddels/component-library';
import pic from '@shared/images/loading.gif';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { OS, os } from '@shared/components/tools/os';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $UserService } from '@knuddelsModules/UserData';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
export const ExportMessageHistoryModal: React.FC<{
  conversation: FullConversationFragment;
}> = ({
  conversation
}) => {
  const messengerService = useService($MessengerService);
  const thisVisibleOverlay = useService($ThisVisibleOverlay);
  const authenticatedClientService = useService($AuthenticatedClientService);
  const snackbarService = useService($SnackbarService);
  const userService = useService($UserService);
  const conversationId = conversation.id;
  React.useEffect(() => {
    const dispose = messengerService.onMessageHistoryExportReady.sub(event => {
      if (event.conversationId === conversationId) {
        const timestamp = getFormattedTimestamp();
        const nick1 = userService.currentUser.nick;
        const nick2 = conversation.otherParticipants[0].nick;
        const fileName = `chat_history_${nick1}_${nick2}_${timestamp}.txt`;
        download(event.downloadUrl, fileName);
        thisVisibleOverlay.dispose();
      }
    }).dispose;
    authenticatedClientService.currentK3Client.mutateWithResultPromise(InitiateMessageHistoryExport, {
      id: conversationId
    }).match({
      ok: result => {
        switch (result.__typename) {
          case 'ExportInitiatedRecentlyError':
            thisVisibleOverlay.dispose();
            snackbarService.showGenericError(formatMessage(declareFormat({
              id: 'EXPORT_RECENT_ERROR',
              defaultFormat: 'Please wait some time before exporting this conversation again.'
            })));
            break;
          case 'InternalError':
            thisVisibleOverlay.dispose();
            snackbarService.showGenericError();
            break;
        }
      },
      error: () => {
        thisVisibleOverlay.dispose();
        snackbarService.showGenericError();
      }
    });
    return dispose;
  }, [conversationId]);
  return <ModalWrapper cancelModal={() => {}} fillHeight renderNativeModal={false}>
			<ModalCard fillHeight>
				<div className={_c0}>
					<Smiley src={pic} width={'69px'} height={'62px'} />
					<Spacer size={'base'} />
					<Text bold={true} type={'body1'} state={'secondary'} className={_c1}>
						{formatMessage(declareFormat({
            id: 'EXPORTING_MESSAGE_HISTORY',
            defaultFormat: 'Preparing export...'
          }))}
					</Text>
					<div className={_c2}>
						<Button onPress={thisVisibleOverlay.dispose}>
							{formatMessage(declareFormat({
              id: 'EXPORT_CANCEL',
              defaultFormat: 'Cancel'
            }))}
						</Button>
					</div>
				</div>
			</ModalCard>
		</ModalWrapper>;
};
async function download(url: string, fileName: string): Promise<void> {
  try {
    if (os === OS.web) {
      await forceDownload(url, fileName);
      return;
    }
    const result = await Filesystem.downloadFile({
      url: url,
      directory: Directory.ExternalStorage,
      path: `Download/${fileName}`
    });
    await FileOpener.openFile({
      path: result.path
    });
  } catch (e) {
    console.error(e);
    this.snackbarService.showGenericError();
  }
}

// This function returns a string representing the current date and time in the "YYYYMMDD_HHMMSS" format.
function getFormattedTimestamp(): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}
async function forceDownload(url: string, fileName: string): Promise<void> {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function (): void {
    const urlCreator = window.URL || window.webkitURL;
    const fileUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = fileUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}
const _c0 = " Knu-FlexCol flex-1 placeItems-center ";
const _c1 = "  ";
const _c2 = " Knu-Flex mt-large ";