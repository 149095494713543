import * as React from 'react';
import { AlbumPhoto } from './AlbumPhoto';
import { User } from '@generated/graphql';
import { $CurrentEndpointStore, KnownEndpoints } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { createAccessiblePointerEventHandler, FlexCol, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { useOpenAlbumDetails } from '../../../useOpenAlbumDetails';
import { ProfileAlbumInfo } from '../profileQueryHelper';
import defaultPic from '@shared/images/nopic.png';
type Props = {
  user: Pick<User, 'id'> & {
    profilePicture: Pick<User['profilePicture'], 'urlLargeSquare' | 'urlVeryLarge'>;
  };
  data: ProfileAlbumInfo;
  size: number;
};
export const ProfilePhoto: React.FC<Omit<Props, 'data'>> = observer('ProfilePhoto', props => {
  const currentEndpointStore = useService($CurrentEndpointStore);
  const {
    profilePicture
  } = props.user;
  const profileUrl = currentEndpointStore.currentEndpoint.id === KnownEndpoints.Test.id ? profilePicture.urlLargeSquare : profilePicture.urlVeryLarge;
  return <AlbumPhoto size={props.size} url={profileUrl || defaultPic} blurUrl={profilePicture.urlLargeSquare || defaultPic} />;
});
export const OpenDetailsProfilePhoto: React.FC<Props> = props => {
  return <OpenDetailsProfilePhotoClickArea {...props}>
			<ProfilePhoto {...props} />
		</OpenDetailsProfilePhotoClickArea>;
};
export const OpenDetailsProfilePhotoClickArea: React.FC<Props> = props => {
  const openDetails = useOpenAlbumDetails();
  const profilePhoto = props.data?.albumProfilePhoto;
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => openDetails({
      userId: props.user.id,
      photoId: profilePhoto?.id
    }),
    disabled: !profilePhoto
  })} style={{
    height: resolveThemingValue(props.size, "sizes", useTheme()),
    width: resolveThemingValue(props.size, "sizes", useTheme())
  }} className={_c0 + " " + 'transition-view-target' + " "}>
			{props.children}
		</div>;
};
const _c0 = " Knu-FlexCol cursor-pointer ";