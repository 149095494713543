import {
	Children,
	Country,
	EditProfileEntryFragment,
	ProfileEditField,
	RelationshipStatus,
	Scalars,
	SexualOrientation,
	Smoker,
} from '@generated/graphql';
import { expectUnreachable, shouldBeUnreachable } from '@knuddels/std';
import { ProfileEntryValidator } from './ProfileEntryValidator';
import { ValidityState } from '../Entry/EditProfileEntry';

export type ProfileEntryValues =
	| string
	| readonly string[]
	| number
	| Country
	| Children
	| RelationshipStatus
	| SexualOrientation
	| Smoker
	| null
	| undefined;

export type ProfileEntryData = {
	entry: EditProfileEntryFragment;
	// used to easier save the data...
	value: string;
	changed: boolean;
	validity: ValidityState;
};
export type ProfileEntryDataMap = {
	[key in ProfileEditField]?: ProfileEntryData;
};

export function entryToTextValue(
	entry: EditProfileEntryFragment
): string | null | undefined {
	switch (entry.__typename) {
		case 'ProfileEditEntryFreeText':
			return entry.freeTextValue;
		case 'ProfileEditEntryStringList':
			return entry.stringListValue.join(', ');
		case 'ProfileEditEntryDate':
			return dateToText(entry.dateValue);
		case 'ProfileEditEntryChildren':
			return entry.childrenValue;
		case 'ProfileEditEntryRelationshipStatus':
			return entry.relationshipStatusValue === RelationshipStatus.Unknown
				? undefined
				: entry.relationshipStatusValue;
		case 'ProfileEditEntrySexualOrientation':
			return entry.sexualOrientationValue === SexualOrientation.Unknown
				? undefined
				: entry.sexualOrientationValue;
		case 'ProfileEditEntrySmoker':
			return entry.smokerValue;
		case 'ProfileEditEntryCountry':
			return entry.countryValue;
		default:
			shouldBeUnreachable(entry);
			return null;
	}
}

// should probably make this i18n ready (not force DD.MM.YYYY) or just use a date picker
// instead of manually transforming to a string input (same in validator)
export function dateToText(timestampString: string | null | undefined): string {
	if (timestampString) {
		try {
			const timestamp = parseInt(timestampString, 10);
			const date = new Date(timestamp);
			const day = date.getDate().toString().padStart(2, '0');
			// month goes from 0-11 => we need to do +1
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			return `${day}.${month}.${date.getFullYear()}`;
		} catch {
			// fallthrough
		}
	}
	return '';
}

export function updateProfileEntryData(
	entryData: ProfileEntryData,
	newValue: string,
	validator: ProfileEntryValidator
): ProfileEntryData | null {
	switch (entryData.entry.__typename) {
		case 'ProfileEditEntryFreeText':
			return {
				entry: {
					...entryData.entry,
					freeTextValue: newValue,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateFreeText
				),
				changed: true,
			};
		case 'ProfileEditEntryStringList': {
			const stringList = newValue
				? newValue
						.split(',')
						.map(it => it.trim())
						.filter(it => !!it)
				: [];
			return {
				entry: {
					...entryData.entry,
					stringListValue: stringList,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateStringList
				),
				changed: true,
			};
		}
		case 'ProfileEditEntryDate': {
			const date = newValue && parseDate(newValue);
			const timestamp = date
				? (date.getTime().toString() as Scalars['UtcTimestamp'])
				: null;
			return {
				entry: {
					...entryData.entry,
					dateValue: timestamp,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateDate
				),
				changed: true,
			};
		}
		case 'ProfileEditEntryRelationshipStatus':
			return {
				entry: {
					...entryData.entry,
					relationshipStatusValue: newValue as RelationshipStatus,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateMultiChoice
				),
				changed: true,
			};
		case 'ProfileEditEntrySexualOrientation':
			return {
				entry: {
					...entryData.entry,
					sexualOrientationValue: newValue as SexualOrientation,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateMultiChoice
				),
				changed: true,
			};
		case 'ProfileEditEntrySmoker':
			return {
				entry: {
					...entryData.entry,
					smokerValue: newValue as Smoker,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateMultiChoice
				),
				changed: true,
			};
		case 'ProfileEditEntryChildren':
			return {
				entry: {
					...entryData.entry,
					childrenValue: newValue as Children,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateMultiChoice
				),
				changed: true,
			};
		case 'ProfileEditEntryCountry':
			return {
				entry: {
					...entryData.entry,
					countryValue: newValue as Country,
				},
				value: newValue,
				validity: updateValidity(
					entryData,
					newValue,
					validator.validateMultiChoice
				),
				changed: true,
			};
		default:
			// It's fine to throw here because the entry isn't rendered
			// and thus can't be changed if it is just added in the backend.
			expectUnreachable(entryData.entry);
			return null;
	}
}

function updateValidity(
	entryData: ProfileEntryData,
	newValue: string,
	validation: (
		entry: EditProfileEntryFragment,
		value: string
	) => ValidityState
): ValidityState {
	const prevValidity = entryData.validity;
	const newValidity = validation(entryData.entry, newValue);

	if (
		prevValidity.type !== newValidity.type ||
		prevValidity.errorText !== newValidity.errorText
	) {
		return newValidity;
	}

	return prevValidity;
}

export function parseDate(input: string): Date | null {
	const parts = input.match(/(\d+)/g);
	if (!parts || parts.length < 2) {
		return null;
	}
	// note parts[1]-1
	return new Date(+parts[2], +parts[1] - 1, +parts[0]);
}
