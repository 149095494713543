import { ConversationKnuddelTransferMessageContentFragment, ConversationMessageFragment, MessengerConversation, User } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { FormattedMessage } from '@knuddels-app/i18n';
import { FlexCol, Text, useTheme } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { NickSwitch } from '@knuddelsModules/FeatureFlags';
import { $MessengerConversationService } from '@knuddelsModules/Messenger';
import { isPhotoMessage, isSnapMessage } from '@knuddelsModules/Messenger/bundle/isPhotoMessage';
import { IconReply } from '@shared/icons/IconReply';
import { motion, MotionValue, useTransform } from 'framer-motion';
import * as React from 'react';
import { conversationProfileImageEvent } from '../../../../analytics';
import { isPhotoCommentMessage } from '../../../../isPhotoCommentMessage';
import { MessageLightboxSource } from '../../Lightbox/Lightbox';
import { ConversationMessage } from '../../Messages/ConversationMessage';
import { useShowImages } from '../../Messages/useShowImages';
import { BirthdayMessage } from './BirthdayMessage';
import { KnuddelReceivedMessage } from './KnuddelReceivedMessage';
import { KnuddelSentMessage } from './KnuddelSentMessage';
import { MentorAchievedMessage } from './MentorAchievedMessage';
import { Message } from './Message';
import { NickSwitchMessage } from './NickSwitchMessage';
import { PrivateSystemMessage } from './PrivateSystemMessage';
import { MessageSwipeable } from '@shared/components/MessageSwipeable';
export interface MessengerChatMessageProps {
  message: ConversationMessageFragment;
  conversationId: MessengerConversation['id'];
  receiverId: string;
  receiverNick: string;
  senderId: MessengerConversation['otherParticipants'][0]['id'];
  senderNick: string;
  activeUserId: User['id'];
  isStackedLayout: boolean;
  showUserImage?: boolean;
  additionalMargin?: number;
  hasArrow?: boolean;
  animated?: boolean;
  setLightboxSrc: (src: MessageLightboxSource) => void;
  openProfile: (uid: User['id']) => void;
}
export const MessengerChatMessage: React.FC<MessengerChatMessageProps> = props => <MessageContent {...props} />;
const MessageContent: React.FC<MessengerChatMessageProps> = props => {
  const content = props.message.content;
  switch (content.__typename) {
    case 'ConversationKnuddelTransferMessageContent':
      return <KnuddelTransferMessage {...props} content={content} />;
    case 'ConversationPrivateSystemMessageContent':
      return <PrivateSystemMessage content={content} />;
    case 'ConversationMentorAchievedMessageContent':
      return <MentorAchievedMessage otherParticipantNick={props.activeUserId === props.senderId ? props.receiverNick : props.senderNick} />;
    case 'ConversationBirthdayMessageContent':
      return <BirthdayMessage otherParticipantNick={props.activeUserId === props.senderId ? props.receiverNick : props.senderNick} />;
    case 'ConversationNicknameChangeMessageContent':
      return <NickSwitch.OnActive>
					<NickSwitchMessage senderId={props.message.sender.id} messageId={props.message.id} oldNick={content.oldNick} newNick={content.newNick} />
				</NickSwitch.OnActive>;
    default:
      return <DefaultMessage {...props} />;
  }
};
const KnuddelTransferMessage: React.FC<MessengerChatMessageProps & {
  content: ConversationKnuddelTransferMessageContentFragment;
}> = ({
  message,
  receiverId,
  receiverNick,
  senderId,
  activeUserId,
  content
}) => {
  const isActiveUser = senderId === activeUserId;
  return !isActiveUser ? <KnuddelReceivedMessage knuddelAmount={content.knuddelAmount} sender={message.sender} /> : <KnuddelSentMessage receiverId={receiverId} receiverNick={receiverNick} content={content} />;
};
const AnimationWrapper: React.FC<{
  progress: MotionValue;
  direction?: 'left' | 'right';
}> = ({
  progress,
  direction,
  children
}) => {
  const x = useTransform(progress, value => {
    if (direction === 'left') {
      return Math.min(value * 100 - 20, 0);
    } else {
      return Math.max((1 - value - 0.8) * 100, 0);
    }
  });
  return <motion.div style={{
    opacity: progress,
    x: x
  }}>
			{children}
		</motion.div>;
};
const ListItem: React.FC<{
  onLeftAction: () => void;
  forceDisableSwipe?: boolean;
}> = props => {
  return <MessageSwipeable threshold={60} leftAction={props.forceDisableSwipe ? undefined : {
    component: progress => {
      return <AnimationWrapper progress={progress} direction={'left'}>
										<div className={_c0}>
											<IconReply size={'large'} />
										</div>
									</AnimationWrapper>;
    },
    onAction: cb => {
      props.onLeftAction();
      cb(true, true);
    }
  }}>
			{props.children}
		</MessageSwipeable>;
};
const DefaultMessage: React.FC<MessengerChatMessageProps> = props => {
  const {
    senderId,
    activeUserId,
    message,
    conversationId,
    setLightboxSrc,
    isStackedLayout,
    showUserImage,
    additionalMargin,
    hasArrow,
    receiverId,
    receiverNick
  } = props;
  const theme = useTheme();
  const handleUserImageClick = useHandleUserImageClick(props);
  const isActiveUser = senderId === activeUserId;
  const align = isActiveUser ? 'right' : 'left';
  const hasImagePermission = useShowImages(false, isActiveUser, message.sender.id);
  const hasSensitiveContent = getHasSensitiveContent(message.content);
  const isPhotoComment = isPhotoCommentMessage(message);
  const isPhoto = isPhotoMessage(message);
  const isSnap = isSnapMessage(message);
  const noContextMenu = isPhotoComment || isSnap || isPhoto && !hasImagePermission || hasSensitiveContent;
  const color = shouldHideBubble(isActiveUser, message) ? undefined : isActiveUser && !isPhotoComment ? theme.colors.Messenger.outgoingMessage : theme.colors.Messenger.incomingMessage;
  const messengerConversationService = useService($MessengerConversationService);
  const content = <ConversationMessage message={message} conversationId={conversationId} receiverId={receiverId} type={isActiveUser ? 'sender' : 'receiver'} setLightboxSrc={setLightboxSrc} noContextMenu={noContextMenu} />;
  const header = useMessageHeader(message, receiverNick, isActiveUser);
  return <ListItem onLeftAction={() => {
    messengerConversationService.getOrCreateClientConversationState(props.conversationId).setSelectedMessageToQuote(props.message);
  }} forceDisableSwipe={hasSensitiveContent || isPhoto && !hasImagePermission}>
			<Message uid={senderId} isStackedLayout={isStackedLayout} align={align} bubbleColor={color} onImageClick={handleUserImageClick} showImage={showUserImage} additionalMargin={additionalMargin} hasArrow={hasArrow} header={header} noBubblePadding={isPhotoComment} shadow>
				{content}
			</Message>
		</ListItem>;
};
function shouldHideBubble(isActiveUser: boolean, message: ConversationMessageFragment): boolean {
  switch (message.content.__typename) {
    case 'ConversationVisiblePhotoCommentMessageContent':
      return false;
    case 'ConversationDeletedPhotoCommentMessageContent':
    case 'ConversationPrivateSystemMessageContent':
      return true;
    case 'ConversationHiddenPhotoCommentMessageContent':
      return !isActiveUser;
    case 'ConversationKnuddelTransferMessageContent':
      return isActiveUser;
    default:
      return false;
  }
}
const useMessageHeader = (message: ConversationMessageFragment, receiverNick: string, isSenderActiveUser: boolean) => {
  if (isPhotoCommentMessage(message)) {
    if (isSenderActiveUser) {
      return <Text state={'secondary'} className={_c1}>
					<FormattedMessage id={declareFormat({
          id: 'messenger.photoComment.self.header',
          defaultFormat: 'You commented on the photo of {nick}:'
        })} values={{
          nick: <Text bold={true} className={_c2}>{receiverNick}</Text>
        }} />
				</Text>;
    } else {
      return <Text state={'secondary'} className={_c3}>
					<FormattedMessage id={declareFormat({
          id: 'messenger.photoComment.other.header',
          defaultFormat: '{nick} commented on your photo:'
        })} values={{
          nick: <Text bold={true} className={_c4}>{message.sender.nick}</Text>
        }} />
				</Text>;
    }
  } else {
    return undefined;
  }
};
const useHandleUserImageClick = (props: MessengerChatMessageProps) => {
  const environment = useService($Environment);
  const stAppOpenProfile = () => {
    if (environment.messengerSystemAppInterface) {
      environment.messengerSystemAppInterface.executeOpenProfileSlashCommand(props.senderNick);
    }
  };
  return () => {
    conversationProfileImageEvent.track('ProfileImage_Clicked');
    if (globalEnv.product === 'stapp-messenger') {
      stAppOpenProfile();
    } else {
      props.openProfile(props.senderId);
    }
  };
};
const getHasSensitiveContent = (content: ConversationMessageFragment['content']): boolean => {
  if (content.__typename === 'ConversationForwardedMessageContent' || content.__typename === 'ConversationQuotedMessageContent') {
    return getHasSensitiveContent(content.nestedMessage.content);
  }
  return (content.__typename === 'ConversationImageMessageContent' || content.__typename === 'ConversationSnapMessageContent') && !content.imageAccepted;
};
const _c0 = " Knu-FlexCol placeItems-center gap-base px-base flexShrink-0 ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";