import {
	CreateDeviceToken,
	CreateDeviceTokenResult,
	RefreshSessionErrorFragment,
} from '@generated/graphql';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	autorunWhen,
	computed,
	observable,
	runInAction,
	when,
} from '@knuddels-app/mobx';
import {
	BugIndicatingError,
	Disposable,
	EventEmitter,
	expectUnreachable,
	Narrow,
} from '@knuddels/std';
import { OS, os } from '@shared/components/tools/os';
import { AuthSession } from './AuthSession';
import { AuthTokenProvider } from './client/AuthTokenProvider';
import { Endpoint, KnownEndpoints } from './Endpoints';
import {
	$ClientInfoStore,
	$CurrentEndpointStore,
	$DeviceTokenStore,
	$K3ApolloClientFactory,
} from './serviceIds';

type AuthServiceState =
	| { kind: 'initializing' }
	| { kind: 'loggedOut'; error?: LinkError }
	| {
			kind: 'loggingIn';
			reason: 'obtainingDeviceToken' | 'obtainingSessionToken';
			endpoint: Endpoint;
	  }
	| {
			kind: 'loggedIn';
			sessionTokenProvider: AuthTokenProvider;
			deviceToken: string;
			endpoint: Endpoint;
	  }
	| { kind: 'loggingOut' };

type PrivateAuthServiceState =
	| { kind: 'initializing' }
	| { kind: 'loggedOut'; error?: LinkError }
	| { kind: 'fetchingDeviceToken'; endpoint: Endpoint }
	| { kind: 'session'; session: AuthSession; loggingOut?: boolean };
export type LinkError = { error?: unknown } & (
	| {
			kind: 'AuthError';
			details:
				| 'UnknownUser'
				| 'InvalidCredentials'
				| 'InvalidToken'
				| 'NickSwitchInProgress';
	  }
	| { kind: 'CouldNotReachServer' | 'InternalError' }
	| {
			// Note that this error has special handling and a separate error screen.
			kind: 'SessionConnectionError';
			data: RefreshSessionErrorFragment;
	  }
);
type LogoutOptions = {
	disableConvenienceLogin?: boolean;
	newNick?: string;
};

@injectable()
export class AuthService implements Disposable {
	public readonly dispose = Disposable.fn();

	private _lastForceRefreshSession = 0;

	public get lastForceRefreshSession(): number {
		return this._lastForceRefreshSession;
	}

	@computed
	get state(): AuthServiceState {
		const state = this._state;

		if (state.kind === 'loggedOut') {
			return { kind: 'loggedOut', error: state.error };
		} else if (state.kind === 'fetchingDeviceToken') {
			return {
				kind: 'loggingIn',
				reason: 'obtainingDeviceToken',
				endpoint: state.endpoint,
			};
		} else if (state.kind === 'session') {
			const session = state.session;

			if (session.state.kind === 'error') {
				// Wait for the autorun to dispose the session.
				// It then sets the state to `loggedOut`.
				return { kind: 'loggingOut' };
			} else if (
				session.state.kind === 'requestingSessionToken' &&
				session.state.initial
			) {
				return {
					kind: 'loggingIn',
					reason: 'obtainingSessionToken',
					endpoint: session.endpoint,
				};
			} else if (
				session.state.kind === 'loggedIn' ||
				(session.state.kind === 'requestingSessionToken' &&
					!session.state.initial)
			) {
				this.firebaseAnalyticsService.logLogin();

				return {
					kind: 'loggedIn',
					sessionTokenProvider: session,
					endpoint: session.endpoint,
					deviceToken: session.deviceToken,
				};
			} else {
				throw new BugIndicatingError('Unexpected state');
			}
		} else if (state.kind === 'initializing') {
			return { kind: 'initializing' };
		} else {
			return expectUnreachable(state);
		}
	}

	@observable private _state: PrivateAuthServiceState = {
		kind: 'initializing',
	};

	private readonly beforeVoluntaryLogoutEmitter = new EventEmitter<{
		logoutOptions: LogoutOptions;
		waitOnPromise(promise: Promise<any>): void;
	}>();
	// tslint:disable-next-line: member-ordering
	public readonly onBeforeVoluntaryLogout =
		this.beforeVoluntaryLogoutEmitter.asEvent();

	private readonly deviceTokenAvailableEmitter = new EventEmitter<{
		token: string;
	}>();
	// tslint:disable-next-line: member-ordering
	public readonly onDeviceTokenAvailable =
		this.deviceTokenAvailableEmitter.asEvent();

	private readonly beforeRedirectEmitter = new EventEmitter<{
		waitOnPromise(promise: Promise<any>): void;
	}>();
	// tslint:disable-next-line: member-ordering
	public readonly onBeforeRedirect = this.beforeRedirectEmitter.asEvent();

	constructor(
		@inject($K3ApolloClientFactory)
		private readonly k3ApolloClientFactory: typeof $K3ApolloClientFactory.T,
		@inject($DeviceTokenStore)
		private readonly deviceTokenStore: typeof $DeviceTokenStore.T,
		@inject($CurrentEndpointStore)
		private readonly currentEndpointStore: typeof $CurrentEndpointStore.T,
		@inject($ClientInfoStore)
		private readonly clientInfoStore: typeof $ClientInfoStore.T,
		@inject($FirebaseAnalyticsService)
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {
		this.init();

		const LIVE_APP = 'https://app.knuddels.de';
		const PREVIEW_APP = 'https://preview.knuddels.de';

		if (os === OS.web && [LIVE_APP, PREVIEW_APP].includes(window.origin)) {
			if (
				window.origin === PREVIEW_APP &&
				currentEndpointStore.currentEndpoint.id !==
					KnownEndpoints.Test.id
			) {
				return;
			}

			this.dispose.track(
				autorunWhen(
					{ name: 'Redirect when logged out' },
					() =>
						this.state.kind === 'loggedOut' &&
						(!this.state.error ||
							// don't instantly redirect with this error, because we want the user to see it and click it away first
							this.state.error.kind !== 'SessionConnectionError'),
					() => {
						this.redirect(
							`${
								window.origin === LIVE_APP
									? KnownEndpoints.DE.urls.defaultOrigin
									: KnownEndpoints.Test.urls.defaultOrigin
							}/login`
						);
					}
				)
			);
		}

		this.dispose.track(
			autorunWhen(
				{ name: 'Logout when session is in error state' },
				() => {
					if (this._state.kind !== 'session') {
						return false;
					}
					const session = this._state.session;
					if (session.state.kind !== 'error') {
						return false;
					}

					return session.state.error;
				},
				error => {
					if (this._state.kind !== 'session') {
						// This should be logically impossible,
						// but it rules out a bug that was observed.
						throw new BugIndicatingError(
							`Unexpected logged-in state (${this._state.kind})`
						);
					}
					this.internalLogout(error);
				}
			)
		);
	}

	private async init(): Promise<void> {
		const deviceTokenInfo =
			await this.deviceTokenStore.getDeviceTokenInfo();

		if (deviceTokenInfo) {
			await this.internalLoginWithDeviceToken(
				// TODO store endpoint associated to jwt
				deviceTokenInfo.endpoint,
				deviceTokenInfo.deviceToken
			);
		} else {
			runInAction(
				'Set state to loggedOut as device token was invalid',
				() => this.updateState({ kind: 'loggedOut' })
			);
		}
	}

	public redirect = async (url: string) => {
		if (globalEnv.platform === 'web') {
			const promises = new Array<Promise<any>>();
			this.beforeRedirectEmitter.emit({
				waitOnPromise: p => promises.push(p),
			});
			await Promise.all(promises);

			window.location.replace(url);
		}
	};

	public async login(
		endpoint: Endpoint,
		username: string,
		password: string
	): Promise<Narrow<AuthServiceState, 'loggedIn' | 'loggedOut'>> {
		this.assertState(s => s.kind === 'loggedOut');

		this.updateState({ kind: 'fetchingDeviceToken', endpoint });
		const result = await this.getDeviceToken(endpoint, username, password);

		if (result.kind !== 'success') {
			return this.updateState({
				kind: 'loggedOut',
				error: result,
			});
		}

		const state = await this.internalLoginWithDeviceToken(
			endpoint,
			result.deviceToken
		);
		return state;
	}

	private async getDeviceToken(
		endpoint: Endpoint,
		username: string,
		password: string
	): Promise<{ kind: 'success'; deviceToken: string } | LinkError> {
		const unauthenticatedClient = this.k3ApolloClientFactory.createClient({
			endpoint,
			authTokenProvider: AuthTokenProvider.empty,
			withSubscriptionClient: false,
		});

		return unauthenticatedClient
			.queryWithResultPromise(CreateDeviceToken, {
				username,
				password,
			})
			.match({
				error: err => ({
					kind: 'CouldNotReachServer',
					error: err,
				}),
				ok: ({ result, token }) => {
					switch (result) {
						case CreateDeviceTokenResult.Success: {
							return {
								kind: 'success',
								deviceToken: token!,
							};
						}

						case CreateDeviceTokenResult.InvalidCredentials: {
							return {
								kind: 'AuthError',
								details: 'InvalidCredentials',
							};
						}
						case CreateDeviceTokenResult.UnknownUser: {
							return {
								kind: 'AuthError',
								details: 'UnknownUser',
							};
						}
						case CreateDeviceTokenResult.NickSwitchInProgress:
							return {
								kind: 'AuthError',
								details: 'NickSwitchInProgress',
							};

						case CreateDeviceTokenResult.InternalError:
						default:
							return {
								kind: 'InternalError',
							};
					}
				},
			});
	}

	public async loginWithDeviceToken(
		endpoint: Endpoint,
		deviceToken: string
	): Promise<Narrow<AuthServiceState, 'loggedIn' | 'loggedOut'>> {
		this.assertState(s => s.kind === 'loggedOut');
		return await this.internalLoginWithDeviceToken(endpoint, deviceToken);
	}

	private async internalLoginWithDeviceToken(
		endpoint: Endpoint,
		deviceToken: string
	): Promise<Narrow<AuthServiceState, 'loggedIn' | 'loggedOut'>> {
		const clientInfo = await this.clientInfoStore.getOrCreateClientInfo();
		const session = new AuthSession(
			deviceToken,
			this.k3ApolloClientFactory,
			endpoint,
			clientInfo
		);

		this.updateState({ kind: 'session', session });

		const newState = await when(
			{ name: 'Wait for successful login or error' },
			() => this.state,
			{
				resolveTest: state =>
					(state.kind === 'loggedIn' || state.kind === 'loggedOut') &&
					state,
			}
		);

		if (newState.kind === 'loggedIn') {
			this.deviceTokenStore.setDeviceToken(deviceToken);
			this.deviceTokenAvailableEmitter.emit({ token: deviceToken });
		}

		return newState;
	}

	public logout = async (options?: LogoutOptions): Promise<void> => {
		if (this._state.kind !== 'session') {
			throw new BugIndicatingError(
				`Can only logout when being logged in (kind was ${this._state.kind})`
			);
		}
		if (this._state.loggingOut) {
			return;
		}
		this.updateState({ ...this._state, loggingOut: true });

		const promises = new Array<Promise<any>>();
		this.beforeVoluntaryLogoutEmitter.emit({
			logoutOptions: options || {},
			waitOnPromise: p => promises.push(p),
		});
		await Promise.all(promises);

		if (this._state.kind !== 'session') {
			throw new BugIndicatingError(
				`Unexpectedly logged out. The Before-Voluntary-Logout-Event may not alter the auth session! (kind was ${
					(this._state as any).kind
				})`
			);
		}
		this.internalLogout(undefined);
	};

	public clearError = (): void => {
		this.assertState(s => s.kind === 'loggedOut');
		this.updateState({ kind: 'loggedOut' });
	};

	// used to test for authorization changes
	public forceRefreshSession(): void {
		this._lastForceRefreshSession = Date.now();

		if (this._state.kind !== 'session') {
			// should we throw here?
			return;
		}

		this._state.session.forceRefreshSession();
	}

	private internalLogout(error: LinkError | undefined): void {
		if (this._state.kind !== 'session') {
			throw new BugIndicatingError(
				`Can only logout internally when being logged in (kind was ${this._state.kind})`
			);
		}

		this._state.session.dispose();
		this.updateState({ kind: 'loggedOut', error });

		this.deviceTokenStore.clearDeviceToken();
	}

	public expectLoggedInState(): Narrow<AuthServiceState, 'loggedIn'> {
		if (this.state.kind === 'loggedIn') {
			return this.state;
		} else {
			throw new BugIndicatingError('Not logged in');
		}
	}

	private updateState<T extends PrivateAuthServiceState>(newState: T): T {
		runInAction(`Update auth service state to "${newState.kind}"`, () => {
			this._state = newState;
		});
		return newState;
	}

	private assertState(
		test: (state: PrivateAuthServiceState) => boolean
	): void {
		if (!test(this._state)) {
			throw new BugIndicatingError('Unexpected state');
		}
	}
}

// tslint:disable:max-file-line-count
