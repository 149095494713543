import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ClientInfoStore } from '@knuddels-app/Connection/serviceIds';
import { Flex, FlexCol, Smiley, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export const SettingsAppInfo: React.FC = observer('SettingsAppInfo', () => {
  return <div className={_c0}>
			<div className={_c1 + ("blue-200" ? resolveIsDarkColor("blue-200", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Smiley width={56} height={28} mb={'large'} src={require('../../assets/sm_weltreise_wallstreet.png')} />

				<ClientVersion />
			</div>
		</div>;
});
const ClientVersion: React.FC = observer('ClientVersion', () => {
  const clientInfo = useService($ClientInfoStore);
  const clientVersion = clientInfo?.clientVersion;
  if (!clientVersion) {
    return null;
  }
  const {
    major,
    minor,
    patch,
    gitRevision
  } = clientVersion;
  const version = `Version: ${major}.${minor}.${patch}`;
  const git = `(${gitRevision.substr(0, 8)})`;
  return <div className={_c2}>
			<Text bold={true} type="body2" color={'blue-600'} className={_c3}>
				{version}
			</Text>
			<Text type="body2" color={'blue-600'} className={_c4}>
				{git}
			</Text>
		</div>;
});
const _c0 = " Knu-Flex placeItems-center ";
const _c1 = " Knu-FlexCol borderRadius-circle placeItems-center width-160px height-160px bg-blue-200 ";
const _c2 = " Knu-FlexCol ";
const _c3 = " textAlign-center ";
const _c4 = " textAlign-center ";