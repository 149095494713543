import * as React from 'react';
import { formatMessage } from '@knuddels-app/i18n';
import { TextBadge } from '@shared/components';
import { declareFormat } from '@knuddels-app/i18n';
import { Flex } from '@knuddels/component-library';
export class ImagePlaceholderBadge extends React.PureComponent<unknown> {
  render(): JSX.Element {
    const text = formatMessage(declareFormat({
      id: 'IMAGE_PLACEHOLDER_BADGE_TEXT',
      defaultFormat: 'IMAGE'
    }));
    return <div className={_c0}>
				<TextBadge text={text} />
			</div>;
  }
}
const _c0 = " Knu-Flex ";