import * as React from 'react';
import { Flex, FallbackBox } from '@knuddels/component-library';
interface Props {
  children?(width: number, height: number): React.ReactNode;
}
export const EditProfileContentWrapper: React.FC<Props> = ({
  children
}) => {
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  return <FallbackBox onLayout={e => setSize(e)} className={_c0 + (height ? _c1 : _c2)}>
			{width && children ? children(width, height) : null}
		</FallbackBox>;
};
const _c0 = " Knu-Flex flex-1 overflow-hidden ";
const _c1 = " opacity-1 ";
const _c2 = " opacity-0 ";