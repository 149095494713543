import React from 'react';
import { ChannelListItem, templateCategoryHeader } from '../shared/ChannelList/ChannelListVirtualItems';
import { ChannelGroupExpandedContext } from '../ChannelGroupExpandedContext';
import { ChannelCategoryFragment } from '@generated/graphql';
import { useFullCategoryHeader } from './useFullCategoryHeader';
import { getMoreCategoryFormatId, getTopCategoryFormatId } from '../shared/getCategoryFormatId';
import { ExpandChannelsButton } from '../Overview/ChannelSelection/ChannelList/CategoryHeaderButton';
import { useSmallChannelGroupExpanded } from '../SmallChannelGroupExpandedContext';
import { sortAllChannels } from '../shared/ChannelList/sortAllChannels';
export const useFullListData = (categories: ChannelCategoryFragment, isGrouped?: boolean): ChannelListItem[] => {
  const {
    isExpanded: isSmallGroupExpanded
  } = useSmallChannelGroupExpanded();
  const {
    isExpanded
  } = React.useContext(ChannelGroupExpandedContext);
  const topChannelsHeaderTitle = useFullCategoryHeader(categories?.id, getTopCategoryFormatId(categories?.id));
  const moreChannelsHeaderTitle = useFullCategoryHeader(categories?.id, getMoreCategoryFormatId(categories?.id));
  return React.useMemo(() => {
    const topChannels: ChannelListItem[] = [];
    const moreChannels: ChannelListItem[] = [];
    topChannels.push({
      type: templateCategoryHeader,
      key: `${categories?.id}-top-channels-header`,
      height: 72,
      title: topChannelsHeaderTitle
    });
    if (isGrouped) {
      moreChannels.push({
        type: templateCategoryHeader,
        key: `${categories?.id}-more-channels-header`,
        height: 72,
        title: moreChannelsHeaderTitle,
        button: <ExpandChannelsButton />
      });
    }
    sortAllChannels(categories, topChannels, moreChannels, isExpanded, isSmallGroupExpanded, categories?.id === 'games' || categories?.id === 'lastvisited' ? 'default' : 'condensed', isGrouped);
    return [...topChannels, ...moreChannels];
  }, [categories, isExpanded, isSmallGroupExpanded, moreChannelsHeaderTitle, topChannelsHeaderTitle]);
};