import { inject, injectable, ServiceInjector } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { action, computed, observable, ObservableSet, reaction } from '@knuddels-app/mobx';
import { Disposable, EventEmitter } from '@knuddels/std';
import { $LocationService } from '@knuddels-app/location';
import { $ThisVisibleOverlay } from './serviceIds';
import { FormattedText, TextModal } from '@shared/components';
import { OverlayFactory } from '@knuddels-app/overlays/OverlayFactory';
import { generateId } from '@knuddels/component-library';
interface SimpleTextModal {
  headline: string;
  text: string | FormattedText;
  buttonText: string;
}
@injectable()
export class OverlayService {
  @observable
  public isSystemAppOverlayVisible = false;
  private readonly overlayProviders = new ObservableSet<OverlayProvider>();
  private readonly additionalOverlays = new ObservableSet<VisibleOverlayImpl<any>>();
  @computed
  public get overlays(): Overlay[] {
    const result = new Array<Overlay>();
    for (const p of this.overlayProviders) {
      for (const o of p.overlays) {
        result.push(o);
      }
    }
    for (const o of this.additionalOverlays) {
      result.push(o);
    }
    return result.sort((a, b) => a.addedAt - b.addedAt);
  }
  @computed
  public get isAnyOverlayVisible(): boolean {
    return this.overlays.length > 0 || this.isSystemAppOverlayVisible;
  }
  constructor(@inject($LocationService)
  locationService: typeof $LocationService.T) {
    reaction({
      name: 'Close Overlays on location change'
    }, () => locationService.currentLocation, () => {
      for (const o of this.additionalOverlays) {
        if (!o.keepOnLocationChange) {
          o.dispose();
        }
      }
    });
  }
  @action
  public registerOverlayProvider(overlayProvider: OverlayProvider): Disposable {
    this.overlayProviders.add(overlayProvider);
    return {
      dispose: () => {
        this.overlayProviders.delete(overlayProvider);
      }
    };
  }
  @action
  public showOverlayIfNotVisible<TProps, TTag>(factory: OverlayFactory<TProps, TTag>, props: TProps): VisibleOverlay | null {
    const isAlreadyVisible = !!this.findOverlay(factory.getFilter());
    if (isAlreadyVisible) {
      return null;
    }
    return this.showOverlay(factory.create(props));
  }
  @action
  public showOverlay(overlay: OverlayInfo): VisibleOverlay {
    const c = new VisibleOverlayImpl(<ServiceInjector
    // tslint:disable-next-line: jsx-no-lambda
    registerServices={b => b.bind($ThisVisibleOverlay).toDynamicSingleton({}, () => c)}>
					{overlay.view}
				</ServiceInjector>, overlay.tag, !!overlay.keepOnLocationChange, Date.now(), overlay.backgroundType ?? 'transparent');
    this.additionalOverlays.add(c);
    c.onClosedEvent.one(() => this.additionalOverlays.delete(c));
    return c;
  }
  public showTextOverlay(props: SimpleTextModal): VisibleOverlay {
    // eslint-disable-next-line prefer-const
    let modal: VisibleOverlay;
    const closeModal = () => modal.dispose();
    modal = this.showOverlay({
      view: <TextModal {...props} primaryButton={{
        text: props.buttonText,
        onClick: closeModal
      }} cancelModal={closeModal} />
    });
    return modal;
  }
  public findOverlay<TTag>(tagPredicate: (tag: any) => tag is TTag): VisibleOverlay<TTag> | undefined {
    const r = [...this.additionalOverlays].find(o => tagPredicate(o.tag));
    return r;
  }
  @action.bound
  public closeAllOverlays(): void {
    for (const o of this.overlays) {
      o.dispose();
    }
  }
}
export interface OverlayProvider {
  overlays: Overlay[];
}
export interface Overlay {
  view: React.ReactNode;
  id: string;
  backgroundType: 'transparent' | 'opaque';
  addedAt: number;
  tag?: string;
  dispose(): void;
}
export interface OverlayInfo {
  view: React.ReactNode;
  keepOnLocationChange?: boolean;
  tag?: unknown;
  backgroundType?: 'transparent' | 'opaque';
}
export interface VisibleOverlay<TTag = unknown> {
  readonly tag: TTag;

  /**
   * Is resolved when the overlay is closed.
   * This can be caused by the overlay itself,
   * by the user or by the creator of the overlay.
   * Promises react always asynchronously!
   */
  onClosed: Promise<void>;

  /**
   * Closes the overlay.
   */
  dispose(): void;
  // TODO: Implement updateView(newView: React.ReactNode): void;
}

class VisibleOverlayImpl<TTag = unknown> implements VisibleOverlay<TTag> {
  public readonly dispose = Disposable.fn();
  private readonly closedEmitter = new EventEmitter();

  /**
   * For synchronous event notification.
   * If this pattern is used more often, a `Signal` class should be implemented.
   */
  // tslint:disable:member-ordering
  public readonly onClosedEvent = this.closedEmitter.asEvent();
  public readonly onClosed = this.onClosedEvent.waitOne();
  public readonly id = generateId('overlay');
  constructor(public view: React.ReactNode, public readonly tag: TTag, public readonly keepOnLocationChange: boolean, public readonly addedAt: number, public backgroundType: 'transparent' | 'opaque') {
    this.dispose.track(() => this.closedEmitter.emit());
  }
}