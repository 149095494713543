import { ObservableQuery, ObservableSubscription } from '@apollo/client';
import { runInAction, observable } from '@knuddels-app/mobx';

export class K3ObservableQuery<TResult, TVars> {
	private readonly subscription: ObservableSubscription;

	@observable
	private _value: TResult | 'loading' | 'error' = 'loading';

	/**
	 * The current value or "loading" or "error".
	 * Is observable.
	 */
	public get value(): TResult | 'loading' | 'error' {
		return this._value;
	}

	constructor(
		public readonly observableQuery: ObservableQuery<TResult, TVars>
	) {
		this.subscription = observableQuery.subscribe(
			val => {
				runInAction(
					`Update value because query "${observableQuery.queryName}" changed.`,
					() => {
						this._value = val.data;
					}
				);
			},
			error => {
				console.error(error);
				runInAction(
					`Set value to error for query "${observableQuery.queryName}".`,
					() => {
						this._value = 'error';
					}
				);
			}
		);
	}

	/**
	 * Removes the subscription.
	 */
	public dispose(): void {
		this.subscription.unsubscribe();
	}
}
