import { newServiceId } from '@knuddels-app/DependencyInjection';
import { OverlayService, VisibleOverlay } from './OverlayService';

export const $OverlayService = newServiceId<OverlayService>('$OverlayService');

/**
 * Use this to access the current visible overlay (e.g. if you want to close it).
 * Is only available to react components that are mounted inside an overlay.
 */
export const $ThisVisibleOverlay = newServiceId<VisibleOverlay<unknown>>(
	'$ThisVisibleOverlay'
);
