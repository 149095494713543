import * as React from 'react';
import { FlexCol, PatternBackground, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export const ConversationBackground: React.FC = ({
  children
}) => {
  return <div className={_c0}>
			<div className={_c1 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			<PatternBackground />
			<div className={_c2 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{children}
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol flex-1 position-relative ";
const _c1 = " Knu-FlexCol position-absolute inset-none bg-contentBg ";
const _c2 = " Knu-FlexCol position-absolute inset-none bg-transparent ";