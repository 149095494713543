import {
	GetVisitorNotificationSettings,
	ProfileVisitorNotificationSettings,
	VisitorNotificationSettingsSubscription,
} from '@generated/graphql';
import { useQuery, useSubscription } from '@knuddels-app/Connection';
import { useEffect, useState } from 'react';

export const useVisitorNotificationSettingState = () => {
	const { data, loading } = useQuery(
		GetVisitorNotificationSettings,
		{},
		'no-cache'
	);

	const [privacyState, setPrivacyState] = useState<
		ProfileVisitorNotificationSettings | undefined
	>(data);

	useEffect(() => {
		if (data) {
			setPrivacyState(data);
		}
	}, [data]);

	useSubscription(
		VisitorNotificationSettingsSubscription,
		{},
		updatedSettings => {
			setPrivacyState(updatedSettings.profileVisitorSettings);
		}
	);

	return {
		loading,
		data: privacyState,
		updateData: (newData: Partial<ProfileVisitorNotificationSettings>) => {
			const saveData = privacyState;

			setPrivacyState(oldData => ({
				...oldData,
				...newData,
			}));

			return {
				revertUpdate: () => {
					setPrivacyState(saveData);
				},
			};
		},
	};
};
