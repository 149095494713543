import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { CenterContentBar } from './shared/CenterContentBar';
import { MentorConversationStartedProgressBar } from './MentorConversationStartedProgressBar';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MentorService } from '../../providedServices';
import { createAccessiblePointerEventHandler } from '@knuddels/component-library';
export const MentorConversationStartedMiniStatusBar: React.FC<{
  onPress?(): void;
  onProgressChangeAnimated?(): void;
}> = observer('MentorConversationStartedMiniStatusBar', props => {
  const mentorService = useService($MentorService);
  const status = mentorService.mentorStatus;
  if (!status || status.__typename !== 'MentorStatusActive') {
    return null;
  }
  return <CenterContentBar {...createAccessiblePointerEventHandler({
    disabled: !props.onPress,
    pointerCallback: props.onPress
  })}>
			<MentorConversationStartedProgressBar activeStatus={status} onProgressChangeAnimated={props.onProgressChangeAnimated} />
		</CenterContentBar>;
});