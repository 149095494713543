import * as React from 'react';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { declareFormat, FormatId } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { Spacer, SPACING } from '@shared/components';
import { FlexCol, ScrollView } from '@knuddels/component-library';
import { action, computed } from '@knuddels-app/mobx';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { ContactListTab, ContactListTabsEntry } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { expectUnreachable, shouldBeUnreachable } from '@knuddels/std';
import { DraggableListItem } from './DraggableList/DraggableListProps';
import { reorder } from '@knuddels-app/tools/reorder';
import { ListGroupDraggableList } from './shared/ListGroupDraggableList';
interface TabData extends DraggableListItem {
  title: string;
  checked: boolean;
  toggleable: boolean;
  onChanged(checked: boolean): void;
}
@injectable()
class ContactListSettingsModel {
  private scrollTop = 0;
  constructor(@injectProps()
  private readonly props: unknown, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T) {}
  public get contactListTabs(): readonly ContactListTabsEntry[] {
    return this.clientSettingsService.contactListTabEntries;
  }
  @action.bound
  private setTabEnabled(tab: ContactListTab, checked: boolean): void {
    this.firebaseAnalyticsService.logEvent('Contacts_Settings', getTabClickedTrackingItemId(tab, checked));
    const tabIndex = this.contactListTabs.findIndex(it => it.tab === tab);
    if (tabIndex < 0) {
      return;
    }
    const newTabs = [...this.contactListTabs];
    newTabs[tabIndex] = {
      tab,
      active: checked,
      toggleable: newTabs[tabIndex].toggleable
    };
    this.clientSettingsService.setContactListTabs({
      tabs: newTabs
    }).catch(() => this.snackbarService.showGenericError());
  }
  @computed
  public get tabData(): TabData[] {
    return this.contactListTabs.map(tab => ({
      tab,
      titleFormat: getTabTitleFormat(tab.tab)
    })).filter(data => !!data.titleFormat).map(({
      tab,
      titleFormat
    }) => ({
      uniqueId: tab.tab,
      title: titleFormat.format(this.i18n),
      checked: tab.active,
      toggleable: tab.toggleable,
      onChanged: checked => {
        this.setTabEnabled(tab.tab, checked);
      }
    }));
  }
  @action.bound
  public onDropEnd(from: number, to: number): void {
    this.firebaseAnalyticsService.logEvent('Contacts_Settings', 'ChangedListOrder');
    const newTabs = reorder([...this.contactListTabs], from, to);
    this.clientSettingsService.setContactListTabs({
      tabs: newTabs
    }).catch(() => this.snackbarService.showGenericError());
  }
  public onScroll = (e: React.UIEvent): void => {
    this.scrollTop = e.currentTarget.scrollTop;
  };
  public getDragItemOffset = (): number => {
    const textHeight = 18;
    return SPACING.XLARGE + textHeight + SPACING.MINOR - this.scrollTop - 56;
  };
}
export const ContactListSettings = injectedComponent({
  name: 'ContactListSettings',
  model: ContactListSettingsModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => {
  const visibleContactsTitleFormat = declareFormat({
    id: 'settings.contactListSettings.visible.title',
    defaultFormat: 'Visible contact types'
  });
  return <div className={_c0}>
				<div onScroll={model.onScroll} className={_c1}>
					<ListGroupDraggableList title={visibleContactsTitleFormat.format(i18n)} items={model.tabData} onItemOrderChanged={model.onDropEnd} dragItemOffset={model.getDragItemOffset} />
					<Spacer size={'xxxlarge'} />
				</div>
			</div>;
});
function getTabTitleFormat(tab: ContactListTab): FormatId | undefined {
  switch (tab) {
    case ContactListTab.Fotomeet:
      return declareFormat({
        id: 'settings.contactListSettings.tabs.fotomeet',
        defaultFormat: 'Fotomeet'
      });
    case ContactListTab.Friends:
      return declareFormat({
        id: 'settings.contactListSettings.tabs.friends',
        defaultFormat: 'Friends'
      });
    case ContactListTab.Latest:
      return declareFormat({
        id: 'settings.contactListSettings.tabs.latest',
        defaultFormat: 'Latest contacts'
      });
    case ContactListTab.Mentee:
      return declareFormat({
        id: 'settings.contactListSettings.tabs.mentee',
        defaultFormat: 'Mentees'
      });
    case ContactListTab.Watchlist:
      return declareFormat({
        id: 'settings.contactListSettings.tabs.watchlist',
        defaultFormat: 'Watchlist'
      });
    default:
      shouldBeUnreachable(tab);
      return undefined;
  }
}
function getTabClickedTrackingItemId(tab: ContactListTab, checked: boolean): string {
  const suffix = checked ? '_Activated' : '_Deactivated';
  switch (tab) {
    case ContactListTab.Fotomeet:
      return 'Fotomeet' + suffix;
    case ContactListTab.Friends:
      return 'Friends' + suffix;
    case ContactListTab.Latest:
      return 'LastContacts' + suffix;
    case ContactListTab.Mentee:
      return 'Mentees' + suffix;
    case ContactListTab.Watchlist:
      return 'Watchlist' + suffix;
    default:
      // Crashing is ok here, we don't show tabs which we have no mapping for
      expectUnreachable(tab);
  }
}
const _c0 = " Knu-FlexCol height-100-percent width-100-percent ";
const _c1 = " Knu-ScrollView ";