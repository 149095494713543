import { Environment } from './Environment';
import {
	newServiceId,
	K3ServiceBinder,
} from '@knuddels-app/DependencyInjection';
import { EnvironmentsImpl } from './EnvironmentsImpl';

export const $Environment = newServiceId<Environment>('$Environment');

export function registerServices(ctx: K3ServiceBinder): void {
	const nativeInterface = EnvironmentsImpl.createNativeInterface();
	const browserInterface = EnvironmentsImpl.createBrowserInterface();
	const messengerSystemAppInterface = EnvironmentsImpl.createMessengerSystemAppInterface();

	ctx.globalScope
		.bind($Environment)
		.toConstant(
			new Environment(
				nativeInterface,
				browserInterface,
				messengerSystemAppInterface
			)
		);
}

export type { MessengerSystemAppClient } from './MessengerSystemApp/MessengerSystemAppInterface';
