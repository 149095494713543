import { MessengerOverviewItem } from '@knuddelsModules/Messenger/bundle/services/messengerOverviewHelper';
import * as React from 'react';
const SCROLL_INDICATOR_THREASHOLD = 10;
export const useScrollableContentStates = ({
  conversations,
  scrollRef
}: {
  conversations: MessengerOverviewItem[];
  scrollRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const [hasScrollableContentLeft, setHasScrollableContentLeft] = React.useState(false);
  const [hasScrollableContentRight, setHasScrollableContentRight] = React.useState(false);
  const checkForScrollableContent = () => {
    if (!scrollRef.current) {
      return;
    }
    const div = scrollRef.current;
    const isContentLeft = div.scrollLeft >= SCROLL_INDICATOR_THREASHOLD;
    const isContentRight = div.scrollWidth - div.clientWidth - SCROLL_INDICATOR_THREASHOLD > div.scrollLeft;
    setHasScrollableContentLeft(isContentLeft);
    setHasScrollableContentRight(isContentRight);
  };
  React.useEffect(() => {
    const div = scrollRef.current;
    if (!div) {
      return;
    }
    checkForScrollableContent();
    div.addEventListener('scroll', checkForScrollableContent);
    window.addEventListener('resize', checkForScrollableContent);
    return () => {
      div.removeEventListener('scroll', checkForScrollableContent);
      window.removeEventListener('resize', checkForScrollableContent);
    };
  }, [conversations]);
  return {
    hasScrollableContentLeft,
    hasScrollableContentRight
  };
};