import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { Flex, FlexCol, Z_INDEX, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { BAR_HEIGHT, INIT_ANIMATION_DELAY, MINIFIED_BAR_HEIGHT } from './constants';
import { MentorStatusActiveFragment } from '@generated/graphql';
import { ProgressDisplay } from './ProgressDisplay';
export const MentorBarExtendable: React.FC<PropsWithChildren<{
  activeStatus: MentorStatusActiveFragment;
  mentorBarExtended: boolean;
}>> = observer('MentorBarExtendable', props => {
  const domRef = useExtendAnimation(props.mentorBarExtended, BAR_HEIGHT, true);
  return <>
			<div ref={domRef} style={{
      zIndex: resolveThemingValue(Z_INDEX.BELOW_TITLE_BAR, "theme", useTheme())
    }} className={_c0}>
				{props.children}
			</div>
			<MinifiedMentorPointsProgressBar mentorBarExtended={props.mentorBarExtended} activeStatus={props.activeStatus} />
		</>;
});
const MinifiedMentorPointsProgressBar: React.FC<{
  mentorBarExtended: boolean;
  activeStatus: MentorStatusActiveFragment;
}> = observer('MinifiedMentorPointsProgressBar', props => {
  const status = props.activeStatus;
  const domRef = useExtendAnimation(!props.mentorBarExtended, MINIFIED_BAR_HEIGHT);
  return <div ref={domRef} style={{
    zIndex: resolveThemingValue(Z_INDEX.BELOW_TITLE_BAR, "theme", useTheme())
  }} className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<ProgressDisplay value={status.mentorPoints} maxValue={status.mentorLevelBoundary} prevMaxValue={status.previousMentorLevelBoundary} minified />
		</div>;
});
const useExtendAnimation = (active: boolean, activeHeight: number, initialActive?: boolean) => {
  const domRef = React.useRef<HTMLDivElement | null>(null);
  const isInitAnimationRef = useRef(true);
  useLayoutEffect(() => {
    if (!domRef.current) {
      return;
    }
    domRef.current!.style.animation = 'none';
    if (isInitAnimationRef.current) {
      domRef.current!.style.height = initialActive ? `${activeHeight}px` : '0';
      domRef.current!.style.opacity = initialActive ? '1' : '0';
    }
    const delay = isInitAnimationRef.current ? INIT_ANIMATION_DELAY : 0;
    domRef.current!.animate({
      height: active ? `${activeHeight}px` : '0'
    }, {
      duration: 200,
      delay,
      fill: 'forwards',
      easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
    });
    domRef.current!.animate({
      opacity: active ? '1' : '0'
    }, {
      duration: 200,
      delay,
      fill: 'forwards',
      easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
    });
    if (isInitAnimationRef.current) {
      isInitAnimationRef.current = false;
    }
  }, [active]);
  return domRef;
};
const _c0 = " Knu-Flex overflow-hidden shadow-Shadow2 ";
const _c1 = " Knu-FlexCol shadow-Shadow2 bg-contentLightBg overflow-hidden ";