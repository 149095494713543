import { createRouter } from '@knuddels/component-library';

export type EditProfileRouterConfig = {
	overview: { params: undefined };
	nickSwitch: { params: undefined };
};

export const { Router, useRouter, useNavigate, useParams } = createRouter<
	EditProfileRouterConfig
>();
