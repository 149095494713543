import * as React from 'react';
import { LoadingEntry } from '../../shared/LoadingEntry';
import { ChannelList } from './ChannelList/ChannelList';
import { useRecommendedChannels, useUpdateRecommendedChannels } from './RecommendedChannelsContext';
import { ChannelGroupFragment, GetRecommendedChannels } from '@generated/graphql';
import { equalUpToDepth } from '@shared/components';
import { useChannelGroupUpdater } from './useChannelGroupUpdater';
import { useIsTabVisible } from './useIsTabVisible';
import { mergePreviewMembers } from './mergePreviewMembers';
export const RecommendedChannelsTab: React.FC<{
  refetchOverview: boolean;
}> = ({
  refetchOverview
}) => {
  const {
    data,
    error,
    loading,
    setData
  } = useRecommendedChannels();
  const updateRecommendedChannels = useUpdateRecommendedChannels();
  const setVisibleChannels = useUpdater(setData);
  React.useEffect(() => {
    if (refetchOverview) {
      updateRecommendedChannels();
    }
  }, [refetchOverview]);
  if (error) {
    return null;
  }
  if (loading && !data) {
    return <LoadingEntry />;
  }
  return <ChannelList categories={[data.lastVisited, data.popular]} trackingContentType={'ChannelSelection_Recommendations'} onVisibleChannelsChanged={visibleChannels => {
    setVisibleChannels(visibleChannels);
  }} />;
};
type QueryResult = typeof GetRecommendedChannels.TPrimaryResult;
type DataUpdater = (prevData: QueryResult) => QueryResult;
const useUpdater = (setData: (updater: DataUpdater) => void) => {
  const updateChannelData = React.useCallback((newChannel: ChannelGroupFragment) => {
    const groupId = newChannel.id;
    setData(prevData => {
      const oldInPopular = prevData.popular.channelGroups.find(group => group.id === groupId);
      const oldInLastVisited = prevData.lastVisited.channelGroups.find(group => group.id === groupId);
      const oldChannel = oldInPopular || oldInLastVisited;
      if (!oldChannel) {
        return prevData;
      }
      if (equalUpToDepth(oldChannel, newChannel, undefined, 10)) {
        return prevData;
      }
      const channelToInsert = mergePreviewMembers(oldChannel, newChannel);
      return {
        ...prevData,
        popular: {
          ...prevData.popular,
          channelGroups: prevData.popular.channelGroups.map(group => {
            if (group.id === channelToInsert.id) {
              return channelToInsert;
            }
            return group;
          })
        },
        lastVisited: {
          ...prevData.lastVisited,
          channelGroups: prevData.lastVisited.channelGroups.map(group => {
            if (group.id === channelToInsert.id) {
              return channelToInsert;
            }
            return group;
          })
        }
      };
    });
  }, [setData]);
  const isTabVisible = useIsTabVisible(0);
  return useChannelGroupUpdater(isTabVisible, updateChannelData);
};