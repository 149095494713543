import * as React from 'react';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { ProfileMobile } from './ProfileMobile';
import { GetUserForProfileQuery, User } from '@generated/graphql';
import { ProfileViewOpenedEvent } from '@knuddelsModules/Profile/analytics';
import { ProfileBaseProps } from './ProfileBase';
import { ProfileDesktopContent } from './ProfileDesktopContent';
import { $ViewService } from '@knuddels-app/layout';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import { ProfileCommonFriendsContext, ProfileFriendsContext } from './ProfileContent/Friends/ProfileFriendsContext';
import { ProfileAlbumInfo } from '../profileQueryHelper';
type OwnProps = {
  userId: User['id'];
  currentUser: Pick<User, 'id'>;
  profileUser: GetUserForProfileQuery | undefined;
  profileAlbumInfo: ProfileAlbumInfo | undefined;
  isStackedLayout: boolean;
};
type Props = OwnProps;
@injectable()
class ProfileModel implements IModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T) {}
  componentDidMount(): void {
    ProfileViewOpenedEvent.track('Opened');
  }
  public readonly onClose = (): void => {
    this.viewService.closeView(profileViewId);
  };
}
export const Profile = injectedComponent({
  name: 'Profile',
  model: ProfileModel,
  props: declareProps<Props>()
}, ({
  model,
  profileUser,
  profileAlbumInfo,
  currentUser,
  isStackedLayout
}) => {
  if (!profileUser && !isStackedLayout) {
    return;
  }
  const user = profileUser?.user?.user;
  const profileProps: ProfileBaseProps = {
    onClose: model.onClose,
    currentUserId: currentUser.id,
    user: user,
    photoData: profileAlbumInfo,
    conversationId: profileUser?.messenger?.conversationWithParticipants?.id
  };
  const ProfileComponent: React.ComponentType<ProfileBaseProps> = isStackedLayout ? ProfileMobile : ProfileDesktopContent;
  return <ProfileFriendsContext.Provider value={profileUser?.contacts?.friends}>
				<ProfileCommonFriendsContext.Provider value={profileUser?.contacts?.commonFriends}>
					<ProfileComponent {...profileProps} />
				</ProfileCommonFriendsContext.Provider>
			</ProfileFriendsContext.Provider>;
});