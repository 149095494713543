import { jsxs as l, jsx as t } from "react/jsx-runtime";
import { useToggle as o } from "./useToggle.js";
import { useDisabledOpacity as a } from "./useDisabledOpacity.js";
import { HitArea as c, Label as d } from "./CommonRadioCheckbox.js";
import { IconCheckmark as m } from "../Icons/Checkmark.js";
import { useTheme as h } from "../../themes/useTheme.js";
import { Flex as u } from "../Layout/Flex.js";
import { FlexCol as b } from "../Layout/FlexCol.js";
const C = (e) => {
  const r = o(e), n = a(e), i = h().sizes.checkbox[e.size ?? "default"];
  return /* @__PURE__ */ l(u, { ...r, ...n, children: [
    /* @__PURE__ */ t(c, { labelStyle: e.labelStyle, children: /* @__PURE__ */ l(
      b,
      {
        width: i + "px",
        height: i + "px",
        placeItems: "center",
        overflow: "hidden",
        borderRadius: e.circular ? "circle" : "tiny",
        bg: e.value || e.indeterminate ? "accent" : "inputFieldBg",
        borderColor: e.value || e.indeterminate ? "transparent" : "inputFieldBorder",
        borderStyle: "solid",
        borderWidth: "base",
        ariaLabel: e.label,
        onFocus: e.onFocus,
        onBlur: e.onBlur,
        children: [
          e.value && !e.indeterminate && /* @__PURE__ */ t("div", { className: "Knu-Flex pointerEvents-none", children: /* @__PURE__ */ t(
            m,
            {
              size: "large",
              color: "white-solid-white"
            }
          ) }),
          e.indeterminate && /* @__PURE__ */ t(
            "div",
            {
              className: "Knu-Flex pointerEvents-none",
              style: {
                width: "70%",
                height: "2px",
                backgroundColor: "white",
                borderRadius: "2px"
              }
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ t(d, { labelStyle: e.labelStyle, children: e.label })
  ] });
};
export {
  C as Checkbox
};
