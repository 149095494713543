import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { AlbumDetailsUserContext } from '../AlbumDetailsUserContext';
import { Flex, FlexCol, LineDivider, Text } from '@knuddels/component-library';
import { PhotoCommentsInput } from '@knuddelsModules/Profile/bundle/components/AlbumDetails/PhotoComments/PhotoCommentsInput';
export const PhotoCommentsInputBar: React.FC = () => {
  const {
    canReceivePhotoComments
  } = React.useContext(AlbumDetailsUserContext);
  if (canReceivePhotoComments) {
    return <div className={_c0}>
				<PhotoCommentsInput />
			</div>;
    // return (
    // 	<SmileyboxInputBar
    // 		{...smileyboxStateCallbacks}
    // 		onReady={focusable => {
    // 			ref.current = focusable;
    // 		}}
    // 		track={() => {}}
    // 		onFocus={() => {
    // 			setInputbarFocused?.(true);
    // 		}}
    // 		onBlur={() => {
    // 			setInputbarFocused?.(false);
    // 		}}
    // 		blurOnSubmit
    // 		onSubmit={text => {
    // 			if (ref.current && isMobileOrNative()) {
    // 				setTimeout(() => {
    // 					ref.current.blur();
    // 				}, 100);
    // 			}
    // 			sendComment(photoId, text);
    // 		}}
    // 		disableAutoFocus={!isMobileOrNative()}
    // 		validateInput={input => input.trim().length > 0}
    // 		placeholder={formatMessage(
    // 			declareFormat({
    // 				id: 'album.details.sendComment.placeholder',
    // 				defaultFormat: 'Comment',
    // 			})
    // 		)}
    // 	/>
    // );
  } else {
    return <CommentingUnavailable />;
  }
};
const CommentingUnavailable = () => {
  return <div className={_c1}>
			<div className={_c2}>
				<LineDivider className={_c3} />
			</div>
			<div className={_c4}>
				<Text type={'body2'} state={'disabled'} className={_c5}>
					<FormattedMessage id={declareFormat({
          id: 'album.details.sendComment.unavailable',
          defaultFormat: "Unfortunately, you can't leave a comment"
        })} />
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-Flex ";
const _c1 = " Knu-FlexCol height-48px ";
const _c2 = " Knu-Flex alignSelf-stretch ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol placeItems-center flex-1 ";
const _c5 = "  ";