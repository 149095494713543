import { transformPropOrder as o } from "./transform.js";
const f = {
  x: "translateX",
  y: "translateY",
  z: "translateZ",
  transformPerspective: "perspective"
}, l = o.length;
function m(e, s, i) {
  let r = "";
  for (let n = 0; n < l; n++) {
    const t = o[n];
    if (e[t] !== void 0) {
      const a = f[t] || t;
      r += `${a}(${e[t]}) `;
    }
  }
  return r = r.trim(), i ? r = i(e, s ? "" : r) : s && (r = "none"), r;
}
export {
  m as buildTransform
};
