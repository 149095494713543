import * as React from 'react';
import { SpeedDial } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { FriendState } from '@generated/graphql';
import { ProfileUser } from '../../../profileQueryHelper';
import { IconFriendAdd } from '../../../shared/IconFriendAdd';
import { IconFriendRemove } from '@knuddels-app/shared-components/IconFriendRemove';
import { ACCEPT_FRIEND_REQUEST, ADD_FRIEND_REQUEST, CANCEL_FRIEND_REQUEST } from '../../../../i18n/shared-formats';
import { useCancelFriendRequest } from '../../../shared/useCancelFriendRequest';
import { useAcceptFriendRequest } from '../../../shared/useAcceptFriendRequest';
import { useTrackProfileView, useTrackProfileViewSnackbar } from '../../../shared/useTrackProfileView';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
export const FriendFabEntry: React.FC<{
  user: ProfileUser;
}> = props => {
  switch (props.user.friendState) {
    case FriendState.Friends:
      return null;
    case FriendState.RequestInitiated:
      return <CancelRequestEntry userId={props.user.id} />;
    case FriendState.RequestReceived:
      return <AcceptRequestEntry userId={props.user.id} />;
    case FriendState.None:
    default:
      return <AddFriendEntry userId={props.user.id} />;
  }
};
const AddFriendEntry: React.FC<{
  userId: string;
}> = props => {
  const i18n = useService($I18n);
  const friendRequestsService = useService($FriendRequestsService);
  const trackSnackbar = useTrackProfileViewSnackbar();
  const track = useTrackProfileView();
  return <SpeedDial.Option label={i18n.format(ADD_FRIEND_REQUEST)} icon={<IconFriendAdd size={'large'} />} onPress={() => {
    track('FAB_SendFriendrequest');
    friendRequestsService.sendFriendRequest(props.userId, trackSnackbar);
  }} />;
};
const CancelRequestEntry: React.FC<{
  userId: string;
}> = props => {
  const i18n = useService($I18n);
  const trackSnackbar = useTrackProfileViewSnackbar();
  const cancelFriendRequest = useCancelFriendRequest(trackSnackbar);
  const track = useTrackProfileView();
  return <SpeedDial.Option label={i18n.format(CANCEL_FRIEND_REQUEST)} icon={<IconFriendRemove size={'large'} />} onPress={() => {
    track('FAB_RevokeFriendrequest');
    cancelFriendRequest(props.userId);
  }} />;
};
const AcceptRequestEntry: React.FC<{
  userId: string;
}> = props => {
  const i18n = useService($I18n);
  const trackSnackbar = useTrackProfileViewSnackbar();
  const acceptFriendRequest = useAcceptFriendRequest(trackSnackbar);
  const track = useTrackProfileView();
  return <SpeedDial.Option label={i18n.format(ACCEPT_FRIEND_REQUEST)} icon={<IconFriendAdd size={'large'} />} onPress={() => {
    track('FAB_AcceptFriendrequest');
    acceptFriendRequest(props.userId);
  }} />;
};