import { jsx as d } from "react/jsx-runtime";
import * as o from "react";
import { VirtualList as I } from "./VirtualList.js";
function u({
  id: i,
  param: s = "",
  resetOnParamChange: e = !1,
  ...t
}) {
  const [r] = o.useState(
    () => c.getStartIndex(i, s, e)
  );
  return /* @__PURE__ */ d(
    I,
    {
      ...t,
      initialScrollIndex: Math.min(
        r,
        t.data.length - 1
      ),
      onRenderedItemsChanged: (n) => {
        var a;
        c.persist(n.startIndex, i, s), (a = t.onRenderedItemsChanged) == null || a.call(t, n);
      }
    }
  );
}
class S {
  constructor() {
    this.persistedStartIndices = {};
  }
  persist(s, e, t) {
    var r;
    (r = this.persistedStartIndices)[e] ?? (r[e] = {}), this.persistedStartIndices[e] = {
      ...this.persistedStartIndices[e],
      [t]: s
    };
  }
  getStartIndex(s, e, t) {
    const r = this.persistedStartIndices[s];
    return r ? (t && Object.keys(r).some((n) => n !== e) && (this.persistedStartIndices[s] = {}), this.persistedStartIndices[s][e] ?? 0) : 0;
  }
  clear() {
    this.persistedStartIndices = {};
  }
}
const c = new S();
function f() {
  c.clear();
}
export {
  u as PersistedVirtualList,
  f as clearPersistedVirtualListPersistence
};
