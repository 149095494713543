import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';

export const autocompleteInputBarModule = new Module({
	name: 'Autcomplete InputBar module',
	dependencies: [],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
