import { InitialJoinBehavior, PrivateMessageReplyBehavior } from '@generated/graphql';
import { inject, injectProps, injectable, injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $I18n } from '@knuddels-app/i18n';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { Flex, FlexCol, IconChevronRight, LineDivider, ListGroup, Panel, PanelsList, RadioButton, RadioGroup, SwitchListItem, Tab, TabText, Tabs, TabsList, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { $ClientSettingsService, $MiniChatSettingsService } from '@knuddelsModules/Settings';
import { SettingsListItem } from '@knuddelsModules/Settings/bundle/components/SettingsListItem';
import { useNavigate } from '@knuddelsModules/Settings/bundle/components/SettingsRouter';
import { isUsingTouch } from '@shared/components';
import * as React from 'react';
import { ChannelLayoutSettings } from './ChannelLayoutSettings';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { os, OS } from '@shared/components/tools/os';
@injectable()
class ChannelSettingsModel {
  @observable.ref
  private autocompleteProviderService: typeof $AutocompleteProviderService.T | undefined;
  constructor(@injectProps()
  private readonly props: unknown, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject.lazy($ActiveChannelService)
  private readonly getActiveChannelService: typeof $ActiveChannelService.TLazy, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($MiniChatSettingsService)
  private readonly miniChatSettingsService: typeof $MiniChatSettingsService.T, @inject.lazy($AutocompleteProviderService)
  private readonly autocompleteProviderServiceGetter: typeof $AutocompleteProviderService.TLazy) {
    this.autocompleteProviderServiceGetter().then(autocompleteProviderService => {
      runInAction('Set autocomplete provider', () => {
        this.autocompleteProviderService = autocompleteProviderService;
      });
    });
  }
  @action.bound
  public async startInLastChannelChanged(checked: boolean): Promise<void> {
    this.firebaseAnalyticsService.logEvent('Settings_Channelsettings', checked ? 'StartInLastChannel_Activated' : 'StartInLastChannel_Deactivated');
    const newValue = checked ? (await this.getActiveChannelService()).state.kind === 'noChannel' ? InitialJoinBehavior.DisabledUntilJoin : InitialJoinBehavior.Enabled : InitialJoinBehavior.Disabled;
    try {
      await this.clientSettingsService.setInitialJoinBehavior(newValue);
    } catch (e) {
      this.snackbarService.showGenericError();
    }
  }
  public get isStartInLastChannelChecked(): boolean | undefined {
    return this.clientSettingsService.initialJoinBehavior !== InitialJoinBehavior.Disabled;
  }
  @action.bound
  public async macroboxEnabledChanged(checked: boolean): Promise<void> {
    this.firebaseAnalyticsService.logEvent('Settings_Channelsettings', checked ? 'MakroQuickAccess_Activated' : 'MakroQuickAccess_Deactivated');
    try {
      await this.clientSettingsService.setMacroBoxEnabled(checked);
    } catch (e) {
      this.snackbarService.showGenericError();
    }
  }
  public get isAvailableOnDevice(): boolean {
    return this.screenService.isStackedLayout || isUsingTouch();
  }
  public get isMacroboxEnabledChecked(): boolean | undefined {
    return this.clientSettingsService.macroBoxEnabled;
  }
  public get isMiniChatAvailable(): boolean {
    return this.miniChatSettingsService.isMiniChatAvailable;
  }
  public get isSlashCommandsAutocompleteChecked(): boolean {
    return this.autocompleteProviderService?.autocompleteSlashCommands ?? false;
  }
  @action.bound
  public setIsSlashCommandsAutocompleteChecked(checked: boolean): void {
    this.autocompleteProviderService?.setAutocompleteSlashCommands(checked);
  }
  @action.bound
  public async privateMessageReplyBehaviorChanged(newValue: PrivateMessageReplyBehavior | 'MINI_CHAT'): Promise<void> {
    this.firebaseAnalyticsService.logEvent('Settings_Channelsettings', newValue === PrivateMessageReplyBehavior.SlashCommand ? 'PrivateMessageAnswer_ChatroomSelected' : newValue === PrivateMessageReplyBehavior.Messenger ? 'PrivateMessageAnswer_MessengerSelected' : 'PrivateMessageAnswer_MiniChatSelected');
    try {
      await this.clientSettingsService.setPrivateMessageReplyBehavior(newValue);
    } catch (e) {
      this.snackbarService.showGenericError();
    }
  }
  public get privateMessageReplyBehavior(): PrivateMessageReplyBehavior | 'MINI_CHAT' {
    return this.clientSettingsService.privateMessageReplyBehavior;
  }
  @action.bound
  public miniChatTriggerChanged(checked: boolean): void {
    this.firebaseAnalyticsService.logEvent('Settings_Channelsettings', checked ? 'MiniChatTrigger_Activated' : 'MiniChatTrigger_Deactivated');
    this.clientSettingsService.setMiniChatTriggerEnabled(checked);
  }
  public get isMiniChatTriggerEnabled(): boolean {
    return this.clientSettingsService.miniChatTriggerEnabled;
  }
}
export const ChannelSettings = injectedComponent({
  name: 'ChannelSettings',
  model: ChannelSettingsModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => {
  const navigate = useNavigate();
  const nativeWebViewService = useService($NativeWebViewService);
  const openActionsCustomization = () => {
    navigate('MacroBoxSettings');
  };
  return <div className={_c0}>
				<Tabs variant={'scrollable'}>
					<TabsList>
						<Tab className={_c1}>
							<TabText>
								{declareFormat({
              id: 'settings.channelSettings.tab.layoutSettings'
            }).format(i18n)}
							</TabText>
						</Tab>
						<Tab className={_c2}>
							<TabText>
								{declareFormat({
              id: 'settings.channelSettings.tab.functionalSettings'
            }).format(i18n)}
							</TabText>
						</Tab>
					</TabsList>
					<PanelsList>
						<Panel>
							<ChannelLayoutSettings />
						</Panel>
						<Panel>
							<div className={_c3}>
								<ListGroup title={declareFormat({
              id: 'settings.channelSettings.group.start',
              defaultFormat: 'Appstart'
            }).format(i18n)}>
									{model.isStartInLastChannelChecked !== undefined && <SwitchListItem caption={declareFormat({
                id: 'settings.channelSettings.startInLastChannel',
                defaultFormat: 'Start in last channel'
              }).format(i18n)} checked={model.isStartInLastChannelChecked} onChange={model.startInLastChannelChanged} />}
								</ListGroup>
								<ListGroup title={declareFormat({
              id: 'settings.channelSettings.group.privateMessage',
              defaultFormat: 'Private messages'
            }).format(i18n)}>
									<div className={_c4 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
										<Text type={'subtitle'} bold={true} className={_c5}>
											{i18n.format(declareFormat({
                    id: 'settings.channelSettings.privateMessageReplyBehavior',
                    defaultFormat: 'Where would you like to answer private messages?'
                  }))}
										</Text>
										<div className={_c6}>
											<RadioGroup value={model.privateMessageReplyBehavior} onChange={({
                    value
                  }) => {
                    if (value === model.privateMessageReplyBehavior) {
                      return;
                    }
                    model.privateMessageReplyBehaviorChanged(value);
                  }}>
												{model.isMiniChatAvailable && <RadioButton value={'MINI_CHAT'} label={i18n.format(declareFormat({
                      id: 'settings.channelSettings.privateMessageReplyBehavior.miniChat',
                      defaultFormat: 'In mini chat'
                    }))} />}
												<RadioButton value={PrivateMessageReplyBehavior.Messenger} label={i18n.format(declareFormat({
                      id: 'settings.channelSettings.privateMessageReplyBehavior.messenger',
                      defaultFormat: 'In messenger conversation'
                    }))} />
												<RadioButton value={PrivateMessageReplyBehavior.SlashCommand} label={i18n.format(declareFormat({
                      id: 'settings.channelSettings.privateMessageReplyBehavior.slashCommand',
                      defaultFormat: 'In chat (using /p)'
                    }))} />
											</RadioGroup>
										</div>
									</div>
									<div className={_c7}>
										<LineDivider className={_c8} />
									</div>
									<SwitchListItem caption={declareFormat({
                id: 'settings.channelSettings.miniChatTrigger',
                defaultFormat: 'Enable actions menu'
              }).format(i18n)} additionalInfo={declareFormat({
                id: 'settings.channelSettings.miniChatTrigger.additionalInfo',
                defaultFormat: 'Member profiles are opened directly'
              }).format(i18n)} checked={model.isMiniChatTriggerEnabled} onChange={model.miniChatTriggerChanged} disabled={model.privateMessageReplyBehavior !== 'MINI_CHAT'} />
								</ListGroup>
								{model.isAvailableOnDevice && <ListGroup title={declareFormat({
              id: 'settings.channelSettings.group.macrobox',
              defaultFormat: 'Action menu'
            }).format(i18n)}>
										<SwitchListItem caption={declareFormat({
                id: 'settings.channelSettings.macroboxEnabled',
                defaultFormat: 'Enable actions menu'
              }).format(i18n)} additionalInfo={declareFormat({
                id: 'settings.channelSettings.macroboxEnabled.additionalInfo',
                defaultFormat: 'Member profiles are opened directly'
              }).format(i18n)} checked={model.isMacroboxEnabledChecked} onChange={model.macroboxEnabledChanged} />
										<SettingsListItem caption={declareFormat({
                id: 'settings.channelSettings.customizeMacrobox',
                defaultFormat: 'Customize actions'
              }).format(i18n)} onPress={openActionsCustomization} iconRight={<IconChevronRight />} />
									</ListGroup>}
								<ListGroup title={declareFormat({
              id: 'settings.channelSettings.group.autocomplete',
              defaultFormat: 'Autocomplete'
            }).format(i18n)}>
									<SwitchListItem caption={declareFormat({
                id: 'settings.channelSettings.slashCommandsAutocomplete',
                defaultFormat: 'Autocomplete slash commands'
              }).format(i18n)} checked={model.isSlashCommandsAutocompleteChecked} onChange={model.setIsSlashCommandsAutocompleteChecked} />
								</ListGroup>

								{os === OS.ios && <ListGroup title={'LABS'}>
										<div className={_c9}>
											<Text type={'tiny'} className={_c10}>
												Mit den experimentellen
												Funktionen hast du die
												Möglichkeit, neue Features vorab
												zu testen. Diese befindent sich
												in einem (frühen)
												Entwicklungsstadium und können
												daher Fehler enthalten und ohne
												Ankündigung geändert oder
												entfernt werden.
												<br /> Mit deinem Feedback
												hilfst du uns, die Funktionen zu
												verbessern und zu entscheiden,
												ob diese offiziell
												veröffentlicht werden.
												<br />
												<br /> Du kannst die
												experimentellen Funktionen
												jederzeit deaktivieren.
											</Text>
										</div>
										<SwitchListItem caption={'Experimentelle native Webviews (iOS)'} additionalInfo={'Spiele und Apps im Channel werden mit dieser Funktion in einem separaten Webview geöffnet. Dies verbessert die Performance.'} checked={nativeWebViewService.isEnabled} onChange={e => {
                nativeWebViewService.setEnabled(e);
                window.location.reload();
              }} />
									</ListGroup>}
							</div>
						</Panel>
					</PanelsList>
				</Tabs>
			</div>;
});
const _c0 = " Knu-FlexCol height-100-percent ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = " Knu-FlexCol size-full overflow-auto pb-base ";
const _c4 = " Knu-FlexCol bg-transparent ";
const _c5 = " mx-base my-minor ";
const _c6 = " Knu-FlexCol pl-minor ";
const _c7 = " Knu-Flex px-minor ";
const _c8 = "  ";
const _c9 = " Knu-FlexCol p-base ";
const _c10 = "  ";