import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { Router } from '../../../DownloadRouterConfig';
import { $NavBarRegistry, downloadViewId } from '../../../index';
import { observer } from '@knuddels-app/mobx';
import { $I18n } from '@knuddels-app/i18n';
import { AppBenefits } from './AppBenefits';
import { INSTALL_APP } from '../../i18n/shared-formats';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export const DownloadView: React.FC = observer('DownloadView', () => {
  const i18n = useService($I18n);
  const viewService = useService($ViewService);
  const navBarRegistry = useService($NavBarRegistry);
  const track = useLogEvent();
  const closeView = !navBarRegistry.viewInsideBottomNav(downloadViewId) ? (): void => {
    track('InstallApp_AdScreen', 'Titlebar_CloseClicked');
    viewService.closeViewAndTryOpenParent(downloadViewId);
  } : undefined;
  return <Router rootPath={'download'} onClose={closeView}>
			<Router.Route path={'download'} title={INSTALL_APP.format(i18n)}>
				<AppBenefits />
			</Router.Route>
		</Router>;
});