import {
	CannotJoinChannelReason,
	ChannelJoinMutationResponseError,
	Gender,
} from '@generated/graphql';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';

export function mapChannelJoinErrorToMessage(
	channelName: string,
	reason: ChannelJoinMutationResponseError
): string {
	switch (reason.type) {
		case CannotJoinChannelReason.DoesNotExist:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_DOES_NOT_EXIST',
					defaultFormat: "The channel {channel} doesn't exist.",
				}),
				{ channel: channelName }
			);
		case CannotJoinChannelReason.IsSystemChannel:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_IS_SYSTEM_CHANNEL',
					defaultFormat:
						"The channel {channel} can't be created because there is a system channel with the same name.",
				}),
				{ channel: channelName }
			);
		case CannotJoinChannelReason.ChannelNameContainsCorruptChar:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_CONTAINS_CORRUPT_CHAR',
					defaultFormat:
						"The channel {channel} can't be created because its name contains invalid characters. Allowed are letters, digits and the following characters: {allowedChars}.",
				}),
				{ channel: channelName, allowedChars: '' } // TODO allowed chars
			);
		case CannotJoinChannelReason.ChannelNameReserved:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_CHANNEL_NAME_RESERVED',
					defaultFormat:
						"The channel {channel} can't be created because the name has already been reserved.",
				}),
				{ channel: channelName }
			);
		case CannotJoinChannelReason.ChannelNameMayCollideWithMyChannel:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_MAY_COLLIDE_WITH_MY_CHANNEL',
					defaultFormat:
						"The channel {channel} can't be created, because its name could collide with a subchannel of a MyChannel.",
				}),
				{ channel: channelName }
			);
		case CannotJoinChannelReason.InvisibleChannelRestriction:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_INVISIBLE_CHANNEL_RESTRICTION',
					defaultFormat:
						'Invisible channels can only be created by {statusName}',
				}),
				{ statusName: reason.requiredStatusName }
			);
		case CannotJoinChannelReason.Locked:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_LOCKED',
					defaultFormat:
						'The channel {channel} (owner: {owner}) is currently locked.',
				}),
				{
					channel: channelName,
					owner: reason.userNick,
				}
			);

		case CannotJoinChannelReason.AgeAndGenderRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_AGE_AND_GENDER_REQUIRED',
					defaultFormat:
						'You need to provide your age and gender in the profile to join the channel.',
				})
			);
		case CannotJoinChannelReason.AgeNotVerified:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_AGE_NOT_VERIFIED',
					defaultFormat:
						'You need to verify your age and be at least {minAge} years old to join this channel.',
				}),
				{ minAge: reason.minAge.toString() }
			);
		case CannotJoinChannelReason.AgeRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_AGE_REQUIRED',
					defaultFormat:
						'You need to provide your age in the profile to join this channel.',
				})
			);
		case CannotJoinChannelReason.AlreadyInChannel:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_ALREADY_IN_CHANNEL',
					defaultFormat: 'You are already in this channel.',
				})
			);
		case CannotJoinChannelReason.AppDeveloperOnly:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_APP_DEVELOPER_ONLY',
					defaultFormat:
						'You need to be an app developer to join this channel.',
				})
			);
		case CannotJoinChannelReason.BannedTemporarily:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_BANNED_TEMPORARILY',
					defaultFormat:
						"You're blocked for this channel until tomorrow morning.",
				})
			);
		case CannotJoinChannelReason.CmRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_CM_REQUIRED',
					defaultFormat:
						'You need to be a CM or HZM to join this channel.',
				})
			);
		case CannotJoinChannelReason.Full:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_FULL',
					defaultFormat:
						'This channel is restricted to {maxUsers} users, please choose another channel.',
				}),
				{
					maxUsers: reason.maxUser.toString(),
				}
			);
		case CannotJoinChannelReason.IphoneRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_IPHONE_REQUIRED',
					defaultFormat:
						'You can only join this channel with an iPhone.',
				})
			);
		case CannotJoinChannelReason.NeedToLeaveCurrentChannel:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NEED_TO_LEAVE_CURRENT_CHANNEL',
					defaultFormat:
						'Leave the channel {otherChannel} to join this channel.',
				}),
				{ otherChannel: reason.otherChannelName }
			);
		case CannotJoinChannelReason.NoPermission:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NO_PERMISSION',
					defaultFormat: "You can't join this channel at the moment.",
				})
			);
		case CannotJoinChannelReason.NotEnoughKnuddels:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NOT_ENOUGH_KNUDDELS',
					defaultFormat:
						'You need at least {minKnuddel} Knuddel to join this channel.',
				}),
				{ minKnuddel: reason.minKnuddels.toString() }
			);
		case CannotJoinChannelReason.NotEnoughRegisteredDays:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NOT_ENOUGH_REGISTERED_DAYS',
					defaultFormat:
						'You need to be registered at Knuddels for at least {minDays} to join this channel.',
				}),
				{ minDays: reason.minRegisteredDays.toString() }
			);
		case CannotJoinChannelReason.NotEnoughRights:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NOT_ENOUGH_RIGHTS',
					defaultFormat:
						"You don't have the required rights to join this channel.",
				})
			);
		case CannotJoinChannelReason.NotEnoughStammiMonths:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NOT_ENOUGH_STAMMI_MONTHS',
					defaultFormat:
						'You need to be Stammi for at least {stammiMonths} months to join this channel.',
				}),
				{ stammiMonths: reason.minStammiMonths.toString() }
			);
		case CannotJoinChannelReason.NotEnoughTradeableSmileys:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NOT_ENOUGH_TRADEABLE_SMILEYS',
					defaultFormat:
						'You need at least {minSmileys, plural, one {one tradeable Smiley or Abo-Basic-Smiley} other {# tradeable Smileys or Abo-Basic-Smileys}} to join this channel.',
				}),
				{ minSmileys: reason.minTradeableSmileys.toString() }
			);
		case CannotJoinChannelReason.NotOpen:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_NOT_OPEN',
					defaultFormat:
						'The channel is currently closed. It is open between {startTime} and {endTime}.',
				}),
				{ startTime: reason.startTime, endTime: reason.endTime }
			);
		case CannotJoinChannelReason.OnlyOneUserPerIp:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_ONLY_ONE_USER_PER_IP',
					defaultFormat:
						"In this channel, only one nick per IP is allowed. {otherNick} has the same IP, so you can't login.",
				}),
				{ otherNick: reason.userNick }
			);
		case CannotJoinChannelReason.PartyRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_PARTY_REQUIRED',
					defaultFormat:
						'You need to be a member of a party to join this channel. Use /partei.',
				})
			);
		case CannotJoinChannelReason.PhotoRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_PHOTO_REQUIRED',
					defaultFormat:
						'You need to upload a photo to join this channel.',
				})
			);
		case CannotJoinChannelReason.SecretReason:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_SECRET_REASON',
					defaultFormat:
						"You can't join this channel due to mysterious reasons...",
				})
			);
		case CannotJoinChannelReason.SmarthphonesNotAllowed:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_SMARTHPHONES_NOT_ALLOWED',
					defaultFormat:
						"You can't join this channel with a smartphone.",
				})
			);
		case CannotJoinChannelReason.StatusTooLow:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_STATUS_TOO_LOW',
					defaultFormat: 'Only {statusName} can join this channel.',
				}),
				{ statusName: reason.requiredStatusName }
			);
		case CannotJoinChannelReason.TooOld:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_TOO_OLD',
					defaultFormat:
						"You can't join this channel beacuse it is reserved for younger users.",
				})
			);
		case CannotJoinChannelReason.TooYoung:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_TOO_YOUNG',
					defaultFormat:
						'You have to be at least {minAge} years old to join this channel.',
				}),
				{ minAge: reason.minAge.toString() }
			);
		case CannotJoinChannelReason.UrlRestriction:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_URL_RESTRICTION',
					defaultFormat: "Unfortunately you can't join this channel.",
				})
			);
		case CannotJoinChannelReason.VerifiedPhotoRequired:
			return formatMessage(
				declareFormat({
					id: 'CANNOT_JOIN_VERIFIED_PHOTO_REQUIRED',
					defaultFormat:
						'You can only join this channel with a verified photo.',
				})
			);
		case CannotJoinChannelReason.WrongGender:
			if (reason.requiredGender === Gender.Male) {
				return formatMessage(
					declareFormat({
						id: 'CANNOT_JOIN_WRONG_GENDER_MALE',
						defaultFormat:
							'This channel can only be joined by male users.',
					})
				);
			} else {
				return formatMessage(
					declareFormat({
						id: 'CANNOT_JOIN_WRONG_GENDER_FEMALE',
						defaultFormat:
							'This channel can only be joined by female users.',
					})
				);
			}
		case CannotJoinChannelReason.Other:
		default:
			return reason.freetext || '';
	}
}
