import React from 'react';
export const CssAnimatePresence: React.FC<{
  containerRef: React.RefObject<HTMLElement>;
}> = ({
  children,
  containerRef
}) => {
  const last = React.useRef(children);
  const rerender = React.useState({})[1];
  React.useLayoutEffect(() => {
    if (!children) {
      containerRef.current?.classList.add('animate-out');
      containerRef.current?.addEventListener('animationend', () => {
        last.current = null;
        rerender({});
      }, {
        once: true
      });
    }
  }, [children]);
  if (children !== null) {
    last.current = children;
  }
  return <>{last.current}</>;
};