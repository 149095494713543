import { FormatId } from '@knuddels/i18n';
import {
	HEADER_ALL_FRIENDS,
	HEADER_ALL_IN_CATEGORY,
	HEADER_ALL_LAST,
	HEADER_ALL_RECOMMENDED,
	HEADER_MORE_IN_CATEGORY,
	HEADER_MORE_LAST,
	HEADER_MORE_RECOMMENDED,
	HEADER_TOP_IN_CATEGORY,
	HEADER_TOP_LAST,
	HEADER_TOP_RECOMMENDED,
} from '../../i18n/shared-formats';

export function getCategoryFormatId(categoryId: string): FormatId {
	if (istLastVisitedCategory(categoryId)) {
		return HEADER_ALL_LAST;
	} else if (categoryId.toLowerCase() === 'recommended') {
		return HEADER_ALL_RECOMMENDED;
	} else if (categoryId.toLowerCase() === 'friends') {
		return HEADER_ALL_FRIENDS;
	} else {
		return HEADER_ALL_IN_CATEGORY;
	}
}

export function getTopCategoryFormatId(categoryId: string): FormatId {
	if (istLastVisitedCategory(categoryId)) {
		return HEADER_TOP_LAST;
	} else if (categoryId?.toLowerCase() === 'recommended') {
		return HEADER_TOP_RECOMMENDED;
	} else {
		return HEADER_TOP_IN_CATEGORY;
	}
}

export function getMoreCategoryFormatId(categoryId: string): FormatId {
	if (istLastVisitedCategory(categoryId)) {
		return HEADER_MORE_LAST;
	} else if (categoryId?.toLowerCase() === 'recommended') {
		return HEADER_MORE_RECOMMENDED;
	} else {
		return HEADER_MORE_IN_CATEGORY;
	}
}

export const istLastVisitedCategory = (categoryId: string): boolean =>
	categoryId?.toLowerCase() === 'lastvisited';
