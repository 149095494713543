import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { createSvgIcon } from '@knuddels/component-library';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { useMutation } from '@knuddels-app/Connection';
import { CancelFriendRequest } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useTrackFriendRequestsView } from './useTrackFriendRequestsView';
interface Props extends ContextMenuProps {
  requestUserId: string;
}
const IconWithdraw = createSvgIcon(<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.71429 0C6.80497 0 5.9329 0.361223 5.28992 1.00421C4.64694 1.64719 4.28571 2.51926 4.28571 3.42857C4.28571 4.33789 4.64694 5.20996 5.28992 5.85294C5.9329 6.49592 6.80497 6.85714 7.71429 6.85714C8.6236 6.85714 9.49567 6.49592 10.1387 5.85294C10.7816 5.20996 11.1429 4.33789 11.1429 3.42857C11.1429 2.51926 10.7816 1.64719 10.1387 1.00421C9.49567 0.361223 8.6236 0 7.71429 0ZM12.8571 7.71429C10.0286 7.71429 7.71429 10.0286 7.71429 12.8571C7.71429 15.6857 10.0286 18 12.8571 18C15.6857 18 18 15.6857 18 12.8571C18 10.0286 15.6857 7.71429 12.8571 7.71429ZM6.90067 9.46875C4.1921 9.69332 0 10.968 0 13.2857V15.4286H6.50391C6.18162 14.634 6 13.7674 6 12.8571C6 11.6229 6.32981 10.469 6.90067 9.46875ZM12 12H13.7143H15.4286V13.7143H13.7143H12H10.2857V12H12Z" fill="currentColor" />
	</svg>);
IconWithdraw.displayName = 'IconWithdraw';
export const SentFriendRequestContextMenu: React.FC<Props> = ({
  requestUserId,
  ...props
}) => {
  const thisVisibleOverlay = useService($ThisVisibleOverlay);
  const cancelRequest = useCancelRequest(requestUserId);
  const track = useTrackFriendRequestsView();
  const closeContextMenu = () => {
    thisVisibleOverlay.dispose();
  };
  return <ContextMenu {...props} closeContextMenu={closeContextMenu}>
			<ContextMenuEntry icon={IconWithdraw} text={formatMessage(declareFormat({
      id: 'friendrequests.sent.remove',
      defaultFormat: 'Withdraw request'
    }))} onClick={() => {
      track('SentRequest_Removed');
      cancelRequest();
      closeContextMenu();
    }} />
		</ContextMenu>;
};
const useCancelRequest = (userId: string) => {
  const [cancelRequest] = useMutation(CancelFriendRequest);
  const snackbarService = useService($SnackbarService);
  return async () => {
    const result = await cancelRequest({
      userId
    });
    if (!result.data || result.data.__typename !== 'Success') {
      snackbarService.showGenericError();
    } else {
      // Success is handled through event
    }
  };
};
export const SentFriendRequestContextMenuOverlay = new OverlayFactory(SentFriendRequestContextMenu, props => ({
  requestUserId: props.requestUserId
}));