import * as React from 'react';
import { SmileyId } from '@knuddelsModules/Smileybox/bundle/services';
import { SmileyCell, SmileyCellSkeleton } from '@knuddelsModules/Smileybox/bundle/components/SmileyCell';
import { SmileyDetails } from '@generated/graphql';
import { Spacer } from '@shared/components';
import { XOR } from '@knuddels-app/tools/types';
import { listPadding } from './listConstants';
import { insertSeparators } from '@knuddels-app/tools/insertSeparators';
import { Flex, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
type NormalProps = {
  smileyIds: readonly SmileyId[];
  width: number;
  smileyCellFetchPolicy?: 'cache-only' | 'cache-first';
  onSmileyClick(smileyDetails: SmileyDetails): void;
};
type SkeletonProps = {
  smileyCount: number;
  width: number;
};
type Props = XOR<NormalProps & {
  skeleton: false;
}, SkeletonProps & {
  skeleton: true;
}>;
function SmileyListRowContent(props: Props): React.ReactElement {
  return <div style={{
    marginBottom: resolveThemingValue((listPadding.vertical as ThemeOverride), "spacing", useTheme()),
    width: resolveThemingValue(props.width, "sizes", useTheme()),
    paddingLeft: resolveThemingValue((listPadding.horizontal as ThemeOverride), "spacing", useTheme()),
    paddingRight: resolveThemingValue((listPadding.horizontal as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			{props.skeleton ? createSmileyCellSkeletons(props.smileyCount) : createSmileyCells(props.smileyIds, props.onSmileyClick, props.smileyCellFetchPolicy)}
		</div>;
}
export function SmileyListRow(props: NormalProps): React.ReactElement {
  return <SmileyListRowContent skeleton={false} {...props} />;
}
export function SmileyListRowSkeleton(props: SkeletonProps): React.ReactElement {
  return <SmileyListRowContent skeleton {...props} />;
}
function createSmileyCells(smileyIds: readonly SmileyId[], onSmileyClick: (smileyDetails: SmileyDetails) => void, smileyCellFetchPolicy?: 'cache-only' | 'cache-first'): React.ReactElement[] {
  const smileys = smileyIds.map(id => <SmileyCell key={id} id={id} onClick={onSmileyClick} fetchPolicy={smileyCellFetchPolicy} />);
  return insertSeparators(smileys, index => <Spacer size={'small'} key={`Spacer_${index}`} />);
}
function createSmileyCellSkeletons(smileyCount: number): React.ReactElement[] {
  const smileys: React.ReactElement[] = [];
  for (let i = 0; i < smileyCount; i++) {
    smileys.push(<SmileyCellSkeleton key={i} />);
  }
  return insertSeparators(smileys, index => <Spacer size={'small'} key={`Spacer_${index}`} />);
}
const _c0 = " Knu-Flex overflow-visible alignSelf-center ";