import { createKeyboardHandler as a } from "./KeyboardManager.js";
import { touchableFeedbackProps as u } from "./touchableFeedback.js";
import { PointerPropsMap as P } from "../components/Layout/Box/PointerPropsMap.js";
const p = ({
  pointerCallback: e,
  pointerEventType: o = "onPress",
  disabled: r = !1,
  ariaRole: t = "button"
}) => {
  if (!e)
    return {};
  const n = a().map("Enter").to(e);
  return {
    cursor: r ? "default" : "pointer",
    [o]: !r && e ? (s) => {
      s.stopPropagation(), e();
    } : void 0,
    onKeyPress: r ? void 0 : n.handle,
    ariaRole: t,
    tabIndex: r ? -1 : 0,
    ariaDisabled: r,
    ...u
  };
}, m = ({
  pointerCallback: e,
  pointerEventType: o = "onPress",
  disabled: r = !1,
  ariaRole: t = "button"
}) => {
  if (!e)
    return {};
  const n = a().map("Enter").to(e);
  return {
    cursor: r ? "default" : "pointer",
    [P[o]]: !r && e ? (s) => {
      s.stopPropagation(), e();
    } : void 0,
    onKeyPress: r ? void 0 : n.handle,
    role: t,
    tabIndex: r ? -1 : 0,
    "aria-disabled": r
  };
};
export {
  p as createAccessiblePointerEventHandler,
  m as createNativeAccessiblePointerEventHandler
};
