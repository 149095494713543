import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { ContainerProvider, useService } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { SyncedCacheProvider } from '@knuddels-app/Connection';
import { DarkTheme, LightTheme, Theme, ThemeProvider } from '@knuddels/component-library';
import { $LocalStorage } from '@knuddels-app/local-storage';
import { accentKey, themeKey } from '@shared/themeKeys';
import { ThemeSwitchProvider } from './ThemeSwitchProvider';
import { usePrefersDarkMode } from '@knuddels-app/tools/usePrefersDarkMode';
import { useNativeDarkModeListener } from './tools/useNativeDarkModeListener';
import { isMobileBrowser } from './tools/isMobileBrowser';
import { isNative } from './tools/isNative';
import { createThemeWithFontScale } from '@knuddels-app/tools/createThemeWithFontScale';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { useFontScale } from '@knuddels-app/tools/getFontScale';
import { StAppMessengerThemeProvider } from '@knuddels-app/StAppMessenger/StAppMessengerThemeProvider';

/**
 * Used to inject all relevant providers into subtrees.
 * Was created for the {@link KeyboardReplacementContainer} component that
 * renders its children outside the providers' scope (only on native).
 */

const useThemeFromStorage = () => {
  const prefersDarkMode = usePrefersDarkMode();
  const [config, setConfig] = React.useState<{
    theme: Theme;
    accent: string;
  } | null>();
  const localStorage = useService($LocalStorage);
  const fontScale = useFontScale();
  const updateTheme = (theme: Theme) => {
    setConfig({
      theme,
      accent: config?.accent ?? LightTheme.colors.basic.accent
    });
  };
  const createTheme = async () => {
    const themeEntry = localStorage.getEntry(themeKey);
    const accentEntry = localStorage.getEntry(accentKey);
    const theme = themeEntry.get();
    const accent = accentEntry.get();
    const useTheme = theme === 'dark' ? DarkTheme : theme === 'light' ? LightTheme : prefersDarkMode ? DarkTheme : LightTheme;
    if (!accent) {
      setConfig({
        theme: await createThemeWithFontScale(useTheme.colors.basic.accent, useTheme),
        accent: useTheme.colors.basic.accent
      });
      return;
    }
    setConfig({
      theme: await createThemeWithFontScale(accent, useTheme),
      accent
    });
  };
  React.useLayoutEffect(() => {
    createTheme();
  }, [prefersDarkMode, fontScale]);
  return {
    config,
    updateTheme
  };
};
const CustomThemeProvider: React.FC = ({
  children
}) => {
  const {
    config,
    updateTheme
  } = useThemeFromStorage();
  useNativeDarkModeListener(updateTheme);
  if (isNative()) {
    React.useEffect(() => {
      if (!config) {
        return;
      }
      Keyboard.setStyle({
        style: config.theme.id === 'dark' ? KeyboardStyle.Dark : KeyboardStyle.Light
      });
    }, [config?.theme]);
  }
  if (!config) {
    return null;
  }
  return <ThemeSwitchProvider value={updateTheme}>
			<ThemeProvider theme={config.theme} useCustomScrollBars={!isMobileBrowser() || isNative()}>
				{children}
			</ThemeProvider>
		</ThemeSwitchProvider>;
};
export function AllProviders(props: React.PropsWithChildren<{}>): React.ReactElement {
  return <ContainerProvider value={dangerouslyGetK3Container()}>
			<CustomThemeProvider>
				<StAppMessengerThemeProvider>
					<SyncedCacheProvider>{props.children}</SyncedCacheProvider>
				</StAppMessengerThemeProvider>
			</CustomThemeProvider>
		</ContainerProvider>;
}