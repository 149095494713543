import { isPrimaryPointer as t } from "./utils/is-primary-pointer.js";
function n(o, r = "page") {
  return {
    point: {
      x: o[`${r}X`],
      y: o[`${r}Y`]
    }
  };
}
const a = (o) => (r) => t(r) && o(r, n(r));
export {
  a as addPointerInfo,
  n as extractEventInfo
};
