import { DisableFreeProfileVisitorUnlockAvailableNotification, DisableNewProfileVisitorNotification, EnableFreeProfileVisitorUnlockAvailableNotification, EnableNewProfileVisitorNotification, ProfileVisitorNotificationSettings } from '@generated/graphql';
import { useMutation } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { FlexCol, ListGroup, SwitchListItem } from '@knuddels/component-library';
import * as React from 'react';
import { useFailedSaveSnackbar } from './usePrivacySettingState';
export const ProfileVisitorSettings: React.FC<{
  data: ProfileVisitorNotificationSettings;
  updateData: (newData: Partial<ProfileVisitorNotificationSettings>) => {
    revertUpdate: () => void;
  };
}> = ({
  data,
  updateData
}) => {
  const i18n = useService($I18n);
  const showErrorSnackbar = useFailedSaveSnackbar();
  const [enableNewProfileVisitorNotification] = useMutation(EnableNewProfileVisitorNotification);
  const [disableNewProfileVisitorNotification] = useMutation(DisableNewProfileVisitorNotification);
  const [enableFreeProfileVisitorUnlockAvailableNotification] = useMutation(EnableFreeProfileVisitorUnlockAvailableNotification);
  const [disableFreeProfileVisitorUnlockAvailableNotification] = useMutation(DisableFreeProfileVisitorUnlockAvailableNotification);
  const onNewProfileVisitorChange = (value: boolean) => {
    if (value === data?.newVisitorNotificationEnabled) {
      return;
    }
    const {
      revertUpdate
    } = updateData({
      newVisitorNotificationEnabled: value
    });
    const changePromise = value ? enableNewProfileVisitorNotification({}) : disableNewProfileVisitorNotification({});
    changePromise.then(result => {
      if (result.data?.__typename !== 'Success') {
        revertUpdate();
        showErrorSnackbar();
      }
    });
  };
  const onFreeProfileVisitorUnlockAvailableChange = (value: boolean) => {
    if (value === data.freeUnlockAvailableNotificationEnabled) {
      return;
    }
    const {
      revertUpdate
    } = updateData({
      freeUnlockAvailableNotificationEnabled: value
    });
    const changePromise = value ? enableFreeProfileVisitorUnlockAvailableNotification({}) : disableFreeProfileVisitorUnlockAvailableNotification({});
    changePromise.then(result => {
      if (result.data?.__typename !== 'Success') {
        revertUpdate();
        showErrorSnackbar();
      }
    });
  };
  return <div className={_c0}>
			<ListGroup title={declareFormat({
      id: 'settings.privacy.visitorNotifications.title',
      defaultFormat: 'Visitor notifications'
    }).format(i18n)}>
				<SwitchListItem caption={declareFormat({
        id: 'settings.privacy.visitorNotifications.newVisitor.label',
        defaultFormat: 'New profile visitors'
      }).format(i18n)} checked={data?.newVisitorNotificationEnabled ?? false} onChange={checked => onNewProfileVisitorChange(checked)} />
				<SwitchListItem caption={declareFormat({
        id: 'settings.privacy.visitorNotifications.freeUnlock.label',
        defaultFormat: 'Free unlock available'
      }).format(i18n)} checked={data?.freeUnlockAvailableNotificationEnabled ?? false} onChange={checked => onFreeProfileVisitorUnlockAvailableChange(checked)} separator={'none'} />
			</ListGroup>
		</div>;
};
const _c0 = " Knu-FlexCol ";