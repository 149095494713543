import { injectable } from '@knuddels-app/DependencyInjection';
import { computed, observable, runInAction } from '@knuddels-app/mobx';
import { LocaleId } from '@knuddels/std';
import { BugIndicatingError } from '@knuddels/std';
import { NativeInterface } from './Native/NativeInterface';
import { BrowserInterface } from './Browser/BrowserInterface';
import { MessengerSystemAppInterface } from './MessengerSystemApp/MessengerSystemAppInterface';
import { CommonInterface } from './CommonInterface';
import { History } from 'history';
import { openInNewTab } from '@shared/helper/openUrl';

@injectable()
export class Environment implements CommonInterface {
	@observable
	private _isHidden = document.hidden;

	constructor(
		public readonly nativeInterface: NativeInterface | undefined,
		public readonly browserInterface: BrowserInterface | undefined,
		public readonly messengerSystemAppInterface:
			| MessengerSystemAppInterface
			| undefined
	) {
		document.addEventListener('visibilitychange', () => {
			runInAction('App visibility changed', () => {
				this._isHidden = document.hidden;
			});
		});
	}

	private getFirstDefinedInterface(): CommonInterface {
		const interfaces = [
			this.nativeInterface,
			this.messengerSystemAppInterface,
			this.browserInterface,
		];
		const result = interfaces.find(i => i !== undefined);
		if (!result) {
			throw new BugIndicatingError('No interface');
		}
		return result;
	}

	@computed public get hasFocus(): boolean {
		return this.getFirstDefinedInterface().hasFocus;
	}

	public get isHidden(): boolean {
		return this._isHidden;
	}

	public get history(): History {
		return this.getFirstDefinedInterface().history;
	}

	public get defaultLocale(): LocaleId {
		return this.getFirstDefinedInterface().defaultLocale;
	}

	public openUrl = (url: string): void => {
		if (globalEnv.product === 'stapp-messenger') {
			if (this.messengerSystemAppInterface) {
				this.messengerSystemAppInterface.client.openBrowserWindow(url);
			}
		} else {
			openInNewTab(url);
		}
	};

	public get isStappSidebarOverview(): boolean {
		return (
			globalEnv.product === 'stapp-messenger' &&
			this.messengerSystemAppInterface!.client.pageData.isSidebarOverview
		);
	}
}
