import { Text, TextProps } from '@knuddels/component-library';
import { motion, useAnimation } from 'framer-motion';
import * as React from 'react';
export const AnimatedDots: React.FC<{
  type?: TextProps['type'];
  state?: TextProps['state'];
}> = ({
  type = 'body1',
  state = 'primary'
}) => {
  const dots = [useAnimatedDot(0), useAnimatedDot(1), useAnimatedDot(2)];
  return <>
			{dots.map((dotControls, index) => <motion.div key={index} animate={dotControls}>
					<Text type={type} state={state} bold={true} className={_c0}>
						.
					</Text>
				</motion.div>)}
		</>;
};
const dotAnimationOneDirectionDuration = 0.15;
const useAnimatedDot = (index: number) => {
  const mounted = React.useRef(false);
  const controls = useAnimation();
  React.useEffect(() => {
    mounted.current = true;
    const delay = index * 2 * dotAnimationOneDirectionDuration;
    async function doAnimation(): Promise<void> {
      if (!mounted.current) {
        return;
      }
      await controls.start({
        opacity: 0.5,
        y: 0,
        transition: {
          duration: delay
        }
      });
      await controls.start({
        opacity: 1,
        y: -2,
        transition: {
          duration: dotAnimationOneDirectionDuration,
          ease: 'easeInOut'
        }
      });
      await controls.start({
        opacity: 0.5,
        y: 0,
        transition: {
          duration: dotAnimationOneDirectionDuration,
          ease: 'easeInOut'
        }
      });
    }
    const interval = setInterval(doAnimation, (6 + 2) * dotAnimationOneDirectionDuration * 1000);
    return () => {
      mounted.current = false;
      clearInterval(interval);
    };
  }, []);
  return controls;
};
const _c0 = "  ";