import { acceleratedValues as o } from "../../animation/animators/utils/accelerated-values.js";
import { camelToDash as e } from "../../render/dom/utils/camel-to-dash.js";
import { transformProps as t } from "../../render/html/utils/transform.js";
function s(r) {
  if (t.has(r))
    return "transform";
  if (o.has(r))
    return e(r);
}
export {
  s as getWillChangeName
};
