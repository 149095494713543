import * as React from 'react';
import { Form } from '@shared/components';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { FlexCol, SearchInput } from '@knuddels/component-library';
interface Props {
  searchValue: string;
  searchbarRef: React.MutableRefObject<any>;
  onSubmit(event: any): boolean;
  updateSearchValue(newInput: string): void;
  handleSearchbarFocused(): void;
}
export function ContactsSearchInput({
  searchValue,
  searchbarRef,
  onSubmit,
  updateSearchValue,
  handleSearchbarFocused
}: Props): React.ReactElement {
  return <div className={_c0}>
			<Form onSubmit={onSubmit}>
				<SearchInput size={'condensed'} value={searchValue} onChange={e => updateSearchValue(e.value)} onFocus={handleSearchbarFocused} placeholder={formatMessage(declareFormat({
        id: 'contacts.search.input.placeholder',
        defaultFormat: 'Search contact'
      }))} innerRef={searchbarRef} />
			</Form>
		</div>;
}
const _c0 = " Knu-FlexCol px-base py-small ";