import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import iconCheck from '../assets/icon-check.png';

export const SnackbarDefinitionAutomaticComplaintSuccess: SnackbarData = {
	type: 'automaticComplaintSuccess',
	text: () =>
		formatMessage(
			declareFormat({
				id: 'messenger.automaticComplaint.success',
				defaultFormat: 'Your complaint has been sent successfully.',
			})
		),
	adornment: iconCheck,
};
