import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { RemoveAdsBadge } from './RemoveAdsBadge';
import { FlexCol, Text } from '@knuddels/component-library';
export function AdsPlaceholder(props: {
  width: number;
  height: number;
  hideRemoveAdsBadge?: true;
}): React.ReactElement {
  return <div className={_c0}>
			<div className={_c1}>
				{props.hideRemoveAdsBadge || <RemoveAdsBadge />}
				<FlexCol position={'relative'} width={props.width} height={props.height} bg={'black-solid-020'} darkBg={'white-solid-050'} placeItems={'center'} opacity={0.44} handleDarkProps>
					<Text type="body1" state="tertiary" bold={true} className={_c2}>
						<FormattedMessage id={declareFormat({
            id: 'LOADING_ADS',
            defaultFormat: 'Loading ad...'
          })} />
					</Text>
				</FlexCol>
			</div>
		</div>;
}
const _c0 = " Knu-FlexCol position-absolute inset-none placeItems-center overflow-visible ";
const _c1 = " Knu-FlexCol position-relative ";
const _c2 = "  ";