import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const t = r(
  /* @__PURE__ */ o(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      children: /* @__PURE__ */ o(
        "path",
        {
          d: "M5.713,13.293l5.586-5.586c0.391-0.391,1.024-0.391,1.414,0l5.586,5.586c0.63,0.63,0.184,1.707-0.707,1.707H6.42 C5.529,15,5.083,13.923,5.713,13.293z",
          fill: "currentColor"
        }
      )
    }
  )
);
t.displayName = "IconSortUp";
export {
  t as IconSortUp
};
