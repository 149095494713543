import * as React from 'react';
import { GenericModal, GenericModalProps } from './GenericModal';
import { Spacer, TextProps } from '../../atoms';
import { FormattedText } from '../FormattedText/FormattedText';
import { FormattedTextDisplay } from '../FormattedText/FormattedTextDisplay';
import { Text } from '@knuddels/component-library';
export interface TextModalProps extends GenericModalProps {
  text: string | FormattedText;
}
export class TextModal extends React.PureComponent<TextModalProps> {
  render(): JSX.Element {
    const {
      text,
      ...restProps
    } = this.props;
    const textProps: TextProps = {
      type: 'body1'
    };
    return <GenericModal {...restProps} primaryButton={{
      width: 195,
      ...restProps.primaryButton
    }}>
				{typeof text === 'string' ? <Text {...textProps} textAlign={this.props.alignCenter ? 'center' : undefined}>
						{text}
					</Text> : <FormattedTextDisplay textProps={{
        ...textProps,
        textAlign: this.props.alignCenter ? 'center' : undefined
      }} text={text} />}
				<Spacer size="xlarge" />
			</GenericModal>;
  }
}