import { isString as l, singleColorRegex as p, isNullish as c, floatRegex as h } from "../utils.js";
const u = (r, o) => (t) => !!(l(t) && p.test(t) && t.startsWith(r) || o && !c(t) && Object.prototype.hasOwnProperty.call(t, o)), f = (r, o, t) => (a) => {
  if (!l(a))
    return a;
  const [s, n, i, e] = a.match(h);
  return {
    [r]: parseFloat(s),
    [o]: parseFloat(n),
    [t]: parseFloat(i),
    alpha: e !== void 0 ? parseFloat(e) : 1
  };
};
export {
  u as isColorString,
  f as splitColor
};
