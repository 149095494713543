import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { PlaceholderContent } from '@shared/components';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
export const NoCommentsPlaceholder: React.FC = () => {
  const i18n = useService($I18n);
  const headline = declareFormat({
    id: 'album.details.noComment.headline',
    defaultFormat: 'Leave a comment'
  }).format(i18n);
  const text = declareFormat({
    id: 'album.details.noComment.text',
    defaultFormat: 'There are no comments yet. Leave the first one.'
  }).format(i18n);
  return <PlaceholderContent image={require('./sm_abo_16-12_wunschliste_boy_love.png')} imageWidth={66} imageHeight={58} title={headline} description={text} py={'none'} />;
};