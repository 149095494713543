import { Environments } from './Environments';
import { BrowserInterfaceImplementation } from './Browser/BrowserInterfaceImpl';
import { createMessengerSystemAppInterface } from '@knuddels-app/Environment/MessengerSystemApp/MessengerSystemAppInterfaceImpl';

export const EnvironmentsImpl: Environments = {
	createBrowserInterface: () => new BrowserInterfaceImplementation(),
	createMessengerSystemAppInterface: () =>
		// TODO EnvironmentsImpl.stapp.ts
		globalEnv.product === 'stapp-messenger'
			? createMessengerSystemAppInterface()
			: undefined,
	createNativeInterface: () => undefined,
};
