import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import {
	FullConversationWithoutMessagesFragment,
	GetConversationWithoutMessages,
	MessengerConversation,
	MessengerConversationReadStateChangedFragment,
	MessengerConversationState,
	MessengerConversationVisibility,
	MessengerMessageReceivedFragment,
} from '@generated/graphql';
import { Disposable } from '@knuddels/std';
import {
	messengerViewId,
	MessengerViewState,
} from '../../MessengerViewProvider';
import { $ViewService } from '@knuddels-app/layout';
import {
	$MessengerConversationService,
	$MessengerListService,
	$MessengerService,
} from '../../providedServices';
import { isConversationUnread } from '../utils/conversationReadStateHelper';
import {
	addConversationToOverviewList,
	removeAllConversationsFromCache,
	removeConversationFromOverviewList,
} from '../cacheHelpers';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';

@injectable()
export class MessengerOverviewService {
	private readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($MessengerService)
		private readonly messengerService: typeof $MessengerService.T,
		@inject($MessengerConversationService)
		private readonly messengerConversationService: typeof $MessengerConversationService.T,
		@inject($MessengerListService)
		private readonly messengerListService: typeof $MessengerListService.T,
		@inject($FriendRequestsService)
		friendRequestsService: typeof $FriendRequestsService.T
	) {
		this.dispose.track(
			this.messengerService.onConversationVisibilityChanged.sub(event =>
				this.handleVisibilityChangedEvent(event.conversation)
			)
		);
		this.dispose.track(
			this.messengerService.onConversationReadStateChanged.sub(event =>
				this.handleReadStateEvent(event.conversation)
			)
		);
		this.dispose.track(
			this.authenticatedClientService.onReconnected.sub(async () => {
				removeAllConversationsFromCache(
					this.authenticatedClientService
				);

				await this.messengerListService.refetchAll();
			})
		);

		this.dispose.track(
			friendRequestsService.onReceivedFriendRequestAdded.sub(request => {
				const conversation =
					messengerListService.overviewConversations.find(
						it =>
							it.type === 'conversation' &&
							it.conversation.otherParticipants[0].id ===
								request.user.id
					);

				if (conversation) {
					const clientState =
						messengerConversationService.getOrCreateClientConversationState(
							conversation.conversationId
						);

					clientState.friendRecommendationsState.removeAll();
				}
			})
		);
	}

	private readonly handleReadStateEvent = async (
		conversation: MessengerConversationReadStateChangedFragment['conversation']
	): Promise<void> => {
		if (isConversationUnread(conversation)) {
			this.addCachedConversationToList(
				conversation.id,
				MessengerConversationState.Unread
			);
		} else {
			this.removeConversationFromList(
				conversation.id,
				MessengerConversationState.Unread
			);
		}
	};

	public readonly handleNewMessage = async (
		event: MessengerMessageReceivedFragment
	) => {
		const conversation = event.conversation;
		const queriedConversation = await this.queryConversation(
			conversation.id
		);
		const newConversation = this.updateLatestMessage(
			conversation,
			queriedConversation
		);

		if (
			newConversation.visibility ===
			MessengerConversationVisibility.Visible
		) {
			this.removeConversationFromList(
				newConversation.id,
				MessengerConversationState.Archived
			);
			this.addConversationToList(
				newConversation,
				MessengerConversationState.NotArchived
			);
			if (isConversationUnread(newConversation)) {
				this.addConversationToList(
					newConversation,
					MessengerConversationState.Unread
				);
			}
		} else if (
			newConversation.visibility ===
			MessengerConversationVisibility.Archived
		) {
			this.addConversationToList(
				newConversation,
				MessengerConversationState.Archived
			);
		}
	};

	public readonly queryConversation = async (
		id: MessengerConversation['id']
	): Promise<FullConversationWithoutMessagesFragment> => {
		// query conversation (most of the time from cache)
		const result =
			await this.authenticatedClientService.currentK3Client.query(
				GetConversationWithoutMessages,
				{
					id,
					pixelDensity: getPixelRatio(),
				}
			);
		return result.match({
			ok: value => value.primaryData,
			error: err => {
				throw new Error(
					`Loading conversation '${id}' failed: ` +
						JSON.stringify(err)
				);
			},
		});
	};

	private readonly updateLatestMessage = (
		conversation: Pick<
			FullConversationWithoutMessagesFragment,
			'latestConversationMessage'
		>,
		cachedConversation: FullConversationWithoutMessagesFragment
	) => {
		if (!cachedConversation.latestConversationMessage) {
			return {
				...cachedConversation,
				latestConversationMessage:
					conversation.latestConversationMessage,
			};
		} else {
			return cachedConversation;
		}
	};

	private readonly handleVisibilityChangedEvent = async (
		conversation: Pick<MessengerConversation, 'id' | 'visibility'>
	): Promise<void> => {
		const id = conversation.id;

		if (
			conversation.visibility === MessengerConversationVisibility.Archived
		) {
			this.moveConversation(
				id,
				MessengerConversationState.NotArchived,
				MessengerConversationState.Archived
			);
			this.removeConversationFromList(
				id,
				MessengerConversationState.Unread
			);
		} else if (
			conversation.visibility === MessengerConversationVisibility.Deleted
		) {
			this.removeConversationFromLists(id, [
				MessengerConversationState.Unread,
				MessengerConversationState.Archived,
				MessengerConversationState.NotArchived,
			]);
		} else {
			this.moveConversation(
				id,
				MessengerConversationState.Archived,
				MessengerConversationState.NotArchived
			);
			this.withCachedConversation(id, conv => {
				if (isConversationUnread(conv)) {
					this.addCachedConversationToList(
						id,
						MessengerConversationState.Unread
					);
				}
			});
		}

		if (this.messengerService.getOpenConversationId() !== id) {
			return;
		}

		const openedConversation =
			this.messengerConversationService.getCachedOverviewConversation(id);
		if (!openedConversation) {
			return;
		}

		if (
			conversation.visibility !==
				MessengerConversationVisibility.Deleted &&
			openedConversation.visibility !==
				MessengerConversationVisibility.Visible &&
			openedConversation.latestConversationMessage
		) {
			setTimeout(() => {
				this.openNextConversation(
					+openedConversation.latestConversationMessage.timestamp
				);
			}, 1);
		}
	};

	private readonly openNextConversation = (
		activeConversationTimestamp: number
	): void => {
		const nextConversationId =
			this.messengerListService.getNextConversationId(
				activeConversationTimestamp
			);

		if (nextConversationId) {
			this.viewService.openView(
				messengerViewId.with(s =>
					s.withConversation({
						scrollToMessageId: undefined,
						conversationId: nextConversationId,
					})
				)
			);
		} else {
			// TODO should only close conversation
			this.viewService.openView(
				messengerViewId.with(
					s => s.withPath('overview') as MessengerViewState
				)
			);
		}
	};

	private readonly withCachedConversation = (
		id: MessengerConversation['id'],
		fn: (conv: FullConversationWithoutMessagesFragment) => void
	) => {
		const cachedConversation =
			this.messengerConversationService.getCachedOverviewConversation(id);
		if (cachedConversation) {
			fn(cachedConversation);
		}
	};

	private readonly moveConversation = (
		id: MessengerConversation['id'],
		from: MessengerConversationState,
		to: MessengerConversationState
	): void => {
		this.withCachedConversation(id, conversation => {
			removeConversationFromOverviewList(
				this.authenticatedClientService,
				id,
				from
			);
			addConversationToOverviewList(
				this.authenticatedClientService,
				conversation,
				to
			);
		});
	};

	private readonly addCachedConversationToList = (
		id: MessengerConversation['id'],
		to: MessengerConversationState
	): void => {
		this.withCachedConversation(id, conversation => {
			addConversationToOverviewList(
				this.authenticatedClientService,
				conversation,
				to
			);
		});
	};

	private readonly addConversationToList = (
		conversation: FullConversationWithoutMessagesFragment,
		to: MessengerConversationState
	): void => {
		addConversationToOverviewList(
			this.authenticatedClientService,
			conversation,
			to,
			true
		);
	};

	private readonly removeConversationFromList = (
		id: MessengerConversation['id'],
		from: MessengerConversationState
	): void => {
		removeConversationFromOverviewList(
			this.authenticatedClientService,
			id,
			from
		);
	};

	private readonly removeConversationFromLists = (
		id: MessengerConversation['id'],
		from: MessengerConversationState[]
	): void => {
		from.forEach(state =>
			removeConversationFromOverviewList(
				this.authenticatedClientService,
				id,
				state
			)
		);
	};
}
