import { Spacer } from '../../atoms';
import * as React from 'react';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Text, ThemeOverride, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
export interface BannerBoxButtonProps {
  symbol?: React.ComponentType<any>;
  size?: 'small' | 'normal';
  text: string;
  onClick: () => void;
}
export function BannerBoxButton(props: BannerBoxButtonProps): React.ReactElement {
  const [hovered, setHovered] = React.useState(false);
  return <div className={_c0}>
			<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: props.onClick
    })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c1 + (props.size === 'small' ? _c2 : _c3)}>
				{props.symbol && <>
						{React.createElement(props.symbol, {
          size: 'large'
        })}
						<Spacer size={'small'} />
					</>}
				<Text state={((hovered ? 'secondary' : 'tertiary') as TextStates)} bold={true} numberOfLines={1} className={_c4}>
					{props.text}
				</Text>
			</div>
		</div>;
}
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = " Knu-Flex cursor-pointer flex-1 flexGrow-1 flexBasis-0-px px-base placeItems-center ";
const _c2 = " py-minor ";
const _c3 = " py-18px ";
const _c4 = "  ";