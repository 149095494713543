import * as React from 'react';
import { useEffect } from 'react';
import { Flex, FlexCol, Link, ListGroup, ScrollView, Smiley, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { Knuddel, Spacer } from '@shared/components';
import { RetroSmileyItem, useRetroSmileys } from './retroSmileysData';
import { MentorConversationStartedProgressBar } from '../MentorConversationStartedProgressBar';
import { MentorPointsProgressBar } from '../MentorPointsProgressBar';
import { $MentorService } from '../../../providedServices';
import { useTrackMentorSystemInfo } from './useTrackMentorSystemInfo';
import { $Environment } from '@knuddels-app/Environment';
import { IconMentorPoints } from '../shared/IconMentorPoints';
export const MentorSystemRewardsInfo: React.FC = observer('MentorSystemRewardsInfo', () => {
  const i18n = useService($I18n);
  const track = useTrackMentorSystemInfo();
  const environment = useService($Environment);
  const service = useService($MentorService);
  const status = service.mentorStatus;
  if (!status || status?.__typename !== 'MentorStatusActive') {
    return null;
  }
  useEffect(() => {
    track('Rewards_Shown');
  }, []);
  return <div className={_c0}>
				<InfoGroup title={declareFormat({
      id: 'mentorsystem.rewards.info.started-chat.title',
      defaultFormat: 'Rewards for started conversations'
    }).format(i18n)}>
					<div className={_c1}>
						<MentorConversationStartedProgressBar activeStatus={status} />
					</div>

					<div className={_c2}>
						<Text state={'secondary'} className={_c3}>
							<FormattedMessage id={declareFormat({
            id: 'mentorsystem.rewards.info.started-chat.text',
            defaultFormat: 'You get for every {count} started conversations 1 {knuddel}'
          })} values={{
            count: status.conversationStartedBoundary,
            knuddel: <Knuddel optimizeForStapp={environment.isStappSidebarOverview} />
          }} />
						</Text>
					</div>
				</InfoGroup>

				<InfoGroup title={declareFormat({
      id: 'mentorsystem.rewards.info.mentor.title',
      defaultFormat: 'Rewards for mentor points'
    }).format(i18n)}>
					<div className={_c4}>
						<MentorPointsProgressBar activeStatus={status} />
					</div>

					<RetroSmileyInfo title={declareFormat({
        id: 'mentorsystem.rewards.info.mentor.retro-smileys',
        defaultFormat: 'Our retro smileys'
      }).format(i18n)} />

					<div className={_c5}>
						<Smiley width={32} height={32} src={require('@shared/images/member-status.png')} />
						<HonoraryMembershipInfo />
					</div>
				</InfoGroup>
				<Spacer size={'base'} />
			</div>;
});
const InfoGroup: React.FC<{
  children: React.ReactNode;
  title: string;
}> = ({
  children,
  title
}) => {
  return <div className={_c6}>
			<ListGroup title={title}>
				<div className={_c7 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					{children}
				</div>
			</ListGroup>
		</div>;
};
const RetroSmileyInfo: React.FC<{
  title: string;
}> = ({
  title
}) => {
  const items = useRetroSmileys();
  const middleIndex = Math.ceil(items.length / 2);
  const leftItems = items.splice(0, middleIndex);
  const rightItems = items.splice(-middleIndex);
  return <div className={_c8}>
			<Text state={'secondary'} bold={true} className={_c9}>
				{title}
			</Text>
			<div className={_c10}>
				<div className={_c11}>
					{leftItems.map((item, index) => <RetroSmileyInfoItem key={`retroSmileyL-${index}`} item={item} />)}
				</div>
				<div className={_c12}>
					{rightItems.map((item, index) => <RetroSmileyInfoItem key={`retroSmileyR-${index}`} item={item} />)}
				</div>
			</div>
		</div>;
};
const RetroSmileyInfoItem: React.FC<{
  item: RetroSmileyItem;
}> = ({
  item
}) => {
  return <div className={_c13}>
			<Smiley width={16} height={16} src={item.src} />
			<Text type={'body2'} state={'secondary'} className={_c14}>
				{item.text}
			</Text>
			<IconMentorPoints />
		</div>;
};
const HonoraryMembershipInfo: React.FC = () => {
  return <div className={_c15}>
			<div className={_c16}>
				<Text type={'body2'} state={'secondary'} className={_c17}>
					<FormattedMessage id={declareFormat({
          id: 'mentorsystem.rewards.info.mentor.honorary-membership.text',
          defaultFormat: '{link} from 60.000'
        })} values={{
          link: <HonoraryMembershipLink />
        }} />
				</Text>
				<IconMentorPoints />
			</div>

			<Text type={'body2'} state={'secondary'} className={_c18}>
				<FormattedMessage id={declareFormat({
        id: 'mentorsystem.rewards.info.mentor.honorary-membership.explanation',
        defaultFormat: '(requirements: min. 6 months membership time, account checked by honorary committee)'
      })} />
			</Text>
		</div>;
};
const HonoraryMembershipLink: React.FC = () => {
  const HELP_CENTER_LINK = 'https://knuddels-wiki.de/index.php/Ehrenmitglied';
  return <Link state={'secondary'} type={'tiny'} bold to={HELP_CENTER_LINK}>
			<Text color={'accent'} type={'body2'} bold={true} className={_c19}>
				<FormattedMessage id={declareFormat({
        id: 'mentorsystem.rewards.info.mentor.honorary-membership.link-text',
        defaultFormat: 'Honorary membership'
      })} />
			</Text>
		</Link>;
};
const _c0 = " Knu-ScrollView alignItems-center width-100-percent ";
const _c1 = " Knu-Flex ";
const _c2 = " Knu-Flex alignItems-center alignSelf-center mt-base ";
const _c3 = "  ";
const _c4 = " Knu-Flex ";
const _c5 = " Knu-Flex alignItems-flex-start mt-large maxWidth-100-percent ";
const _c6 = " Knu-FlexCol minWidth-0-px width-400px maxWidth-100-percent alignItems-stretch ";
const _c7 = " Knu-FlexCol minWidth-0-px p-large bg-transparent ";
const _c8 = " Knu-FlexCol ";
const _c9 = " my-base ";
const _c10 = " Knu-Flex ";
const _c11 = " Knu-FlexCol flex-1 ";
const _c12 = " Knu-FlexCol flex-1 ml-base ";
const _c13 = " Knu-Flex alignItems-center mb-tiny ";
const _c14 = " ml-base mr-tiny ";
const _c15 = " Knu-FlexCol flex-1 ";
const _c16 = " Knu-Flex ";
const _c17 = " mr-tiny ";
const _c18 = "  ";
const _c19 = "  ";