type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

function isObject<T>(item?: T): item is T {
	return !!item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeepPartial<T extends Record<any, any>>(
	oldData: T,
	newData?: DeepPartial<T>
): T {
	const output = { ...oldData };
	if (isObject(oldData) && isObject(newData)) {
		Object.keys(newData).forEach(key => {
			const typedKey = key as keyof T;
			if (isObject(newData[typedKey]) && key in oldData) {
				output[typedKey] = mergeDeepPartial(
					oldData[typedKey],
					newData[typedKey]
				);
			} else {
				output[typedKey] = newData[typedKey] as any;
			}
		});
	}
	return output;
}
