import * as React from 'react';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat, FormatId } from '@knuddels/i18n';
import { MacroBoxEntry } from './MacroBoxEntry';
import { BoxProps, Flex, FlexCol, Image, FallbackBox, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { $CommandService } from '@knuddels-app/Commands';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { associate } from '@knuddels-app/tools/associate';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import blowKiss from './icons/sm_vip_06-blowkiss.gif';
import kissing from './icons/sm_jubi-2019_kissing.gif';
import starEyes from './icons/sm_abo_09-13_star-eyes.gif';
import cuddle from './icons/sm_abo_12-09_cuddle.gif';
import tickle from './icons/sm_abo_15-10_ticklefight.gif';
import party from './icons/sm_party.gif';
import sorry from './icons/sm_abo_15-11_sorry.gif';
import cry from './icons/sm_abo_16-01_heul-leise.gif';
import sulk from './icons/sm_abo_13-06_schmollen-f.gif';
import complain from './icons/sm_abo_19-01_noway_boy.gif';
import trick from './icons/sm_abo_20-02_wedgie.gif';
import cake from './icons/sm_wr2016_cherrycake.gif';
import splash from './icons/sm_abo_12-12_snowball.gif';
import kick from './icons/sm_jubi_booty3.b.gif';
import slap from './icons/sm_abo_14-07_ohrfeige.gif';
import love from './icons/sm_wr2020_arrowheart.gif';
import flirt from './icons/sm_cal_2022_schmetterlinge-ani.gif';
import massage from './icons/sm_abo_21-04_hadoukenheart-ani-boy.gif';
import romantic from './icons/sm_abo_21-11_herbstromantik_boy-ani.gif';
import secretkiss from './icons/sm_abo_12-08_big-kiss.gif';
export const MacroBoxInteractionEntries: React.FC<{
  nick: string;
  close: () => void;
}> = ({
  nick,
  close
}) => {
  const [width, setWidth] = React.useState(0);
  const entries = useEntries();
  const layout = useLayout(entries, width);
  return <FallbackBox onLayout={e => {
    setWidth(e.width);
  }} className={_c0}>
			{width >= 0 && layout.map((row, rowIndex) => <div key={rowIndex} className={_c1 + (rowIndex === 0 ? _c2 : _c3)}>
						{row.map((entry, entryIndex) => <MacroBoxInteractionEntry ml={entryIndex === 0 ? undefined : 'minor'} key={entry.id} entry={entry} nick={nick} close={close} />)}
					</div>)}
		</FallbackBox>;
};
const useEntries = () => {
  const settingsEntries = useService($ClientSettingsService).macroBoxInteractionEntries;
  return React.useMemo(() => {
    const activeMap = associate(settingsEntries, it => it.id, it => it.active);
    const indexMap = associate(settingsEntries, it => it.id, (it, index) => index);
    return macroBoxInteractionEntries.filter(it => activeMap[it.id] ?? true).sort((a, b) => (indexMap[a.id] ?? 1000) - (indexMap[b.id] ?? 1000));
  }, []);
};
const useLayout = (entries: readonly Entry[], width: number) => {
  return React.useMemo(() => {
    if (width <= 0) {
      return [];
    }
    return getLayout(entries, width);
  }, [width]);
};
function getLayout(entries: readonly Entry[], width: number): Entry[][] {
  const actionsPerRow = Math.floor((width + 12) / 84);
  const actions = entries;
  const rows: Entry[][] = [];
  let row: Entry[] = [];
  for (const action of actions) {
    row.push(action);
    if (row.length >= actionsPerRow) {
      rows.push(row);
      row = [];
    }
  }
  if (row.length > 0) {
    rows.push(row);
  }
  return rows;
}
const MacroBoxInteractionEntry: React.FC<{
  ml?: BoxProps['ml'];
  entry: Entry;
  nick: string;
  close: () => void;
}> = ({
  ml,
  entry,
  nick,
  close
}) => {
  const commandService = useService($CommandService);
  const track = useLogEvent();
  const sendCommand = () => {
    close();
    const command = entry.slashCommandTemplate.replace('%NICK%', nick);
    commandService.parseAndInvokeCommand(command);
    track('Chat_MacroQuickAccess', entry.trackingItemId);
  };
  const i18n = useService($I18n);
  const title = entry.title.format(i18n);
  return <MacroBoxEntry ml={ml} title={title} onPress={sendCommand}>
			<Image src={entry.image} alt={title} style={{
      width: resolveThemingValue(entry.width, "sizes", useTheme()),
      height: resolveThemingValue(entry.height, "sizes", useTheme())
    }} className={_c4} />
		</MacroBoxEntry>;
};
type Entry = {
  id: string;
  image: any;
  title: FormatId;
  slashCommandTemplate: string;
  width: number;
  height: number;
  trackingItemId: string;
};
const macroBoxInteractionEntries: Entry[] = [{
  id: 'kiss',
  image: blowKiss,
  title: declareFormat({
    id: 'channel.macrobox.kiss',
    defaultFormat: 'Kiss'
  }),
  slashCommandTemplate: '/kiss %NICK%',
  width: 33,
  height: 18,
  trackingItemId: 'Kiss_Clicked'
}, {
  id: 'frenchkiss',
  image: kissing,
  title: declareFormat({
    id: 'channel.macrobox.frenchkiss',
    defaultFormat: 'French kiss'
  }),
  slashCommandTemplate: '/kizz %NICK%',
  width: 37,
  height: 20,
  trackingItemId: 'Kizz_Clicked'
}, {
  id: 'happy',
  image: starEyes,
  title: declareFormat({
    id: 'channel.macrobox.happy',
    defaultFormat: 'Happy'
  }),
  slashCommandTemplate: '/freu %NICK%',
  width: 19,
  height: 16,
  trackingItemId: 'Freu_Clicked'
}, {
  id: 'snuggle',
  image: cuddle,
  title: declareFormat({
    id: 'channel.macrobox.snuggle',
    defaultFormat: 'Snuggle'
  }),
  slashCommandTemplate: '/kuschel %NICK%',
  width: 29,
  height: 15,
  trackingItemId: 'Kuschel_Clicked'
}, {
  id: 'tickle',
  image: tickle,
  title: declareFormat({
    id: 'channel.macrobox.tickle',
    defaultFormat: 'Tickle'
  }),
  slashCommandTemplate: '/kitzel %NICK%',
  width: 59,
  height: 25,
  trackingItemId: 'Kitzel_Clicked'
}, {
  id: 'dance',
  image: party,
  title: declareFormat({
    id: 'channel.macrobox.dance',
    defaultFormat: 'Dance'
  }),
  slashCommandTemplate: '/dance %NICK%',
  width: 19,
  height: 14,
  trackingItemId: 'Tanzen_Clicked'
}, {
  id: 'sorry',
  image: sorry,
  title: declareFormat({
    id: 'channel.macrobox.sorry',
    defaultFormat: 'Sorry'
  }),
  slashCommandTemplate: '/sorry %NICK%',
  width: 35,
  height: 27,
  trackingItemId: 'Sorry_Clicked'
}, {
  id: 'comfort',
  image: cry,
  title: declareFormat({
    id: 'channel.macrobox.comfort',
    defaultFormat: 'Comfort'
  }),
  slashCommandTemplate: '/tröst %NICK%',
  width: 54,
  height: 29,
  trackingItemId: 'Tröst_Clicked'
}, {
  id: 'sulk',
  image: sulk,
  title: declareFormat({
    id: 'channel.macrobox.sulk',
    defaultFormat: 'Sulk'
  }),
  slashCommandTemplate: '/schmoll %NICK%',
  width: 33,
  height: 24,
  trackingItemId: 'Schmoll_Clicked'
}, {
  id: 'complain',
  image: complain,
  title: declareFormat({
    id: 'channel.macrobox.complain',
    defaultFormat: 'Complain'
  }),
  slashCommandTemplate: '/complain %NICK%',
  width: 45,
  height: 32,
  trackingItemId: 'Meckern_Clicked'
}, {
  id: 'trick',
  image: trick,
  title: declareFormat({
    id: 'channel.macrobox.trick',
    defaultFormat: 'Trick'
  }),
  slashCommandTemplate: '/trick %NICK%',
  width: 41,
  height: 20,
  trackingItemId: 'Streich_Clicked'
}, {
  id: 'cake',
  image: cake,
  title: declareFormat({
    id: 'channel.macrobox.cake',
    defaultFormat: 'Cake'
  }),
  slashCommandTemplate: '/torte %NICK%',
  width: 59,
  height: 27,
  trackingItemId: 'Torte_Clicked'
}, {
  id: 'splash',
  image: splash,
  title: declareFormat({
    id: 'channel.macrobox.splash',
    defaultFormat: 'Splash'
  }),
  slashCommandTemplate: '/splash %NICK%',
  width: 51,
  height: 45,
  trackingItemId: 'Splash_Clicked'
}, {
  id: 'kick',
  image: kick,
  title: declareFormat({
    id: 'channel.macrobox.kick',
    defaultFormat: 'Kick'
  }),
  slashCommandTemplate: '/tritt %NICK%',
  width: 19,
  height: 15,
  trackingItemId: 'Tritt_Clicked'
}, {
  id: 'slap',
  image: slap,
  title: declareFormat({
    id: 'channel.macrobox.slap',
    defaultFormat: 'Slap'
  }),
  slashCommandTemplate: '/ohrfeige %NICK%',
  width: 41,
  height: 18,
  trackingItemId: 'Ohrfeige_Clicked'
}, {
  id: 'love',
  image: love,
  title: declareFormat({
    id: 'channel.macrobox.love',
    defaultFormat: 'Love'
  }),
  slashCommandTemplate: '/love %NICK%',
  width: 57,
  height: 14,
  trackingItemId: 'Love_Clicked'
}, {
  id: 'flirt',
  image: flirt,
  title: declareFormat({
    id: 'channel.macrobox.flirt',
    defaultFormat: 'Flirt'
  }),
  slashCommandTemplate: '/flirt %NICK%',
  width: 34,
  height: 34,
  trackingItemId: 'Flirt_Clicked'
}, {
  id: 'massage',
  image: massage,
  title: declareFormat({
    id: 'channel.macrobox.massage',
    defaultFormat: 'Massage'
  }),
  slashCommandTemplate: '/massage %NICK%',
  width: 76,
  height: 58,
  trackingItemId: 'Massage_Clicked'
}, {
  id: 'romantic',
  image: romantic,
  title: declareFormat({
    id: 'channel.macrobox.romantic',
    defaultFormat: 'Romantic'
  }),
  slashCommandTemplate: '/romantic %NICK%',
  width: 60,
  height: 57,
  trackingItemId: 'Romantisch_Clicked'
}, {
  id: 'secretkiss',
  image: secretkiss,
  title: declareFormat({
    id: 'channel.macrobox.secretkiss',
    defaultFormat: 'Secret kiss'
  }),
  slashCommandTemplate: '/secretkiss %NICK%',
  width: 41,
  height: 16,
  trackingItemId: 'Secret Kiss_Clicked'
}];
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex ";
const _c2 = " mt-none ";
const _c3 = " mt-base ";
const _c4 = "  ";