let scriptAdded = false;

export function providerInit(): void {
	if (scriptAdded) {
		return;
	}

	scriptAdded = true;

	{
		const scriptNode = document.createElement('script');
		scriptNode.async = false;
		scriptNode.type = 'text/javascript';
		scriptNode.text =
			'window.ADNPM = window.ADNPM || {};ADNPM.cmd = ADNPM.cmd || [];';

		const node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(scriptNode, node);
	}

	{
		const scriptNode = document.createElement('script');
		scriptNode.async = true;
		scriptNode.type = 'text/javascript';
		scriptNode.src = 'https://cdn.netpoint-media.de/1186967.js';

		const node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(scriptNode, node);
	}
}
