import { FormatId } from '@knuddels/i18n';
import * as React from 'react';
import { useQuery } from '@knuddels-app/Connection';
import { GetChannelListCategoryHeader } from '@generated/graphql';
import { formatMessage } from '@knuddels-app/i18n';

export const useFullCategoryHeader = (
	categoryId: string,
	formatId: FormatId
) => {
	const [title, setTitle] = React.useState<string>('');
	const { data: category } = useQuery(
		GetChannelListCategoryHeader,
		{
			categoryId,
		},
		'cache-only'
	);

	React.useLayoutEffect(() => {
		setTitle(
			category?.id && category?.name
				? formatMessage(formatId, { category: category?.name })
				: ''
		);
	}, [category?.id, category?.name]);

	return title;
};
