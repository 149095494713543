import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { $MentorService } from '../../providedServices';
import { CenterContentBar } from './shared/CenterContentBar';
import { MentorBarExtendable } from './shared/MentorBarExtendable';
import { createAccessiblePointerEventHandler } from '@knuddels/component-library';
import { MentorPointsProgressBar } from './MentorPointsProgressBar';
import { $ClientSettingsService } from '../../../Settings';
export const MentorMiniStatusBar: React.FC<{
  onPress?(): void;
}> = observer('MentorMiniStatusBar', props => {
  const clientSettingsService = useService($ClientSettingsService);
  const mentorService = useService($MentorService);
  const status = mentorService.mentorStatus;
  if (!status || status.__typename !== 'MentorStatusActive') {
    return null;
  }
  return <>
			<MentorBarExtendable activeStatus={status} mentorBarExtended={clientSettingsService.mentorBarExtended}>
				<CenterContentBar width={'100%'} {...createAccessiblePointerEventHandler({
        disabled: !props.onPress,
        pointerCallback: props.onPress
      })}>
					<MentorPointsProgressBar activeStatus={status} />
				</CenterContentBar>
			</MentorBarExtendable>
		</>;
});