import { LayoutInfo } from './LayoutInfo';

export function measureWindow(): LayoutInfo {
	return {
		x: 0,
		y: 0,
		width: window.innerWidth,
		height: window.innerHeight,
	};
}
