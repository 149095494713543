import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { $ViewProvider } from '@knuddels-app/layout';
import { GlobalAppViewProvider } from './GlobalAppViewProvider';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import { $I18n } from '@knuddels-app/i18n';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { featureFlagsModule } from '../FeatureFlags/module';
import {
	$SmileyTradeAppView,
	SmileyTradeAppView,
} from '@knuddelsModules/SystemApps/bundle/services';
import { appsModule } from '@knuddelsModules/Apps/module';
import { smileyTradeAppViewId } from './bundle/components/SmileyTrade/viewId';
import { SmileyTradeAppViewProvider } from './SmileyTradeAppViewProvider';
import { GenericSystemAppViewProvider } from '@knuddelsModules/SystemApps/GenericSystemAppViewProvider';
import { globalAppViewId } from './bundle/components/GlobalApp/viewId';

export const systemAppsModule = new Module({
	name: 'System app module',
	dependencies: [appsModule, notificationsModule, featureFlagsModule],
	providedServices,
	load: {
		from: () =>
			import(
				/* webpackChunkName: "systemApps" */ './bundle/registerServices'
			),
	},
	loadEvent: 'onLogin',
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(GlobalAppViewProvider);
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(SmileyTradeAppViewProvider);
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(GenericSystemAppViewProvider);

		ctx.loggedInScope
			.bind($SmileyTradeAppView)
			.toSingletonAutostart(SmileyTradeAppView);

		ctx.loggedInScope.bind($NavBarItemProvider).toDynamicSingleton(
			{
				i18n: $I18n,
			},
			() => ({
				getItems: () => [
					{
						id: 'smileyAuction',
						kind: 'view',
						view: smileyTradeAppViewId,
						trackingId: 'SmileyTrade',
					},
					{
						id: 'globalApps',
						kind: 'view',
						trackingId: 'GlobalApps_Overview',
						view: globalAppViewId,
					},
				],
			})
		);
	},
});
