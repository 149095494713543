import * as React from 'react';
import { uploadProfilePictureWithProgressModalByOTP } from '../sagas/profilePictureUpload/uploadProfilePictureWithProgressModal';
import { UploadProfilePictureProgressModal } from '../components/UploadModal/UploadProfilePictureProgressModal';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider, declareFormat, formatMessage } from '@knuddels-app/i18n';
import { $OverlayService } from '@knuddels-app/overlays';
import { SelectProfilePictureArgs } from '@knuddelsModules/Profile/bundle/sagas/profilePictureUpload/interfaces';
import { SelectFileResult } from '@knuddels-app/tools/filePicking';
import { ProfilePictureModal } from '@knuddelsModules/Profile/bundle/components/UploadModal/ProfilePictureModal';
import { Disposable } from '@knuddels/std';
@injectable()
export class LoggedOutProfileService {
  public readonly uploadProfilePictureWithProgressModalByOTP = uploadProfilePictureWithProgressModalByOTP;
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de: require('../i18n/formats.de.json'),
      en: require('../i18n/formats.en.json')
    } as any)[locale.language]);
  }
  public readonly showProgressModal = (): Disposable => {
    return this.overlayService.showOverlay({
      view: <UploadProfilePictureProgressModal />
    });
  };
  public readonly selectProfilePictureWithPreviewModal = (args: SelectProfilePictureArgs): Promise<SelectFileResult | undefined> => {
    args.onPreviewOpened();
    return new Promise(resolve => {
      this.overlayService.showOverlay({
        view: <ProfilePictureModal primaryButtonText={formatMessage(declareFormat({
          id: 'PROFILE_PICTURE_PREVIEW_BUTTON_PRIMARY',
          defaultFormat: 'Continue'
        }))} secondaryButtonText={formatMessage(declareFormat({
          id: 'PROFILE_PICTURE_PREVIEW_BUTTON_SECONDARY',
          defaultFormat: 'Change'
        }))} headline={formatMessage(declareFormat({
          id: 'PROFILE_PICTURE_PREVIEW_HEADLINE',
          defaultFormat: 'Your profile picture'
        }))} initialProfilePicUrl={args.initialPictureUrl} {...args} onFinished={resolve} />
      });
    });
  };
}