import { toKeyboardEvent as t } from "./toKeyboardEvent.js";
function n(o) {
  if (o)
    return (r) => {
      o(t(r));
    };
}
export {
  n as toKeyboardHandler
};
