const s = ", Comic Sans MS, sans-serif", o = {
  DEFAULT: "sans-serif",
  // fontWeight:'normal' => regular=400, 'bold' => semibold=600
  BASE: "Source Sans Pro" + s,
  // fontWeight:'normal' => regular=400, 'bold' => bold=700
  TITLE: "Dosis" + s
};
export {
  o as FONT_FAMILY
};
