export interface Serializer<T> {
	serialize(val: T): string | undefined;
	deserialize(val: string | undefined): T;
}

export const stringOrUndefinedSerializer: Serializer<string | undefined> = {
	serialize: val => val,
	deserialize: val => val,
};

export const booleanOrUndefinedSerializer: Serializer<boolean | undefined> = {
	serialize: val => (val ? '1' : undefined),
	deserialize: val => (val === undefined ? undefined : val === '1'),
};

export function createJsonSerializer<T>(options?: {
	defaultValue?: T;
}): Serializer<T | undefined>;
export function createJsonSerializer<T>(options: {
	defaultValue: T;
}): Serializer<T>;
export function createJsonSerializer<T>(
	options: { defaultValue?: T } = {}
): Serializer<T> {
	return {
		serialize: val => (typeof val === 'string' ? val : JSON.stringify(val)),
		deserialize: val => {
			if (val === undefined) {
				if ('defaultValue' in options) {
					return options.defaultValue;
				}
				return undefined;
			}
			try {
				return JSON.parse(val);
			} catch (e) {
				return val;
			}
		},
	};
}
