import { isAnimationControls as o } from "../../animation/utils/is-animation-controls.js";
import { isVariantLabel as r } from "./is-variant-label.js";
import { variantProps as t } from "./variant-props.js";
function a(i) {
  return o(i.animate) || t.some((n) => r(i[n]));
}
function f(i) {
  return !!(a(i) || i.variants);
}
export {
  a as isControllingVariants,
  f as isVariantNode
};
