import * as React from 'react';
import { Disposable } from '@knuddels/std';
import { inject, injectable, injectProps, declareProps, IModel, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $NativeBackgroundStore } from '../serviceIds';
import { TopBackgroundStyle } from '../interfaces';
import { autorun } from '@knuddels-app/mobx';
@injectable()
class NativeBackgroundWrapperModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: TopBackgroundStyle & {
    isInBackground?: boolean;
  }, @inject($NativeBackgroundStore)
  private readonly nativeBackgroundStore: typeof $NativeBackgroundStore.T) {
    // we don't unset the background because sometimes it was unset after the next view set the new background
    // moving the setting of the background in `componentDidMount` will fix this but delays setting the background which results in flickering
    this.dispose.track(autorun({
      name: 'change native background'
    }, () => {
      if (!this.props.isInBackground) {
        this.nativeBackgroundStore.setTopBackground(this.props.color, this.props.type);
      }
    }));
  }
}
export const NativeBackgroundWrapper = injectedComponent({
  name: 'NativeBackgroundWrapper',
  model: NativeBackgroundWrapperModel,
  props: declareProps<{
    children: React.ReactElement;
  }>()
}, ({
  children
}) => children);