import * as React from 'react';
import { useContext } from 'react';
import { autorun, observer, when } from '@knuddels-app/mobx';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { useService } from '@knuddels-app/DependencyInjection';
import { $AppService, $CustomPositionAppViewer, $ModalAppViewer, AppsWebview } from '@knuddelsModules/Apps';
import { BlockSkeleton, Flex, FlexCol, TITLE_BAR_HEIGHT, useTheme, toPointerHandler } from '@knuddels/component-library';
import { $ViewService, InsideOverlayViewContext, ViewId, ViewIdWithStateEffect, ViewState } from '@knuddels-app/layout';
import { RouterViewState } from '@knuddels-app/layout/k3Router';
import { $UserService } from '@knuddelsModules/UserData';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $NavBarRegistry } from '@knuddelsModules/LoggedInArea';
import { $SystemAppService } from '@knuddelsModules/SystemApps/providedServices';
import { $ScreenService } from '@knuddels-app/Screen';
import { globalAppViewId } from './GlobalApp/viewId';
import { useThemedNativeBackground } from '@knuddels-app/NativeBackground';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { $NativeBackgroundStore } from '@knuddels-app/NativeBackground/serviceIds';
const DefaultSkeleton = (): React.ReactElement => <div className={_c0}>
		<BlockSkeleton height={TITLE_BAR_HEIGHT} />

		<div className={_c1}>
			<BlockSkeleton height={'100%'} />
		</div>
	</div>;
const NOOP: any = {
  stack: [],
  pathItems: []
};
const getOverlay = <TState extends ViewState,>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>): {
  overlay: {
    close(): void;
  };
  state: RouterViewState<any>;
} | null => {
  try {
    if (!viewIdWithOptionalStateEffect) {
      return null;
    }
    const overlay = useContext(InsideOverlayViewContext);
    if (!overlay) {
      return null;
    }
    const viewService = useService($ViewService);
    const state = (viewService.getStateForOverlay(viewIdWithOptionalStateEffect) as any);
    return {
      overlay,
      state
    };
  } catch (e) {
    return null;
  }
};
const getRouterInfo = <T extends ViewId<RouterViewState<any>>,>(viewService: typeof $ViewService.T, navBarRegistry: typeof $NavBarRegistry.T, viewId: T | null, inOverlay: boolean) => {
  if (!viewId) {
    return null;
  }
  const state = inOverlay ? viewService.findViewInOverlay(viewId)?.state : viewService.findView(viewId)?.visibleView?.state;
  if (!state) {
    return NOOP;
  }
  return state;
};
const getDynamicSlotInfo = (navBarRegistry: typeof $NavBarRegistry.T, viewId: ViewId<RouterViewState<any>> | null) => {
  if (!viewId) {
    return false;
  }
  return navBarRegistry.viewInsideBottomNav(viewId);
};
export type ViewType = 'router' | 'subpath' | 'sidebar' | 'inline';
export enum EventType {
  OnAppReady = 'onAppReady',
  CloseApp = 'CLOSE_APP',
  RequestFocus = 'REQUEST_FOCUS',
  OverlayMount = 'OVERLAY_MOUNT',
  OverlayUnmount = 'OVERLAY_UNMOUNT',
  RouterUpdate = 'ROUTER_UPDATE',
  AfterRouterInit = 'AFTER_ROUTER_INIT',
}
type AppState = 'active' | 'background';
type GenericSystemAppProps = {
  appId: string;
  onAppClose?: () => void;
  onAppEvent?: (data: any) => void;
  skeleton?: React.ReactNode;
  viewId?: ViewId<RouterViewState<any>>;
  viewType?: ViewType;
  customSlashCommand?: string;
};
export const GenericSystemApp = observer('GenericSystemApp', (props: GenericSystemAppProps) => {
  const authenticatedClientService = useService($AuthenticatedClientService);
  if (authenticatedClientService.connectionState !== 'Authorized') {
    return <div className={_c2}>
					{props.skeleton ?? <DefaultSkeleton />}
				</div>;
  }
  return <InnerGenericSystemApp {...props} />;
});
const RETRY = 10;
const InnerGenericSystemApp: React.FC<GenericSystemAppProps> = observer('InnerGenericSystemApp', ({
  viewType = 'router',
  ...props
}) => {
  const timeoutRef = React.useRef<any>(null);
  const initTimeoutRef = React.useRef<any>(null);
  const openStateForModal = React.useRef<boolean>(undefined);
  const customPositionAppViewer = useService($CustomPositionAppViewer);
  const appService = useService($AppService);
  const viewService = useService($ViewService);
  const navBarRegistry = useService($NavBarRegistry);
  const userService = useService($UserService);
  const screenService = useService($ScreenService);
  const systemAppService = useService($SystemAppService);
  const modalAppViewer = useService($ModalAppViewer);
  const nativeBackgroundStore = useService($NativeBackgroundStore);
  const theme = useTheme();
  const initializeRetryTimeout = React.useRef(RETRY);
  useReactiveState(() => {
    if (props.viewId && viewService.isViewVisible(props.viewId)) {
      nativeBackgroundStore.setTopBackground(theme.colors.basic.contentBg, theme.id === 'dark' ? 'dark' : 'light');
    }
  }, [theme, props.viewId]);
  useThemedNativeBackground();
  const overlay = getOverlay(props.viewId);
  const appId = props.appId + (overlay ? ':overlay' : '');
  const app = customPositionAppViewer.getApp(appId);
  const [state, setState] = React.useState<'loading' | 'initialized'>('loading');
  const [routerInfo, setRouterInfo] = React.useState(() => {
    return getRouterInfo(viewService, navBarRegistry, props.viewId, !!overlay);
  });
  const insideDynamicSlot = getDynamicSlotInfo(navBarRegistry, props.viewId);
  const showCloseButton = !insideDynamicSlot || !!overlay;
  const context = React.useMemo(() => {
    return {
      routerInfo: routerInfo,
      showCloseButton: showCloseButton,
      viewType,
      initialOrientation: screenService.orientation,
      initialAppState: overlay ? 'active' : ((props.viewId ? viewService.isViewVisible(props.viewId) ? 'active' : 'background' : 'active') as AppState)
    };
  }, []);
  const execute = useSlashCommandWithoutChannel({
    disableClientSideCommandHandling: true
  });
  const initializeApp = () => {
    setState('loading');
    const command = props.customSlashCommand ? props.customSlashCommand.startsWith('/') ? props.customSlashCommand : `/${props.customSlashCommand}` : `/opensystemapp ${appId}`;
    execute(command);
    initTimeoutRef.current = setTimeout(() => {
      initializeRetryTimeout.current = Math.max(RETRY ** 2, 360);
      initializeApp();
    }, initializeRetryTimeout.current * 1000);
  };
  React.useEffect(() => {
    if (state === 'initialized') {
      clearTimeout(initTimeoutRef.current);
    }
  }, [state]);
  React.useEffect(() => {
    return () => {
      clearTimeout(initTimeoutRef.current);
    };
  }, []);
  React.useEffect(() => {
    if (!props.viewId && viewType === 'router') {
      throw new Error('viewId is required for viewType "router"');
    }
    if (viewType !== 'router') {
      return;
    }
    return autorun({
      name: 'View Updates'
    }, () => {
      const newRouterInfo = getRouterInfo(viewService, navBarRegistry, props.viewId, !!overlay);
      if (!newRouterInfo) {
        return;
      }
      setRouterInfo(newRouterInfo);
    });
  }, [props.viewId]);
  React.useEffect(() => {
    if (!app || !props.viewId || overlay) {
      return;
    }
    return autorun({
      name: 'AppState Change'
    }, () => {
      const appState: AppState = viewService.isViewVisible(props.viewId) && !viewService.isViewInOverlay(props.viewId) || props.viewId === globalAppViewId && modalAppViewer.getAllApps().some(it => it.isGlobalApp) ? 'active' : 'background';
      app.updateVisibleStatus(appState);
    });
  }, [app, props.viewId]);
  React.useEffect(() => {
    if (!props.viewId) {
      return;
    }
    return appService.registerAppCloseListener(appId, () => {
      viewService.closeView(props.viewId);
    });
  }, [props.viewId, appId]);
  const routerStack = routerInfo?.stack ?? [];
  React.useEffect(() => {
    const unsub = appService.registerEventHandler(appId, event => {
      if (event.type === EventType.OnAppReady) {
        setState('initialized');
        return;
      }
      if (event.type === EventType.RequestFocus && !overlay) {
        clearTimeout(timeoutRef.current);
        const isOpen = viewService.isViewVisible(props.viewId);

        /**
         * determine the open state on first open request
         * subsequent modal will use the same state
         * so a closing request can determine whether to navigate back to the previous view
         */
        systemAppService.setOverlayOpened(true);
        if (openStateForModal.current === undefined) {
          openStateForModal.current = isOpen;
        }
        if (props.viewId && !isOpen) {
          viewService.openView(props.viewId, {
            locationUpdateMode: 'replace'
          });
        }
        return;
      }
      if (event.type === EventType.OverlayUnmount) {
        /**
         * account for navigation between two overlays in the same app
         */
        timeoutRef.current = setTimeout(() => {
          if (openStateForModal.current === false) {
            viewService.navigateBack();
          }
          systemAppService.setOverlayOpened(false);
          openStateForModal.current = undefined;
        }, 100);
        return;
      }
      if (event.type === EventType.CloseApp) {
        if (overlay) {
          overlay.overlay.close();
          return;
        }
        props.onAppClose?.();
        return;
      }
      if (viewType === 'router' && (event.type === EventType.AfterRouterInit || event.type === EventType.RouterUpdate)) {
        const replace = event.type === EventType.AfterRouterInit;
        if (overlay) {
          viewService.openViewAsOverlay(props.viewId.with(s => s.withStack(event.stack)), replace ? 'replace' : 'push', !!overlay);
          return;
        }
        if (!viewService.isViewVisible(props.viewId)) {
          viewService.updateLatestViewState(props.viewId, s => s.withStack(event.stack));
          return;
        }
        viewService.openView(props.viewId.with(s => s.withStack(event.stack)), {
          locationUpdateMode: replace ? 'replace' : 'push'
        });
        return;
      }
      props.onAppEvent?.(event);
    });
    setState('loading');
    customPositionAppViewer.registerApp(appId, props.viewId);
    when({
      name: 'Waiting for subscription connected'
    }, () => [userService.currentUser, appService.initialized], {
      resolveTest: s => !!s[0] && !!s[1]
    }).then(() => {
      initializeApp();
    });
    return () => {
      if (!overlay) {
        customPositionAppViewer.closeApp(appId);
      }
      unsub();
    };
  }, [appId]);
  React.useEffect(() => {
    if (!app || !routerStack) {
      return;
    }
    if (viewType !== 'router') {
      app.sendEventToWebview('routerEvent', {
        type: 'ROUTER_PATH_CHANGED',
        stack: [],
        showCloseButton: showCloseButton
      });
      return;
    }
    if (routerStack.length === 0) {
      return;
    }
    app.sendEventToWebview('routerEvent', {
      type: 'ROUTER_PATH_CHANGED',
      stack: routerStack,
      showCloseButton: showCloseButton
    });
  }, [app, routerStack, insideDynamicSlot]);
  if (!app) {
    return <>{props.skeleton ?? <DefaultSkeleton />}</>;
  }
  return <div className={_c3}>
				{state !== 'initialized' && <div className={_c4}>
						{props.skeleton ?? <DefaultSkeleton />}
					</div>}
				<div onClick={toPointerHandler(e => e.stopPropagation())} className={_c5}>
					<AppsWebview appInstance={app} context={context} />
				</div>
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 height-full width-full gap-base ";
const _c1 = " Knu-FlexCol flex-1 width-full p-base ";
const _c2 = " Knu-FlexCol height-100-percent flex-1 ";
const _c3 = " Knu-FlexCol flex-1 width-full position-relative ";
const _c4 = " Knu-Flex position-absolute inset-none ";
const _c5 = " Knu-Flex flex-1 ";