import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { Spacer } from '@shared/components';
import { Flex, IconCheckmark, Text, createAccessiblePointerEventHandler, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export const AcceptRequestButton: React.FC<{
  disabled?: boolean;
  onPress: () => void;
}> = ({
  disabled,
  onPress
}) => {
  // // The touchableStyle within an animated view will cause the layout to
  // // break here.
  // if ('touchableStyle' in pointerHandlerProps) {
  // 	pointerHandlerProps['touchableStyle' as any] = undefined as never;
  // }

  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress,
    disabled
  })} className={_c0 + ("rgba(71,209,179,1)" ? resolveIsDarkColor("rgba(71,209,179,1)", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (disabled ? _c1 : _c2)}>
			<div className={_c3 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<IconCheckmark size={'base'} />
				<Spacer size={'small'} />
				<Text type={'body2'} bold={true} className={_c4}>
					<FormattedMessage id={declareFormat({
          id: 'friendrequests.received.confirm',
          defaultFormat: 'CONFIRM'
        })} />
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-Flex cursor-pointer height-44px borderRadius-22px bg-rgba-71-209-179-1- px-large flex-1 ";
const _c1 = " cursor-default ";
const _c2 = " cursor-pointer ";
const _c3 = " Knu-Flex bg-transparentDark placeItems-center ";
const _c4 = "  ";