import { newServiceId, ServiceId } from '@knuddels-app/DependencyInjection';

/**
 * An AppLoadingProvider is responsible for delaying the app-start in order to load data required for the app to work.
 */
interface AppLoadingProvider<T> {
	serviceId: ServiceId<T>;
	load(service: T): Promise<void>;
}

export const $AppLoadingProvider = newServiceId<AppLoadingProvider<unknown>>(
	'$AppLoadingProvider'
);

export function simpleAppLoadingProvider<T>(
	serviceId: ServiceId<T>,
	resolver: (service: T) => Promise<void>
): AppLoadingProvider<T> {
	return {
		serviceId,
		async load(service: T): Promise<void> {
			return await resolver(service);
		},
	};
}
