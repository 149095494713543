import * as React from 'react';
import { $UserDataComponents, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { StatusBadgeSizes } from '@shared/components';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { BoxProps, createAccessiblePointerEventHandler, FlexCol, ThemeColors, ThemeOverride, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
export const EntryImage = injectedComponent({
  name: 'EntryImage',
  props: declareProps<{
    userImageUserId?: string;
    isStAppMessengerOverview: boolean;
    bg?: string | ThemeColors;
    onClick?(): void;
  }>(),
  inject: {
    UserDataComponents: $UserDataComponents
  }
}, ({
  isStAppMessengerOverview,
  userImageUserId,
  UserDataComponents,
  onClick,
  bg
}) => {
  return <div
  // @ts-expect-error Not exposed
  {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onClick
  })} style={{
    ...styles.imageWrapper(isStAppMessengerOverview)
  }} className={_c0}>
				<UserImage uid={userImageUserId} highlight={'fancy'} type={isStAppMessengerOverview ? UserImageType.STAPP_SIDEBAR_BIG : UserImageType.MESSENGER} onlineBadge={userImageUserId && <UserDataComponents.UserOnlineStatusBadge userId={userImageUserId} size={StatusBadgeSizes.NORMAL} parentBg={bg} />} />
			</div>;
});
const styles = {
  imageWrapper: (isStAppMessengerOverview: boolean) => isStAppMessengerOverview ? ({
    overflow: 'visible',
    marginTop: (10 as ThemeOverride),
    marginBottom: (12 as ThemeOverride)
  } as BoxProps) : ({
    overflow: 'visible',
    marginTop: (16 as ThemeOverride),
    marginBottom: (22 as ThemeOverride)
  } as BoxProps)
};
const _c0 = " Knu-FlexCol cursor-pointer ";