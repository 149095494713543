import { createTrackingEventType } from '@knuddels-app/analytics/eventCreators';
import { Category } from '@knuddels-app/analytics/Category';
import { createUserTimingTracker } from '@knuddels-app/analytics/userTimingTracking';
import { eventLabel } from './utils/timeTracking';

export const communicationRequestBoxEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_CommunicationRequestBox'
);

export const mentorBoxEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_MentorBox'
);

export const conversationTitleBarEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_Titlebar'
);

export const conversationInputEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_InputBar'
);

export const forwardMessageEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_ForwardMessage'
);

export const conversationOverviewMenuEvent = createTrackingEventType(
	Category.MessengerConversationOverview,
	'Conversationoverview_ConversationMenu'
);

export const conversationOverviewTitleBarEvent = createTrackingEventType(
	Category.MessengerConversationOverview,
	'Conversationoverview_Titlebar'
);

export const conversationOverviewOpenConversationEvent = createTrackingEventType(
	Category.MessengerConversationOverview,
	'Conversationoverview_OpenConversation'
);

export const conversationOverviewNotificationBoxEvent = createTrackingEventType(
	Category.MessengerConversationOverview,
	'Conversationoverview_NotificationrequestBox'
);

export const conversationMessageMenuEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_MessageMenu'
);

export const conversationStateEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_Conversationstate'
);

export const conversationImagesEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_Images'
);

export const conversationProfileImageEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_ProfileImage'
);

export const conversationArchiveOverviewEvent = createTrackingEventType(
	Category.MessengerConversationArchive,
	'ConversationArchive_ArchiveOverview'
);

export const conversationArchiveTitleBarEvent = createTrackingEventType(
	Category.MessengerConversationArchive,
	'ConversationArchive_Titlebar'
);

export const appletSideBarOpenConversaionEvent = createTrackingEventType(
	Category.MessengerAppletSidebar,
	'AppletSidebar_OpenConversation'
);

export const newConversationSearchResultEvent = createTrackingEventType(
	Category.MessengerNewConversation,
	'NewConversation_SearchResult'
);

export const newConversationTitleBarEvent = createTrackingEventType(
	Category.MessengerNewConversation,
	'NewConversation_Titlebar'
);

export const newConversationSearchBarEvent = createTrackingEventType(
	Category.MessengerNewConversation,
	'NewConversation_Searchbar'
);

export const newConversationContactOverviewEvent = createTrackingEventType(
	Category.MessengerNewConversation,
	'NewConversation_Contactoverview'
);

export const markedMessagesTitleBarEvent = createTrackingEventType(
	Category.MessengerMarkedMessages,
	'MarkedMessages_Titlebar'
);

export const markedMessagesOverviewEvent = createTrackingEventType(
	Category.MessengerMarkedMessages,
	'MarkedMessages_MarkedMessagesOverview'
);

export const sendImageEvent = createTrackingEventType(
	Category.MessengerConversation,
	'Conversation_SendImage'
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const conversationOpenedTracker = createUserTimingTracker(
	eventLabel,
	'ConversationOpened'
);
export const newConversationVisibleTracker = createUserTimingTracker(
	eventLabel,
	'newConversationVisible'
);
export const moreConversationsLoadedTracker = createUserTimingTracker(
	eventLabel,
	'MoreConversations'
);
export const moreMessagesLoadedTracker = createUserTimingTracker(
	eventLabel,
	'MoreMessages'
);
export const contactsAvailableTracker = createUserTimingTracker(
	eventLabel,
	'ContactsAvailable'
);
export const conversationListLoadedTracker = createUserTimingTracker(
	eventLabel,
	'ConversationListLoaded'
);
