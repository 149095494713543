import { jsx as l, jsxs as L } from "react/jsx-runtime";
import f from "react";
import { ThemeProvider as w } from "styled-components";
import { LightTheme as P } from "./Light.js";
import { GlobalStyle as M, CustomScrollBarStyles as E } from "./GlobalStyle.js";
const I = (r, t, n, s = 1) => `rgba(${r}, ${t}, ${n}, ${s})`, B = f.createContext(P), D = f.createContext(null), N = (r, t) => {
  const n = f.useMemo(() => {
    var d, u, i, b, v, m, $, y, x, j, p, h, k, O, g, C, z;
    const s = {};
    for (const [e, o] of Object.entries(
      ((d = r.colors) == null ? void 0 : d.basic) ?? {}
    ))
      s[`--colors-${e}`] = o;
    for (const [e, o] of Object.entries(
      ((u = r.sizes) == null ? void 0 : u.box) ?? {}
    ))
      s[`--sizes-${e}`] = o;
    for (const [e, o] of Object.entries(
      ((i = r.sizes) == null ? void 0 : i.icons) ?? {}
    ))
      s[`--sizes-icons-${e}`] = o + "px";
    for (const [e, o] of Object.entries(r.spacings ?? {}))
      s[`--spacing-${e}`] = o + "px";
    for (const [e, o] of Object.entries(
      ((v = (b = r.colors) == null ? void 0 : b.texts) == null ? void 0 : v.default) ?? {}
    ))
      s[`--text-colors-${e}`] = o;
    for (const [e, o] of Object.entries(
      (($ = (m = r.colors) == null ? void 0 : m.texts) == null ? void 0 : $.alternate) ?? {}
    ))
      s[`--text-colors-${e}-alternate`] = o;
    for (const [e, o] of Object.entries(
      ((x = (y = r.colors) == null ? void 0 : y.icons) == null ? void 0 : x.alternate) ?? {}
    ))
      s[`--icons-colors-${e}-alternate`] = o;
    for (const [e, o] of Object.entries(
      ((j = r.colors) == null ? void 0 : j.icons.default) ?? {}
    ))
      s[`--icons-colors-${e}`] = o;
    for (const [e, o] of Object.entries(
      ((p = r.sizes) == null ? void 0 : p.borderRadius) ?? {}
    ))
      s[`--borderRadius-${e}`] = o + (e === "circle" ? "" : "px");
    for (const [e, o] of Object.entries(
      ((h = r.sizes) == null ? void 0 : h.border) ?? {}
    ))
      s[`--borderWidth-${e}`] = o + "px";
    for (const [e, o] of Object.entries(
      ((O = (k = r.colors) == null ? void 0 : k.Shadow) == null ? void 0 : O.onLight) ?? {}
    ))
      s[`--shadows-onLight-${e}`] = o.literal;
    for (const [e, o] of Object.entries(
      ((C = (g = r.colors) == null ? void 0 : g.Shadow) == null ? void 0 : C.onDark) ?? {}
    ))
      s[`--shadows-onDark-${e}`] = o.literal;
    for (const [e, o] of Object.entries(
      ((z = r.sizes) == null ? void 0 : z.texts) ?? {}
    ))
      for (const [R, S] of Object.entries(o)) {
        const K = typeof S == "number" ? "px" : "";
        s[`--${R}-${e}`] = S + K;
      }
    for (const [e, o] of Object.entries(
      r.aspectRatio ?? {}
    ))
      s[`--aspectRatio-${e}`] = o;
    const a = "Theme-" + (Math.random() + 1).toString(36).substring(7), c = t.document.createElement("style");
    return c.innerHTML = `.${a} { ${Object.entries(s).map(([e, o]) => `${e}: ${o};`).join(`
`)} }`, t.document.head.append(c), c.id = `styles-${a}`, a;
  }, [r]);
  return f.useEffect(() => () => {
    var s;
    (s = t.document.getElementById(`styles-${n}`)) == null || s.remove();
  }, [n]), n;
}, T = ({
  children: r,
  defaultTheme: t = P,
  parentWindow: n,
  useCustomScrollBars: s = !0,
  ...a
}) => {
  const [c, d] = f.useState(t), u = f.useMemo(() => ({ setTheme: d, currentTheme: c }), [d, c]), i = a.theme ?? c, b = N(i, n ?? window);
  return /* @__PURE__ */ l(D.Provider, { value: u, children: /* @__PURE__ */ l(B.Provider, { value: i, children: /* @__PURE__ */ L(w, { theme: i, children: [
    /* @__PURE__ */ l(M, {}),
    s && /* @__PURE__ */ l(
      E,
      {
        scrollBarThumbColor: i.colors.basic.scrollbarThumbColor,
        scrollbarBackgroundColor: i.colors.basic.scrollbarBackgroundColor
      }
    ),
    /* @__PURE__ */ l(
      "div",
      {
        className: b + " Knu-Flex size-full minHeight-0",
        children: r
      }
    )
  ] }) }) });
}, q = ({ children: r, styles: t, overrideVariables: n, theme: s, parentWindow: a }) => {
  const c = N(
    n ?? s,
    a ?? window
  );
  return /* @__PURE__ */ l(B.Provider, { value: s, children: /* @__PURE__ */ l(w, { theme: s, children: /* @__PURE__ */ l(
    "div",
    {
      className: c + " Knu-Flex",
      style: t || { display: "contents" },
      children: r
    }
  ) }) });
};
export {
  q as ChildThemeProvider,
  B as ThemeContext,
  T as ThemeProvider,
  D as ThemeSwitcherContext,
  I as rgb
};
