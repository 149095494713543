import * as React from 'react';
import { MenteeStatus } from '@generated/graphql';
import { Flex, Image, TextProps, PointerEvent, toPointerHandler } from '@knuddels/component-library';
import { TextWithIcons } from './TextWithIcons';
import { Spacer } from '@shared/components';
import { IconInfoUser, useAuthenticityIconComponent } from './AuthenticityIcon';
import { SheepIcon } from '@shared/icons/Sheep';
import { userIsJames } from '@shared/helper/user';
export const NickWithIcons: React.FC<{
  user: IconInfoUser;
  state?: TextProps['state'];
  type?: TextProps['type'];
  bold?: boolean;
  showNickSwitchIcon?: boolean;
  hideNegativeAuthenticityStates?: boolean;
  onAuthenticityIconPress?: (e: PointerEvent) => void;
  onNickPress?: () => void;
}> = React.memo(({
  user,
  state,
  type,
  bold,
  showNickSwitchIcon,
  hideNegativeAuthenticityStates,
  onAuthenticityIconPress,
  onNickPress
}) => {
  const isMentee = user.menteeStatus === MenteeStatus.Mentee;
  let iconWidth = 0;
  if (isMentee) {
    iconWidth += 16;
  }
  const {
    Component: AuthenticityIcon,
    iconWidth: authenticityIconWidth
  } = useAuthenticityIconComponent(user, hideNegativeAuthenticityStates);
  if (AuthenticityIcon) {
    if (iconWidth > 0) {
      iconWidth += 4;
    }
    iconWidth += authenticityIconWidth;
  }
  if (showNickSwitchIcon) {
    if (iconWidth > 0) {
      iconWidth += 4;
    }
    iconWidth += 19;
  }
  return <TextWithIcons text={user.nick} state={state} type={type} bold={bold} iconsWidth={iconWidth} onTextPress={onNickPress}>
				{isMentee && <SheepIcon />}
				{isMentee && AuthenticityIcon && <Spacer size={'tiny'} />}
				{AuthenticityIcon && <div onClick={toPointerHandler(user.isAppBot || userIsJames(user) ? onNickPress : onAuthenticityIconPress)} className={_c0 + (onAuthenticityIconPress ? _c1 : _c2)}>
						<AuthenticityIcon />
					</div>}
				{(isMentee || AuthenticityIcon) && showNickSwitchIcon ? <Spacer size={'tiny'} /> : null}
				{showNickSwitchIcon && <Image src={require('@shared/icons/nickswitch.png')} alt={''} className={_c3} />}
			</TextWithIcons>;
});
NickWithIcons.displayName = 'NickWithIcons';
const _c0 = " Knu-Flex ";
const _c1 = " cursor-pointer ";
const _c2 = " cursor-default ";
const _c3 = " width-19px height-13px ";