import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { CommunityStatus } from '@generated/graphql';

const RANKS: CommunityStatus[] = [
	CommunityStatus.Normal,
	CommunityStatus.Family,
	CommunityStatus.Stammi,
	CommunityStatus.Honor,
	CommunityStatus.UnofficialAdmin,
	CommunityStatus.Admin,
	CommunityStatus.Sysadmin,
];

export const COMMANDS: Record<
	string,
	{
		/**
		 * The type of the autocomplete. If `none` is specified, no autocomplete will be shown for the first argument.
		 */
		autocompleteType: 'channel' | 'user' | 'none';
		/**
		 * If true, the command has free parameters. Which means a : will be added
		 * after the autocompleted command.
		 */
		hasFreeParams?: boolean;
		/**
		 * A description of the command. Shown to the user in the autocomplete.
		 */
		description: string;
		/**
		 * If false, the command will not be shown in the autocomplete suggestions.
		 * However, autocompletion of channel or user names will still work.
		 */
		showInSuggestions?: boolean;
		minRank?: CommunityStatus;
		parameterRequired?: boolean;
	}
> = {
	go: {
		autocompleteType: 'channel',
		parameterRequired: true,
		description: 'Wechsele in einen anderen Channel!',
	},
	ct: {
		autocompleteType: 'none',
		description: 'Alle Infos zum Chattertreffen!',
	},
	w: {
		autocompleteType: 'user',
		description: 'Öffne das Profil eines Mitglieds!',
	},
	pet: {
		autocompleteType: 'none',
		description: 'Schau wie es deinem Hausknuddel geht!',
	},
	kiss: {
		autocompleteType: 'user',
		description: 'Gib einem Mitglied einen Kuss!',
	},
	tb: {
		autocompleteType: 'none',
		description: 'Entdecke und tausche coole Smileys!',
	},
	ig: { autocompleteType: 'user', description: 'Ignoriere einen Mitglied!' },
	'top distance': {
		autocompleteType: 'none',
		description: 'Zeige alle Mitglieder sortiert zu deiner Entfernung an',
	},
	edit: {
		autocompleteType: 'none',
		description: 'Ändere dein Profl!',
	},
	me: {
		autocompleteType: 'none',
		description: 'Zeige anderen was du gerade machst!',
	},
	d: {
		autocompleteType: 'none',
		description: 'Rolle einen Würfel!',
	},
	away: {
		autocompleteType: 'none',
		description: 'Teile anderen mit, dass du gerade nicht da bist!',
	},
	block: {
		autocompleteType: 'user',
		description: 'Blockiere einen Mitglied!',
		showInSuggestions: false,
	},
	rose: {
		autocompleteType: 'user',
		description: 'Schenke jemandem eine Rose!',
	},
	garden: {
		autocompleteType: 'user',
		description: 'Schau dir deinen Knuddelsgarten an!',
	},
	knuddel: {
		autocompleteType: 'user',
		description: 'Schenke jemandem einen Knuddel!',
	},
	back: {
		autocompleteType: 'none',
		description: 'Teile dem Chat mit, dass du wieder da bist',
	},
	echtheit: {
		autocompleteType: 'user',
		description: 'Schau wie echt ein Mitglied ist!',
	},
	hilfe: {
		autocompleteType: 'none',
		description: 'Finde Antworten auf all deine Fragen!',
	},
	buyknuddel: {
		autocompleteType: 'none',
		description: 'Öffne den Knuddelkauf!',
	},
	m: {
		autocompleteType: 'user',
		hasFreeParams: true,
		description: 'Sende eine private Nachricht!',
		showInSuggestions: false,
	},
	p: {
		autocompleteType: 'user',
		hasFreeParams: true,
		description: 'Sende eine private Nachricht!',
	},
	worldtour: {
		autocompleteType: 'none',
		description: 'Spiele eine Runde Weltreise',
	},
	applist: {
		autocompleteType: 'none',
		description: 'Entdecke Apps und Spiele!',
	},
} as const;

export const ALL_COMMANDS = Object.keys(COMMANDS).sort();
const AUTOCOMPLETE_COMMANDS = ALL_COMMANDS.filter(
	c => COMMANDS[c].showInSuggestions !== false
);

export const getPossibleAutocompleteCommands = (
	currentRank: CommunityStatus
) => {
	const ownRankIndex = RANKS.indexOf(currentRank);
	return AUTOCOMPLETE_COMMANDS.filter(c => {
		const command = COMMANDS[c];
		return (
			command.minRank === undefined ||
			ownRankIndex >= RANKS.indexOf(command.minRank)
		);
	});
};

export class SlashTypeaheadOption extends MenuOption {
	constructor(
		public command: string,
		public data: typeof COMMANDS[keyof typeof COMMANDS]
	) {
		super(command);
	}

	toString(): string {
		return this.command;
	}
}
