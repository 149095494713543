import * as React from 'react';
import { Spacer } from '@shared/components';
import { ScrollView } from '@knuddels/component-library';
import { useNavigate } from '../SettingsRouter';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { SettingsAppInfo } from './SettingsAppInfo';
import { AdsSettings } from './AdsSettings';
import { GeneralSettings } from './GeneralSettings';
import { SectionsSettings } from './SectionsSettings';
import { LegalSettings } from './LegalSettings';
import { SettingsNavigateFunction } from '../../../SettingsRouterConfig';
import { SupportSettings } from './SupportSettings';
export const SettingsOverview: React.FC<{
  shouldShowChannelSetting: boolean;
  shouldShowAccountSettings: boolean;
  navigate?: SettingsNavigateFunction;
}> = ({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  navigate = useNavigate(),
  shouldShowChannelSetting,
  shouldShowAccountSettings
}) => {
  const track = useLogEvent();
  React.useEffect(() => {
    track('Settings_Overview', 'Opened');
  }, []);
  return <div className={_c0}>
			<GeneralSettings shouldShowAccountSettings={shouldShowAccountSettings} navigate={navigate} />
			<SectionsSettings shouldShowChannelSetting={shouldShowChannelSetting} navigate={navigate} />
			{globalEnv.product !== 'stapp-messenger' && <SupportSettings />}
			<AdsSettings />
			<LegalSettings navigate={navigate} />
			<Spacer size={'xxlarge'} />
			<SettingsAppInfo />
			<Spacer size={'xxxlarge'} />
		</div>;
};
const _c0 = " Knu-ScrollView ";