import { jsx as c } from "react/jsx-runtime";
import * as u from "react";
import { AnimationConfig as o } from "./AnimationConfig.js";
import "./Primitives.scss.js";
import { clsx as d } from "../../node_modules/clsx/dist/clsx.m.js";
const f = (n) => {
  const t = u.useRef();
  return u.useEffect(() => {
    if (!(!t.current || !n))
      return t.current.addEventListener("transitionend", n), () => {
        var a;
        return (a = t.current) == null ? void 0 : a.removeEventListener("transitionend", n);
      };
  }, []), t;
}, x = ({ rotated: n, from: t = 0, to: a, delay: l = 0, onFinished: r, children: i, ...e }) => {
  const s = f(r);
  return /* @__PURE__ */ c(
    "div",
    {
      ref: s,
      ...e,
      className: d("Knu-Rotate", e.className),
      style: {
        "--rotation": (n ? a : t) + "deg",
        "--time": o.snappy.time + "ms",
        "--delay": l + "ms",
        ...e.style
      },
      children: i
    }
  );
}, T = ({
  visible: n,
  duration: t,
  from: a = 0,
  to: l = 1,
  delay: r = 0,
  onFinished: i,
  ...e
}) => {
  const s = f(i);
  return /* @__PURE__ */ c(
    "div",
    {
      ref: s,
      ...e,
      style: {
        "--opacity": n ? l : a,
        "--time": (t ?? o.snappy.time) + "ms",
        "--delay": r + "ms",
        ...e.style
      },
      className: d("Knu-Fade", e.className),
      children: e.children
    }
  );
}, C = ({
  from: n = 0,
  to: t = 1,
  delay: a = 0,
  duration: l,
  onFinished: r,
  scaled: i,
  ...e
}) => {
  const s = f(r);
  return /* @__PURE__ */ c(
    "div",
    {
      ref: s,
      ...e,
      style: {
        "--scale": i ? t : n,
        "--time": (l ?? o.snappy.time) + "ms",
        "--delay": a + "ms",
        ...e.style
      },
      className: d("Knu-Scale", e.className),
      children: e.children
    }
  );
}, E = y("Knu-TranslateX"), F = y("Knu-TranslateY");
function y(n) {
  return ({
    from: t = 0,
    to: a,
    delay: l = 0,
    duration: r,
    onFinished: i,
    translated: e,
    ...s
  }) => {
    const h = f(i);
    let m = e ? a : t;
    return typeof m == "number" && (m = `${m}px`), /* @__PURE__ */ c(
      "div",
      {
        ref: h,
        ...s,
        style: {
          "--translation": m,
          "--time": (r ?? o.snappy.time) + "ms",
          "--delay": l + "ms",
          ...s.style
        },
        className: d(n, s.className),
        children: s.children
      }
    );
  };
}
export {
  T as Fade,
  x as Rotate,
  C as Scale,
  E as TranslateX,
  F as TranslateY
};
