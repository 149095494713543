import * as React from 'react';
import { ConversationListFilterType, MessengerConversation, MessengerConversationState } from '@generated/graphql';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $MessengerListService } from '@knuddelsModules/Messenger';
import { MessengerList } from '@knuddelsModules/Messenger/bundle/components/MessengerList/MessengerList';
export const OverviewMessengerList: React.FC<{
  isMinifiedOverview: boolean;
  openConversation: (conversationId: MessengerConversation['id'], index: number) => void;
}> = observer('OverviewMessengerList', ({
  isMinifiedOverview,
  openConversation
}) => {
  const clientSettingsService = useService($ClientSettingsService);
  const filterByState = clientSettingsService.conversationListFilterType === ConversationListFilterType.UnreadMessages ? MessengerConversationState.Unread : MessengerConversationState.NotArchived;
  const messengerListService = useService($MessengerListService);
  const query = filterByState === MessengerConversationState.Unread ? messengerListService.messengerUnreadQuery : messengerListService.messengerOverviewQuery;
  return <MessengerList openConversation={openConversation} isArchive={false} conversations={filterByState === MessengerConversationState.Unread ? messengerListService.unreadConversationsAndActiveConversation : messengerListService.overviewConversations} fetchMore={query.fetchMoreConversations} fetchStatus={query.fetchStatus} isMinifiedOverview={isMinifiedOverview} />;
});