import * as React from 'react';
import { $ContactsService, ContactsTab, ContactsUser } from '@knuddelsModules/Contacts';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { EmptyContactsPlaceholder } from './EmptyContactsPlaceholder';
import { ContactsVirtualList } from './ContactsVirtualList';
import { FlexCol } from '@knuddels/component-library';
export const ContactsTabView: React.FC<{
  tab: ContactsTab;
  onContactClicked: (contact: ContactsUser, index: number) => void;
  header?: React.ReactElement;
}> = observer('ContactsTabView', ({
  tab,
  onContactClicked,
  header
}) => {
  const contactsService = useService($ContactsService);
  const contacts = contactsService.getContactsForTab(tab);
  if (contacts.length === 0) {
    return <div className={_c0}>
				<EmptyContactsPlaceholder tab={tab} />
			</div>;
  }
  return <ContactsVirtualList contextMenuType={tab} contacts={contacts} header={header} onContactClicked={onContactClicked} />;
});
const _c0 = " Knu-FlexCol flex-1 ";