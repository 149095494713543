import * as React from 'react';
import { ChannelPreviewMembersFragment, ColorFragment } from '@generated/graphql';
import { ChannelThumbnail } from './ChannelThumbnail';
import { Flex, FlexCol, LineDivider, Text, ThemeOverride, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { os, OS } from '@shared/components/tools/os';
import { ChannelListContacts } from './ChannelListContacts';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { observer } from '@knuddels-app/mobx';
import { ActiveChannelIndicator } from '../../../shared/ActiveChannelIndicator';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
type ChannelListItemProps = {
  id: string;
  name: string;
  userCount: number;
  backgroundColor?: ColorFragment;
  channelImage?: string;
  previewMembers?: ChannelPreviewMembersFragment;
  onEntryClick: (id: string) => void;
  onExpanderClick?: (id: string) => void;
  hasSubChannels?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
};
export const SmallChannelListItem: React.FC<ChannelListItemProps> = observer('SmallChannelListItem', props => {
  const [isActive, setIsActive] = React.useState(false);
  const activeChannelService = useService($ActiveChannelService);
  const bgColor = colorToRgbaString(props.backgroundColor) || 'transparent';
  React.useEffect(() => {
    const activeChannelId = activeChannelService.activeChannel?.id.split(':')[0];
    setIsActive(activeChannelId === props.id);
  }, [activeChannelService.activeChannel?.id, props.id]);
  const friends = props.previewMembers?.friends ?? [];
  const watchlist = props.previewMembers?.watchlist ?? [];
  const style = os === OS.web ? {
    overflowWrap: 'anywhere'
  } : {};
  return <div onClick={() => props.onEntryClick(props.id)} style={{
    borderTopWidth: props.isFirst ? 1 : 0,
    borderBottomWidth: props.isLast ? 1 : 0
  }} className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "") + (props.isFirst ? _c1 : _c2) + (props.isFirst ? _c3 : _c4) + (props.isLast ? _c5 : _c6) + (props.isLast ? _c7 : _c8)}>
				<div className={_c9}>
					<ChannelThumbnail src={props.channelImage} alt={props.name} userCount={props.userCount} backgroundColor={bgColor} type={'small'} />
					<div className={_c10}>
						<div className={_c11}>
							<Text type="subtitle" numberOfLines={1}
          // @ts-expect-error Not exposed
          style={{
            ...style
          }} className={_c12}>
								{props.name}
							</Text>
							{isActive && <ActiveChannelIndicator />}
						</div>

						{friends.length > 0 && <div className={_c13}>
								<div className={_c14}>
									<ChannelListContacts friends={friends} watchlist={watchlist} others={[]} align={'right'} />
								</div>
							</div>}
					</div>
				</div>
				{!props.isLast && <div className={_c15}>
						<LineDivider className={_c16} />
					</div>}
			</div>;
});
const _c0 = " Knu-FlexCol position-relative mx-base overflow-hidden bg-contentLightBg hoverBg-listHoverAtom borderWidth-base borderStyle-solid borderColor-lineDivider cursor-pointer ";
const _c1 = " borderTop-base ";
const _c2 = " borderTop-none ";
const _c3 = " borderTopRadius-xlarge ";
const _c4 = " borderTopRadius-none ";
const _c5 = " borderBottom-base ";
const _c6 = " borderBottom-none ";
const _c7 = " borderBottomRadius-xlarge ";
const _c8 = " borderBottomRadius-none ";
const _c9 = " Knu-Flex p-small alignItems-center ";
const _c10 = " Knu-Flex mx-minor alignItems-center flex-1 ";
const _c11 = " Knu-Flex alignItems-center ";
const _c12 = "  ";
const _c13 = " Knu-FlexCol position-relative flexGrow-1 minWidth-0-px ml-tiny height-32px ";
const _c14 = " Knu-FlexCol position-absolute inset-none ";
const _c15 = " Knu-FlexCol position-absolute left-62px right-base bottom-none ";
const _c16 = "  ";