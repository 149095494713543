import * as React from 'react';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { Button, ModalCard, ModalHeader, ModalWrapper, Spacer } from '@shared/components';
import { FlexCol, Image, Text } from '@knuddels/component-library';
import { $ScreenService } from '@knuddels-app/Screen';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import pic from '../assets/payment_success.gif';
@injectable()
class PaymentSuccessModalModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($ScreenService)
  private screenService: typeof $ScreenService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  public get maxWidth(): number {
    return this.screenService.isStackedLayout ? 312 : 360;
  }
  public hideModal = () => {
    this.firebaseAnalyticsService.logHappyMoment('BoughtKnuddelsPlus');
    // TODO Update UI?
    this.thisVisibleOverlay.dispose();
  };
}
export const PaymentSuccessModal = injectedComponent({
  name: 'PaymentSuccessModal',
  model: PaymentSuccessModalModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => <ModalWrapper cancelModal={model.hideModal}>
			<ModalCard>
				<div className={_c0}>
					<Image src={pic} alt={''} resizeMode={'contain'} className={_c1} />
					<ModalHeader alignCenter headline={i18n.format(declareFormat({
        id: 'payment.successmodal.header',
        defaultFormat: 'Woohoo, your purchase was successful!'
      }))} />
					<Spacer size={'base'} />
					<Text state={'tertiary'} className={_c2}>
						{i18n.format(declareFormat({
          id: 'payment.successmodal.subheader',
          defaultFormat: 'We wish you lots of fun with your new items!'
        }))}
					</Text>
					<Spacer size={'xxlarge'} />
					<Button text={i18n.format(declareFormat({
        id: 'payment.successmodal.button',
        defaultFormat: 'Okay, cool!'
      }))} onClick={model.hideModal} />
				</div>
			</ModalCard>
		</ModalWrapper>);
const _c0 = " Knu-FlexCol px-base py-xlarge alignItems-center ";
const _c1 = " width-128px height-39px ";
const _c2 = "  ";