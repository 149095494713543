import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { PersistedViewState, Position, ViewProvider, ViewRegistry, ViewState } from '@knuddels-app/layout';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $LoggedInAreaService } from './providedServiceIds';
import { ScreenWidth } from '@knuddels-app/Screen';
import { navigationViewId } from './constants';
@injectable()
export class NavigationViewProviderImpl implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: navigationViewId,
      position: Position.Side,
      hasTitleBar: false,
      requiredScreenWidths: [ScreenWidth.XS, ScreenWidth.S],
      loadState: NavigationViewState.fromPersistedViewState,
      render: () => ({
        mainView: <LoadService service={$LoggedInAreaService}>
						{s => <s.NavigationView />}
					</LoadService>
      })
    });
  }
}
export class NavigationViewState implements ViewState {
  public static fromPersistedViewState(): NavigationViewState {
    return new NavigationViewState();
  }
  persist(): PersistedViewState {
    return {
      pathItems: [],
      state: {}
    };
  }
}