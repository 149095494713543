import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as ids from '../providedServices';
import * as s from './services';
import { foreachProp } from '@knuddels/std';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$ContactsService: ctx.loggedInScope.toSingletonAutostart(
			s.ContactsService
		),
		$ContactsCommandService: ctx.loggedInScope.toSingletonAutostart(
			s.ContactsCommandService
		),
		$FriendRequestsService: ctx.loggedInScope.toSingletonAutostart(
			s.FriendRequestsService
		),
		$WatchlistService: ctx.loggedInScope.toSingleton(s.WatchlistService),
	});
}
