import { OperationResult, useQuery } from './operationHooks';
import { TypedQueryOperation } from './Operation';
import { useEffect, useState, useCallback } from 'react';

/**
 * `useQuery` implementation that only uses a local cache.
 * The hook always calls the network when the component is mounted. Then it
 * allows cache manipulation and on unmount it throws the cache away.
 *
 * This hook is useful to prevent high memory usage with apollo cache (e.g. opening
 * lots of data heavy user profiles) while being able to update the query data
 * (e.g. optimistically updating through a mutation or after an event).
 */
export const useQueryWithLocalCache = <TVars, TResult, TPrimaryResult>(
	operation: TypedQueryOperation<TVars, TResult, TPrimaryResult>,
	variables: TVars
): OperationResult<TPrimaryResult> & {
	refetch(): void;
	updateCache(
		newDataOrUpdater:
			| TPrimaryResult
			| ((oldData: TPrimaryResult | undefined) => TPrimaryResult)
	): void;
} => {
	const result = useQuery(operation, variables, 'no-cache');
	const [overriddenResultData, overrideResultData] = useState<
		TPrimaryResult | undefined
	>(undefined);
	useEffect(() => {
		// reset override if remote data updates
		overrideResultData(undefined);
	}, [result.data]);

	const currentData = overriddenResultData || result.data;

	const updateCache = useCallback(
		newDataOrUpdater => {
			const newData =
				typeof newDataOrUpdater === 'function'
					? (newDataOrUpdater as Function)(currentData)
					: newDataOrUpdater;
			overrideResultData(newData);
		},
		[currentData, overrideResultData]
	);

	return {
		...result,
		data: currentData,
		updateCache,
	};
};
