import * as React from 'react';
import { DO_KISS } from '../../../../i18n/shared-formats';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { SpeedDial } from '@knuddels/component-library';
import { IconKiss } from '../../../shared/IconKiss';
import { useDoKiss } from '../../../shared/useDoKiss';
import { UserForProfileFragment } from '@generated/graphql';
import { useTrackProfileView, useTrackProfileViewSnackbar } from '../../../shared/useTrackProfileView';
export const KissFabEntry: React.FC<{
  user: UserForProfileFragment;
}> = ({
  user
}) => {
  const i18n = useService($I18n);
  const trackSnackbar = useTrackProfileViewSnackbar();
  const doKiss = useDoKiss(trackSnackbar);
  const track = useTrackProfileView();
  return <SpeedDial.Option label={i18n.format(DO_KISS)} icon={<IconKiss size={'large'} />} onPress={async () => {
    track('FAB_Kiss');
    await doKiss(user.id, user.nick, true);
  }} />;
};