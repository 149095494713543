import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import {
	$DeepLinkingStorage,
	DeepLinkingStorage,
} from '@knuddelsModules/DeepLinking/bundle/services';

export const deepLinkingModule = new Module({
	name: 'DeepLinking module',
	dependencies: [],
	providedServices,
	loadEvent: 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope
			.bind($DeepLinkingStorage)
			.toSingletonAutostart(DeepLinkingStorage);
	},
});
