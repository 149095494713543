import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { IPhoneWrapperSimple } from '@knuddels-app/NativeBackground';
import { Lightbox, ScrollableModalCard, Spacer } from '@shared/components';
import * as React from 'react';
import { FlexCol, Smiley } from '@knuddels/component-library';
export const ErrorScreenWrapper = injectedComponent({
  name: 'ErrorScreenWrapper',
  inject: {
    i18n: $I18n
  },
  props: declareProps<React.PropsWithChildren<{
    logout: () => void;
  }>>()
}, ({
  i18n,
  logout,
  children
}) => {
  return <IPhoneWrapperSimple>
				<Lightbox>
					<div className={_c0}>
						<ScrollableModalCard primaryButton={{
          text: i18n.format(declareFormat({
            id: 'refresh-session-error.logout',
            defaultFormat: 'Logout'
          })),
          width: 200,
          onClick: logout
        }}>
							<Spacer size="large" />
							<Smiley src={require('./error-smiley.png')} width={'36px'} height={'32px'} />
							<Spacer size="base" />
							{children}
							<Spacer size="xlarge" />
						</ScrollableModalCard>
					</div>
				</Lightbox>
			</IPhoneWrapperSimple>;
});
const _c0 = " Knu-FlexCol m-large maxWidth-500px ";