import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import {
	MessengerMessage,
	MessengerMessageStarredStateChangedFragment,
	StarMessengerMessageDocument,
	StarMessengerMessageMutation,
	StarMessengerMessageMutationVariables,
	StarredConversationMessageFragment,
	StarredMessagesDocument,
	StarredMessagesQuery,
	StarredMessagesQueryVariables,
	UnStarMessengerMessageDocument,
	UnStarMessengerMessageMutation,
	UnStarMessengerMessageMutationVariables,
} from '@generated/graphql';
import { $MessengerService } from '@knuddelsModules/Messenger/providedServices';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { last } from '@shared/components';

@injectable()
export class StarMessageService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($MessengerService)
		private readonly messengerService: typeof $MessengerService.T
	) {
		this.dispose.track(
			this.messengerService.onMessageStarStateChanged.sub(
				this.handleSyncMessageStarEvent
			)
		);
	}

	starMessage(messageId: MessengerMessage['id']): void {
		this.authenticatedClientService.currentClient
			.mutate<
				StarMessengerMessageMutation,
				StarMessengerMessageMutationVariables
			>({
				mutation: StarMessengerMessageDocument,
				variables: {
					id: messageId,
				},
			})
			.then(result => {
				if (result.data && result.data.messenger.starMessage.error) {
					this.snackbarService.showGenericError();
				}
			})
			.catch(() => {
				this.snackbarService.showGenericError();
			});
	}

	unStarMessage(messageId: MessengerMessage['id']): void {
		this.authenticatedClientService.currentClient
			.mutate<
				UnStarMessengerMessageMutation,
				UnStarMessengerMessageMutationVariables
			>({
				mutation: UnStarMessengerMessageDocument,
				variables: {
					id: messageId,
				},
			})
			.then(result => {
				if (result.data && result.data.messenger.unstarMessage.error) {
					this.snackbarService.showGenericError();
				}
			})
			.catch(() => {
				this.snackbarService.showGenericError();
			});
	}

	private readonly handleSyncMessageStarEvent = ({
		starredMessage,
	}: MessengerMessageStarredStateChangedFragment): void => {
		if (!('starred' in starredMessage.message.content)) {
			return;
		}

		const currentQuery = this.readQuery();
		if (!currentQuery) {
			return;
		}

		const currentMessages =
			currentQuery.messenger.starredConversationMessages.messages;

		const isStarred = starredMessage.message.content.starred;
		const newMessages = isStarred
			? this.insertMessage(currentMessages, starredMessage)
			: this.removeMessage(currentMessages, starredMessage);

		this.writeQuery(currentQuery, newMessages);
	};

	private insertMessage(
		currentMessages: readonly StarredConversationMessageFragment[],
		starredMessage: StarredConversationMessageFragment
	): readonly StarredConversationMessageFragment[] {
		const index = currentMessages.findIndex(
			message => message.message.id === starredMessage.message.id
		);
		const lastMessage = last(currentMessages);
		if (!lastMessage) {
			return;
		}

		// Don't insert message if it's older than the current oldest message,
		// because if there were any messages between the oldest and the new one,
		// they would not be fetched when loading more messages from the server.
		if (
			index === -1 &&
			+starredMessage.message.timestamp >= +lastMessage.message.timestamp
		) {
			return this.sortMessages([...currentMessages, starredMessage]);
		}
		return currentMessages;
	}

	private sortMessages(
		messages: readonly StarredConversationMessageFragment[]
	): readonly StarredConversationMessageFragment[] {
		return [...messages].sort((a, b) => {
			const aDate = +a.message.timestamp;
			const bDate = +b.message.timestamp;
			return bDate - aDate;
		});
	}

	private removeMessage(
		currentMessages: readonly StarredConversationMessageFragment[],
		starredMessage: StarredConversationMessageFragment
	): readonly StarredConversationMessageFragment[] {
		const index = currentMessages.findIndex(
			message => message.message.id === starredMessage.message.id
		);
		if (index === -1) {
			return currentMessages;
		}
		return currentMessages
			.slice(0, index)
			.concat(currentMessages.slice(index + 1));
	}

	private readQuery(): StarredMessagesQuery | undefined {
		try {
			return this.authenticatedClientService.currentClient.readQuery<
				StarredMessagesQuery,
				StarredMessagesQueryVariables
			>({
				query: StarredMessagesDocument,
				variables: {
					limit: 50,
					pixelDensity: getPixelRatio(),
				},
			});
		} catch (e) {
			return undefined;
		}
	}

	private writeQuery(
		currentQuery: StarredMessagesQuery,
		messages: readonly StarredConversationMessageFragment[]
	): void {
		this.authenticatedClientService.currentClient.writeQuery<
			StarredMessagesQuery,
			StarredMessagesQueryVariables
		>({
			query: StarredMessagesDocument,
			variables: {
				limit: 50,
				pixelDensity: getPixelRatio(),
			},
			data: {
				...currentQuery,
				messenger: {
					...currentQuery.messenger,
					starredConversationMessages: {
						...currentQuery.messenger.starredConversationMessages,
						messages,
					},
				},
			},
		});
	}
}
