import { jsxs as h, jsx as d } from "react/jsx-runtime";
import v from "react";
import "./createSvgIcon.scss.js";
import { clsx as C } from "../../node_modules/clsx/dist/clsx.m.js";
import { useColor as x } from "../Layout/hooks/useColor.js";
import { toPointerHandler as c } from "../Layout/CommonTypes/toPointerHandler.js";
import { Ripple as g } from "../Layout/Box/Ripple.js";
import { resolveThemeColor as k } from "../Layout/isDarkColor.js";
import { useTheme as y } from "../../themes/useTheme.js";
const O = (r) => v.memo(
  ({
    size: t = "base",
    type: e = "default",
    disabled: o,
    active: p,
    onPress: l,
    onPointerDown: s,
    onPointerUp: n,
    ripple: R = !0,
    ...a
  }) => {
    const i = v.useRef(null), b = x(a, i), { innerRef: m } = a, f = !!l || !!s || !!n;
    return /* @__PURE__ */ h(
      "div",
      {
        ref: (u) => {
          i.current = u, m && (m.current = u);
        },
        style: S(b, y()),
        onClick: o ? void 0 : c(l),
        onPointerDown: c(s),
        onPointerUp: c(n),
        className: C(
          "Knu-Svg",
          t,
          e,
          f && "clickable",
          o && "disabled",
          p && "active"
        ),
        children: [
          f && R && /* @__PURE__ */ d(
            "div",
            {
              className: "Knu-FlexCol RippleWrapper overflow-hidden position-absolute alignItems-center borderRadius-circle",
              children: /* @__PURE__ */ d(g, { containerRef: i })
            }
          ),
          r
        ]
      }
    );
  }
);
function S(r, t) {
  let e = {};
  if (r) {
    const o = k(r, t);
    e["--icons-colors-default-active"] = o, e["--icons-colors-hover-active"] = o, e["--icons-colors-active-active"] = o, e["--icons-colors-static-active"] = o, e["--icons-colors-disabled-active"] = o;
  }
  return e;
}
export {
  O as createSvgIcon
};
