import { NotificationFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { Flex, FlexCol, Text, createAccessiblePointerEventHandler, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { $NotificationBarService } from '@knuddelsModules/Notifications';
import { FormattedText, PreviewFormattedTextDisplay, Spacer } from '@shared/components';
import * as React from 'react';
import { NotificationContentIcon, NotificationContentImage } from './NotificationContentIcon';
export const NotificationContentFromSubscription = observer('NotificationContentFromSubscription', (props: {
  notification: NotificationFragment;
  close(): void;
}) => {
  const notificationBarService = useService($NotificationBarService);
  const notification = props.notification;
  const callToAction = notification.callToAction;
  const handlePress = async () => {
    props.close();
    notificationBarService.handleNotificationPress(notification);
  };
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: handlePress
  })} className={_c0}>
				{notification.icon && !notification.image && <NotificationContentIcon icon={notification.icon} />}
				{notification.image && <NotificationContentImage image={notification.image} />}
				<Spacer size={'minor'} />
				<div className={_c1}>
					<Text bold={true} className={_c2}>{notification.title}</Text>
					<PreviewFormattedTextDisplay text={FormattedText.fromJsonString(notification.description)} />
					{callToAction && <Text state={'highlighted'} bold={true} className={_c3}>
							{callToAction.text}
						</Text>}
				</div>
			</div>;
});
const _c0 = " Knu-Flex cursor-pointer px-minor py-minor alignItems-center ";
const _c1 = " Knu-FlexCol flex-1 ";
const _c2 = "  ";
const _c3 = "  ";