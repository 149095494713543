import { injectedComponent, declareProps } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { $I18n } from './services';
import { FormatId, FormattedData, DateTimeFormatOptions, DateSource } from './facade';
import { expectUnreachable } from '@knuddels/std';
export const FormattedMessage = injectedComponent({
  name: 'FormattedMessage',
  inject: {
    i18n: $I18n
  },
  props: declareProps<{
    id: FormatId;
    values?: Record<string, unknown>;
  }>()
}, ({
  i18n,
  id: format,
  values
}) => {
  const formatted = i18n.formatStructured(format, values);
  return formattedToReact(formatted, 0);
});
export const FormattedDateTime = injectedComponent({
  name: 'FormattedDateTime',
  inject: {
    i18n: $I18n
  },
  props: declareProps<{
    value: DateSource;
  } & DateTimeFormatOptions>()
}, ({
  i18n,
  value,
  ...options
}) => {
  return (i18n.formatDateTime(value, options) as any);
});
export const FormattedNumber = injectedComponent({
  name: 'FormattedNumber',
  inject: {
    i18n: $I18n
  },
  props: declareProps<{
    value: number;
  } & Intl.NumberFormatOptions>()
}, ({
  i18n,
  value,
  ...options
}) => {
  return (i18n.formatNumber(value, options) as any);
});
function formattedToReact(f: FormattedData, key: number): React.ReactElement {
  switch (f.kind) {
    case 'object':
      return <React.Fragment key={key}>{(f.data as any)}</React.Fragment>;
    case 'text':
      return <React.Fragment key={key}>{f.value}</React.Fragment>;
    case 'sequence':
      return <React.Fragment key={key}>
					{f.items.map(formattedToReact)}
				</React.Fragment>;
    default:
      return expectUnreachable(f);
  }
}