import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Deferred, Disposable } from '@knuddels/std';
import { $LocalStorage, LocalStorageKey } from '@knuddels-app/local-storage';
import { observable, runInAction } from '@knuddels-app/mobx';
import { $AuthService } from '@knuddels-app/Connection';

type NavBarState = 'collapsed' | 'extended';

const navBarStateStorageKey = LocalStorageKey.withJsonSerializer<NavBarState>({
	name: 'navbar.state',
	cookieExpires: { inDays: 365 },
});

@injectable()
export class NavBarStateStore {
	public readonly dispose = Disposable.fn();
	public readonly deferredReady = new Deferred();

	@observable
	private _state: NavBarState | undefined;

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T
	) {
		this.dispose.track(
			authService.onBeforeVoluntaryLogout.sub(() => {
				this.localStorage.getEntry(navBarStateStorageKey).reset();
			})
		);

		this.loadState();
	}

	private loadState = (): void => {
		const state = this.localStorage.getEntry(navBarStateStorageKey).get();

		runInAction('set navbar state', () => {
			this._state = state;
			this.deferredReady.resolve();
		});
	};

	public get state(): NavBarState | undefined {
		return this._state;
	}

	public setState = (state: NavBarState): void => {
		runInAction('Update navbar state', () => {
			this._state = state;
		});
		this.localStorage.getEntry(navBarStateStorageKey).set(state);
	};
}
