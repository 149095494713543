function a(o) {
  var n;
  const t = o, e = (n = t.nativeEvent.target) == null ? void 0 : n.getBoundingClientRect();
  return {
    pointerId: t.pointerId,
    pageX: t.pageX,
    pageY: t.pageY,
    locationY: e ? t.pageY - e.y : -1,
    locationX: e ? t.pageX - e.x : -1,
    preventDefault: () => t.preventDefault(),
    stopPropagation: () => t.stopPropagation(),
    originalEvent: t
  };
}
export {
  a as toPointerEvent
};
