import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { FlexCol, Panel, PanelsList, Tab, Tabs, TabsList, TabText, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import { MentorSystemRewardsInfo } from './MentorSystemRewardsInfo';
import { MentorSystemPointsInfo } from './MentorSystemPointsInfo';
export const MentorSystemRewards: React.FC<{
  initialTab?: number;
}> = observer('MentorSystemRewards', props => {
  const [activeIndex, setActiveIndex] = React.useState(props.initialTab ?? 0);
  const i18n = useService($I18n);
  return <Tabs value={activeIndex} onChange={setActiveIndex}>
				<TabsList>
					<Tab className={_c0}>
						<TabText>
							{declareFormat({
            id: 'mentorsystem.rewards.info.tab',
            defaultFormat: 'Rewards'
          }).format(i18n)}
						</TabText>
					</Tab>
					<Tab className={_c1}>
						<TabText>
							{declareFormat({
            id: 'mentorsystem.rewards.points.tab',
            defaultFormat: 'Collect points'
          }).format(i18n)}
						</TabText>
					</Tab>
				</TabsList>
				<PanelsList>
					<Panel>
						<div className={_c2 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<MentorSystemRewardsInfo />
						</div>
					</Panel>
					<Panel>
						<div className={_c3 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<MentorSystemPointsInfo />
						</div>
					</Panel>
				</PanelsList>
			</Tabs>;
});
const _c0 = "  ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol width-100-percent bg-contentBg ";
const _c3 = " Knu-FlexCol width-100-percent bg-contentBg ";