import * as React from 'react';
import { useEffect } from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { FlexCol, ScrollView, Text } from '@knuddels/component-library';
import { useMentorSystemTips } from './mentorSystemTipsData';
import { InfoCard } from '@shared/components';
import { useTrackMentorSystemInfo } from './useTrackMentorSystemInfo';
export const MentorSystemTips: React.FC = observer('MentorSystemTips', () => {
  const i18n = useService($I18n);
  const track = useTrackMentorSystemInfo();
  const items = useMentorSystemTips();
  useEffect(() => {
    track('Tipps_Shown');
  }, []);
  return <div className={_c0}>
			<div className={_c1}>
				<Text bold={true} className={_c2}>
					{declareFormat({
          id: 'mentorsystem.about.tips.intro.title',
          defaultFormat: 'Some tips for you in the mentor system'
        }).format(i18n)}
				</Text>
				<Text state={'secondary'} className={_c3}>
					{declareFormat({
          id: 'mentorsystem.about.tips.intro.text',
          defaultFormat: 'A few tips to help you become the most successful mentor possible:'
        }).format(i18n)}
				</Text>
			</div>

			<div className={_c4}>
				{items.map((item, index) => <InfoCard key={`tip-${index}`} item={item} />)}
			</div>
		</div>;
});
const _c0 = " Knu-ScrollView px-large ";
const _c1 = " Knu-FlexCol pt-base ";
const _c2 = " my-small textAlign-center ";
const _c3 = " textAlign-center ";
const _c4 = " Knu-FlexCol py-small ";