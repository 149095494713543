import { injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import { action, observable } from '@knuddels-app/mobx';

type UploadingImage = { albumId: string; base64Image: string };

@injectable()
export class AlbumPhotoUploadService {
	public readonly dispose = Disposable.fn();

	@observable
	public uploadingPhotos: UploadingImage[] = [];

	public readonly onUploadFinished: EventSource;
	private readonly onUploadFinishedEmitter = new EventEmitter();

	constructor() {
		this.onUploadFinished = this.onUploadFinishedEmitter.asEvent();
	}

	@action.bound
	public addPhotos(photos: UploadingImage[]): () => void {
		this.uploadingPhotos = [...this.uploadingPhotos, ...photos];

		return () => {
			this.removePhotos(photos);
		};
	}

	@action.bound
	public removePhotos(photos: UploadingImage[]): void {
		this.uploadingPhotos = this.uploadingPhotos.filter(
			it => photos.indexOf(it) >= 0
		);
	}

	public fireOnUploadFinished = () => {
		this.onUploadFinishedEmitter.emit();
	};
}
