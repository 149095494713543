import { createK3Router } from '@knuddels-app/layout/k3Router';
import { downloadViewId } from './constants';

export type DownloadRouterConfig = {
	download: { params: undefined };
};

export const { Router, useRouter, useParams } = createK3Router<
	DownloadRouterConfig
>(downloadViewId);
