import { MotionGlobalConfig as i } from "../utils/GlobalConfig.js";
import { frameData as n } from "./frame.js";
let e;
function t() {
  e = void 0;
}
const r = {
  now: () => (e === void 0 && r.set(n.isProcessing || i.useManualTiming ? n.timestamp : performance.now()), e),
  set: (o) => {
    e = o, queueMicrotask(t);
  }
};
export {
  r as time
};
