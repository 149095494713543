import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $AuthService } from '@knuddels-app/Connection';
import { $Environment } from '@knuddels-app/Environment';
import { getAndRemoveSearchParam } from '@knuddels-app/tools/getAndRemoveSearchParam';
import {
	$LocalStorage,
	createJsonSerializer,
	LocalStorageKey,
} from '@knuddels-app/local-storage';
import { DeepLinkType } from '@knuddelsModules/DeepLinking';
import { DeepLink } from './DeepLinkingService';
import * as services from '@knuddelsModules/DeepLinking/bundle/services/index';
import { getScopeIfWeb } from '@knuddels-app/local-storage/sessionCookieHelper';

export const $DeepLinkingStorage = newServiceId<services.DeepLinkingStorage>(
	'$DeepLinkingStorage'
);

const deeplInkStorageKey = new LocalStorageKey<DeepLink | undefined>({
	name: 'deeplink',
	scope: getScopeIfWeb(),
	cookieExpires: { inDays: 1 },
	serializer: createJsonSerializer({ defaultValue: undefined }),
});

@injectable()
export class DeepLinkingStorage {
	public readonly dispose = Disposable.fn();

	private storageEntry = this.localStorage.getEntry(deeplInkStorageKey);

	constructor(
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		this.dispose.track(
			authService.onBeforeRedirect.sub(() => {
				const appSearchParam = getAndRemoveSearchParam(
					environment,
					'app'
				);
				if (appSearchParam) {
					this.storageEntry.set({
						type: DeepLinkType.App,
						target: appSearchParam,
					});
				}
			})
		);
	}

	public getAndRemoveStoredDeepLink = () => {
		const deepLink = this.storageEntry.get();
		this.storageEntry.set(undefined);
		return deepLink;
	};
}
