import { Box, FlexCol, TranslateX, TranslateY, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
export const Confettis: React.FC<{
  active: boolean;
}> = ({
  active
}) => {
  return <>
			{confettis.map((confetti, index) => <Confetti key={'confetti-' + index} active={active} size={confetti.size} left={confetti.left} destinationY={confetti.targetY} color={confetti.color} delay={confetti.delay} />)}
		</>;
};
const Confetti: React.FC<{
  active: boolean;
  size: number;
  left: number;
  destinationY: number;
  color: string;
  delay: number;
}> = ({
  size,
  delay,
  left,
  color,
  destinationY,
  active
}) => {
  return <div style={{
    height: resolveThemingValue(destinationY + size + 'px', "sizes", useTheme())
  }} className={_c0}>
			<TranslateX translated to={left}>
				<TranslateY translated={active} from={-size} to={destinationY} duration={500} delay={250 + delay}>
					<div style={{
          background: resolveThemingValue((color as any), "colors", useTheme()),
          width: resolveThemingValue(size, "sizes", useTheme()),
          height: resolveThemingValue(size, "sizes", useTheme())
        }} className={_c1 + ((color as any) ? resolveIsDarkColor((color as any), useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				</TranslateY>
			</TranslateX>
		</div>;
};
const confettis = [{
  size: 6,
  color: 'rgb(251,106,142)',
  left: 15,
  targetY: 33,
  delay: 250
}, {
  size: 9,
  color: 'rgb(251,176,106)',
  left: 36,
  targetY: 62,
  delay: 50
}, {
  size: 9,
  color: 'rgba(255,255,255,0.6)',
  left: 70,
  targetY: 52,
  delay: 250
}, {
  size: 6,
  color: 'rgb(251,176,106)',
  left: 83,
  targetY: 25,
  delay: 400
}, {
  size: 9,
  color: 'rgb(251,106,142)',
  left: 122,
  targetY: 10,
  delay: 400
}, {
  size: 9,
  color: 'rgb(251,106,142)',
  left: 202,
  targetY: 56,
  delay: 50
}, {
  size: 6,
  color: 'rgba(255,255,255,0.6)',
  left: 206,
  targetY: 21,
  delay: 400
}, {
  size: 6,
  color: 'rgba(255,255,255,0.6)',
  left: 250,
  targetY: 81,
  delay: 250
}, {
  size: 9,
  color: 'rgb(251,176,106)',
  left: 258,
  targetY: 32,
  delay: 50
}];
const _c0 = " Knu-FlexCol position-absolute left-none top-none width-100-percent overflow-hidden ";
const _c1 = " Knu-Box borderRadius-circle ";