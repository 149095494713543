import {
	K3ServiceBinder,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { LoadingScreenService } from '@knuddels-app/LoadingScreen/LoadingScreenService';

export const $LoadingScreenService = newServiceId<LoadingScreenService>(
	'$LoadingScreenService'
);

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope
		.bind($LoadingScreenService)
		.toSingletonAutostart(LoadingScreenService);
}
