import { newServiceId } from '@knuddels-app/DependencyInjection';
import { LocationService } from './LocationService';
import { LocationPersistor } from './LocationPersistor';

export const $LocationService = newServiceId<LocationService>(
	'$LocationService'
);

export const $LocationPersistor = newServiceId<LocationPersistor>(
	'$LocationPersistor'
);
