import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { FormattedText, FormattedTextDisplay, Spacer } from '@shared/components';
import * as React from 'react';
import { ErrorScreenWrapper } from './ErrorScreenWrapper';
import { Text } from '@knuddels/component-library';
export const GenericErrorMessageScreen = injectedComponent({
  name: 'GenericErrorMessageScreen',
  props: declareProps<{
    logout: () => void;
    errorMessage: string;
  }>()
}, ({
  logout,
  errorMessage
}) => <ErrorScreenWrapper logout={logout}>
			<Text type={'title'} state={'primary'} bold={true} className={_c0}>
				<FormattedMessage id={declareFormat({
      id: 'refresh-session-error.generic-message.headline',
      defaultFormat: 'Unfortunately we could not connect you.'
    })} />
			</Text>
			<Spacer size="large" />
			<FormattedTextDisplay textProps={{
    type: 'body1'
  }} text={FormattedText.fromJsonString(errorMessage)} />
		</ErrorScreenWrapper>);
const _c0 = "  ";