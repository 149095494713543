import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';

export const useReportComment = () => {
	const executeCommand = useSlashCommandWithoutChannel();

	return async (photoId: string, commentId: string) => {
		return executeCommand(
			`/opensystemapp UserReport:{selectedPhotoCommentData:{photoId:"${photoId}", commentId:"${commentId}"}}`
		);
	};
};
