import { interpolate as c } from "./interpolate.js";
const p = (t) => t && typeof t == "object" && t.mix, m = (t) => p(t) ? t.mix : void 0;
function f(...t) {
  const o = !Array.isArray(t[0]), n = o ? 0 : -1, r = t[0 + n], s = t[1 + n], e = t[2 + n], u = t[3 + n], i = c(s, e, {
    mixer: m(e[0]),
    ...u
  });
  return o ? i(r) : i;
}
export {
  f as transform
};
