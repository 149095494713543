import { removeNonTranslationalTransform as y } from "../dom/utils/unit-conversion.js";
import { frame as u } from "../../frameloop/frame.js";
const n = /* @__PURE__ */ new Set();
let d = !1, m = !1;
function h() {
  if (m) {
    const r = Array.from(n).filter((e) => e.needsMeasurement), s = new Set(r.map((e) => e.element)), l = /* @__PURE__ */ new Map();
    s.forEach((e) => {
      const t = y(e);
      t.length && (l.set(e, t), e.render());
    }), r.forEach((e) => e.measureInitialState()), s.forEach((e) => {
      e.render();
      const t = l.get(e);
      t && t.forEach(([a, i]) => {
        var o;
        (o = e.getValue(a)) === null || o === void 0 || o.set(i);
      });
    }), r.forEach((e) => e.measureEndState()), r.forEach((e) => {
      e.suspendedScrollY !== void 0 && window.scrollTo(0, e.suspendedScrollY);
    });
  }
  m = !1, d = !1, n.forEach((r) => r.complete()), n.clear();
}
function c() {
  n.forEach((r) => {
    r.readKeyframes(), r.needsMeasurement && (m = !0);
  });
}
function K() {
  c(), h();
}
class S {
  constructor(s, l, e, t, a, i = !1) {
    this.isComplete = !1, this.isAsync = !1, this.needsMeasurement = !1, this.isScheduled = !1, this.unresolvedKeyframes = [...s], this.onComplete = l, this.name = e, this.motionValue = t, this.element = a, this.isAsync = i;
  }
  scheduleResolve() {
    this.isScheduled = !0, this.isAsync ? (n.add(this), d || (d = !0, u.read(c), u.resolveKeyframes(h))) : (this.readKeyframes(), this.complete());
  }
  readKeyframes() {
    const { unresolvedKeyframes: s, name: l, element: e, motionValue: t } = this;
    for (let a = 0; a < s.length; a++)
      if (s[a] === null)
        if (a === 0) {
          const i = t == null ? void 0 : t.get(), o = s[s.length - 1];
          if (i !== void 0)
            s[0] = i;
          else if (e && l) {
            const f = e.readValue(l, o);
            f != null && (s[0] = f);
          }
          s[0] === void 0 && (s[0] = o), t && i === void 0 && t.set(s[0]);
        } else
          s[a] = s[a - 1];
  }
  setFinalKeyframe() {
  }
  measureInitialState() {
  }
  renderEndStyles() {
  }
  measureEndState() {
  }
  complete() {
    this.isComplete = !0, this.onComplete(this.unresolvedKeyframes, this.finalKeyframe), n.delete(this);
  }
  cancel() {
    this.isComplete || (this.isScheduled = !1, n.delete(this));
  }
  resume() {
    this.isComplete || this.scheduleResolve();
  }
}
export {
  S as KeyframeResolver,
  K as flushKeyframeResolvers
};
