import { gtag } from '@knuddels-app/analytics/gtag';

export interface TrackingEvent {
	track(
		label?: string,
		value?: number,
		name?: string,
		callback?: () => void
	): void;
}

export function createTrackingEventType(
	category: string,
	action: string
): TrackingEvent {
	return {
		track: (
			label?: string,
			value?: number,
			name?: string,
			callback?: () => void
		) => {
			gtag('event', action, {
				name,
				event_category: category,
				event_label: label,
				event_callback: callback,
				value,
			});
		},
	};
}

interface PageViewEvent {
	track(): void;
}

export function createPageViewEventType(
	pageTitle: string,
	pagePath: string,
	pageLocation?: string
): PageViewEvent {
	return {
		track: (): void =>
			gtag('event', 'pageview', {
				page_title: pageTitle,
				page_path: pagePath,
				page_location: pageLocation,
			}),
	};
}

interface ECommercePurchaseEvent {
	/**
	 * @param productId The internal ID of the product we send to the server
	 * @param value Price of the product in €
	 */
	track(productId: string, value: number): void;
}

export function createECommercePurchaseEvent(
	productCategory: string
): ECommercePurchaseEvent {
	return {
		track(productId: string, value: number): void {
			gtag('event', 'purchase', {
				value: value,
				items: [
					{
						id: productId,
						name: productId,
						brand: 'Knuddels',
						category: productCategory,
						price: value,
						currency: 'EUR',
					},
				],
			});
		},
	};
}
