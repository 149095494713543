import * as c from "react";
const o = (e, t) => {
  e.addEventListener ? e.addEventListener("change", t) : e.addListener(t);
}, r = (e, t) => {
  e.removeEventListener ? e.removeEventListener("change", t) : e.removeListener(t);
}, i = (e) => {
  if (!window)
    return !1;
  const [t, a] = c.useState(
    () => window.matchMedia(e).matches
  );
  return c.useEffect(() => {
    const s = window.matchMedia(e), n = () => {
      a(s.matches);
    };
    return o(s, n), () => {
      r(s, n);
    };
  }, [e]), t;
};
export {
  i as useMatchMediaQuery
};
