import React from 'react';
import { ChannelGroupExpandedContext } from '../../../ChannelGroupExpandedContext';
import { ChannelListItem, templateCategoryHeader, useCreateHighlightChannelGroupVirtualListItem } from '../../../shared/ChannelList/ChannelListVirtualItems';
import { GetGameChannels } from '@generated/graphql';
import { ExpandChannelsButton } from './CategoryHeaderButton';
import { formatMessage } from '@knuddels-app/i18n';
import { HEADER_MORE_GAMES, HEADER_POPULAR_GAMES, HEADER_TOP_GAMES } from '../../../../i18n/shared-formats';
import { useSmallChannelGroupExpanded } from '../../../SmallChannelGroupExpandedContext';
import { sortAllChannels } from '../../../shared/ChannelList/sortAllChannels';
import { chunkArray } from '@knuddels-app/tools/chunkArray';
export const useGameListData = (channels: typeof GetGameChannels.TPrimaryResult, availableWidth: number): ChannelListItem[] => {
  const {
    isExpanded: isSmallGroupExpanded
  } = useSmallChannelGroupExpanded();
  const {
    isExpanded
  } = React.useContext(ChannelGroupExpandedContext);
  const {
    top: topGameChannels,
    all: allGameChannels
  } = channels;
  const topChannels: ChannelListItem[] = [];
  const popularChannels: ChannelListItem[] = [];
  const moreChannels: ChannelListItem[] = [];
  const createHighlightChannelGroupVirtualListItem = useCreateHighlightChannelGroupVirtualListItem();
  return React.useMemo(() => {
    if (topGameChannels.length === 0 && allGameChannels.channelGroups.length === 0) {
      return [];
    }
    topChannels.push({
      type: templateCategoryHeader,
      key: 'top-game-channels-header',
      height: 72,
      title: formatMessage(HEADER_TOP_GAMES)
    });
    popularChannels.push({
      type: templateCategoryHeader,
      key: 'popular-game-channels-header',
      height: 72,
      title: formatMessage(HEADER_POPULAR_GAMES)
    });
    moreChannels.push({
      type: templateCategoryHeader,
      key: 'more-game-channels-header',
      height: 72,
      title: formatMessage(HEADER_MORE_GAMES),
      button: <ExpandChannelsButton />
    });
    const topChannelPerRow = Math.max(1, Math.floor(availableWidth / 328));
    const topGameChannelLimit = topChannelPerRow > 1 ? 4 : 3;
    chunkArray(topGameChannels || [], topChannelPerRow).slice(0, topGameChannelLimit).forEach((row, index) => {
      topChannels.push(createHighlightChannelGroupVirtualListItem(`top-game-channel-${index}`, topChannelPerRow, row, index === topGameChannels.length - 1, availableWidth));
    });
    sortAllChannels(allGameChannels, popularChannels, moreChannels, isExpanded, isSmallGroupExpanded, 'default', true);
    return [...topChannels, ...popularChannels, ...moreChannels];
  }, [topGameChannels, allGameChannels, isExpanded, isSmallGroupExpanded, availableWidth]);
};