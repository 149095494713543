import { ImageGroupImage } from './ImageGroupData';
import { ImageGroupImageVerified } from './verifyImageGroupImage';

export function transformImageGroupImage(
	image: ImageGroupImageVerified
): ImageGroupImage | undefined {
	const mx = parseInt(image.pseudoCSS.mx, 10);
	const my = parseInt(image.pseudoCSS.my, 10);
	const pw = parseInt(image.pseudoCSS.pw, 10);
	const ph = parseInt(image.pseudoCSS.ph, 10);
	const w = parseInt(image.pseudoCSS.w, 10);
	const h = parseInt(image.pseudoCSS.h, 10);
	const scalew = image.pseudoCSS.scalew
		? parseInt(image.pseudoCSS.scalew, 10)
		: NaN;
	const scaleh = image.pseudoCSS.scaleh
		? parseInt(image.pseudoCSS.scaleh, 10)
		: NaN;
	const url = rewriteURL(image.url);
	const clickUrl = image.pseudoCSS.clickurl
		? rewriteURL(image.pseudoCSS.clickurl)
		: undefined;
	const clickPw = image.pseudoCSS.clickpw
		? parseInt(image.pseudoCSS.clickpw, 10)
		: NaN;
	const clickPh = image.pseudoCSS.clickph
		? parseInt(image.pseudoCSS.clickph, 10)
		: NaN;

	// h/w are not required, so don't check them here
	if (isNaN(mx) || isNaN(my) || isNaN(pw) || isNaN(ph)) {
		return undefined;
	}

	if (clickUrl && (isNaN(clickPw) || isNaN(clickPh))) {
		return undefined;
	}

	const pwOut = applyScale(scalew, pw);
	const phOut = applyScale(scalew, ph);

	const clickPwOut = applyScale(scalew, clickPw);
	const clickPhOut = applyScale(scaleh, clickPh);

	return {
		mx,
		my,
		pw: pwOut,
		ph: phOut,
		w,
		h,
		url,
		linkUrl: image.linkUrl,
		b: image.pseudoCSS.b,
		clickUrl,
		clickPw: clickPwOut,
		clickPh: clickPhOut,
		clickToggleOnce: image.pseudoCSS.clicktoggleonce === '1',
	};
}

function applyScale(scale: number, input: number): number {
	return isNaN(scale) ? input : Math.ceil((input * scale) / 100);
}

const PICS_FOLDER = 'pics/';
const rewriteURL = (url: string): string => {
	if (
		url.startsWith('http://') ||
		url.startsWith('https://') ||
		url.startsWith('//')
	) {
		return url;
	}

	url = url.indexOf(PICS_FOLDER) === 0 ? url : PICS_FOLDER + url;
	return 'https://cdnc.knuddelscom.de/' + url;
};
