import { spring as a } from "../../animation/generators/spring/index.js";
import { calcGeneratorDuration as i, maxGeneratorDuration as m } from "../../animation/generators/utils/calc-duration.js";
import { millisecondsToSeconds as s } from "../../utils/time-conversion.js";
function p(t, r = 100) {
  const e = a({ keyframes: [0, r], ...t }), o = Math.min(i(e), m);
  return {
    type: "keyframes",
    ease: (n) => e.next(o * n).value / r,
    duration: s(o)
  };
}
export {
  p as createGeneratorEasing
};
