import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { TextModal } from '@shared/components';
import cryBaby from '@shared/images/sm_abo-wlcm2012_crybaby.png';
export type ConfirmDialogProps = {
  headline: string;
  text: string;
  onConfirm(): void;
  onCancel?(): void;
};
export const useConfirmDeleteDialog = () => {
  const overlayService = useService($OverlayService);
  const i18n = useService($I18n);
  return (props: ConfirmDialogProps) => {
    const modal = overlayService.showOverlay({
      view: <TextModal pictureProps={{
        source: cryBaby,
        size: 20
      }} headline={props.headline} text={props.text} primaryButton={{
        text: i18n.format(declareFormat({
          id: 'confirm-delete-dialog.confirm',
          defaultFormat: 'Yes, delete'
        })),
        onClick: () => {
          props.onConfirm();
          modal.dispose();
        }
      }} secondaryButton={{
        text: i18n.format(declareFormat({
          id: 'confirm-delete-dialog.cancel',
          defaultFormat: 'Cancel'
        })),
        onClick: () => {
          props.onCancel?.();
          modal.dispose();
        }
      }} cancelModal={() => {
        props.onCancel?.();
        modal.dispose();
      }} />
    });
  };
};