import * as React from 'react';
import { GetUserForProfileQuery } from '@generated/graphql';

export const ProfileFriendsContext = React.createContext<
	GetUserForProfileQuery['contacts']['friends']
>(undefined);

export const ProfileCommonFriendsContext = React.createContext<
	GetUserForProfileQuery['contacts']['commonFriends']
>(undefined);
