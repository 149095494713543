const e = /* @__PURE__ */ new Map();
function b(a, n) {
  const r = a + n;
  if (e.has(r))
    return e.get(r);
  try {
    const t = a.match(/\d+/g), o = n.match(/\d+/g), s = Math.abs, h = (t[0] * 299 + o[1] * 587 + o[2] * 114) / 1e3, u = (t[0] * 299 + t[1] * 587 + t[2] * 114) / 1e3, d = Math.round(Math.abs(h - u)), l = s(o[0] - t[0]) + s(o[1] - t[1]) + s(o[2] - t[2]), c = d > 125 && l > 500;
    return e.set(r, c), c;
  } catch {
    throw new Error(
      `Could not validate contrast. Your colors need to be rgb values. Are they? Text Color: ${a}, Background Color: ${n}`
    );
  }
}
export {
  b as validateContrast
};
