import * as React from 'react';
import { FormattedTextDisplay, FormattedTextDisplayProps } from '@shared/components';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '../serviceIds';
type Props = FormattedTextDisplayProps & {
  commandContext?: string;
  onCommandExecuted?: (commandName: string, parameter: string) => void;
};
@injectable()
class FormattedTextDisplayWithCommandHandlingModel {
  constructor(@injectProps()
  private readonly props: Props, @inject($CommandService)
  private commandService: typeof $CommandService.T) {}
  public readonly handleLink = (link: string): boolean => {
    const command = this.commandService.tryParseCommandCall(link);
    if (command) {
      this.commandService.invokeCommand(command, this.props.commandContext);
      this.props.onCommandExecuted?.(command.commandName, command.parameter);
      return true;
    }
    return false;
  };
}
export const FormattedTextDisplayWithCommandHandling = injectedComponent({
  model: FormattedTextDisplayWithCommandHandlingModel,
  name: 'FormattedTextDisplayWithCommandHandling',
  props: declareProps<FormattedTextDisplayProps>()
}, props => <FormattedTextDisplay {...props} onLinkClicked={props.model.handleLink} />);