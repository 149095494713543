import { invariant as a } from "../../../utils/errors.js";
function v(r, o, i) {
  var f;
  if (typeof r == "string") {
    let u = document;
    o && (a(!!o.current), u = o.current), i ? ((f = i[r]) !== null && f !== void 0 || (i[r] = u.querySelectorAll(r)), r = i[r]) : r = u.querySelectorAll(r);
  } else
    r instanceof Element && (r = [r]);
  return Array.from(r || []);
}
export {
  v as resolveElements
};
