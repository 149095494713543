import * as React from 'react';
import { Container } from '../../Containers';
import { LayoutProps } from '../LayoutProps';
import styles from './Row.module.scss';
export class Row extends React.PureComponent<LayoutProps> {
  render(): JSX.Element {
    const {
      className,
      fluid,
      testId,
      ...newProps
    } = this.props;
    const classes = [fluid ? styles.RowFluid : styles.Row, className].join(' ');
    return <Container className={classes} {...newProps} data-test-id={testId} />;
  }
}