import { PanSession as m } from "./PanSession.js";
import { addPointerEvent as P } from "../../events/add-pointer-event.js";
import { Feature as h } from "../../motion/features/Feature.js";
import { noop as p } from "../../utils/noop.js";
import { getContextWindow as u } from "../../utils/get-context-window.js";
import { frame as r } from "../../frameloop/frame.js";
const s = (e) => (n, o) => {
  e && r.postRender(() => e(n, o));
};
class v extends h {
  constructor() {
    super(...arguments), this.removePointerDownListener = p;
  }
  onPointerDown(n) {
    this.session = new m(n, this.createPanHandlers(), {
      transformPagePoint: this.node.getTransformPagePoint(),
      contextWindow: u(this.node)
    });
  }
  createPanHandlers() {
    const { onPanSessionStart: n, onPanStart: o, onPan: i, onPanEnd: t } = this.node.getProps();
    return {
      onSessionStart: s(n),
      onStart: s(o),
      onMove: i,
      onEnd: (a, d) => {
        delete this.session, t && r.postRender(() => t(a, d));
      }
    };
  }
  mount() {
    this.removePointerDownListener = P(this.node.current, "pointerdown", (n) => this.onPointerDown(n));
  }
  update() {
    this.session && this.session.updateHandlers(this.createPanHandlers());
  }
  unmount() {
    this.removePointerDownListener(), this.session && this.session.end();
  }
}
export {
  v as PanGesture
};
