import { Feature as m } from "../Feature.js";
import { observeIntersection as f } from "./observers.js";
const w = {
  some: 0,
  all: 1
};
class v extends m {
  constructor() {
    super(...arguments), this.hasEnteredView = !1, this.isInView = !1;
  }
  startObserver() {
    this.unmount();
    const { viewport: n = {} } = this.node.getProps(), { root: t, margin: r, amount: s = "some", once: h } = n, u = {
      root: t ? t.current : void 0,
      rootMargin: r,
      threshold: typeof s == "number" ? s : w[s]
    }, c = (i) => {
      const { isIntersecting: e } = i;
      if (this.isInView === e || (this.isInView = e, h && !e && this.hasEnteredView))
        return;
      e && (this.hasEnteredView = !0), this.node.animationState && this.node.animationState.setActive("whileInView", e);
      const { onViewportEnter: p, onViewportLeave: d } = this.node.getProps(), a = e ? p : d;
      a && a(i);
    };
    return f(this.node.current, u, c);
  }
  mount() {
    this.startObserver();
  }
  update() {
    if (typeof IntersectionObserver > "u")
      return;
    const { props: n, prevProps: t } = this.node;
    ["amount", "margin", "root"].some(l(n, t)) && this.startObserver();
  }
  unmount() {
  }
}
function l({ viewport: o = {} }, { viewport: n = {} } = {}) {
  return (t) => o[t] !== n[t];
}
export {
  v as InViewFeature
};
