import * as React from 'react';
import { ContextMenu, ContextMenuProps } from '@shared/components';
import { FlexCol, LineDivider } from '@knuddels/component-library';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { DeleteAlbumPhotoContextMenuEntry, DeleteAlbumPhotoContextMenuEntryProps } from './DeleteAlbumPhotoContextMenuEntry';
import { PhotoAdministrationContextMenu, PhotoAdministrationContextMenuProps } from './PhotoAdministrationContextMenu';
import { EditDescriptionContextMenuEntry } from './EditDescriptionContextMenuEntry';
import { DeleteProfilePictureContextMenuEntry } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfilePictureContextMenu/DeleteProfilePictureContextMenuEntry';
import { EditProfilePictureContextMenuEntry } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfilePictureContextMenu/EditProfilePictureContextMenuEntry';
export type SpecificAlbumDetailsContextMenuProps = {
  isOwner: boolean;
  photoKind: 'profile' | 'album';
  startEditingDescription: () => void;
} & DeleteAlbumPhotoContextMenuEntryProps & PhotoAdministrationContextMenuProps;
export const AlbumDetailsOverflowContextMenu: React.FC<ContextMenuProps & SpecificAlbumDetailsContextMenuProps> = props => {
  const closeContextMenu = useCloseThisOverlay();
  return <ContextMenu {...props} closeContextMenu={closeContextMenu}>
			{props.isOwner ? <>
					<EditDescriptionContextMenuEntry startEditingDescription={props.startEditingDescription} />
					<div className={_c0}>
						<LineDivider color={'hairlineAtom'} className={_c1} />
					</div>
					{props.photoKind === 'album' ? <DeleteAlbumPhotoContextMenuEntry photoId={props.photoId} updateProfileAlbumInfo={props.updateProfileAlbumInfo} /> : <>
							<EditProfilePictureContextMenuEntry />
							<DeleteProfilePictureContextMenuEntry />
						</>}
					{!!props.administrationUrl && <div className={_c2}>
							<LineDivider color={'hairlineAtom'} className={_c3} />
						</div>}
				</> : null}
			{!!props.administrationUrl && <PhotoAdministrationContextMenu administrationUrl={props.administrationUrl} />}
		</ContextMenu>;
};
const _c0 = " Knu-FlexCol my-small ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol my-small ";
const _c3 = "  ";