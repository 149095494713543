import { $NativeBackgroundStore } from '@knuddels-app/NativeBackground/serviceIds';
import { useService } from '@knuddels-app/DependencyInjection';
import { useEffect } from 'react';
import { BackgroundType } from '../interfaces';

export function useNativeBackground(color: string, type: BackgroundType): void {
	const nativeBackgroundStore = useService($NativeBackgroundStore);

	useEffect(() => {
		nativeBackgroundStore.setTopBackground(color, type);
	}, [color]);
}
