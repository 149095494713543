import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';
import bigHello from '@shared/icons/icon-bighello.gif';

export const SnackbarDefinitionArchiveConversation: SnackbarData = {
	type: 'archiveConversation',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_ARCHIVE_CONVERSATION_TEXT',
				defaultFormat:
					'The conversation has been moved to your archive.',
			})
		),
	adornment: bigHello,
};
