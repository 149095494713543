import { PanSession as M } from "../pan/PanSession.js";
import { getGlobalLock as S } from "./utils/lock.js";
import { isRefObject as P } from "../../utils/is-ref-object.js";
import { addPointerEvent as b } from "../../events/add-pointer-event.js";
import { applyConstraints as B, calcRelativeConstraints as k, resolveDragElastic as j, calcViewportConstraints as T, rebaseAxisConstraints as w, calcOrigin as G, defaultElastic as W } from "./utils/constraints.js";
import { createBox as R } from "../../projection/geometry/models.js";
import { eachAxis as u } from "../../projection/utils/each-axis.js";
import { measurePageBox as _ } from "../../projection/utils/measure.js";
import { extractEventInfo as O } from "../../events/event-info.js";
import { convertBoxToBoundingBox as U, convertBoundingBoxToBox as z } from "../../projection/geometry/conversion.js";
import { addDomEvent as F } from "../../events/add-dom-event.js";
import { calcLength as I } from "../../projection/geometry/delta-calc.js";
import { mixNumber as x } from "../../utils/mix/number.js";
import { percent as K } from "../../value/types/numbers/units.js";
import { animateMotionValue as N } from "../../animation/interfaces/motion-value.js";
import { getContextWindow as $ } from "../../utils/get-context-window.js";
import { addValueToWillChange as L } from "../../value/use-will-change/add-will-change.js";
import { frame as y } from "../../frameloop/frame.js";
const q = /* @__PURE__ */ new WeakMap();
class pt {
  constructor(t) {
    this.openGlobalLock = null, this.isDragging = !1, this.currentDirection = null, this.originPoint = { x: 0, y: 0 }, this.constraints = !1, this.hasMutatedConstraints = !1, this.elastic = R(), this.visualElement = t;
  }
  start(t, { snapToCursor: i = !1 } = {}) {
    const { presenceContext: n } = this.visualElement;
    if (n && n.isPresent === !1)
      return;
    const s = (e) => {
      const { dragSnapToOrigin: r } = this.getProps();
      r ? this.pauseAnimation() : this.stopAnimation(), i && this.snapToCursor(O(e, "page").point);
    }, a = (e, r) => {
      var m;
      const { drag: p, dragPropagation: d, onDragStart: v } = this.getProps();
      if (p && !d && (this.openGlobalLock && this.openGlobalLock(), this.openGlobalLock = S(p), !this.openGlobalLock))
        return;
      this.isDragging = !0, this.currentDirection = null, this.resolveConstraints(), this.visualElement.projection && (this.visualElement.projection.isAnimationBlocked = !0, this.visualElement.projection.target = void 0), u((C) => {
        let E = this.getAxisMotionValue(C).get() || 0;
        if (K.test(E)) {
          const { projection: D } = this.visualElement;
          if (D && D.layout) {
            const V = D.layout.layoutBox[C];
            V && (E = I(V) * (parseFloat(E) / 100));
          }
        }
        this.originPoint[C] = E;
      }), v && y.postRender(() => v(e, r)), (m = this.removeWillChange) === null || m === void 0 || m.call(this), this.removeWillChange = L(this.visualElement, "transform");
      const { animationState: f } = this.visualElement;
      f && f.setActive("whileDrag", !0);
    }, o = (e, r) => {
      const { dragPropagation: m, dragDirectionLock: p, onDirectionLock: d, onDrag: v } = this.getProps();
      if (!m && !this.openGlobalLock)
        return;
      const { offset: f } = r;
      if (p && this.currentDirection === null) {
        this.currentDirection = H(f), this.currentDirection !== null && d && d(this.currentDirection);
        return;
      }
      this.updateAxis("x", r.point, f), this.updateAxis("y", r.point, f), this.visualElement.render(), v && v(e, r);
    }, l = (e, r) => this.stop(e, r), c = () => u((e) => {
      var r;
      return this.getAnimationState(e) === "paused" && ((r = this.getAxisMotionValue(e).animation) === null || r === void 0 ? void 0 : r.play());
    }), { dragSnapToOrigin: h } = this.getProps();
    this.panSession = new M(t, {
      onSessionStart: s,
      onStart: a,
      onMove: o,
      onSessionEnd: l,
      resumeAnimation: c
    }, {
      transformPagePoint: this.visualElement.getTransformPagePoint(),
      dragSnapToOrigin: h,
      contextWindow: $(this.visualElement)
    });
  }
  stop(t, i) {
    var n;
    (n = this.removeWillChange) === null || n === void 0 || n.call(this);
    const s = this.isDragging;
    if (this.cancel(), !s)
      return;
    const { velocity: a } = i;
    this.startAnimation(a);
    const { onDragEnd: o } = this.getProps();
    o && y.postRender(() => o(t, i));
  }
  cancel() {
    this.isDragging = !1;
    const { projection: t, animationState: i } = this.visualElement;
    t && (t.isAnimationBlocked = !1), this.panSession && this.panSession.end(), this.panSession = void 0;
    const { dragPropagation: n } = this.getProps();
    !n && this.openGlobalLock && (this.openGlobalLock(), this.openGlobalLock = null), i && i.setActive("whileDrag", !1);
  }
  updateAxis(t, i, n) {
    const { drag: s } = this.getProps();
    if (!n || !A(t, s, this.currentDirection))
      return;
    const a = this.getAxisMotionValue(t);
    let o = this.originPoint[t] + n[t];
    this.constraints && this.constraints[t] && (o = B(o, this.constraints[t], this.elastic[t])), a.set(o);
  }
  resolveConstraints() {
    var t;
    const { dragConstraints: i, dragElastic: n } = this.getProps(), s = this.visualElement.projection && !this.visualElement.projection.layout ? this.visualElement.projection.measure(!1) : (t = this.visualElement.projection) === null || t === void 0 ? void 0 : t.layout, a = this.constraints;
    i && P(i) ? this.constraints || (this.constraints = this.resolveRefConstraints()) : i && s ? this.constraints = k(s.layoutBox, i) : this.constraints = !1, this.elastic = j(n), a !== this.constraints && s && this.constraints && !this.hasMutatedConstraints && u((o) => {
      this.constraints !== !1 && this.getAxisMotionValue(o) && (this.constraints[o] = w(s.layoutBox[o], this.constraints[o]));
    });
  }
  resolveRefConstraints() {
    const { dragConstraints: t, onMeasureDragConstraints: i } = this.getProps();
    if (!t || !P(t))
      return !1;
    const n = t.current, { projection: s } = this.visualElement;
    if (!s || !s.layout)
      return !1;
    const a = _(n, s.root, this.visualElement.getTransformPagePoint());
    let o = T(s.layout.layoutBox, a);
    if (i) {
      const l = i(U(o));
      this.hasMutatedConstraints = !!l, l && (o = z(l));
    }
    return o;
  }
  startAnimation(t) {
    const { drag: i, dragMomentum: n, dragElastic: s, dragTransition: a, dragSnapToOrigin: o, onDragTransitionEnd: l } = this.getProps(), c = this.constraints || {}, h = u((e) => {
      if (!A(e, i, this.currentDirection))
        return;
      let r = c && c[e] || {};
      o && (r = { min: 0, max: 0 });
      const m = s ? 200 : 1e6, p = s ? 40 : 1e7, d = {
        type: "inertia",
        velocity: n ? t[e] : 0,
        bounceStiffness: m,
        bounceDamping: p,
        timeConstant: 750,
        restDelta: 1,
        restSpeed: 10,
        ...a,
        ...r
      };
      return this.startAxisValueAnimation(e, d);
    });
    return Promise.all(h).then(l);
  }
  startAxisValueAnimation(t, i) {
    const n = this.getAxisMotionValue(t);
    return n.start(N(t, n, 0, i, this.visualElement, !1, L(this.visualElement, t)));
  }
  stopAnimation() {
    u((t) => this.getAxisMotionValue(t).stop());
  }
  pauseAnimation() {
    u((t) => {
      var i;
      return (i = this.getAxisMotionValue(t).animation) === null || i === void 0 ? void 0 : i.pause();
    });
  }
  getAnimationState(t) {
    var i;
    return (i = this.getAxisMotionValue(t).animation) === null || i === void 0 ? void 0 : i.state;
  }
  /**
   * Drag works differently depending on which props are provided.
   *
   * - If _dragX and _dragY are provided, we output the gesture delta directly to those motion values.
   * - Otherwise, we apply the delta to the x/y motion values.
   */
  getAxisMotionValue(t) {
    const i = `_drag${t.toUpperCase()}`, n = this.visualElement.getProps(), s = n[i];
    return s || this.visualElement.getValue(t, (n.initial ? n.initial[t] : void 0) || 0);
  }
  snapToCursor(t) {
    u((i) => {
      const { drag: n } = this.getProps();
      if (!A(i, n, this.currentDirection))
        return;
      const { projection: s } = this.visualElement, a = this.getAxisMotionValue(i);
      if (s && s.layout) {
        const { min: o, max: l } = s.layout.layoutBox[i];
        a.set(t[i] - x(o, l, 0.5));
      }
    });
  }
  /**
   * When the viewport resizes we want to check if the measured constraints
   * have changed and, if so, reposition the element within those new constraints
   * relative to where it was before the resize.
   */
  scalePositionWithinConstraints() {
    if (!this.visualElement.current)
      return;
    const { drag: t, dragConstraints: i } = this.getProps(), { projection: n } = this.visualElement;
    if (!P(i) || !n || !this.constraints)
      return;
    this.stopAnimation();
    const s = { x: 0, y: 0 };
    u((o) => {
      const l = this.getAxisMotionValue(o);
      if (l && this.constraints !== !1) {
        const c = l.get();
        s[o] = G({ min: c, max: c }, this.constraints[o]);
      }
    });
    const { transformTemplate: a } = this.visualElement.getProps();
    this.visualElement.current.style.transform = a ? a({}, "") : "none", n.root && n.root.updateScroll(), n.updateLayout(), this.resolveConstraints(), u((o) => {
      if (!A(o, t, null))
        return;
      const l = this.getAxisMotionValue(o), { min: c, max: h } = this.constraints[o];
      l.set(x(c, h, s[o]));
    });
  }
  addListeners() {
    if (!this.visualElement.current)
      return;
    q.set(this.visualElement, this);
    const t = this.visualElement.current, i = b(t, "pointerdown", (c) => {
      const { drag: h, dragListener: e = !0 } = this.getProps();
      h && e && this.start(c);
    }), n = () => {
      const { dragConstraints: c } = this.getProps();
      P(c) && c.current && (this.constraints = this.resolveRefConstraints());
    }, { projection: s } = this.visualElement, a = s.addEventListener("measure", n);
    s && !s.layout && (s.root && s.root.updateScroll(), s.updateLayout()), y.read(n);
    const o = F(window, "resize", () => this.scalePositionWithinConstraints()), l = s.addEventListener("didUpdate", ({ delta: c, hasLayoutChanged: h }) => {
      this.isDragging && h && (u((e) => {
        const r = this.getAxisMotionValue(e);
        r && (this.originPoint[e] += c[e].translate, r.set(r.get() + c[e].translate));
      }), this.visualElement.render());
    });
    return () => {
      o(), i(), a(), l && l();
    };
  }
  getProps() {
    const t = this.visualElement.getProps(), { drag: i = !1, dragDirectionLock: n = !1, dragPropagation: s = !1, dragConstraints: a = !1, dragElastic: o = W, dragMomentum: l = !0 } = t;
    return {
      ...t,
      drag: i,
      dragDirectionLock: n,
      dragPropagation: s,
      dragConstraints: a,
      dragElastic: o,
      dragMomentum: l
    };
  }
}
function A(g, t, i) {
  return (t === !0 || t === g) && (i === null || i === g);
}
function H(g, t = 10) {
  let i = null;
  return Math.abs(g.y) > t ? i = "y" : Math.abs(g.x) > t && (i = "x"), i;
}
export {
  pt as VisualElementDragControls,
  q as elementDragControls
};
