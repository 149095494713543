import {
	DontUseThisMemberInANonTypePositionError,
	BugIndicatingError,
} from '@knuddels/std';
import { observable, when, action } from '@knuddels-app/mobx';
import { FeatureSetting } from './FeatureSetting';

/**
 * Represents a feature setting with multiple valid string values.
 */
export class FeatureEnum<T> extends FeatureSetting {
	public static create<T extends string>(options: {
		/**
		 * A unique id. Must be unique among all feature settings.
		 */
		id: string;
		/**
		 * All valid values.
		 */
		values: T[];
		/**
		 * Default value for production.
		 * If not specified, it falls back to `default`.
		 */
		productionDefault?: T;
		/**
		 * Default value for development.
		 * If not specified, it falls back to `default`.
		 */
		devDefault?: T;
		/**
		 * Default value.
		 * If not specified, the promise will stay pending until a value is set.
		 */
		default?: T;
	}): FeatureEnum<T> {
		return new FeatureEnum(
			options.id,
			new Set(options.values),
			options.default,
			options.productionDefault,
			options.devDefault
		);
	}

	public readonly kind = 'FeatureEnum';

	public get T(): T {
		throw new DontUseThisMemberInANonTypePositionError();
	}

	public readonly default: T | undefined;

	private constructor(
		public readonly id: string,
		public readonly values: Set<T>,
		defaultValue: T | undefined,
		productionDefault: T | undefined,
		devDefault: T | undefined
	) {
		super(id);

		if (import.meta.env.MODE === 'development') {
			this.default = devDefault !== undefined ? devDefault : defaultValue;
		} else if (import.meta.env.MODE === 'production') {
			this.default =
				productionDefault !== undefined
					? productionDefault
					: defaultValue;
		}
	}
}

export interface FeatureEnumState<T extends string> {
	/**
	 * Is `undefined` if still loading.
	 */
	readonly value: T | undefined;
	/**
	 * Throws an exception if it has not been loaded.
	 */
	readonly valueAssertLoaded: T;
	readonly valuePromise: Promise<T>;
}

export class FeatureEnumStateImpl<T extends string>
	implements FeatureEnumState<T>
{
	public readonly valuePromise: Promise<T>;

	@observable
	private _value: T | undefined;

	public get value(): T | undefined {
		return this._value;
	}

	public get valueAssertLoaded(): T {
		const e = this.value;
		if (e === undefined) {
			throw new BugIndicatingError(
				`Flag "${this.featureEnum.id}" was expected to be loaded, but was not!`
			);
		}
		return e;
	}

	constructor(public readonly featureEnum: FeatureEnum<T>) {
		this.valuePromise = when(
			{ name: `Wait for flag "${this.featureEnum.id}" to load` },
			() => this.value,
			{
				resolveTest: value =>
					value !== undefined ? { value } : undefined,
			}
		).then(v => v.value);
	}

	@action
	public setValue(value: T): void {
		if (!this.featureEnum.values.has(value)) {
			throw new BugIndicatingError(
				`Invalid value "${value}" for feature enum "${
					this.featureEnum.id
				}". Valid values are ${[...this.featureEnum.values]
					.map(v => `"${v}"`)
					.join(', ')}`
			);
		}
		this._value = value;
	}
}
