import { createCustomTheme as g } from "./createCustomTheme.js";
import { normal as u } from "../node_modules/color-blend/dist/index.modern.js";
import { createDarkTheme as T } from "./Dark.js";
import { createLightTheme as $ } from "./Light.js";
function L(t, e, r) {
  t = t / 255, e = e / 255, r = r / 255;
  var a = Math.max(t, e, r), s = Math.min(t, e, r), n = (a + s) / 2, o, h;
  if (a == s)
    o = 0, h = 0;
  else {
    var i = a - s;
    switch (h = i / (1 - Math.abs(2 * n - 1)), a) {
      case t:
        o = (e - r) / i, o = (e - r) / i % 6, o = (e - r) / i + (e < r ? 6 : 0);
        break;
      case e:
        o = (r - t) / i + 2;
        break;
      case r:
        o = (t - e) / i + 4;
        break;
    }
  }
  return o = Math.round(o * 60), h = Math.round(h * 100), n = Math.round(n * 100), [o, h, n];
}
const M = (t, e, r) => {
  e /= 100, r /= 100;
  const a = (o) => (o + t / 30) % 12, s = e * Math.min(r, 1 - r), n = (o) => r - s * Math.max(-1, Math.min(a(o) - 3, Math.min(9 - a(o), 1)));
  return [
    Math.round(255 * n(0)),
    Math.round(255 * n(8)),
    Math.round(255 * n(4))
  ];
}, R = /rgba?\((\d+).?,.?(\d+).?,.?(\d+)/, C = (t) => {
  const e = t.match(R);
  return [Number(e[1]), Number(e[2]), Number(e[3])];
}, l = 10, c = (t, e = l, r = 1) => {
  const [a, s, n] = L(t[0], t[1], t[2]), o = M(a, s, n - e);
  return `rgba(${o[0]},${o[1]},${o[2]},${r})`;
}, _ = {
  white: 1,
  880: 0.88,
  660: 0.66,
  440: 0.44,
  220: 0.22,
  160: 0.16,
  150: 0.15,
  140: 0.14,
  120: 0.12,
  110: 0.11,
  "090": 0.09,
  "080": 0.08,
  "070": 0.07,
  "050": 0.05
}, k = {
  black: 1,
  880: 0.88,
  660: 0.66,
  440: 0.44,
  330: 0.33,
  220: 0.22,
  110: 0.11,
  "040": 0.04,
  "020": 0.02
}, A = { r: 18, g: 18, b: 18, a: 1 }, E = { r: 250, g: 250, b: 250, a: 1 }, O = 255, f = 0, p = 0.05, G = 0.03, b = (t, e, r, a, s) => {
  const n = {
    r: t[0],
    g: t[1],
    b: t[2],
    a: e
  }, o = u(r, n);
  return [
    Object.keys(s).reduce((h, i) => {
      const d = s[i], m = u(o, {
        r: a,
        g: a,
        b: a,
        a: d
      });
      return {
        ...h,
        [i]: `rgba(${m.r},${m.g},${m.b},1)`
      };
    }, {}),
    `rgba(${o.r},${o.g},${o.b},${o.a})`
  ];
}, v = (t, e) => {
  const [r, a] = b(
    t,
    p,
    A,
    O,
    _
  );
  return T(
    e,
    { ...r, white: "rgba(255, 255, 255, 1)" },
    a
  );
}, I = (t, e) => {
  const [r, a] = b(
    t,
    G,
    E,
    f,
    k
  );
  return $(
    e,
    { ...r, black: "rgba(0,0,0,1)" },
    a
  );
}, S = (t, e, r = 1) => {
  const a = C(t), s = c(a), n = c(a, 5), o = c(a, 15), h = c(a, 0, 0.6), i = c(a, l, 0.6), d = e.id === "dark" ? v(a, r) : I(a, r);
  return g(d, {
    colors: {
      basic: { accent: t, accentHover: n },
      texts: {
        default: {
          highlighted: t
        },
        alternate: {
          highlighted: t
        }
      },
      icons: {
        default: {
          highlighted: t
        },
        alternate: {
          highlighted: t
        }
      }
    },
    buttons: {
      primary: {
        colors: {
          background: `linear-gradient(to bottom,${t},${s})`,
          hover: `linear-gradient(to bottom,${n},${o})`,
          disabled: `linear-gradient(to bottom,${h},${i})`
        }
      }
    }
  });
};
export {
  S as createThemeForAccentColor
};
