import { isAnimationControls as M } from "../../animation/utils/is-animation-controls.js";
import { isKeyframesTarget as D } from "../../animation/utils/is-keyframes-target.js";
import { shallowCompare as L } from "../../utils/shallow-compare.js";
import { isVariantLabel as z } from "./is-variant-label.js";
import { resolveVariant as j } from "./resolve-dynamic-variants.js";
import { variantPriorityOrder as _ } from "./variant-props.js";
import { animateVisualElement as q } from "../../animation/interfaces/visual-element.js";
const G = [..._].reverse(), J = _.length;
function N(e) {
  return (f) => Promise.all(f.map(({ animation: c, options: v }) => q(e, c, v)));
}
function te(e) {
  let f = N(e), c = O(), v = !0;
  const k = (s) => (i, m) => {
    var l;
    const a = j(e, m, s === "exit" ? (l = e.presenceContext) === null || l === void 0 ? void 0 : l.custom : void 0);
    if (a) {
      const { transition: u, transitionEnd: y, ...g } = a;
      i = { ...i, ...g, ...y };
    }
    return i;
  };
  function B(s) {
    f = s(e);
  }
  function T(s) {
    const i = e.getProps(), m = e.getVariantContext(!0) || {}, l = [], a = /* @__PURE__ */ new Set();
    let u = {}, y = 1 / 0;
    for (let p = 0; p < J; p++) {
      const r = G[p], t = c[r], o = i[r] !== void 0 ? i[r] : m[r], C = z(o), P = r === s ? t.isActive : null;
      P === !1 && (y = p);
      let V = o === m[r] && o !== i[r] && C;
      if (V && v && e.manuallyAnimateOnMount && (V = !1), t.protectedKeys = { ...u }, // If it isn't active and hasn't *just* been set as inactive
      !t.isActive && P === null || // If we didn't and don't have any defined prop for this animation type
      !o && !t.prevProp || // Or if the prop doesn't define an animation
      M(o) || typeof o == "boolean")
        continue;
      let w = Q(t.prevProp, o) || // If we're making this variant active, we want to always make it active
      r === s && t.isActive && !V && C || // If we removed a higher-priority variant (i is in reverse order)
      p > y && C, I = !1;
      const b = Array.isArray(o) ? o : [o];
      let A = b.reduce(k(r), {});
      P === !1 && (A = {});
      const { prevResolvedValues: R = {} } = t, H = {
        ...R,
        ...A
      }, x = (n) => {
        w = !0, a.has(n) && (I = !0, a.delete(n)), t.needsAnimating[n] = !0;
        const d = e.getValue(n);
        d && (d.liveStyle = !1);
      };
      for (const n in H) {
        const d = A[n], S = R[n];
        if (u.hasOwnProperty(n))
          continue;
        let K = !1;
        D(d) && D(S) ? K = !L(d, S) : K = d !== S, K ? d != null ? x(n) : a.add(n) : d !== void 0 && a.has(n) ? x(n) : t.protectedKeys[n] = !0;
      }
      t.prevProp = o, t.prevResolvedValues = A, t.isActive && (u = { ...u, ...A }), v && e.blockInitialAnimation && (w = !1), w && (!V || I) && l.push(...b.map((n) => ({
        animation: n,
        options: { type: r }
      })));
    }
    if (a.size) {
      const p = {};
      a.forEach((r) => {
        const t = e.getBaseTarget(r), o = e.getValue(r);
        o && (o.liveStyle = !0), p[r] = t ?? null;
      }), l.push({ animation: p });
    }
    let g = !!l.length;
    return v && (i.initial === !1 || i.initial === i.animate) && !e.manuallyAnimateOnMount && (g = !1), v = !1, g ? f(l) : Promise.resolve();
  }
  function F(s, i) {
    var m;
    if (c[s].isActive === i)
      return Promise.resolve();
    (m = e.variantChildren) === null || m === void 0 || m.forEach((a) => {
      var u;
      return (u = a.animationState) === null || u === void 0 ? void 0 : u.setActive(s, i);
    }), c[s].isActive = i;
    const l = T(s);
    for (const a in c)
      c[a].protectedKeys = {};
    return l;
  }
  return {
    animateChanges: T,
    setActive: F,
    setAnimateFunction: B,
    getState: () => c,
    reset: () => {
      c = O(), v = !0;
    }
  };
}
function Q(e, f) {
  return typeof f == "string" ? f !== e : Array.isArray(f) ? !L(f, e) : !1;
}
function h(e = !1) {
  return {
    isActive: e,
    protectedKeys: {},
    needsAnimating: {},
    prevResolvedValues: {}
  };
}
function O() {
  return {
    animate: h(!0),
    whileInView: h(),
    whileHover: h(),
    whileTap: h(),
    whileDrag: h(),
    whileFocus: h(),
    exit: h()
  };
}
export {
  Q as checkVariantsDidChange,
  te as createAnimationState
};
