import * as React from 'react';
import { Button, Dialog } from '@knuddels/component-library';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { LightboxBlurBackdrop } from '@shared/components';
export const AdultChannelContent: React.FC<{
  onAccept?: () => void;
  onCancel?: () => void;
  blurBackdrop?: boolean;
}> = ({
  onAccept,
  onCancel,
  blurBackdrop = false
}) => {
  const i18n = useService($I18n);
  const thisOverlay = useService($ThisVisibleOverlay);
  const cancel = () => {
    onCancel?.();
    thisOverlay.dispose();
  };
  const accept = () => {
    onAccept?.();
    thisOverlay.dispose();
  };
  return <LightboxBlurBackdrop enabled={blurBackdrop}>
			<Dialog onClose={cancel} open>
				<Dialog.Content align="center">
					<Dialog.Icon src={require('@shared/components/assets/shared/sm_advent-2013_kissenschlacht.gif')} alt={''} />
					<Dialog.Title>
						{i18n.format(declareFormat({
            id: 'adultChannel.title',
            defaultFormat: 'This is a erotic channel'
          }))}
					</Dialog.Title>
					<Dialog.Body>
						{i18n.format(declareFormat({
            id: 'adultChannel.text',
            defaultFormat: 'You may receive requests that appear as inappropriate in this channel.'
          }))}
					</Dialog.Body>
				</Dialog.Content>
				<Dialog.Footer>
					<Button type="secondary" onPress={cancel}>
						{i18n.format(declareFormat({
            id: 'adultChannel.cancel',
            defaultFormat: 'Cancel'
          }))}
					</Button>
					<Button onPress={accept}>
						{i18n.format(declareFormat({
            id: 'adultChannel.join',
            defaultFormat: 'Join'
          }))}
					</Button>
				</Dialog.Footer>
			</Dialog>
		</LightboxBlurBackdrop>;
};