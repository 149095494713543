import { $Environment } from '@knuddels-app/Environment';

export function getAndRemoveSearchParam(
	environment: typeof $Environment.T,
	name: string
): string | null {
	const searchParams = new URLSearchParams(window.location.search);
	const value = searchParams.get(name);
	if (value) {
		searchParams.delete(name);
		environment.history.replace({
			search: searchParams.toString(),
		});
	}
	return value;
}
