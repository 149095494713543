import { jsx as l } from "react/jsx-runtime";
import { Flex as r } from "../Flex.js";
const n = (o) => /* @__PURE__ */ l(
  r,
  {
    touchAction: o.scrollDirection === "horizontal" ? "pan-x" : "pan-y",
    flexDirection: o.scrollDirection === "horizontal" ? "row" : "column",
    ...o,
    overflow: o.disableScroll ? "visible" : "auto",
    onScroll: o.onScroll
  }
);
export {
  n as ScrollView
};
