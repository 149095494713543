import * as React from 'react';
import { NotificationBadge } from '@shared/components';
import { BoxProps, FlexCol, ThemeOverride } from '@knuddels/component-library';
export function EntryNotificationBadge(props: {
  text: string;
  isStAppMessengerOverview: boolean;
  isWarn?: boolean;
  paddingBottom: number;
}): React.ReactElement {
  return <div
  // @ts-expect-error Not exposed
  style={{
    ...styles.badge(props.paddingBottom)
  }} className={_c0}>
			<NotificationBadge colorPreset={props.isWarn ? 'warning' : props.isStAppMessengerOverview ? 'stapp' : 'accent'} number={props.text} />
		</div>;
}
const styles = {
  badge: (defaultPadding: number) => ({
    position: 'absolute',
    right: (0 as any),
    bottom: (defaultPadding as ThemeOverride)
  } as BoxProps)
};
const _c0 = " Knu-FlexCol ";