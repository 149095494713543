import * as React from 'react';
import { User } from '@generated/graphql';
import { createAccessiblePointerEventHandler, FlexCol, Image, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { FormattedTextDisplay, Spacer } from '@shared/components';
import { MessengerMessageProps } from './MessengerMessageProps';
import { useOpenAlbumDetails } from '@knuddelsModules/Profile';
import { usePhotoCommentText } from '../../usePhotoCommentText';
export const PhotoCommentMessageContent: React.FC<{
  receiverId: string;
  receiverNick: string;
  activeUserId: User['id'];
  message: MessengerMessageProps['message'];
}> = ({
  receiverId,
  receiverNick,
  activeUserId,
  message
}) => {
  const openAlbumDetails = useOpenAlbumDetails();

  // This shouldn't happen as new messages are always visible
  if (message.content.__typename !== 'ConversationVisiblePhotoCommentMessageContent') {
    return null;
  }
  const otherNick = activeUserId === message.sender.id ? receiverNick : message.sender.nick;
  const commentText = usePhotoCommentText(message.content, message.sender.id, otherNick);
  const albumPhotoId = message.content.albumPhotoId;
  return <div className={_c0}>
			<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: () => {
        openAlbumDetails({
          userId: receiverId,
          photoId: albumPhotoId
        });
      }
    })} className={_c1}>
				<Image src={message.content.photoUrl} alt={''} className={_c2} />
			</div>
			<Spacer size={'tiny'} />
			<div className={_c3}>
				<FormattedTextDisplay textProps={{}} text={commentText} />
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol maxWidth-276px ";
const _c1 = " Knu-FlexCol cursor-pointer ";
const _c2 = " size-276px maxWidth-100-percent ";
const _c3 = " Knu-FlexCol ";