import { User } from '@generated/graphql';
import * as React from 'react';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { createAbstractMessage } from '@shared/components';
import { FlexCol, toPointerHandler } from '@knuddels/component-library';
export const Message = createAbstractMessage<{
  onImageClick?: () => void;
  uid?: User['id'];
}>(props => <div onClick={toPointerHandler(props.onImageClick)} className={_c0}>
		<UserImage shadow uid={props.uid} type={UserImageType.CHAT} highlight={'disabled'} />
	</div>);
const _c0 = " Knu-FlexCol alignSelf-flex-start overflow-visible ";