import { useService } from '@knuddels-app/DependencyInjection';
import { autorun } from '@knuddels-app/mobx';
import * as React from 'react';
import { $ActiveChannelService } from '../../../providedServiceIds';
import throttle from 'lodash/throttle';
export const useDelayedsActiveChannelUserCount = () => {
  const [userCount, setUserCount] = React.useState(0);
  const activeChannelService = useService($ActiveChannelService);
  const activeChannel = activeChannelService.activeChannel.id;
  const updateUserCount = React.useCallback(throttle((count: number) => {
    setUserCount(count);
  }, 5000, {
    leading: true,
    trailing: false
  }), [activeChannel]);
  React.useEffect(() => {
    updateUserCount(activeChannelService.activeChannel?.participants.size ?? 0);
  }, [activeChannel]);
  React.useEffect(() => {
    const disposer = autorun({
      name: 'update active channel user-count'
    }, () => {
      const newCount = activeChannelService.activeChannel?.participants.size ?? 0;
      if (newCount !== userCount) {
        updateUserCount(newCount);
      }
    });
    return () => {
      disposer();
    };
  }, [userCount]);
  return userCount;
};