import { isNone as h } from "../../animation/utils/is-none.js";
import { getVariableValue as y } from "./utils/css-variables-conversion.js";
import { isCSSVariableToken as p } from "./utils/is-css-variable.js";
import { positionalKeys as g, isNumOrPxType as l, positionalValues as u } from "./utils/unit-conversion.js";
import { findDimensionValueType as d } from "./value-types/dimensions.js";
import { KeyframeResolver as c } from "../utils/KeyframesResolver.js";
import { makeNoneKeyframesAnimatable as K } from "../html/utils/make-none-animatable.js";
class b extends c {
  constructor(e, t, n, r) {
    super(e, t, n, r, r == null ? void 0 : r.owner, !0);
  }
  readKeyframes() {
    const { unresolvedKeyframes: e, element: t, name: n } = this;
    if (!t.current)
      return;
    super.readKeyframes();
    for (let s = 0; s < e.length; s++) {
      let i = e[s];
      if (typeof i == "string" && (i = i.trim(), p(i))) {
        const m = y(i, t.current);
        m !== void 0 && (e[s] = m), s === e.length - 1 && (this.finalKeyframe = i);
      }
    }
    if (this.resolveNoneKeyframes(), !g.has(n) || e.length !== 2)
      return;
    const [r, f] = e, o = d(r), a = d(f);
    if (o !== a)
      if (l(o) && l(a))
        for (let s = 0; s < e.length; s++) {
          const i = e[s];
          typeof i == "string" && (e[s] = parseFloat(i));
        }
      else
        this.needsMeasurement = !0;
  }
  resolveNoneKeyframes() {
    const { unresolvedKeyframes: e, name: t } = this, n = [];
    for (let r = 0; r < e.length; r++)
      h(e[r]) && n.push(r);
    n.length && K(e, n, t);
  }
  measureInitialState() {
    const { element: e, unresolvedKeyframes: t, name: n } = this;
    if (!e.current)
      return;
    n === "height" && (this.suspendedScrollY = window.pageYOffset), this.measuredOrigin = u[n](e.measureViewportBox(), window.getComputedStyle(e.current)), t[0] = this.measuredOrigin;
    const r = t[t.length - 1];
    r !== void 0 && e.getValue(n, r).jump(r, !1);
  }
  measureEndState() {
    var e;
    const { element: t, name: n, unresolvedKeyframes: r } = this;
    if (!t.current)
      return;
    const f = t.getValue(n);
    f && f.jump(this.measuredOrigin, !1);
    const o = r.length - 1, a = r[o];
    r[o] = u[n](t.measureViewportBox(), window.getComputedStyle(t.current)), a !== null && this.finalKeyframe === void 0 && (this.finalKeyframe = a), !((e = this.removedTransforms) === null || e === void 0) && e.length && this.removedTransforms.forEach(([s, i]) => {
      t.getValue(s).set(i);
    }), this.resolveNoneKeyframes();
  }
}
export {
  b as DOMKeyframesResolver
};
