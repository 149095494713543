import { useContext as g, useRef as o, useInsertionEffect as y } from "react";
import { isMotionValue as f } from "./utils/is-motion-value.js";
import { useMotionValue as S } from "./use-motion-value.js";
import { MotionConfigContext as V } from "../context/MotionConfigContext.js";
import { useIsomorphicLayoutEffect as d } from "../utils/use-isomorphic-effect.js";
import { animateValue as h } from "../animation/animators/MainThreadAnimation.js";
import { frame as x, frameData as A } from "../frameloop/frame.js";
function c(t) {
  return typeof t == "number" ? t : parseFloat(t);
}
function k(t, i = {}) {
  const { isStatic: p } = g(V), n = o(null), e = S(f(t) ? c(t.get()) : t), a = o(e.get()), u = o(() => {
  }), l = () => {
    const r = n.current;
    r && r.time === 0 && r.sample(A.delta), m(), n.current = h({
      keyframes: [e.get(), a.current],
      velocity: e.getVelocity(),
      type: "spring",
      restDelta: 1e-3,
      restSpeed: 0.01,
      ...i,
      onUpdate: u.current
    });
  }, m = () => {
    n.current && n.current.stop();
  };
  return y(() => e.attach((r, s) => p ? s(r) : (a.current = r, u.current = s, x.update(l), e.get()), m), [JSON.stringify(i)]), d(() => {
    if (f(t))
      return t.on("change", (r) => e.set(c(r)));
  }, [e]), e;
}
export {
  k as useSpring
};
