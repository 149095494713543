import { createProjectionNode as t } from "./create-projection-node.js";
import { DocumentProjectionNode as n } from "./DocumentProjectionNode.js";
const e = {
  current: void 0
}, i = t({
  measureScroll: (o) => ({
    x: o.scrollLeft,
    y: o.scrollTop
  }),
  defaultParent: () => {
    if (!e.current) {
      const o = new n({});
      o.mount(window), o.setOptions({ layoutScroll: !0 }), e.current = o;
    }
    return e.current;
  },
  resetTransform: (o, r) => {
    o.style.transform = r !== void 0 ? r : "none";
  },
  checkIsScrollRoot: (o) => window.getComputedStyle(o).position === "fixed"
});
export {
  i as HTMLProjectionNode,
  e as rootProjectionNode
};
