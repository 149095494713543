import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PlaceholderContent } from '@shared/components';
export const NoFriendsPlaceholder: React.FC<{
  nick: string;
  fullHeight: boolean;
}> = ({
  nick,
  fullHeight
}) => <PlaceholderContent image={require('@shared/images/sm_abo_18-06_guineapig-nohat-ani.gif')} imageWidth={60} imageHeight={34} title={nick} fullHeight={fullHeight} description={<FormattedMessage id={declareFormat({
  id: 'profile.friends.empty',
  defaultFormat: 'has no friends yet'
})} />} />;