import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	$LocalStorage,
	createJsonSerializer,
	LocalStorageKey,
} from '@knuddels-app/local-storage';
import { action, observable } from '@knuddels-app/mobx';
import { OS, os } from '@shared/components/tools/os';
import { KnownEndpointId } from './KnownEndpoints';

const allowedServerStorageKey = new LocalStorageKey({
	name: 'allowedServer',
	serializer: createJsonSerializer({ defaultValue: [] as KnownEndpointId[] }),
	cookieExpires: { inDays: 365 },
});

@injectable()
export class AllowedServerAccessStore {
	private static Active = os !== OS.web;

	@observable
	private _speciallyAllowedServers: KnownEndpointId[] = [];

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		if (AllowedServerAccessStore.Active) {
			this.loadSpeciallyAllowedServers();
		}
	}

	public get speciallyAllowedServers(): KnownEndpointId[] {
		return this._speciallyAllowedServers;
	}

	@action('Add access to server')
	public addAllowedServer(server: KnownEndpointId): void {
		if (
			!AllowedServerAccessStore.Active ||
			this._speciallyAllowedServers.indexOf(server) > -1
		) {
			return;
		}
		this._speciallyAllowedServers.push(server);

		this.localStorage
			.getEntry(allowedServerStorageKey)
			.set(this._speciallyAllowedServers);
	}

	private loadSpeciallyAllowedServers(): void {
		this._speciallyAllowedServers =
			this.localStorage.getEntry(allowedServerStorageKey).get() || [];
	}
}
