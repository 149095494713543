import { $ClientInfoStore } from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { $LocalStorage, LocalStorageKey } from '@knuddels-app/local-storage';
import { $OverlayService } from '@knuddels-app/overlays';
import { Disposable } from '@knuddels/std';
import * as React from 'react';
import { OnboardingModal } from '../components/OnboardingModal';
import { $FeatureFlagsService } from '@knuddelsModules/FeatureFlags';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { newReleaseInfoItems } from '../components/newReleaseInfoItems';
import { newFeaturesOnboardingItems } from '../components/newFeaturesOnboardingItems';
import { NewFeatureInfo } from '../components/InfoBox';
import { getDateDifference } from '@knuddels-app/tools/getDateDifference';
import { getPlatform } from '@knuddels-app/tools/clientInformation/platform';
const onboardingVersionStorageKey = LocalStorageKey.withJsonSerializer<number | undefined>({
  name: 'onboarding.shownModalVersion',
  cookieExpires: {
    inDays: 365
  }
});
const onboardingFeaturesStorageKey = LocalStorageKey.withJsonSerializer<string[] | undefined>({
  name: 'onboarding.shownForFeatures',
  cookieExpires: {
    inDays: 365
  }
});

/**
 * Used to determine if the modal has been shown before.
 * Has to be monotonically increased with every change to the
 * modal contents that should be visible to users who already
 * saw the modal in the past.
 */
const MODAL_VERSION = 7;
@injectable()
export class OnboardingService {
  public readonly dispose = Disposable.fn();
  private readonly isOnboardingApplicable = globalEnv.product === 'app';
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($LocalStorage)
  private readonly localStorage: typeof $LocalStorage.T, @inject($FeatureService)
  private readonly featureService: typeof $FeatureService.T, @inject($FeatureFlagsService)
  private readonly featureFlagsService: typeof $FeatureFlagsService.T, @inject($ClientInfoStore)
  private readonly clientInfoStore: typeof $ClientInfoStore.T) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de: require('../i18n/formats.de.json'),
      en: require('../i18n/formats.en.json')
    } as any)[locale.language]);
    this.init();
  }
  private async init(): Promise<void> {
    // ensure flags are available
    await this.featureFlagsService.deferredReady.promise;
    if (this.shouldShowNewReleaseModal()) {
      await this.showNewReleaseModal();
    }
    if (this.shouldShowNewFeaturesModal()) {
      await this.showNewFeaturesModal();
    }
  }
  private async showNewFeaturesModal(): Promise<void> {
    const alreadySeenFeatures = this.localStorage.getEntry(onboardingFeaturesStorageKey).get();
    const featuresToBeShown = this.getFeaturesToBeShown(alreadySeenFeatures);
    const featuresToBeShownIds = featuresToBeShown.map(item => item.featureFlag.id);
    const featuresToBeShownItems = featuresToBeShown.map(feat => feat.items).reduce((a, b) => a.concat(b), []);
    await this.overlayService.showOverlay({
      view: <OnboardingModal infoItems={featuresToBeShownItems} />,
      keepOnLocationChange: true
    }).onClosed;
    this.localStorage.getEntry(onboardingFeaturesStorageKey).set([...alreadySeenFeatures, ...featuresToBeShownIds]);
  }
  private async showNewReleaseModal(): Promise<void> {
    this.setOnboardingDone();
    await this.overlayService.showOverlay({
      view: <OnboardingModal infoItems={newReleaseInfoItems} />,
      keepOnLocationChange: true
    }).onClosed;
  }
  private shouldShowNewReleaseModal(): boolean {
    if (!this.isOnboardingApplicable) {
      return false;
    }
    return !this.isOnboardingDone();
  }
  private shouldShowNewFeaturesModal(): boolean {
    if (!this.isOnboardingApplicable) {
      return false;
    }
    const alreadySeenFeatures = this.localStorage.getEntry(onboardingFeaturesStorageKey).get();
    if (!alreadySeenFeatures) {
      this.localStorage.getEntry(onboardingFeaturesStorageKey).set([]);
    }
    const featuresToBeShown = this.getFeaturesToBeShown(alreadySeenFeatures);
    return featuresToBeShown.length > 0;
  }
  private getFeaturesToBeShown(alreadySeenFeatures: string[]): NewFeatureInfo[] {
    const showForPlatform = (platforms: ReturnType<typeof getPlatform>[] | undefined): boolean => {
      if (platforms && platforms.length > 0) {
        return platforms.includes(this.clientInfoStore.clientInfo?.platform);
      }
      return true;
    };
    const showForDate = (endDate: number | undefined): boolean => {
      if (endDate) {
        return getDateDifference(endDate) <= 0;
      }
      return true;
    };
    return newFeaturesOnboardingItems.filter(item => showForPlatform(item.platforms) && !alreadySeenFeatures?.includes(item.featureFlag.id) && this.featureService.getState(item.featureFlag).isEnabled && showForDate(item.endDate));
  }
  private isOnboardingDone(): boolean {
    const version = this.localStorage.getEntry(onboardingVersionStorageKey).get();
    if (!version) {
      // don't show onboarding/news modal on first visit
      this.setOnboardingDone();
      return true;
    }
    return version >= MODAL_VERSION;
  }
  public setOnboardingDone = (): void => {
    if (this.isOnboardingApplicable) {
      this.localStorage.getEntry(onboardingVersionStorageKey).set(MODAL_VERSION);
    }
  };
}