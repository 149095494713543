import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { EditProfileEntry, EditProfileEntryBaseProps } from './EditProfileEntry';
import { action, computed } from '@knuddels-app/mobx';
import { $OverlayService } from '@knuddels-app/overlays';
import { Dropdown, Option } from '@knuddels/component-library';
interface Props<T> extends EditProfileEntryBaseProps {
  choices: {
    label: string;
    value: T;
  }[];
  value?: T;
  // disables the "Not specified" entry and forces to select one.
  forceValue?: boolean;
}
@injectable()
class EditProfileEntryMultiChoiceModel<T extends string = string> {
  constructor(@injectProps()
  public readonly props: Props<T>, @inject($I18n)
  public readonly i18n: typeof $I18n.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {}
  @computed
  public get dropdownChoices(): {
    label: string;
    value: T;
  }[] {
    const choices = [...this.props.choices];
    if (!this.props.forceValue) {
      choices.unshift({
        label: declareFormat({
          id: 'MULTI_CHOICE_NO_VALUE',
          defaultFormat: 'Not specified'
        }).format(this.i18n),
        value: undefined
      });
    }
    return choices;
  }
  public get selectedValue(): T {
    return this.props.value || this.dropdownChoices[0].value;
  }
  @action.bound
  public onValueChange(value: string): void {
    this.props.onValueChanged(this.props.field, value);
  }
}
export const EditProfileEntryMultiChoice = injectedComponent({
  model: EditProfileEntryMultiChoiceModel,
  name: 'EditProfileEntryMultiChoice',
  props: declareProps<Props<any>>()
}, ({
  model,
  image,
  title,
  validity
}) => <EditProfileEntry image={image} valid={validity.type === 'valid'}>
			<Dropdown label={title} placeholder={declareFormat({
    id: 'MULTI_CHOICE_PLACEHOLDER',
    defaultFormat: 'Please select'
  }).format(model.i18n)} value={model.selectedValue} labelStyle={'always-on-top'} onChange={({
    value
  }) => model.onValueChange(value)} {...{
    style: {
      width: '100%'
    }
  }}>
				{model.dropdownChoices.map(choice => <Option key={choice.label} value={choice.value}>
						{choice.label}
					</Option>)}
			</Dropdown>
		</EditProfileEntry>);