import { LineDividerWithText } from '@shared/components';
import { FlexCol, createAccessiblePointerEventHandler, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { CreateNewAlbum } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useRefetchProfile } from '../../../profileQueryHelper';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useMutation } from '@knuddels-app/Connection/client/operationHooks';
import { useTrackOwnProfileView } from '../../../shared/useTrackOwnProfileView';
export const NewAlbumDivider: React.FC<{
  position: number;
}> = ({
  position
}) => {
  const createAlbum = useCreateNewAlbum();
  const i18n = useService($I18n);
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => createAlbum(position)
  })} className={_c0}>
			<LineDividerWithText text={declareFormat({
      id: 'profile.edit.add-new-album',
      defaultFormat: '+ Add album'
    }).format(i18n)} lineStyle={'dashed'} transparentBadge colorPreset={'accent'} />
		</div>;
};
const useCreateNewAlbum = () => {
  const track = useTrackOwnProfileView();
  const snackbarService = useService($SnackbarService);
  const refetchProfile = useRefetchProfile();
  const i18n = useService($I18n);
  const [createAlbum] = useMutation(CreateNewAlbum);
  return (position: number) => {
    track('AlbumEdit_AddAlbum');
    const title = i18n.format(declareFormat({
      id: 'profile.edit.new-album-title',
      defaultFormat: 'New album'
    }));
    createAlbum({
      position,
      title
    }).then(result => {
      if (!result.data) {
        snackbarService.showGenericError();
      }
      switch (result.data.__typename) {
        case 'Album':
          refetchProfile();
          return;
        case 'AlbumLimitReachedError':
          snackbarService.showErrorSnackbarWithDefaults({
            text: declareFormat({
              id: 'profile.edit.new-album.error.limit-reached',
              defaultFormat: 'You have already created the maximum number of albums.'
            }).format(i18n),
            subtext: undefined
          });
          return;
        case 'FakeSuspectError':
          snackbarService.showErrorSnackbarWithDefaults({
            text: declareFormat({
              id: 'profile.edit.new-album.error.fake-suspect',
              defaultFormat: 'You must first verify your profile photo before you can create a new album.'
            }).format(i18n),
            subtext: undefined
          });
          return;
        case 'InternalError':
        default:
          snackbarService.showGenericError();
      }
    });
  };
};
const _c0 = " Knu-FlexCol cursor-pointer ";