import * as React from 'react';
import { ProfileEditor } from './logic/ProfileEditor';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { MutationResult, useMutation } from '@knuddels-app/Connection';
import { DeletePhoto } from '@generated/graphql';
import { runInAction } from '@knuddels-app/mobx';
import { useRefetchProfile, useUpdateProfileAlbums } from '../../../profileQueryHelper';
import { useConfirmDeleteDialog } from '@knuddels-app/shared-hooks/useConfirmDeleteDialog';
import { useTrackOwnProfileView } from '../../../shared/useTrackOwnProfileView';
import { IconTrash } from '@knuddels/component-library';
export const DeletePhotosIcon: React.FC<{
  editor: ProfileEditor;
}> = ({
  editor
}) => {
  const deletePhotos = useDeletePhotos(editor);
  return <IconTrash key={'delete'} size={'large'} onPress={deletePhotos} />;
};
const useDeletePhotos = (editor: ProfileEditor) => {
  const track = useTrackOwnProfileView();
  const [deletePhoto] = useMutation(DeletePhoto);
  const handleRequestErrors = useHandleDeleteRequests();
  const removePhotosOptimistically = useRemovePhotos();
  const openConfirmDialog = useConfirmOverlay();
  return () => {
    track('AlbumEdit_DeleteImage');
    openConfirmDialog({
      onConfirm: () => {
        const photoIds = Array.from(editor.selectedPhotoIds);
        removePhotosOptimistically(photoIds);
        runInAction('clear selected photos', () => editor.selectedPhotoIds.clear());
        const requests = photoIds.map(photoId => deletePhoto({
          photoId
        }).then(result => {
          return result;
        }));
        handleRequestErrors(requests);
      }
    });
  };
};
const useHandleDeleteRequests = () => {
  const refetchProfile = useRefetchProfile();
  const showDeleteFailedSnackbar = useShowDeleteFailedSnackbar();
  return async (requests: Promise<MutationResult<typeof DeletePhoto['TPrimaryResult']>>[]) => {
    const results = await Promise.all(requests);
    const notDeletedPhotos = results.reduce((prev, cur) => {
      if (cur.error || cur.data.__typename !== 'DeleteAlbumPhotoSuccess') {
        return prev + 1;
      }
      return prev;
    }, 0);
    if (notDeletedPhotos > 0) {
      refetchProfile();
      showDeleteFailedSnackbar(notDeletedPhotos, results.length);
    }
  };
};
const useShowDeleteFailedSnackbar = () => {
  const snackbarService = useService($SnackbarService);
  const i18n = useService($I18n);
  return (notDeletedCount: number, allCount: number) => {
    snackbarService.showSnackbar({
      type: 'delete-photo',
      adornment: require('@shared/images/sorry.gif'),
      text: i18n.format(declareFormat({
        id: 'profile.edit.photo-delete-failed-snackbar.text',
        defaultFormat: 'Delete failed'
      })),
      subtext: i18n.format(declareFormat({
        id: 'profile.edit.photo-delete-failed-snackbar.subtext',
        defaultFormat: '{notDeletedCount} / {allCount} photos not deleted'
      }), {
        notDeletedCount: notDeletedCount,
        allCount: allCount
      })
    });
  };
};
const useRemovePhotos = () => {
  const updateProfileAlbums = useUpdateProfileAlbums();
  return (photoIds: string[]) => {
    updateProfileAlbums(oldData => ({
      ...oldData,
      albumPhotos: oldData.albumPhotos.filter(p => !photoIds.includes(p.id)),
      albums: [...oldData.albums.map(album => ({
        ...album,
        albumPhotos: album.albumPhotos.filter(p => !photoIds.includes(p.id))
      }))]
    }));
  };
};
const useConfirmOverlay = () => {
  const i18n = useService($I18n);
  const showConfirmDialog = useConfirmDeleteDialog();
  return ({
    onConfirm
  }: {
    onConfirm(): void;
  }) => {
    showConfirmDialog({
      headline: i18n.format(declareFormat({
        id: 'profile.delete-photos.confirm.headline',
        defaultFormat: 'Delete selection'
      })),
      text: i18n.format(declareFormat({
        id: 'profile.delete-photos.confirm.text',
        defaultFormat: 'Do you really want to delete the selection?'
      })),
      onConfirm: onConfirm
    });
  };
};