import * as React from 'react';
import { Button, Flex, FlexCol } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { smileyTradeAppViewId } from '@knuddelsModules/SystemApps';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { $Environment } from '@knuddels-app/Environment';
export const SmileyTradeButton: React.FC = () => {
  const openSmileyTrade = useOpenSmileyTrade();
  const i18n = useService($I18n);
  return <div className={_c0}>
			<div className={_c1}>
				<Button onPress={openSmileyTrade} type={'secondary'}>
					{i18n.format(declareFormat({
          id: 'smileybox.auction-button',
          defaultFormat: 'Explore more smileys'
        }))}
				</Button>
			</div>
		</div>;
};
const useOpenSmileyTrade = () => {
  if (globalEnv.product === 'stapp-messenger') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const environment = useService($Environment);
    return () => {
      environment.messengerSystemAppInterface.client.executeSlashCommand('/tb');
    };
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const viewService = useService($ViewService);
    return () => {
      viewService.openViewAsOverlay(smileyTradeAppViewId);
    };
  }
};
const _c0 = " Knu-FlexCol py-minor alignItems-center ";
const _c1 = " Knu-Flex ";