import * as React from 'react';
import { Flex, FlexCol, Text } from '@knuddels/component-library';
import { Knuddel, Spacer } from '@shared/components';
import { BasicUser } from '../../../../utils/BasicUser';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { $KnuddelDisplayComponents } from '@knuddelsModules/KnuddelDisplay';
import { NickLink } from '../../../NickLink';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { RadialHeaderMessage } from './RadialHeaderMessage';
import { HeaderIcon } from './HeaderIcon';
import { useTrackConversation } from '../../useTrackConversation';
import { useFormatAmount } from '@knuddels-app/tools/formatAmount';
export const KnuddelReceivedMessage: React.FC<{
  sender: BasicUser;
  knuddelAmount: number;
}> = ({
  sender,
  knuddelAmount
}) => {
  const track = useTrackConversation();
  const formatAmount = useFormatAmount('millionOnly');
  React.useEffect(() => {
    track('SystemMessages_KnuddelMessageShown');
  }, []);
  return <RadialHeaderMessage radialInnerColor={'#FFFFFF'} radialOuterColor={'#FFCB11'} headerIcon={<HeaderIcon src={require('./sm_abo_19-04_kuschelbedarf.gif')} width={52} height={46} top={6} left={13} />}>
			<div className={_c0}>
				<Text className={_c1}>
					<FormattedMessage id={declareFormat({
          id: 'conversation.message.knuddelreceived.knuddeled',
          defaultFormat: '{nick} knuddeled you.'
        })} values={{
          nick: <NickLink userId={sender.id} nick={sender.nick} />
        }} />
				</Text>
				<Text className={_c2}>
					<FormattedMessage id={declareFormat({
          id: 'conversation.message.knuddelreceived.received',
          defaultFormat: 'You receive {amount} {image}'
        })} values={{
          amount: <Text bold={true} className={_c3}>{formatAmount(knuddelAmount)}</Text>,
          image: <Knuddel />
        }} />
				</Text>
				<Spacer size={'small'} />
				<div className={_c4}>
					<Text className={_c5}>
						<FormattedMessage id={declareFormat({
            id: 'conversation.message.knuddelreceived.now',
            defaultFormat: 'You now have'
          })} />
					</Text>
					<Spacer size={'small'} />
					{/* use async LoadService instead of useService to prevent a circular dependencies */}
					<LoadService service={$KnuddelDisplayComponents}>
						{knuddelDisplayComponents => <knuddelDisplayComponents.CurrentUserKnuddelDisplay type={'normal'} background={'light'} numberFormat={'full'} firebaseContentType={'Messenger_Conversation'} firebaseItemId={'SystemMessages_KnuddelMessageBuyKnuddelClicked'} />}
					</LoadService>
				</div>
			</div>
		</RadialHeaderMessage>;
};
const _c0 = " Knu-FlexCol alignItems-center ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = " Knu-Flex alignItems-center maxHeight-22px ";
const _c5 = "  ";