import * as React from 'react';
import { ConversationTitleData } from '../../../utils/getConversationTitle';
import { Flex, Text } from '@knuddels/component-library';
export function EntryTitle(props: {
  title: ConversationTitleData;
  isStAppMessengerOverview: boolean;
  textState: React.ComponentProps<typeof Text>['state'];
}): React.ReactElement {
  const type = props.isStAppMessengerOverview ? 'body2' : 'subtitle';
  return <div className={_c0}>
			{typeof props.title.text === 'string' ? <Text italic={props.title.italic} type={type} state={props.textState} bold={true} numberOfLines={1} className={_c1}>
					{props.title.text}
				</Text> : props.title.text(type, props.textState, true)}
		</div>;
}
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";