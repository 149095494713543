import { complex as n } from "../../../value/types/complex/index.js";
import { filter as a } from "../../../value/types/complex/filter.js";
import { getDefaultValueType as i } from "./defaults.js";
function f(t, o) {
  let e = i(t);
  return e !== a && (e = n), e.getAnimatableNone ? e.getAnimatableNone(o) : void 0;
}
export {
  f as getAnimatableNone
};
