import * as React from 'react';
import { BlurredBackgroundImage } from '@shared/components';
import { FlexCol, Image, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import defaultPic from '@shared/images/nopic.png';
type Props = {
  size: number;
  url?: string;
  blurUrl?: string;
};
export const AlbumPhoto: React.FC<Props> = observer('AlbumPhoto', props => {
  return <div style={{
    minWidth: resolveThemingValue(`${props.size}px`, "sizes", useTheme())
  }} className={_c0}>
			<BlurredBackgroundImage source={props.blurUrl || props.url || defaultPic} />
			<Image width={props.size + 'px'} height={props.size + 'px'} resizeMode={'cover'} {...{
      style: {
        objectPosition: 'center top'
      }
    }} src={props.url || defaultPic} alt={''} />
		</div>;
});
const _c0 = " Knu-FlexCol position-relative overflow-hidden ";