import * as React from 'react';
import { Spacer } from '../../atoms';
import { Flex, Ripple, Text, ThemeOverride } from '@knuddels/component-library';
import { useHovered } from '@knuddels-app/tools/useHovered';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
import { useIsStAppSidebarOverview } from '@knuddels-app/Environment/useIsStAppSidebarOverview';
interface Props {
  disabled?: boolean;
  testId?: string;
  text: string;
  icon?: 'none' | React.ComponentType<any>;
  onClick(): void;
}
export const ContextMenuEntry: React.FC<Props> = ({
  disabled,
  icon,
  text,
  onClick
}) => {
  const {
    hovered,
    bind
  } = useHovered();
  const condensed = useIsStAppSidebarOverview();
  return <Flex {...bind} pr={'minor'} pl={'small'} py={(6 as ThemeOverride)} bg={hovered && !disabled ? 'listHoverAtom' : undefined} cursor={disabled ? 'default' : 'pointer'} onPress={!disabled ? onClick : undefined} alignItems={'center'} position={'relative'}>
			<Ripple />
			<EntryIcon icon={icon ?? 'none'} disabled={disabled} />
			<Text type={condensed ? 'body2' : 'body1'} state={((disabled ? 'disabled' : 'secondary') as TextStates)} className={_c0}>
				{text}
			</Text>
		</Flex>;
};
const EntryIcon: React.FC<{
  icon: 'none' | React.ComponentType<any>;
  disabled?: boolean;
}> = ({
  icon,
  disabled
}) => {
  if (icon === 'none') {
    return <Spacer style={{
      height: 24
    }} size={'large'} />;
  } else {
    const IconComponent = icon;
    return <IconComponent size={'large'} disabled={disabled} />;
  }
};
const _c0 = " ml-small ";