import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { ModalButtons, ModalCard, ModalContentWrapper, ModalWrapper, Spacer } from '@shared/components';
import { Image, LineDivider, Text } from '@knuddels/component-library';
export const NotAllowedToCreateChannelModal: React.FC = () => {
  const visibleOverlayService = useService($ThisVisibleOverlay);
  const i18n = useService($I18n);
  const cancelModal = () => {
    visibleOverlayService.dispose();
  };
  return <ModalWrapper cancelModal={cancelModal} maxWidth={425}>
			<ModalCard>
				<ModalContentWrapper alignCenter>
					<Spacer size={'xxlarge'} />
					<Image src={require('@shared/images/sorry.gif')} alt={''} className={_c0} />
					<Spacer size={'large'} />
					<Text type={'title'} className={_c1}>
						<FormattedMessage id={declareFormat({
            id: 'channellist.search.notallowedtocreatechannel.title',
            defaultFormat: "You can't create a channel yet."
          })} />
					</Text>
					<Spacer size={'base'} />
					<Text state={'secondary'} className={_c2}>
						<FormattedMessage id={declareFormat({
            id: 'channellist.search.notallowedtocreatechannel.description',
            defaultFormat: 'You need the <b>Family status</b> for temporary channels to be able to create.'
          })} values={{
            b: (content: any) => <Text bold={true} state={'secondary'} className={_c3}>
										{content}
									</Text>
          }} />
					</Text>
					<Spacer size={'xlarge'} />
				</ModalContentWrapper>
				<LineDivider className={_c4} />
				<ModalButtons primaryButton={{
        text: declareFormat({
          id: 'channellist.search.notallowedtocreatechannel.button',
          defaultFormat: 'Understood'
        }).format(i18n),
        onClick: cancelModal,
        width: 'fluid'
      }} />
			</ModalCard>
		</ModalWrapper>;
};
const _c0 = " width-35px height-27px ";
const _c1 = " textAlign-center ";
const _c2 = " textAlign-center ";
const _c3 = "  ";
const _c4 = "  ";