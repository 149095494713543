import { useContext as f, useRef as d, useInsertionEffect as g, useEffect as w } from "react";
import { PresenceContext as x } from "../../context/PresenceContext.js";
import { MotionContext as h } from "../../context/MotionContext/index.js";
import { useIsomorphicLayoutEffect as j } from "../../utils/use-isomorphic-effect.js";
import { LazyContext as M } from "../../context/LazyContext.js";
import { MotionConfigContext as S } from "../../context/MotionConfigContext.js";
import { optimizedAppearDataAttribute as H } from "../../animation/optimized-appear/data-id.js";
import { microtask as L } from "../../frameloop/microtask.js";
import { isRefObject as b } from "../../utils/is-ref-object.js";
import { SwitchLayoutGroupContext as I } from "../../context/SwitchLayoutGroupContext.js";
let l = !1;
function T(o, u, n, r, c) {
  const { visualElement: a } = f(h), m = f(M), e = f(x), p = f(S).reducedMotion, i = d();
  r = r || m.renderer, !i.current && r && (i.current = r(o, {
    visualState: u,
    parent: a,
    props: n,
    presenceContext: e,
    blockInitialAnimation: e ? e.initial === !1 : !1,
    reducedMotionConfig: p
  }));
  const t = i.current, y = f(I);
  t && !t.projection && c && (t.type === "html" || t.type === "svg") && k(i.current, n, c, y), g(() => {
    t && t.update(n, e);
  });
  const s = d(!!(n[H] && !window.HandoffComplete));
  return j(() => {
    t && (t.updateFeatures(), L.render(t.render), s.current && t.animationState && t.animationState.animateChanges());
  }), w(() => {
    t && (!s.current && t.animationState && t.animationState.animateChanges(), s.current && (s.current = !1, l || (l = !0, queueMicrotask(R))));
  }), t;
}
function R() {
  window.HandoffComplete = !0;
}
function k(o, u, n, r) {
  const { layoutId: c, layout: a, drag: m, dragConstraints: e, layoutScroll: p, layoutRoot: i } = u;
  o.projection = new n(o.latestValues, u["data-framer-portal-id"] ? void 0 : C(o.parent)), o.projection.setOptions({
    layoutId: c,
    layout: a,
    alwaysMeasureLayout: !!m || e && b(e),
    visualElement: o,
    /**
     * TODO: Update options in an effect. This could be tricky as it'll be too late
     * to update by the time layout animations run.
     * We also need to fix this safeToRemove by linking it up to the one returned by usePresence,
     * ensuring it gets called if there's no potential layout animations.
     *
     */
    animationType: typeof a == "string" ? a : "both",
    initialPromotionConfig: r,
    layoutScroll: p,
    layoutRoot: i
  });
}
function C(o) {
  if (o)
    return o.options.allowProjection !== !1 ? o.projection : C(o.parent);
}
export {
  T as useVisualElement
};
