import { OverlayInfo } from './OverlayService';
import * as React from 'react';
import { DontUseThisMemberInANonTypePositionError } from '@knuddels/std';
interface OverlayFactoryTag<TTagData> {
  data: TTagData;
  factory: OverlayFactory<any, TTagData>;
}

/**
 * Use this if you want to manage overlay instances.
 * You can use tags and the `getFilter` method to test whether a certain overlay is visible.
 */
export class OverlayFactory<TProps, TTag = undefined> {
  private readonly keepOnLocationChange: boolean;
  constructor(private readonly reactElement: React.ComponentType<TProps>, private readonly tagFactory: (props: TProps) => TTag = () => undefined!, options: {
    keepOnLocationChange?: boolean;
  } = {}) {
    this.keepOnLocationChange = !!options.keepOnLocationChange;
  }
  public get tagType(): TTag {
    throw new DontUseThisMemberInANonTypePositionError();
  }
  create(props: TProps): OverlayInfo {
    const ReactElement = this.reactElement;
    return {
      view: <ReactElement {...props} />,
      tag: {
        factory: this,
        data: this.tagFactory(props)
      },
      keepOnLocationChange: this.keepOnLocationChange
    };
  }
  getFilter(predicate?: (tag: TTag) => boolean): (tag: any) => tag is {
    data: TTag;
  } {
    return (tag): tag is {
      data: TTag;
    } => {
      if (typeof tag !== 'object' || !tag) {
        return false;
      }
      if (!('factory' in tag) || !('data' in tag)) {
        return false;
      }
      const t: OverlayFactoryTag<TTag> = tag;
      if (t.factory !== this) {
        return false;
      }
      if (predicate) {
        return predicate(t.data);
      }
      return true;
    };
  }
}