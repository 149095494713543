import { FlexCol, IconCloseCircular, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { Z_INDEX } from '@shared/components';
import { IconWithShadow } from '../shared/IconWithShadow';
export const ProfileCloseIcon: React.FC<{
  onPress: () => void;
}> = ({
  onPress
}) => {
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
  }} className={_c0}>
			<IconWithShadow InnerIcon={IconCloseCircular} onPress={onPress} />
		</div>;
};
const _c0 = " Knu-FlexCol position-absolute left-none top-none ";