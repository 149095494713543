import * as React from 'react';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
import { FlexCol } from '@knuddels/component-library';
import { ADMIN_INFO_APP_ID } from '@shared/constants';
export const AdminInfoApp: React.FC<{
  nick: string;
}> = ({
  nick
}) => {
  return <GenericSystemApp appId={ADMIN_INFO_APP_ID} viewType={'inline'} customSlashCommand={`/ai ${nick}`} skeleton={<div className={_c0} />} />;
};
const _c0 = " Knu-FlexCol width-full height-full ";