import { FullConversationWithoutMessagesFragment, MessengerConversation, MessengerConversationVisibility, Scalars } from '@generated/graphql';
import { declareProps, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { computed } from '@knuddels-app/mobx';
import { formatAmount } from '@knuddels-app/tools/formatAmount';
import { FlexCol, IconTrash, IconUndo, Text, TextProps, ThemeOverride, useTheme } from '@knuddels/component-library';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
import { $MessengerService, $ReadSystemService } from '@knuddelsModules/Messenger';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $UserService } from '@knuddelsModules/UserData';
import { FormattedText, FormattedTextText, Knuddel, Swipeable } from '@shared/components';
import { IconForward } from '@shared/icons/IconForward';
import { IconRead } from '@shared/icons/IconRead';
import { IconReport } from '@shared/icons/IconReport';
import { IconUnread } from '@shared/icons/IconUnread';
import * as React from 'react';
import { BIRTHDAY_1, BIRTHDAY_2 } from '../../i18n/shared-formats';
import { ClientConversationState } from '../../services/conversationServices/MessengerConversationService';
import { ConversationWithLatestMessage, ConversationWithoutLatestMessage } from '../../services/messengerOverviewHelper';
import { ReadStateSource } from '../../utils/ReadStateSource';
import { conversationReadStateToString } from '../../utils/conversationReadStateHelper';
import { useConversationTitle } from '../../utils/getConversationTitle';
import { ImagePlaceholderBadge } from '../MessengerList/ImagePlaceholderBadge';
import { GenericListEntry } from './GenericListEntry/GenericListEntry';
import { IconArchive } from '@shared/icons/IconArchive';
type Props = {
  index: number;
  isActive?: boolean;
  conversationPartner: FullConversationWithoutMessagesFragment['otherParticipants'][0] | undefined;
  isArchive: boolean;
  isContextMenuOpen: boolean;
  isMinifiedOverview: boolean;
  clientConversationState: ClientConversationState;
  hideDivider?: boolean;
  testId?: string;
  openConversation(conversationId: MessengerConversation['id'], index: number): void;
  showContextMenu(x: number, y: number, width: number, height: number, conversationId: MessengerConversation['id']): void;
} & ({
  isInvalidConversation: false;
  conversation: ConversationWithLatestMessage;
} | {
  isInvalidConversation: true;
  conversation: ConversationWithoutLatestMessage;
});
export function getMessengerListEntryHeight(isMini: boolean, isStAppMessengerOverview: boolean): number {
  return isStAppMessengerOverview ? isMini ? 40 : 67 : 94;
}
@injectable()
class Model {
  constructor(@injectProps()
  private readonly props: Props, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T, @inject($FriendRequestsService)
  private readonly friendRequestsService: typeof $FriendRequestsService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($ProfileNavigationService)
  private readonly profileNavigationService: typeof $ProfileNavigationService.T) {}
  @computed
  get userImageUserId(): string | undefined {
    return this.props.conversationPartner && this.props.conversationPartner.id;
  }
  @computed
  get userImageUserNick(): string | undefined {
    return this.props.conversationPartner && this.props.conversationPartner.nick;
  }
  @computed
  get subtitle(): FormattedText | JSX.Element {
    if (this.props.isInvalidConversation) {
      return new FormattedTextText(this.i18n.format(declareFormat({
        id: 'messenger.overview.invalid-conversation.subtitle',
        defaultFormat: 'Problem loading the conversation!'
      })));
    }
    const message = this.props.conversation.latestConversationMessage;
    const content = message.content;
    const isStappMessengerOverview = this.environment.isStappSidebarOverview;
    if (content.__typename === 'ConversationKnuddelTransferMessageContent') {
      const senderIsActiveUser = this.userService.isCurrentUser(message.sender.id);
      const format = senderIsActiveUser ? declareFormat({
        id: 'messenger.overview.subtitle.knuddelsent',
        defaultFormat: 'You sent {amount} {image} to {nick}.'
      }) : declareFormat({
        id: 'messenger.overview.subtitle.knuddelreceived',
        defaultFormat: '{nick} knuddeld you. You receive {amount} {image}'
      });
      return <Text
      // more or less copied from `EntrySubtitle.tsx` to be consistent
      type={isStappMessengerOverview ? 'tiny' : 'body1'} state={this.textState}
      // keep space for unread badge and context menu
      className={_c0 + (isStappMessengerOverview ? _c1 : _c2)}>
					<FormattedMessage id={format} values={{
          nick: this.props.conversationPartner?.nick || '',
          amount: formatAmount(content.knuddelAmount, 'millionOnly', this.i18n),
          image: <Knuddel optimizeForStapp={isStappMessengerOverview} />
        }} />
				</Text>;
    }
    if (content.__typename === 'ConversationBirthdayMessageContent') {
      return <Text
      // more or less copied from `EntrySubtitle.tsx` to be consistent
      type={isStappMessengerOverview ? 'tiny' : 'body1'} state={this.textState}
      // keep space for unread badge and context menu
      className={_c3 + (isStappMessengerOverview ? _c4 : _c5)}>
					<FormattedMessage id={BIRTHDAY_1} values={{
          nick: this.props.conversationPartner?.nick
        }} />{' '}
					<FormattedMessage id={BIRTHDAY_2} />
				</Text>;
    }
    if (content.__typename === 'ConversationNicknameChangeMessageContent') {
      return <Text
      // more or less copied from `EntrySubtitle.tsx` to be consistent
      type={isStappMessengerOverview ? 'tiny' : 'body1'} state={this.textState}
      // keep space for unread badge and context menu
      className={_c6 + (isStappMessengerOverview ? _c7 : _c8)}>
					<FormattedMessage id={declareFormat({
          id: 'conversation.message.nickswitch2',
          defaultFormat: '{oldNick} is now called {newNick}'
        })} values={{
          oldNick: content.oldNick,
          newNick: content.newNick
        }} />{' '}
				</Text>;
    }
    if (content.__typename === 'ConversationForwardedMessageContent') {
      if (content.nestedMessage.content.__typename === 'ConversationTextMessageContent') {
        return FormattedText.fromJsonString(content.nestedMessage.content.formattedText);
      } else {
        return new FormattedTextText('');
      }
    }
    if ('formattedText' in content) {
      return FormattedText.fromJsonString(content.formattedText);
    }
    return new FormattedTextText('');
  }
  @computed
  get subtitleTextPrefix(): React.ReactElement | false {
    return this.hasSnapOrImage && <>
					<ImagePlaceholderBadge />
					<Text className={_c9}> </Text>
				</>;
  }
  @computed
  get subtitleViewPrefix(): React.ReactElement | false {
    if (this.props.isInvalidConversation) {
      return <div className={_c10}>
					<IconReport />
				</div>;
    }
    return this.isForwardedMessage && <div className={_c11 + (!(this.notArchiveButUnread && this.props.isActive) ? _c12 : _c13)}>
					<IconForward />
				</div>;
  }
  @computed
  get time(): Scalars['UtcTimestamp'] | undefined {
    return this.props.isInvalidConversation ? undefined : this.props.conversation.latestConversationMessage.timestamp;
  }
  @computed
  get timeTextState(): TextProps['state'] {
    return this.notArchiveButUnread ? 'highlighted' : 'disabled';
  }
  @computed
  get textState(): TextProps['state'] {
    return this.notArchiveButUnread ? 'primary' : this.props.isActive ? 'secondary' : 'tertiary';
  }
  @computed
  get isSendingMessageUnread(): boolean {
    const sendingMessages = this.props.clientConversationState.sendingMessages;
    return this.props.clientConversationState.isUnread && +sendingMessages[sendingMessages.length - 1].timestamp > +this.props.conversation.latestConversationMessage.timestamp;
  }
  @computed
  get notificationBadgeText(): string | undefined {
    if (this.isSendingMessageUnread) {
      return '!';
    } else {
      return conversationReadStateToString(this.readState);
    }
  }
  @computed
  get readState(): ReadStateSource['readState'] {
    const conversation = this.props.conversation;
    const otherParticipant = conversation.otherParticipants[0];
    const unreadMessageCount = conversation.readState.unreadMessageCount + this.friendRequestsService.friendRequestsForConversation.filter(it => it.user.id === otherParticipant.id).reduce((prev, current) => prev + current.readState.unreadMessageCount, 0);
    return {
      unreadMessageCount,
      markedAsUnread: conversation.readState.markedAsUnread
    };
  }
  @computed
  get badgeType(): 'normal' | 'warning' {
    if (this.isSendingMessageUnread) {
      return 'warning';
    } else {
      return 'normal';
    }
  }
  @computed
  get hideContextMenuChevron(): boolean {
    return this.props.isMinifiedOverview;
  }
  @computed
  get notArchiveButUnread(): boolean {
    if (this.props.isArchive) {
      return false;
    }
    const readState = this.readState;
    return readState.unreadMessageCount > 0 || readState.markedAsUnread || this.props.clientConversationState.isUnread;
  }
  @computed
  get isStAppMessengerOverview(): boolean {
    return this.environment.isStappSidebarOverview;
  }
  readonly onClick = () => {
    this.props.openConversation(this.props.conversation.id, this.props.index);
  };
  readonly onUserImageClick = async () => {
    const messengerSystemApp = this.environment.messengerSystemAppInterface;
    messengerSystemApp ? messengerSystemApp.executeOpenProfileSlashCommand(this.userImageUserNick) : this.profileNavigationService.showProfile(this.userImageUserId);
  };
  readonly showContextMenu = (x: number, y: number, width: number, height: number) => {
    this.props.showContextMenu(x, y, width, height, this.props.conversation.id);
  };
  @computed
  private get hasSnapOrImage(): boolean {
    if (this.props.isInvalidConversation) {
      return false;
    }
    const message = this.props.conversation.latestConversationMessage;
    return message.content.__typename === 'ConversationSnapMessageContent' || message.content.__typename === 'ConversationImageMessageContent' || 'nestedMessage' in message.content && message.content.nestedMessage.content.__typename === 'ConversationImageMessageContent';
  }
  @computed
  private get isForwardedMessage(): boolean {
    if (this.props.isInvalidConversation) {
      return false;
    }
    const message = this.props.conversation.latestConversationMessage;
    return message.content.__typename === 'ConversationForwardedMessageContent';
  }
}
const MessengerListEntryBase = injectedComponent({
  name: 'MessengerListEntry',
  model: Model,
  props: declareProps<Props>()
}, ({
  model,
  ...props
}) => {
  const title = useConversationTitle(props.conversationPartner, true, true);
  const readService = useService($ReadSystemService);
  const messengerService = useService($MessengerService);
  const colors = useTheme().colors.basic;
  const i18n = useService($I18n);
  return <Swipeable key={props.conversation.id} swipeId={props.conversation.id} threshold={200} leftAction={props.conversation.visibility === MessengerConversationVisibility.Archived ? {
    component: () => <ActionContent Icon={IconUndo} text={declareFormat({
      id: 'messenger.overview.swipe.restore',
      defaultFormat: 'Restore'
    }).format(i18n)} />,
    backgroundColor: colors['teal-500'],
    onAction: async cb => {
      const success = await messengerService.restoreConversation(props.conversation.id);
      cb(!success);
    }
  } : {
    component: () => props.conversation.readState.markedAsUnread ? <ActionContent Icon={IconUnread} text={declareFormat({
      id: 'messenger.overview.swipe.read',
      defaultFormat: 'Read'
    }).format(i18n)} /> : <ActionContent Icon={IconRead} text={declareFormat({
      id: 'messenger.overview.swipe.unread',
      defaultFormat: 'Unread'
    }).format(i18n)} />,
    backgroundColor: colors.accent,
    onAction: cb => {
      props.conversation.readState.markedAsUnread ? readService.markConversationsAsRead([props.conversation.id]) : readService.markConversationAsUnread(props.conversation.id);
      cb(true);
    }
  }} rightActionPrimary={{
    component: () => <ActionContent Icon={IconTrash} text={declareFormat({
      id: 'messenger.overview.swipe.remove',
      defaultFormat: 'Remove'
    }).format(i18n)} />,
    backgroundColor: colors['red-500'],
    onAction: async cb => {
      const success = await messengerService.deleteConversation(props.conversation.id);
      cb(!success);
    }
  }} rightActionSecondary={props.conversation.visibility === MessengerConversationVisibility.Visible ? {
    component: () => <ActionContent Icon={IconArchive} text={declareFormat({
      id: 'messenger.overview.swipe.archive',
      defaultFormat: 'Archive'
    }).format(i18n)} />,
    backgroundColor: colors['orange-500'],
    onAction: async cb => {
      const success = await messengerService.archiveConversation(props.conversation.id);
      cb(!success);
    }
  } : undefined}>
				<GenericListEntry isContextMenuOpen={props.isContextMenuOpen} index={props.index} isActive={props.isActive} onClick={model.onClick} onUserImageClick={model.onUserImageClick} userImageUserId={model.userImageUserId} userImageUserNick={model.userImageUserNick} title={title} subtitle={model.subtitle} time={model.time} timeTextState={model.timeTextState} notificationBadgeText={model.notificationBadgeText} showUnreadMarker={model.notArchiveButUnread} subtitleTextPrefix={model.subtitleTextPrefix} subtitleViewPrefix={model.subtitleViewPrefix} hideContextMenuChevron={model.hideContextMenuChevron} showContextMenu={model.showContextMenu} isMinifiedOverview={props.isMinifiedOverview} textState={model.textState} notificationBadgeType={model.badgeType} hideDivider={props.hideDivider} />
			</Swipeable>;
});
const ActionContent: React.FC<{
  Icon: React.ComponentType<React.ComponentProps<typeof IconTrash>>;
  text: string;
}> = ({
  Icon,
  text
}) => {
  return <>
			<Icon size={'large'} active />
			<Text type={'tiny'} bold={true} className={_c14}>
				{text}
			</Text>
		</>;
};
export const MessengerListEntry = React.memo(MessengerListEntryBase);

// tslint:disable-next-line: max-file-line-count
const _c0 = "  ";
const _c1 = " mr-20px ";
const _c2 = " mr-30px ";
const _c3 = "  ";
const _c4 = " mr-20px ";
const _c5 = " mr-30px ";
const _c6 = "  ";
const _c7 = " mr-20px ";
const _c8 = " mr-30px ";
const _c9 = "  ";
const _c10 = " Knu-FlexCol size-16px top-2px mr-tiny opacity-1 ";
const _c11 = " Knu-FlexCol size-16px top-2px mr-tiny ";
const _c12 = " opacity-0-44 ";
const _c13 = " opacity-1 ";
const _c14 = " mt-tiny ";