import { createAccessiblePointerEventHandler as i } from "../../a11y/createAccessiblePointerEventHandler.js";
const t = (e) => ({
  ...i({
    ariaRole: "switch",
    disabled: e.disabled,
    pointerCallback() {
      var a, l;
      (a = e.onChange) == null || a.call(e, { value: !e.value }), (l = e.onBlur) == null || l.call(e);
    }
  }),
  ariaChecked: e.value
});
export {
  t as useToggle
};
