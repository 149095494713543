const myChannelImgToken = '-myc0l0p&';
const cacheKeySep = '\u0000';
const imageUrlFilterCache: { [key: string]: string } = {};

const picsFotoDirectory = 'pics/fotos/';
const chatServer = '//chat.knuddels.de';
const picsFolder = 'pics/';
const picsFolderMinified = 'picsm/';
const proxy = '//cdn.knuddelscom.de/';

export function getChannelPreviewImageUrl(
	input: string | undefined,
	minified: boolean
): string | undefined {
	if (input && input.length > 0 && !input.endsWith('-')) {
		const cacheKey = input + cacheKeySep + minified;

		// return filtered and cached url if present
		if (imageUrlFilterCache[cacheKey] !== undefined) {
			return imageUrlFilterCache[cacheKey]; // return cache hit!
		}

		// FIXME cut "..." parameter
		input = cutPseudoCSS(input);

		if (input.startsWith(picsFotoDirectory)) {
			// replace mychannel large version with small [HACKY VERSION]
			if (minified === true && input.indexOf(myChannelImgToken) >= 0) {
				input = input.replace(myChannelImgToken, '-myc0m0p&');
			}

			input = chatServer + '/' + input;
		} else {
			if (
				!input.startsWith('http://') &&
				!input.startsWith('https://') &&
				!input.startsWith('//')
			) {
				if (minified === true && input.startsWith(picsFolder)) {
					input =
						picsFolderMinified +
						input.substring(picsFolder.length, input.length);
				}

				input = proxy + 'res/' + input;
			}
		}

		if (input !== undefined && input.substring(0, 2) === '//') {
			input = 'https:' + input;
		}

		// cache filtered url to improve performance
		imageUrlFilterCache[cacheKey] = input;

		return input;
	}

	return undefined;
}

export function cutPseudoCSS(imageText: string): string {
	const input = imageText;
	const index = imageText.indexOf('.');
	const endIndex = imageText.lastIndexOf('.');
	if (index > -1 && endIndex > index) {
		const index3 = imageText.indexOf('...');
		if (index3 > -1 && endIndex > index3) {
			imageText = imageText.substring(0, index3);
			if (input.endsWith('.')) {
				const match = /([.][^.]{2,3})[.]{3}/.exec(input);
				if (match != null) {
					const ending = match[1];
					if (!imageText.endsWith(ending)) {
						imageText += ending;
					}
				}
			} else {
				const qmPos = input.indexOf('?');
				if (qmPos > -1) {
					imageText += input.substring(
						input.lastIndexOf('.', qmPos),
						qmPos
					);
				} else {
					imageText += input.substring(endIndex);
				}
			}
		}
	}

	return imageText;
}
