import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { SNACKBAR_GENERIC_ERROR_SUBTEXT } from '@knuddels-app/SnackbarManager/i18n/shared-formats';

import adornment from '@shared/images/sorry.gif';

export function createErrorSnackbarWithDefaults(
	data: Partial<SnackbarData>
): SnackbarData {
	return {
		type: 'genericError',
		text: formatMessage(
			declareFormat({
				id: 'SNACKBAR_GENERIC_ERROR_TEXT',
				defaultFormat: 'Oops - something went wrong.',
			})
		),
		subtext: formatMessage(SNACKBAR_GENERIC_ERROR_SUBTEXT),
		adornment,
		...data,
	};
}
