import { FormattedText, FormattedTextText } from '@shared/components';
import * as React from 'react';
import {
	ConversationMessageContentFragment,
	ConversationNestedMessageContentFragment,
} from '@generated/graphql';

export const useMessageText = (
	content:
		| ConversationMessageContentFragment
		| ConversationNestedMessageContentFragment
) => {
	return React.useMemo(() => {
		if ('formattedText' in content) {
			const text = content.formattedText;
			if (typeof text === 'string') {
				return FormattedText.fromJsonString(text);
			} else {
				// For sending messages
				return (text as unknown) as FormattedText;
			}
		} else {
			return new FormattedTextText('');
		}
	}, [content]);
};
