import { $ViewProvider } from '@knuddels-app/layout';
import { Module } from '@knuddels-app/ModuleSystem';
import { adsModule } from '@knuddelsModules/Ads/module';
import { ChannelViewProvider } from '@knuddelsModules/Channel/ChannelViewProvider';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import { messengerModule } from '@knuddelsModules/Messenger/module';
import { settingsModule } from '@knuddelsModules/Settings/module';
import { smileyboxModule } from '@knuddelsModules/Smileybox/module';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { contactsModule } from '@knuddelsModules/Contacts/module';
import { autocompleteInputBarModule } from '@knuddelsModules/AutocompleteInputBar/module';
import { appsModule } from '@knuddelsModules/Apps/module';
import { ChannelNavBarItemProvider } from './ChannelNavBarItemProvider';
import * as providedServices from './providedServiceIds';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { promotionModule } from '@knuddelsModules/Promotions/module';
import { systemAppsModule } from '@knuddelsModules/SystemApps/module';

export const channelModule = new Module({
	name: 'ChannelModule',
	dependencies: [
		userDataModule,
		messengerModule,
		smileyboxModule,
		adsModule,
		settingsModule,
		contactsModule,
		autocompleteInputBarModule,
		appsModule,
		notificationsModule,
		promotionModule,
		systemAppsModule,
	],
	loadEvent: globalEnv.product === 'stapp-messenger' ? 'onDemand' : 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	providedServices,
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(ChannelViewProvider);
		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toSingleton(ChannelNavBarItemProvider);
	},
});
