import { Omit } from '@knuddels-app/tools/types';
import {
	Disposable,
	err,
	EventEmitter,
	ok,
	Result,
	ResultPromise,
	select,
} from '@knuddels/std';
import {
	ApolloClient,
	FetchPolicy,
	FetchResult,
	NormalizedCacheObject,
	Observer,
} from '@apollo/client';
import { action, observable } from '@knuddels-app/mobx';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { K3ObservableQuery } from './K3ObservableQuery';

import {
	TypedMutationOperation,
	TypedQueryOperation,
	TypedSubscriptionOperation,
} from './Operation';

/**
 * Normally we would like to omit error if `T extends { error: any }` but that didn't work in all cases.
 * Always using Omit will result in problems with enums. That's why we don't use Omit for strings or arrays.
 */
type RemoveErrorIfPresent<T> = T extends { error: any } ? Omit<T, 'error'> : T;

export type GraphQlOperationError<T> =
	| { kind: 'NetworkError'; error: unknown }
	| { kind: 'GraphQlErrors'; errors: FetchResult['errors'] }
	| { kind: 'DomainError'; error: T };

/**
 * This class encapsulates an `ApolloClient` and provides more powerful and better typed methods.
 */
export class K3ApolloClient {
	// TODO rename this class to EnhancedApolloClient. It has nothing to do with K3.

	public readonly dispose = Disposable.fn();

	/**
	 * Disconnected: websocket is disconnected.
	 * Connecting: websocket is connecting or initializing (sending an initial handshake with the auth token if provided).
	 * Connected: websocket is connected and initialized.
	 */
	@observable
	public subscriptionClientState:
		| { kind: 'connected' }
		| { kind: 'connecting' }
		| { kind: 'disconnected' } = { kind: 'disconnected' };

	private readonly disconnectedFirstEmitter = new EventEmitter<void>();

	// tslint:disable-next-line: member-ordering
	public readonly onDisconnectedFirst =
		this.disconnectedFirstEmitter.asEvent();

	constructor(
		/**
		 * You should try to use methods on this instance first!
		 * @deprecated
		 */
		public readonly client: ApolloClient<NormalizedCacheObject>,
		/**
		 * You should try to use methods on this instance first!
		 * @deprecated
		 */
		public readonly subscriptionClient: SubscriptionClient | undefined
	) {
		if (subscriptionClient) {
			subscriptionClient.onConnecting(() => {
				this.updateSubscriptionClientState({ kind: 'connecting' });
			});
			subscriptionClient.onConnected(() => {
				this.updateSubscriptionClientState({ kind: 'connected' });
			});
			subscriptionClient.onReconnecting(() => {
				this.updateSubscriptionClientState({ kind: 'connecting' });
			});
			subscriptionClient.onReconnected(() => {
				this.updateSubscriptionClientState({ kind: 'connected' });
			});
			subscriptionClient.onDisconnected(() => {
				this.disconnectedFirstEmitter.emit();
				this.updateSubscriptionClientState({ kind: 'disconnected' });
			});
			subscriptionClient.onError(e => {
				console.error(e);
			});

			this.dispose.track(() => {
				subscriptionClient.unsubscribeAll();
				const isForced = true; // prevents reconnect
				subscriptionClient.close(isForced);
			});
		}
	}

	@action
	private updateSubscriptionClientState(
		newState: this['subscriptionClientState']
	): void {
		this.subscriptionClientState = newState;
	}

	/**
	 * Performs a graphql mutation and returns a ResultPromise with the primary data or error.
	 *
	 * Returns an ok result with the primary data if the request succeeded and the response doesn't have an `error` field.
	 * Returns an error result if the network request failed, the graphql endpoint returns with an error state
	 * or the server answers with a response that has an `error` field.
	 */
	public mutateWithResultPromise<
		TMutation extends TypedMutationOperation<any, any, any>,
	>(
		mutation: TMutation,
		vars: TMutation['TVariables']
	): ResultPromise<
		RemoveErrorIfPresent<TMutation['TPrimaryResult']>,
		GraphQlOperationError<TMutation['TPrimaryResult']['error']>
	> {
		return new ResultPromise(
			this.mutate(mutation, vars).then(result =>
				result.flatMap(value =>
					value.primaryData && value.primaryData.error
						? err({
								kind: 'DomainError',
								error: value.primaryData.error,
							})
						: ok(value.primaryData)
				)
			)
		);
	}

	public async mutate<TVars, TResult, TPrimaryResult>(
		operation: TypedMutationOperation<TVars, TResult, TPrimaryResult>,
		variables: TVars
	): Promise<
		Result<
			{ primaryData: TPrimaryResult; data: TResult | null | undefined },
			| { kind: 'NetworkError'; error: unknown }
			| { kind: 'GraphQlErrors'; errors: FetchResult['errors'] }
		>
	> {
		let r;
		try {
			r = await this.client.mutate<TResult, TVars>({
				mutation: operation.document,
				variables,
			});
		} catch (e) {
			return err({ kind: 'NetworkError', error: e });
		}

		if (r.errors && r.errors.length > 0) {
			return err({ kind: 'GraphQlErrors', errors: r.errors });
		}

		return ok({
			primaryData: select(r.data, operation.primaryResultPath) as any,
			data: r.data,
		});
	}

	/**
	 * Performs a graphql query and returns a ResultPromise with the primary data or error.
	 *
	 * Returns an ok result with the primary data if the request succeeded and the response doesn't have an `error` field.
	 * Returns an error result if the network request failed, the graphql endpoint returns with an error state
	 * or the server answers with a response that has an `error` field.
	 */
	public queryWithResultPromise<
		TQuery extends TypedQueryOperation<any, any, any>,
	>(
		operation: TQuery,
		variables: TQuery['TVariables'],
		fetchPolicy?: FetchPolicy
	): ResultPromise<
		RemoveErrorIfPresent<TQuery['TPrimaryResult']>,
		GraphQlOperationError<TQuery['TPrimaryResult']['error']>
	> {
		return new ResultPromise(
			this.query(operation, variables, fetchPolicy).then(result =>
				result.flatMap(value =>
					value.primaryData && value.primaryData.error
						? err({
								kind: 'DomainError',
								error: value.primaryData.error as any,
							})
						: ok(value.primaryData)
				)
			)
		);
	}

	public async query<TVars, TResult, TPrimaryResult>(
		operation: TypedQueryOperation<TVars, TResult, TPrimaryResult>,
		variables: TVars,
		fetchPolicy?: FetchPolicy
	): Promise<
		Result<
			{ primaryData: TPrimaryResult; data: TResult },
			| { kind: 'NetworkError'; error: unknown }
			| { kind: 'GraphQlErrors'; errors: FetchResult['errors'] }
		>
	> {
		let r;
		try {
			r = await this.client.query<TResult, TVars>({
				query: operation.document,
				variables,
				fetchPolicy,
			});
		} catch (e) {
			return err({ kind: 'NetworkError', error: e });
		}

		if (r.errors && r.errors.length > 0) {
			return err({ kind: 'GraphQlErrors', errors: r.errors });
		}

		// There seems to be a bug in apollo where it returns partial data
		// even if we don't want it.
		if (r.partial) {
			return err({ kind: 'GraphQlErrors', errors: [] });
		}

		return ok({
			primaryData: select(r.data, operation.primaryResultPath) as any,
			data: r.data,
		});
	}

	public subscribeToPrimaryData<TVars, TEventData, TPrimaryEventData>(
		operation: TypedSubscriptionOperation<
			TVars,
			TEventData,
			TPrimaryEventData
		>,
		variables: TVars,
		observer: Observer<TPrimaryEventData>
	): Disposable {
		const subscription = this.client
			.subscribe({ variables, query: operation.document })
			.subscribe(
				({ data }) => {
					if (observer.next) {
						const selectedData = select(
							data,
							operation.primaryResultPath
						) as any;
						observer.next(selectedData);
					}
				},
				error => {
					if (observer.error) {
						observer.error(error);
					}
				},
				() => {
					if (observer.complete) {
						observer.complete();
					}
				}
			);

		return { dispose: () => subscription.unsubscribe() };
	}

	/**
	 * Creates an mobx observable for a graphql query.
	 * Must be disposed when not used anymore!
	 */
	public watchQuery<TVars, TResult, TPrimaryResult>(
		operation: TypedQueryOperation<TVars, TResult, TPrimaryResult>,
		variables: TVars,
		fetchPolicy?: FetchPolicy
	): K3ObservableQuery<TResult, TVars> {
		return new K3ObservableQuery<TResult, TVars>(
			this.client.watchQuery<TResult, TVars>({
				query: operation.document,
				variables,
				fetchPolicy,
			})
		);
	}
}
