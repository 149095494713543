import * as React from 'react';
import { FlexCol, ThemeColors, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import './ActionSheet.css';
import { useAnimateContext } from '@shared/components/RichText/CSSAnimatePrecense';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
export const ActionSheet: React.FC<{
  bg?: ThemeColors;
}> = ({
  children,
  bg = 'cardBg'
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const animateContext = useAnimateContext();
  const keyboardService = useService($KeyboardService);
  React.useEffect(() => {
    if (!animateContext.show) {
      ref.current.classList.add('ActionSheet--hide');
      ref.current.addEventListener('animationend', animateContext.onExit, {
        once: true
      });
    } else {
      ref.current.classList.remove('ActionSheet--hide');
    }
  }, [animateContext.show]);
  useReactiveState(() => {
    if (!keyboardService.anyKeyboardVisible && !animateContext.show) {
      animateContext.onExit();
    }
  }, [animateContext]);
  return <div ref={ref} style={{
    background: resolveThemingValue(bg, "colors", useTheme())
  }} className={_c0 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "") + " " + 'ActionSheet' + " "}>
			{children}
		</div>;
};
const _c0 = " Knu-FlexCol borderTopLeftRadius-base borderTopRightRadius-base size-full overflow-auto ";