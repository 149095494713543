import * as React from 'react';
import { ContextMenuWrapperInternal, ContextMenuWrapperProps } from './ContextMenuWrapperInternal';
export class ContextMenuWrapper extends React.PureComponent<ContextMenuWrapperProps> {
  private keyListener = (e: any): void => {
    if (e.keyCode === 27) {
      this.props.cancelModal();
    }
  };
  UNSAFE_componentWillMount(): void {
    window.addEventListener('keydown', this.keyListener, false);
  }
  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.keyListener);
  }
  render(): JSX.Element {
    return <ContextMenuWrapperInternal x={this.props.x} y={this.props.y} cancelModal={this.props.cancelModal}>
				{this.props.children}
			</ContextMenuWrapperInternal>;
  }
}