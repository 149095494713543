import { px as o } from "../../../value/types/numbers/units.js";
function n(r, t, c) {
  return typeof r == "string" ? r : o.transform(t + c * r);
}
function a(r, t, c) {
  const f = n(t, r.x, r.width), p = n(c, r.y, r.height);
  return `${f} ${p}`;
}
export {
  a as calcSVGTransformOrigin
};
