import * as React from 'react';
import { injectedComponent } from '@knuddels-app/DependencyInjection';
import { $ChannelBackgroundAppViewer } from '@knuddelsModules/Apps';
import { AppsWebview } from './AppsWebview';
import { Flex } from '@knuddels/component-library';
export const ChannelBackgroundAppView = injectedComponent({
  name: 'ChannelBackgroundAppView',
  inject: {
    channelBackgroundAppViewer: $ChannelBackgroundAppViewer
  }
}, ({
  channelBackgroundAppViewer
}) => {
  const app = channelBackgroundAppViewer.currentApp;
  if (!app) {
    return null;
  }
  return <div className={_c0}>
				<AppsWebview key={app.appId} appInstance={app} />
			</div>;
});
const _c0 = " Knu-Flex position-absolute inset-none flex-1 overflow-hidden ";