import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';
import { $LocalStorage, LocalStorageEntry } from '@knuddels-app/local-storage';
import { accentKey, themeKey } from '@shared/themeKeys';

@injectable()
export class ThemeSettingsService {
	public readonly dispose = Disposable.fn();

	private accentEntry: LocalStorageEntry<string> = this.localStorage.getEntry(
		accentKey
	);

	private themeEntry: LocalStorageEntry<string> = this.localStorage.getEntry(
		themeKey
	);

	constructor(
		@inject($K3Firebase)
		private readonly k3Firebase: typeof $K3Firebase.T,
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		this.dispose.track(() => {
			k3Firebase.analytics.setUserProperties({
				['Colorscheme']: null,
			});
		});
	}

	setTheme = (newTheme: string) => {
		this.themeEntry.set(newTheme);

		const scheme =
			newTheme === 'dark'
				? 'Dark'
				: newTheme === 'light'
				? 'Bright'
				: 'System';

		this.k3Firebase.analytics.setUserProperties({
			['Colorscheme']: scheme,
		});
	};

	setAccent = (newAccent: string) => {
		this.accentEntry.set(newAccent);
	};
}
