import * as React from 'react';
import { FlexCol, useMeasureElement } from '@knuddels/component-library';
interface Props {
  hasWebAds: boolean;
  hasCapacitorAds: boolean;
  adsWidth: number;
  children: (profileHeight: number, centerHorizontally: boolean, hasSpaceForWebAds: boolean, hasSpaceForCapacitorAds: boolean) => JSX.Element;
}
const PROFILE_PADDING = 40;
const MAX_PROFILE_HEIGHT = 576;
const PROFILE_CONTENT_WIDTH = 360;
export const ProfileDesktopLayoutManager: React.FC<Props> = props => {
  const {
    bind,
    width,
    height
  } = useMeasureElement();
  return <FlexCol position={'absolute'} inset={'none'} {...bind}>
			{width ? <Content adsWidth={props.adsWidth} hasWebAds={props.hasWebAds} hasCapacitorAds={props.hasCapacitorAds} width={width} height={height}>
					{props.children}
				</Content> : null}
		</FlexCol>;
};
const Content: React.FC<Props & {
  width: number;
  height: number;
}> = props => {
  const profileHeight = Math.min(props.height - 2 * PROFILE_PADDING, MAX_PROFILE_HEIGHT);
  const profileWidth = profileHeight + PROFILE_CONTENT_WIDTH;
  const hasSpaceForWebAds = props.width - 2 * PROFILE_PADDING - profileWidth > props.adsWidth;
  const hasSpaceForCapacitorAds = (props.height - profileHeight) / 2 >= 90;
  const shouldCenter = calculateShouldCenterProfile(props.hasWebAds, props.width, props.adsWidth, profileWidth, hasSpaceForWebAds);
  return props.children(profileHeight, shouldCenter, hasSpaceForWebAds, hasSpaceForCapacitorAds);
};
const calculateShouldCenterProfile = (hasAds: boolean, width: number, adsWidth: number, profileWidth: number, hasSpaceForAds: boolean): boolean => {
  if (!hasAds || !hasSpaceForAds) {
    return true;
  }
  const oneSidePadding = (width - profileWidth) / 2;
  return oneSidePadding >= adsWidth;
};