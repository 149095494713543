import { AfterSubmitBehavior, RichInput } from '@shared/components/RichInput';
import * as React from 'react';
import { PhotoCommentsContext, PhotoCommentsUpdater } from './PhotoComments';
import { useMutation } from '@knuddels-app/Connection';
import { CantCommentReason, SendAlbumPhotoComment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { SelectedAlbumPhotoContext } from '../AlbumDetailsUserContext';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
const useSendComment = (updateComments: PhotoCommentsUpdater) => {
  const [sendComment] = useMutation(SendAlbumPhotoComment);
  const snackbarService = useService($SnackbarService);
  const track = useTrackAlbumDetails();
  const i18n = useService($I18n);
  return (albumPhotoId: string, text: string) => {
    track('Comment_Send');
    sendComment({
      albumPhotoId,
      text,
      pixelDensity: getPixelRatio()
    }).then(result => {
      if (result.data?.__typename === 'CantCommentError') {
        const errorText = createErrorText(result.data.reason, i18n);
        if (errorText) {
          snackbarService.showErrorSnackbarWithDefaults({
            text: errorText,
            subtext: ''
          });
        } else {
          snackbarService.showGenericError();
        }
      } else if (!result.error && result.data?.__typename === 'AlbumPhotoComment') {
        const newComment = result.data;
        updateComments(oldComments => {
          return [...oldComments, newComment];
        });
      } else {
        snackbarService.showGenericError();
      }
    });
  };
};
function createErrorText(reason: CantCommentReason, i18n: typeof $I18n.T): string {
  switch (reason) {
    case CantCommentReason.Spam:
      return declareFormat({
        id: 'album.details.sendComment.error.spam',
        defaultFormat: 'Please wait a bit before creating a new comment.'
      }).format(i18n);
    case CantCommentReason.TextFilter:
      return declareFormat({
        id: 'album.details.sendComment.error.textFilter',
        defaultFormat: 'Please mind your language!'
      }).format(i18n);
    case CantCommentReason.Ignored:
      return declareFormat({
        id: 'album.details.sendComment.error.ignored',
        defaultFormat: 'The member does not wish to receive comments from you.'
      }).format(i18n);
    case CantCommentReason.CommentsDisabled:
      return declareFormat({
        id: 'album.details.sendComment.error.commentsDisabled',
        defaultFormat: 'The member has disabled comments.'
      }).format(i18n);
    case CantCommentReason.CommentsOnlyForFamily:
      return declareFormat({
        id: 'album.details.sendComment.error.commentsOnlyForFamily',
        defaultFormat: 'You must be at least a family member to comment here.'
      }).format(i18n);
    default:
      return declareFormat({
        id: 'album.details.sendComment.error.generic',
        defaultFormat: "Oooops! You can't leave a comment for this member."
      }).format(i18n);
  }
}
export const PhotoCommentsInput: React.FC<{
  autoFocus?: boolean;
}> = ({
  autoFocus = false
}) => {
  const {
    photoId
  } = React.useContext(SelectedAlbumPhotoContext);
  const {
    updateCache
  } = React.useContext(PhotoCommentsContext);
  const sendComment = useSendComment(updateCache);
  return <RichInput mode={!autoFocus ? 'inline-button' : 'inline'} autoFocus={autoFocus} onSubmit={(value: string) => {
    sendComment(photoId, value);
  }} afterSubmitBehavior={AfterSubmitBehavior.ClearAndDismissKeyboard} />;
};