import * as React from 'react';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { GenericModal, InputField, Spacer } from '@shared/components';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { $CurrentEndpointStore } from '@knuddels-app/Connection/serviceIds';
import { action, observable } from '@knuddels-app/mobx';
import { FlexCol, Text } from '@knuddels/component-library';
interface ModelProps {
  onCancel(): void;
  onFinish(endpoint: string): void;
}
@injectable()
class EndpointInputModalModel {
  @observable
  private _url = this.currentEndpointStore.localOverrideDomain || '';
  constructor(@injectProps()
  private readonly props: ModelProps, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($CurrentEndpointStore)
  private readonly currentEndpointStore: typeof $CurrentEndpointStore.T) {}
  public get url(): string {
    return this._url;
  }
  cancel = (): void => {
    this.thisVisibleOverlay.dispose();
    this.props.onCancel();
  };
  finish = (): void => {
    this.thisVisibleOverlay.dispose();
    this.props.onFinish(this.url);
  };
  @action.bound
  onChange(url: string): void {
    this._url = url;
  }
}
export const EndpointInputModal = injectedComponent({
  name: 'EndpointInputModal',
  model: EndpointInputModalModel
}, ({
  model
}) => {
  return <GenericModal headline={'Set Local Endpoint'} primaryButton={{
    text: 'Okay',
    onClick: model.finish,
    width: 195
  }} cancelModal={model.cancel}>
				<Text className={_c0}>GraphQL</Text>
				<div className={_c1}>
					<InputField onChangeText={model.onChange} value={model.url} placeholder={'Enter URL'} styleOverride={{
        borderColor: 'rgba(0,0,0,0.11)',
        backgroundColor: '#fafafa'
      }} autoCapitalize={'none'} />
				</div>
				<Spacer size={'base'} />
			</GenericModal>;
});
const _c0 = "  ";
const _c1 = " Knu-FlexCol position-relative py-small ";