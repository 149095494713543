import { jsx as i } from "react/jsx-runtime";
import { Z_INDEX as d } from "../configs/zindex.js";
import * as n from "react";
import * as c from "react-dom";
const l = n.createContext(null), m = (t) => /* @__PURE__ */ i(l.Provider, { value: t.element, children: t.children }), f = (t) => {
  const [r, a] = n.useState(null), o = n.useContext(l);
  return n.useLayoutEffect(() => {
    const e = document.createElement("div");
    return t.innerRef && (t.innerRef.current = e), o ? e.parent = o : e.parent = null, e.style.position = "fixed", e.style.top = "0px", e.style.left = "0px", e.style.width = "100%", e.style.height = "100%", e.style.pointerEvents = t.pointerEvents ?? "all", e.style.zIndex = `${t.zIndex ?? d.ABOVE_MODAL}`, document.body.appendChild(e), a(e), () => {
      document.body.removeChild(e);
    };
  }, []), r ? c.createPortal(t.children, r) : null;
};
export {
  m as ModalParentProvider,
  f as ModalWrapper
};
