import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';
import sorry from '@shared/images/sorry.gif';

export const SnackbarDefinitionTooManyMessagesWithoutPong: SnackbarData = {
	type: 'tooManyMessagesWithoutPong',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_TOO_MANY_MESSAGES_WITHOUT_PONG_TEXT',
				defaultFormat: 'You are sending too many messages.',
			})
		),
	subtext: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_TOO_MANY_MESSAGES_WITHOUT_PONG_SUBTEXT',
				defaultFormat: 'Please wait for a response first.',
			})
		),
	adornment: sorry,
};
