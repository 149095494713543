import * as React from 'react';
import { ContainerProps } from './ContainerProps';
export class Container extends React.PureComponent<ContainerProps> {
  render(): JSX.Element {
    const {
      style,
      backgroundImage,
      backgroundColor,
      backgroundGradient,
      backgroundResizeMode = 'cover',
      backgroundPosition = 'center',
      ...newProps
    } = this.props;
    const bgStyles: any = {};
    if (backgroundGradient && backgroundGradient.length > 0) {
      bgStyles.backgroundImage = `linear-gradient(${backgroundGradient.join(',')})`;
    }
    if (backgroundImage) {
      bgStyles.backgroundImage = `url(${backgroundImage})`;
      bgStyles.backgroundPosition = backgroundPosition;
      switch (backgroundResizeMode) {
        case 'stretch':
          bgStyles.backgroundSize = '100% 100%';
          break;
        case 'center':
          bgStyles.backgroundSize = 'contain';
          bgStyles.backgroundPosition = 'center';
          break;
        default:
          bgStyles.backgroundSize = backgroundResizeMode;
      }
    }
    if (backgroundColor) {
      bgStyles.backgroundColor = backgroundColor;
    }
    return <div style={{
      backgroundRepeat: 'no-repeat',
      ...style,
      ...bgStyles
    }} {...newProps} />;
  }
}