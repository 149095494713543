import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, shouldBeUnreachable } from '@knuddels/std';
import { BasicUser } from '../utils/BasicUser';
import {
	AllowImagesDocument,
	AllowImagesMutation,
	AllowImagesMutationVariables,
	BlockImagesDocument,
	BlockImagesMutation,
	BlockImagesMutationVariables,
	CanSendImagesChanged,
	CanSendImagesDocument,
	CreateImageUploadUrl,
	CreateUploadUrlError,
	MessengerConversation,
	ShowSensitiveContentDocument,
	ShowSensitiveContentMutation,
	ShowSensitiveContentMutationVariables,
	User,
} from '@generated/graphql';
import { createPicturesShownSnackbar } from '../snackbars/PicturesShown';
import { createPicturesHiddenSnackbar } from '../snackbars/PicturesHidden';
import {
	$AuthenticatedClientService,
	GraphQlOperationError,
} from '@knuddels-app/Connection';
import {
	$SnackbarService,
	SNACKBAR_GENERIC_ERROR_SUBTEXT,
} from '@knuddels-app/SnackbarManager';
import { $I18n, FormatId } from '@knuddels-app/i18n';
import {
	IMAGE_ERROR_BLOCKED_HAGGED_SUBTEXT,
	IMAGE_ERROR_BLOCKED_NOT_TITLE,
	IMAGE_ERROR_BLOCKED_NOT_YET_TITLE,
	IMAGE_ERROR_BLOCKED_PING_PONG_SUBTEXT,
	IMAGE_ERROR_BLOCKED_SUBTEXT,
	IMAGE_ERROR_NOT_ALLOWED_SUBTEXT,
	IMAGE_ERROR_UNAVAILABLE_SUBTEXT,
} from '../i18n/shared-formats';

@injectable()
export class MessengerImageService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {
		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				CanSendImagesChanged,
				{},
				{}
			)
		);
	}

	allowImages(user: BasicUser): void {
		this.authenticatedClientService.currentClient
			.mutate<AllowImagesMutation, AllowImagesMutationVariables>({
				mutation: AllowImagesDocument,
				variables: {
					userId: user.id,
				},
				refetchQueries: [
					{
						query: CanSendImagesDocument,
						variables: {
							userId: user.id,
						},
					},
				],
			})
			.then(result => {
				if (result.data.user.allowImages.error) {
					this.snackbarService.showGenericError();
				} else {
					this.snackbarService.showSnackbar(
						createPicturesShownSnackbar(user.nick)
					);
				}
			})
			.catch(() => {
				this.snackbarService.showGenericError();
			});
	}

	blockImages(user: BasicUser): void {
		this.authenticatedClientService.currentClient
			.mutate<BlockImagesMutation, BlockImagesMutationVariables>({
				mutation: BlockImagesDocument,
				variables: {
					userId: user.id,
				},
				refetchQueries: [
					{
						query: CanSendImagesDocument,
						variables: {
							userId: user.id,
						},
					},
				],
			})
			.then(result => {
				if (result.data.user.blockImages.error) {
					this.snackbarService.showGenericError();
				} else {
					this.snackbarService.showSnackbar(
						createPicturesHiddenSnackbar(user.nick)
					);
				}
			})
			.catch(() => {
				this.snackbarService.showGenericError();
			});
	}

	showSensitiveContent(messageId: string): void {
		this.authenticatedClientService.currentClient
			.mutate<
				ShowSensitiveContentMutation,
				ShowSensitiveContentMutationVariables
			>({
				mutation: ShowSensitiveContentDocument,
				variables: {
					messageId,
				},
			})
			.catch(() => {
				this.snackbarService.showGenericError();
			});
	}

	public async sendImage(
		imageUploadObject: object,
		conversationId: MessengerConversation['id'],
		otherParticipant: Pick<User, 'nick'>,
		expirationTimeInSeconds?: number
	): Promise<boolean> {
		const result = await this.authenticatedClientService.currentK3Client.mutateWithResultPromise(
			CreateImageUploadUrl,
			{
				conversationId,
				expirationTimeInSeconds,
			}
		);

		return new Promise<boolean>(resolve => {
			result.match({
				ok: ({ url }) => {
					this.uploadImage(url, imageUploadObject, resolve);
				},
				error: e => {
					this.showSnackbarForImageUploadError(e, otherParticipant);
					resolve(false);
				},
			});
		});
	}

	private async uploadImage(
		url: string,
		imageUploadObject: object,
		resolve: (value: boolean | PromiseLike<boolean>) => void,
		retryCount = 0
	): Promise<void> {
		// We are retrying here because Android randomly fails when uploading images in rapid succession
		// Related issue: https://github.com/facebook/react-native/issues/33508
		if (retryCount > 2) {
			this.snackbarService.showGenericError();
			resolve(false);
			return;
		}
		if (url) {
			const formData = new FormData();
			if ('blob' in imageUploadObject) {
				formData.append(
					'image',
					(imageUploadObject as any).blob,
					(imageUploadObject as any).name
				);
			} else {
				formData.append('image', imageUploadObject as any);
			}

			try {
				const response = await fetch(encodeURI(url), {
					method: 'POST',
					body: formData,
				});

				if (response.ok) {
					resolve(true);
					return;
				} else {
					this.snackbarService.showGenericError();
					resolve(false);
					return;
				}
			} catch (error) {
				console.error(error);
				this.uploadImage(
					url,
					imageUploadObject,
					resolve,
					retryCount + 1
				);
			}
		}
	}

	private showSnackbarForImageUploadError = (
		error: GraphQlOperationError<CreateUploadUrlError>,
		otherParticipant: Pick<User, 'nick'>
	): void => {
		const errorTitleFormatId =
			error.kind === 'DomainError'
				? MessengerImageService.mapSnapErrorStateToTitleFormatId(
						error.error
				  )
				: undefined;

		const errorSubtextFormatId =
			error.kind === 'DomainError'
				? MessengerImageService.mapSnapErrorStateToSubtextFormatId(
						error.error
				  )
				: SNACKBAR_GENERIC_ERROR_SUBTEXT;

		this.snackbarService.showErrorSnackbarWithDefaults({
			text: errorTitleFormatId && this.i18n.format(errorTitleFormatId),
			subtext: this.i18n.format(errorSubtextFormatId, {
				nickname: otherParticipant.nick,
			}),
		});
	};

	private static mapSnapErrorStateToTitleFormatId(
		errorState: CreateUploadUrlError
	): FormatId {
		switch (errorState) {
			case CreateUploadUrlError.BlockedHagged:
			case CreateUploadUrlError.BlockedPingPongDepth:
			case CreateUploadUrlError.SnapsNotAllowed:
				return IMAGE_ERROR_BLOCKED_NOT_YET_TITLE;
			case CreateUploadUrlError.Blocked:
			case CreateUploadUrlError.SnapServerUnavailable:
			case CreateUploadUrlError.Internal:
			default:
				return IMAGE_ERROR_BLOCKED_NOT_TITLE;
		}
	}

	private static mapSnapErrorStateToSubtextFormatId(
		errorState: CreateUploadUrlError
	): FormatId {
		switch (errorState) {
			case CreateUploadUrlError.Blocked:
				return IMAGE_ERROR_BLOCKED_SUBTEXT;
			case CreateUploadUrlError.BlockedHagged:
				return IMAGE_ERROR_BLOCKED_HAGGED_SUBTEXT;
			case CreateUploadUrlError.BlockedPingPongDepth:
				return IMAGE_ERROR_BLOCKED_PING_PONG_SUBTEXT;
			case CreateUploadUrlError.SnapServerUnavailable:
				return IMAGE_ERROR_UNAVAILABLE_SUBTEXT;
			case CreateUploadUrlError.SnapsNotAllowed:
				return IMAGE_ERROR_NOT_ALLOWED_SUBTEXT;
			case CreateUploadUrlError.Internal:
				return SNACKBAR_GENERIC_ERROR_SUBTEXT;
			default:
				// Don't throw here or we will block backend updates that extend those errors.
				// It's fine to log warnings here as this doesn't happen frequently (e.g. render).
				shouldBeUnreachable(errorState);
				return SNACKBAR_GENERIC_ERROR_SUBTEXT;
		}
	}
}
