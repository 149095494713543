import * as React from 'react';
import { ContextMenuTrigger } from '@shared/components';
import { ConversationMessageContent } from './ConversationMessageContent';
import { conversationMessageMenuEvent } from '../../../analytics';
import { ConversationMessageFragment, MessengerConversation } from '@generated/graphql';
import { MessageLightboxSource } from '../Lightbox/Lightbox';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { MessageContextMenuOverlay } from '../../ContextMenus';
import { $OverlayService } from '@knuddels-app/overlays';
import { useTheme } from '@knuddels/component-library';
export interface ConversationMessageProps {
  message: ConversationMessageFragment;
  conversationId: MessengerConversation['id'];
  receiverId: string;
  type: 'sender' | 'receiver';
  noContextMenu?: boolean;
  testId?: string;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
}
type Props = ConversationMessageProps;
@injectable()
class ConversationMessageModel {
  constructor(@injectProps()
  private readonly props: Props, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {}
  public readonly handleShowContextMenu = (x: number, y: number, width: number, height: number): void => {
    conversationMessageMenuEvent.track('MessageMenu_OpenMenu');
    this.overlayService.showOverlayIfNotVisible(MessageContextMenuOverlay, {
      message: this.props.message,
      conversationId: this.props.conversationId,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  public get isContextMenuOpen(): boolean {
    return !!this.overlayService.findOverlay(MessageContextMenuOverlay.getFilter(t => t.messageId === this.props.message.id));
  }
}
export const ConversationMessage = injectedComponent({
  name: 'ConversationMessage',
  model: ConversationMessageModel,
  props: declareProps<Props>()
}, ({
  model,
  receiverId,
  message,
  type,
  testId,
  setLightboxSrc,
  noContextMenu
}) => {
  const theme = useTheme();
  const chevronColor = type === 'sender' ? theme.colors.Messenger.outgoingMessage : theme.colors.Messenger.incomingMessage;
  const content = <ConversationMessageContent sentByActiveUser={type === 'sender'} message={message} sender={message.sender} receiverId={receiverId} setLightboxSrc={setLightboxSrc} />;
  return <>
				{noContextMenu ? content : <ContextMenuTrigger showContextMenu={model.handleShowContextMenu} chevronBackgroundColor={chevronColor} position={'top'} onDark={type === 'sender'} isMenuActive={model.isContextMenuOpen} testId={testId ? testId + '-context-menu' : undefined}>
						{content}
					</ContextMenuTrigger>}
			</>;
});