import { Endpoint } from '../Endpoints';
import { AuthTokenProvider } from './AuthTokenProvider';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client/link/http';
import { ApolloLink } from '@apollo/client';

export function createHttpLink(
	endpoint: Endpoint,
	authTokenProvider: AuthTokenProvider
): ApolloLink {
	const customFetch = (input: RequestInfo, init?: RequestInit) => {
		return fetch(input, init).then(response => {
			// Early fail to stop any tries to parse the result if status code is not successful.
			// Might be able to remove this with newer versions of @apollo/client.
			if (response.status >= 400) {
				return Promise.reject({
					// can be checked for special errors (see AuthSession)
					statusError: response.status,
					message: `Response not successful. Received status code: ${response.status}`,
				});
			}
			return response;
		});
	};
	const httpLink = new HttpLink({
		uri: endpoint.urls.graphQl,
		fetch: customFetch,
	});

	return createAuthLink(authTokenProvider).concat(httpLink);
}

function createAuthLink(tokenProvider: AuthTokenProvider): ApolloLink {
	return setContext(async (_, { headers }) => {
		const token = await tokenProvider.getOrRetrieveAuthToken();
		if (!token) {
			return { headers };
		}
		return {
			headers: {
				...headers,
				authorization: `Bearer ${token}`,
			},
		};
	});
}
