import React from 'react';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
const SmileyboxAnalyticsContext = React.createContext<string | null>(null);
export const SmileyboxAnalyticsProvider: React.FC<{
  context: string;
}> = ({
  children,
  context
}) => {
  return <SmileyboxAnalyticsContext.Provider value={context}>
			{children}
		</SmileyboxAnalyticsContext.Provider>;
};
const useSmileyBoxAnalyticsContext = () => ((React as any).use(SmileyboxAnalyticsContext) as string);
export const useSmileyBoxTrackEvent = () => {
  const context = useSmileyBoxAnalyticsContext();
  return (itemId: string) => {
    dangerouslyGetK3Container().getService($FirebaseAnalyticsService).logEvent(context, itemId);
  };
};