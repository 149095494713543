import { Gender } from '@generated/graphql';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
export const useNickSubstituteForGender = (gender: Gender) => {
  const i18n = useService($I18n);
  switch (gender) {
    case Gender.NonbinaryHe:
    case Gender.Male:
      return i18n.format(declareFormat({
        id: 'profileVisitors.blurredNick.male',
        defaultFormat: 'He'
      }));
    case Gender.NonbinaryShe:
    case Gender.Female:
      return i18n.format(declareFormat({
        id: 'profileVisitors.blurredNick.female',
        defaultFormat: 'She'
      }));
    case Gender.Unknown:
      return ' ';
    // Placeholder for height
    default:
      // Don't throw here or we will block backend updates if we want to allow more genders.
      // Check with never to get type/compile errors. We don't want to throw warnings
      // with `shouldBeUnreachable` because it is called frequently in render.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
      const checkTypeToNotForgetUpdatingThis: never = gender;
      return ' ';
    // Placeholder for height
  }
};