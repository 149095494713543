import * as React from 'react';
import { OpenDetailsProfilePhoto } from '../PhotoDisplays/ProfilePhoto';
import { ProfileContent, ProfileHeader } from './ProfileContent';
import { ProfileBase } from './ProfileBase';
import { observer } from 'mobx-react';
import { SelectedProfileTabContext } from './SelectedProfileTabContext';
import { Flex, FlexCol, Image } from '@knuddels/component-library';
import { FriendStateBanner } from '../shared/FriendStateBanner';
import { ProfileContentRouter } from './ProfileContentRouter';
import { ProfileCloseIcon } from './ProfileCloseIcon';
import { ProfileFAB } from './FABs/ProfileFAB';
import { ChangeProfilePictureButton } from './EditProfile/EditProfileContent/EditProfilePicture/ChangeProfilePictureButton';
@observer
export class ProfileDesktopContent extends ProfileBase {
  protected renderBody = (width: number, height: number): React.ReactNode => {
    const {
      user,
      photoData
    } = this.props;
    const isCurrentUser = this.props.currentUserId === user.id;
    return <SelectedProfileTabContext.Consumer>
				{({
        activeTabIndex,
        setActiveTabIndex
      }) => <div className={_c0}>
						<div className={_c1}>
							<OpenDetailsProfilePhoto size={height} user={user} data={photoData} />
							{user.profilePictureOverlayUrls.urlsLarge.map((overlayUrl, index) => <div key={index} style={{
            pointerEvents: 'none'
          }} className={_c2}>
										<Image src={overlayUrl} alt={''} imageRendering={'pixelated'} className={_c3} />
									</div>)}
							{isCurrentUser && <div className={_c4}>
									<ChangeProfilePictureButton />
								</div>}
							<FriendStateBanner user={user} size={'large'} />
						</div>
						<div className={_c5}>
							<ProfileContentRouter nick={user.nick}>
								<ProfileHeader isCurrentUser={isCurrentUser} user={user} photoData={photoData} onTabClicked={setActiveTabIndex} activeTabIndex={activeTabIndex} />
								<ProfileContent user={user} photoData={photoData} isCurrentUser={isCurrentUser} activeTabIndex={activeTabIndex} onTabChange={setActiveTabIndex} />
							</ProfileContentRouter>
						</div>

						<ProfileCloseIcon onPress={this.props.onClose} />
						<ProfileFAB {...this.props} />
					</div>}
			</SelectedProfileTabContext.Consumer>;
  };
}
const _c0 = " Knu-Flex position-relative flex-1 minWidth-0-px overflow-hidden borderRadius-base ";
const _c1 = " Knu-FlexCol position-relative ";
const _c2 = " Knu-Flex position-absolute inset-none ";
const _c3 = " size-full ";
const _c4 = " Knu-Flex position-absolute bottom-small justifyContent-center left-none right-none width-100-percent ";
const _c5 = " Knu-FlexCol flex-1 position-relative overflow-hidden ";