import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { computed } from '@knuddels-app/mobx';
import {
	ConversationListFilterType,
	FullConversationWithoutMessagesFragment,
	MessengerConversationState,
} from '@generated/graphql';
import {
	conversationToMessengerOverviewItem,
	getSortedOverviewItems,
	MessengerOverviewItem,
} from './messengerOverviewHelper';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessengerService } from '../../providedServices';
import { MessengerListQuery } from './MessengerListQuery';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { distinct, last } from '@knuddels/std';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';

@injectable()
export class MessengerListService {
	public readonly messengerOverviewQuery: MessengerListQuery;
	public readonly messengerArchiveQuery: MessengerListQuery;
	public readonly messengerUnreadQuery: MessengerListQuery;

	constructor(
		@inject($ClientSettingsService)
		private readonly clientSettingsService: typeof $ClientSettingsService.T,
		@inject($MessengerService)
		private readonly messengerService: typeof $MessengerService.T,
		@inject($AuthenticatedClientService)
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		snackbarService: typeof $SnackbarService.T,
		@inject($FriendRequestsService)
		private readonly friendRequestsService: typeof $FriendRequestsService.T
	) {
		this.messengerOverviewQuery = new MessengerListQuery(
			MessengerConversationState.NotArchived,
			authenticatedClientService,
			snackbarService
		);
		this.messengerArchiveQuery = new MessengerListQuery(
			MessengerConversationState.Archived,
			authenticatedClientService,
			snackbarService
		);
		this.messengerUnreadQuery = new MessengerListQuery(
			MessengerConversationState.Unread,
			authenticatedClientService,
			snackbarService
		);
	}

	@computed
	public get unarchivedConversationsOverviewItems(): readonly MessengerOverviewItem[] {
		return getSortedOverviewItems(
			this.unarchivedConversations.map(
				conversationToMessengerOverviewItem
			)
		);
	}

	@computed
	private get activeConversation(): MessengerOverviewItem | undefined {
		const openConversationId =
			this.messengerService.getOpenConversationId();
		const unarchivedConversation = this.unarchivedConversations.find(
			it => it.id === openConversationId
		);
		if (unarchivedConversation) {
			return conversationToMessengerOverviewItem(unarchivedConversation);
		}

		const unreadConversation = this.unreadConversations.find(
			it => it.id === openConversationId
		);
		if (unreadConversation) {
			return conversationToMessengerOverviewItem(unreadConversation);
		}

		return undefined;
	}

	@computed
	public get unreadConversationsAndActiveConversation(): readonly MessengerOverviewItem[] {
		const openConversationId =
			this.messengerService.getOpenConversationId();
		const activeConversation = this.activeConversation;
		const unreadConversations = this.unreadConversations
			.filter(it => it.id !== openConversationId)
			.map(it => conversationToMessengerOverviewItem(it));
		return getSortedOverviewItems(
			activeConversation
				? [activeConversation, ...unreadConversations]
				: [...unreadConversations]
		);
	}

	@computed
	public get overviewConversations(): readonly MessengerOverviewItem[] {
		if (
			this.clientSettingsService.conversationListFilterType ===
			ConversationListFilterType.UnreadMessages
		) {
			return this.unreadConversationsAndActiveConversation;
		}

		return this.unarchivedConversationsOverviewItems;
	}

	@computed
	public get unreadConversations(): readonly FullConversationWithoutMessagesFragment[] {
		const unreadFriendRequests =
			this.friendRequestsService.friendRequestsForConversation.filter(
				r => r.readState.unreadMessageCount > 0
			);
		const conversationsWithUnreadRequests =
			this.messengerOverviewQuery.conversations.filter(conversation =>
				unreadFriendRequests.some(
					request =>
						request.user.id === conversation.otherParticipants[0].id
				)
			);
		return distinct(
			[
				...conversationsWithUnreadRequests,
				...this.messengerUnreadQuery.conversations,
			],
			conversation => conversation.id
		);
	}

	public get unarchivedConversations(): readonly FullConversationWithoutMessagesFragment[] {
		return this.messengerOverviewQuery.conversations;
	}

	public get archivedConversations(): readonly FullConversationWithoutMessagesFragment[] {
		return this.messengerArchiveQuery.conversations;
	}

	public refetchAll = async () => {
		return await Promise.all([
			this.messengerOverviewQuery.refetch(),
			this.messengerArchiveQuery.refetch(),
			this.messengerUnreadQuery.refetch(),
		]);
	};

	public getNextConversationId(
		activeConversationTimestamp: number
	): string | undefined {
		const nextOldestConversation = this.overviewConversations.find(
			// @ts-expect-error Not exposed
			conv => conv.timestamp < activeConversationTimestamp
		);

		// try to open next older or last conversation
		const nextConversation =
			nextOldestConversation || last(this.overviewConversations);
		return nextConversation && nextConversation.conversationId;
	}
}
