import { FormatId } from './facade';
import { $I18n, $MessageFormatProvider } from './services';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';

/*
 * All functions in this file are deprecated!
 */

export function todoUseDI_i18n(): typeof $I18n.T {
  return dangerouslyGetK3Container().getService($I18n);
}
export function todoUseDI_messageFormatProvider(): typeof $MessageFormatProvider.T {
  return dangerouslyGetK3Container().getService($MessageFormatProvider);
}
export function formatMessage(formatId: FormatId, data?: {}): string {
  return todoUseDI_i18n().format(formatId, data);
}