import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import talkToTheHand from '../assets/icon-talktothehand.gif';

export function createPicturesHiddenSnackbar(nick?: string): SnackbarData {
	return {
		type: 'picturesHidden' + (nick ? '_' + nick : ''),
		text: () => {
			return nick
				? formatMessage(
						declareFormat({
							id: 'SNACKBAR_PICTURES_HIDDEN_TEXT',
							defaultFormat:
								'Pictures from {name} are now hidden.',
						}),
						{ name: nick }
					)
				: formatMessage(
						declareFormat({
							id: 'SNACKBAR_PICTURES_HIDDEN_TEXT_NO_NICK',
							defaultFormat:
								'Pictures from this user are now hidden.',
						})
					);
		},
		subtext: (): string =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_PICTURES_HIDDEN_SUBTEXT',
					defaultFormat:
						'Pictures from this user are no longer shown to you.',
				})
			),
		adornment: talkToTheHand,
	};
}
