/**
 * This prevents the search from being executed if the query is the same as the last one and no results were found.
 */
export const withSearchCache = <
	TService,
	T extends (
		autocompleteService: TService,
		queryText: string
	) => Promise<any>,
>(
	searchFn: T,
	minQueryLength = 0
) => {
	let lastQuery: string = null;
	let lastMatchCount = 0;
	return (async (autocompleteService: TService, queryText: string) => {
		if (
			queryText.length < minQueryLength ||
			(lastMatchCount === 0 &&
				lastQuery &&
				queryText.startsWith(lastQuery))
		) {
			return [];
		}

		const options = await searchFn(autocompleteService, queryText);
		lastQuery = queryText;
		lastMatchCount = options.length;
		return options;
	}) as T;
};
