import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as ids from '../providedServiceIds';
import * as s from './services';
import { foreachProp } from '@knuddels/std';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$LoggedInAreaService: ctx.globalScope.toSingletonAutostart(
			s.LoggedInAreaService
		),
		$NavBarRegistry: ctx.loggedInScope.toSingleton(s.NavBarItemRegistry),
		$NavBarStateStore: ctx.loggedInScope.toSingleton(s.NavBarStateStore),
		$NavHidingService: ctx.loggedInScope.toSingleton(s.NavHidingService),
		$NavBarService: ctx.loggedInScope.toSingleton(s.NavBarService),
	});
}
