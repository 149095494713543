import { Flex, FlexCol, isTouchDevice, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useDrag } from '@use-gesture/react';
import { DynamicAnimationOptions, MotionValue, clamp, motion, useAnimate, useMotionValue, useTransform } from 'framer-motion';
import * as React from 'react';
import { SwipeContext } from './SwipeContext';
export interface SwipeAction {
  component: () => React.ReactNode;
  backgroundColor?: string;
  onAction?: (cb: (reset: boolean) => void) => void;
}
interface SwipeableProps {
  children: React.ReactNode;
  threshold?: number;
  leftAction?: SwipeAction;
  rightActionPrimary?: SwipeAction;
  rightActionSecondary?: SwipeAction;
  swipeId?: string;
}
type Direction = 'left' | 'right';
const ACTION_WIDTH = 92;
const TRIGGER_THRESHOLD = 0.8;
const options: Pick<DynamicAnimationOptions, 'ease' | 'duration'> = {
  ease: 'easeInOut',
  duration: 0.2
};
export const Swipeable: React.FC<SwipeableProps> = ({
  children,
  leftAction,
  rightActionPrimary,
  rightActionSecondary,
  swipeId
}) => {
  const swipeContext = React.useContext(SwipeContext);
  const startX = useMotionValue(0);
  const x = useMotionValue(0);
  const left = useMotionValue(('none' as 'flex' | 'none'));
  const right = useMotionValue(('none' as 'flex' | 'none'));
  const paddingLeft = useTransform(x, value => Math.max(value - ACTION_WIDTH, 0));
  const panelWidthRight = useTransform(x, value => Math.abs(value));
  const rightWidthPrimary = useMotionValue(ACTION_WIDTH);
  const secondaryWidthTriggered = useMotionValue(false);
  const resetting = useMotionValue(false);
  const rightWidthSecondary = useTransform(x, value => {
    const offset = Math.abs(value);
    if (resetting.get()) {
      if (secondaryWidthTriggered.get()) {
        return offset;
      } else {
        return 0;
      }
    }
    if (secondaryWidthTriggered.get()) {
      return ACTION_WIDTH + (offset - ACTION_WIDTH * 2) * 2;
    }
    const rightTotalWidth = rightActionSecondary ? 2 * ACTION_WIDTH : ACTION_WIDTH;
    const shrinkStart = rightTotalWidth + ACTION_WIDTH * 0.5;
    return offset < shrinkStart ? ACTION_WIDTH : Math.max(0, Math.min(ACTION_WIDTH, shrinkStart - offset + ACTION_WIDTH));
  });
  const [scope, animate] = useAnimate<HTMLDivElement>();
  React.useEffect(() => {
    if (swipeContext.currentSwipeId !== swipeId) {
      animateTo(0);
    }
  }, [swipeContext.currentSwipeId, swipeId]);
  const handleAction = async (action: SwipeAction, directionVal: Direction) => {
    startX.set(0);
    const maxWidth = scope.current.clientWidth;
    const targetX = directionVal === 'left' ? maxWidth : -maxWidth;
    if (action === rightActionSecondary) {
      secondaryWidthTriggered.set(true);
    }
    await Promise.all(([animate(scope.current, {
      x: targetX
    }, options), animate(x, targetX, options), action === rightActionSecondary ? animate(rightWidthPrimary, 0, options) : Promise.resolve()] as unknown[]));
    action.onAction!(async reset => {
      if (!reset) {
        return;
      }
      resetting.set(true);
      await Promise.all([animate(scope.current, {
        x: 0
      }, options), animate(x, 0, options)]);
      resetting.set(false);
      if (action === rightActionSecondary) {
        secondaryWidthTriggered.set(false);
        rightWidthPrimary.set(ACTION_WIDTH);
      }
      if (directionVal === 'right') {
        right.set('none');
      } else {
        left.set('none');
      }
    });
  };
  const animateTo = (value: number) => {
    startX.set(value);
    animate(scope.current, {
      x: value
    }, options);
    animate(x, value, options);
  };
  const bind = useDrag(({
    movement,
    last
  }) => {
    if (!scope.current) {
      return;
    }
    swipeContext.setCurrentSwipeId(swipeId || null);
    const maxWidth = scope.current.clientWidth;
    x.set(clamp(-maxWidth, leftAction ? maxWidth : 0, startX.get() + movement[0]));
    animate(scope.current, {
      x: x.get()
    }, {
      duration: 0
    });
    if (x.get() < 0) {
      left.set('none');
      right.set('flex');
    } else {
      left.set('flex');
      right.set('none');
    }
    if (last) {
      const rightTotalWidth = rightActionSecondary ? 2 * ACTION_WIDTH : ACTION_WIDTH;
      const offset = Math.abs(x.get());
      if (x.get() < 0 && rightActionPrimary) {
        if (offset < rightTotalWidth) {
          animateTo(0);
        } else if (offset < maxWidth * TRIGGER_THRESHOLD) {
          animateTo(-rightTotalWidth);
        } else {
          handleAction(rightActionPrimary, 'right');
        }
      } else if (x.get() > 0 && leftAction) {
        const leftTotalWidth = ACTION_WIDTH;
        if (offset < leftTotalWidth) {
          animateTo(0);
        } else if (offset < maxWidth * TRIGGER_THRESHOLD) {
          animateTo(leftTotalWidth);
        } else {
          handleAction(leftAction, 'left');
        }
      }
    }
  }, {
    axis: 'x',
    enabled: isTouchDevice(),
    bounds: {
      right: leftAction ? undefined : 0,
      left: rightActionPrimary ? undefined : 0
    }
  });
  return <div className={_c0}>
			<div className={_c1}>
				{leftAction && <Panel direction={'left'} display={left} padding={paddingLeft} backgroundColor={leftAction.backgroundColor}>
						<Action action={leftAction} width={ACTION_WIDTH} onPress={() => handleAction(leftAction, 'left')} />
					</Panel>}
			</div>
			<div {...bind()} ref={scope} style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      flex: 1,
      flexShrink: 0,
      zIndex: 2,
      maxWidth: '100%',
      touchAction: 'pan-y'
    }}>
				{children}
			</div>
			<div className={_c2}>
				{rightActionPrimary && <Panel direction={'right'} display={right} backgroundColor={rightActionPrimary.backgroundColor}>
						<motion.div style={{
          width: panelWidthRight,
          height: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}>
							{rightActionSecondary && <Action action={rightActionSecondary} width={rightWidthSecondary} onPress={() => handleAction(rightActionSecondary, 'right')} />}

							<Action action={rightActionPrimary} width={rightWidthPrimary} onPress={() => handleAction(rightActionPrimary, 'right')} />
						</motion.div>
					</Panel>}
			</div>
		</div>;
};
const Action: React.FC<{
  action: SwipeAction;
  width: number | MotionValue<number>;
  onPress: () => void;
}> = ({
  action,
  width,
  onPress
}) => {
  return <motion.div style={{
    overflow: 'hidden',
    minWidth: 0,
    width,
    height: '100%',
    backgroundColor: action.backgroundColor
  }}>
			<div onClick={toPointerHandler(onPress)} style={{
      width: resolveThemingValue(ACTION_WIDTH, "sizes", useTheme())
    }} className={_c3 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{action.component()}
			</div>
		</motion.div>;
};
const Panel: React.FC<{
  display: MotionValue<'none' | 'flex'>;
  direction: Direction;
  padding?: MotionValue<number>;
  backgroundColor?: string;
}> = ({
  display,
  direction,
  children,
  padding,
  backgroundColor
}) => {
  return <motion.div style={{
    display: display,
    alignItems: 'center',
    backgroundColor: backgroundColor,
    position: 'absolute',
    justifyContent: direction === 'left' ? 'flex-start' : 'flex-end',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    paddingLeft: direction === 'left' && padding ? padding : 0,
    paddingRight: direction === 'right' && padding ? padding : 0
  }}>
			{children}
		</motion.div>;
};
const _c0 = " Knu-Flex position-relative ";
const _c1 = " Knu-Flex ";
const _c2 = " Knu-Flex ";
const _c3 = " Knu-FlexCol height-full placeItems-center bg-transparentDark ";