import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $AdsService } from '@knuddelsModules/Ads';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { onAdSettingClicked } from './onAdSettingClicked';
import { SettingsListItem } from '../SettingsListItem';
import { IconChevronRight, ListGroup } from '@knuddels/component-library';
export const AdsSettings: React.FC = observer('AdsSettings', () => {
  const i18n = useService($I18n);
  const adsService = useService($AdsService);
  const track = useLogEvent();
  const onAdsClicked = (): void => {
    track('Settings_Overview', 'AdSettings_Clicked');
    onAdSettingClicked();
  };

  // Don't show setting if ads are not displayed because then we don't even load the ad scripts
  // and the ad setting link wouldn't work.
  if (!adsService.shouldShowAds) {
    return null;
  }
  return <ListGroup title={declareFormat({
    id: 'settings.group.misc',
    defaultFormat: 'Miscellaneous'
  }).format(i18n)}>
			<SettingsListItem iconRight={<IconChevronRight />} caption={declareFormat({
      id: 'settings.ads.title',
      defaultFormat: 'Ads'
    }).format(i18n)} onPress={onAdsClicked} separator={'none'} />
		</ListGroup>;
});