function l(e, n) {
  e.indexOf(n) === -1 && e.push(n);
}
function u(e, n) {
  const t = e.indexOf(n);
  t > -1 && e.splice(t, 1);
}
function f([...e], n, t) {
  const i = n < 0 ? e.length + n : n;
  if (i >= 0 && i < e.length) {
    const c = t < 0 ? e.length + t : t, [s] = e.splice(n, 1);
    e.splice(c, 0, s);
  }
  return e;
}
export {
  l as addUniqueItem,
  f as moveItem,
  u as removeItem
};
