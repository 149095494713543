import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerConversationService, $SendMessageService } from '@knuddelsModules/Messenger/providedServices';
import { FormattedText, FormattedTextDisplay, SuggestionButton, SuggestionsContainer, TextSuggestionButton } from '@shared/components';
import { observer } from '@knuddels-app/mobx';
import { NicknameChangeReaction, NicknameChangeReactionSmileys } from '@generated/graphql';
import { useConversationId } from '../ConversationIdContext';
import { useTrackConversation } from '../../useTrackConversation';
import { useQuery } from '@knuddels-app/Connection';
export const CongratulateReactions: React.FC<{
  onMessageSent(): void;
}> = observer('CongratulateReactions', ({
  onMessageSent
}) => {
  const sendMessageService = useService($SendMessageService);
  const messengerConversationService = useService($MessengerConversationService);
  const conversationId = useConversationId();
  const {
    data: nicknameChangeReactionSmileys
  } = useQuery(NicknameChangeReactionSmileys, {
    conversationId
  });
  const track = useTrackConversation();
  if (!conversationId) {
    return null;
  }
  if (!nicknameChangeReactionSmileys || nicknameChangeReactionSmileys.length === 0) {
    return null;
  }
  const clientConversationState = messengerConversationService.getOrCreateClientConversationState(conversationId);
  const closeSuggestions = () => {
    clientConversationState.clearSelectedMessageIdForSuggestions();
  };
  const handleReaction = (reactionText: string) => {
    track(`NicknameChange_ReactedWith_${reactionText}`);
    closeSuggestions();
    onMessageSent();
  };
  const renderReactionButton = (reaction: NicknameChangeReaction) => {
    switch (reaction.__typename) {
      case 'NicknameChangeReactionSmiley':
        {
          return <SmileyReactionButton key={reaction.smiley.id} formattedText={FormattedText.fromJsonString(reaction.smiley.image)} onPress={async () => {
            await sendMessageService.sendNicknameChangeSmileyReaction(conversationId, reaction.smiley);
            handleReaction(reaction.smiley.textRepresentation);
          }} />;
        }
      case 'NicknameChangeReactionText':
        {
          return <TextReactionButton key={reaction.text} formattedText={FormattedText.fromJsonString(reaction.formattedText)} onPress={async () => {
            await sendMessageService.sendMessage(conversationId, reaction.text);
            handleReaction(reaction.text);
          }} />;
        }
      default:
        return null;
    }
  };
  return <SuggestionsContainer onClickOutside={closeSuggestions} onCloseSuggestions={closeSuggestions}>
			{nicknameChangeReactionSmileys.map(renderReactionButton)}
		</SuggestionsContainer>;
});
const SmileyReactionButton: React.FC<{
  formattedText: FormattedText;
  onPress(): void;
}> = ({
  formattedText,
  onPress
}) => {
  return <SuggestionButton onPress={onPress}>
			<FormattedTextDisplay text={formattedText} textProps={{}} />
		</SuggestionButton>;
};
const TextReactionButton: React.FC<{
  formattedText: FormattedText;
  onPress: () => void;
}> = ({
  formattedText,
  onPress
}) => {
  return <TextSuggestionButton formattedText={formattedText} onPress={onPress} />;
};