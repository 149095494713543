import { newServiceId } from '@knuddels-app/DependencyInjection';
import { AllowedServerAccessStore, CurrentEndpointStore } from './Endpoints';
import { DeviceTokenStore } from './DeviceToken';
import { EndpointProvider } from './Endpoints/EndpointProvider';
import { LoggedInContainerBridge } from './LoggedInContainerBridge';
import { LoggedInModuleLoader } from './LoggedInModuleLoader';
import { AuthenticatedClientService } from './client/AuthenticatedClientService';
import { AuthService } from './AuthService';
import { K3ApolloClientFactory } from './client/K3ApolloClientFactory';
import { CheckSystemTimeService } from './CheckSystemTimeService';
import { LastLoginStorage } from './login-data/LastLoginStorage';
import { ClientInfoStore } from './ClientInfo/ClientInfoStore';
import { FirstLoginStorage } from './login-data/FirstLoginStorage';

export const $AuthenticatedClientService =
	newServiceId<AuthenticatedClientService>('$AuthenticatedClientService');
export const $AuthService = newServiceId<AuthService>('$AuthService');
export const $K3ApolloClientFactory = newServiceId<K3ApolloClientFactory>(
	'$K3ApolloClientFactory'
);
export const $EndpointProvider =
	newServiceId<EndpointProvider>('$EndpointProvider');
export const $CurrentEndpointStore = newServiceId<CurrentEndpointStore>(
	'$CurrentEndpointStore'
);
export const $DeviceTokenStore =
	newServiceId<DeviceTokenStore>('$DeviceTokenStore');
export const $LoggedInContainerBridge = newServiceId<LoggedInContainerBridge>(
	'$LoggedInContainerBridge'
);
export const $LoggedInModuleLoader = newServiceId<LoggedInModuleLoader>(
	'$LoggedInModuleLoader'
);

export const $CheckSystemTimeService = newServiceId<CheckSystemTimeService>(
	'$CheckSystemTimeService'
);

export const $LastLoginStorage =
	newServiceId<LastLoginStorage>('$LastLoginStorage');

export const $FirstLoginStorage =
	newServiceId<FirstLoginStorage>('$FirstLoginStorage');

export const $ClientInfoStore =
	newServiceId<ClientInfoStore>('$ClientInfoStore');

export const $AllowedServerAccessStore = newServiceId<AllowedServerAccessStore>(
	'$AllowedServerAccessStore'
);
