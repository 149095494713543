import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import {
	$CommandService,
	$CommandWithoutChannelService,
} from '@knuddels-app/Commands';
import { $ActiveChannelService } from '@knuddelsModules/Channel';

@injectable()
export class ChannelCommandsService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($ActiveChannelService)
		private readonly activeChannelService: typeof $ActiveChannelService.T,
		@inject($CommandWithoutChannelService)
		private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T
	) {
		this.dispose.track(
			commandService.setFallbackCommandHandler({
				invoke: async (
					commandName: string,
					parameter: string
				): Promise<boolean> => {
					if (this.activeChannelService.activeChannel) {
						commandService.invokeListeners({
							commandName,
							parameter,
						});

						await this.activeChannelService.activeChannel.send(
							`/${commandName} ${parameter}`
						);
						return true;
					} else {
						const result =
							await this.commandWithoutChannelService.invokeCommand(
								commandName,
								parameter
							);
						return result === 'SendSlashCommandSuccess';
					}
				},
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'clear',
				invoke: async (): Promise<void> => {
					if (this.activeChannelService.activeChannel) {
						this.activeChannelService.activeChannel.clearMessages();
					}
				},
			})
		);
	}
}
