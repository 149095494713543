import * as React from 'react';
import { generateId } from '@knuddels/component-library';
export const RegisterBottomInputContext = React.createContext<(() => void) | null>(null);
export const useRegisterBottomInputContext = () => {
  const registerBottomInputContext = React.useContext(RegisterBottomInputContext);
  if (!registerBottomInputContext) {
    throw new Error('useRegisterBottomInputContext must be used within a K3SnackbarProvider');
  }
  return registerBottomInputContext;
};
export const useRegisterBottomInput = () => {
  const registerBottomInput = useRegisterBottomInputContext();
  React.useEffect(() => {
    return registerBottomInput();
  }, []);
};
export const useBottomInputs = () => {
  const [inputs, setInputs] = React.useState([]);
  const registerInput = React.useCallback(() => {
    const id = generateId('input');
    setInputs(currentInputs => [...currentInputs, id]);
    return () => {
      setInputs(currentInputs => currentInputs.filter(input => input !== id));
    };
  }, [setInputs]);
  return {
    inputCount: inputs.length,
    registerInput
  };
};