import { PersistedViewState, Position, ViewId, ViewProvider, ViewRegistry, ViewState } from '@knuddels-app/layout';
import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import * as providedServices from '@knuddelsModules/Channel/providedServiceIds';
import { Channel } from '@generated/graphql';
import { chatroomShownTimingTracker } from '@knuddelsModules/Channel/analytics';
@injectable()
export class ChannelViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: channelViewId,
      position: Position.Main,
      persistIndex: 0,
      autostartInBackground: globalEnv.product === 'app',
      isDefault: () => globalEnv.product === 'app',
      loadState: ChannelViewState.fromPersistedViewState,
      render: state => {
        chatroomShownTimingTracker.start();
        return {
          mainView: <LoadService service={providedServices.$ChannelComponents}>
							{s => <s.MainView routeChatId={state.channelId} nicklistOpen={state.isNickListOpen} />}
						</LoadService>
        };
      }
    });
  }
}
export class ChannelViewState implements ViewState {
  public static fromPersistedViewState({
    pathItems,
    state
  }: PersistedViewState): ChannelViewState {
    return new ChannelViewState(pathItems.length > 0 ? pathItems[0] : undefined, !!state);
  }
  constructor(public readonly channelId: Channel['id'] | undefined, public readonly isNickListOpen: boolean) {}
  persist(): PersistedViewState {
    return {
      pathItems: this.channelId ? [this.channelId] : [],
      state: this.isNickListOpen
    };
  }
  public toggleNickList(): ChannelViewState {
    return new ChannelViewState(this.channelId, !this.isNickListOpen);
  }
  public closeNickList(): ChannelViewState {
    if (this.isNickListOpen) {
      return this.toggleNickList();
    } else {
      return this;
    }
  }
  public setChannel(channelId: Channel['id']): ChannelViewState {
    return new ChannelViewState(channelId, this.channelId === channelId ? this.isNickListOpen : false);
  }
  public clearChannel(): ChannelViewState {
    return new ChannelViewState(undefined, false);
  }
  withOnlyRootViewOpened(): ViewState {
    if (this.isNickListOpen) {
      return this.toggleNickList();
    } else {
      return this;
    }
  }
  onOpenByMenu(): ViewState {
    return this.withOnlyRootViewOpened();
  }
}
export const channelViewId = new ViewId<ChannelViewState>('channel');