import { RichTextPluginsGetter } from '@shared/components/RichInput';
import { NickPlugin } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/NickPlugin';
import { ChannelPlugin } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/ChannelPlugin';
import * as React from 'react';
import { HistoryPlugin } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/HistoryPlugin';
import { SmileyPlugin } from './SmileyPlugin';
export const ChatInputPlugins: RichTextPluginsGetter = anchorRef => {
  return <>
			<NickPlugin anchor={anchorRef} />
			<ChannelPlugin anchor={anchorRef} />
			<SmileyPlugin anchor={anchorRef} />
			<HistoryPlugin anchor={anchorRef} />
		</>;
};