import { FlexCol, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
export interface ContextMenuWrapperProps {
  cancelModal: (nativeCancel?: boolean) => void;
  x: number;
  y: number;
}
export const ContextMenuWrapperInternal: React.FC<ContextMenuWrapperProps> = ({
  cancelModal,
  x,
  y,
  children
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleClick = (e: {
    stopPropagation: Function;
  }): void => {
    e.stopPropagation();
  };
  const clickCancel = (): void => {
    cancelModal();
  };
  return <div onClick={toPointerHandler(clickCancel)} className={_c0}>
			<div onClick={toPointerHandler(handleClick)} style={{
      left: resolveThemingValue((x as ThemeOverride), "spacing", useTheme()),
      top: resolveThemingValue((y as ThemeOverride), "spacing", useTheme())
    }} className={_c1}>
				{children}
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol position-absolute inset-none zIndex-100 ";
const _c1 = " Knu-FlexCol position-absolute overflow-visible ";