import * as React from 'react';
import { useState } from 'react';
import { Z_INDEX } from '@shared/components/globalStyleConfiguration';
import { Flex, FlexCol } from '@knuddels/component-library';
import { SlidingView } from '@knuddels-app/shared-components/SlidingView';
import { NotificationBarController } from '../services';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { HorizontalSwipeOutView } from '@knuddels-app/shared-components/HorizontalSwipeOutView';
export type NotificationProps = {
  content: React.ReactElement;
  id: string;
  onComponentHidden: () => void;
  controller: NotificationBarController;
};
export const Notification: React.FC<NotificationProps> = ({
  onComponentHidden,
  content,
  controller
}) => {
  const [hide, setHide] = useState(false);
  const isStackedLayout = useIsStackedLayout();
  React.useEffect(() => {
    return controller.onClose(() => setHide(true));
  }, [controller]);
  return <HorizontalSwipeOutView onSwipeOut={onComponentHidden} enabled={isStackedLayout}>
			<SlidingView hide={hide} slideOutCallback={onComponentHidden} bg={'contentLightBg'} borderRadius={'base'} minHeight={'64px'} zIndex={Z_INDEX.JUST_BELOW_OVERLAY} ariaRole={'alert'} ariaLive={'assertive'} shadow={'Shadow4'}>
				<div className={_c0}>
					<div className={_c1}>
						{content}
					</div>
				</div>
			</SlidingView>
		</HorizontalSwipeOutView>;
};
const _c0 = " Knu-Flex alignItems-center flexGrow-1 ";
const _c1 = " Knu-FlexCol flexGrow-1 flexShrink-1 width-full ";