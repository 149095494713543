import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { useCallback } from 'react';
import { albumDetailsViewId } from '../../../ProfileViewProvider';
import { ProfileCloseIcon } from '../ProfileOverlay/ProfileCloseIcon';
export const useCloseAlbumDetails = () => {
  const viewService = useService($ViewService);
  return useCallback(() => viewService.closeView(albumDetailsViewId), []);
};
export const CloseAlbumDetailsButton: React.FC = () => {
  const closeAlbumDetails = useCloseAlbumDetails();
  return <ProfileCloseIcon onPress={closeAlbumDetails} />;
};