import * as React from 'react';
import { useEffect } from 'react';
import { observer } from '@knuddels-app/mobx';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { FlexCol, ScrollView, Text } from '@knuddels/component-library';
import { InfoCard } from '@shared/components';
import { useMentorSystemPoints } from './mentorSystemPointsData';
import { useTrackMentorSystemInfo } from './useTrackMentorSystemInfo';
export const MentorSystemPointsInfo: React.FC = observer('MentorSystemPointsInfo', () => {
  const track = useTrackMentorSystemInfo();
  const items = useMentorSystemPoints();
  useEffect(() => {
    track('CollectPoints_Shown');
  }, []);
  return <div className={_c0}>
				<div className={_c1}>
					<Text bold={true} className={_c2}>
						<FormattedMessage id={declareFormat({
          id: 'mentorsystem.rewards.points.title',
          defaultFormat: 'Points you can get as a mentor per mentee'
        })} />
					</Text>
				</div>

				<div className={_c3}>
					{items.map((item, index) => <InfoCard key={`pointsInfo-${index}`} item={item} />)}
				</div>
			</div>;
});
const _c0 = " Knu-ScrollView px-large ";
const _c1 = " Knu-FlexCol pt-base ";
const _c2 = " my-small textAlign-center ";
const _c3 = " Knu-FlexCol py-small ";