import * as React from 'react';
import { AuthenticityClassification, User } from '@generated/graphql';
import { createSvgIcon, Image } from '@knuddels/component-library';
import { userIsJames } from '@shared/helper/user';
import { Optional } from '@knuddels-app/tools/types';
export type IconInfoUser = Optional<Pick<User, 'nick' | 'menteeStatus' | 'isAppBot' | 'authenticityClassification'>, 'isAppBot' | 'menteeStatus' | 'authenticityClassification'>;
export const useAuthenticityIconComponent = (user: IconInfoUser | undefined, hideNegativeStates?: boolean) => {
  if (!user) {
    return {
      Component: null,
      iconWidth: 0
    };
  }
  return getAuthenticityIconComponent(user, hideNegativeStates);
};
function getAuthenticityIconComponent(user: Optional<Pick<User, 'nick' | 'menteeStatus' | 'isAppBot' | 'authenticityClassification'>, 'isAppBot' | 'menteeStatus' | 'authenticityClassification'>, hideNegativeStates?: boolean): {
  Component: any;
  iconWidth: number;
} {
  if (user.isAppBot || userIsJames(user)) {
    return {
      Component: IconBot,
      iconWidth: 21
    };
  }
  switch (user.authenticityClassification) {
    case AuthenticityClassification.VeryTrusted:
      return {
        Component: IconVeryTrusted,
        iconWidth: 16
      };
    case AuthenticityClassification.Trusted:
      return {
        Component: IconTrusted,
        iconWidth: 16
      };
    default:
      if (hideNegativeStates) {
        return {
          Component: null,
          iconWidth: 0
        };
      }
      return {
        Component: IconUnknown,
        iconWidth: 16
      };
  }
}
const IconUnknown = createSvgIcon(<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="19.9996" cy="20" r="19.9" fill="var(--colors-authenticityUnknownBg)" />
		<rect x="18.1914" y="28.2446" width="4.81203" height="18.0718" transform="rotate(-135 18.1914 28.2446)" fill="var(--colors-authenticityUnknownIcon)" />
		<rect x="9.23242" y="19.2861" width="4.81203" height="12.6692" transform="rotate(-45 9.23242 19.2861)" fill="var(--colors-authenticityUnknownIcon)" />
	</svg>);
IconUnknown.displayName = 'IconUnknown';
const IconTrusted = createSvgIcon(<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="19.9996" cy="20" r="17.4" fill="#5DCCFF" />
		<circle cx="20.0508" cy="19.95" r="15.25" fill="#5DCCFF" />
		<rect x="18.1914" y="28.2446" width="4.81203" height="18.0718" transform="rotate(-135 18.1914 28.2446)" fill="white" />
		<rect x="9.23242" y="19.2861" width="4.81203" height="12.6692" transform="rotate(-45 9.23242 19.2861)" fill="white" />
	</svg>);
IconTrusted.displayName = 'IconTrusted';
const IconVeryTrusted = createSvgIcon(<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="19.9996" cy="20" r="19.9" fill="#009BED" />
		<circle cx="19.9996" cy="20" r="17.4" fill="white" />
		<circle cx="20.0508" cy="19.95" r="15.25" fill="#009BED" />
		<rect x="18.1914" y="28.2446" width="4.81203" height="18.0718" transform="rotate(-135 18.1914 28.2446)" fill="white" />
		<rect x="9.23242" y="19.2861" width="4.81203" height="12.6692" transform="rotate(-45 9.23242 19.2861)" fill="white" />
	</svg>);
IconVeryTrusted.displayName = 'IconVeryTrusted';
const IconBot: React.FC = () => {
  return <Image src={require('./link-icon_userapps.png')} alt={'Bot Icon'} className={_c0} />;
};
const _c0 = " width-21px height-15px ";