import { injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { $OverlayService } from './serviceIds';
import { FlexCol, Modal, Z_INDEX } from '@knuddels/component-library';
import { InsideModalContext } from '@knuddels-app/overlays/InsideModalContext';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
@injectable()
class OverlayPresenterModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(@injectProps()
  props: unknown) {}
}
export const OverlayPresenter = injectedComponent({
  model: OverlayPresenterModel,
  name: 'OverlayPresenter',
  inject: {
    service: $OverlayService,
    nativeWebView: $NativeWebViewService
  }
}, ({
  service
}) => <>
			{service.overlays.map((o, i) => <Modal zIndex={Z_INDEX.OVERLAY + i + 1} backgroundStyle={o.backgroundType ?? 'transparent'} pointerEvents={'none'} key={o.id}>
					<InsideModalContext.Provider value>
						<div className={_c0}>
							<div style={{
          height: 'env(safe-area-inset-top)'
        }} />
							<div className={_c1}>
								<div style={{
            display: 'contents',
            pointerEvents: 'all'
          }}>
									{o.view}
								</div>
							</div>
						</div>
					</InsideModalContext.Provider>
				</Modal>)}
		</>);
const _c0 = " Knu-FlexCol size-full ";
const _c1 = " Knu-FlexCol flex-1 position-relative ";