import { jsxs as n, jsx as s } from "react/jsx-runtime";
import { Fade as c } from "./Primitives.js";
import { clsx as t } from "../../node_modules/clsx/dist/clsx.m.js";
const p = ({ initial: d, end: o, duration: l, initialVisible: e, onFinished: a, ...m }) => {
  const r = m.width === "full";
  return /* @__PURE__ */ n("div", { className: "Knu-CrossFade size-full", children: [
    /* @__PURE__ */ s(
      "div",
      {
        className: t("Knu-Flex placeItems-center position-relative"),
        style: { zIndex: e ? 2 : 1 },
        children: /* @__PURE__ */ s(
          c,
          {
            duration: l,
            visible: e,
            onFinished: a,
            className: r ? "width-full" : "",
            children: d
          }
        )
      }
    ),
    /* @__PURE__ */ s(
      "div",
      {
        className: t("Knu-Flex placeItems-center position-relative"),
        style: { zIndex: e ? 1 : 2 },
        children: /* @__PURE__ */ s(
          c,
          {
            duration: l,
            visible: !e,
            className: r ? "width-full" : "",
            children: o
          }
        )
      }
    )
  ] });
};
export {
  p as CrossFade
};
