import * as React from 'react';
import { QUICK_WHOIS_WIDTH, QuickWhois } from './QuickWhois';
import { quickWhoisOpenedTracker } from '@knuddelsModules/Profile/analytics';
import { Z_INDEX } from '@shared/components';
import { GetUserForQuickWhoisProps, User, withGetUserForQuickWhois } from '@generated/graphql';
import { $OverlayService, $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { observable, reaction, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { QuickWhoisOverflowMenuOverlay } from './QuickWhoisOverflowMenu';
import { FlexCol, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { LayoutInfo } from '@knuddels-app/tools/LayoutInfo';
import { measureLayoutRelativeToWindow } from '@knuddels-app/tools/measureLayoutRelativeToWindow';
import { FlexColClass } from '@knuddels-app/shared-components/FlexColClass';
import throttle from 'lodash/throttle';
interface QuickWhoisModalProps {
  userId: User['id'];
  x: number;
  y: number;
  w: number;
  h: number;
}
type Props = QuickWhoisModalProps & GetUserForQuickWhoisProps;
type Point = {
  x: number;
  y: number;
};
const ARROW_OFFSET = 115;
@injectable()
class QuickWhoisModalModel implements IModel {
  public get x(): number | undefined {
    return this.bounds && this.bounds.x;
  }
  public get y(): number | undefined {
    return this.bounds && this.bounds.y;
  }
  public readonly dispose = Disposable.fn();
  public readonly ref = React.createRef<FlexColClass>();
  private trackedOpen = false;
  private prevMouseX: number | undefined = undefined;
  private prevMouseY: number | undefined = undefined;
  @observable
  private bounds: LayoutInfo | undefined;
  private onLayoutThrottled: ReturnType<typeof throttle>;
  constructor(@injectProps()
  private readonly props: Props, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {
    this.dispose.track(reaction({
      name: 'handle quick whois error',
      fireImmediately: true
    }, () => this.props.data.error, error => {
      if (error) {
        // TODO proper error handling
        console.error(error);
        this.handleClose();
      }
    }));
    this.dispose.track(reaction({
      name: 'check for close on context menu close',
      fireImmediately: false
    }, () => this.isOverflowMenuOpen, isOpen => {
      if (!isOpen) {
        this.checkForClose(this.prevMouseX, this.prevMouseY);
      }
    }));
    this.dispose.track(() => {
      this.handleClose();
    });
    this.onLayoutThrottled = throttle(this.onLayoutHandler, 200);
  }
  private onLayoutHandler = (): void => {
    // Check if the ref is currently pointing to an element
    if (this.ref.current) {
      measureLayoutRelativeToWindow(this.ref.current).then(layout => {
        runInAction('update quickwhois modal bounds', () => {
          this.bounds = this.calculateBounds(layout);
        });
      }).catch(error => {
        console.error('Error measuring layout:', error);
      });
    }
  };
  componentDidMount(): void {
    window.addEventListener('mousemove', this.onMouseMove);
    // Call the throttled function immediately to set initial bounds
    this.onLayoutThrottled();
  }
  componentWillUnmount(): void {
    window.removeEventListener('mousemove', this.onMouseMove);
    // Cancel any pending invocations of the throttled function
    this.onLayoutThrottled.cancel();
  }
  readonly handleClose = (): void => {
    window.removeEventListener('mousemove', this.onMouseMove);
    this.thisVisibleOverlay.dispose();
  };
  readonly trackOpenedModal = (): void => {
    if (!this.trackedOpen) {
      this.trackedOpen = true;
      quickWhoisOpenedTracker.stop();
    }
  };
  private readonly onMouseMove = (ev: MouseEvent): void => {
    this.checkForClose(ev.x, ev.y);
    this.prevMouseX = ev.x;
    this.prevMouseY = ev.y;
  };
  private readonly checkForClose = (mouseX: number, mouseY: number) => {
    const onEntry = this.isOnNickEntry(mouseX, mouseY);
    const onTriangle = this.isOnTriangles(mouseX, mouseY);
    const isOverflowMenuOpen = this.isOverflowMenuOpen;
    const isOnModal = this.bounds && mouseX >= this.bounds.x && mouseX < this.bounds.x + this.bounds.width && mouseY >= this.bounds.y && mouseY < this.bounds.y + this.bounds.height;
    if (!isOnModal && !onEntry && !onTriangle && !isOverflowMenuOpen) {
      this.handleClose();
    }
  };
  private get isOverflowMenuOpen(): boolean {
    return !!this.overlayService.findOverlay(QuickWhoisOverflowMenuOverlay.getFilter(tag => tag.userId === this.props.userId));
  }
  private readonly isOnNickEntry = (mx: number, my: number): boolean => {
    const {
      x,
      y,
      w,
      h
    } = this.props;
    return mx >= x - 10 && mx <= x + w && my >= y && my <= y + h;
  };
  private readonly isOnTriangles = (mx: number, my: number): boolean => {
    if (!this.prevMouseX || !this.prevMouseY || !this.bounds) {
      return true;
    }
    const {
      x,
      y,
      width: w,
      height: h
    } = this.bounds;
    const p = {
      x: mx,
      y: my
    };
    const p0 = {
      x: this.prevMouseX + 1,
      y: this.prevMouseY
    };
    return this.isPointInTriangle(p, p0, {
      x,
      y: y - 10
    }, {
      x,
      y: y + h + 10
    }) || this.isPointInTriangle(p, p0, {
      x: x + w,
      y: y - 10
    }, {
      x: x + w,
      y: y + h + 10
    });
  };
  private readonly isPointInTriangle = (p: Point, p0: Point, p1: Point, p2: Point): boolean => {
    const area = 0.5 * (-p1.y * p2.x + p0.y * (-p1.x + p2.x) + p0.x * (p1.y - p2.y) + p1.x * p2.y);
    const s = 1 / (2 * area) * (p0.y * p2.x - p0.x * p2.y + (p2.y - p0.y) * p.x + (p0.x - p2.x) * p.y);
    const t = 1 / (2 * area) * (p0.x * p1.y - p0.y * p1.x + (p0.y - p1.y) * p.x + (p1.x - p0.x) * p.y);
    return s > 0 && t > 0 && 1 - s - t > 0;
  };
  readonly onLayout = (): void => {
    this.onLayoutThrottled();
  };
  private readonly calculateBounds = (layout: LayoutInfo): LayoutInfo => {
    const minY = 64; // Height of title bar
    const maxY = window.innerHeight - 46 - 8 - layout.height;
    const top = Math.min(Math.max(this.props.y - ARROW_OFFSET, minY), maxY);
    return {
      x: this.props.x - QUICK_WHOIS_WIDTH - 8,
      y: top,
      width: layout.width,
      height: layout.height
    };
  };
}
const QuickWhoisModalBase = injectedComponent({
  name: 'QuickWhoisModalBase',
  model: QuickWhoisModalModel,
  props: declareProps<Props>()
}, ({
  data,
  model
}) => {
  if (!data.user) {
    return null;
  }
  model.trackOpenedModal();
  return <div style={{
    width: resolveThemingValue(QUICK_WHOIS_WIDTH, "sizes", useTheme()),
    left: resolveThemingValue((model.x as ThemeOverride), "spacing", useTheme()),
    top: resolveThemingValue((model.y as ThemeOverride), "spacing", useTheme()),
    zIndex: resolveThemingValue(Z_INDEX.MODAL, "theme", useTheme())
  }} className={_c0 + (model.x !== undefined ? _c1 : _c2)}>
				<FlexColClass
    // reset view if user changes => or else positioning is weird
    key={data.user.user.id} ref={model.ref} onLayout={model.onLayout}>
					<QuickWhois currentUserId={data.user.currentUser.id} conversationId={data.messenger.conversationWithParticipants.id} user={data.user.user} onClose={model.handleClose} />
				</FlexColClass>
			</div>;
});
const QuickWhoisModal = withGetUserForQuickWhois<QuickWhoisModalProps>({
  options: props => ({
    variables: {
      userId: props.userId
    }
  })
})(QuickWhoisModalBase);
export const QuickWhoisModalOverlay = new OverlayFactory(QuickWhoisModal, props => ({
  userId: props.userId
}));
const _c0 = " Knu-FlexCol shadow-Shadow4 borderRadius-base position-absolute overflow-hidden ";
const _c1 = " opacity-1 ";
const _c2 = " opacity-0 ";