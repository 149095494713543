import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const e = r(
  /* @__PURE__ */ o(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      children: /* @__PURE__ */ o(
        "path",
        {
          d: "M 3 3 A 1 1 0 0 0 2 4 L 2 9.5957031 C 2 10.109703 2.3893906 10.538844 2.9003906 10.589844 L 17 12 L 2.9003906 13.410156 C 2.3893906 13.461156 2 13.890297 2 14.404297 L 2 20 A 1 1 0 0 0 3 21 A 1 1 0 0 0 3.5390625 20.84375 A 1 1 0 0 0 3.5410156 20.841797 L 21.339844 12.943359 L 21.337891 12.939453 A 1 1 0 0 0 22 12 A 1 1 0 0 0 21.337891 11.060547 L 21.339844 11.056641 L 3.5273438 3.1523438 A 1 1 0 0 0 3 3 z",
          fill: "currentColor"
        }
      )
    }
  )
);
e.displayName = "IconSend";
export {
  e as IconSend
};
