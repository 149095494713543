import { jsx as c, jsxs as R } from "react/jsx-runtime";
import * as i from "react";
import { clsx as b } from "../../node_modules/clsx/dist/clsx.m.js";
import "./Tabs.scss.js";
import { useDrag as N } from "../../deps/@use-gesture/react/dist/use-gesture-react.esm.js";
import { createNativeAccessiblePointerEventHandler as S } from "../../a11y/createAccessiblePointerEventHandler.js";
import { generateId as M } from "../../a11y/generateId.js";
import { Ripple as K } from "../Layout/Box/Ripple.js";
class F {
  constructor(e, t, s, n) {
    this.activeIndex = e, this.mountingType = t, this.variant = s, this.manageState = n, this.tabs = [], this.panels = [], this.visitedPanels = /* @__PURE__ */ new Map(), this.subscriptions = /* @__PURE__ */ new Map(), this.activeIndexSubscription = [], this.lastIndex = 0, this.onChange = () => {
    }, this.visitedPanels.set(e, !0);
  }
  registerTab(e) {
    const t = this.generateId(), s = this.tabs.push({ id: t, domRef: e }) - 1;
    return { id: t, index: s };
  }
  isScrollable() {
    return this.variant === "scrollable" || this.variant === "auto" && this.numTabs() > 3;
  }
  setOnChange(e) {
    this.onChange = e;
  }
  getActiveIndex() {
    return this.activeIndex;
  }
  numTabs() {
    return Math.max(this.tabs.length, this.panels.length);
  }
  registerPanel() {
    const e = this.generateId(), t = this.panels.push(e) - 1;
    return { id: e, index: t };
  }
  subscribeForIndexChange(e, t) {
    const s = this.subscriptions.get(e) ?? [];
    return s.push(t), this.subscriptions.set(e, s), () => {
      const n = this.subscriptions.get(e) ?? [], o = n.indexOf(t);
      o !== -1 && n.splice(o, 1);
    };
  }
  subscribeForActiveIndexChange(e) {
    return this.activeIndexSubscription.push(e), () => {
      const t = this.activeIndexSubscription.indexOf(e);
      t !== -1 && this.activeIndexSubscription.splice(t, 1);
    };
  }
  setTabActive(e, t = !1) {
    t || this.onChange(e), (this.manageState || t) && (this.visitedPanels.set(this.activeIndex, !0), this.lastIndex = this.activeIndex, this.activeIndex = e, this.activeIndexSubscription.forEach((s) => s(e)), this.notifySubscribers([this.activeIndex, this.lastIndex]));
  }
  willBecomeActive(e) {
    this.visitedPanels.has(e) || (this.visitedPanels.set(e, !0), this.notifySubscribers([e]));
  }
  shouldMountPanel(e) {
    return this.mountingType === "eager" ? !0 : this.visitedPanels.has(e) || e === this.activeIndex;
  }
  animationDone() {
    this.mountingType === "unmountInactive" && (this.visitedPanels.delete(this.lastIndex), this.notifySubscribers([this.lastIndex]));
  }
  isActive(e) {
    return this.activeIndex === e;
  }
  getActiveTabPosition() {
    var f, h, a;
    const e = this.tabs[this.activeIndex], t = this.tabs[this.activeIndex + 1];
    if (!((f = e == null ? void 0 : e.domRef) != null && f.current))
      return null;
    const s = e.domRef.current.offsetParent, n = s == null ? void 0 : s.getBoundingClientRect(), o = (n == null ? void 0 : n.left) ?? 0, l = (s == null ? void 0 : s.scrollLeft) ?? 0;
    return {
      width: e.domRef.current.offsetWidth,
      left: e.domRef.current.offsetLeft - ((n == null ? void 0 : n.width) ?? 0) + e.domRef.current.offsetWidth + (((a = (h = t == null ? void 0 : t.domRef) == null ? void 0 : h.current) == null ? void 0 : a.offsetWidth) ?? 0),
      offsetLeft: e.domRef.current.getBoundingClientRect().left - o + l
    };
  }
  notifySubscribers(e) {
    e.forEach((t) => {
      (this.subscriptions.get(t) ?? []).forEach((n) => n());
    });
  }
  generateId() {
    return M("tab-");
  }
}
const p = (r) => i.Children.toArray(r).filter(Boolean), A = i.createContext(null), m = () => {
  const r = i.useContext(A);
  if (r === null)
    throw new Error("TabsContext is null");
  return r;
}, I = () => {
  const [, r] = i.useState({});
  return i.useCallback(() => r({}), []);
}, C = (r) => {
  const e = m(), t = I();
  i.useEffect(() => e.subscribeForIndexChange(r, t), [e, t, r]);
}, V = ({
  children: r,
  mountingType: e = "keepInactive",
  variant: t = "auto",
  onChange: s,
  value: n
}) => {
  const [o] = i.useState(
    () => new F(n ?? 0, e, t, n === void 0)
  );
  return i.useLayoutEffect(() => {
    n !== void 0 && o.setTabActive(n, !0);
  }, [n]), s && o.setOnChange(s), /* @__PURE__ */ c("div", { className: "Knu-FlexCol flex-1 size-full", children: /* @__PURE__ */ c(A.Provider, { value: o, children: r }) });
}, D = i.memo(({ children: r, ariaLabel: e, variant: t }) => {
  const s = m(), n = i.useRef(null), [{ id: o, index: l }] = i.useState(() => s.registerTab(n));
  return C(l), /* @__PURE__ */ c(
    "div",
    {
      ref: n,
      ...S({
        pointerEventType: s.isScrollable() ? "onPress" : "onPointerDown",
        pointerCallback: () => s.setTabActive(l)
      }),
      "aria-label": e,
      className: b(
        "Knu-Flex flex-1 py-small placeItems-center cursor-pointer position-relative",
        t === "condensed" ? "px-tiny" : "px-small",
        s.isScrollable() && "minWidth-fitContent"
      ),
      children: /* @__PURE__ */ R(
        "div",
        {
          className: b(
            "Knu-Flex placeItems-center overflow-hidden position-relative py-tiny",
            t === "condensed" ? "px-small" : "px-base"
          ),
          style: { borderRadius: "100px" },
          children: [
            /* @__PURE__ */ c(K, { containerRef: n }),
            p(r).map(
              (f, h) => i.cloneElement(f, {
                key: h,
                id: o,
                active: s.isActive(l)
              })
            )
          ]
        }
      )
    }
  );
});
D.displayName = "Tab";
const q = ({
  children: r,
  active: e,
  ...t
}) => {
  const { id: s } = t;
  return /* @__PURE__ */ c(
    "span",
    {
      id: s,
      className: b(
        "Knu-Text bold text-body1 ",
        e ? "text-primary" : "text-tertiary"
      ),
      children: r
    }
  );
}, P = 10, O = i.memo(({ children: r }) => {
  const e = m(), t = I(), s = i.useRef(null), n = e.getActiveTabPosition();
  return i.useEffect(() => e.subscribeForActiveIndexChange(() => {
    t();
  }), [e, t]), i.useLayoutEffect(() => {
    t();
  }, []), i.useLayoutEffect(() => {
    var o;
    (o = s.current) == null || o.scrollTo({
      left: n == null ? void 0 : n.left,
      behavior: "smooth"
    });
  }, [n]), i.useEffect(() => (window.addEventListener("resize", t), () => window.removeEventListener("resize", t)), []), /* @__PURE__ */ R(
    "div",
    {
      ref: s,
      className: b(
        "Knu-Flex Knu-TabsList position-relative height-48px flexShrink-0",
        e.isScrollable() && "scrollable"
      ),
      children: [
        p(r),
        (n == null ? void 0 : n.width) && /* @__PURE__ */ c(
          "div",
          {
            style: {
              width: n.width - P * 2,
              "--translateX": `${n.offsetLeft + P}px`
            },
            className: "Knu-TabIndicator bg-accent position-absolute bottom-0 left-0 width-full"
          }
        )
      ]
    }
  );
});
O.displayName = "TabsList";
const k = i.memo(({ children: r }) => {
  const e = m(), [{ id: t, index: s }] = i.useState(() => e.registerPanel());
  return C(s), e.shouldMountPanel(s) ? /* @__PURE__ */ c(
    "div",
    {
      id: t,
      className: "Knu-Flex Knu-Panel flex-1 size-full minHeight-0",
      children: p(r)
    }
  ) : /* @__PURE__ */ c("div", { className: "Knu-Panel" });
});
k.displayName = "Panel";
const L = 200, W = 50, X = i.memo(({ children: r }) => {
  const e = i.useRef(null), t = i.useRef(null), s = m(), n = I(), o = s.getActiveIndex(), l = i.useRef(0), f = N(
    (a) => {
      const d = e.current;
      if (a.first) {
        const u = a.direction[0] === -1;
        (o === 0 && !u || o === s.numTabs() - 1 && u) && a.cancel(), d.classList.add("dragging"), s.willBecomeActive(o - a.direction[0]), l.current = a.movement[0];
      }
      const v = a.movement[0] - l.current, g = `translateX(calc(${o * -100}% + ${v}px))`;
      if (d.style.transform = g, !a.dragging) {
        const u = Math.abs(v) > W ? Math.max(
          0,
          Math.min(
            s.numTabs() - 1,
            o - a.direction[0]
          )
        ) : o, E = u !== o, w = (d.offsetWidth - Math.abs(v)) / Math.abs(a.velocity[0]);
        t.current = d.animate(
          [
            {
              transform: g
            },
            { transform: `translateX(${u * -100}%)` }
          ],
          {
            duration: E ? Math.min(w, L) : L,
            fill: "forwards"
          }
        );
        const T = () => {
          var y;
          const x = t.current;
          setTimeout(() => {
            x == null || x.cancel();
          }), e.current.style.transform = `translateX(${u * -100}%)`, s.setTabActive(u), d.classList.remove("dragging"), (y = t.current) == null || y.removeEventListener("finish", T), t.current = null;
        };
        t.current.addEventListener("finish", T);
      }
    },
    {
      threshold: 10,
      axis: "x"
    }
  );
  i.useEffect(() => s.subscribeForActiveIndexChange(n), [s, n]);
  const h = o * -100;
  return i.useLayoutEffect(() => {
    e.current.style.transform = "";
  }), /* @__PURE__ */ c(
    "div",
    {
      ...f(),
      ref: e,
      onTransitionEnd: (a) => {
        s.animationDone();
      },
      className: "Knu-Flex Knu-PanelsList flex-1 size-full",
      style: {
        "--translateX": h + "%"
      },
      children: r
    }
  );
});
X.displayName = "PanelsList";
export {
  k as Panel,
  X as PanelsList,
  D as Tab,
  q as TabText,
  V as Tabs,
  O as TabsList
};
