import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { useThemedNativeBackground } from '@knuddels-app/NativeBackground';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Link, Text, ThemeOverride, useTheme, resolveThemingValue, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $KnuddelDisplayComponents } from '@knuddelsModules/KnuddelDisplay';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService, KnuddelsPlusHeaderBackground, KnuddelsPlusStatusBadge, UserImage } from '@knuddelsModules/UserData';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { $OverlayService } from '@knuddels-app/overlays';
import { $AdsService, CAPACITOR_BADGE_HEIGHT, CapacitorBanners } from '@knuddelsModules/Ads';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
const useIsOverlayOpen = () => {
  const overlayService = useService($OverlayService);
  return useReactiveState(() => {
    return overlayService.isAnyOverlayVisible;
  }, [overlayService]);
};
export const DashboardHeader: React.FC<{
  layout: 'desktop' | 'mobile';
}> = observer('DashboardHeader', ({
  layout
}) => {
  useThemedNativeBackground();
  const adsService = useService($AdsService);
  const isOverlayOpen = useIsOverlayOpen();
  const showAds = adsService.areCapacitorAdsVisible && layout === 'mobile';
  const adsHeight = showAds ? CapacitorBanners.K3MoreBanner[1] + CAPACITOR_BADGE_HEIGHT : 0;
  return <div style={{
    height: resolveThemingValue((layout === 'mobile' ? 72 : 104) + adsHeight, "sizes", useTheme())
  }} className={_c0}>
			<KnuddelsPlusHeaderBackground />
			{showAds && <adsService.CapacitorAdzone adVisible={!isOverlayOpen} bannerId={'K3DiscoverBanner'} />}
			<div className={_c1}>
				<div className={_c2 + (layout === 'desktop' ? _c3 : _c4)}>
					<HeaderUserImage layout={layout} />
					<HeaderTitle />
				</div>
				<div className={_c5 + (layout === 'desktop' ? _c6 : _c7) + (layout === 'desktop' ? _c8 : _c9)}>
					<LoadService service={$KnuddelDisplayComponents}>
						{components => <components.CurrentUserKnuddelDisplay type={'normal'} showLoyaltySystemLabels numberFormat={'full'} firebaseContentType={'Dashboard_Header'} />}
					</LoadService>
				</div>
			</div>
		</div>;
});
const HeaderTitle: React.FC = () => {
  const userService = useService($UserService);
  const profileNavigationService = useService($ProfileNavigationService);
  const track = useLogEvent();
  const theme = useTheme();
  const user = userService.currentUser;
  const hasKnuddelsPlus = user && user.hasKnuddelsPlus;
  return <div className={_c10 + ((theme.id === 'light' && !hasKnuddelsPlus ? "transparentLight" : "transparentDark") ? resolveIsDarkColor(theme.id === 'light' && !hasKnuddelsPlus ? "transparentLight" : "transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (theme.id === 'light' && !hasKnuddelsPlus ? _c11 : _c12)}>
			<Text type={'subtitle'} state={'secondary'} bold={true} className={_c13}>
				<FormattedMessage id={declareFormat({
        id: 'channel.dashboard.title',
        defaultFormat: 'Hello {nick}!'
      })} values={{
        nick: <Link state={'secondary'} type={'subtitle'} bold onPress={() => {
          profileNavigationService.showCurrentUserProfile();
          track('Dashboard_Header', 'Nickname_Clicked');
        }}>
								{user?.nick}
							</Link>
      }} />
			</Text>
			<KnuddelsPlusStatusBadge />
		</div>;
};
const HeaderUserImage: React.FC<{
  layout: 'desktop' | 'mobile';
}> = ({
  layout
}) => {
  const userService = useService($UserService);
  const profileNavigationService = useService($ProfileNavigationService);
  const track = useLogEvent();
  const execute = useSlashCommandWithoutChannel();
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      if (userService.currentUser.profilePicture.exists) {
        profileNavigationService.showCurrentUserProfile();
      } else {
        execute('/opensystemapp ' + PROFILE_CUSTOMIZATION_APP_ID);
      }
      track('Dashboard_Header', 'ProfileImage_Clicked');
    }
  })} className={_c14}>
			<UserImage type={layout === 'mobile' ? 40 : 48} userId={userService.currentUser?.id} />
		</div>;
};
const _c0 = " Knu-FlexCol position-relative minHeight-0-px justifyContent-center overflow-hidden ";
const _c1 = " Knu-Flex position-relative alignItems-center flex-1 ";
const _c2 = " Knu-Flex position-relative mx-base zIndex-1 alignItems-center flex-1 ";
const _c3 = " justifyContent-center ";
const _c4 = " justifyContent-flex-start ";
const _c5 = " Knu-Flex zIndex-2 ";
const _c6 = " position-absolute ";
const _c7 = " position-relative ";
const _c8 = " right-xlarge ";
const _c9 = " right-base ";
const _c10 = " Knu-FlexCol ml-small gap-2px ";
const _c11 = " bg-transparentLight ";
const _c12 = " bg-transparentDark ";
const _c13 = "  ";
const _c14 = " Knu-Flex cursor-pointer ";