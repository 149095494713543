import { AlbumFragment } from '@generated/graphql';
import { PhotoRowElement } from './PhotoRow';

export function splitToPhotoRows(
	album: AlbumFragment,
	photoSize: number,
	photoSpacing: number,
	width: number,
	uploadingImages: { base64Image: string }[] = [],
	shouldAddNewPhotoButton = false
): readonly PhotoRowElement[][] {
	const elements = createPhotoRowElements(
		album,
		uploadingImages,
		shouldAddNewPhotoButton
	);

	const maxPhotosPerRow = Math.floor(
		(width + photoSpacing) / (photoSize + photoSpacing)
	);

	// Need to check for 0, to prevent divide by zero issue when no space for any
	// photo is available.
	const numRows =
		maxPhotosPerRow === 0
			? 0
			: Math.ceil(elements.length / maxPhotosPerRow);

	const rows: PhotoRowElement[][] = [];
	for (let i = 0; i < numRows; i++) {
		const startIndex = i * maxPhotosPerRow;
		const endIndex = startIndex + maxPhotosPerRow;
		const elementsInRow = elements.slice(startIndex, endIndex);

		rows.push(elementsInRow);
	}

	return rows;
}

function createPhotoRowElements(
	album: AlbumFragment,
	uploadingImages: { base64Image: string }[],
	shouldAddNewPhotoButton: boolean
): PhotoRowElement[] {
	const elements: PhotoRowElement[] = album.albumPhotos.map(photo => ({
		type: 'photo',
		photoId: photo.id,
		url: photo.thumbnailUrl,
	}));

	elements.push(
		...uploadingImages.map(
			(photo, index) =>
				({
					type: 'uploadingPhoto',
					id: 'uploading-' + index,
					base64Image: photo.base64Image,
				} as PhotoRowElement)
		)
	);

	if (shouldAddNewPhotoButton) {
		elements.push({
			type: 'addPhoto',
			albumId: album.id,
		});
	}

	return elements;
}
