import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PlaceholderContent } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import { TabContentPlaceholder } from '../TabContentPlaceholder';
export const NoAlbumsPlaceholder: React.FC<{
  title: string;
}> = ({
  title
}) => {
  const screenService = useService($ScreenService);
  const fullHeight = !screenService.isStackedLayout;
  return <TabContentPlaceholder fullHeight={fullHeight}>
			<PlaceholderContent image={require('./sm_world_06-0.gif')} imageWidth={94} imageHeight={56} title={title} fullHeight={fullHeight} description={<FormattedMessage id={declareFormat({
      id: 'profile.albums.empty',
      defaultFormat: "hasn't uploaded any photos yet"
    })} />} />
		</TabContentPlaceholder>;
};