import * as React from 'react';
import { EditProfileEntry, EditProfileEntryBaseProps } from './EditProfileEntry';
import { declareProps, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Input, TextArea } from '@knuddels/component-library';
import { useScrollIntoView } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfile/EditProfileContent/Entry/useScrollIntoView';
type Props = EditProfileEntryBaseProps & {
  text: string;
  multiLine?: boolean;
};
@injectable()
class EditProfileEntryFreeTextModel {
  constructor(@injectProps()
  private readonly props: Props) {}
  public onChangeText = (newText: string): void => {
    this.props.onValueChanged(this.props.field, newText);
  };
}
const PLACEHOLDER_FORMAT = declareFormat({
  id: 'FREE_TEXT_PLACEHOLDER',
  defaultFormat: 'Not specified'
});
export const EditProfileEntryFreeText = injectedComponent({
  name: 'EditProfileEntryFreeText',
  model: EditProfileEntryFreeTextModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<Props>()
}, ({
  model,
  i18n,
  image,
  title,
  validity,
  helperText,
  multiLine,
  text
}) => {
  const {
    ref,
    onFocus
  } = useScrollIntoView();
  return <EditProfileEntry image={image} valid={validity.type === 'valid'} innerRef={ref}>
				{multiLine ? <TextArea
    // innerRef={ref}
    label={title} labelStyle={'always-on-top'} error={validity.errorText || validity.type === 'invalid'} onFocus={onFocus} description={helperText} placeholder={PLACEHOLDER_FORMAT.format(i18n)} value={text} onChange={({
      value
    }) => {
      model.onChangeText(value);
    }} autoResize /> : <Input
    // innerRef={ref}
    onFocus={() => {
      // ref.current.scrollIntoView({
      // 	block: 'center',
      // 	behavior: 'smooth',
      // });
      onFocus();
    }} label={title} labelStyle={'always-on-top'} error={validity.errorText || validity.type === 'invalid'} description={helperText} placeholder={PLACEHOLDER_FORMAT.format(i18n)} value={text} onChange={({
      value
    }) => {
      model.onChangeText(value);
    }} />}
			</EditProfileEntry>;
});