import * as React from 'react';
import { declareProps, injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { $SmileyService } from '@knuddelsModules/AutocompleteInputBar';
import { Grid, Item, ItemRow } from '@knuddelsModules/Smileybox/bundle/components/SmileyGrid';
// tslint:disable-next-line: no-module-bleeding
import { SmileyImage } from '../../../Channel/bundle/components/Chat/ChatInput/SmileyImage';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { SmileyData } from '@shared/components/RichText/nodes/SmileyNode';
import { Flex, FlexCol, IconInfo, rgb, ThemeOverride, useTheme, toPointerHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { PlaceholderContent, SkeletonAnimation } from '@shared/components';
import { observer } from '@knuddels-app/mobx';
import { IconStar } from '@shared/icons/IconStar';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { IconStarOff } from '@shared/icons/IconStarOff';
import { useSmileyBoxTrackEvent } from './SmileyboxAnalyticsContext';
export const SmileyItem: React.FC<{
  smiley: SmileyData;
  editorRef: React.RefObject<RichTextEditorRef>;
  onPress?: () => void;
  nested?: boolean;
}> = observer('SmileyItem', ({
  smiley,
  editorRef,
  onPress,
  nested = false
}) => {
  const smileyService = useService($SmileyService);
  const keyboardService = useService($KeyboardService);
  const track = useSmileyBoxTrackEvent();
  const theme = useTheme();
  const renderData = smiley.multi && !nested && smileyService.smileyOverrides[smiley.id];
  return <Item title={smiley.name} multi={smiley.multi && !nested} onPress={() => {
    if (!smiley.owned) {
      track('UnownedSmiley_Clicked');
      smileyService.showBuySmiley(smiley);
      return;
    }
    onPress?.();
    track('OwnedSmiley_Used');
    editorRef.current.insertSmiley({
      ...smiley,
      url: renderData?.url ?? smiley.url,
      rpl: renderData?.rpl ?? smiley.rpl
    }, ({
      preventSelection
    }) => {
      if (keyboardService.focusable?.parentFocusable) {
        preventSelection();
      }
    });
  }} renderContextMenu={nested ? undefined : {
    getItems: async () => {
      const details = smiley.multi ? await smileyService.getMultiSmiley(smiley.id) : null;
      const detailData = details ? details.multiSyntax.map((_s, index) => {
        return {
          ...smiley,
          url: details.multiPics[index],
          rpl: details.multiSyntax[index]
        };
      }).filter(s => s.url !== (renderData ?? smiley)?.url) : null;
      if (!smiley.owned) {
        return detailData ?? [];
      }
      const favoriteEntry = ({
        type: smileyService.favoriteSmileys.find(favSmiley => smiley.id === favSmiley.id) ? 'unfavorite' : 'favorite'
      } as const);
      return detailData ? [...detailData, favoriteEntry, {
        type: 'info'
      }] : [favoriteEntry, {
        type: 'info'
      }];
    },
    renderItem: s => {
      if ('type' in s) {
        return <div onClick={toPointerHandler(e => {
          e.preventDefault();
          if (s.type === 'info') {
            smileyService.showSmileyDetails(smiley.id);
            return;
          }
          if (s.type === 'favorite') {
            smileyService.addFavorite({
              ...smiley,
              rpl: smiley.rpl
            });
            return;
          }
          smileyService.removeFavorite(smiley.id, smiley.rpl);
        })} key={s.type} style={{
          background: resolveThemingValue(theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880'], "colors", useTheme())
        }} className={_c0 + ((theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880']) ? resolveIsDarkColor(theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880'], useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
												{s.type === 'favorite' ? <IconStar size={'large'} /> : s.type === 'info' ? <IconInfo size={'large'} /> : <IconStarOff size={'large'} />}
											</div>;
      }
      return <SmileyItem key={s.url} nested smiley={s} editorRef={editorRef} onPress={() => {
        if (s.url === smiley.url) {
          smileyService.addSmileyOverride(smiley.id, null);
          return;
        }
        smileyService.addSmileyOverride(smiley.id, {
          url: s.url,
          rpl: s.rpl
        });
      }} />;
    }
  }}>
				<Flex style={smiley.owned ? {} : {
      filter: 'grayscale(1)'
    }}>
					<SmileyImage height={50} width={50} imageUrl={renderData?.url ?? smiley.url} />
				</Flex>
			</Item>;
});
const Skeleton: React.FC<{
  maxItemsPerRow: number;
}> = React.memo(({
  maxItemsPerRow
}) => {
  return <div className={_c2}>
				<div className={_c3}>
					<ItemRow itemSpacing={8}>
						{Array.from({
          length: maxItemsPerRow
        }).map((_, index) => <SkeletonAnimation key={index}>
									<Item />
								</SkeletonAnimation>)}
					</ItemRow>
				</div>
				<div className={_c4}>
					<ItemRow itemSpacing={8}>
						{Array.from({
          length: maxItemsPerRow
        }).map((_, index) => <SkeletonAnimation key={index}>
									<Item />
								</SkeletonAnimation>)}
					</ItemRow>
				</div>
				<div className={_c5}>
					<ItemRow itemSpacing={8}>
						{Array.from({
          length: maxItemsPerRow
        }).map((_, index) => <SkeletonAnimation key={index}>
									<Item />
								</SkeletonAnimation>)}
					</ItemRow>
				</div>
			</div>;
});
Skeleton.displayName = 'Skeleton';
export const SmileyList = injectedComponent({
  name: 'SmileyList',
  props: declareProps<{
    maxItemsPerRow: number;
    editorRef: React.RefObject<RichTextEditorRef>;
  }>()
}, ({
  editorRef,
  maxItemsPerRow
}) => {
  const smileyService = useService($SmileyService);
  const [loading, setLoading] = React.useState(smileyService.mySmileys?.length === 0);
  React.useEffect(() => {
    if (smileyService.mySmileys.length > 0) {
      return;
    }
    smileyService.getMySmileys().then(() => {
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <Skeleton maxItemsPerRow={maxItemsPerRow - 1} />;
  }
  if (smileyService.mySmileys.length === 0) {
    return <div className={_c6}>
					<PlaceholderContent setBgTop={false} fullHeight py={'none'} description={'Erhalte handgefertigte Knuddel-Smileys über Quests oder direkt über der Smiley-Börse!'} title={'Du besitzt noch keine Smileys'} image={require('@shared/icons/sm_17-04-Columbus_ani.gif')} imageWidth={69} imageHeight={63} />
				</div>;
  }
  return <Grid groups={[{
    label: 'Zuletzt verwendet',
    items: smileyService.lastUsedSmileys.map(s => ({
      ...s,
      owned: true
    }))
  }, {
    label: 'Favoriten',
    items: smileyService.favoriteSmileys
  }, {
    label: 'Smiley-Rotation',
    items: smileyService.rotationSmiley
  }, {
    label: 'Alle',
    items: smileyService.mySmileys
  }]} itemSize={64} itemSpacing={8} pl={16} bottomSpacing={24} maxItemsPerRow={maxItemsPerRow} renderItem={smiley => {
    return <SmileyItem key={smiley.id + smiley.rpl} editorRef={editorRef} smiley={smiley} />;
  }} />;
});
const _c0 = " Knu-Flex cursor-pointer overflow-hidden borderRadius-minor size-48px placeItems-center ";
const _c1 = " Knu-Flex ";
const _c2 = " Knu-FlexCol px-8px ";
const _c3 = " Knu-Flex ";
const _c4 = " Knu-Flex ";
const _c5 = " Knu-Flex ";
const _c6 = " Knu-Flex size-full placeItems-center ";