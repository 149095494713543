import * as React from 'react';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { InfoCardItem } from '@shared/components/molecules/InfoCard';
import { useService } from '@knuddels-app/DependencyInjection';
import { Smiley, Text } from '@knuddels/component-library';
const SMILEY_SIZE = 40;
export const useMentorSystemTips = (): InfoCardItem[] => {
  const i18n = useService($I18n);
  return [{
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/kleinerplausch.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.curious.header',
      defaultFormat: 'Zeig Interesse'
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c0}>
					{declareFormat({
        id: 'mentorsystem.about.tips.curious.text',
        defaultFormat: 'Interessiere dich mit Neugier für deinen neuen Schützling. Dadurch habt ihr viel bessere Gespräche und dein Schützling wird begeistert sein.'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/koffertausch.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.secret.header',
      defaultFormat: 'Secret mentor'
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c1}>
					{declareFormat({
        id: 'mentorsystem.about.tips.secret.text',
        defaultFormat: "Build a conversation without offering help or saying you're the mentor."
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/selfie.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.introducing.header',
      defaultFormat: 'Introduce yourself'
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c2}>
					{declareFormat({
        id: 'mentorsystem.about.tips.introducing.text',
        defaultFormat: 'Make your profile as appealing as possible so that your mentees know who they are chatting with.'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/captionthis.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.smalltalk.header',
      defaultFormat: 'Start with small talk'
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c3}>
					{declareFormat({
        id: 'mentorsystem.about.tips.smalltalk.text',
        defaultFormat: "Don't overwhelm your mentee at the beginning with too long or too many texts. Short, simple small talk will do for now."
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/sprayer.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.emotions.header',
      defaultFormat: 'Show emotions'
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c4}>
					{declareFormat({
        id: 'mentorsystem.about.tips.emotions.text',
        defaultFormat: 'Use smileys like :D'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/schreibblockade.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.dontgiveup.header',
      defaultFormat: "Don't give up"
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c5}>
					{declareFormat({
        id: 'mentorsystem.about.tips.dontgiveup.text',
        defaultFormat: "Write a second time if you don't get an answer the first time. Especially new members overlook one or the other."
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <Smiley size={SMILEY_SIZE} src={require('../../assets/picknick.png')} />,
    header: declareFormat({
      id: 'mentorsystem.about.tips.grow.header',
      defaultFormat: 'Grow together'
    }).format(i18n),
    textContent: <Text state={'secondary'} className={_c6}>
					{declareFormat({
        id: 'mentorsystem.about.tips.grow.text',
        defaultFormat: "Don't just take care of the first minutes, but also in the following days and weeks."
      }).format(i18n)}
				</Text>
  }];
};
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = "  ";
const _c6 = "  ";