import { jsx as n, jsxs as T } from "react/jsx-runtime";
import * as d from "react";
import { InputContainer as y } from "./InputContainer.js";
import { StyledTextArea as B } from "./StyledTextArea.js";
import { ClearButton as C } from "./ClearButton.js";
import { useTheme as F } from "../../themes/useTheme.js";
const R = (e) => {
  var l, c;
  const a = F().sizes.input.textarea, [m, h] = d.useState(e.height ?? a), [s, i] = d.useState(!1), { placeholder: f, onKeyPress: g, ...u } = e, o = e.focused ?? s, x = e.autoResize ? (t) => {
    h(Math.max(e.height ?? a, t));
  } : void 0, r = () => {
    var t;
    (t = e.onChange) == null || t.call(e, { value: "" });
  }, v = e.showClearButton && ((l = e.value) != null && l.length) ? /* @__PURE__ */ n(C, { onPress: r }) : e.adornmentEnd;
  return /* @__PURE__ */ n(
    y,
    {
      ...u,
      adornmentEnd: v,
      focused: o,
      placeholder: f,
      label: e.label,
      height: m,
      additionalInfo: e.maxLength !== void 0 ? /* @__PURE__ */ T("span", { className: "Knu-Text text-tiny text-tertiary", children: [
        ((c = e.value) == null ? void 0 : c.length) ?? 0,
        "/",
        e.maxLength
      ] }) : void 0,
      children: /* @__PURE__ */ n(
        B,
        {
          ...u,
          onKeyPress: g,
          focused: o,
          onResize: x,
          onFocus: () => {
            var t;
            i(!0), (t = e.onFocus) == null || t.call(e);
          },
          onBlur: () => {
            setTimeout(() => {
              var t;
              i(!1), (t = e.onBlur) == null || t.call(e);
            }, 100);
          }
        }
      )
    }
  );
};
export {
  R as TextArea
};
