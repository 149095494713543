import * as React from 'react';
import { declareProps, IModel, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { AppsWebviewProps } from './AppsWebviewProps';
import { useAppContext } from './useAppContext';
@injectable()
class AppsWebviewModel implements IModel {
  public readonly iframeRef = React.createRef<HTMLIFrameElement>();
  constructor(@injectProps()
  private readonly props: AppsWebviewProps) {
    window.addEventListener('message', this.handleMessage);
  }
  dispose(): void {
    window.removeEventListener('message', this.handleMessage);
  }
  init = (context: any) => {
    const windowRef = this.iframeRef.current && this.iframeRef.current.contentWindow;
    if (!windowRef) {
      console.error('Something went wrong initializing app:', this.props.appInstance.data.appId);
      this.props.appInstance.close();
      return;
    }
    this.props.appInstance.initWebview({
      postMessage: (message: string) => {
        windowRef.postMessage(message, '*');
      }
    }, {
      ...context,
      ...this.props.context
    });
  };
  handleMessage = (ev: MessageEvent) => {
    if (this.iframeRef.current && ev.source === this.iframeRef.current.contentWindow) {
      this.props.appInstance.handleMessageEvent(ev.data);
    }
  };
}
export const AppsWebview = injectedComponent({
  name: 'AppsWebview',
  model: AppsWebviewModel,
  props: declareProps<AppsWebviewProps>()
}, ({
  model,
  appInstance
}) => {
  const genericContext = useAppContext(appInstance);
  const contentBg = React.useRef(genericContext.contentBg);
  return <iframe key={appInstance.uniqueId} ref={model.iframeRef} style={{
    width: '100%',
    height: '100%',
    border: 'none'
  }} src={appInstance.getLoaderUrl(contentBg.current).replace('client=K3IOS', 'client=K3IOSCapacitor').replace('client=K3Android', 'client=K3AndroidCapacitor')} onLoad={() => model.init(genericContext)} allow={appInstance.isUserApp ? undefined : 'microphone;camera;clipboard-write'} />;
});