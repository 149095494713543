import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $ViewService } from '@knuddels-app/layout';
import { channelListViewId } from './ChannelListViewProvider';
import { $CommandService } from '@knuddels-app/Commands';

export const $ChannelListCommandService =
	newServiceId<ChannelListCommandService>('$ChannelListCommandService');

@injectable()
export class ChannelListCommandService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($ViewService)
		viewService: typeof $ViewService.T,
		@inject($CommandService)
		commandService: typeof $CommandService.T
	) {
		this.dispose.track(
			commandService.registerCommand({
				commandName: 'wc',
				async invoke(): Promise<void> {
					viewService.openView(
						channelListViewId.with(s => s.asOverview())
					);
				},
			})
		);
	}
}
