import { declareProps, useService } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import * as React from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { $AuthenticatedClientService, $AuthService } from '../serviceIds';

/**
 * Injects an ApolloProvider if the client is in loggedIn state.
 */
export const K3ApolloProvider = injectedComponent({
  name: 'K3ApolloProvider',
  props: declareProps<React.PropsWithChildren<{}>>(),
  inject: {
    authService: $AuthService
  }
}, ({
  children
}) => {
  const service = useService($AuthenticatedClientService);
  return <ApolloProvider client={service.currentClient}>
				{children}
			</ApolloProvider>;
});