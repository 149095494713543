import * as React from 'react';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { AccordionItem } from '@shared/components/molecules/Accordion';
import { useService } from '@knuddels-app/DependencyInjection';
import { Link } from '@knuddels/component-library';
import { FAMILY_MEMBER_URL, MENTOR_POINTS_URL } from '../../constants';
export const useMentorSystemInfos = (): AccordionItem[] => {
  const i18n = useService($I18n);
  return [{
    header: declareFormat({
      id: 'mentorsystem.about.info.meaning.header',
      defaultFormat: 'What is the mentor system?'
    }).format(i18n),
    content: <FormattedMessage id={declareFormat({
      id: 'mentorsystem.about.info.meaning.text',
      defaultFormat: 'The mentor system is used to welcome new members in a friendly manner in the chat and to enable initial discussions. In addition, more experienced chatters are assigned to new members who can look after them.'
    })} />
  }, {
    header: declareFormat({
      id: 'mentorsystem.about.info.conditions.header',
      defaultFormat: 'When can I become a mentor?'
    }).format(i18n),
    content: <FormattedMessage id={declareFormat({
      id: 'mentorsystem.about.info.conditions.text',
      defaultFormat: 'At the latest as a {familyMember} you can also become a mentor, earlier if there is a particularly high need for mentors and you already appear to be suitable. With the help of the mentor system, you can meet new people, have nice conversations and gain new experiences.'
    })} values={{
      familyMember: <Link to={FAMILY_MEMBER_URL} state={'highlighted'} openInLocation={'new'}>
								<FormattedMessage id={declareFormat({
          id: 'mentorsystem.about.info.conditions.text.family-member',
          defaultFormat: 'family member'
        })} />
							</Link>
    }} />
  }, {
    header: declareFormat({
      id: 'mentorsystem.about.info.benefits.header',
      defaultFormat: 'What do I get as a mentor?'
    }).format(i18n),
    content: <FormattedMessage id={declareFormat({
      id: 'mentorsystem.about.info.benefits.text',
      defaultFormat: 'And you can also collect {mentorPoints} and get great smileys. You will also receive 1 Knuddel for every 10 contacts made and for successful appointments as a mentor.\n\nYou can get mentor points from the very first moment. As an official mentor, however, you have the chance to get significantly more points if your mentee actively chats. But you also have to make a long-term effort to look after your mentee.'
    })} values={{
      mentorPoints: <Link to={MENTOR_POINTS_URL} state={'highlighted'} openInLocation={'new'}>
								<FormattedMessage id={declareFormat({
          id: 'mentorsystem.about.info.benefits.text.mentor-points',
          defaultFormat: 'mentor points'
        })} />
							</Link>
    }} />
  }, {
    header: declareFormat({
      id: 'mentorsystem.about.info.how.header',
      defaultFormat: 'How do I become a mentor?'
    }).format(i18n),
    content: <FormattedMessage id={declareFormat({
      id: 'mentorsystem.about.info.how.text',
      defaultFormat: 'New members will be suggested to you as a possible mentor. The best thing to do is switch to the channel the member is in and start a nice conversation. We give you tips that have resulted from our many years of experience. At this moment you are a mentor candidate. You can only become a mentor once you and the potential mentee have exchanged at least 5 messages.'
    })} />
  }];
};