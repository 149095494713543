import { isNumericalString as l } from "../../../utils/is-numerical-string.js";
import { isCSSVariableToken as c } from "./is-css-variable.js";
const u = (
  // eslint-disable-next-line redos-detector/no-unsafe-regex -- false positive, as it can match a lot of words
  /^var\(--(?:([\w-]+)|([\w-]+), ?([a-zA-Z\d ()%#.,-]+))\)/u
);
function s(a) {
  const t = u.exec(a);
  if (!t)
    return [,];
  const [, e, o, r] = t;
  return [`--${e ?? o}`, r];
}
function f(a, t, e = 1) {
  const [o, r] = s(a);
  if (!o)
    return;
  const i = window.getComputedStyle(t).getPropertyValue(o);
  if (i) {
    const n = i.trim();
    return l(n) ? parseFloat(n) : n;
  }
  return c(r) ? f(r, t, e + 1) : r;
}
export {
  f as getVariableValue,
  s as parseCSSVariable
};
