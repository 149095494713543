import { FullConversationFragment, GetConversationComponent, GetConversationQuery, GetConversationQueryVariables, GetConversationWithoutMessagesComponent, GetConversationWithoutMessagesQuery, IgnoreState, LoadMoreMessagesDocument, LoadMoreMessagesQuery, MessengerConversation } from '@generated/graphql';
import * as React from 'react';
import { QueryResult } from '@apollo/client/react';
import { observable, runInAction } from '@knuddels-app/mobx';
import { Observer } from 'mobx-react';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { distinct } from '@knuddels/std';
interface Props {
  id: MessengerConversation['id'];
  children: (conversation: FullConversationFragment | undefined, loading: boolean, fetchMoreMessages: () => Promise<void>, initialLoading: boolean) => JSX.Element;
}
export class WithConversationWithMessages extends React.Component<Props, {
  shouldRender: boolean;
}> {
  state = {
    shouldRender: false
  };
  @observable
  private isFetchingMessages = false;
  componentDidMount(): void {
    /*
    	we need to delay the rendering in order for the animations to work more smoothly
    	*/
    setTimeout(() => {
      this.setState({
        shouldRender: true
      });
    });
  }
  render(): React.ReactNode {
    const {
      id,
      children
    } = this.props;
    if (!this.state.shouldRender) {
      return <Observer>{() => children(null, true, null, true)}</Observer>;
    }
    return <GetConversationComponent variables={{
      id,
      pixelDensity: getPixelRatio()
    }} fetchPolicy={'cache-first'}>
				{({
        data,
        loading,
        fetchMore
      }) => {
        return <GetConversationWithoutMessagesComponent variables={{
          id,
          pixelDensity: getPixelRatio()
        }} fetchPolicy={'cache-only'}>
							{({
            data: partialData
          }) => {
            return <CombineConversationData remoteLoading={loading} conversationId={id} partialData={partialData} data={data}>
										{(finalConversation, initialLoading) => {
                // add Observer to trigger an update when `this.isFetchingMessages` is updated
                return <Observer>
													{() => children(finalConversation, loading || this.isFetchingMessages, () => this.handleFetchMore(finalConversation, fetchMore), initialLoading)}
												</Observer>;
              }}
									</CombineConversationData>;
          }}
						</GetConversationWithoutMessagesComponent>;
      }}
			</GetConversationComponent>;
  }
  private handleFetchMore = async (conversation: FullConversationFragment | undefined, fetchMore: QueryResult<GetConversationQuery, GetConversationQueryVariables>['fetchMore']): Promise<void> => {
    if (!conversation || !conversation.conversationMessages.hasMore || this.isFetchingMessages) {
      return;
    }
    runInAction('Start fetching more messages', () => {
      this.isFetchingMessages = true;
    });
    try {
      await fetchMore({
        query: LoadMoreMessagesDocument,
        variables: {
          conversationId: conversation.id,
          beforeMessageTimestamp: conversation.conversationMessages.messages[0].timestamp
        },
        updateQuery: (previosQueryResult, options) => {
          return mergeQueries(previosQueryResult, (
          // The types are incorrect here because we use a different query for loading more messages
          options.fetchMoreResult as LoadMoreMessagesQuery));
        }
      });
    } catch (e) {
      console.error('Failed fetching more messages:', e);
    }
    runInAction('Stop fetching more messages', () => {
      this.isFetchingMessages = false;
    });
  };
}
function mergeQueries(current: GetConversationQuery, fetchMoreResult: LoadMoreMessagesQuery | undefined): GetConversationQuery {
  const fetchMoreMessagesWrapper = fetchMoreResult && fetchMoreResult.messenger.conversationMessages;
  if (!fetchMoreMessagesWrapper) {
    return current;
  }
  // force current conversation type to be not null because
  // this function should not be called if this is the case.
  const currentConversation = current.messenger.conversation!;
  const currentMessages = currentConversation.conversationMessages.messages;
  const fetchMoreMessages = fetchMoreMessagesWrapper.messages;

  // It is possible for two messages to have the exact same timestamp,
  // which results in messages being returned that we already have.
  const distinctMessages = distinct([...fetchMoreMessages, ...currentMessages], item => item.id);
  return {
    ...current,
    messenger: {
      ...current.messenger,
      conversation: {
        ...currentConversation,
        conversationMessages: {
          ...currentConversation.conversationMessages,
          messages: distinctMessages,
          hasMore: fetchMoreMessagesWrapper.hasMore
        }
      }
    }
  };
}
export const CombineConversationData: React.FC<{
  conversationId: string;
  partialData: GetConversationWithoutMessagesQuery;
  data: GetConversationQuery;
  remoteLoading: boolean;
  children: (conversation: FullConversationFragment | undefined, initialLoading: boolean) => JSX.Element;
}> = ({
  conversationId,
  partialData,
  remoteLoading,
  data,
  children
}) => {
  const [initialLoading, setInitialLoading] = React.useState(true);
  React.useEffect(() => {
    if (!remoteLoading) {
      setTimeout(() => {
        setInitialLoading(false);
      });
    }
  }, [remoteLoading]);
  const [conversation, setConversation] = React.useState(() => combineConversationData(conversationId, partialData, data));
  React.useLayoutEffect(() => {
    setConversation(combineConversationData(conversationId, partialData, data));
  }, [conversationId, partialData, data]);
  return children(conversation, initialLoading);
};
function combineConversationData(conversationId: string, partialData: GetConversationWithoutMessagesQuery, data: GetConversationQuery): FullConversationFragment | undefined {
  const conversation = data && data.messenger && data.messenger.conversation;
  const partialConversation = partialData && partialData.messenger && partialData.messenger.conversation;
  return conversation && conversation.id === conversationId ? conversation : partialConversation && partialConversation.otherParticipants ? {
    ...partialConversation,
    otherParticipants: partialConversation.otherParticipants.map(it => ({
      ...it,
      ignoreState: IgnoreState.None,
      isIgnoring: false,
      isAllowedByContactFilter: true
    })),
    conversationMessages: {
      messages: partialConversation.latestConversationMessage ? [partialConversation.latestConversationMessage] : [],
      hasMore: false
    }
  } : undefined;
}