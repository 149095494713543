import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $UserService } from '@knuddelsModules/UserData';
export const useOpenKnuddelShopIfNecessary = () => {
  const getUserService = useLazyService($UserService);
  const execute = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  return async (amountNeeded = 1) => {
    const currentUser = (await getUserService()).currentUser;
    const currentKnuddelAmount = currentUser?.knuddelAmount;
    if (
    // if currentKnuddelAmount is not known (which shouldn't happen here),
    // we just ignore it and try to execute the command.
    typeof currentKnuddelAmount === 'number' && currentKnuddelAmount < amountNeeded) {
      execute('/buyknuddel');
      return true;
    }
    return false;
  };
};
export const useDoKnuddel = (trackSnackbar: (message: string) => void = () => {}) => {
  const openKnuddelShopIfNecessary = useOpenKnuddelShopIfNecessary();
  const execute = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  return async (nick: string) => {
    const hasOpenedKnuddelShop = await openKnuddelShopIfNecessary(1);
    if (hasOpenedKnuddelShop) {
      trackSnackbar('KnuddelNotEnoughError');
      return;
    }
    execute(`/sendknuddel ${nick}:1`);
  };
};