import { jsx as o } from "react/jsx-runtime";
import { Flex as i } from "./Flex.js";
import { Fade as s } from "../Animation/Primitives.js";
import { LinearGradient as d } from "./LinearGradient.js";
import { useTheme as a } from "../../themes/useTheme.js";
const f = ({
  backgroundStyle: n = "gradient",
  ...e
}) => {
  const t = a(), r = {
    onHover: e.onHover,
    onPress: e.onPress,
    position: "absolute",
    inset: "none",
    flex: 1,
    zIndex: e.zIndex
  };
  return /* @__PURE__ */ o(
    i,
    {
      position: "absolute",
      inset: "none",
      display: e.open ? void 0 : "none",
      zIndex: e.zIndex,
      children: /* @__PURE__ */ o(
        s,
        {
          visible: e.open,
          style: { width: "100%", height: "100%" },
          children: n === "gradient" ? /* @__PURE__ */ o(
            d,
            {
              ...r,
              colors: t.colors.gradients.backgroundScrim
            }
          ) : /* @__PURE__ */ o(
            i,
            {
              ...r,
              bg: n === "opaque" ? "backgroundScrim" : "transparent"
            }
          )
        }
      )
    }
  );
};
export {
  f as Backdrop
};
