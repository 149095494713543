import { ChannelCategoryFragment } from '@generated/graphql';
import React from 'react';
import { ChannelGroupExpandedContext } from '../../../ChannelGroupExpandedContext';
import { getChannelGroupsPerCategory, mayShowMoreButton } from '../../constants';
import { ChannelListItem, createChannelGroupVirtualListItem, templateCategoryHeader } from '../../../shared/ChannelList/ChannelListVirtualItems';
import { ShowAllChannelsButton } from './CategoryHeaderButton';
import { istLastVisitedCategory } from '../../../shared/getCategoryFormatId';
export const useListData = (categories: readonly ChannelCategoryFragment[], trackingContentType: string, condensedEntries?: boolean): ChannelListItem[] => {
  const {
    isExpanded
  } = React.useContext(ChannelGroupExpandedContext);
  const result: ChannelListItem[] = [];
  categories.filter(it => it.channelGroups.length > 0).forEach(category => {
    const channelGroups = category.channelGroups;
    const groupsPerCategory = getChannelGroupsPerCategory(category.id);
    const exceedsLimit = channelGroups.length > groupsPerCategory;
    const headerButton = exceedsLimit && mayShowMoreButton(category.id) ? <ShowAllChannelsButton categoryName={category.name} categoryId={category.id} grouped={!istLastVisitedCategory(category?.id)} trackingContentType={trackingContentType} /> : null;
    result.push({
      type: templateCategoryHeader,
      key: category.id,
      height: 72,
      title: category.name,
      button: headerButton
    });
    const limitedGroups = channelGroups.slice(0, groupsPerCategory);
    limitedGroups.forEach((group, index) => {
      const userCount = group.channels.reduce((previousValue, currentValue) => previousValue + currentValue.onlineUserCount, 0);
      const key = category.id + '-' + group.id;
      result.push(createChannelGroupVirtualListItem(key, group, userCount, isExpanded(key), index === limitedGroups.length - 1, condensedEntries ? 'condensed' : 'default'));
    });
  });
  return result;
};