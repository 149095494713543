import React from 'react';
import { SettingsOverview } from '../components/Overview/SettingsOverview';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { SettingsMainView } from '../components/SettingsMainView';
import { Disposable } from '@knuddels/std';
import { ContactListSettings } from '../components/ContactListSettings';
import { PrivacySettings } from '../components/PrivacySettings/PrivacySettings';
import { SoundSettings } from '../components/SoundSettings';
import { MentorSystemSettings } from '../components/MentorSystemSettings';
import { MessengerSettingsOverview } from '../components/MessengerSettings/MessengerSettingsOverview';
import { ChannelListSettings } from '../components/ChannelListSettings/ChannelListSettings';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

@injectable()
export class SettingsComponents {
	public readonly dispose = Disposable.fn();

	public readonly MainView = SettingsMainView;
	public readonly Settings = SettingsOverview;
	public readonly CreditsGetter = React.lazy(
		() => import('../components/Credits/Credits')
	);
	public readonly ContactListSettings = ContactListSettings;
	public readonly SoundSettings = SoundSettings;
	public readonly PrivacySettings = PrivacySettings;
	public readonly MentorSystemSettings = MentorSystemSettings;
	public readonly MessengerSettings = MessengerSettingsOverview;
	public readonly ChannelListSettings = ChannelListSettings;

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
	}
}
