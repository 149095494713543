import * as React from 'react';
import { Box, Image, resolveThemingValue, useTheme } from '@knuddels/component-library';
export const UploadingThumbnailPhoto: React.FC<{
  base64Image: string;
  size: number;
} & React.ComponentProps<typeof Box>> = ({
  base64Image,
  size,
  ...boxProps
}) => {
  return <Box opacity={0.44} {...boxProps}>
			<Image src={base64Image} resizeMode={'cover'} alt={''} style={{
      width: resolveThemingValue(size + 'px', "sizes", useTheme()),
      height: resolveThemingValue(size + 'px', "sizes", useTheme())
    }} className={_c0} />
		</Box>;
};
const _c0 = "  ";