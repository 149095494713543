import { easeInOut } from './easeInOut';

export function setScrollTop(component: any, newScrollTop: number): void {
	const start = component.scrollTop;
	const change = newScrollTop - start;
	const increment = 20;
	const duration = 200;

	const animateScroll = (elapsedTime: number) => {
		component.scrollTop = easeInOut(
			elapsedTime + increment,
			start,
			change,
			duration
		);
		if (elapsedTime < duration) {
			setTimeout(() => {
				animateScroll(elapsedTime + increment);
			}, increment);
		}
	};

	animateScroll(0);
}
