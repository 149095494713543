interface StyleProps {
	className?: string;
	style?: any;
}

// TODO unit test me
export function mergeStyles(
	styleProps: StyleProps[],
	customStyles: any
): StyleProps {
	const mergedStyleObject = styleProps
		.map(item => {
			return item.style;
		})
		.concat([customStyles])
		.filter(item => {
			return item !== undefined;
		})
		.reduce((x, y) => {
			return { ...x, ...y };
		}, {});

	const concatenatedClassNames = styleProps
		.map(item => {
			return item.className;
		})
		.filter(item => {
			return item !== undefined;
		})
		.join(' ');

	const result: StyleProps = {
		style: mergedStyleObject,
	};

	if (concatenatedClassNames.length) {
		result.className = concatenatedClassNames;
	}

	return result;
}
