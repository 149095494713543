import * as React from 'react';
import { Column, GenericModal, Spacer } from '@shared/components';
import { $I18n, declareFormat, FormatId, formatMessage } from '@knuddels-app/i18n';
import { $CurrentEndpointStore, Endpoint, idToKnownEndpointId, KnownEndpoints } from '@knuddels-app/Connection';
import { $OverlayService, $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action } from '@knuddels-app/mobx';
import { $AllowedServerAccessStore, $EndpointProvider } from '@knuddels-app/Connection/serviceIds';
import { EndpointInputModal } from './EndpointInputModal';
import { Box, FlexCol, LineDivider, Text, toPointerHandler } from '@knuddels/component-library';
function getLocalizedIntlKey(serverKey: keyof typeof KnownEndpoints): FormatId {
  switch (serverKey) {
    case 'DE':
      return declareFormat({
        id: 'landingpage.server-switch-modal.server.de',
        defaultFormat: 'Germany'
      });
    case 'Dev':
      return declareFormat({
        id: 'landingpage.server-switch-modal.server.dev',
        defaultFormat: 'Dev Server (User-Apps-Development)'
      });
    case 'Local':
      return declareFormat({
        id: 'landingpage.server-switch-modal.server.local',
        defaultFormat: 'Local'
      });
    case 'Test':
      return declareFormat({
        id: 'landingpage.server-switch-modal.server.test',
        defaultFormat: 'Test'
      });
    default:
  }
  throw new Error('Not supported');
}
@injectable()
class ServerSwitchModalModel {
  private hiddenDevClickCounter = 0;
  constructor(@injectProps()
  props: {}, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($CurrentEndpointStore)
  private readonly currentEndpointStore: typeof $CurrentEndpointStore.T, @inject($EndpointProvider)
  private readonly endpointProvider: typeof $EndpointProvider.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($AllowedServerAccessStore)
  private readonly allowedServerAccessStore: typeof $AllowedServerAccessStore.T) {}
  get endpoints(): Endpoint[] {
    return this.endpointProvider.getEndpoints();
  }
  @action.bound
  public hideModal(): void {
    this.thisVisibleOverlay.dispose();
  }
  @action.bound
  public setEndpoint(endpoint: Endpoint): void {
    if (endpoint.id === 'Local') {
      const setEndpoint = () => {
        this.currentEndpointStore.setEndpoint(endpoint);
      };
      const updateLocalEndpoint = (text: string) => {
        this.updateLocalEndpoint(text, endpoint);
      };
      this.hideModal();
      this.overlayService.showOverlay({
        view: <EndpointInputModal onCancel={setEndpoint} onFinish={updateLocalEndpoint} />
      });
    } else {
      this.currentEndpointStore.setEndpoint(endpoint);
      this.hideModal();
    }
  }
  private updateLocalEndpoint = (text: string, endpoint: Endpoint): void => {
    const domain = text.trim();
    this.currentEndpointStore.setLocalOverrideDomain(domain);
    this.currentEndpointStore.setEndpoint(endpoint);
  };
  public handleHiddenDevClick = () => {
    this.hiddenDevClickCounter++;
    if (this.hiddenDevClickCounter > 10) {
      this.allowedServerAccessStore.addAllowedServer('Test');
      this.allowedServerAccessStore.addAllowedServer('Dev');
    }
  };
}
export const ServerSwitchModal = injectedComponent({
  name: 'ServerSwitchModal',
  model: ServerSwitchModalModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => <GenericModal headline={i18n.format(declareFormat({
  id: 'landingpage.server-switch-modal.headline',
  defaultFormat: 'Choose Server'
}))} primaryButton={{
  text: i18n.format(declareFormat({
    id: 'landingpage.server-switch-modal.button',
    defaultFormat: 'Okay'
  })),
  onClick: model.hideModal,
  width: 195
}} cancelModal={model.hideModal}>
			{model.endpoints.map((endpoint, index) => <React.Fragment key={`server-${endpoint.id}`}>
					{index === 0 ? null : <LineDivider className={_c0} />}
					<ServerEntry endpoint={endpoint} setEndpoint={model.setEndpoint} />
				</React.Fragment>)}
			<div onClick={toPointerHandler(model.handleHiddenDevClick)} className={_c1}>
				<Spacer size={'base'} />
			</div>
		</GenericModal>);
function ServerEntry({
  endpoint,
  setEndpoint
}: {
  endpoint: Endpoint;
  setEndpoint(endpoint: Endpoint): void;
}): JSX.Element {
  const intlKey = getLocalizedIntlKey(idToKnownEndpointId(endpoint.id));
  const server = intlKey ? formatMessage(intlKey) : endpoint.id;
  const onClick = () => setEndpoint(endpoint);
  return <div onClick={toPointerHandler(onClick)} className={_c2}>
			<Column>
				<Text type={'body1'} className={_c3}>
					{server}
				</Text>
			</Column>
		</div>;
}
const _c0 = "  ";
const _c1 = " Knu-Box ";
const _c2 = " Knu-FlexCol cursor-pointer position-relative ";
const _c3 = " my-small ";