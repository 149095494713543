import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { ChannelGroupExpandedContextDefaultProvider } from '../ChannelGroupExpandedContext';
import { ChannelSelectionContent } from './ChannelSelection/ChannelSelectionContent';
export const Overview: React.FC<{
  refetchOverview: boolean;
}> = observer('Overview', ({
  refetchOverview
}) => {
  return <ChannelGroupExpandedContextDefaultProvider>
				<ChannelSelectionContent refetchOverview={refetchOverview} />
			</ChannelGroupExpandedContextDefaultProvider>;
});