import { createK3Container } from './createK3Container';
import { knownModules } from '@knuddelsModules/knownModules';
import * as connection from '@knuddels-app/Connection';
import { coreModule } from '@knuddels-app/registerCoreServices';
import { dangerouslySetCurrentContainer } from '@knuddels-app/ModuleSystem/dangerouslyGetK3Container';

/**
 * Only use this once at the start of the app.
 */
export function dangerouslyInitK3Container(): void {
	const container = createK3Container([coreModule, ...knownModules]);
	dangerouslySetCurrentContainer(container);
	container.activateAutostartServices();
	connection.setupConstants(container);
}
