import { FullConversationFragment } from '@generated/graphql';
import { ReadStateSource } from './ReadStateSource';
import { BADGE_PLACEHOLDER, getBadgeCount } from '@shared/helper/getBadgeCount';

/**
 * Returns if the conversation is unread. Returns false if conversation is undefined.
 * @param conversation
 */
export function isConversationUnread(
	conversation: Pick<FullConversationFragment, 'readState'> | undefined | null
): boolean {
	if (!conversation) {
		return false;
	}
	return (
		conversation.readState.markedAsUnread ||
		conversation.readState.unreadMessageCount > 0
	);
}

export function conversationReadStateToString(
	readState: ReadStateSource['readState'] | undefined
): string | undefined {
	if (readState.unreadMessageCount > 0) {
		return getBadgeCount(readState.unreadMessageCount);
	}
	if (readState.markedAsUnread) {
		return BADGE_PLACEHOLDER;
	}
	return undefined;
}
