import { Flex } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { AfterSubmitBehavior, RichInput } from '@shared/components/RichInput';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { $SendMessageService } from '@knuddelsModules/Messenger';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $KeyboardService } from '@knuddels-app/Keyboard';
export const MacroBoxInput: React.FC<{
  user: {
    nick: string;
    conversationId: string;
  };
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  inHeader?: boolean;
}> = ({
  user,
  autoFocus = false,
  inHeader = false,
  onFocus,
  onBlur
}) => {
  const i18n = useService($I18n);
  const keyboard = useService($KeyboardService);
  const sendMessage = useSendMessage(user.conversationId);
  const handleTextChange = useHandleTextChange();
  React.useEffect(() => {
    return () => {
      keyboard.closeAll();
    };
  }, []);
  return <div className={_c0}>
			<RichInput mode={!inHeader ? 'inline-button' : 'inline'} bg={inHeader ? 'transparent' : undefined} onFocus={onFocus} onBlur={onBlur} autoFocus={autoFocus} placeholder={declareFormat({
      id: 'channel.macrobox.input.placeholder',
      defaultFormat: 'Message to {nick}'
    }).format(i18n, {
      nick: user.nick
    })} onSubmit={sendMessage} afterSubmitBehavior={AfterSubmitBehavior.ClearAndDismissKeyboard} onChange={handleTextChange} />
		</div>;
};
const useSendMessage = (conversationId: string) => {
  const sendMessageService = useService($SendMessageService);
  const track = useLogEvent();
  return async (originalText: string) => {
    const text = originalText.trim().replace(/\n/g, '#');
    await sendMessageService.sendMessage(conversationId, text);
    track('Chat_MacroQuickAccess', 'Message_Send');
  };
};
const useHandleTextChange = () => {
  const trackedTextEntered = React.useRef(false);
  const logEvent = useLogEvent();
  return (text: string) => {
    if (!trackedTextEntered.current && text) {
      trackedTextEntered.current = true;
      logEvent('Chat_MacroQuickAccess', 'Message_Textentered');
    }
  };
};
const _c0 = " Knu-Flex flex-1 alignItems-center gap-small ";