import { injectedComponent } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Spacer } from '@shared/components';
import { FlexCol, rgb, Smiley, Text, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { SplashScreen } from '@capacitor/splash-screen';
import loadingImage from '../assets/loading.gif';
export const LoadingScreen = injectedComponent({
  name: 'LoadingScreen',
  inject: {
    i18n: $I18n
  }
}, ({
  i18n
}) => {
  React.useEffect(() => {
    return () => {
      SplashScreen.hide();
    };
  }, []);
  return <div style={{
    background: resolveThemingValue(rgb(62, 54, 75), "colors", useTheme())
  }} className={_c0 + (rgb(62, 54, 75) ? resolveIsDarkColor(rgb(62, 54, 75), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Smiley src={loadingImage} width={'65px'} height={'44px'} />
				<Spacer size={'small'} />
				<Text type={'title'} className={_c1}>
					{declareFormat({
        id: 'loadingscreen.loading',
        defaultFormat: 'Loading chat...'
      }).format(i18n)}
				</Text>
			</div>;
});
const _c0 = " Knu-FlexCol position-absolute inset-none placeItems-center ";
const _c1 = "  ";