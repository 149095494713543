import { toPointerEvent as r } from "./toPointerEvent.js";
function e(t) {
  if (t)
    return (o) => {
      t(r(o));
    };
}
export {
  e as toPointerHandler
};
