import * as React from 'react';
import { FlexCol, useTheme } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { ListGroupDraggableList, ListGroupDraggableListItem } from '../shared/ListGroupDraggableList';
import { reorder } from '@knuddels-app/tools/reorder';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { autorun } from '@knuddels-app/mobx';
import { MacroBoxSettingsEntryFragment } from '@generated/graphql';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';
import { MacroboxScrollContainer } from './MacroboxScrollContainer';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export const MacroBoxSettings: React.FC = () => {
  const [render, setRender] = React.useState(false);
  const theme = useTheme();
  const i18n = useService($I18n);
  const {
    items: quickAccessItems,
    onDropEnd: setQuickAccessItems
  } = useQuickAccessItems();
  const {
    items: interactionsItems,
    onDropEnd: setInteractionsItems
  } = useInteractionsItems();
  React.useEffect(() => {
    onMainThreadIdle(() => {
      setRender(true);
    });
  }, []);
  if (!render) {
    return null;
  }
  return <div className={_c0}>
			<MacroboxScrollContainer pb={theme.spacings.xxxlarge}>
				<ListGroupDraggableList items={quickAccessItems} title={declareFormat({
        id: 'settings.macroBoxSettings.group.quickaccess',
        defaultFormat: 'Quick access'
      }).format(i18n)} onItemOrderChanged={setQuickAccessItems} />

				<ListGroupDraggableList items={interactionsItems} title={declareFormat({
        id: 'settings.macroBoxSettings.group.interactions',
        defaultFormat: 'Interactions'
      }).format(i18n)} onItemOrderChanged={setInteractionsItems} />
			</MacroboxScrollContainer>
		</div>;
};
const useQuickAccessItems = () => {
  const clientSettingsService = useService($ClientSettingsService);
  return useItems(() => clientSettingsService.macroBoxQuickAccessEntries, items => clientSettingsService.setMacroBoxQuickAccessEntries(items));
};
const useInteractionsItems = () => {
  const clientSettingsService = useService($ClientSettingsService);
  return useItems(() => clientSettingsService.macroBoxInteractionEntries, items => clientSettingsService.setMacroBoxInteractionEntries(items));
};
const useItems = (getItems: () => readonly MacroBoxSettingsEntryFragment[], setItems: (newItems: MacroBoxSettingsEntryFragment[]) => void) => {
  const [, setState] = React.useState(false);
  React.useEffect(() => {
    autorun({
      name: 'Update items'
    }, () => {
      getItems();
      setState(oldState => !oldState);
    });
  }, []);
  const getTitle = useGetTitle();
  const track = useLogEvent();
  return {
    items: getItems().map(it => ({
      uniqueId: it.id,
      title: getTitle(it.id),
      checked: it.active,
      toggleable: true,
      onChanged: (checked: boolean) => {
        const items = getItems();
        const index = items.findIndex(item => item.id === it.id);
        if (index < 0) {
          return;
        }
        const newItems = [...items];
        newItems[index] = {
          id: it.id,
          active: checked
        };
        setItems(newItems);
        track('Settings_Channelsettings', 'MakroQuickAccess_Interaction' + (checked ? 'Activated' : 'Deactivated'));
      }
    } as ListGroupDraggableListItem)),
    onDropEnd: (from: number, to: number) => {
      setItems(reorder(getItems(), from, to));
      track('Settings_Channelsettings', 'MakroQuickAccess_InteractionOrderChanged');
    }
  };
};
const useGetTitle = () => {
  const i18n = useService($I18n);
  return (id: string) => {
    switch (id) {
      case 'conversation':
      case 'openProfile':
      case 'knuddel':
        return declareFormat({
          id: 'settings.macroBoxSettings.knuddel',
          defaultFormat: 'Knuddel'
        }).format(i18n);
      case 'ignore':
        return declareFormat({
          id: 'settings.macroBoxSettings.ignore',
          defaultFormat: 'Ignore'
        }).format(i18n);
      case 'report':
        return declareFormat({
          id: 'settings.macroBoxSettings.report',
          defaultFormat: 'Report'
        }).format(i18n);
      case 'kiss':
        return declareFormat({
          id: 'settings.macroBoxSettings.kiss',
          defaultFormat: 'Kiss'
        }).format(i18n);
      case 'frenchkiss':
        return declareFormat({
          id: 'settings.macroBoxSettings.frenchkiss',
          defaultFormat: 'French kiss'
        }).format(i18n);
      case 'happy':
        return declareFormat({
          id: 'settings.macroBoxSettings.happy',
          defaultFormat: 'Happy'
        }).format(i18n);
      case 'snuggle':
        return declareFormat({
          id: 'settings.macroBoxSettings.snuggle',
          defaultFormat: 'Snuggle'
        }).format(i18n);
      case 'tickle':
        return declareFormat({
          id: 'settings.macroBoxSettings.tickle',
          defaultFormat: 'Tickle'
        }).format(i18n);
      case 'dance':
        return declareFormat({
          id: 'settings.macroBoxSettings.dance',
          defaultFormat: 'Dance'
        }).format(i18n);
      case 'sorry':
        return declareFormat({
          id: 'settings.macroBoxSettings.sorry',
          defaultFormat: 'Sorry'
        }).format(i18n);
      case 'comfort':
        return declareFormat({
          id: 'settings.macroBoxSettings.comfort',
          defaultFormat: 'Comfort'
        }).format(i18n);
      case 'sulk':
        return declareFormat({
          id: 'settings.macroBoxSettings.sulk',
          defaultFormat: 'Sulk'
        }).format(i18n);
      case 'complain':
        return declareFormat({
          id: 'settings.macroBoxSettings.complain',
          defaultFormat: 'Complain'
        }).format(i18n);
      case 'trick':
        return declareFormat({
          id: 'settings.macroBoxSettings.trick',
          defaultFormat: 'Trick'
        }).format(i18n);
      case 'cake':
        return declareFormat({
          id: 'settings.macroBoxSettings.cake',
          defaultFormat: 'Cake'
        }).format(i18n);
      case 'splash':
        return declareFormat({
          id: 'settings.macroBoxSettings.splash',
          defaultFormat: 'Splash'
        }).format(i18n);
      case 'kick':
        return declareFormat({
          id: 'settings.macroBoxSettings.kick',
          defaultFormat: 'Kick'
        }).format(i18n);
      case 'slap':
        return declareFormat({
          id: 'settings.macroBoxSettings.slap',
          defaultFormat: 'Slap'
        }).format(i18n);
      case 'love':
        return declareFormat({
          id: 'settings.macroBoxSettings.love',
          defaultFormat: 'Love'
        }).format(i18n);
      case 'flirt':
        return declareFormat({
          id: 'settings.macroBoxSettings.flirt',
          defaultFormat: 'Flirt'
        }).format(i18n);
      case 'massage':
        return declareFormat({
          id: 'settings.macroBoxSettings.massage',
          defaultFormat: 'Massage'
        }).format(i18n);
      case 'romantic':
        return declareFormat({
          id: 'settings.macroBoxSettings.romantic',
          defaultFormat: 'Romantic'
        }).format(i18n);
      case 'secretkiss':
        return declareFormat({
          id: 'settings.macroBoxSettings.secretkiss',
          defaultFormat: 'Secret kiss'
        }).format(i18n);
      default:
        return id;
    }
  };
};
const _c0 = " Knu-FlexCol height-100-percent ";