import { useService } from '@knuddels-app/DependencyInjection';
import { observer, reaction } from '@knuddels-app/mobx';
import { Flex } from '@knuddels/component-library';
import { $NavHidingService } from '@knuddelsModules/LoggedInArea';
import { Z_INDEX } from '@shared/components';
import { NAV_BAR_BOTTOM_HEIGHT, navBarWidthCollapsed } from '@shared/constants';
import { motion } from 'framer-motion';
import * as React from 'react';
import { NavBarConfiguration } from './configuration';
export const NavBarHidingWrapper: React.FC<{
  config: NavBarConfiguration;
}> = ({
  config,
  children
}) => {
  if (config.navLocation === 'bottom') {
    return <NavbarAnimationWrapperBottom>
				{children}
			</NavbarAnimationWrapperBottom>;
  }
  if (config.navLocation === 'left' && config.shownItems === 'minimal') {
    return <NavbarAnimationWrapperLeft>{children}</NavbarAnimationWrapperLeft>;
  }
  return <>{children}</>;
};
const NavbarAnimationWrapperBottom: React.FC<{
  children: React.ReactNode;
}> = observer('NavbarAnimationWrapperBottom', ({
  children
}) => {
  const hidingService = useService($NavHidingService);
  const progress = hidingService.isHidden ? 1 : 0;

  /**
   * shadow={'Shadow2'}
   */
  return <motion.div animate={{
    height: (1 - progress) * NAV_BAR_BOTTOM_HEIGHT
  }} style={{
    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden',
    zIndex: Z_INDEX.NAV
  }}>
			{children}
		</motion.div>;
});
const NavbarAnimationWrapperLeft: React.FC = ({
  children
}) => {
  const progress = useProgress();
  return <motion.div animate={{
    width: (1 - progress) * navBarWidthCollapsed
  }} transition={{
    duration: 0.25
  }} style={{
    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden',
    zIndex: Z_INDEX.NAV,
    boxShadow: 'var(--shadows-Shadow2)'
  }}>
			<div className={_c0}>
				{children}
			</div>
		</motion.div>;
};
const useProgress = () => {
  const hidingService = useService($NavHidingService);
  const [progress, setProgress] = React.useState(hidingService.isHidden ? 1 : 0);
  React.useEffect(() => {
    return reaction({
      name: 'Update nav hiding state'
    }, () => hidingService.isHidden, isHidden => {
      setProgress(isHidden ? 1 : 0);
    });
  }, []);
  return progress;
};
const _c0 = " Knu-Flex size-full overflow-hidden ";