import { Text, Box, FlexCol, IconPlus, createAccessiblePointerEventHandler } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export const AddAlbumPhotoButton: React.FC<{
  onPress: () => void;
  size: number;
} & React.ComponentProps<typeof Box>> = ({
  onPress,
  size,
  ...boxProps
}) => {
  return <FlexCol {...createAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} width={`${size}px`} height={`${size}px`} bg={'contentLightBg'} borderRadius={'minor'} borderWidth={'base'} borderStyle={'dashed'} borderColor={'accent'} placeItems={'center'} px={'tiny'} {...boxProps}>
			<IconPlus type={'highlighted'} size={'large'} />
			<Spacer size={'tiny'} />
			<Text state={'highlighted'} type={'tiny'} className={_c0}>
				<FormattedMessage id={declareFormat({
        id: 'profile.edit.add-photo',
        defaultFormat: 'Add photo'
      })} />
			</Text>
		</FlexCol>;
};
const _c0 = " textAlign-center ";