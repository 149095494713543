import { BoxProps, Flex, ThemeOverride } from '@knuddels/component-library';
import * as React from 'react';
import { XOR } from '../../tools';
export type LineDividerColors = 'default';
export type LineDividerStyle = 'solid' | 'dashed' | 'dotted';
interface Props {
  fluid?: boolean;
  lineStyle?: LineDividerStyle;
}
interface PresetProps extends Props {
  colorPreset?: LineDividerColors;
}
interface RawProps extends Props {
  rawColor?: string;
}
export function LineDivider({
  colorPreset = 'default',
  fluid,
  lineStyle = 'solid',
  rawColor
}: XOR<PresetProps, RawProps>): JSX.Element {
  const dividerDirectionStyle = fluid ? styles.fluid : styles.orthogonal;
  const color = rawColor || colors[colorPreset];

  // The outer Flex is needed as a workaround on iOS which doesn't allow
  // borderTopWidth to be dashed or dotted.
  return <Flex position={'relative'} {...dividerDirectionStyle} m={(-2 as ThemeOverride)} overflow={'hidden'}>
			<Flex height={1} width={'full'} overflow={'hidden'} bg={lineStyle === 'solid' ? (color as ThemeOverride) : 'transparent'} borderColor={lineStyle === 'dashed' || lineStyle === 'dotted' ? (color as ThemeOverride) : 'transparent'} borderStyle={lineStyle} borderWidth={'none'} {...{
      style: {
        borderWidth: lineStyle === 'dashed' ? 1 : lineStyle === 'dotted' ? 2 : undefined
      }
    }} />
		</Flex>;
}
const colors: { [key in LineDividerColors]: string } = {
  default: 'rgb(229, 229, 229)'
};
const styles = {
  // divider against flexDirection e.g. xy | z
  orthogonal: ({
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: ('1px' as ThemeOverride),
    alignSelf: 'stretch',
    minHeight: 1,
    minWidth: 1
  } as BoxProps),
  // divider in flexDirection e.g. xy --- z
  fluid: ({
    flex: 1,
    height: 1,
    width: 1,
    alignSelf: 'center' // needed?
  } as BoxProps)
};