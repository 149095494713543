import { useSubscription } from '@knuddels-app/Connection';
import { useUpdateProfileUser } from '../profileQueryHelper';
import { UserFriendStateChanged } from '@generated/graphql';
import { mergeDeepPartial } from '@knuddels-app/tools/mergeDeepPartial';

// use a component instead of a hook so we can use the context via `useUpdateProfile`
// while also keeping it in the root component `ProfileRouting`
export const SubscribeAndUpdateFriendState: React.FC<{
  userId: string;
}> = props => {
  const updateProfileUser = useUpdateProfileUser();
  // eslint-disable-next-line react/prop-types
  useSubscription(UserFriendStateChanged, {
    userId: props.userId
  }, data => {
    updateProfileUser(oldData => mergeDeepPartial(oldData, {
      user: {
        user: data
      }
    }));
  });
  return null;
};