import { jsx as e } from "react/jsx-runtime";
import { Image as o } from "./Image.js";
const i = (r) => /* @__PURE__ */ e(
  o,
  {
    ...r,
    src: r.src,
    resizeMode: "contain",
    alt: ""
  }
);
export {
  i as Smiley
};
