import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands/serviceIds';
import { Disposable } from '@knuddels/std';
import { $AllowedServerAccessStore, $AuthenticatedClientService } from '@knuddels-app/Connection/serviceIds';
import { AuthAsJamesUrl, PhotoVerifyUrl, RevertSilenceApp, SilenceApp } from '@generated/graphql';
import { declareFormat } from '@knuddels/i18n';
import { Box } from '@knuddels/component-library';
import { IconGreenCheckMark } from '@knuddels-app/shared-components/IconGreenCheckMark';
import * as React from 'react';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n } from '@knuddels-app/i18n';
import { openInNewTab } from '@shared/helper/openUrl';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { authenticityFlag } from '@knuddelsModules/FeatureFlags';
import { action, observable } from '@knuddels-app/mobx';
@injectable()
export class CoreCommands {
  public readonly dispose = Disposable.fn();

  // this is only used for a temporary performance test and should be removed after the test
  @observable
  private _isNicklistEffectTestEnabled: boolean;
  constructor(@inject($CommandService)
  commandService: typeof $CommandService.T, @inject($AllowedServerAccessStore)
  allowedServerAccessStore: typeof $AllowedServerAccessStore.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T, @inject($FeatureService)
  private readonly featureService: typeof $FeatureService.T) {
    this._isNicklistEffectTestEnabled = false;
    this.dispose.track([commandService.registerCommand({
      commandName: 'accessdevserver',
      invoke: async () => {
        allowedServerAccessStore.addAllowedServer('Dev');
      }
    }), commandService.registerCommand({
      commandName: 'accesstestserver',
      invoke: async () => {
        allowedServerAccessStore.addAllowedServer('Test');
      }
    }), commandService.registerCommand({
      commandName: 'silence',
      invoke: async (parameter: string): Promise<void> => {
        const withoutSid = parameter.replace(/sid~.+?:/, '');
        if (withoutSid.startsWith('!')) {
          await this.revertSilenceApp(withoutSid.substring(1));
        } else {
          await this.silenceApp(withoutSid);
        }
      }
    }), commandService.registerCommand({
      commandName: 'authenticateAsJames',
      invoke: async (): Promise<void> => await this.authenticateAsJamesByUrl()
    }), commandService.registerCommand({
      commandName: 'fotoverify',
      invoke: async (): Promise<void> => await this.verifyPhotoByUrl(),
      shouldInvoke: () => {
        return !featureService.hasSomeFlagsEnabled([authenticityFlag]);
      }
    }), commandService.registerCommand({
      commandName: 'tssne',
      invoke: async () => {
        this.setIsNicklistEffectTestEnabled(!this._isNicklistEffectTestEnabled);
      }
    })]);
  }
  @action
  private setIsNicklistEffectTestEnabled(value: boolean): void {
    this._isNicklistEffectTestEnabled = value;
  }
  public get isNicklistEffectTestEnabled(): boolean {
    return this._isNicklistEffectTestEnabled;
  }
  private silenceApp = async (parameter: string) => {
    const split = parameter.split(':');
    const channel = split[0];
    const days = split.length > 1 ? +split[1] : null;
    await this.authenticatedClientService.currentK3Client.mutateWithResultPromise(SilenceApp, {
      channel,
      days
    }).match({
      error: () => {
        this.snackbarService.showGenericError();
      },
      ok: r => {
        if (r.__typename === 'Success') {
          this.showSilenceSuccessSnackbar();
        } else if (r.__typename === 'SilenceCommandChannelNotFoundError') {
          this.showChannelNotFoundSnackbar(channel);
        } else {
          this.snackbarService.showGenericError();
        }
      }
    });
  };
  private showSilenceSuccessSnackbar = () => {
    this.snackbarService.showSnackbar({
      type: 'silenceSuccess',
      text: declareFormat({
        id: 'apps.silence.success.text',
        defaultFormat: 'Successfully unsubscribed'
      }).format(this.i18n),
      subtext: declareFormat({
        id: 'apps.silence.success.subtext',
        defaultFormat: "You'll no longer receive bot messages from this channel."
      }).format(this.i18n),
      adornment: <div className={_c0}>
					<IconGreenCheckMark size={'large'} />
				</div>
    });
  };
  private revertSilenceApp = async (channel: string) => {
    await this.authenticatedClientService.currentK3Client.mutateWithResultPromise(RevertSilenceApp, {
      channel: channel
    }).match({
      error: () => {
        this.snackbarService.showGenericError();
      },
      ok: r => {
        if (r.__typename === 'Success') {
          this.showRevertSilenceSuccessSnackbar();
        } else if (r.__typename === 'SilenceCommandChannelNotFoundError') {
          this.showChannelNotFoundSnackbar(channel);
        } else {
          this.snackbarService.showGenericError();
        }
      }
    });
  };
  private showRevertSilenceSuccessSnackbar = () => {
    this.snackbarService.showSnackbar({
      type: 'revertSilenceSuccess',
      text: declareFormat({
        id: 'apps.revertSilence.success.text',
        defaultFormat: 'Successfully subscribed'
      }).format(this.i18n),
      subtext: declareFormat({
        id: 'apps.revertSilence.success.subtext',
        defaultFormat: "You'll will receive bot messages from this channel again."
      }).format(this.i18n),
      adornment: <div className={_c1}>
					<IconGreenCheckMark size={'large'} />
				</div>
    });
  };
  private showChannelNotFoundSnackbar = (channel: string) => {
    this.snackbarService.showErrorSnackbarWithDefaults({
      type: 'silenceChannelNotFound',
      subtext: declareFormat({
        id: 'apps.silence.channelNotFound',
        defaultFormat: "The channel {channel} doesn't exist."
      }).format(this.i18n, {
        channel
      })
    });
  };
  private async authenticateAsJamesByUrl(): Promise<void> {
    const result = await this.authenticatedClientService.currentK3Client.query(AuthAsJamesUrl, {}, 'network-only');
    if (!result.isOk()) {
      return;
    }
    const primaryData = result.unwrap().primaryData;
    if (primaryData.__typename === 'PhotoAuthenticatedAsJamesUrl') {
      openInNewTab(primaryData.url);
    }
  }
  private verifyPhotoByUrl = async (): Promise<void> => {
    const result = await this.authenticatedClientService.currentK3Client.query(PhotoVerifyUrl, {}, 'network-only');
    if (!result.isOk()) {
      return;
    }
    const primaryData = result.unwrap().primaryData;
    if (primaryData.__typename === 'PhotoVerifyUrl') {
      openInNewTab(primaryData.url);
    }
  };
}
const _c0 = " Knu-Box mr-minor ";
const _c1 = " Knu-Box mr-minor ";