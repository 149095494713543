import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as serviceIds from './serviceIds';
import { ViewService } from './ViewService';
import { foreachProp } from '@knuddels/std';
import { LocationToViewsMapping } from './LocationToViewsMapping';
import { ViewOverlayProvider } from './ViewOverlayProvider';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(serviceIds, {
		$LocationToViewsMapping: ctx.globalScope.toSingleton(
			LocationToViewsMapping
		),
		$ViewService: ctx.loggedInScope.toSingleton(ViewService),
		$ViewOverlayProvider: ctx.loggedInScope.toSingletonAutostart(
			ViewOverlayProvider
		),
	});
}
