import * as React from 'react';
import { Spacer } from '@shared/components';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Text, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { bottomNavMoreViewEvent } from '../../analytics';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { KnuddelsPlusHeaderBackground, KnuddelsPlusStatusBadge, UserImage } from '@knuddelsModules/UserData';
import { declareProps, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { CurrentUserKnuddelDisplayLazy } from '@knuddelsModules/KnuddelDisplay';
import { $OverlayService } from '@knuddels-app/overlays';
import { $AdsService } from '@knuddelsModules/Ads';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
@injectable()
class NavigationViewTitlebarModel {
  constructor(@injectProps()
  private readonly props: {
    uid: string;
  }, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy) {}
  public readonly handleNicknameClick = (): void => {
    bottomNavMoreViewEvent.track('MoreView_NicknameClicked');
    this.getProfileNavigationService().then(s => s.showProfile(this.props.uid, 'Nav'));
  };
  public readonly handleProfilePicClick = (): void => {
    bottomNavMoreViewEvent.track('MoreView_ProfileImageClicked');
    this.getProfileNavigationService().then(s => s.showProfile(this.props.uid, 'Nav'));
  };
}
export const NavigationViewTitlebar = injectedComponent({
  name: 'NavigationViewTitlebar',
  model: NavigationViewTitlebarModel,
  props: declareProps<{
    nick: string;
    uid: string;
  }>()
}, ({
  model,
  nick,
  uid
}) => {
  const adsService = useService($AdsService);
  const isOverlayOpen = useIsOverlayOpen();
  const isStackedLayout = useIsStackedLayout();
  return <div className={_c0}>
				<KnuddelsPlusHeaderBackground />
				{isStackedLayout && adsService.areCapacitorAdsVisible && <adsService.CapacitorAdzone adVisible={!isOverlayOpen} bannerId={'K3MoreBanner'} />}
				<div className={_c1}>
					<div className={_c2}>
						<div {...createNativeAccessiblePointerEventHandler({
          pointerCallback: model.handleProfilePicClick
        })} className={_c3}>
							<UserImage type={40} uid={uid} />
						</div>
						<Spacer size={'minor'} />
						<div className={_c4}>
							<div {...createNativeAccessiblePointerEventHandler({
            pointerCallback: model.handleNicknameClick
          })} className={_c5}>
								<Text type={'subtitle'} bold={true} numberOfLines={1} className={_c6}>
									{nick}
								</Text>
							</div>
							<KnuddelsPlusStatusBadge />
						</div>
						<div className={_c7} />
						<Spacer size={'large'} />
						<CurrentUserKnuddelDisplayLazy type={'normal'} numberFormat={'full'} firebaseContentType={'Navigation_BottomNavigation'} firebaseItemId={'MoreView_KnuddelCountClicked'} happyHourBannerLocation={'top'} showLoyaltySystemLabels />
					</div>
				</div>
			</div>;
});
const useIsOverlayOpen = () => {
  const overlayService = useService($OverlayService);
  return useReactiveState(() => {
    return overlayService.isAnyOverlayVisible;
  }, [overlayService]);
};
const _c0 = " Knu-FlexCol position-relative overflow-hidden ";
const _c1 = " Knu-FlexCol zIndex-0 position-relative px-base height-72px justifyContent-center ";
const _c2 = " Knu-Flex position-relative alignItems-center overflow-visible ";
const _c3 = " Knu-FlexCol cursor-pointer ";
const _c4 = " Knu-FlexCol ";
const _c5 = " Knu-Flex cursor-pointer height-full alignItems-center overflow-visible ";
const _c6 = "  ";
const _c7 = " Knu-Flex flex-1 ";