import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { AddToWatchlist, RemoveFromWatchlist } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat } from '@knuddels-app/i18n';

@injectable()
export class WatchlistService {
	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {}

	addToWatchlist = async (
		userId: string,
		trackSnackbar: (message: string) => void = () => {}
	) => {
		return await this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(AddToWatchlist, { userId })
			.match({
				ok: r => {
					switch (r.__typename) {
						case 'Success':
							return true;
						case 'WatchlistLimitReachedError':
							trackSnackbar('AddToWatchlistLimitReachedError');
							this.snackbarService.showSnackbar({
								type: 'watchlistLimitReached',
								text: declareFormat({
									id: 'watchlist.limitReached.text',
									defaultFormat: 'Your watchlist is full',
								}).format(this.i18n),
								subtext: declareFormat({
									id: 'watchlist.limitReached.subtext',
									defaultFormat:
										'You can only add {limit} users to your watchlist.',
								}).format(this.i18n, { limit: r.limit }),
							});
							return false;

						case 'InternalError':
						default:
							trackSnackbar('AddToWatchlistError');
							this.snackbarService.showGenericError();
							return false;
					}
				},
				error: () => {
					trackSnackbar('AddToWatchlistError');
					this.snackbarService.showGenericError();
					return false;
				},
			});
	};

	removeFromWatchlist = async (
		userId: string,
		trackSnackbar: (message: string) => void = () => {}
	) => {
		return await this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(RemoveFromWatchlist, { userId })
			.match({
				ok: r => {
					switch (r.__typename) {
						case 'Success':
							return true;
						case 'InternalError':
						default:
							trackSnackbar('RemoveFromWatchlistError');
							this.snackbarService.showGenericError();
							return false;
					}
				},
				error: () => {
					trackSnackbar('RemoveFromWatchlistError');
					this.snackbarService.showGenericError();
					return false;
				},
			});
	};
}
