import { MenteeStatus, ProfileTagCategory, UserForQuickWhoisFragment } from '@generated/graphql';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { formatMessage } from '@knuddels-app/i18n';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { NickWithIcons } from '@knuddels-app/shared-components/NickWithIcons';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Text, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $UserService, UserTagList } from '@knuddelsModules/UserData';
import { Spacer } from '@shared/components';
import * as React from 'react';
import { CITY_AND_DISTANCE } from '../../i18n/shared-formats';
import { translateRelationshipStatus } from '../entryValueTranslations';
import { QuickWhoisOverflowMenuTrigger } from './QuickWhoisOverflowMenuTrigger';
interface Props {
  user: UserForQuickWhoisFragment;
  onNickClicked?(): void;
}
export const InfoPanel = injectedComponent({
  name: 'InfoPanel',
  props: declareProps<Props>(),
  inject: {
    userService: $UserService
  }
}, ({
  user,
  userService,
  onNickClicked
}) => {
  const profileNavigationService = useLazyService($ProfileNavigationService);
  const relationshipStatus = translateRelationshipStatus(user.relationshipStatus);
  const {
    gender,
    age,
    distance,
    city
  } = user;
  return <div className={_c0}>
				<div className={_c1}>
					<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: onNickClicked
      })} className={_c2}>
						<NickWithIcons type={'title'} user={{
          ...user,
          menteeStatus: MenteeStatus.None
        }} hideNegativeAuthenticityStates onAuthenticityIconPress={async () => {
          const service = await profileNavigationService();

          // Without the timeout the profile does open
          // but the authenticity view is not shown
          setTimeout(() => {
            if (userService.isCurrentUser(user.id)) {
              service.showAuthenticityOverview();
            } else {
              service.showAuthenticityForUser(user);
            }
          }, 1);
        }} />
					</div>
					{userService.currentUser?.id !== user.id && <QuickWhoisOverflowMenuTrigger user={user} />}
				</div>
				<Spacer size={'tiny'} />
				<div className={_c3}>
					<div className={_c4}>
						<GenderIcon gender={gender} />
						<Spacer size="tiny" />
						{!!age && <Text type={'body1'} state={'tertiary'} className={_c5}>
								{age}
							</Text>}
						{!!age && !!relationshipStatus && <Text type={'body1'} state={'tertiary'} className={_c6}>
								{' - '}
							</Text>}
						{!!relationshipStatus && <Text type={'body1'} state={'tertiary'} className={_c7}>
								{relationshipStatus}
							</Text>}
					</div>
					{distance !== null && distance >= 0 && city && <Text type={'body1'} state={'tertiary'} className={_c8}>
							{formatMessage(CITY_AND_DISTANCE, {
          city,
          distance: distance.toString()
        })}
						</Text>}
					{userService.currentUser?.id !== user.id && <div className={_c9}>
							<UserTagList tags={user.profileTags.filter(it => it.category !== ProfileTagCategory.RelationshipStatus)} />
						</div>}
				</div>
			</div>;
});
const _c0 = " Knu-FlexCol pl-base pb-minor ";
const _c1 = " Knu-Flex pt-tiny ";
const _c2 = " Knu-Flex cursor-pointer flex-1 pt-minor pr-base ";
const _c3 = " Knu-FlexCol pr-base ";
const _c4 = " Knu-Flex alignItems-center ";
const _c5 = "  ";
const _c6 = "  ";
const _c7 = "  ";
const _c8 = "  ";
const _c9 = " Knu-Flex pt-minor ";