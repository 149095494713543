import { AbstractHistory as e } from "./History.js";
class o extends e {
  constructor() {
    super(...arguments), this.stack = [];
  }
  init(t) {
    return this.stack.push({
      path: t.rootPath,
      params: {},
      key: this.generateKey()
    }), t.initialPath && this.stack.push({
      path: t.initialPath,
      params: {},
      key: this.generateKey()
    }), this.toHistoryUpdate();
  }
  getState() {
    return this.toHistoryUpdate();
  }
  push(t) {
    this.stack.push({ ...t, key: this.generateKey() }), this.notifyObservers(this.toHistoryUpdate());
  }
  pop() {
    this.stack.length > 1 && (this.stack.pop(), this.notifyObservers(this.toHistoryUpdate()));
  }
  popRouteWithKey(t) {
    const s = this.stack.findIndex((i) => i.key === t);
    this.stack.splice(s, 1), this.notifyObservers(this.toHistoryUpdate());
  }
  toHistoryUpdate() {
    return {
      stack: [...this.stack],
      rootViewVisible: this.stack.length === 1
    };
  }
}
export {
  o as MemoryHistory
};
