import { clamp as n } from "../../../utils/clamp.js";
import { number as s, alpha as i } from "../numbers/index.js";
import { sanitize as p } from "../utils.js";
import { isColorString as e, splitColor as f } from "./utils.js";
const l = (r) => n(0, 255, r), o = {
  ...s,
  transform: (r) => Math.round(l(r))
}, h = {
  test: e("rgb", "red"),
  parse: f("red", "green", "blue"),
  transform: ({ red: r, green: t, blue: a, alpha: m = 1 }) => "rgba(" + o.transform(r) + ", " + o.transform(t) + ", " + o.transform(a) + ", " + p(i.transform(m)) + ")"
};
export {
  o as rgbUnit,
  h as rgba
};
