import { UserForProfileFragment } from '@generated/graphql';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { declareProps, injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { formatMessage } from '@knuddels-app/i18n';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { NickWithIcons } from '@knuddels-app/shared-components/NickWithIcons';
import { Flex, FlexCol, IconCloseCircular, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { nickSwitchFlag } from '@knuddelsModules/FeatureFlags';
import { ProfileTitlebarEvent } from '@knuddelsModules/Profile/analytics';
import { $UserService } from '@knuddelsModules/UserData';
import { Spacer } from '@shared/components';
import { userIsJames } from '@shared/helper/user';
import { IconReport } from '@shared/icons/IconReport';
import * as React from 'react';
import { useContext } from 'react';
import { CITY_AND_DISTANCE } from '../../../i18n/shared-formats';
import { ProfileShowNickSwitchIconContext } from '../ProfileShowNickSwitchIconConext';
import { useNavigate } from './ProfileContentRouter';
import { ProfileOverflowMenuTrigger } from './ProfileOverflowMenuTrigger';
interface Props {
  user: UserForProfileFragment;
  showReportIcon: boolean;
  onClose?(): void;
}
export const InfoPanel = React.memo(injectedComponent({
  name: 'InfoPanel',
  props: declareProps<Props>()
}, ({
  user,
  onClose,
  showReportIcon
}) => {
  const currentUserId = useService($UserService).currentUser?.id;
  const featureService = useService($FeatureService);
  const showNickSwitchIcon = useContext(ProfileShowNickSwitchIconContext);
  const executeSlashCommand = useSlashCommandWithoutChannel();
  const nickSwitchEnabled = featureService.hasSomeFlagsEnabled([nickSwitchFlag]);
  const showProfileOverflowMenuTrigger = nickSwitchEnabled && user.hasNicknameHistoryVisible;
  const openComplaintsMenu = () => {
    ProfileTitlebarEvent.track('Titlebar_AdministrationActions');
    executeSlashCommand(`/opensystemapp UserReport:{selectedUser:'${user.nick}'}`);
  };
  const {
    gender,
    age,
    distance,
    city
  } = user;
  const textParts: string[] = [];
  if (age) {
    textParts.push(age.toString());
  }
  if (distance !== null && distance >= 0 && city) {
    textParts.push(formatMessage(CITY_AND_DISTANCE, {
      city,
      distance: distance.toString()
    }));
  }
  const navigate = useNavigate();
  return <div className={_c0}>
					{!!onClose && <div style={{
      marginLeft: resolveThemingValue((-8 as ThemeOverride), "spacing", useTheme())
    }} className={_c1}>
							<IconCloseCircular size={'large'} onPress={onClose} />
							<Spacer size={'tiny'} />
						</div>}
					<div className={_c2}>
						<div className={_c3}>
							<NickWithIcons user={user} type={'title'} onAuthenticityIconPress={!user.isAppBot && !userIsJames(user) ? () => {
          navigate(user.id === currentUserId ? 'authenticityOverview' : 'authenticityInfoOther');
        } : undefined} showNickSwitchIcon={showNickSwitchIcon} />
						</div>
						<Spacer size={'tiny'} />
						<div className={_c4}>
							<GenderIcon gender={gender} />
							<Spacer size="tiny" />
							{textParts.length > 0 && <div className={_c5}>
									<Text type={'body1'} state={'tertiary'} numberOfLines={1} className={_c6}>
										{textParts.join(' · ')}
									</Text>
								</div>}
						</div>
					</div>
					{showReportIcon && <div className={_c7 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<IconReport size="large" onPress={openComplaintsMenu} />
						</div>}

					{showProfileOverflowMenuTrigger && <ProfileOverflowMenuTrigger />}
				</div>;
}));
const _c0 = " Knu-Flex py-small px-base height-64px alignItems-center ";
const _c1 = " Knu-Flex ";
const _c2 = " Knu-FlexCol flex-2 alignSelf-stretch ";
const _c3 = " Knu-Flex ";
const _c4 = " Knu-Flex alignItems-center ";
const _c5 = " Knu-Flex flex-1 ";
const _c6 = "  ";
const _c7 = " Knu-FlexCol bg-contentLightBg alignItems-flex-end flex-1 ";