import * as React from 'react';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { Box, FlexCol, Image, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { SnapSize } from './SnapSizes';
import iconInactive from '../../../../assets/icon-snap-inactive.png';
import iconActive from '../../../../assets/icon-snap-active-sw.png';
export const SnapStaticView: React.FC<{
  size: SnapSize;
  type: 'loading' | 'unavailable' | 'sent';
}> = React.memo(({
  size,
  type
}) => {
  const wasSent = type === 'sent';
  return <div style={{
    height: resolveThemingValue(size, "sizes", useTheme()),
    maxWidth: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c0 + ("black-transparent-220" ? resolveIsDarkColor("black-transparent-220", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div style={{
      width: resolveThemingValue(size, "sizes", useTheme())
    }} className={_c1} />
			{type !== 'loading' && <>
					<div className={_c2}>
						<Image src={type === 'sent' ? iconActive : iconInactive} alt={''} resizeMode={'contain'} className={_c3} />
					</div>
					<Text bold={true} className={_c4}>
						{formatMessage(wasSent ? declareFormat({
          id: 'SNAP_SENT_MESSAGE',
          defaultFormat: 'Your snap was sent.'
        }) : declareFormat({
          id: 'SNAP_UNAVAILABLE',
          defaultFormat: 'This snap is over.'
        }))}
					</Text>
				</>}
		</div>;
});
SnapStaticView.displayName = 'SnapStaticView';
const _c0 = " Knu-FlexCol py-64px px-50px justifyContent-center bg-black-transparent-220 overflow-hidden position-relative ";
const _c1 = " Knu-Box ";
const _c2 = " Knu-FlexCol mb-small alignSelf-center ";
const _c3 = " width-48px height-48px ";
const _c4 = " mt-small textAlign-center ";