import { ContextMenuProps } from './ContextMenu';
import { getSafeAreaBottomHeight } from '@shared/helper/getSafeArea';

export type Position = { x: number; y: number };

const minimumDistanceToScreen = 8;
const distanceToChevron = 8;

export function positionContextMenu(
	props: ContextMenuProps,
	menuWidth: number,
	menuHeight: number,
	windowWidth: number,
	windowHeight: number
): Position {
	let x = props.chevronX - distanceToChevron - menuWidth;
	let y = props.chevronY;

	const saveAreaBottom = getSafeAreaBottomHeight();

	// Not enough space on left
	if (x < minimumDistanceToScreen) {
		x = props.chevronX + props.chevronWidth + distanceToChevron;
	}
	// Also not enough space on right
	const fitsOnRight = x + menuWidth <= windowWidth - minimumDistanceToScreen;
	if (!fitsOnRight) {
		x = minimumDistanceToScreen;
		y = props.chevronY + props.chevronHeight + distanceToChevron;
	}

	// Not enough space on bottom
	if (
		y + menuHeight >
		windowHeight - minimumDistanceToScreen - saveAreaBottom
	) {
		y = fitsOnRight
			? props.chevronY + props.chevronHeight - menuHeight
			: props.chevronY - distanceToChevron - menuHeight;
	}

	return { x, y: Math.max(0, y) };
}
