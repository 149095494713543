import * as React from 'react';
import { EditProfileContentWrapper } from './EditProfileContentWrapper';
import { Flex, FlexCol, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { ProfileEditor } from './EditProfileContent/logic/ProfileEditor';
import { declareProps, IModel, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { observable, observer } from '@knuddels-app/mobx';
import { ChangeProfilePictureButton } from './EditProfileContent/EditProfilePicture/ChangeProfilePictureButton';
import { EditProfileCommonProps } from './EditProfileLogic';
import { EditProfileTitleBar } from './EditProfileContent/EditProfileTitleBar';
import { EditProfilePictureOverflowMenuTrigger } from './EditProfileContent/EditProfilePicture/EditProfilePictureContextMenu';
import { EditProfileHeader } from './EditProfileContent/EditProfileHeader';
import { EditProfileContentTabs } from './EditProfileContentTabs';
import { ProfileCloseIcon } from '../ProfileCloseIcon';
import { EditProfileContentRouter } from './EditProfileContentRouter';
interface Props extends EditProfileCommonProps {
  editor: ProfileEditor;
  activeTabIndex: number;
  setActiveTabIndex: (newTabIndex: number) => void;
  goBack(): void;
}
@injectable()
class EditProfileDesktopModel implements IModel {
  public dispose = Disposable.fn();
  @observable
  public readonly editor: ProfileEditor;
  constructor(@injectProps()
  public readonly props: Props) {
    this.editor = props.editor;
  }
  public readonly goBack = (): void => {
    this.props.goBack();
  };
  public readonly close = (): void => {
    this.props.onClose();
  };
}
export const EditProfileDesktop = injectedComponent({
  model: EditProfileDesktopModel,
  name: 'EditProfileDesktop',
  props: declareProps<Props>()
}, ({
  model
}) => {
  return <EditProfileContentWrapper {...model.props}>
				{(width, height) => <Content model={model} size={height} />}
			</EditProfileContentWrapper>;
});
const Content = observer('Content', function ({
  model,
  size
}: typeof EditProfileDesktop.TPropsWithModel & {
  size: number;
}): JSX.Element {
  return <div className={_c0}>
				<div className={_c1}>
					<div style={{
        height: resolveThemingValue(size, "sizes", useTheme()),
        width: resolveThemingValue(size, "sizes", useTheme())
      }} className={_c2} />
					<div className={_c3}>
						<ChangeProfilePictureButton />
					</div>
					<div className={_c4}>
						<EditProfilePictureOverflowMenuTrigger />
					</div>
				</div>
				<EditProfileContentRouter>
					<div className={_c5 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<div className={_c6}>
							<EditProfileTitleBar goBack={model.goBack} editor={model.editor} />
							<EditProfileHeader onTabClicked={model.props.setActiveTabIndex} activeTabIndex={model.props.activeTabIndex} />
						</div>
						<EditProfileContentTabs {...model.props} />
					</div>
				</EditProfileContentRouter>
				<ProfileCloseIcon onPress={model.close} />
			</div>;
});
const _c0 = " Knu-Flex flex-1 overflow-hidden borderRadius-base ";
const _c1 = " Knu-FlexCol position-relative ";
const _c2 = " Knu-FlexCol ";
const _c3 = " Knu-Flex position-absolute bottom-small justifyContent-center left-none right-none width-100-percent height-100-percent ";
const _c4 = " Knu-FlexCol position-absolute p-tiny right-none top-none ";
const _c5 = " Knu-FlexCol flex-1 minWidth-0-px position-relative bg-contentLightBg overflow-hidden ";
const _c6 = " Knu-FlexCol shadow-Shadow2 zIndex-1 ";