import * as t from "react";
const c = (n) => {
  const e = t.useRef();
  return e.current = n, t.useCallback((...u) => {
    var r;
    return (r = e.current) == null ? void 0 : r.call(e, ...u);
  }, []);
};
export {
  c as useEvent
};
