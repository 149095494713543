import { jsx as r } from "react/jsx-runtime";
import * as o from "react";
import { useDisabledOpacity as a } from "../Inputs/useDisabledOpacity.js";
import { createAccessiblePointerEventHandler as c } from "../../a11y/createAccessiblePointerEventHandler.js";
import { useAnchor as s } from "./Anchor.js";
import { useTheme as d } from "../../themes/useTheme.js";
import { Flex as l } from "../Layout/Flex.js";
const n = (e) => {
  const i = a({ disabled: e.disabled }), t = d();
  return /* @__PURE__ */ r(
    l,
    {
      height: t.sizes.fab.default,
      width: t.sizes.fab.default,
      borderRadius: "circle",
      shadow: "Shadow2",
      placeItems: "center",
      bg: "accent",
      ...i,
      ...c({
        pointerCallback: e.onPress,
        disabled: e.disabled
      }),
      ...s(e),
      ...e,
      children: e.children
    }
  );
}, m = (e) => o.createElement(e.icon, {
  color: "white-solid-white",
  size: "large"
});
n.Icon = m;
export {
  n as Fab
};
