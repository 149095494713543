import * as n from "react";
function u(e) {
  return n.useMemo(() => r(e), [
    e,
    e.length
  ]);
}
function r(e) {
  if (e.length === 0)
    return 100;
  let t = 0;
  return e.forEach((i, l) => {
    t += i.height;
  }), t / e.length;
}
export {
  u as useCalculatedEstimatedSize
};
