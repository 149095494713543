import * as React from 'react';
import { Overview } from './Overview/Overview';
import { FullCategory } from './FullCategory/FullCategory';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { Router } from './ChannelListRouter';
import { categoryOverviewEvent } from '@knuddelsModules/ChannelList/analytics';
import { $ScreenService } from '@knuddels-app/Screen';
import { observer } from '@knuddels-app/mobx';
import { channelListViewId } from '../../ChannelListViewProvider';
import { $ViewService } from '@knuddels-app/layout';
import { $ClientSettingsService, $SettingsComponents, settingsFormat } from '@knuddelsModules/Settings';
import { CHANNEL_LIST_TITLE } from '../i18n/shared-formats';
import { ChannelListRouteConfig } from '../../ChannelListRouteConfig';
import { RecommendedChannelsProvider } from './Overview/ChannelSelection/RecommendedChannelsContext';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export const ChannelList: React.FC = observer('ChannelList', () => {
  const i18n = useService($I18n);
  const screenService = useService($ScreenService);
  const viewService = useService($ViewService);
  const settingsComponents = useService($SettingsComponents);
  const track = useLogEvent();
  const {
    onNavigate,
    refetchOverview
  } = useRefetchOnChange();
  const router = <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Router rootPath="overview" onClose={screenService.isStackedLayout ? undefined : () => {
      viewService.closeView(channelListViewId);
      track('ChannelSelection_Navigation', 'Titlebar_Close');
    }} onNavigate={changes => {
      if (changes.oldPath === 'category') {
        categoryOverviewEvent.track('CategoryOverview_Closed');
      }
      onNavigate(changes);
    }}>
				<Router.Route path="overview" title={i18n.format(CHANNEL_LIST_TITLE)} hideShadow>
					<Overview refetchOverview={refetchOverview} />
				</Router.Route>
				<Router.Route path="category">
					<FullCategory />
				</Router.Route>
				<Router.Route path="channel-list-settings" title={settingsFormat.format(i18n)}>
					<settingsComponents.ChannelListSettings />
				</Router.Route>
			</Router>
		</div>;
  return <RecommendedChannelsProvider>{router}</RecommendedChannelsProvider>;
});
const useRefetchOnChange = () => {
  const clientSettingsService = useService($ClientSettingsService);
  const settingsChanged = React.useRef(false);
  const isSettingsOpen = React.useRef(false);
  const [refetchOverview, setRefetchOverivew] = React.useState(false);
  React.useEffect(() => {
    return clientSettingsService.addChannelListCategoriesMutationListener(() => {
      if (isSettingsOpen.current) {
        settingsChanged.current = true;
      } else {
        // Toggle state to make it sure it is refetched
        setRefetchOverivew(false);
        setTimeout(() => {
          setRefetchOverivew(true);
        }, 1);
      }
    });
  }, []);
  const onNavigate = React.useCallback((changes: {
    oldPath: keyof ChannelListRouteConfig;
    newPath: keyof ChannelListRouteConfig;
  }) => {
    isSettingsOpen.current = changes.newPath === 'channel-list-settings';
    if (changes.oldPath === 'channel-list-settings') {
      if (settingsChanged.current === true) {
        settingsChanged.current = false;
        setRefetchOverivew(true);
      }
    } else if (changes.newPath === 'channel-list-settings') {
      setRefetchOverivew(false);
    }
  }, []);
  return {
    onNavigate,
    refetchOverview
  };
};
const _c0 = " Knu-FlexCol flex-1 bg-contentBg ";