import * as React from 'react';
import { XOR } from '../../tools';
import { FlexCol, ThemeColors, ThemeOverride, Text, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
export type NotificationBadgeColors = 'accent' | 'stapp' | 'warning';
interface NotificationBadgeProps {
  number: number | string;
}
interface PresetProps extends NotificationBadgeProps {
  colorPreset?: NotificationBadgeColors;
}
interface RawProps extends NotificationBadgeProps {
  rawColor?: string;
}
export const NotificationBadge: React.FC<XOR<PresetProps, RawProps>> = props => {
  const colorPreset = props.colorPreset || 'accent';
  const color = (props.rawColor as ThemeOverride) || colors[colorPreset];
  return <div style={{
    background: resolveThemingValue(color, "colors", useTheme())
  }} className={_c0 + (color ? resolveIsDarkColor(color, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Text type={'tiny'} bold={true} color={'white-solid-white'} style={{
      fontSize: 11
    }} className={_c1}>
				{props.number}
			</Text>
		</div>;
};
const colors: { [key in NotificationBadgeColors]: ThemeColors } = {
  accent: 'accent',
  stapp: ('rgb(238,0,0)' as ThemeOverride),
  warning: ('rgb(242, 141, 25)' as ThemeOverride)
};
const _c0 = " Knu-FlexCol position-relative borderRadius-tiny size-16px placeItems-center ";
const _c1 = "  ";