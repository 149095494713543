import {
	ChannelConnectionDisconnectReason,
	SystemEvents,
} from '@generated/graphql';
import {
	$AuthenticatedClientService,
	$AuthService,
} from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { chatroomErrorEvent } from '../../analytics';
import { $ActiveChannelService } from '../../providedServiceIds';
import { openUrl } from '@shared/helper/openUrl';

@injectable()
export class SystemEventsService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthenticatedClientService)
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($ActiveChannelService)
		private readonly activeChannelService: typeof $ActiveChannelService.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T
	) {
		this.dispose.track(
			authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				SystemEvents,
				{},
				{
					next: this.handleEvent,
				}
			)
		);
	}

	handleEvent = (event: (typeof SystemEvents)['TPrimaryResult']): void => {
		if (event.__typename === 'ClientDisconnected') {
			chatroomErrorEvent.track(
				`Error_${event.disconnectReason.__typename}`
			);

			switch (event.disconnectReason.__typename) {
				case 'ClientDisconnectReasonUserDeleted':
					this.authService.logout({ disableConvenienceLogin: true });
					return;
				case 'ClientDisconnectReasonNicknameChanged':
					this.authService.logout({
						disableConvenienceLogin: true,
						newNick: event.disconnectReason.newNick,
					});
					return;
				default:
					// refresh session to check for errors with the chatserver connection.
					this.authService.forceRefreshSession();
			}
		} else if (event.__typename === 'ChannelConnectionDisconnected') {
			chatroomErrorEvent.track(`Error_${event.newReason}`);
			if (this.activeChannelService.state.kind !== 'active') {
				// Don't need/want to trigger disconnects or clear channel if it is not active.
				// E.g. We don't want to transition into the disconnected modal when we left the channel previously
				return;
			}

			switch (event.newReason) {
				case ChannelConnectionDisconnectReason.OtherSessionConnected:
					this.activeChannelService.setState({
						kind: 'disconnected-by-other-session',
					});
					break;
				case ChannelConnectionDisconnectReason.Internal:
					this.activeChannelService.setState({
						kind: 'disconnected-by-server',
					});
					break;
				case ChannelConnectionDisconnectReason.UserLeave:
					// this event should only arrive for the current session that left the channel.
					// and since we already clear the channel in the mutation we don't need to do anything here.
					break;
				default:
			}
		} else if (event.__typename === 'OpenUrl') {
			openUrl(event.url, event.target);
		}
		// Don't throw here to not prevent the backend to extend the events.
	};
}
