import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { OverflowMenuTrigger } from '@shared/components';
import { $OverlayService, OverlayFactory } from '@knuddels-app/overlays';
import { useService } from '@knuddels-app/DependencyInjection';
import { EditProfilePictureContextMenu } from './EditProfilePictureContextMenu';
export const OpenEditProfilePictureContextMenu: React.FC = observer('OpenEditProfilePictureContextMenu', () => {
  const {
    open,
    isOpen
  } = useOverflowMenu();
  return <OverflowMenuTrigger showOverflowMenu={open} isMenuActive={isOpen} />;
});
const useOverflowMenu = () => {
  const overlayService = useService($OverlayService);
  const open: React.ComponentProps<typeof OverflowMenuTrigger>['showOverflowMenu'] = (x, y, width, height) => {
    overlayService.showOverlayIfNotVisible(EditProfilePictureContextMenuOverlay, {
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  return {
    open,
    isOpen: !!overlayService.findOverlay(EditProfilePictureContextMenuOverlay.getFilter())
  };
};
const EditProfilePictureContextMenuOverlay = new OverlayFactory(EditProfilePictureContextMenu);