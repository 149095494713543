import { KeyframeResolver as R } from "../../render/utils/KeyframesResolver.js";
import { spring as G } from "../generators/spring/index.js";
import { inertia as x } from "../generators/inertia.js";
import { keyframes as y } from "../generators/keyframes.js";
import { BaseAnimation as I } from "./BaseAnimation.js";
import { pipe as A } from "../../utils/pipe.js";
import { mix as _ } from "../../utils/mix/index.js";
import { calcGeneratorDuration as B } from "../generators/utils/calc-duration.js";
import { millisecondsToSeconds as P, secondsToMilliseconds as C } from "../../utils/time-conversion.js";
import { clamp as O } from "../../utils/clamp.js";
import { frameloopDriver as V } from "./drivers/driver-frameloop.js";
import { getFinalKeyframe as U } from "./waapi/utils/get-final-keyframe.js";
const W = {
  decay: x,
  inertia: x,
  tween: y,
  keyframes: y,
  spring: G
}, j = (u) => u / 100;
class q extends I {
  constructor({ KeyframeResolver: e = R, ...n }) {
    super(n), this.holdTime = null, this.startTime = null, this.cancelTime = null, this.currentTime = 0, this.playbackSpeed = 1, this.pendingPlayState = "running", this.state = "idle", this.stop = () => {
      if (this.resolver.cancel(), this.isStopped = !0, this.state === "idle")
        return;
      this.teardown();
      const { onStop: s } = this.options;
      s && s();
    };
    const { name: i, motionValue: t, keyframes: o } = this.options, l = (s, a) => this.onKeyframesResolved(s, a);
    i && t && t.owner ? this.resolver = t.owner.resolveKeyframes(o, l, i, t) : this.resolver = new e(o, l, i, t), this.resolver.scheduleResolve();
  }
  initPlayback(e) {
    const { type: n = "keyframes", repeat: i = 0, repeatDelay: t = 0, repeatType: o, velocity: l = 0 } = this.options, s = W[n] || y;
    let a, p;
    s !== y && typeof e[0] != "number" && (a = A(j, _(e[0], e[1])), e = [0, 100]);
    const r = s({ ...this.options, keyframes: e });
    o === "mirror" && (p = s({
      ...this.options,
      keyframes: [...e].reverse(),
      velocity: -l
    })), r.calculatedDuration === null && (r.calculatedDuration = B(r));
    const { calculatedDuration: d } = r, f = d + t, T = f * (i + 1) - t;
    return {
      generator: r,
      mirroredGenerator: p,
      mapPercentToKeyframes: a,
      calculatedDuration: d,
      resolvedDuration: f,
      totalDuration: T
    };
  }
  onPostResolved() {
    const { autoplay: e = !0 } = this.options;
    this.play(), this.pendingPlayState === "paused" || !e ? this.pause() : this.state = this.pendingPlayState;
  }
  tick(e, n = !1) {
    const { resolved: i } = this;
    if (!i) {
      const { keyframes: c } = this.options;
      return { done: !0, value: c[c.length - 1] };
    }
    const { finalKeyframe: t, generator: o, mirroredGenerator: l, mapPercentToKeyframes: s, keyframes: a, calculatedDuration: p, totalDuration: r, resolvedDuration: d } = i;
    if (this.startTime === null)
      return o.next(0);
    const { delay: f, repeat: T, repeatType: D, repeatDelay: k, onUpdate: S } = this.options;
    this.speed > 0 ? this.startTime = Math.min(this.startTime, e) : this.speed < 0 && (this.startTime = Math.min(e - r / this.speed, this.startTime)), n ? this.currentTime = e : this.holdTime !== null ? this.currentTime = this.holdTime : this.currentTime = Math.round(e - this.startTime) * this.speed;
    const g = this.currentTime - f * (this.speed >= 0 ? 1 : -1), M = this.speed >= 0 ? g < 0 : g > r;
    this.currentTime = Math.max(g, 0), this.state === "finished" && this.holdTime === null && (this.currentTime = r);
    let K = this.currentTime, w = o;
    if (T) {
      const c = Math.min(this.currentTime, r) / d;
      let v = Math.floor(c), h = c % 1;
      !h && c >= 1 && (h = 1), h === 1 && v--, v = Math.min(v, T + 1), !!(v % 2) && (D === "reverse" ? (h = 1 - h, k && (h -= k / d)) : D === "mirror" && (w = l)), K = O(0, 1, h) * d;
    }
    const m = M ? { done: !1, value: a[0] } : w.next(K);
    s && (m.value = s(m.value));
    let { done: F } = m;
    !M && p !== null && (F = this.speed >= 0 ? this.currentTime >= r : this.currentTime <= 0);
    const b = this.holdTime === null && (this.state === "finished" || this.state === "running" && F);
    return b && t !== void 0 && (m.value = U(a, this.options, t)), S && S(m.value), b && this.finish(), m;
  }
  get duration() {
    const { resolved: e } = this;
    return e ? P(e.calculatedDuration) : 0;
  }
  get time() {
    return P(this.currentTime);
  }
  set time(e) {
    e = C(e), this.currentTime = e, this.holdTime !== null || this.speed === 0 ? this.holdTime = e : this.driver && (this.startTime = this.driver.now() - e / this.speed);
  }
  get speed() {
    return this.playbackSpeed;
  }
  set speed(e) {
    const n = this.playbackSpeed !== e;
    this.playbackSpeed = e, n && (this.time = P(this.currentTime));
  }
  play() {
    if (this.resolver.isScheduled || this.resolver.resume(), !this._resolved) {
      this.pendingPlayState = "running";
      return;
    }
    if (this.isStopped)
      return;
    const { driver: e = V, onPlay: n } = this.options;
    this.driver || (this.driver = e((t) => this.tick(t))), n && n();
    const i = this.driver.now();
    this.holdTime !== null ? this.startTime = i - this.holdTime : (!this.startTime || this.state === "finished") && (this.startTime = i), this.state === "finished" && this.updateFinishedPromise(), this.cancelTime = this.startTime, this.holdTime = null, this.state = "running", this.driver.start();
  }
  pause() {
    var e;
    if (!this._resolved) {
      this.pendingPlayState = "paused";
      return;
    }
    this.state = "paused", this.holdTime = (e = this.currentTime) !== null && e !== void 0 ? e : 0;
  }
  complete() {
    this.state !== "running" && this.play(), this.pendingPlayState = this.state = "finished", this.holdTime = null;
  }
  finish() {
    this.teardown(), this.state = "finished";
    const { onComplete: e } = this.options;
    e && e();
  }
  cancel() {
    this.cancelTime !== null && this.tick(this.cancelTime), this.teardown(), this.updateFinishedPromise();
  }
  teardown() {
    this.state = "idle", this.stopDriver(), this.resolveFinishedPromise(), this.updateFinishedPromise(), this.startTime = this.cancelTime = null, this.resolver.cancel();
  }
  stopDriver() {
    this.driver && (this.driver.stop(), this.driver = void 0);
  }
  sample(e) {
    return this.startTime = 0, this.tick(e, !0);
  }
}
function ie(u) {
  return new q(u);
}
export {
  q as MainThreadAnimation,
  ie as animateValue
};
