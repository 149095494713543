import { mixNumber as x } from "../../utils/mix/number.js";
import { hasTransform as E } from "../utils/has-transform.js";
function y(t, n, o) {
  const i = t - o, s = n * i;
  return o + s;
}
function e(t, n, o, i, s) {
  return s !== void 0 && (t = y(t, s, i)), y(t, o, i) + n;
}
function p(t, n = 0, o = 1, i, s) {
  t.min = e(t.min, n, o, i, s), t.max = e(t.max, n, o, i, s);
}
function _(t, { x: n, y: o }) {
  p(t.x, n.translate, n.scale, n.originPoint), p(t.y, o.translate, o.scale, o.originPoint);
}
const m = 0.999999999999, a = 1.0000000000001;
function D(t, n, o, i = !1) {
  const s = o.length;
  if (!s)
    return;
  n.x = n.y = 1;
  let l, f;
  for (let c = 0; c < s; c++) {
    l = o[c], f = l.projectionDelta;
    const { visualElement: u } = l.options;
    u && u.props.style && u.props.style.display === "contents" || (i && l.options.layoutScroll && l.scroll && l !== l.root && A(t, {
      x: -l.scroll.offset.x,
      y: -l.scroll.offset.y
    }), f && (n.x *= f.x.scale, n.y *= f.y.scale, _(t, f)), i && E(l.latestValues) && A(t, l.latestValues));
  }
  n.x < a && n.x > m && (n.x = 1), n.y < a && n.y > m && (n.y = 1);
}
function N(t, n) {
  t.min = t.min + n, t.max = t.max + n;
}
function r(t, n, o, i, s = 0.5) {
  const l = x(t.min, t.max, s);
  p(t, n, o, l, i);
}
function A(t, n) {
  r(t.x, n.x, n.scaleX, n.scale, n.originX), r(t.y, n.y, n.scaleY, n.scale, n.originY);
}
export {
  p as applyAxisDelta,
  _ as applyBoxDelta,
  e as applyPointDelta,
  D as applyTreeDeltas,
  y as scalePoint,
  r as transformAxis,
  A as transformBox,
  N as translateAxis
};
