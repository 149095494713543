import { getDateDifference, getFormattedDate } from './getDateDifference';
import { declareFormat, todoUseDI_i18n } from '@knuddels-app/i18n';

// Time for dates younger than this are shown relatively
const RELATIVE_MILLIS = 5 * 60 * 1000;

export function getMessageDayText(
	timestamp: number,
	options?: {
		alwaysShowTime?: boolean;
		showTodayMoreSpecific?: boolean;
		noUpperCase?: boolean;
	}
): string {
	const now = Date.now();

	const dateDifference = getDateDifference(timestamp);
	const intl = todoUseDI_i18n();

	if (options?.showTodayMoreSpecific && dateDifference === 0) {
		const age = now - timestamp;
		if (age <= RELATIVE_MILLIS) {
			// DE => 'vor 2 Minuten' or EN => '2 minutes ago'
			return intl.formatDateTimeRelative(timestamp, 'minute', {
				numeric: 'auto',
			});
		} else {
			// DE => '14:33' or EN => '2:33 PM'
			return intl.formatDateTime(timestamp, {
				hour: 'numeric',
				minute: 'numeric',
			});
		}
	} else {
		let date = getFormattedDate(timestamp);
		if (options?.alwaysShowTime) {
			const time = intl.formatDateTime(timestamp, {
				hour: 'numeric',
				minute: 'numeric',
			});
			date = intl.format(
				declareFormat({
					id: 'datetime',
					defaultFormat: '{date} at {time}',
				}),
				{ date, time }
			);
		}

		if (options?.noUpperCase) {
			return date;
		}
		return date.toUpperCase();
	}
}
