import * as providedServices from './providedServices';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { Module } from '@knuddels-app/ModuleSystem';
import { settingsModule } from '@knuddelsModules/Settings/module';
import { fotoMeetModule } from '@knuddelsModules/Fotomeet/module';
import { smileyboxModule } from '@knuddelsModules/Smileybox/module';
import { $ViewProvider } from '@knuddels-app/layout';
import { MessengerViewProvider } from '@knuddelsModules/Messenger/MessengerViewProvider';
import { contactsModule } from '@knuddelsModules/Contacts/module';
import { adsModule } from '@knuddelsModules/Ads/module';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import { MessengerNavBarItemProvider } from './MessengerNavBarItemProvider';
import { profileVisitorsModule } from '@knuddelsModules/ProfileVisitors/module';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { autocompleteInputBarModule } from '@knuddelsModules/AutocompleteInputBar/module';

export const messengerModule = new Module({
	name: 'MessengerModule',
	dependencies: [
		userDataModule,
		settingsModule,
		fotoMeetModule,
		smileyboxModule,
		contactsModule,
		adsModule,
		profileVisitorsModule,
		notificationsModule,
		autocompleteInputBarModule,
	],
	loadEvent:
		globalEnv.product === 'app' || globalEnv.product === 'stapp-messenger'
			? 'onLogin'
			: 'onDemand',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	providedServices,
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(MessengerViewProvider);
		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toSingleton(MessengerNavBarItemProvider);
	},
});
