const o = {
  onPress: "onClick",
  onPointerDown: "onPointerDown",
  onSecondaryPress: "onContextMenu",
  onHover: "onMouseEnter",
  onHoverEnd: "onMouseLeave"
};
export {
  o as PointerPropsMap
};
