import { mixNumber as i } from "../../utils/mix/number.js";
import { complex as f } from "../../value/types/complex/index.js";
const y = {
  correct: (e, { treeScale: c, projectionDelta: t }) => {
    const a = e, o = f.parse(e);
    if (o.length > 5)
      return a;
    const p = f.createTransformer(e), r = typeof o[0] != "number" ? 1 : 0, n = t.x.scale * c.x, m = t.y.scale * c.y;
    o[0 + r] /= n, o[1 + r] /= m;
    const s = i(n, m, 0.5);
    return typeof o[2 + r] == "number" && (o[2 + r] /= s), typeof o[3 + r] == "number" && (o[3 + r] /= s), p(o);
  }
};
export {
  y as correctBoxShadow
};
