import * as React from 'react';
export class IPhoneXWrapper extends React.PureComponent {
  render(): JSX.Element {
    return <>{this.props.children}</>;

    /* Return this to test the wrapper in the Web UI:
    	return (
    	<>
    		<View style={{ height: 10 }} />
    		<View style={{ flex: 1 }}>{this.props.children}</View>
    	</>
    );
    */
  }
}