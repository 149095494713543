import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as ids from '../providedServices';
import * as s from './services';
import { foreachProp } from '@knuddels/std';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$PaymentModule: ctx.loggedInScope.toSingletonAutostart(s.PaymentModule),
		$PaymentHandler: ctx.loggedInScope.toSingleton(s.PaymentHandler),
		$PaymentErrorDisplay: ctx.loggedInScope.toSingleton(
			s.PaymentErrorDisplay
		),
		$PaymentSuccessDisplay: ctx.loggedInScope.toSingleton(
			s.PaymentSuccessDisplay
		),
		$PaymentSubscriptionHandler: ctx.loggedInScope.toSingleton(
			s.PaymentSubscriptionHandler
		),
	});
}
