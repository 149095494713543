import * as React from 'react';
import { Image } from '@knuddels/component-library';
const ICON_SIZE = 24;
export const IconPng: React.FC<{
  iconUrl: string;
  active: boolean;
  hovered: boolean;
}> = ({
  iconUrl,
  active,
  hovered
}) => {
  const filter = active ? 'brightness(0) invert(1)' : hovered ? 'brightness(0) invert(0.8)' : 'brightness(0) invert(0.5)';
  return <Image src={iconUrl} alt={''} size={ICON_SIZE} {...{
    style: {
      filter
    }
  }} />;
};