import { Module } from '@knuddels-app/ModuleSystem';
import { $AdsService, AdsService } from './AdsService';
import { appStartInit } from '@knuddelsModules/Ads/bundle/adSetup';

export const adsModule = new Module({
	name: 'AdsModule',
	dependencies: [],
	providedServices: { $AdsService },
	load: ctx => {
		ctx.loggedInScope.bind($AdsService).toSingleton(AdsService);
	},
});

try {
	appStartInit();
} catch (e) {
	// appStartInit might fail if ads are blocked
	console.error(e);
}
