// tslint:disable:max-file-line-count
import {
	Children,
	Country,
	ProfileEditField,
	RelationshipStatus,
	SexualOrientation,
	Smoker,
} from '@generated/graphql';
import { declareFormat, FormatId } from '@knuddels-app/i18n';
import { OwnProfileViewProfileEditEvent } from '@knuddelsModules/Profile/analytics';
import { EditProfileRouterConfig } from '../../EditProfileRouter';
import { authenticityFlag } from '@knuddelsModules/FeatureFlags';
import { FeatureFlag } from '@knuddels-app/featureFlags';
import { UserDataInterfaces } from '@knuddelsModules/UserData';
import { EMAIL_APP_SLASH_COMMAND } from '@shared/constants';

import entryHobbies from '../../../../../assets/profile-entry-hobbies.png';
import entryMusic from '../../../../../assets/profile-entry-music.png';
import entryMovies from '../../../../../assets/profile-entry-movies.png';
import entrySeries from '../../../../../assets/profile-entry-series.png';
import entryBooks from '../../../../../assets/profile-entry-books.png';
import entryReadme from '../../../../../assets/profile-entry-readme.png';
import entryName from '../../../../../assets/profile-entry-name.png';
import entryBirthday from '../../../../../assets/profile-entry-birthday.png';
import entryEmail from '../../../../../assets/profile-entry-email.png';
import entryRelationship from '../../../../../assets/profile-entry-relationship.png';
import entryOrientation from '../../../../../assets/profile-entry-orientation.png';
import entryChildren from '../../../../../assets/profile-entry-children.png';
import entrySmoker from '../../../../../assets/profile-entry-smoker.png';
import entryCountry from '../../../../../assets/profile-entry-country.png';
import entryPLZ from '../../../../../assets/profile-entry-plz.png';
import entryLanguage from '../../../../../assets/profile-entry-language.png';

export type EditProfileEntryData = {
	category: EditProfileCategories;
	label: FormatId;
	image?: string;
	helperText?: FormatId;
	systemAppOptions?: SystemAppOptions;
	fireSaveTrackingCallback?(): void;
};

export type SystemAppOptions = {
	trackingId: string;
	userProperty: keyof UserDataInterfaces.StaticUserData;
	navigateTo?: keyof EditProfileRouterConfig;
	openAppSlashCommand?: string;
	flag?: FeatureFlag;
};

export type EditProfileCategories = 'noCategory' | 'general' | 'lifestyle';
export const allProfileCategories: ReadonlyArray<EditProfileCategories> = [
	'noCategory',
	'general',
	'lifestyle',
];

export const categoryFormats: {
	[key in Exclude<EditProfileCategories, 'noCategory'>]: FormatId;
} = {
	general: declareFormat({
		id: 'EDIT_PROFILE_CATEGORY_GENERAL',
		defaultFormat: 'General',
	}),
	lifestyle: declareFormat({
		id: 'EDIT_PROFILE_CATEGORY_LIFESTYLE',
		defaultFormat: 'Lifestyle',
	}),
};

export const editProfileFieldData: {
	[key in ProfileEditField]?: EditProfileEntryData;
} = {
	[ProfileEditField.Hobbies]: {
		category: 'lifestyle',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_HOBBIES',
			defaultFormat: 'Hobbies',
		}),
		image: entryHobbies,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_HobbiesChanged');
		},
	},
	[ProfileEditField.Music]: {
		category: 'lifestyle',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_MUSIC',
			defaultFormat: 'Music',
		}),
		image: entryMusic,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_MusicChanged');
		},
	},
	[ProfileEditField.Movies]: {
		category: 'lifestyle',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_MOVIES',
			defaultFormat: 'Movies',
		}),
		image: entryMovies,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_MoviesChanged');
		},
	},
	[ProfileEditField.Series]: {
		category: 'lifestyle',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_SERIES',
			defaultFormat: 'Series',
		}),
		image: entrySeries,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_TVShowsChanged');
		},
	},
	[ProfileEditField.Books]: {
		category: 'lifestyle',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_BOOKS',
			defaultFormat: 'Books',
		}),
		image: entryBooks,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_BooksChanged');
		},
	},

	[ProfileEditField.Readme]: {
		category: 'noCategory',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_REAME',
			defaultFormat: 'Readme',
		}),
		image: entryReadme,
		helperText: declareFormat({
			id: 'EDIT_README_HELPER_TEXT',
			defaultFormat:
				'Say something about yourself! Everyone visiting your profile will see this message.',
		}),
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_ReadmeChanged');
		},
	},

	[ProfileEditField.Name]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_NAME',
			defaultFormat: 'Name',
		}),
		image: entryName,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_NameChanged');
		},
	},
	[ProfileEditField.DateOfBirth]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_DATE_OF_BIRTH',
			defaultFormat: 'Date of birth',
		}),
		image: entryBirthday,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_BirthdayChanged');
		},
	},
	[ProfileEditField.Email]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_EMAIL',
			defaultFormat: 'E-Mail',
		}),
		image: entryEmail,
		helperText: declareFormat({
			id: 'EDIT_EMAIL_HELPER_TEXT',
			defaultFormat: 'Diese Information ist nicht öffentlich.',
		}),
		systemAppOptions: {
			userProperty: 'email',
			trackingId: 'ChangeEmail_Clicked',
			openAppSlashCommand: EMAIL_APP_SLASH_COMMAND,
			flag: authenticityFlag,
		},
	},
	[ProfileEditField.RelationshipStatus]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_RELATIONSHIP_STATUS',
			defaultFormat: 'Relationship status',
		}),
		image: entryRelationship,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track(
				'ProfileEdit_RelationshipstatusChanged'
			);
		},
	},
	[ProfileEditField.SexualOrientation]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_SEXUAL_ORIENTATION',
			defaultFormat: 'Sexual orientation',
		}),
		image: entryOrientation,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track(
				'ProfileEdit_SexualOrientationChanged'
			);
		},
	},
	[ProfileEditField.Children]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_CHILDREN',
			defaultFormat: 'Children',
		}),
		image: entryChildren,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track(
				'ProfileEdit_KidAmountChanged'
			);
		},
	},
	[ProfileEditField.Smoker]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_SMOKER',
			defaultFormat: 'Smoker',
		}),
		image: entrySmoker,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_SmokerChanged');
		},
	},
	[ProfileEditField.CountryEnum]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_COUNTRY',
			defaultFormat: 'Country',
		}),
		image: entryCountry,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_CountryChanged');
		},
	},
	[ProfileEditField.ZipCode]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_ZIP_CODE',
			defaultFormat: 'Zip code',
		}),
		image: entryPLZ,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track('ProfileEdit_PLZChanged');
		},
		helperText: declareFormat({
			id: 'EDIT_ZIP_CODE_HELPER_TEXT',
			defaultFormat: 'For other users only the city is visible.',
		}),
	},
	[ProfileEditField.Languages]: {
		category: 'general',
		label: declareFormat({
			id: 'EDIT_PROFILE_ENTRY_LANGUAGES',
			defaultFormat: 'Languages',
		}),
		image: entryLanguage,
		fireSaveTrackingCallback(): void {
			OwnProfileViewProfileEditEvent.track(
				'ProfileEdit_LanguagesChanged'
			);
		},
	},
};

export const SexualOrientationMultiChoices = [
	SexualOrientation.Bi,
	SexualOrientation.Heterosexual,
	SexualOrientation.Homosexual,
	SexualOrientation.Other,
	SexualOrientation.Transgender,
	SexualOrientation.Undecided,
];

export const RelationshipStatusMultiChoices = [
	RelationshipStatus.Solo,
	RelationshipStatus.HappilySolo,
	RelationshipStatus.InLove,
	RelationshipStatus.UnhappilyInLove,
	RelationshipStatus.Taken,
	RelationshipStatus.HappilyTaken,
	RelationshipStatus.Engaged,
	RelationshipStatus.Married,
	RelationshipStatus.HappilyMarried,
	RelationshipStatus.Divorced,
	RelationshipStatus.HappilyDivorced,
	RelationshipStatus.RelationallyDisturbed,
	RelationshipStatus.IGoToTheMonastery,
	RelationshipStatus.OpenRelationship,
	RelationshipStatus.HappilyInLove,
	RelationshipStatus.InSearchOf,
	RelationshipStatus.InHogHeaven,
	RelationshipStatus.JustSeparated,
	RelationshipStatus.LivingSeparated,
	RelationshipStatus.SingleParent,
	RelationshipStatus.TotallyOpenMinded,
	RelationshipStatus.ForeverAlone,
];

export const ChildrenMultiChoices = [
	Children.ALot,
	Children.ByAllMeans,
	Children.Existing,
	Children.ExtendedFamily,
	Children.InProgress,
	Children.MaybeLater,
	Children.NoThanks,
	Children.Zero,
	Children.One,
	Children.Two,
	Children.Three,
	Children.PatchworkFamily,
	Children.Planned,
];

export const SmokerMultiChoices = [
	Smoker.Yes,
	Smoker.No,
	Smoker.Occasional,
	Smoker.Quit,
];

export const CountryChoices = [
	Country.Germany,
	Country.Switzerland,
	Country.Austria,
];

export const EditProfileFieldOrder = [
	ProfileEditField.Readme,
	ProfileEditField.Name,
	ProfileEditField.DateOfBirth,
	ProfileEditField.RelationshipStatus,
	ProfileEditField.SexualOrientation,
	ProfileEditField.Children,
	ProfileEditField.Smoker,
	ProfileEditField.CountryEnum,
	ProfileEditField.ZipCode,
	ProfileEditField.Languages,
	ProfileEditField.Email,
	ProfileEditField.Hobbies,
	ProfileEditField.Music,
	ProfileEditField.Movies,
	ProfileEditField.Series,
	ProfileEditField.Books,
];
