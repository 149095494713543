import { Flex, FlexCol, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { useSlideInRow } from './useSlideInRow';
import { Amount } from './KnuddelDisplayAmount';
import { HappyHourFragment } from '@generated/graphql';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
export const VerticalDisplayAnimatedAmount: React.FC<{
  amount: number | undefined | null;
  happyHour: HappyHourFragment;
  numberFormat: 'none' | 'millionOnly' | 'full';
}> = ({
  happyHour,
  numberFormat,
  amount
}) => {
  const {
    domRef1,
    domRef2
  } = useSlideInRow({
    interval: 3000,
    duration: 400
  });
  const color = (colorToRgbaString(happyHour.color) as ThemeOverride);
  return <div className={_c0}>
			<div className={_c1}>
				<div ref={domRef1} className={_c2}>
					<Amount amount={amount} textType={'body2'} numberFormat={numberFormat} textColor={color} />
				</div>
				<div ref={domRef2} className={_c3}>
					<div style={{
          background: resolveThemingValue((color as any), "colors", useTheme())
        }} className={_c4 + ((color as any) ? resolveIsDarkColor((color as any), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<Text type={'tiny'} bold={true} className={_c5}>
							%
						</Text>
					</div>
				</div>
			</div>
		</div>;
};
const _c0 = " Knu-Flex alignSelf-stretch placeItems-center ";
const _c1 = " Knu-Flex position-relative flex-1 overflow-hidden height-20px ";
const _c2 = " Knu-Flex position-absolute left-none right-none placeItems-center overflow-hidden ";
const _c3 = " Knu-Flex position-absolute left-none right-none placeItems-center overflow-hidden pt-tiny ";
const _c4 = " Knu-FlexCol borderRadius-tiny placeItems-center width-16px height-16px ";
const _c5 = "  ";