import { SaveFieldError } from '@knuddelsModules/Profile/bundle/services/SaveProfileService';
import { EditProfileEntryFragment, ProfileEditField } from '@generated/graphql';
import { notNull } from '@knuddels-app/tools/notNull';
import {
	entryToTextValue,
	ProfileEntryData,
	ProfileEntryDataMap,
	updateProfileEntryData,
} from './profileEditorData';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { ProfileEntryValidator } from './ProfileEntryValidator';
import { $SaveProfileService } from '@knuddelsModules/Profile/providedServices';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat, FormatId } from '@knuddels-app/i18n';
import { objectKeys } from '@knuddels-app/tools/objectKeys';
import { createInvalidProfileFieldValueSnackbar } from '@knuddelsModules/Profile/bundle/snackbars';
import { OwnProfileViewProfileEditEvent } from '@knuddelsModules/Profile/analytics';
import { Result } from '@knuddels/std';

/**
 * The class is responsible for storing field validity and values.
 */
export class DetailsEditor {
	get entries(): EditProfileEntryFragment[] {
		return Object.values(this.entriesByField)
			.filter(notNull)
			.map(data => data.entry);
	}

	get entriesByField(): ProfileEntryDataMap {
		return this._entriesByField;
	}

	@observable
	private _entriesByField: ProfileEntryDataMap = {};

	@observable
	private _progress = 0;

	private validator = new ProfileEntryValidator();

	constructor(
		private readonly saveProfileService: typeof $SaveProfileService.T,
		private readonly snackbarService: typeof $SnackbarService.T,
		private readonly i18n: typeof $I18n.T,
		private readonly _entries: ReadonlyArray<EditProfileEntryFragment>,
		private readonly markProfileDirty: () => void
	) {
		for (const entry of _entries) {
			const text = entryToTextValue(entry);

			this._entriesByField[entry.field] = {
				entry: entry,
				changed: false,
				// not relevant if changed=false
				value: text,
				validity: {
					type: text ? 'valid' : 'empty',
				},
			};
		}

		this.updateProgress();
	}

	@action.bound
	private updateProgress(): void {
		const totalFields = this._entries.length;
		const validFields = objectKeys(this._entriesByField)
			.map(it => this._entriesByField[it]!)
			.filter(it => it.validity.type === 'valid').length;

		this._progress = validFields / totalFields;
	}

	public get progress(): number {
		return this._progress;
	}

	public canSave(): boolean {
		const invalidField = Object.values(this._entriesByField).find(
			it => it && it.validity.type === 'invalid'
		);
		if (invalidField) {
			this.snackbarService.showSnackbar(
				createInvalidProfileFieldValueSnackbar(invalidField.entry.field)
			);
			return false;
		}

		return true;
	}

	public save = async (): Promise<boolean> => {
		const promises: Promise<Result<void, unknown>>[] = [];
		const countryEntryData = this._entriesByField[
			ProfileEditField.CountryEnum
		];
		if (countryEntryData && countryEntryData.changed) {
			// Prevents race condition in backend when country and zip code
			// because backend clears zip code when changing country which sometimes happens
			// if both fields are saved at the same time.
			const countryPromise = this.saveField(countryEntryData);
			await countryPromise;
			// needed to handle the later snackbar
			promises.push(countryPromise);
			// to not save it again
			countryEntryData.changed = false;
		}

		const changedEntries = Object.values(this._entriesByField)
			.filter(notNull)
			// we already handled it separately
			.filter(it => it.entry.field !== ProfileEditField.CountryEnum)
			.filter(it => it.changed);
		promises.push(...changedEntries.map(this.saveField));

		const results = await Promise.all(promises);

		runInAction('Unmark profile entries dirty', () => {
			changedEntries.forEach(entry => {
				entry.changed = false;
			});
		});

		return results.filter(it => it.isError()).length === 0;
	};

	@action.bound
	public valueChanged(field: ProfileEditField, newValue: string): void {
		const entryData = this.entriesByField[field];
		if (!entryData || entryData.value === newValue) {
			return;
		}
		const updatedData = updateProfileEntryData(
			entryData,
			newValue,
			this.validator
		);
		if (updatedData) {
			if (field === ProfileEditField.CountryEnum) {
				// reset zip code if changing the country (old zip should not be valid anymore)
				this.valueChanged(ProfileEditField.ZipCode, '');
			}
			this.entriesByField[field] = updatedData;
			this.markProfileDirty();
			this.updateProgress();
		}
	}

	private saveField = (
		entryData: ProfileEntryData
	): Promise<Result<void, unknown>> => {
		const entry = entryData.entry;
		return this.saveProfileService.saveField(entry).then(result => {
			if (result.isError()) {
				trackProfileEditError(entry.field);
				this.handleSpecialErrors(entry.field, result.value);
			}
			return result;
		});
	};

	@action.bound
	private handleSpecialErrors(
		field: ProfileEditField,
		error: SaveFieldError
	): void {
		const specialErrorFormatId = getSpecialErrorFormatId(error);
		if (specialErrorFormatId && this._entriesByField[field]) {
			this._entriesByField[field].validity = {
				type: 'invalid',
				errorText: this.i18n.format(specialErrorFormatId),
			};
		}
	}
}

function trackProfileEditError(field: ProfileEditField): void {
	OwnProfileViewProfileEditEvent.track('ProfileEdit_Error - ' + field);
}

// see `EditProfileEntryFreeTextError`.
// For zip code: Can be one of `CountryNeeded`, `UnsupportedCountry` or `ZipNotFound`.
function getSpecialErrorFormatId(error: SaveFieldError): FormatId | null {
	switch (error) {
		case 'ZipNotFound':
			return declareFormat({
				id: 'profile.edit.zip-code-error.not-found',
				defaultFormat: 'The postal code was not found.',
			});
		case 'CountryNeeded':
		case 'UnsupportedCountry':
			// should not happen unless users use hacked clients or there are bugs somewhere
			return declareFormat({
				id: 'profile.edit.zip-code-error.country-invalid',
				defaultFormat: 'Country is invalid. Cannot validate zip code.',
			});
		default:
			return null;
	}
}
