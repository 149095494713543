import * as React from 'react';
type ContextType = {
  setExpanded: (key: string, expanded: boolean) => void;
  isExpanded: (key: string) => boolean;
};
export const ChannelGroupExpandedContext = React.createContext<ContextType>(undefined);
export const ChannelGroupExpandedContextDefaultProvider: React.FC = ({
  children
}) => {
  const [expanded, setExpanded] = React.useState<Record<string, boolean>>({});
  const context: ContextType = {
    setExpanded: (id, newExpanded) => {
      setExpanded(oldExpanded => ({
        ...oldExpanded,
        [id]: newExpanded
      }));
    },
    isExpanded: id => !!expanded[id]
  };
  return <ChannelGroupExpandedContext.Provider value={context}>
			{children}
		</ChannelGroupExpandedContext.Provider>;
};