import * as React from 'react';
import { EditProfileRouterConfig, Router } from './EditProfileRouter';
import { FlexCol } from '@knuddels/component-library';
import { EditProfileChangeNicknameApp } from './EditProfileChangeNicknameApp';
export const InitialEditProfileRouteContext = React.createContext<keyof EditProfileRouterConfig>('overview');
export const EditProfileContentRouter: React.FC = ({
  children
}) => {
  const initialRoute = React.useContext(InitialEditProfileRouteContext);
  return <Router rootPath={'overview'} initialPath={initialRoute === 'overview' ? undefined : initialRoute}>
			<Router.Route path={'overview'} hideTitleBar>
				{children}
			</Router.Route>

			<Router.Route path={'nickSwitch'} hideTitleBar>
				<div className={_c0}>
					<EditProfileChangeNicknameApp />
				</div>
			</Router.Route>
		</Router>;
};
const _c0 = " Knu-FlexCol height-full ";