import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '@knuddels-app/AppLoadingService';
import { $FeatureFlagsService } from './providedServices';

export const featureFlagsModule = new Module({
	name: 'Feature flag module',
	dependencies: [],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope
			.bind($AppLoadingProvider)
			.toConstant(
				simpleAppLoadingProvider(
					$FeatureFlagsService,
					service => service.deferredReady.promise
				)
			);
	},
});
