import * as React from 'react';
import { ConversationPrivateSystemMessageContentFragment } from '@generated/graphql';
import { FlexCol } from '@knuddels/component-library';
import { FormattedText, FormattedTextDisplay, Spacer } from '@shared/components';
import { PrivateMessageFooter } from './PrivateMessageFooter';
import { useTrackConversation } from '../../useTrackConversation';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
export const PrivateSystemMessage: React.FC<{
  content: ConversationPrivateSystemMessageContentFragment;
}> = ({
  content
}) => {
  const track = useTrackConversation();
  React.useEffect(() => {
    track('SystemMessages_GenericMessageShown');
  }, []);
  return <div className={_c0}>
			<div className={_c1}>
				{content.icon && <>
						<FormattedTextDisplay textProps={{
          textAlign: 'center'
        }} text={FormattedText.fromJsonString(content.icon)} />
						<Spacer size={'small'} />
					</>}
				<FormattedTextDisplayWithCommandHandling textProps={{
        state: 'secondary',
        textAlign: 'center'
      }} text={FormattedText.fromJsonString(content.formattedText)} />
				<Spacer size={'small'} />
				<PrivateMessageFooter />
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol alignItems-center mt-small px-xlarge py-small ";
const _c1 = " Knu-FlexCol alignItems-center maxWidth-360px ";