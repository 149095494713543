export const smileyDimensions = {
	width: 52,
	height: 52,
};

export const smileyHoverPadding = 12;

export const listGap = 8;

export const listPadding = {
	horizontal: 12,
	vertical: 12,
};

export const initialSmileyCountPerRow = 10;

export const rowGap = 4;
