const g = (n, o) => {
  const b = {};
  return Object.keys(o).forEach((c) => {
    b[`${n.length > 0 ? n + "-" : ""}${c}`] = o[c];
  }), b;
}, r = {
  white: "rgba(255,255,255,1)",
  880: "rgba(227, 227, 227, 1)",
  660: "rgba(168, 168, 168, 1)",
  440: "rgba(112, 112, 112, 1)",
  220: "rgba(56, 56, 56, 1)",
  160: "rgba(63, 61, 65, 1)",
  150: "rgba(60, 59, 63, 1)",
  140: "rgba(58, 56, 61, 1)",
  120: "rgba(53, 52, 56, 1)",
  110: "rgba(51, 49, 54, 1)",
  "090": "rgba(47, 45, 49, 1)",
  "080": "rgba(44, 42, 47, 1)",
  "070": "rgba(42, 40, 45, 1)",
  "050": "rgba(42, 40, 45, 1)"
}, e = {
  white: "rgba(255,255,255,1)",
  880: "rgba(255, 255, 255, 0.88)",
  660: "rgba(255, 255, 255, 0.66)",
  440: "rgba(255, 255, 255, 0.44)",
  330: "rgba(255, 255, 255, 0.33)",
  220: "rgba(255, 255, 255, 0.22)",
  160: "rgba(255, 255, 255, 0.16)",
  150: "rgba(255, 255, 255, 0.15)",
  140: "rgba(255, 255, 255, 0.14)",
  120: "rgba(255, 255, 255, 0.12)",
  110: "rgba(255, 255, 255, 0.11)",
  "090": "rgba(255, 255, 255, 0.09)",
  "080": "rgba(255, 255, 255, 0.08)",
  "070": "rgba(255, 255, 255, 0.07)",
  "050": "rgba(255, 255, 255, 0.05)"
}, i = {
  black: "rgba(0, 0, 0, 1)",
  880: "rgba(31, 31, 31, 1)",
  660: "rgba(87, 87, 87, 1)",
  440: "rgba(143, 143, 143, 1)",
  330: "rgba(171, 171, 171, 1)",
  220: "rgba(199, 199, 199, 1)",
  110: "rgba(227, 227, 227, 1)",
  "040": "rgba(245, 245, 245, 1)",
  "020": "rgba(250, 250, 250, 1)"
}, t = {
  black: "rgba(0,0,0,1)",
  880: "rgba(0, 0, 0, 0.88)",
  770: "rgba(0, 0, 0, 0.77)",
  660: "rgba(0, 0, 0, 0.66)",
  550: "rgba(0, 0, 0, 0.55)",
  440: "rgba(0, 0, 0, 0.44)",
  330: "rgba(0, 0, 0, 0.33)",
  220: "rgba(0, 0, 0, 0.22)",
  110: "rgba(0, 0, 0, 0.12)",
  "040": "rgba(0, 0, 0, 0.04)",
  "020": "rgba(0, 0, 0, 0.02)"
}, s = {
  100: "rgba(255, 235, 240, 1)",
  200: "rgba(255, 204, 216, 1)",
  300: "rgba(255, 153, 178, 1)",
  400: "rgba(251, 106, 142, 1)",
  500: "rgba(229, 76, 115, 1)",
  600: "rgba(217, 38, 83, 1)"
}, h = {
  100: "rgba(255, 244, 235, 1)",
  200: "rgba(255, 229, 204, 1)",
  300: "rgba(255, 202, 153, 1)",
  400: "rgba(251, 176, 106, 1)",
  500: "rgba(244, 137, 37, 1)",
  600: "rgba(236, 124, 19, 1)"
}, u = {
  100: "rgba(231, 254, 249, 1)",
  200: "rgba(208, 251, 242, 1)",
  300: "rgba(163, 245, 227, 1)",
  400: "rgba(106, 226, 200, 1)",
  500: "rgba(71, 209, 179, 1)",
  600: "rgba(64, 191, 163, 1)"
}, B = {
  100: "rgba(236, 242, 253, 1)",
  200: "rgba(208, 222, 251, 1)",
  300: "rgba(161, 190, 247, 1)",
  400: "rgba(125, 161, 232, 1)",
  500: "rgba(87, 131, 219, 1)",
  600: "rgba(51, 102, 204, 1)"
}, d = {
  100: "rgba(243, 236, 253, 1)",
  200: "rgba(225, 212, 247, 1)",
  300: "rgba(209, 188, 245, 1)",
  400: "rgba(164, 126, 231, 1)",
  500: "rgba(137, 88, 223, 1)",
  600: "rgba(105, 45, 210, 1)"
}, l = {
  500: "rgba(138, 222, 52, 1)"
}, a = {
  accent: d[400],
  accentHover: "rgb(141, 116, 207)",
  darkContent: "rgb(18, 18, 18)",
  success: l[500],
  danger: s[400],
  attention: h[500]
}, w = {
  onDark: {
    highlighted: a.accent,
    success: a.success,
    danger: a.danger,
    attention: a.attention,
    error: a.danger,
    disabled: e[330],
    tertiary: e[440],
    secondary: e[660],
    primary: r.white,
    white: r.white
  },
  onLight: {
    highlighted: a.accent,
    success: a.success,
    danger: a.danger,
    attention: a.attention,
    error: a.danger,
    disabled: t[220],
    tertiary: t[440],
    secondary: t[660],
    primary: t[880],
    white: r.white
  }
}, m = {
  onLight: {
    default: t[330],
    hover: t[660],
    highlighted: a.accent,
    disabled: t[220],
    static: t[440],
    active: i.black,
    error: a.danger,
    success: a.success,
    danger: a.danger,
    attention: a.attention
  },
  onDark: {
    default: e[330],
    hover: e[660],
    highlighted: a.accent,
    disabled: e[220],
    static: e[440],
    active: e.white,
    error: a.danger,
    success: a.success,
    danger: a.danger,
    attention: a.attention
  }
}, v = {
  ...g("white-solid", r),
  ...g("white-transparent", e),
  ...g("black-solid", i),
  ...g("black-transparent", t),
  ...g("", a),
  ...g("red", s),
  ...g("orange", h),
  ...g("green", l),
  ...g("blue", B),
  ...g("teal", u),
  ...g("purple", d)
}, p = (n = i, o) => ({
  /*  Atoms  */
  contentBarsAtom: r.white,
  contentBgAtom: n["020"],
  inputFieldBgAtom: r.white,
  hairlineAtom: r[880],
  listHoverAtom: n["040"],
  listActiveAtom: n[110],
  iconDefaultAtom: t[330],
  iconHoverAtom: t[660],
  iconDisabledAtom: t[110],
  iconStaticAtom: t[440],
  /*  Navigation   */
  naviBg: "rgba(25, 25, 25, 1)",
  naviIconDefault: e[330],
  naviIconHover: e[660],
  naviIconActive: r.white,
  naviIconDisabled: e[110],
  /* Bars & Content  */
  titleBarBg: r.white,
  buttonBarBg: r.white,
  tabBarBg: r.white,
  contentBg: o ?? n["020"],
  contentLightBg: r.white,
  actionIconDefault: t[330],
  actionIconHover: t[660],
  settingsGroupBg: r.white,
  listGroupBg: r.white,
  inputBarBg: r.white,
  inputBarFieldBg: i["040"],
  inputBarFieldBorder: i[110],
  /*  Elements */
  categoryHeader: n[110],
  lineDivider: n[110],
  bannerBoxBg: r.white,
  cardBg: r.white,
  /*  Selection Controls    */
  switchHandleDefault: r.white,
  switchSlideBgDefault: n[110],
  switchHandleActive: r.white,
  /*  Input Fields    */
  inputFieldBg: r.white,
  inputFieldBorder: t[220],
  inputFieldBorderHover: t[440],
  inputFieldBorderError: a.danger,
  inputFieldLabel: w.onLight.tertiary,
  /*  Dialogs */
  backgroundScrim: "rgba(40, 34, 45, 0.8)",
  backgroundScrimGradientTop: "rgba(40, 34, 45, 0.8)",
  backgroundScrimGradientBottom: "rgba(40, 34, 45, 0)",
  dialogBoxBg: r.white,
  dialogBoxFooterBg: n["040"],
  /* FAB  */
  speedDialIconBg: r.white,
  speedDialOptionLabelBg: t[660],
  /* Scrollbar */
  scrollbarThumbColor: t[110],
  scrollbarBackgroundColor: "transparent",
  /* Snackbar */
  snackbarBackground: i[660],
  transparent: "rgba(255,255,255,0)",
  transparentDark: "rgba(0,0,0,0)",
  transparentLight: "rgba(255,255,255,0)",
  skeleton: i[110],
  /* Authenticity Icon */
  authenticityUnknownBg: t[110],
  authenticityUnknownIcon: r.white
}), D = p();
export {
  v as BaseColors,
  i as BlackSolid,
  t as BlackTransparent,
  B as Blue,
  D as Decisions,
  l as Green,
  m as Icons,
  a as Main,
  h as Orange,
  d as Purple,
  s as Red,
  u as Teal,
  w as Texts,
  r as WhiteSolid,
  e as WhiteTransparent,
  p as getLightThemeDecisions,
  g as toThemeColorMapping
};
