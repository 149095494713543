import { ConversationMessageFragment, ConversationNestedMessageFragment, FullConversationFragment, User } from '@generated/graphql';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { computed } from '@knuddels-app/mobx';
import { Flex, FlexCol, IconClose, Image, ScrollView, ThemeOverride, useTheme, Z_INDEX, resolveThemingValue, FallbackBox, resolveIsDarkColor } from '@knuddels/component-library';
import { $UserService } from '@knuddelsModules/UserData';
import { FormattedText, FormattedTextText, Spacer } from '@shared/components';
import { IconReply } from '@shared/icons/IconReply';
import { useDrag } from '@use-gesture/react';
import * as React from 'react';
import { conversationInputEvent } from '../../../analytics';
import { SnapSize } from '../Messages/ImageAndSnap/SnapSizes';
import { NestedMessage } from '../Messages/NestedMessage';
import { NestedMessageHeader } from '../Messages/NestedMessageHeader';
import { useSafeAreaPaddingBottom } from '@knuddels-app/overlays/InsideModalContext';
@injectable()
class InputBarQuoteExtensionModel implements IModel {
  constructor(@injectProps()
  private readonly props: OwnProps, @inject($UserService)
  private readonly userService: typeof $UserService.T) {}
  componentDidMount(): void {
    conversationInputEvent.track('Inputbar_QuoteShown');
  }
  public isOwnQuote = (quote: OwnProps['quotedMessage']): boolean => {
    return quote.sender.id === this.activeUserId;
  };
  private get activeUserId(): User['id'] | undefined {
    return this.userService.currentUser && this.userService.currentUser.id;
  }
  @computed
  public get text(): FormattedText {
    const quotedMessage = this.props.quotedMessage;
    return 'formattedText' in quotedMessage.content ? FormattedText.fromJsonString(quotedMessage.content.formattedText) : new FormattedTextText('');
  }
}
const SlideUpView: React.FC<{
  animateOut: boolean;
  onDone: () => void;
}> = ({
  children,
  animateOut,
  onDone
}) => {
  const domRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState(0);
  const paddingBottom = useSafeAreaPaddingBottom();
  const bind = useDrag(({
    offset: [, y],
    last
  }) => {
    if (y > 100 && last) {
      out();
    }
  }, {
    axis: 'y'
  });
  React.useLayoutEffect(() => {
    if (height) {
      domRef.current!.style.animation = 'none';
      domRef.current!.style.transform = `translateY(${height}px)`;
      domRef.current!.animate({
        transform: `translateY(0)`
      }, {
        duration: 200,
        fill: 'forwards',
        easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
      });
    }
  }, [height]);
  const out = () => {
    domRef.current!.style.animation = 'none';
    const animation = domRef.current!.animate({
      transform: `translateY(${height}px)`
    }, {
      duration: 200,
      fill: 'forwards',
      easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
    });
    animation.addEventListener('finish', () => {
      onDone();
    }, {
      once: true
    });
  };
  React.useEffect(() => {
    if (animateOut) {
      out();
    }
  }, [animateOut]);
  return <div {...bind()} style={{
    zIndex: Z_INDEX.HIGHEST_CONTENT,
    touchAction: 'none'
  }}>
			<div ref={domRef} style={{
      bottom: resolveThemingValue((66 + paddingBottom as ThemeOverride), "spacing", useTheme())
    }} className={_c0}>
				<FallbackBox onLayout={e => setHeight(e.height)} className={_c1}>
					{children}
				</FallbackBox>
			</div>
		</div>;
};
export const InputBarQuoteExtension = injectedComponent({
  name: 'InputBarQuoteExtension',
  model: InputBarQuoteExtensionModel,
  props: declareProps<OwnProps>()
}, ({
  model,
  quotedMessage,
  indent,
  clearQuote
}) => {
  const theme = useTheme();
  const [animateOut, setAnimateOut] = React.useState(false);
  const isOwnQuote = model.isOwnQuote(quotedMessage);
  const backgroundColor = isOwnQuote ? theme.colors.Messenger.outgoingMessage : theme.colors.Messenger.incomingMessage;
  const image = quotedMessage.content.__typename === 'ConversationImageMessageContent' ? quotedMessage.content.image : undefined;
  const senderNick = quotedMessage.sender.nick;
  return <SlideUpView onDone={clearQuote} animateOut={animateOut}>
				<div style={{
      marginBottom: resolveThemingValue((-10 as ThemeOverride), "spacing", useTheme()),
      background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
    }} className={_c2 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (indent ? _c3 : _c4)}>
					<NestedMessageHeader type={IconReply} timestamp={quotedMessage.timestamp} senderNick={senderNick} />
					<Spacer size={'small'} />
					<div className={_c5}>
						<NestedMessage text={model.text} senderNick={senderNick}>
							{image && <>
									<Image src={image.url} alt={''} resizeMode={'cover'} style={{
              width: resolveThemingValue(SnapSize.QUOTE_PREVIEW + 'px', "sizes", useTheme()),
              height: resolveThemingValue(SnapSize.QUOTE_PREVIEW + 'px', "sizes", useTheme())
            }} className={_c6} />
									<Spacer size={'minor'} />
								</>}
						</NestedMessage>
					</div>
					<div className={_c7}>
						<IconClose onPress={() => {
          setAnimateOut(true);
        }} />
					</div>
				</div>
			</SlideUpView>;
});
interface OwnProps {
  conversation: FullConversationFragment;
  indent: boolean;
  quotedMessage: ConversationMessageFragment | ConversationNestedMessageFragment;
  clearQuote: () => void;
}
const _c0 = " Knu-Flex width-full position-absolute ";
const _c1 = " Knu-Flex size-full ";
const _c2 = " Knu-FlexCol flex-1 borderRadius-base px-10px pt-small pb-22px position-relative ";
const _c3 = " mx-base ";
const _c4 = " mx-none ";
const _c5 = " Knu-ScrollView maxHeight-80px ";
const _c6 = "  ";
const _c7 = " Knu-FlexCol position-absolute right-small top-small ";