import { MenteeStatus, User } from '@generated/graphql';
import { $UserService } from '@knuddelsModules/UserData';
import { $MessageLayoutSettingsService } from '@knuddelsModules/Settings';
import { RenderMode } from '@knuddelsModules/Settings/bundle/services';

type Sender = Pick<
	User,
	| 'nick'
	| 'id'
	| 'menteeStatus'
	| 'isOnline'
	| 'currentOnlineChannelName'
	| 'canSendImages'
>;
const createSender = (nick: string, id: string): Sender => ({
	nick,
	id,
	menteeStatus: MenteeStatus.None,
	canSendImages: true,
	isOnline: true,
	currentOnlineChannelName: '',
});

const Senders = {
	LamiKa: createSender('LamiKa', '59314495'),
	plexx: createSender('plexx', '65'),
	Btcm: createSender('Btcm', '62554138'),
	holgi: createSender('holgi', '1'),
	James: createSender('James', '0'),
};

const textFragment = (text: string) => `{"text":{"text":"${text}"}}`;
const linkFragment = (text: string, href: string) =>
	`{"link":{"text":"${text}","href":"${href}"}}`;
const listFragment = (items: string[]) =>
	`{"list":{"items":[${items
		.map(item => `${item}`)
		.join(',' + textFragment(' ') + ',')}]}}`;

const createMessage = (
	formattedText: string,
	typename: string,
	sender: Sender
) => ({
	id: 1,
	formattedText,
	__typename: typename,
	sender,
});

export type MockMessage =
	| ReturnType<typeof createMessage>
	| ReturnType<typeof createPrivateGroupMessage>;

const createPublicMessage = (formattedText: string, sender: Sender) => {
	return createMessage(formattedText, 'ChannelMsgPublic', sender);
};
const createActionMessage = (formattedText: string, sender: Sender) => {
	return createMessage(formattedText, 'ChannelMsgAction', sender);
};
const createPrivateMessage = (formattedText: string, sender: Sender) => {
	return {
		__typename: 'CLIENT_MESSENGER_MESSAGE',
		id: '1',
		conversation: {
			id: '1',
			visibility: 'VISIBLE',
			otherParticipants: [] as any[],
		},
		message: {
			id: '1',
			__typename: 'ConversationMessage',
			content: {
				formattedText,
				__typename: 'ConversationTextMessageContent',
			},
			timestamp: Date.now() + '',
			sender,
		},
	};
};
const createPrivateGroupMessage = (
	formattedText: string,
	sender: Sender,
	receiver: any
) => {
	return {
		__typename: 'ChannelMsgPrivateGroup',
		id: '1',
		sender,
		formattedText,
		receiver: [receiver, Senders.Btcm],
	};
};

const getRenderModeText = (renderMode: RenderMode) => {
	switch (renderMode) {
		case 'modern':
			return 'Design mit Sprechblasen';
		case 'modernWithoutBubbles':
			return 'Design ohne Sprechblasen';
		case 'condensed':
			return 'minimalistische Design';
		default:
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const n: never = renderMode;
	}
};

export const getMockMessages = (
	userService: typeof $UserService.T,
	layoutService: typeof $MessageLayoutSettingsService.T
) => {
	const messages: MockMessage[] = [
		createPublicMessage(
			listFragment([
				textFragment('Hallo'),
				linkFragment(`@Neo-Kamui`, `/w Neo-Kamui`),
			]),
			Senders.LamiKa
		),
		createActionMessage(
			listFragment([
				linkFragment('plexx', `/w plexx`),
				textFragment(
					`winkt kurz allen Anwesenden, besonders ${
						userService.currentUser?.nick ?? ''
					}, zur Begrüßung.`
				),
			]),
			Senders.plexx
		),
		createPrivateMessage(
			textFragment('Hey, da bist du ja wieder! Wie war dein Urlaub?'),
			Senders.James
		) as any as MockMessage,
		createPublicMessage(
			textFragment('Jemand aus Karlsruhe Lust zu schreiben?'),
			Senders.Btcm
		),
		createPublicMessage(
			listFragment([
				textFragment('Ich habe gerade mega viele Knuddel in '),
				linkFragment('#Crash', '/go crash'),
				textFragment(' gewonnen.'),
			]),
			Senders.holgi
		),
		createPublicMessage(
			textFragment(
				`Das ${getRenderModeText(
					layoutService.renderMode
				)} gefällt mir am besten!`
			),
			createSender(
				userService.currentUser?.nick ?? '',
				userService.currentUser?.id ?? '1'
			)
		),
	];

	if (import.meta.env.DEV) {
		messages.push(
			createPrivateGroupMessage(
				textFragment('Hey, wie geht es euch?'),
				Senders.James,
				userService.currentUser
			)
		);
	}

	return messages;
};
