import * as React from 'react';
import { FriendState, UserForQuickWhoisFragment } from '@generated/graphql';
import { AcceptFriendRequestButton } from './AcceptFriendRequestButton';
import { NewFriendsBanner } from './NewFriendsBanner';
import { Flex } from '@knuddels/component-library';
export const FriendStateBanner: React.FC<{
  user: Pick<UserForQuickWhoisFragment, 'id' | 'friendState' | 'hasSeenProfileAfterFriendshipStarted'>;
  size: 'large' | 'small';
}> = ({
  user,
  size
}) => {
  if (user.friendState === FriendState.RequestReceived) {
    return <AbsoluteElementContainer>
				<AcceptFriendRequestButton userId={user.id} />
			</AbsoluteElementContainer>;
  }
  if (user.friendState === FriendState.Friends && !user.hasSeenProfileAfterFriendshipStarted) {
    return <AbsoluteElementContainer>
				<NewFriendsBanner size={size} userId={user.id} />
			</AbsoluteElementContainer>;
  }
  return null;
};
const AbsoluteElementContainer: React.FC = ({
  children
}) => {
  return <div className={_c0}>
			{children}
		</div>;
};
const _c0 = " Knu-Flex position-absolute bottom-minor alignSelf-center justifyContent-center ";