import * as React from 'react';
import { Flex, ListGroup, ScrollView, SwitchListItem, useTheme, resolveThemingValue } from '@knuddels/component-library';
import { ProfileSettings } from './ProfileSettings';
import { VerifiedDataSettings } from './VerifiedDataSettings';
import { SecuritySettings } from './SecuritySettings';
import { TanSystemEnabled } from '@knuddelsModules/FeatureFlags';
import { SettingsListItem } from '../SettingsListItem';
import { declareFormat } from '@knuddels/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useMutation } from '@knuddels-app/Connection';
import { OpenAccountDeletionApp } from '@generated/graphql';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $LastLoginStorage } from '@knuddels-app/Connection/serviceIds';
import { observer } from '@knuddels-app/mobx';
export const AccountSettings: React.FC = () => {
  const i18n = useService($I18n);
  const theme = useTheme();
  const deleteAccount = useDeleteAccount();
  return <div className={_c0}>
			<ProfileSettings />
			<VerifiedDataSettings />

			<TanSystemEnabled.OnActive>
				<SecuritySettings />
			</TanSystemEnabled.OnActive>

			<SaveAccountSettings />

			<ListGroup>
				<SettingsListItem caption={declareFormat({
        id: 'settings.accountSettings.deleteAccount',
        defaultFormat: 'Delete account'
      }).format(i18n)} onPress={async () => await deleteAccount()} separator={'none'} />
			</ListGroup>

			<div style={{
      minHeight: resolveThemingValue(theme.spacings.xxlarge, "sizes", useTheme())
    }} className={_c1} />
		</div>;
};
const SaveAccountSettings = observer('SaveAccountSettings', () => {
  const lastLoginStore = useService($LastLoginStorage);
  const i18n = useService($I18n);
  return <ListGroup title={declareFormat({
    id: 'settings.saveAccountsSettings.headline'
  }).format(i18n)}>
			<SwitchListItem checked={lastLoginStore.storeMultipleNicks} onChange={lastLoginStore.setStoreMultipleNicks} caption={declareFormat({
      id: 'settings.saveAccountsSettings.caption'
    }).format(i18n)} additionalInfo={declareFormat({
      id: 'settings.saveAccountsSettings.info'
    }).format(i18n)} separator={'none'} />
		</ListGroup>;
});
const useDeleteAccount = () => {
  const i18n = useService($I18n);
  const snackbarService = useService($SnackbarService);
  const [openAccountDeletionApp] = useMutation(OpenAccountDeletionApp);
  const logEvent = useLogEvent();
  return async (): Promise<void> => {
    logEvent('Settings_AccountAndSecurity', 'RemoveAccount_Clicked');
    const result = await openAccountDeletionApp({});
    if (result.data.__typename === 'Success') {
      return;
    }
    switch (result.data.__typename) {
      case 'DeletionAlreadyRunningError':
        {
          snackbarService.showErrorSnackbarWithDefaults({
            text: declareFormat({
              id: 'settings.accountSettings.deleteAccount.error.deletionAlreadyRunning.text',
              defaultFormat: 'The deletion is already in progress'
            }).format(i18n)
          });
          break;
        }
      case 'SpecialStatusOrRightsError':
        {
          snackbarService.showErrorSnackbarWithDefaults({
            text: declareFormat({
              id: 'settings.accountSettings.deleteAccount.error.specialStatusOrRights.text',
              defaultFormat: 'Your account is too valuable'
            }).format(i18n),
            subtext: declareFormat({
              id: 'settings.accountSettings.deleteAccount.error.specialStatusOrRights.subtext',
              defaultFormat: 'Contact support to have your account deleted'
            }).format(i18n)
          });
          break;
        }
      case 'ActivePremiumSubscriptionError':
        {
          snackbarService.showErrorSnackbarWithDefaults({
            text: declareFormat({
              id: 'settings.accountSettings.deleteAccount.error.activePremiumSubscription.text',
              defaultFormat: 'You cannot delete your account because you have an active subscription'
            }).format(i18n)
          });
          break;
        }
      case 'InternalError':
        {
          snackbarService.showErrorSnackbarWithDefaults({
            text: declareFormat({
              id: 'settings.accountSettings.deleteAccount.error.generic.text',
              defaultFormat: 'You cannot currently delete your account'
            }).format(i18n),
            subtext: declareFormat({
              id: 'settings.accountSettings.deleteAccount.error.generic.subtext',
              defaultFormat: 'Please contact support'
            }).format(i18n)
          });
          break;
        }
      default:
        {
          snackbarService.showGenericError();
          break;
        }
    }
  };
};
const _c0 = " Knu-ScrollView size-full ";
const _c1 = " Knu-Flex height-full ";