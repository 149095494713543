import { useService } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { useParams, useRouter } from '@knuddelsModules/Settings/bundle/components/SettingsRouter';
import { $SystemAppService, GenericSystemApp } from '@knuddelsModules/SystemApps';
import { $UserService } from '@knuddelsModules/UserData';
import { TRANSFER_HISTORY_APP_ID } from '@shared/constants';
import * as React from 'react';
export const KnuddelTransferHistoryApp = () => {
  const {
    pop
  } = useRouter();
  const params = useParams('KnuddelTransferHistory');
  const userService = useService($UserService);
  return <LoadService service={$SystemAppService}>
			{() => <GenericSystemApp viewType={'subpath'} onAppClose={() => {
      pop();
    }} appId={TRANSFER_HISTORY_APP_ID} customSlashCommand={`/knuddeltransfers ${params?.channelName ?? userService.currentUser.nick}`} />}
		</LoadService>;
};