import * as React from 'react';
import { LoadFromService } from '@knuddels-app/ModuleSystem';
import * as providedServiceIds from './providedServices';
import { LoadingScreen } from '@knuddels-app/LoadingScreen';
export { AppsWebview } from './bundle/components/AppsWebview';
export { useAppContext } from './bundle/components/useAppContext';
export * from './providedServices';
export { SystemAppOverlayPresenter } from './bundle/components/SystemAppOverlayPresenter';
export const ChannelAppViewerLoadable = LoadFromService({
  service: providedServiceIds.$AppService,
  selector: s => s.ChannelAppViewer,
  loading: () => <LoadingScreen />
});
export const ChannelAppViewerBackgroundSpacerLoadable = LoadFromService({
  service: providedServiceIds.$AppService,
  selector: s => s.ChannelAppViewerBackgroundSpacer,
  loading: () => <LoadingScreen />
});
export const ChannelBackgroundAppViewLoadable = LoadFromService({
  service: providedServiceIds.$AppService,
  selector: s => s.ChannelBackgroundAppComponent,
  loading: () => <LoadingScreen />
});