import * as React from 'react';
import { MessengerConversation } from '@generated/graphql';
import { conversationArchiveOverviewEvent, conversationOpenedTracker, conversationOverviewOpenConversationEvent } from '@knuddelsModules/Messenger/bundle/analytics';
export const useOpenConversation = (isArchive: boolean, cb: (conversationId: string) => void) => {
  return React.useCallback((conversationId: MessengerConversation['id'], index: number) => {
    conversationOpenedTracker.start();
    if (isArchive) {
      conversationArchiveOverviewEvent.track('ArchiveOverview_OpenConversation' + (index + 1));
    } else {
      conversationOverviewOpenConversationEvent.track('OpenConversation_ClickPosition' + (index + 1));
    }
    cb(conversationId);
  }, [isArchive]);
};