import { time as S } from "../../frameloop/sync-time.js";
import { DOMKeyframesResolver as P } from "../../render/dom/DOMKeyframesResolver.js";
import { memo as A } from "../../utils/memo.js";
import { noop as y } from "../../utils/noop.js";
import { millisecondsToSeconds as g, secondsToMilliseconds as T } from "../../utils/time-conversion.js";
import { BaseAnimation as V } from "./BaseAnimation.js";
import { MainThreadAnimation as w } from "./MainThreadAnimation.js";
import { acceleratedValues as K } from "./utils/accelerated-values.js";
import { animateStyle as F } from "./waapi/index.js";
import { isWaapiSupportedEasing as R } from "./waapi/easing.js";
import { getFinalKeyframe as b } from "./waapi/utils/get-final-keyframe.js";
const D = A(() => Object.hasOwnProperty.call(Element.prototype, "animate")), d = 10, M = 2e4;
function x(r) {
  return r.type === "spring" || !R(r.ease);
}
function C(r, e) {
  const t = new w({
    ...e,
    keyframes: r,
    repeat: 0,
    delay: 0,
    isGenerator: !0
  });
  let i = { done: !1, value: r[0] };
  const o = [];
  let n = 0;
  for (; !i.done && n < M; )
    i = t.sample(n), o.push(i.value), n += d;
  return {
    times: void 0,
    keyframes: o,
    duration: n - d,
    ease: "linear"
  };
}
class L extends V {
  constructor(e) {
    super(e);
    const { name: t, motionValue: i, keyframes: o } = this.options;
    this.resolver = new P(o, (n, s) => this.onKeyframesResolved(n, s), t, i), this.resolver.scheduleResolve();
  }
  initPlayback(e, t) {
    var i;
    let { duration: o = 300, times: n, ease: s, type: a, motionValue: l, name: h } = this.options;
    if (!(!((i = l.owner) === null || i === void 0) && i.current))
      return !1;
    if (x(this.options)) {
      const { onComplete: p, onUpdate: v, motionValue: f, ...c } = this.options, u = C(e, c);
      e = u.keyframes, e.length === 1 && (e[1] = e[0]), o = u.duration, n = u.times, s = u.ease, a = "keyframes";
    }
    const m = F(l.owner.current, h, e, { ...this.options, duration: o, times: n, ease: s });
    return m.startTime = S.now(), this.pendingTimeline ? (m.timeline = this.pendingTimeline, this.pendingTimeline = void 0) : m.onfinish = () => {
      const { onComplete: p } = this.options;
      l.set(b(e, this.options, t)), p && p(), this.cancel(), this.resolveFinishedPromise();
    }, {
      animation: m,
      duration: o,
      times: n,
      type: a,
      ease: s,
      keyframes: e
    };
  }
  get duration() {
    const { resolved: e } = this;
    if (!e)
      return 0;
    const { duration: t } = e;
    return g(t);
  }
  get time() {
    const { resolved: e } = this;
    if (!e)
      return 0;
    const { animation: t } = e;
    return g(t.currentTime || 0);
  }
  set time(e) {
    const { resolved: t } = this;
    if (!t)
      return;
    const { animation: i } = t;
    i.currentTime = T(e);
  }
  get speed() {
    const { resolved: e } = this;
    if (!e)
      return 1;
    const { animation: t } = e;
    return t.playbackRate;
  }
  set speed(e) {
    const { resolved: t } = this;
    if (!t)
      return;
    const { animation: i } = t;
    i.playbackRate = e;
  }
  get state() {
    const { resolved: e } = this;
    if (!e)
      return "idle";
    const { animation: t } = e;
    return t.playState;
  }
  /**
   * Replace the default DocumentTimeline with another AnimationTimeline.
   * Currently used for scroll animations.
   */
  attachTimeline(e) {
    if (!this._resolved)
      this.pendingTimeline = e;
    else {
      const { resolved: t } = this;
      if (!t)
        return y;
      const { animation: i } = t;
      i.timeline = e, i.onfinish = null;
    }
    return y;
  }
  play() {
    if (this.isStopped)
      return;
    const { resolved: e } = this;
    if (!e)
      return;
    const { animation: t } = e;
    t.playState === "finished" && this.updateFinishedPromise(), t.play();
  }
  pause() {
    const { resolved: e } = this;
    if (!e)
      return;
    const { animation: t } = e;
    t.pause();
  }
  stop() {
    if (this.resolver.cancel(), this.isStopped = !0, this.state === "idle")
      return;
    this.resolveFinishedPromise(), this.updateFinishedPromise();
    const { resolved: e } = this;
    if (!e)
      return;
    const { animation: t, keyframes: i, duration: o, type: n, ease: s, times: a } = e;
    if (t.playState === "idle" || t.playState === "finished")
      return;
    if (this.time) {
      const { motionValue: h, onUpdate: m, onComplete: p, ...v } = this.options, f = new w({
        ...v,
        keyframes: i,
        duration: o,
        type: n,
        ease: s,
        times: a,
        isGenerator: !0
      }), c = T(this.time);
      h.setWithVelocity(f.sample(c - d).value, f.sample(c).value, d);
    }
    const { onStop: l } = this.options;
    l && l(), this.cancel();
  }
  complete() {
    const { resolved: e } = this;
    e && e.animation.finish();
  }
  cancel() {
    const { resolved: e } = this;
    e && e.animation.cancel();
  }
  static supports(e) {
    const { motionValue: t, name: i, repeatDelay: o, repeatType: n, damping: s, type: a } = e;
    return D() && i && K.has(i) && t && t.owner && t.owner.current instanceof HTMLElement && /**
     * If we're outputting values to onUpdate then we can't use WAAPI as there's
     * no way to read the value from WAAPI every frame.
     */
    !t.owner.getProps().onUpdate && !o && n !== "mirror" && s !== 0 && a !== "inertia";
  }
}
export {
  L as AcceleratedAnimation
};
