import { mixNumber as m } from "../../utils/mix/number.js";
const N = 1e-4, u = 1 - N, E = 1 + N, R = 0.01, S = 0 - R, a = 0 + R;
function s(n) {
  return n.max - n.min;
}
function L(n, i, c) {
  return Math.abs(n - i) <= c;
}
function x(n, i, c, o = 0.5) {
  n.origin = o, n.originPoint = m(i.min, i.max, n.origin), n.scale = s(c) / s(i), n.translate = m(c.min, c.max, n.origin) - n.originPoint, (n.scale >= u && n.scale <= E || isNaN(n.scale)) && (n.scale = 1), (n.translate >= S && n.translate <= a || isNaN(n.translate)) && (n.translate = 0);
}
function I(n, i, c, o) {
  x(n.x, i.x, c.x, o ? o.originX : void 0), x(n.y, i.y, c.y, o ? o.originY : void 0);
}
function A(n, i, c) {
  n.min = c.min + i.min, n.max = n.min + s(i);
}
function P(n, i, c) {
  A(n.x, i.x, c.x), A(n.y, i.y, c.y);
}
function f(n, i, c) {
  n.min = i.min - c.min, n.max = n.min + s(i);
}
function T(n, i, c) {
  f(n.x, i.x, c.x), f(n.y, i.y, c.y);
}
export {
  x as calcAxisDelta,
  I as calcBoxDelta,
  s as calcLength,
  A as calcRelativeAxis,
  f as calcRelativeAxisPosition,
  P as calcRelativeBox,
  T as calcRelativePosition,
  L as isNear
};
