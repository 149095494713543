import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { GenericModal, Spacer } from '@shared/components';
import { $ScreenService } from '@knuddels-app/Screen';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { observable } from '@knuddels-app/mobx';
import { Text } from '@knuddels/component-library';
import pic from '../assets/payment_error.gif';
export interface PaymentU18AmountRestrictionModalProps {
  onCancel(): void;
}
@injectable()
class PaymentU18AmountRestrictionModalModel {
  @observable
  private _waitingForClose = false;
  constructor(@injectProps()
  private readonly props: PaymentU18AmountRestrictionModalProps, @inject($ScreenService)
  private screenService: typeof $ScreenService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  public get waitingForClose(): boolean {
    return this._waitingForClose;
  }
  public get maxWidth(): number {
    return this.screenService.isStackedLayout ? 312 : 360;
  }
  public hideModal = () => {
    this.props.onCancel();
    this.thisVisibleOverlay.dispose();
  };
}
export const PaymentU18AmountRestrictionModal = injectedComponent({
  name: 'PaymentU18AmountRestrictionModal',
  model: PaymentU18AmountRestrictionModalModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<PaymentU18AmountRestrictionModalProps>()
}, ({
  model,
  i18n
}) => <GenericModal headline={i18n.format(declareFormat({
  id: 'payment.u18restrictionmodal.header',
  defaultFormat: 'Snap, something went wrong.'
}))} alignCenter primaryButton={{
  text: i18n.format(declareFormat({
    id: 'payment.u18restrictionmodal.button.cancel',
    defaultFormat: 'Cancel'
  })),
  onClick: model.hideModal,
  width: 'fluid',
  disabled: model.waitingForClose
}} cancelModal={model.hideModal} maxWidth={model.maxWidth} pictureProps={{
  source: pic,
  size: 53,
  sizingMode: 'autoHeight',
  borderType: 'normal'
}}>
			<Spacer size={'base'} />
			<Text state={'tertiary'} className={_c0}>
				{i18n.format(declareFormat({
      id: 'payment.u18restrictionmodal.subheader',
      defaultFormat: 'We have limited the maximum turnover for members under 18 to €50 per month. With this purchase you would probably exceed this limit. Therefore it cannot be carried out.'
    }))}
			</Text>
			<Spacer size={'xlarge'} />
			<Spacer size={'small'} />
		</GenericModal>);
const _c0 = " textAlign-center ";