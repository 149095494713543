import * as React from 'react';
import { ScrollContext } from '../../EditProfileMobileScrollContext';
import { TITLE_BAR_HEIGHT } from '@knuddels/component-library';

const TAB_BAR_HEIGHT = 48;

export const useScrollIntoView = ({
	useScrollTop = true,
}: {
	useScrollTop?: boolean;
} = {}) => {
	const ref = React.useRef<HTMLInputElement>(null);
	const scrollRef = React.useContext(ScrollContext);

	return {
		ref,
		onFocus(): void {
			if (!useScrollTop && ref.current) {
				setTimeout(() => {
					if (ref.current) {
						ref.current.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
					}
				}, 400);
			} else if (ref.current && scrollRef.current) {
				setTimeout(() => {
					if (scrollRef.current && ref.current) {
						scrollRef.current.scrollTo({
							behavior: 'smooth',
							top:
								ref.current.offsetTop +
								TITLE_BAR_HEIGHT +
								TAB_BAR_HEIGHT,
						});
					}
				}, 400);
			}
		},
	};
};
