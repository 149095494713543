import * as React from 'react';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $AdsService } from '@knuddelsModules/Ads';
import { Lightbox, LightboxBlurBackdrop } from '@shared/components';
import { $ScreenService } from '@knuddels-app/Screen';
import { FlexCol, ThemeColors, ThemeOverride, TITLE_BAR_HEIGHT, resolveThemingValue, useTheme } from '@knuddels/component-library';
export const ChannelLightbox = injectedComponent({
  name: 'ChannelLightbox',
  props: declareProps<React.PropsWithChildren<{
    backgroundColor?: ThemeColors;
    blurBackground?: boolean;
    addTitlebarOffset?: boolean;
    sideAdPosition: 'center' | 'emptyScreen';
  }>>(),
  inject: {
    adsService: $AdsService,
    screenService: $ScreenService
  }
}, ({
  children,
  adsService,
  screenService,
  backgroundColor,
  blurBackground,
  addTitlebarOffset = false,
  sideAdPosition
}) => {
  const shouldRenderSide = (width: number) => !screenService.isStackedLayout && width - 340 >= 412;
  const renderSide = (width: number) => {
    return shouldRenderSide(width) && <SideAdzone adsService={adsService} width={300} position={sideAdPosition} />;
  };
  const renderBottom = (width: number, height: number, availableHeight: number) => {
    const totalAdzoneHeight = bottomAdzoneHeight + adPaddingTop + adPaddingBottom;
    return !shouldRenderSide(width) && availableHeight > totalAdzoneHeight && <BottomAdzone adsService={adsService} />;
  };
  return <LightboxBlurBackdrop enabled={blurBackground}>
				<Lightbox pt={addTitlebarOffset ? (TITLE_BAR_HEIGHT as ThemeOverride) : undefined} backgroundColor={backgroundColor} renderSideComponent={adsService.areWebAdsVisible ? renderSide : undefined} renderBottomComponent={adsService.areWebAdsVisible ? renderBottom : undefined}>
					{children}
				</Lightbox>
			</LightboxBlurBackdrop>;
});
const bottomAdzoneHeight = 50 + 24;
function BottomAdzone({
  adsService
}: {
  adsService: typeof $AdsService.T;
}): React.ReactElement {
  const Adzone = adsService.Adzone;
  return <div style={{
    paddingTop: resolveThemingValue((adPaddingTop as ThemeOverride), "spacing", useTheme()),
    paddingBottom: resolveThemingValue((adPaddingBottom as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			<Adzone adzone="channelNoConnectionMobileBanner" fixedSize={bottomAdzoneHeight} reloadAd hideLoadingIndicator />
		</div>;
}
function SideAdzone({
  adsService,
  width,
  position
}: {
  adsService: typeof $AdsService.T;
  width: number;
  position: 'center' | 'emptyScreen';
}): React.ReactElement {
  const Adzone = adsService.Adzone;
  return <>
			<div className={_c1}>
				<Adzone adzone="channelNoConnectionOffContent" fixedSize={width} reloadAd verticalAd />
			</div>
			{position === 'emptyScreen' && <div className={_c2} />}
		</>;
}
const adPaddingTop = 12;
const adPaddingBottom = 12;
const _c0 = " Knu-FlexCol position-absolute bottom-none insetX-none ";
const _c1 = " Knu-FlexCol position-relative flex-9 px-20px ";
const _c2 = " Knu-FlexCol flexGrow-1 ";