import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { TanEnabledInput } from '@generated/graphql';

export const useTrackTanSettings = () => {
	const logEvent = useLogEvent();

	return (label: string) => {
		logEvent('TANsystem_SettingsApp', label);
	};
};

export const useTrackUpdateTanSettings = () => {
	const track = useTrackTanSettings();

	return (settings: TanEnabledInput) => {
		const settingKey = Object.keys(settings)[0];
		const settingValue = Object.values(settings)[0];

		if (!settingKey) {
			return;
		}

		let trackingId: string;

		if (settingKey === 'knuddelTransferAmount') {
			trackingId = `Tansystem_Update_KnuddelTransferAmount_${settingValue}`;
		} else {
			trackingId = `Tansystem_${
				settingValue ? 'Activate' : 'Deactivate'
			}_${settingKey}`;
		}

		track(trackingId);
	};
};
