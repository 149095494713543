import { MessengerConversation } from '@generated/graphql';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $OverlayService } from '@knuddels-app/overlays';
import { FlexCol, PersistedVirtualList, Theme, ThemeContext, ThemeOverride, VirtualListRenderItemInfo, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import { $AdsService, ADZONE_HEIGHT } from '@knuddelsModules/Ads';
import { ArchivedConversationContextMenuOverlay } from '@knuddelsModules/Messenger/bundle/components/ContextMenus/ArchivedConversationContextMenu';
import { $NotificationsComponents } from '@knuddelsModules/Notifications';
import { $ProfileVisitorsPanelService, ProfileVisitorsOverview } from '@knuddelsModules/ProfileVisitors';
import { $UserOnlineStatusService } from '@knuddelsModules/UserData';
import { ActivityIndicator, SwipeContextProvider } from '@shared/components';
import { observer } from 'mobx-react';
import * as React from 'react';
import { $MessengerConversationService, $MessengerService } from '../../../providedServices';
import { conversationListLoadedTracker } from '../../analytics';
import { FetchStatus } from '../../services/MessengerListQuery';
import { MessengerOverviewItem } from '../../services/messengerOverviewHelper';
import { ConversationContextMenuOverlay } from '../ContextMenus/ConversationContextMenu';
import { MessengerListSkeleton } from '../Skeleton/MessengerListSkeleton';
import { createConversationListItem } from './createConversationListItem';
import { getMessengerListEntryHeight, MessengerListEntry } from './MessengerListEntry';
import { ConversationVirtualListViewItemInfo, ItemInfos, templateAdzone, templateConversation, templateLoadingIndicator, templateNotificationConfirmBox, templateProfileVistors } from './MessengerOverviewVirtualListTypes';
import { NoConversationsView } from './NoConversationsView';
import { notificationBoxTracking } from './notificationBoxTracking';
interface Props {
  isMinifiedOverview?: boolean;
  openConversation: (conversationId: MessengerConversation['id'], index: number) => void;
  conversations: readonly MessengerOverviewItem[];
  isArchive: boolean;
  fetchMore: () => void;
  fetchStatus: FetchStatus;
}
@injectable()
class MessengerListModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: Props, @inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($NotificationsComponents)
  private readonly notificationsComponents: typeof $NotificationsComponents.T, @inject($ProfileVisitorsPanelService)
  private readonly profileVisitorsPanelService: typeof $ProfileVisitorsPanelService.T, @inject($AdsService)
  private readonly adsService: typeof $AdsService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T) {
    conversationListLoadedTracker.start();
  }
  public get isStAppSidebarOverview(): boolean {
    return this.environment.isStappSidebarOverview;
  }
  public get shouldShowNotificationBox(): boolean {
    return !this.isStAppSidebarOverview && this.notificationsComponents.showConfirmBox;
  }
  public get shouldShowVisitorsPanel(): boolean {
    return !this.isStAppSidebarOverview && !this.props.isArchive && this.profileVisitorsPanelService.shouldShow();
  }
  public get shouldShowAds(): boolean {
    return this.adsService.areWebAdsVisible && !this.props.isArchive;
  }
  public showContextMenu = (x: number, y: number, width: number, height: number, conversationId: MessengerConversation['id']): void => {
    this.overlayService.showOverlayIfNotVisible(this.props.isArchive ? ArchivedConversationContextMenuOverlay : ConversationContextMenuOverlay, {
      conversationId,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  public get contextMenuConversation(): MessengerConversation['id'] | undefined {
    const overlay = this.overlayService.findOverlay(ConversationContextMenuOverlay.getFilter());
    return overlay && overlay.tag.data.conversationId;
  }
  public get conversationItems(): ItemInfos[] {
    const height = getMessengerListEntryHeight(!!this.props.isMinifiedOverview, this.isStAppSidebarOverview);
    const conversationList = this.props.conversations;
    return conversationList.map((item, index) => {
      return createConversationListItem(this.messengerConversationService, item, index, height, this.messengerService.getOpenConversationId());
    });
  }
}
export const MessengerList = injectedComponent({
  name: 'MessengerList',
  model: MessengerListModel,
  props: declareProps<Props>()
}, ({
  model,
  fetchMore,
  isArchive,
  isMinifiedOverview = false,
  openConversation,
  fetchStatus
}) => {
  const userOnlineStatusService = useService($UserOnlineStatusService);
  const allItems = model.conversationItems;
  React.useEffect(() => {
    allItems.forEach(item => {
      if (item.type === 'conversation') {
        if (item.conversation.otherParticipants[0]) {
          // Don't miss out on events and mitigate need to re-query online status later
          userOnlineStatusService.subscribeToOnlineStatus(item.conversation.otherParticipants[0].id);
        }
      }
    });
  }, [allItems]);
  if (fetchStatus === 'initiallyFetching') {
    return <MessengerListSkeleton amount={6} />;
  }
  if (fetchStatus === 'error') {
    // TODO show error with retry button
    return null;
  }

  // don't show anything in minified overview
  const shouldShowNoMessagesView = !isMinifiedOverview && allItems.length === 0;
  const shouldShowVisitorsPanel = model.shouldShowVisitorsPanel;
  const showNotificationBox = model.shouldShowNotificationBox;
  if (shouldShowNoMessagesView) {
    conversationListLoadedTracker.stop();
    return <div className={_c0}>
					{shouldShowVisitorsPanel && <ProfileVisitorsOverview type={'messenger'} showTopSpacer={!showNotificationBox} />}
					<NoConversationsView isArchivedView={isArchive} />
				</div>;
  }
  if (model.shouldShowAds) {
    allItems.splice(3, 0, {
      type: templateAdzone,
      height: ADZONE_HEIGHT + 2 * adPaddingVertical
    });
  }
  if (fetchStatus === 'fetchingMore') {
    allItems.push({
      type: templateLoadingIndicator,
      height: 36
    });
  }
  if (shouldShowVisitorsPanel) {
    allItems.unshift({
      type: templateProfileVistors,
      showTopSpacer: !showNotificationBox,
      // expected height... should somehow make this more robust
      height: 164
    });
  }
  if (showNotificationBox) {
    allItems.unshift({
      type: templateNotificationConfirmBox,
      // expected height... should somehow make this more robust
      height: 194
    });
  }
  conversationListLoadedTracker.stop();
  return <SwipeContextProvider>
				<MessengerListBase allItems={allItems} contextMenuConversation={model.contextMenuConversation} isMinifiedOverview={isMinifiedOverview} isArchive={isArchive} fetchMoreConversations={fetchMore} showContextMenu={model.showContextMenu} openConversation={openConversation} />
			</SwipeContextProvider>;
});
@observer
class MessengerListBase extends React.PureComponent<{
  allItems: ItemInfos[];
  contextMenuConversation: MessengerConversation['id'] | undefined;
  isMinifiedOverview: boolean;
  isArchive: boolean;
  fetchMoreConversations(): void;
  showContextMenu(x: number, y: number, width: number, height: number, conversationId: MessengerConversation['id']): void;
  openConversation(conversationId: MessengerConversation['id'], index: number): void;
}> {
  static contextType = ThemeContext;
  render(): JSX.Element {
    return <PersistedVirtualList param={this.props.isArchive ? 'archive' : 'overview'} data={this.props.allItems} renderItem={this.renderRow} onEndReached={this.props.fetchMoreConversations} onEndReachedThreshold={0.1} id="messengerOverview" className={_c1} />;
  }
  private renderRow = ({
    item
  }: VirtualListRenderItemInfo<ItemInfos>): JSX.Element => {
    switch (item.type) {
      case templateAdzone:
        return this.renderAdzoneItem();
      case templateLoadingIndicator:
        return <div className={_c2}>
						<ActivityIndicator color={(this.context as Theme).colors.basic.accent} size={'small'} />
					</div>;
      case templateProfileVistors:
        return <ProfileVisitorsOverview type={'messenger'} showTopSpacer={item.showTopSpacer} />;
      case templateNotificationConfirmBox:
        return <NotificationConfirmBoxItem />;
      case templateConversation: // fall-through
      default:
        return this.renderListItem(item);
    }
  };
  private renderAdzoneItem = (): JSX.Element => {
    return <AdzoneItem />;
  };
  private renderListItem = ({
    ...item
  }: ConversationVirtualListViewItemInfo): JSX.Element => {
    const conversationId = item.conversation.id;
    const conversationPartner = item.conversation.otherParticipants[0];
    return <MessengerListEntry {...item} isArchive={this.props.isArchive} isMinifiedOverview={this.props.isMinifiedOverview} conversationPartner={conversationPartner} openConversation={this.props.openConversation} showContextMenu={this.props.showContextMenu} isContextMenuOpen={conversationId === this.props.contextMenuConversation} />;
  };
}
const AdzoneItem = injectedComponent({
  name: 'AdzoneItem',
  inject: {
    adsService: $AdsService
  }
}, ({
  adsService
}) => {
  const Adzone = adsService.Adzone;
  return Adzone ? <div style={{
    paddingTop: resolveThemingValue((adPaddingVertical as ThemeOverride), "spacing", useTheme()),
    paddingBottom: resolveThemingValue((adPaddingVertical as ThemeOverride), "spacing", useTheme())
  }} className={_c3}>
				<Adzone adzone="messengerOverview" />
			</div> : null;
});
const NotificationConfirmBoxItem = injectedComponent({
  name: 'NotificationConfirmBoxItem',
  inject: {
    notificationsComponents: $NotificationsComponents
  }
}, ({
  notificationsComponents
}) => {
  const NotificationConfirmBox = notificationsComponents.NotificationConfirmBox;
  return <NotificationConfirmBox tracking={notificationBoxTracking} />;
});
const adPaddingVertical = 16;

// tslint:disable-next-line: max-file-line-count
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol placeItems-center p-small ";
const _c3 = " Knu-FlexCol ";