import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { GenericModal, Spacer } from '@shared/components';
import { $ScreenService } from '@knuddels-app/Screen';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { observable, action } from '@knuddels-app/mobx';
import { Text } from '@knuddels/component-library';
import pic from '../assets/payment_error.gif';
export interface PaymentUserNotInChannelModalProps {
  onSuccess(): Promise<void>;
  onCancel(): void;
}
@injectable()
class PaymentUserNotInChannelModalModel {
  @observable
  private _waitingForClose = false;
  constructor(@injectProps()
  private readonly props: PaymentUserNotInChannelModalProps, @inject($ScreenService)
  private screenService: typeof $ScreenService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  public get waitingForClose(): boolean {
    return this._waitingForClose;
  }
  public get maxWidth(): number {
    return this.screenService.isStackedLayout ? 312 : 360;
  }
  public hideModal = () => {
    this.props.onCancel();
    this.thisVisibleOverlay.dispose();
  };
  @action.bound
  public async handleSuccess(): Promise<void> {
    this._waitingForClose = true;
    await this.props.onSuccess();
    this.thisVisibleOverlay.dispose();
  }
}
export const PaymentUserNotInChannelModal = injectedComponent({
  name: 'PaymentUserNotInChannelModal',
  model: PaymentUserNotInChannelModalModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<PaymentUserNotInChannelModalProps>()
}, ({
  model,
  i18n
}) => <GenericModal headline={i18n.format(declareFormat({
  id: 'payment.notinchannelmodal.header',
  defaultFormat: 'Snap, something went wrong.'
}))} alignCenter primaryButton={{
  text: i18n.format(declareFormat({
    id: 'payment.notinchannelmodal.button.yes',
    defaultFormat: 'Yes, please!'
  })),
  onClick: model.handleSuccess,
  width: 'fluid',
  spinning: model.waitingForClose
}} secondaryButton={{
  text: i18n.format(declareFormat({
    id: 'payment.notinchannelmodal.button.cancel',
    defaultFormat: 'Cancel'
  })),
  onClick: model.hideModal,
  disabled: model.waitingForClose
}} cancelModal={model.hideModal} maxWidth={model.maxWidth} pictureProps={{
  source: pic,
  size: 53,
  sizingMode: 'autoHeight',
  borderType: 'normal'
}}>
			<Spacer size={'base'} />
			<Text state={'tertiary'} className={_c0}>
				{i18n.format(declareFormat({
      id: 'payment.notinchannelmodal.subheader',
      defaultFormat: "You may only start the buying process, if you're currently in a channel. We can connect you with your most recent channel and start the process again."
    }))}
			</Text>
			<Spacer size={'xlarge'} />
			<Spacer size={'small'} />
		</GenericModal>);
const _c0 = " textAlign-center ";