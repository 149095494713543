import { resolveVariant as c } from "../../render/utils/resolve-dynamic-variants.js";
import { animateTarget as p } from "./visual-element-target.js";
import { animateVariant as e } from "./visual-element-variant.js";
function n(a, r, t = {}) {
  a.notify("AnimationStart", r);
  let m;
  if (Array.isArray(r)) {
    const o = r.map((y) => e(a, y, t));
    m = Promise.all(o);
  } else if (typeof r == "string")
    m = e(a, r, t);
  else {
    const o = typeof r == "function" ? c(a, r, t.custom) : r;
    m = Promise.all(p(a, o, t));
  }
  return m.then(() => {
    a.notify("AnimationComplete", r);
  });
}
export {
  n as animateVisualElement
};
