import { ContactsTab } from './ContactTabs';
import { PlaceholderContent } from '@shared/components';
import { declareFormat, FormatId, FormattedMessage } from '@knuddels-app/i18n';
import * as React from 'react';
export function EmptyContactsPlaceholder(props: {
  tab?: ContactsTab;
}): JSX.Element {
  return <PlaceholderContent description={<FormattedMessage id={declareFormat({
    id: 'contacts.empty.description',
    defaultFormat: 'Chat in a channel or play Fotomeet to make new contacts.'
  })} />} title={<FormattedMessage id={getPlaceholderTitleFormat(props.tab)} />} image={require('@shared/icons/sm_17-04-Columbus_ani.gif')} imageWidth={69} imageHeight={63} />;
}
function getPlaceholderTitleFormat(tab: ContactsTab | undefined): FormatId {
  const defaultFormat = declareFormat({
    id: 'contacts.empty.title',
    defaultFormat: 'No contacts in sight!'
  });
  if (!tab) {
    return defaultFormat;
  }
  switch (tab) {
    case 'friends':
      return declareFormat({
        id: 'contacts.empty.friends.title',
        defaultFormat: 'No friends in sight!'
      });
    case 'fotomeet':
      return declareFormat({
        id: 'contacts.empty.fotomeet.title',
        defaultFormat: 'No matches in sight!'
      });
    case 'mentees':
      return declareFormat({
        id: 'contacts.empty.mentees.title',
        defaultFormat: 'No mentees in sight!'
      });
    default:
      return defaultFormat;
  }
}