import * as React from 'react';
import { Gender } from '@generated/graphql';
import { IconMale } from '@shared/icons/IconMale';
import { IconFemale } from '@shared/icons/IconFemale';
import { IconNonbinary } from '@shared/icons/IconNonbinary';
export function GenderIcon(props: {
  gender: Gender;
}): React.ReactElement | null {
  switch (props.gender) {
    case Gender.Female:
      return <IconFemale size="base" />;
    case Gender.Male:
      return <IconMale size="base" />;
    case Gender.NonbinaryHe:
    case Gender.NonbinaryShe:
      return <IconNonbinary size="base" />;
    default:
      return null;
  }
}