import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { SettingsListItem } from '../SettingsListItem';
import { IconExternal, IconHeart, ListGroup } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import { IconHelp } from './IconHelp';
import { IconChangelog } from '@shared/icons/IconChangelog';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
const HELPCENTER_SLASH_COMMAND = '/helpcenter';
const CHANGELOG_SLASH_COMMAND = '/changelog';
const UPVOTY_SLASH_COMMAND = '/featurerequest open';
export const SupportSettings: React.FC = observer('SupportSettings', () => {
  const i18n = useService($I18n);
  const snackbarService = useService($SnackbarService);
  const execute = useSlashCommandWithoutChannel();
  const track = useLogEvent();
  const openHelpCenter = () => {
    track('Settings_Overview', 'Help_Clicked');
    execute(HELPCENTER_SLASH_COMMAND).catch(() => {
      snackbarService.showGenericError();
    });
  };
  const openChangelog = () => {
    track('Settings_Overview', 'Changelog_Clicked');
    execute(CHANGELOG_SLASH_COMMAND).catch(() => {
      snackbarService.showGenericError();
    });
  };
  const openUpvoty = () => {
    track('Settings_Overview', 'Feedback_Clicked');
    execute(UPVOTY_SLASH_COMMAND).catch(() => {
      snackbarService.showGenericError();
    });
  };
  return <ListGroup title={declareFormat({
    id: 'settings.group.support',
    defaultFormat: 'Support'
  }).format(i18n)}>
			<SettingsListItem iconLeft={<IconHelp />} iconRight={<IconExternal />} caption={declareFormat({
      id: 'settings.help.title',
      defaultFormat: 'Help'
    }).format(i18n)} onPress={openHelpCenter} />
			<SettingsListItem iconLeft={<IconHeart />} iconRight={<IconExternal />} caption={declareFormat({
      id: 'settings.feedback.title',
      defaultFormat: 'Feedback'
    }).format(i18n)} onPress={openUpvoty} />
			<SettingsListItem iconLeft={<IconChangelog />} iconRight={<IconExternal />} caption={declareFormat({
      id: 'settings.changelog.title',
      defaultFormat: 'Changelog'
    }).format(i18n)} onPress={openChangelog} />
		</ListGroup>;
});