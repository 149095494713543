import {
	Children,
	CommunityStatus,
	Country,
	RelationshipStatus,
	SexualOrientation,
	Smoker,
} from '@generated/graphql';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';

export function translateRelationshipStatus(
	relationshipStatus: RelationshipStatus
): string {
	switch (relationshipStatus) {
		case RelationshipStatus.Solo:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_SOLO',
					defaultFormat: 'Solo',
				})
			);
		case RelationshipStatus.HappilySolo:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_HAPPILY_SOLO',
					defaultFormat: 'Happily solo',
				})
			);
		case RelationshipStatus.InLove:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_IN_LOVE',
					defaultFormat: 'In love',
				})
			);
		case RelationshipStatus.UnhappilyInLove:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_UNHAPPILY_IN_LOVE',
					defaultFormat: 'Unhappily in love',
				})
			);
		case RelationshipStatus.Taken:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_TAKEN',
					defaultFormat: 'Taken',
				})
			);
		case RelationshipStatus.HappilyTaken:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_HAPPILY_TAKEN',
					defaultFormat: 'Happily taken',
				})
			);
		case RelationshipStatus.Engaged:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_ENGAGED',
					defaultFormat: 'Engaged',
				})
			);
		case RelationshipStatus.Married:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_MARRIED',
					defaultFormat: 'Married',
				})
			);
		case RelationshipStatus.HappilyMarried:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_HAPPILY_MARRIED',
					defaultFormat: 'Happily married',
				})
			);
		case RelationshipStatus.Divorced:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_DIVORCED',
					defaultFormat: 'Divorced',
				})
			);
		case RelationshipStatus.HappilyDivorced:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_HAPPILY_DIVORCED',
					defaultFormat: 'Happily divorced',
				})
			);
		case RelationshipStatus.RelationallyDisturbed:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_RELATIONALLY_DISTURBED',
					defaultFormat: 'Relationally disturbed',
				})
			);
		case RelationshipStatus.IGoToTheMonastery:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_I_GO_TO_THE_MONASTERY',
					defaultFormat: 'I go to the monastery',
				})
			);
		case RelationshipStatus.OpenRelationship:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_OPEN_RELATIONSHIP',
					defaultFormat: 'Open relationship',
				})
			);
		case RelationshipStatus.HappilyInLove:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_HAPPILY_IN_LOVE',
					defaultFormat: 'Happily in love',
				})
			);
		case RelationshipStatus.InSearchOf:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_IN_SEARCH_OF',
					defaultFormat: 'In search of',
				})
			);
		case RelationshipStatus.InHogHeaven:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_IN_HOG_HEAVEN',
					defaultFormat: 'In hog heaven',
				})
			);
		case RelationshipStatus.JustSeparated:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_JUST_SEPARATED',
					defaultFormat: 'Just separated',
				})
			);
		case RelationshipStatus.LivingSeparated:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_LIVING_SEPARATED',
					defaultFormat: 'Living separated',
				})
			);
		case RelationshipStatus.SingleParent:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_SINGLE_PARENT',
					defaultFormat: 'Single parent',
				})
			);
		case RelationshipStatus.TotallyOpenMinded:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_TOTALLY_OPEN_MINDED',
					defaultFormat: 'Totally open minded',
				})
			);
		case RelationshipStatus.ForeverAlone:
			return formatMessage(
				declareFormat({
					id: 'RELATIONSHIP_STATUS_FOREVER_ALONE',
					defaultFormat: 'Forever alone',
				})
			);
		case RelationshipStatus.Unknown:
			return '';
		default:
			// Don't throw here or we will block backend updates to add more options.
			// Check with never to get type/compile errors. We don't want to throw warnings
			// with `shouldBeUnreachable` because it is called frequently in render.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const checkTypeToNotForgetUpdatingThis: never = relationshipStatus;
			return '';
	}
}

export function translateSexualOrientation(
	sexualOrientation: SexualOrientation
): string {
	switch (sexualOrientation) {
		case SexualOrientation.Bi:
			return formatMessage(
				declareFormat({
					id: 'SEXUAL_ORIENTATION_BI',
					defaultFormat: 'bisexual',
				})
			);
		case SexualOrientation.Heterosexual:
			return formatMessage(
				declareFormat({
					id: 'SEXUAL_ORIENTATION_HETERO',
					defaultFormat: 'heterosexual',
				})
			);
		case SexualOrientation.Homosexual:
			return formatMessage(
				declareFormat({
					id: 'SEXUAL_ORIENTATION_HOMO',
					defaultFormat: 'homosexual',
				})
			);
		case SexualOrientation.Other:
			return formatMessage(
				declareFormat({
					id: 'SEXUAL_ORIENTATION_OTHER',
					defaultFormat: 'other',
				})
			);
		case SexualOrientation.Transgender:
			return formatMessage(
				declareFormat({
					id: 'SEXUAL_ORIENTATION_TRANSGENDER',
					defaultFormat: 'transgender',
				})
			);
		case SexualOrientation.Undecided:
			return formatMessage(
				declareFormat({
					id: 'SEXUAL_ORIENTATION_UNDECIDED',
					defaultFormat: 'undecided',
				})
			);
		case SexualOrientation.Unknown:
			return '';
		default:
			// Don't throw here or we will block backend updates to add more options.
			// Check with never to get type/compile errors. We don't want to throw warnings
			// with `shouldBeUnreachable` because it is called frequently in render.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const checkTypeToNotForgetUpdatingThis: never = sexualOrientation;
			return '';
	}
}

export function translateChildren(children: Children): string {
	switch (children) {
		case Children.ALot:
			return formatMessage(
				declareFormat({ id: 'CHILDREN_A_LOT', defaultFormat: 'a lot' })
			);
		case Children.ByAllMeans:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_BY_ALL_MEANS',
					defaultFormat: 'by all means',
				})
			);
		case Children.Existing:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_EXISTING',
					defaultFormat: 'existing',
				})
			);
		case Children.ExtendedFamily:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_EXTENDED_FAMILY',
					defaultFormat: 'extended family',
				})
			);
		case Children.InProgress:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_IN_PRGORESS',
					defaultFormat: 'in progress',
				})
			);
		case Children.MaybeLater:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_MAYBE_LATER',
					defaultFormat: 'maybe later',
				})
			);
		case Children.NoThanks:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_NO_THANKS',
					defaultFormat: 'no thanks',
				})
			);
		case Children.One:
			return formatMessage(
				declareFormat({ id: 'CHILDREN_ONE', defaultFormat: 'one' })
			);
		case Children.PatchworkFamily:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_PATCHWORK_FAMILY',
					defaultFormat: 'patchwork family',
				})
			);
		case Children.Planned:
			return formatMessage(
				declareFormat({
					id: 'CHILDREN_PLANNED',
					defaultFormat: 'planned',
				})
			);
		case Children.Three:
			return formatMessage(
				declareFormat({ id: 'CHILDREN_THREE', defaultFormat: 'three' })
			);
		case Children.Two:
			return formatMessage(
				declareFormat({ id: 'CHILDREN_TWO', defaultFormat: 'two' })
			);
		case Children.Zero:
			return formatMessage(
				declareFormat({ id: 'CHILDREN_ZERO', defaultFormat: 'none' })
			);
		default:
			// Don't throw here or we will block backend updates to add more options.
			// Check with never to get type/compile errors. We don't want to throw warnings
			// with `shouldBeUnreachable` because it is called frequently in render.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const checkTypeToNotForgetUpdatingThis: never = children;
			return '';
	}
}

export function translateCountry(country: Country): string {
	switch (country) {
		case Country.Germany:
			return formatMessage(
				declareFormat({
					id: 'profile.country.germany',
					defaultFormat: 'Germany',
				})
			);
		case Country.Austria:
			return formatMessage(
				declareFormat({
					id: 'profile.country.austria',
					defaultFormat: 'Austria',
				})
			);
		case Country.Switzerland:
			return formatMessage(
				declareFormat({
					id: 'profile.country.switzerland',
					defaultFormat: 'Switzerland',
				})
			);
		default:
			// Don't throw here or we will block backend updates to add more options.
			// Check with never to get type/compile errors. We don't want to throw warnings
			// with `shouldBeUnreachable` because it is called frequently in render.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const checkTypeToNotForgetUpdatingThis: never = country;
			return '';
	}
}

export function translateSmoker(smoker: Smoker): string {
	switch (smoker) {
		case Smoker.No:
			return formatMessage(
				declareFormat({ id: 'SMOKER_NO', defaultFormat: 'No' })
			);
		case Smoker.Occasional:
			return formatMessage(
				declareFormat({
					id: 'SMOKER_OCCASIONAL',
					defaultFormat: 'occasional',
				})
			);
		case Smoker.Quit:
			return formatMessage(
				declareFormat({ id: 'SMOKER_QUIT', defaultFormat: 'quit' })
			);
		case Smoker.Yes:
			return formatMessage(
				declareFormat({ id: 'SMOKER_YES', defaultFormat: 'No' })
			);
		default:
			// Don't throw here or we will block backend updates to add more options.
			// Check with never to get type/compile errors. We don't want to throw warnings
			// with `shouldBeUnreachable` because it is called frequently in render.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const checkTypeToNotForgetUpdatingThis: never = smoker;
			return '';
	}
}

export function translateCommunityStatus(status: CommunityStatus): string {
	switch (status) {
		case CommunityStatus.Admin:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_ADMIN',
					defaultFormat: 'Admin',
				})
			);
		case CommunityStatus.Family:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_FAMILY',
					defaultFormat: 'Familymember',
				})
			);
		case CommunityStatus.Honor:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_HONOR',
					defaultFormat: 'Honor member',
				})
			);
		case CommunityStatus.Normal:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_NORMAL',
					defaultFormat: 'Member',
				})
			);
		case CommunityStatus.Stammi:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_STAMMI',
					defaultFormat: 'Stammi',
				})
			);
		case CommunityStatus.Sysadmin:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_SYSADMIN',
					defaultFormat: 'Sysadmin',
				})
			);
		case CommunityStatus.UnofficialAdmin:
			return formatMessage(
				declareFormat({
					id: 'COMMUNITY_STATUS_UNOFFICIAL_ADMIN',
					defaultFormat: 'Unoffical Admin',
				})
			);
		default:
			// Don't throw here or we will block backend updates to add more options.
			// Check with never to get type/compile errors. We don't want to throw warnings
			// with `shouldBeUnreachable` because it is called frequently in render.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
			const checkTypeToNotForgetUpdatingThis: never = status;
			return '';
	}
}
