import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $UserService } from '@knuddelsModules/UserData';
import { action, observable, runInAction } from '@knuddels-app/mobx';

import classicYellowGif from '../assets/sm_classic_yellow.gif';
import knockGif from '../assets/kpurchase_ani_000_knock.gif';
import sadGif from '../assets/kpurchase_ani_000_sad.gif';
import bigHelloGif from '../assets/kpurchase_ani_049_bighello.gif';
import summerSaultGif from '../assets/kpurchase_ani_050_somersault.gif';

export type AnimatedIconData = {
	icon: any;
	width: number;
	height: number;
	translateX?: number;
	translateY?: number;
};

const icons = {
	default: {
		icon: classicYellowGif,
		width: 17,
		height: 13,
	},
	zeroKnuddel: [
		{
			icon: knockGif,
			width: 24,
			height: 17,
			translateX: 3,
			translateY: -1,
		},
		{
			icon: sadGif,
			width: 17,
			height: 14,
		},
	],
	some: {
		icon: bigHelloGif,
		translateY: -3,
		width: 45,
		height: 20,
	},
	many: {
		icon: summerSaultGif,
		width: 19,
		height: 46,
	},
};

@injectable()
export class KnuddelDisplayAnimationService {
	public readonly dispose = Disposable.fn();

	@observable
	private _currentIcon: AnimatedIconData = icons.default;
	private lastZeroKnuddelIconIndex = 0;
	private currentTimeout: any | undefined;

	constructor(
		@inject($UserService)
		private readonly userService: typeof $UserService.T
	) {
		this.dispose.track(() => {
			if (this.currentTimeout) {
				clearTimeout(this.currentTimeout);
				this.currentTimeout = undefined;
			}
		});

		this.updateIcon();
	}

	public get currentIcon(): any {
		return this._currentIcon;
	}

	@action.bound
	private updateIcon(): void {
		this._currentIcon = this.getIcon();

		this.currentTimeout = setTimeout(() => {
			// first reset to default
			runInAction(
				'reset icon',
				() => (this._currentIcon = icons.default)
			);

			// then start timer to update it again
			// we need to do it this way, so we can restart a gif
			const timeoutMillis = 5000 + Math.round(Math.random() * 45000);
			this.currentTimeout = setTimeout(() => {
				this.updateIcon();
			}, timeoutMillis);
		}, 10000);
	}

	private getIcon(): AnimatedIconData {
		const user = this.userService.currentUser;
		if (!user) {
			return icons.default;
		}

		const knuddelAmount = user.knuddelAmount;
		if (knuddelAmount === undefined || knuddelAmount === null) {
			return icons.default;
		}

		if (knuddelAmount === 0) {
			this.lastZeroKnuddelIconIndex =
				(this.lastZeroKnuddelIconIndex + 1) % icons.zeroKnuddel.length;
			return icons.zeroKnuddel[this.lastZeroKnuddelIconIndex];
		}

		if (knuddelAmount < 50) {
			return icons.some;
		} else {
			return icons.many;
		}
	}
}
