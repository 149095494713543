import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { ContextMenuEntry } from '@shared/components';
import * as React from 'react';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
import { IconEdit } from '@knuddels/component-library';
export const EditDescriptionContextMenuEntry: React.FC<{
  startEditingDescription: () => void;
}> = ({
  startEditingDescription
}) => {
  const closeContextMenu = useCloseThisOverlay();
  const track = useTrackAlbumDetails();
  return <ContextMenuEntry icon={IconEdit} text={formatMessage(declareFormat({
    id: 'album.details.change-photo-description',
    defaultFormat: 'Edit description'
  }))} onClick={() => {
    startEditingDescription();
    track('ChangeDescription_Clicked');
    closeContextMenu();
  }} />;
};