import * as React from 'react';
import { Flex, FlexCol, Image, Text } from '@knuddels/component-library';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
export class ProfileNoDetailsAvailable extends React.PureComponent {
  render(): React.ReactNode {
    return <div className={_c0}>
				<div className={_c1}>
					<Image src={require('../../../assets/profile-placeholder.png')} alt={''} className={_c2} />
				</div>
				<div className={_c3}>
					<Text type={'body2'} state={'tertiary'} bold={true} className={_c4}>
						{formatMessage(declareFormat({
            id: 'PROFILE_NO_DETAILS_AVAILABLE',
            defaultFormat: 'There are no details for this user yet.'
          }))}
					</Text>
				</div>
			</div>;
  }
}
const _c0 = " Knu-FlexCol flex-1 py-xxlarge minHeight-124px alignItems-center ";
const _c1 = " Knu-Flex opacity-0-66 mb-minor ";
const _c2 = " width-82px height-41px ";
const _c3 = " Knu-Flex alignItems-center ";
const _c4 = "  ";