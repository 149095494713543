import * as React from 'react';
import { ChooseContactEntry, OnChangeHandler } from './ChooseContactEntry';
import { BasicUser } from '../../utils/BasicUser';
import { VirtualList, VirtualListItem, VirtualListRenderItemInfo } from '@knuddels/component-library';
interface ChooseContactVirtualListProps {
  contacts: BasicUser[];
  selectedContacts: BasicUser[];
  onChange: OnChangeHandler;
}
interface ChooseContactItem extends VirtualListItem {
  user: BasicUser;
  selected: boolean;
  handleChange: OnChangeHandler;
}
export class ChooseContactVirtualList extends React.PureComponent<ChooseContactVirtualListProps> {
  render(): JSX.Element {
    return <VirtualList data={this.createItemList()} renderItem={this.renderItem} className={_c0} />;
  }
  private readonly createItemList = (): ChooseContactItem[] => {
    return this.props.contacts.map(user => ({
      template: 'entry',
      key: `${user.id}`,
      height: 53,
      user,
      selected: this.props.selectedContacts.some(it => it.id === user.id),
      handleChange: this.props.onChange
    }));
  };
  private readonly renderItem = ({
    item: info
  }: VirtualListRenderItemInfo<ChooseContactItem>): JSX.Element => {
    const {
      user,
      selected,
      handleChange
    } = info;
    return <ChooseContactEntry user={user} selected={selected} onChange={handleChange} />;
  };
}
const _c0 = "  ";