import { DisposableLike } from '@knuddels/std';
import { DeepLink } from './DeepLinkingService';
import { DeepLinkType } from '../../DeeplinkTypes';
import { $Environment } from '@knuddels-app/Environment';
import { getAndRemoveSearchParam } from '@knuddels-app/tools/getAndRemoveSearchParam';

export async function setupDeepLinking(
	environment: typeof $Environment.T,
	onDeepLink: (link: DeepLink) => void
): Promise<DisposableLike> {
	if (globalEnv.product !== 'app') {
		return () => {};
	}

	const target = getAndRemoveSearchParam(environment, 'app');
	if (target) {
		setTimeout(() => {
			onDeepLink({
				type: DeepLinkType.App,
				target,
			});
		}, 1000);
	}

	return () => {};
}
