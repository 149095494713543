import * as React from 'react';
import { ChildThemeProvider, Flex, FlexCol, Text, useChildTheme, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $MessengerConversationService, $MessengerListService, messengerViewId } from '@knuddelsModules/Messenger';
import { declareFormat } from '@knuddels/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { $I18n } from '@knuddels-app/i18n';
import { MessengerListSkeleton } from '../Skeleton/MessengerListSkeleton';
import { getMessengerListEntryHeight, MessengerListEntry } from './MessengerListEntry';
import { createConversationListItem } from './createConversationListItem';
import { ConversationVirtualListViewItemInfo } from './MessengerOverviewVirtualListTypes';
import { observer } from '@knuddels-app/mobx';
import { $OverlayService } from '@knuddels-app/overlays';
import { MessengerConversation } from '@generated/graphql';
import { ConversationContextMenuOverlay } from '../ContextMenus';
import { CondensedButton, SwipeContextProvider } from '@shared/components';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export const DashboardMessengerListElement: React.FC<{
  layout: 'desktop' | 'mobile';
}> = observer('DashboardMessengerListElement', ({
  layout
}) => {
  const viewService = useService($ViewService);
  const i18n = useService($I18n);
  const {
    items,
    fetchStatus
  } = useItems();
  const renderItem = useRenderItem(items.length);
  const track = useLogEvent();
  const currentTheme = useTheme();
  const theme = useChildTheme({
    colors: {
      basic: {
        contentBg: currentTheme.colors.basic.contentLightBg
      }
    }
  }, [currentTheme.colors.basic.contentLightBg]);
  return <div className={_c0}>
			<div className={_c1}>
				<Text type={'body2'} state={'tertiary'} bold={true} className={_c2}>
					{declareFormat({
          id: 'dashboard.messenger.title',
          defaultFormat: 'Your messages'
        }).format(i18n).toUpperCase()}
				</Text>
			</div>
			{fetchStatus === 'initiallyFetching' ? <MessengerListSkeleton amount={5} /> : <ChildThemeProvider theme={theme}>
					<SwipeContextProvider>
						<div className={_c3 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (layout === 'desktop' ? _c4 : _c5) + (layout === 'desktop' ? _c6 : _c7)}>
							{items.map(renderItem)}
						</div>
					</SwipeContextProvider>
				</ChildThemeProvider>}
			<div className={_c8}>
				<CondensedButton onPress={() => {
        viewService.openView(messengerViewId);
        track('Dashboard_Messages', 'OpenMessenger_Clicked');
      }} text={declareFormat({
        id: 'dashboard.messenger.button',
        defaultFormat: 'Open messenger'
      }).format(i18n)} />
			</div>
		</div>;
});
const useItems = () => {
  const messengerConversationService = useService($MessengerConversationService);
  const messengerListService = useService($MessengerListService);
  const conversations = messengerListService.overviewConversations;
  const itemHeight = getMessengerListEntryHeight(false, false);
  const items = conversations.slice(0, 5).map((item, index) => createConversationListItem(messengerConversationService, item, index, itemHeight));
  const query = messengerListService.messengerOverviewQuery;
  const fetchStatus = query.fetchStatus;
  return {
    items,
    fetchStatus
  };
};
const useContextMenuConversation = () => {
  const overlayService = useService($OverlayService);
  const overlay = overlayService.findOverlay(ConversationContextMenuOverlay.getFilter());
  return overlay && overlay.tag.data.conversationId;
};
const useRenderItem = (itemCount: number) => {
  const openContextMenu = useOpenContextMenu();
  const contextMenuConversation = useContextMenuConversation();
  const viewService = useService($ViewService);
  const track = useLogEvent();
  const openConversation = React.useCallback((conversationId: string) => {
    viewService.openViewAsOverlay(messengerViewId.with(state => state.withConversation({
      conversationId,
      initialView: true
    })));
    track('Dashboard_Messages', 'Conversation_Clicked');
  }, []);
  return React.useCallback((item: ConversationVirtualListViewItemInfo, index: number) => {
    const conversationPartner = item.conversation.otherParticipants[0];
    return <MessengerListEntry {...item} key={index} isActive={false} isArchive={false} isMinifiedOverview={false} conversationPartner={conversationPartner} openConversation={openConversation} showContextMenu={openContextMenu} isContextMenuOpen={item.conversation.id === contextMenuConversation} hideDivider={index === itemCount - 1} />;
  }, [itemCount, contextMenuConversation]);
};
const useOpenContextMenu = () => {
  const overlayService = useService($OverlayService);
  return React.useCallback((x: number, y: number, width: number, height: number, conversationId: MessengerConversation['id']) => {
    overlayService.showOverlayIfNotVisible(ConversationContextMenuOverlay, {
      conversationId,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  }, []);
};
const _c0 = " Knu-FlexCol width-full minWidth-0-px maxWidth-100-percent ";
const _c1 = " Knu-Flex ml-base mb-minor mt-base ";
const _c2 = "  ";
const _c3 = " Knu-FlexCol shadow-Shadow1 flexGrow-0 overflow-hidden bg-contentLightBg ";
const _c4 = " borderRadius-base ";
const _c5 = " borderRadius-none ";
const _c6 = " py-small ";
const _c7 = " py-none ";
const _c8 = " Knu-Flex alignSelf-center mt-minor ";