import { $ViewProvider } from '@knuddels-app/layout';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { $NavBarItemProvider } from './extensionPoints';
import { NavigationViewProviderImpl } from './NavigationViewProviderImpl';
import * as providedServiceIds from './providedServiceIds';
import { $NavBarStateStore } from './providedServiceIds';
import { LoggedInAreaNavBarItemProvider } from './LoggedInAreaNavBarItemProvider';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '@knuddels-app/AppLoadingService';
import { promotionModule } from '@knuddelsModules/Promotions/module';
import { DownloadViewProvider } from './DownloadViewProvider';
import { settingsModule } from '@knuddelsModules/Settings/module';

export const loggedInAreaModule = new Module({
	name: 'loggedInArea module',
	dependencies:
		globalEnv.product === 'stapp-messenger'
			? [userDataModule]
			: [userDataModule, promotionModule, settingsModule],
	providedServices: providedServiceIds,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(NavigationViewProviderImpl);

		ctx.globalScope.bind($ViewProvider).toSingleton(DownloadViewProvider);

		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toSingleton(LoggedInAreaNavBarItemProvider);

		ctx.globalScope
			.bind($AppLoadingProvider)
			.toConstant(
				simpleAppLoadingProvider(
					$NavBarStateStore,
					service => service.deferredReady.promise
				)
			);
	},
});
