import * as React from 'react';
import { EditProfileEntry, EditProfileEntryBaseProps } from './EditProfileEntry';
import { declareProps, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Input } from '@knuddels/component-library';
import { useScrollIntoView } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfile/EditProfileContent/Entry/useScrollIntoView';
type Props = EditProfileEntryBaseProps & {
  valueString: string;
};
@injectable()
class EditProfileEntryStringListModel {
  constructor(@injectProps()
  private readonly props: Props) {}
  public onChangeText = (newText: string): void => {
    this.props.onValueChanged(this.props.field, newText);
  };
}
export const EditProfileEntryStringList = injectedComponent({
  name: 'EditProfileEntryStringList',
  model: EditProfileEntryStringListModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<Props>()
}, ({
  model,
  i18n,
  image,
  title,
  validity,
  helperText,
  valueString
}) => {
  const {
    ref,
    onFocus
  } = useScrollIntoView();
  return <EditProfileEntry image={image} valid={validity.type === 'valid'} innerRef={ref}>
				<Input label={title} labelStyle={'always-on-top'} error={validity.errorText || validity.type === 'invalid'} description={helperText} placeholder={declareFormat({
      id: 'STRING_LIST_PLACEHOLDER',
      defaultFormat: 'Not specified'
    }).format(i18n)} value={valueString} onChange={({
      value
    }) => {
      model.onChangeText(value);
    }} onFocus={onFocus} />
			</EditProfileEntry>;
});