import { useMotionValue as a } from "./use-motion-value.js";
import { useIsomorphicLayoutEffect as c } from "../utils/use-isomorphic-effect.js";
import { cancelFrame as m, frame as p } from "../frameloop/frame.js";
function d(n, t) {
  const r = a(t()), e = () => r.set(t());
  return e(), c(() => {
    const s = () => p.preRender(e, !1, !0), u = n.map((o) => o.on("change", s));
    return () => {
      u.forEach((o) => o()), m(e);
    };
  }), r;
}
export {
  d as useCombineMotionValues
};
