import { camelToDash as i } from "../../dom/utils/camel-to-dash.js";
import { renderHTML as m } from "../../html/utils/render.js";
import { camelCaseAttributes as e } from "./camel-case-attrs.js";
function c(o, t, f, s) {
  m(o, t, void 0, s);
  for (const r in t.attrs)
    o.setAttribute(e.has(r) ? r : i(r), t.attrs[r]);
}
export {
  c as renderSVG
};
