import * as React from 'react';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { Button, ChildThemeProvider, DarkTheme, Flex, FlexCol, Image, rgb, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { conversationImagesEvent } from '../../../../analytics';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $MessengerImageService } from '../../../../../providedServices';
import { BasicUser } from '../../../../utils/BasicUser';
import { BlurredBackgroundImage } from '@shared/components';
import { isNative } from '@knuddels-app/tools/isNative';
import { defaultBlurredImage } from './defaultBlurredImage';
import icon from './sm_abo_18-10_quokka.gif';
interface Props {
  user: BasicUser;
  size: number;
  url?: string;
  snap?: boolean;
}
@injectable()
class ImagePermissionModel {
  constructor(@injectProps()
  private readonly props: Props, @inject($MessengerImageService)
  private readonly messengerImageService: typeof $MessengerImageService.T) {}
  public allowImages = (): void => {
    conversationImagesEvent.track('Images_PermissenGranted');
    this.messengerImageService.allowImages(this.props.user);
  };
}
export const ImagePermission = injectedComponent({
  name: 'ImagePermission',
  model: ImagePermissionModel,
  props: declareProps<Props>()
}, ({
  model,
  user,
  size,
  url,
  snap
}) => <div style={{
  background: resolveThemingValue(rgb(75, 75, 75), "colors", useTheme()),
  height: resolveThemingValue(size, "sizes", useTheme()),
  maxWidth: resolveThemingValue(size, "sizes", useTheme())
}} className={_c0 + (rgb(75, 75, 75) ? resolveIsDarkColor(rgb(75, 75, 75), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<BlurredBackgroundImage source={url || defaultBlurredImage} blurRadius={isNative() ? 100 : 40} />
			<div className={_c1 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />

			<div style={{
    width: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c2}>
				<Image src={icon} alt={''} resizeMode={'contain'} className={_c3} />

				<Text state={'primary'} type={'subtitle'} bold={true} className={_c4}>
					{getTitle(user.nick, snap)}
				</Text>
				<Text state={'secondary'} type={'body2'} bold={true} className={_c5}>
					{formatMessage(declareFormat({
        id: 'IMAGE_PERMISSION_PREVIEW_SUBTITLE',
        defaultFormat: 'Would you like to receive pictures from {name}?'
      }), {
        name: user.nick
      })}
				</Text>
				<div className={_c6}>
					<ChildThemeProvider theme={DarkTheme}>
						<Button type={'secondary'} onPress={model.allowImages}>
							{formatMessage(declareFormat({
            id: 'IMAGE_PERMISSION_BUTTON',
            defaultFormat: 'Receive pictures'
          }), {
            name: user.nick
          })}
						</Button>
					</ChildThemeProvider>
				</div>
			</div>
		</div>);
const getTitle = (name: string, snap: boolean) => {
  const formatId = snap ? declareFormat({
    id: 'SNAP_PERMISSION_PREVIEW_TITLE',
    defaultFormat: '{name} sends you a picture'
  }) : declareFormat({
    id: 'IMAGE_PERMISSION_PREVIEW_TITLE',
    defaultFormat: '{name} sends you a picture'
  });
  return formatMessage(formatId, {
    name
  });
};
const _c0 = " Knu-FlexCol cursor-pointer alignItems-center overflow-hidden position-relative ";
const _c1 = " Knu-FlexCol bg-backgroundScrim position-absolute inset-none opacity-0-66 ";
const _c2 = " Knu-FlexCol p-30px zIndex-1 height-100-percent alignItems-center justifyContent-flex-end ";
const _c3 = " width-72px height-40px ";
const _c4 = " mt-minor textAlign-center ";
const _c5 = " mt-small textAlign-center ";
const _c6 = " Knu-Flex mt-xlarge ";