import { jsxs as b, jsx as h } from "react/jsx-runtime";
import * as r from "react";
import { DropdownContent as F } from "./DropdownContent.js";
import { Input as H } from "./Input.js";
import { IconSortUp as L } from "../Icons/SortUp.js";
import { IconSortDown as R } from "../Icons/SortDown.js";
import { createKeyboardHandler as k } from "../../a11y/KeyboardManager.js";
import { isTouchDevice as V } from "../Layout/helper/isTouchDevice.js";
import { Option as B } from "./Option.js";
import { useMeasureElement as M } from "../Layout/hooks/useMeasureElement.js";
import { Z_INDEX as j } from "../configs/zindex.js";
import { FlexCol as K } from "../Layout/FlexCol.js";
const U = (o) => {
  if (!o.value)
    return "";
  if (o.optionToString)
    return o.optionToString(o.value);
  const e = r.Children.toArray(o.children).find(
    (t) => t.props.value === o.value
  );
  if (typeof (e == null ? void 0 : e.props.children) != "string")
    throw new Error(
      "Your option component does not contain a raw string. Consider using the optionToString prop of the dropdown component to format the value."
    );
  return e == null ? void 0 : e.props.children;
}, X = (o, e) => {
  switch (e.type) {
    case "OpenDropdown":
      return {
        ...o,
        open: !0,
        focused: !0,
        highlightedIndex: e.activeIndex,
        activeIndex: e.activeIndex
      };
    case "CloseDropdown":
      return {
        ...o,
        open: !1,
        focused: !1,
        highlightedIndex: -1,
        activeIndex: -1
      };
    case "Focus":
      return { ...o, focused: !0 };
    case "Blur":
      return { ...o, open: !1, focused: !1 };
    case "ActivateIndex":
      return {
        ...o,
        open: !0,
        focused: !0,
        activeIndex: e.index,
        highlightedIndex: -1
      };
    case "HighlightIndex":
      return {
        ...o,
        open: !0,
        focused: !0,
        highlightedIndex: e.index
      };
  }
  return o;
}, Y = {
  open: !1,
  focused: !1,
  highlightedIndex: -1,
  activeIndex: -1
}, oe = (o) => {
  const [e, t] = r.useReducer(
    X,
    Y
  ), s = r.useRef({}), {
    bind: w,
    getPosition: y,
    height: D,
    width: S,
    pageY: A,
    pageX: E
  } = M({ debounce: 0 }), [x, T] = r.useState(null);
  r.useLayoutEffect(() => {
    e.open && y().then((n) => {
      T(n);
    });
  }, [e.open, E, A]), r.useEffect(() => {
    e.open;
  }, [e.open]);
  const O = e.open ? L : R, { children: v, onChange: p, onBlur: l, ...C } = o, d = r.Children.toArray(v).filter((n) => (n == null ? void 0 : n.type) === B), f = d.length, I = () => d.findIndex(
    (n) => n.props.value === o.value
  );
  r.useEffect(() => {
    e.open && !V() && (s.current = {}, d.forEach((n, i) => {
      var a, m;
      const c = (o.optionToString ? o.optionToString(n.props.value) : n.props.children).toLowerCase();
      (a = s.current)[m = c[0]] ?? (a[m] = []), s.current[c[0]].push({ value: c, index: i });
    }));
  }, [d, o.optionToString, e.open]);
  const u = () => {
    l == null || l(), t({ type: "CloseDropdown" });
  }, g = (n) => {
    p == null || p({ value: n.props.value }), u();
  }, P = k().map("ArrowDown").to(() => {
    const n = (e.activeIndex + 1) % f;
    t({ type: "ActivateIndex", index: n });
  }).map("ArrowUp").to(() => {
    if (!e.open)
      return;
    const n = e.activeIndex - 1 >= 0 ? e.activeIndex - 1 : f - 1;
    t({ type: "ActivateIndex", index: n });
  }).map("Enter").to(() => {
    e.open && g(d[e.activeIndex]);
  });
  return /* @__PURE__ */ b(
    K,
    {
      width: "100%",
      ...w,
      position: "relative",
      zIndex: e.open ? j.ABOVE_MODAL + 1 : void 0,
      children: [
        /* @__PURE__ */ h(
          H,
          {
            ...C,
            value: U({
              children: v,
              value: o.value,
              optionToString: o.optionToString
            }),
            onPress: () => {
              e.open ? u() : t({
                type: "OpenDropdown",
                activeIndex: I()
              });
            },
            onFocus: () => {
              var n;
              t({ type: "Focus" }), (n = o.onFocus) == null || n.call(o);
            },
            onKeyPress: (n) => {
              const i = s.current[n.key.toLowerCase()];
              if (i) {
                const c = i.find(
                  (a) => a.index > e.activeIndex
                ) ?? i[0];
                t({
                  type: "ActivateIndex",
                  index: c.index
                });
                return;
              }
              if (n.key === "Tab") {
                u();
                return;
              }
              P.handle(n);
            },
            focused: e.focused,
            adornmentEnd: /* @__PURE__ */ h(
              O,
              {
                type: e.focused ? "highlighted" : "default",
                onPress: () => t({
                  type: "OpenDropdown",
                  activeIndex: I()
                })
              }
            ),
            hideCursor: !0,
            readonly: !0
          }
        ),
        e.open && x && /* @__PURE__ */ h(
          F,
          {
            scrollToIndex: e.activeIndex,
            maxVisibleOptions: 6,
            totalOptions: f,
            onBackdropPressed: () => {
              u();
            },
            anchorCoords: {
              width: S,
              height: D,
              ...x
            },
            children: r.Children.toArray(o.children).filter(Boolean).map((n, i) => r.cloneElement(n, {
              selected: n.props.value === o.value,
              highlighted: i === e.highlightedIndex || i === e.activeIndex,
              onHover: () => {
                t({ type: "HighlightIndex", index: i });
              },
              onPress: () => {
                g(n);
              }
            }))
          }
        )
      ]
    }
  );
};
export {
  oe as Dropdown
};
