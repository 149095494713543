function p(d) {
  let s = /* @__PURE__ */ new Set(), t = /* @__PURE__ */ new Set(), r = !1, n = !1;
  const a = /* @__PURE__ */ new WeakSet();
  let i = {
    delta: 0,
    timestamp: 0,
    isProcessing: !1
  };
  function u(e) {
    a.has(e) && (f.schedule(e), d()), e(i);
  }
  const f = {
    /**
     * Schedule a process to run on the next frame.
     */
    schedule: (e, l = !1, h = !1) => {
      const o = h && r ? s : t;
      return l && a.add(e), o.has(e) || o.add(e), e;
    },
    /**
     * Cancel the provided callback from running on the next frame.
     */
    cancel: (e) => {
      t.delete(e), a.delete(e);
    },
    /**
     * Execute all schedule callbacks.
     */
    process: (e) => {
      if (i = e, r) {
        n = !0;
        return;
      }
      r = !0, [s, t] = [t, s], t.clear(), s.forEach(u), r = !1, n && (n = !1, f.process(e));
    }
  };
  return f;
}
export {
  p as createRenderStep
};
