import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $SaveProfileService = newServiceId<services.SaveProfileService>(
	'$SaveProfileService'
);

export const $ProfileNavigationService = newServiceId<
	services.ProfileNavigationService
>('$ProfileNavigationService');

export const $AlbumPicturesService = newServiceId<
	services.AlbumPicturesService
>('$AlbumPicturesService');

export const $ProfilePictureUploadService = newServiceId<
	services.ProfilePictureUploadService
>('$ProfilePictureUploadService');

export const $ProfileService = newServiceId<services.ProfileService>(
	'$ProfileService'
);

export const $LoggedOutProfileService = newServiceId<
	services.LoggedOutProfileService
>('$LoggedOutProfileService');

export const $AlbumPhotoUploadService = newServiceId<
	services.AlbumPhotoUploadService
>('$AlbumPhotoUploadService');
