import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import {
	FullConversationWithoutMessagesFragment,
	MessengerConversation,
	MessengerConversationState,
	MessengerOverviewDocument,
	MessengerOverviewQuery,
	MessengerOverviewQueryVariables,
} from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';

export function readMessengerOverviewQuery(
	authenticatedClientService: typeof $AuthenticatedClientService.T,
	filterByState: MessengerConversationState
): MessengerOverviewQuery | undefined {
	try {
		return authenticatedClientService.currentClient.readQuery<
			MessengerOverviewQuery,
			MessengerOverviewQueryVariables
		>({
			query: MessengerOverviewDocument,
			variables: {
				limit: 10000, // ignored by @connection directive
				filterByState,
				pixelDensity: getPixelRatio(),
			},
		});
	} catch (e) {
		return undefined;
	}
}

function writeMessengerOverviewQuery(
	authenticatedClientService: typeof $AuthenticatedClientService.T,
	currentQuery: MessengerOverviewQuery,
	conversations: readonly FullConversationWithoutMessagesFragment[],
	filterByState: MessengerConversationState
): void {
	authenticatedClientService.currentClient.writeQuery<
		MessengerOverviewQuery,
		MessengerOverviewQueryVariables
	>({
		query: MessengerOverviewDocument,
		variables: {
			limit: 1000, // ignored by @connection directive
			filterByState: filterByState,
			pixelDensity: getPixelRatio(),
		},
		data: {
			...currentQuery,
			messenger: {
				...currentQuery.messenger,
				conversations: {
					...currentQuery.messenger.conversations,
					conversations: conversations,
				},
			},
		},
	});
}

function updateMessengerOverviewConversations(
	authenticatedClientService: typeof $AuthenticatedClientService.T,
	filterByState: MessengerConversationState,
	mapper: (
		currentConversations: readonly FullConversationWithoutMessagesFragment[]
	) => readonly FullConversationWithoutMessagesFragment[]
): void {
	const result = readMessengerOverviewQuery(
		authenticatedClientService,
		filterByState
	);
	const currentConversations =
		result?.messenger?.conversations?.conversations;
	if (!currentConversations) {
		return;
	}

	const newConversations = mapper(currentConversations);
	writeMessengerOverviewQuery(
		authenticatedClientService,
		result,
		newConversations,
		filterByState
	);
}

export function addConversationToOverviewList(
	authenticatedClientService: typeof $AuthenticatedClientService.T,
	conversation: FullConversationWithoutMessagesFragment,
	to: MessengerConversationState,
	replaceExisting = false
): void {
	updateMessengerOverviewConversations(
		authenticatedClientService,
		to,
		conversations => {
			if (replaceExisting) {
				return [
					...conversations.filter(it => it.id !== conversation.id),
					conversation,
				];
			}

			const index = conversations.findIndex(
				it => it.id === conversation.id
			);
			if (index >= 0) {
				return conversations;
			} else {
				return [...conversations, conversation];
			}
		}
	);
}

export function removeConversationFromOverviewList(
	authenticatedClientService: typeof $AuthenticatedClientService.T,
	id: MessengerConversation['id'],
	from: MessengerConversationState
): void {
	updateMessengerOverviewConversations(
		authenticatedClientService,
		from,
		conversations => {
			const index = conversations.findIndex(it => it.id === id);
			if (index >= 0) {
				const newConversations = [...conversations];
				newConversations.splice(index, 1);
				return newConversations;
			} else {
				return conversations;
			}
		}
	);
}

export function removeAllConversationsFromCache(
	authenticatedClientService: typeof $AuthenticatedClientService.T
): void {
	// This is a bit hacky, but apollo does not provide an api to delete individual
	// cache entries.
	const cache = authenticatedClientService.currentClient.cache as any;
	Object.keys(cache.data.data).forEach(key => {
		if (
			key.startsWith('MessengerConversation:') ||
			key.startsWith('$MessengerConversation:')
		) {
			cache.data.delete(key);
		}
	});
}
