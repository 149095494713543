import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands/serviceIds';
import { $JoinChannelService } from '../../../providedServiceIds';
import { Disposable } from '@knuddels/std';
import { $ViewService } from '@knuddels-app/layout';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { $UserService } from '@knuddelsModules/UserData';
import { ChallengeCommand } from './ChallengeCommand';
import { DoubleActionCommand } from './DoubleActionCommand';
import { rewriteAssetUrl } from '@knuddels-app/tools/rewriteAssetUrl';
import { CHANNEL_MESSAGE_COMMAND_CONTEXT } from '@shared/constants';
import { $SoundService } from '@knuddelsModules/Sound';

export const CHANNEL_COMMAND_CONTEXT = 'channel';

@injectable()
export class Commands {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($CommandService) commandService: typeof $CommandService.T,
		@inject($JoinChannelService)
		joinChannelService: typeof $JoinChannelService.T,
		@inject($ViewService)
		viewService: typeof $ViewService.T,
		@inject($UserService)
		userService: typeof $UserService.T,
		@inject($SoundService)
		soundService: typeof $SoundService.T
	) {
		this.dispose.track([
			commandService.registerCommand({
				commandName: 'go',
				async invoke(channelName): Promise<void> {
					const sanitizedChannelName =
						sanitizeChannelName(channelName);

					if (!sanitizedChannelName.trim()) {
						viewService.openView(channelListViewId);
					} else {
						await joinChannelService.joinChannelByName(
							sanitizedChannelName
						);
					}
				},
				shouldInvoke(
					channelName: string,
					context?: string | undefined
				): boolean {
					// In the channel view we want error messages to be displayed in the channel instead of having an
					// error modal open up. Also this way we get more specific error messages from the backend.
					return (
						context !== CHANNEL_COMMAND_CONTEXT &&
						context !== CHANNEL_MESSAGE_COMMAND_CONTEXT
					);
				},
			}),
			// TODO we wanted to track private message (only in channel).
			//  But this doesn't work and would catch all /p.
			// commandService.registerCommand({
			// 	commandName: 'p',
			// 	async invoke(parameter): Promise<void> {
			// 		sendMessageEvent.track(`SendMessage_PrivateMessage`);
			// 		// we don't handle it here
			// 		return Promise.reject();
			// 	},
			// }),
			commandService.registerOpenUrlCommand({
				commandName: 'h',
				url: 'https://hilfe.knuddels.de/',
			}),
			commandService.registerOpenUrlCommand({
				commandName: 'forum',
				url: 'https://forum.knuddels.de/',
			}),
			...['wiki', 'knuddelswiki'].map(cmd =>
				commandService.registerOpenUrlCommand({
					commandName: cmd,
					url: 'https://knuddels-wiki.de/',
				})
			),
			commandService.registerCommand(
				new DoubleActionCommand(cmd =>
					commandService.parseAndInvokeCommand(cmd)
				)
			),
			commandService.registerCommand({
				commandName: 'playsound',
				async invoke(command): Promise<void> {
					const parts = command.split(':');
					const url = rewriteAssetUrl(parts[1]);
					soundService.playSoundFromUrl(url);
				},
			}),
			commandService.registerCommand(
				new ChallengeCommand(
					() => userService.currentUser.nick,
					cmd => commandService.parseAndInvokeCommand(cmd)
				)
			),
		]);
	}
}

function sanitizeChannelName(channelName: string): string {
	const withoutSid = channelName.replace(/sid~.+?:/, '');
	const withoutPrefix = withoutSid.replace(/^([+?])/, '');

	return withoutPrefix;
}
