import * as React from 'react';
import { GetGameChannels } from '@generated/graphql';
import { FlexCol, VirtualList, FallbackBox } from '@knuddels/component-library';
import { useRenderChannelListItem } from '../../../shared/ChannelList/ChannelListVirtualItems';
import { useGameListData } from './useGameListData';
import { GamesDisabledPlaceholder } from './GamesDisabledPlaceholder';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { extractChannelGroupIds } from '../extractChannelGroupIds';
export const GameChannelList: React.FC<{
  channels: typeof GetGameChannels.TPrimaryResult;
  onVisibleChannelsChanged: (visibleChannels: string[]) => void;
}> = ({
  channels,
  onVisibleChannelsChanged
}) => {
  const [width, setWidth] = React.useState(0);
  const listData = useGameListData(channels, width);
  const renderChannelListItem = useRenderChannelListItem({
    shouldUseShortDescription: true,
    trackingContentType: 'ChannelSelection_Games'
  });
  const track = useLogEvent();
  React.useEffect(() => {
    track('ChannelSelection_Games', 'Gamelist_Opened');
  }, []);
  if (listData.length === 0) {
    return <div className={_c0}>
				<GamesDisabledPlaceholder />
			</div>;
  }
  return <FallbackBox onLayout={e => {
    setWidth(e.width);
  }} className={_c1}>
			{width > 0 && <VirtualList data={listData} renderItem={renderChannelListItem} gapBottom={'xlarge'} onRenderedItemsChanged={range => {
      const visibleChannels = extractChannelGroupIds(listData, range);
      onVisibleChannelsChanged?.(visibleChannels);
    }} className={_c2} />}
		</FallbackBox>;
};
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = " Knu-FlexCol size-full ";
const _c2 = "  ";