import * as React from 'react';
import { IconPng, NotificationBadge, Spacer } from '@shared/components';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Text, ThemeOverride, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { openInNewTab } from '@shared/helper/openUrl';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
export const NavigationViewEntry: React.FC<{
  icon: string | React.ComponentType<any>;
  text: string | JSX.Element;
  url?: string;
  badge?: string;
  active?: boolean;
  onClick(): void;
}> = props => {
  const [hovered, setHovered] = React.useState(false);
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      props.onClick();
      if (props.url) {
        openInNewTab(props.url);
      }
    }
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c0}>
			<div className={_c1}>
				<NavIcon icon={props.icon} active={props.active} hovered={hovered} />

				<Spacer size={'base'} />
				<Text type={'body1'} state={hovered ? 'secondary' : ('tertiary' as TextStates)} className={_c2}>
					{props.text}
				</Text>
				<Spacer size={'auto'} />
				{!!props.badge && <div className={_c3}>
						<NotificationBadge number={props.badge} />
					</div>}
			</div>
		</div>;
};
export const NavIcon: React.FC<{
  icon: string | React.ComponentType<any>;
  active?: boolean;
  hovered?: boolean;
}> = ({
  icon,
  active,
  hovered
}) => {
  if (typeof icon == 'string') {
    return <IconPng iconUrl={icon} active={active} hovered={hovered} />;
  }
  return <div className={_c4}>
			{React.createElement(icon, {
      size: 'large'
    })}
		</div>;
};
const _c0 = " Knu-FlexCol cursor-pointer py-14px overflow-visible ";
const _c1 = " Knu-Flex alignItems-center pl-large ";
const _c2 = "  ";
const _c3 = " Knu-FlexCol mr-large ";
const _c4 = " Knu-Flex opacity-0-66 ";