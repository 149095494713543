import { addDomEvent as e } from "../events/add-dom-event.js";
import { Feature as i } from "../motion/features/Feature.js";
import { pipe as s } from "../utils/pipe.js";
class a extends i {
  constructor() {
    super(...arguments), this.isActive = !1;
  }
  onFocus() {
    let t = !1;
    try {
      t = this.node.current.matches(":focus-visible");
    } catch {
      t = !0;
    }
    !t || !this.node.animationState || (this.node.animationState.setActive("whileFocus", !0), this.isActive = !0);
  }
  onBlur() {
    !this.isActive || !this.node.animationState || (this.node.animationState.setActive("whileFocus", !1), this.isActive = !1);
  }
  mount() {
    this.unmount = s(e(this.node.current, "focus", () => this.onFocus()), e(this.node.current, "blur", () => this.onBlur()));
  }
  unmount() {
  }
}
export {
  a as FocusGesture
};
