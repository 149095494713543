import { EditProfileEntryFragment, ProfileEditField, RelationshipStatus, SexualOrientation } from '@generated/graphql';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $I18n } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { notNull } from '@knuddels-app/tools/notNull';
import { Spacer } from '@shared/components';
import { shouldBeUnreachable } from '@knuddels/std';
import * as React from 'react';
import { translateChildren, translateCountry, translateRelationshipStatus, translateSexualOrientation, translateSmoker } from '../../../entryValueTranslations';
import { ProfileNoDetailsAvailable } from '../../ProfileContent/ProfileNoDetailsAvailable';
import { allProfileCategories, categoryFormats, ChildrenMultiChoices, CountryChoices, EditProfileCategories, editProfileFieldData, EditProfileFieldOrder, RelationshipStatusMultiChoices, SexualOrientationMultiChoices, SmokerMultiChoices } from './Entry/EditProfileEntries';
import { EditProfileEntryDate } from './Entry/EditProfileEntryDate';
import { EditProfileEntryFreeText } from './Entry/EditProfileEntryFreeText';
import { EditProfileEntryMultiChoice } from './Entry/EditProfileEntryMultiChoice';
import { EditProfileEntryStringList } from './Entry/EditProfileEntryStringList';
import { ProfileEntryData } from './logic/profileEditorData';
import { DetailsEditor } from './logic/DetailsEditor';
import { CategoryHeader, FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { EditProfileNicknameEntry } from './Entry/EditProfileNicknameEntry';
import { EditProfileSystemAppEntry } from './Entry/EditProfileSystemAppEntry';
interface Props {
  editor: DetailsEditor;
}
export const EditProfileDetailsContent = injectedComponent({
  name: 'EditProfileDetailsContent',
  props: declareProps<Props>(),
  inject: {
    i18n: $I18n
  }
}, ({
  i18n,
  editor
}) => {
  const visibleCategoriesMap = mapVisibleCategories(editor.entries);
  const visibleCategories = (Object.keys(visibleCategoriesMap) as EditProfileCategories[]);
  return <div className={_c0 + ("contentBgAtom" ? resolveIsDarkColor("contentBgAtom", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1}>
					{visibleCategories.length > 0 ? visibleCategories.map(category => {
        const renderedEntries = visibleCategoriesMap[category].map(it => editor.entriesByField[it.field] ? <EditProfileEntry key={it.field} entryData={editor.entriesByField[it.field]} onValueChanged={editor.valueChanged} i18n={i18n} /> : null).filter(notNull);
        return <Category key={'category-' + category} category={category} i18n={i18n}>
									{renderedEntries}
								</Category>;
      }) : <ProfileNoDetailsAvailable />}
					<Spacer size={'xxlarge'} />
				</div>
			</div>;
});
type CatToEntry = { [key in EditProfileCategories]?: ReadonlyArray<EditProfileEntryFragment> };
function mapVisibleCategories(allEntries: DetailsEditor['entries']): CatToEntry {
  const result: CatToEntry = {};
  allProfileCategories.forEach(cat => {
    const entries = allEntries.filter(it => editProfileFieldData[it.field] && editProfileFieldData[it.field].category === cat).sort((a, b) => EditProfileFieldOrder.indexOf(a.field) - EditProfileFieldOrder.indexOf(b.field));
    if (entries.length > 0) {
      result[cat] = entries;
    }
  });
  return result;
}
function Category({
  category,
  i18n,
  children
}: {
  category: EditProfileCategories;
  i18n: typeof $I18n.T;
  children: JSX.Element[];
}): JSX.Element {
  return <>
			{category !== 'noCategory' && <CategoryHeader title={categoryFormats[category].format(i18n)} />}
			<Spacer size={'tiny'} />
			{/*TODO: generate and use ProfileEditField.Nickname type*/}
			{category === 'general' && <EditProfileNicknameEntry />}
			{children}
		</>;
}
const EditProfileEntry = observer('EditProfileEntry', ({
  entryData,
  i18n,
  onValueChanged
}: {
  entryData: ProfileEntryData;
  i18n: typeof $I18n.T;
  onValueChanged(field: ProfileEditField, newValue: string): void;
}) => {
  const entry = entryData.entry;
  const fieldData = editProfileFieldData[entry.field];
  const commonProps = {
    key: entry.field,
    field: entry.field,
    title: fieldData.label.format(i18n),
    image: fieldData.image,
    helperText: fieldData.helperText && i18n.format(fieldData.helperText),
    onValueChanged,
    validity: entryData.validity
  };
  switch (entry.__typename) {
    case 'ProfileEditEntryFreeText':
      return fieldData.systemAppOptions ? <EditProfileSystemAppEntry label={commonProps.title} image={commonProps.image} description={commonProps.helperText} systemAppOptions={fieldData.systemAppOptions} /> : <EditProfileEntryFreeText {...commonProps} text={entry.freeTextValue} multiLine={entry.freeTextMultiLineAllowed} />;
    case 'ProfileEditEntryStringList':
      return <EditProfileEntryStringList {...commonProps} valueString={entryData.value} />;
    case 'ProfileEditEntryChildren':
      return <EditProfileEntryMultiChoice {...commonProps} choices={ChildrenMultiChoices.map(it => ({
        value: it,
        label: translateChildren(it)
      }))} value={entry.childrenValue} />;
    case 'ProfileEditEntryRelationshipStatus':
      return <EditProfileEntryMultiChoice {...commonProps} choices={RelationshipStatusMultiChoices.map(it => ({
        value: it,
        label: translateRelationshipStatus(it)
      }))} value={entry.relationshipStatusValue === RelationshipStatus.Unknown ? undefined : entry.relationshipStatusValue} />;
    case 'ProfileEditEntrySexualOrientation':
      return <EditProfileEntryMultiChoice {...commonProps} choices={SexualOrientationMultiChoices.map(it => ({
        value: it,
        label: translateSexualOrientation(it)
      }))} value={entry.sexualOrientationValue === SexualOrientation.Unknown ? undefined : entry.sexualOrientationValue} />;
    case 'ProfileEditEntrySmoker':
      return <EditProfileEntryMultiChoice {...commonProps} choices={SmokerMultiChoices.map(it => ({
        value: it,
        label: translateSmoker(it)
      }))} value={entry.smokerValue} />;
    case 'ProfileEditEntryDate':
      return <EditProfileEntryDate {...commonProps} dateString={entryData.value} />;
    case 'ProfileEditEntryCountry':
      return <EditProfileEntryMultiChoice {...commonProps} choices={CountryChoices.map(it => ({
        value: it,
        label: translateCountry(it)
      }))} value={entry.countryValue} forceValue />;
    default:
      shouldBeUnreachable(entry);
      break;
  }
  return null;
});
const _c0 = " Knu-FlexCol bg-contentBgAtom position-relative overflow-hidden pt-base ";
const _c1 = " Knu-FlexCol px-tiny position-relative overflow-hidden ";