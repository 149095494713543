import * as React from 'react';
const AnimateContext = React.createContext<{
  show: boolean;
  onExit: () => void;
} | null>(null);
export const useAnimateContext = () => {
  const context = React.useContext(AnimateContext);
  if (!context) {
    throw new Error('AnimateContext must be used within a AnimatePresence');
  }
  return context;
};
export const CssAnimatePresence: React.FC = ({
  children
}) => {
  const last = React.useRef(children);
  const rerender = React.useState({})[1];
  if (children) {
    last.current = children;
  }
  return <AnimateContext.Provider value={{
    show: !!children,
    onExit(): void {
      last.current = null;
      rerender({});
    }
  }}>
			{last.current}
		</AnimateContext.Provider>;
};