import * as React from 'react';
import { debounce } from '@knuddels-app/tools/debounce';
import ResizeObserver from 'resize-observer-polyfill';
export const useResizeWithScroll = (elementId: string, scrolledToEnd: React.MutableRefObject<boolean>, callback: () => void): void => {
  const resizingStatus = React.useRef({
    resizing: false,
    scrolledToEnd: true
  });
  const handleResize = React.useCallback(() => {
    if (!resizingStatus.current.resizing) {
      resizingStatus.current = {
        resizing: false,
        scrolledToEnd: scrolledToEnd.current
      };
    }
    handleResizeEnd();
  }, []);
  const handleResizeEnd = debounce(() => {
    resizingStatus.current.resizing = false;
    if (resizingStatus.current.scrolledToEnd) {
      callback();
    }
  }, 100);
  React.useEffect(() => {
    const observer = new ResizeObserver(() => {
      handleResize();
    });
    const element = document.getElementById(elementId);
    if (element) {
      observer.observe(element);
    }
    return () => observer.disconnect();
  }, []);
};