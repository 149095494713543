import { UserForProfileFragment } from '@generated/graphql';
import { FlexCol, Panel, PanelsList, Tabs } from '@knuddels/component-library';
import { KnuddelSubscriptionLazy } from '@knuddelsModules/KnuddelDisplay';
import { motion, useMotionValue } from 'framer-motion';
import * as React from 'react';
import { ProfileAlbumInfo } from '../../profileQueryHelper';
import { AdminInfoApp } from '../AdminInfoApp';
import { DetailsTabContent } from './DetailsTabContent';
import { FriendsTabContent } from './Friends/FriendsTabContent';
import { NotAvailablePlaceholder } from './Photos/NotAvailablePlaceholder';
import { PhotosTabContent } from './Photos/PhotosTabContent';
interface Props {
  user: UserForProfileFragment;
  photoData: ProfileAlbumInfo;
  isCurrentUser: boolean;
  activeTabIndex: number;
  onTabChange: (index: number) => void;
  registerAdminInfoTabHeightListener?: (listener: (newHeight: number) => void) => void;
  initialAdminInfoHeight?: number;
}
export class ProfileContent extends React.PureComponent<Props> {
  private initialAdminInfoHeight: number | undefined = this.props.initialAdminInfoHeight;
  constructor(props: Props) {
    super(props);
    this.props.registerAdminInfoTabHeightListener?.(newHeight => {
      this.initialAdminInfoHeight = newHeight;
      this.listener(newHeight);
    });
  }
  private listener: (newHeight: number) => void = () => {};
  render(): React.ReactNode {
    return <div
    // bg={'contentBgAtom'}
    className={_c0}>
				{/* To prevent frequently starting/stopping knuddel subscription, we put it here instead of inside the tab */}
				{this.props.isCurrentUser && <KnuddelSubscriptionLazy id={this.props.user.id} />}

				<Tabs value={this.props.activeTabIndex} onChange={this.props.onTabChange}>
					<PanelsList>
						<Panel>
							<DetailsTabContent photoData={this.props.photoData} user={this.props.user} isCurrentUser={this.props.isCurrentUser} onTabChange={this.props.onTabChange} />
						</Panel>
						<Panel>{this.renderPhotosTabContent()}</Panel>
						<Panel>{this.renderFriendsTabContent()}</Panel>
						{this.props.user.canSeeAdminInfo && <Panel>{this.renderAdminInfoTabContent()}</Panel>}
					</PanelsList>
				</Tabs>
			</div>;
  }
  private readonly renderAdminInfoTabContent = (): React.ReactNode => {
    if (this.props.registerAdminInfoTabHeightListener && this.initialAdminInfoHeight) {
      return <AdminInfoTabContent nick={this.props.user.nick} registerHeightListener={listener => this.listener = listener} initialHeight={this.initialAdminInfoHeight} />;
    } else {
      return <div className={_c1}>
					<AdminInfoApp nick={this.props.user.nick} />
				</div>;
    }
  };
  private readonly renderFriendsTabContent = (): React.ReactNode => {
    return <div className={_c2}>
				<FriendsTabContent user={this.props.user} />
			</div>;
  };
  private readonly renderPhotosTabContent = (): React.ReactNode => {
    const {
      user,
      photoData
    } = this.props;
    return <div className={_c3}>
				{photoData ? <PhotosTabContent user={user} data={photoData} /> :
      // Show fallback screen if the photo server is not available
      <NotAvailablePlaceholder />}
			</div>;
  };
}
export const AdminInfoTabContent: React.FC<{
  nick: string;
  initialHeight: number;
  registerHeightListener: (listener: (newHeight: number) => void) => void;
}> = ({
  nick,
  initialHeight,
  registerHeightListener
}) => {
  const maxHeight = useMotionValue(initialHeight);
  React.useLayoutEffect(() => {
    registerHeightListener(height => {
      maxHeight.set(height);
    });
  }, []);
  return <motion.div style={{
    width: '100%',
    maxHeight: maxHeight,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }}>
			<AdminInfoApp nick={nick} />
		</motion.div>;
};
const _c0 = " Knu-FlexCol overflow-hidden flex-1 minHeight-124px ";
const _c1 = " Knu-FlexCol width-100-percent position-relative ";
const _c2 = " Knu-FlexCol width-100-percent position-relative ";
const _c3 = " Knu-FlexCol width-100-percent position-relative ";