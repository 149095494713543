import { injectable } from '@knuddels-app/DependencyInjection';
import { AppViewer } from './AppViewer';
import { AppInstance, AppInstanceData } from './AppInstance';
import { action, observable } from '@knuddels-app/mobx';
@injectable()
export class ChannelBackgroundAppViewer implements AppViewer {
  @observable.ref
  public currentApp: AppInstance | undefined;
  @action.bound
  addApp(appInstance: AppInstance): void {
    this.closeAllApps();
    this.currentApp = appInstance;
  }
  closeAllApps(): void {
    if (this.currentApp) {
      this.closeApp(this.currentApp.appId);
    }
  }
  closeAllAppsExceptForChannel(channelName?: string): void {
    if (!channelName) {
      this.closeAllApps();
      return;
    }
    if (this.currentApp && this.currentApp.data.channelName !== channelName) {
      this.closeApp(this.currentApp.appId);
    }
  }
  closeApp(appId: string): void {
    if (this.currentApp && this.currentApp.appId === appId) {
      this.currentApp.close();
    }
  }
  closeChannelBoundApps(channelName: string): void {
    if (this.currentApp && this.currentApp.data.channelName === channelName) {
      this.currentApp.close();
    }
  }
  getApp(appId: string): AppInstance | undefined {
    if (this.currentApp && this.currentApp.appId === appId) {
      return this.currentApp;
    }
    return undefined;
  }
  canHandleAppEvent(app: AppInstanceData): boolean {
    return app.display.__typename === 'AppDisplayChannelBackground';
  }
  @action.bound
  removeApp(appId: string): void {
    if (this.currentApp && this.currentApp.appId === appId) {
      this.currentApp = undefined;
    }
  }
  getAllApps(): AppInstance[] {
    return this.currentApp ? [this.currentApp] : [];
  }
}