import * as React from 'react';
import { Row, Spacer, StatusBadge, StatusBadgeSizes } from '@shared/components';
import { declareFormat, formatMessage, FormattedMessage } from '@knuddels-app/i18n';
import { $Environment } from '@knuddels-app/Environment';
import { conversationTitleBarEvent } from '../../analytics';
import { injectedComponent, injectable, declareProps, injectProps, inject } from '@knuddels-app/DependencyInjection';
import { UserDataInterfaces } from '@knuddelsModules/UserData';
import { Link, Text } from '@knuddels/component-library';
import { User } from '@generated/graphql';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { getMessageDayText } from '@knuddels-app/tools/getMessageDayText';
@injectable()
class OnlineAndChannelInfoModel {
  constructor(@injectProps()
  private readonly props: Props, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject.lazy($JoinChannelService)
  private readonly getJoinChannelService: typeof $JoinChannelService.TLazy) {}
  public readonly openChannel = async (channelName: string): Promise<void> => {
    conversationTitleBarEvent.track('Titlebar_OnlineChannel');
    if (globalEnv.product !== 'stapp-messenger') {
      const service = await this.getJoinChannelService();
      await service.joinChannelByName(channelName);
    } else if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeOpenChannelSlashCommand(channelName);
    }
  };
}
export const OnlineAndChannelInfo = injectedComponent({
  name: 'OnlineAndChannelInfo',
  model: OnlineAndChannelInfoModel,
  props: declareProps<Props>()
}, ({
  model,
  user
}) => {
  const {
    isOnline = false,
    currentOnlineChannelName,
    lastOnlineTime
  } = user;
  if (!isOnline) {
    if (!lastOnlineTime) {
      return null;
    }
    return <Row style={{
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center'
    }}>
					<Text type={'tiny'} state={'tertiary'} numberOfLines={1} className={_c0}>
						<FormattedMessage id={declareFormat({
          id: 'TITLEBAR_LAST_ONLINE',
          defaultFormat: 'Last online {time}'
        })} values={{
          time: getMessageDayText(+lastOnlineTime, {
            alwaysShowTime: true,
            showTodayMoreSpecific: true,
            noUpperCase: true
          })
        }} />
					</Text>
				</Row>;
  }
  return <Row style={{
    alignItems: 'center'
  }}>
				<StatusBadge size={StatusBadgeSizes.SMALL} borderPreset={'none'} />
				<Spacer size={'tiny'} />
				<Text type={'tiny'} state={'tertiary'} className={_c1}>
					{formatMessage(currentOnlineChannelName ? declareFormat({
        id: 'TITLEBAR_ONLINE_CHANNEL',
        defaultFormat: 'Online in '
      }) : declareFormat({
        id: 'TITLEBAR_ONLINE_NO_CHANNEL',
        defaultFormat: 'Online'
      }), {
        channel: currentOnlineChannelName
      })}
				</Text>
				{!!currentOnlineChannelName && <ChannelName channel={currentOnlineChannelName} clickable={currentOnlineChannelName !== '?'} onClick={() => model.openChannel(currentOnlineChannelName)} />}
			</Row>;
});
interface Props {
  user: UserDataInterfaces.UserWithOnlineChannelName & Pick<User, 'lastOnlineTime'>;
}
interface ChannelNameProps {
  channel: string;
  clickable?: boolean;
  onClick?: () => void;
}
class ChannelName extends React.PureComponent<ChannelNameProps> {
  render(): React.ReactNode {
    const {
      channel,
      onClick,
      clickable
    } = this.props;
    return clickable ? <Link type={'tiny'} state={'highlighted'} onPress={onClick}>
				{channel}
			</Link> : <Text type={'tiny'} state={'tertiary'} className={_c2}>
				{channel}
			</Text>;
  }
}
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";