import { OS, os } from '../tools/os';
import { getPixelRatio } from './getPixelRatio';

export function calculateCircleBorderRadius(size: number): number {
	return os === OS.android
		? // somehow android shows only rounded corners if it's set too small...
		  size * getPixelRatio()
		: // iOS does not show an image if borderRadius is to big ( * 2 )
		  size / 2;
}
