import * as React from 'react';
import { FlexCol, Text } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export function LoadingEntry(): React.ReactElement {
  return <div className={_c0}>
			<Text type="subtitle" state="secondary" className={_c1}>
				<FormattedMessage id={declareFormat({
        id: 'LOADING',
        defaultFormat: 'Loading...'
      })} />
			</Text>
		</div>;
}
const _c0 = " Knu-FlexCol flex-1 py-small placeItems-center ";
const _c1 = "  ";