import * as React from 'react';
import { Box, createAccessiblePointerEventHandler, createSvgIcon, FlexCol, Text } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { Spacer } from '@shared/components';
import { useTrackOwnProfileView } from '../../shared/useTrackOwnProfileView';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
const IconChange = createSvgIcon(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M15.82 11.6154L12.82 9.11539C12.671 8.99039 12.463 8.96539 12.288 9.04639C12.112 9.12939 12 9.30539 12 9.49939V10.9994H11.163C10.469 10.9994 9.835 10.6474 9.467 10.0594L6.229 4.87939C5.494 3.70239 4.226 2.99939 2.837 2.99939H0V4.99939H2.837C3.531 4.99939 4.165 5.35139 4.533 5.93939L7.771 11.1194C8.507 12.2964 9.775 12.9994 11.163 12.9994H12V14.4994C12 14.6934 12.112 14.8694 12.288 14.9524C12.355 14.9834 12.428 14.9994 12.5 14.9994C12.615 14.9994 12.729 14.9604 12.82 14.8834L15.82 12.3834C15.935 12.2884 16 12.1474 16 11.9994C16 11.8514 15.935 11.7104 15.82 11.6154Z" fill="currentColor" />
		<path d="M15.8203 3.61574L12.8203 1.11574C12.6713 0.991745 12.4633 0.965745 12.2883 1.04774C12.1123 1.12874 12.0003 1.30574 12.0003 1.49974V2.99974H11.1633C9.77433 2.99974 8.50633 3.70274 7.77133 4.87974L7.59033 5.16974L8.76933 7.05674L9.46733 5.93974C9.83533 5.35174 10.4693 4.99975 11.1633 4.99975H12.0003V6.49974C12.0003 6.69374 12.1123 6.87074 12.2883 6.95274C12.3553 6.98374 12.4283 6.99974 12.5003 6.99974C12.6153 6.99974 12.7293 6.95974 12.8203 6.88374L15.8203 4.38374C15.9353 4.28874 16.0003 4.14774 16.0003 3.99974C16.0003 3.85174 15.9353 3.71074 15.8203 3.61574Z" fill="currentColor" />
		<path d="M5.231 8.94263L4.534 10.0596C4.166 10.6476 3.531 10.9996 2.837 10.9996H0V12.9996H2.837C4.225 12.9996 5.493 12.2966 6.229 11.1196L6.41 10.8296L5.231 8.94263Z" fill="currentColor" />
	</svg>);
IconChange.displayName = 'IconChange';
export const ChangeProfilePhotoButton: React.FC<{
  size: number;
} & React.ComponentProps<typeof Box>> = ({
  size,
  ...boxProps
}) => {
  const track = useTrackOwnProfileView();
  const execute = useSlashCommandWithoutChannel();
  const selectAndUploadProfilePicture = () => {
    track('AlbumEdit_ChangeProfileImage');
    execute('/opensystemapp ' + PROFILE_CUSTOMIZATION_APP_ID);
  };
  return <FlexCol {...createAccessiblePointerEventHandler({
    pointerCallback: selectAndUploadProfilePicture
  })} width={`${size}px`} height={`${size}px`} bg={'contentLightBg'} borderRadius={'minor'} borderWidth={'base'} borderStyle={'dashed'} borderColor={'accent'} placeItems={'center'} px={'minor'} {...boxProps}>
			<IconChange type={'highlighted'} size={'large'} />
			<Spacer size={'tiny'} />
			<Text state={'highlighted'} type={'tiny'} className={_c0}>
				<FormattedMessage id={declareFormat({
        id: 'profile.edit.profile-picture.change',
        defaultFormat: 'Change picture'
      })} />
			</Text>
		</FlexCol>;
};
const _c0 = " textAlign-center ";