import { jsxs as r, jsx as e } from "react/jsx-runtime";
import { resolveIsDarkColor as s } from "./isDarkColor.js";
import { clsx as i } from "../../node_modules/clsx/dist/clsx.m.js";
import { useTheme as o } from "../../themes/useTheme.js";
function d(t) {
  const n = s("contentBg", o());
  return /* @__PURE__ */ r(
    "div",
    {
      className: i(
        "Knu-Flex borderRadius-base py-tiny px-base pl-minor mx-small my-minor bg-hairlineAtom alignItems-center justifyContent-spaceBetween",
        n ? "content-is-dark" : "content-is-light"
      ),
      children: [
        /* @__PURE__ */ e("div", { className: "Knu-Flex", children: /* @__PURE__ */ e("span", { className: "Knu-Text text-body2 bold text-secondary", children: t.title }) }),
        /* @__PURE__ */ e("div", { className: "Knu-Flex", children: t.adornmentRight })
      ]
    }
  );
}
export {
  d as CategoryHeader
};
