import { Deferred } from '@knuddels/std';

export function getImageData<TSource extends string | number>(
	source: TSource
): Promise<{ source: TSource; width: number; height: number } | undefined> {
	// different handling for number is needed on native (shouldn't happen in web)
	if (typeof source !== 'string') {
		throw new Error(`getImageSize: Invalid source: ${source}.`);
	}
	return getMetadata(source)
		.then(({ width, height }) => ({
			source,
			width,
			height,
		}))
		.catch(() => undefined);
}

function getMetadata(url: string): Promise<{ width: number; height: number }> {
	const defer = new Deferred<{ width: number; height: number }>();

	const img = new window.Image();

	img.onload = () => {
		defer.resolve({
			width: img.naturalWidth,
			height: img.naturalHeight,
		});
	};

	img.onerror = () => {
		defer.reject('Failed to prefetch url ' + url);
	};

	img.onabort = () => {
		defer.reject('Prefetch cancelled for url ' + url);
	};
	img.src = url;

	return defer.promise;
}
