import { useService } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';

export const useTrackOwnProfileView = () => {
	const firebaseAnalyticsService = useService($FirebaseAnalyticsService);

	return (itemId: string) => {
		firebaseAnalyticsService.logEvent('Profile_OwnProfileView', itemId);
	};
};
