import * as React from 'react';
import { AlbumPhoto, User } from '@generated/graphql';
import { Spacer } from '@shared/components';
import { Box, createAccessiblePointerEventHandler, Flex, FlexCol, IconMoreHorizontal, Text, FallbackBox, resolveThemingValue, useTheme, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useOpenAlbumDetails } from '../../../../../useOpenAlbumDetails';
import { ThumbnailPhoto } from '../../../PhotoDisplays/ThumbnailPhoto';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import { AddAlbumPhotoButton } from '../../AddAlbumPhotoButton';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $ProfileNavigationService } from '@knuddelsModules/Profile/providedServices';
import { observer } from '@knuddels-app/mobx';
const ELEMENT_SIZE = 80;
const SPACING_WIDTH = 5;
type Photo = Pick<AlbumPhoto, 'thumbnailUrl' | 'id'>;
const usePhotosToShow = (photos: readonly Photo[]) => {
  const [width, setWidth] = React.useState(0);
  const maxPhotos = Math.floor((width + SPACING_WIDTH) / (ELEMENT_SIZE + SPACING_WIDTH));
  const tooManyPhotos = photos.length > maxPhotos;
  const photosToShow = tooManyPhotos ? photos.slice(0, maxPhotos - 1) // -1 to have space for the overflow item
  : photos;
  return {
    setWidth,
    photosToShow,
    showOverflowItem: tooManyPhotos
  };
};
export const AlbumPhotoBar: React.FC<{
  userId: User['id'];
  photos: readonly Photo[];
  changeToAlbumTab: () => void;
}> = observer('AlbumPhotoBar', ({
  userId,
  photos,
  changeToAlbumTab
}) => {
  const profileNavigationService = useService($ProfileNavigationService);
  const {
    setWidth,
    photosToShow,
    showOverflowItem
  } = usePhotosToShow(photos);
  const userService = useService($UserService);
  const openDetails = useOpenAlbumDetails();
  const firebaseAnalyticsService = useService($FirebaseAnalyticsService);
  const showAddPhotoButton = photos.length === 0 && userService.currentUser?.id === userId;
  const hasEntries = photosToShow.length > 0 || showOverflowItem || showAddPhotoButton;
  const onPhotoPress = React.useCallback((photoId: string) => {
    firebaseAnalyticsService.logEvent('Profile_Profileview', 'Images_Clicked');
    openDetails({
      userId,
      photoId
    });
  }, [openDetails, userId]);
  return <FallbackBox onLayout={e => setWidth(e.width)} className={_c0 + (hasEntries ? _c1 : _c2) + (hasEntries ? _c3 : _c4)}>
			{showAddPhotoButton && <AddAlbumPhotoButton size={80} onPress={() => {
      changeToAlbumTab();
      profileNavigationService.showEditProfile();
    }} />}
			{photosToShow.map((photo, index) => <ThumbnailPhoto key={'photo-' + index} ml={index === 0 ? 'none' : (`${SPACING_WIDTH}px` as any)} url={photo.thumbnailUrl} size={ELEMENT_SIZE} onPress={onPhotoPress} photoId={photo.id} />)}
			{showOverflowItem && <div style={{
      marginLeft: resolveThemingValue((`${SPACING_WIDTH}px` as any), "spacing", useTheme())
    }} className={_c5}>
					<OverflowItem onPress={changeToAlbumTab} />
				</div>}
		</FallbackBox>;
});
const OverflowItem: React.FC<{
  onPress: () => void;
}> = ({
  onPress
}) => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} style={{
    width: resolveThemingValue(`${ELEMENT_SIZE}px`, "sizes", useTheme()),
    height: resolveThemingValue(`${ELEMENT_SIZE}px`, "sizes", useTheme())
  }} className={_c6 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<IconMoreHorizontal />
			<Spacer size={'tiny'} />
			<Text type={'tiny'} bold={true} state={'tertiary'} className={_c7}>
				<FormattedMessage id={declareFormat({
        id: 'profile.details.albumoverview.overflow',
        defaultFormat: 'All'
      })} />
			</Text>
		</div>;
};
const _c0 = " Knu-Flex flexShrink-0 ";
const _c1 = " mx-minor ";
const _c2 = " mx-none ";
const _c3 = " mt-minor ";
const _c4 = " mt-none ";
const _c5 = " Knu-Box ";
const _c6 = " Knu-FlexCol cursor-pointer bg-contentLightBg borderRadius-minor borderStyle-solid borderWidth-base borderColor-hairlineAtom alignItems-center justifyContent-center ";
const _c7 = "  ";