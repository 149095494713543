import * as React from 'react';
import { useEffect } from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SendMessageService } from '@knuddelsModules/Messenger/providedServices';
import { FormattedText, FormattedTextDisplay, SuggestionButton, SuggestionsContainer, TextSuggestionButton } from '@shared/components';
import { useConversationId } from '../ConversationIdContext';
import { useQuery } from '@knuddels-app/Connection';
import { ConversationStarterSuggestions as ConversationStarterSuggestionsQuery, ConversationStarterSuggestionSmileyFragment, ConversationStarterSuggestionTextFragment } from '@generated/graphql';
import { useTrackConversation } from '../../useTrackConversation';
export const ConversationStarterSuggestions: React.FC<{
  onMessageSent(): void;
}> = props => {
  const conversationId = useConversationId();
  const track = useTrackConversation();
  const {
    data: suggestions
  } = useQuery(ConversationStarterSuggestionsQuery, {
    conversationId
  });
  const showSuggestions = suggestions && suggestions.length > 0;
  useEffect(() => {
    if (showSuggestions) {
      if (suggestions[0].__typename === 'ConversationStarterSuggestionText') {
        track('MentorMessageSuggestion_Shown');
      } else if (suggestions[0].__typename === 'ConversationStarterSuggestionSmiley') {
        track('SmileyMessageSuggestion_Shown');
      }
    }
  }, [suggestions]);
  return showSuggestions ? <SuggestionsContainer>
			{suggestions.map(suggestion => {
      if (suggestion.__typename === 'ConversationStarterSuggestionText') {
        return <TextSuggestion key={suggestion.text} suggestion={suggestion} onMessageSent={props.onMessageSent} />;
      } else {
        return <SmileySuggestion key={suggestion.smiley.textRepresentation} onMessageSent={props.onMessageSent} suggestion={suggestion} />;
      }
    })}
		</SuggestionsContainer> : null;
};
const TextSuggestion: React.FC<{
  onMessageSent: () => void;
  suggestion: ConversationStarterSuggestionTextFragment;
}> = ({
  onMessageSent,
  suggestion
}) => {
  const {
    text,
    formattedText
  } = suggestion;
  const sendMessageService = useService($SendMessageService);
  const conversationId = useConversationId();
  const track = useTrackConversation();
  return <TextSuggestionButton formattedText={FormattedText.fromJsonString(formattedText)} onPress={async () => {
    await sendMessageService.sendMessage(conversationId, text);
    track(`MentorMessageSuggestion_${text}Clicked`);
    onMessageSent();
  }} />;
};
const SmileySuggestion: React.FC<{
  onMessageSent: () => void;
  suggestion: ConversationStarterSuggestionSmileyFragment;
}> = ({
  onMessageSent,
  suggestion
}) => {
  const sendMessageService = useService($SendMessageService);
  const conversationId = useConversationId();
  const track = useTrackConversation();
  return <SuggestionButton onPress={async () => {
    track('SmileyMessageSuggestion_Clicked' + suggestion.smiley.textRepresentation);
    await sendMessageService.sendConversationStarterSmiley(conversationId, suggestion.smiley);
    onMessageSent();
  }}>
			<FormattedTextDisplay text={FormattedText.fromJsonString(suggestion.smiley.image)} textProps={{}} />
		</SuggestionButton>;
};