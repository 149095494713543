import { ConversationMessageFragment } from '@generated/graphql';

export function isPhotoMessage(message: ConversationMessageFragment): boolean {
	const contentTypename = message.content.__typename;
	return (
		contentTypename === 'ConversationImageMessageContent' ||
		contentTypename === 'ConversationSnapMessageContent'
	);
}

export function isSnapMessage(message: ConversationMessageFragment): boolean {
	const contentTypename = message.content.__typename;
	return contentTypename === 'ConversationSnapMessageContent';
}
