import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { ClientEvents, StorageGetItem, StorageRemoveItem, StorageSetItem } from './ClientEventHandler';
import { AppInstance } from './AppInstance';
import { Preferences } from '@capacitor/preferences';
import { $CurrentEndpointStore } from '@knuddels-app/Connection';
@injectable()
export class SystemAppStorageService {
  constructor(@inject($CurrentEndpointStore)
  private currentEndpointStore: typeof $CurrentEndpointStore.T) {}
  handleStorageEvent(appInstance: AppInstance, event: ClientEvents): boolean {
    /**
     * We do not allow user apps to access the storage
     */
    if (appInstance.isUserApp) {
      return false;
    }
    if (event.name === 'STORAGE_GET_ITEM') {
      Preferences.get({
        key: this.getKey(appInstance, event)
      }).then(value => {
        appInstance.sendEventToWebview('STORAGE_GET_ITEM_RESPONSE', {
          type: 'STORAGE_GET_ITEM_RESPONSE',
          requestId: event.requestId,
          value: value.value
        });
      });
      return true;
    }
    if (event.name === 'STORAGE_SET_ITEM') {
      Preferences.set({
        key: this.getKey(appInstance, event),
        value: event.value
      });
      return true;
    }
    if (event.name === 'STORAGE_REMOVE_ITEM') {
      Preferences.remove({
        key: this.getKey(appInstance, event)
      });
      return true;
    }
    return false;
  }
  private getKey(appInstance: AppInstance, event: StorageRemoveItem | StorageGetItem | StorageSetItem): string {
    return `${appInstance.appIdWithoutMeta}:${this.currentEndpointStore.currentEndpoint.id}:${event.key}`;
  }
}