import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import sorry from '@shared/images/sorry.gif';

export const SnackbarDefinitionSendMessagePostboxIsFull: SnackbarData = {
	type: 'sendMessagePostboxIsFull',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_POSTBOX_IS_FULL_TEXT',
				defaultFormat:
					'Unfortunately the post box of this user is full.',
			})
		),
	subtext: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_POSTBOX_IS_FULL_SUBTEXT',
				defaultFormat: "Your message can't be sent at the moment.",
			})
		),
	adornment: sorry,
};
