import { InfoBoxItem } from './InfoBox';
import { declareFormat } from '@knuddels-app/i18n';

import codensed from '../assets/condensed.png';
import condensedBg from '../assets/condensed-bg.png';

export const newReleaseInfoItems: ReadonlyArray<InfoBoxItem> = [
	{
		image: {
			source: codensed,
			width: 345,
			height: 279,
		},
		bgImage: {
			source: condensedBg,
			width: '100%',
			height: '85%',
		},
		highlightText: declareFormat({
			id: 'onboarding.modal.condensed-mode.highlight',
			defaultFormat: 'Design your Knuddels',
		}),
		headlineText: declareFormat({
			id: 'onboarding.modal.condensed-mode.headline',
			defaultFormat: 'More space for important things',
		}),
		descriptionText: declareFormat({
			id: 'onboarding.modal.condensed-mode.description',
			defaultFormat:
				'The new classic design creates a nostalgic mood. You can now choose between the space-saving classic and modern views for channels.',
		}),
	},
];
