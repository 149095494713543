import { FullConversationFragment, MenteeStatus, MessengerConversationVisibility } from '@generated/graphql';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { $OverlayService, $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { IconCheckmark, IconDownload, IconTrash, IconUndo } from '@knuddels/component-library';
import { $MessengerImageService, $MessengerService, $ReadSystemService } from '@knuddelsModules/Messenger/providedServices';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { IconArchive } from '@shared/icons/IconArchive';
import { IconImageBlock } from '@shared/icons/IconImageBlock';
import { IconImagePermit } from '@shared/icons/IconImagePermit';
import { IconRead } from '@shared/icons/IconRead';
import { IconUnread } from '@shared/icons/IconUnread';
import * as React from 'react';
import { conversationTitleBarEvent } from '../../analytics';
import { ARCHIVE_CHAT, DELETE_CHAT, MARK_CONVERSATION_READ, MARK_CONVERSATION_UNREAD, RESTORE_CHAT } from '../../i18n/shared-formats';
import { isConversationUnread } from '../../utils/conversationReadStateHelper';
import { ContextMenuDivider } from '@shared/components/molecules/Modal/ContextMenuDivider';
import { ExportMessageHistoryModal } from '@knuddelsModules/Messenger/bundle/components/ExportMessageHistoryModal/ExportMessageHistoryModal';
import { userIsJames } from '@shared/helper/user';
interface ConversationOverflowContextMenuProps extends ContextMenuProps {
  conversation: FullConversationFragment;
  onConversationDeleted?: () => void;
}
@injectable()
export class ConversationOverflowContextMenuModel {
  public get conversation(): FullConversationFragment | undefined {
    return this.props.conversation;
  }
  public get mentorBarExtended(): boolean {
    return this.clientSettingsService.mentorBarExtended;
  }
  public get otherParticipant(): FullConversationFragment['otherParticipants'][0] | undefined {
    if (!this.conversation) {
      return undefined;
    }
    return this.conversation.otherParticipants[0];
  }
  public get contextMenuProps(): ContextMenuProps {
    return this.props;
  }
  public get isUnread(): boolean {
    return isConversationUnread(this.conversation);
  }
  public get showImages(): boolean {
    return this.otherParticipant !== undefined && this.otherParticipant.canSendImages;
  }
  constructor(@injectProps()
  private readonly props: ConversationOverflowContextMenuProps, @inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($ReadSystemService)
  private readonly readSystemService: typeof $ReadSystemService.T, @inject($MessengerImageService)
  private readonly messengerImageService: typeof $MessengerImageService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {}
  public closeContextMenu = (): void => {
    this.thisVisibleOverlay.dispose();
  };
  public exportMessageHistory = (): void => {
    conversationTitleBarEvent.track('Titlebar_ExportMessageHistory');
    this.closeContextMenu();
    this.overlayService.showOverlay({
      view: <ExportMessageHistoryModal conversation={this.conversation} />,
      keepOnLocationChange: true
    });
  };
  public archiveConversation = (): void => {
    conversationTitleBarEvent.track('Titlebar_ArchiveChat');
    this.messengerService.archiveConversation(this.conversation.id);
    this.closeContextMenu();
  };
  public deleteConversation = async (): Promise<void> => {
    conversationTitleBarEvent.track('Titlebar_DeleteChat');
    await this.messengerService.deleteConversation(this.conversation.id);
    this.props.onConversationDeleted?.();
    this.closeContextMenu();
  };
  public restoreConversation = (): void => {
    conversationTitleBarEvent.track('Titlebar_RestoreChat');
    this.messengerService.restoreConversation(this.conversation.id);
    this.closeContextMenu();
  };
  public markUnread = (): void => {
    conversationTitleBarEvent.track('Titlebar_MarkAsUnread');
    this.readSystemService.markConversationAsUnread(this.conversation.id);
    if (this.conversation.visibility === MessengerConversationVisibility.Deleted) {
      this.restoreConversation();
    }
    this.closeContextMenu();
  };
  public markRead = (): void => {
    conversationTitleBarEvent.track('Titlebar_MarkAsRead');
    this.readSystemService.markConversationsAsRead([this.conversation.id]);
    this.closeContextMenu();
  };
  public allowImages = (): void => {
    conversationTitleBarEvent.track('Titlebar_AllowImages');
    this.setAllowImages(true);
  };
  public disallowImages = (): void => {
    conversationTitleBarEvent.track('Titlebar_DisallowImages');
    this.setAllowImages(false);
  };
  public setAllowImages = (allowed: boolean): void => {
    if (allowed) {
      this.messengerImageService.allowImages(this.otherParticipant);
    } else {
      this.messengerImageService.blockImages(this.otherParticipant);
    }
    this.closeContextMenu();
  };
  public toggleMentorBarExtended = (): void => {
    conversationTitleBarEvent.track(this.mentorBarExtended ? 'Titlebar_DontShowMentorbarPermanently' : 'Titlebar_ShowMentorbarPermanently');
    this.clientSettingsService.updateMentorBarExtended(!this.mentorBarExtended).catch(() => {
      this.snackbarService.showGenericError();
    });
    this.closeContextMenu();
  };
  public get isNewConversation(): boolean {
    return !this.conversation || !this.conversation.latestConversationMessage;
  }
  public get hasMenteeStatus(): boolean {
    return this.otherParticipant && this.otherParticipant.menteeStatus && this.otherParticipant.menteeStatus !== MenteeStatus.None;
  }
  public get canExportConversation(): boolean {
    return this.otherParticipant && !this.otherParticipant.isAppBot && !userIsJames(this.otherParticipant);
  }
}
export const ConversationOverflowContextMenu = injectedComponent({
  name: 'ConversationOverflowContextMenu',
  model: ConversationOverflowContextMenuModel
}, ({
  model
}) => {
  return <ContextMenu closeContextMenu={model.closeContextMenu} {...model.contextMenuProps}>
				<ReadOrUnreadContextMenuEntry model={model} />
				<ContextMenuDivider />
				<AllowDisallowImages model={model} />
				{model.conversation && <>
						<ContextMenuDivider />
						<ArchiveContextMenuEntry model={model} />
						<DeleteContextMenuEntry model={model} />
						<RestoreContextMenuEntry model={model} />
					</>}
				{model.canExportConversation && <>
						<ContextMenuDivider />
						<ExportMessageHistoryMenuEntry model={model} />
					</>}
				{model.hasMenteeStatus && <>
						<ContextMenuDivider />
						<ExtendMentorBarMenuEntry model={model} />
					</>}
			</ContextMenu>;
});
export const ExportMessageHistoryMenuEntry: React.FC<typeof ConversationOverflowContextMenu.TPropsWithModel> = ({
  model
}) => {
  return <ContextMenuEntry icon={IconDownload} text={formatMessage(declareFormat({
    id: 'EXPORT_CHAT_HISTORY',
    defaultFormat: 'Export chat history'
  }))} onClick={model.exportMessageHistory} disabled={model.isNewConversation} />;
};
function RestoreContextMenuEntry({
  model
}: typeof ConversationOverflowContextMenu.TPropsWithModel): JSX.Element {
  const visibility = model.conversation.visibility;
  if (visibility === MessengerConversationVisibility.Visible) {
    return null;
  }
  return <ContextMenuEntry icon={IconUndo} text={formatMessage(RESTORE_CHAT)} onClick={model.restoreConversation} disabled={model.isNewConversation} />;
}
function DeleteContextMenuEntry({
  model
}: typeof ConversationOverflowContextMenu.TPropsWithModel): JSX.Element {
  const visibility = model.conversation.visibility;
  if (visibility === MessengerConversationVisibility.Deleted) {
    return null;
  }
  return <ContextMenuEntry icon={IconTrash} text={formatMessage(DELETE_CHAT)} onClick={model.deleteConversation} disabled={model.isNewConversation} />;
}
function ArchiveContextMenuEntry({
  model
}: typeof ConversationOverflowContextMenu.TPropsWithModel): JSX.Element {
  const visibility = model.conversation.visibility;
  if (visibility !== MessengerConversationVisibility.Visible) {
    return null;
  }
  return <ContextMenuEntry icon={IconArchive} text={formatMessage(ARCHIVE_CHAT)} onClick={model.archiveConversation} disabled={model.isNewConversation} />;
}
function AllowDisallowImages({
  model
}: typeof ConversationOverflowContextMenu.TPropsWithModel): JSX.Element {
  const {
    showImages
  } = model;
  return <ContextMenuEntry icon={showImages ? IconImageBlock : IconImagePermit} text={formatMessage(showImages ? declareFormat({
    id: 'OVERFLOW_DISALLOW_IMAGES',
    defaultFormat: 'Block images'
  }) : declareFormat({
    id: 'OVERFLOW_ALLOW_IMAGES',
    defaultFormat: 'Allow images'
  }))} onClick={showImages ? model.disallowImages : model.allowImages} disabled={!model.otherParticipant} />;
}
function ReadOrUnreadContextMenuEntry({
  model
}: typeof ConversationOverflowContextMenu.TPropsWithModel): JSX.Element {
  return model.isUnread ? <ContextMenuEntry icon={IconRead} text={formatMessage(MARK_CONVERSATION_READ)} onClick={model.markRead} testId={'conversation-overflow-mark-read'} disabled={model.isNewConversation} /> : <ContextMenuEntry icon={IconUnread} text={formatMessage(MARK_CONVERSATION_UNREAD)} onClick={model.markUnread} testId={'conversation-overflow-mark-unread'} disabled={model.isNewConversation} />;
}
function ExtendMentorBarMenuEntry({
  model
}: typeof ConversationOverflowContextMenu.TPropsWithModel): JSX.Element {
  return <ContextMenuEntry icon={model.mentorBarExtended ? IconCheckmark : 'none'} text={formatMessage(declareFormat({
    id: 'SHOW_MENTORBAR',
    defaultFormat: 'Mentorbar dauerhaft einblenden'
  }))} onClick={model.toggleMentorBarExtended} />;
}