import * as React from 'react';
import { ChannelPreviewMembersFragment, ColorFragment } from '@generated/graphql';
import { ChannelThumbnail } from './ChannelThumbnail';
import { Flex, FlexCol, IconSortUp, Ripple, Rotate, Text, ThemeOverride, PointerEvent, Image, Z_INDEX, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { os, OS } from '@shared/components/tools/os';
import { ChannelListContacts } from './ChannelListContacts';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { observer } from '@knuddels-app/mobx';
import { ActiveChannelIndicator } from '../../../shared/ActiveChannelIndicator';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { isNative } from '@knuddels-app/tools/isNative';
type ChannelListItemProps = {
  id: string;
  name: string;
  userCount: number;
  backgroundColor?: ColorFragment;
  channelImage?: string;
  blurredChannelImage?: string;
  previewMembers?: ChannelPreviewMembersFragment;
  onEntryClick: (id: string) => void;
  onExpanderClick?: (id: string) => void;
  hasSubChannels?: boolean;
  expanded?: boolean;
  indented?: boolean;
  subline?: string;
  type: 'default' | 'condensed';
  isLast: boolean;
  hideImageBorder?: boolean;
};
export const ChannelListItem: React.FC<ChannelListItemProps> = observer('ChannelListItem', props => {
  const [isActive, setIsActive] = React.useState(false);
  const activeChannelService = useService($ActiveChannelService);
  const bgColor = colorToRgbaString(props.backgroundColor) || 'transparent';
  React.useEffect(() => {
    const activeChannelId = activeChannelService.activeChannel?.id.split(':')[0];
    setIsActive(activeChannelId === props.id);
  }, [activeChannelService.activeChannel?.id, props.id]);
  const friends = props.previewMembers?.friends ?? [];
  const watchlist = props.previewMembers?.watchlist ?? [];
  const others = props.previewMembers?.others ?? [];
  return <div onClick={() => {
    props.onEntryClick(props.id);
  }} style={{
    clipPath: 'content-box',
    background: resolveThemingValue((bgColor as ThemeOverride), "colors", useTheme()),
    transform: resolveThemingValue(!isNative() ? 'scale(1.01)' : undefined, "theme", useTheme())
  }} className={_c0 + ((bgColor as ThemeOverride) ? resolveIsDarkColor((bgColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "") + (props.indented ? _c1 : _c2) + (props.isLast ? _c3 : _c4)}>
				{!!props.blurredChannelImage && <div className={_c5}>
						<Image resizeMode={'cover'} src={props.blurredChannelImage} alt={''} className={_c6} />
					</div>}
				<div style={{
      background: resolveThemingValue(!props.channelImage ? (bgColor as ThemeOverride) : undefined, "colors", useTheme()),
      zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
    }} className={_c7 + ((!props.channelImage ? (bgColor as ThemeOverride) : undefined) ? resolveIsDarkColor(!props.channelImage ? (bgColor as ThemeOverride) : undefined, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<ChannelThumbnail src={props.channelImage} alt={props.name} userCount={props.userCount} backgroundColor={bgColor} type={props.type === 'condensed' ? 'default' : 'large'} hideBorder={props.hideImageBorder} />
					<div className={_c8}>
						<EntryTitle name={props.name} isActive={isActive} type={props.type} subline={props.subline} />
						{(friends.length > 0 || watchlist.length > 0 || others.length > 0) && <ChannelListContacts friends={friends} watchlist={watchlist} others={others} align={'left'} />}
					</div>
				</div>
				<Ripple mode={'full'} zIndex={5} />
				<Expander {...props} />
			</div>;
});
export const EntryTitle: React.FC<{
  name: string;
  isActive: boolean;
  type: 'default' | 'condensed';
  textColor?: string;
  subline?: string;
}> = ({
  name,
  isActive,
  type,
  subline
}) => {
  const style = os === OS.web ? {
    overflowWrap: 'anywhere'
  } : {};
  return <div className={_c9}>
			<div className={_c10}>
				<Text type="subtitle" numberOfLines={1}
      // @ts-expect-error Not exposed
      style={{
        ...style
      }} className={_c11}>
					{name}
				</Text>
				{isActive && <ActiveChannelIndicator />}
			</div>
			{type === 'default' && !!subline && <Text type={'tiny'} numberOfLines={1} className={_c12}>
					{subline}
				</Text>}
		</div>;
};
const Expander: React.FC<ChannelListItemProps> = props => {
  const handleExpanderClick = (e: PointerEvent): void => {
    e.stopPropagation();
    props.onExpanderClick(props.id);
  };
  if (!props.hasSubChannels || !props.onExpanderClick) {
    return null;
  }
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
  }} className={_c13}>
			<Rotate from={180} to={0} rotated={props.expanded} className={_c14}>
				<IconSortUp ripple={false} onPress={handleExpanderClick} />
			</Rotate>
		</div>;
};
const _c0 = " Knu-FlexCol position-relative mr-none borderRadius-xlarge overflow-hidden cursor-pointer ";
const _c1 = " ml-small ";
const _c2 = " ml-none ";
const _c3 = " mb-none ";
const _c4 = " mb-small ";
const _c5 = " Knu-FlexCol position-absolute inset-none ";
const _c6 = " size-full ";
const _c7 = " Knu-Flex p-small ";
const _c8 = " Knu-FlexCol flex-1 mx-minor alignItems-flex-start justifyContent-space-between pb-tiny minWidth-0-px ";
const _c9 = " Knu-FlexCol ";
const _c10 = " Knu-Flex alignItems-center ";
const _c11 = "  ";
const _c12 = "  ";
const _c13 = " Knu-FlexCol position-absolute right-tiny top-none bottom-none width-34px height-32px justifyContent-center ";
const _c14 = " height-full alignItems-center ";