import * as React from 'react';
import { smileyTradeAppViewId } from './viewId';
import { SMILEY_TRADE_APP_ID } from '@shared/constants';
import { GenericSystemApp } from '../GenericSystemApp';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService, InsideOverlayViewContext } from '@knuddels-app/layout';
import { $ScreenService } from '@knuddels-app/Screen';
import { Flex, toPointerHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
export const SmileyTradeSystemApp = () => {
  const viewService = useService($ViewService);
  const screenService = useService($ScreenService);
  const overlay = React.useContext(InsideOverlayViewContext);
  const inOverlay = !!overlay && !screenService.isStackedLayout;
  return <div onClick={toPointerHandler(inOverlay ? overlay.close : undefined)} style={{
    padding: resolveThemingValue(inOverlay ? 'base' : undefined, "spacing", useTheme())
  }} className={_c0}>
			<div style={{
      maxHeight: resolveThemingValue(inOverlay ? 800 : undefined, "sizes", useTheme()),
      maxWidth: resolveThemingValue(inOverlay ? 1000 : undefined, "sizes", useTheme()),
      borderRadius: resolveThemingValue(inOverlay ? 'large' : undefined, "borderRadius", useTheme())
    }} className={_c1}>
				<GenericSystemApp onAppClose={() => viewService.closeViewAndTryOpenParent(smileyTradeAppViewId)} appId={SMILEY_TRADE_APP_ID} viewId={smileyTradeAppViewId} />
			</div>
		</div>;
};
const _c0 = " Knu-Flex size-full placeItems-center ";
const _c1 = " Knu-Flex size-full mx-auto overflow-hidden ";