import * as React from 'react';
import { FormattedTextDisplay } from './FormattedTextDisplay';
import { FormattedText } from './FormattedText';
const SMILEY_CHARS_EQUIVALENT = 20;
export function PreviewFormattedTextDisplay(props: Omit<React.ComponentProps<typeof FormattedTextDisplay>, 'disableBold' | 'disableLinks'> & {
  maxTextLength?: number;
}): JSX.Element {
  const text = props.maxTextLength ? getShortText(props.text, props.maxTextLength) : props.text;
  return <FormattedTextDisplay disableBold disableLinks {...props} text={text} />;
}
const getShortText = (text: FormattedText, maxTextLength: number): FormattedText => {
  switch (text.kind) {
    case 'Text':
    case 'Link':
      return {
        ...text,
        text: `${text.text.slice(0, maxTextLength)}${text.text.length > maxTextLength ? '...' : ''}`
      };
    case 'List':
      return {
        ...text,
        items: calculateTextListToRender(text.items, maxTextLength).items
      };
    case 'Smiley':
    default:
      return text;
  }
  return text;
};
const calculateTextListToRender = (items: FormattedText[], maxTextLength: number, sumChars = 0): {
  items: FormattedText[];
  sumChars: number;
} => {
  let lastIndex = 0;
  while (sumChars <= maxTextLength && lastIndex < items.length) {
    const item = items[lastIndex];
    switch (item.kind) {
      case 'Text':
      case 'Link':
        sumChars += item.text.length;
        break;
      case 'Smiley':
        sumChars += SMILEY_CHARS_EQUIVALENT;
        break;
      case 'List':
        {
          const {
            items: newItems,
            sumChars: newSumChars
          } = calculateTextListToRender(item.items, maxTextLength, sumChars);
          item.items = newItems;
          sumChars = newSumChars;
          break;
        }
      default:
        break;
    }
    lastIndex++;
  }
  return {
    items: items.slice(0, lastIndex),
    sumChars
  };
};