import { ConversationMessageFragment, ConversationWithParticipants, FriendRecommendationSubscription, FullConversationFragment, FullConversationWithoutMessagesFragment, GetConversation, GetConversationDocument, GetConversationQuery, GetConversationQueryVariables, GetConversationWithoutMessagesDocument, GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables, MessengerConversation, Scalars, SendStopTyping, SendTyping, User } from '@generated/graphql';
import { action, computed, observable, runInAction } from '@knuddels-app/mobx';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { SendingMessagesState } from './SendingMessagesState';
import { SendingMessage } from './SendingMessage';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { FriendRecommendationsState } from './FriendRecommendationsState';
import { Disposable } from '@knuddels/std';
@injectable()
export class MessengerConversationService {
  dispose = Disposable.fn();
  private _conversationState: {
    [conversationId: string]: ClientConversationState;
  } = {};
  constructor(@inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T) {
    this.dispose.track(this.subscribeToFriendRecommendations());
  }
  getConversationId(userId: User['id']): Promise<string | undefined> {
    const otherParticipantIds: ReadonlyArray<User['id']> = [userId];
    return this.authenticatedClientService.currentK3Client.queryWithResultPromise(ConversationWithParticipants, {
      ids: otherParticipantIds
    }).match({
      ok: conversation => {
        return conversation && conversation.id;
      },
      error: () => {
        return undefined;
      }
    });
  }
  getOrCreateClientConversationState(conversationId: Scalars['ID']): ClientConversationState {
    const clientConversationState = this._conversationState[conversationId];
    if (!clientConversationState) {
      this._conversationState[conversationId] = new ClientConversationState(conversationId, this.authenticatedClientService);
    }
    return this._conversationState[conversationId];
  }
  public getCachedFullConversation(id: MessengerConversation['id']): GetConversationQuery['messenger']['conversation'] | undefined {
    try {
      const result = this.authenticatedClientService.currentClient.readQuery<GetConversationQuery, GetConversationQueryVariables>({
        query: GetConversationDocument,
        // with the @connection directive we always get all the messages no matter the messageCount
        variables: {
          id,
          pixelDensity: getPixelRatio()
        }
      });
      return result.messenger && result.messenger.conversation;
    } catch (e) {
      // return undefined if there is no cached conversation.
      return undefined;
    }
  }
  public async getFullConversation(id: MessengerConversation['id']): Promise<FullConversationFragment | undefined> {
    return await this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetConversation,
    // with the @connection directive we always get all the messages no matter the messageCount
    {
      id,
      pixelDensity: getPixelRatio()
    }, 'network-only').match({
      ok: r => r,
      error: () => undefined
    });
  }
  public getCachedOverviewConversation(id: MessengerConversation['id']): FullConversationWithoutMessagesFragment | undefined {
    try {
      return this.authenticatedClientService.currentClient.readQuery<GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables>({
        query: GetConversationWithoutMessagesDocument,
        variables: {
          id,
          pixelDensity: getPixelRatio()
        }
      }).messenger.conversation;
    } catch (e) {
      // return undefined if there is no cached conversation.
      return undefined;
    }
  }
  public updateOverviewConversation(conversation: FullConversationWithoutMessagesFragment): void {
    this.authenticatedClientService.currentClient.writeQuery<GetConversationWithoutMessagesQuery, GetConversationWithoutMessagesQueryVariables>({
      query: GetConversationWithoutMessagesDocument,
      data: {
        messenger: {
          __typename: 'MessengerQuery',
          conversation
        }
      },
      variables: {
        id: conversation.id,
        pixelDensity: getPixelRatio()
      }
    });
  }
  private subscribeToFriendRecommendations = (): Disposable => {
    return this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(FriendRecommendationSubscription, {}, {
      next: conversationId => {
        this.getOrCreateClientConversationState(conversationId).friendRecommendationsState.add(Date.now());
      }
    });
  };
}
export interface SendingImage {
  image: string;
  timestamp: number;
}
export enum SuggestionsType {
  ThankSuggestions,
  CongratulateReactions,
}
export class ClientConversationState {
  public readonly sendingMessagesState = new SendingMessagesState();
  public readonly friendRecommendationsState = new FriendRecommendationsState();
  @observable
  public scrolledToBottom = true;
  @observable
  private _selectedMessageToQuote: ConversationMessageFragment | undefined = undefined;
  @observable
  private _activeSuggestionsType: SuggestionsType | undefined = undefined;
  @observable
  private _selectedMessageIdForSuggestions: string | undefined = undefined;
  @observable
  private _messageIdBeforeUnreadDivider: Scalars['ID'] | undefined = undefined;
  @observable
  private _isOtherParticipantTyping = false;
  @observable
  private _sendingImage: SendingImage | undefined = undefined;
  @observable
  private _automaticComplaintCommand: string | undefined = undefined;
  @observable
  private _hasSeenUnreadMessage = true;
  private resetTypingHandle: any | undefined = undefined;
  constructor(public readonly conversationId: MessengerConversation['id'], private readonly authenticatedClientService: typeof $AuthenticatedClientService.T) {}
  get selectedMessageToQuote(): ConversationMessageFragment | undefined {
    return this._selectedMessageToQuote;
  }
  get hasSeenUnreadMessage(): boolean {
    return this._hasSeenUnreadMessage;
  }
  @action
  setSelectedMessageToQuote(message: ConversationMessageFragment): void {
    this.clearSelectedMessageIdForSuggestions();
    this._selectedMessageToQuote = message;
  }
  @action
  clearSelectedMessageToQuote(): void {
    this._selectedMessageToQuote = undefined;
  }
  get activeSuggestionsType(): SuggestionsType | undefined {
    return this._activeSuggestionsType;
  }
  get selectedMessageIdForSuggestions(): string | undefined {
    return this._selectedMessageIdForSuggestions;
  }
  @action
  setSelectedMessageIdForSuggestions(id: string, type: SuggestionsType): void {
    this.clearSelectedMessageToQuote();
    this._activeSuggestionsType = type;
    this._selectedMessageIdForSuggestions = id;
  }
  @action
  clearSelectedMessageIdForSuggestions(): void {
    this._activeSuggestionsType = undefined;
    this._selectedMessageIdForSuggestions = undefined;
  }
  get messageIdBeforeUnreadDivider(): Scalars['ID'] | undefined {
    return this._messageIdBeforeUnreadDivider;
  }
  @action
  setMessageIdBeforeUnreadDivider(messageBeforeUnreadDivider: Scalars['ID'] | undefined): void {
    this._messageIdBeforeUnreadDivider = messageBeforeUnreadDivider;
    if (!this.scrolledToBottom) {
      this._hasSeenUnreadMessage = !messageBeforeUnreadDivider;
    }
  }
  @action
  clearMessageIdBeforeUnreadDivider(): void {
    this._messageIdBeforeUnreadDivider = undefined;
    this._hasSeenUnreadMessage = true;
  }
  @action
  markUnreadAsSeen(): void {
    this._hasSeenUnreadMessage = true;
  }

  /**
   * Mark the other participant as typing. Will also unmark it again after 3 seconds unless this is called again.
   */
  @action
  setOtherParticipantTyping(willReceiveStopEvent: boolean): void {
    if (this.resetTypingHandle) {
      clearTimeout(this.resetTypingHandle);
    }
    this._isOtherParticipantTyping = true;
    this.resetTypingHandle = setTimeout(() => {
      runInAction('Reset typing indicator', () => this._isOtherParticipantTyping = false);
    }, willReceiveStopEvent ? 31000 : 3000);
  }
  @action
  clearOtherParticipantTyping(): void {
    if (this.resetTypingHandle) {
      clearTimeout(this.resetTypingHandle);
      this.resetTypingHandle = undefined;
    }
    this._isOtherParticipantTyping = false;
  }
  get isOtherParticipantTyping(): boolean {
    return this._isOtherParticipantTyping;
  }
  sendTyping(): void {
    this.authenticatedClientService.currentK3Client.mutateWithResultPromise(SendTyping, {
      id: this.conversationId
    }).onErr(error => {
      console.error('Error sending typing', error);
    });
  }
  sendStopTyping(): void {
    this.authenticatedClientService.currentK3Client.mutateWithResultPromise(SendStopTyping, {
      id: this.conversationId
    }).onErr(error => {
      console.error('Error sending stop typing', error);
    });
  }
  @action
  setSendingImage(image: SendingImage | undefined): void {
    this._sendingImage = image;
  }
  get sendingImage(): SendingImage | undefined {
    return this._sendingImage;
  }
  public get sendingMessages(): readonly SendingMessage[] {
    return this.sendingMessagesState.messages;
  }
  @computed
  public get isUnread(): boolean {
    return this.sendingMessages.some(it => it.isUnread);
  }
  @action
  setAutomaticComplaintCommand(command: string): void {
    this._automaticComplaintCommand = command;
  }
  public get automaticComplaintCommand(): string | undefined {
    return this._automaticComplaintCommand;
  }
}