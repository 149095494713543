import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { User } from '@generated/graphql';
import * as React from 'react';
import { PointerEvent, TextProps } from '@knuddels/component-library';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { NickWithIcons } from '@knuddels-app/shared-components/NickWithIcons';
import { conversationTitleBarEvent } from '../analytics';
export interface ConversationTitleData {
  imageNick: string;
  text: string | ((type: TextProps['type'], state: TextProps['state'], bold: boolean) => JSX.Element);
  italic?: boolean;
}
export const useConversationTitle = (otherConversationParticipant: Pick<User, 'id' | 'nick' | 'isOnline' | 'latestOnlineChannelName' | 'menteeStatus' | 'isAppBot' | 'authenticityClassification'> | undefined, hideNegativeAuthenticityStates?: boolean, disableNickPress?: boolean) => {
  const profileNavigationService = useLazyService($ProfileNavigationService);
  return React.useMemo(() => {
    const titleData: ConversationTitleData = {
      imageNick: '',
      text: ''
    };
    if (!otherConversationParticipant) {
      return titleData;
    }
    const onNickPress = !disableNickPress ? async () => {
      conversationTitleBarEvent.track('Titlebar_Nickname');
      conversationTitleBarEvent.track('Titlebar_ShowProfile');
      const service = await profileNavigationService();
      service.showProfileForUser(otherConversationParticipant, {
        openedFromTrackingLabel: 'Conversation'
      });
    } : undefined;
    titleData.imageNick = otherConversationParticipant.nick;
    titleData.text = otherConversationParticipant.nick ? (type, state, bold) => {
      return <NickWithIcons type={type} state={state} bold={bold} user={otherConversationParticipant} onAuthenticityIconPress={async (e: PointerEvent) => {
        e.stopPropagation();
        const service = await profileNavigationService();
        service.showAuthenticityForUser(otherConversationParticipant);
      }} onNickPress={onNickPress} hideNegativeAuthenticityStates={hideNegativeAuthenticityStates} />;
    } : undefined;
    if (!titleData.text) {
      titleData.text = formatMessage(declareFormat({
        id: 'DELETED_USER',
        defaultFormat: 'Deleted Member'
      }));
      titleData.italic = true; // do we want that?
    }

    return titleData;
  }, [otherConversationParticipant, hideNegativeAuthenticityStates]);
};