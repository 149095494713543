import * as React from 'react';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $ProfileNavigationService, $SaveProfileService } from '@knuddelsModules/Profile/providedServices';
import { ProfileEditEntry, User } from '@generated/graphql';
import { ProfileEditor } from './EditProfileContent/logic/ProfileEditor';
import { $ScreenService } from '@knuddels-app/Screen';
import { EditProfileMobile } from './EditProfileMobile';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { EditProfileDesktop } from './EditProfileDesktop';
import { OwnProfileViewProfileEditEvent } from '@knuddelsModules/Profile/analytics';
import { $I18n } from '@knuddels-app/i18n';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { SelectedProfileTabContext } from '../SelectedProfileTabContext';
import { ProfileTabsMapping } from '../ProfileTabsMapping';
import { ProfileAlbumInfo, ProfileUser } from '../../profileQueryHelper';
export interface EditProfileCommonProps {
  entries: ReadonlyArray<ProfileEditEntry>;
  currentUser: Pick<User, 'id'>;
  user: ProfileUser;
  albumInfo: ProfileAlbumInfo;
  onClose(): void;
}
interface Props extends EditProfileCommonProps {
  onEditor(editor: ProfileEditor | undefined): void;
}
@injectable()
class EditProfileLogicModel implements IModel {
  public dispose = Disposable.fn();
  public readonly editor: ProfileEditor;
  constructor(@injectProps()
  private readonly props: Props, @inject($SaveProfileService)
  saveProfileService: typeof $SaveProfileService.T, @inject($SnackbarService)
  snackbarService: typeof $SnackbarService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($ProfileNavigationService)
  private readonly profileNavigationService: typeof $ProfileNavigationService.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T) {
    this.editor = new ProfileEditor(authenticatedClientService, saveProfileService, snackbarService, i18n, props.entries);
    this.props.onEditor(this.editor);
    this.dispose.track(() => this.props.onEditor(undefined));
  }
  public get isStackedLayout(): boolean {
    return this.screenService.isStackedLayout;
  }
  public close = (): void => {
    if (this.editor.hasChanges) {
      this.editor.save();
    }
    this.doClose();
  };
  private doClose = (): void => {
    if (this.editor.savedChanges) {
      OwnProfileViewProfileEditEvent.track('ClosedAfterChange');
    } else {
      OwnProfileViewProfileEditEvent.track('ClosedWithoutChange');
    }
    this.props.onClose();
  };
  public goBack = (): void => {
    if (this.editor.hasChanges) {
      this.editor.save();
    }
    this.doGoBack();
  };
  private doGoBack = (): void => {
    this.profileNavigationService.showProfile(this.props.currentUser.id, undefined, true);
  };
}
export const EditProfileLogic = injectedComponent({
  name: 'EditProfileLogic',
  model: EditProfileLogicModel,
  props: declareProps<Props>()
}, ({
  model,
  currentUser,
  entries,
  user,
  albumInfo
}) => {
  const {
    activeTabIndex,
    setActiveTabIndex
  } = React.useContext(SelectedProfileTabContext);

  // Friends tab is not available when editing profile
  React.useLayoutEffect(() => {
    if (activeTabIndex === ProfileTabsMapping.friends || activeTabIndex === ProfileTabsMapping.admin) {
      setActiveTabIndex(ProfileTabsMapping.photos);
    }
  }, [activeTabIndex, setActiveTabIndex]);
  const profileProps: EditProfileCommonProps = {
    currentUser: currentUser,
    user,
    albumInfo,
    entries,
    onClose: model.close
  };
  return model.isStackedLayout ? <EditProfileMobile {...profileProps} editor={model.editor} goBack={model.goBack} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} /> : <EditProfileDesktop {...profileProps} editor={model.editor} goBack={model.goBack} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />;
});