export type ImageGroupImageUnverified = {
	pseudoCSS?: {
		b?: string;
		w?: string;
		h?: string;
		mx?: string;
		my?: string;
		pw?: string;
		ph?: string;
		mw?: string;
		mh?: string;
		quadcut?: string;
		scaleh?: string;
		scalew?: string;
		clickurl?: string;
		clickpw?: string;
		clickph?: string;
		clicktoggleonce?: string;
	};
	linkUrl?: string;
	url?: string;
};

export type ImageGroupImageVerified = {
	pseudoCSS: {
		b?: string;

		w: string;
		h: string;
		mx: string;
		my: string;
		mw?: string;
		mh?: string;
		pw: string;
		ph: string;
		quadcut?: string;
		scalew?: string;
		scaleh?: string;
		clickurl?: string;
		clickpw?: string;
		clickph?: string;
		clicktoggleonce?: string;
	};
	url: string;
	linkUrl?: string;
};

export function verifyImageGroupImage(
	image: ImageGroupImageUnverified
): ImageGroupImageVerified | undefined {
	if (image.url === undefined || typeof image.url !== 'string') {
		return undefined;
	}

	if (!image.pseudoCSS) {
		return undefined;
	}

	if (image.pseudoCSS.b && typeof image.pseudoCSS.b !== 'string') {
		return undefined;
	}

	if (
		typeof image.pseudoCSS.pw !== 'string' ||
		typeof image.pseudoCSS.ph !== 'string'
	) {
		return undefined;
	}

	if (
		image.pseudoCSS.clickurl &&
		typeof image.pseudoCSS.clickurl !== 'string'
	) {
		return undefined;
	}

	if (typeof image.pseudoCSS.mw === 'string') {
		image.pseudoCSS.pw = image.pseudoCSS.mw;
	}
	if (typeof image.pseudoCSS.mh === 'string') {
		image.pseudoCSS.ph = image.pseudoCSS.mh;
	}

	if (typeof image.pseudoCSS.quadcut === 'string') {
		image.pseudoCSS.ph = image.pseudoCSS.quadcut;
		image.pseudoCSS.pw = image.pseudoCSS.quadcut;
	}

	if (typeof image.pseudoCSS.w !== 'string') {
		image.pseudoCSS.w = image.pseudoCSS.pw;
	}
	if (typeof image.pseudoCSS.h !== 'string') {
		image.pseudoCSS.h = image.pseudoCSS.ph;
	}

	if (typeof image.pseudoCSS.mx !== 'string') {
		image.pseudoCSS.mx = '0';
	}
	if (typeof image.pseudoCSS.my !== 'string') {
		image.pseudoCSS.my = '0';
	}

	return {
		pseudoCSS: {
			b: image.pseudoCSS.b,
			w: image.pseudoCSS.w,
			h: image.pseudoCSS.h,
			mx: image.pseudoCSS.mx,
			my: image.pseudoCSS.my,
			pw: image.pseudoCSS.pw,
			ph: image.pseudoCSS.ph,
			mw: image.pseudoCSS.mw,
			mh: image.pseudoCSS.mh,
			quadcut: image.pseudoCSS.quadcut,
			scalew: image.pseudoCSS.scalew,
			scaleh: image.pseudoCSS.scaleh,
			clickurl: image.pseudoCSS.clickurl,
			clickpw: image.pseudoCSS.clickpw,
			clickph: image.pseudoCSS.clickph,
			clicktoggleonce: image.pseudoCSS.clicktoggleonce,
		},
		url: image.url,
		linkUrl: image.linkUrl,
	};
}
