import { transformProps as s } from "../../render/html/utils/transform.js";
const r = {
  type: "spring",
  stiffness: 500,
  damping: 25,
  restSpeed: 10
}, n = (t) => ({
  type: "spring",
  stiffness: 550,
  damping: t === 0 ? 2 * Math.sqrt(550) : 30,
  restSpeed: 10
}), i = {
  type: "keyframes",
  duration: 0.8
}, a = {
  type: "keyframes",
  ease: [0.25, 0.1, 0.35, 1],
  duration: 0.3
}, o = (t, { keyframes: e }) => e.length > 2 ? i : s.has(t) ? t.startsWith("scale") ? n(e[1]) : r : a;
export {
  o as getDefaultTransition
};
