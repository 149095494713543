import * as React from 'react';
import { useContext } from 'react';
import { MultipleUserOnlineOrChannelUpdated, ProfileFriendUserFragment, User, UserForProfileFragment } from '@generated/graphql';
import { FlexCol, FallbackBox, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ContactsService, ContactsUser } from '@knuddelsModules/Contacts';
import { $ProfileNavigationService } from '@knuddelsModules/Profile/providedServices';
import { $UserService } from '@knuddelsModules/UserData';
import { observer } from '@knuddels-app/mobx';
import { useFriendsState } from './useFriendsState';
import { CommonFriends } from './CommonFriends';
import { ProfileCommonFriendsContext } from './ProfileFriendsContext';
import { useTrackProfileView } from '../../../shared/useTrackProfileView';
import { useTrackOwnProfileView } from '../../../shared/useTrackOwnProfileView';
import { useSubscription } from '@knuddels-app/Connection';
import { useUpdateProfileUser } from '../../../profileQueryHelper';
import { mergeDeepPartial } from '@knuddels-app/tools/mergeDeepPartial';
import { $ScreenService } from '@knuddels-app/Screen';
import { FriendsHiddenPlaceholder } from './FriendsHiddenPlaceholder';
import { NoFriendsPlaceholder } from './NoFriendsPlaceholder';
import { TabContentPlaceholder } from '../TabContentPlaceholder';
export const FriendsTabContent: React.FC<{
  user: UserForProfileFragment;
}> = observer('FriendsTabContent', ({
  user
}) => {
  const isCurrentUser = useService($UserService).isCurrentUser(user.id);
  const friendsData = useFriendsState(isCurrentUser);
  const [width, setWidth] = React.useState(0);
  useTrackFriendsAvailable(friendsData, isCurrentUser);
  return <FallbackBox onLayout={e => setWidth(e.width)} className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{friendsData.friends && friendsData.friends.length > 0 ? <FriendsVirtualList listWidth={width} user={user} friends={friendsData.friends} /> : <FriendsTabPlaceholder nick={user.nick} hiddenByPrivacy={friendsData.__typename === 'FriendsHiddenByPrivacy'} />}
		</FallbackBox>;
});
const FriendsVirtualList: React.FC<{
  listWidth: number;
  user: UserForProfileFragment;
  friends: readonly ContactsUser[];
}> = ({
  listWidth,
  user,
  friends
}) => {
  const trackOthersProfile = useTrackProfileView();
  const trackOwnProfile = useTrackOwnProfileView();
  const contactsService = useService($ContactsService);
  const profileNavigationService = useService($ProfileNavigationService);
  const isCurrentUser = useService($UserService).isCurrentUser(user.id);
  const commonFriendsQuery = useContext(ProfileCommonFriendsContext);
  const commonFriends = commonFriendsQuery.__typename === 'FriendList' ? commonFriendsQuery.friends : [];
  useSubscribeToOnlineStatusUpdates(friends);
  const openProfile = React.useCallback((contact: Pick<User, 'id'>) => {
    if (isCurrentUser) {
      trackOwnProfile('Friendstab_ContactClicked');
    } else {
      trackOthersProfile('Friendstab_FriendClicked');
    }
    profileNavigationService.showProfile(contact.id);
  }, [profileNavigationService, isCurrentUser]);
  return <contactsService.ContactsVirtualList contextMenuType={isCurrentUser ? 'friends' : undefined} contacts={friends} onContactClicked={openProfile} noOnlineStatusSubscription header={!isCurrentUser && commonFriends.length > 0 ? <CommonFriends listWidth={listWidth} nick={user.nick} friends={commonFriends} /> : undefined} />;
};
const useTrackFriendsAvailable = (friendsData: ReturnType<typeof useFriendsState>, isCurrentUser: boolean) => {
  const trackOthersProfile = useTrackProfileView();
  const trackOwnProfile = useTrackOwnProfileView();
  const wasTracked = React.useRef(false);
  React.useEffect(() => {
    if (!wasTracked.current && friendsData && friendsData.friends) {
      wasTracked.current = true;
      if (isCurrentUser) {
        trackOwnProfile(friendsData.friends.length > 0 ? 'Friendstab_ContactsAvailable' : 'Friendstab_NoContactShown');
      } else if (friendsData.friends.length > 0) {
        trackOthersProfile('Friendstab_FriendsAvailable');
      }
    }
  }, [friendsData]);
};
const useSubscribeToOnlineStatusUpdates = (friends: readonly ProfileFriendUserFragment[]) => {
  const updateProfileUser = useUpdateProfileUser();
  useSubscription(MultipleUserOnlineOrChannelUpdated, {
    ids: friends.map(it => it.id)
  }, data => {
    updateProfileUser(oldData => {
      if (oldData.contacts.friends.__typename !== 'FriendList') {
        return oldData;
      }
      const oldFriends = oldData.contacts.friends.friends;
      const oldFriendIndex = oldFriends.findIndex(it => it.id === data.id);
      if (oldFriendIndex < 0) {
        return oldData;
      }
      const oldFriend = oldFriends[oldFriendIndex];
      const newFriend = {
        ...oldFriend,
        isOnline: data.isOnline,
        currentOnlineChannelName: data.currentOnlineChannelName
      };
      const newFriends = [...oldFriends];
      newFriends[oldFriendIndex] = newFriend;
      return mergeDeepPartial(oldData, {
        contacts: {
          friends: {
            friends: newFriends
          }
        }
      });
    });
  });
};
const FriendsTabPlaceholder: React.FC<{
  nick: string;
  hiddenByPrivacy: boolean;
}> = ({
  nick,
  hiddenByPrivacy
}) => {
  const screenService = useService($ScreenService);
  const fullHeight = !screenService.isStackedLayout;
  return <TabContentPlaceholder fullHeight={fullHeight}>
			{hiddenByPrivacy ? <FriendsHiddenPlaceholder nick={nick} fullHeight={fullHeight} /> : <NoFriendsPlaceholder nick={nick} fullHeight={fullHeight} />}
		</TabContentPlaceholder>;
};
const _c0 = " Knu-FlexCol width-100-percent height-100-percent bg-contentBg ";