import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { OverflowMenuTrigger } from '@shared/components';
import { $OverlayService, OverlayFactory } from '@knuddels-app/overlays';
import { useService } from '@knuddels-app/DependencyInjection';
import { EditAlbumContextMenu } from './EditAlbumContextMenu';
import { useRefetchProfile } from '../../profileQueryHelper';
export const OpenEditAlbumContextMenu: React.FC<{
  albumId: string;
}> = observer('OpenEditAlbumContextMenu', ({
  albumId
}) => {
  const {
    open,
    isOpen
  } = useOverflowMenu(albumId);
  return <OverflowMenuTrigger showOverflowMenu={open} isMenuActive={isOpen} />;
});
const useOverflowMenu = (albumId: string) => {
  const overlayService = useService($OverlayService);
  const refetchProfile = useRefetchProfile();
  const open: React.ComponentProps<typeof OverflowMenuTrigger>['showOverflowMenu'] = (x, y, width, height) => {
    overlayService.showOverlayIfNotVisible(EditAlbumContextMenuOverlay, {
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height,
      albumId,
      refetchProfile
    });
  };
  return {
    open,
    isOpen: !!overlayService.findOverlay(EditAlbumContextMenuOverlay.getFilter())
  };
};
const EditAlbumContextMenuOverlay = new OverlayFactory(EditAlbumContextMenu);