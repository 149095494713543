import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import sorry from '@shared/images/sorry.gif';

export const SnackbarDefinitionSendMessageContactFilter: SnackbarData = {
	type: 'sendMessageContactFilter',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_CONTACT_FILTER_TEXT',
				defaultFormat:
					"The receiver's contact filter is blocking your message.",
			})
		),
	subtext: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_CONTACT_FILTER_SUBTEXT',
				defaultFormat: "You can't send messages to this user.",
			})
		),
	adornment: sorry,
};
