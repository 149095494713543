import { User } from '@generated/graphql';
import { gtagInit } from '@knuddelsModules/Ads/bundle/web/gtagInit';
import { providerInit } from '@knuddelsModules/Ads/bundle/web/providerInit';
import { cmpInit } from './web/cmpInit';

export function appStartInit(): void {
	cmpInit('769bb2e471e0d');
	gtagInit();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function loginInit(user: Pick<User, 'gender' | 'age'>): void {
	providerInit();
}
