import * as React from 'react';
import { Spacer } from '@shared/components';
import { UserImageType } from '@knuddelsModules/UserData';
import { FlexCol, createAccessiblePointerEventHandler, Text, createNativeAccessiblePointerEventHandler, toPointerHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { OS, os } from '@shared/components/tools/os';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
export function VistorsPanelItem(props: {
  children: React.ReactNode;
  label: string | React.ReactNode;
  onClick(): void;
}): React.ReactElement {
  const [hovered, setHovered] = React.useState(false);
  const style = os === OS.web ? {
    overflowWrap: 'anywhere'
  } : {};
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: props.onClick
  })} onClick={toPointerHandler(props.onClick)} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{
    width: resolveThemingValue(UserImageType.MESSENGER, "sizes", useTheme())
  }} className={_c0}>
			<div style={{
      height: resolveThemingValue(UserImageType.MESSENGER, "sizes", useTheme()),
      width: resolveThemingValue(UserImageType.MESSENGER, "sizes", useTheme())
    }} className={_c1}>
				{props.children}
			</div>
			<Spacer size={'tiny'} />
			<Text type={'body2'} state={((hovered ? 'primary' : 'secondary') as TextStates)} bold={hovered} numberOfLines={1} style={{
      ...style
    }} className={_c2}>
				{props.label}
			</Text>
		</div>;
}
const _c0 = " Knu-FlexCol cursor-pointer overflow-visible ";
const _c1 = " Knu-FlexCol borderRadius-circle overflow-visible ";
const _c2 = " textAlign-center ";