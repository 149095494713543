import { createThemeForAccentColor, Theme } from '@knuddels/component-library';
import { getFontScale } from '@knuddels-app/tools/getFontScale';

export async function createThemeWithFontScale(
	accentColor: string,
	theme: Theme
): Promise<Theme> {
	const fontScale = await getFontScale();
	return createThemeForAccentColor(accentColor, theme, fontScale);
}
