import { LocalStorageKey } from './LocalStorageKey';
import { stringOrUndefinedSerializer } from './serializers';
import { isNative } from '@knuddels-app/tools/isNative';

export function getMigrationFunctionForSharedKeys(
	key: string
): LocalStorageKey<string | undefined>['tryMigrateFromOld'] | undefined {
	if (!isNative()) {
		return localStorage => {
			const cookieDomain = 'knuddels.de';
			const migrateEntry = localStorage.getEntry(
				new LocalStorageKey({
					name: key,
					scope: { cookieDomain },
					serializer: stringOrUndefinedSerializer,
					cookieExpires: { inDays: 365 }, // doesn't matter here as we will delete it
				})
			);
			const value = migrateEntry.getAndReset();
			return { value };
		};
	}
	return undefined;
}
