import React from 'react';
import { openInNewTab } from '@shared/helper/openUrl';

type CommandInfo =
	| {
			type: 'link';
			url: string;
			onClick(): void;
	  }
	| {
			type: 'command';
			onClick(): void;
	  };

export function useCommand(
	onClick: (command: string) => boolean,
	command?: string,
	enableFallbackOpenUrl?: boolean
): CommandInfo {
	if (!command) {
		// TODO handle this
		return {
			type: 'command',
			onClick(): void {},
		};
	}

	const url = command.startsWith('/') ? undefined : command;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const handleClick = React.useCallback(() => {
		const wasHandledByParent = onClick(command);

		// TODO shouldn't do this here...
		//  not setting the `onLinkClicked` shouldn't try to open a link...
		//  have to think how to solve this
		if (!wasHandledByParent && url && enableFallbackOpenUrl) {
			// Need to manually call openUrl because native TextLink does not respect url prop
			openInNewTab(command);
		}
	}, [onClick, command]);

	return url
		? {
				type: 'link',
				url,
				onClick: handleClick,
			}
		: {
				type: 'command',
				onClick: handleClick,
			};
}
