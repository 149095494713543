import React from 'react';
import { ImageGroup } from '@shared/components/molecules/FormattedText/ImageGroup';
export type SmileyImageProps = {
  imageUrl: string;
  width?: number;
  height?: number;
  scaleToFit?: boolean;
};
export const SmileyImage: React.FC<SmileyImageProps> = React.memo(({
  imageUrl = 'missing.png',
  width = undefined,
  height = undefined
}) => {
  const h = getPseudoCssParamInt(imageUrl, 'h');
  const ph = getPseudoCssParamInt(imageUrl, 'ph', 30);
  const w = getPseudoCssParamInt(imageUrl, 'w');
  const pw = getPseudoCssParamInt(imageUrl, 'pw', 30);
  const baseline = getPseudoCssParamBool(imageUrl, 'b');
  const my = getPseudoCssParamInt(imageUrl, 'my', 0);
  const mx = getPseudoCssParamInt(imageUrl, 'mx', 0);
  const actualWidth = w || pw;
  const actualMx = w ? mx : 0;
  const actualHeight = h || ph;
  const actualMy = h ? my : 0;
  const maxWidth = Math.min(actualWidth, width ?? Number.MAX_SAFE_INTEGER);
  const maxHeight = Math.min(actualHeight, height ?? Number.MAX_SAFE_INTEGER);
  return <ImageGroup maxWidth={maxWidth} imageGroupData={{
    baseline: baseline,
    containerWidth: maxWidth,
    containerHeight: maxHeight,
    clickable: false,
    images: imageUrl.split('<>').map(url => ({
      url: fixImageUrl(url),
      w: Math.min(actualWidth, maxWidth),
      pw,
      ph,
      h: Math.min(actualHeight, maxHeight),
      b: 'not-set',
      clickPh: ph,
      clickPw: pw,
      clickH: h,
      clickW: w,
      linkUrl: undefined,
      clickUrl: undefined,
      clickToggleOnce: false,
      mx: actualMx,
      my: actualMy
    }))
  }} onClick={() => {
    return false;
  }} />;
});
SmileyImage.displayName = 'SmileyImage';
export function getPseudoCssParamInt(input: string, name: string): number | undefined;
export function getPseudoCssParamInt(input: string, name: string, def: number): number;
export function getPseudoCssParamInt(input: string, name: string, def?: number | undefined): any {
  const value = getPseudoCssParam(input, name);
  return value === undefined ? def : parseInt(value, 10);
}
export function getPseudoCssParamBool(input: string, name: string): boolean {
  return getPseudoCssParam(input, name) !== undefined;
}
export function getPseudoCssParam(input: string, name: string): string | undefined {
  // XXX does not handle "\\." in value!!!
  let result: string | undefined = new RegExp('.*\\.' + escapeRegExp(name) + '_([^.]+)\\..*').exec(input)?.[1];
  if (result === undefined) {
    result = new RegExp('.*\\.' + escapeRegExp(name) + '\\..*').test(input) ? '1' : undefined;
  }
  return result;
}
export function escapeRegExp(input: string): string {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function fixImageUrl(url: string): string {
  if (url.includes('cdnpet')) {
    return url;
  }
  const index = url.indexOf('...');
  if (index > -1) {
    const lastIndex = url.lastIndexOf('.');
    url = url.substring(0, index) + url.substring(lastIndex);
  }
  return 'https://cdnc.knuddelscom.de/pics/' + url;
}