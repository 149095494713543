import {
	MultipleUserOnlineOrChannelUpdated,
	MultipleUserOnlineUpdated,
	User,
	UserIsOnlineInChannel,
} from '@generated/graphql';
import { Disposable } from '@knuddels/std';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection/serviceIds';
import { UserWithOnlineChannelName } from '../../interfaces';
import { debounce } from '@knuddels-app/tools/debounce';
import { TypedSubscriptionOperation } from '@knuddels-app/Connection/client/Operation';

/**
 * Service to handle subscriptions to the online status of users.
 */
@injectable()
export class UserOnlineStatusService {
	public readonly dispose = Disposable.fn();

	private readonly userWithOnlineChannelCache: {
		[userId: string]: UserWithOnlineChannel;
	} = {};

	private readonly onlineStatusSubscriber: DebouncedMultiSubscriber;
	private readonly onlineStatusWithChannelSubscriber: DebouncedMultiSubscriber;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {
		this.onlineStatusSubscriber = this.dispose.track(
			new DebouncedMultiSubscriber(
				MultipleUserOnlineUpdated,
				authenticatedClientService
			)
		);

		this.onlineStatusWithChannelSubscriber = this.dispose.track(
			new DebouncedMultiSubscriber(
				MultipleUserOnlineOrChannelUpdated,
				authenticatedClientService
			)
		);
	}

	/**
	 * Subscribe to online status updates of the user with given id.
	 * Attention: we currently never unsubscribe until we logout.
	 * In the future we might want to add more logic to unsubscribe old/unused subscription
	 * and automatically refetch the current online status.
	 * @param id
	 */
	public subscribeToOnlineStatus(id: User['id']): void {
		this.onlineStatusSubscriber.subscribe(id);
	}

	public getUserWithOnlineChannel(id: User['id']): UserWithOnlineChannel {
		if (!this.userWithOnlineChannelCache[id]) {
			this.userWithOnlineChannelCache[id] = new UserWithOnlineChannel(
				id,
				this.authenticatedClientService
			);
			this.dispose.track(this.userWithOnlineChannelCache[id]);
			this.onlineStatusWithChannelSubscriber.subscribe(id);
		}
		return this.userWithOnlineChannelCache[id];
	}
}

class DebouncedMultiSubscriber implements Disposable {
	public readonly dispose = Disposable.fn();

	private subscribedUserIds: { [userId: string]: boolean } = {};
	private toSubscribeUserIds: string[] = [];

	private debouncedSubscribe = debounce(() => {
		if (this.toSubscribeUserIds.length > 0) {
			const unsubscribe = this.subscribeToManyUpdates(
				this.toSubscribeUserIds
			);
			this.dispose.track(unsubscribe);
			this.toSubscribeUserIds.forEach(
				id => (this.subscribedUserIds[id] = true)
			);
			this.toSubscribeUserIds = [];
		}
	}, 25);

	constructor(
		private operation: TypedSubscriptionOperation<unknown>,
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {}

	public subscribe(id: User['id']): void {
		if (this.subscribedUserIds[id]) {
			return;
		}

		this.toSubscribeUserIds.push(id);

		this.debouncedSubscribe();
	}

	private subscribeToManyUpdates(ids: User['id'][]): () => void {
		const {
			dispose,
		} = this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
			this.operation,
			{ ids },
			{}
		);
		return dispose;
	}
}

class UserWithOnlineChannel implements Disposable {
	dispose = Disposable.fn();

	private readonly watchQuery = this.authenticatedClientService.currentK3Client.watchQuery(
		UserIsOnlineInChannel,
		{ id: this.userId },
		// might need to do a network query here to be sure we are in sync with the server
		'cache-first'
	);

	constructor(
		private readonly userId: User['id'],
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {
		this.dispose.track(this.watchQuery);
	}

	public get userWithOnlineChannelName(): UserWithOnlineChannelName {
		const user =
			this.watchQuery.value !== 'loading' &&
			this.watchQuery.value !== 'error' &&
			this.watchQuery.value.user &&
			this.watchQuery.value.user.user;
		if (!user) {
			// default value
			return {
				isOnline: false,
				currentOnlineChannelName: null,
			};
		}

		return {
			isOnline: user.isOnline,
			// prevent inconsistency (can't be not online but in a channel)
			currentOnlineChannelName: user.isOnline
				? user.currentOnlineChannelName
				: null,
		};
	}
}
