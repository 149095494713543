import * as React from 'react';
import { Flex, FlexCol, SvgRenderer, BoxProps, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import { PrivateMessageFooter } from './PrivateMessageFooter';
export const RadialHeaderMessage: React.FC<{
  radialInnerColor: string;
  radialOuterColor: string;
  headerIcon?: React.ReactElement;
  px?: BoxProps['px'];
  mb?: BoxProps['mb'];
  showConfetti?: boolean;
}> = ({
  children,
  radialInnerColor,
  radialOuterColor,
  headerIcon,
  px,
  mb,
  showConfetti
}) => {
  return <div className={_c0}>
			<div className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{showConfetti && <Confetti />}
				<div style={{
        paddingLeft: resolveThemingValue(px || 'xlarge', "spacing", useTheme()),
        paddingRight: resolveThemingValue(px || 'xlarge', "spacing", useTheme())
      }} className={_c2}>
					<div style={{
          marginBottom: resolveThemingValue(mb || 'base', "spacing", useTheme())
        }} className={_c3}>
						{children}
					</div>
				</div>
				<div className={_c4}>
					<div className={_c5}>
						<RadialBackground innerColor={radialInnerColor} outerColor={radialOuterColor} />
						<div className={_c6}>
							{headerIcon}
						</div>
					</div>
				</div>
			</div>
			<Spacer size={'minor'} />
			<PrivateMessageFooter />
		</div>;
};
const RadialBackground: React.FC<{
  innerColor: string;
  outerColor: string;
}> = ({
  innerColor,
  outerColor
}) => {
  return <div style={({
    width: 72,
    height: 72,
    background: `radial-gradient(${innerColor}, ${outerColor})`
  } as any)} />;
};
const Confetti: React.FC = () => {
  return <>
			<div className={_c7}>
				<ConfettiLeft />
			</div>

			<div className={_c8}>
				<ConfettiRight />
			</div>
		</>;
};
const ConfettiLeftSvg = `
<svg width="74" height="35" viewBox="0 0 74 35" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle opacity="0.6" cx="19.5" cy="4.5" r="4.5" fill="#D0FBF2"/>
	<circle cx="46.5" cy="30.5" r="4.5" fill="#FBB06A"/>
	<circle cx="71" cy="10" r="3" fill="#FBB06A"/>
	<circle cx="3" cy="18" r="3" fill="#FB6A8E"/>
</svg>
`;
const ConfettiRightSvg = `
<svg width="66" height="36" viewBox="0 0 66 36" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle opacity="0.6" cx="31.5" cy="31.5" r="4.5" fill="#D0FBF2"/>
	<circle cx="44" cy="3" r="3" fill="#FB6A8E"/>
	<circle cx="5.33531" cy="11.3353" r="4.5" transform="rotate(129.564 5.33531 11.3353)" fill="#FB6A8E"/>
	<circle cx="61.3353" cy="17.3353" r="4.5" transform="rotate(129.564 61.3353 17.3353)" fill="#FBB06A"/>
</svg>
`;
export const ConfettiLeft: React.FC = () => {
  return <SvgRenderer svg={ConfettiLeftSvg} width={74} height={35} color={'transparent'} />;
};
export const ConfettiRight: React.FC = () => {
  return <SvgRenderer svg={ConfettiRightSvg} width={66} height={36} color={'transparent'} />;
};
const _c0 = " Knu-FlexCol alignItems-center mt-small ";
const _c1 = " Knu-FlexCol position-relative overflow-visible shadow-Shadow2 bg-contentLightBg borderRadius-large mt-36px mx-xlarge width-288px ";
const _c2 = " Knu-FlexCol overflow-hidden position-relative borderRadius-large ";
const _c3 = " Knu-FlexCol mt-xxlarge position-relative alignItems-center ";
const _c4 = " Knu-Flex position-absolute left-none right-none justifyContent-center ";
const _c5 = " Knu-FlexCol position-relative mt--36px width-72px height-72px borderRadius-circle zIndex-1 overflow-hidden shadow-Shadow2 ";
const _c6 = " Knu-FlexCol position-absolute width-72px height-72px ";
const _c7 = " Knu-FlexCol position-absolute left-14px top-4px ";
const _c8 = " Knu-FlexCol position-absolute right-18px top-7px ";