import * as inversify from 'inversify';
import { Binding } from '../ServiceBinder';
import { MutableContainer } from './Container';
import {
	ServiceIdMap,
	TranslateServiceIds,
	getServices,
} from '../../getServices';

export class MutableContainerImpl implements MutableContainer {
	constructor(public readonly inversifyContainer: inversify.Container) {}

	/**
	 * Immediately registers a binding to the underlying inversify container.
	 */
	public add(binding: Binding): void {
		binding.addToContainer(this.inversifyContainer);
	}

	public getServices<TServiceIds extends ServiceIdMap>(
		serviceIds: TServiceIds
	): TranslateServiceIds<TServiceIds> {
		return getServices(serviceIds, this.inversifyContainer);
	}

	public createSubContainer(): MutableContainer {
		const c = new inversify.Container();
		c.parent = this.inversifyContainer;
		return new MutableContainerImpl(c);
	}
}
