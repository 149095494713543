const t = {
  none: {
    position: "relative"
  },
  "bottom-right": {
    position: "absolute",
    bottom: "large",
    right: "large"
  }
}, n = (o) => t[o.anchor ?? "none"];
export {
  t as AncorDefinitions,
  n as useAnchor
};
