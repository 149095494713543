/**
 * We use dependency injection to import all view providers
 * rather than let the modules register their views.
 * This has the advantage that the ViewService
 * has access to all registered views right on construction.
 * Otherwise, services could add views too late and urls might not be resolvable.
 */

import { newServiceId } from '@knuddels-app/DependencyInjection';
import { ViewRegistry } from './ViewService';

/**
 * A view provider binds a view implementation to a view id.
 * A view id should be exported so that other components can open or close the view
 * associated to the view.
 */
export interface ViewProvider {
	registerViews(viewRegistry: ViewRegistry): void;
}

export const $ViewProvider = newServiceId<ViewProvider>('$ViewProvider');
