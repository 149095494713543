import * as React from 'react';
import { useContext } from 'react';
import { ProfileAlbumInfo } from '../../profileQueryHelper';
import { UserForProfileFragment } from '@generated/graphql';
import { ProfileTabsMapping } from '../ProfileTabsMapping';
import { AlbumPhotoBar } from './Photos/AlbumPhotoBar';
import { ProfileNoDetailsAvailable } from './ProfileNoDetailsAvailable';
import { detailCategories, knuddelsCategories, ProfileEntryData } from './profileEntries';
import { formatMessage } from '@knuddels-app/i18n';
import { FormattedText, Spacer } from '@shared/components';
import { CategoryHeader, FlexCol, ScrollView } from '@knuddels/component-library';
import { ProfileEntry, ProfileEntryChannel, ProfileEntryChannels, ProfileEntryDate, ProfileEntryFormattedText, ProfileEntryNick, ProfileEntryNicks, ProfileEntryNumber, ProfileEntryText, ProfileEntryTextList, ProfileEntryTime } from './ProfileEntry';
import { DisableScrollContext } from '@shared/helper/DisableScrollContext';
export const DetailsTabContent = React.memo(({
  user,
  isCurrentUser,
  photoData,
  onTabChange
}: {
  photoData: ProfileAlbumInfo;
  isCurrentUser: boolean;
  user: UserForProfileFragment;
  onTabChange: (index: number) => void;
}) => {
  const changeToAlbumTab = React.useCallback(() => {
    onTabChange(ProfileTabsMapping.photos);
  }, [onTabChange]);
  return <div className={_c0}>
				<ScrollViewWithDisableScrollContext>
					<div onPointerDown={() => {
        /* this is required for scrolling on ipads. otherwise no touch events are processed */
      }} className={_c1}>
						{photoData && <AlbumPhotoBar userId={user.id} photos={photoData.albumPhotos} changeToAlbumTab={changeToAlbumTab} />}
						<Categories user={user} isCurrentUser={isCurrentUser} />
					</div>
				</ScrollViewWithDisableScrollContext>
			</div>;
});
DetailsTabContent.displayName = 'DetailsTabContent';
const Categories: React.FC<{
  user: UserForProfileFragment;
  isCurrentUser: boolean;
}> = ({
  user,
  isCurrentUser
}) => {
  const categories = useCategoriesToRender(user, isCurrentUser);
  return <div className={_c2}>
			{categories.length === 0 ? <ProfileNoDetailsAvailable /> : categories.map((category, index) => <React.Fragment key={'category-' + index}>
						{category.category.headerFormatId && <CategoryHeader title={formatMessage(category.category.headerFormatId)} />}
						<Spacer size={'tiny'} />
						{category.entries}
					</React.Fragment>)}
		</div>;
};
const useCategoriesToRender = (user: UserForProfileFragment, isCurrentUser: boolean) => {
  return React.useMemo(() => [...detailCategories, ...knuddelsCategories].map(category => {
    const renderedEntries = category.entries.map(it => renderEntry(user, isCurrentUser, it)).filter(it => it !== null);
    return {
      category,
      entries: renderedEntries
    };
  }).filter(it => it.entries.length > 0), [user, isCurrentUser]);
};
const renderEntry = (user: UserForProfileFragment, isCurrentUser: boolean, entry: ProfileEntryData): React.ReactNode => {
  const title = formatMessage(entry.titleFormatId);
  const props = {
    key: entry.titleFormatId.id,
    title: title,
    image: typeof entry.icon === 'function' ? entry.icon(user) : entry.icon
  };
  let contentComponent: React.ReactNode | null = null;
  if (entry.type === 'text') {
    const content = entry.content(user);
    if (content) {
      contentComponent = <ProfileEntryText content={content} />;
    }
  } else if (entry.type === 'formattedText') {
    const content = entry.content(user);
    if (content && !FormattedText.isEmpty(content)) {
      contentComponent = <ProfileEntryFormattedText content={content} />;
    }
  } else if (entry.type === 'textList') {
    const content = entry.content(user);
    if (content && content.length > 0) {
      contentComponent = <ProfileEntryTextList content={content} />;
    }
  } else if (entry.type === 'number') {
    const content = entry.content(user);
    if (content) {
      contentComponent = <ProfileEntryNumber content={content} />;
    }
  } else if (entry.type === 'date') {
    const content = entry.content(user);
    if (content) {
      contentComponent = <ProfileEntryDate content={content} />;
    }
  } else if (entry.type === 'time') {
    const content = entry.content(user);
    if (content) {
      contentComponent = <ProfileEntryTime content={content} />;
    }
  } else if (entry.type === 'custom') {
    const content = entry.content(user, isCurrentUser);
    if (content) {
      contentComponent = content;
    }
  } else if (entry.type === 'channel') {
    const content = entry.content(user);
    if (content) {
      contentComponent = <ProfileEntryChannel channel={content} />;
    }
  } else if (entry.type === 'channels') {
    const content = entry.content(user);
    if (content && content.length > 0) {
      contentComponent = <ProfileEntryChannels channels={content} />;
    }
  } else if (entry.type === 'nick') {
    const content = entry.content(user);
    if (content) {
      contentComponent = <ProfileEntryNick nick={content} />;
    }
  } else if (entry.type === 'nicks') {
    const content = entry.content(user);
    if (content && content.length > 0) {
      contentComponent = <ProfileEntryNicks nicks={content} />;
    }
  }
  if (contentComponent) {
    return <ProfileEntry {...props}>{contentComponent}</ProfileEntry>;
  }
  return null;
};
const ScrollViewWithDisableScrollContext: React.FC = props => {
  const disableScroll = useContext(DisableScrollContext);
  /**
   * TODO: fix disableScroll in compiler if prop is used often
   */
  return <div style={{
    overflowY: disableScroll ? 'hidden' : 'auto'
  }} className={_c3}>
			{props.children}
		</div>;
};
const _c0 = " Knu-FlexCol width-100-percent position-relative ";
const _c1 = " Knu-FlexCol ";
const _c2 = " Knu-FlexCol flex-1 pt-base pb-xxlarge ";
const _c3 = " Knu-ScrollView width-100-percent ";