import { $I18n } from '@knuddels-app/i18n';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import {
	DisableTanSystem,
	EnableTanSystem,
	GetTanSystemStatus,
	TanEnabledInput,
	TanSystemStatusFragment,
	TanSystemSubscription,
	UpdateTanSystemStatus,
} from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { ERROR_SAVING_SETTINGS } from '../shared-i18n';
import { $CommandService } from '@knuddels-app/Commands';
import { $ViewService } from '@knuddels-app/layout';
import { settingsViewId } from '@knuddelsModules/Settings';

@injectable()
export class TanSystemService {
	public readonly dispose = Disposable.fn();

	public get status(): TanSystemStatusFragment | null {
		return this._status;
	}

	@observable
	private _status: TanSystemStatusFragment | null = null;

	constructor(
		@inject($AuthenticatedClientService)
		private authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private i18n: typeof $I18n.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T
	) {
		if (globalEnv.product !== 'stapp-messenger') {
			this.init();
			this.dispose.track(this.subscribeToChanges());
		}
	}

	@action
	public async enable(): Promise<boolean> {
		return await this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(EnableTanSystem, {})
			.match({
				ok: data => this.handleSuccess(data.__typename === 'Success'),
				error: this.handleError,
			});
	}

	@action
	public async disable(): Promise<boolean> {
		return await this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(DisableTanSystem, {})
			.match({
				ok: data => this.handleSuccess(data.__typename === 'Success'),
				error: this.handleError,
			});
	}

	@action
	public async update(input: TanEnabledInput): Promise<boolean> {
		return await this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(UpdateTanSystemStatus, { status: input })
			.match({
				ok: data => this.handleSuccess(data.__typename === 'Success'),
				error: this.handleError,
			});
	}

	@action
	private updateStatusFromRemote(status: TanSystemStatusFragment): void {
		this._status = status;
	}

	private init(): void {
		this.dispose.track(
			this.commandService.registerCommand({
				commandName: 'tan',
				invoke: async (): Promise<void> => {
					this.viewService.openView(
						settingsViewId.with(s => s.withPath('AccountSettings'))
					);
				},
				shouldInvoke(parameter: string): boolean {
					const trimmedParameter = parameter.toLowerCase().trim();
					return (
						trimmedParameter === 'settings' ||
						trimmedParameter === 'status'
					);
				},
			})
		);

		this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(GetTanSystemStatus, {}, 'cache-first')
			.then(result => {
				result.match({
					ok: data => {
						runInAction('set initial tan status', () => {
							this._status = data;
						});
					},
					error: err => {
						// ignore error
						console.error(err);
					},
				});
			});
	}

	private subscribeToChanges(): Disposable {
		return this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
			TanSystemSubscription,
			{},
			{
				next: result => {
					this.updateStatusFromRemote(result);
				},
			}
		);
	}

	private handleSuccess(isSuccess: boolean): boolean {
		return isSuccess ? true : this.handleError();
	}

	private handleError(): boolean {
		this.snackbarService.showGenericError(
			ERROR_SAVING_SETTINGS.format(this.i18n)
		);

		return false;
	}
}
