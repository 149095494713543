import * as React from 'react';
import { Flex, Text, ThemeOverride } from '@knuddels/component-library';
export const CategoryHeader: React.FC<{
  title: string;
  button?: React.ReactNode;
}> = ({
  title,
  button
}) => <Flex pt={'xlarge'} pb={'minor'} pl={'large'} overflow={'visible'} ariaLabel={title} ariaRole={'group'} alignItems={'center'} justifyContent={'space-between'}>
		<div className={_c0}>
			<Text type={'body2'} state={'tertiary'} bold={true} className={_c1}>
				{title.toUpperCase()}
			</Text>
		</div>
		{button}
	</Flex>;
const _c0 = " Knu-Flex my-5px ";
const _c1 = "  ";