import * as React from 'react';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';

export const useTrackProfileView = () => {
	const logEvent = useLogEvent();

	return React.useCallback(
		(itemId: string) => {
			logEvent('Profile_Profileview', itemId);
		},
		[logEvent]
	);
};

export const useTrackProfileViewSnackbar = () => {
	const track = useTrackProfileView();

	return (snackbarMessage: string) => {
		track(`Snackbar_${snackbarMessage}Shown`);
	};
};
