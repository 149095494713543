import { DontUseThisMemberInANonTypePositionError } from '@knuddels/std';

/**
 * Represents a typed service id.
 * Implementations with appropriate types can be bound
 * to such an id with a container.
 * The container can then be queried
 * to get instances of this type bound to this id.
 */
export interface ServiceId<T> {
	/**
	 * The type of the service.
	 * Do not access this field at runtime.
	 */
	readonly T: T;
	readonly TLazy: () => Promise<T>;
	readonly id: string;
}

export function newServiceId<T>(
	/* matchesAssignmentTarget:asIs */ id: string
): ServiceId<T> {
	const o = {
		id,
	};
	for (const propName of ['T', 'TLazy']) {
		Object.defineProperty(o, propName, {
			get: () => {
				throw new DontUseThisMemberInANonTypePositionError();
			},
			// To prevent accidental evaluation of type-only properties in debugging environments,
			// we set enumerable to false.
			enumerable: false,
		});
	}

	return o as any;
}
