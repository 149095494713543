import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import {
	AllSmileyIds,
	RecentSmileys,
	SmileyboxChanged,
	SmileyDetails,
	SmileyListType,
} from '@generated/graphql';
import { action, observable } from '@knuddels-app/mobx';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { Disposable } from '@knuddels/std/dist/src/disposable';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

export type SmileyId = SmileyDetails['id'];

const RECENT_SMILEYS_LIMIT = 10;

@injectable()
export class SmileyboxService {
	@observable.shallow
	_recentSmileyIds: readonly SmileyId[] = [];
	@observable
	_allSmileyIds: readonly SmileyId[] = [];
	@observable private _loading = true;

	private readonly disposable = Disposable.fn();

	constructor(
		@inject($MessageFormatProvider)
		private readonly messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		this.queryAllSmileys();
		this.startSubscription();
	}

	get recentSmileyIds(): readonly SmileyId[] {
		return this._recentSmileyIds;
	}

	get allSmileyIds(): readonly SmileyId[] {
		return this._allSmileyIds;
	}

	get isLoading(): boolean {
		return this._loading;
	}

	@action
	private async queryAllSmileys(): Promise<void> {
		// Update fields when both recent and all smiley IDs are fetched to prevent flashing and content jumping
		// In case of error, don't update the list
		const [all = this.allSmileyIds, recent = this.recentSmileyIds] =
			await Promise.all<readonly SmileyId[]>([
				this.authenticatedClientService.currentK3Client
					.queryWithResultPromise(AllSmileyIds, {})
					.match({
						ok: smileyIds => smileyIds.map(smileyId => smileyId.id),
						error: err => {
							// TODO Handle error
							console.warn(err);
							return [];
						},
					}),
				this.authenticatedClientService.currentK3Client
					.queryWithResultPromise(RecentSmileys, {
						limit: RECENT_SMILEYS_LIMIT,
					})
					.match({
						ok: smileyDetails =>
							smileyDetails.map(smiley => smiley.id),
						error: err => {
							// TODO Handle error
							console.warn(err);
							return [];
						},
					}),
			]);

		this._loading = false;

		this.updateAllSmileys(all);
		this.updateRecentlyUsedSmileys(recent);
	}

	private startSubscription(): void {
		this.disposable.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				SmileyboxChanged,
				{},
				{
					next: data => {
						if (data.listType === SmileyListType.AllUsable) {
							this.updateAllSmileys(
								data.smileys.map(smiley => smiley.id)
							);
						} else if (
							data.listType === SmileyListType.RecentlyUsed
						) {
							this.updateRecentlyUsedSmileys(
								data.smileys.map(smiley => smiley.id)
							);
						} else {
							// Don't throw here to not prevent the backend to extend the list types.
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							const checkTypeToNotForgetUpdatingThis: never =
								data.listType;
						}
					},
				}
			)
		);
	}

	@action.bound
	private updateAllSmileys(smileyIds: readonly SmileyId[]): void {
		this._allSmileyIds = smileyIds;
	}
	@action.bound
	private updateRecentlyUsedSmileys(smileyIds: readonly SmileyId[]): void {
		// Slice array in client because it's simpler than doing it in the backend
		// and allows for runtime changes of the limit without resubscribing (The
		// subscription has no `limit` parameter currently)
		this._recentSmileyIds = smileyIds.slice(0, RECENT_SMILEYS_LIMIT);
	}
}
