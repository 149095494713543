import { ProfileUser } from '../profileQueryHelper';
import { useTrackProfileView } from './useTrackProfileView';
import { useEffect, useRef } from 'react';
import { FriendState } from '@generated/graphql';

export const useTrackFriendStateOnProfileOpen = (
	currentUserId: string,
	user?: ProfileUser
) => {
	const track = useTrackProfileView();
	const alreadyTracked = useRef(false);

	useEffect(() => {
		if (alreadyTracked.current || !user || currentUserId === user.id) {
			return;
		}
		alreadyTracked.current = true;

		if (user.friendState === FriendState.RequestReceived) {
			track('OpenFriendrequest_Available');
		} else if (
			user.friendState === FriendState.Friends &&
			!user.hasSeenProfileAfterFriendshipStarted
		) {
			track('OpenFriendrequest_FriendshipAchieved');
		}
	}, [user]);
};
