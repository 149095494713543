import * as React from 'react';
import { ServiceId } from '@knuddels-app/DependencyInjection';
import { Module } from './Module';
import { LoadService } from './LoadService';

/**
 * Use this HOC to make a component exported by a service standalone.
 * You can omit the module if it is a known module.
 * Example:
 * ```ts
 * export const LandingpageWrapperLoadable = LoadFromService({
 *  module: landingpageModule,
 *  service: $LandingpageService,
 *  selector: s => s.LandingpageWrapper,
 * });
 * ```
 */
export function LoadFromService<TService, TProps>(data: {
  module?: Module;
  service: ServiceId<TService>;
  selector: (s: TService) => React.ComponentType<TProps>;
  loading?: () => JSX.Element;
}): React.ComponentType<TProps> {
  // eslint-disable-next-line react/display-name
  return (props: any) => <LoadService module={data.module} service={data.service} loading={data.loading}>
			{s => {
      const C = data.selector(s);
      return <C {...props} />;
    }}
		</LoadService>;
}