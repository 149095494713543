import * as React from 'react';
import { ProfileBaseProps } from '../ProfileBase';
import { InteractionFabMenu } from './InteractionFabMenu/InteractionFabMenu';
import { FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Z_INDEX } from '@shared/components';
import { OpenEditProfileButton } from './OpenEditProfileButton';
import { Disposable } from '@knuddels/std';
import { SHOW_FAB_ON_TAB_INDEX } from '../constants';
import { SelectedProfileTabContext } from '../SelectedProfileTabContext';
const ProfileFABHidingContext = React.createContext<boolean>(false);
const ProfileFABHiderContext = React.createContext<() => Disposable>(() => Disposable.create());
export const HideFAB: React.FC = ({
  children
}) => {
  const hide = React.useContext(ProfileFABHiderContext);
  React.useEffect(() => {
    return hide().dispose;
  });
  return <>{children}</>;
};
export const ProfileFABHiderContextProvider: React.FC = ({
  children
}) => {
  const [hiderCount, setHiderCount] = React.useState(0);
  const hide = React.useCallback(() => {
    setHiderCount(prevState => prevState + 1);
    return Disposable.create(() => {
      setHiderCount(prevState => prevState - 1);
    });
  }, [setHiderCount]);
  return <ProfileFABHiderContext.Provider value={hide}>
			<ProfileFABHidingContext.Provider value={hiderCount > 0}>
				{children}
			</ProfileFABHidingContext.Provider>
		</ProfileFABHiderContext.Provider>;
};
export const ProfileFAB = React.memo(({
  user,
  currentUserId,
  conversationId,
  onClose
}: ProfileBaseProps) => {
  const shouldBeHidden = React.useContext(ProfileFABHidingContext);
  if (shouldBeHidden) {
    return null;
  }
  return <>
				{currentUserId !== user.id ? <InteractionFabMenu user={user} conversationId={conversationId} onClose={onClose} /> : <SelectedProfileTabContext.Consumer>
						{({
        activeTabIndex
      }) => SHOW_FAB_ON_TAB_INDEX.includes(activeTabIndex) && <div style={{
        zIndex: resolveThemingValue(Z_INDEX.HIGHEST_CONTENT, "theme", useTheme())
      }} className={_c0}>
									<OpenEditProfileButton />
								</div>}
					</SelectedProfileTabContext.Consumer>}
			</>;
});
ProfileFAB.displayName = 'ProfileFAB';
const _c0 = " Knu-FlexCol position-absolute right-large bottom-large overflow-visible ";