import * as React from 'react';
import { useState } from 'react';
import { MentorConversationStartedMiniStatusBar } from './MentorConversationStartedMiniStatusBar';
import { MentorMiniStatusBar } from './MentorMiniStatusBar';
export const ConversationMentorStatusBar: React.FC<{
  hasNoMessagesInConversation: boolean;
  onPress?(): void;
}> = props => {
  const [showConversationStartedStatusBar, setShowConversationStartedStatusBar] = useState(props.hasNoMessagesInConversation);
  return showConversationStartedStatusBar ? <MentorConversationStartedMiniStatusBar onPress={props.onPress} onProgressChangeAnimated={() => {
    if (!props.hasNoMessagesInConversation) {
      setTimeout(() => {
        setShowConversationStartedStatusBar(false);
      }, 1000);
    }
  }} /> : <MentorMiniStatusBar onPress={props.onPress} />;
};