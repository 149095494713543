import * as React from 'react';
import { ConversationMessageFragment, FullConversationFragment, Scalars, User } from '@generated/graphql';
import { ActivityIndicator, LineDividerWithText, SpacerSizes } from '@shared/components';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { SendingImage } from '../../../../services/conversationServices/MessengerConversationService';
import { SendImageBubbleProps } from './SendImageBubble';
import { MessageLightboxSource } from '../../Lightbox/Lightbox';
import { MessengerChatMessageProps } from './MessengerChatMessage';
import { SendingMessage } from '../../../../services/conversationServices/SendingMessage';
import { getMessageDayText } from '@knuddels-app/tools/getMessageDayText';
import { FriendRecommendationData } from '../../../../services/conversationServices/FriendRecommendationsState';
import { ReceivedFriendRequest } from '@knuddelsModules/Contacts';
import { Flex, useTheme } from '@knuddels/component-library';
export interface TimeDividerData {
  type: 'timeDivider';
  uniqueKey: string;
  time: string;
}
export interface UnreadDividerData {
  type: 'unreadDivider';
  uniqueKey: string;
}
export interface SpinnerData {
  type: 'spinner';
  uniqueKey: string;
}
export interface SendImageBubbleData {
  type: 'sendImageBubble';
  uniqueKey: string;
  isStackedLayout: boolean;
  withArrow: boolean;
  sendingImage: SendingImage;
}
export interface MessageData {
  type: 'message';
  uniqueKey: string;
  isStackedLayout: boolean;
  activeUserId: User['id'];
  activeUserNick: User['nick'];
  conversation: FullConversationFragment;
  message: ConversationMessageFragment;
  showUserImage?: boolean;
  hasArrow?: boolean;
  setLightboxSrc: (src: MessageLightboxSource) => void;
  openProfile: (uid: User['id']) => void;
}
export interface SendingMessageData {
  type: 'sendingMessage';
  uniqueKey: string;
  isStackedLayout: boolean;
  receiverId: string;
  activeUserId: User['id'];
  message: SendingMessage;
  hasArrow?: boolean;
  animate?: boolean;
  showLoadingIndicator?: boolean;
}
export interface RetryMessageData {
  type: 'retryMessage';
  uniqueKey: string;
  isStackedLayout: boolean;
  retry(): void;
}
export interface SpacerData {
  type: 'spacer';
  uniqueKey: string;
  size: SpacerSizes | number;
}
export interface FriendRecommendationListData {
  type: 'friendRecommendation';
  uniqueKey: string;
  isNewestMessage: boolean;
  recommendation: FriendRecommendationData;
  user: Pick<User, 'id' | 'nick' | 'gender'>;
}
export interface ReceivedFriendRequestListData {
  type: 'receivedFriendRequest';
  uniqueKey: string;
  request: ReceivedFriendRequest;
  onAccepted: () => void;
  onAcceptError: () => void;
}
export interface MentorSystemHintData {
  type: 'mentorSystemHint';
  uniqueKey: string;
  hasNoMessages: boolean;
  menteeNick: string;
}
export type ConversationItemData = TimeDividerData | UnreadDividerData | SpinnerData | SendImageBubbleData | MessageData | SendingMessageData | RetryMessageData | SpacerData | FriendRecommendationListData | ReceivedFriendRequestListData | MentorSystemHintData;
export const TimeDivider = ({
  uniqueKey,
  time
}: TimeDividerData) => {
  return <LineDividerWithText key={uniqueKey} text={time} lineStyle={'none'} />;
};
export const UnreadDivider = ({
  uniqueKey
}: UnreadDividerData) => {
  return <LineDividerWithText key={uniqueKey} text={formatMessage(declareFormat({
    id: 'NEW_MESSAGES_MESSAGE',
    defaultFormat: 'NEW MESSAGES'
  })).toUpperCase()} colorPreset={'accent'} lineStyle={'dashed'}
  // ref={scrollToElementRef}
  testId={'new-message-divider'} />;
};
export const Spinner = ({
  uniqueKey
}: SpinnerData) => {
  const theme = useTheme();
  return <div key={uniqueKey} className={_c0}>
			<ActivityIndicator size={'small'} color={theme.colors.basic.accent} />
		</div>;
};
export function SendImageBubbleDataToSendImageBubbleProps({
  isStackedLayout,
  sendingImage,
  withArrow
}: SendImageBubbleData): SendImageBubbleProps {
  return {
    isStackedLayout: isStackedLayout,
    withArrow: withArrow,
    sendingImage: sendingImage
  };
}
export function messageDataToMessengerChatMessageProps({
  activeUserId,
  activeUserNick,
  conversation,
  isStackedLayout,
  message,
  setLightboxSrc,
  openProfile,
  showUserImage,
  hasArrow
}: MessageData): MessengerChatMessageProps {
  const sender = message.sender;
  return {
    message: message,
    conversationId: conversation.id,
    senderId: sender.id,
    senderNick: sender.nick,
    receiverId: activeUserId === sender.id ? conversation.otherParticipants[0].id : activeUserId,
    receiverNick: activeUserId === sender.id ? conversation.otherParticipants[0].nick : activeUserNick,
    activeUserId: activeUserId,
    isStackedLayout: isStackedLayout,
    setLightboxSrc: setLightboxSrc,
    openProfile: openProfile,
    showUserImage: showUserImage,
    hasArrow: hasArrow
  };
}
export function generateSets(lastReadIndex: Scalars['ID'], messages: readonly ConversationMessageFragment[], sendingMessages: readonly SendingMessage[]): (ConversationMessageFragment | SendingMessage)[][] {
  const sets: (ConversationMessageFragment | SendingMessage)[][] = [];
  const mergedMessages = [...messages, ...sendingMessages].sort((a, b) => +a.timestamp - +b.timestamp);
  let set: (ConversationMessageFragment | SendingMessage)[] = [];
  mergedMessages.forEach(msg => {
    if (set.length === 0 || set[0].sender.id === msg.sender.id && getMessageDayText(+set[0].timestamp) === getMessageDayText(+msg.timestamp)) {
      set.push(msg);
    } else {
      sets.push(set);
      set = [msg];
    }
    if (lastReadIndex && msg.id === lastReadIndex && set.length > 0) {
      sets.push(set);
      set = [];
    }
  });
  if (set.length > 0) {
    sets.push(set);
  }
  return sets;
}
const _c0 = " Knu-Flex justifyContent-center position-relative pr-small ";