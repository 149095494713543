import * as React from 'react';
import { debounce } from '../../../tools';
import { ScrollableContainerProps } from './ScrollContainerProps';
import styles from './ScrollContainer.module.scss';
export class ScrollContainer extends React.PureComponent<ScrollableContainerProps> {
  private debounce: any;
  private unmounting = false;
  componentDidMount(): void {
    if (this.props.autoScroll) {
      this.debounce = debounce(this.scrollToBottom);
      window.addEventListener('resize', this.debounce);
      this.scrollToBottom();
    }
  }
  componentWillUnmount(): void {
    this.unmounting = true;
    window.removeEventListener('resize', this.debounce);
  }
  componentDidUpdate(): void {
    if (this.props.autoScroll) {
      this.scrollToBottom();
    }
  }
  private scrollToBottom = (): void => {
    if (this.unmounting) {
      return;
    }
    if (this.props.autoScroll) {
      // TODO refactor to not use id
      // maybe steal the code and just use ref
      if (this.props.id) {
        document.getElementById(this.props.id)?.scrollTo({
          top: 1000000,
          behavior: 'smooth'
        });
      }
    }
  };
  render(): JSX.Element {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      autoScroll,
      className,
      horizontal,
      fluid = true,
      ...props
    } = this.props;
    const mainClassName = horizontal ? styles.horizontal : styles.default;
    const classes = mainClassName + (fluid ? ' ' + styles.fluid : '') + (className ? ' ' + className : '');
    return <div className={classes} {...props} />;
  }
}