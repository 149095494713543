import * as i from "react";
import { useTheme as c } from "./useTheme.js";
const f = (t, n) => {
  const o = c();
  return i.useMemo(
    () => m(o, t),
    [o, ...n]
  );
};
function m(t, n) {
  return { ...u(t, n), $$Cache: /* @__PURE__ */ new Map() };
}
function u(t, n) {
  const o = {};
  for (let e in t) {
    if (!t.hasOwnProperty(e))
      continue;
    const r = n[e];
    if (r == null) {
      o[e] = t[e];
      continue;
    }
    if (typeof r != "object") {
      o[e] = n[e];
      continue;
    }
    o[e] = u(t[e], n[e]);
  }
  return o;
}
export {
  m as createCustomTheme,
  f as useChildTheme
};
