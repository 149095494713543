import { NotificationCallToAction, NotificationFragment, Notifications } from '@generated/graphql';
import { $ViewService } from '@knuddels-app/layout';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { FormattedText } from '@shared/components';
import { $CommandService, $CommandWithoutChannelService } from '@knuddels-app/Commands';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { Disposable, last } from '@knuddels/std';
import { getPixelRatio } from '@shared/components/helper/getPixelRatio';
import * as React from 'react';
import { NotificationProps } from '../components/Notification';
import { NotificationContentFromSubscription } from '../components/NotificationContentFromSubscription';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $Environment } from '@knuddels-app/Environment';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
const DEFAULT_LIFETIME = 10000;
let nextId = 0;
type NotificationData = {
  content: React.ReactElement;
  controller: NotificationBarController;
};
type NotificationInputData = {
  lifeTime?: number;
  renderContent: (close: () => void) => React.ReactElement;
  onHidden?: () => void;
};
type NotificationComponentProps = NotificationProps & {
  id: string;
};
export type SlashCommandHandler = (command: string) => void;
@injectable()
export class NotificationBarService {
  public readonly dispose = Disposable.fn();
  @observable
  public currentNotification: NotificationComponentProps | undefined;
  private notifications: NotificationComponentProps[] = [];
  private slashCommandHandlers: Map<string, SlashCommandHandler> = new Map();
  constructor(@inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($AuthenticatedClientService)
  authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($NativeWebViewService)
  private nativeWebViewService: typeof $NativeWebViewService.T, @inject($KeyboardService)
  private readonly keyboardService: typeof $KeyboardService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($CommandWithoutChannelService)
  private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {
    authenticatedClientService.currentK3Client.subscribeToPrimaryData(Notifications, {
      pixelDensity: getPixelRatio()
    }, {
      next: data => {
        if (!this.shouldDisplayNotification(data)) {
          return;
        }
        const trackingConfig = getTrackingForKey(data.key);
        if (trackingConfig) {
          this.firebaseAnalyticsService.logEvent(`InAppNotification_${trackingConfig[0]}`, trackingConfig[1] + '_Shown');
        }
        if (this.nativeWebViewService.isEnabled) {
          this.renderNativeNotification(data);
          return;
        }
        this.showNotification({
          lifeTime: DEFAULT_LIFETIME,
          renderContent: close => <NotificationContentFromSubscription close={close} notification={data} />
        });
      }
    });
  }
  private renderNativeNotification(notification: NotificationFragment): void {
    this.nativeWebViewService.renderNotification({
      title: notification.title,
      body: FormattedText.asText(FormattedText.fromJsonString(notification.description), false),
      ctaText: notification.callToAction?.text,
      iconUrl: notification.icon?.url ?? '',
      iconColor: notification.icon?.subtitle?.color,
      iconText: notification.icon?.subtitle?.text,
      onPress: () => {
        this.handleNotificationPress(notification);
      }
    });
  }
  @action.bound
  private pushNotification(notification: NotificationData, onHiddenCallback?: () => void): void {
    const notificationProps: NotificationComponentProps = {
      ...notification,
      id: `${nextId++}`,
      onComponentHidden: () => {
        this.notifications = this.notifications.splice(1);
        runInAction('Update current notification', () => {
          this.currentNotification = this.notifications[0];
          this.currentNotification?.controller.startTimeout();
          onHiddenCallback?.();
        });
      }
    };
    const lastNotification = last(this.notifications);
    if (!lastNotification) {
      this.notifications = [notificationProps];
      this.currentNotification = notificationProps;
      notificationProps.controller.startTimeout();
      return;
    }
    this.notifications = [...this.notifications, notificationProps];
  }
  public handleCta(cta: NotificationCallToAction): boolean {
    const slashCommand = cta.slashCommand;
    if (!slashCommand) {
      return false;
    }
    const parsedSlashCommand = slashCommand.split(' ');
    const handler = this.slashCommandHandlers.get(parsedSlashCommand[0]);
    if (handler) {
      handler(parsedSlashCommand.slice(1).join(' '));
      return true;
    }
    return false;
  }
  public registerSlashCommandHandler(command: string, handler: SlashCommandHandler): () => void {
    this.slashCommandHandlers.set(command, handler);
    return () => {
      this.slashCommandHandlers.delete(command);
    };
  }
  public handleNotificationPress = async (notification: NotificationFragment) => {
    this.keyboardService.dismissKeyboard();
    const trackingConfig = getTrackingForKey(notification.key);
    if (trackingConfig) {
      this.firebaseAnalyticsService.logEvent('InAppNotification_' + trackingConfig[0], trackingConfig[1] + '_Clicked');
    }
    if (notification.callToAction) {
      const hasHandle = this.handleCta(notification.callToAction);
      if (hasHandle) {
        return;
      }
      const command = this.commandService.tryParseCommandCall(notification.callToAction.slashCommand);
      if (!command) {
        this.snackbarService.showGenericError();
        return;
      }
      const result = await this.commandWithoutChannelService.invokeCommand(command.commandName, command.parameter);
      if (result !== 'SendSlashCommandSuccess') {
        this.snackbarService.showGenericError();
      }
    }
  };
  public showNotification = (notification: NotificationInputData): {
    close: () => void;
  } => {
    if (globalEnv.product === 'stapp-messenger') {
      return {
        close: () => {}
      };
    }
    const lifeTime = notification.lifeTime || DEFAULT_LIFETIME;
    const controller = new NotificationBarController(lifeTime);
    this.pushNotification({
      ...notification,
      content: notification.renderContent(controller.close),
      controller
    }, notification.onHidden);
    return {
      close: controller.close
    };
  };
  private shouldDisplayNotification(notification: NotificationFragment): boolean {
    return !this.viewService.visibleViews.some(view => view.viewConfig.notificationAssociationKey === notification.key);
  }
}
export class NotificationBarController {
  private readonly onCloseCallbacks: (() => void)[] = [];
  private closed = false;
  private timeout: any;
  private readonly lifeTime: number;
  constructor(lifeTime: number) {
    this.lifeTime = lifeTime;
    // this.timeout = setTimeout(() => {
    // 	if (!this.closed) {
    // 		this.runCallbacks();
    // 		this.closed = true;
    // 	}
    // }, lifeTime);
  }

  // Close the notification. This will start its slide-out animation.
  close = () => {
    if (!this.closed) {
      clearTimeout(this.timeout);
      this.runCallbacks();
      this.closed = true;
    }
  };
  public startTimeout = () => {
    this.timeout = setTimeout(() => {
      if (!this.closed) {
        this.runCallbacks();
        this.closed = true;
      }
    }, this.lifeTime);
  };
  private runCallbacks = () => {
    this.onCloseCallbacks.forEach(it => it());
    this.onCloseCallbacks.splice(0, this.onCloseCallbacks.length);
  };

  // Register a callback to be called when the notification should be closed
  onClose = (callback: () => void) => {
    // Notification might have been closed while it wasn't mounted
    if (this.closed) {
      callback();
      return () => {};
    }
    this.onCloseCallbacks.push(callback);
    return () => {
      this.onCloseCallbacks.splice(this.onCloseCallbacks.indexOf(callback), 1);
    };
  };
}
const getTrackingForKey = (key?: string | null) => {
  if (!key) {
    return null;
  }
  switch (key) {
    case 'engagementsystem':
      return ['EngagementSystem', 'TaskCompleted'];
    default:
      return key.indexOf(':') > -1 ? key.split(':') : null;
  }
};