// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends Function>(
	func: T,
	wait = 200,
	immediate = false
): T {
	let timeout: any;
	return function (this: any, ...args: any[]): any {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;
		const later = function (): void {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	} as any;
}
