/**
 * Creates a record of the form { [key: string]: T } from an array of objects extracting
 * the key and value from each object using the provided key and value functions.
 */
export function associate<T, V = T>(
	array: readonly T[],
	keySelector: (element: T, index: number) => string,
	valueSelector: (element: T, index: number) => V
): Record<string, V> {
	const result: Record<string, V> = {};
	for (let i = 0; i < array.length; i++) {
		const element = array[i];
		result[keySelector(element, i)] = valueSelector(element, i);
	}
	return result;
}
