import * as React from 'react';
import { ListGroup, ScrollView, SwitchListItem } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useMutation, useQuery } from '@knuddels-app/Connection';
import { GetFotomeetEnabled, SetFotomeetEnabled } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
export const FotomeetSettings: React.FC = observer('FotomeetSettings', () => {
  return <div className={_c0}>
			<EnabledSetting />
		</div>;
});
export const EnabledSetting: React.FC = () => {
  const i18n = useService($I18n);
  const {
    loading,
    disabled,
    setDisabled
  } = useFotomeetDisabled();
  if (loading) {
    return null;
  }
  return <ListGroup title={'Fotomeet'}>
			<SwitchListItem checked={disabled} onChange={setDisabled} caption={i18n.format(declareFormat({
      id: 'settings.fotomeet.enabled.caption',
      defaultFormat: 'Disable Fotomeet'
    }))} additionalInfo={i18n.format(declareFormat({
      id: 'settings.fotomeet.enabled.info',
      defaultFormat: 'Your photos will no longer be displayed to other members'
    }))} />
		</ListGroup>;
};
const useFotomeetDisabled = () => {
  const snackbarService = useService($SnackbarService);
  const {
    data,
    loading
  } = useQuery(GetFotomeetEnabled, {}, 'no-cache');
  const [enabled, setEnabled] = React.useState(data);
  React.useEffect(() => {
    setEnabled(data ?? true);
  }, [data]);
  const [mutate] = useMutation(SetFotomeetEnabled);
  return {
    loading,
    disabled: !enabled,
    setDisabled: async (newDisabled: boolean) => {
      const newEnabled = !newDisabled;
      const prevEnabled = enabled;
      setEnabled(newEnabled);
      const result = await mutate({
        enabled: newEnabled
      });
      if (result.data?.__typename !== 'Success') {
        setEnabled(prevEnabled);
        snackbarService.showGenericError();
      }
    }
  };
};
const _c0 = " Knu-ScrollView pb-large height-full ";