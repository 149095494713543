import { VisualElement as o } from "../VisualElement.js";
import { DOMKeyframesResolver as s } from "./DOMKeyframesResolver.js";
class l extends o {
  constructor() {
    super(...arguments), this.KeyframeResolver = s;
  }
  sortInstanceNodePosition(e, t) {
    return e.compareDocumentPosition(t) & 2 ? 1 : -1;
  }
  getBaseTargetFromProps(e, t) {
    return e.style ? e.style[t] : void 0;
  }
  removeValueFromRenderState(e, { vars: t, style: r }) {
    delete t[e], delete r[e];
  }
}
export {
  l as DOMVisualElement
};
