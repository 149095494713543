import { DeleteProfilePicture, GetCurrentUserProfilePictureCustomSizeUrl, GetCurrentUserProfilePictureUrls } from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { EventEmitter } from '@knuddels/std';
import { customUserImageSizes } from '@knuddelsModules/UserData';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $SnackbarService, SnackbarData } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
@injectable()
export class ProfilePictureUploadService {
  private readonly profilePicturedDeletedEmitter = new EventEmitter();
  // tslint:disable-next-line: member-ordering
  public readonly onProfilePictureDeleted = this.profilePicturedDeletedEmitter.asEvent();
  constructor(@inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T) {}
  private readonly refetchProfilePictureUrls = () => {
    this.authenticatedClientService.currentK3Client.query(GetCurrentUserProfilePictureUrls, {}, 'network-only');
    const pixelDensity = getPixelRatio();
    customUserImageSizes.forEach(size => {
      this.authenticatedClientService.currentK3Client.query(GetCurrentUserProfilePictureCustomSizeUrl, {
        pixelDensity,
        size
      }, 'network-only');
    });
  };
  deleteProfilePicture = (): {
    onErr(callback: () => void): void;
    onOk(callback: () => void): void;
  } => {
    const result = this.authenticatedClientService.currentK3Client.mutateWithResultPromise(DeleteProfilePicture, {});
    result.onErr(() => {
      this.snackbarService.showErrorSnackbarWithDefaults(getDeleteProfilePictureFailedMessage(this.i18n));
    });
    result.onOk(() => {
      this.snackbarService.showSnackbar(getDeleteProfilePictureSuccessMessage(this.i18n));
      this.profilePicturedDeletedEmitter.emit();

      // We need to delay fetching the new urls because the info about the new image
      // takes a bit to propagate through the backend
      setTimeout(() => {
        this.refetchProfilePictureUrls();
      }, 500);
    });
    return result;
  };
}
const getDeleteProfilePictureSuccessMessage = (i18n: typeof $I18n.T): SnackbarData => ({
  type: 'deleteProfilePictureSuccess',
  text: i18n.format(declareFormat({
    id: 'profile.profile-picture.delete.success.text',
    defaultFormat: 'The picture has been successfully deleted'
  })),
  adornment: require('@shared/icons/icon-bighello.gif')
});
const getDeleteProfilePictureFailedMessage = (i18n: typeof $I18n.T): SnackbarData => ({
  type: 'deleteProfilePictureFailed',
  text: i18n.format(declareFormat({
    id: 'profile.profile-picture.delete.failed.text',
    defaultFormat: 'The image could not be deleted.'
  })),
  subtext: i18n.format(declareFormat({
    id: 'profile.profile-picture.delete.failed.subtext',
    defaultFormat: 'The image could not be deleted. Please try again later.'
  }))
});