import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as providedServiceIds from '../providedServiceIds';
import * as services from './services';
import { foreachProp } from '@knuddels/std';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(providedServiceIds, {
		$SettingsComponents: ctx.loggedInScope.toSingletonAutostart(
			services.SettingsComponents
		),
		$ClientSettingsService: ctx.loggedInScope.toSingleton(
			services.ClientSettingsService
		),
		$TanSystemService: ctx.loggedInScope.toSingletonAutostart(
			services.TanSystemService
		),
		$MessageLayoutSettingsService: ctx.loggedInScope.toSingleton(
			services.MessageLayoutSettingsService
		),
		$ThemeSettingsService: ctx.loggedInScope.toSingletonAutostart(
			services.ThemeSettingsService
		),
		$MiniChatSettingsService: ctx.loggedInScope.toSingletonAutostart(
			services.MiniChatSettingsService
		),
	});
}
