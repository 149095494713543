import { FlexCol, Theme, ThemeContext, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { CircularProgressProps } from './CircularProgressProps';
export const CircularProgress = React.memo((props: CircularProgressProps) => {
  const theme: Theme = React.useContext(ThemeContext);
  const {
    percentage,
    size,
    fillColor = theme.colors.basic['teal-500'],
    backgroundColor = '#ededed',
    strokeWidth = 'thick',
    children
  } = props;
  return <div style={{
    height: resolveThemingValue(size, "sizes", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c0}>
			<CircularProgressbarWithChildren value={percentage} styles={buildStyles({
      pathColor: fillColor,
      strokeLinecap: 'butt',
      trailColor: backgroundColor
    })} strokeWidth={strokeWidth === 'thin' ? 7 : 14}>
				{children(percentage)}
			</CircularProgressbarWithChildren>
		</div>;
});
CircularProgress.displayName = 'CircularProgress';
const _c0 = " Knu-FlexCol flexShrink-0 ";