import * as React from 'react';
import { inject, injectable, useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService, $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { Button, Flex, FlexCol, Text } from '@knuddels/component-library';
import { MultiSmiley, SmileyData } from '@shared/components/RichText/nodes/SmileyNode';
import { $AppService } from '@knuddelsModules/Apps';
import { Disposable } from '@knuddels/std';

// tslint:disable-next-line: no-module-bleeding
import { AppInstance } from '@knuddelsModules/Apps/bundle/services/AppInstance';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';
import emojis from 'emojibase-data/de/data.json';
import { $SmileyService } from '@knuddelsModules/AutocompleteInputBar';
// tslint:disable-next-line: no-module-bleeding
import { usePromise } from '@knuddels-app/tools/usePromise';
// tslint:disable-next-line: no-module-bleeding
import { IconKnuddel } from '@knuddelsModules/Profile/bundle/components/shared/IconKnuddel';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { IconStar } from '@shared/icons/IconStar';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { $SystemAppService } from '../../../SystemApps';
import { $UserService } from '../../../UserData';
import { getMaxEmojiVersion, supportsFlagEmoji } from './getMaxEmojiVersion';
import { IconStarOff } from '@shared/icons/IconStarOff';
const storedEmojiSkinColorIndexKey = 'emojiSkinColorIndex';
const lastUsedSmileys = 'lastUsedSmileys';
const lastUsedEmojis = 'lastUsedEmojis';
const emojiOverrides = 'emojiOverrides';
const smileyOverrides = 'smileyOverrides';
const favoriteEmojiIds = 'favoriteEmojiIds';
const favoriteFeatureNames = 'favoriteFeatureNames';
const lastUsedFeatureNames = 'lastUsedFeaturesNames';
const storeIdWithUser = (key: string, userId: string) => {
  return `${key}-${userId}`;
};
const SMILEY_BOX_FEATURE_CATEGORIES = ['Channel', 'Game', 'Macro'];
type Events = {
  'k3-search-similar-smileys': {
    args: {
      emoji: string;
    };
    returnValue: {
      smileys: SmileyData[];
    };
  };
  'k3-search-smiley-features': {
    args: {
      query: string;
      categories: string[];
    };
    returnValue: {
      features: SmileyFeature[];
    };
  };
  'k3-restore-features': {
    args: {
      lastUsedNames: string[];
      favoriteNames: string[];
      categories: string[];
    };
    returnValue: {
      lastUsedFeatures: SmileyFeature[];
      favoriteFeatures: SmileyFeature[];
    };
  };
  'k3-get-features-for-knuddel': {
    args: {};
    returnValue: {
      features: BuyableSmileyFeature[];
    };
  };
  'k3-buy-feature-for-knuddel': {
    args: {
      buyReference: string;
    };
    returnValue: {
      success: boolean;
    };
  };
  'k3-search': {
    args: {
      term: string;
    };
    returnValue: {
      items: SmileyData[];
    };
  };
  'k3-add-favorite': {
    args: {
      smileyId: number;
      rpl: string;
    };
    returnValue: {};
  };
  'k3-remove-favorite': {
    args: {
      smileyId: number;
      rpl: string;
    };
    returnValue: {};
  };
  'k3-slash-commands': {
    args: {};
    returnValue: {
      commands: SlashCommand[];
    };
  };
  'k3-get-details': {
    args: {
      smileyId: number;
    };
    returnValue: {
      details: any;
      price: number;
    };
  };
  'k3-trade-smiley': {
    args: {
      smileyId: number;
      price: number;
    };
    returnValue: {};
  };
  'k3-smiley-features': {
    args: {
      start: number;
      limit: number;
      categories: string[];
    };
    returnValue: {
      features: SmileyFeature[];
      hasMore: boolean;
    };
  };
  'k3-consumable-smiley-features': {
    args: {
      start: number;
      limit: number;
      categories: string[];
    };
    returnValue: {
      features: SmileyFeature[];
      hasMore: boolean;
    };
  };
  'k3-get-favorites': {
    args: {};
    returnValue: {
      favorites: SmileyData[];
    };
  };
  'k3-get-smiley-rotation': {
    args: {
      categories?: string[];
    };
    returnValue: {
      rotation: {
        smileys: SmileyData[];
        features: SmileyFeature[];
      };
    };
  };
  'k3-multi-smilies': {
    args: {
      smileyId: number;
    };
    returnValue: {
      details: MultiSmiley;
    };
  };
  'k3-my-smileys': {
    args: {};
    returnValue: {
      items: SmileyData[];
    };
  };
  'k3-open-details': {
    args: {
      smileyId: number;
    };
    returnValue: {};
  };
};
export const EMOJI_GROUPS = (['Smileys & Menschen', 'Tiere & Natur', 'Essen & Trinken', 'Reisen & Orte', 'Aktivitäten', 'Objekte', 'Symbole', 'Flaggen'] as const);
const groupMapping = ({
  0: 0,
  1: 0,
  2: 0,
  3: 1,
  4: 2,
  5: 3,
  6: 4,
  7: 5,
  8: 6,
  9: 7
} as const);
export type EmojiData = {
  emoji: string;
  /**
   * set when value is overriden
   */
  originalEmoji?: string;
  hexcode: string;
  tags: string[];
  skins?: EmojiData[];
  label: string;
  version: number;
};
export type SlashCommand = {
  command: string;
  description: string;
  autocompleteType?: 'user' | 'channel' | 'unknown' | 'none';
  parameter?: string;
};
export type SmileyFeature = {
  smileyId: number;
  url: string;
  name: string;
  command: SlashCommand;
  cooldown?: number;
  owned?: boolean;
  notUsableUntil?: number;
};
export type BuyableSmileyFeature = {
  feature: SmileyFeature;
  price: number;
  buyReference: string;
};
const store = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};
const getStoredValue = <T,>(key: string, defaultValue: T): T => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};
type SmileyUsedObserver = (smileyId: number) => void;
export type NewSmileysObserver = (newSmileyIds: SmileyData[]) => void;
@injectable()
export class SmileyService {
  private mySmileysChangedObservers = new Set<NewSmileysObserver>();
  public readonly dispose = Disposable.fn();
  public SmileyTradeAppInstance: AppInstance | null = null;
  @observable
  public emojiSkinColorIndex = getStoredValue(storedEmojiSkinColorIndexKey, 0);
  @observable.ref
  public lastUsedSmileys = ([] as SmileyData[]);
  @observable.ref
  public favoriteSmileys = ([] as SmileyData[]);
  @observable.ref
  public favoriteEmojiIds = ([] as string[]);
  @observable.ref
  public favoriteFeature = ([] as SmileyFeature[]);
  @observable.ref
  public lastUsedFeature = ([] as SmileyFeature[]);
  @observable.ref
  public rotationSmiley = ([] as SmileyData[]);
  @observable.ref
  public rotationFeatures = ([] as SmileyFeature[]);
  @observable.ref
  public featureForKnuddel = ([] as BuyableSmileyFeature[]);
  @observable.ref
  public lastUsedEmojis = ([] as {
    hexcode: string;
    emoji: string;
  }[]);
  @observable.ref
  public emojiOverrides = ({} as {
    [key: string]: number;
  });
  @observable.ref
  public smileyOverrides = getStoredValue(smileyOverrides, ({} as {
    [key: number]: {
      url: string;
      rpl: string;
    };
  }));
  @observable.ref
  public allEmojis: EmojiData[] = [];
  @observable.ref
  public emojisByGroup: Record<string, EmojiData[]> = {};
  @observable.ref
  public mySmileys: SmileyData[] = [];
  private resolvers: Map<string, any> = new Map();
  private pendingRequests: Function[] = [];
  private smileyUsedObservers = new Set<SmileyUsedObserver>();
  constructor(@inject($OverlayService)
  private overlayService: typeof $OverlayService.T, @inject.lazy($AppService)
  private readonly appServiceGetter: typeof $AppService.TLazy, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($KeyboardService)
  private readonly keyboardService: typeof $KeyboardService.T, @inject.lazy($SystemAppService)
  private readonly systemAppServiceGetter: typeof $SystemAppService.TLazy, @inject.lazy($UserService)
  private readonly userServiceGetter: typeof $UserService.TLazy) {
    this.setupEmojis();
    if (globalEnv.product === 'app') {
      this.appServiceGetter().then(appService => {
        this.dispose.track(appService.registerEventHandler('SmileyAuctionApp', event => {
          if (event?.name === 'smileyFeatureUsed') {
            this.smileyUsedObservers.forEach(o => o(event.smileyId));
            return;
          }
          if (event?.name === 'mySmileysChanged') {
            this.mySmileysChangedObservers.forEach(o => o(event.newSmileys));
            // TODO: Diff instead of refetch
            this.getMySmileys();
            return;
          }
          if (event?.name === 'k3-trade-successful') {
            this.getMySmileys();
            return;
          }
          if (event?.name === 'smileyAddedToFavorites') {
            this.getFavorites().then(favorites => {
              runInAction('set favorites', () => {
                this.favoriteSmileys = favorites;
              });
            });
            return;
          }
          if (event?.name === 'smileyRemovedFromFavorites') {
            runInAction('remove favourite', () => {
              this.favoriteSmileys = this.favoriteSmileys.filter(f => f.id !== event.smileyId);
            });
            return;
          }
          const resolved = this.resolvers.get(event.id);
          if (resolved) {
            resolved(event);
            this.resolvers.delete(event.id);
          }
        }));
        this.dispose.track(appService.registerAppOpenListener('SmileyAuctionApp', appInstance => {
          this.SmileyTradeAppInstance = appInstance;
          setTimeout(() => {
            onMainThreadIdle(async () => {
              const [, favorites] = await Promise.all([this.getMySmileys(), this.getFavorites()]);
              await this.getStoredValues();
              runInAction('set favorites', () => {
                this.favoriteSmileys = favorites;
              });
              this.getFeatureForKnuddel().then(r => {
                runInAction('set feature for knuddel', () => {
                  this.featureForKnuddel = r;
                });
              });
              this.getRotation().then(r => {
                runInAction('set rotation', () => {
                  this.rotationSmiley = r.smileys;
                  this.rotationFeatures = r.features;
                });
              });
              this.pendingRequests.forEach((r: Function) => {
                r();
              });
              this.pendingRequests = [];
            });
          }, 2000);
        }));
        this.dispose.track(appService.registerAppCloseListener('SmileyAuctionApp', () => {
          this.SmileyTradeAppInstance = null;
        }));
      });
    }
  }
  public addSmileyUsedObserver(observer: SmileyUsedObserver): () => void {
    this.smileyUsedObservers.add(observer);
    return () => {
      this.smileyUsedObservers.delete(observer);
    };
  }
  @action
  setEmojiSkinColorIndex(value: number): void {
    this.emojiSkinColorIndex = value;
    this.setupEmojis();
    store(storedEmojiSkinColorIndexKey, value);
  }
  @action
  pushSmileys(smileys: SmileyData[]): void {
    const newSmileys = ([] as SmileyData[]);
    const usedRpl = new Set<string>();
    for (const smiley of smileys) {
      if (usedRpl.has(smiley.rpl)) {
        continue;
      }
      newSmileys.push(smiley);
      usedRpl.add(smiley.rpl);
    }
    this.lastUsedSmileys = [...newSmileys, ...this.lastUsedSmileys.filter(s => !smileys.some(s2 => s2.rpl === s.rpl))].slice(0, 15);
    this.storeWithUserId(lastUsedSmileys, this.lastUsedSmileys);
  }
  @action
  pushEmoji(emojis: {
    hexcode: string;
    emoji: string;
  }[]): void {
    const newEmojis = ([] as {
      hexcode: string;
      emoji: string;
    }[]);
    const usedEmojis = new Set<string>();
    for (const emoji of emojis) {
      if (usedEmojis.has(emoji.emoji)) {
        continue;
      }
      newEmojis.push(emoji);
      usedEmojis.add(emoji.emoji);
    }
    this.lastUsedEmojis = [...newEmojis, ...this.lastUsedEmojis.filter(e => !emojis.some(e2 => e2.emoji === e.emoji))].slice(0, 15);
    this.storeWithUserId(lastUsedEmojis, this.lastUsedEmojis);
  }
  @action
  pushLastUsedFeature(feature: SmileyFeature): void {
    this.lastUsedFeature = [feature, ...this.lastUsedFeature.filter(f => f.name !== feature.name)].slice(0, 15);
    this.storeWithUserId(lastUsedFeatureNames, this.lastUsedFeature.map(f => f.name));
  }
  @action
  addEmojiOverride(id: string, index: number): void {
    if (index === -1) {
      delete this.emojiOverrides[id];
      this.emojiOverrides = {
        ...this.emojiOverrides
      };
      this.storeWithUserId(emojiOverrides, this.emojiOverrides);
      return;
    }
    this.emojiOverrides = {
      ...this.emojiOverrides,
      [id]: index
    };
    this.storeWithUserId(emojiOverrides, this.emojiOverrides);
  }
  @action
  addSmileyOverride(id: number, data: {
    url: string;
    rpl: string;
  } | null): void {
    if (data === null) {
      delete this.smileyOverrides[id];
      this.smileyOverrides = {
        ...this.smileyOverrides
      };
      this.storeWithUserId(smileyOverrides, this.smileyOverrides);
      return;
    }
    this.smileyOverrides = {
      ...this.smileyOverrides,
      [id]: data
    };
    this.storeWithUserId(smileyOverrides, this.smileyOverrides);
  }
  public getSmileysMatching(term: string): Promise<SmileyData[]> {
    return this.requestSmileyApp('k3-search', {
      term
    }, event => event?.items ?? [], false);
  }
  public getSimilarSmileys(emoji: string): Promise<SmileyData[]> {
    return this.requestSmileyApp('k3-search-similar-smileys', {
      emoji
    }, event => event?.smileys ?? []);
  }
  public getDetails(smileyId: number): Promise<{
    details: {
      description: string;
    };
    price: number;
  }> {
    return this.requestSmileyApp('k3-get-details', {
      smileyId
    }, event => event ? {
      details: event.details,
      price: event.price
    } : {
      details: null,
      price: -1
    });
  }
  @action
  public handleFeatureUsed(feature: SmileyFeature): void {
    const mapper = (f: SmileyFeature) => {
      if (f.smileyId === feature.smileyId) {
        return ({
          ...feature,
          notUsableUntil: Date.now() + (feature.cooldown ?? 0)
        } satisfies SmileyFeature);
      }
      return f;
    };
    this.lastUsedFeature = this.lastUsedFeature.map(mapper);
    this.rotationFeatures = this.rotationFeatures.map(mapper);
    this.favoriteFeature = this.favoriteFeature.map(mapper);
  }
  public addNewSmileysObserver(observer: NewSmileysObserver): () => void {
    this.mySmileysChangedObservers.add(observer);
    return () => {
      this.mySmileysChangedObservers.delete(observer);
    };
  }
  private clearUnownedSmileys(smileys: SmileyData[]) {
    const unowned: SmileyData[] = [];
    const owned: SmileyData[] = [];
    for (const smiley of smileys) {
      if (this.mySmileys.some(s => s.id === smiley.id)) {
        owned.push(smiley);
      } else {
        unowned.push(smiley);
      }
    }
    return {
      owned,
      unowned
    };
  }
  private requestSmileyApp<TKey extends keyof Events, ReturnType>(key: TKey, data: Events[TKey]['args'], responseMapper: (event: Events[TKey]['returnValue'] | null) => ReturnType, allowPending = true): Promise<ReturnType> {
    return new Promise(resolve => {
      const request = () => {
        const id = Math.random().toString();
        this.resolvers.set(id, (event: Events[TKey]['returnValue']) => {
          resolve(responseMapper(event));
        });
        this.SmileyTradeAppInstance!.sendEventToWebview(key, {
          ...data,
          id
        });
      };
      if (!this.SmileyTradeAppInstance) {
        if (!allowPending) {
          resolve(responseMapper(null));
          return;
        }
        this.pendingRequests.push(request);
        return;
      }
      request();
    });
  }
  public async getEmojis(term: string): Promise<EmojiData[]> {
    const actualTerm = term.replace(/[()-]/g, '').toLowerCase();
    return this.allEmojis.map(e => {
      return {
        ...e,
        exactMatch: e.tags.some((k: string) => k === actualTerm)
      };
    }).filter(e => {
      return e.exactMatch || e.tags.some((k: string) => k.includes(actualTerm));
    }).sort((a, b) => {
      if (this.lastUsedEmojis.find(e => e.hexcode === a.hexcode)) {
        return -1;
      }
      if (this.lastUsedEmojis.find(e => e.hexcode === b.hexcode)) {
        return 1;
      }
      if (a.exactMatch) {
        return -1;
      }
      if (b.exactMatch) {
        return 1;
      }
      return 0;
    });
  }
  public async getSmiliesAndEmojis(term: string): Promise<{
    topResults: (SmileyData | EmojiData)[];
    results: (SmileyData | EmojiData)[];
  }> {
    if (term.trim().length === 0) {
      return {
        topResults: [],
        results: []
      };
    }
    const actualQuery = term.trim().replace(':', '').toLowerCase();
    const [smilies, allEmojis] = await Promise.all([this.getSmileysMatching(actualQuery), this.getEmojis(actualQuery)]);
    const topSmilies = smilies.slice(0, Math.max(5, 10 - allEmojis.length));
    const topEmojis = allEmojis.slice(0, 5);
    return {
      topResults: [...topSmilies, ...topEmojis],
      results: [...smilies.slice(topSmilies.length), ...allEmojis.slice(topEmojis.length)]
    };
  }
  public async getMySmileys(): Promise<SmileyData[]> {
    const mySmileys = await this.requestSmileyApp('k3-my-smileys', {}, event => event?.items ?? []);
    runInAction('set my smileys', () => {
      this.mySmileys = mySmileys;
    });
    return mySmileys;
  }
  public getSlashCommands(): Promise<any[]> {
    return this.requestSmileyApp('k3-slash-commands', {}, event => event?.commands ?? []);
  }
  public getSmileyFeatures: (start: number, limit: number) => Promise<{
    features: SmileyFeature[];
    hasMore: boolean;
  }> = (start, limit) => {
    return this.requestSmileyApp('k3-smiley-features', {
      start,
      limit,
      categories: SMILEY_BOX_FEATURE_CATEGORIES
    }, event => !event ? {
      features: [],
      hasMore: false
    } : event);
  };
  public showSmileyDetails(smileyId: number): void {
    const currentFocusable = this.keyboardService.focusable;
    setTimeout(() => {
      this.keyboardService.hideKeyboard();
    });
    this.requestSmileyApp('k3-open-details', {
      smileyId
    }, () => null);
    this.systemAppServiceGetter().then(s => s.onOverlayClosed()).then(() => {
      setTimeout(() => {
        currentFocusable?.focus();
      });
    });
  }
  public getConsumableSmileyFeatures: (start: number, limit: number) => Promise<{
    features: SmileyFeature[];
    hasMore: boolean;
  }> = (start, limit) => {
    return this.requestSmileyApp('k3-consumable-smiley-features', {
      start,
      limit,
      categories: SMILEY_BOX_FEATURE_CATEGORIES
    }, event => !event ? {
      features: [],
      hasMore: false
    } : event);
  };
  public getFavorites(): Promise<SmileyData[]> {
    const favIdCount: Record<string, number> = {};

    /**
     * if there are multiple smileys with the same id, this means the stapp as set them
     * in this case we want to show them as not multi
     */

    return this.requestSmileyApp('k3-get-favorites', {}, event => {
      const favorites = event?.favorites ?? ([] as SmileyData[]);
      for (const fav of favorites) {
        favIdCount[fav.id] ??= 0;
        favIdCount[fav.id]++;
      }
      return favorites.map(fav => ({
        ...fav,
        multi: favIdCount[fav.id] > 1 ? false : fav.multi
      }));
    });
  }
  public getRotation(): Promise<Events['k3-get-smiley-rotation']['returnValue']['rotation']> {
    return this.requestSmileyApp('k3-get-smiley-rotation', {
      categories: SMILEY_BOX_FEATURE_CATEGORIES
    }, event => event?.rotation ?? {
      smileys: [],
      features: []
    });
  }
  public getFeatureForKnuddel(): Promise<BuyableSmileyFeature[]> {
    return this.requestSmileyApp('k3-get-features-for-knuddel', {}, event => event?.features ?? []);
  }
  public buyFeatureForKnuddel(buyReference: string): Promise<boolean> {
    return this.requestSmileyApp('k3-buy-feature-for-knuddel', {
      buyReference
    }, event => event?.success ?? false);
  }
  public searchSmileyFeatures(query: string): Promise<SmileyFeature[]> {
    return this.requestSmileyApp('k3-search-smiley-features', {
      query: query.trim(),
      categories: SMILEY_BOX_FEATURE_CATEGORIES
    }, event => event?.features ?? []);
  }
  public restoreLastAndFavoriteFeatures(userId: string): void {
    const storedFavoriteFeatureNames = getStoredValue(storeIdWithUser(favoriteFeatureNames, userId), []);
    const storedLastUsedFeatureNames = getStoredValue(storeIdWithUser(lastUsedFeatureNames, userId), []);
    this.requestSmileyApp('k3-restore-features', {
      lastUsedNames: storedLastUsedFeatureNames,
      favoriteNames: storedFavoriteFeatureNames,
      categories: SMILEY_BOX_FEATURE_CATEGORIES
    }, event => ({
      lastUsedFeatures: event?.lastUsedFeatures ?? [],
      favoriteFeatures: event?.favoriteFeatures ?? []
    })).then(({
      lastUsedFeatures,
      favoriteFeatures
    }) => {
      runInAction('set last and favorite features', () => {
        const removedNames = [];
        const filter = (f: SmileyFeature) => {
          if (this.mySmileys.some(s => s.id === f.smileyId)) {
            return true;
          } else {
            removedNames.push(f.name);
            return false;
          }
        };
        this.lastUsedFeature = lastUsedFeatures.filter(filter);
        this.favoriteFeature = favoriteFeatures.filter(filter);
        if (removedNames.length > 0) {
          this.storeWithUserId(lastUsedFeatureNames, this.lastUsedFeature.map(f => f.name));
          this.storeWithUserId(favoriteFeatureNames, this.favoriteFeature.map(f => f.name));
        }
      });
    });
  }
  @action
  public addFavorite(smiley: SmileyData): Promise<void> {
    this.favoriteSmileys = [...this.favoriteSmileys, smiley];
    this.showAddSnackbar();
    return this.requestSmileyApp('k3-add-favorite', {
      smileyId: smiley.id,
      rpl: smiley.rpl
    }, () => {});
  }
  @action
  public addFavoriteFeature(feature: SmileyFeature): Promise<void> {
    this.favoriteFeature = [feature, ...this.favoriteFeature.filter(s => s.name !== feature.name)];
    this.showAddSnackbar();
    this.storeWithUserId(favoriteFeatureNames, this.favoriteFeature.map(f => f.name));
    return Promise.resolve();
  }
  @action
  removeFavoriteFeature(feature: SmileyFeature): Promise<void> {
    this.favoriteFeature = this.favoriteFeature.filter(f => f.name !== feature.name);
    this.showRemoveSnackbar();
    this.storeWithUserId(favoriteFeatureNames, this.favoriteFeature.map(f => f.name));
    return Promise.resolve();
  }
  @action
  public removeFavorite(smileyId: number, rpl: string): Promise<void> {
    this.favoriteSmileys = this.favoriteSmileys.filter(smiley => smiley.rpl !== rpl);
    this.showRemoveSnackbar();
    return this.requestSmileyApp('k3-remove-favorite', {
      smileyId,
      rpl
    }, () => {});
  }
  @action
  public addFavoriteEmoji(emojiId: string): void {
    this.favoriteEmojiIds = [...this.favoriteEmojiIds, emojiId];
    this.storeWithUserId(favoriteEmojiIds, this.favoriteEmojiIds);
    this.showAddSnackbar();
  }
  @action
  public removeFavoriteEmoji(emojiId: string): void {
    this.favoriteEmojiIds = this.favoriteEmojiIds.filter(id => id !== emojiId);
    this.storeWithUserId(favoriteEmojiIds, this.favoriteEmojiIds);
    this.showRemoveSnackbar();
  }
  public getMultiSmiley(smileyId: number): Promise<MultiSmiley | null> {
    return this.requestSmileyApp('k3-multi-smilies', {
      smileyId
    }, event => event?.details ?? null);
  }
  public tradeSmiley(smileyId: number, price: number): Promise<void> {
    return this.requestSmileyApp('k3-trade-smiley', {
      smileyId,
      price
    }, () => {});
  }
  private async setupEmojis(): Promise<void> {
    const emojiGroups: Record<string, EmojiData[]> = {};
    const maxVersion = await getMaxEmojiVersion();
    const supportsFlags = await supportsFlagEmoji();
    this.allEmojis = emojis.filter(e => !!e.tags).filter(e => !maxVersion || e.version <= maxVersion).filter(e => {
      return supportsFlags || e.group !== 9; // 9 is the flag group;
    }).map(e => {
      const groupName = EMOJI_GROUPS[groupMapping[(e.group as keyof typeof groupMapping)]];
      emojiGroups[groupName] ??= [];
      emojiGroups[groupName].push(e);

      // default skin is index 0 but not in the skins array
      if (e.skins && this.emojiSkinColorIndex > 0) {
        e.originalEmoji = e.emoji;
        e.emoji = e.skins[this.emojiSkinColorIndex - 1].emoji;
      } else if (e.skins && this.emojiSkinColorIndex === 0) {
        e.emoji = e.originalEmoji ?? e.emoji;
      }
      return {
        ...e,
        tags: e.tags.map((t: string) => t.toLowerCase())
      };
    });
    this.emojisByGroup = emojiGroups;
  }
  public showBuySmiley(smiley: SmileyData): void {
    this.showSmileyDetails(smiley.id);
  }
  private async storeWithUserId(key: string, value: any): Promise<void> {
    const userService = await this.userServiceGetter();
    const user = await userService.getCurrentUser();
    store(storeIdWithUser(key, user.id), value);
  }
  private async getStoredValues() {
    const userService = await this.userServiceGetter();
    const user = await userService.getCurrentUser();
    const userId = user.id;
    runInAction('set stored values', () => {
      const lastUsedSmileysStored = getStoredValue(storeIdWithUser(lastUsedSmileys, userId), []);
      const {
        owned: ownedLastUsedSmileys
      } = this.clearUnownedSmileys(lastUsedSmileysStored);
      this.lastUsedSmileys = ownedLastUsedSmileys;
      this.storeWithUserId(lastUsedSmileys, this.lastUsedSmileys);
      this.lastUsedEmojis = getStoredValue(storeIdWithUser(lastUsedEmojis, userId), []);
      this.favoriteEmojiIds = getStoredValue(storeIdWithUser(favoriteEmojiIds, userId), []);
      this.emojiOverrides = getStoredValue(storeIdWithUser(emojiOverrides, userId), {});
      this.smileyOverrides = getStoredValue(storeIdWithUser(smileyOverrides, userId), {});
      this.restoreLastAndFavoriteFeatures(userId);
    });
  }
  private showAddSnackbar(): void {
    this.snackbarService.showSnackbar({
      text: 'Zu Favoriten hinzugefügt',
      adornment: <div className={_c0}>
					<IconStar size={'large'} />
				</div>,
      type: 'add'
    });
  }
  private showRemoveSnackbar(): void {
    this.snackbarService.showSnackbar({
      text: 'Aus Favoriten entfernt',
      adornment: <div className={_c1}>
					<IconStarOff size={'large'} />
				</div>,
      type: 'remove'
    });
  }
}
const cache = new Map<number, Promise<any>>();
const SmileyDetailsView: React.FC<{
  smileyId: number;
}> = React.memo(({
  smileyId
}) => {
  const smileyService = useService($SmileyService);
  const overlay = useService($ThisVisibleOverlay);
  if (!cache.has(smileyId)) {
    cache.set(smileyId, smileyService.getDetails(smileyId));
  }
  const details = usePromise(cache.get(smileyId)!);
  React.useEffect(() => {
    return () => {
      cache.delete(smileyId);
    };
  }, []);
  return <div className={_c2}>
			<div className={_c3}>
				<Text state={'secondary'} className={_c4}>
					<span dangerouslySetInnerHTML={{
          __html: details.details.description
        }} />{' '}
				</Text>
			</div>
			<div className={_c5}>
				<Button onPress={() => {
        smileyService.tradeSmiley(smileyId, details.price);
        overlay.dispose();
      }}>
					<Text className={_c6}>
						<div className={_c7}>
							Für{' '}
							{Intl.NumberFormat('de-DE', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }).format(details.price)}
							<IconKnuddel size={'large'} />
							Knuddel kaufen
						</div>
					</Text>
				</Button>
			</div>
		</div>;
});
SmileyDetailsView.displayName = 'SmileyDetailsView';
const _c0 = " Knu-Flex mr-small ";
const _c1 = " Knu-Flex mr-small ";
const _c2 = " Knu-FlexCol ";
const _c3 = " Knu-FlexCol gap-tiny ";
const _c4 = "  ";
const _c5 = " Knu-FlexCol mt-large ";
const _c6 = "  ";
const _c7 = " Knu-Flex alignItems-center ";