import { Flex, Text } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { AnimatedDots } from '@shared/components';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
export const TypingIndicator = () => {
  const i18n = useService($I18n);
  return <div className={_c0}>
			<Text type={'tiny'} state={'tertiary'} className={_c1}>
				{i18n.format(declareFormat({
        id: 'TYPING',
        defaultFormat: 'Typing'
      }))}
			</Text>
			<AnimatedDots type={'tiny'} state={'tertiary'} />
		</div>;
};
const _c0 = " Knu-Flex alignItems-center height-16px ";
const _c1 = "  ";