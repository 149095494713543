import { mixNumber as p } from "./number.js";
import { hslaToRgba as f } from "../hsla-to-rgba.js";
import { hex as c } from "../../value/types/color/hex.js";
import { rgba as i } from "../../value/types/color/rgba.js";
import { hsla as l } from "../../value/types/color/hsla.js";
import { mixImmediate as u } from "./immediate.js";
const s = (o, e, r) => {
  const t = o * o, n = r * (e * e - t) + t;
  return n < 0 ? 0 : Math.sqrt(n);
}, d = [c, i, l], x = (o) => d.find((e) => e.test(o));
function a(o) {
  const e = x(o);
  if (!e)
    return !1;
  let r = e.parse(o);
  return e === l && (r = f(r)), r;
}
const A = (o, e) => {
  const r = a(o), t = a(e);
  if (!r || !t)
    return u(o, e);
  const n = { ...r };
  return (m) => (n.red = s(r.red, t.red, m), n.green = s(r.green, t.green, m), n.blue = s(r.blue, t.blue, m), n.alpha = p(r.alpha, t.alpha, m), i.transform(n));
};
export {
  A as mixColor,
  s as mixLinearColor
};
