import * as React from 'react';
import { ChangeEvent, PureComponent } from 'react';
import { defaultProps, InputFieldProps } from './InputFieldInterface';
import { Flex } from '@knuddels/component-library';
import styles from './InputField.module.scss';
export class InputField extends PureComponent<InputFieldProps> {
  static defaultProps = defaultProps;
  private ref = React.createRef<HTMLInputElement>();
  private handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (this.props.onChangeText) {
      this.props.onChangeText(event.target.value);
    }
  };
  public focus = (): void => {
    if (this.ref.current) {
      this.ref.current.focus();
    }
  };
  public clear = (): void => {
    if (this.ref.current) {
      this.ref.current.value = '';
    }
    if (this.props.onChangeText) {
      this.props.onChangeText('');
    }
  };
  render(): JSX.Element {
    const {
      icon,
      invalidInput,
      label,
      placeholder,
      value,
      onBlur,
      children,
      autoFocus,
      bordered,
      onFocus,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChangeText,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSubmit,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      returnKeyType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      autoCapitalize,
      styleOverride,
      testId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      textContentType,
      ...restProps
    } = this.props;
    const containerClassname = [styles.textinput, bordered ? styles.bordered : '', invalidInput ? styles.invalid : '', 'content-is-light'].join(' ');
    return <label className={containerClassname} style={styleOverride}>
				{icon && <div className={_c0}>
						{icon}
					</div>}
				<input ref={this.ref} placeholder={placeholder} className={styles.input} style={{
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: icon ? 36 : 8,
        paddingRight: 8
      }} defaultValue={value} aria-label={label} onChange={this.handleChange} onBlur={onBlur} autoFocus={autoFocus} onFocus={onFocus} data-test-id={testId} readOnly={false} onClick={e => {
        e.stopPropagation();
      }} {...restProps} />
				{children}
			</label>;
  }
}
const _c0 = " Knu-Flex position-absolute pointerEvents-none left-small ";