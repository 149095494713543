import * as React from 'react';
import { EditProfileEntry, EditProfileEntryBaseProps } from './EditProfileEntry';
import { declareProps, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Input } from '@knuddels/component-library';
import { useScrollIntoView } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfile/EditProfileContent/Entry/useScrollIntoView';
@injectable()
class EditProfileEntryDateModel {
  constructor(@injectProps()
  private readonly props: EditProfileEntryBaseProps) {}
  public onChangeText = (newText: string): void => {
    this.props.onValueChanged(this.props.field, newText);
  };
}
export const EditProfileEntryDate = injectedComponent({
  name: 'EditProfileEntryDateBase',
  model: EditProfileEntryDateModel,
  inject: {
    i18n: $I18n
  },
  props: declareProps<EditProfileEntryBaseProps & {
    dateString: string;
  }>()
}, ({
  model,
  i18n,
  dateString,
  validity,
  helperText,
  title,
  image
}) => {
  const {
    ref,
    onFocus
  } = useScrollIntoView();
  return <EditProfileEntry image={image} valid={validity.type === 'valid'} innerRef={ref}>
				<Input onFocus={onFocus} label={title} labelStyle={'always-on-top'} error={validity.errorText || validity.type === 'invalid'} description={helperText} placeholder={declareFormat({
      id: 'DATE_PLACEHOLDER',
      defaultFormat: 'Not specified'
    }).format(i18n)} value={dateString} onChange={({
      value
    }) => {
      model.onChangeText(value);
    }} />
			</EditProfileEntry>;
});