import { ChannelGroupFragment } from '@generated/graphql';
export function mergePreviewMembers(oldChannel: ChannelGroupFragment, newChannel: ChannelGroupFragment): ChannelGroupFragment {
  const indicesToUpdate = selectRandomIndices(0, newChannel.previewMembers.others.length - 1, 3);
  const newOthers = [...oldChannel.previewMembers.others];
  indicesToUpdate.forEach(index => {
    const toInsert = newChannel.previewMembers.others[index];
    if (newOthers.some(it => it.id === toInsert?.id)) {
      return;
    }
    if (index > newOthers.length) {
      newOthers.push(newChannel.previewMembers.others[index]);
    } else {
      newOthers[index] = newChannel.previewMembers.others[index];
    }
  });
  return {
    ...newChannel,
    info: {
      ...newChannel.info,
      mainCategoryName: oldChannel.info.mainCategoryName
    },
    previewMembers: {
      ...newChannel.previewMembers,
      others: newOthers
    }
  };
}
function selectRandomIndices(start: number, end: number, count: number): number[] {
  const result = [];
  for (let i = 0; i < count; i++) {
    const index = Math.floor(Math.random() * (end - start)) + start;
    result.push(index);
  }
  return result;
}