import * as React from 'react';
import { MessengerBasicUserFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { $MessengerComponents, MessengerInterfaces } from '@knuddelsModules/Messenger';
import { KnuddelTransferMessageContent } from './KnuddelTransferMessageContent';
import { PhotoCommentMessageContent } from './PhotoCommentMessageContent';
type MessengerMessageContentProps = {
  renderTime?: boolean;
  activeUserId: string;
  sender: MessengerBasicUserFragment;
  receiverId: string;
  receiverNick: string;
  message: MessengerInterfaces.ClientMessengerMessage['message'];
  getHighlightedLinkColor?(): string;
};
export const MessengerMessageContent = observer<MessengerMessageContentProps>('MessengerMessageContent', ({
  activeUserId,
  receiverId,
  receiverNick,
  sender,
  message,
  renderTime = true,
  getHighlightedLinkColor
}): JSX.Element => {
  const MessengerComponents = useService($MessengerComponents);
  switch (message.content.__typename) {
    case 'ConversationKnuddelTransferMessageContent':
      return <KnuddelTransferMessageContent receiverNick={receiverNick} message={message} content={message.content} activeUserId={activeUserId} />;
    case 'ConversationHiddenPhotoCommentMessageContent':
    case 'ConversationDeletedPhotoCommentMessageContent':
    case 'ConversationVisiblePhotoCommentMessageContent':
      return <PhotoCommentMessageContent receiverId={receiverId} receiverNick={receiverNick} activeUserId={activeUserId} message={message} />;
    default:
      return <MessengerComponents.ConversationMessageContent sentByActiveUser={sender.id === activeUserId} message={message} sender={sender} addPadding={false} receiverId={receiverId} renderTime={renderTime} getHighlightedLinkColor={getHighlightedLinkColor} forceBoldLinks />;
  }
});