import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService, $JoinChannelService } from '@knuddelsModules/Channel';
import { ModalButtons, ModalCard, ModalContentWrapper, Spacer } from '@shared/components';
import { Flex, Image, LineDivider, Text, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { action, observable } from '@knuddels-app/mobx';
import { $I18n, declareFormat, FormatId } from '@knuddels-app/i18n';
@injectable()
class ChannelDisconnectedContentModel {
  @observable
  private _reconnecting = false;
  constructor(@injectProps()
  props: unknown, @inject($ActiveChannelService)
  private readonly activeChannelService: typeof $ActiveChannelService.T, @inject($JoinChannelService)
  private readonly joinChannelService: typeof $JoinChannelService.T) {}
  public reconnect = async (): Promise<void> => {
    this.setReconnecting(true);
    const activeChannel = this.activeChannelService.activeChannel;
    const connected = activeChannel ? await this.joinChannelService.joinChannelById(activeChannel.id, {
      redirectToChannelListOnFail: true,
      redirectToChannelOnSuccess: true,
      mayJoinSubChannel: true
    }) : await this.joinChannelService.initialJoinChannel();
    if (connected) {
      this.activeChannelService.setState({
        kind: 'active'
      });
    } else {
      this.setReconnecting(false);
    }
  };
  public get reconnecting(): boolean {
    return this._reconnecting;
  }
  @action
  public setReconnecting(reconnecting: boolean): void {
    this._reconnecting = reconnecting;
  }
  @action.bound
  public closeDisconnectModal(): Promise<void> {
    // clearing channel closes the modal by setting the state to `noChannel`.
    return this.activeChannelService.clearActiveChannel();
  }
}
type ContentType = 'disconnected-by-other-session' | 'disconnected-by-server';
export const ChannelDisconnectedContent = injectedComponent({
  name: 'ChannelDisconnectedContent',
  model: ChannelDisconnectedContentModel,
  props: declareProps<{
    type: ContentType;
  }>(),
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n,
  type
}) => {
  const data = modalContent[type];
  return <div className={_c0}>
				<ModalCard>
					<ModalContentWrapper alignCenter>
						<Spacer size={'xxlarge'} />
						<Image src={data.imageSrc} alt={''} resizeMode={'contain'} style={{
          width: resolveThemingValue(data.imageWidth + 'px', "sizes", useTheme()),
          height: resolveThemingValue(data.imageHeight + 'px', "sizes", useTheme())
        }} className={_c1} />
						<Spacer size={'large'} />
						<Text type={'title'} className={_c2}>
							{data.headline.format(i18n)}
						</Text>
						<Spacer size={'base'} />
						<Text state={'secondary'} className={_c3}>
							{data.content.format(i18n)}
						</Text>
						<Spacer size={'xlarge'} />
					</ModalContentWrapper>
					<LineDivider className={_c4} />
					<ModalButtons primaryButton={{
        text: data.mainButtonText.format(i18n),
        spinning: model.reconnecting,
        onClick: model.reconnect,
        width: 'fluid',
        // to fully fit the button text in one line
        minWidth: 134
      }} secondaryButton={data.secondaryButtonText ? {
        text: data.secondaryButtonText.format(i18n),
        onClick: model.closeDisconnectModal
      } : undefined} />
				</ModalCard>
			</div>;
});
const reconnectText = declareFormat({
  id: 'disconnectedModal.button',
  defaultFormat: 'Reconnect'
});
const modalContent: { [key in ContentType]: {
  imageSrc: string;
  imageWidth: number;
  imageHeight: number;
  headline: FormatId;
  content: FormatId;
  mainButtonText: FormatId;
  secondaryButtonText?: FormatId;
} } = {
  'disconnected-by-other-session': {
    imageSrc: require('../../assets/sm_handyA_00a.bx2.png'),
    imageWidth: 40,
    imageHeight: 40,
    headline: declareFormat({
      id: 'disconnectedModal.headline',
      defaultFormat: 'You are currently connected to a channel with another device.'
    }),
    content: declareFormat({
      id: 'disconnectedModal.text',
      defaultFormat: 'You can only be in a channel with one device. Either you can reconnect here or continue using all the other features available to you without a chat room.'
    }),
    mainButtonText: reconnectText,
    secondaryButtonText: declareFormat({
      id: 'disconnectedModal.ok-button',
      defaultFormat: 'Ok'
    })
  },
  'disconnected-by-server': {
    imageSrc: require('../../assets/sm_15-cal_blackhole.gif'),
    imageWidth: 61,
    imageHeight: 44,
    headline: declareFormat({
      id: 'disconnectedModal-server.headline',
      defaultFormat: 'Connection has been lost'
    }),
    content: declareFormat({
      id: 'disconnectedModal-server.text',
      defaultFormat: "Unfortunately you've lost connection to the channel. Please reconnect."
    }),
    mainButtonText: reconnectText
  }
};
const _c0 = " Knu-Flex position-relative px-large maxWidth-425px ";
const _c1 = "  ";
const _c2 = " textAlign-center ";
const _c3 = " textAlign-center ";
const _c4 = "  ";