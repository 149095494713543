import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { NotificationEnabledInput } from '@generated/graphql';
import { shouldBeUnreachable } from '@knuddels/std';
import { NotificationStatusType } from '../../../constants';

export const useTrackMessengerSettings = () => {
	const logEvent = useLogEvent();

	return (itemId: string) => {
		logEvent('Settings_Messenger', itemId);
	};
};

export const useTrackEnableChanged = (type: NotificationStatusType) => {
	const track = useTrackMessengerSettings();

	return (enabled: boolean) => {
		if (enabled) {
			track(`${type}_Activated`);
		} else {
			track(`${type}_Deactivated`);
		}
	};
};

export const useTrackUpdateNotificationSettings = (
	type: NotificationStatusType
) => {
	const track = useTrackMessengerSettings();

	const trackOneUpdate = (settingName: string, value: boolean) => {
		if (value) {
			track(`${settingName}${type}_Activated`);
		} else {
			track(`${settingName}${type}_Deactivated`);
		}
	};

	return (settings: NotificationEnabledInput) => {
		const settingKey = Object.keys(
			settings
		)[0] as keyof NotificationEnabledInput;
		const settingValue = Object.values(settings)[0];

		if (!settingKey) {
			return;
		}

		switch (settingKey) {
			case 'notifyBirthday':
				trackOneUpdate('Birthday', settingValue);
				return;
			case 'notifyCommunityStatusChange':
				trackOneUpdate('NewStatus', settingValue);
				return;
			case 'notifyProfileChange':
				trackOneUpdate('ProfileChanged', settingValue);
				return;
			case 'notifyProfilePictureChange':
				trackOneUpdate('NewPhoto', settingValue);
				return;
			case 'notifyTeamJoin':
				trackOneUpdate('Team', settingValue);
				return;
			case 'notifyNicknameChange':
				trackOneUpdate('NickChanged', settingValue);
				return;
			default:
				shouldBeUnreachable(settingKey);
		}
	};
};
