import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import * as React from 'react';
import { CommunicationRequestBox } from './CommunicationRequestBox';
import { Flex, FlexCol, Text, toPointerHandler } from '@knuddels/component-library';
export function CommunicationRequestBoxTitle({
  model
}: typeof CommunicationRequestBox.TPropsWithModel): JSX.Element {
  return <div className={_c0}>
			<div onClick={toPointerHandler(model.handleUserImageClick)} className={_c1}>
				<UserImage uid={model.user.id} type={UserImageType.COMMUNICATION_REQUEST_BOX} highlight={'fancy'} />
			</div>
			<div className={_c2}>
				<Text selectable={true} type={'title'} bold={true} className={_c3}>
					{model.title}
				</Text>
			</div>
		</div>;
}
const _c0 = " Knu-Flex alignItems-center overflow-visible ";
const _c1 = " Knu-FlexCol overflow-visible ";
const _c2 = " Knu-FlexCol flex-1 ml-base ";
const _c3 = "  ";