type BrowserInfo = {
	name: string;
	version: string;
};

const getBrowserInfo = (): BrowserInfo => {
	if (!navigator?.userAgent) {
		return { name: 'unknown', version: '0' };
	}

	const ua = navigator.userAgent;

	let tem: RegExpMatchArray | null = null;
	let M: RegExpMatchArray | string[] =
		ua.match(
			/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
		) || [];

	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || null;
		return { name: 'IE', version: tem ? tem[1] : '' };
	}

	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR|Edge\/(\d+)/);
		if (tem !== null) {
			return { name: 'Opera', version: tem[1] };
		}
	}

	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

	tem = ua.match(/version\/(\d+)/i);

	if (tem !== null) {
		M.splice(1, 1, tem[1]);
	}

	return {
		name: M[0],
		version: M[1],
	};
};

// TODO: use from the same constant from the component library
export const BrowserInfo = getBrowserInfo();
