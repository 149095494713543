import { ServiceId } from '../../ServiceId';
import { ServiceIdMap, TranslateServiceIds } from '../../getServices';
import { BindingWithoutTargetImpl } from './BindingWithoutTargetImpl';
import { BindingTarget } from './BindingTarget';

/**
 * Binds service ids to implementations in a given context.
 * Does not instantiate them.
 */
export interface ServiceBinder extends LateBindingWithoutTarget {
	bind<T>(id: ServiceId<T>): BindingWithoutTarget<T>;
}

/**
 * A binding that still misses the target implementation.
 */
export interface BindingWithoutTarget<T> {
	/**
	 * Binds to class that is only created once during the lifetime of the container.
	 */
	toSingleton(clazz: Ctor<T>): void;
	/**
	 * Like `toSingleton`, but automatically instantiates the class when the container is instantiated.
	 */
	toSingletonAutostart(clazz: Ctor<T>): void;
	/**
	 * Binds to an already created instance.
	 */
	toConstant(constant: PublicProperties<T>): void;
	/**
	 * Like `toSingleton`, but binds to the result of the factory function.
	 * You can use other services here.
	 */
	toDynamicSingleton<TServices extends ServiceIdMap>(
		dependencies: TServices,
		factory: (services: TranslateServiceIds<TServices>) => T
	): void;
}

export type Ctor<T> = new (...args: any[]) => T;
export type PublicProperties<T> = { [TKey in keyof T]: T[TKey] };

/**
 * A binding that still misses the target implementation and the service id.
 *
 * This interface allows to specify the id after the binding.
 * With it, you can do this:
 * ```ts
 * foreachProp(ids, {
 * $UnnamedModule: ctx.globalScope.toSingleton(s.UnnamedModule),
 * });
 * ```
 */
export interface LateBindingWithoutTarget {
	toSingleton<T>(clazz: Ctor<T>): ServiceIdFunc<T>;
	toSingletonAutostart<T>(clazz: Ctor<T>): ServiceIdFunc<T>;
	toConstant<T>(constant: T): ServiceIdFunc<T>;
}

export type ServiceIdFunc<T> = ((id: ServiceId<T>) => void) & {
	/** tag is to improve typesafety for foreachProp  */
	tag: ServiceId<T>;
};

export class ServiceBinderImpl implements ServiceBinder {
	constructor(private readonly bindingTarget: BindingTarget) {}

	public rebind<T>(id: ServiceId<T>): BindingWithoutTarget<T> {
		return new BindingWithoutTargetImpl(id, this.bindingTarget, true);
	}

	public bind<T>(id: ServiceId<T>): BindingWithoutTarget<T> {
		return new BindingWithoutTargetImpl(id, this.bindingTarget, false);
	}

	public toSingleton<T>(clazz: Ctor<T>): ServiceIdFunc<T> {
		return ((id: ServiceId<T>) => this.bind(id).toSingleton(clazz)) as any;
	}
	public toSingletonAutostart<T>(clazz: Ctor<T>): ServiceIdFunc<T> {
		return ((id: ServiceId<T>) =>
			this.bind(id).toSingletonAutostart(clazz)) as any;
	}
	public toConstant<T>(constant: T): ServiceIdFunc<T> {
		return ((id: ServiceId<T>) =>
			this.bind(id).toConstant(constant)) as any;
	}
}
