import { Category } from '@knuddels-app/analytics/Category';
import { createUserTimingTracker } from '@knuddels-app/analytics/userTimingTracking';
import { createTrackingEventType } from '@knuddels-app/analytics/eventCreators';
import { SelectAndUploadProfilePictureEvents } from './bundle/sagas/profilePictureUpload/interfaces';

export const photoUploadTimingTracker = createUserTimingTracker(
	'Profile',
	'ProfileImageUpload'
);

export const profileOpenedTracker = createUserTimingTracker(
	'Profile',
	'ProfileOpened'
);

export const quickWhoisOpenedTracker = createUserTimingTracker(
	'Profile',
	'QuickwhoisOpened'
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////

// These are needed in Nav

export const trackingCallbacks: SelectAndUploadProfilePictureEvents = {
	onNativeGalleryPermissionRequested: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_PermissionRequested'
		),
	onNativeGalleryPermissionNever: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_PermissionNever'
		),
	onNativeGalleryPermissionGranted: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_PermissionGranted'
		),
	onNativeGalleryPermissionDenied: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_PermissionDenied'
		),

	onExistingProfileImageShown: () => {},
	onExistingProfileImageChange: () => {},
	onExistingProfileImageClose: () => {},
	onExistingProfileImageClicked: () => {},

	onGalleryOpened: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_ImageGalleryOpened'
		),
	onImageSelected: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_ImageSelected'
		),

	onPreviewOpened: () => {},
	onPreviewChange: () => {},
	onPreviewImageClicked: () => {},
	onContinue: () => {},
	onAbort: () => {},

	onProgressModalShown: () => {},
	onUploadError: () =>
		OwnProfileViewUploadProfileImageEvent.track('UploadProfileImage_Error'),
	onUploadSuccess: () =>
		OwnProfileViewUploadProfileImageEvent.track(
			'UploadProfileImage_ImageChanged'
		),

	onUploadTaskStarted: () => photoUploadTimingTracker.start(),
	onUploadTaskEnded: () => photoUploadTimingTracker.stop(),
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ProfileViewOpenedEvent = createTrackingEventType(
	Category.ProfileProfileview,
	'Profileview_Opened'
);

export const ProfileViewClosedEvent = createTrackingEventType(
	Category.ProfileProfileview,
	'Profileview_Closed'
);

export const ProfileViewTabbarEvent = createTrackingEventType(
	Category.ProfileProfileview,
	'Profileview_Tabbar'
);

export const ProfileViewFABEvent = createTrackingEventType(
	Category.ProfileProfileview,
	'Profileview_FAB'
);

export const OwnProfileViewOpenedEvent = createTrackingEventType(
	Category.ProfileOwnProfileview,
	'OwnProfileView_Opened'
);

export const OwnProfileViewClosedEvent = createTrackingEventType(
	Category.ProfileOwnProfileview,
	'OwnProfileView_Closed'
);

export const OwnProfileViewTabbarEvent = createTrackingEventType(
	Category.ProfileOwnProfileview,
	'OwnProfileView_Tabbar'
);

export const OwnProfileViewFABEvent = createTrackingEventType(
	Category.ProfileOwnProfileview,
	'OwnProfileView_FAB'
);

export const OwnProfileViewProfileEditEvent = createTrackingEventType(
	Category.ProfileOwnProfileview,
	'OwnProfileView_ProfileEdit'
);

export const OwnProfileViewUploadProfileImageEvent = createTrackingEventType(
	Category.ProfileOwnProfileview,
	'OwnProfileView_UploadProfileImage'
);

export const ProfileTitlebarEvent = createTrackingEventType(
	Category.ProfileProfileview,
	'Profileview_Titlebar'
);

/* Reporting user account events */

export const ProfileReportUserAccountOpenEvent = createTrackingEventType(
	Category.ProfileReportUserAccount,
	'ReportUserAccount_Opened'
);

export const ProfileReportUserAccountReasonChosenEvent = createTrackingEventType(
	Category.ProfileReportUserAccount,
	'ReportUserAccount_ReasonChosen'
);

export const ProfileReportUserAccountAddedDescriptionEvent = createTrackingEventType(
	Category.ProfileReportUserAccount,
	'ReportUserAccount_AddedDescription'
);

export const ProfileReportUserAccountCancelEvent = createTrackingEventType(
	Category.ProfileReportUserAccount,
	'ReportUserAccount_Cancel'
);

export const ProfileReportUserAccountSendMessageEvent = createTrackingEventType(
	Category.ProfileReportUserAccount,
	'ReportUserAccount_SendMessage'
);

export const ProfileReportUserAccountErrorEvent = createTrackingEventType(
	Category.ProfileReportUserAccount,
	'ReportUserAccount_Error'
);
