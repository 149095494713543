import * as React from 'react';
import { MutableRefObject } from 'react';
import { Carousel, Spacer } from '@shared/components';
import { Flex, FlexCol, Image, PointerEvent, Smiley, SvgRenderer, Text, FallbackBox, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { $I18n, FormatId } from '@knuddels-app/i18n';
import { Confetti } from './confetti';
import { useService } from '@knuddels-app/DependencyInjection';
import { FeatureFlag } from '@knuddels-app/featureFlags';
import { $ScreenService } from '@knuddels-app/Screen';
import { getPlatform } from '@knuddels-app/tools/clientInformation/platform';
export interface InfoBoxImage {
  source: any;
  width?: number | string;
  height?: number | string;
  position?: 'top' | 'bottom';
}
export interface InfoBoxItem {
  image: InfoBoxImage;
  smile?: InfoBoxImage;
  bgImage?: InfoBoxImage;
  highlightText?: FormatId;
  headlineText: FormatId;
  descriptionText: FormatId;
  width?: number;
  height?: number;
  showConfetti?: boolean;
}
export interface NewFeatureInfo {
  featureFlag: FeatureFlag;
  items: ReadonlyArray<InfoBoxItem>;
  // If not specified, will be shown on all platforms
  platforms?: ReturnType<typeof getPlatform>[];
  // If not specified, will be shown without any time limit
  endDate?: number;
}
interface Props {
  snapToNextRef: MutableRefObject<(() => void) | null | undefined>;
  items: ReadonlyArray<InfoBoxItem>;
  initialActiveItemIndex: number;
  i18n: typeof $I18n.T;
  onActiveIndexChanged(index: number): void;
}
export const InfoBox: React.FC<Props> = props => {
  const screenService = useService($ScreenService);
  const carouselRef = React.useRef<Carousel<unknown> | null>();
  const [{
    pageX,
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0,
    pageX: 0
  });
  const isStacked = screenService.isStackedLayout;
  const handlePress = (e: PointerEvent) => {
    const mouseX = e.pageX;
    if (mouseX - pageX <= width / 2) {
      carouselRef.current?.snapToPrev();
    } else {
      carouselRef.current?.snapToNext();
    }
  };
  React.useEffect(() => {
    props.snapToNextRef.current = () => {
      carouselRef.current?.snapToNext();
    };
  }, [props.snapToNextRef, carouselRef]);
  return <FallbackBox onClick={toPointerHandler(handlePress)} onLayout={e => setSize(e)} className={_c0}>
			{height > 0 && <Carousel key={height} ref={ref => carouselRef.current = ref} size={width} items={props.items} renderItem={(item, index) => <Item key={`${index}`} item={item} width={width} height={height} isStacked={isStacked} />} onSelectedItemChanged={props.onActiveIndexChanged} initialIndex={props.initialActiveItemIndex} />}
		</FallbackBox>;
};
const Item: React.FC<{
  item: InfoBoxItem;
  width: number;
  height: number;
  isStacked: boolean;
}> = ({
  item,
  width,
  height,
  isStacked
}) => {
  const i18n = useService($I18n);
  return <div style={{
    width: resolveThemingValue(width, "sizes", useTheme()),
    height: resolveThemingValue(height, "sizes", useTheme())
  }} className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c2 + (isStacked ? _c3 : _c4)}>
				{item.showConfetti && <div className={_c5}>
						<SvgRenderer height={102} width={387} color={'rgba(0, 0,0,1.0)'} svg={Confetti} />
					</div>}
				{!!item.bgImage && <div className={_c6}>
						<Image src={item.bgImage.source} alt={''} style={{
          width: resolveThemingValue(item.bgImage.width ?? 'full', "sizes", useTheme()),
          height: resolveThemingValue(item.bgImage.height ? item.bgImage.height : isStacked ? '80%' : '90%', "sizes", useTheme())
        }} className={_c7} />
					</div>}
				<ItemImage image={item.image} smile={item.smile} />
			</div>
			<div className={_c8}>
				{item.highlightText ? <>
						<Text type={'body2'} state={'highlighted'} bold={true} className={_c9}>
							{item.highlightText.format(i18n)}
						</Text>
						<Spacer size={'small'} />
					</> : <Spacer size={'large'} />}
				<Text type={'headline'} bold={true} className={_c10}>
					{item.headlineText.format(i18n)}
				</Text>
				<Spacer size={'large'} />
				<div className={_c11}>
					<Text type={'body1'} className={_c12}>
						{item.descriptionText.format(i18n)}
					</Text>
				</div>
			</div>
		</div>;
};
const ItemImage: React.FC<{
  image: InfoBoxImage;
  smile?: InfoBoxImage;
}> = ({
  image,
  smile
}) => {
  const justifyContent = !image.position ? 'center' : image.position === 'top' ? 'flex-start' : 'flex-end';
  return <div style={{
    justifyContent: resolveThemingValue(justifyContent, "theme", useTheme()),
    paddingTop: resolveThemingValue(!image.position ? 'xlarge' : undefined, "spacing", useTheme())
  }} className={_c13}>
			<div style={{
      minWidth: resolveThemingValue(image.width, "sizes", useTheme()),
      minHeight: resolveThemingValue(image.height, "sizes", useTheme())
    }} className={_c14}>
				<Image src={image.source} alt={''} style={{
        width: resolveThemingValue(image.width, "sizes", useTheme()),
        height: resolveThemingValue(image.height, "sizes", useTheme())
      }} className={_c15} />
				{!!smile && <div className={_c16}>
						<Smiley src={smile.source} width={smile.width} height={smile.height} />
					</div>}
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol flex-1 alignSelf-stretch ";
const _c1 = " Knu-FlexCol bg-contentLightBg alignItems-center pb-xlarge ";
const _c2 = " Knu-FlexCol position-relative size-full ";
const _c3 = " flex-2 ";
const _c4 = " flex-3 ";
const _c5 = " Knu-Flex position-absolute inset-none placeItems-center ";
const _c6 = " Knu-Flex position-absolute inset-none ";
const _c7 = "  ";
const _c8 = " Knu-FlexCol flex-2 width-full alignItems-center pt-xlarge px-xlarge ";
const _c9 = " textAlign-center ";
const _c10 = " textAlign-center ";
const _c11 = " Knu-Flex justifyContent-center width-295px ";
const _c12 = " textAlign-center ";
const _c13 = " Knu-FlexCol position-absolute inset-none alignItems-center px-xlarge ";
const _c14 = " Knu-FlexCol position-relative ";
const _c15 = "  ";
const _c16 = " Knu-Flex position-absolute bottom-none right-tiny ";