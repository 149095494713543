import { FullConversationFragment, Scalars } from '@generated/graphql';

export function getPingPongDepth(
	conversation: FullConversationFragment
): number {
	let depth = 0;
	let firstUser: Scalars['ID'] = undefined;
	let prevUser: Scalars['ID'] = undefined;

	conversation.conversationMessages.messages.forEach(msg => {
		if (!firstUser) {
			firstUser = msg.sender.id;
		} else if (prevUser !== msg.sender.id && firstUser !== msg.sender.id) {
			depth++;
		}

		prevUser = msg.sender.id;
	});

	return depth;
}
