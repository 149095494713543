import { ChannelInfo } from './ChannelInfo';
import { LazyMap } from '@knuddels/std';
import { K3ApolloClient } from '@knuddels-app/Connection';
import { $I18n } from '@knuddels-app/i18n';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { Channel } from '@generated/graphql';
import { ActiveChannelData } from './ActiveChannelService';
import { action, observable } from '@knuddels-app/mobx';

/**
 * Keeps track of events that occur for a channel, before the channel bootstrap
 * event occurs.
 * These events are replayed on a new ChannelInfo object when a channel
 * bootstrap event occurs.
 */
export class ChannelInfoManager {
	public get activeChannel(): ChannelInfo | undefined {
		return this._activeChannel;
	}

	@observable private _activeChannel: ChannelInfo | undefined;

	private readonly channelEventQueueMap = new LazyMap<
		Channel['id'],
		ChannelInfoEventQueue
	>(() => new ChannelInfoEventQueue());

	constructor(
		private k3ApolloClient: K3ApolloClient,
		private i18n: typeof $I18n.T,
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {}

	public handle(
		channelId: Channel['id'],
		eventHandler: (channelInfo: ChannelInfo) => void
	): void {
		if (this._activeChannel && this._activeChannel.id === channelId) {
			eventHandler(this._activeChannel);
		} else {
			this.channelEventQueueMap.get(channelId).add(eventHandler);
		}
	}

	@action
	public initChannel(bootstrapChannelInfo: ActiveChannelData): void {
		this._activeChannel = new ChannelInfo(
			bootstrapChannelInfo.id,
			this.k3ApolloClient,
			this.i18n,
			this.firebaseAnalyticsService
		);
		this._activeChannel.updateData(bootstrapChannelInfo);

		this.channelEventQueueMap
			.get(bootstrapChannelInfo.id)
			.playEvents(this._activeChannel);
		this.channelEventQueueMap.clear();
	}

	@action
	public clearChannel(): void {
		this._activeChannel = undefined;
	}
}

class ChannelInfoEventQueue {
	private readonly events = new Array<(channelInfo: ChannelInfo) => void>();

	add(eventHandler: (channelInfo: ChannelInfo) => void): void {
		this.events.push(eventHandler);
	}

	playEvents(channelInfo: ChannelInfo): void {
		this.events.forEach(handler => handler(channelInfo));
		this.events.length = 0;
	}
}
