import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	CurrentUserKnuddelDisplay,
	KnuddelDisplay,
	KnuddelSubscription,
} from '../components/KnuddelDisplay';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';
@injectable()
export class KnuddelDisplayComponents {
	KnuddelDisplay = KnuddelDisplay;
	CurrentUserKnuddelDisplay = CurrentUserKnuddelDisplay;
	KnuddelSubscription = KnuddelSubscription;

	constructor(
		@inject($MessageFormatProvider)
		private readonly messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
	}
}
