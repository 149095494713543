const a = {
  id: "id",
  tabIndex: "tabIndex",
  ariaLabel: "aria-label",
  ariaDisabled: "aria-disabled",
  ariaLabelledBy: "aria-labelledby",
  ariaDescribedBy: "aria-describedby",
  ariaRole: "role",
  ariaControls: "aria-controls",
  ariaSelected: "aria-selected",
  ariaHidden: "aria-hidden",
  ariaChecked: "aria-checked",
  ariaValueNow: "aria-valuenow",
  ariaValueMin: "aria-valuemin",
  ariaValueMax: "aria-valuemax",
  ariaOrientation: "aria-orientation",
  ariaLive: "aria-live"
};
export {
  a as AriaPropMap
};
