import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerListService } from '../../providedServices';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
import { ReadStateSource } from './ReadStateSource';
import { BADGE_PLACEHOLDER } from '@shared/helper/getBadgeCount';
type MessengerReadState = {
  kind: 'SomeUnreadMessages';
  unreadCount: number;
} | {
  kind: 'NoUnreadMessages';
} | {
  kind: 'SomeUnreadConversations';
};
export const useMessengerReadStateString = () => {
  const conversations = useService($MessengerListService).unreadConversations;
  const friendRequests = useService($FriendRequestsService).friendRequestsForConversation;
  return messengerReadStateToString(getReadStateFromConversations([...conversations, ...friendRequests]));
};
export function getReadStateFromConversations(readStateSources: readonly ReadStateSource[]): MessengerReadState {
  return readStateSources.reduce((currentReadState, source) => {
    if (currentReadState.kind === 'SomeUnreadMessages') {
      return {
        kind: 'SomeUnreadMessages',
        unreadCount: currentReadState.unreadCount + source.readState.unreadMessageCount
      };
    } else if (source.readState.unreadMessageCount > 0) {
      return {
        kind: 'SomeUnreadMessages',
        unreadCount: source.readState.unreadMessageCount
      };
    } else if (source.readState.markedAsUnread) {
      return {
        kind: 'SomeUnreadConversations'
      };
    } else {
      return currentReadState;
    }
  }, ({
    kind: 'NoUnreadMessages'
  } as MessengerReadState));
}
export function messengerReadStateToString<T extends MessengerReadState>(messengerReadState: T): {
  NoUnreadMessages: undefined;
  SomeUnreadMessages: string;
  SomeUnreadConversations: string;
}[T['kind']];
export function messengerReadStateToString(unreadCount: MessengerReadState): string | undefined {
  if (unreadCount.kind === 'NoUnreadMessages') {
    return undefined;
  }
  if (unreadCount.kind === 'SomeUnreadConversations') {
    return BADGE_PLACEHOLDER;
  }
  return unreadCount.unreadCount.toString();
}