import * as React from 'react';
import { Spacer } from '@shared/components';
import { observer } from 'mobx-react';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { createAccessiblePointerEventHandler, Flex, FlexCol, PointerEvent, rgb, Text, ThemeOverride, toPointerHandler, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { IconTimer } from '@shared/icons/IconTimer';
export interface UploadImageLightboxSource {
  type: 'upload';
  imageUrl: string;
  sendImage(expirationInSeconds?: number): void;
  onClose(): void;
}
interface Props {
  source: UploadImageLightboxSource;
  caption?: string;
  onClose?: () => void;
}
@observer
export class LightboxImageUploadFooter extends React.PureComponent<Props> {
  render(): React.ReactNode {
    return <>
				<div className={_c0}>
					<Text type={'body1'} state={'secondary'} className={_c1}>
						{formatMessage(declareFormat({
            id: 'IMAGE_PREVIEW',
            defaultFormat: 'Image preview'
          }))}
					</Text>
				</div>
				<div onClick={toPointerHandler(this.stopPropagation)} className={_c2}>
					<Button icon text={'3s'} onClick={this.send3sSnap} />
					<Button icon text={'10s'} onClick={this.send10sSnap} />
					<Button icon text={'60s'} onClick={this.send60sSnap} />
					<Button text={'Normal'} onClick={this.sendNormal} />
				</div>
			</>;
  }
  private readonly send3sSnap = (): void => {
    this.sendImage('3s');
  };
  private readonly send10sSnap = (): void => {
    this.sendImage('10s');
  };
  private readonly send60sSnap = (): void => {
    this.sendImage('60s');
  };
  private readonly sendNormal = (): void => {
    this.sendImage('normal');
  };
  private readonly stopPropagation = (e: PointerEvent): void => {
    e.stopPropagation();
  };
  private readonly sendImage = (expirationType: SnapExpireType) => {
    this.props.source.sendImage(mapSnapExpireTypeToValue[expirationType]);
  };
}
function Button({
  text,
  icon,
  onClick
}: {
  text: string;
  icon?: boolean;
  onClick(): void;
}): JSX.Element {
  const [hovered, setHovered] = React.useState(false);
  const contentState = hovered ? 'primary' : 'secondary';
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onClick
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c3}>
			<div style={{
      background: resolveThemingValue(hovered ? 'accent' : rgb(0, 0, 0, 0.0), "colors", useTheme())
    }} className={_c4 + ((hovered ? 'accent' : rgb(0, 0, 0, 0.0)) ? resolveIsDarkColor(hovered ? 'accent' : rgb(0, 0, 0, 0.0), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (hovered ? _c5 : _c6)}>
				<Spacer size={'auto'} />
				{icon && <>
						<IconTimer />
						<Spacer size={'tiny'} />
					</>}

				<Text type={'body1'} state={contentState} bold={true} className={_c7}>
					{text}
				</Text>
				<Spacer size={'auto'} />
			</div>
		</div>;
}
type SnapExpireType = '3s' | '10s' | '60s' | 'normal';
const mapSnapExpireTypeToValue: { [key in SnapExpireType]: number | undefined } = {
  '3s': 3,
  '10s': 10,
  '60s': 60,
  normal: undefined
};
const _c0 = " Knu-FlexCol position-absolute left-base top-minor ";
const _c1 = "  ";
const _c2 = " Knu-Flex position-absolute insetX-none bottom-none py-minor justifyContent-center ";
const _c3 = " Knu-FlexCol cursor-pointer m-tiny ";
const _c4 = " Knu-Flex borderRadius-5px borderWidth-large borderStyle-solid py-6px px-base alignItems-center ";
const _c5 = " borderColor-accent ";
const _c6 = " borderColor-white-transparent-440 ";
const _c7 = "  ";