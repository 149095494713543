import { WillChangeMotionValue as e } from "./WillChangeMotionValue.js";
import { isWillChangeMotionValue as n } from "./is.js";
function t(a, o) {
  var i;
  if (!a.applyWillChange)
    return;
  let l = a.getValue("willChange");
  if (!l && !(!((i = a.props.style) === null || i === void 0) && i.willChange) && (l = new e("auto"), a.addValue("willChange", l)), n(l))
    return l.add(o);
}
export {
  t as addValueToWillChange
};
