import { MotionValue as i } from "../index.js";
import { getWillChangeName as n } from "./get-will-change-name.js";
import { removeItem as r } from "../../utils/array.js";
class c extends i {
  constructor() {
    super(...arguments), this.output = [], this.counts = /* @__PURE__ */ new Map();
  }
  add(u) {
    const t = n(u);
    if (!t)
      return;
    const e = this.counts.get(t) || 0;
    this.counts.set(t, e + 1), e === 0 && (this.output.push(t), this.update());
    let s = !1;
    return () => {
      if (s)
        return;
      s = !0;
      const o = this.counts.get(t) - 1;
      this.counts.set(t, o), o === 0 && (r(this.output, t), this.update());
    };
  }
  update() {
    this.set(this.output.length ? this.output.join(", ") : "auto");
  }
}
export {
  c as WillChangeMotionValue
};
