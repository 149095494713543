import { declareFormat } from '@knuddels-app/i18n';

export const DELETE_CHAT = declareFormat({
	id: 'DELETE_CHAT',
	defaultFormat: 'Delete conversation',
});
export const ARCHIVE_CHAT = declareFormat({
	id: 'ARCHIVE_CHAT',
	defaultFormat: 'Archive conversation',
});
export const RESTORE_CHAT = declareFormat({
	id: 'RESTORE_CHAT',
	defaultFormat: 'Restore conversation',
});
export const MARK_CONVERSATION_READ = declareFormat({
	id: 'MARK_CONVERSATION_READ',
	defaultFormat: 'Mark as read',
});
export const MARK_CONVERSATION_UNREAD = declareFormat({
	id: 'MARK_CONVERSATION_UNREAD',
	defaultFormat: 'Mark as unread',
});

export const STARRED_MESSAGES = declareFormat({
	id: 'STARRED_MESSAGES',
	defaultFormat: 'Starred messages',
});

export const UNSTAR_MESSAGE = declareFormat({
	id: 'CONTEXT_MENU_UNSTAR',
	defaultFormat: 'Remove star',
});

export const BIRTHDAY_1 = declareFormat({
	id: 'conversation.message.birthday1',
	defaultFormat: "It's the birthday of {nick} today!",
});

export const BIRTHDAY_2 = declareFormat({
	id: 'conversation.message.birthday2',
	defaultFormat: 'Please send some congratulations.',
});

export const IMAGE_ERROR_BLOCKED_NOT_YET_TITLE = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_BLOCKED_TITLE_NOT_YET',
	defaultFormat: 'Sending pictures not yet possible.',
});

export const IMAGE_ERROR_BLOCKED_NOT_TITLE = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_BLOCKED_TITLE_NOT',
	defaultFormat: 'Sending pictures not possible.',
});

export const IMAGE_ERROR_BLOCKED_SUBTEXT = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_BLOCKED',
	defaultFormat:
		'Other members have been disturbed by your pictures. You cannot send pictures.',
});

export const IMAGE_ERROR_BLOCKED_HAGGED_SUBTEXT = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_BLOCKED_HAGGED',
	defaultFormat:
		'You should talk some more with {nickname} to be able to send them snaps.',
});

export const IMAGE_ERROR_BLOCKED_PING_PONG_SUBTEXT = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_BLOCKED_PING_PONG_DEPTH',
	defaultFormat:
		'You should talk some more with {nickname} to be able to send them snaps.',
});

export const IMAGE_ERROR_UNAVAILABLE_SUBTEXT = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_NOT_SERVER_UNAVAILABLE',
	defaultFormat:
		'We have problems sending the picture. Please try again later.',
});

export const IMAGE_ERROR_NOT_ALLOWED_SUBTEXT = declareFormat({
	id: 'SNACKBAR_SNAP_ERROR_NOT_ALLOWED',
	defaultFormat:
		'{nickname} has to give their consent to receiving pictures from you.',
});

export const SENSITIVE_CONTENT_TITLE = declareFormat({
	id: 'SENSITIVE_CONTENT_TITLE',
	defaultFormat: 'Sensitive content',
});

export const SENSITIVE_CONTENT_IMAGE_BUTTON_TEXT = declareFormat({
	id: 'SENSITIVE_CONTENT_IMAGE_BUTTON_TEXT',
	defaultFormat: 'Show image',
});

export const SENSITIVE_CONTENT_SNAP_BUTTON_TEXT = declareFormat({
	id: 'SENSITIVE_CONTENT_SNAP_BUTTON_TEXT',
	defaultFormat: 'Show snap',
});

export const SENSITIVE_CONTENT_EROTIC_IMAGE_TEXT = declareFormat({
	id: 'SENSITIVE_CONTENT_EROTIC_IMAGE_TEXT',
	defaultFormat: 'Danger! This image could contain erotic.',
});

export const SENSITIVE_CONTENT_EROTIC_SNAP_TEXT = declareFormat({
	id: 'SENSITIVE_CONTENT_EROTIC_SNAP_TEXT',
	defaultFormat: 'Danger! This snap could contain erotic.',
});

export const SENSITIVE_CONTENT_VIOLENCE_IMAGE_TEXT = declareFormat({
	id: 'SENSITIVE_CONTENT_VIOLENCE_IMAGE_TEXT',
	defaultFormat: 'Danger! This image could contain violence.',
});

export const SENSITIVE_CONTENT_VIOLENCE_SNAP_TEXT = declareFormat({
	id: 'SENSITIVE_CONTENT_VIOLENCE_SNAP_TEXT',
	defaultFormat: 'Danger! This snap could contain violence.',
});
