import { useSubscription } from '@knuddels-app/Connection';
import { useUpdateProfileUser } from '../profileQueryHelper';
import { UserEmailChanged } from '@generated/graphql';
import { mergeDeepPartial } from '@knuddels-app/tools/mergeDeepPartial';

// use a component instead of a hook so we can use the context via `useUpdateProfile`
// while also keeping it in the root component `ProfileRouting`
export const SubscribeAndUpdateEmail: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const updateProfileUser = useUpdateProfileUser();
  useSubscription(UserEmailChanged, {
    userId
  }, data => {
    updateProfileUser(oldData => mergeDeepPartial(oldData, {
      user: {
        user: data
      }
    }));
  });
  return null;
};