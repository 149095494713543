import { Color } from '@generated/graphql';

export type ChannelColorMode = 'dark' | 'light';

const threshold = 255 + 255 + 50;
const greenMultiplier = 2.5;
const redMultiplier = 1;
const blueMultiplier = 2;

export function calculateChannelColorMode({
	red,
	green,
	blue,
}: Color): ChannelColorMode {
	const additiveValue =
		red * redMultiplier + green * greenMultiplier + blue * blueMultiplier;

	return additiveValue < threshold ? 'dark' : 'light';
}
