import * as React from 'react';
import { LayoutInfo } from './LayoutInfo';
import { Deferred } from '@knuddels/std';
import * as ReactDOM from 'react-dom';

export function measureLayoutRelativeToAncestor(
	component: React.Component<any, any>,
	ancestor: React.Component<any, any>
): Promise<LayoutInfo> {
	const deferred = new Deferred<LayoutInfo>();
	let componentDomNode: HTMLElement | null = null;
	let ancestorDomNode: HTMLElement | null = null;

	try {
		// eslint-disable-next-line react/no-find-dom-node
		componentDomNode = ReactDOM.findDOMNode(
			component
		) as HTMLElement | null;
		// eslint-disable-next-line react/no-find-dom-node
		ancestorDomNode = ReactDOM.findDOMNode(ancestor) as HTMLElement | null;
	} catch {
		// Components are no longer mounted.
	}

	if (!componentDomNode || !ancestorDomNode) {
		deferred.reject('measureLayoutRelativeToAncestor failed');
	} else {
		const componentBoundingRect = componentDomNode.getBoundingClientRect();
		const ancestorBoundingRect = ancestorDomNode.getBoundingClientRect();

		deferred.resolve({
			x: componentBoundingRect.left - ancestorBoundingRect.left,
			y: componentBoundingRect.top - ancestorBoundingRect.top,
			width: componentBoundingRect.width,
			height: componentBoundingRect.height,
		});
	}

	return deferred.promise;
}
