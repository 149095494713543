import * as React from 'react';
import { OldTouchable } from '../Touchables';
import { Row } from '../../Layouts';
import { Spacer } from '../../Spacers';
import { ButtonProps } from './ButtonProps';
import { containerStyles } from '../../../sharedComponentStyles';
import { useTheme, Text, resolveIsDarkColor } from '@knuddels/component-library';
import styles from './Button_styles.module.scss';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
const InnerButton: React.FC<ButtonProps & {
  touchableRef: React.RefObject<OldTouchable>;
}> = ({
  kind = 'primary',
  size = 'normal',
  text,
  circular = false,
  disableTouchableHeight = false,
  textStyle,
  touchableRef,
  ...restProps
}) => {
  const theme = useTheme();
  let classes = [circular ? styles.circular : styles.general, styles[kind], styles[size]].join(' ');
  if (kind === 'primary') {
    classes += ' ' + (resolveIsDarkColor(theme.buttons.primary.colors.background, theme) ? 'content-is-dark' : 'content-is-light');
  }
  const textStyleClasses = [styles.text, circular ? styles.extraPadding : ''].join(' ');
  return <OldTouchable ref={touchableRef} type={restProps.type} addTouchheight={!disableTouchableHeight ? <Spacer size="tiny" /> : undefined} className={classes} disabled={restProps.disabled} fluid={restProps.fluid} label={restProps.label} colorStyles={{
    '--primaryBg': theme.buttons.primary.colors.background,
    '--primaryBgHover': theme.buttons.primary.colors.hover,
    '--primaryBgDisabled': theme.buttons.primary.colors.disabled,
    '--ghostBg': theme.buttons.secondary.colors.background,
    '--ghostHoverBg': theme.buttons.secondary.colors.hover,
    '--ghostDisabledBg': theme.buttons.secondary.colors.disabled,
    '--ghostBorderColor': theme.buttons.secondary.border.colors.normal,
    '--ghostBorderColorHover': theme.buttons.secondary.border.colors.hover,
    '--ghostBorderColorDisabled': theme.buttons.secondary.border.colors.disabled
  }} style={{
    ...restProps.style
  }} onMouseLeave={restProps.onMouseLeave} onMouseEnter={restProps.onMouseEnter} {...restProps}>
			<Row {...containerStyles.alignItemsCenter}>
				<Spacer size={'auto'} />
				<div className={textStyleClasses} style={{
        ...textStyle
      }}>
					<Text state={((kind === 'primary' ? 'primary' : 'secondary') as TextStates)} bold={true} className={_c0}>
						{text}
					</Text>
				</div>
				<Spacer size={'auto'} />
			</Row>
		</OldTouchable>;
};
export class Button extends React.PureComponent<ButtonProps, {
  context: string;
}> {
  private touchableRef = React.createRef<OldTouchable>();
  public focus = (): void => {
    if (this.touchableRef.current) {
      this.touchableRef.current.focus();
    }
  };
  render(): JSX.Element {
    return <InnerButton {...this.props} touchableRef={this.touchableRef} />;
  }
}
const _c0 = "  ";