import * as React from 'react';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { getLicenseInfo } from './LicenseInfo';
import { CreditedLibrary } from './CreditLibrary';
import { Card } from '../Card';
import { injectable, injectedComponent } from '@knuddels-app/DependencyInjection';
import { action, observable } from '@knuddels-app/mobx';
import { Flex, FlexCol, ScrollView, useTheme, resolveThemingValue } from '@knuddels/component-library';
import awayCoffee from '../../assets/sm_abo_11-06_away-coffee.gif';
import rainbowUnicorn from '../../assets/sm_abo_11-02_rainbowunicorn_ani2.gif';
@injectable()
class CreditsModel {
  public readonly licenseInfo = getLicenseInfo();
  @observable
  private packageIdOfVisibleLicense: string | undefined;
  constructor() {}
  @action.bound
  public handlePackageNameClicked(packageId: string): void {
    this.packageIdOfVisibleLicense = packageId === this.packageIdOfVisibleLicense ? undefined : packageId;
  }
  public isPackageVisible(packageId: string): boolean {
    return this.packageIdOfVisibleLicense === packageId;
  }
}
export default injectedComponent({
  name: 'Credits',
  model: CreditsModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => {
  const theme = useTheme();
  return <div className={_c0}>
				<Card header={declareFormat({
      id: 'COMMUNITY_CREDITS',
      defaultFormat: 'Community Credits'
    }).format(i18n)} title={declareFormat({
      id: 'COMMUNITY_CREDITS_TITLE',
      defaultFormat: 'A huge thanks to everyone!!'
    }).format(i18n)} text={declareFormat({
      id: 'COMMUNITY_CREDITS_TEXT',
      defaultFormat: "We would like to extend a big thank-you to everyone in the community contributing to the messenger.  Without your constructive feedback and active involvement in the development of the messenger, the results we've achieved would not have been possible.  You're the best!"
    }).format(i18n)} imageSource={rainbowUnicorn} />
				<Card header={declareFormat({
      id: 'DEVELOPER_CREDITS',
      defaultFormat: 'Developer Credits'
    }).format(i18n)} title={declareFormat({
      id: 'DEVELOPER_CREDITS_TITLE',
      defaultFormat: 'Libraries that we use'
    }).format(i18n)} text={declareFormat({
      id: 'DEVELOPER_CREDITS_TEXT',
      defaultFormat: 'Here are attribution notices for third-party software that my be used in this application.  We are grateful for everything the open source community has so graciously made available!'
    }).format(i18n)} imageSource={awayCoffee}>
					<div className={_c1}>
						{model.licenseInfo.map(li => <CreditedLibrary key={li.id} licenseInfo={li} showLicense={model.isPackageVisible(li.id)} onClick={model.handlePackageNameClicked} />)}
					</div>
				</Card>
				<div style={{
      minHeight: resolveThemingValue(theme.spacings.xxlarge, "sizes", useTheme())
    }} className={_c2} />
			</div>;
});
const _c0 = " Knu-ScrollView height-100-percent ";
const _c1 = " Knu-FlexCol ";
const _c2 = " Knu-Flex height-full ";