import * as React from 'react';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { ContextMenuEntry } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { IconTrash } from '@knuddels/component-library';
import { $ProfilePictureUploadService } from '../../../../providedServices';
import { useConfirmDeleteProfilePicture } from '../useConfirmDeleteProfilePicture';
export const DeleteProfilePictureContextMenuEntry: React.FC = () => {
  const profilePictureUploadService = useService($ProfilePictureUploadService);
  const deletePicture = useConfirmDeleteProfilePicture({
    onConfirm: () => {
      profilePictureUploadService.deleteProfilePicture();
    }
  });
  return <ContextMenuEntry icon={IconTrash} text={formatMessage(declareFormat({
    id: 'profile.edit.profile-picture.context-menu.delete',
    defaultFormat: 'Delete picture'
  }))} onClick={deletePicture} />;
};