import { injectable } from '@knuddels-app/DependencyInjection';
import { LocationInfo } from '@knuddels-app/location';
import { ViewConfig, ViewState } from './ViewId';
import { VisibleView } from './ViewService';

@injectable()
export class LocationToViewsMapping {
	public getLocationFromVisibleViews(
		visibleViews: readonly VisibleView[],
		currentLocation: LocationInfo
	): LocationInfo {
		const paths = new Array<string>();
		const state: Record<string, any> = {};
		for (const visibleView of visibleViews) {
			const p = visibleView.state.persist();
			if (visibleView.viewConfig.allowMultipleOverlays) {
				state[visibleView.viewConfig.viewId.id] = [
					...(state[visibleView.viewConfig.viewId.id] || []),
					p.state,
				];
			} else {
				paths.push(visibleView.viewConfig.viewId.id);
				paths.push(...p.pathItems);
				state[visibleView.viewConfig.viewId.id] = p.state;
			}
		}
		return new LocationInfo(paths, currentLocation.search, '', state);
	}

	public getViewsFromLocation(
		location: LocationInfo,
		getViewConfig: (id: string) => ViewConfig | undefined,
		createVisibleView: <TState extends ViewState>(
			config: ViewConfig<TState>,
			state: TState
		) => VisibleView<TState>
	): VisibleView[] {
		const result = new Array<VisibleView>();
		const state = location.state as Record<string, unknown> | undefined;
		const parts = location.path.slice(0);

		while (parts.length > 0) {
			const v = parseView(parts);
			if (v) {
				result.push(v);
			}
		}

		function parseView(remainingParts: string[]): VisibleView | undefined {
			const viewId = remainingParts.shift()!;
			const view = getViewConfig(viewId);
			if (!view) {
				// This can happen if the user enters a malformed url.
				console.warn(
					`Expected location part "${viewId}" to be a registered view id, but it was not! Maybe an invalid url?`
				);
				return;
			}
			const persistedViewState = state ? state[viewId] : undefined;
			const pathItems = new Array<string>();
			while (
				remainingParts.length > 0 &&
				!getViewConfig(remainingParts[0])
			) {
				pathItems.push(remainingParts.shift()!);
			}
			const viewState = view.loadState({
				pathItems,
				state: persistedViewState,
			});

			return createVisibleView(view, viewState);
		}

		if (state) {
			Object.keys(state).forEach(viewIdString => {
				const view = getViewConfig(viewIdString);
				const viewState = state[viewIdString];
				if (
					view &&
					view.allowMultipleOverlays &&
					Array.isArray(viewState) &&
					!result.find(visibleview =>
						visibleview.viewConfig.viewId.equals(view.viewId)
					)
				) {
					viewState.forEach(s => {
						const mappedState = view.loadState({
							pathItems: [],
							state: s,
						});
						result.push(createVisibleView(view, mappedState));
					});
				}
			});
		}

		return result;
	}
}
