import { BrowserInterface, BrowserLocation } from './BrowserInterface';
import { observable, runInAction } from '@knuddels-app/mobx';
import { LocaleId } from '@knuddels/std';
import { createMemoryHistory, createBrowserHistory } from 'history';

interface WindowExtension {
	debugDisableBlur?: true;
}

export class BrowserInterfaceImplementation implements BrowserInterface {
	@observable hasFocus: boolean =
		!!window.document && window.document.hasFocus();

	public readonly history =
		globalEnv.product === 'stapp-messenger' ||
		import.meta.env.MODE === 'test'
			? createMemoryHistory()
			: createBrowserHistory();

	constructor() {
		window.addEventListener('focus', () => {
			runInAction('Window got focus', () => {
				this.hasFocus = true;
			});
		});

		window.addEventListener('blur', () => {
			// use that flag for debugging
			if ((window as WindowExtension).debugDisableBlur) {
				return;
			}
			runInAction('Window lost focus', () => {
				this.hasFocus = false;
			});
		});
	}

	get defaultLocale(): LocaleId {
		return LocaleId.deDE;
	}

	getBrowserLocation(): BrowserLocation {
		return {
			url: window.location.href,
			host: window.location.host,
			origin: window.location.origin,

			getParameterByName(name: string): string {
				return getParameterByName(this.url, name);
			},
		};
	}
}

export function getParameterByName(url: string, name: string): string {
	const escapedName = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + escapedName + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);

	if (!results || !results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
