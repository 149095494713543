import { jsx as r } from "react/jsx-runtime";
import { IconClear as m } from "../Icons/Clear.js";
import { useTheme as s } from "../../themes/useTheme.js";
import { Flex as t } from "../Layout/Flex.js";
const c = (o) => {
  const e = s();
  return /* @__PURE__ */ r(t, { mr: -e.spacings.small + "px", children: /* @__PURE__ */ r(m, { onPress: o.onPress }) });
};
export {
  c as ClearButton
};
