import * as React from 'react';
import { ChildThemeProvider, Flex, FlexCol, FONT_FAMILY, Image, Text, ThemeOverride, useChildTheme, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
const LARGE_SIZE = 76;
const DEFAULT_SIZE = 64;
const SMALL_SIZE = 32;
type Size = 'small' | 'default' | 'large';
type Props = {
  userCount: number;
  backgroundColor: string;
  alt?: string;
  src?: string;
  hideBorder?: boolean;
  type?: Size;
};
const useSize = (type?: Size) => {
  switch (type) {
    case 'small':
      return SMALL_SIZE;
    case 'large':
      return LARGE_SIZE;
    default:
      return DEFAULT_SIZE;
  }
};
export const ChannelThumbnail: React.FC<Props> = ({
  src,
  userCount,
  backgroundColor,
  alt = '',
  type = 'default',
  hideBorder
}) => {
  const i18n = useService($I18n);
  const size = useSize(type);
  const thumbnailTheme = useChildTheme({
    sizes: {
      texts: {
        tiny: {
          fontFamily: FONT_FAMILY.TITLE
        }
      }
    }
  }, []);
  return <ChildThemeProvider theme={thumbnailTheme} styles={{}} overrideVariables={{
    sizes: {
      texts: {
        tiny: {
          fontFamily: FONT_FAMILY.TITLE
        }
      }
    }
  }}>
			<div style={{
      height: resolveThemingValue(size, "sizes", useTheme()),
      width: resolveThemingValue(size, "sizes", useTheme()),
      background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
    }} className={_c0 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (hideBorder ? _c1 : _c2) + (type === 'default' || type === 'large' ? _c3 : _c4)}>
				{src && <Image src={src} alt={alt} resizeMode={'cover'} style={{
        height: resolveThemingValue(size, "sizes", useTheme()),
        width: resolveThemingValue(size, "sizes", useTheme())
      }} className={_c5} />}
				<div style={{
        height: resolveThemingValue(size, "sizes", useTheme()),
        width: resolveThemingValue(size, "sizes", useTheme())
      }} className={_c6}>
					<UserCount count={userCount} type={type} />
					{type !== 'small' && <Text type={'tiny'} bold {...textShadowStyle}>
							{i18n.format(declareFormat({
            id: 'channellist.item.thumbnail.online',
            defaultFormat: 'Online'
          })).toUpperCase()}
						</Text>}
				</div>
			</div>
		</ChildThemeProvider>;
};
export const UserCount: React.FC<{
  count: number;
  type?: Size;
}> = ({
  count,
  type
}) => {
  const textType = type === 'default' ? 'subtitle' : 'title';
  return <div className={_c7}>
			<Text type={textType} bold {...textShadowStyle}>
				{count}
			</Text>
		</div>;
};

// TODO: Remove this once the component library supports text shadows
const textShadowStyle = {
  style: {
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)'
  }
};
const _c0 = " Knu-Flex flexShrink-0 borderStyle-solid borderColor-white-transparent-220 overflow-hidden placeItems-center ";
const _c1 = " borderWidth-none ";
const _c2 = " borderWidth-large ";
const _c3 = " borderRadius-large ";
const _c4 = " borderRadius-minor ";
const _c5 = "  ";
const _c6 = " Knu-FlexCol position-absolute placeItems-center justifyContent-center ";
const _c7 = " Knu-FlexCol alignItems-center ";