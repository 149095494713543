import * as React from 'react';
import { $I18n, declareFormat, formatMessage } from '@knuddels-app/i18n';
import { ContextMenuEntry } from '@shared/components';
import { useMutation } from '@knuddels-app/Connection';
import { DeletePhotoAlbum } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { useConfirmDeleteDialog } from '@knuddels-app/shared-hooks/useConfirmDeleteDialog';
import { useTrackOwnProfileView } from '../../shared/useTrackOwnProfileView';
import { IconTrash } from '@knuddels/component-library';
export const DeleteAlbumContextMenuEntry: React.FC<{
  albumId: string;
  refetchProfile: () => void;
}> = ({
  albumId,
  refetchProfile
}) => {
  const handleDeleteAlbum = useDeleteAlbum(albumId, refetchProfile);
  return <ContextMenuEntry icon={IconTrash} text={formatMessage(declareFormat({
    id: 'profile.edit.delete-album',
    defaultFormat: 'Delete album'
  }))} onClick={handleDeleteAlbum} />;
};
const useDeleteAlbum = (albumId: string, refetchProfile: () => void) => {
  const [deleteAlbum] = useMutation(DeletePhotoAlbum);
  const snackbarService = useService($SnackbarService);
  return useOpenConfirmOverlay({
    onConfirm: async () => {
      const result = await deleteAlbum({
        albumId
      });
      if (result.data?.__typename === 'DeleteAlbumSuccess') {
        refetchProfile();
      } else {
        snackbarService.showGenericError();
      }
    }
  });
};
const useOpenConfirmOverlay = ({
  onConfirm
}: {
  onConfirm(): void;
}) => {
  const track = useTrackOwnProfileView();
  const i18n = useService($I18n);
  const closeMenu = useCloseThisOverlay();
  const showConfirmDialog = useConfirmDeleteDialog();
  return () => {
    track('AlbumEdit_DeleteAlbum');
    showConfirmDialog({
      headline: i18n.format(declareFormat({
        id: 'profile.edit.delete-album.confirm.headline',
        defaultFormat: 'Delete album'
      })),
      text: i18n.format(declareFormat({
        id: 'profile.edit.delete-album.confirm.text',
        defaultFormat: 'Do you really want to delete this album?'
      })),
      onConfirm: onConfirm
    });
    closeMenu();
  };
};