import * as React from 'react';
import { InternalContainerContext } from './ContainerContext';
import { ServiceBinder, ServiceBinderImpl } from './K3Container/ServiceBinder';
import { BugIndicatingError } from '@knuddels/std';
export function ServiceInjector(props: {
  children: React.ReactNode;
  registerServices: (binder: ServiceBinder) => void;
}): React.ReactElement {
  const container = React.useContext(InternalContainerContext);
  if (!container) {
    throw new BugIndicatingError('ServiceInjector only works with existing container context.');
  }
  const subContainer = React.useMemo(() => {
    const newContainer = container.createSubContainer();
    props.registerServices(new ServiceBinderImpl(newContainer));
    return newContainer;
  }, [props.registerServices, container]);
  return <InternalContainerContext.Provider value={subContainer}>
			{props.children}
		</InternalContainerContext.Provider>;
}