import * as React from 'react';
import { Button, Dialog, Flex, PasswordInput } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { CannotJoinChannelReason } from '@generated/graphql';
import { ResultPromise } from '@knuddels/std';
export const JoinProtectedChannelDialog: React.FC<{
  retryWithPassword: (password: string) => ResultPromise<any, any>;
  onCancel: () => void;
  onSuccess: (data: any) => Promise<boolean>;
}> = ({
  retryWithPassword,
  onCancel,
  onSuccess
}) => {
  const i18n = useService($I18n);
  const thisOverlay = useService($ThisVisibleOverlay);
  const [password, setPassword] = React.useState<string>('');
  const {
    joinProtectedChannel,
    error
  } = useJoinProtectedChannel(retryWithPassword);
  const close = () => thisOverlay.dispose();
  const cancel = (): void => {
    close();
    onCancel();
  };
  const accept = async (): Promise<void> => {
    const success = await joinProtectedChannel(password);
    await onSuccess(success);
    close();
  };
  return <Dialog open onClose={close}>
			<Dialog.Content align="center">
				<Dialog.Icon src={require('../assets/sm_wr2020_kritzelei.gif')} alt="" />

				<Dialog.Title>
					{i18n.format(declareFormat({
          id: 'joinProtectedChannelDialog.title',
          defaultFormat: 'This channel requires a password'
        }))}
				</Dialog.Title>

				<Dialog.Body>
					{i18n.format(declareFormat({
          id: 'joinProtectedChannelDialog.text',
          defaultFormat: 'Please enter the password:'
        }))}
				</Dialog.Body>

				<div className={_c0}>
					<PasswordInput labelStyle="always-on-top" label={i18n.format(declareFormat({
          id: 'joinProtectedChannelDialog.label',
          defaultFormat: 'Password'
        }))} placeholder={i18n.format(declareFormat({
          id: 'joinProtectedChannelDialog.placeholder',
          defaultFormat: 'Password'
        }))} value={password} error={error} onChange={event => setPassword(event.value)} autoComplete={'new-password'} />
				</div>
			</Dialog.Content>
			<Dialog.Footer>
				<Button type="secondary" onPress={cancel}>
					{i18n.format(declareFormat({
          id: 'joinProtectedChannelDialog.cancel',
          defaultFormat: 'Cancel'
        }))}
				</Button>
				<Button onPress={accept}>
					{i18n.format(declareFormat({
          id: 'joinProtectedChannelDialog.join',
          defaultFormat: 'Join'
        }))}
				</Button>
			</Dialog.Footer>
		</Dialog>;
};
const useJoinProtectedChannel = (retryJoinWithPassword: (password: string) => ResultPromise<any, any>) => {
  const i18n = useService($I18n);
  const [error, setError] = React.useState<string>('');
  return {
    joinProtectedChannel: async (password: string) => {
      // @ts-expect-error Not exposed
      const {
        state
      } = await retryJoinWithPassword(password);
      if (state.kind === 'ok') {
        setError(undefined);
        return state.value;
      }
      switch (state.value.error.type) {
        case CannotJoinChannelReason.IncorrectPassword:
          {
            return setError(i18n.format(declareFormat({
              id: 'joinProtectedChannelDialog.error.password',
              defaultFormat: 'Unfortunately, the password is not correct'
            })));
          }
        default:
          {
            return setError(i18n.format(declareFormat({
              id: 'joinProtectedChannelDialog.error.unknown',
              defaultFormat: 'Something went wrong, please try later'
            })));
          }
      }
    },
    error
  };
};
const _c0 = " Knu-Flex mt-minor px-base width-100-percent ";