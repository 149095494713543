import { PersistedViewState, Position, ViewId, ViewProvider, ViewRegistry, ViewState } from '@knuddels-app/layout';
import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { ProfileSubViews } from '@knuddelsModules/Profile/ProfileSubViews';
import { User } from '@generated/graphql';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $ProfileService } from '@knuddelsModules/Profile/providedServices';
import { TabType } from './bundle/components/ProfileOverlay/ProfileTabsMapping';
import { ProfileContentRouterConfig } from './bundle/components/ProfileOverlay/ProfileContent/ProfileContentRouter';
@injectable()
export class ProfileViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: profileViewId,
      position: Position.Overlay,
      loadState: ProfileViewState.fromPersistedViewState,
      render: state => ({
        mainView: <LoadService service={$ProfileService}>
						{s => <s.MainView shownSubView={state.shownSubView} userId={state.userId} initialTab={state.initialTab} initialRoute={state.initialRoute} showNickSwitchIcon={state.showNickSwitchIcon} />}
					</LoadService>
      })
    });
    registry.registerView({
      viewId: albumDetailsViewId,
      position: Position.Overlay,
      loadState: AlbumDetailsViewState.fromPersistedViewState,
      render: state => ({
        mainView: <LoadService service={$ProfileService}>
						{s => <s.AlbumDetails userId={state.userId} photoId={state.photoId} />}
					</LoadService>
      })
    });
  }
}
export class ProfileViewState implements ViewState {
  public static fromPersistedViewState({
    pathItems,
    state
  }: PersistedViewState): ProfileViewState {
    const userId = pathItems[0];
    const isSubview = (stateToCheck: unknown) => typeof stateToCheck === 'string' && stateToCheck in ProfileSubViews;
    const getSubview = () => {
      if (isSubview(state)) {
        return state;
      } else if (typeof state === 'object' && isSubview((state as any).subview)) {
        return (state as any).subview;
      } else if (userId) {
        return undefined;
      } else {
        // profile edit does not have a userId in route path
        return ProfileSubViews.edit;
      }
    };
    return new ProfileViewState(userId, getSubview(), typeof state === 'object' ? (state as any).initialTab : undefined, typeof state === 'object' ? (state as any).initialRoute : undefined, typeof state === 'object' ? (state as any).showNickSwitchIcon : undefined);
  }
  constructor(public readonly userId?: User['id'], public readonly shownSubView?: ProfileSubViews, public readonly initialTab?: TabType, public readonly initialRoute?: keyof ProfileContentRouterConfig, public readonly showNickSwitchIcon?: boolean) {}
  persist(): PersistedViewState {
    return {
      pathItems: this.userId ? [this.userId] : [],
      state: {
        subview: this.shownSubView,
        initialTab: this.initialTab,
        initialRoute: this.initialRoute,
        showNickSwitchIcon: this.showNickSwitchIcon
      }
    };
  }
  public showProfile(userId: User['id'], initialTab?: TabType, initialRoute?: keyof ProfileContentRouterConfig, showNickSwitchIcon?: boolean): ProfileViewState {
    return new ProfileViewState(userId, undefined, initialTab, initialRoute, showNickSwitchIcon);
  }
  public editProfile(): ProfileViewState {
    return new ProfileViewState(undefined, ProfileSubViews.edit);
  }
}
export class AlbumDetailsViewState implements ViewState {
  public static fromPersistedViewState({
    state
  }: PersistedViewState): AlbumDetailsViewState {
    const normalizedState = typeof state === 'object' && !!state ? (state as any) : {
      userId: undefined,
      photoId: undefined
    };
    return new AlbumDetailsViewState(normalizedState.userId, normalizedState.photoId);
  }
  constructor(public readonly userId?: User['id'], public readonly photoId?: any) {}
  persist(): PersistedViewState {
    return {
      pathItems: [],
      state: {
        userId: this.userId,
        photoId: this.photoId
      }
    };
  }
  public showAlbumDetails(userId: User['id'], photoId?: any): AlbumDetailsViewState {
    return new AlbumDetailsViewState(userId, photoId);
  }
}
export const profileViewId = new ViewId<ProfileViewState>('profile', ['profileViewId']);
export const albumDetailsViewId = new ViewId<AlbumDetailsViewState>('albumDetails', ['albumDetailsViewId']);