function r({ top: t, left: o, right: n, bottom: x }) {
  return {
    x: { min: o, max: n },
    y: { min: t, max: x }
  };
}
function e({ x: t, y: o }) {
  return { top: o.min, right: t.max, bottom: o.max, left: t.min };
}
function m(t, o) {
  if (!o)
    return t;
  const n = o({ x: t.left, y: t.top }), x = o({ x: t.right, y: t.bottom });
  return {
    top: n.y,
    left: n.x,
    bottom: x.y,
    right: x.x
  };
}
export {
  r as convertBoundingBoxToBox,
  e as convertBoxToBoundingBox,
  m as transformBoxPoints
};
