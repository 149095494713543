import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $K3Firebase } from '@knuddels-app/analytics/firebase/serviceId';
import { TraceId } from './TraceId';

@injectable()
export class FirebaseAnalyticsService {
	private readonly activeTraces: {
		[key in string]: ReturnType<
			(typeof $K3Firebase.T)['perf']['startTrace']
		>;
	} = {};

	constructor(
		@inject($K3Firebase)
		private readonly k3Firebase: typeof $K3Firebase.T
	) {}

	public logEvent(contentType: string, itemId: string): void {
		this.k3Firebase.analytics.logSelectContent({
			content_type: contentType,
			item_id: itemId,
		});
	}

	public logHappyMoment(itemId: string): void {
		this.k3Firebase.analytics.logEvent('happy_moment', {
			item_id: itemId,
		});
	}

	public logLogin(): void {
		this.k3Firebase.analytics.logLogin({});
	}

	public logSignUp(): void {
		this.k3Firebase.analytics.logSignUp({});
	}

	public startTrace = async (traceId: TraceId): Promise<void> => {
		const perf = this.k3Firebase.perf;
		const promise = perf.startTrace(traceId.id);
		this.activeTraces[traceId.id] = promise;
		await promise;
	};

	public stopTrace = async (traceId: TraceId): Promise<void> => {
		const startPromise = this.activeTraces[traceId.id];
		if (startPromise) {
			// first clear promise to prevent multiple stop calls.
			delete this.activeTraces[traceId.id];

			const trace = await startPromise;
			await trace.stop();
		}
	};
}
