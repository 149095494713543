import * as React from 'react';
import { AlbumDetailPhotoFragment } from '@generated/graphql';
import { PhotoRow, PhotoRowElement } from '../PhotoRow';
import { Flex, FlexCol, Text } from '@knuddels/component-library';
import { FormattedMessage } from '@knuddels-app/i18n';
import { PROFILE_PICTURE } from '../../../i18n/shared-formats';
import { useTrackProfileView } from '../../shared/useTrackProfileView';
import { OpenEditProfilePictureContextMenu } from '../EditProfilePictureContextMenu/OpenEditProfilePictureContextMenu';
export const ProfilePhotoSection: React.FC<{
  userId: string;
  photo: AlbumDetailPhotoFragment | undefined;
  photoSize: number;
  photoSpacing: number;
  editable?: boolean;
}> = ({
  userId,
  photo,
  photoSize,
  photoSpacing,
  editable
}) => {
  const track = useTrackProfileView();
  const trackPhotoClicked = React.useCallback(() => {
    track('Albumtab_ImageClicked');
  }, [track]);
  const elements = React.useMemo<PhotoRowElement[]>(() => {
    const result: PhotoRowElement[] = [];
    if (photo) {
      result.push({
        type: 'photo',
        photoId: photo.id,
        url: photo.thumbnailUrl
      });
    }
    if (editable) {
      result.push({
        type: 'changeProfilePhoto'
      });
    }
    return result;
  }, [photo]);
  return <div onPointerDown={() => {
    /* this is required for scrolling on ipads. otherwise no touch events are processed */
  }} className={_c0}>
			<div className={_c1 + (editable ? _c2 : _c3) + (editable ? _c4 : _c5)}>
				<Text type={'body1'} state={'tertiary'} bold={true} className={_c6}>
					<FormattedMessage id={PROFILE_PICTURE} />
				</Text>
				{editable && photo && <OpenEditProfilePictureContextMenu />}
			</div>
			<PhotoRow userId={userId} elements={elements} size={photoSize} spacing={photoSpacing} onPhotoClicked={trackPhotoClicked} />
		</div>;
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex pl-base justifyContent-space-between alignItems-center ";
const _c2 = " pt-none ";
const _c3 = " pt-large ";
const _c4 = " pb-none ";
const _c5 = " pb-base ";
const _c6 = "  ";