import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { OverflowMenuTrigger } from '@shared/components';
import { EditProfilePictureOverflowMenu } from './EditProfilePictureOverflowMenu';
import { $OverlayService } from '@knuddels-app/overlays';
export const EditProfilePictureOverflowMenuTrigger: React.FC = () => {
  const showOverflowMenu = useShowOverflowMenu();
  return <OverflowMenuTrigger bg={'transparent'} showOverflowMenu={showOverflowMenu} />;
};
const useShowOverflowMenu = () => {
  const overlayService = useService($OverlayService);
  return React.useCallback((x: number, y: number, width: number, height: number) => {
    overlayService.showOverlay({
      view: <EditProfilePictureOverflowMenu chevronX={x} chevronY={y} chevronWidth={width} chevronHeight={height} />
    });
  }, []);
};