import * as React from 'react';
import { Box, Flex, FlexCol, Text } from '@knuddels/component-library';
import { TextWithIconsProps } from './TextWithIconsProps';
export const TextWithIcons: React.FC<TextWithIconsProps> = ({
  state,
  type,
  bold,
  text,
  children,
  onTextPress
}) => {
  return <div className={_c0}>
			<div className={_c1}>
				<Text state={state} type={type} className={_c2}>
					{' '}
				</Text>
			</div>
			<div className={_c3}>
				<div className={_c4}>
					<div style={{
          whiteSpace: 'nowrap'
        }} className={_c5}>
						<Text state={state} type={type} bold={bold}
          // @ts-expect-error Not exposed
          onPress={onTextPress} style={{
            ...textStyle
          }} className={_c6}>
							{text}
						</Text>

						<div className={_c7 + (React.Children.count(children) > 0 ? _c8 : _c9)}>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>;
};
const textStyle = {
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
TextWithIcons.displayName = 'TextWithIcons';
const _c0 = " Knu-Flex position-relative flex-1 ";
const _c1 = " Knu-Box position-relative left-none top-none ";
const _c2 = "  ";
const _c3 = " Knu-Flex position-absolute inset-none ";
const _c4 = " Knu-FlexCol maxWidth-full alignItems-stretch mr-auto ";
const _c5 = " Knu-Flex alignItems-center overflow-hidden ";
const _c6 = "  ";
const _c7 = " Knu-Flex flexShrink-0 flexGrow-0 flexBasis-auto alignItems-center ";
const _c8 = " pl-tiny ";
const _c9 = " pl-none ";