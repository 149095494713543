import { Flex, Z_INDEX, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import * as React from 'react';

/**
 * A responsive bar component that centers the content but also allows
 * it to grow for bigger screen sizes to a specified maxWidth.
 */
export const CenterContentBar: React.FC<{
  centeredElementMaxWidth?: number;
  rightAlignedElement?: React.ReactElement;
} & React.ComponentProps<typeof Flex>> = ({
  centeredElementMaxWidth,
  children,
  rightAlignedElement,
  ...flexProps
}) => {
  // The touchableStyle from the onPress handler messes up the style on native,
  // so we removed it here
  const passedFlexProps = {
    ...flexProps,
    touchableStyle: (undefined as any)
  };
  return <Flex shadow={'Shadow2'} bg={'contentLightBg'} zIndex={Z_INDEX.BELOW_TITLE_BAR} {...passedFlexProps}>
			<div className={_c0}>
				<Spacer size={'auto'} />
				<div style={{
        maxWidth: resolveThemingValue(centeredElementMaxWidth ?? 400, "sizes", useTheme())
      }} className={_c1}>
					{children}
				</div>
				<Spacer size={'auto'} />
			</div>
			{rightAlignedElement}
		</Flex>;
};
const _c0 = " Knu-Flex py-minor px-small alignItems-center flex-10 minWidth-200px ";
const _c1 = " Knu-Flex flex-20 alignItems-center ";