import * as s from "react";
const i = (e, t) => {
  let n;
  return (...o) => {
    clearTimeout(n), n = setTimeout(() => e(...o), t);
  };
}, c = ({
  debounce: e = 50
} = {}) => {
  const t = s.useRef(), [n, o] = s.useState({
    pageX: 0,
    pageY: 0,
    width: 0,
    height: 0
  }), u = e === 0 ? o : s.useMemo(() => i(o, e), [e]);
  return {
    bind: { onLayout: (r) => {
      u(r);
    }, innerRef: t },
    getPosition: () => {
      const { top: r, left: a } = t.current.getBoundingClientRect();
      return Promise.resolve({ x: a, y: r });
    },
    ...n
  };
};
export {
  c as useMeasureElement
};
