import * as React from 'react';
import { Text, ThemeOverride } from '@knuddels/component-library';
interface Props {
  text: string;
}
export class ContextMenuTitle extends React.PureComponent<Props> {
  render(): React.ReactNode {
    return <Text state={'tertiary'} type={'body2'} bold={true} className={_c0}>
				{this.props.text}
			</Text>;
  }
}
const _c0 = " ml-base my-6px ";