import { useContext as o, useMemo as i } from "react";
import { MotionContext as a } from "./index.js";
import { getCurrentTreeVariants as m } from "./utils.js";
function C(t) {
  const { initial: e, animate: n } = m(t, o(a));
  return i(() => ({ initial: e, animate: n }), [r(e), r(n)]);
}
function r(t) {
  return Array.isArray(t) ? t.join(" ") : t;
}
export {
  C as useCreateMotionContext
};
