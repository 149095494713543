import { injectable } from '@knuddels-app/DependencyInjection';
import { storageProvider } from './storage-provider';
import { LocalStorageKey } from './LocalStorageKey';
import { StorageOptions } from './storage-provider/StorageProvider';
import { KnownEndpoints } from '../Connection/Endpoints/KnownEndpoints';

/**
 * Uses local storage for web (cookies if scope is set) and async storage for native.
 */
@injectable()
export class LocalStorage {
	private readonly entries = new Map<
		LocalStorageKey<any>,
		LocalStorageEntry<any>
	>();

	getEntry<T>(key: LocalStorageKey<T>): LocalStorageEntry<T> {
		let entry = this.entries.get(key);
		if (!entry) {
			entry = new LocalStorageEntry(this, key);
			this.entries.set(key, entry);
			entry.removeOtherNamespaceCookies();
		}
		return entry;
	}
}

export class LocalStorageEntry<T> {
	/**
	 * Indicates whether `tryMigrateFromOld` has been called.
	 */
	private migrated = false;
	private removedOtherNamespaceCookies = false;

	private readonly options: StorageOptions = {
		cookieDomain: this.key.scope ? this.key.scope.cookieDomain : undefined,
		cookieExpires:
			this.key.cookieExpires === 'withSession'
				? undefined
				: this.key.cookieExpires.inDays,
		preferLocalStorage: this.key.scope === undefined,
	};

	constructor(
		private localStorage: LocalStorage,
		public readonly key: LocalStorageKey<T>
	) {}

	isSet(): boolean {
		const stringValue = this.getStringValue();
		return stringValue !== undefined;
	}

	get(): T {
		const stringValue = this.getStringValue();
		return this.key.serializer.deserialize(stringValue);
	}

	set(value: T): void {
		const serializedValue = this.key.serializer.serialize(value);
		this.setStringValue(serializedValue);
	}

	/**
	 * Deletes the value from the store.
	 * If read again, the default value will be returned.
	 */
	reset(): void {
		this.setStringValue(undefined);
	}

	getAndReset(): T {
		const val = this.get();
		this.reset();
		return val;
	}

	private getStringValue(): string | undefined {
		this.migrateIfRequired();
		const stringValue = storageProvider.get(this.key.id, this.options);
		return stringValue;
	}

	private setStringValue(stringValue: string | undefined): void {
		this.migrateIfRequired();
		storageProvider.set(this.key.id, stringValue, this.options);
	}

	removeOtherNamespaceCookies(): void {
		if (this.removedOtherNamespaceCookies) {
			return;
		}

		this.removedOtherNamespaceCookies = true;

		if (this.options.preferLocalStorage) {
			return;
		}

		if (!this.key.namespace) {
			return;
		}

		const allNamespaces = Object.keys(KnownEndpoints).map(it =>
			it.toLowerCase()
		);
		allNamespaces.forEach(namespace => {
			const idForNamespace = this.key.getIdForNamespace(namespace);
			if (idForNamespace !== this.key.id) {
				storageProvider.set(idForNamespace, undefined, this.options);
			}
		});
	}

	private migrateIfRequired(): void {
		if (this.migrated) {
			return;
		}
		this.migrated = true;
		const val = storageProvider.get(this.key.id, this.options);
		if (val === undefined) {
			const result = this.key.tryMigrateFromOld(this.localStorage);
			if (result !== undefined) {
				this.set(result.value);
			}
		}
	}
}
