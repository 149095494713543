import { useService } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '../registerServices';

/*
	Caution: When you use this hooks be sure to wrap your component in an observer
	or use an injectedComponent in order for them to update when the screen size changes
 */

export const useIsStackedLayout = () => {
  const screenService = useService($ScreenService);
  return screenService.isStackedLayout;
};
export const useScreenWidth = () => {
  const screenSize = useScreenSize();
  return screenSize.screenWidth;
};
export const useScreenHeight = () => {
  const screenSize = useScreenSize();
  return screenSize.screenHeight;
};
export const useScreenSize = () => {
  const screenService = useService($ScreenService);
  return {
    screenWidth: screenService.screenWidth,
    screenHeight: screenService.screenHeight
  };
};