import {
	ConversationForwardedMessageContent,
	ConversationMessageContent,
	ConversationMessageFragment,
	ConversationNestedMessage,
	ConversationNestedMessageFragment,
	ConversationQuotedMessageContent,
	ConversationTextMessageContent,
	MessengerConversation,
} from '@generated/graphql';
import { FormattedTextText } from '@shared/components';
import { SendingMessage } from './SendingMessage';
import { v4 as uuidv4 } from 'uuid';
import { expectUnreachable } from '@knuddels/std';

export class SendingMessageFactory {
	constructor(
		private readonly currentUserProvider: () => SendingMessage['sender']
	) {}

	public createMessage(
		conversationId: MessengerConversation['id'],
		text: string,
		retry: (correlationId: string) => void,
		messageType: 'text' | 'forward' | 'quote',
		nestedMessage?: ConversationMessageFragment
	): SendingMessage {
		const correlationId = generateMessageCorrelationId();
		const content = createContent(text, messageType, nestedMessage);

		return {
			messageCorrelationId: correlationId,
			id: 'sending_' + correlationId,
			conversationId,
			timestamp: Date.now().toString() as ConversationNestedMessageFragment['timestamp'],
			sender: this.currentUserProvider(),
			content,
			isUnread: false,
			retry: () => retry(correlationId),
		};
	}
}

function createContent(
	text: string,
	messageType: 'text' | 'forward' | 'quote',
	nestedMessage?: ConversationMessageFragment
): ConversationMessageContent {
	switch (messageType) {
		case 'text':
			return {
				__typename: 'ConversationTextMessageContent',
				formattedText: new FormattedTextText(text, {
					bold: false,
					italic: false,
					state: 'tertiary',
				}) as any,
				starred: false,
			} as ConversationTextMessageContent;
		case 'forward':
			return {
				__typename: 'ConversationForwardedMessageContent',
				nestedMessage: {
					__typename: 'ConversationNestedMessage',
					sender: nestedMessage.sender,
					timestamp: nestedMessage.timestamp,
					content: nestedMessage.content,
				} as ConversationNestedMessage,
				starred: false,
			} as ConversationForwardedMessageContent;
		case 'quote':
			return {
				__typename: 'ConversationQuotedMessageContent',
				formattedText: new FormattedTextText(text, {
					bold: false,
					italic: false,
					state: 'tertiary',
				}) as any,
				nestedMessage: {
					__typename: 'ConversationNestedMessage',
					sender: nestedMessage.sender,
					timestamp: nestedMessage.timestamp,
					content: nestedMessage.content,
				} as ConversationNestedMessage,
				starred: false,
			} as ConversationQuotedMessageContent;
		default:
			expectUnreachable(messageType);
	}
}

function generateMessageCorrelationId(): string {
	return uuidv4();
}
