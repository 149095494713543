import { scale as e, alpha as i } from "../../../value/types/numbers/index.js";
import { px as t, degrees as r, progressPercentage as o } from "../../../value/types/numbers/units.js";
import { int as a } from "./type-int.js";
const g = {
  // Border props
  borderWidth: t,
  borderTopWidth: t,
  borderRightWidth: t,
  borderBottomWidth: t,
  borderLeftWidth: t,
  borderRadius: t,
  radius: t,
  borderTopLeftRadius: t,
  borderTopRightRadius: t,
  borderBottomRightRadius: t,
  borderBottomLeftRadius: t,
  // Positioning props
  width: t,
  maxWidth: t,
  height: t,
  maxHeight: t,
  size: t,
  top: t,
  right: t,
  bottom: t,
  left: t,
  // Spacing props
  padding: t,
  paddingTop: t,
  paddingRight: t,
  paddingBottom: t,
  paddingLeft: t,
  margin: t,
  marginTop: t,
  marginRight: t,
  marginBottom: t,
  marginLeft: t,
  // Transform props
  rotate: r,
  rotateX: r,
  rotateY: r,
  rotateZ: r,
  scale: e,
  scaleX: e,
  scaleY: e,
  scaleZ: e,
  skew: r,
  skewX: r,
  skewY: r,
  distance: t,
  translateX: t,
  translateY: t,
  translateZ: t,
  x: t,
  y: t,
  z: t,
  perspective: t,
  transformPerspective: t,
  opacity: i,
  originX: o,
  originY: o,
  originZ: t,
  // Misc
  zIndex: a,
  backgroundPositionX: t,
  backgroundPositionY: t,
  // SVG
  fillOpacity: i,
  strokeOpacity: i,
  numOctaves: a
};
export {
  g as numberValueTypes
};
