import * as React from 'react';
import { Box, Flex, FlexCol, Image, rgb, resolveThemingValue, useTheme, toPointerHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { MessageLightboxSource } from '../../Lightbox/Lightbox';
import { BasicUser } from '../../../../utils/BasicUser';
import { ImageOrSnapWithSensitiveContent } from './ImageOrSnapWithSensitiveContent';
import { ImagePermission } from './ImagePermission';
import { ConversationImageMessageContentFragment } from '@generated/graphql';
type Props = {
  content: ConversationImageMessageContentFragment;
  snapsAllowed?: boolean;
  sender: BasicUser;
  size: number;
  messageId: string;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
};
export const ImageDisplay: React.FC<Props> = ({
  content,
  snapsAllowed,
  sender,
  size,
  messageId,
  setLightboxSrc
}) => {
  const handleSnapImageViewPress = (): void => setLightboxSrc && setLightboxSrc({
    type: 'image',
    imageUrl: content.image.url
  });
  if (!snapsAllowed && sender) {
    return <ImagePermission user={sender} size={size} url={content.image.url} />;
  }
  if (!content.imageAccepted && sender) {
    return <ImageOrSnapWithSensitiveContent size={size} image={content.image} messageId={messageId} sensitiveContentClassification={content.sensitiveContentClassification} />;
  }
  if (!content.image.url || !sender) {
    return <div style={{
      height: resolveThemingValue(size, "sizes", useTheme()),
      maxWidth: resolveThemingValue(size, "sizes", useTheme()),
      background: resolveThemingValue(rgb(75, 75, 75), "colors", useTheme())
    }} className={_c0 + (rgb(75, 75, 75) ? resolveIsDarkColor(rgb(75, 75, 75), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div style={{
        width: resolveThemingValue(size, "sizes", useTheme())
      }} className={_c1} />
			</div>;
  } else {
    return <div onClick={toPointerHandler(handleSnapImageViewPress)} style={{
      height: resolveThemingValue(size, "sizes", useTheme()),
      maxWidth: resolveThemingValue(size, "sizes", useTheme())
    }} className={_c2 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Image src={content.image.url} resizeMode={'cover'} alt={''} className={_c3} />
			</div>;
  }
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Box ";
const _c2 = " Knu-Flex bg-contentLightBg cursor-pointer ";
const _c3 = " height-full width-full ";