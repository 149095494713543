import { useHovered } from '@knuddels-app/tools/useHovered';
import { Flex, IconProps, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import * as React from 'react';
export const IconWithShadow: React.FC<{
  innerRef?: React.MutableRefObject<any>;
  InnerIcon: React.ComponentType<{
    active: boolean;
    size: IconProps['size'];
  }>;
  active?: boolean;
  onPress: () => void;
}> = ({
  active,
  innerRef,
  onPress,
  InnerIcon
}) => {
  const {
    hovered,
    bind
  } = useHovered();
  return <Flex innerRef={innerRef} {...bind} p={'minor'} onPress={onPress} placeItems={'center'} cursor={'pointer'} opacity={active ? 1 : hovered ? 0.66 : 0.33}>
			<div className={_c0 + ("black-solid-black" ? resolveIsDarkColor("black-solid-black", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<InnerIcon size={'large'} active />
			</div>
		</Flex>;
};
const _c0 = " Knu-Flex borderRadius-circle bg-black-solid-black p-tiny ";