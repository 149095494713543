import { injectable, inject } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { Disposable, shouldBeUnreachable } from '@knuddels/std';
import { happyMomentEvents, MessengerConversation } from '@generated/graphql';
import { $ViewService } from '@knuddels-app/layout';
import { $MessengerService } from '@knuddelsModules/Messenger';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { autorun } from '@knuddels-app/mobx';

@injectable()
export class HappyMomentService {
	public readonly dispose = Disposable.fn();

	private pendingLongConversation:
		| MessengerConversation['id']
		| undefined = undefined;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($MessengerService)
		private readonly messengerService: typeof $MessengerService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($FirebaseAnalyticsService)
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {
		this.dispose.track(
			authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				happyMomentEvents,
				{},
				{
					next: async (data): Promise<void> => {
						switch (data.__typename) {
							case 'LongConversationOccurred':
								this.onLongConversationOccurred(
									data.conversation.id
								);
								break;
							case 'DailyLoginUsed':
								this.firebaseAnalyticsService.logHappyMoment(
									'DailyLoginUsed'
								);
								break;
							case 'FriendRequestAccepted':
								this.firebaseAnalyticsService.logHappyMoment(
									'FriendRequestAccepted'
								);
								break;
							default:
								shouldBeUnreachable(data);
						}
					},
				}
			)
		);

		this.dispose.track(
			autorun(
				{
					name:
						'Trigger happy moment event if conversation is closed',
				},
				async () => {
					const openConversationId = messengerService.getOpenConversationId();
					if (
						this.pendingLongConversation &&
						this.pendingLongConversation !== openConversationId
					) {
						this.trackLongConversationHappyMoment();
						this.pendingLongConversation = undefined;
					}
				}
			)
		);
	}

	private async onLongConversationOccurred(
		conversationId: MessengerConversation['id']
	): Promise<void> {
		if (conversationId === this.messengerService.getOpenConversationId()) {
			this.pendingLongConversation = conversationId;
		} else {
			this.trackLongConversationHappyMoment();
		}
	}

	private trackLongConversationHappyMoment(): void {
		this.firebaseAnalyticsService.logHappyMoment('LongConversation');
	}
}
