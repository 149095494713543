import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';
import sorry from '@shared/images/sorry.gif';

export const SnackbarDefinitionSendMessageIsModerator: SnackbarData = {
	type: 'sendMessageIsModerator',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_IS_MODERATOR_TEXT',
				defaultFormat: 'This user is currently moderator in a channel.',
			})
		),
	subtext: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_SEND_MESSAGE_IS_MODERATOR_SUBTEXT',
				defaultFormat:
					"Therefore you can't send messages to him at the moment.",
			})
		),
	adornment: sorry,
};
