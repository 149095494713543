import { action, observable } from '@knuddels-app/mobx';

export type FriendRecommendationData = {
	timestamp: number;
	wasSent: boolean;
	markSent(): void;
	removeRecommendation(): void;
};

export class FriendRecommendationsState {
	@observable
	private _recommendations: FriendRecommendationData[] = [];

	public get recommendations(): readonly FriendRecommendationData[] {
		return this._recommendations;
	}

	@action.bound
	public add(timestamp: number): void {
		this._recommendations.push({
			timestamp,
			wasSent: false,
			markSent: () => this.markSent(timestamp),
			removeRecommendation: () => this.removeRecommendation(timestamp),
		});
	}

	@action.bound
	public removeAll(): void {
		this._recommendations = [];
	}

	@action.bound
	private markSent(timestamp: number): void {
		const index = this._recommendations.findIndex(
			it => it.timestamp === timestamp
		);
		if (index > -1) {
			this._recommendations[index] = {
				...this._recommendations[index],
				wasSent: true,
			};
		}
	}

	@action.bound
	private removeRecommendation(timestamp: number): void {
		const index = this._recommendations.findIndex(
			it => it.timestamp === timestamp
		);
		if (index > -1) {
			this._recommendations.splice(index, 1);
		}
	}
}
