import * as React from 'react';
import { calculateHoverColor, hexToRgba } from '@knuddels-app/StAppMessenger/calculateHoverColor';
import { useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { ChildThemeProvider, DarkTheme, LightTheme, useChildTheme } from '@knuddels/component-library';
import { StAppMessengerThemeReadyContext } from '@knuddels-app/StAppMessenger/StAppMessengerThemeReadyContext';
interface State {
  backgroundColor?: string;
  borderColor?: string;
  hoverBackgroundColor?: string;
  onDark?: boolean;
}
const defaultColorState: State = import.meta.env.DEV ? {
  backgroundColor: 'rgba(50,50,50, 1.0)',
  borderColor: 'rgba(0,0,0, 0.5)',
  hoverBackgroundColor: 'rgba(200,200,200, 1.0)',
  onDark: false
} : {};
export const StAppMessengerThemeProvider: React.FC<{}> = globalEnv.product === 'stapp-messenger' ? ({
  children
}) => {
  const environment = useService($Environment);
  const systemAppClient = environment.messengerSystemAppInterface!.client;
  const [colorState, setColorState] = React.useState<State>(defaultColorState);
  const onChannelDesignChange = (event: {
    data: any;
  }): void => {
    const {
      backgroundColor,
      borderColor,
      theme
    } = event.data;
    setColorState({
      backgroundColor: hexToRgba(backgroundColor),
      borderColor: hexToRgba(borderColor),
      hoverBackgroundColor: calculateHoverColor(backgroundColor, borderColor, 0.1),
      onDark: theme === 'dark'
    });
  };
  React.useEffect(() => {
    if (environment.isStappSidebarOverview) {
      systemAppClient.addEventListener('channelDesign', onChannelDesignChange);
      systemAppClient.sendClientEvent('requestChannelDesign', '');
      return () => {
        systemAppClient.removeEventListener('channelDesign');
      };
    }
  }, []);
  const theme = useChildTheme(environment.isStappSidebarOverview ? {
    ...(colorState.onDark ? DarkTheme : LightTheme),
    colors: {
      basic: {
        contentLightBg: colorState.backgroundColor,
        contentBg: colorState.backgroundColor,
        listHoverAtom: colorState.hoverBackgroundColor,
        hairlineAtom: colorState.borderColor
      }
    }
  } : {}, [colorState.backgroundColor, colorState.borderColor, environment.isStappSidebarOverview]);
  return <ChildThemeProvider theme={theme}>
						<StAppMessengerThemeReadyContext.Provider value={!environment.isStappSidebarOverview || colorState.onDark !== undefined}>
							{children}
						</StAppMessengerThemeReadyContext.Provider>
					</ChildThemeProvider>;
} : ({
  children
}) => {
  return <> {children}</>;
};