import { useNativeBackground } from './useNativeBackground';
import { useTheme } from '@knuddels/component-library';

export function useThemedNativeBackground(): void {
	const theme = useTheme();
	useNativeBackground(
		theme.colors.basic.contentBg,
		theme.id === 'dark' ? 'dark' : 'light'
	);
}
