import * as React from 'react';
import { $K3Container, ServiceId, useService } from '../DependencyInjection';
import { Module } from './Module';
import { $ModuleService } from './providedServices';
type State<T> = null | {
  service: T;
  serviceId: ServiceId<T>;
};
export function LoadService<T>(props: {
  service: ServiceId<T>;
  module?: Module;
  children: (service: T) => JSX.Element;
  loading?: React.ComponentType;
}): JSX.Element | null {
  const k3Container = useService($K3Container);
  const moduleService = useService($ModuleService);
  const [state, setState] = React.useState<State<T>>(() => {
    const service = moduleService.getService(props.service);
    if (service) {
      return {
        service,
        serviceId: props.service
      };
    }
    return null;
  });
  React.useLayoutEffect(() => {
    if (state?.service) {
      return;
    }
    const p = props.module ? moduleService.loadModule(props.module) : moduleService.loadModuleThatProvidesService(props.service);
    p.then(() => {
      const service = k3Container.getService(props.service);
      setState({
        service,
        serviceId: props.service
      });
    });
  }, [props.service.id, state?.service]);
  if (!state || state.serviceId !== props.service) {
    const Loader = props.loading;
    return Loader ? <Loader /> : null;
  }
  return props.children(state.service);
}