import { useTrackOwnProfileView } from '../shared/useTrackOwnProfileView';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { useConfirmDeleteDialog } from '@knuddels-app/shared-hooks/useConfirmDeleteDialog';

export const useConfirmDeleteProfilePicture = ({
	onConfirm,
}: {
	onConfirm(): void;
}) => {
	const track = useTrackOwnProfileView();
	const i18n = useService($I18n);
	const closeMenu = useCloseThisOverlay();
	const showConfirmDialog = useConfirmDeleteDialog();

	return () => {
		track('AlbumEdit_DeleteProfilePicture');

		showConfirmDialog({
			headline: i18n.format(
				declareFormat({
					id:
						'profile.edit.profile-picture.context-menu.delete.confirm.headline',
					defaultFormat: 'Delete picture',
				})
			),
			text: i18n.format(
				declareFormat({
					id:
						'profile.edit.profile-picture.context-menu.delete.confirm.text',
					defaultFormat:
						'Do you really want to delete the profile picture?',
				})
			),
			onConfirm,
		});
		closeMenu();
	};
};
