import { ValidityState } from '../Entry/EditProfileEntry';
import { EditProfileEntryFragment } from '@generated/graphql';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { parseDate } from './profileEditorData';

export class ProfileEntryValidator {
	public readonly validateMultiChoice = (
		entry: EditProfileEntryFragment,
		value: string
	): ValidityState => {
		return { type: value ? 'valid' : 'empty' };
	};

	public readonly validateDate = (
		entry: EditProfileEntryFragment,
		value: string
	): ValidityState => {
		if (!value || entry.__typename !== 'ProfileEditEntryDate') {
			return { type: 'empty' };
		}

		const date = parseDate(value);

		if (
			!date ||
			isNaN(date.getTime()) ||
			!value.match(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/)
		) {
			return {
				type: 'invalid',
				errorText: formatMessage(
					declareFormat({
						id: 'PROFILE_ENTRY_DATE_INVALID',
						defaultFormat: 'Please enter in the format DD.MM.YYYY',
					})
				),
			};
		}

		const isDateTooOld =
			entry.dateMinValue &&
			new Date(+entry.dateMinValue).getTime() > date.getTime();
		const isDateTooNew =
			entry.dateMaxValue &&
			new Date(+entry.dateMaxValue).getTime() < date.getTime();
		if (isDateTooOld || isDateTooNew) {
			return {
				type: 'invalid',
				errorText: formatMessage(
					declareFormat({
						id: 'PROFILE_ENTRY_DATE_INCORRECT_RANGE',
						defaultFormat: 'This date cannot match your given age',
					})
				),
			};
		}

		return { type: 'valid' };
	};

	public readonly validateFreeText = (
		entry: EditProfileEntryFragment,
		value: string
	): ValidityState => {
		if (!value || entry.__typename !== 'ProfileEditEntryFreeText') {
			return { type: 'empty' };
		}

		const minLength = entry.freeTextMinLength;
		const maxLength = entry.freeTextMaxLength;
		const regex = entry.freeTextPattern
			? new RegExp(`${entry.freeTextPattern}`, 'gi')
			: undefined;

		if (minLength && value.length < minLength) {
			return {
				type: 'invalid',
				errorText: formatMessage(
					declareFormat({
						id: 'FREE_TEXT_ERROR_TOO_SHORT',
						defaultFormat: 'Text is too short',
					})
				),
			};
		}
		if (maxLength && value.length > maxLength) {
			return {
				type: 'invalid',
				errorText: formatMessage(
					declareFormat({
						id: 'FREE_TEXT_ERROR_TOO_LONG',
						defaultFormat: 'Text is too long',
					})
				),
			};
		}

		return !regex || value.match(regex)
			? { type: 'valid' }
			: {
					type: 'invalid',
					errorText: formatMessage(
						declareFormat({
							id: 'FREE_TEXT_ERROR_INVALID',
							defaultFormat: 'Invalid text',
						})
					),
			  };
	};

	public readonly validateStringList = (
		entry: EditProfileEntryFragment,
		value: string
	): ValidityState => {
		return { type: value && !!value.trim() ? 'valid' : 'empty' };
	};
}
