import { LoadService } from '@knuddels-app/ModuleSystem';
import { useNavigate, useParams, useRouter } from '@knuddelsModules/Settings/bundle/components/SettingsRouter';
import { $SystemAppService, GenericSystemApp } from '@knuddelsModules/SystemApps';
import { CHANNEL_LIMIT_SETTINGS_APP_ID } from '@shared/constants';
import * as React from 'react';
export const KnuddelAccessApp = () => {
  const {
    pop
  } = useRouter();
  const navigate = useNavigate();
  const params = useParams('KnuddelAccessSettings');
  return <LoadService service={$SystemAppService}>
			{() => <GenericSystemApp viewType={'subpath'} onAppClose={() => {
      pop();
    }} onAppEvent={(e: any) => {
      if (e.type === 'showChannelTransactions') {
        navigate('KnuddelTransferHistory', {
          params: {
            channelName: e.channelName
          }
        });
      } else if (e.type === 'showAllTransactions') {
        navigate('KnuddelTransferHistory');
      }
    }} appId={CHANNEL_LIMIT_SETTINGS_APP_ID} customSlashCommand={`/knuddelzugriff2 ${params?.channelName || ''}`} />}
		</LoadService>;
};