import * as React from 'react';
import './ShadePreset.css';
interface ShadePresetProps {
  type?: 'strong' | 'weak';
}
export const ShadePreset = React.memo(({
  type = 'weak'
}: ShadePresetProps) => {
  return <div className={'Knu-FlexCol p-absolute inset-none ShadePreset-' + type} />;
});
ShadePreset.displayName = 'ShadePreset';