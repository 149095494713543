import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { computed, observable, reaction } from '@knuddels-app/mobx';
import { Disposable, EventEmitter } from '@knuddels/std';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Endpoint } from '../Endpoints';
import { $AuthService, $K3ApolloClientFactory } from '../serviceIds';
import { AuthTokenProvider } from './AuthTokenProvider';
import { ConnectionState } from './ConnectionState';
import { K3ApolloClient } from './K3ApolloClient';
import { isNative } from '@knuddels-app/tools/isNative';
import { $Environment } from '@knuddels-app/Environment';

@injectable()
export class AuthenticatedClientService implements Disposable {
	public readonly dispose = Disposable.fn();

	@computed public get connectionState(): ConnectionState {
		switch (this._currentK3Client.subscriptionClientState.kind) {
			case 'connected':
				return ConnectionState.Authorized;
			case 'connecting':
				return ConnectionState.Connecting;
			case 'disconnected':
			default:
				return ConnectionState.Offline;
		}
	}
	@observable private readonly _currentK3Client: K3ApolloClient;

	private internalState:
		| 'initializing'
		| 'connected'
		| 'reconnecting-forced'
		| 'reconnecting' = 'initializing';

	private readonly reconnectedEmitter = new EventEmitter<void>();

	// tslint:disable-next-line: member-ordering
	public readonly onReconnected = this.reconnectedEmitter.asEvent();

	/**
	 * You should use `currentK3Client` instead if possible!
	 * @deprecated
	 */
	public get currentClient(): ApolloClient<NormalizedCacheObject> {
		return this._currentK3Client.client;
	}

	public get currentK3Client(): K3ApolloClient {
		return this._currentK3Client;
	}

	@computed.struct get state():
		| { endpoint: Endpoint; sessionTokenProvider: AuthTokenProvider }
		| undefined {
		if (this.authService.state.kind === 'loggedIn') {
			return {
				sessionTokenProvider: this.authService.state
					.sessionTokenProvider,
				endpoint: this.authService.state.endpoint,
			};
		}
		return undefined;
	}

	constructor(
		@inject($K3ApolloClientFactory)
		private readonly k3ApolloClientFactory: typeof $K3ApolloClientFactory.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($FirebaseAnalyticsService)
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T
	) {
		if (this.authService.state.kind !== 'loggedIn') {
			throw new Error(
				'Authenticated client is only available after login'
			);
		}

		const data = this.authService.state;
		const newK3Client = this.k3ApolloClientFactory.createClient({
			endpoint: data.endpoint,
			withSubscriptionClient: true,
			authTokenProvider: data.sessionTokenProvider,
		});

		if (newK3Client.subscriptionClient) {
			this.dispose.track(
				reaction(
					{ name: 'reconnect websocket on session refresh' },
					() =>
						this.authService.state.kind === 'loggedIn' &&
						!!this.authService.state.sessionTokenProvider
							.currentAuthToken,
					sessionValid => {
						// force reconnecting the websocket after we got a fresh token
						// (this is only called if we shortly have no token and then get one)
						if (
							sessionValid &&
							this.internalState === 'connected'
						) {
							// prevent disconnect/reconnect events
							if (!isNative() || this.environment.hasFocus) {
								this.internalState = 'reconnecting-forced';
							}
							newK3Client.subscriptionClient?.client?.close();
						}
					}
				)
			);
			this.dispose.track(
				newK3Client.onDisconnectedFirst.sub(() => {
					// necessary because sometimes the subscription client starts with "onDisconnected" and "onReconnected" instead of "onConnected"
					if (this.internalState !== 'connected') {
						return;
					}
					this.firebaseAnalyticsService.logEvent(
						'Client_Connection',
						'ConnectionLost_ConnectionLost'
					);

					// Invalidate session to ensure a fresh session after reconnect.
					// This will also make other requests and the websocket reconnect wait
					// until the new session is available.
					this.authService.forceRefreshSession();
					this.internalState = 'reconnecting';
				})
			);
			newK3Client.subscriptionClient.onConnected(() => {
				this.internalState = 'connected';
			});
			newK3Client.subscriptionClient.onReconnected(() => {
				if (this.internalState === 'reconnecting') {
					this.firebaseAnalyticsService.logEvent(
						'Client_Connection',
						'ConnectionLost_Reconnected'
					);
				}

				if (
					this.internalState === 'reconnecting' ||
					this.internalState === 'reconnecting-forced'
				) {
					this.reconnectedEmitter.emit();
				}

				this.internalState = 'connected';
			});
		}

		this._currentK3Client = newK3Client;

		this.dispose.track(newK3Client);
	}
}
