import * as React from 'react';
import { HappyHourFragment } from '@generated/graphql';
import { useTimer } from '@knuddels-app/tools/useTimer';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { Flex, Text, ThemeColors, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useSlideInRow } from './useSlideInRow';
export const LabelContainer: React.FC<{
  location: 'top' | 'bottom';
  bg: ThemeColors;
  minWidth?: boolean;
}> = ({
  location,
  bg,
  children,
  minWidth = true
}) => {
  return <div style={{
    background: resolveThemingValue(bg, "colors", useTheme())
  }} className={_c0 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "") + (location === 'top' ? _c1 : _c2) + (location === 'top' ? _c3 : _c4) + (location === 'bottom' ? _c5 : _c6) + (location === 'bottom' ? _c7 : _c8) + (minWidth ? _c9 : _c10)}>
			{children}
		</div>;
};
export const HappyHourLabel: React.FC<{
  happyHour: HappyHourFragment;
  location: 'top' | 'bottom';
}> = ({
  happyHour,
  location
}) => {
  return <LabelContainer bg={(colorToRgbaString(happyHour.color) as ThemeColors)} location={location}>
			<AnimatedHappyHourText happyHour={happyHour} />
		</LabelContainer>;
};
const AnimatedHappyHourText: React.FC<{
  happyHour: HappyHourFragment;
}> = props => {
  const {
    domRef1,
    domRef2
  } = useSlideInRow({
    interval: 2500,
    duration: 750
  });
  const [timer] = useTimer(parseInt(props.happyHour.endTimestamp, 10), 'hh:mm:ss');
  return <div className={_c11}>
			<div ref={domRef1} className={_c12}>
				<Text type={'tiny'} numberOfLines={1} bold={true} className={_c13}>
					<FormattedMessage id={declareFormat({
          id: 'knuddeldisplay.happyhour.name',
          defaultFormat: 'HAPPY HOUR'
        })} />
				</Text>
			</div>
			<div ref={domRef2} className={_c14}>
				<Text type={'tiny'} className={_c15}>
					<FormattedMessage id={declareFormat({
          id: 'knuddeldisplay.happyhour.remaining',
          defaultFormat: '{timer} LEFT'
        })} values={{
          timer: <Text type={'tiny'} bold={true} className={_c16}>
									{timer}
								</Text>
        }} />
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-Flex placeItems-center height-16px px-tiny ";
const _c1 = " borderTopLeftRadius-tiny ";
const _c2 = " borderTopLeftRadius-none ";
const _c3 = " borderTopRightRadius-tiny ";
const _c4 = " borderTopRightRadius-none ";
const _c5 = " borderBottomLeftRadius-tiny ";
const _c6 = " borderBottomLeftRadius-none ";
const _c7 = " borderBottomRightRadius-tiny ";
const _c8 = " borderBottomRightRadius-none ";
const _c9 = " minWidth-110px ";
const _c10 = " minWidth-0-px ";
const _c11 = " Knu-Flex position-relative flex-1 overflow-hidden height-16px ";
const _c12 = " Knu-Flex position-absolute left-none right-none placeItems-center overflow-hidden ";
const _c13 = "  ";
const _c14 = " Knu-Flex position-absolute left-none right-none placeItems-center overflow-hidden ";
const _c15 = "  ";
const _c16 = "  ";