// Due to a restriction of RN firebase, categories may only consist of up to
// 32 alphanumeric characters or underscores
export const Category = {
	Core: 'Core',
	ChannelSelectionAll: 'ChannelSelection_All',
	ChannelSelection: 'ChannelSelection',
	Chatraum: 'Chat_Chatraum',
	Login: 'Login',
	NewUser: 'NewUser_Registration_ChatBot',
	NewUserStepByStep: 'NewUser_Registration_StepByStep',
	RegistrationFotoUploadChatBot: 'Registration_FotoUpload',
	RegistrationPhotoUploadStepByStep: 'Registration_FotoUp_StepByStep',
	RegistrationStepByStep: 'Registration_Funnel_StepByStep',
	RegistrationEmailMandatory: 'Registration_Email_Mandatory',
	RegistrationEmailOptional: 'Registration_Email_Optional',
	RegistrationPhoneVerification: 'Registration_TwoFactor',
	LandingPageMarketingSection: 'LandingPage_MarketingSection',
	RecoverySteps: 'RecoverySteps',
	NewPassword: 'NewPassword',
	Timing: {
		BotAnswersReg: 'BotAnswersReg',
		BotAnswersFB: 'BotAnswersFB',
		PasswordReset: 'PasswordReset',
	},
	Interstitial: 'DownloadAppModal',
	MessengerAppletSidebar: 'Messenger_AppletSidebar',
	MessengerConversation: 'Messenger_Conversation',
	MessengerNewConversation: 'Messenger_NewConversation',
	MessengerConversationOverview: 'Messenger_Conversationoverview',
	MessengerConversationArchive: 'Messenger_ConversationArchive',
	MessengerMarkedMessages: 'Messenger_MarkedMessages',
	Nicklist: 'Chat_Nicklist',
	SidebarNavigation: 'Navigation_SidebarNavigation',
	BottomNavigation: 'Navigation_BottomNavigation',
	Upload: 'Upload',
	FeedbackOverview: 'Feedback_FeedbackOverview',
	FeedbackCredits: 'Feedback_Credits',
	ProfileProfileview: 'Profile_Profileview',
	ProfileQuickwhois: 'Profile_Quickwhois',
	ProfileOwnProfileview: 'Profile_OwnProfileView',
	ProfileReportImage: 'Profile_ReportImage',
	ProfileReportUserAccount: 'Profile_ReportUserAccount',
	FotomeetVotingView: 'Fotomeet_VotingView',
	FotomeetMatchesView: 'Fotomeet_MatchesView',
	FotomeetReportImage: 'Fotomeet_ReportImage',
	CheckoutKnuddelsPlus: 'Checkout_KnuddelsPlus',
	Smileybox: 'Smileybox',
};
