export enum Z_INDEX {
	// WARNING: you can't rely on higher numbers to always render above smaller numbers
	// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

	OVERCONTENT = 5,
	HIGHEST_CONTENT = 8,

	BELOW_TITLE_BAR = 9,
	TITLE_BAR = 10,
	OVER_TITLE_BAR = 11,

	BELOW_NAV = 79,
	NAV_OVERLAY = 80,
	NAV = 81,

	JUST_BELOW_OVERLAY = 89,
	OVERLAY = 90,

	// modal always on top on native
	MODAL = 100,
	ABOVE_MODAL = 101,
}
