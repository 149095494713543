import { addUniqueItem as t, removeItem as r } from "../../utils/array.js";
import { compareByDepth as e } from "./compare-by-depth.js";
class c {
  constructor() {
    this.children = [], this.isDirty = !1;
  }
  add(i) {
    t(this.children, i), this.isDirty = !0;
  }
  remove(i) {
    r(this.children, i), this.isDirty = !0;
  }
  forEach(i) {
    this.isDirty && this.children.sort(e), this.isDirty = !1, this.children.forEach(i);
  }
}
export {
  c as FlatTree
};
