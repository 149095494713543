import { createGeneratorEasing as J } from "../../easing/utils/create-generator-easing.js";
import { resolveElements as P } from "../../render/dom/utils/resolve-element.js";
import { defaultOffset as Q } from "../../utils/offsets/default.js";
import { fillOffset as R } from "../../utils/offsets/fill.js";
import { progress as U } from "../../utils/progress.js";
import { secondsToMilliseconds as W } from "../../utils/time-conversion.js";
import { isMotionValue as X } from "../../value/utils/is-motion-value.js";
import { calcNextTime as N } from "./utils/calc-time.js";
import { addKeyframes as Y } from "./utils/edit.js";
import { compareByTime as Z } from "./utils/sort.js";
const _ = "easeInOut";
function pe(e, { defaultTransition: t = {}, ...I } = {}, B) {
  const C = t.duration || 0.3, b = /* @__PURE__ */ new Map(), M = /* @__PURE__ */ new Map(), F = {}, v = /* @__PURE__ */ new Map();
  let S = 0, l = 0, A = 0;
  for (let p = 0; p < e.length; p++) {
    const i = e[p];
    if (typeof i == "string") {
      v.set(i, l);
      continue;
    } else if (!Array.isArray(i)) {
      v.set(i.name, N(l, i.at, S, v));
      continue;
    }
    let [m, r, s = {}] = i;
    s.at !== void 0 && (l = N(l, s.at, S, v));
    let o = 0;
    const g = (a, f, c, d = 0, y = 0) => {
      const n = $(a), { delay: E = 0, times: h = Q(n), type: G = "keyframes", ...z } = f;
      let { ease: T = t.ease || "easeOut", duration: u } = f;
      const x = typeof E == "function" ? E(d, y) : E, D = n.length;
      if (D <= 2 && G === "spring") {
        let V = 100;
        if (D === 2 && ne(n)) {
          const H = n[1] - n[0];
          V = Math.abs(H);
        }
        const w = { ...z };
        u !== void 0 && (w.duration = W(u));
        const L = J(w, V);
        T = L.ease, u = L.duration;
      }
      u ?? (u = C);
      const O = l + x, k = O + u;
      h.length === 1 && h[0] === 0 && (h[1] = 1);
      const K = h.length - n.length;
      K > 0 && R(h, K), n.length === 1 && n.unshift(null), Y(c, n, T, h, O, k), o = Math.max(x + u, o), A = Math.max(k, A);
    };
    if (X(m)) {
      const a = j(m, M);
      g(r, s, q("default", a));
    } else {
      const a = P(m, B, F), f = a.length;
      for (let c = 0; c < f; c++) {
        r = r, s = s;
        const d = a[c], y = j(d, M);
        for (const n in r)
          g(r[n], ee(s, n), q(n, y), c, f);
      }
    }
    S = l, l += o;
  }
  return M.forEach((p, i) => {
    for (const m in p) {
      const r = p[m];
      r.sort(Z);
      const s = [], o = [], g = [];
      for (let f = 0; f < r.length; f++) {
        const { at: c, value: d, easing: y } = r[f];
        s.push(d), o.push(U(0, A, c)), g.push(y || "easeOut");
      }
      o[0] !== 0 && (o.unshift(0), s.unshift(s[0]), g.unshift(_)), o[o.length - 1] !== 1 && (o.push(1), s.push(null)), b.has(i) || b.set(i, {
        keyframes: {},
        transition: {}
      });
      const a = b.get(i);
      a.keyframes[m] = s, a.transition[m] = {
        ...t,
        duration: A,
        ease: g,
        times: o,
        ...I
      };
    }
  }), b;
}
function j(e, t) {
  return !t.has(e) && t.set(e, {}), t.get(e);
}
function q(e, t) {
  return t[e] || (t[e] = []), t[e];
}
function $(e) {
  return Array.isArray(e) ? e : [e];
}
function ee(e, t) {
  return e[t] ? {
    ...e,
    ...e[t]
  } : { ...e };
}
const te = (e) => typeof e == "number", ne = (e) => e.every(te);
export {
  pe as createAnimationsFromSequence,
  ee as getValueTransition
};
