import {
	ConversationMessageFragment,
	FullConversationWithoutMessagesFragment,
} from '@generated/graphql';

function isConversationWithLatestMessage(
	conv: FullConversationWithoutMessagesFragment
): conv is ConversationWithLatestMessage {
	return !!conv.latestConversationMessage;
}

export function conversationToMessengerOverviewItem(
	conversation: FullConversationWithoutMessagesFragment
):
	| MessengerOverviewConversationItem
	| MessengerOverviewInvalidConversationItem {
	if (isConversationWithLatestMessage(conversation)) {
		return {
			type: 'conversation',
			conversation,
			conversationId: conversation.id,
			timestamp: +conversation.latestConversationMessage.timestamp,
		};
	} else {
		return {
			type: 'invalid-conversation',
			conversation: conversation as ConversationWithoutLatestMessage,
			conversationId: conversation.id,
			timestamp: 'invalid',
		};
	}
}

export interface MessengerOverviewItemBase {
	conversationId: string;
	timestamp: number | 'invalid';
}

export type ConversationWithLatestMessage = FullConversationWithoutMessagesFragment & {
	latestMessage: ConversationMessageFragment;
};
export interface MessengerOverviewConversationItem
	extends MessengerOverviewItemBase {
	type: 'conversation';
	timestamp: number;
	conversation: ConversationWithLatestMessage;
}

export type ConversationWithoutLatestMessage = FullConversationWithoutMessagesFragment & {
	latestMessage: null | undefined;
};
export interface MessengerOverviewInvalidConversationItem
	extends MessengerOverviewItemBase {
	type: 'invalid-conversation';
	timestamp: 'invalid';
	conversation: ConversationWithoutLatestMessage;
}

export type MessengerOverviewItem =
	| MessengerOverviewConversationItem
	| MessengerOverviewInvalidConversationItem;

export function getSortedOverviewItems(
	items: readonly MessengerOverviewItem[]
): MessengerOverviewItem[] {
	return [...items].sort((a, b) => {
		// sort invalid timestamps to top
		// (to see them easier and get users to report it)
		const ts1 = a.timestamp === 'invalid' ? Infinity : a.timestamp;
		const ts2 = b.timestamp === 'invalid' ? Infinity : b.timestamp;

		return ts2 - ts1;
	});
}
