import { observer } from '@knuddels-app/mobx';
import { Flex, FlexCol, Text, toPointerHandler } from '@knuddels/component-library';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { FormattedText, FormattedTextDisplay } from '@shared/components';
import React from 'react';
export const isFormattedJsonString = (jsonString: string): boolean => {
  try {
    FormattedText.fromJsonString(jsonString);
    return true;
  } catch {
    return false;
  }
};
const MessengerNotification: React.FC<{
  userId: string;
  username: string;
  conversationId: string;
  activeNotifications: Map<string, {
    messages: string[];
  }>;
  onClick(): void;
}> = observer('MessengerNotification', ({
  userId,
  username,
  conversationId,
  activeNotifications,
  onClick
}) => {
  const messageCount = activeNotifications.get(conversationId)?.messages.length;
  return <div onClick={toPointerHandler(onClick)} className={_c0}>
				<UserImage type={UserImageType.MESSENGER_NOTIFICATION} uid={userId} />
				<div className={_c1}>
					<Text bold={true} className={_c2}>{username}</Text>
					{activeNotifications.get(conversationId)?.messages.slice(-1).map((message, index) => <div key={index} className={_c3 + (messageCount > 1 ? _c4 : _c5)}>
								{isFormattedJsonString(message) ? <FormattedTextDisplay text={FormattedText.fromJsonString(message)} textProps={{
          type: 'body1',
          state: 'secondary',
          numberOfLines: 3
        }} /> : <Text type={'body1'} state={'secondary'} numberOfLines={3} className={_c6}>
										{message}
									</Text>}
							</div>)}
					{messageCount > 1 && <Text type={'tiny'} state={'tertiary'} className={_c7}>{`${activeNotifications.get(conversationId).messages.length - 1} weitere`}</Text>}
				</div>
			</div>;
});
export default MessengerNotification;
const _c0 = " Knu-Flex p-minor alignItems-center gap-minor ";
const _c1 = " Knu-FlexCol overflow-hidden ";
const _c2 = "  ";
const _c3 = " Knu-Flex ";
const _c4 = " pb-tiny ";
const _c5 = " pb-none ";
const _c6 = "  ";
const _c7 = "  ";