import * as React from 'react';
import { useContext } from 'react';
import { AlbumDetailPhotoFragment, AlbumFragment, User } from '@generated/graphql';
import { Spacer } from '@shared/components';
import { Box, Flex, FlexCol, Text, useTheme, VirtualList, VirtualListItem, VirtualListRenderItemInfo, FallbackBox, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { expectUnreachable } from '@knuddels/std';
import { NoAlbumsPlaceholder } from './NoAlbumsPlaceholder';
import { PhotoRow, PhotoRowElement } from '../../PhotoRow';
import { splitToPhotoRows } from '../../splitToPhotoRows';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import { ProfilePhotoSection } from '../ProfilePhotoSection';
import { useTrackProfileView } from '../../../shared/useTrackProfileView';
import { DisableScrollContext } from '@shared/helper/DisableScrollContext';
import { ProfileAlbumInfo } from '../../../profileQueryHelper';
const PHOTO_SIZE = 104;
const PHOTO_SPACING = 8;
const templateHeader = 'header';
const templateRow = 'row';
const templateSpacer = 'spacer';
const templateProfilePhoto = 'profilePhoto';
type AlbumsTabListItem = HeaderListItem | RowListItem | SpacerListItem | ProfilePhotoListItem;
interface HeaderListItem extends VirtualListItem {
  type: typeof templateHeader;
  title: string;
  photoCount: number;
}
interface RowListItem extends VirtualListItem {
  type: typeof templateRow;
  userId: User['id'];
  rowElements: readonly PhotoRowElement[];
  onClick(): void;
}
interface SpacerListItem extends VirtualListItem {
  type: typeof templateSpacer;
}
interface ProfilePhotoListItem extends VirtualListItem {
  type: typeof templateProfilePhoto;
  userId: User['id'];
  photo: AlbumDetailPhotoFragment | undefined;
  isOwner: boolean;
}
export const PhotosTabContent: React.FC<{
  user: {
    id: User['id'];
    nick: string;
  };
  data: ProfileAlbumInfo | undefined;
}> = ({
  user,
  data
}) => {
  const [width, setWidth] = React.useState(0);
  const theme = useTheme();
  const listData = useListData(user.id, data.albumProfilePhoto, data.albums, width - 2 * theme.spacings.base);
  const disableScroll = useContext(DisableScrollContext);
  return <FallbackBox onLayout={e => setWidth(e.width)} className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{listData.length > 0 ? <VirtualList data={listData} renderItem={renderListItem} disableScroll={disableScroll} className={_c1} /> : <NoAlbumsPlaceholder title={user.nick} />}
		</FallbackBox>;
};
const useListData = (userId: User['id'], profilePhoto: AlbumDetailPhotoFragment | undefined, albums: readonly AlbumFragment[], width: number) => {
  const isOwner = useService($UserService).currentUser?.id === userId;
  const track = useTrackProfileView();
  const trackAlbumPhotoClick = React.useCallback(() => {
    track('Albumtab_ImageClicked');
  }, [track]);
  return React.useMemo(() => {
    const result: AlbumsTabListItem[] = [];
    if (profilePhoto || isOwner) {
      result.push({
        userId,
        isOwner,
        type: templateProfilePhoto,
        photo: profilePhoto,
        height: 156
      });
      result.push({
        type: templateSpacer,
        height: 20
      });
    }
    albums.filter(album => album.albumPhotos.length > 0 || isOwner).forEach(album => {
      result.push({
        type: 'header',
        title: album.title,
        photoCount: album.albumPhotos.length,
        height: 44
      });
      const rows = splitToPhotoRows(album, PHOTO_SIZE, PHOTO_SPACING, width);
      rows.forEach((row, index) => {
        if (index > 0) {
          result.push({
            type: templateSpacer,
            height: PHOTO_SPACING
          });
        }
        result.push({
          type: templateRow,
          rowElements: row,
          height: PHOTO_SIZE,
          userId,
          onClick: trackAlbumPhotoClick
        });
      });
      result.push({
        type: templateSpacer,
        height: 20
      });
    });
    return result;
  }, [albums, width]);
};
function renderListItem({
  item
}: VirtualListRenderItemInfo<AlbumsTabListItem>): JSX.Element {
  switch (item.type) {
    case 'header':
      return <Header title={item.title} photoCount={item.photoCount} />;
    case 'row':
      return <PhotoRow userId={item.userId} elements={item.rowElements} size={PHOTO_SIZE} spacing={PHOTO_SPACING} onPhotoClicked={item.onClick} />;
    case 'spacer':
      return <div style={{
        height: resolveThemingValue(item.height, "sizes", useTheme())
      }} className={_c2} />;
    case 'profilePhoto':
      return <ProfilePhotoSection userId={item.userId} photo={item.photo} photoSize={PHOTO_SIZE} photoSpacing={PHOTO_SPACING} editable={item.isOwner && !item.photo} />;
    default:
      expectUnreachable(item);
  }
}
const Header = React.memo(({
  title,
  photoCount
}: {
  title: string;
  photoCount: number;
}) => {
  return <div onPointerDown={() => {
    /* this is required for scrolling on ipads. otherwise no touch events are processed */
  }} className={_c3}>
				<Text type={'body1'} state={'tertiary'} bold={true} className={_c4}>
					{title}
				</Text>
				<Spacer size={'minor'} />
				<Text type={'tiny'} state={'tertiary'} className={_c5}>
					<FormattedMessage id={declareFormat({
        id: 'profile.albums.albumPhotoCount',
        defaultFormat: '{count} {count, plural, one {photo} other {photos}}'
      })} values={{
        count: photoCount
      }} />
				</Text>
			</div>;
});
Header.displayName = 'Header';
const _c0 = " Knu-FlexCol width-100-percent height-100-percent bg-contentBg ";
const _c1 = "  ";
const _c2 = " Knu-Box ";
const _c3 = " Knu-Flex alignItems-flex-end py-minor px-base ";
const _c4 = "  ";
const _c5 = "  ";