import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import {
	$LocalStorage,
	booleanOrUndefinedSerializer,
	LocalStorageKey,
} from '@knuddels-app/local-storage';
import { getScopeIfWeb } from '@knuddels-app/local-storage/sessionCookieHelper';

const isLoginAfterRegistrationEntry = new LocalStorageKey({
	name: 'isLoginAfterRegistration',
	cookieExpires: 'withSession',
	serializer: booleanOrUndefinedSerializer,
	scope: getScopeIfWeb(),
});

@injectable()
export class FirstLoginStorage {
	public readonly dispose = Disposable.fn();

	private readonly isLoginAfterRegistrationEntry = this.localStorage.getEntry(
		isLoginAfterRegistrationEntry
	);

	protected isFirstLogin: boolean | undefined = undefined;

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {}

	setFirstLoginAfterRegistration = () => {
		this.isFirstLogin = true;
		this.isLoginAfterRegistrationEntry.set(true);
	};

	resetFirstLoginAfterRegistration = () => {
		if (this.isFirstLogin) {
			this.isFirstLogin = false;
			this.isLoginAfterRegistrationEntry.set(undefined);
		}
	};

	isFirstLoginAfterRegistration = () => {
		if (this.isFirstLogin !== undefined) {
			return this.isFirstLogin;
		}

		this.isFirstLogin = this.isLoginAfterRegistrationEntry.get() ?? false;
		return this.isFirstLogin;
	};
}
