import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
export interface RetroSmileyItem {
  src: string;
  text: string;
}
export const useRetroSmileys = (): RetroSmileyItem[] => {
  const i18n = useService($I18n);
  const getRetroSmileyText = (points: string): string => {
    return declareFormat({
      id: 'mentorsystem.rewards.info.mentor.points-necessary',
      defaultFormat: 'starting from {points}'
    }).format(i18n, {
      points
    });
  };
  return [{
    src: require('@shared/images/retro/sm_00.gif'),
    text: getRetroSmileyText('100')
  }, {
    src: require('@shared/images/retro/sm_03.gif'),
    text: getRetroSmileyText('200')
  }, {
    src: require('@shared/images/retro/sm_13.gif'),
    text: getRetroSmileyText('400')
  }, {
    src: require('@shared/images/retro/sm_10.gif'),
    text: getRetroSmileyText('700')
  }, {
    src: require('@shared/images/retro/sm_06.gif'),
    text: getRetroSmileyText('1.000')
  }, {
    src: require('@shared/images/retro/sm_01.gif'),
    text: getRetroSmileyText('1.500')
  }, {
    src: require('@shared/images/retro/sm_12.gif'),
    text: getRetroSmileyText('2.400')
  }, {
    src: require('@shared/images/retro/sm_08.gif'),
    text: getRetroSmileyText('3.600')
  }, {
    src: require('@shared/images/retro/sm_07.gif'),
    text: getRetroSmileyText('5.000')
  }, {
    src: require('@shared/images/retro/sm_04.gif'),
    text: getRetroSmileyText('7.000')
  }, {
    src: require('@shared/images/retro/sm_05.gif'),
    text: getRetroSmileyText('10.000')
  }, {
    src: require('@shared/images/retro/sm_09.gif'),
    text: getRetroSmileyText('15.000')
  }, {
    src: require('@shared/images/retro/sm_02.gif'),
    text: getRetroSmileyText('25.000')
  }, {
    src: require('@shared/images/retro/sm_11.gif'),
    text: getRetroSmileyText('40.000')
  }];
};