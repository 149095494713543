import { LocalStorageKey } from '@knuddels-app/local-storage';

export const themeKey = LocalStorageKey.withJsonSerializer<string>({
	name: 'theme',
	cookieExpires: { inDays: Number.MAX_SAFE_INTEGER },
});

export const accentKey = LocalStorageKey.withJsonSerializer<string>({
	name: 'accent',
	cookieExpires: { inDays: Number.MAX_SAFE_INTEGER },
});
