import * as React from 'react';
import { NotificationEnabledFragment, NotificationEnabledInput, NotificationStatusFragment } from '@generated/graphql';
import { useTrackEnableChanged, useTrackUpdateNotificationSettings } from './useTrackMessengerSettings';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Checkbox, FlexCol, SwitchListItem, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { NotificationStatusType } from '../../../constants';
export const NotificationSettingsGroupItem: React.FC<{
  type: NotificationStatusType;
  caption: string;
  status: NotificationStatusFragment;
  updateRemoteSettings: (input: NotificationEnabledInput) => Promise<boolean>;
  updateRemoteEnabled: (enabled: boolean) => Promise<boolean>;
}> = ({
  type,
  caption,
  status,
  updateRemoteEnabled,
  updateRemoteSettings
}) => {
  const trackSetActive = useTrackEnableChanged(type);
  const [active, setActive] = React.useState(status.__typename === 'NotificationEnabled');
  React.useEffect(() => {
    setActive(status.__typename === 'NotificationEnabled');
  }, [status.__typename]);
  const updateActive = async (newActive: boolean) => {
    trackSetActive(newActive);
    const oldActive = active;
    setActive(newActive);
    const ok = await updateRemoteEnabled(newActive);
    if (!ok) {
      setActive(oldActive);
    }
  };
  return <>
			<SwitchListItem caption={caption} checked={active} onChange={value => updateActive(value)} separator={'none'} />
			{status.__typename === 'NotificationEnabled' && <NotificationSettings type={type} status={status} updateRemoteSettings={updateRemoteSettings} />}
		</>;
};
const NotificationSettings: React.FC<{
  type: NotificationStatusType;
  status: NotificationEnabledFragment;
  updateRemoteSettings: (input: NotificationEnabledInput) => Promise<boolean>;
}> = ({
  type,
  status,
  updateRemoteSettings
}) => {
  const i18n = useService($I18n);
  const trackChange = useTrackUpdateNotificationSettings(type);
  const [settings, setSettings] = React.useState(status);
  React.useEffect(() => {
    setSettings(status);
  }, [status]);
  const updateSettings = async (input: NotificationEnabledInput): Promise<void> => {
    trackChange(input);
    const oldSettings = settings;
    setSettings({
      ...settings,
      ...input
    });
    const ok = await updateRemoteSettings(input);
    if (!ok) {
      setSettings(oldSettings);
      return;
    }
  };
  if (!settings) {
    return null;
  }
  return <div className={_c0 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Checkbox value={settings.notifyBirthday} onChange={({
      value
    }) => updateSettings({
      notifyBirthday: value
    })} label={declareFormat({
      id: 'settings.messengerSettings.birthday',
      defaultFormat: 'Birthdays'
    }).format(i18n)} />
			<Checkbox value={settings.notifyTeamJoin} onChange={({
      value
    }) => updateSettings({
      notifyTeamJoin: value
    })} label={declareFormat({
      id: 'settings.messengerSettings.teamjoin',
      defaultFormat: 'Team joined'
    }).format(i18n)} />
			<Checkbox value={settings.notifyProfilePictureChange} onChange={({
      value
    }) => updateSettings({
      notifyProfilePictureChange: value
    })} label={declareFormat({
      id: 'settings.messengerSettings.newpicture',
      defaultFormat: 'New picture'
    }).format(i18n)} />
			<Checkbox value={settings.notifyCommunityStatusChange} onChange={({
      value
    }) => updateSettings({
      notifyCommunityStatusChange: value
    })} label={declareFormat({
      id: 'settings.messengerSettings.newstatus',
      defaultFormat: 'New community status'
    }).format(i18n)} />
			<Checkbox value={settings.notifyProfileChange} onChange={({
      value
    }) => updateSettings({
      notifyProfileChange: value
    })} label={declareFormat({
      id: 'settings.messengerSettings.profilechange',
      defaultFormat: 'Profile changed'
    }).format(i18n)} />
			{type === NotificationStatusType.Friends && <Checkbox value={settings.notifyNicknameChange} onChange={({
      value
    }) => updateSettings({
      notifyNicknameChange: value
    })} label={declareFormat({
      id: 'settings.messengerSettings.nickChange',
      defaultFormat: 'Nickname changed'
    }).format(i18n)} />}
		</div>;
};
const _c0 = " Knu-FlexCol ml-tiny mb-small bg-transparent ";