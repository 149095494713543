import { injectable, inject } from '@knuddels-app/DependencyInjection';
import { autorunWhen } from '@knuddels-app/mobx';
import { $AuthService } from './serviceIds';
import { Disposable } from '@knuddels/std';
import { $ModuleService } from '@knuddels-app/ModuleSystem/providedServices'; // Direct import needed to prevent circular dependencies

@injectable()
export class LoggedInModuleLoader implements Disposable {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($AuthService) authService: typeof $AuthService.T,
		@inject($ModuleService) moduleService: typeof $ModuleService.T
	) {
		this.dispose.track(
			autorunWhen(
				{ name: 'Load modules on login' },
				() => authService.state.kind === 'loggedIn',
				() => {
					for (const m of moduleService.knownModules) {
						if (m.activationMode === 'onLogin') {
							moduleService.loadModule(m);
						}
					}
				}
			)
		);
	}
}
