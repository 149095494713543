import * as React from 'react';
import { Z_INDEX } from '../../../globalStyleConfiguration';
import { CenterShrinkView } from '../CenterShrinkView';
import { Flex, FlexCol, FlexProps, ThemeColors, ThemeOverride, FallbackBox, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
interface Props {
  backgroundColor?: ThemeColors;
  renderSideComponent?: (width: number, height: number) => React.ReactNode;
  /**
   * Render function for a component that is shown beneath the lightbox
   *
   * @param width The width of the area in which the lightbox is shown
   * @param height The height of the area in which the lightbox is shown
   * @param availableHeight The total height of the empty space below the lightbox
   */
  renderBottomComponent?: (width: number, height: number, availableHeight: number) => React.ReactNode;
}
export const Lightbox: React.FC<Props & {
  pt?: FlexProps['pt'];
}> = ({
  renderBottomComponent,
  renderSideComponent,
  backgroundColor,
  children,
  pt
}) => {
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  const [{
    width: contentWidth,
    height: contentHeight
  }, setContentSize] = React.useState({
    width: 0,
    height: 0
  });
  return <FallbackBox onLayout={e => setSize(e)} style={{
    zIndex: resolveThemingValue(Z_INDEX.OVER_TITLE_BAR, "theme", useTheme()),
    background: resolveThemingValue(backgroundColor || ('rgba(40, 34, 34, 0.8)' as ThemeOverride), "colors", useTheme()),
    paddingTop: resolveThemingValue(pt, "spacing", useTheme())
  }} className={_c0 + (backgroundColor || ('rgba(40, 34, 34, 0.8)' as ThemeOverride) ? resolveIsDarkColor(backgroundColor || ('rgba(40, 34, 34, 0.8)' as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{!!(width && height) && <>
					<div className={_c1}>
						<CenterShrinkView>
							<FallbackBox onLayout={e => setContentSize(e)} className={_c2}>
								{children}
							</FallbackBox>
						</CenterShrinkView>
						{!!renderBottomComponent && !!(contentWidth && contentHeight) && renderBottomComponent(width, height, (height - contentHeight) / 2)}
					</div>
					<div className={_c3}>
						{!!renderSideComponent && renderSideComponent(width, height)}
					</div>
				</>}
		</FallbackBox>;
};
const _c0 = " Knu-Flex position-absolute inset-none ";
const _c1 = " Knu-FlexCol position-relative flex-1 ";
const _c2 = " Knu-FlexCol position-relative ";
const _c3 = " Knu-FlexCol position-relative ";