import * as React from 'react';
import { BannerBoxButton, BannerBoxButtonProps } from './BannerBoxButton';
import { Flex, FlexCol, LineDivider, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
interface BannerBoxProps {
  primaryButton: BannerBoxButtonProps;
  secondaryButton?: BannerBoxButtonProps;
  showFooterButtons?: boolean;
  maxWidth?: number;
}

/**
 * Basic BannerBox component.
 * Should be wrapped in a View with padding (to not cut off the shadows).
 */
export function BannerBox({
  primaryButton,
  secondaryButton,
  showFooterButtons = true,
  maxWidth = 344,
  children
}: React.PropsWithChildren<BannerBoxProps>): React.ReactElement {
  return <div style={{
    maxWidth: resolveThemingValue(maxWidth, "sizes", useTheme())
  }} className={_c0 + ("cardBg" ? resolveIsDarkColor("cardBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c1}>
				{children}
			</div>
			{showFooterButtons && <>
					<div className={_c2}>
						<LineDivider className={_c3} />
					</div>
					<div className={_c4}>
						{secondaryButton && <>
								<BannerBoxButton {...secondaryButton} />
								<div className={_c5}>
									<LineDivider orientation={'vertical'} className={_c6} />
								</div>
							</>}
						<BannerBoxButton {...primaryButton} />
					</div>
				</>}
		</div>;
}
const _c0 = " Knu-FlexCol shadow-Shadow1 bg-cardBg borderRadius-10px ";
const _c1 = " Knu-FlexCol position-relative minWidth-0-px p-large ";
const _c2 = " Knu-Flex ";
const _c3 = "  ";
const _c4 = " Knu-Flex ";
const _c5 = " Knu-FlexCol ";
const _c6 = "  ";