import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $ContactsService = newServiceId<services.ContactsService>(
	'$ContactsService'
);

export const $ContactsCommandService = newServiceId<
	services.ContactsCommandService
>('$ContactsCommandService');

export const $FriendRequestsService = newServiceId<
	services.FriendRequestsService
>('$FriendRequestsService');

export const $WatchlistService = newServiceId<services.WatchlistService>(
	'$WatchlistService'
);
