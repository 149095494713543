import * as React from 'react';
import { SmileyItem, SmileyList } from './SmileyList';
import { declareProps, useService } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { Flex, FlexCol, Panel, PanelsList, PointerEvent, rgb, Tabs, ThemeOverride, useTheme, toPointerHandler, resolveThemingValue, FallbackBox, resolveIsDarkColor } from '@knuddels/component-library';
import { $SmileyService } from '@knuddelsModules/AutocompleteInputBar';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { ContainerContext, Grid, Item } from '@knuddelsModules/Smileybox/bundle/components/SmileyGrid';

// tslint:disable-next-line: no-module-bleeding
import { EmojiData } from '@knuddelsModules/AutocompleteInputBar/bundle/services';
import { $createEmojiNode } from '@shared/components/RichText/nodes/EmojiNode';
import { observer } from '@knuddels-app/mobx';
import { BoxHeader, ScrollIndicatorProvider } from '@knuddelsModules/Smileybox/bundle/components/BoxHeader';
import { usePromise } from '@knuddels-app/tools/usePromise';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { IconStar } from '@shared/icons/IconStar';
import { useMySmileysChangedObserver } from './useMySmileysChangedObserver';
import { VirtuosoHandle } from 'react-virtuoso';
import { IconStarOff } from '@shared/icons/IconStarOff';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { SmileyboxAnalyticsProvider, useSmileyBoxTrackEvent } from './SmileyboxAnalyticsContext';
const Spacing = 8;
const TabHeaders = ['Smileys', 'Emojis'];
const ItemSize = 48 + Spacing;
const lastShownIndeyKey = 'lastShownIndex';
const getActiveIndexFromSessionStorage = () => {
  const lastShownIndex = sessionStorage.getItem(lastShownIndeyKey);
  return lastShownIndex ? parseInt(lastShownIndex, 10) : 0;
};
const setActiveIndexToSessionStorage = (index: number) => {
  sessionStorage.setItem(lastShownIndeyKey, index.toString());
};

// const setActiveIndexToLocalStorage = (index: number) => {
// 	localStorage.setItem(lastShownIndeyKey, index.toString());
// };

const SearchResults: React.FC<{
  query: string;
  maxItemsPerRow: number;
  editorRef: React.RefObject<RichTextEditorRef>;
}> = ({
  query,
  maxItemsPerRow,
  editorRef
}) => {
  const smileyService = useService($SmileyService);
  const [searchKey, setSearchKey] = React.useState(0);
  const gridRef = React.useRef<VirtuosoHandle>(null);
  useMySmileysChangedObserver(() => {
    setSearchKey(searchKey + 1);
    gridRef.current?.scrollToIndex({
      index: 0
    });
  });
  const data = usePromise(React.useMemo(() => {
    return smileyService.getSmiliesAndEmojis(query);
  }, [query, smileyService, searchKey]));
  return <Grid pl={(Spacing * 2 as ThemeOverride)} gridRef={gridRef} groups={[{
    label: 'Top-Treffer',
    items: data.topResults
  }, {
    label: 'Weitere Suchergebnisse',
    items: data.results
  }]} itemSize={64} itemSpacing={8} bottomSpacing={24} maxItemsPerRow={maxItemsPerRow} renderItem={smileyOrEmoji => {
    if ('emoji' in smileyOrEmoji) {
      return <EmojiItem emoji={smileyOrEmoji} key={smileyOrEmoji.emoji} editorRef={editorRef} />;
    }
    return <SmileyItem smiley={smileyOrEmoji} editorRef={editorRef} />;
  }} />;
};
const EmojiItem: React.FC<{
  editorRef: React.RefObject<RichTextEditorRef>;
  emoji: EmojiData;
  showFavorite?: boolean;
}> = observer('EmojiItem', ({
  editorRef,
  emoji,
  showFavorite
}) => {
  const multi = !!emoji.skins;
  const smileyService = useService($SmileyService);
  const keyboardService = useService($KeyboardService);
  const track = useSmileyBoxTrackEvent();
  const theme = useTheme();
  const renderEmoji = multi && smileyService.emojiOverrides[emoji.hexcode] !== undefined ? emoji.skins[smileyService.emojiOverrides[emoji.hexcode]].emoji : emoji.emoji;
  return <Item<EmojiData | {
    type: 'unfavorite' | 'favorite';
  }> multi={multi} onPress={() => {
    track('Emoji_Used');
    editorRef.current.insertNode(() => $createEmojiNode({
      ...emoji,
      emoji: renderEmoji,
      originalEmoji: multi ? emoji.emoji : emoji.originalEmoji ?? emoji.emoji
    }), ({
      preventSelection
    }) => {
      if (keyboardService.focusable?.parentFocusable) {
        preventSelection();
      }
    });
  }} renderContextMenu={{
    getItems: async () => {
      if (!showFavorite) {
        return [];
      }
      const favoriteEntry = ({
        type: smileyService.favoriteEmojiIds.includes(emoji.hexcode) ? 'unfavorite' : 'favorite'
      } as const);
      if (!multi) {
        return [favoriteEntry];
      }
      return renderEmoji === emoji.emoji ? [...emoji.skins, favoriteEntry] : [emoji, ...emoji.skins.filter(skin => skin.emoji !== renderEmoji), favoriteEntry];
    },
    renderItem: item => {
      if (!('emoji' in item)) {
        return <div onClick={toPointerHandler(e => {
          e.preventDefault();
          if (item.type === 'favorite') {
            smileyService.addFavoriteEmoji(emoji.hexcode);
            return;
          }
          smileyService.removeFavoriteEmoji(emoji.hexcode);
        })} key={item.type} style={{
          background: resolveThemingValue(theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880'], "colors", useTheme())
        }} className={_c0 + ((theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880']) ? resolveIsDarkColor(theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880'], useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
								{item.type === 'favorite' ? <IconStar size={'large'} /> : <IconStarOff size={'large'} />}
							</div>;
      }
      return <Item key={item.emoji} multi={false} onPress={() => {
        const index = emoji.skins.indexOf(item);
        smileyService.addEmojiOverride(emoji.hexcode, index);
        editorRef.current.insertNode(() => $createEmojiNode({
          ...emoji,
          emoji: item.emoji
        }));
      }}>
							{item.emoji}
						</Item>;
    }
  }}>
			{renderEmoji}
		</Item>;
});
export const Smileybox = injectedComponent({
  name: 'Smileybox',
  props: declareProps<{
    containerRef: React.RefObject<HTMLDivElement>;
    editorRef: React.RefObject<RichTextEditorRef>;
  }>()
}, ({
  containerRef,
  editorRef
}) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [query, setQuery] = React.useState('');
  const keyboardService = useService($KeyboardService);
  const firebaseAnalytics = useService($FirebaseAnalyticsService);
  const rerender = React.useState({})[1];
  const [, startTransition] = (React as any).useTransition();
  const smileyService = useService($SmileyService);
  const [activeIndex, setActiveIndex] = React.useState(() => getActiveIndexFromSessionStorage());
  React.useEffect(() => {
    if (activeIndex === 0) {
      firebaseAnalytics.logEvent('Smileybox_Smileys', 'Opened_');
    } else {
      firebaseAnalytics.logEvent('Smileybox_Emojis', 'Opened_');
    }
  }, [activeIndex]);
  const emojiGroups = React.useMemo(() => {
    return Object.keys(smileyService.emojisByGroup).map(emojiGroup => ({
      label: emojiGroup,
      items: smileyService.emojisByGroup[emojiGroup]
    }));
  }, [smileyService.emojisByGroup]);
  const handle = (e: PointerEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const containerWidth = containerRef.current?.clientWidth ?? 0;
  if (containerWidth === 0) {
    return null;
  }
  const maxItemsPerRow = Math.floor((containerWidth - Spacing * 2 + Spacing) / ItemSize);
  return <ContainerContext.Provider value={containerRef}>
				<ScrollIndicatorProvider>
					<FallbackBox onPointerDown={toPointerHandler(handle)} onClick={toPointerHandler(handle)} onLayout={e => {
        if (containerWidth !== e.width && e.width > 0) {
          rerender({});
        }
      }} className={_c1}>
						<SmileyboxAnalyticsProvider context={'Smileybox_Search'}>
							<BoxHeader editorRef={editorRef} tabs={TabHeaders} searchValue={searchValue} setSearchValue={value => {
            setSearchValue(value);
            startTransition(() => {
              setQuery(value);
            });
          }} activeIndex={activeIndex} setActiveIndex={newIndex => {
            setActiveIndex(newIndex);
            setActiveIndexToSessionStorage(newIndex);
          }} />
						</SmileyboxAnalyticsProvider>

						<div style={{
          display: 'contents'
        }} onPointerDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()} onTouchMove={e => {
          if (keyboardService.focusable.parentFocusable) {
            e.preventDefault();
            keyboardService.hideKeyboard();
          }
        }}>
							<React.Suspense fallback={null}>
								{!query.length ? <Tabs value={activeIndex} mountingType={'unmountInactive'}>
										<PanelsList>
											<Panel>
												<SmileyboxAnalyticsProvider context={'Smileybox_Smileys'}>
													<SmileyList editorRef={editorRef} maxItemsPerRow={maxItemsPerRow} />
												</SmileyboxAnalyticsProvider>
											</Panel>
											<Panel>
												<SmileyboxAnalyticsProvider context={'Smileybox_Emojis'}>
													<Grid pl={(Spacing * 2 as ThemeOverride)} itemSpacing={Spacing} bottomSpacing={24} itemSize={ItemSize} maxItemsPerRow={maxItemsPerRow} groups={[{
                      label: 'Zuletzt verwendet',
                      items: smileyService.lastUsedEmojis.map(storedEmoji => {
                        const data = smileyService.allEmojis.find(e => e.hexcode === storedEmoji.hexcode);
                        return {
                          ...data,
                          emoji: storedEmoji.emoji,
                          originalEmoji: data.emoji,
                          skins: undefined,
                          lastUsed: true
                        };
                      })
                    }, {
                      label: 'Favoriten',
                      items: smileyService.favoriteEmojiIds.map(hexcode => smileyService.allEmojis.find(e => e.hexcode === hexcode))
                    }, ...emojiGroups]} renderItem={emoji => {
                      return <EmojiItem emoji={emoji} key={emoji.emoji} showFavorite={!('lastUsed' in emoji)} editorRef={editorRef} />;
                    }} />
												</SmileyboxAnalyticsProvider>
											</Panel>
										</PanelsList>
									</Tabs> : <SmileyboxAnalyticsProvider context={'Smileybox_Search'}>
										<SearchResults editorRef={editorRef} query={query} maxItemsPerRow={maxItemsPerRow} />
									</SmileyboxAnalyticsProvider>}
							</React.Suspense>
						</div>
					</FallbackBox>
				</ScrollIndicatorProvider>
			</ContainerContext.Provider>;
});
const _c0 = " Knu-Flex cursor-pointer overflow-hidden borderRadius-minor size-48px placeItems-center ";
const _c1 = " Knu-FlexCol flex-1 ";