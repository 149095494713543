import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $ProfileVisitorsService } from '@knuddelsModules/ProfileVisitors';

@injectable()
export class ProfileVisitorsPanelService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($ProfileVisitorsService)
		private readonly profileVisitorsService: typeof $ProfileVisitorsService.T
	) {}

	public readonly shouldShow = (): boolean => {
		return (
			this.profileVisitorsService.visitors.length > 0 &&
			this.profileVisitorsService.visitorsEnabled
		);
	};
}
