import { transformPropOrder as m } from "../../html/utils/transform.js";
import { number as p } from "../../../value/types/numbers/index.js";
import { px as x } from "../../../value/types/numbers/units.js";
const b = /* @__PURE__ */ new Set([
  "width",
  "height",
  "top",
  "left",
  "right",
  "bottom",
  "x",
  "y",
  "translateX",
  "translateY"
]), d = (t) => t === p || t === x, i = (t, a) => parseFloat(t.split(", ")[a]), l = (t, a) => (r, { transform: o }) => {
  if (o === "none" || !o)
    return 0;
  const s = o.match(/^matrix3d\((.+)\)$/u);
  if (s)
    return i(s[1], a);
  {
    const n = o.match(/^matrix\((.+)\)$/u);
    return n ? i(n[1], t) : 0;
  }
}, c = /* @__PURE__ */ new Set(["x", "y", "z"]), f = m.filter((t) => !c.has(t));
function g(t) {
  const a = [];
  return f.forEach((r) => {
    const o = t.getValue(r);
    o !== void 0 && (a.push([r, o.get()]), o.set(r.startsWith("scale") ? 1 : 0));
  }), a;
}
const e = {
  // Dimensions
  width: ({ x: t }, { paddingLeft: a = "0", paddingRight: r = "0" }) => t.max - t.min - parseFloat(a) - parseFloat(r),
  height: ({ y: t }, { paddingTop: a = "0", paddingBottom: r = "0" }) => t.max - t.min - parseFloat(a) - parseFloat(r),
  top: (t, { top: a }) => parseFloat(a),
  left: (t, { left: a }) => parseFloat(a),
  bottom: ({ y: t }, { top: a }) => parseFloat(a) + (t.max - t.min),
  right: ({ x: t }, { left: a }) => parseFloat(a) + (t.max - t.min),
  // Transform
  x: l(4, 13),
  y: l(5, 14)
};
e.translateX = e.x;
e.translateY = e.y;
export {
  d as isNumOrPxType,
  b as positionalKeys,
  e as positionalValues,
  g as removeNonTranslationalTransform
};
