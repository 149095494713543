import { conversationOverviewNotificationBoxEvent } from '../../analytics';
import { TrackingCallbacks } from '@knuddelsModules/Notifications';

export const notificationBoxTracking: TrackingCallbacks = {
	trackShown(): void {
		conversationOverviewNotificationBoxEvent.track(
			'NotificationrequestBox_Shown'
		);
	},
	trackActiveClicked(): void {
		conversationOverviewNotificationBoxEvent.track(
			'NotificationrequestBox_ActivateClicked'
		);
	},
	trackNotNowClicked(): void {
		conversationOverviewNotificationBoxEvent.track(
			'NotificationrequestBox_NotNowClicked'
		);
	},
	trackRequestGranted(): void {
		conversationOverviewNotificationBoxEvent.track(
			'NotificationrequestBox_RequestGranted'
		);
	},
	trackRequestDenied(): void {
		conversationOverviewNotificationBoxEvent.track(
			'NotificationrequestBox_RequestDenied'
		);
	},
};
