import { $I18n } from '@knuddels-app/i18n';
import { expectUnreachable } from '@knuddels/std';
import { useService } from '@knuddels-app/DependencyInjection';

export type AmountFormat = 'none' | 'millionOnly' | 'full';

export const useFormatAmount = (type: AmountFormat) => {
	const i18n = useService($I18n);

	return (amount: number) => {
		return formatAmount(amount, type, i18n);
	};
};

export function formatAmount(
	amount: number,
	type: AmountFormat,
	i18n: typeof $I18n.T
): string {
	switch (type) {
		case 'none':
			return i18n.formatNumber(amount, { useGrouping: true });
		case 'millionOnly':
			if (amount < 1_000_000) {
				return i18n.formatNumber(amount, { useGrouping: true });
			} else {
				return formatMillion(amount, i18n);
			}
		case 'full':
			if (amount < 10_000) {
				return i18n.formatNumber(amount, { useGrouping: true });
			} else if (amount < 100_000) {
				return formatThousand(amount, 1, i18n);
			} else if (amount < 1_000_000) {
				return formatThousand(amount, 0, i18n);
			} else {
				return formatMillion(amount, i18n);
			}
		default:
			return expectUnreachable(type);
	}
}

function formatMillion(amount: number, i18n: typeof $I18n.T): string {
	const formattedNumber = i18n.formatNumber(amount / 1_000_000, {
		minimumFractionDigits: 1,
		maximumFractionDigits: 1,
		useGrouping: true,
	});

	return formattedNumber + ' M';
}

function formatThousand(
	amount: number,
	fractionDigits: number,
	i18n: typeof $I18n.T
): string {
	const formattedNumber = i18n.formatNumber(amount / 1000, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
		useGrouping: true,
	});

	return formattedNumber + ' k';
}
