import { Endpoint, EndpointUrls } from './Endpoint';

export type KnownEndpointId = keyof typeof KnownEndpoints;
export const KnownEndpoints = translateEndpoints({
	DE: {
		name: 'Deutschland',
		urls: {
			defaultOrigin: 'https://www.knuddels.de',
			profilePic: 'https://cdn.knuddelscom.de/knuddels.de/',
			login: 'https://api-de.knuddels.de/login',
			webSocket: 'wss://k8s.knuddels.de/clientgateway-de',
			channelList: 'https://api-de.knuddels.de/channel-list',
			chatServer: 'https://chat.knuddels.de',
			photoServer: 'https://photo.knuddels.de',
			registration: 'https://www.knuddels.de/registration',
			graphQl: 'https://api-de.knuddels.de/api-gateway/graphql',
			graphQlSubscription:
				'wss://api-de.knuddels.de/api-gateway/subscriptions',
		},
	},
	Dev: {
		name: 'Entwicklung',
		urls: {
			defaultOrigin: 'https://devwww.knuddels.de',
			// use DE images because it uses DE users
			profilePic: 'https://cdn.knuddelscom.de/knuddels.de/',
			login: 'https://api-dev.knuddels.de/login',
			chatServer: 'https://developer.knuddels.de:4337',
			photoServer: 'https://photo.knuddels.de',
			registration: 'https://devwww.knuddels.de/registration',
			graphQl: 'https://api-dev.knuddels.de/api-gateway/graphql',
			graphQlSubscription:
				'wss://api-dev.knuddels.de/api-gateway/subscriptions',
		},
	},
	Test: {
		name: 'Testserver',
		urls: {
			defaultOrigin: 'https://testserver.knuddels.de',
			profilePic: 'https://cdn.knuddelscom.de/knuddels.test/',
			login: 'https://api-test.knuddels.de/login',
			chatServer: 'https://chat.knuddels.de:4720',
			photoServer: 'https://testphoto.knuddels.de',
			registration: 'https://testserver.knuddels.de/registration',
			graphQl: 'https://api-test.knuddels.de/api-gateway/graphql',
			graphQlSubscription:
				'wss://api-test.knuddels.de/api-gateway/subscriptions',
		},
	},
	// is used if selected in android server selection
	// or your location.host starts with `local`
	Local: {
		name: 'Lokaler Server',
		urls: {
			defaultOrigin: 'https://testserver.knuddels.de',
			profilePic: 'https://cdn.knuddelscom.de/knuddels.test/',
			login: 'https://www.dev.local.knuddels.de/login',
			chatServer: 'https://local.knuddels.de:7443',
			photoServer: 'https://www.dev.local.knuddels.de',
			registration: `https://www.dev.local.knuddels.de/registration`,
			graphQl: 'http://localhost:8806/graphql',
			graphQlSubscription: 'ws://localhost:8806/subscriptions',
		},
	},
});
export interface EndpointDescriptor {
	name: string;
	supportEmail?: string;
	urls: EndpointUrls;
}

export type EndpointDescriptorMap = Record<string, EndpointDescriptor>;

function translateEndpoints<TEndpoints extends EndpointDescriptorMap>(
	endpoints: TEndpoints
): { [TId in keyof TEndpoints]: Endpoint } {
	const result: Record<string, Endpoint> = {};
	for (const [id, data] of Object.entries(endpoints)) {
		result[id] = new Endpoint({
			id,
			name: data.name,
			urls: data.urls,
			supportEmail: data.supportEmail || 'community@knuddels.de',
		});
	}
	return result as any;
}

export function idToKnownEndpointId(id: string): keyof typeof KnownEndpoints {
	if (id in KnownEndpoints) {
		return id as any;
	} else {
		throw new Error(`There is no known endpoint with id "${id}"!`);
	}
}
