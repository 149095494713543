import { useCallback } from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import type { AlbumDetailsOverlayProps } from './bundle/components/AlbumDetails/AlbumDetailsOverlay';
import { $ViewService } from '@knuddels-app/layout';
import { albumDetailsViewId } from './ProfileViewProvider';
export const useOpenAlbumDetails = ({
  shouldReplace
}: {
  shouldReplace: boolean;
} = {
  shouldReplace: false
}) => {
  const viewService = useService($ViewService);
  return useCallback((props: AlbumDetailsOverlayProps) => {
    viewService.openView(albumDetailsViewId.with(state => state.showAlbumDetails(props.userId, props.photoId)), shouldReplace ? {
      locationUpdateMode: 'replace'
    } : undefined);
  }, []);
};