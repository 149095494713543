import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import fullApproval from '../assets/sm_abo_15-07_fullapproval.gif';

export function createPicturesShownSnackbar(nick?: string): SnackbarData {
	return {
		type: 'picturesShown' + (nick ? '_' + nick : ''),
		text: () => {
			return nick
				? formatMessage(
						declareFormat({
							id: 'SNACKBAR_PICTURES_SHOWN_TEXT',
							defaultFormat:
								'Pictures from {name} are now shown.',
						}),
						{ name: nick }
					)
				: formatMessage(
						declareFormat({
							id: 'SNACKBAR_PICTURES_SHOWN_TEXT_NO_NICK',
							defaultFormat:
								'Pictures from this user are now shown.',
						})
					);
		},
		subtext: (): string =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_PICTURES_SHOWN_SUBTEXT',
					defaultFormat:
						'Pictures from this user are now shown to you.',
				})
			),
		adornment: fullApproval,
	};
}
