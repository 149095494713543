import { createLightTheme as s } from "./Light.js";
import { toThemeColorMapping as B, WhiteTransparent as r, WhiteSolid as a, Texts as m, BlackSolid as n } from "./colors.js";
import { createCustomTheme as g } from "./createCustomTheme.js";
const o = (t = a, e) => ({
  /* Atoms  */
  contentBarsAtom: t[120],
  contentBgAtom: t["050"],
  inputFieldBgAtom: t["050"],
  hairlineAtom: r[110],
  listHoverAtom: t["050"],
  listActiveAtom: t[110],
  iconDefaultAtom: r[330],
  iconHoverAtom: t[660],
  iconDisabledAtom: r[110],
  iconStaticAtom: r[440],
  /*  Navigation   */
  naviBg: "rgb(34,34,34)",
  /* Bars & Content  */
  titleBarBg: t[120],
  buttonBarBg: t["070"],
  tabBarBg: t[120],
  contentBg: e ?? "rgba(26, 24, 29, 1)",
  contentLightBg: t[120],
  actionIconDefault: r[330],
  actionIconHover: r[660],
  settingsGroupBg: r["080"],
  listGroupBg: r["050"],
  inputBarBg: "rgb(34,34,34)",
  inputBarFieldBg: r[160],
  inputBarFieldBorder: r["080"],
  /*  Elements */
  categoryHeader: r["070"],
  lineDivider: r[120],
  bannerBoxBg: t[110],
  cardBg: t[110],
  /*  Selection Controls    */
  switchSlideBgDefault: t[220],
  /*  Input Fields    */
  inputFieldBg: t["050"],
  inputFieldBorder: r[220],
  inputFieldBorderHover: r[440],
  inputFieldLabel: m.onDark.tertiary,
  /*  Dialogs */
  backgroundScrim: "rgba(40, 34, 45, 0.8)",
  dialogBoxBg: t[110],
  dialogBoxFooterBg: t[110],
  /* Snackbar */
  snackbarBackground: n[660],
  /*  Messenger */
  transparent: "rgba(0,0,0,0)",
  scrollbarThumbColor: r[110],
  skeleton: a["090"],
  /* Authenticity Icon */
  authenticityUnknownBg: r[110],
  authenticityUnknownIcon: n[440]
}), u = (t = 1, e = a, c) => g(s(t), {
  id: "dark",
  colors: {
    basic: {
      ...o(e, c),
      ...B("white-solid", e)
    },
    Messenger: {
      outgoingMessage: "rgba(96, 84, 109, 1.0)",
      incomingMessage: e[140]
    }
  },
  buttons: {
    secondary: {
      colors: {
        background: "transparent",
        hover: r[220],
        disabled: "transparent"
      },
      border: {
        colors: {
          normal: r[220],
          hover: r[440],
          disabled: r[110]
        }
      }
    }
  }
}), l = o(), d = u();
export {
  d as DarkTheme,
  l as DarkThemeDecisions,
  u as createDarkTheme
};
