import { useService } from '@knuddels-app/DependencyInjection';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { IconChevronRight, IconUser, ListGroup } from '@knuddels/component-library';
import { IconSilent } from '@shared/icons/IconSilent';
import { IconSound } from '@shared/icons/IconSound';
import { IconTheme } from '@shared/icons/IconTheme';
import * as React from 'react';
import { SettingsNavigateFunction } from '../../../SettingsRouterConfig';
import { SettingsListItem } from '../SettingsListItem';
import { accountSettingsFormat, contactFilterSettingsFormat, displaySettingsFormat, soundSettingsFormat } from '../formats';
export const GeneralSettings: React.FC<{
  navigate: SettingsNavigateFunction;
  shouldShowAccountSettings: boolean;
}> = observer('GeneralSettings', ({
  shouldShowAccountSettings,
  navigate
}) => {
  const i18n = useService($I18n);
  const track = useLogEvent();
  const openContactFilterSettings = () => {
    navigate('ContactFilterSettings');
    track('Settings_Overview', 'ContactFilter_Clicked');
  };
  const openDisplaySettings = () => {
    navigate('DisplaySettings');
    track('Settings_Overview', 'Display_Clicked');
  };
  const openSoundSettings = () => {
    navigate('SoundSettings');
    track('Settings_Overview', 'Sounds_Clicked');
  };
  const openAccountSettings = () => {
    navigate('AccountSettings');
    track('Settings_Overview', 'AccountAndSecurity_Clicked');
  };
  return <ListGroup title={declareFormat({
    id: 'settings.group.general',
    defaultFormat: 'General'
  }).format(i18n)}>
			{shouldShowAccountSettings && <SettingsListItem iconLeft={<IconUser />} iconRight={<IconChevronRight />} caption={accountSettingsFormat.format(i18n)} onPress={openAccountSettings} />}
			<SettingsListItem iconLeft={<IconSilent />} iconRight={<IconChevronRight />} caption={contactFilterSettingsFormat.format(i18n)} onPress={openContactFilterSettings} />

			{globalEnv.product !== 'stapp-messenger' && <SettingsListItem iconLeft={<IconSound />} iconRight={<IconChevronRight />} caption={soundSettingsFormat.format(i18n)} onPress={openSoundSettings} />}

			{globalEnv.product !== 'stapp-messenger' && <SettingsListItem iconLeft={<IconTheme />} iconRight={<IconChevronRight />} caption={displaySettingsFormat.format(i18n)} onPress={openDisplaySettings} />}
		</ListGroup>;
});