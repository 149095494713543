import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $KnuddelDisplayComponents = newServiceId<
	services.KnuddelDisplayComponents
>('$KnuddelDisplayComponents');

export const $KnuddelDisplayAnimationService = newServiceId<
	services.KnuddelDisplayAnimationService
>('$KnuddelDisplayAnimationService');
