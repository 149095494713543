import { declareFormat } from '@knuddels-app/i18n';

export const CHANNEL_NEW_MESSAGES_MESSAGE = declareFormat({
	id: 'CHANNEL_NEW_MESSAGES_MESSAGE',
	defaultFormat: 'NEW MESSAGES',
});

export const YOU = declareFormat({ id: 'YOU', defaultFormat: 'You' });
export const TO = declareFormat({ id: 'TO', defaultFormat: 'to' });
export const PRIVATE_MESSAGE_TITLE = declareFormat({
	id: 'PRIVATE_MESSAGE_TITLE',
	defaultFormat: '{name} (private)',
});

export const PRIVATE_MESSAGE_TITLE_FROM = declareFormat({
	id: 'PRIVATE_MESSAGE_TITLE_FROM',
	defaultFormat: ' from ',
});
