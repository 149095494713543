import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { ContactsView } from '../components/ContactsView/ContactsView';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import {
	ContactListChanged,
	ContactListType,
	ContactsUserByNick,
	ContactsUserByNickDocument,
	ContactsUserByNickQuery,
	ContactsUserByNickQueryVariables,
	ContactsUserFragment,
	FullConversationWithoutMessagesFragment,
	GetContactsDocument,
	GetContactsQuery,
	GetContactsQueryVariables,
	IgnoreState,
	MessengerConversationVisibility,
	User,
} from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { Disposable, expectUnreachable } from '@knuddels/std';
import {
	ALL_CONTACT_TABS,
	ContactsTab,
	mapServerContactListTab,
} from '../components/ContactsView/ContactTabs';
import { ObservableQuery } from '@apollo/client';
import {
	autorun,
	computed,
	fromPromise,
	observable,
	runInAction,
} from '@knuddels-app/mobx';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $MessengerListService } from '@knuddelsModules/Messenger';
import { ContactsMainView } from '../components/ContactsMainView';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { ContactsUser } from '../ContactsUser';
import { ReceivedFriendRequest } from '../components/FriendRequests/ReceivedFriendRequest';
import { $FriendRequestsService } from '../../providedServices';
import { ContactsVirtualList } from '../components/ContactsView/ContactsVirtualList';
import { $UserOnlineStatusService } from '@knuddelsModules/UserData';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

@injectable()
export class ContactsService {
	readonly MainView: typeof ContactsMainView = ContactsMainView;
	readonly ContactsView: typeof ContactsView = ContactsView;
	readonly ReceivedFriendRequest: typeof ReceivedFriendRequest =
		ReceivedFriendRequest;
	readonly ContactsVirtualList: typeof ContactsVirtualList =
		ContactsVirtualList;

	public readonly dispose = Disposable.fn();

	@observable
	private readonly tabHelpers: {
		[key in Exclude<ContactsTab, 'all'>]?: ContactsTabHelper;
	} = {};

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($ClientSettingsService)
		private readonly clientSettingsService: typeof $ClientSettingsService.T,
		@inject.lazy($MessengerListService)
		getMessengerListService: typeof $MessengerListService.TLazy,
		@inject($AutocompleteProviderService)
		autocompleteProviderService: typeof $AutocompleteProviderService.T,
		@inject($FriendRequestsService)
		friendRequestsService: typeof $FriendRequestsService.T,
		@inject($UserOnlineStatusService)
		private readonly userOnlineStatusService: typeof $UserOnlineStatusService.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		this.dispose.track(
			autocompleteProviderService.registerUserProvider(
				() => this.allContacts
			)
		);

		const createTabHelper = (tab: Exclude<ContactsTab, 'all'>) => {
			switch (tab) {
				case 'latest':
					return new LatestContactsTabHelper(
						authenticatedClientService,
						userOnlineStatusService,
						getMessengerListService
					);
				case 'friends':
					return new FriendsContactsTabHelper(
						authenticatedClientService,
						friendRequestsService,
						userOnlineStatusService
					);
				default:
					return new ContactsTabHelper(
						authenticatedClientService,
						userOnlineStatusService,
						tab,
						tab === 'watchlist' || tab === 'fotomeet'
					);
			}
		};

		// Prefetch contacts (see watchQuery in ContactsTabHelper)
		ALL_CONTACT_TABS.forEach(tab => {
			if (tab !== 'all') {
				this.tabHelpers[tab] = this.dispose.track(createTabHelper(tab));
			}
		});
	}

	@computed
	public get activeTabs(): Exclude<ContactsTab, 'all'>[] {
		return this.clientSettingsService.activeContactListTabs
			.map(it => mapServerContactListTab(it))
			.filter(it => !!it);
	}

	/**
	 * Gets contacts in the given tab. For the 'all' tab, this takes disabled contact types into account.
	 */
	getContactsForTab(tab: ContactsTab): readonly ContactsUser[] {
		if (tab === 'all') {
			return this.getContactsForTabs(this.activeTabs);
		} else {
			return this.tabHelpers[tab].contacts || [];
		}
	}

	@computed
	public get allTabsLoaded(): boolean {
		return Object.keys(this.tabHelpers).every(
			key => this.tabHelpers[key as Exclude<ContactsTab, 'all'>].loaded
		);
	}

	/**
	 * Returns all contacts of the current user. This does not take client settings into account.
	 */
	@computed
	public get allContacts(): readonly ContactsUser[] {
		return this.getContactsForTabs(
			Object.keys(this.tabHelpers) as Exclude<ContactsTab, 'all'>[]
		);
	}

	private getContactsForTabs(
		tabs: Exclude<ContactsTab, 'all'>[]
	): readonly ContactsUser[] {
		const contacts: ContactsUser[] = [];

		tabs.forEach(key => {
			this.tabHelpers[key].contacts
				.filter(it => !contacts.some(contact => contact.id === it.id))
				.forEach(it => contacts.push(it));
		});

		return contacts;
	}

	/**
	 * Fetches contacts for the given tab. Does not take client settings into account.
	 */
	async fetchContacts(tab: ContactsTab): Promise<void> {
		if (tab === 'all') {
			await Promise.all(
				Object.keys(this.tabHelpers).map(key =>
					this.tabHelpers[
						key as Exclude<ContactsTab, 'all'>
					].refetch()
				)
			);
		} else {
			await this.tabHelpers[tab].refetch();
		}
	}

	/**
	 * Gets the user with the given nick (case-insensitive) from the cache.
	 */
	getContactByNick(nick: string): ContactsUser | undefined {
		try {
			return this.authenticatedClientService.currentClient.readQuery<
				ContactsUserByNickQuery,
				ContactsUserByNickQueryVariables
			>({
				query: ContactsUserByNickDocument,
				variables: {
					nick: nick.toLowerCase(),
					pixelDensity: getPixelRatio(),
					accountForNickSwitch: true,
				},
			}).user.userFromNick;
		} catch (e) {
			return undefined;
		}
	}

	/**
	 * Fetches the user with the given nick (case-insensitive).
	 */
	fetchContactByNick = async (nick: string): Promise<void> => {
		await this.authenticatedClientService.currentK3Client.query(
			ContactsUserByNick,
			{
				nick: nick.toLowerCase(),
				pixelDensity: getPixelRatio(),
				accountForNickSwitch: true,
			}
		);
	};

	isUserInActiveContactTypes = (userId: User['id']): boolean => {
		return !!this.contactsOfActiveTabsByUserId[userId];
	};

	@computed
	private get contactsOfActiveTabsByUserId(): {
		[key in User['id']]: ContactsUser;
	} {
		const result: {
			[key in User['id']]: ContactsUser;
		} = {};

		this.getContactsForTab('all').forEach(
			contact => (result[contact.id] = contact)
		);

		return result;
	}
}

function tabToFilterType(tab: ContactsTab): ContactListType | null {
	switch (tab) {
		case 'all':
			return null;
		case 'fotomeet':
			return ContactListType.Fotomeet;
		case 'friends':
			return ContactListType.Friends;
		case 'latest':
			return ContactListType.Latest;
		case 'mentees':
			return ContactListType.Mentee;
		case 'watchlist':
			return ContactListType.Watchlist;
		default:
			expectUnreachable(tab);
	}
}

class ContactsTabHelper implements Disposable {
	public readonly dispose = Disposable.fn();

	private readonly type: ContactListType;
	private readonly watchQuery: ObservableQuery<
		GetContactsQuery,
		GetContactsQueryVariables
	>;

	@observable
	protected _contacts: readonly ContactsUser[] = [];
	@observable
	protected _loaded = false;

	constructor(
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		private readonly userOnlineStatusService: typeof $UserOnlineStatusService.T,
		public readonly tab: Exclude<ContactsTab, 'all'>,
		subscribeToChanges = false
	) {
		this.type = tabToFilterType(tab);
		this.watchQuery = authenticatedClientService.currentClient.watchQuery<
			GetContactsQuery,
			GetContactsQueryVariables
		>({
			query: GetContactsDocument,
			variables: {
				filter: { type: this.type },
				pixelDensity: getPixelRatio(),
			},
		});

		this.dispose.track(
			this.watchQuery.subscribe(data => {
				if (data.data) {
					runInAction('Update contacts for tab', () => {
						this._contacts = data.data.user.contactList.contacts;
					});
				} else if (data.errors) {
					console.error(...data.errors);
				}
			}).unsubscribe
		);

		this.dispose.track(
			autorun({ name: 'creating subscriptions' }, () => {
				if (this.loaded) {
					this.contacts.forEach(c =>
						this.userOnlineStatusService.getUserWithOnlineChannel(
							c.id
						)
					);
				}
			})
		);

		if (subscribeToChanges) {
			this.dispose.track(
				authenticatedClientService.currentK3Client.subscribeToPrimaryData(
					ContactListChanged,
					{
						filter: { type: this.type },
						pixelDensity: getPixelRatio(),
					},
					{
						next: event => {
							switch (event.__typename) {
								case 'ContactAddedEvent':
									this.updateContacts(oldContacts => [
										...oldContacts,
										event.user,
									]);
									break;
								case 'ContactRemovedEvent':
									this.updateContacts(oldContacts =>
										oldContacts.filter(
											contact =>
												contact.id !== event.user.id
										)
									);
									break;
								default:
									break;
							}
						},
					}
				)
			);
		}
	}

	private updateContacts = (
		mapFn: (
			contacts: readonly ContactsUserFragment[]
		) => ContactsUserFragment[]
	) => {
		this.watchQuery.updateQuery(prev => ({
			...prev,
			user: {
				...prev.user,
				contactList: {
					...prev.user.contactList,
					contacts: mapFn(prev.user.contactList.contacts),
				},
			},
		}));
	};

	public get contacts(): readonly ContactsUser[] {
		return this._contacts;
	}

	public get loaded(): boolean {
		return this._loaded;
	}

	public async refetch(): Promise<void> {
		await this.watchQuery.refetch({
			filter: { type: this.type },
			pixelDensity: getPixelRatio(),
		});

		if (!this.loaded) {
			runInAction('Contact for tab loaded', () => (this._loaded = true));
		}
	}
}

class FriendsContactsTabHelper extends ContactsTabHelper {
	constructor(
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		private readonly friendRequestService: typeof $FriendRequestsService.T,
		userOnlineStatusService: typeof $UserOnlineStatusService.T
	) {
		super(
			authenticatedClientService,
			userOnlineStatusService,
			'friends',
			true
		);
	}

	protected onContactAdded = (contact: ContactsUserFragment) => {
		this.friendRequestService.onFriendAdded(contact);
	};
}

class LatestContactsTabHelper extends ContactsTabHelper {
	private messengerListService = fromPromise(this.getMessengerListService());

	constructor(
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		userOnlineStatusService: typeof $UserOnlineStatusService.T,
		private readonly getMessengerListService: typeof $MessengerListService.TLazy
	) {
		super(authenticatedClientService, userOnlineStatusService, 'latest');
	}

	private get messengerConversationsLoaded(): boolean {
		return (
			this.messengerListService.case({
				fulfilled: s =>
					s.messengerOverviewQuery.fetchStatus !==
					'initiallyFetching',
			}) || false
		);
	}

	private get messengerConversations(): readonly FullConversationWithoutMessagesFragment[] {
		return (
			this.messengerListService.case({
				fulfilled: s => s.unarchivedConversations,
			}) || []
		);
	}

	@computed
	public get contacts(): readonly ContactsUser[] {
		const contacts: ContactsUser[] = [...this._contacts];
		this.messengerConversations
			.filter(conv => this.shouldShowConversation(conv))
			.map(conv => {
				conv.otherParticipants.forEach(user => {
					if (!contacts.some(it => it.id === user.id)) {
						contacts.push(user);
					}
				});
			});
		return contacts;
	}

	private shouldShowConversation = (
		conv: FullConversationWithoutMessagesFragment
	) => {
		if (conv.visibility !== MessengerConversationVisibility.Visible) {
			return false;
		}

		if (
			conv.otherParticipants.some(user => {
				return user.ignoreState !== IgnoreState.None;
			})
		) {
			return false;
		}

		return true;
	};

	public get loaded(): boolean {
		return this._loaded && this.messengerConversationsLoaded;
	}
}
// tslint:disable-next-line:max-file-line-count
