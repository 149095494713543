import * as React from 'react';
import { ChannelBackgroundImageInfo, ChannelBackgroundImageMode } from '@generated/graphql';
import { Flex, FlexCol, Image, ImageProps, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { getImageData } from '@shared/components/atoms/Image/getImageData';
import { $MessageLayoutSettingsService } from '@knuddelsModules/Settings';
import { useService } from '@knuddels-app/DependencyInjection/useService';
import { observer } from '@knuddels-app/mobx';
interface ChannelBackgroundProps {
  backgroundColor: string | undefined;
  backgroundImageInfo: ChannelBackgroundImageInfo | undefined;
  children: React.ReactNode;
}
export const ChannelBackgroundColorContext = React.createContext<string>(undefined);
export const ChannelBackground: React.FC<ChannelBackgroundProps> = observer('ChannelBackground', ({
  children,
  backgroundColor,
  backgroundImageInfo
}) => {
  const layoutService = useService($MessageLayoutSettingsService);
  return <div style={{
    background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
  }} className={_c0 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<ChannelBackgroundColorContext.Provider value={backgroundColor}>
					{layoutService.showChannelBackground && backgroundImageInfo && <BackgroundImage key={backgroundImageInfo.url} info={backgroundImageInfo} />}
					{children}
				</ChannelBackgroundColorContext.Provider>
			</div>;
});
const BackgroundImage: React.FC<{
  info: ChannelBackgroundImageInfo;
}> = React.memo(({
  info
}) => {
  const size = useBackgroundImageSize(info);
  return <div className={_c1}>
			{!!size && <Image src={info.url} alt={''} resizeMode={channelBackgroundModeToImageResizeMode[info.mode]} style={{
      width: resolveThemingValue(size.width, "sizes", useTheme()),
      height: resolveThemingValue(size.height, "sizes", useTheme())
    }} className={_c2} />}
		</div>;
});
BackgroundImage.displayName = 'BackgroundImage';
const channelBackgroundModeToImageResizeMode: { [mode in ChannelBackgroundImageMode]: ImageProps['resizeMode'] } = {
  CENTERED: 'contain',
  COVER: 'cover',
  TILED: 'repeat'
};
const useBackgroundImageSize = (info: ChannelBackgroundImageInfo) => {
  const [size, setSize] = React.useState<{
    width: ImageProps['width'];
    height: ImageProps['height'];
  } | null>(info.mode !== ChannelBackgroundImageMode.Centered ? {
    width: 'full',
    height: 'full'
  } : null);
  React.useLayoutEffect(() => {
    if (info.mode !== ChannelBackgroundImageMode.Centered) {
      setSize({
        width: 'full',
        height: 'full'
      });
      return;
    }
    setSize(null);
    getImageData(info.url).then(result => {
      setSize({
        width: result.width,
        height: result.height
      });
    }).catch(() => {
      setSize(null);
    });
  }, [info.mode, info.url]);
  return size;
};
const _c0 = " Knu-Flex position-relative overflow-hidden flexGrow-1 ";
const _c1 = " Knu-FlexCol position-absolute overflow-hidden inset-none placeItems-center ";
const _c2 = " maxWidth-100-percent ";