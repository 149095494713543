import { SearchChannelGroupFragment } from '@generated/graphql';
import * as React from 'react';
import { ChannelGroupExpandedContext } from '../../../../ChannelGroupExpandedContext';
import { CategoryHeaderListItem, ChannelListItem, createChannelGroupVirtualListItem, templateCategoryHeader } from '../../../../shared/ChannelList/ChannelListVirtualItems';
import { formatMessage } from '@knuddels-app/i18n';
import { SEARCH_RESULTS } from '../../../../../i18n/shared-formats';
export const useSearchListData = (groups: readonly SearchChannelGroupFragment[], exactMatch?: SearchChannelGroupFragment) => {
  const {
    isExpanded
  } = React.useContext(ChannelGroupExpandedContext);
  return React.useMemo(() => {
    const channels: ChannelListItem[] = [];
    if (groups.length > 0 || exactMatch) {
      channels.push(createSearchResultHeader());
    }
    if (exactMatch) {
      const userCount = exactMatch.channels.reduce((previousValue, currentValue) => previousValue + currentValue.onlineUserCount, 0);
      channels.push(createChannelGroupVirtualListItem(exactMatch.id, exactMatch, userCount, isExpanded(exactMatch.id), groups.length === 0, 'default'));
    }
    groups.forEach((group, index) => {
      if (exactMatch && exactMatch.id === group.id) {
        return;
      }
      const userCount = group.channels.reduce((previousValue, currentValue) => previousValue + currentValue.onlineUserCount, 0);
      channels.push(createChannelGroupVirtualListItem(group.id, group, userCount, isExpanded(group.id), index === groups.length - 1, 'default'));
    });
    return channels;
  }, [groups, isExpanded, exactMatch]);
};
function createSearchResultHeader(): CategoryHeaderListItem {
  return {
    type: templateCategoryHeader,
    key: `search-results`,
    height: 40,
    title: formatMessage(SEARCH_RESULTS)
  };
}