// spacings
export enum SPACING {
	TINY = 4,
	SMALL = 8,
	MINOR = 12,
	BASE = 16,
	LARGE = 24,
	XLARGE = 32,
	XXLARGE = 48,
	XXXLARGE = 56,
}

export enum SIZING {
	TINY = 4,
	SMALL = 8,
	MINOR = 12,
	BASE = 16,
	LARGE = 24,
	XLARGE = 32,
	XXLARGE = 48,
	XXXLARGE = 56,
}

// pic sizes
export enum PIC_SIZE {
	ICON = 16,
	VERY_SMALL = 24,
	SMALL = 32,
	NORMAL = 48,
	LARGE = 64,
}
