import {
	ConversationWithLatestMessage,
	ConversationWithoutLatestMessage,
} from '../../services/messengerOverviewHelper';
import { ClientConversationState } from '../../services/conversationServices/MessengerConversationService';
import { VirtualListItem } from '@knuddels/component-library';

export const templateConversation = 'conversation';
export const templateAdzone = 'adzone';
export const templateLoadingIndicator = 'loadingIndicator';
export const templateProfileVistors = 'profileVisitors';
export const templateNotificationConfirmBox = 'notificationConfirmBox';

export type ConversationVirtualListViewItemInfo = (VirtualListItem & {
	type: typeof templateConversation;
	isActive: boolean;
	index: number;
	clientConversationState: ClientConversationState;
}) &
	(
		| {
				isInvalidConversation: false;
				conversation: ConversationWithLatestMessage;
		  }
		| {
				isInvalidConversation: true;
				conversation: ConversationWithoutLatestMessage;
		  }
	);

export type AdzoneItemInfo = VirtualListItem & {
	type: typeof templateAdzone;
};

export type LoadingIndicatorItemInfo = VirtualListItem & {
	type: typeof templateLoadingIndicator;
};

export type ProfileVisitorsItemInfo = VirtualListItem & {
	type: typeof templateProfileVistors;
	showTopSpacer: boolean;
};

export type NotificationConfirmBoxItemInfo = VirtualListItem & {
	type: typeof templateNotificationConfirmBox;
};

export type ItemInfos =
	| ConversationVirtualListViewItemInfo
	| NotificationConfirmBoxItemInfo
	| AdzoneItemInfo
	| LoadingIndicatorItemInfo
	| ProfileVisitorsItemInfo;
