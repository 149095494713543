import { jsx as t } from "react/jsx-runtime";
import "./FadingView.scss.js";
import { clsx as a } from "../../node_modules/clsx/dist/clsx.m.js";
const n = 500, r = ({
  delay: i = 0,
  duration: l = n,
  ...e
}) => /* @__PURE__ */ t(
  "div",
  {
    className: a(
      "Knu-FadingView Knu-FlexCol",
      !e.hide && "visible",
      e.className
    ),
    style: {
      "--delay": i + "ms",
      "--duration": l + "ms",
      ...e.style
    },
    children: e.children
  }
);
export {
  r as FadingView
};
