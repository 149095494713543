import * as React from 'react';
import { useRef } from 'react';
import { Flex, toPointerHandler } from '@knuddels/component-library';
import { ScrollContainer, Spacer } from '../../atoms';
import { useClickOutside } from '@knuddels-app/shared-hooks/useClickOutside';
import { CloseSuggestionButton } from './SuggestionButton';
export const SuggestionsContainer: React.FC<{
  children: React.ReactNode;
  onClickOutside?(): void;
  onCloseSuggestions?(): void;
}> = ({
  children,
  onClickOutside,
  onCloseSuggestions
}) => {
  const wrapperRef = useRef<typeof Flex>(null);
  useClickOutside(wrapperRef, onClickOutside);
  return <div onClick={toPointerHandler(onClickOutside)} className={_c0}>
			<div ref={wrapperRef} className={_c1}>
				<ScrollContainer horizontal>
					<div className={_c2}>
						<Spacer size={'auto'} />
						<Spacer size={'small'} />
						{children}

						{onCloseSuggestions && <CloseSuggestionButton onPress={onCloseSuggestions} />}
						<Spacer size={'small'} />
					</div>
				</ScrollContainer>
			</div>
		</div>;
};
const _c0 = " Knu-Flex position-absolute insetX-none bottom-none ";
const _c1 = " Knu-Flex width-full ";
const _c2 = " Knu-Flex pb-small minWidth-100-percent alignItems-flex-end ";