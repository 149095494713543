import * as React from 'react';
import { SmileyDetails, SmileyDetailsProps, withSmileyDetails } from '@generated/graphql';
import { FormattedText, FormattedTextDisplay } from '@shared/components';
import { injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { action, computed, observable } from '@knuddels-app/mobx';
import { SmileyId } from '../services';
import { XOR } from '@knuddels-app/tools/types';
import { smileyDimensions, smileyHoverPadding } from './listConstants';
import { FlexCol, isTouchDevice, PointerEvent, rgb, Text, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, FallbackBox, resolveIsDarkColor } from '@knuddels/component-library';
interface OwnProps {
  id: SmileyId;
  fetchPolicy?: 'cache-only' | 'cache-first';
  onClick(smiley: SmileyDetails): void;
}
type Props = XOR<SmileyDetailsProps<OwnProps & {
  skeleton: false;
}>, {
  skeleton: true;
}>;
@injectable()
class SmileyCellModel {
  @observable
  offsetHorizontal = 0;
  @observable
  offsetVertical = 0;
  constructor(@injectProps()
  private readonly props: Props) {}
  @computed
  get smiley(): SmileyDetails | undefined {
    if (this.props.skeleton || !this.props.data || !this.props.data.smileybox) {
      return undefined;
    }
    return this.props.data.smileybox.smileyDetails;
  }
  @computed
  get smileyImage(): FormattedText | undefined {
    if (!this.smiley) {
      return undefined;
    }
    const image = this.smiley.image;
    try {
      return FormattedText.fromJsonString(image);
    } catch {
      console.error(`Illegal smiley image value ${image}`);
      return undefined;
    }
  }
  @computed
  get smileyText(): string | undefined {
    return this.smiley && this.smiley.textRepresentation;
  }
  @computed
  get isLoading(): boolean {
    return this.props.skeleton || !this.props.data || this.props.data.loading;
  }
  @computed
  get isError(): boolean {
    return !this.isLoading && !!this.props.data?.error;
  }
  @action.bound
  updateFormattedTextDimensions(width: number, height: number): void {
    const maxWidth = Math.max(width, smileyDimensions.width) + smileyHoverPadding;
    const maxHeight = Math.max(height, smileyDimensions.height) + smileyHoverPadding;
    this.offsetVertical = (smileyDimensions.height - maxHeight) / 2;
    this.offsetHorizontal = (smileyDimensions.width - maxWidth) / 2;
  }
  handleClick = (e: PointerEvent): void => {
    if (!this.smiley) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick?.(this.smiley);
  };
}

/**
 * To render the smileys correctly, the container has to be as large as the smiley
 * itself or larger. To ensure that, we have the outer Touchable container that has
 * a fixed size. Inside of it is the Shadow container. On web, it enlarges on hover
 * to show the full smiley. At last there is the inner RX.View wrapper that ensures
 * that the smiley is never in a container smaller than its own dimensions.
 * So if a smiley is larger thean the Touchable container, it still is rendered
 * correctly when in non-hovered state and on native.
 */
const SmileyCellContent = injectedComponent({
  model: SmileyCellModel,
  name: 'SmileyCellContent'
}, ({
  model
}) => {
  const {
    offsetHorizontal,
    offsetVertical
  } = model;
  const [hovered, setHovered] = React.useState(false);
  const outerOffsetVertical = hovered ? offsetVertical : 0;
  const outerOffsetHorizontal = hovered ? offsetHorizontal : 0;

  // Needs to be constant and bigger than any smiley, or else
  // onLayout may constantly be triggered on native
  const innerOffset = -200;
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  React.useEffect(() => {
    if (width !== 0 && height !== 0) {
      model.updateFormattedTextDimensions(width, height);
    } else {
      model.updateFormattedTextDimensions(Math.max(width, smileyDimensions.width), Math.max(height, smileyDimensions.height));
    }
  }, [width, height, model]);
  return <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={toPointerHandler(isTouchDevice() ? model.handleClick : undefined)} onPointerDown={toPointerHandler(isTouchDevice() ? undefined : model.handleClick)} style={{
    width: resolveThemingValue(smileyDimensions.width, "sizes", useTheme()),
    height: resolveThemingValue(smileyDimensions.height, "sizes", useTheme())
  }} className={_c0}>
				<div style={{
      left: resolveThemingValue((outerOffsetHorizontal as ThemeOverride), "spacing", useTheme()),
      right: resolveThemingValue((outerOffsetHorizontal as ThemeOverride), "spacing", useTheme()),
      top: resolveThemingValue((outerOffsetVertical as ThemeOverride), "spacing", useTheme()),
      bottom: resolveThemingValue((outerOffsetVertical as ThemeOverride), "spacing", useTheme()),
      background: resolveThemingValue(model.smiley ? 'contentLightBg' : rgb(221, 221, 211), "colors", useTheme()),
      borderColor: resolveThemingValue(hovered ? 'black-transparent-110' : rgb(0, 0, 0, 0.06), "colors", useTheme())
    }} className={_c1 + ((model.smiley ? 'contentLightBg' : rgb(221, 221, 211)) ? resolveIsDarkColor(model.smiley ? 'contentLightBg' : rgb(221, 221, 211), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (hovered ? _c2 : _c3) + (hovered ? _c4 : _c5)}>
					{!model.isLoading && !model.isError ? model.smileyImage ? <div style={{
        left: resolveThemingValue((innerOffset as ThemeOverride), "spacing", useTheme()),
        right: resolveThemingValue((innerOffset as ThemeOverride), "spacing", useTheme()),
        minHeight: resolveThemingValue(smileyDimensions.width, "sizes", useTheme()),
        minWidth: resolveThemingValue(smileyDimensions.height, "sizes", useTheme())
      }} className={_c6 + (hovered ? _c7 : _c8)}>
								<FallbackBox onLayout={e => setSize(e)} className={_c9}>
									<FormattedTextDisplay text={model.smileyImage} textProps={{}} />
								</FallbackBox>
							</div> : <Text className={_c10}>{model.smileyText}</Text> : <Text className={_c11}>{model.isError ? '!' : '...'}</Text>}
				</div>
			</div>;
});
export const SmileyCell = withSmileyDetails<OwnProps>({
  options: ({
    id,
    fetchPolicy
  }) => ({
    variables: {
      id
    },
    fetchPolicy: fetchPolicy ?? 'cache-first'
  })
})(props => <SmileyCellContent {...props} skeleton={false} />);
export function SmileyCellSkeleton(): React.ReactElement {
  return <SmileyCellContent skeleton />;
}
const _c0 = " Knu-FlexCol position-relative borderRadius-5px flexShrink-0 cursor-pointer ";
const _c1 = " Knu-FlexCol overflow-hidden placeItems-center position-absolute borderRadius-5px ";
const _c2 = " shadow-Shadow2 ";
const _c3 = " shadow-none ";
const _c4 = " zIndex-999 ";
const _c5 = " zIndex-1 ";
const _c6 = " Knu-FlexCol position-absolute placeItems-center ";
const _c7 = " overflow-visible ";
const _c8 = " overflow-hidden ";
const _c9 = " Knu-FlexCol position-relative overflow-visible ";
const _c10 = "  ";
const _c11 = "  ";