import * as React from 'react';
import { ModalContentWrapper } from './ModalContentWrapper';
import { ShrinkStyle } from './Modal_styles';
import { ModalButtonsProps, ModalButtons } from './ModalButtons';
import { BackgroundContainerProps, ContentBox, ScrollContainer, Column } from '../../../atoms';
import { CSSProperties } from 'react';
import { LineDivider } from '@knuddels/component-library';
export interface ScrollableModalCardProps extends ModalButtonsProps {
  background?: BackgroundContainerProps;
  alignCenter?: boolean;
}
export class ScrollableModalCard extends React.PureComponent<ScrollableModalCardProps> {
  render(): JSX.Element {
    const {
      background,
      alignCenter,
      ...buttonsProps
    } = this.props;
    return <ContentBox style={boxStyle}>
				<Column {...ShrinkStyle} {...background}>
					<ScrollContainer {...ShrinkStyle}>
						<ModalContentWrapper alignCenter={alignCenter}>
							{this.props.children}
						</ModalContentWrapper>
					</ScrollContainer>
				</Column>
				<LineDivider className={_c0} />
				<ModalButtons {...buttonsProps} />
			</ContentBox>;
  }
}

// actually shouldn't use CSSProperties as types but we don't have any fitting
const boxStyle: CSSProperties = {
  maxWidth: 480,
  margin: 24,
  flexShrink: 1
};
const _c0 = "  ";