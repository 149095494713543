import * as React from 'react';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { StarredMessagesConversationMessageFragment } from '@generated/graphql';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { IconStarOff } from '@shared/icons/IconStarOff';
import { $I18n } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $StarMessageService } from '@knuddelsModules/Messenger';
import { runInAction } from '@knuddels-app/mobx';
import { UNSTAR_MESSAGE } from '../../i18n/shared-formats';
export const StarredMessageContextMenu: React.FC<{
  message: StarredMessagesConversationMessageFragment;
} & ContextMenuProps> = ({
  message,
  ...otherProps
}) => {
  const i18n = useService($I18n);
  const starMessageService = useService($StarMessageService);
  const thisVisibleOverlay = useService($ThisVisibleOverlay);
  const closeContextMenu = React.useCallback(() => {
    runInAction('closeContextMenu', () => {
      thisVisibleOverlay.dispose();
    });
  }, [thisVisibleOverlay]);
  const unstarMessage = () => {
    starMessageService.unStarMessage(message.id);
    closeContextMenu();
  };
  return <ContextMenu {...otherProps} closeContextMenu={closeContextMenu}>
			<ContextMenuEntry icon={IconStarOff} text={UNSTAR_MESSAGE.format(i18n)} onClick={unstarMessage} testId={'star-message'} />
		</ContextMenu>;
};
export const StarredMessageContextMenuOverlay = new OverlayFactory(StarredMessageContextMenu, props => ({
  messageId: props.message.id
}));