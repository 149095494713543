import * as React from 'react';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { FlexCol, SwitchListItem, Text, ListGroup } from '@knuddels/component-library';
import { ChangeNicknameChangeOptions, NicknameChangeOptions, PrivacySettings } from '@generated/graphql';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { PrivacySettingsProps } from './privacySettings.props';
import { useMutation } from '@knuddels-app/Connection';
import { useFailedSaveSnackbar } from './usePrivacySettingState';
import { getDaysFromNow } from '@shared/components';
const AVAILABLE_UNTIL_TEXT = declareFormat({
  id: 'settings.privacy.changeNickname.options.additionalInfo',
  defaultFormat: '{days, plural, one {# day} other {# days}} left'
});
function areAllSettingsDisabled(settings: NicknameChangeOptions): boolean {
  return !settings.showOnTopOfNickList.toggleable && !settings.specialGreeting.toggleable && !settings.showNickListIcon.toggleable && !settings.showNickListEffect.toggleable;
}
export const ChangeNicknameSettings: React.FC<PrivacySettingsProps> = ({
  data,
  updateData,
  nickSwitchEnabled
}) => {
  const i18n = useService($I18n);
  const {
    nicknameChangeOptions: settings
  } = data;
  const onSettingChange = useOnSettingsChange(settings, updateData);
  if (!nickSwitchEnabled || areAllSettingsDisabled(settings)) {
    return null;
  }
  return <ListGroup title={i18n.format(declareFormat({
    id: 'settings.privacy.changeNickname.title',
    defaultFormat: 'Nickname renaming'
  }))}>
			<Text type={'subtitle'} bold={true} className={_c0}>
				{i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.options.label',
        defaultFormat: 'How do you want to celebrate your renaming?'
      }))}
			</Text>

			<div className={_c1}>
				{settings.showOnTopOfNickList.toggleable && <SwitchListItem caption={i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.options.showOnTopOfNickList',
        defaultFormat: 'Show me at the top of the nicklist'
      }))} additionalInfo={i18n.format(AVAILABLE_UNTIL_TEXT, {
        days: getDaysFromNow(settings.showOnTopOfNickList.availableUntil)
      })} checked={settings.showOnTopOfNickList.checked} onChange={value => onSettingChange('showOnTopOfNickList', value)} />}

				{settings.showNickListIcon.toggleable && <SwitchListItem caption={i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.options.showNickListIcon',
        defaultFormat: 'Special nicklist icon'
      }))} additionalInfo={i18n.format(AVAILABLE_UNTIL_TEXT, {
        days: getDaysFromNow(settings.showNickListIcon.availableUntil)
      })} checked={settings.showNickListIcon.checked} onChange={value => onSettingChange('showNickListIcon', value)} />}

				{settings.specialGreeting.toggleable && <SwitchListItem caption={i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.options.specialGreeting',
        defaultFormat: 'Personal welcome from James in the channel'
      }))} additionalInfo={i18n.format(AVAILABLE_UNTIL_TEXT, {
        days: getDaysFromNow(settings.specialGreeting.availableUntil)
      })} checked={settings.specialGreeting.checked} onChange={value => onSettingChange('specialGreeting', value)} />}

				{settings.showNickListEffect.toggleable && <SwitchListItem caption={i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.options.showNickListEffect',
        defaultFormat: 'Nicklist Effect - Desktop App Only'
      }), {
        days: getDaysFromNow(settings.showNickListEffect.availableUntil)
      })} additionalInfo={i18n.format(AVAILABLE_UNTIL_TEXT, {
        days: getDaysFromNow(settings.showNickListEffect.availableUntil)
      })} checked={settings.showNickListEffect.checked} onChange={value => onSettingChange('showNickListEffect', value)} />}
			</div>
		</ListGroup>;
};
const useOnSettingsChange = (settings: NicknameChangeOptions, updateData: (newData: Partial<PrivacySettings>) => {
  revertUpdate: () => void;
}) => {
  const track = useLogEvent();
  const showErrorSnackbar = useFailedSaveSnackbar();
  const [changeNicknameChangeOptions] = useMutation(ChangeNicknameChangeOptions);
  const onSettingChange = (settingName: keyof Omit<NicknameChangeOptions, '__typename'>, value: boolean) => {
    if (value === settings[settingName].checked) {
      // if value didn't change, do nothing.
      return;
    }
    track('Settings_Privacy', `ChangeNickname_${settingName}_${value ? 'Activate' : 'Deactivate'}`);
    const {
      revertUpdate
    } = updateData({
      nicknameChangeOptions: {
        ...settings,
        [settingName]: {
          ...settings[settingName],
          checked: value
        }
      }
    });
    changeNicknameChangeOptions({
      change: {
        [settingName]: value
      }
    }).then(result => {
      if (result.data.__typename !== 'Success') {
        revertUpdate();
        showErrorSnackbar();
      }
    });
  };
  return onSettingChange;
};
const _c0 = " mx-base my-minor ";
const _c1 = " Knu-FlexCol ";