import { Disposable, startTimeout } from '@knuddels/std';

type DelayAction = (action: () => void, key: number) => Disposable;

export class WaitingOrderingQueue<T> {
	private lastProcessedKey: number | undefined = undefined;
	private readonly queue: QueueEntry<T>[] = [];

	private readonly delayAction: DelayAction;

	constructor(
		private readonly orderingKeyExtractor: (el: T) => number,
		delayMsOrDelayAction: DelayAction | number
	) {
		this.delayAction =
			typeof delayMsOrDelayAction === 'number'
				? action => startTimeout(delayMsOrDelayAction, action)
				: delayMsOrDelayAction;
	}

	insert = (
		el: T,
		processElement: (inOrder: boolean, el: T) => void
	): void => {
		const key = this.orderingKeyExtractor(el);

		const entry: QueueEntry<T> = {
			processElement,
			element: el,
			key,
			delayedAction: Disposable.create(),
		};

		this.queue.push(entry);

		if (
			this.lastProcessedKey !== undefined &&
			(key === this.lastProcessedKey + 1 || key < this.lastProcessedKey) // in order || out of order
		) {
			this.process(entry);
		} else {
			// Element is in order but there could be another element before this (e.g. receiving 1, 3 then 2)
			// => wait some time for the previous element to arrive
			entry.delayedAction = this.delayAction(() => {
				this.process(entry);
			}, key);
		}
	};

	private process = (entry: QueueEntry<T>): void => {
		const entriesToProcess = [...this.queue.sort((a, b) => a.key - b.key)];

		let prevKey: number | undefined = undefined;
		for (const entryToProcess of entriesToProcess) {
			if (entryToProcess.key <= entry.key) {
				this.doProcessElement(entryToProcess);
			} else if (
				prevKey !== undefined &&
				entryToProcess.key === prevKey + 1
			) {
				this.doProcessElement(entryToProcess);
			} else {
				break;
			}

			prevKey = entryToProcess.key;
		}
	};

	private doProcessElement = (entry: QueueEntry<T>): void => {
		const index = this.queue.indexOf(entry);
		if (index !== -1) {
			this.queue.splice(index, 1);
			entry.delayedAction.dispose();
		}

		const isInOrder =
			this.lastProcessedKey === undefined ||
			entry.key > this.lastProcessedKey;
		if (isInOrder) {
			this.lastProcessedKey = entry.key;
		}

		entry.processElement(isInOrder, entry.element);
	};
}

type QueueEntry<T> = {
	element: T;
	key: number;
	delayedAction: Disposable;
	processElement: (inOrder: boolean, el: T) => void;
};
