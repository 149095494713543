import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { MESSAGE_BUBBLE_PADDING_HORIZONTAL } from '@shared/components';
import { FlexCol, ThemeOverride, resolveThemingValue, useTheme, toPointerHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { IconReply } from '@shared/icons/IconReply';
export const ANSWER_BUTTON_SIZE = 32;
export const AnswerButtonWrapper: React.FC<{
  onButtonPress: () => void;
  color?: string;
}> = ({
  children,
  onButtonPress,
  color
}) => {
  return <div style={{
    paddingRight: resolveThemingValue((ANSWER_BUTTON_SIZE / 2 as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			{children}
			<AnswerButton onClick={onButtonPress} color={color} />
		</div>;
};
const AnswerButton = observer('AnswerButton', (props: {
  onClick: () => void;
  color: string;
}): JSX.Element => {
  const [hovered, setHovered] = React.useState(false);
  return <div onClick={toPointerHandler(props.onClick)} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{
    right: resolveThemingValue((-(ANSWER_BUTTON_SIZE / 2 + MESSAGE_BUBBLE_PADDING_HORIZONTAL) as ThemeOverride), "spacing", useTheme())
  }} className={_c1}>
				<div style={{
      minWidth: resolveThemingValue(ANSWER_BUTTON_SIZE, "sizes", useTheme()),
      minHeight: resolveThemingValue(ANSWER_BUTTON_SIZE, "sizes", useTheme()),
      background: resolveThemingValue((props.color as ThemeOverride), "colors", useTheme())
    }} className={_c2 + ((props.color as ThemeOverride) ? resolveIsDarkColor((props.color as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (hovered ? _c3 : _c4)}>
					<IconReply color={'white-solid-white'} />
				</div>
			</div>;
});
const _c0 = " Knu-FlexCol overflow-visible position-relative ";
const _c1 = " Knu-FlexCol overflow-visible position-absolute insetY-none placeItems-center cursor-pointer ";
const _c2 = " Knu-FlexCol borderRadius-circle borderWidth-large borderStyle-solid placeItems-center ";
const _c3 = " borderColor-white-transparent-880 ";
const _c4 = " borderColor-white-transparent-440 ";