import * as React from 'react';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PlaceholderContent } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
export const NotAvailablePlaceholder: React.FC = () => {
  const i18n = useService($I18n);
  return <PlaceholderContent image={require('@shared/images/sorry.gif')} imageWidth={35} imageHeight={27} title={declareFormat({
    id: 'profile.albums.not-available.title',
    defaultFormat: 'Oops - there was an error'
  }).format(i18n)} description={<FormattedMessage id={declareFormat({
    id: 'profile.albums.not-available.text',
    defaultFormat: 'Try again later!'
  })} />} />;
};