import { isMotionValue as n } from "../../../value/utils/is-motion-value.js";
import { scrapeMotionValuesFromProps as i } from "../../html/utils/scrape-motion-values.js";
import { transformPropOrder as m } from "../../html/utils/transform.js";
function l(r, t, s) {
  const e = i(r, t, s);
  for (const o in r)
    if (n(r[o]) || n(t[o])) {
      const a = m.indexOf(o) !== -1 ? "attr" + o.charAt(0).toUpperCase() + o.substring(1) : o;
      e[a] = r[o];
    }
  return e;
}
export {
  l as scrapeMotionValuesFromProps
};
