import S from "react";
import Dn from "react-dom";
const de = 0, Bt = 1, Dt = 2, un = 4;
function an(t, e) {
  return (n) => t(e(n));
}
function $n(t, e) {
  return e(t);
}
function fn(t, e) {
  return (n) => t(e, n);
}
function De(t, e) {
  return () => t(e);
}
function me(t, e) {
  return e(t), t;
}
function J(...t) {
  return t;
}
function Un(t) {
  t();
}
function $e(t) {
  return () => t;
}
function jn(...t) {
  return () => {
    t.map(Un);
  };
}
function He(t) {
  return t !== void 0;
}
function $t() {
}
function $(t, e) {
  return t(Bt, e);
}
function _(t, e) {
  t(de, e);
}
function Be(t) {
  t(Dt);
}
function it(t) {
  return t(un);
}
function O(t, e) {
  return $(t, fn(e, de));
}
function Ct(t, e) {
  const n = t(Bt, (o) => {
    n(), e(o);
  });
  return n;
}
function W() {
  const t = [];
  return (e, n) => {
    switch (e) {
      case Dt:
        t.splice(0, t.length);
        return;
      case Bt:
        return t.push(n), () => {
          const o = t.indexOf(n);
          o > -1 && t.splice(o, 1);
        };
      case de:
        t.slice().forEach((o) => {
          o(n);
        });
        return;
      default:
        throw new Error(`unrecognized action ${e}`);
    }
  };
}
function C(t) {
  let e = t;
  const n = W();
  return (o, r) => {
    switch (o) {
      case Bt:
        r(e);
        break;
      case de:
        e = r;
        break;
      case un:
        return e;
    }
    return n(o, r);
  };
}
function Kn(t) {
  let e, n;
  const o = () => e && e();
  return function(r, s) {
    switch (r) {
      case Bt:
        return s ? n === s ? void 0 : (o(), n = s, e = $(t, s), e) : (o(), $t);
      case Dt:
        o(), n = null;
        return;
      default:
        throw new Error(`unrecognized action ${r}`);
    }
  };
}
function ft(t) {
  return me(W(), (e) => O(t, e));
}
function ct(t, e) {
  return me(C(e), (n) => O(t, n));
}
function qn(...t) {
  return (e) => t.reduceRight($n, e);
}
function g(t, ...e) {
  const n = qn(...e);
  return (o, r) => {
    switch (o) {
      case Bt:
        return $(t, n(r));
      case Dt:
        Be(t);
        return;
    }
  };
}
function dn(t, e) {
  return t === e;
}
function Z(t = dn) {
  let e;
  return (n) => (o) => {
    t(e, o) || (e = o, n(o));
  };
}
function k(t) {
  return (e) => (n) => {
    t(n) && e(n);
  };
}
function b(t) {
  return (e) => an(e, t);
}
function wt(t) {
  return (e) => () => e(t);
}
function vt(t, e) {
  return (n) => (o) => n(e = t(e, o));
}
function Wt(t) {
  return (e) => (n) => {
    t > 0 ? t-- : e(n);
  };
}
function bt(t) {
  let e = null, n;
  return (o) => (r) => {
    e = r, !n && (n = setTimeout(() => {
      n = void 0, o(e);
    }, t));
  };
}
function Ue(t) {
  let e, n;
  return (o) => (r) => {
    e = r, n && clearTimeout(n), n = setTimeout(() => {
      o(e);
    }, t);
  };
}
function G(...t) {
  const e = new Array(t.length);
  let n = 0, o = null;
  const r = Math.pow(2, t.length) - 1;
  return t.forEach((s, i) => {
    const l = Math.pow(2, i);
    $(s, (c) => {
      const f = n;
      n = n | l, e[i] = c, f !== r && n === r && o && (o(), o = null);
    });
  }), (s) => (i) => {
    const l = () => s([i].concat(e));
    n === r ? l() : o = l;
  };
}
function je(...t) {
  return function(e, n) {
    switch (e) {
      case Bt:
        return jn(...t.map((o) => $(o, n)));
      case Dt:
        return;
      default:
        throw new Error(`unrecognized action ${e}`);
    }
  };
}
function P(t, e = dn) {
  return g(t, Z(e));
}
function nt(...t) {
  const e = W(), n = new Array(t.length);
  let o = 0;
  const r = Math.pow(2, t.length) - 1;
  return t.forEach((s, i) => {
    const l = Math.pow(2, i);
    $(s, (c) => {
      n[i] = c, o = o | l, o === r && _(e, n);
    });
  }), function(s, i) {
    switch (s) {
      case Bt:
        return o === r && i(n), $(e, i);
      case Dt:
        return Be(e);
      default:
        throw new Error(`unrecognized action ${s}`);
    }
  };
}
function U(t, e = [], { singleton: n } = { singleton: !0 }) {
  return {
    id: Yn(),
    constructor: t,
    dependencies: e,
    singleton: n
  };
}
const Yn = () => Symbol();
function Zn(t) {
  const e = /* @__PURE__ */ new Map(), n = ({ id: o, constructor: r, dependencies: s, singleton: i }) => {
    if (i && e.has(o))
      return e.get(o);
    const l = r(s.map((c) => n(c)));
    return i && e.set(o, l), l;
  };
  return n(t);
}
function Jn(t, e) {
  const n = {}, o = {};
  let r = 0;
  const s = t.length;
  for (; r < s; )
    o[t[r]] = 1, r += 1;
  for (const i in e)
    o.hasOwnProperty(i) || (n[i] = e[i]);
  return n;
}
const re = typeof document < "u" ? S.useLayoutEffect : S.useEffect;
function mn(t, e, n) {
  const o = Object.keys(e.required || {}), r = Object.keys(e.optional || {}), s = Object.keys(e.methods || {}), i = Object.keys(e.events || {}), l = S.createContext({});
  function c(d, h) {
    d.propsReady && _(d.propsReady, !1);
    for (const I of o) {
      const y = d[e.required[I]];
      _(y, h[I]);
    }
    for (const I of r)
      if (I in h) {
        const y = d[e.optional[I]];
        _(y, h[I]);
      }
    d.propsReady && _(d.propsReady, !0);
  }
  function f(d) {
    return s.reduce((h, I) => (h[I] = (y) => {
      const m = d[e.methods[I]];
      _(m, y);
    }, h), {});
  }
  function a(d) {
    return i.reduce((h, I) => (h[I] = Kn(d[e.events[I]]), h), {});
  }
  const p = S.forwardRef((d, h) => {
    const { children: I, ...y } = d, [m] = S.useState(() => me(Zn(t), (v) => c(v, y))), [u] = S.useState(De(a, m));
    return re(() => {
      for (const v of i)
        v in y && $(u[v], y[v]);
      return () => {
        Object.values(u).map(Be);
      };
    }, [y, u, m]), re(() => {
      c(m, y);
    }), S.useImperativeHandle(h, $e(f(m))), S.createElement(
      l.Provider,
      { value: m },
      n ? S.createElement(
        n,
        Jn([...o, ...r, ...i], y),
        I
      ) : I
    );
  }), x = (d) => S.useCallback(fn(_, S.useContext(l)[d]), [d]), E = (d) => {
    const I = S.useContext(l)[d], y = S.useCallback(
      (m) => $(I, m),
      [I]
    );
    return S.useSyncExternalStore(
      y,
      () => it(I),
      () => it(I)
    );
  }, R = (d) => {
    const I = S.useContext(l)[d], [y, m] = S.useState(De(it, I));
    return re(
      () => $(I, (u) => {
        u !== y && m($e(u));
      }),
      [I, y]
    ), y;
  }, T = S.version.startsWith("18") ? E : R;
  return {
    Component: p,
    usePublisher: x,
    useEmitterValue: T,
    useEmitter: (d, h) => {
      const y = S.useContext(l)[d];
      re(() => $(y, h), [h, y]);
    }
  };
}
const Qn = typeof document < "u" ? S.useLayoutEffect : S.useEffect, Xn = Qn;
var dt = /* @__PURE__ */ ((t) => (t[t.DEBUG = 0] = "DEBUG", t[t.INFO = 1] = "INFO", t[t.WARN = 2] = "WARN", t[t.ERROR = 3] = "ERROR", t))(dt || {});
const to = {
  0: "debug",
  1: "log",
  2: "warn",
  3: "error"
}, eo = () => typeof globalThis > "u" ? window : globalThis, Ot = U(
  () => {
    const t = C(
      3
      /* ERROR */
    );
    return {
      log: C((n, o, r = 1) => {
        var s;
        const i = (s = eo().VIRTUOSO_LOG_LEVEL) != null ? s : it(t);
        r >= i && console[to[r]](
          "%creact-virtuoso: %c%s %o",
          "color: #0253b3; font-weight: bold",
          "color: initial",
          n,
          o
        );
      }),
      logLevel: t
    };
  },
  [],
  { singleton: !0 }
);
function Oe(t, e = !0) {
  const n = S.useRef(null);
  let o = (r) => {
  };
  if (typeof ResizeObserver < "u") {
    const r = S.useMemo(() => new ResizeObserver((s) => {
      requestAnimationFrame(() => {
        const i = s[0].target;
        i.offsetParent !== null && t(i);
      });
    }), [t]);
    o = (s) => {
      s && e ? (r.observe(s), n.current = s) : (n.current && r.unobserve(n.current), n.current = null);
    };
  }
  return { ref: n, callbackRef: o };
}
function _t(t, e = !0) {
  return Oe(t, e).callbackRef;
}
function no(t, e, n, o, r, s, i) {
  const l = S.useCallback(
    (c) => {
      const f = oo(c.children, e, "offsetHeight", r);
      let a = c.parentElement;
      for (; !a.dataset.virtuosoScroller; )
        a = a.parentElement;
      const p = a.lastElementChild.dataset.viewportType === "window", x = i ? i.scrollTop : p ? window.pageYOffset || document.documentElement.scrollTop : a.scrollTop, E = i ? i.scrollHeight : p ? document.documentElement.scrollHeight : a.scrollHeight, R = i ? i.offsetHeight : p ? window.innerHeight : a.offsetHeight;
      o({
        scrollTop: Math.max(x, 0),
        scrollHeight: E,
        viewportHeight: R
      }), s == null || s(ro("row-gap", getComputedStyle(c).rowGap, r)), f !== null && t(f);
    },
    [t, e, r, s, i, o]
  );
  return Oe(l, n);
}
function oo(t, e, n, o) {
  const r = t.length;
  if (r === 0)
    return null;
  const s = [];
  for (let i = 0; i < r; i++) {
    const l = t.item(i);
    if (!l || l.dataset.index === void 0)
      continue;
    const c = parseInt(l.dataset.index), f = parseFloat(l.dataset.knownSize), a = e(l, n);
    if (a === 0 && o("Zero-sized element, this should not happen", { child: l }, dt.ERROR), a === f)
      continue;
    const p = s[s.length - 1];
    s.length === 0 || p.size !== a || p.endIndex !== c - 1 ? s.push({ startIndex: c, endIndex: c, size: a }) : s[s.length - 1].endIndex++;
  }
  return s;
}
function ro(t, e, n) {
  return e !== "normal" && !(e != null && e.endsWith("px")) && n(`${t} was not resolved to pixel value correctly`, e, dt.WARN), e === "normal" ? 0 : parseInt(e ?? "0", 10);
}
function Ht(t, e) {
  return Math.round(t.getBoundingClientRect()[e]);
}
function hn(t, e) {
  return Math.abs(t - e) < 1.01;
}
function pn(t, e, n, o = $t, r) {
  const s = S.useRef(null), i = S.useRef(null), l = S.useRef(null), c = S.useCallback(
    (p) => {
      const x = p.target, E = x === window || x === document, R = E ? window.pageYOffset || document.documentElement.scrollTop : x.scrollTop, T = E ? document.documentElement.scrollHeight : x.scrollHeight, H = E ? window.innerHeight : x.offsetHeight, d = () => {
        t({
          scrollTop: Math.max(R, 0),
          scrollHeight: T,
          viewportHeight: H
        });
      };
      p.suppressFlushSync ? d() : Dn.flushSync(d), i.current !== null && (R === i.current || R <= 0 || R === T - H) && (i.current = null, e(!0), l.current && (clearTimeout(l.current), l.current = null));
    },
    [t, e]
  );
  S.useEffect(() => {
    const p = r || s.current;
    return o(r || s.current), c({ target: p, suppressFlushSync: !0 }), p.addEventListener("scroll", c, { passive: !0 }), () => {
      o(null), p.removeEventListener("scroll", c);
    };
  }, [s, c, n, o, r]);
  function f(p) {
    const x = s.current;
    if (!x || "offsetHeight" in x && x.offsetHeight === 0)
      return;
    const E = p.behavior === "smooth";
    let R, T, H;
    x === window ? (T = Math.max(Ht(document.documentElement, "height"), document.documentElement.scrollHeight), R = window.innerHeight, H = document.documentElement.scrollTop) : (T = x.scrollHeight, R = Ht(x, "height"), H = x.scrollTop);
    const d = T - R;
    if (p.top = Math.ceil(Math.max(Math.min(d, p.top), 0)), hn(R, T) || p.top === H) {
      t({ scrollTop: H, scrollHeight: T, viewportHeight: R }), E && e(!0);
      return;
    }
    E ? (i.current = p.top, l.current && clearTimeout(l.current), l.current = setTimeout(() => {
      l.current = null, i.current = null, e(!0);
    }, 1e3)) : i.current = null, x.scrollTo(p);
  }
  function a(p) {
    s.current.scrollBy(p);
  }
  return { scrollerRef: s, scrollByCallback: a, scrollToCallback: f };
}
const ut = U(
  () => {
    const t = W(), e = W(), n = C(0), o = W(), r = C(0), s = W(), i = W(), l = C(0), c = C(0), f = C(0), a = C(0), p = W(), x = W(), E = C(!1);
    return O(
      g(
        t,
        b(({ scrollTop: R }) => R)
      ),
      e
    ), O(
      g(
        t,
        b(({ scrollHeight: R }) => R)
      ),
      i
    ), O(e, r), {
      // input
      scrollContainerState: t,
      scrollTop: e,
      viewportHeight: s,
      headerHeight: l,
      fixedHeaderHeight: c,
      fixedFooterHeight: f,
      footerHeight: a,
      scrollHeight: i,
      smoothScrollTargetReached: o,
      // signals
      scrollTo: p,
      scrollBy: x,
      // state
      statefulScrollTop: r,
      deviation: n,
      scrollingInProgress: E
    };
  },
  [],
  { singleton: !0 }
), Kt = { lvl: 0 };
function gn(t, e, n, o = Kt, r = Kt) {
  return { k: t, v: e, lvl: n, l: o, r };
}
function j(t) {
  return t === Kt;
}
function Nt() {
  return Kt;
}
function Ce(t, e) {
  if (j(t))
    return Kt;
  const { k: n, l: o, r } = t;
  if (e === n) {
    if (j(o))
      return r;
    if (j(r))
      return o;
    {
      const [s, i] = In(o);
      return ie(tt(t, { k: s, v: i, l: Sn(o) }));
    }
  } else
    return e < n ? ie(tt(t, { l: Ce(o, e) })) : ie(tt(t, { r: Ce(r, e) }));
}
function qt(t, e) {
  if (!j(t))
    return e === t.k ? t.v : e < t.k ? qt(t.l, e) : qt(t.r, e);
}
function Tt(t, e, n = "k") {
  if (j(t))
    return [-1 / 0, void 0];
  if (Number(t[n]) === e)
    return [t.k, t.v];
  if (Number(t[n]) < e) {
    const o = Tt(t.r, e, n);
    return o[0] === -1 / 0 ? [t.k, t.v] : o;
  }
  return Tt(t.l, e, n);
}
function at(t, e, n) {
  return j(t) ? gn(e, n, 1) : e === t.k ? tt(t, { k: e, v: n }) : e < t.k ? Ke(tt(t, { l: at(t.l, e, n) })) : Ke(tt(t, { r: at(t.r, e, n) }));
}
function Ee(t, e, n) {
  if (j(t))
    return [];
  const { k: o, v: r, l: s, r: i } = t;
  let l = [];
  return o > e && (l = l.concat(Ee(s, e, n))), o >= e && o <= n && l.push({ k: o, v: r }), o <= n && (l = l.concat(Ee(i, e, n))), l;
}
function Pt(t) {
  return j(t) ? [] : [...Pt(t.l), { k: t.k, v: t.v }, ...Pt(t.r)];
}
function In(t) {
  return j(t.r) ? [t.k, t.v] : In(t.r);
}
function Sn(t) {
  return j(t.r) ? t.l : ie(tt(t, { r: Sn(t.r) }));
}
function tt(t, e) {
  return gn(
    e.k !== void 0 ? e.k : t.k,
    e.v !== void 0 ? e.v : t.v,
    e.lvl !== void 0 ? e.lvl : t.lvl,
    e.l !== void 0 ? e.l : t.l,
    e.r !== void 0 ? e.r : t.r
  );
}
function xe(t) {
  return j(t) || t.lvl > t.r.lvl;
}
function Ke(t) {
  return ye(Tn(t));
}
function ie(t) {
  const { l: e, r: n, lvl: o } = t;
  if (n.lvl >= o - 1 && e.lvl >= o - 1)
    return t;
  if (o > n.lvl + 1) {
    if (xe(e))
      return Tn(tt(t, { lvl: o - 1 }));
    if (!j(e) && !j(e.r))
      return tt(e.r, {
        l: tt(e, { r: e.r.l }),
        r: tt(t, {
          l: e.r.r,
          lvl: o - 1
        }),
        lvl: o
      });
    throw new Error("Unexpected empty nodes");
  } else {
    if (xe(t))
      return ye(tt(t, { lvl: o - 1 }));
    if (!j(n) && !j(n.l)) {
      const r = n.l, s = xe(r) ? n.lvl - 1 : n.lvl;
      return tt(r, {
        l: tt(t, {
          r: r.l,
          lvl: o - 1
        }),
        r: ye(tt(n, { l: r.r, lvl: s })),
        lvl: r.lvl + 1
      });
    } else
      throw new Error("Unexpected empty nodes");
  }
}
function he(t, e, n) {
  if (j(t))
    return [];
  const o = Tt(t, e)[0];
  return so(Ee(t, o, n));
}
function xn(t, e) {
  const n = t.length;
  if (n === 0)
    return [];
  let { index: o, value: r } = e(t[0]);
  const s = [];
  for (let i = 1; i < n; i++) {
    const { index: l, value: c } = e(t[i]);
    s.push({ start: o, end: l - 1, value: r }), o = l, r = c;
  }
  return s.push({ start: o, end: 1 / 0, value: r }), s;
}
function so(t) {
  return xn(t, ({ k: e, v: n }) => ({ index: e, value: n }));
}
function ye(t) {
  const { r: e, lvl: n } = t;
  return !j(e) && !j(e.r) && e.lvl === n && e.r.lvl === n ? tt(e, { l: tt(t, { r: e.l }), lvl: n + 1 }) : t;
}
function Tn(t) {
  const { l: e } = t;
  return !j(e) && e.lvl === t.lvl ? tt(e, { r: tt(t, { l: e.r }) }) : t;
}
function ce(t, e, n, o = 0) {
  let r = t.length - 1;
  for (; o <= r; ) {
    const s = Math.floor((o + r) / 2), i = t[s], l = n(i, e);
    if (l === 0)
      return s;
    if (l === -1) {
      if (r - o < 2)
        return s - 1;
      r = s - 1;
    } else {
      if (r === o)
        return s;
      o = s + 1;
    }
  }
  throw new Error(`Failed binary finding record in array - ${t.join(",")}, searched for ${e}`);
}
function wn(t, e, n) {
  return t[ce(t, e, n)];
}
function io(t, e, n, o) {
  const r = ce(t, e, o), s = ce(t, n, o, r);
  return t.slice(r, s + 1);
}
const Le = U(
  () => ({ recalcInProgress: C(!1) }),
  [],
  { singleton: !0 }
);
function lo(t) {
  const { size: e, startIndex: n, endIndex: o } = t;
  return (r) => r.start === n && (r.end === o || r.end === 1 / 0) && r.value === e;
}
function qe(t, e) {
  let n = 0, o = 0;
  for (; n < t; )
    n += e[o + 1] - e[o] - 1, o++;
  return o - (n === t ? 0 : 1);
}
function co(t, e) {
  let n = j(t) ? 0 : 1 / 0;
  for (const o of e) {
    const { size: r, startIndex: s, endIndex: i } = o;
    if (n = Math.min(n, s), j(t)) {
      t = at(t, 0, r);
      continue;
    }
    const l = he(t, s - 1, i + 1);
    if (l.some(lo(o)))
      continue;
    let c = !1, f = !1;
    for (const { start: a, end: p, value: x } of l)
      c ? (i >= a || r === x) && (t = Ce(t, a)) : (f = x !== r, c = !0), p > i && i >= a && x !== r && (t = at(t, i + 1, x));
    f && (t = at(t, s, r));
  }
  return [t, n];
}
function uo() {
  return {
    offsetTree: [],
    sizeTree: Nt(),
    groupOffsetTree: Nt(),
    lastIndex: 0,
    lastOffset: 0,
    lastSize: 0,
    groupIndices: []
  };
}
function ze({ index: t }, e) {
  return e === t ? 0 : e < t ? -1 : 1;
}
function ao({ offset: t }, e) {
  return e === t ? 0 : e < t ? -1 : 1;
}
function fo(t) {
  return { index: t.index, value: t };
}
function mo(t, e, n, o = 0) {
  return o > 0 && (e = Math.max(e, wn(t, o, ze).offset)), xn(io(t, e, n, ao), fo);
}
function Re(t, e, n, o) {
  let r = t, s = 0, i = 0, l = 0, c = 0;
  if (e !== 0) {
    c = ce(r, e - 1, ze), l = r[c].offset;
    const a = Tt(n, e - 1);
    s = a[0], i = a[1], r.length && r[c].size === Tt(n, e)[1] && (c -= 1), r = r.slice(0, c + 1);
  } else
    r = [];
  for (const { start: f, value: a } of he(n, e, 1 / 0)) {
    const p = f - s, x = p * i + l + p * o;
    r.push({
      offset: x,
      size: a,
      index: f
    }), s = f, l = x, i = a;
  }
  return {
    offsetTree: r,
    lastIndex: s,
    lastOffset: l,
    lastSize: i
  };
}
function ho(t, [e, n, o, r]) {
  e.length > 0 && o("received item sizes", e, dt.DEBUG);
  const s = t.sizeTree;
  let i = s, l = 0;
  if (n.length > 0 && j(s) && e.length === 2) {
    const x = e[0].size, E = e[1].size;
    i = n.reduce((R, T) => at(at(R, T, x), T + 1, E), i);
  } else
    [i, l] = co(i, e);
  if (i === s)
    return t;
  const { offsetTree: c, lastIndex: f, lastSize: a, lastOffset: p } = Re(t.offsetTree, l, i, r);
  return {
    sizeTree: i,
    offsetTree: c,
    lastIndex: f,
    lastOffset: p,
    lastSize: a,
    groupOffsetTree: n.reduce((x, E) => at(x, E, Yt(E, c, r)), Nt()),
    groupIndices: n
  };
}
function Yt(t, e, n) {
  if (e.length === 0)
    return 0;
  const { offset: o, index: r, size: s } = wn(e, t, ze), i = t - r, l = s * i + (i - 1) * n + o;
  return l > 0 ? l + n : l;
}
function po(t) {
  return typeof t.groupIndex < "u";
}
function vn(t, e, n) {
  if (po(t))
    return e.groupIndices[t.groupIndex] + 1;
  {
    const o = t.index === "LAST" ? n : t.index;
    let r = Cn(o, e);
    return r = Math.max(0, r, Math.min(n, r)), r;
  }
}
function Cn(t, e) {
  if (!pe(e))
    return t;
  let n = 0;
  for (; e.groupIndices[n] <= t + n; )
    n++;
  return t + n;
}
function pe(t) {
  return !j(t.groupOffsetTree);
}
function go(t) {
  return Pt(t).map(({ k: e, v: n }, o, r) => {
    const s = r[o + 1], i = s ? s.k - 1 : 1 / 0;
    return { startIndex: e, endIndex: i, size: n };
  });
}
const Io = {
  offsetHeight: "height",
  offsetWidth: "width"
}, Et = U(
  ([{ log: t }, { recalcInProgress: e }]) => {
    const n = W(), o = W(), r = ct(o, 0), s = W(), i = W(), l = C(0), c = C([]), f = C(void 0), a = C(void 0), p = C((m, u) => Ht(m, Io[u])), x = C(void 0), E = C(0), R = uo(), T = ct(
      g(n, G(c, t, E), vt(ho, R), Z()),
      R
    ), H = ct(
      g(
        c,
        Z(),
        vt((m, u) => ({ prev: m.current, current: u }), {
          prev: [],
          current: []
        }),
        b(({ prev: m }) => m)
      ),
      []
    );
    O(
      g(
        c,
        k((m) => m.length > 0),
        G(T, E),
        b(([m, u, v]) => {
          const L = m.reduce((B, F, V) => at(B, F, Yt(F, u.offsetTree, v) || V), Nt());
          return {
            ...u,
            groupIndices: m,
            groupOffsetTree: L
          };
        })
      ),
      T
    ), O(
      g(
        o,
        G(T),
        k(([m, { lastIndex: u }]) => m < u),
        b(([m, { lastIndex: u, lastSize: v }]) => [
          {
            startIndex: m,
            endIndex: u,
            size: v
          }
        ])
      ),
      n
    ), O(f, a);
    const d = ct(
      g(
        f,
        b((m) => m === void 0)
      ),
      !0
    );
    O(
      g(
        a,
        k((m) => m !== void 0 && j(it(T).sizeTree)),
        b((m) => [{ startIndex: 0, endIndex: 0, size: m }])
      ),
      n
    );
    const h = ft(
      g(
        n,
        G(T),
        vt(
          ({ sizes: m }, [u, v]) => ({
            changed: v !== m,
            sizes: v
          }),
          { changed: !1, sizes: R }
        ),
        b((m) => m.changed)
      )
    );
    $(
      g(
        l,
        vt(
          (m, u) => ({ diff: m.prev - u, prev: u }),
          { diff: 0, prev: 0 }
        ),
        b((m) => m.diff)
      ),
      (m) => {
        const { groupIndices: u } = it(T);
        if (m > 0)
          _(e, !0), _(s, m + qe(m, u));
        else if (m < 0) {
          const v = it(H);
          v.length > 0 && (m -= qe(-m, v)), _(i, m);
        }
      }
    ), $(g(l, G(t)), ([m, u]) => {
      m < 0 && u(
        "`firstItemIndex` prop should not be set to less than zero. If you don't know the total count, just use a very high value",
        { firstItemIndex: l },
        dt.ERROR
      );
    });
    const I = ft(s);
    O(
      g(
        s,
        G(T),
        b(([m, u]) => {
          const v = u.groupIndices.length > 0, L = [], B = u.lastSize;
          if (v) {
            const F = qt(u.sizeTree, 0);
            let V = 0, D = 0;
            for (; V < m; ) {
              const M = u.groupIndices[D], q = u.groupIndices.length === D + 1 ? 1 / 0 : u.groupIndices[D + 1] - M - 1;
              L.push({
                startIndex: M,
                endIndex: M,
                size: F
              }), L.push({
                startIndex: M + 1,
                endIndex: M + 1 + q - 1,
                size: B
              }), D++, V += q + 1;
            }
            const K = Pt(u.sizeTree);
            return V !== m && K.shift(), K.reduce(
              (M, { k: q, v: ot }) => {
                let mt = M.ranges;
                return M.prevSize !== 0 && (mt = [
                  ...M.ranges,
                  {
                    startIndex: M.prevIndex,
                    endIndex: q + m - 1,
                    size: M.prevSize
                  }
                ]), {
                  ranges: mt,
                  prevIndex: q + m,
                  prevSize: ot
                };
              },
              {
                ranges: L,
                prevIndex: m,
                prevSize: 0
              }
            ).ranges;
          }
          return Pt(u.sizeTree).reduce(
            (F, { k: V, v: D }) => ({
              ranges: [...F.ranges, { startIndex: F.prevIndex, endIndex: V + m - 1, size: F.prevSize }],
              prevIndex: V + m,
              prevSize: D
            }),
            {
              ranges: [],
              prevIndex: 0,
              prevSize: B
            }
          ).ranges;
        })
      ),
      n
    );
    const y = ft(
      g(
        i,
        G(T, E),
        b(([m, { offsetTree: u }, v]) => {
          const L = -m;
          return Yt(L, u, v);
        })
      )
    );
    return O(
      g(
        i,
        G(T, E),
        b(([m, u, v]) => {
          if (u.groupIndices.length > 0) {
            if (j(u.sizeTree))
              return u;
            let B = Nt();
            const F = it(H);
            let V = 0, D = 0, K = 0;
            for (; V < -m; ) {
              K = F[D];
              const M = F[D + 1] - K - 1;
              D++, V += M + 1;
            }
            if (B = Pt(u.sizeTree).reduce((M, { k: q, v: ot }) => at(M, Math.max(0, q + m), ot), B), V !== -m) {
              const M = qt(u.sizeTree, K);
              B = at(B, 0, M);
              const q = Tt(u.sizeTree, -m + 1)[1];
              B = at(B, 1, q);
            }
            return {
              ...u,
              sizeTree: B,
              ...Re(u.offsetTree, 0, B, v)
            };
          } else {
            const B = Pt(u.sizeTree).reduce((F, { k: V, v: D }) => at(F, Math.max(0, V + m), D), Nt());
            return {
              ...u,
              sizeTree: B,
              ...Re(u.offsetTree, 0, B, v)
            };
          }
        })
      ),
      T
    ), {
      // input
      data: x,
      totalCount: o,
      sizeRanges: n,
      groupIndices: c,
      defaultItemSize: a,
      fixedItemSize: f,
      unshiftWith: s,
      shiftWith: i,
      shiftWithOffset: y,
      beforeUnshiftWith: I,
      firstItemIndex: l,
      gap: E,
      // output
      sizes: T,
      listRefresh: h,
      statefulTotalCount: r,
      trackItemSizes: d,
      itemSize: p
    };
  },
  J(Ot, Le),
  { singleton: !0 }
), So = typeof document < "u" && "scrollBehavior" in document.documentElement.style;
function En(t) {
  const e = typeof t == "number" ? { index: t } : t;
  return e.align || (e.align = "start"), (!e.behavior || !So) && (e.behavior = "auto"), e.offset || (e.offset = 0), e;
}
const Qt = U(
  ([
    { sizes: t, totalCount: e, listRefresh: n, gap: o },
    {
      scrollingInProgress: r,
      viewportHeight: s,
      scrollTo: i,
      smoothScrollTargetReached: l,
      headerHeight: c,
      footerHeight: f,
      fixedHeaderHeight: a,
      fixedFooterHeight: p
    },
    { log: x }
  ]) => {
    const E = W(), R = W(), T = C(0);
    let H = null, d = null, h = null;
    function I() {
      H && (H(), H = null), h && (h(), h = null), d && (clearTimeout(d), d = null), _(r, !1);
    }
    return O(
      g(
        E,
        G(t, s, e, T, c, f, x),
        G(o, a, p),
        b(
          ([
            [y, m, u, v, L, B, F, V],
            D,
            K,
            z
          ]) => {
            const M = En(y), { align: q, behavior: ot, offset: mt } = M, It = v - 1, ht = vn(M, m, It);
            let rt = Yt(ht, m.offsetTree, D) + B;
            q === "end" ? (rt += K + Tt(m.sizeTree, ht)[1] - u + z, ht === It && (rt += F)) : q === "center" ? rt += (K + Tt(m.sizeTree, ht)[1] - u + z) / 2 : rt -= L, mt && (rt += mt);
            const zt = (yt) => {
              I(), yt ? (V("retrying to scroll to", { location: y }, dt.DEBUG), _(E, y)) : (_(R, !0), V("list did not change, scroll successful", {}, dt.DEBUG));
            };
            if (I(), ot === "smooth") {
              let yt = !1;
              h = $(n, (Gt) => {
                yt = yt || Gt;
              }), H = Ct(l, () => {
                zt(yt);
              });
            } else
              H = Ct(g(n, xo(150)), zt);
            return d = setTimeout(() => {
              I();
            }, 1200), _(r, !0), V("scrolling from index to", { index: ht, top: rt, behavior: ot }, dt.DEBUG), { top: rt, behavior: ot };
          }
        )
      ),
      i
    ), {
      scrollToIndex: E,
      scrollTargetReached: R,
      topListHeight: T
    };
  },
  J(Et, ut, Ot),
  { singleton: !0 }
);
function xo(t) {
  return (e) => {
    const n = setTimeout(() => {
      e(!1);
    }, t);
    return (o) => {
      o && (e(!0), clearTimeout(n));
    };
  };
}
const Zt = "up", Ut = "down", To = "none", wo = {
  atBottom: !1,
  notAtBottomBecause: "NOT_SHOWING_LAST_ITEM",
  state: {
    offsetBottom: 0,
    scrollTop: 0,
    viewportHeight: 0,
    scrollHeight: 0
  }
}, vo = 0, Xt = U(([{ scrollContainerState: t, scrollTop: e, viewportHeight: n, headerHeight: o, footerHeight: r, scrollBy: s }]) => {
  const i = C(!1), l = C(!0), c = W(), f = W(), a = C(4), p = C(vo), x = ct(
    g(
      je(g(P(e), Wt(1), wt(!0)), g(P(e), Wt(1), wt(!1), Ue(100))),
      Z()
    ),
    !1
  ), E = ct(
    g(je(g(s, wt(!0)), g(s, wt(!1), Ue(200))), Z()),
    !1
  );
  O(
    g(
      nt(P(e), P(p)),
      b(([h, I]) => h <= I),
      Z()
    ),
    l
  ), O(g(l, bt(50)), f);
  const R = ft(
    g(
      nt(t, P(n), P(o), P(r), P(a)),
      vt((h, [{ scrollTop: I, scrollHeight: y }, m, u, v, L]) => {
        const B = I + m - y > -L, F = {
          viewportHeight: m,
          scrollTop: I,
          scrollHeight: y
        };
        if (B) {
          let D, K;
          return I > h.state.scrollTop ? (D = "SCROLLED_DOWN", K = h.state.scrollTop - I) : (D = "SIZE_DECREASED", K = h.state.scrollTop - I || h.scrollTopDelta), {
            atBottom: !0,
            state: F,
            atBottomBecause: D,
            scrollTopDelta: K
          };
        }
        let V;
        return F.scrollHeight > h.state.scrollHeight ? V = "SIZE_INCREASED" : m < h.state.viewportHeight ? V = "VIEWPORT_HEIGHT_DECREASING" : I < h.state.scrollTop ? V = "SCROLLING_UPWARDS" : V = "NOT_FULLY_SCROLLED_TO_LAST_ITEM_BOTTOM", {
          atBottom: !1,
          notAtBottomBecause: V,
          state: F
        };
      }, wo),
      Z((h, I) => h && h.atBottom === I.atBottom)
    )
  ), T = ct(
    g(
      t,
      vt(
        (h, { scrollTop: I, scrollHeight: y, viewportHeight: m }) => {
          if (hn(h.scrollHeight, y))
            return {
              scrollTop: I,
              scrollHeight: y,
              jump: 0,
              changed: !1
            };
          {
            const u = y - (I + m) < 1;
            return h.scrollTop !== I && u ? {
              scrollHeight: y,
              scrollTop: I,
              jump: h.scrollTop - I,
              changed: !0
            } : {
              scrollHeight: y,
              scrollTop: I,
              jump: 0,
              changed: !0
            };
          }
        },
        { scrollHeight: 0, jump: 0, scrollTop: 0, changed: !1 }
      ),
      k((h) => h.changed),
      b((h) => h.jump)
    ),
    0
  );
  O(
    g(
      R,
      b((h) => h.atBottom)
    ),
    i
  ), O(g(i, bt(50)), c);
  const H = C(Ut);
  O(
    g(
      t,
      b(({ scrollTop: h }) => h),
      Z(),
      vt(
        (h, I) => it(E) ? { direction: h.direction, prevScrollTop: I } : { direction: I < h.prevScrollTop ? Zt : Ut, prevScrollTop: I },
        { direction: Ut, prevScrollTop: 0 }
      ),
      b((h) => h.direction)
    ),
    H
  ), O(g(t, bt(50), wt(To)), H);
  const d = C(0);
  return O(
    g(
      x,
      k((h) => !h),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      wt(0)
    ),
    d
  ), O(
    g(
      e,
      bt(100),
      G(x),
      k(([h, I]) => !!I),
      vt(([h, I], [y]) => [I, y], [0, 0]),
      b(([h, I]) => I - h)
    ),
    d
  ), {
    isScrolling: x,
    isAtTop: l,
    isAtBottom: i,
    atBottomState: R,
    atTopStateChange: f,
    atBottomStateChange: c,
    scrollDirection: H,
    atBottomThreshold: a,
    atTopThreshold: p,
    scrollVelocity: d,
    lastJumpDueToItemResize: T
  };
}, J(ut)), Lt = U(
  ([{ log: t }]) => {
    const e = C(!1), n = ft(
      g(
        e,
        k((o) => o),
        Z()
      )
    );
    return $(e, (o) => {
      o && it(t)("props updated", {}, dt.DEBUG);
    }), { propsReady: e, didMount: n };
  },
  J(Ot),
  { singleton: !0 }
);
function ke(t, e) {
  t == 0 ? e() : requestAnimationFrame(() => ke(t - 1, e));
}
function Fe(t, e) {
  const n = e - 1;
  return typeof t == "number" ? t : t.index === "LAST" ? n : t.index;
}
const te = U(
  ([{ sizes: t, listRefresh: e, defaultItemSize: n }, { scrollTop: o }, { scrollToIndex: r, scrollTargetReached: s }, { didMount: i }]) => {
    const l = C(!0), c = C(0), f = C(!0);
    return O(
      g(
        i,
        G(c),
        k(([a, p]) => !!p),
        wt(!1)
      ),
      l
    ), O(
      g(
        i,
        G(c),
        k(([a, p]) => !!p),
        wt(!1)
      ),
      f
    ), $(
      g(
        nt(e, i),
        G(l, t, n, f),
        k(([[, a], p, { sizeTree: x }, E, R]) => a && (!j(x) || He(E)) && !p && !R),
        G(c)
      ),
      ([, a]) => {
        Ct(s, () => {
          _(f, !0);
        }), ke(4, () => {
          Ct(o, () => {
            _(l, !0);
          }), _(r, a);
        });
      }
    ), {
      scrolledToInitialItem: l,
      initialTopMostItemIndex: c,
      initialItemFinalLocationReached: f
    };
  },
  J(Et, ut, Qt, Lt),
  { singleton: !0 }
);
function Ye(t) {
  return t ? t === "smooth" ? "smooth" : "auto" : !1;
}
const Co = (t, e) => typeof t == "function" ? Ye(t(e)) : e && Ye(t), Eo = U(
  ([
    { totalCount: t, listRefresh: e },
    { isAtBottom: n, atBottomState: o },
    { scrollToIndex: r },
    { scrolledToInitialItem: s },
    { propsReady: i, didMount: l },
    { log: c },
    { scrollingInProgress: f }
  ]) => {
    const a = C(!1), p = W();
    let x = null;
    function E(T) {
      _(r, {
        index: "LAST",
        align: "end",
        behavior: T
      });
    }
    $(
      g(
        nt(g(P(t), Wt(1)), l),
        G(P(a), n, s, f),
        b(([[T, H], d, h, I, y]) => {
          let m = H && I, u = "auto";
          return m && (u = Co(d, h || y), m = m && !!u), { totalCount: T, shouldFollow: m, followOutputBehavior: u };
        }),
        k(({ shouldFollow: T }) => T)
      ),
      ({ totalCount: T, followOutputBehavior: H }) => {
        x && (x(), x = null), x = Ct(e, () => {
          it(c)("following output to ", { totalCount: T }, dt.DEBUG), E(H), x = null;
        });
      }
    );
    function R(T) {
      const H = Ct(o, (d) => {
        T && !d.atBottom && d.notAtBottomBecause === "SIZE_INCREASED" && !x && (it(c)("scrolling to bottom due to increased size", {}, dt.DEBUG), E("auto"));
      });
      setTimeout(H, 100);
    }
    return $(
      g(
        nt(P(a), t, i),
        k(([T, , H]) => T && H),
        vt(
          ({ value: T }, [, H]) => ({ refreshed: T === H, value: H }),
          { refreshed: !1, value: 0 }
        ),
        k(({ refreshed: T }) => T),
        G(a, t)
      ),
      ([, T]) => {
        it(s) && R(T !== !1);
      }
    ), $(p, () => {
      R(it(a) !== !1);
    }), $(nt(P(a), o), ([T, H]) => {
      T && !H.atBottom && H.notAtBottomBecause === "VIEWPORT_HEIGHT_DECREASING" && E("auto");
    }), { followOutput: a, autoscrollToBottom: p };
  },
  J(Et, Xt, Qt, te, Lt, Ot, ut)
);
function yo(t) {
  return t.reduce(
    (e, n) => (e.groupIndices.push(e.totalCount), e.totalCount += n + 1, e),
    {
      totalCount: 0,
      groupIndices: []
    }
  );
}
const yn = U(([{ totalCount: t, groupIndices: e, sizes: n }, { scrollTop: o, headerHeight: r }]) => {
  const s = W(), i = W(), l = ft(g(s, b(yo)));
  return O(
    g(
      l,
      b((c) => c.totalCount)
    ),
    t
  ), O(
    g(
      l,
      b((c) => c.groupIndices)
    ),
    e
  ), O(
    g(
      nt(o, n, r),
      k(([c, f]) => pe(f)),
      b(([c, f, a]) => Tt(f.groupOffsetTree, Math.max(c - a, 0), "v")[0]),
      Z(),
      b((c) => [c])
    ),
    i
  ), { groupCounts: s, topItemsIndexes: i };
}, J(Et, ut));
function Jt(t, e) {
  return !!(t && t[0] === e[0] && t[1] === e[1]);
}
function Rn(t, e) {
  return !!(t && t.startIndex === e.startIndex && t.endIndex === e.endIndex);
}
const ue = "top", ae = "bottom", Ze = "none";
function Je(t, e, n) {
  return typeof t == "number" ? n === Zt && e === ue || n === Ut && e === ae ? t : 0 : n === Zt ? e === ue ? t.main : t.reverse : e === ae ? t.main : t.reverse;
}
function Qe(t, e) {
  return typeof t == "number" ? t : t[e] || 0;
}
const Pe = U(
  ([{ scrollTop: t, viewportHeight: e, deviation: n, headerHeight: o, fixedHeaderHeight: r }]) => {
    const s = W(), i = C(0), l = C(0), c = C(0), f = ct(
      g(
        nt(
          P(t),
          P(e),
          P(o),
          P(s, Jt),
          P(c),
          P(i),
          P(r),
          P(n),
          P(l)
        ),
        b(
          ([
            a,
            p,
            x,
            [E, R],
            T,
            H,
            d,
            h,
            I
          ]) => {
            const y = a - h, m = H + d, u = Math.max(x - y, 0);
            let v = Ze;
            const L = Qe(I, ue), B = Qe(I, ae);
            return E -= h, E += x + d, R += x + d, R -= h, E > a + m - L && (v = Zt), R < a - u + p + B && (v = Ut), v !== Ze ? [
              Math.max(y - x - Je(T, ue, v) - L, 0),
              y - u - d + p + Je(T, ae, v) + B
            ] : null;
          }
        ),
        k((a) => a != null),
        Z(Jt)
      ),
      [0, 0]
    );
    return {
      // input
      listBoundary: s,
      overscan: c,
      topListHeight: i,
      increaseViewportBy: l,
      // output
      visibleRange: f
    };
  },
  J(ut),
  { singleton: !0 }
);
function Ro(t, e, n) {
  if (pe(e)) {
    const o = Cn(t, e);
    return [
      { index: Tt(e.groupOffsetTree, o)[0], size: 0, offset: 0 },
      { index: o, size: 0, offset: 0, data: n && n[0] }
    ];
  }
  return [{ index: t, size: 0, offset: 0, data: n && n[0] }];
}
const Te = {
  items: [],
  topItems: [],
  offsetTop: 0,
  offsetBottom: 0,
  top: 0,
  bottom: 0,
  topListHeight: 0,
  totalCount: 0,
  firstItemIndex: 0
};
function Xe(t, e, n) {
  if (t.length === 0)
    return [];
  if (!pe(e))
    return t.map((f) => ({ ...f, index: f.index + n, originalIndex: f.index }));
  const o = t[0].index, r = t[t.length - 1].index, s = [], i = he(e.groupOffsetTree, o, r);
  let l, c = 0;
  for (const f of t) {
    (!l || l.end < f.index) && (l = i.shift(), c = e.groupIndices.indexOf(l.start));
    let a;
    f.index === l.start ? a = {
      type: "group",
      index: c
    } : a = {
      index: f.index - (c + 1) + n,
      groupIndex: c
    }, s.push({
      ...a,
      size: f.size,
      offset: f.offset,
      originalIndex: f.index,
      data: f.data
    });
  }
  return s;
}
function le(t, e, n, o, r, s) {
  const { lastSize: i, lastOffset: l, lastIndex: c } = r;
  let f = 0, a = 0;
  if (t.length > 0) {
    f = t[0].offset;
    const T = t[t.length - 1];
    a = T.offset + T.size;
  }
  const p = n - c, x = l + p * i + (p - 1) * o, E = f, R = x - a;
  return {
    items: Xe(t, r, s),
    topItems: Xe(e, r, s),
    topListHeight: e.reduce((T, H) => H.size + T, 0),
    offsetTop: f,
    offsetBottom: R,
    top: E,
    bottom: a,
    totalCount: n,
    firstItemIndex: s
  };
}
function bn(t, e, n, o, r, s) {
  let i = 0;
  if (n.groupIndices.length > 0)
    for (const a of n.groupIndices) {
      if (a - i >= t)
        break;
      i++;
    }
  const l = t + i, c = Fe(e, l), f = Array.from({ length: l }).map((a, p) => ({
    index: p + c,
    size: 0,
    offset: 0,
    data: s[p + c]
  }));
  return le(f, [], l, r, n, o);
}
const Vt = U(
  ([
    { sizes: t, totalCount: e, data: n, firstItemIndex: o, gap: r },
    s,
    { visibleRange: i, listBoundary: l, topListHeight: c },
    { scrolledToInitialItem: f, initialTopMostItemIndex: a },
    { topListHeight: p },
    x,
    { didMount: E },
    { recalcInProgress: R }
  ]) => {
    const T = C([]), H = C(0), d = W();
    O(s.topItemsIndexes, T);
    const h = ct(
      g(
        nt(
          E,
          R,
          P(i, Jt),
          P(e),
          P(t),
          P(a),
          f,
          P(T),
          P(o),
          P(r),
          n
        ),
        k(([u, v, , L, , , , , , , B]) => {
          const F = B && B.length !== L;
          return u && !v && !F;
        }),
        b(
          ([
            ,
            ,
            [u, v],
            L,
            B,
            F,
            V,
            D,
            K,
            z,
            M
          ]) => {
            const q = B, { sizeTree: ot, offsetTree: mt } = q, It = it(H);
            if (L === 0)
              return { ...Te, totalCount: L };
            if (u === 0 && v === 0)
              return It === 0 ? { ...Te, totalCount: L } : bn(It, F, B, K, z, M || []);
            if (j(ot))
              return It > 0 ? null : le(
                Ro(Fe(F, L), q, M),
                [],
                L,
                z,
                q,
                K
              );
            const ht = [];
            if (D.length > 0) {
              const kt = D[0], w = D[D.length - 1];
              let N = 0;
              for (const Y of he(ot, kt, w)) {
                const X = Y.value, st = Math.max(Y.start, kt), St = Math.min(Y.end, w);
                for (let et = st; et <= St; et++)
                  ht.push({ index: et, size: X, offset: N, data: M && M[et] }), N += X;
              }
            }
            if (!V)
              return le([], ht, L, z, q, K);
            const rt = D.length > 0 ? D[D.length - 1] + 1 : 0, zt = mo(mt, u, v, rt);
            if (zt.length === 0)
              return null;
            const yt = L - 1, Gt = me([], (kt) => {
              for (const w of zt) {
                const N = w.value;
                let Y = N.offset, X = w.start;
                const st = N.size;
                if (N.offset < u) {
                  X += Math.floor((u - N.offset + z) / (st + z));
                  const et = X - w.start;
                  Y += et * st + et * z;
                }
                X < rt && (Y += (rt - X) * st, X = rt);
                const St = Math.min(w.end, yt);
                for (let et = X; et <= St && !(Y >= v); et++)
                  kt.push({ index: et, size: st, offset: Y, data: M && M[et] }), Y += st + z;
              }
            });
            return le(Gt, ht, L, z, q, K);
          }
        ),
        //@ts-expect-error filter needs to be fixed
        k((u) => u !== null),
        Z()
      ),
      Te
    );
    O(
      g(
        n,
        k(He),
        b((u) => u == null ? void 0 : u.length)
      ),
      e
    ), O(
      g(
        h,
        b((u) => u.topListHeight)
      ),
      p
    ), O(p, c), O(
      g(
        h,
        b((u) => [u.top, u.bottom])
      ),
      l
    ), O(
      g(
        h,
        b((u) => u.items)
      ),
      d
    );
    const I = ft(
      g(
        h,
        k(({ items: u }) => u.length > 0),
        G(e, n),
        k(([{ items: u }, v]) => u[u.length - 1].originalIndex === v - 1),
        b(([, u, v]) => [u - 1, v]),
        Z(Jt),
        b(([u]) => u)
      )
    ), y = ft(
      g(
        h,
        bt(200),
        k(({ items: u, topItems: v }) => u.length > 0 && u[0].originalIndex === v.length),
        b(({ items: u }) => u[0].index),
        Z()
      )
    ), m = ft(
      g(
        h,
        k(({ items: u }) => u.length > 0),
        b(({ items: u }) => {
          let v = 0, L = u.length - 1;
          for (; u[v].type === "group" && v < L; )
            v++;
          for (; u[L].type === "group" && L > v; )
            L--;
          return {
            startIndex: u[v].index,
            endIndex: u[L].index
          };
        }),
        Z(Rn)
      )
    );
    return { listState: h, topItemsIndexes: T, endReached: I, startReached: y, rangeChanged: m, itemsRendered: d, initialItemCount: H, ...x };
  },
  J(
    Et,
    yn,
    Pe,
    te,
    Qt,
    Xt,
    Lt,
    Le
  ),
  { singleton: !0 }
), bo = U(
  ([{ sizes: t, firstItemIndex: e, data: n, gap: o }, { initialTopMostItemIndex: r }, { initialItemCount: s, listState: i }, { didMount: l }]) => (O(
    g(
      l,
      G(s),
      k(([, c]) => c !== 0),
      G(r, t, e, o, n),
      b(([[, c], f, a, p, x, E = []]) => bn(c, f, a, p, x, E))
    ),
    i
  ), {}),
  J(Et, te, Vt, Lt),
  { singleton: !0 }
), Hn = U(
  ([{ scrollVelocity: t }]) => {
    const e = C(!1), n = W(), o = C(!1);
    return O(
      g(
        t,
        G(o, e, n),
        k(([r, s]) => !!s),
        b(([r, s, i, l]) => {
          const { exit: c, enter: f } = s;
          if (i) {
            if (c(r, l))
              return !1;
          } else if (f(r, l))
            return !0;
          return i;
        }),
        Z()
      ),
      e
    ), $(
      g(nt(e, t, n), G(o)),
      ([[r, s, i], l]) => r && l && l.change && l.change(s, i)
    ), { isSeeking: e, scrollSeekConfiguration: o, scrollVelocity: t, scrollSeekRangeChanged: n };
  },
  J(Xt),
  { singleton: !0 }
), Ho = U(([{ topItemsIndexes: t }]) => {
  const e = C(0);
  return O(
    g(
      e,
      k((n) => n > 0),
      b((n) => Array.from({ length: n }).map((o, r) => r))
    ),
    t
  ), { topItemCount: e };
}, J(Vt)), Bn = U(
  ([{ footerHeight: t, headerHeight: e, fixedHeaderHeight: n, fixedFooterHeight: o }, { listState: r }]) => {
    const s = W(), i = ct(
      g(
        nt(t, o, e, n, r),
        b(([l, c, f, a, p]) => l + c + f + a + p.offsetBottom + p.bottom)
      ),
      0
    );
    return O(P(i), s), { totalListHeight: i, totalListHeightChanged: s };
  },
  J(ut, Vt),
  { singleton: !0 }
);
function On(t) {
  let e = !1, n;
  return () => (e || (e = !0, n = t()), n);
}
const Bo = On(() => /iP(ad|od|hone)/i.test(navigator.userAgent) && /WebKit/i.test(navigator.userAgent)), Oo = U(
  ([
    { scrollBy: t, scrollTop: e, deviation: n, scrollingInProgress: o },
    { isScrolling: r, isAtBottom: s, scrollDirection: i, lastJumpDueToItemResize: l },
    { listState: c },
    { beforeUnshiftWith: f, shiftWithOffset: a, sizes: p, gap: x },
    { log: E },
    { recalcInProgress: R }
  ]) => {
    const T = ft(
      g(
        c,
        G(l),
        vt(
          ([, d, h, I], [{ items: y, totalCount: m, bottom: u, offsetBottom: v }, L]) => {
            const B = u + v;
            let F = 0;
            return h === m && d.length > 0 && y.length > 0 && (y[0].originalIndex === 0 && d[0].originalIndex === 0 || (F = B - I, F !== 0 && (F += L))), [F, y, m, B];
          },
          [0, [], 0, 0]
        ),
        k(([d]) => d !== 0),
        G(e, i, o, s, E, R),
        k(([, d, h, I, , , y]) => !y && !I && d !== 0 && h === Zt),
        b(([[d], , , , , h]) => (h("Upward scrolling compensation", { amount: d }, dt.DEBUG), d))
      )
    );
    function H(d) {
      d > 0 ? (_(t, { top: -d, behavior: "auto" }), _(n, 0)) : (_(n, 0), _(t, { top: -d, behavior: "auto" }));
    }
    return $(g(T, G(n, r)), ([d, h, I]) => {
      I && Bo() ? _(n, h - d) : H(-d);
    }), $(
      g(
        nt(ct(r, !1), n, R),
        k(([d, h, I]) => !d && !I && h !== 0),
        b(([d, h]) => h),
        bt(1)
      ),
      H
    ), O(
      g(
        a,
        b((d) => ({ top: -d }))
      ),
      t
    ), $(
      g(
        f,
        G(p, x),
        b(([d, { lastSize: h, groupIndices: I, sizeTree: y }, m]) => {
          function u(v) {
            return v * (h + m);
          }
          if (I.length === 0)
            return u(d);
          {
            let v = 0;
            const L = qt(y, 0);
            let B = 0, F = 0;
            for (; B < d; ) {
              B++, v += L;
              let V = I.length === F + 1 ? 1 / 0 : I[F + 1] - I[F] - 1;
              B + V > d && (v -= L, V = d - B + 1), B += V, v += u(V), F++;
            }
            return v;
          }
        })
      ),
      (d) => {
        _(n, d), requestAnimationFrame(() => {
          _(t, { top: d }), requestAnimationFrame(() => {
            _(n, 0), _(R, !1);
          });
        });
      }
    ), { deviation: n };
  },
  J(ut, Xt, Vt, Et, Ot, Le)
), Lo = U(
  ([{ didMount: t }, { scrollTo: e }, { listState: n }]) => {
    const o = C(0);
    return $(
      g(
        t,
        G(o),
        k(([, r]) => r !== 0),
        b(([, r]) => ({ top: r }))
      ),
      (r) => {
        Ct(
          g(
            n,
            Wt(1),
            k((s) => s.items.length > 1)
          ),
          () => {
            requestAnimationFrame(() => {
              _(e, r);
            });
          }
        );
      }
    ), {
      initialScrollTop: o
    };
  },
  J(Lt, ut, Vt),
  { singleton: !0 }
), zo = U(
  ([{ viewportHeight: t }, { totalListHeight: e }]) => {
    const n = C(!1), o = ct(
      g(
        nt(n, t, e),
        k(([r]) => r),
        b(([, r, s]) => Math.max(0, r - s)),
        bt(0),
        Z()
      ),
      0
    );
    return { alignToBottom: n, paddingTopAddition: o };
  },
  J(ut, Bn),
  { singleton: !0 }
), _e = U(([{ scrollTo: t, scrollContainerState: e }]) => {
  const n = W(), o = W(), r = W(), s = C(!1), i = C(void 0);
  return O(
    g(
      nt(n, o),
      b(([{ viewportHeight: l, scrollTop: c, scrollHeight: f }, { offsetTop: a }]) => ({
        scrollTop: Math.max(0, c - a),
        scrollHeight: f,
        viewportHeight: l
      }))
    ),
    e
  ), O(
    g(
      t,
      G(o),
      b(([l, { offsetTop: c }]) => ({
        ...l,
        top: l.top + c
      }))
    ),
    r
  ), {
    // config
    useWindowScroll: s,
    customScrollParent: i,
    // input
    windowScrollContainerState: n,
    windowViewportRect: o,
    // signals
    windowScrollTo: r
  };
}, J(ut)), ko = ({
  itemTop: t,
  itemBottom: e,
  viewportTop: n,
  viewportBottom: o,
  locationParams: { behavior: r, align: s, ...i }
}) => t < n ? { ...i, behavior: r, align: s ?? "start" } : e > o ? { ...i, behavior: r, align: s ?? "end" } : null, Fo = U(
  ([
    { sizes: t, totalCount: e, gap: n },
    { scrollTop: o, viewportHeight: r, headerHeight: s, fixedHeaderHeight: i, fixedFooterHeight: l, scrollingInProgress: c },
    { scrollToIndex: f }
  ]) => {
    const a = W();
    return O(
      g(
        a,
        G(t, r, e, s, i, l, o),
        G(n),
        b(([[p, x, E, R, T, H, d, h], I]) => {
          const { done: y, behavior: m, align: u, calculateViewLocation: v = ko, ...L } = p, B = vn(p, x, R - 1), F = Yt(B, x.offsetTree, I) + T + H, V = F + Tt(x.sizeTree, B)[1], D = h + H, K = h + E - d, z = v({
            itemTop: F,
            itemBottom: V,
            viewportTop: D,
            viewportBottom: K,
            locationParams: { behavior: m, align: u, ...L }
          });
          return z ? y && Ct(
            g(
              c,
              k((M) => M === !1),
              // skips the initial publish of false, and the cleanup call.
              // but if scrollingInProgress is true, we skip the initial publish.
              Wt(it(c) ? 1 : 2)
            ),
            y
          ) : y && y(), z;
        }),
        k((p) => p !== null)
      ),
      f
    ), {
      scrollIntoView: a
    };
  },
  J(Et, ut, Qt, Vt, Ot),
  { singleton: !0 }
), Po = U(
  ([
    { sizes: t, sizeRanges: e },
    { scrollTop: n },
    { initialTopMostItemIndex: o },
    { didMount: r },
    { useWindowScroll: s, windowScrollContainerState: i, windowViewportRect: l }
  ]) => {
    const c = W(), f = C(void 0), a = C(null), p = C(null);
    return O(i, a), O(l, p), $(
      g(c, G(t, n, s, a, p)),
      ([x, E, R, T, H, d]) => {
        const h = go(E.sizeTree);
        T && H !== null && d !== null && (R = H.scrollTop - d.offsetTop), x({ ranges: h, scrollTop: R });
      }
    ), O(g(f, k(He), b(_o)), o), O(
      g(
        r,
        G(f),
        k(([, x]) => x !== void 0),
        Z(),
        b(([, x]) => x.ranges)
      ),
      e
    ), {
      getState: c,
      restoreStateFrom: f
    };
  },
  J(Et, ut, te, Lt, _e)
);
function _o(t) {
  return { offset: t.scrollTop, index: 0, align: "start" };
}
const Vo = U(
  ([
    t,
    e,
    n,
    o,
    r,
    s,
    i,
    l,
    c,
    f
  ]) => ({
    ...t,
    ...e,
    ...n,
    ...o,
    ...r,
    ...s,
    ...i,
    ...l,
    ...c,
    ...f
  }),
  J(
    Pe,
    bo,
    Lt,
    Hn,
    Bn,
    Lo,
    zo,
    _e,
    Fo,
    Ot
  )
), Go = U(
  ([
    {
      totalCount: t,
      sizeRanges: e,
      fixedItemSize: n,
      defaultItemSize: o,
      trackItemSizes: r,
      itemSize: s,
      data: i,
      firstItemIndex: l,
      groupIndices: c,
      statefulTotalCount: f,
      gap: a,
      sizes: p
    },
    { initialTopMostItemIndex: x, scrolledToInitialItem: E, initialItemFinalLocationReached: R },
    T,
    H,
    d,
    { listState: h, topItemsIndexes: I, ...y },
    { scrollToIndex: m },
    u,
    { topItemCount: v },
    { groupCounts: L },
    B
  ]) => (O(y.rangeChanged, B.scrollSeekRangeChanged), O(
    g(
      B.windowViewportRect,
      b((F) => F.visibleHeight)
    ),
    T.viewportHeight
  ), {
    // input
    totalCount: t,
    data: i,
    firstItemIndex: l,
    sizeRanges: e,
    initialTopMostItemIndex: x,
    scrolledToInitialItem: E,
    initialItemFinalLocationReached: R,
    topItemsIndexes: I,
    topItemCount: v,
    groupCounts: L,
    fixedItemHeight: n,
    defaultItemHeight: o,
    gap: a,
    ...d,
    // output
    statefulTotalCount: f,
    listState: h,
    scrollToIndex: m,
    trackItemSizes: r,
    itemSize: s,
    groupIndices: c,
    // exported from stateFlagsSystem
    ...y,
    // the bag of IO from featureGroup1System
    ...B,
    ...T,
    sizes: p,
    ...H
  }),
  J(
    Et,
    te,
    ut,
    Po,
    Eo,
    Vt,
    Qt,
    Oo,
    Ho,
    yn,
    Vo
  )
), we = "-webkit-sticky", tn = "sticky", Ln = On(() => {
  if (typeof document > "u")
    return tn;
  const t = document.createElement("div");
  return t.style.position = we, t.style.position === we ? we : tn;
});
function zn(t, e) {
  const n = S.useRef(null), o = S.useCallback(
    (l) => {
      if (l === null || !l.offsetParent)
        return;
      const c = l.getBoundingClientRect(), f = c.width;
      let a, p;
      if (e) {
        const x = e.getBoundingClientRect(), E = c.top - x.top;
        a = x.height - Math.max(0, E), p = E + e.scrollTop;
      } else
        a = window.innerHeight - Math.max(0, c.top), p = c.top + window.pageYOffset;
      n.current = {
        offsetTop: p,
        visibleHeight: a,
        visibleWidth: f
      }, t(n.current);
    },
    [t, e]
  ), { callbackRef: r, ref: s } = Oe(o), i = S.useCallback(() => {
    o(s.current);
  }, [o, s]);
  return S.useEffect(() => {
    if (e) {
      e.addEventListener("scroll", i);
      const l = new ResizeObserver(() => {
        requestAnimationFrame(i);
      });
      return l.observe(e), () => {
        e.removeEventListener("scroll", i), l.unobserve(e);
      };
    } else
      return window.addEventListener("scroll", i), window.addEventListener("resize", i), () => {
        window.removeEventListener("scroll", i), window.removeEventListener("resize", i);
      };
  }, [i, e]), r;
}
const kn = S.createContext(void 0), Fn = S.createContext(void 0);
function Pn(t) {
  return t;
}
const Mo = /* @__PURE__ */ U(() => {
  const t = C((c) => `Item ${c}`), e = C(null), n = C((c) => `Group ${c}`), o = C({}), r = C(Pn), s = C("div"), i = C($t), l = (c, f = null) => ct(
    g(
      o,
      b((a) => a[c]),
      Z()
    ),
    f
  );
  return {
    context: e,
    itemContent: t,
    groupContent: n,
    components: o,
    computeItemKey: r,
    headerFooterTag: s,
    scrollerRef: i,
    FooterComponent: l("Footer"),
    HeaderComponent: l("Header"),
    TopItemListComponent: l("TopItemList"),
    ListComponent: l("List", "div"),
    ItemComponent: l("Item", "div"),
    GroupComponent: l("Group", "div"),
    ScrollerComponent: l("Scroller", "div"),
    EmptyPlaceholder: l("EmptyPlaceholder"),
    ScrollSeekPlaceholder: l("ScrollSeekPlaceholder")
  };
}), Ao = /* @__PURE__ */ U(([t, e]) => ({ ...t, ...e }), J(Go, Mo)), No = ({ height: t }) => /* @__PURE__ */ S.createElement("div", { style: { height: t } }), Wo = { position: Ln(), zIndex: 1, overflowAnchor: "none" }, Do = { overflowAnchor: "none" }, en = /* @__PURE__ */ S.memo(function({ showTopList: e = !1 }) {
  const n = A("listState"), o = gt("sizeRanges"), r = A("useWindowScroll"), s = A("customScrollParent"), i = gt("windowScrollContainerState"), l = gt("scrollContainerState"), c = s || r ? i : l, f = A("itemContent"), a = A("context"), p = A("groupContent"), x = A("trackItemSizes"), E = A("itemSize"), R = A("log"), T = gt("gap"), { callbackRef: H } = no(
    o,
    E,
    x,
    e ? $t : c,
    R,
    T,
    s
  ), [d, h] = S.useState(0);
  Ve("deviation", (z) => {
    d !== z && h(z);
  });
  const I = A("EmptyPlaceholder"), y = A("ScrollSeekPlaceholder") || No, m = A("ListComponent"), u = A("ItemComponent"), v = A("GroupComponent"), L = A("computeItemKey"), B = A("isSeeking"), F = A("groupIndices").length > 0, V = A("alignToBottom"), D = A("initialItemFinalLocationReached"), K = e ? {} : {
    boxSizing: "border-box",
    paddingTop: n.offsetTop,
    paddingBottom: n.offsetBottom,
    marginTop: d !== 0 ? d : V ? "auto" : 0,
    ...D ? {} : { visibility: "hidden" }
  };
  return !e && n.totalCount === 0 && I ? S.createElement(I, lt(I, a)) : S.createElement(
    m,
    {
      ...lt(m, a),
      ref: H,
      style: K,
      "data-testid": e ? "virtuoso-top-item-list" : "virtuoso-item-list"
    },
    (e ? n.topItems : n.items).map((z) => {
      const M = z.originalIndex, q = L(M + n.firstItemIndex, z.data, a);
      return B ? S.createElement(y, {
        ...lt(y, a),
        key: q,
        index: z.index,
        height: z.size,
        type: z.type || "item",
        ...z.type === "group" ? {} : { groupIndex: z.groupIndex }
      }) : z.type === "group" ? S.createElement(
        v,
        {
          ...lt(v, a),
          key: q,
          "data-index": M,
          "data-known-size": z.size,
          "data-item-index": z.index,
          style: Wo
        },
        p(z.index, a)
      ) : S.createElement(
        u,
        {
          ...lt(u, a),
          ...jo(u, z.data),
          key: q,
          "data-index": M,
          "data-known-size": z.size,
          "data-item-index": z.index,
          "data-item-group-index": z.groupIndex,
          style: Do
        },
        F ? f(z.index, z.groupIndex, z.data, a) : f(z.index, z.data, a)
      );
    })
  );
}), $o = {
  height: "100%",
  outline: "none",
  overflowY: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch"
}, ge = (t) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  ...t ? { display: "flex", flexDirection: "column" } : {}
}), Uo = {
  width: "100%",
  position: Ln(),
  top: 0,
  zIndex: 1
};
function lt(t, e) {
  if (typeof t != "string")
    return { context: e };
}
function jo(t, e) {
  return { item: typeof t == "string" ? void 0 : e };
}
const Ko = /* @__PURE__ */ S.memo(function() {
  const e = A("HeaderComponent"), n = gt("headerHeight"), o = A("headerFooterTag"), r = _t((i) => n(Ht(i, "height"))), s = A("context");
  return e ? S.createElement(o, { ref: r }, S.createElement(e, lt(e, s))) : null;
}), qo = /* @__PURE__ */ S.memo(function() {
  const e = A("FooterComponent"), n = gt("footerHeight"), o = A("headerFooterTag"), r = _t((i) => n(Ht(i, "height"))), s = A("context");
  return e ? S.createElement(o, { ref: r }, S.createElement(e, lt(e, s))) : null;
});
function _n({ usePublisher: t, useEmitter: e, useEmitterValue: n }) {
  return S.memo(function({ style: s, children: i, ...l }) {
    const c = t("scrollContainerState"), f = n("ScrollerComponent"), a = t("smoothScrollTargetReached"), p = n("scrollerRef"), x = n("context"), { scrollerRef: E, scrollByCallback: R, scrollToCallback: T } = pn(
      c,
      a,
      f,
      p
    );
    return e("scrollTo", T), e("scrollBy", R), S.createElement(
      f,
      {
        ref: E,
        style: { ...$o, ...s },
        "data-testid": "virtuoso-scroller",
        "data-virtuoso-scroller": !0,
        tabIndex: 0,
        ...l,
        ...lt(f, x)
      },
      i
    );
  });
}
function Vn({ usePublisher: t, useEmitter: e, useEmitterValue: n }) {
  return S.memo(function({ style: s, children: i, ...l }) {
    const c = t("windowScrollContainerState"), f = n("ScrollerComponent"), a = t("smoothScrollTargetReached"), p = n("totalListHeight"), x = n("deviation"), E = n("customScrollParent"), R = n("context"), { scrollerRef: T, scrollByCallback: H, scrollToCallback: d } = pn(
      c,
      a,
      f,
      $t,
      E
    );
    return Xn(() => (T.current = E || window, () => {
      T.current = null;
    }), [T, E]), e("windowScrollTo", d), e("scrollBy", H), S.createElement(
      f,
      {
        style: { position: "relative", ...s, ...p !== 0 ? { height: p + x } : {} },
        "data-virtuoso-scroller": !0,
        ...l,
        ...lt(f, R)
      },
      i
    );
  });
}
const Yo = ({ children: t }) => {
  const e = S.useContext(kn), n = gt("viewportHeight"), o = gt("fixedItemHeight"), r = A("alignToBottom"), s = _t(an(n, (i) => Ht(i, "height")));
  return S.useEffect(() => {
    e && (n(e.viewportHeight), o(e.itemHeight));
  }, [e, n, o]), /* @__PURE__ */ S.createElement("div", { style: ge(r), ref: s, "data-viewport-type": "element" }, t);
}, Zo = ({ children: t }) => {
  const e = S.useContext(kn), n = gt("windowViewportRect"), o = gt("fixedItemHeight"), r = A("customScrollParent"), s = zn(n, r), i = A("alignToBottom");
  return S.useEffect(() => {
    e && (o(e.itemHeight), n({ offsetTop: 0, visibleHeight: e.viewportHeight, visibleWidth: 100 }));
  }, [e, n, o]), /* @__PURE__ */ S.createElement("div", { ref: s, style: ge(i), "data-viewport-type": "window" }, t);
}, Jo = ({ children: t }) => {
  const e = A("TopItemListComponent") || "div", n = A("headerHeight"), o = { ...Uo, marginTop: `${n}px` }, r = A("context");
  return S.createElement(e, { style: o, ...lt(e, r) }, t);
}, Qo = /* @__PURE__ */ S.memo(function(e) {
  const n = A("useWindowScroll"), o = A("topItemsIndexes").length > 0, r = A("customScrollParent"), s = r || n ? er : tr, i = r || n ? Zo : Yo;
  return /* @__PURE__ */ S.createElement(s, { ...e }, o && /* @__PURE__ */ S.createElement(Jo, null, /* @__PURE__ */ S.createElement(en, { showTopList: !0 })), /* @__PURE__ */ S.createElement(i, null, /* @__PURE__ */ S.createElement(Ko, null), /* @__PURE__ */ S.createElement(en, null), /* @__PURE__ */ S.createElement(qo, null)));
}), {
  Component: Xo,
  usePublisher: gt,
  useEmitterValue: A,
  useEmitter: Ve
} = /* @__PURE__ */ mn(
  Ao,
  {
    required: {},
    optional: {
      restoreStateFrom: "restoreStateFrom",
      context: "context",
      followOutput: "followOutput",
      itemContent: "itemContent",
      groupContent: "groupContent",
      overscan: "overscan",
      increaseViewportBy: "increaseViewportBy",
      totalCount: "totalCount",
      groupCounts: "groupCounts",
      topItemCount: "topItemCount",
      firstItemIndex: "firstItemIndex",
      initialTopMostItemIndex: "initialTopMostItemIndex",
      components: "components",
      atBottomThreshold: "atBottomThreshold",
      atTopThreshold: "atTopThreshold",
      computeItemKey: "computeItemKey",
      defaultItemHeight: "defaultItemHeight",
      fixedItemHeight: "fixedItemHeight",
      itemSize: "itemSize",
      scrollSeekConfiguration: "scrollSeekConfiguration",
      headerFooterTag: "headerFooterTag",
      data: "data",
      initialItemCount: "initialItemCount",
      initialScrollTop: "initialScrollTop",
      alignToBottom: "alignToBottom",
      useWindowScroll: "useWindowScroll",
      customScrollParent: "customScrollParent",
      scrollerRef: "scrollerRef",
      logLevel: "logLevel"
    },
    methods: {
      scrollToIndex: "scrollToIndex",
      scrollIntoView: "scrollIntoView",
      scrollTo: "scrollTo",
      scrollBy: "scrollBy",
      autoscrollToBottom: "autoscrollToBottom",
      getState: "getState"
    },
    events: {
      isScrolling: "isScrolling",
      endReached: "endReached",
      startReached: "startReached",
      rangeChanged: "rangeChanged",
      atBottomStateChange: "atBottomStateChange",
      atTopStateChange: "atTopStateChange",
      totalListHeightChanged: "totalListHeightChanged",
      itemsRendered: "itemsRendered",
      groupIndices: "groupIndices"
    }
  },
  Qo
), tr = /* @__PURE__ */ _n({ usePublisher: gt, useEmitterValue: A, useEmitter: Ve }), er = /* @__PURE__ */ Vn({ usePublisher: gt, useEmitterValue: A, useEmitter: Ve }), xr = Xo, nn = {
  items: [],
  offsetBottom: 0,
  offsetTop: 0,
  top: 0,
  bottom: 0,
  itemHeight: 0,
  itemWidth: 0
}, nr = {
  items: [{ index: 0 }],
  offsetBottom: 0,
  offsetTop: 0,
  top: 0,
  bottom: 0,
  itemHeight: 0,
  itemWidth: 0
}, { round: on, ceil: rn, floor: fe, min: ve, max: jt } = Math;
function or(t) {
  return {
    ...nr,
    items: t
  };
}
function sn(t, e, n) {
  return Array.from({ length: e - t + 1 }).map((o, r) => {
    const s = n === null ? null : n[r + t];
    return { index: r + t, data: s };
  });
}
function rr(t, e) {
  return t && t.column === e.column && t.row === e.row;
}
function se(t, e) {
  return t && t.width === e.width && t.height === e.height;
}
const sr = /* @__PURE__ */ U(
  ([
    { overscan: t, visibleRange: e, listBoundary: n },
    { scrollTop: o, viewportHeight: r, scrollBy: s, scrollTo: i, smoothScrollTargetReached: l, scrollContainerState: c, footerHeight: f, headerHeight: a },
    p,
    x,
    { propsReady: E, didMount: R },
    { windowViewportRect: T, useWindowScroll: H, customScrollParent: d, windowScrollContainerState: h, windowScrollTo: I },
    y
  ]) => {
    const m = C(0), u = C(0), v = C(nn), L = C({ height: 0, width: 0 }), B = C({ height: 0, width: 0 }), F = W(), V = W(), D = C(0), K = C(null), z = C({ row: 0, column: 0 }), M = W(), q = W(), ot = C(!1), mt = C(0), It = C(!0), ht = C(!1);
    $(
      g(
        R,
        G(mt),
        k(([w, N]) => !!N)
      ),
      () => {
        _(It, !1), _(u, 0);
      }
    ), $(
      g(
        nt(R, It, B, L, mt, ht),
        k(([w, N, Y, X, , st]) => w && !N && Y.height !== 0 && X.height !== 0 && !st)
      ),
      ([, , , , w]) => {
        _(ht, !0), ke(1, () => {
          _(F, w);
        }), Ct(g(o), () => {
          _(n, [0, 0]), _(It, !0);
        });
      }
    ), O(
      g(
        q,
        k((w) => w != null && w.scrollTop > 0),
        wt(0)
      ),
      u
    ), $(
      g(
        R,
        G(q),
        k(([, w]) => w != null)
      ),
      ([, w]) => {
        w && (_(L, w.viewport), _(B, w == null ? void 0 : w.item), _(z, w.gap), w.scrollTop > 0 && (_(ot, !0), Ct(g(o, Wt(1)), (N) => {
          _(ot, !1);
        }), _(i, { top: w.scrollTop })));
      }
    ), O(
      g(
        L,
        b(({ height: w }) => w)
      ),
      r
    ), O(
      g(
        nt(
          P(L, se),
          P(B, se),
          P(z, (w, N) => w && w.column === N.column && w.row === N.row),
          P(o)
        ),
        b(([w, N, Y, X]) => ({
          viewport: w,
          item: N,
          gap: Y,
          scrollTop: X
        }))
      ),
      M
    ), O(
      g(
        nt(
          P(m),
          e,
          P(z, rr),
          P(B, se),
          P(L, se),
          P(K),
          P(u),
          P(ot),
          P(It),
          P(mt)
        ),
        k(([, , , , , , , w]) => !w),
        b(
          ([
            w,
            [N, Y],
            X,
            st,
            St,
            et,
            Mt,
            ,
            ee,
            Rt
          ]) => {
            const { row: pt, column: An } = X, { height: ne, width: Ie } = st, { width: Ge } = St;
            if (Mt === 0 && (w === 0 || Ge === 0))
              return nn;
            if (Ie === 0) {
              const Se = Fe(Rt, w), Wn = Se === 0 ? Math.max(Mt - 1, 0) : Se;
              return or(sn(Se, Wn, et));
            }
            const oe = Gn(Ge, Ie, An);
            let At, Ft;
            ee ? N === 0 && Y === 0 && Mt > 0 ? (At = 0, Ft = Mt - 1) : (At = oe * fe((N + pt) / (ne + pt)), Ft = oe * rn((Y + pt) / (ne + pt)) - 1, Ft = ve(w - 1, jt(Ft, oe - 1)), At = ve(Ft, jt(0, At))) : (At = 0, Ft = -1);
            const Me = sn(At, Ft, et), { top: Ae, bottom: Ne } = ln(St, X, st, Me), We = rn(w / oe), Nn = We * ne + (We - 1) * pt - Ne;
            return { items: Me, offsetTop: Ae, offsetBottom: Nn, top: Ae, bottom: Ne, itemHeight: ne, itemWidth: Ie };
          }
        )
      ),
      v
    ), O(
      g(
        K,
        k((w) => w !== null),
        b((w) => w.length)
      ),
      m
    ), O(
      g(
        nt(L, B, v, z),
        k(([w, N, { items: Y }]) => Y.length > 0 && N.height !== 0 && w.height !== 0),
        b(([w, N, { items: Y }, X]) => {
          const { top: st, bottom: St } = ln(w, X, N, Y);
          return [st, St];
        }),
        Z(Jt)
      ),
      n
    );
    const rt = C(!1);
    O(
      g(
        o,
        G(rt),
        b(([w, N]) => N || w !== 0)
      ),
      rt
    );
    const zt = ft(
      g(
        P(v),
        k(({ items: w }) => w.length > 0),
        G(m, rt),
        k(([{ items: w }, N, Y]) => Y && w[w.length - 1].index === N - 1),
        b(([, w]) => w - 1),
        Z()
      )
    ), yt = ft(
      g(
        P(v),
        k(({ items: w }) => w.length > 0 && w[0].index === 0),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        wt(0),
        Z()
      )
    ), Gt = ft(
      g(
        P(v),
        G(ot),
        k(([{ items: w }, N]) => w.length > 0 && !N),
        b(([{ items: w }]) => ({
          startIndex: w[0].index,
          endIndex: w[w.length - 1].index
        })),
        Z(Rn),
        bt(0)
      )
    );
    O(Gt, x.scrollSeekRangeChanged), O(
      g(
        F,
        G(L, B, m, z),
        b(([w, N, Y, X, st]) => {
          const St = En(w), { align: et, behavior: Mt, offset: ee } = St;
          let Rt = St.index;
          Rt === "LAST" && (Rt = X - 1), Rt = jt(0, Rt, ve(X - 1, Rt));
          let pt = be(N, st, Y, Rt);
          return et === "end" ? pt = on(pt - N.height + Y.height) : et === "center" && (pt = on(pt - N.height / 2 + Y.height / 2)), ee && (pt += ee), { top: pt, behavior: Mt };
        })
      ),
      i
    );
    const kt = ct(
      g(
        v,
        b((w) => w.offsetBottom + w.bottom)
      ),
      0
    );
    return O(
      g(
        T,
        b((w) => ({ width: w.visibleWidth, height: w.visibleHeight }))
      ),
      L
    ), {
      // input
      data: K,
      totalCount: m,
      viewportDimensions: L,
      itemDimensions: B,
      scrollTop: o,
      scrollHeight: V,
      overscan: t,
      scrollBy: s,
      scrollTo: i,
      scrollToIndex: F,
      smoothScrollTargetReached: l,
      windowViewportRect: T,
      windowScrollTo: I,
      useWindowScroll: H,
      customScrollParent: d,
      windowScrollContainerState: h,
      deviation: D,
      scrollContainerState: c,
      footerHeight: f,
      headerHeight: a,
      initialItemCount: u,
      gap: z,
      restoreStateFrom: q,
      ...x,
      initialTopMostItemIndex: mt,
      // output
      gridState: v,
      totalListHeight: kt,
      ...p,
      startReached: yt,
      endReached: zt,
      rangeChanged: Gt,
      stateChanged: M,
      propsReady: E,
      stateRestoreInProgress: ot,
      ...y
    };
  },
  J(Pe, ut, Xt, Hn, Lt, _e, Ot)
);
function ln(t, e, n, o) {
  const { height: r } = n;
  if (r === void 0 || o.length === 0)
    return { top: 0, bottom: 0 };
  const s = be(t, e, n, o[0].index), i = be(t, e, n, o[o.length - 1].index) + r;
  return { top: s, bottom: i };
}
function be(t, e, n, o) {
  const r = Gn(t.width, n.width, e.column), s = fe(o / r), i = s * n.height + jt(0, s - 1) * e.row;
  return i > 0 ? i + e.row : i;
}
function Gn(t, e, n) {
  return jt(1, fe((t + n) / (fe(e) + n)));
}
const ir = /* @__PURE__ */ U(() => {
  const t = C((f) => `Item ${f}`), e = C({}), n = C(null), o = C("virtuoso-grid-item"), r = C("virtuoso-grid-list"), s = C(Pn), i = C("div"), l = C($t), c = (f, a = null) => ct(
    g(
      e,
      b((p) => p[f]),
      Z()
    ),
    a
  );
  return {
    context: n,
    itemContent: t,
    components: e,
    computeItemKey: s,
    itemClassName: o,
    listClassName: r,
    headerFooterTag: i,
    scrollerRef: l,
    FooterComponent: c("Footer"),
    HeaderComponent: c("Header"),
    ListComponent: c("List", "div"),
    ItemComponent: c("Item", "div"),
    ScrollerComponent: c("Scroller", "div"),
    ScrollSeekPlaceholder: c("ScrollSeekPlaceholder", "div")
  };
}), lr = /* @__PURE__ */ U(([t, e]) => ({ ...t, ...e }), J(sr, ir)), cr = /* @__PURE__ */ S.memo(function() {
  const e = Q("gridState"), n = Q("listClassName"), o = Q("itemClassName"), r = Q("itemContent"), s = Q("computeItemKey"), i = Q("isSeeking"), l = xt("scrollHeight"), c = Q("ItemComponent"), f = Q("ListComponent"), a = Q("ScrollSeekPlaceholder"), p = Q("context"), x = xt("itemDimensions"), E = xt("gap"), R = Q("log"), T = Q("stateRestoreInProgress"), H = _t((d) => {
    const h = d.parentElement.parentElement.scrollHeight;
    l(h);
    const I = d.firstChild;
    if (I) {
      const { width: y, height: m } = I.getBoundingClientRect();
      x({ width: y, height: m });
    }
    E({
      row: cn("row-gap", getComputedStyle(d).rowGap, R),
      column: cn("column-gap", getComputedStyle(d).columnGap, R)
    });
  });
  return T ? null : S.createElement(
    f,
    {
      ref: H,
      className: n,
      ...lt(f, p),
      style: { paddingTop: e.offsetTop, paddingBottom: e.offsetBottom },
      "data-testid": "virtuoso-item-list"
    },
    e.items.map((d) => {
      const h = s(d.index, d.data, p);
      return i ? S.createElement(a, {
        key: h,
        ...lt(a, p),
        index: d.index,
        height: e.itemHeight,
        width: e.itemWidth
      }) : S.createElement(
        c,
        { ...lt(c, p), className: o, "data-index": d.index, key: h },
        r(d.index, d.data, p)
      );
    })
  );
}), ur = S.memo(function() {
  const e = Q("HeaderComponent"), n = xt("headerHeight"), o = Q("headerFooterTag"), r = _t((i) => n(Ht(i, "height"))), s = Q("context");
  return e ? S.createElement(o, { ref: r }, S.createElement(e, lt(e, s))) : null;
}), ar = S.memo(function() {
  const e = Q("FooterComponent"), n = xt("footerHeight"), o = Q("headerFooterTag"), r = _t((i) => n(Ht(i, "height"))), s = Q("context");
  return e ? S.createElement(o, { ref: r }, S.createElement(e, lt(e, s))) : null;
}), fr = ({ children: t }) => {
  const e = S.useContext(Fn), n = xt("itemDimensions"), o = xt("viewportDimensions"), r = _t((s) => {
    o(s.getBoundingClientRect());
  });
  return S.useEffect(() => {
    e && (o({ height: e.viewportHeight, width: e.viewportWidth }), n({ height: e.itemHeight, width: e.itemWidth }));
  }, [e, o, n]), /* @__PURE__ */ S.createElement("div", { style: ge(!1), ref: r }, t);
}, dr = ({ children: t }) => {
  const e = S.useContext(Fn), n = xt("windowViewportRect"), o = xt("itemDimensions"), r = Q("customScrollParent"), s = zn(n, r);
  return S.useEffect(() => {
    e && (o({ height: e.itemHeight, width: e.itemWidth }), n({ offsetTop: 0, visibleHeight: e.viewportHeight, visibleWidth: e.viewportWidth }));
  }, [e, n, o]), /* @__PURE__ */ S.createElement("div", { ref: s, style: ge(!1) }, t);
}, mr = /* @__PURE__ */ S.memo(function({ ...e }) {
  const n = Q("useWindowScroll"), o = Q("customScrollParent"), r = o || n ? pr : hr, s = o || n ? dr : fr;
  return /* @__PURE__ */ S.createElement(r, { ...e }, /* @__PURE__ */ S.createElement(s, null, /* @__PURE__ */ S.createElement(ur, null), /* @__PURE__ */ S.createElement(cr, null), /* @__PURE__ */ S.createElement(ar, null)));
}), {
  Component: Tr,
  usePublisher: xt,
  useEmitterValue: Q,
  useEmitter: Mn
} = /* @__PURE__ */ mn(
  lr,
  {
    optional: {
      context: "context",
      totalCount: "totalCount",
      overscan: "overscan",
      itemContent: "itemContent",
      components: "components",
      computeItemKey: "computeItemKey",
      data: "data",
      initialItemCount: "initialItemCount",
      scrollSeekConfiguration: "scrollSeekConfiguration",
      headerFooterTag: "headerFooterTag",
      listClassName: "listClassName",
      itemClassName: "itemClassName",
      useWindowScroll: "useWindowScroll",
      customScrollParent: "customScrollParent",
      scrollerRef: "scrollerRef",
      logLevel: "logLevel",
      restoreStateFrom: "restoreStateFrom",
      initialTopMostItemIndex: "initialTopMostItemIndex"
    },
    methods: {
      scrollTo: "scrollTo",
      scrollBy: "scrollBy",
      scrollToIndex: "scrollToIndex"
    },
    events: {
      isScrolling: "isScrolling",
      endReached: "endReached",
      startReached: "startReached",
      rangeChanged: "rangeChanged",
      atBottomStateChange: "atBottomStateChange",
      atTopStateChange: "atTopStateChange",
      stateChanged: "stateChanged"
    }
  },
  mr
), hr = /* @__PURE__ */ _n({ usePublisher: xt, useEmitterValue: Q, useEmitter: Mn }), pr = /* @__PURE__ */ Vn({ usePublisher: xt, useEmitterValue: Q, useEmitter: Mn });
function cn(t, e, n) {
  return e !== "normal" && !(e != null && e.endsWith("px")) && n(`${t} was not resolved to pixel value correctly`, e, dt.WARN), e === "normal" ? 0 : parseInt(e ?? "0", 10);
}
export {
  dt as LogLevel,
  xr as Virtuoso,
  Fn as VirtuosoGridMockContext,
  kn as VirtuosoMockContext
};
