import { color as o } from "../../../value/types/color/index.js";
import { filter as r } from "../../../value/types/complex/filter.js";
import { numberValueTypes as l } from "./number.js";
const t = {
  ...l,
  // Color props
  color: o,
  backgroundColor: o,
  outlineColor: o,
  fill: o,
  stroke: o,
  // Border props
  borderColor: o,
  borderTopColor: o,
  borderRightColor: o,
  borderBottomColor: o,
  borderLeftColor: o,
  filter: r,
  WebkitFilter: r
}, m = (e) => t[e];
export {
  t as defaultValueTypes,
  m as getDefaultValueType
};
