import { FullConversationFragment } from '@generated/graphql';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { Box } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { IconReport } from '@shared/icons/IconReport';
import * as React from 'react';
import { conversationTitleBarEvent } from '../../analytics';
export const ReportButton: React.FC<{
  otherParticipant: FullConversationFragment['otherParticipants'][0];
}> = observer('ReportButton', ({
  otherParticipant
}) => {
  const i18n = useService($I18n);
  const executeSlashCommand = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  const openComplaintsMenu = () => {
    conversationTitleBarEvent.track('Titlebar_AdministrationActions');
    executeSlashCommand(`/opensystemapp UserReport:{selectedUser:'${otherParticipant.nick}'}`);
  };
  return <div key="IconReport" className={_c0}>
			<IconReport ariaLabel={i18n.format(declareFormat({
      id: 'REPORT_CONVERSATION',
      defaultFormat: 'Report Conversation'
    }))} size="large" onPress={openComplaintsMenu} />
		</div>;
});
const _c0 = " Knu-Box ";