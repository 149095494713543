import { MutableRefObject, useEffect } from 'react';

/**
 * Hook that handles clicks outside the passed ref
 */
export function useClickOutside(ref: MutableRefObject<any>, onClickOutside?: () => any): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (onClickOutside && ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}