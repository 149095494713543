import { useService } from '@knuddels-app/DependencyInjection';
import { $ContactsService } from '@knuddelsModules/Contacts';
import { useContext } from 'react';
import { ProfileFriendsContext } from './ProfileFriendsContext';

export const useFriendsState = (isCurrentUser: boolean) => {
	const friendQuery = useContext(ProfileFriendsContext);
	const contactsService = useService($ContactsService);

	return {
		friends: isCurrentUser
			? // using this contacts friendlist to get changes for your own profile
			  // (e.g. so removing friends actually looks like it works >.<)
			  contactsService.getContactsForTab('friends')
			: friendQuery.__typename === 'FriendList'
			? friendQuery.friends
			: undefined,
		__typename: friendQuery.__typename,
	};
};
