import * as React from 'react';
import { declareProps, useService } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { createAccessiblePointerEventHandler, Flex, FlexCol, IconInfo, Panel, PanelsList, PointerEvent, rgb, Tabs, Text, ThemeOverride, useTheme, useTransparentAccentColor, toPointerHandler, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $SmileyService } from '@knuddelsModules/AutocompleteInputBar';
// tslint:disable-next-line: no-module-bleeding
import { BuyableSmileyFeature, SmileyFeature as SmileyFeatureData } from '@knuddelsModules/AutocompleteInputBar/bundle/services/SmileyService';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';

// tslint:disable-next-line: no-module-bleeding
import { observer } from '@knuddels-app/mobx';
import { VirtuosoHandle } from 'react-virtuoso';
// tslint:disable-next-line: no-module-bleeding
import { SmileyImage } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/SmileyImage';
import { IconFlash } from '@shared/icons/IconFlash';
import { PlaceholderContent, SkeletonAnimation } from '@shared/components';
import { $CommandService } from '@knuddels-app/Commands';
import { BoxHeader, ScrollIndicatorProvider } from '@knuddelsModules/Smileybox/bundle/components/BoxHeader';
import { usePromise } from '@knuddels-app/tools/usePromise';
import { ContainerContext, ContextMenu, Grid, Item, useContextMenu } from './SmileyGrid';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { useMySmileysChangedObserver } from './useMySmileysChangedObserver';
import { IconStar } from '@shared/icons/IconStar';
import { IconStarOff } from '@shared/icons/IconStarOff';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { SmileyboxAnalyticsProvider, useSmileyBoxTrackEvent } from './SmileyboxAnalyticsContext';
const LIMIT = 10;
const useSmileyFeatures = (getter: (typeof $SmileyService.T)['getConsumableSmileyFeatures'] | (typeof $SmileyService.T)['getSmileyFeatures']) => {
  const [loading, setLoading] = React.useState(true);
  const [features, setFeatures] = React.useState<SmileyFeatureData[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const smileyService = useService($SmileyService);
  React.useEffect(() => {
    return smileyService.addSmileyUsedObserver(smileyId => {
      setFeatures(currentFeatures => currentFeatures.map(f => {
        if (f.smileyId === smileyId) {
          smileyService.handleFeatureUsed(f);
          smileyService.pushLastUsedFeature(f);
          return {
            ...f,
            notUsableUntil: Date.now() + f.cooldown
          };
        }
        return f;
      }));
    });
  }, []);
  useMySmileysChangedObserver(newSmileys => {
    const ids = newSmileys.map(s => s.id);
    setFeatures(currentFeatures => {
      return currentFeatures.map(f => {
        if (ids.includes(f.smileyId)) {
          return {
            ...f,
            owned: true
          };
        }
        return f;
      });
    });
  });
  const requestMore = (index: number) => {
    if (!hasMore) {
      return;
    }
    getter(index + 1, LIMIT).then(f => {
      setFeatures([...features, ...f.features]);
      setHasMore(f.hasMore);
      setLoading(false);
    });
  };
  React.useEffect(() => {
    requestMore(-1);
  }, []);
  return {
    features,
    requestMore,
    loading
  };
};
const isBuyable = (feature: SmileyFeatureData | BuyableSmileyFeature): feature is BuyableSmileyFeature => {
  return (feature as BuyableSmileyFeature).price !== undefined;
};
const FeatureTab: React.FC<{
  buyableFeatures?: BuyableSmileyFeature[];
  getter: (typeof $SmileyService.T)['getConsumableSmileyFeatures'] | (typeof $SmileyService.T)['getSmileyFeatures'];
  editorRef: React.RefObject<RichTextEditorRef>;
  type: 'consumable' | 'non-consumable';
}> = observer('FeatureTab', ({
  getter,
  editorRef,
  type,
  buyableFeatures = []
}) => {
  const {
    features,
    requestMore,
    loading
  } = useSmileyFeatures(getter);
  const smileyService = useService($SmileyService);
  const theme = useTheme();
  if (loading) {
    return <Skeleton />;
  }
  return <div className={_c0}>
				{features.length === 0 ? <div className={_c1}>
						<PlaceholderContent setBgTop={false} fullHeight py={'none'} description={'1x Features sind Smiley-Features, die du einmalig ausführen kannst. Du erhältst sie z.B.  über Kalender oder die Weltreise.'} title={'Noch keine 1x-Features in Sicht.'} image={require('@shared/icons/sm_17-04-Columbus_ani.gif')} imageWidth={69} imageHeight={63} />
					</div> : <Grid groups={[type === 'non-consumable' && {
      label: 'Zuletzt verwendet',
      items: smileyService.lastUsedFeature
    }, type === 'non-consumable' && {
      label: 'Favoriten',
      items: smileyService.favoriteFeature
    }, {
      label: 'Features gegen Knuddel direkt starten',
      badgeText: 'NEU!',
      items: buyableFeatures
    }, type === 'non-consumable' && {
      label: 'Smiley Rotation',
      items: smileyService.rotationFeatures
    }, {
      label: 'Alle Features',
      items: features
    }].filter(Boolean)} maxItemsPerRow={1} itemSize={76} pr={theme.spacings.small} pl={16} itemSpacing={12} bottomSpacing={theme.spacings.small} onEndReached={requestMore} renderItem={data => {
      if (isBuyable(data)) {
        return <SmileyFeature key={data.feature.name} feature={data.feature} actions={<BuyableFeatureAction feature={data} editorRef={editorRef} />} />;
      }
      return <SmileyFeature key={data.name} feature={data} actions={<FeatureAction feature={data} editorRef={editorRef} />} />;
    }} />}
			</div>;
});
const cache = new Map<string, Promise<SmileyFeatureData[]>>();
const FeatureSearchResults: React.FC<{
  query: string;
  editorRef: React.RefObject<RichTextEditorRef>;
}> = React.memo(({
  query,
  editorRef
}) => {
  const smileyService = useService($SmileyService);
  const lastUsed = React.useRef<Record<number, number>>({});
  const rerender = React.useState({})[1];
  const scrollToId = React.useRef<number>(null);
  const virtualListRef = React.useRef<VirtuosoHandle>(null);
  const theme = useTheme();
  React.useEffect(() => {
    return smileyService.addSmileyUsedObserver(smileyId => {
      lastUsed.current = {
        ...lastUsed.current,
        [smileyId]: Date.now()
      };
      rerender({});
    });
  }, []);
  useMySmileysChangedObserver(newSmileyIds => {
    cache.clear();
    rerender({});
    scrollToId.current = newSmileyIds[newSmileyIds.length - 1].id;
  });
  React.useEffect(() => {
    return () => {
      cache.clear();
    };
  }, []);
  if (!cache.has(query)) {
    cache.set(query, smileyService.searchSmileyFeatures(query));
  }
  const features = usePromise(cache.get(query));
  const actualFeatures = React.useMemo(() => {
    return features.map(f => {
      if (lastUsed.current[f.smileyId]) {
        return {
          ...f,
          notUsableUntil: lastUsed.current[f.smileyId] + f.cooldown
        };
      }
      return f;
    });
  }, [features, lastUsed.current]);
  React.useEffect(() => {
    if (scrollToId.current && actualFeatures.length) {
      const index = actualFeatures.findIndex(f => f.smileyId === scrollToId.current);
      virtualListRef.current.scrollToIndex({
        index: index ?? 0,
        behavior: 'smooth'
      });
      scrollToId.current = null;
    }
  }, [actualFeatures]);
  return <Grid gridRef={virtualListRef} groups={[{
    label: 'Suchergebnisse',
    items: actualFeatures
  }]} itemSize={76} maxItemsPerRow={1} pl={16} pr={theme.spacings.small} itemSpacing={12} renderItem={data => {
    return <SmileyFeature key={data.name} feature={data} actions={<FeatureAction feature={data} editorRef={editorRef} />} />;
  }} />;
});
FeatureSearchResults.displayName = 'FeatureSearchResults';
export const FeatureBox = injectedComponent({
  name: 'FeatureBox',
  props: declareProps<{
    containerRef: React.RefObject<HTMLDivElement>;
    editorRef: React.RefObject<RichTextEditorRef>;
  }>()
}, ({
  editorRef,
  containerRef
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const firebaseAnalytics = useService($FirebaseAnalyticsService);
  const [searchValue, setSearchValue] = React.useState('');
  const [query, setQuery] = React.useState('');
  const keyboardService = useService($KeyboardService);
  const [, startTransition] = (React as any).useTransition();
  const smileyService = useService($SmileyService);
  const handle = (e: PointerEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  React.useEffect(() => {
    if (activeIndex === 0) {
      firebaseAnalytics.logEvent('Featurelist_Features', 'Opened_');
    } else {
      firebaseAnalytics.logEvent('Featurelist_OneTimeFeatures', 'Opened_');
    }
  }, [activeIndex]);
  return <ContainerContext.Provider value={containerRef}>
				<ScrollIndicatorProvider>
					<div onPointerDown={toPointerHandler(handle)} onClick={toPointerHandler(handle)} className={_c2}>
						<SmileyboxAnalyticsProvider context={'Featurelist_Search'}>
							<BoxHeader editorRef={editorRef} tabs={['Features', '1x Features']} searchValue={searchValue} setSearchValue={value => {
            setSearchValue(value);
            startTransition(() => {
              setQuery(value);
            });
          }} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
						</SmileyboxAnalyticsProvider>

						<div style={{
          display: 'contents'
        }} onPointerDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()} onTouchMove={e => {
          if (keyboardService.focusable.parentFocusable) {
            e.preventDefault();
            keyboardService.hideKeyboard();
          }
        }}>
							<React.Suspense fallback={<Skeleton />}>
								{!query.length ? <Tabs value={activeIndex} mountingType={'unmountInactive'}>
										<PanelsList>
											<Panel>
												<SmileyboxAnalyticsProvider context={'Featurelist_Features'}>
													<FeatureTab type={'non-consumable'} getter={smileyService.getSmileyFeatures} editorRef={editorRef} />
												</SmileyboxAnalyticsProvider>
											</Panel>
											<Panel>
												<SmileyboxAnalyticsProvider context={'Featurelist_OneTimeFeatures'}>
													<FeatureTab type={'consumable'} buyableFeatures={smileyService.featureForKnuddel} getter={smileyService.getConsumableSmileyFeatures} editorRef={editorRef} />
												</SmileyboxAnalyticsProvider>
											</Panel>
										</PanelsList>
									</Tabs> : <FeatureSearchResults query={query} editorRef={editorRef} />}
							</React.Suspense>
						</div>
					</div>
				</ScrollIndicatorProvider>
			</ContainerContext.Provider>;
});
const Skeleton = () => <div className={_c3}>
		{Array.from({
    length: 3
  }).map((_, i) => <SkeletonAnimation key={i}>
				<div className={_c4}>
					<div className={_c5 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					<div className={_c6}>
						<div className={_c7 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
						<div className={_c8 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					</div>
				</div>
			</SkeletonAnimation>)}
	</div>;
const SmileyFeature: React.FC<{
  feature: SmileyFeatureData;
  actions?: React.ReactNode;
}> = observer('SmileyFeature', ({
  feature,
  actions
}) => {
  const theme = useTheme();
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    triggerProps,
    closeMenu,
    contextMenuPosition
  } = useContextMenu(ref, true);
  const smileyService = useService($SmileyService);
  return <>
			<div className={_c9}>
				<Flex innerRef={ref} height={'full'} width={80} pointerEvents={'all'} borderRadius={'small'} placeItems={'center'} flexShrink={0} {...triggerProps} bg={theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880']} style={feature.owned ? {} : {
        filter: 'grayscale(1)'
      }}>
					<SmileyImage imageUrl={feature?.url} />
				</Flex>
				<div className={_c10}>
					<div className={_c11}>
						{feature ? <>
								<Text bold={true} type={'body2'} className={_c12}>
									{feature.name}
								</Text>
								<Text state={'tertiary'} type={'tiny'} numberOfLines={1} className={_c13}>
									<span dangerouslySetInnerHTML={{
                __html: feature.command.description
              }} />
								</Text>
							</> : <>
								<SkeletonAnimation>
									<div className={_c14 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
								</SkeletonAnimation>
								<SkeletonAnimation>
									<div className={_c15 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
								</SkeletonAnimation>
							</>}
					</div>
					<div className={_c16}>
						{actions}
					</div>
				</div>
			</div>
			{contextMenuPosition && <ContextMenu onClose={closeMenu} renderContextMenu={{
      getItems: async () => feature.owned ? [smileyService.favoriteFeature.find(f => f.smileyId === feature.smileyId) ? {
        type: 'unfavorite'
      } : {
        type: 'favorite'
      }, {
        type: 'info'
      }] : [{
        type: 'info'
      }],
      renderItem: item => {
        return <Item onPress={() => {
          if (item.type === 'info') {
            smileyService.showSmileyDetails(feature.smileyId);
            return;
          }
          if (item.type === 'favorite') {
            smileyService.addFavoriteFeature(feature);
          } else {
            smileyService.removeFavoriteFeature(feature);
          }
        }}>
									{item.type === 'info' ? <IconInfo size={'large'} /> : item.type === 'favorite' ? <IconStar size={'large'} /> : <IconStarOff size={'large'} />}
								</Item>;
      }
    }} renderContextPosition={contextMenuPosition} />}
		</>;
});
const BuyableFeatureAction: React.FC<{
  feature: BuyableSmileyFeature;
  editorRef: React.RefObject<RichTextEditorRef>;
}> = ({
  feature,
  editorRef
}) => {
  const smileyService = useService($SmileyService);
  const handleFeature = useHandleFeature(editorRef);
  const snackbarService = useService($SnackbarService);
  return <>
			<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: () => {
        smileyService.buyFeatureForKnuddel(feature.buyReference).then(success => {
          if (!success) {
            snackbarService.showGenericError();
            return;
          }
          handleFeature(feature.feature);
        });
      }
    })} className={_c17 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'tiny'} bold={true} className={_c18}>
					Für {feature.price} Knuddel starten
				</Text>
			</div>
		</>;
};
const useHandleFeature = (editorRef: React.RefObject<RichTextEditorRef>) => {
  const commandService = useService($CommandService);
  return (feature: SmileyFeatureData) => {
    if (feature.command.autocompleteType === 'user') {
      const parameter = feature.command.parameter.trim().length ? ' ' + feature.command.parameter.trim() : '';
      editorRef.current.appendText(feature.command.command + parameter + ' @');
    } else if (feature.command.autocompleteType === 'none') {
      commandService.invokeCommand({
        commandName: feature.command.command.replace('/', ''),
        parameter: feature.command.parameter ?? ''
      });
    } else {
      editorRef.current.appendText(feature.command.command + ' ' + feature.command.parameter);
    }
  };
};
const FeatureAction: React.FC<{
  feature: SmileyFeatureData;
  editorRef: React.RefObject<RichTextEditorRef>;
}> = ({
  feature,
  editorRef
}) => {
  const smileyService = useService($SmileyService);
  const handleFeature = useHandleFeature(editorRef);
  const theme = useTheme();
  const track = useSmileyBoxTrackEvent();
  const transparentAccent = useTransparentAccentColor(0.7);
  const [isLocked, setIsLocked] = React.useState(!!feature.notUsableUntil);
  React.useEffect(() => {
    setIsLocked(!!feature.notUsableUntil);
  }, [feature.notUsableUntil]);
  return <>
			{feature.owned ? <>
					<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: () => {
          track('Start_' + feature.name);
          handleFeature(feature);
        }
      })} style={{
        background: resolveThemingValue(!isLocked ? 'accent' : (transparentAccent as ThemeOverride), "colors", useTheme())
      }} className={_c19 + ((!isLocked ? 'accent' : (transparentAccent as ThemeOverride)) ? resolveIsDarkColor(!isLocked ? 'accent' : (transparentAccent as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						{isLocked ? <Countdown onEnd={() => setIsLocked(false)} endTime={feature.notUsableUntil} /> : <>
								<IconFlash color={'white-solid-white'} size={'base'} />
								<Text type={'tiny'} bold={true} className={_c20}>
									Starten
								</Text>
							</>}
					</div>
					{feature.smileyId >= 0 && <div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: () => {
          track('MoreInfo_' + feature.name);
          smileyService.showSmileyDetails(feature.smileyId);
        }
      })} style={{
        background: resolveThemingValue(theme.id === 'dark' ? rgb(45, 45, 45) : theme.colors.basic['white-solid-880'], "colors", useTheme())
      }} className={_c21 + ((theme.id === 'dark' ? rgb(45, 45, 45) : theme.colors.basic['white-solid-880']) ? resolveIsDarkColor(theme.id === 'dark' ? rgb(45, 45, 45) : theme.colors.basic['white-solid-880'], useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<Text type={'tiny'} bold={true} style={{
          whiteSpace: 'nowrap'
        }} className={_c22}>
								Mehr Infos
							</Text>
						</div>}
					{feature.cooldown && !isLocked && <Text type={'tiny'} state={'tertiary'} numberOfLines={1} className={_c23}>
							{mapMiliSecondsToText(feature.cooldown)}
						</Text>}
				</> : <div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: () => {
        smileyService.showSmileyDetails(feature.smileyId);
      }
    })} className={_c24 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Text type={'tiny'} bold={true} style={{
        whiteSpace: 'nowrap'
      }} className={_c25}>
						Zum Shop
					</Text>
				</div>}
		</>;
};
const Countdown: React.FC<{
  endTime: number;
  onEnd: () => void;
}> = ({
  endTime,
  onEnd
}) => {
  const diff = endTime - Date.now();
  const [countDown, setCountDown] = React.useState(mapMiliSecondsToCountDown(diff));
  React.useEffect(() => {
    const interval = setInterval(() => {
      const delta = endTime - Date.now();
      if (delta <= 0) {
        clearInterval(interval);
        onEnd();
        return;
      }
      setCountDown(mapMiliSecondsToCountDown(delta));
    }, 1000);
    return () => clearInterval(interval);
  }, [endTime]);
  return <div className={_c26 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Text type={'tiny'} state={'tertiary'} className={_c27}>
				Noch {countDown}
			</Text>
		</div>;
};
const mapMiliSecondsToCountDown = (ms: number) => {
  const days = Math.floor(ms / 1000 / 60 / 60 / 24);
  const hours = Math.floor(ms / 1000 / 60 / 60 % 24);
  const minutes = Math.floor(ms / 1000 / 60 % 60);
  const seconds = Math.floor(ms / 1000 % 60);
  if (days > 0) {
    return days === 1 ? 'ein Tag' : `${days} Tage`;
  }
  return [hours, minutes, seconds].filter((v, i) => v > 0 || i > 0).map(v => v.toString().padStart(2, '0')).join(':');
};
const mapMiliSecondsToText = (ms: number) => {
  const minutes = Math.ceil(ms / 1000 / 60);
  if (minutes < 60) {
    if (minutes === 1) {
      return '1x pro Minute';
    }
    return `Alle ${minutes} Minuten`;
  }
  const hours = minutes / 60;
  if (hours === 1) {
    return '1x pro Stunde';
  }
  if (hours === 24) {
    return '1x pro Tag';
  }
  if (hours === 168) {
    return '1x pro Woche';
  }
  if (hours < 24) {
    return `Alle ${hours} Stunden`;
  }
  return `Alle ${Math.ceil(hours / 24)} Tage`;
};
const _c0 = " Knu-Flex size-full flex-1 ";
const _c1 = " Knu-Flex size-full placeItems-center ";
const _c2 = " Knu-FlexCol flex-1 ";
const _c3 = " Knu-FlexCol px-base width-full ";
const _c4 = " Knu-Flex width-full mb-12px height-64px gap-base ";
const _c5 = " Knu-Flex height-full width-80px pointerEvents-none borderRadius-small placeItems-center flexShrink-0 bg-skeleton ";
const _c6 = " Knu-FlexCol gap-tiny flex-1 pt-tiny ";
const _c7 = " Knu-Flex borderRadius-minor height-12px bg-skeleton width-half ";
const _c8 = " Knu-Flex borderRadius-minor height-12px bg-skeleton width-three-quarter ";
const _c9 = " Knu-Flex height-66px gap-base overflow-hidden ";
const _c10 = " Knu-FlexCol ";
const _c11 = " Knu-FlexCol flex-1 gap-tiny ";
const _c12 = "  ";
const _c13 = "  ";
const _c14 = " Knu-Flex height-12px borderRadius-base width-100px bg-contentLightBg ";
const _c15 = " Knu-Flex height-12px borderRadius-base width-300px bg-contentLightBg ";
const _c16 = " Knu-Flex gap-small alignItems-center ";
const _c17 = " Knu-Flex cursor-pointer bg-accent height-24px px-small placeItems-center borderRadius-small ";
const _c18 = "  ";
const _c19 = " Knu-Flex cursor-pointer height-24px px-small placeItems-center gap-1px borderRadius-small ";
const _c20 = "  ";
const _c21 = " Knu-Flex cursor-pointer height-24px px-small placeItems-center borderRadius-small ";
const _c22 = "  ";
const _c23 = "  ";
const _c24 = " Knu-Flex cursor-pointer bg-accent height-24px px-small placeItems-center borderRadius-small ";
const _c25 = "  ";
const _c26 = " Knu-Flex bg-transparentDark ";
const _c27 = "  ";