import { Box, Flex, FlexCol, FlexColProps, LayoutEvent, Text, ThemeOverride, FallbackBox } from '@knuddels/component-library';
import { PrivateMessageContext } from '@knuddelsModules/Messenger/bundle/contexts/PrivateMessageContext';
import * as React from 'react';
import { FormattedText } from './FormattedText';
import { ImageGroup } from './ImageGroup';
import { Link, LinkProps } from './Link';
type TextProps = React.ComponentProps<typeof Text> & {
  selectable?: boolean;
};
export interface FormattedTextDisplayProps {
  text: FormattedText;
  textProps?: TextProps;
  containerProps?: FlexColProps;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  disableBold?: boolean;
  forceBoldLinks?: boolean;
  disableLinks?: boolean;
  renderIndent?: boolean;
  /**
   * Appends a invisible placeholder string to the end of the text.
   * This is used to prevent the text from overlapping the time in the message.
   **/
  placeholderTime?: string;
  getHighlightedLinkColor?(text: string, href: string): string | undefined;

  /**
   * should return false if the command should be opened as an URL
   */
  onLinkClicked?(link: string): boolean;
}
const noopOnLinkClicked: Exclude<FormattedTextDisplayProps['onLinkClicked'], undefined> = () => false;
const determineRenderStyle = (t: FormattedText) => {
  if (t.kind === 'Text' || t.kind === 'Link') {
    return {
      containsImages: false,
      canRenderSingleTextComponent: true
    };
  }
  if (t.kind === 'List') {
    const {
      containsImages
    } = t.meta;
    return {
      containsImages: containsImages,
      canRenderSingleTextComponent: true
    };
  }
  return {
    containsImages: true,
    canRenderSingleTextComponent: false
  };
};
const INDENTATION = '.........';
interface State {
  width?: number;
  textOnly: boolean;
  containsImages: boolean;
}
export class FormattedTextDisplay extends React.PureComponent<FormattedTextDisplayProps, State> {
  static contextType = PrivateMessageContext;
  constructor(props: FormattedTextDisplayProps) {
    super(props);
    const t = determineRenderStyle(props.text);
    this.state = {
      textOnly: t.canRenderSingleTextComponent,
      containsImages: t.containsImages
    };
  }
  static getDerivedStateFromProps(props: FormattedTextDisplayProps): State | null {
    const t = determineRenderStyle(props.text);
    return {
      textOnly: t.canRenderSingleTextComponent,
      containsImages: t.containsImages
    };
  }
  handleLayout = (e: LayoutEvent) => {
    const newWidth = e.width;
    if (Math.abs(newWidth - (this.state.width || 0)) >= 1) {
      this.setState({
        width: newWidth
      });
    }
  };
  render(): JSX.Element {
    const content = this.renderFormattedText(this.props.text, {
      disableBoldStyle: !!this.props.disableBold,
      key: new NumberGenerator(),
      onlyText: this.state.textOnly,
      renderIndent: this.props.renderIndent ?? false
    });
    if (!this.state.textOnly) {
      // android does not allow Views in text. Views are required for smileys.
      // Thus, text wrapping must be implemented with flex.
      return <FallbackBox onLayout={this.state.containsImages ? this.handleLayout : undefined} className={_c0}>
					{this.props.prefix}
					{content}
					{this.props.suffix}
				</FallbackBox>;
    }
    return <FlexCol overflow={'visible'} position={'relative'} minWidth={0} opacity={typeof this.state?.width === 'number' || !this.state.containsImages ? 1 : 0} onLayout={this.state.containsImages ? this.handleLayout : undefined} {...this.props.containerProps}>
				<Text {...this.props.textProps} {...{
        style: {
          overflowWrap: 'anywhere'
        }
      }}>
					{this.props.prefix}
					{content}
					{this.props.suffix}
					{this.props.placeholderTime && <PlaceholderTime text={this.props.placeholderTime} />}
				</Text>
			</FlexCol>;
  }
  renderFormattedText(text: FormattedText, props: {
    disableBoldStyle: boolean;
    key: NumberGenerator;
    onlyText?: boolean;
    renderIndent?: boolean;
  }, isInsideList = false): React.ReactNode {
    const isPrivateMessage = this.context;
    const {
      getHighlightedLinkColor = (): undefined => undefined
    } = this.props;
    switch (text.kind) {
      case 'List':
        return text.items.map(node => {
          return this.renderFormattedText(node, props, true);
        });
      case 'Smiley':
        return <ImageGroup key={props.key.getNext()} onClick={this.props.onLinkClicked || noopOnLinkClicked} maxWidth={this.state?.width ?? text.data.containerWidth} imageGroupData={text.data} disableLinks={this.props.disableLinks} isSingleSmiley={!isInsideList && isPrivateMessage} />;
      case 'Text':
        {
          const textProps: TextProps & {
            key: string;
          } = {
            key: props.key.getNext() + '',
            // removing numberOfLines from Text components inside lists
            // to prevent line breaks due to display: -webkit-box
            ...(isInsideList ? {
              ...this.props.textProps,
              numberOfLines: undefined
            } : this.props.textProps),
            bold: !props.disableBoldStyle && text.style.bold,
            italic: this.props.textProps?.italic || text.style.italic
          };
          if (text.style.color) {
            textProps.color = (text.style.color as ThemeOverride);
          }
          if (text.style.state) {
            textProps.state = text.style.state;
          }
          if (!props.onlyText) {
            // split text to words to allow text wrapping
            const words = splitTextToWords(text.text);
            return words.map(word => {
              if (word === '\n') {
                // Just rendering a new line text by itself does not work properly,
                // so we simulate that by filling up remaining space in the current line
                // and force the next element to be in the next line.
                return <div key={props.key.getNext()} className={_c1} />;
              } else {
                return <Text {...textProps} key={props.key.getNext()}>
									{text.style.indented && props.renderIndent ? INDENTATION : ''}
									{word}
								</Text>;
              }
            });
          }
          return <Text {...textProps}>
						{text.style.indented && props.renderIndent ? INDENTATION : ''}
						{text.text}
					</Text>;
        }
      case 'Link':
        {
          const highlightedLinkColor = getHighlightedLinkColor(text.text, text.href);
          const bold = !props.disableBoldStyle && (text.style.bold || this.props.forceBoldLinks);
          const linkProps: LinkProps & {
            key: string;
          } = {
            disabled: this.props.disableLinks,
            command: text.href,
            key: props.key.getNext() + '',
            overrideColor: !this.props.disableLinks ? text.style.color ?? highlightedLinkColor : undefined,
            bold,
            italic: text.style.italic || this.props.textProps?.italic,
            onClick: this.props.onLinkClicked || noopOnLinkClicked
          };

          // Do we need to split words here for android?

          return <Link {...linkProps}>
						{text.style.indented && props.renderIndent ? INDENTATION : ''}
						{text.text}
					</Link>;
        }
      default:
        // TODO Use error boundaries
        // assertUnreachable(text);
        console.error('Unrecognized FormattedText element ' + (text as any).kind);
        return null;
    }
  }
}
class NumberGenerator {
  private cur = 0;
  public getNext(): number {
    return this.cur++;
  }
}

/**
 * Splits the given string into individual words. New lines are separate words in the
 * returned array.
 */
function splitTextToWords(str: string): (string | '\n')[] {
  const lines = str.split('\n');
  const wordsPerLine = lines.map((line, index) => {
    const wordsInLine = splitLineToWords(line);
    if (index < lines.length - 1) {
      wordsInLine.push('\n');
    }
    return wordsInLine;
  });
  return ([] as string[]).concat(...wordsPerLine);
}
function splitLineToWords(line: string): string[] {
  return line.split(' ').map((word, index, list) => {
    if (index < list.length - 1) {
      return word + ' ';
    }
    return word;
  });
}
const PlaceholderTime = ({
  text
}: {
  text: string;
}) => <Box ariaHidden pointerEvents={'none'} opacity={0} ml={'base'} style={{
  display: 'inline-block',
  height: 0,
  overflow: 'hidden'
}}>
		<Text type={'tiny'} className={_c2}>{text}</Text>
	</Box>;
const _c0 = " Knu-Flex position-relative minWidth-0-px alignItems-baseline justifyContent-flex-start overflow-visible flexWrap-wrap ";
const _c1 = " Knu-FlexCol width-5000px ";
const _c2 = "  ";