import * as React from 'react';
import { Box, FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Components, StateSnapshot, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { ChatVirtualListItem, ChatVirtualListProps } from './ChatVirtualListProps';
import { useResizeWithScroll } from './useResizeWithScroll';
import { debounce } from '@shared/components';
import { OS, os } from '@shared/components/tools/os';
const List: Components['List'] = React.forwardRef((props, ref) => {
  const {
    children,
    ...remainingProps
  } = props;
  return <div className={_c0}>
			<div className={_c1}>
				{(props.context as any).header}
			</div>
			<div className={_c2}>
				<div {...remainingProps} ref={ref} style={{
        ...props.style,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        paddingLeft: 8,
        paddingRight: 8
      }}>
					{children}
				</div>
			</div>
		</div>;
});
List.displayName = 'List';
let snapshot: {
  id: string;
  state: StateSnapshot;
  scrolledToEnd: boolean;
} | null = null;
export const getSnapshot = (id: string) => {
  if (snapshot && snapshot.id === id) {
    return snapshot;
  }
  return null;
};
const setSnapshot = debounce((id: string, state: StateSnapshot, scrolledToEnd: boolean) => {
  // virtuoso sometimes calls this with scrollTop === 0 on initial render, even when the scroll position is not 0
  if (state.scrollTop === 0) {
    return;
  }
  snapshot = {
    id,
    state,
    scrolledToEnd
  };
});
function InnerView<T extends ChatVirtualListItem>(props: ChatVirtualListProps<T> & {
  scrollViewRef: React.RefObject<VirtuosoHandle>;
}): React.ReactElement {
  const scrolledToEnd = React.useRef(getSnapshot(props.channelId)?.scrolledToEnd ?? true);
  const followOutputRef = React.useRef(scrolledToEnd.current);
  const isScrollingRef = React.useRef(false);
  const scrollTopRef = React.useRef(0);
  const listRef = React.useRef<HTMLDivElement>(null);
  const prevItemsLengthRef = React.useRef(props.items.length);
  useResizeWithScroll('chat-virtual-list', scrolledToEnd, () => {
    if (props.scrollViewRef.current) {
      if (scrolledToEnd.current) {
        props.scrollViewRef.current.scrollToIndex({
          index: 'LAST',
          behavior: 'smooth'
        });
        return;
      }
      props.scrollViewRef.current.scrollToIndex({
        index: prevItemsLengthRef.current - 1
      });
    }
  });
  React.useEffect(() => {
    setTimeout(() => {
      if (followOutputRef.current && props.scrollViewRef.current) {
        props.scrollViewRef.current.scrollToIndex({
          index: 'LAST',
          behavior: 'smooth'
        });
      }
    }, 500);
  }, []);
  React.useEffect(() => {
    prevItemsLengthRef.current = props.items.length;
  }, [props.items]);
  React.useEffect(() => {
    const listener = () => {
      if (!document.hidden && props.scrollViewRef.current && followOutputRef.current) {
        props.scrollViewRef.current.scrollToIndex({
          index: 'LAST',
          behavior: 'auto'
        });
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, []);
  React.useEffect(() => {
    if (followOutputRef.current && props.scrollViewRef.current && !document.hidden) {
      props.scrollViewRef.current.scrollToIndex({
        index: 'LAST',
        behavior: 'smooth'
      });
    }
  }, [props.items.length]);
  const initialState = React.useMemo(() => {
    /** we don't want to restore the state if scrolledToEnd is true
     * in this case we immediately scroll to the bottom, because otherwise
     * we will not account for the new items that were added
     */

    const currentSnapshot = getSnapshot(props.channelId);
    if (currentSnapshot?.scrolledToEnd) {
      return undefined;
    }
    if (currentSnapshot) {
      return currentSnapshot.state;
    }
    return undefined;
  }, [props.channelId]);
  return <Virtuoso id="chat-virtual-list" ref={props.scrollViewRef} totalCount={props.items.length + 2} isScrolling={isScrolling => {
    if (!isScrolling && listRef.current) {
      const top = scrollTopRef.current;
      const targetTop = listRef.current.scrollHeight - listRef.current.clientHeight;
      if (targetTop > top && props.scrollViewRef.current && followOutputRef.current) {
        props.scrollViewRef.current.scrollTo({
          top: targetTop,
          behavior: 'smooth'
        });
      }
    }
    isScrollingRef.current = isScrolling;
    props.isScrolling(isScrolling);
  }} restoreStateFrom={initialState} itemContent={index => {
    if (index === 0) {
      const headerHeight = props.header && props.headerHeight ? props.headerHeight + 16 : 0;
      return <div style={{
        height: resolveThemingValue(16 + headerHeight, "sizes", useTheme())
      }} className={_c3} />;
    }
    if (index === props.items.length + 1) {
      return <div className={_c4} />;
    }
    return props.renderItem(props.items[index - 1], index);
  }} components={{
    List
  }} atBottomThreshold={50} followOutput={() => {
    return followOutputRef.current ? 'smooth' : false;
  }} onScroll={e => {
    const list = e.currentTarget;
    const delta = list.scrollTop - scrollTopRef.current;
    scrollTopRef.current = list.scrollTop;
    const scrollDirection = delta > 0 ? 'down' : 'up';
    listRef.current = list;
    const deltaToBottom = list.scrollHeight - list.clientHeight - list.scrollTop;

    // scrollDirection is also up on ios during overscroll
    // in that case we still want to follow the output
    if (scrollDirection === 'up' && deltaToBottom >= 10) {
      followOutputRef.current = false;
    } else if (scrolledToEnd.current) {
      followOutputRef.current = true;
    }
  }} context={{
    header: props.header
  }} atBottomStateChange={atBottom => {
    props.scrolledToEndChanged(atBottom);
    scrolledToEnd.current = atBottom;
    if (atBottom) {
      followOutputRef.current = true;
    }
    props.scrollViewRef.current.getState(state => {
      setSnapshot(props.channelId, state, scrolledToEnd.current);
    });
  }} />;
}
const MemoizedInnerView = React.memo(InnerView);
export class ChatVirtualList<T extends ChatVirtualListItem> extends React.PureComponent<ChatVirtualListProps<T>> {
  private scrollViewRef = React.createRef<VirtuosoHandle>();
  private scrolledToEnd = true;
  render(): React.ReactElement {
    return <MemoizedInnerView {...this.props} scrolledToEndChanged={this.scrollToEndChanged} scrollViewRef={this.scrollViewRef} />;
  }
  scrollToEndChanged = (newValue: boolean): void => {
    this.props.scrolledToEndChanged?.(newValue);
    this.scrolledToEnd = newValue;
  };
  scrollToBottom(): void {
    const behavior = this.scrolledToEnd ? 'smooth' : 'auto';
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollToIndex({
        index: 'LAST',
        behavior
      });
      this.scrollViewRef.current.autoscrollToBottom();
    }
    if (os === OS.ios) {
      // this accounts for inertia scrolling on iOS, which would otherwise result in a blank screen
      setTimeout(() => {
        this.scrollViewRef.current.scrollToIndex({
          index: 'LAST',
          behavior
        });
      }, 200);
    }
  }
  scrollToIndex(index: number): void {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollToIndex({
        index: index,
        behavior: 'auto',
        align: 'start'
      });
    }
  }
  scrollTo(top: number): void {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollTo({
        top,
        behavior: 'auto'
      });
    }
  }
}
const _c0 = " Knu-FlexCol minHeight-100-percent ";
const _c1 = " Knu-Box position-absolute px-base pt-base zIndex-1 insetX-none ";
const _c2 = " Knu-FlexCol flex-1 position-relative justifyContent-flex-end ";
const _c3 = " Knu-Box ";
const _c4 = " Knu-Box height-16px ";