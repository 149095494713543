import * as React from 'react';
import { OpenDetailsProfilePhotoClickArea, ProfilePhoto } from '../PhotoDisplays/ProfilePhoto';
import { ProfileContent, ProfileHeader } from './ProfileContent';
import { ProfileBase } from './ProfileBase';
import { action, observable } from '@knuddels-app/mobx';
import { Observer, observer } from 'mobx-react';
import { SelectedProfileTabContext } from './SelectedProfileTabContext';
import { FriendStateBanner } from '../shared/FriendStateBanner';
import { Flex, FlexCol, Image, ScrollView, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { DisableScrollContext } from '@shared/helper/DisableScrollContext';
import { ProfileContentRouter } from './ProfileContentRouter';
import { ProfileCloseIcon } from './ProfileCloseIcon';
import { ProfileFAB } from './FABs/ProfileFAB';
import styles from './ProfileFadeIn.module.scss';
import { ProfileMobileSkeleton } from './ProfileMobileSkeleton';
import { ChangeProfilePictureButton } from './EditProfile/EditProfileContent/EditProfilePicture/ChangeProfilePictureButton';
import { User, UserForProfileFragment } from '@generated/graphql';
import { ProfileAlbumInfo } from '../profileQueryHelper';
@observer
export class ProfileMobile extends ProfileBase {
  @observable
  protected isScrolled = false;
  @observable
  protected hasScrolledToTheEnd = false;
  @observable
  protected iconsInHeader = false;
  private lastAdminInfoHeight: number | undefined = undefined;
  private initialAdminInfoHeight: number;
  private adminInfoHeightListener: (height: number) => void = () => {};
  protected renderBody = (width: number, height: number): React.ReactNode => {
    if (typeof this.initialAdminInfoHeight !== 'number') {
      this.initialAdminInfoHeight = height - width - 112;
    }
    if (!this.props.user) {
      return <ProfileMobileSkeleton width={width} />;
    }
    const isCurrentUser = this.props.user.id === this.props.currentUserId;
    return <div className={_c0}>
				<ProfileContentRouter nick={this.props.user.nick}>
					{this.renderProfilePhoto(width, isCurrentUser)}
					<SelectedProfileTabContext.Consumer>
						{() => <Observer>
								{() => <ScrollView width={'100%'} onScroll={e => this.onScroll(width, height, e)} hideScrollIndicator disableOverscrollBounce
            // Prevent overscrolling on mobile Safari (>= iOS 16)
            {...{
              style: {
                overscrollBehavior: 'none'
              }
            }}>
										<ScrollContainerContent currentUserId={this.props.currentUserId} iconsInHeader={this.iconsInHeader} hasScrolledToTheEnd={this.hasScrolledToTheEnd} height={height} initialAdminInfoHeight={this.initialAdminInfoHeight} onClose={this.props.onClose} photoData={this.props.photoData} registerAdminInfoTabHeightListener={this.registerAdminInfoTabHeightListener} user={this.props.user} width={width} />
									</ScrollView>}
							</Observer>}
					</SelectedProfileTabContext.Consumer>
					{this.renderAbsoluteElements()}
				</ProfileContentRouter>
			</div>;
  };
  private renderProfilePhoto = (width: number, isCurrentUser: boolean): React.ReactNode => {
    const {
      user,
      photoData
    } = this.props;
    const imageSize = width;
    return <>
				<div className={_c1}>
					<ProfilePhoto size={imageSize} user={user} />
					{user.profilePictureOverlayUrls.urlsLarge.map((overlayUrl, index) => <div key={index} className={_c2}>
								<Image src={overlayUrl} alt={''} imageRendering={'pixelated'} className={_c3} />
							</div>)}
				</div>
				<div className={_c4 + (this.isScrolled ? _c5 : _c6)}>
					<OpenDetailsProfilePhotoClickArea size={imageSize} user={user} data={photoData} />
					{!this.isScrolled && <FriendStateBanner user={user} size={'large'} />}
					{!this.isScrolled && isCurrentUser && <div className={_c7}>
							<ChangeProfilePictureButton />
						</div>}
				</div>
			</>;
  };
  private registerAdminInfoTabHeightListener = (listener: (newHeight: number) => void) => {
    this.adminInfoHeightListener = listener;
  };
  private renderAbsoluteElements = (): React.ReactNode => {
    return <Observer>
				{() => <>
						{!this.iconsInHeader && <ProfileCloseIcon onPress={this.props.onClose} />}
						<ProfileFAB {...this.props} />
					</>}
			</Observer>;
  };
  @action.bound
  private onScroll(width: number, height: number, e: React.UIEvent): void {
    const top = e.currentTarget.scrollTop;
    this.isScrolled = top > 0;
    this.lastAdminInfoHeight = height - width - 112 + top;
    this.adminInfoHeightListener(this.lastAdminInfoHeight);

    // width here is also the profile picture height which is the distance needed
    // for the header to scroll to the top / the scroll view to the end.
    const profilePicHeight = width;
    const scrollEventRoundingErrorCorrection = 1;
    this.hasScrolledToTheEnd = top >= profilePicHeight - scrollEventRoundingErrorCorrection;

    // Use additional threshold to prevent overlapping absolute icons and header bar
    const iconChangeThreshold = 200;
    this.iconsInHeader = top >= profilePicHeight - iconChangeThreshold;
  }
}
const ScrollContainerContent: React.FC<{
  width: number;
  height: number;
  user: UserForProfileFragment;
  photoData: ProfileAlbumInfo;
  currentUserId: User['id'];
  iconsInHeader: boolean;
  onClose: () => void;
  hasScrolledToTheEnd: boolean;
  registerAdminInfoTabHeightListener: (listener: (newHeight: number) => void) => void;
  initialAdminInfoHeight: number;
}> = ({
  width,
  height,
  photoData,
  user,
  currentUserId,
  hasScrolledToTheEnd,
  iconsInHeader,
  initialAdminInfoHeight,
  onClose,
  registerAdminInfoTabHeightListener
}) => {
  const imageSize = width;
  const isCurrentUser = user.id === currentUserId;
  return <div className={_c8}>
			<div style={{
      height: resolveThemingValue(imageSize, "sizes", useTheme())
    }} className={_c9} />
			<div style={{
      height: resolveThemingValue(height, "sizes", useTheme()),
      width: resolveThemingValue(width, "sizes", useTheme())
    }} className={_c10 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={styles.FadeIn} style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
					<SelectedProfileTabContext.Consumer>
						{({
            activeTabIndex,
            setActiveTabIndex
          }) => <Observer>
								{() => <>
										<ProfileHeader user={user} photoData={photoData} onTabClicked={setActiveTabIndex} isCurrentUser={isCurrentUser} onClose={iconsInHeader ? onClose : undefined} activeTabIndex={activeTabIndex} />
										<DisableScrollContext.Provider
              // disable inner scroll until outer scroll view was scrolled to the end
              value={!hasScrolledToTheEnd}>
											<ProfileContent user={user} photoData={photoData} isCurrentUser={currentUserId === user.id} activeTabIndex={activeTabIndex} onTabChange={setActiveTabIndex} registerAdminInfoTabHeightListener={registerAdminInfoTabHeightListener} initialAdminInfoHeight={initialAdminInfoHeight} />
										</DisableScrollContext.Provider>
									</>}
							</Observer>}
					</SelectedProfileTabContext.Consumer>
				</div>
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol flex-1 position-relative ";
const _c1 = " Knu-FlexCol position-absolute zIndex-0 ";
const _c2 = " Knu-Flex position-absolute inset-none ";
const _c3 = " size-full ";
const _c4 = " Knu-FlexCol position-absolute ";
const _c5 = " zIndex-0 ";
const _c6 = " zIndex-1 ";
const _c7 = " Knu-Flex position-absolute insetX-none bottom-small width-100-percent placeItems-center ";
const _c8 = " Knu-FlexCol ";
const _c9 = " Knu-FlexCol width-100-percent ";
const _c10 = " Knu-FlexCol bg-contentBg position-relative zIndex-1 ";