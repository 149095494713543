import { isDarkColor as o } from "../isDarkColor.js";
const h = (r, t, n) => r ? r !== "hover" && r !== "focus" ? `@media(min-width:${t.breakpoints[r]}px){${n}}` : r === "hover" ? `@media(hover: hover){&:${r}{${n}}}` : `&:${r}{${n}}` : n, p = (r, t, n, e, u) => {
  if (!t)
    return null;
  if (n === "dark")
    throw new Error("This should not happen");
  return e ? u && o(u) ? h(n, r, t) : null : h(n, r, t);
};
export {
  p as enhanceWithModifier
};
