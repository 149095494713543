import { FullConversationWithoutMessagesFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { Flex } from '@knuddels/component-library';
import { MessengerOverviewItem } from '@knuddelsModules/Messenger/bundle/services/messengerOverviewHelper';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger/providedServices';
import * as React from 'react';
import { MiniChatHead } from '../MiniChatHead';
import { HorizontalScrollIndicator } from './HorizontalScrollIndicator';
import { canOtherParticipantsReceiveMessages } from './helper';
import { useScrollableContentStates } from './useScrollableContentStates';
import { useUnreadMessagesStates } from './useUnreadMessagesStates';
import styles from './MiniChatConversationList.module.scss';
type MiniChatConversationListProps = {
  conversations: MessengerOverviewItem[];
};
export const MiniChatConversationList: React.FC<MiniChatConversationListProps> = ({
  conversations
}) => {
  const messengerMiniChatService = useService($MessengerMiniChatService);
  const keyboardService = useService($KeyboardService);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const {
    chatHeadRefs,
    hasUnreadMessagesLeft,
    hasUnreadMessagesRight
  } = useUnreadMessagesStates({
    conversations,
    scrollRef
  });
  const {
    hasScrollableContentLeft,
    hasScrollableContentRight
  } = useScrollableContentStates({
    conversations,
    scrollRef
  });
  React.useEffect(() => {
    const activeConversationId = messengerMiniChatService?.activeConversation?.conversationId;
    const activeConversationIndex = conversations.findIndex(conversation => conversation?.conversationId === activeConversationId);
    const activeChatHeadRef = chatHeadRefs?.current[activeConversationIndex];
    activeChatHeadRef?.scrollIntoView({
      block: 'nearest',
      inline: 'center'
    });
  }, []);
  const unlistedConversation = messengerMiniChatService.unlistedConversation;
  const handleChatHeadClick = (currentConversation: FullConversationWithoutMessagesFragment | MessengerOverviewItem, nextConversation: FullConversationWithoutMessagesFragment | MessengerOverviewItem) => {
    const currentCanReceiveMessages = canOtherParticipantsReceiveMessages(currentConversation);
    const nextCanReceiveMessages = canOtherParticipantsReceiveMessages(nextConversation);
    if (!currentCanReceiveMessages) {
      return;
    }
    const shouldAutoFocus = !nextCanReceiveMessages ? keyboardService.isKeyboardVisible ?? messengerMiniChatService.shouldAutoFocus : keyboardService.isKeyboardVisible;
    messengerMiniChatService.setAutoFocus(shouldAutoFocus);
  };
  const handleScroll = (direction: 'left' | 'right') => {
    const width = chatHeadRefs.current[0].clientWidth;
    const scroll = direction === 'left' ? -width : width;
    scrollRef.current?.scrollBy({
      left: scroll,
      behavior: 'smooth'
    });
  };
  return <div className={_c0}>
			{hasScrollableContentLeft && <HorizontalScrollIndicator onPress={() => handleScroll('left')} direction={'left'} highlighted={hasUnreadMessagesLeft} />}
			<div ref={scrollRef} className={styles.MiniChatConversationList} style={{
      display: 'flex',
      overflowX: 'auto',
      overflowY: 'hidden',
      width: '100%',
      scrollSnapType: 'x mandatory'
    }}>
				<div className={_c1}>
					{unlistedConversation.id && <MiniChatHead first last={conversations.length === 0} key={unlistedConversation.id} conversationId={unlistedConversation.id} unreadMessageCount={0} userId={unlistedConversation.otherParticipants[0].id} nick={unlistedConversation.otherParticipants[0].nick} active={messengerMiniChatService.activeConversationId === unlistedConversation.id} onClick={() => {
          handleChatHeadClick(messengerMiniChatService.activeConversation, unlistedConversation);
        }} />}
					{conversations.map((conversation, index) => {
          return <MiniChatHead key={conversation.conversationId} first={!unlistedConversation.id && index === 0} last={index === conversations.length - 1} innerRef={ref => chatHeadRefs.current[index] = ref} conversationId={conversation.conversationId} unreadMessageCount={conversation.type === 'conversation' ? conversation.conversation.readState.unreadMessageCount : 0} userId={conversation.type === 'conversation' ? conversation.conversation.otherParticipants[0].id : ''} nick={conversation.type === 'conversation' ? conversation.conversation.otherParticipants[0].nick : ''} active={messengerMiniChatService.activeConversationId === conversation.conversationId} onClick={() => {
            handleChatHeadClick(messengerMiniChatService.activeConversation || unlistedConversation, conversation);
          }} />;
        })}
				</div>
			</div>
			{hasScrollableContentRight && <HorizontalScrollIndicator direction={'right'} highlighted={hasUnreadMessagesRight} onPress={() => {
      handleScroll('right');
    }} />}
		</div>;
};
const _c0 = " Knu-Flex position-relative ";
const _c1 = " Knu-Flex gap-small placeItems-flex-end flex-1 pt-tiny ";