import { declareFormat } from '@knuddels-app/i18n';

export const creditsFormat = declareFormat({
	id: 'CREDITS',
	defaultFormat: 'Credits',
});

export const settingsFormat = declareFormat({
	id: 'settings.title',
	defaultFormat: 'Settings',
});

export const contactListSettingsFormat = declareFormat({
	id: 'settings.contactListSettings.title',
	defaultFormat: 'Contacts',
});

export const channelListSettingsFormat = declareFormat({
	id: 'settings.channelListSettings.title',
	defaultFormat: 'Channel list',
});

export const messengerSettingsFormat = declareFormat({
	id: 'settings.messengerSettings.title',
	defaultFormat: 'Messenger',
});

export const contactFilterSettingsFormat = declareFormat({
	id: 'settings.contactFilterSettings.title',
	defaultFormat: 'Contact filter',
});

export const privacySettingsFormat = declareFormat({
	id: 'settings.privacySettings.title',
	defaultFormat: 'Profile & Visitors',
});

export const displaySettingsFormat = declareFormat({
	id: 'settings.displaySettings.title',
	defaultFormat: 'Display Settings',
});

export const subscriptionsSettingsFormat = declareFormat({
	id: 'settings.subscriptions.title',
	defaultFormat: 'Subscriptions',
});

export const soundSettingsFormat = declareFormat({
	id: 'settings.soundSettings.title',
	defaultFormat: 'Sounds',
});

export const mentorSystemSettingsFormat = declareFormat({
	id: 'settings.mentorSystemSettings.title',
	defaultFormat: 'Mentor system',
});

export const accountSettingsFormat = declareFormat({
	id: 'settings.accountSettings.title',
	defaultFormat: 'Account & Security',
});

export const macroBoxSettingsFormat = declareFormat({
	id: 'settings.macroBoxSettings.title',
	defaultFormat: 'Customize actions',
});
