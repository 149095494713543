import { addUniqueItem as a, removeItem as o } from "../../utils/array.js";
class n {
  constructor() {
    this.members = [];
  }
  add(e) {
    a(this.members, e), e.scheduleRender();
  }
  remove(e) {
    if (o(this.members, e), e === this.prevLead && (this.prevLead = void 0), e === this.lead) {
      const t = this.members[this.members.length - 1];
      t && this.promote(t);
    }
  }
  relegate(e) {
    const t = this.members.findIndex((i) => e === i);
    if (t === 0)
      return !1;
    let s;
    for (let i = t; i >= 0; i--) {
      const r = this.members[i];
      if (r.isPresent !== !1) {
        s = r;
        break;
      }
    }
    return s ? (this.promote(s), !0) : !1;
  }
  promote(e, t) {
    const s = this.lead;
    if (e !== s && (this.prevLead = s, this.lead = e, e.show(), s)) {
      s.instance && s.scheduleRender(), e.scheduleRender(), e.resumeFrom = s, t && (e.resumeFrom.preserveOpacity = !0), s.snapshot && (e.snapshot = s.snapshot, e.snapshot.latestValues = s.animationValues || s.latestValues), e.root && e.root.isUpdating && (e.isLayoutDirty = !0);
      const { crossfade: i } = e.options;
      i === !1 && s.hide();
    }
  }
  exitAnimationComplete() {
    this.members.forEach((e) => {
      const { options: t, resumingFrom: s } = e;
      t.onExitComplete && t.onExitComplete(), s && s.options.onExitComplete && s.options.onExitComplete();
    });
  }
  scheduleRender() {
    this.members.forEach((e) => {
      e.instance && e.scheduleRender(!1);
    });
  }
  /**
   * Clear any leads that have been removed this render to prevent them from being
   * used in future animations and to prevent memory leaks
   */
  removeLeadSnapshot() {
    this.lead && this.lead.snapshot && (this.lead.snapshot = void 0);
  }
}
export {
  n as NodeStack
};
