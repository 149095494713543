import { mixNumber as e } from "../mix/number.js";
import { progress as f } from "../progress.js";
function l(o, t) {
  const s = o[o.length - 1];
  for (let r = 1; r <= t; r++) {
    const m = f(0, t, r);
    o.push(e(s, 1, m));
  }
}
export {
  l as fillOffset
};
