import * as React from 'react';
import { Image, Z_INDEX } from '@shared/components';
import { $ScreenService, ScreenWidth } from '@knuddels-app/Screen';
import { conversationImagesEvent } from '../../../analytics';
import { ClientSnapState } from '../../../services/conversationServices/SnapService';
import { LightboxImageUploadFooter, UploadImageLightboxSource } from './LightboxImageUploadFooter';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { Box, Flex, FlexCol, IconClose, IconDownload, PointerEvent, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { OS, os } from '@shared/components/tools/os';
import { Media } from '@capacitor-community/media';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { IconGreenCheckMark } from '@knuddels-app/shared-components/IconGreenCheckMark';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
export type { UploadImageLightboxSource };
export interface MessageLightboxSource {
  type: 'image' | 'snap';
  imageUrl?: string;
  snap?: ClientSnapState;
}
interface Props {
  source: string | MessageLightboxSource | UploadImageLightboxSource;
  caption?: string;
  onClose?: () => void;
}
const IMAGE_TOP_DISTANCE = 48;
const IMAGE_BOTTOM_DISTANCE = 68;
@injectable()
class LightboxModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: Props, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T) {
    this.dispose.track(this.trackClosed);
  }
  private readonly trackClosed = (): void => {
    const source = this.props.source;
    if (typeof source !== 'string') {
      if (source.type === 'image') {
        conversationImagesEvent.track('Images_ImageClosed');
      } else if (source.type === 'snap') {
        conversationImagesEvent.track('Images_SnapClosed');
      }
    }
  };
  componentDidMount(): void {
    const source = this.props.source;
    if (typeof source !== 'string') {
      if (source.type === 'image') {
        conversationImagesEvent.track('Images_ImageOpened');
      } else if (source.type === 'snap') {
        conversationImagesEvent.track('Images_SnapOpened');
      }
    }
  }
  public readonly stopPropagation = (e: {
    stopPropagation: () => void;
  }): void => {
    e.stopPropagation();
  };
  public readonly closeAndStopPropagation = (e: PointerEvent): void => {
    this.close();
    e.stopPropagation();
  };
  public getImageSource = (): string | undefined => {
    const source = this.props.source;
    if (typeof source === 'string') {
      return source;
    } else if (source.imageUrl) {
      return source.imageUrl;
    } else if (source.type !== 'upload' && source.snap) {
      return source.snap.decryptedSnap;
    } else {
      return undefined;
    }
  };
  public get canBeDownloaded(): boolean {
    const source = this.props.source;
    return typeof source !== 'string' && 'type' in source && source.type === 'image';
  }
  public readonly close = (): void => {
    if (typeof this.props.source !== 'string' && this.props.source.type === 'upload') {
      this.props.source.onClose();
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  public readonly download = async () => {
    try {
      const timestamp = getFormattedTimestamp();
      const fileName = `image_${timestamp}.jpg`;
      if (os === OS.web) {
        await forceDownload(this.getImageSource(), fileName);
        return;
      }
      if (os === OS.android) {
        await Filesystem.downloadFile({
          url: this.getImageSource(),
          directory: Directory.ExternalStorage,
          path: `Download/${fileName}`
        });
      } else if (os === OS.ios) {
        await Media.savePhoto({
          fileName: fileName,
          path: this.getImageSource()
        });
      }
      this.snackbarService.showSnackbar({
        adornment: <div className={_c0}>
						<IconGreenCheckMark size={'large'} />
					</div>,
        text: declareFormat({
          id: 'conversation.lightbox.download.success.text',
          defaultFormat: 'Image downloaded successfully'
        }).format(this.i18n),
        subtext: declareFormat({
          id: 'conversation.lightbox.download.success.subtext',
          defaultFormat: 'It is now available in your gallery'
        }).format(this.i18n),
        type: 'imageDownloadSuccess'
      });
    } catch (e) {
      console.error(e);
      this.snackbarService.showGenericError();
    }
  };
}
async function forceDownload(url: string, fileName: string): Promise<void> {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function (): void {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

// This function returns a string representing the current date and time in the "YYYYMMDD_HHMMSS" format.
function getFormattedTimestamp(): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}
export const Lightbox = injectedComponent({
  name: 'Lightbox',
  model: LightboxModel,
  props: declareProps<Props>(),
  inject: {
    screenService: $ScreenService
  }
}, ({
  model,
  screenService,
  source,
  caption: captionFromProps
}) => {
  const screenWidth = screenService.screenWidth;
  const caption = captionFromProps || typeof source !== 'string' && source.type === 'snap' && source.snap && source.snap.remainingTime + ' Sek';
  const isImageUpload = typeof source !== 'string' && source.type === 'upload';
  const useSmallLayout = screenWidth === ScreenWidth.XS || screenWidth === ScreenWidth.S;
  const imageSource = model.getImageSource();
  return <div onClick={toPointerHandler(model.closeAndStopPropagation)} style={{
    zIndex: resolveThemingValue(Z_INDEX.BELOW_TITLE_BAR, "theme", useTheme())
  }} className={_c1 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div style={{
      top: resolveThemingValue((IMAGE_TOP_DISTANCE as ThemeOverride), "spacing", useTheme()),
      bottom: resolveThemingValue((IMAGE_BOTTOM_DISTANCE as ThemeOverride), "spacing", useTheme())
    }} className={_c2 + (useSmallLayout ? _c3 : _c4)}>
					<div className={_c5}>
						{imageSource !== undefined && <Image source={model.getImageSource()} sizingMode={'fit'} onClick={model.stopPropagation} caption={caption} className={_c6} />}
					</div>
				</div>
				{isImageUpload && <LightboxImageUploadFooter source={(source as UploadImageLightboxSource)} />}
				<div className={_c7}>
					{model.canBeDownloaded && <IconDownload size={'large'} onPress={model.download} />}
					<IconClose size={'large'} onPress={model.close} />
				</div>
			</div>;
});
const _c0 = " Knu-Box mr-minor ";
const _c1 = " Knu-Flex bg-backgroundScrim shadow-Shadow4 position-absolute inset-none placeItems-center ";
const _c2 = " Knu-FlexCol position-absolute flex-1 alignItems-center ";
const _c3 = " insetX-none ";
const _c4 = " insetX-base ";
const _c5 = " Knu-FlexCol position-absolute inset-none ";
const _c6 = "  ";
const _c7 = " Knu-Flex position-absolute right-base top-minor ";