import { Flex, FlexCol, IconMenu, LineDivider, ListGroup, Text, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { DraggableList, DraggableListItem } from '../DraggableList';
import { SWITCH_SETTING_HEIGHT, SwitchSetting } from '../SwitchSetting';
import { Column } from '@shared/components';
export interface ListGroupDraggableListItem extends DraggableListItem {
  title: string;
  toggleable?: boolean;
  checked?: boolean;
  onChanged(checked: boolean): void;
}
export function ListGroupDraggableList<T extends ListGroupDraggableListItem>({
  items,
  title,
  dragItemOffset,
  onItemOrderChanged
}: {
  items: T[];
  title: string;
  dragItemOffset?: () => number;
  onItemOrderChanged(from: number, to: number): void;
}): React.ReactElement {
  const theme = useTheme();
  const renderItem = useRenderItem<T>(items.length);
  const onDropEnd = (from: number, to: number) => {
    if (from === to) {
      // nothing changed
      return;
    }
    onItemOrderChanged(from, to);
  };
  return <ListGroup title={title}>
			<div style={{
      height: resolveThemingValue((SWITCH_SETTING_HEIGHT + 1) * items.length, "sizes", useTheme())
    }} className={_c0}>
				<DraggableList items={items} renderItem={renderItem} onDropEnd={onDropEnd} itemOffsetTop={dragItemOffset} itemOffsetLeft={theme.spacings.base} />
			</div>
		</ListGroup>;
}
const useRenderItem = <T extends ListGroupDraggableListItem,>(itemCount: number) => {
  // eslint-disable-next-line react/display-name
  return (item: T, index: number, isDragged: boolean, renderDragHandle: (children: React.ReactNode) => React.ReactNode) => {
    return <div style={{
      height: resolveThemingValue(SWITCH_SETTING_HEIGHT, "sizes", useTheme())
    }} className={_c1 + ((isDragged ? "listActiveAtom" : "transparent") ? resolveIsDarkColor(isDragged ? "listActiveAtom" : "transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isDragged ? _c2 : _c3) + (isDragged ? _c4 : _c5)}>
				{item.toggleable ? <SwitchSetting iconComponent={renderDragHandle(<DragHandleContent />)} key={item.title} title={item.title} checked={item.checked} onChange={item.onChanged} /> : <NonToggleableEntry iconComponent={renderDragHandle(<DragHandleContent />)} key={item.title} title={item.title} />}
				{index < itemCount - 1 && !isDragged ? <Column key={'sep-' + index} style={{
        paddingLeft: 68,
        paddingRight: 16
      }}>
						<LineDivider className={_c6} />
					</Column> : <div key={index} className={_c7} />}
			</div>;
  };
};
const DragHandleContent: React.FC = () => {
  return <div style={{
    height: resolveThemingValue(SWITCH_SETTING_HEIGHT, "sizes", useTheme())
  }} className={_c8}>
			<IconMenu size={'large'} />
		</div>;
};
const NonToggleableEntry: React.FC<{
  iconComponent?: React.ReactNode;
  title: string;
}> = ({
  iconComponent,
  title
}) => {
  return <div style={{
    height: resolveThemingValue(SWITCH_SETTING_HEIGHT, "sizes", useTheme())
  }} className={_c9}>
			{iconComponent}
			<Text type={'subtitle'} numberOfLines={1} bold={true} className={_c10}>
				{title}
			</Text>
		</div>;
};
const _c0 = " Knu-FlexCol position-relative width-full ";
const _c1 = " Knu-FlexCol ";
const _c2 = " shadow-Shadow1 ";
const _c3 = " shadow-none ";
const _c4 = " bg-listActiveAtom ";
const _c5 = " bg-transparent ";
const _c6 = "  ";
const _c7 = " Knu-FlexCol height-1px ";
const _c8 = " Knu-Flex alignItems-center pl-base pr-small ";
const _c9 = " Knu-Flex flex-1 pl-small pr-large alignItems-center ";
const _c10 = " ml-base ";