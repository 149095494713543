import * as React from 'react';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { ContextMenu, ContextMenuProps, Spacer } from '@shared/components';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { GetProfilePictureUrls } from '@generated/graphql';
import { $UserService } from '@knuddelsModules/UserData';
import { LineDivider } from '@knuddels/component-library';
import { EditProfilePictureContextMenuEntry } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfilePictureContextMenu/EditProfilePictureContextMenuEntry';
import { DeleteProfilePictureContextMenuEntry } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/EditProfilePictureContextMenu/DeleteProfilePictureContextMenuEntry';
type EditProfilePictureOverflowMenuProps = ContextMenuProps;
@injectable()
class EditProfilePictureOverflowMenuModel implements IModel {
  userQuery = this.authenticatedClientService.currentK3Client.watchQuery(GetProfilePictureUrls, {
    userId: this.userService.currentUser.id
  }, 'cache-only');
  constructor(@injectProps()
  props: unknown, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T) {}
  closeMenu = (): void => {
    this.thisVisibleOverlay.dispose();
  };
  get showDeleteButton(): boolean {
    const watchQueryValue = this.userQuery.value;
    if (watchQueryValue === 'loading') {
      return false;
    }
    return watchQueryValue !== 'error' && watchQueryValue.user && watchQueryValue.user.user ? watchQueryValue.user.user.profilePicture.exists :
    // default true (errors in query...)
    true;
  }
  dispose(): void {
    this.userQuery.dispose();
  }
}
export const EditProfilePictureOverflowMenu = injectedComponent({
  name: 'EditProfilePictureOverflowMenu',
  model: EditProfilePictureOverflowMenuModel,
  props: declareProps<EditProfilePictureOverflowMenuProps>()
}, ({
  model,
  ...chevronProps
}) => <ContextMenu {...chevronProps} closeContextMenu={model.closeMenu}>
			<EditProfilePictureContextMenuEntry />
			{model.showDeleteButton && <>
					<Spacer size={'small'} />
					<LineDivider className={_c0} />
					<Spacer size={'small'} />
					<DeleteProfilePictureContextMenuEntry />
				</>}
		</ContextMenu>);
const _c0 = "  ";