function c(e, f, r) {
  return r < 0 && (r += 1), r > 1 && (r -= 1), r < 1 / 6 ? e + (f - e) * 6 * r : r < 1 / 2 ? f : r < 2 / 3 ? e + (f - e) * (2 / 3 - r) * 6 : e;
}
function R({ hue: e, saturation: f, lightness: r, alpha: M }) {
  e /= 360, f /= 100, r /= 100;
  let o = 0, u = 0, d = 0;
  if (!f)
    o = u = d = r;
  else {
    const n = r < 0.5 ? r * (1 + f) : r + f - r * f, b = 2 * r - n;
    o = c(b, n, e + 1 / 3), u = c(b, n, e), d = c(b, n, e - 1 / 3);
  }
  return {
    red: Math.round(o * 255),
    green: Math.round(u * 255),
    blue: Math.round(d * 255),
    alpha: M
  };
}
export {
  R as hslaToRgba
};
