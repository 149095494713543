import * as React from 'react';
import { ReactNode, useState } from 'react';
import { Flex, FlexCol, IconSortDown, LineDivider, Rotate, Text, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
export interface AccordionItem {
  header: string;
  content: ReactNode;
}
export const AccordionBlock: React.FC<{
  items: AccordionItem[];
}> = ({
  items
}) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const selectActive = (index: number) => {
    // If we select active accordion => set it inactive
    if (activeIndex === index) {
      setActiveIndex(undefined);
    } else {
      setActiveIndex(index);
    }
  };
  return <div className={_c0 + ("listGroupBg" ? resolveIsDarkColor("listGroupBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{items.map((item, index) => <Accordion key={`accordionItem-${index}`} item={item} active={index === activeIndex} withBorderBottom={index < items.length - 1} onPress={() => selectActive(index)} />)}
		</div>;
};
export const Accordion: React.FC<{
  item: AccordionItem;
  active: boolean;
  withBorderBottom: boolean;
  onPress: () => void;
}> = ({
  item,
  active,
  withBorderBottom,
  onPress
}) => {
  return <div className={_c1 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div onClick={toPointerHandler(onPress)} className={_c2}>
				<Text type={'subtitle'} bold={true} className={_c3}>
					{item.header}
				</Text>
				<Rotate rotated={active} to={-180} className={_c4}>
					<IconSortDown size={'large'} />
				</Rotate>
			</div>
			<div style={{
      height: resolveThemingValue(active ? undefined : 0, "sizes", useTheme())
    }} className={_c5 + (active ? _c6 : _c7)}>
				<Text state={'secondary'} className={_c8}>{item.content}</Text>
			</div>
			{withBorderBottom && <LineDivider className={_c9} />}
		</div>;
};
const _c0 = " Knu-FlexCol px-large bg-listGroupBg shadow-Shadow2 ";
const _c1 = " Knu-FlexCol bg-transparent ";
const _c2 = " Knu-Flex py-large cursor-pointer alignItems-flex-end justifyContent-space-between ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = " Knu-Flex overflow-hidden ";
const _c6 = " pb-large ";
const _c7 = " pb-none ";
const _c8 = "  ";
const _c9 = "  ";