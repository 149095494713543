import {
	ImageGroupImageUnverified,
	verifyImageGroupImage,
} from './verifyImageGroupImage';
import { transformImageGroupImage } from './transformImageGroupImage';
import { ImageGroupData, ImageGroupImage } from './ImageGroupData';

export function parseImageGroup(
	imageGroupJson: string
): ImageGroupData | undefined {
	try {
		const images = JSON.parse(
			imageGroupJson
		) as ImageGroupImageUnverified[];
		return parseImageGroupJson(images);
	} catch (e) {
		// console.warn('ImageGroup could not be parsed', imageGroupJson, e);
		return undefined;
	}
}

function parseImageGroupJson(
	images: ImageGroupImageUnverified[]
): ImageGroupData | undefined {
	// TODO write tests for the parsing functions.
	const verifiedImages = mapNotNull(images, image =>
		verifyImageGroupImage(image)
	);
	const transformedImages = mapNotNull(verifiedImages, image =>
		transformImageGroupImage(image)
	);
	return transformFormat(transformedImages);
}

const transformFormat = (
	images: ImageGroupImage[]
): ImageGroupData | undefined => {
	if (images.length === 0) {
		return undefined;
	}

	let maxImageH = 0;
	let containerWidth = 0;

	let clickUrl: string | undefined;
	let clickToggleOnce = false;
	let linkUrl: string | undefined;

	images.forEach(image => {
		if (!isNaN(image.h)) {
			maxImageH = Math.max(image.h, maxImageH);
		}

		if (!isNaN(image.w)) {
			containerWidth += image.w;
		}

		if (image.clickUrl) {
			clickUrl = image.clickUrl;
		}

		if (image.clickToggleOnce) {
			clickToggleOnce = image.clickToggleOnce;
		}

		if (image.linkUrl) {
			linkUrl = image.linkUrl;
		}
	});

	if (isNaN(containerWidth) || containerWidth < 0) {
		return undefined;
	}

	return {
		baseline: !!images[0].b,
		containerHeight: maxImageH,
		containerWidth: containerWidth,
		images: makeMxAbsolute(images),
		clickable: !!clickUrl,
		// use linkUrl as id because it is more unique (e.g. containing an id)
		clickOnceId: clickToggleOnce && linkUrl ? linkUrl : undefined,
	};
};

function makeMxAbsolute(images: ImageGroupImage[]): ImageGroupImage[] {
	let runningWidth = 0;
	return images.map(image => {
		const result = { ...image, mx: image.mx + runningWidth };
		runningWidth += image.w;
		return result;
	});
}

function mapNotNull<T, R>(
	array: T[],
	mapper: (el: T) => R | undefined | null
): Exclude<R, undefined | null>[] {
	return array.map(mapper).filter(Boolean) as Exclude<R, undefined | null>[];
}
