import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { useSnackbar } from '@shared/components';
import { useEffect } from 'react';
import { $SnackbarService } from '../providedServices';
import { SnackbarData } from '../SnackbarService';
export function SnackbarContextConnector(): React.ReactElement | null {
  const snackbarService = useService($SnackbarService);
  const showSnackbar = useSnackbar();
  useEffect(() => {
    return snackbarService.onSnackbar.sub(data => {
      showSnackbar({
        type: data.type,
        text: getText(data),
        subtext: getSubtext(data),
        adornment: data.adornment,
        button: data.button && {
          text: getButtonText(data.button),
          onPress: data.button ? data.button.onPress : undefined
        }
      });
    }).dispose;
  }, []);
  return null;
}
function getText(data: SnackbarData): string {
  return typeof data.text === 'string' ? data.text : data.text();
}
function getSubtext(data: SnackbarData): string | React.ReactElement | undefined {
  return data.subtext && (typeof data.subtext === 'string' ? data.subtext : typeof data.subtext === 'function' ? data.subtext() : data.subtext);
}
function getButtonText(button: Exclude<SnackbarData['button'], undefined>): string {
  return typeof button.text === 'string' ? button.text : button.text();
}