import { ConversationForwardedMessageContentFragment, ConversationMessageFragment, ConversationNestedMessageContentFragment, MessengerConversation } from '@generated/graphql';
import { inject, injectProps, injectable } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { action } from '@knuddels-app/mobx';
import { $OverlayService, $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { LineDivider } from '@knuddels/component-library';
import { $MessengerConversationService, $StarMessageService } from '@knuddelsModules/Messenger/providedServices';
import { ContextMenu, ContextMenuEntry, ContextMenuProps, FormattedText, Spacer } from '@shared/components';
import { copyToClipboard } from '@shared/helper/copyToClipboard';
import { IconForward } from '@shared/icons/IconForward';
import { IconReply } from '@shared/icons/IconReply';
import { IconStar } from '@shared/icons/IconStar';
import { IconStarOff } from '@shared/icons/IconStarOff';
import * as React from 'react';
import { conversationMessageMenuEvent } from '../../analytics';
import { UNSTAR_MESSAGE } from '../../i18n/shared-formats';
import { ChooseContactModal } from '../ChooseContactModal/ChooseContactModal';
import { IconCopy } from './IconCopy';
interface Props extends ContextMenuProps {
  message: ConversationMessageFragment;
  conversationId: MessengerConversation['id'];
}
@injectable()
class MessageContextMenuModel {
  constructor(@injectProps()
  readonly props: Props, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($StarMessageService)
  private readonly starMessageService: typeof $StarMessageService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  @action.bound
  public quoteMessage(): void {
    conversationMessageMenuEvent.track('MessageMenu_QuoteMessage');
    this.messengerConversationService.getOrCreateClientConversationState(this.props.conversationId).setSelectedMessageToQuote(this.props.message);
    this.closeContextMenu();
  }
  @action.bound
  public forwardMessage(): void {
    conversationMessageMenuEvent.track('MessageMenu_ForwardMessage');
    this.closeContextMenu();
    this.overlayService.showOverlay({
      view: <ChooseContactModal message={this.props.message} />
    });
  }
  @action.bound
  public async copyMessage(): Promise<void> {
    conversationMessageMenuEvent.track('MessageMenu_CopyMessage');
    const text = this.getTextFromMessage(this.props.message);
    await copyToClipboard(text);
    this.closeContextMenu();
  }
  @action.bound
  public onStarOrUnstar(): void {
    conversationMessageMenuEvent.track(this.isStarred ? 'MessageMenu_UnstarMessage' : 'MessageMenu_StarMessage');
    if (this.isStarred) {
      this.starMessageService.unStarMessage(this.props.message.id);
    } else {
      this.starMessageService.starMessage(this.props.message.id);
    }
    this.closeContextMenu();
  }
  public get isStarred(): boolean {
    return 'starred' in this.props.message.content && this.props.message.content.starred;
  }
  public isMessageWithSnap = (): boolean => {
    return this.props.message.content.__typename === 'ConversationSnapMessageContent';
  };
  public isTextMessage = (): boolean => {
    const messageType = this.props.message.content.__typename;
    return messageType === 'ConversationTextMessageContent' || messageType === 'ConversationForwardedMessageContent' || messageType === 'ConversationQuotedMessageContent';
  };
  private getTextFromMessage = (message: ConversationMessageFragment | ConversationForwardedMessageContentFragment | ConversationNestedMessageContentFragment): string => {
    let content;
    if ('content' in message) {
      content = message.content;
    } else {
      content = message;
    }
    if (content.__typename === 'ConversationTextMessageContent' || content.__typename === 'ConversationQuotedMessageContent') {
      const formattedText = FormattedText.fromJsonString(content.formattedText);
      return FormattedText.asText(formattedText);
    }
    if (content.__typename === 'ConversationForwardedMessageContent') {
      return this.getTextFromMessage(content.nestedMessage.content);
    }
    return '';
  };
  @action.bound
  public closeContextMenu(): void {
    this.thisVisibleOverlay.dispose();
  }
}
const MessageContextMenu = injectedComponent({
  name: 'MessageContextMenu',
  model: MessageContextMenuModel
}, ({
  model
}) => {
  return <ContextMenu {...model.props} closeContextMenu={model.closeContextMenu}>
				<ContextMenuEntry icon={IconReply} text={formatMessage(declareFormat({
      id: 'CONTEXT_MENU_CITE',
      defaultFormat: 'Cite'
    }))} onClick={model.quoteMessage} disabled={model.isMessageWithSnap()} testId={'context-menu-quote-message'} />
				<ContextMenuEntry icon={IconForward} text={formatMessage(declareFormat({
      id: 'CONTEXT_MENU_FORWARD',
      defaultFormat: 'Forward'
    }))} onClick={model.forwardMessage} disabled={model.isMessageWithSnap()} testId={'context-menu-forward-message'} />
				<ContextMenuEntry icon={IconCopy} text={formatMessage(declareFormat({
      id: 'CONTEXT_MENU_COPY',
      defaultFormat: 'Copy'
    }))} onClick={model.copyMessage} disabled={!model.isTextMessage()} testId={'context-menu-copy-message'} />
				<Spacer size={'small'} />
				<LineDivider className={_c0} />
				<Spacer size={'small'} />
				<ContextMenuEntry icon={model.isStarred ? IconStarOff : IconStar} text={formatMessage(model.isStarred ? UNSTAR_MESSAGE : declareFormat({
      id: 'CONTEXT_MENU_STAR',
      defaultFormat: 'Mark message'
    }))} onClick={model.onStarOrUnstar} testId={'star-message'} />
			</ContextMenu>;
});
export const MessageContextMenuOverlay = new OverlayFactory(MessageContextMenu, props => ({
  messageId: props.message.id
}));
const _c0 = "  ";