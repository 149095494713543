import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';
import sorry from '@shared/images/sorry.gif';

export const SnackbarDefinitionSendMessageFilteredByNoProfilePic = (
	onPress: () => void
): SnackbarData => {
	return {
		type: 'sendMessageFilteredByNoProfilePic',
		text: (): string =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_SEND_MESSAGE_FILTERED_BY_NO_PROFILE_PIC_TEXT',
					defaultFormat: 'Your message has been blocked.',
				})
			),
		subtext: (): string =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_SEND_MESSAGE_FILTERED_BY_NO_PROFILE_PIC_SUBTEXT',
					defaultFormat:
						'Upload a profile picture to send a message to this user.',
				})
			),
		adornment: sorry,
		button: {
			onPress,
			text: () =>
				formatMessage(
					declareFormat({
						id: 'SNACKBAR_SEND_MESSAGE_FILTERED_BY_NO_PROFILE_PIC_BUTTON',
						defaultFormat: 'Upload',
					})
				),
		},
	};
};
