import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import {
	$LocalStorage,
	LocalStorageKey,
	stringOrUndefinedSerializer,
} from '@knuddels-app/local-storage';
import { $AuthService, $CurrentEndpointStore } from '@knuddels-app/Connection';
import { $K3ApolloClientFactory } from '@knuddels-app/Connection/serviceIds';
import { RemoveFirebaseId } from '@generated/graphql';
import { AuthTokenProvider } from '@knuddels-app/Connection/client/AuthTokenProvider';
import { reactionWhen } from '@knuddels-app/mobx';

const lastTokenKey = new LocalStorageKey({
	name: 'lastPushToken',
	serializer: stringOrUndefinedSerializer,
	cookieExpires: { inDays: 365 },
});

export const $PushTokenService = newServiceId<PushTokenService>(
	'$PushTokenService'
);

@injectable()
export class PushTokenService {
	public readonly dispose = Disposable.fn();

	private lastTokenEntry = this.localStorage.getEntry(lastTokenKey);

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T,
		@inject($K3ApolloClientFactory)
		private readonly k3ApolloClientFactory: typeof $K3ApolloClientFactory.T,
		@inject($CurrentEndpointStore)
		private readonly currentEndpointStore: typeof $CurrentEndpointStore.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T
	) {
		this.dispose.track(
			reactionWhen(
				{
					name: 'Remove push token if not logged in',
					fireImmediately: true,
				},
				() => this.authService.state.kind === 'loggedOut',
				async () => {
					await this.removeLastToken();
				}
			)
		);
	}

	removeLastToken = async (): Promise<void> => {
		const lastToken = this.lastTokenEntry.get();
		if (lastToken) {
			await this.removeToken(lastToken);
		}
	};

	updateToken = (token: string): void => {
		this.setToken(token);
	};

	removeToken(token: string): Promise<boolean> {
		const unauthenticatedClient = this.k3ApolloClientFactory.createClient({
			endpoint: this.currentEndpointStore.currentEndpoint,
			authTokenProvider: AuthTokenProvider.empty,
			withSubscriptionClient: false,
		});

		// remove token from backend. fire and forget
		return unauthenticatedClient
			.mutateWithResultPromise(RemoveFirebaseId, { instanceId: token })
			.match({
				ok: () => {
					this.setToken(undefined);
					return true;
				},
				error: async () => false,
			})
			.then(result => result);
	}

	private setToken = (token: string | undefined): void => {
		this.lastTokenEntry.set(token);
	};
}
