import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthService } from '@knuddels-app/Connection/serviceIds';
import {
	$LocalStorage,
	LocalStorageKey,
	stringOrUndefinedSerializer,
} from '@knuddels-app/local-storage';
import {
	getNamespaceIfWeb,
	getScopeIfWeb,
} from '@knuddels-app/local-storage/sessionCookieHelper';
import { action, observable } from '../../mobx';

const lastLoggedInNick = new LocalStorageKey({
	name: 'lastLoggedInNick',
	getNamespace: getNamespaceIfWeb,
	scope: getScopeIfWeb(),
	serializer: stringOrUndefinedSerializer,
	cookieExpires: { inDays: 365 },
});

const lastUsedDeviceToken = new LocalStorageKey({
	name: 'lastUsedDeviceToken',
	getNamespace: getNamespaceIfWeb,
	scope: getScopeIfWeb(),
	serializer: stringOrUndefinedSerializer,
	cookieExpires: { inDays: 365 },
});

const storeMultipleNicks = new LocalStorageKey({
	name: 'storeMultipleNicks',
	getNamespace: getNamespaceIfWeb,
	scope: getScopeIfWeb(),
	serializer: stringOrUndefinedSerializer,
	cookieExpires: { inDays: Number.MAX_SAFE_INTEGER },
});

@injectable()
export abstract class LastLoginStorage {
	private readonly lastLoggedInNickStorageEntry =
		this.localStorage.getEntry(lastLoggedInNick);
	private readonly lastUsedDeviceTokenStorageEntry =
		this.localStorage.getEntry(lastUsedDeviceToken);

	@observable
	private _storeMultipleNicks =
		this.localStorage.getEntry(storeMultipleNicks).get() ?? 'true';

	get storeMultipleNicks(): boolean {
		return this._storeMultipleNicks === 'true';
	}

	protected constructor(
		@inject($AuthService) authService: typeof $AuthService.T,
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		if (localStorage.getEntry(storeMultipleNicks).get() === undefined) {
			localStorage.getEntry(storeMultipleNicks).set('true');
		}

		authService.onBeforeVoluntaryLogout.sub(async ({ logoutOptions }) => {
			if (logoutOptions.disableConvenienceLogin) {
				this.setLastUsedDeviceToken(undefined);
				return;
			}
		});

		authService.onDeviceTokenAvailable.sub(async ({ token }) => {
			this.setLastUsedDeviceToken(token);
		});
	}

	@action.bound
	setStoreMultipleNicks(value: boolean): void {
		this._storeMultipleNicks = value.toString();
		this.localStorage.getEntry(storeMultipleNicks).set(value.toString());
	}

	getLastedUsedNick(): string | undefined {
		return this.lastLoggedInNickStorageEntry.get();
	}

	setLastUsedNick(nick: string): void {
		this.lastLoggedInNickStorageEntry.set(nick);
	}

	setLastUsedDeviceToken(deviceToken: string | undefined): void {
		this.lastUsedDeviceTokenStorageEntry.set(deviceToken);
	}
}
