import { User } from '@generated/graphql';
import * as React from 'react';
import { createItemsForVirtualListView, sortContacts } from './createItemsForVirtualListView';
import { ContactItemInfo, ContactsVirtualListBase } from './ContactsVirtualListBase';
import { FlexCol, FallbackBox } from '@knuddels/component-library';
import { ContactsTab, ContactsUser } from '@knuddelsModules/Contacts';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserOnlineStatusService } from '@knuddelsModules/UserData';
import { AllMenuContextProvider, FotomeetMenuContextProvider, FriendsMenuContextProvider, WatchlistMenuContextProvider } from './ContextMenu/AllContextMenu';
export interface ContactVirtualListProps {
  contextMenuType?: ContactsTab;
  contacts: readonly ContactsUser[];
  searchText?: string;
  header?: React.ReactElement;
  onContactClicked: (contact: Pick<User, 'id' | 'nick' | 'readMe'>, index: number) => void;
  noOnlineStatusSubscription?: boolean;
}
export const ContactsVirtualList: React.FC<ContactVirtualListProps> = ({
  contextMenuType,
  contacts,
  searchText,
  onContactClicked,
  header,
  noOnlineStatusSubscription
}) => {
  const [height, setHeight] = React.useState(0);
  const userOnlineStatusService = useService($UserOnlineStatusService);

  // Initially open subscription for all users (internally only opens one subscription)
  React.useEffect(() => {
    if (!noOnlineStatusSubscription) {
      contacts.forEach(contact => {
        userOnlineStatusService.getUserWithOnlineChannel(contact.id);
      });
    }
  }, [contacts]);
  const items = React.useMemo(() => {
    return createItemsForVirtualListView(sortContacts(contacts), searchText, noOnlineStatusSubscription, onContactClicked);
  }, [contacts, searchText, onContactClicked]);
  const itemsWithHeader: ContactItemInfo[] = header ? [{
    type: 'header',
    component: <FallbackBox onLayout={e => setHeight(e.height)} className={_c0}>
							{header}
						</FallbackBox>,
    height
  }, ...items] : items;
  return <MenuContextProvider type={contextMenuType}>
			<ContactsVirtualListBase items={itemsWithHeader} />
		</MenuContextProvider>;
};
const MenuContextProvider: ({
  type,
  children
}: {
  type: ContactsTab;
  children: any;
}) => JSX.Element = ({
  type,
  children
}: {
  type: ContactsTab;
  children: any;
}) => {
  switch (type) {
    case 'friends':
      return <FriendsMenuContextProvider>
					{children}
				</FriendsMenuContextProvider>;
    case 'all':
      return <AllMenuContextProvider>{children}</AllMenuContextProvider>;
    case 'watchlist':
      return <WatchlistMenuContextProvider>
					{children}
				</WatchlistMenuContextProvider>;
    case 'fotomeet':
      return <FotomeetMenuContextProvider>
					{children}
				</FotomeetMenuContextProvider>;
    default:
      return <>{children}</>;
  }
};
const _c0 = " Knu-FlexCol ";