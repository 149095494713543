import { expectUnreachable } from '@knuddels/std';
import { useEffect, useState } from 'react';

type Format = 'hh:mm:ss' | 'mm:ss';

export const useTimer = (
	endTimestamp: number,
	format: Format
): [string, boolean] => {
	const [done, setDone] = useState(false);
	const [timerText, setTimerText] = useState(() => {
		return getCountdownToTimestamp(endTimestamp, format);
	});

	useEffect(() => {
		const updateState = () => {
			setTimerText(getCountdownToTimestamp(endTimestamp, format));
			setDone(endTimestamp < Date.now());
		};

		updateState();

		const interval = setInterval(() => {
			updateState();
		}, 1000);
		return () => clearInterval(interval);
	}, [endTimestamp]);

	return [timerText, done];
};

function getCountdownToTimestamp(endTimestamp: number, format: Format): string {
	const now = Date.now();
	const delta = Math.max(endTimestamp - now, 0);
	return millisToTimerString(delta, format);
}

export function millisToTimerString(millis: number, format: Format): string {
	if (format === 'hh:mm:ss') {
		const millisPerHour = 1000 * 60 * 60;

		let h, m, s;
		h = Math.floor(millis / millisPerHour);
		m = Math.floor((millis / millisPerHour - h) * 60);
		s = Math.floor(((millis / millisPerHour - h) * 60 - m) * 60);

		s = toTwoDigits(s);
		m = toTwoDigits(m);
		h = toTwoDigits(h);

		return `${h}:${m}:${s}`;
	} else if (format === 'mm:ss') {
		const millisPerMinute = 1000 * 60;

		let m, s;
		m = Math.floor(millis / millisPerMinute);
		s = Math.floor((millis / millisPerMinute - m) * 60);

		s = toTwoDigits(s);
		m = toTwoDigits(m);

		return `${m}:${s}`;
	} else {
		expectUnreachable(format);
	}
}

function toTwoDigits(n: number): string {
	return n < 10 ? `0${n}` : `${n}`;
}
