import * as React from 'react';
import { Column } from '../../Layouts';
import { LayoutProps } from '../../Layouts/LayoutProps';
import { useBgClassName, useTheme } from '@knuddels/component-library';
import styles from './ContentBox.module.scss';
export function ContentBox({
  className,
  ...props
}: LayoutProps): JSX.Element {
  const theme = useTheme();
  const bgClassName = useBgClassName('dialogBoxBg');
  return <Column className={[styles.box, className, bgClassName].join(' ')} {...props} style={{
    '--bg': theme.colors.basic.dialogBoxBg
  }} />;
}