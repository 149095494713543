function n(e) {
  const t = e;
  return {
    key: t.key,
    preventDefault: () => t.preventDefault(),
    stopPropagation: () => t.stopPropagation(),
    originalEvent: t
  };
}
export {
  n as toKeyboardEvent
};
