import * as React from 'react';
import { Z_INDEX } from '@shared/components';
import { ProfileDesktopLayoutManager } from './ProfileDesktopLayoutManager';
import { $AdsService } from '@knuddelsModules/Ads';
import { declareProps, inject, injectable, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { $ScreenService, ScreenWidth } from '@knuddels-app/Screen';
import { ProfilePicture } from './ProfilePicture';
import { User } from '@generated/graphql';
import { Flex, FlexCol, PointerEvent, ThemeOverride, useTheme, toPointerHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $OverlayService } from '@knuddels-app/overlays';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
interface Props {
  userId: User['id'];
  onWrapperPress(e: PointerEvent): void;
}
@injectable()
class ProfileDesktopContainerModel {
  constructor(@injectProps()
  public readonly props: Props, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T) {}
  public adsWidth = (): number => {
    return this.screenService.screenWidth === ScreenWidth.M ? 250 : 300;
  };
}
export const ProfileDesktopContainer = injectedComponent({
  name: 'ProfileDesktopContainer',
  model: ProfileDesktopContainerModel,
  inject: {
    adsRoot: $AdsService
  },
  props: declareProps<React.PropsWithChildren<Props>>()
}, ({
  model,
  children,
  onWrapperPress,
  userId,
  adsRoot
}) => {
  const theme = useTheme();
  const totalAdsWidth = model.adsWidth() + theme.spacings[adSpacingLeft] + theme.spacings[adSpacingRight];
  const adsService = useService($AdsService);
  const isTopMostOverlay = useIsProfileTopOverlay();
  return <ProfileDesktopLayoutManager hasWebAds={adsRoot.areWebAdsVisible} hasCapacitorAds={adsRoot.areCapacitorAdsVisible} adsWidth={totalAdsWidth}>
				{(profileHeight, shouldCenter, hasSpaceForWebAds, hasSpaceForCapacitorAds) => <>
						{adsService.areCapacitorAdsVisible && hasSpaceForCapacitorAds && <div className={_c0}>
									<adsService.CapacitorAdzone adVisible={isTopMostOverlay} location={'bottom'} bannerId={'K3ProfileBanner'} />
								</div>}
						<div onClick={toPointerHandler(onWrapperPress)} style={{
        paddingRight: resolveThemingValue(shouldCenter ? 0 : (totalAdsWidth as ThemeOverride), "spacing", useTheme())
      }} className={_c1 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (shouldCenter ? _c2 : _c3)}>
							<div onClick={toPointerHandler(e => e.stopPropagation())} style={{
          width: resolveThemingValue(profileHeight + 360, "sizes", useTheme()),
          height: resolveThemingValue(profileHeight, "sizes", useTheme())
        }} className={_c4 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
								<div className={_c5}>
									<ProfilePicture userId={userId} size={profileHeight} />
								</div>
								{children}
							</div>
						</div>

						{hasSpaceForWebAds && <MaybeAdzone model={model} adsRoot={adsRoot} />}
					</>}
			</ProfileDesktopLayoutManager>;
});
function MaybeAdzone({
  model,
  adsRoot
}: typeof ProfileDesktopContainer.TPropsWithModel & {
  adsRoot: typeof $AdsService.T;
}): JSX.Element {
  if (adsRoot.areWebAdsVisible) {
    const adsWidth = model.adsWidth();
    return <div onClick={toPointerHandler(model.props.onWrapperPress)} style={{
      width: resolveThemingValue(adsWidth, "sizes", useTheme()),
      right: resolveThemingValue(adSpacingRight, "spacing", useTheme()),
      paddingTop: resolveThemingValue(adPaddingTop, "spacing", useTheme()),
      paddingBottom: resolveThemingValue(adPaddingBottom, "spacing", useTheme()),
      zIndex: resolveThemingValue(Z_INDEX.ABOVE_MODAL, "theme", useTheme())
    }} className={_c6}>
				<div className={_c7}>
					<adsRoot.Adzone adzone="profile" verticalAd fixedSize={adsWidth} />
				</div>
			</div>;
  } else {
    return null;
  }
}
const adPaddingTop = 'small';
const adPaddingBottom = 'base';
const adSpacingLeft = 'large';
const adSpacingRight = 'large';
const useIsProfileTopOverlay = () => {
  const overlayService = useService($OverlayService);
  return useReactiveState(() => {
    const overlayCount = overlayService.overlays.length;
    return overlayCount > 0 && overlayService.overlays[overlayCount - 1].tag === profileViewId.id && !overlayService.isSystemAppOverlayVisible;
  }, [overlayService]);
};
const _c0 = " Knu-FlexCol position-absolute bottom-none shadow-Shadow1 width-full maxWidth-320px alignSelf-center zIndex-1 ";
const _c1 = " Knu-FlexCol position-absolute inset-none justifyContent-center bg-transparent ";
const _c2 = " alignItems-center ";
const _c3 = " alignItems-flex-end ";
const _c4 = " Knu-Flex bg-contentLightBg borderRadius-base zIndex-1 overflow-hidden shadow-Shadow4 position-relative ";
const _c5 = " Knu-FlexCol position-absolute left-none top-none ";
const _c6 = " Knu-FlexCol position-absolute top-large bottom-large placeItems-center ";
const _c7 = " Knu-FlexCol flex-1 ";