import * as React from 'react';
import { useTimer } from '@knuddels-app/tools/useTimer';
import { HappyHourFragment } from '@generated/graphql';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { createAccessiblePointerEventHandler, Flex, Image, Text, ThemeOverride, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export const HappyHourBanner: React.FC<{
  happyHour: HappyHourFragment;
}> = ({
  happyHour
}) => {
  const execute = useSlashCommandWithoutChannel();
  const [timerText, done] = useTimer(parseInt(happyHour.endTimestamp, 10), 'hh:mm:ss');
  if (done) {
    return null;
  }
  const color = happyHour.color ? colorToRgbaString(happyHour.color) : undefined;
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      execute('/buyknuddel');
    }
  })} style={{
    background: resolveThemingValue((color as ThemeOverride), "colors", useTheme())
  }} className={_c0 + ((color as ThemeOverride) ? resolveIsDarkColor((color as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Image alt={''} src={require('./happyhour_default.png')} className={_c1} />
			<Text type={'tiny'} className={_c2}>
				<Text type={'tiny'} bold={true} className={_c3}>
					HAPPY HOUR
				</Text>
				<FormattedMessage id={declareFormat({
        id: 'happyhourbanner.endsin',
        defaultFormat: ' - ENDS IN '
      })} />
				<Text type={'tiny'} bold={true} className={_c4}>
					{timerText}
					<FormattedMessage id={declareFormat({
          id: 'happyhourbanner.bonus',
          defaultFormat: '- up to {maxBonusPercentage}% more'
        })} values={{
          maxBonusPercentage: happyHour.maxBonusPercentage
        }} />
				</Text>
			</Text>
		</div>;
};
const _c0 = " Knu-Flex cursor-pointer placeItems-center zIndex-1 height-26px alignSelf-stretch gap-small ";
const _c1 = " height-full width-auto ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";