import { mixNumber as h } from "./number.js";
import { mixColor as u } from "./color.js";
import { pipe as b } from "../pipe.js";
import { color as m } from "../../value/types/color/index.js";
import { complex as y, analyseComplexValue as c } from "../../value/types/complex/index.js";
import { isCSSVariableToken as v } from "../../render/dom/utils/is-css-variable.js";
import { invisibleValues as f, mixVisibility as g } from "./visibility.js";
import { mixImmediate as l } from "./immediate.js";
function V(e, r) {
  return (t) => h(e, r, t);
}
function p(e) {
  return typeof e == "number" ? V : typeof e == "string" ? v(e) ? l : m.test(e) ? u : S : Array.isArray(e) ? a : typeof e == "object" ? m.test(e) ? u : C : l;
}
function a(e, r) {
  const t = [...e], o = t.length, n = e.map((i, s) => p(i)(i, r[s]));
  return (i) => {
    for (let s = 0; s < o; s++)
      t[s] = n[s](i);
    return t;
  };
}
function C(e, r) {
  const t = { ...e, ...r }, o = {};
  for (const n in t)
    e[n] !== void 0 && r[n] !== void 0 && (o[n] = p(e[n])(e[n], r[n]));
  return (n) => {
    for (const i in o)
      t[i] = o[i](n);
    return t;
  };
}
function I(e, r) {
  var t;
  const o = [], n = { color: 0, var: 0, number: 0 };
  for (let i = 0; i < r.values.length; i++) {
    const s = r.types[i], x = e.indexes[s][n[s]], d = (t = e.values[x]) !== null && t !== void 0 ? t : 0;
    o[i] = d, n[s]++;
  }
  return o;
}
const S = (e, r) => {
  const t = y.createTransformer(r), o = c(e), n = c(r);
  return o.indexes.var.length === n.indexes.var.length && o.indexes.color.length === n.indexes.color.length && o.indexes.number.length >= n.indexes.number.length ? f.has(e) && !n.values.length || f.has(r) && !o.values.length ? g(e, r) : b(a(I(o, n), n.values), t) : l(e, r);
};
export {
  p as getMixer,
  a as mixArray,
  S as mixComplex,
  C as mixObject
};
