import * as React from 'react';
import { UploadProfilePictureProgressModal } from '../../components/UploadModal/UploadProfilePictureProgressModal';
import { uploadProfilePicture, UploadProfilePictureResult } from './uploadProfilePicture';
import { UploadProfilePictureEvents } from './interfaces';
import { SelectFileResult } from '@knuddels-app/tools/filePicking';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { $OverlayService } from '@knuddels-app/overlays';
async function uploadProfilePictureWithProgressModal(selectPictureResult: SelectFileResult, nick: string, otp: string | undefined, endpoint: string, eventHandlers: UploadProfilePictureEvents): Promise<UploadProfilePictureResult> {
  eventHandlers.onProgressModalShown();
  const overlay = dangerouslyGetK3Container().getService($OverlayService).showOverlay({
    view: <UploadProfilePictureProgressModal />
  });
  eventHandlers.onUploadTaskStarted();
  const uploadResult = await uploadProfilePicture(selectPictureResult, nick, endpoint, otp);
  eventHandlers.onUploadTaskEnded();
  if (uploadResult === UploadProfilePictureResult.SUCCESS) {
    eventHandlers.onUploadSuccess();
  } else {
    eventHandlers.onUploadError();
  }
  overlay.dispose();
  return uploadResult;
}
export async function uploadProfilePictureWithProgressModalByOTP(selectPictureResult: SelectFileResult, nick: string, otp: string, eventHandlers: UploadProfilePictureEvents): Promise<boolean> {
  const result = await uploadProfilePictureWithProgressModal(selectPictureResult, nick, otp, '/photos-loginotpuploadprofile.pl', eventHandlers);
  return result === UploadProfilePictureResult.SUCCESS;
}
export async function uploadProfilePictureWithProgressModalByJWT(selectPictureResult: SelectFileResult, nick: string, eventHandlers: UploadProfilePictureEvents): Promise<UploadProfilePictureResult> {
  return uploadProfilePictureWithProgressModal(selectPictureResult, nick, undefined, '/photos-loginjwtuploadprofile.pl', eventHandlers);
}