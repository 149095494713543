import { isSVGElement as o } from "../../render/dom/utils/is-svg-element.js";
import { SVGVisualElement as n } from "../../render/svg/SVGVisualElement.js";
import { HTMLVisualElement as s } from "../../render/html/HTMLVisualElement.js";
import { visualElementStore as a } from "../../render/store.js";
function u(t) {
  const e = {
    presenceContext: null,
    props: {},
    visualState: {
      renderState: {
        transform: {},
        transformOrigin: {},
        style: {},
        vars: {},
        attrs: {}
      },
      latestValues: {}
    }
  }, r = o(t) ? new n(e) : new s(e);
  r.mount(t), a.set(t, r);
}
export {
  u as createVisualElement
};
