import { jsx as t } from "react/jsx-runtime";
import * as n from "react";
import { Flex as i } from "./Flex.js";
import { FlexCol as u } from "./FlexCol.js";
import { ErrorWithDocsReference as h } from "../../docs/helper.js";
import { useIsOverflowing as m } from "./hooks/useIsOverflowing.js";
import { ScrollView as x } from "./Box/ScrollView.js";
const d = n.createContext(null), s = () => {
  const e = n.useContext(d);
  if (!e)
    throw new h(
      "The ButtonBar needs to be inside of a button bar container.",
      "api/buttonbar"
    );
  return e;
}, p = (e) => {
  const [r, o] = n.useState(!1);
  return /* @__PURE__ */ t(
    d.Provider,
    {
      value: {
        isOverflowing: r,
        onStateChange: o
      },
      children: /* @__PURE__ */ t(
        u,
        {
          minHeight: 0,
          flex: e.shrinkHeight ? void 0 : 1,
          borderBottomRadius: e.borderBottomRadius,
          overflow: "hidden",
          children: e.children
        }
      )
    }
  );
}, k = (e) => {
  const { onStateChange: r } = s(), o = m(r);
  return /* @__PURE__ */ t(
    x,
    {
      ...o,
      flex: e.shrinkHeight ? void 0 : 1,
      minHeight: 0,
      children: e.children
    }
  );
}, B = ({ bg: e, children: r, variant: o = "default" }) => {
  const { isOverflowing: c } = s(), l = n.Children.count(r);
  return /* @__PURE__ */ t(
    i,
    {
      shadow: c ? "ShadowNegative1" : "none",
      borderTop: "solid",
      borderWidth: "base",
      borderColor: "inputFieldBorder",
      flexShrink: 0,
      bg: e,
      px: "large",
      py: "base",
      justifyContent: "center",
      children: /* @__PURE__ */ t(i, { flex: 1, justifyContent: "center", children: n.Children.map(r, (a, f) => f === 0 && l > 1 ? /* @__PURE__ */ t(
        i,
        {
          flexShrink: 0,
          flex: o === "default" ? 1 : void 0,
          style: {
            maxWidth: "fit-content"
          },
          children: n.cloneElement(a, { flex: 1 })
        }
      ) : /* @__PURE__ */ t(
        i,
        {
          ml: l > 1 ? "small" : void 0,
          flexShrink: 1,
          flex: 1,
          style: {
            maxWidth: "fit-content"
          },
          children: n.cloneElement(a, { flex: 1 })
        }
      )) })
    }
  );
}, y = (e) => /* @__PURE__ */ t(B, { bg: "buttonBarBg", variant: e.variant, children: e.children });
export {
  y as ButtonBar,
  p as ButtonBarContainer,
  k as ButtonBarContent,
  B as GenericButtonBar
};
