import * as React from 'react';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { ProfileSubViews } from '../../../ProfileSubViews';
import { EditProfile } from './EditProfile';
import { GetAlbumInfoForProfile, GetCurrentUserNickProps, GetUserForProfile, User, withGetCurrentUserNick } from '@generated/graphql';
import { $ScreenService, ScreenWidth } from '@knuddels-app/Screen';
import { OwnProfileViewClosedEvent, ProfileViewClosedEvent } from '../../../analytics';
import { ProfilePicture } from './ProfilePicture';
import { ProfileDesktopContainer } from './ProfileDesktopContainer';
import { $ViewService } from '@knuddels-app/layout';
import { profileViewId } from '../../../ProfileViewProvider';
import { $OverlayService } from '@knuddels-app/overlays';
import { Disposable } from '@knuddels/std';
import { useQueryWithSyncedCache } from '@knuddels-app/Connection';
import { RefetchProfileContext, UpdateProfileAlbumInfoQueryContext, UpdateProfileUserQueryContext } from '../profileQueryHelper';
import { $AlbumPhotoUploadService, $ProfilePictureUploadService } from '../../../providedServices';
import { SelectedProfileTabContextProvider } from './SelectedProfileTabContext';
import { ProfileEditor } from './EditProfile/EditProfileContent/logic/ProfileEditor';
import { useTrackAlbumImagesAvailable } from './useTrackAlbumImagesAvailable';
import { SubscribeAndUpdateFriendState } from './SubscribeAndUpdateFriendState';
import { useTrackFriendStateOnProfileOpen } from '../shared/useTrackFriendStateOnProfileOpen';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { TabType } from './ProfileTabsMapping';
import { SubscribeAndUpdateIgnoreState } from './SubscribeAndUpdateIgnoreState';
import { Profile } from './Profile';
import { FlexCol, PointerEvent, FallbackBox, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { ProfileContentRouterConfig } from './ProfileContent/ProfileContentRouter';
import { InitialProfileContentRouteContext } from './ProfileContentRouter';
import { ProfileShowNickSwitchIconContext } from './ProfileShowNickSwitchIconConext';
import { SubscribeAndUpdateEmail } from './SubscribeAndUpdateEmail';
import { SubscribeAndUpdateProfilePicture } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/SubscribeAndUpdateProfilePicture';
import { SubscribeAndUpdateProfilePictureOverlays } from '@knuddelsModules/Profile/bundle/components/ProfileOverlay/SubscribeAndUpdateProfilePictureOverlays';
export type CurrentUser = GetCurrentUserNickProps['data']['user']['currentUser'];
export type CurrentUserProps = {
  activeUser: CurrentUser;
};
type OwnProps = {
  userId: User['id'];
  shownSubView?: ProfileSubViews;
  initialTab?: TabType;
  initialRoute?: keyof ProfileContentRouterConfig;
  showNickSwitchIcon?: boolean;
};
type Props = OwnProps & CurrentUserProps;
@injectable()
class ProfileRoutingModel implements IModel {
  public readonly dispose = Disposable.fn();
  private editor: ProfileEditor | undefined;
  constructor(@injectProps()
  private readonly props: Props, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T) {
    this.dispose.track(() => {
      if (this.currentUser.id === this.displayedUserId) {
        OwnProfileViewClosedEvent.track('Closed');
      } else {
        ProfileViewClosedEvent.track('Closed');
      }
    });
  }
  public get currentUser(): Pick<User, 'id'> {
    // default values?
    return this.props.activeUser;
  }
  public get screenWidth(): ScreenWidth {
    return this.screenService.screenWidth;
  }
  public get isStackedLayout(): boolean {
    return this.screenService.isStackedLayout;
  }
  public readonly onWrapperPress = (e: PointerEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    if (this.editor && this.editor.hasChanges) {
      this.editor.save();
    }
    this.doHandleWrapperPress();
  };
  private readonly doHandleWrapperPress = (): void => {
    this.viewService.closeView(profileViewId);
  };
  public get displayedUserId(): User['id'] {
    return this.props.shownSubView === ProfileSubViews.edit ? this.currentUser.id : this.props.userId;
  }
  public setEditor = (editor: ProfileEditor | undefined): void => {
    this.editor = editor;
  };
}
const ProfileRoutingBase = injectedComponent({
  name: 'ProfileRoutingBase',
  model: ProfileRoutingModel,
  props: declareProps<Props>()
}, ({
  model,
  userId,
  ...props
}) => {
  const commonProps = {
    currentUser: model.currentUser,
    isStackedLayout: model.isStackedLayout
  };
  const {
    data: userData,
    refetch: refetchUser,
    updateCache: updateUserCache
  } = useQueryWithSyncedCache(GetUserForProfile, {
    userId,
    pixelDensity: getPixelRatio()
  });
  const {
    data: albumInfoData,
    refetch: refetchAlbums,
    updateCache: updateAlbumsCache
  } = useQueryWithSyncedCache(GetAlbumInfoForProfile, {
    userId
  });
  const isCurrentUser = model.currentUser.id === userId;
  const refetch = () => {
    refetchUser();
    refetchAlbums();
  };
  useHasLeftProfileEdit(props.shownSubView, () => {
    // This is necessary because the query is not necessarily updated
    // by the editor and might show an old state.
    refetch();
  });
  useTrackAlbumImagesAvailable(albumInfoData);
  useTrackFriendStateOnProfileOpen(model.currentUser.id, userData?.user?.user);
  const ProfileComponent = props.shownSubView === ProfileSubViews.edit ? <EditProfile {...commonProps} onEditor={model.setEditor} profileUserData={userData} profileAlbumInfoData={albumInfoData} /> : <Profile userId={userId} profileUser={userData} profileAlbumInfo={albumInfoData} {...commonProps} />;
  const albumPhotoUploadService = useService($AlbumPhotoUploadService);
  React.useEffect(() => {
    return albumPhotoUploadService.onUploadFinished.sub(() => {
      refetch();
    }).dispose;
  }, []);
  const profilePictureUploadService = useService($ProfilePictureUploadService);
  React.useEffect(() => {
    return profilePictureUploadService.onProfilePictureDeleted.sub(() => {
      refetch();
    }).dispose;
  }, []);
  const [width, setWidth] = React.useState(0);
  return <RefetchProfileContext.Provider value={refetch}>
				<UpdateProfileUserQueryContext.Provider value={updateUserCache}>
					<UpdateProfileAlbumInfoQueryContext.Provider value={updateAlbumsCache}>
						<SelectedProfileTabContextProvider userId={userId} initialTab={props.initialTab}>
							<InitialProfileContentRouteContext.Provider value={props.initialRoute || 'overview'}>
								<ProfileShowNickSwitchIconContext.Provider value={props.showNickSwitchIcon}>
									<SubscribeAndUpdateFriendState userId={userId} />
									<SubscribeAndUpdateIgnoreState userId={userId} />
									{isCurrentUser && <>
											<SubscribeAndUpdateProfilePicture userId={userId} />
											<SubscribeAndUpdateProfilePictureOverlays userId={userId} />
											<SubscribeAndUpdateEmail userId={userId} />
										</>}
									<>
										{model.isStackedLayout ? <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
												<FallbackBox onLayout={e => setWidth(e.width)} style={{
                    height: resolveThemingValue(width || 0, "sizes", useTheme())
                  }} className={_c1}>
													{!!width && <ProfilePicture userId={model.displayedUserId} size={width} />}
												</FallbackBox>
												{ProfileComponent}
											</div> : <ProfileDesktopContainer userId={model.displayedUserId} onWrapperPress={model.onWrapperPress}>
												{ProfileComponent}
											</ProfileDesktopContainer>}
									</>
								</ProfileShowNickSwitchIconContext.Provider>
							</InitialProfileContentRouteContext.Provider>
						</SelectedProfileTabContextProvider>
					</UpdateProfileAlbumInfoQueryContext.Provider>
				</UpdateProfileUserQueryContext.Provider>
			</RefetchProfileContext.Provider>;
});
const RefreshOnUserChange: React.FC<OwnProps & {
  activeUser: CurrentUser | undefined;
}> = props => {
  if (!props.activeUser) {
    return null;
  }
  const userId = props.userId ?? props.activeUser.id;
  return <ProfileRoutingBase key={userId} {...props} userId={userId} />;
};

// TODO move this to the UserData module
export const ProfileRoutingComponent = withGetCurrentUserNick<OwnProps, CurrentUserProps,
// used so we don't need to pass data
undefined>({
  props: props => ({
    ...props,
    activeUser: props.data && props.data.user && props.data.user.currentUser
  })
})(RefreshOnUserChange);
const useHasLeftProfileEdit = (subview: ProfileSubViews, cb: () => void) => {
  const lastSubviewRef = React.useRef(subview);
  React.useEffect(() => {
    if (lastSubviewRef.current !== subview && lastSubviewRef.current === ProfileSubViews.edit) {
      cb();
    }
    lastSubviewRef.current = subview;
  }, [subview, cb]);
};
const _c0 = " Knu-FlexCol bg-contentBg flex-1 zIndex-0 position-relative overflow-hidden ";
const _c1 = " Knu-FlexCol position-absolute insetX-none top-none ";