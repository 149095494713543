import { declareFormat } from '@knuddels/i18n';

export const fotomeetRemoveFormat = declareFormat({
	id: 'contacts.fotomeet.remove',
	defaultFormat: 'Remove match',
});

export const endFriendshipFormat = declareFormat({
	id: 'friends.end-friendship',
	defaultFormat: 'End friendship',
});

export const removeFromWatchlistFormat = declareFormat({
	id: 'contacts.remove-from-watchlist',
	defaultFormat: 'Remove bookmark',
});
