import * as React from 'react';
import { Flex, IconEdit, Link, ListItem, ListItemProps, Text } from '@knuddels/component-library';
export const SettingsValueItem: React.FC<{
  value?: string | null;
  fallbackText?: string;
  onEdit?(): void;
} & ListItemProps> = ({
  value,
  fallbackText,
  onEdit,
  ...listItemProps
}) => {
  const adornment = value ? <div className={_c0}>
			<Text state={'tertiary'} className={_c1}>{value}</Text>
			{onEdit && <IconEdit onPress={onEdit} />}
		</div> : fallbackText ? <Link onPress={onEdit} disabled={!onEdit}>
			<Text color={'accent'} className={_c2}>{fallbackText}</Text>
		</Link> : undefined;
  return <ListItem adornmentRight={adornment} {...listItemProps} />;
};
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";
const _c2 = "  ";