import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import * as React from 'react';
import { Fade, Flex, Modal, PointerEvent, rgb, Scale, Text, ThemeOverride, useEvent, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { isMobileSafari } from '@knuddels-app/tools/isMobileSafari';
import { useSetGridRef, useSetScrollState } from './BoxHeader';
export const ItemRow: React.FC<{
  itemSpacing: number;
  paddingBottom?: number;
  pl?: number;
  pr?: number;
}> = React.memo(({
  children,
  itemSpacing,
  paddingBottom,
  pl,
  pr
}) => {
  return <div style={{
    gap: resolveThemingValue((itemSpacing as ThemeOverride), "spacing", useTheme()),
    paddingBottom: resolveThemingValue(paddingBottom ?? (itemSpacing as ThemeOverride), "spacing", useTheme()),
    paddingLeft: resolveThemingValue((pl as ThemeOverride), "spacing", useTheme()),
    paddingRight: resolveThemingValue((pr as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			{children}
		</div>;
});
ItemRow.displayName = 'ItemRow';
type GroupHeaderProps = {
  label: string;
  badgeText?: string;
  type: 'header';
};
const isGroupHeader = (item: any): item is GroupHeaderProps => {
  return 'type' in item && item.type === 'header';
};
const RowRenderer = React.memo(<T,>({
  index,
  items,
  itemSpacing,
  paddingBottom,
  pr,
  pl,
  renderItem
}: {
  itemSpacing: number;
  index: number;
  pl?: number;
  paddingBottom?: number;
  pr?: number;
  items: (GroupHeaderProps | T[])[];
  renderItem: (item: T) => JSX.Element;
}) => {
  const item = items[index];
  if (isGroupHeader(item)) {
    return <div style={{
      paddingLeft: resolveThemingValue(pl ?? (itemSpacing as ThemeOverride), "spacing", useTheme()),
      paddingRight: resolveThemingValue(pr ?? (itemSpacing as ThemeOverride), "spacing", useTheme()),
      paddingBottom: resolveThemingValue((itemSpacing as ThemeOverride), "spacing", useTheme()),
      paddingTop: resolveThemingValue(index !== 0 ? 'base' : undefined, "spacing", useTheme())
    }} className={_c1}>
					{item.badgeText && <div className={_c2 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<Text type={'tiny'} bold={true} className={_c3}>
								{item.badgeText}
							</Text>
						</div>}
					<Text type={'body2'} bold={true} state={'secondary'} className={_c4}>
						{item.label}
					</Text>
				</div>;
  }
  return <ItemRow itemSpacing={itemSpacing} pl={pl} pr={pr} paddingBottom={paddingBottom}>
				{(items[index] as T[]).map(data => {
      return renderItem(data);
    })}
			</ItemRow>;
});
RowRenderer.displayName = 'RowRenderer';
export const ContainerContext = React.createContext<React.RefObject<HTMLElement> | null>(null);
type Group<T> = {
  items: T[];
  label?: string;
  badgeText?: string;
};
export const Grid = <GroupType extends Group<any>[],>(props: {
  itemSize: number;
  itemSpacing: number;
  bottomSpacing?: number;
  gridRef?: React.MutableRefObject<VirtuosoHandle | null>;
  maxItemsPerRow: number;
  onScroll?: (scrollTop: number) => void;
  pl?: number;
  pr?: number;
  onEndReached?: (index: number) => void;
  groups: GroupType;
  renderItem: (item: GroupType[number]['items'][0]) => JSX.Element;
}) => {
  const setScrollState = useSetScrollState();
  const setGridRef = useSetGridRef();
  const rowsInGroup = (groupItems: GroupType[number]['items']) => {
    return Math.ceil(groupItems.length / props.maxItemsPerRow);
  };
  const totalItemCount = props.groups.reduce((acc, group) => {
    return acc + rowsInGroup(group.items) + 1;
  }, 0);
  const items = (props.groups.reduce((acc, group) => {
    if (group.items.length === 0) {
      return acc;
    }
    if (group.label) {
      acc.push(({
        label: group.label,
        badgeText: group.badgeText,
        type: 'header'
      } as GroupHeaderProps));
    }
    for (let i = 0; i < rowsInGroup(group.items); i++) {
      acc.push(group.items.slice(i * props.maxItemsPerRow, i * props.maxItemsPerRow + props.maxItemsPerRow).filter(Boolean));
    }
    return acc;
  }, []) as (GroupHeaderProps | GroupType[])[]);
  const cachedRenderItem = useEvent(props.renderItem);
  return <Virtuoso ref={ref => {
    if (ref) {
      setGridRef(ref);
      if (props.gridRef) {
        props.gridRef.current = ref;
      }
    }
  }} increaseViewportBy={props.itemSize * 2.5} style={{
    height: '100%',
    width: '100%'
  }} onScroll={e => {
    const scrollTop = (e.target as HTMLElement).scrollTop;
    props.onScroll?.(scrollTop);
    setScrollState(scrollTop > 0 ? 'scrolled' : 'notScrolled');
  }} endReached={props.onEndReached} defaultItemHeight={props.itemSize + props.itemSpacing} data={items} totalCount={totalItemCount} itemContent={index => {
    return <RowRenderer key={index} pl={props.pl} pr={props.pr} renderItem={cachedRenderItem} itemSpacing={props.itemSpacing} paddingBottom={index === items.length - 1 ? props.bottomSpacing ?? props.itemSpacing : undefined} index={index} items={items} />;
  }} />;
};
type RenderContextMenuProps<T> = {
  getItems: () => Promise<T[]>;
  renderItem: (item: T) => JSX.Element;
};
const useLongPress = (callback: (e: PointerEvent) => void) => {
  const touchTimerRef = React.useRef<number | null>(null);
  const pointerDownPositionRef = React.useRef<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0
  });
  const cb = useEvent(callback);
  const onPointerDown = (event: PointerEvent) => {
    event.preventDefault();
    pointerDownPositionRef.current = {
      x: event.pageX,
      y: event.pageY
    };

    // Clear any existing timer
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }

    // For touch events (not mouse clicks), set up a long-press timer
    if (event.originalEvent.pointerType === 'touch') {
      touchTimerRef.current = window.setTimeout(() => {
        cb(event);
      }, 500); // Long press threshold (e.g., 500 ms)
    }
  };

  const onPointerUp = (e: PointerEvent) => {
    e.preventDefault();
    // Clear the long-press timer on release
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }
  };
  const onPointerMove = (event: PointerEvent) => {
    const dx = event.pageX - pointerDownPositionRef.current.x;
    const dy = event.pageY - pointerDownPositionRef.current.y;

    // If the user has moved the pointer too far, cancel the long-press timer
    if (Math.abs(dx) > 10 || Math.abs(dy) > 10) {
      if (touchTimerRef.current) {
        clearTimeout(touchTimerRef.current);
        touchTimerRef.current = null;
      }
    }
  };
  return {
    onPointerDown,
    onPointerUp,
    onPointerMove
  };
};
export const useContextMenu = (anchor: React.MutableRefObject<HTMLElement>, active: boolean) => {
  const [contextMenuPosition, setContextMenuPosition] = React.useState<{
    bottom: number;
    left: number;
  } | null>(null);
  const openMenu = (e: PointerEvent) => {
    if (!active) {
      return;
    }
    e.preventDefault();
    const elementPos = anchor.current.getBoundingClientRect();
    setContextMenuPosition({
      bottom: elementPos.bottom - elementPos.height,
      left: elementPos.left
    });
  };
  const longPressProps = useLongPress(openMenu);
  return {
    contextMenuPosition,
    triggerProps: ({
      onSecondaryPress: !isMobileSafari() ? openMenu : undefined,
      ...(isMobileSafari() ? longPressProps : {})
    } as const),
    closeMenu: () => {
      setContextMenuPosition(null);
    }
  };
};
export const Item = <T,>({
  renderContextMenu,
  onPress,
  children,
  multi,
  title
}: {
  title?: string;
  renderContextMenu?: RenderContextMenuProps<T>;
  onPress?: () => void;
  children?: React.ReactNode;
  multi?: boolean;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const {
    triggerProps,
    closeMenu,
    contextMenuPosition
  } = useContextMenu(ref, !!renderContextMenu);
  return <>
			<Flex
    // @ts-expect-error not exported by component-library
    title={title} innerRef={ref} overflow={'hidden'} borderRadius={'minor'} size={48} bg={theme.id === 'dark' ? rgb(41, 41, 41) : theme.colors.basic['white-solid-880']} placeItems={'center'} cursor={'pointer'} position={'relative'} onPress={e => {
      e.preventDefault();
      onPress();
    }} {...triggerProps}>
				<div style={{
        display: 'contents'
      }} className={_c5}>
					{children}
				</div>
				{multi && <div className={_c6}>
						<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 0L1.58661 0C0.672575 0 0.237606 1.1251 0.913935 1.73994L8.32733 8.47939C8.9697 9.06336 10 8.60759 10 7.73945L10 1C10 0.447715 9.55229 0 9 0Z" fill={theme.colors.basic.contentBg} />
						</svg>
					</div>}
			</Flex>
			{contextMenuPosition && renderContextMenu && <ContextMenu renderContextMenu={renderContextMenu} renderContextPosition={contextMenuPosition} onClose={closeMenu} />}
		</>;
};
const MAX_ROWS = 2;
const MAX_COLUMNS = 5;
const SPACE_BETWEEN_ITEMS = 4;
export const ContextMenu = <T,>({
  onClose,
  renderContextMenu,
  renderContextPosition
}: {
  onClose: () => void;
  renderContextPosition: {
    bottom: number;
    left: number;
  };
  renderContextMenu: RenderContextMenuProps<T>;
}) => {
  const [render, setRender] = React.useState(false);
  const container = React.useContext(ContainerContext);
  const [items, setItems] = React.useState<T[]>([]);
  const theme = useTheme();
  React.useEffect(() => {
    renderContextMenu.getItems().then(setItems);
  }, []);
  React.useEffect(() => {
    setRender(items.length > 0);
  }, [items]);
  if (!items.length) {
    return null;
  }
  const rows = Math.min(Math.ceil(items.length / MAX_COLUMNS), MAX_ROWS);
  const height = Math.min(56 * rows);
  const width = (48 + SPACE_BETWEEN_ITEMS) * Math.min(MAX_COLUMNS, items.length);
  const left = renderContextPosition.left - SPACE_BETWEEN_ITEMS;
  const actualLeft = left + width + SPACE_BETWEEN_ITEMS * 2 > container.current.getBoundingClientRect().width + container.current.getBoundingClientRect().left ? container.current.getBoundingClientRect().left + container.current.getBoundingClientRect().width - width - SPACE_BETWEEN_ITEMS * 2 : left;
  return <Modal backgroundStyle={'transparent'} onPress={onClose}>
			<Scale scaled={render} style={{
      height: resolveThemingValue(height, "sizes", useTheme()),
      width: resolveThemingValue(width, "sizes", useTheme()),
      background: resolveThemingValue(theme.id === 'dark' ? rgb(113, 113, 113) : theme.colors.basic.contentBg, "colors", useTheme()),
      top: resolveThemingValue((renderContextPosition.bottom - height as ThemeOverride), "spacing", useTheme()),
      left: resolveThemingValue((actualLeft as ThemeOverride), "spacing", useTheme())
    }} className={_c7 + ((theme.id === 'dark' ? rgb(113, 113, 113) : theme.colors.basic.contentBg) ? resolveIsDarkColor(theme.id === 'dark' ? rgb(113, 113, 113) : theme.colors.basic.contentBg, useTheme()) ? " content-is-dark" : " content-is-light" : "") + (actualLeft === left ? _c8 : _c9)}>
				<Fade visible={render} className={_c10}>
					<Grid groups={[{
          items: items
        }]} itemSize={48} pl={SPACE_BETWEEN_ITEMS} pr={SPACE_BETWEEN_ITEMS} itemSpacing={SPACE_BETWEEN_ITEMS} bottomSpacing={rows === 1 ? 0 : SPACE_BETWEEN_ITEMS} maxItemsPerRow={MAX_COLUMNS} renderItem={renderContextMenu.renderItem} />
				</Fade>
			</Scale>
		</Modal>;
};
const _c0 = " Knu-Flex flex-1 ";
const _c1 = " Knu-Flex alignItems-center gap-small ";
const _c2 = " Knu-Flex bg-accent borderRadius-tiny px-tiny ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = " Knu-Flex pointerEvents-none ";
const _c6 = " Knu-Flex position-absolute zIndex-1 right-tiny top-tiny ";
const _c7 = " alignItems-center borderRadius-minor shadow-Shadow4 position-absolute ";
const _c8 = " transformOrigin-bottomleft ";
const _c9 = " transformOrigin-bottomright ";
const _c10 = " size-full pt-tiny ";