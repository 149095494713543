import * as i from "react";
const r = () => {
  if (!window)
    return { height: 0, width: 0 };
  const [n, t] = i.useState(() => ({
    width: window.innerWidth,
    height: window.innerHeight
  }));
  return i.useEffect(() => {
    const e = () => {
      t({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    return window.addEventListener("resize", e), () => {
      window.removeEventListener("resize", e);
    };
  }, []), n;
};
export {
  r as useScreenSize
};
