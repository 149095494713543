import { mixNumber as v } from "../../utils/mix/number.js";
import { percent as p } from "../../value/types/numbers/units.js";
import { scalePoint as r } from "./delta-apply.js";
function c(e, m, f, n, d) {
  return e -= m, e = r(e, 1 / f, n), d !== void 0 && (e = r(e, 1 / d, n)), e;
}
function D(e, m = 0, f = 1, n = 0.5, d, u = e, y = e) {
  if (p.test(m) && (m = parseFloat(m), m = v(y.min, y.max, m / 100) - y.min), typeof m != "number")
    return;
  let i = v(u.min, u.max, n);
  e === u && (i -= m), e.min = c(e.min, m, f, i, d), e.max = c(e.max, m, f, i, d);
}
function P(e, m, [f, n, d], u, y) {
  D(e, m[f], m[n], m[d], m.scale, u, y);
}
const K = ["x", "scaleX", "originX"], T = ["y", "scaleY", "originY"];
function N(e, m, f, n) {
  P(e.x, m, K, f ? f.x : void 0, n ? n.x : void 0), P(e.y, m, T, f ? f.y : void 0, n ? n.y : void 0);
}
export {
  D as removeAxisDelta,
  P as removeAxisTransforms,
  N as removeBoxTransforms,
  c as removePointDelta
};
