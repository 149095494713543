import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PlaceholderContent } from '@shared/components';
export const FriendsHiddenPlaceholder: React.FC<{
  nick: string;
  fullHeight: boolean;
}> = ({
  nick,
  fullHeight
}) => <PlaceholderContent image={require('@shared/images/sorry.gif')} imageWidth={94} imageHeight={56} title={nick} fullHeight={fullHeight} description={<FormattedMessage id={declareFormat({
  id: 'profile.friends.hidden',
  defaultFormat: 'has hidden their friends through the privacy settings'
})} />} />;