import * as React from 'react';
import { ContactsUser } from '../../../ContactsUser';
import { ContactsTab } from '../ContactTabs';
import { $ContactsService } from '@knuddelsModules/Contacts';

export const ContactsMenuContext = React.createContext<{
	isActive: boolean;
	getMenuEntriesToShow: (
		user: ContactsUser,
		contactService: typeof $ContactsService.T
	) => ContactsTab[];
	openMenuForUserId: string | undefined;
	openMenu(
		userId: string,
		menuEntriesToShow: ContactsTab[],
		layout: { x: number; y: number; width: number; height: number }
	): void;
}>({
	isActive: false,
	getMenuEntriesToShow: () => [],
	openMenuForUserId: undefined,
	openMenu: () => {},
});

export const useContactsMenuContext = () => {
	return React.useContext(ContactsMenuContext);
};
