import { resolveFinalValueInKeyframes as s } from "../../utils/resolve-value.js";
import { motionValue as f } from "../../value/index.js";
import { resolveVariant as V } from "./resolve-dynamic-variants.js";
function l(o, t, e) {
  o.hasValue(t) ? o.getValue(t).set(e) : o.addValue(t, f(e));
}
function p(o, t) {
  const e = V(o, t);
  let { transitionEnd: a = {}, transition: c = {}, ...r } = e || {};
  r = { ...r, ...a };
  for (const n in r) {
    const i = s(r[n]);
    l(o, n, i);
  }
}
export {
  p as setTarget
};
