import { jsx as i } from "react/jsx-runtime";
import { useState as b } from "react";
import { clsx as c } from "../../node_modules/clsx/dist/clsx.m.js";
import "./Image.scss.js";
import { resolveThemingValue as m } from "../Layout/isDarkColor.js";
import { useTheme as l } from "../../themes/useTheme.js";
const F = (e) => {
  const {
    alt: g,
    src: a,
    fallbackSrc: t,
    resizeMode: r = "cover",
    loading: u = "eager",
    onLoad: d,
    imageRendering: f,
    className: s
  } = e, [h, v] = b(!1), n = m(
    e.size ?? e.width,
    "sizes",
    l()
  ), o = m(
    e.size ?? e.height,
    "sizes",
    l()
  );
  return r === "repeat" ? /* @__PURE__ */ i(
    "div",
    {
      className: c(
        "Knu-Image Image-Repeated pointerEvents-none",
        s
      ),
      style: {
        backgroundImage: `url(${a})`,
        width: n,
        height: o,
        ...e.style
      }
    }
  ) : /* @__PURE__ */ i(
    "img",
    {
      alt: g,
      loading: u,
      className: c(
        "Knu-Image pointerEvents-none",
        z[r],
        f,
        s
      ),
      style: {
        width: n,
        height: o,
        ...e.style
      },
      src: h && t ? t : a,
      onError: () => v(!!t),
      onLoad: d
    }
  );
}, z = {
  cover: "cover",
  contain: "contain",
  stretch: "fill"
};
export {
  F as Image
};
