import { renderSVG as i } from "./utils/render.js";
import { scrapeMotionValuesFromProps as s } from "./utils/scrape-motion-values.js";
import { makeUseVisualState as a } from "../../motion/utils/use-visual-state.js";
import { createSvgRenderState as p } from "./utils/create-render-state.js";
import { buildSVGAttrs as f } from "./utils/build-attrs.js";
import { isSVGTag as g } from "./utils/is-svg-tag.js";
import { frame as t } from "../../frameloop/frame.js";
const h = {
  useVisualState: a({
    scrapeMotionValuesFromProps: s,
    createRenderState: p,
    onMount: (e, o, { renderState: r, latestValues: m }) => {
      t.read(() => {
        try {
          r.dimensions = typeof o.getBBox == "function" ? o.getBBox() : o.getBoundingClientRect();
        } catch {
          r.dimensions = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          };
        }
      }), t.render(() => {
        f(r, m, g(o.tagName), e.transformTemplate), i(o, r);
      });
    }
  })
};
export {
  h as svgMotionConfig
};
