import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { DeeplinkHandler, DeepLinkType } from '../../DeeplinkTypes';
import { Disposable, DisposableLike } from '@knuddels/std';
import { $AuthService } from '@knuddels-app/Connection';
import { setupDeepLinking } from './setupDeepLinking';
import { $Environment } from '@knuddels-app/Environment';
import { $DeepLinkingStorage } from './DeepLinkingStorage';

export interface DeepLink {
	type: DeepLinkType;
	target?: string;
}

@injectable()
export class DeepLinkingService {
	public readonly dispose = Disposable.fn();

	private lastDeepLink: DeepLink | undefined;
	private readonly deepLinkHandlers: DeeplinkHandler[] = [];

	constructor(
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($DeepLinkingStorage)
		private readonly deepLinkingStorage: typeof $DeepLinkingStorage.T
	) {
		this.dispose.track(
			authService.onBeforeVoluntaryLogout.sub(() => {
				this.lastDeepLink = undefined;
			})
		);

		setupDeepLinking(environment, link => {
			this.handleDeepLink(link.type, link.target);
		}).then(dispose => {
			this.dispose.track(dispose);
		});

		const storedLink = this.deepLinkingStorage.getAndRemoveStoredDeepLink();
		if (storedLink) {
			setTimeout(() => {
				this.handleDeepLink(storedLink.type, storedLink.target);
			}, 1000);
		}
	}

	public registerDeeplinkHandler(handler: DeeplinkHandler): DisposableLike {
		this.deepLinkHandlers.push(handler);

		if (this.lastDeepLink) {
			handler(this.lastDeepLink.type, this.lastDeepLink.target || '');
		}

		return () => {
			const index = this.deepLinkHandlers.indexOf(handler);
			if (index > -1) {
				this.deepLinkHandlers.splice(index, 1);
			}
		};
	}

	public handleDeepLink(type: DeepLinkType, target?: string): void {
		this.deepLinkHandlers.forEach(handler => {
			handler(type, target || '');
		});

		this.lastDeepLink = { type, target };
	}
}
