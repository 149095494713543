import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { $CommandService } from '@knuddels-app/Commands';
import { Disposable } from '@knuddels/std';
import { $DeepLinkingService, DeepLinkType } from '@knuddelsModules/DeepLinking';
@injectable()
export class PaymentModule {
  public readonly dispose = Disposable.fn();
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($DeepLinkingService)
  deepLinkingService: typeof $DeepLinkingService.T) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de: require('../i18n/formats.de.json'),
      en: require('../i18n/formats.en.json')
    } as any)[locale.language]);
    this.dispose.track(commandService.registerCommand({
      commandName: 'openknuddelsplusshop',
      invoke: async (): Promise<void> => this.openOverviewModal()
    }));
    this.dispose.track(deepLinkingService.registerDeeplinkHandler((type, target) => {
      if (type === DeepLinkType.App && target.toLowerCase() === 'knuddelsplusshop') {
        this.openOverviewModal();
      }
    }));
  }
  public readonly openOverviewModal = (): void => {
    this.commandService.parseAndInvokeCommand('/opensystemapp KnuddelsPlusShopApp');
  };
}