const footer = document.getElementsByTagName('footer')[0];
const marketingSection = document.getElementById('marketingSection');

export function hideLandingpageHtml(hide = true): void {
	// TODO find a better solution as soon as the landingpage is recreated in Astro.
	if (marketingSection) {
		if (hide) {
			marketingSection.style.display = 'none';
		} else {
			marketingSection.style.display = null;
		}
	}
	if (footer) {
		if (hide) {
			footer.style.display = 'none';
		} else {
			footer.style.display = null;
		}
	}
}
