import { ChannelListRouteConfig } from '../../../../ChannelListRouteConfig';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';

export const useIsViewOnTopOfStack = (view: keyof ChannelListRouteConfig) => {
	const viewService = useService($ViewService);

	return useReactiveState(
		() =>
			viewService
				.findView(channelListViewId)
				?.visibleView?.state?.topOfStack()?.path === view,
		[viewService, view]
	);
};
