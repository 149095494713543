import { createTrackingEventType } from '@knuddels-app/analytics/eventCreators';
import { Category } from '@knuddels-app/analytics/Category';

export const sideNavMaximaizeNavigationEvent = createTrackingEventType(
	Category.SidebarNavigation,
	'SidebarNavigation_MaximizeNavigation'
);

export const sideNavProfilImageEvent = createTrackingEventType(
	Category.SidebarNavigation,
	'SidebarNavigation_ProfileImage'
);

export const sideNavMinimizeNavigationEvent = createTrackingEventType(
	Category.SidebarNavigation,
	'SidebarNavigation_MinimizeNavigation'
);

export const sideNavNicknameEvent = createTrackingEventType(
	Category.SidebarNavigation,
	'SidebarNavigation_Nickname'
);

export const bottomNavMoreViewEvent = createTrackingEventType(
	Category.BottomNavigation,
	'BottomNavigation_MoreView'
);
