import { HoverGesture as e } from "../../gestures/hover.js";
import { FocusGesture as r } from "../../gestures/focus.js";
import { PressGesture as t } from "../../gestures/press.js";
import { InViewFeature as o } from "./viewport/index.js";
const a = {
  inView: {
    Feature: o
  },
  tap: {
    Feature: t
  },
  focus: {
    Feature: r
  },
  hover: {
    Feature: e
  }
};
export {
  a as gestureAnimations
};
