import styles from './Modal_styles.module.scss';

export const ModalContentWrapperStyle = {
	className: styles.ModalContentWrapper,
};
export const ModalContentWrapperCenterStyle = {
	className: styles.ModalContentWrapperCenter,
};
export const ModalButtonStyle = { className: styles.ModalButton };
export const ShrinkStyle = { className: styles.shrink };
export const FullHeightStyle = { className: styles.fullHeight };
