import { jsx as t, jsxs as a } from "react/jsx-runtime";
import * as n from "react";
import { Flex as l } from "../Layout/Flex.js";
import { PortalHost as x } from "../Layout/Portal.js";
const o = n.createContext(!1), m = () => n.useContext(o), W = (e) => /* @__PURE__ */ t(o.Provider, { value: !0, children: /* @__PURE__ */ t(
  x,
  {
    defaultChildren: e.children,
    onPortalStateChange: e.onSplitViewStateChange,
    children: ({ children: h, portedComponent: d }) => {
      const i = !!d || !!e.placeholder;
      return /* @__PURE__ */ a(
        l,
        {
          flex: 1,
          zIndex: e.zIndex,
          shadow: e.shadow,
          minWidth: `${e.rootViewWidth + (i ? e.sideViewMinWidth : 0)}px`,
          maxWidth: i ? e.totalMaxWidth ? e.totalMaxWidth + "px" : void 0 : e.rootViewWidth + "px",
          children: [
            /* @__PURE__ */ t(
              l,
              {
                minWidth: e.rootViewWidth + "px",
                maxWidth: e.rootViewWidth + "px",
                zIndex: e.zIndex,
                shadow: e.shadow,
                children: h
              }
            ),
            i && /* @__PURE__ */ t(
              "div",
              {
                className: "Knu-Flex flex-1 overflow-hidden position-relative",
                children: d ?? e.placeholder
              }
            )
          ]
        }
      );
    }
  }
) });
export {
  W as SplitView,
  m as useSplitView
};
