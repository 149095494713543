import { createFlagComponents, FeatureFlag } from '@knuddels-app/featureFlags';

export const tanSystemEnabledFlag = FeatureFlag.create({
	id: 'tanSystemEnabled',
});

export const TanSystemEnabled = createFlagComponents(tanSystemEnabledFlag);

export const engagementSystemChatGroupFlag = FeatureFlag.create({
	id: 'engagementSystemChatGroup',
});

export const authenticityFlag = FeatureFlag.create({
	id: 'authenticityFlag',
});

export const Authenticity = createFlagComponents(authenticityFlag);

export const nickSwitchFlag = FeatureFlag.create({
	id: 'nickSwitchFlag',
});

export const NickSwitch = createFlagComponents(nickSwitchFlag);

export const smileyTradeFlag = FeatureFlag.create({
	id: 'smileyTradeFlag',
});

export const disablePeriodicChannelSelectionUpdatesFlag = FeatureFlag.create({
	id: 'disablePeriodicChannelSelectionUpdatesFlag',
});

export const newAppKnuddelTransferFlag = FeatureFlag.create({
	id: 'newAppKnuddelTransferFlag',
});
