import { observable, runInAction } from '@knuddels-app/mobx';
import { injectable, inject } from '@knuddels-app/DependencyInjection';
import { LocationInfo } from './LocationInfo';
import { Disposable } from '@knuddels/std';
import { $Environment } from '@knuddels-app/Environment';
import { History } from 'history';

@injectable()
export class LocationService {
	public readonly dispose = Disposable.fn();

	@observable private _currentLocation: LocationInfo;
	public get currentLocation(): LocationInfo {
		return this._currentLocation;
	}

	private readonly history: History;
	private readonly historyCanGoBack: () => boolean;

	constructor(@inject($Environment) environment: typeof $Environment.T) {
		if (environment.nativeInterface) {
			// This is what the old BackButton did
			// (https://github.com/ReactTraining/react-router/blob/f4c9672d7baacd004ee3206c10988f82e3541242/packages/react-router-native/BackButton.js#L17)
			this.dispose.track(
				environment.nativeInterface.addBackHandler(() => {
					return this.pop();
				})
			);
			this.historyCanGoBack = () => {
				return (
					!!environment.nativeInterface &&
					environment.nativeInterface.history.index > 0
				);
			};
		} else {
			// browser history does not have index
			this.historyCanGoBack = () => true;
		}

		this.history = environment.history;
		this.dispose.track(
			this.history.listen(e => {
				runInAction('Update current location', () => {
					this._currentLocation = LocationInfo.fromHistoryLocation(e);
				});
			})
		);
		this._currentLocation = LocationInfo.fromHistoryLocation(
			this.history.location
		);
	}

	public pushPath(path: string): void {
		const loc = this.currentLocation.withPath(path);
		this.push(loc);
	}

	public replacePath(path: string): void {
		const loc = this.currentLocation.withPath(path);
		this.replace(loc);
	}

	public push(newLocation: LocationInfo): void {
		this.history.push(newLocation.toHistoryLocation());
	}

	public replace(newLocation: LocationInfo): void {
		this.history.replace(newLocation.toHistoryLocation());
	}

	public pop(): boolean {
		if (this.historyCanGoBack()) {
			this.history.goBack();
			return true;
		} else {
			return false;
		}
	}
}
