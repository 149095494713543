import * as React from 'react';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { EventEmitter, EventSource } from '@knuddels/std';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { createErrorSnackbarWithDefaults } from './components/GenericError';

import de from './i18n/formats.de.json';
import en from './i18n/formats.en.json';

export interface SnackbarData {
	/**
	 * This type is used to prevent the same snackbar from being
	 * shown multiple times in a row.
	 */
	type: string;
	text: string | (() => string);
	subtext?: string | (() => string) | React.ReactElement;
	adornment?: string | React.ReactElement;

	button?: {
		text: string | (() => string);
		onPress?: () => void;
	};
}

@injectable()
export class SnackbarService {
	public readonly onSnackbar: EventSource<SnackbarData>;
	private readonly onSnackbarEmitter = new EventEmitter<SnackbarData>();

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		this.onSnackbar = this.onSnackbarEmitter.asEvent();

		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
	}

	/**
	 * Show the snackbar specified by type in the given context.
	 */
	public showSnackbar(data: SnackbarData): void {
		this.onSnackbarEmitter.emit(data);
	}

	public showErrorSnackbarWithDefaults(data: Partial<SnackbarData>): void {
		this.showSnackbar(createErrorSnackbarWithDefaults(data));
	}

	public showGenericError(message?: string, type = 'genericError'): void {
		this.showSnackbar(
			createErrorSnackbarWithDefaults({ subtext: message, type })
		);
	}
}
