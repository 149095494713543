import { animateMotionValue as m } from "./motion-value.js";
import { motionValue as a } from "../../value/index.js";
import { isMotionValue as r } from "../../value/utils/is-motion-value.js";
function f(o, i, n) {
  const t = r(o) ? o : a(o);
  return t.start(m("", t, i, n)), t.animation;
}
export {
  f as animateSingleValue
};
