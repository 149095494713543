import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $CommandService } from '@knuddels-app/Commands';
import { ChannelMessageFragment, Scalars } from '@generated/graphql';
import { $UserService } from '@knuddelsModules/UserData';
import { FormattedText } from '@shared/components';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat } from '@knuddels-app/i18n';

type ChannelMessageFilter = (message: ChannelMessageFragment) => boolean;

@injectable()
export class ChannelMessageFilterService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {
		this.dispose.track(
			commandService.registerCommand({
				commandName: 'io',
				invoke: this.handleIOCommand,
			})
		);

		this.dispose.track(
			['po', 'privateonly'].map(commandName =>
				commandService.registerCommand({
					commandName,
					invoke: this.handlePOCommand,
				})
			)
		);
	}

	public disableAllFilters = () => {
		this.shouldFilterChannelMessage = () => false;
	};

	private handleIOCommand = async () => {
		const result = await this.handleFilterCommand(this.ioFilter);

		if (result === 'enabled') {
			this.snackbarService.showSnackbar({
				text: declareFormat({
					id: 'channel.messageFilter.io.enabled',
					defaultFormat:
						'From now on, only important messages will be displayed.',
				}).format(this.i18n),
				type: 'ioFilterEnabled',
			});
		}
	};

	private handlePOCommand = async () => {
		const result = await this.handleFilterCommand(this.poFilter);

		if (result === 'enabled') {
			this.snackbarService.showSnackbar({
				text: declareFormat({
					id: 'channel.messageFilter.po.enabled',
					defaultFormat:
						'From now on, only private messages will be displayed.',
				}).format(this.i18n),
				type: 'poFilterEnabled',
			});
		}
	};

	private handleFilterCommand = async (filter: ChannelMessageFilter) => {
		if (this.shouldFilterChannelMessage === filter) {
			this.disableAllFilters();
			this.showFilterDisabledSnackbar();

			return 'disabled';
		} else {
			this.shouldFilterChannelMessage = filter;

			return 'enabled';
		}
	};

	private showFilterDisabledSnackbar = () => {
		this.snackbarService.showSnackbar({
			text: declareFormat({
				id: 'channel.messageFilter.disabled',
				defaultFormat: 'All messages are displayed again.',
			}).format(this.i18n),
			type: 'filterDisabled',
		});
	};

	private ioFilter: ChannelMessageFilter = message => {
		const currentUser = this.userService.currentUser;
		if (!currentUser || message.sender.id === currentUser.id) {
			return false;
		}

		const filterNicks = [currentUser.nick, ...currentUser.aliasNicks];
		switch (message.__typename) {
			case 'ChannelMsgPublic':
			case 'ChannelMsgAction':
				return !this.doesFormattedTextContainTerms(
					message.formattedText,
					filterNicks
				);
			case 'ChannelMsgPrivateGroup':
			case 'ChannelMsgSystem':
			default:
				return false;
		}
	};

	private poFilter: ChannelMessageFilter = message => {
		const currentUser = this.userService.currentUser;
		if (!currentUser || message.sender.id === currentUser.id) {
			return false;
		}

		switch (message.__typename) {
			case 'ChannelMsgPublic':
			case 'ChannelMsgAction':
				return true;
			case 'ChannelMsgSystem':
			case 'ChannelMsgPrivateGroup':
			default:
				return false;
		}
	};

	public shouldFilterChannelMessage: ChannelMessageFilter = () => {
		return false;
	};

	private doesFormattedTextContainTerms(
		formattedText: Scalars['FormattedText'],
		terms: string[]
	): boolean {
		const text = FormattedText.asText(
			FormattedText.fromJsonString(formattedText)
		).toLowerCase();
		return terms.some(term => text.includes(term.toLowerCase()));
	}
}
