import {
	activateProfileVisitorsFeature,
	profileVisitEvents,
	ProfileVisitorsUserFragment,
	profileVisits,
} from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';

export type ProfileVisitor = {
	isUnlocked: boolean;
} & ProfileVisitorsUserFragment;

@injectable()
export class ProfileVisitorsService {
	public readonly dispose = Disposable.fn();

	public readonly onVisitorAdded: EventSource<ProfileVisitor>;

	private readonly visitorAddedEmitter = new EventEmitter<ProfileVisitor>();

	@observable
	private _visitors: ProfileVisitor[] = [];

	@observable
	private _visitorsEnabled = false;

	public get visitors(): ProfileVisitor[] {
		return this._visitors;
	}

	public get visitorsEnabled(): boolean {
		return this._visitorsEnabled;
	}

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T
	) {
		this.onVisitorAdded = this.visitorAddedEmitter.asEvent();

		this.initSubscription();

		this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(profileVisits, {
				pixelDensity: getPixelRatio(),
			})
			.onOk(result => {
				runInAction('Init profile visitors data', () => {
					[...result.profileVisits].reverse().forEach(it =>
						this.addVisitor({
							...it.visitor,
							isUnlocked: it.isUnlocked,
						})
					);
					this._visitorsEnabled = result.profileVisitorsEnabled;
				});
			});
	}

	private initSubscription(): void {
		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				profileVisitEvents,
				{ pixelDensity: getPixelRatio() },
				{
					next: (data): void => {
						switch (data.__typename) {
							case 'ProfileVisitorSystemEnabledChanged':
								runInAction(
									'Subscription: ProfileVisitorSystemEnabledChanged',
									() => {
										this._visitorsEnabled = data.enabled;
									}
								);
								break;
							case 'ProfileVisitChangedEvent':
								runInAction(
									'Subscription: ProfileVisitChangedEvent',
									() => {
										this.updateVisitor({
											...data.profileVisit.visitor,
											isUnlocked:
												data.profileVisit.isUnlocked,
										});
									}
								);

								break;
							case 'NewProfileVisitEvent':
								runInAction(
									'Subscription: NewProfileVisitEvent',
									() => {
										const visitor = this.createVisitor(
											data.profileVisit.visitor,
											data.profileVisit.isUnlocked
										);

										this.addVisitor(visitor);
										this.visitorAddedEmitter.emit(visitor);
									}
								);
								break;
							default:
						}
					},
				}
			)
		);
	}

	@action.bound
	private addVisitor(visitor: ProfileVisitor): void {
		const index = this._visitors.findIndex(it => it.id === visitor.id);
		if (index > -1) {
			this._visitors.splice(index, 1);
		}

		this._visitors.unshift(visitor);
	}

	@action.bound
	private updateVisitor(visitor: ProfileVisitor): void {
		const index = this._visitors.findIndex(it => it.id === visitor.id);
		if (index > -1) {
			this._visitors = [
				...this._visitors.slice(0, index),
				visitor,
				...this._visitors.slice(index + 1),
			];
		}
	}

	private createVisitor(
		visitor: ProfileVisitorsUserFragment,
		isUnlocked: boolean
	): ProfileVisitor {
		return { ...visitor, isUnlocked };
	}

	public activateProfileVisitorsFeature = (): void => {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(activateProfileVisitorsFeature, {})
			.onErr(() => {
				this.snackbarService.showGenericError();
			});
	};
}
