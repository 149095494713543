import { frame as s, cancelFrame as a } from "../../../frameloop/frame.js";
function u(o, c) {
  let r;
  const n = () => {
    const { currentTime: t } = c, e = (t === null ? 0 : t.value) / 100;
    r !== e && o(e), r = e;
  };
  return s.update(n, !0), () => a(n);
}
export {
  u as observeTimeline
};
