import { jsx as m } from "react/jsx-runtime";
import { resolveIsDarkColor as c } from "../Layout/isDarkColor.js";
import { useTheme as a } from "../../themes/useTheme.js";
import { Flex as l } from "../Layout/Flex.js";
const x = 20, N = 8, O = ({ children: e, onPress: t, onHover: n, selected: o, highlighted: i }) => {
  const r = o || i ? "listActiveAtom" : void 0, s = a();
  return /* @__PURE__ */ m(
    l,
    {
      className: r ? c(r, s) ? "content-is-dark" : "content-is-light" : void 0,
      px: "minor",
      py: "small",
      minHeight: x + "px",
      onHover: n,
      onPress: (p) => {
        p.stopPropagation(), t == null || t();
      },
      alignItems: "center",
      cursor: "pointer",
      bg: o || i ? "listActiveAtom" : void 0,
      children: /* @__PURE__ */ m("span", { className: "Knu-Text text-body1 text-primary", children: e })
    }
  );
};
export {
  x as OPTION_HEIGHT,
  N as OPTION_VERTICAL_PADDING,
  O as Option
};
