import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $UserService } from '@knuddelsModules/UserData';
import { isUsingTouch } from '@shared/components';
import { $OverlayService } from '@knuddels-app/overlays';
import { MacroBox } from '@knuddelsModules/Channel/bundle/components/Macrobox/MacroBox';
import * as React from 'react';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $ScreenService } from '@knuddels-app/Screen';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { OS, os } from '@shared/components/tools/os';
@injectable()
export class MacroBoxService {
  public readonly dispose = Disposable.fn();
  constructor(@inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T) {}
  mayShowMacroBox = (userId: string) => {
    return this.isAvailableOnDevice() && !this.userService.isCurrentUser(userId);
  };
  mayShowMacroBoxOfNick = (nick: string) => {
    return this.isAvailableOnDevice() && this.userService.currentUser?.nick !== nick;
  };
  private isAvailableOnDevice = () => {
    if (!this.clientSettingsService.macroBoxEnabled) {
      return false;
    }
    return this.screenService.isStackedLayout || isUsingTouch();
  };
  showMacroBox = (userId: string) => {
    dismissKeyboard();
    return this.overlayService.showOverlay({
      view: <MacroBox user={{
        id: userId
      }} />,
      backgroundType: os === OS.ios ? 'opaque' : undefined
    });
  };
  showMacroBoxOfNick = async (nick: string) => {
    const userId = await this.userService.getUserIdFromNick(nick);
    if (!userId) {
      this.snackbarService.showGenericError();
      return;
    }
    this.showMacroBox(userId);
  };
}