import o from "react";
import { ThemeContext as t } from "./ThemeProvider.js";
const m = () => {
  const e = o.use(t);
  if (!e)
    throw new Error(
      "Could not find a theme. Did you include a <ThemeProvider> at the root of your application?"
    );
  return e;
};
export {
  m as useTheme
};
