import * as React from 'react';
import { LayoutInfo } from './LayoutInfo';
import { Deferred } from '@knuddels/std';
import * as ReactDOM from 'react-dom';

export function measureLayoutRelativeToWindow(
	component: React.Component<any>
): Promise<LayoutInfo> {
	const deferred = new Deferred<LayoutInfo>();
	let componentDomNode: HTMLElement | null = null;

	try {
		// eslint-disable-next-line react/no-find-dom-node
		componentDomNode = ReactDOM.findDOMNode(
			component
		) as HTMLElement | null;
	} catch {
		// Component is no longer mounted.
	}

	if (!componentDomNode) {
		deferred.reject('measureLayoutRelativeToWindow failed');
	} else {
		const componentBoundingRect = componentDomNode.getBoundingClientRect();

		deferred.resolve({
			x: componentBoundingRect.left,
			y: componentBoundingRect.top,
			width: componentBoundingRect.width,
			height: componentBoundingRect.height,
		});
	}

	return deferred.promise;
}
