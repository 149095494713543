import * as React from 'react';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { User } from '@generated/graphql';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { FlexCol, TextProps, toPointerHandler } from '@knuddels/component-library';
import { HighlightTextLink } from '@shared/components/atoms/Texts/HighlightTextLink';
import { $MacroBoxService } from '@knuddelsModules/Channel';
type ClickableUserProps = {
  overrideStyles?: {
    color?: TextProps['color'];
    state?: TextProps['state'];
  };
  uid: User['id'];
  disableMacroBox?: boolean;
  onClick?(): void;
};
type AllProps = ClickableUserProps & {
  kind: 'text' | 'view';
};
@injectable()
class ClickableUserModel {
  constructor(@injectProps()
  private readonly props: AllProps, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($MacroBoxService)
  private readonly macroBoxService: typeof $MacroBoxService.T) {}
  public readonly handlePressed = (): void => {
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (!this.props.disableMacroBox && this.macroBoxService.mayShowMacroBox(this.props.uid)) {
      this.macroBoxService.showMacroBox(this.props.uid);
    } else {
      this.getProfileNavigationService().then(service => service.showProfile(this.props.uid, 'Channel'));
    }
  };
}
function createClickableUserComponent(type: 'text' | 'view'): React.FunctionComponent<ClickableUserProps> {
  return injectedComponent({
    name: `ClickableUser-${type}`,
    model: ClickableUserModel,
    props: declareProps<React.PropsWithChildren<AllProps>>()
  }, ({
    model,
    overrideStyles,
    children
  }) => {
    if (type === 'text') {
      return <HighlightTextLink state={overrideStyles?.state ?? 'secondary'} hoverState={'primary'} color={overrideStyles?.color} bold onPress={model.handlePressed}>
						{children}
					</HighlightTextLink>;
    } else if (type === 'view') {
      return <div onClick={toPointerHandler(model.handlePressed)} className={_c0}>
						{children}
					</div>;
    }
  });
}
export const ClickableUserText = createClickableUserComponent('text');
export const ClickableUser = createClickableUserComponent('view');
const _c0 = " Knu-FlexCol cursor-pointer overflow-visible position-relative ";