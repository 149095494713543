import {
	K3ServiceBinder,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { ScreenService } from '@knuddels-app/Screen/ScreenService';

export const $ScreenService = newServiceId<ScreenService>('$ScreenService');

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope.bind($ScreenService).toSingletonAutostart(ScreenService);
}
