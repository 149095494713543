import { MessengerOverviewItem } from '@knuddelsModules/Messenger/bundle/services/messengerOverviewHelper';
import * as React from 'react';
export const useUnreadMessagesStates = ({
  conversations,
  scrollRef
}: {
  conversations: MessengerOverviewItem[];
  scrollRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const chatHeadsRef = React.useRef<HTMLDivElement[]>([]);
  const [hasUnreadMessagesLeft, setHasUnreadMessagesLeft] = React.useState(false);
  const [hasUnreadMessagesRight, setHasUnreadMessagesRight] = React.useState(false);
  React.useEffect(() => {
    chatHeadsRef.current = chatHeadsRef.current.slice(0, conversations.length);
  }, [conversations]);
  const checkForUnreadMessages = React.useCallback(() => {
    let areUnreadMessagesLeft = false;
    let areUnreadMessagesRight = false;
    chatHeadsRef.current.forEach((ref, index) => {
      const currentConversation = conversations[index];
      const isUnreadConversation = currentConversation.type === 'conversation' && currentConversation.conversation.readState.unreadMessageCount > 0;
      if (!ref || !scrollRef.current || !isUnreadConversation) {
        return;
      }
      const rect = ref.getBoundingClientRect();
      const isItemInView = rect.left >= 0 && rect.right <= scrollRef.current.clientWidth;
      if (!isItemInView && isUnreadConversation) {
        areUnreadMessagesLeft = rect.left < 0;
        areUnreadMessagesRight = rect.left >= 0;
      }
    });
    setHasUnreadMessagesLeft(areUnreadMessagesLeft);
    setHasUnreadMessagesRight(areUnreadMessagesRight);
  }, [conversations]);
  React.useEffect(() => {
    const div = scrollRef.current;
    if (!div) {
      return;
    }
    checkForUnreadMessages();
    div.addEventListener('scroll', checkForUnreadMessages);
    window.addEventListener('resize', checkForUnreadMessages);
    return () => {
      div.removeEventListener('scroll', checkForUnreadMessages);
      window.removeEventListener('resize', checkForUnreadMessages);
    };
  }, [conversations]);
  return {
    chatHeadRefs: chatHeadsRef,
    hasUnreadMessagesLeft,
    hasUnreadMessagesRight
  };
};