import * as mobxReact from 'mobx-react';

/**
 * This observer function forces the programmer to set
 * a name that matches the constant it is assigned to.
 */
export function observer<TProps>(
	/* matchesAssignmentTarget:asIs */ name: string,
	fn: (props: TProps) => React.ReactElement<TProps>
): React.FunctionComponent<TProps> {
	(fn as any).displayName = name;
	const c = mobxReact.observer(fn) as any;
	return c;
}
