import { getNativeFilePicker } from '@knuddels-app/tools/filePicking/getNativeFilePicker';
import { NativeFilePickerTrigger } from '@knuddels-app/tools/filePicking/filePickerInterfaces';

export class NativeFilePicker {
	private fileSelector: ReturnType<typeof getNativeFilePicker> | undefined;

	constructor(private readonly multiselect = false) {}

	select: NativeFilePickerTrigger = callback => {
		// We need to store the selector in the class because otherwise
		// the callback isn't being called in some browsers
		this.fileSelector = getNativeFilePicker(this.multiselect);
		this.fileSelector(callback);
	};
}
