import { APP_DRAWER_BAR_HEIGHT } from '@shared/constants';
import { ViewerSharedValues } from './ViewerSharedValues';

export function calculateAppScale(
	{ heightPercentage, maxWidth, maxHeight }: ViewerSharedValues,
	contentWidth: number,
	contentHeight: number
): number {
	const percentageHeight = heightPercentage.get();
	const actualHeight = Math.max(
		maxHeight.get() * percentageHeight - APP_DRAWER_BAR_HEIGHT,
		0
	);

	const scaleX =
		maxWidth.get() < contentWidth ? maxWidth.get() / contentWidth : 1;
	const scaleY =
		actualHeight < contentHeight ? actualHeight / contentHeight : 1;

	return Math.min(scaleX, scaleY);
}
