import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { requestPicturePermissions, SelectFileResult, useNativeFilePicker } from '@knuddels-app/tools/filePicking';
import { CreateAlbumPhotoUploadUrl } from '@generated/graphql';
import { AddAlbumPhotoButton } from '../AddAlbumPhotoButton';
import { Box, IconCloseCircular, Link } from '@knuddels/component-library';
import { useMutation } from '@knuddels-app/Connection';
import { useProgressHandler } from './useProgressHandler';
import { $AlbumPhotoUploadService } from '@knuddelsModules/Profile/providedServices';
import { useTrackOwnProfileView } from '../../shared/useTrackOwnProfileView';
import { declareFormat } from '@knuddels/i18n';
import { $I18n, FormattedMessage } from '@knuddels-app/i18n';
import { useCustomSnackbar } from '@shared/components';
import { useRefetchProfile } from '@knuddelsModules/Profile/bundle/components/profileQueryHelper';
import { IconGreenCheckMark } from '@knuddels-app/shared-components/IconGreenCheckMark';
import { UPLOAD_IMAGE_ERROR_TEXT } from '@knuddelsModules/Profile/bundle/i18n/shared-formats';
export const UploadAlbumPhotoButton: React.FC<{
  size: number;
  albumId: string;
} & React.ComponentProps<typeof Box>> = ({
  size,
  albumId,
  ...boxProps
}) => {
  const addAlbumPhoto = useAddAlbumPhoto();
  return <AddAlbumPhotoButton {...boxProps} size={size} onPress={() => addAlbumPhoto(albumId)} />;
};
const useAddAlbumPhoto = () => {
  const track = useTrackOwnProfileView();
  const createUploadUrl = useCreatePhotoUploadUrl();
  const addUploadingPhotos = useService($AlbumPhotoUploadService).addPhotos;
  const startProgressHandler = useProgressHandler();
  const filePicker = useNativeFilePicker(true);
  const refetchProfile = useRefetchProfile();
  const {
    showSuccessSnackbar,
    showInvalidFormatSnackbar,
    showFileTooLargeSnackbar,
    showPermissionDeniedSnackbar,
    showFailureWithRetrySnackbar,
    showPhotoUploadBlockedSnackbar
  } = useSnackbars();
  return (albumId: string) => {
    track('AlbumEdit_AddAlbumImage');
    const doUploadFiles = async (files: SelectFileResult[]) => {
      let cancelled = false;
      const {
        closeSnackbar,
        uploadCompleted
      } = startProgressHandler(files.length, () => {
        cancelled = true;
      });
      const clearUploadingPhotos = addUploadingPhotos(files.map(file => ({
        albumId,
        base64Image: file.image
      })));
      const tasks = files.map(file => async () => {
        if (!file.uploadObject.type.includes('image/jpeg') && !file.uploadObject.type.includes('image/jpg') && !file.uploadObject.type.includes('image/png')) {
          showInvalidFormatSnackbar(file.uploadObject.name);
          return {
            file,
            result: 'invalid-format'
          };
        }

        // Max Photo Size: 10 MB
        if (file.uploadObject.size > 10 * 1024 * 1024) {
          showFileTooLargeSnackbar(file.uploadObject.name);
          return {
            file,
            result: 'too-large'
          };
        }
        if (cancelled) {
          return {
            file,
            result: 'cancelled'
          };
        }
        const uploadUrl = await createUploadUrl(albumId);
        if ('result' in uploadUrl) {
          uploadCompleted();
          return {
            file,
            ...uploadUrl
          };
        }
        const success = await uploadPhoto(file, uploadUrl.url);
        uploadCompleted();
        return {
          file,
          result: success ? 'success' : 'generic-error'
        };
      });
      const results = await executeTasksInSequence(tasks);
      const failedFiles = results.filter(it => it.result !== 'success').map(it => it.file);
      closeSnackbar();
      clearUploadingPhotos();
      if (cancelled) {
        return;
      }
      const uploadBlocked = results.find(it => it.result === 'upload-blocked');
      if (uploadBlocked && 'blockedUntil' in uploadBlocked) {
        showPhotoUploadBlockedSnackbar(uploadBlocked.blockedUntil);
        return;
      }
      if (failedFiles.length > 0) {
        const retry = () => {
          if (failedFiles.length > 0) {
            doUploadFiles(failedFiles);
          }
        };
        showFailureWithRetrySnackbar(failedFiles.length, files.length, retry);
        if (failedFiles.length < files.length) {
          await refetchProfile();
        }
      } else {
        showSuccessSnackbar();
        await refetchProfile();
      }
    };
    requestPicturePermissions().then(result => {
      if (result === 'yes') {
        filePicker(files => doUploadFiles(files));
      } else {
        showPermissionDeniedSnackbar();
      }
    });
  };
};
async function uploadPhoto(result: SelectFileResult, uploadUrl: string): Promise<boolean> {
  try {
    const formData = new FormData();
    formData.append('submitElement', 'nojava');
    if ('blob' in result.uploadObject) {
      formData.append('uploadFile', (result.uploadObject as any).blob, (result.uploadObject as any).name);
    } else {
      formData.append('uploadFile', (result.uploadObject as any));
    }
    const encodedUri = encodeURI(uploadUrl);
    const fetchResult: Response = await fetch(encodedUri, {
      method: 'POST',
      body: formData
    });
    if (!fetchResult.ok) {
      return false;
    }
    const text = await fetchResult.text();
    return text === 'OK';
  } catch (e) {
    return false;
  }
}
const useCreatePhotoUploadUrl = () => {
  const [createUrl] = useMutation(CreateAlbumPhotoUploadUrl);
  return async (albumId: string): Promise<{
    url: string;
  } | {
    result: 'fake-suspect';
  } | {
    result: 'upload-blocked';
    blockedUntil?: Date;
  } | {
    result: 'generic-error';
  }> => {
    const result = await createUrl({
      albumId
    });
    switch (result.data?.__typename) {
      case 'CreateAlbumPhotoUploadUrlSuccess':
        return {
          url: result.data.albumPhotoUploadUrl
        };
      case 'FakeSuspectError':
        return {
          result: 'fake-suspect'
        };
      case 'PhotoUploadBlockedError':
        return {
          result: 'upload-blocked',
          blockedUntil: result.data.blockedUntil ? new Date(+result.data.blockedUntil) : null
        };
      default:
        return {
          result: 'generic-error'
        };
    }
  };
};
async function executeTasksInSequence<T>(tasks: (() => Promise<T>)[]): Promise<T[]> {
  const results: T[] = [];
  for (const task of tasks) {
    results.push(await task());
  }
  return results;
}
const useSnackbars = () => {
  const snackbarService = useService($SnackbarService);
  const i18n = useService($I18n);
  const showCustomSnackbar = useCustomSnackbar();
  return {
    showSuccessSnackbar: () => snackbarService.showSnackbar({
      type: 'albumPhotoUploadSuccess',
      text: declareFormat({
        id: 'profile.edit.upload-album-photo.success.text',
        defaultFormat: 'Photo upload successful!'
      }).format(i18n),
      subtext: declareFormat({
        id: 'profile.edit.upload-album-photo.success.subtext',
        defaultFormat: 'Take a look in your album now'
      }).format(i18n),
      adornment: <div className={_c0}>
						<IconGreenCheckMark size={'large'} />
					</div>
    }),
    showInvalidFormatSnackbar: (fileName: string) => snackbarService.showErrorSnackbarWithDefaults({
      text: declareFormat({
        id: 'album.details.uploadPhotos.incompatible-file-format',
        defaultFormat: 'The photo {fileName} has an incompatible file format. Please select a photo with the file format JPG or PNG.'
      }).format(i18n, {
        fileName
      }),
      subtext: undefined
    }),
    showFileTooLargeSnackbar: (fileName: string) => snackbarService.showErrorSnackbarWithDefaults({
      text: declareFormat({
        id: 'album.details.uploadPhotos.file-too-large',
        defaultFormat: 'The photo {fileName} is too large. Please select a photo that is smaller than 10 MB.'
      }).format(i18n, {
        fileName
      }),
      subtext: undefined
    }),
    showPermissionDeniedSnackbar: () => snackbarService.showErrorSnackbarWithDefaults({
      text: declareFormat({
        id: 'album.details.uploadPhotos.no-permission',
        defaultFormat: 'You have to grant the permission for your camera to upload photos.'
      }).format(i18n),
      subtext: undefined
    }),
    showPhotoUploadBlockedSnackbar: (blockedUntil?: Date) => {
      const subtext = blockedUntil ? declareFormat({
        id: 'album.details.uploadPhotos.blocked.text',
        defaultFormat: 'You have been blocked from uploading photos until {blockedUntil}'
      }).format(i18n, {
        blockedUntil: i18n.formatDateTime(blockedUntil)
      }) : declareFormat({
        id: 'album.details.uploadPhotos.blocked.text-indefinite',
        defaultFormat: 'You have been permanently blocked from uploading photos.'
      }).format(i18n);
      snackbarService.showErrorSnackbarWithDefaults({
        text: UPLOAD_IMAGE_ERROR_TEXT.format(i18n),
        subtext
      });
    },
    showFailureWithRetrySnackbar: (failCount: number, totalPhotos: number, onRetry: () => void) => {
      const failSnackbar = showCustomSnackbar({
        type: 'albumPhotoUploadFail',
        text: declareFormat({
          id: 'profile.edit.upload-album-photo.fail.text',
          defaultFormat: '{failCount} / {totalPhotos} photos failed'
        }).format(i18n, {
          failCount,
          totalPhotos
        }),
        subtext: <Link type={'body1'} state={'secondary'} onPress={() => {
          onRetry();
          failSnackbar.close();
        }}>
						<FormattedMessage id={declareFormat({
            id: 'profile.edit.upload-album-photo.fail.subtext',
            defaultFormat: 'Try again'
          })} />
					</Link>,
        adornment: require('@shared/images/sorry.gif'),
        button: {
          text: <IconCloseCircular />,
          onPress: () => {}
        }
      });
    }
  };
};
const _c0 = " Knu-Box mr-minor ";