import { useMemo as m } from "react";
import { copyRawValuesOnly as i } from "../html/use-props.js";
import { buildSVGAttrs as l } from "./utils/build-attrs.js";
import { createSvgRenderState as a } from "./utils/create-render-state.js";
import { isSVGTag as n } from "./utils/is-svg-tag.js";
function V(e, s, y, o) {
  const r = m(() => {
    const t = a();
    return l(t, s, n(o), e.transformTemplate), {
      ...t.attrs,
      style: { ...t.style }
    };
  }, [s]);
  if (e.style) {
    const t = {};
    i(t, e.style, e), r.style = { ...t, ...r.style };
  }
  return r;
}
export {
  V as useSVGProps
};
