import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels-app/i18n';

import bigHello from '@shared/icons/icon-bighello.gif';

export const SnackbarDefinitionRestoreConversation: SnackbarData = {
	type: 'restoreConversation',
	text: (): string =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_RESTORE_CONVERSATION_TEXT',
				defaultFormat: 'The conversation has been restored.',
			})
		),
	adornment: bigHello,
};
