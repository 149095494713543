type UserAgentData = {
	platform: string;
	getHighEntropyValues: (identifiers: string[]) => Promise<UserAgentData>;
	platformVersion: string;
};

let isWindows10Cache: boolean | undefined;
const isWindows10 = async () => {
	if (isWindows10Cache !== undefined) {
		return isWindows10Cache;
	}

	if (
		'userAgentData' in navigator &&
		'getHighEntropyValues' in (navigator.userAgentData as UserAgentData)
	) {
		const data = await (
			navigator.userAgentData as UserAgentData
		).getHighEntropyValues(['platformVersion']);
		const isWindows10 =
			data.platform === 'Windows' &&
			data.platformVersion.startsWith('10');
		isWindows10Cache = isWindows10;
		return isWindows10;
	}

	const userAgent = navigator.userAgent;
	const isWindows10 = /Windows NT 10/.test(userAgent);
	isWindows10Cache = isWindows10;
	return isWindows10;
};

const MAX_EMOJI_VERSION_WINDOWS = 12.99;

export const supportsFlagEmoji = async () => {
	if (await isWindows10()) {
		return false;
	}
	return true;
};

export const getMaxEmojiVersion = async () => {
	if (await isWindows10()) {
		return MAX_EMOJI_VERSION_WINDOWS;
	}
	return undefined;
};
