import { CommandDefinition } from '@knuddels-app/Commands';
import { Result } from '@knuddels/std';
export class DoubleActionCommand implements CommandDefinition {
	commandName = 'doubleaction';

	constructor(
		private invokeCommand: (
			command: string
		) => Promise<
			Result<
				void,
				| 'NoCommandDefinitionFound'
				| 'CommandNotSuccessful'
				| 'CommandNotParsable'
			>
		>
	) {}

	async invoke(command: string): Promise<void> {
		// e.g. /doubleaction /playsound 3100213145984820024:pics/loginknuddelscent/randomgame-normal_001b.mp|/challenge 6641877993205387291 DailyLogin
		// Contrary to what the name suggests, 'doubleaction' can actually execute an arbitrary number of commands separated by | characters. Below parser also allows to escape | using the escape character \.
		// Reerence implementation in base.GroupChat#visitMe

		let commandStart = 0;
		for (let i = 0; i < command.length; i++) {
			if (command.charAt(i) === '|') {
				if (i === 0 || command.charAt(i - 1) !== '\\') {
					await this.invokeCommand(
						command.substring(commandStart, i)
					);
					commandStart = i + 1;
				}
			}
		}

		await this.invokeCommand(
			command.substring(commandStart, command.length)
		);
	}
}
