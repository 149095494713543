import {
	ChannelListItem,
	ChannelListSmallVirtualItems,
	ChannelListVirtualItems,
	createChannelGroupVirtualListItem,
	createSmallChannelGroupVirtualListItem,
} from './ChannelListVirtualItems';
import { MIN_CHANNELS_FOR_TOP_CATEGORY } from '../constants';
import { ChannelCategoryFragment } from '@generated/graphql';

export const sortAllChannels = (
	channels: ChannelCategoryFragment,
	topChannels: ChannelListItem[],
	moreChannels: ChannelListItem[],
	isExpanded: (key: string) => boolean,
	isSmallGroupExpanded: boolean,
	size: 'condensed' | 'default',
	isGrouped?: boolean
): void => {
	let lastPopularChannelItem: ChannelListVirtualItems | undefined;
	let firstMoreChannelItem: ChannelListSmallVirtualItems | undefined;
	let lastMoreChannelItem: ChannelListSmallVirtualItems | undefined;

	(channels?.channelGroups || []).forEach(group => {
		const userCount = group.channels.reduce(
			(previousValue, currentValue) =>
				previousValue + currentValue.onlineUserCount,
			0
		);

		if (userCount >= MIN_CHANNELS_FOR_TOP_CATEGORY || !isGrouped) {
			lastPopularChannelItem = createChannelGroupVirtualListItem(
				group.id,
				group,
				userCount,
				isExpanded(group.id),
				false,
				size
			);

			topChannels.push(lastPopularChannelItem);
		} else if (isSmallGroupExpanded || userCount > 1) {
			const channel = createSmallChannelGroupVirtualListItem(
				group.id,
				group,
				userCount,
				false,
				false
			);

			moreChannels.push(channel);

			if (!firstMoreChannelItem) {
				firstMoreChannelItem = channel;
			}

			lastMoreChannelItem = channel;
		}
	});

	// set first and last items
	if (lastPopularChannelItem) {
		lastPopularChannelItem.isLast = true;
	}
	if (firstMoreChannelItem) {
		firstMoreChannelItem.isFirst = true;
	}
	if (lastMoreChannelItem) {
		lastMoreChannelItem.isLast = true;
	}
};
