import * as React from 'react';
import { ViewConfig } from '@knuddels-app/layout';
import styles from './MountingAnimation.module.scss';
export const MountingAnimation: React.FC<{
  config: ViewConfig<any>;
  ref: any;
}> = React.forwardRef(({
  children
}, ref) => {
  const domRef = React.useRef<React.ElementRef<'div'> | null>(null);

  // Temporarily disable mounting animation as it's causing the "white screen" issue
  // with at least the "MacroBox" component
  const useMountingAnimation = false;
  React.useImperativeHandle(ref, () => ({
    animateOut: () => {
      if (!useMountingAnimation) {
        return Promise.resolve();
      }
      return new Promise<void>(resolve => {
        domRef.current?.classList.add(styles.unmounting);
        setTimeout(() => {
          resolve();
          setTimeout(() => {
            domRef.current?.classList.remove(styles.unmounting);
            domRef.current?.classList.remove(styles.mounting);
          }, 500);
        }, 75);
      });
    },
    animateIn: () => {
      if (!useMountingAnimation) {
        return Promise.resolve();
      }
      return new Promise<void>(resolve => {
        domRef.current?.classList.add(styles.mounting);
        setTimeout(() => {
          resolve();
        }, 150);
      });
    }
  }));
  if (!useMountingAnimation) {
    return <>{children}</>;
  }
  return <div className={styles.mountingView + ' ' + styles.mounting} ref={domRef}>
			{children}
		</div>;
});
MountingAnimation.displayName = 'MountingAnimation';