import { observeTimeline as l } from "../render/dom/scroll/observe.js";
import { supportsScrollTimeline as n } from "../render/dom/scroll/supports.js";
class h {
  constructor(t) {
    this.stop = () => this.runAll("stop"), this.animations = t.filter(Boolean);
  }
  then(t, s) {
    return Promise.all(this.animations).then(t).catch(s);
  }
  /**
   * TODO: Filter out cancelled or stopped animations before returning
   */
  getAll(t) {
    return this.animations[0][t];
  }
  setAll(t, s) {
    for (let e = 0; e < this.animations.length; e++)
      this.animations[e][t] = s;
  }
  attachTimeline(t) {
    const s = this.animations.map((e) => {
      if (n() && e.attachTimeline)
        e.attachTimeline(t);
      else
        return e.pause(), l((i) => {
          e.time = e.duration * i;
        }, t);
    });
    return () => {
      s.forEach((e, i) => {
        e && e(), this.animations[i].stop();
      });
    };
  }
  get time() {
    return this.getAll("time");
  }
  set time(t) {
    this.setAll("time", t);
  }
  get speed() {
    return this.getAll("speed");
  }
  set speed(t) {
    this.setAll("speed", t);
  }
  get duration() {
    let t = 0;
    for (let s = 0; s < this.animations.length; s++)
      t = Math.max(t, this.animations[s].duration);
    return t;
  }
  runAll(t) {
    this.animations.forEach((s) => s[t]());
  }
  play() {
    this.runAll("play");
  }
  pause() {
    this.runAll("pause");
  }
  cancel() {
    this.runAll("cancel");
  }
  complete() {
    this.runAll("complete");
  }
}
export {
  h as GroupPlaybackControls
};
