import * as React from 'react';
import { createAccessiblePointerEventHandler, createSvgIcon, Flex, generateId, IconClose, IconSearch, isTouchDevice, PointerEvent, SearchInput, Text, useTheme, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import { AnimatePresence, motion } from 'framer-motion';
import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { runInAction } from '@knuddels-app/mobx';
import { OS, os } from '@shared/components/tools/os';
import { isSafariMobile } from '@knuddels-app/tools/isSafari';
import { getCommand } from '@shared/components/RichInput';
import { VirtuosoHandle } from 'react-virtuoso';
import { useSmileyBoxTrackEvent } from './SmileyboxAnalyticsContext';
const Pill: React.FC<{
  text: string;
  layoutId: string;
  active: boolean;
  onPress(): void;
}> = ({
  text,
  active,
  layoutId,
  onPress
}) => {
  const theme = useTheme();
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} className={_c0}>
			{active && <motion.div layout={'position'} layoutId={layoutId} style={{
      background: theme.id === 'dark' ? 'rgb(80, 80, 80)' : 'rgb(237, 237, 237)',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }} />}

			<div style={{
      position: 'relative'
    }}>
				<Text state={active ? ('primary' as const) : ('tertiary' as const)} type={'body2'} bold={active} className={_c1}>
					{text}
				</Text>
			</div>
		</div>;
};
const SetScrollStateContext = React.createContext<(state: 'scrolled' | 'notScrolled') => void>(null);
const ScrollStateContext = React.createContext<'scrolled' | 'notScrolled'>('notScrolled');
const GridRefContext = React.createContext<VirtuosoHandle>(null);
const SetGridRefContext = React.createContext<(ref: VirtuosoHandle) => void>(null);
const useScrollState = () => {
  return React.useContext(ScrollStateContext);
};
export const useSetScrollState = () => {
  return React.useContext(SetScrollStateContext) ?? (() => {});
};
const useGridRef = () => {
  return React.useContext(GridRefContext);
};
export const useSetGridRef = () => {
  return React.useContext(SetGridRefContext) ?? (() => {});
};
export const ScrollIndicatorProvider: React.FC = ({
  children
}) => {
  const [scrollTop, setScrollTop] = React.useState<'scrolled' | 'notScrolled'>('notScrolled');
  const gridRef = React.useRef<VirtuosoHandle>(null);
  const setGridRef = React.useCallback((ref: VirtuosoHandle) => {
    gridRef.current = ref;
  }, []);
  return <SetGridRefContext.Provider value={setGridRef}>
			<GridRefContext.Provider value={gridRef.current}>
				<SetScrollStateContext.Provider value={setScrollTop}>
					<ScrollStateContext.Provider value={scrollTop}>
						{children}
					</ScrollStateContext.Provider>
				</SetScrollStateContext.Provider>
			</GridRefContext.Provider>
		</SetGridRefContext.Provider>;
};
export const BoxHeader: React.FC<{
  tabs: string[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  editorRef: React.RefObject<RichTextEditorRef>;
}> = ({
  activeIndex,
  setActiveIndex,
  searchValue,
  tabs,
  setSearchValue,
  editorRef
}) => {
  const [search, setSearch] = React.useState(false);
  const id = React.useState(() => generateId('header'))[0];
  const keyboardService = useService($KeyboardService);
  const track = useSmileyBoxTrackEvent();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const scrollState = useScrollState();
  const setScrollState = useSetScrollState();
  const gridRef = useGridRef();
  return <div className={_c2 + (scrollState === 'scrolled' ? _c3 : _c4)}>
			<motion.div style={{
      display: 'flex',
      position: 'absolute',
      zIndex: 1,
      left: 0,
      height: '48px',
      width: '48px'
    }} animate={{
      opacity: !search ? 1 : 0
    }}>
				<IconSearch size={'large'} onPress={() => {
        track('Opened_');
        setSearch(true);
      }} />
			</motion.div>
			<div className={_c5}>
				<AnimatePresence initial={false}>
					{!search ? <>
							<motion.div key={'tabs'} style={{
            display: 'flex',
            flex: 1,
            marginLeft: 48,
            height: '100%',
            width: '100%',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center'
          }} initial={{
            opacity: 0
          }} animate={{
            opacity: 1
          }} exit={{
            opacity: 0
          }}>
								<div className={_c6}>
									<div className={_c7}>
										{tabs.map((tab, index) => <>
												<Pill key={index} layoutId={id} active={activeIndex === index} text={tab} onPress={() => {
                    if (activeIndex !== index) {
                      setScrollState('notScrolled');
                    } else if (gridRef) {
                      gridRef.scrollTo({
                        top: 0,
                        behavior: 'auto'
                      });
                    }
                    setActiveIndex(index);
                  }} />
											</>)}
									</div>
								</div>
							</motion.div>
						</> : <motion.div key={'search'} style={{
          display: 'flex',
          minWidth: 0,
          flex: 1,
          alignItems: 'center'
        }} initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }}>
							<div className={_c8}>
								<SearchInput innerRef={searchInputRef} onKeyPress={e => {
              if (e.key === 'Escape') {
                setSearch(false);
                setSearchValue('');
              }
            }} onPress={() => {
              searchInputRef.current?.focus();
            }} onFocus={() => {
              keyboardService.setFocusable({
                focus() {
                  searchInputRef.current?.focus();
                },
                blur() {
                  searchInputRef.current?.blur();
                },
                setInputMode(mode) {
                  editorRef.current?.setInputMode(mode);
                },
                parentFocusable: editorRef.current,
                getExtraSpace() {
                  return isTouchDevice() ? 150 : 0;
                }
              });
            }} autoFocus value={searchValue} onChange={({
              value
            }) => setSearchValue(value)} size={'condensed'} />
							</div>
							<div className={_c9}>
								<IconClose size={'large'} onPress={e => {
              e.preventDefault();
              runInAction('set focusable', () => {
                keyboardService.hideKeyboard();
                keyboardService.smileyBoxRecentlyToggle = true;
                keyboardService.setFocusable(editorRef.current);
                /**
                 * an android, if we don't focus, the keyboard will quickly reappear and disappear
                 */
                if (!(os === OS.ios || isSafariMobile())) {
                  keyboardService.focusable?.focus();
                }
              });
              setSearch(false);
              setSearchValue('');
            }} />
							</div>
						</motion.div>}
				</AnimatePresence>
			</div>
			<DeleteButton editorRef={editorRef} />
		</div>;
};
const DeleteButton: React.FC<{
  editorRef: React.MutableRefObject<RichTextEditorRef | null>;
}> = ({
  editorRef
}) => {
  const command = React.useMemo(() => getCommand(), []);
  const {
    pointerDown,
    pointerUp
  } = useButtonHold(() => {
    editorRef.current?.dispatchCommand(command, {});
  });
  return <IconRemove size={'large'} onPointerDown={pointerDown} onPointerUp={pointerUp} />;
};
const IconRemove = createSvgIcon(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.10938 4C5.42421 4 4.77491 4.35976 4.41211 4.93945L0 12L4.41406 19.0605C4.77687 19.6402 5.42424 20 6.10938 20H21C22.0943 20 23 19.0943 23 18V6C23 4.90575 22.0943 4 21 4H6.10938ZM6.10742 6H6.10938H21V18H6.10938L2.35742 12L6.10742 6ZM9.70703 7.29297L8.29297 8.70703L11.5859 12L8.29297 15.293L9.70703 16.707L13 13.4141L16.293 16.707L17.707 15.293L14.4141 12L17.707 8.70703L16.293 7.29297L13 10.5859L9.70703 7.29297Z" fill="currentColor" />
	</svg>);
const useButtonHold = (cb: Function) => {
  const timeout = React.useRef<number | null>(null);
  const pointerDown = (e: PointerEvent) => {
    e?.preventDefault();
    cb();
    timeout.current = window.setTimeout(pointerDown, 200);
  };
  const pointerUp = (e: PointerEvent) => {
    e?.preventDefault();
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };
  React.useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
  return {
    pointerDown,
    pointerUp
  };
};
const _c0 = " Knu-Flex cursor-pointer px-base flexShrink-0 py-tiny position-relative alignItems-center ";
const _c1 = "  ";
const _c2 = " Knu-Flex position-relative width-full alignItems-center ";
const _c3 = " shadow-Shadow4 ";
const _c4 = " shadow-none ";
const _c5 = " Knu-Flex alignItems-center pl-small overflow-hidden flex-1 justifyContent-center position-relative height-48px ";
const _c6 = " Knu-Flex flex-1 size-full gap-minor position-absolute inset-none placeItems-center ";
const _c7 = " Knu-Flex borderStyle-solid borderWidth-base borderColor-inputBarFieldBorder borderRadius-minor overflow-hidden ";
const _c8 = " Knu-Flex flex-1 minWidth-0-px ";
const _c9 = " Knu-Flex flexShrink-0 ";