import * as React from 'react';
import { useCacheAndNetworkQuery } from './useCacheAndNetworkQuery';
import { GetRecommendedChannels } from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
type DataContextType = {
  data?: typeof GetRecommendedChannels.TPrimaryResult;
  loading: boolean;
  error?: Error;
  setData: (updater: (prevData: typeof GetRecommendedChannels.TPrimaryResult) => typeof GetRecommendedChannels.TPrimaryResult) => void;
};
const GROUP_AMOUNT = 9;
const DataContext = React.createContext<DataContextType>(undefined);
const UpdateContext = React.createContext<() => void>(() => {});
export const useRecommendedChannels = (): DataContextType => {
  const context = React.useContext(DataContext);
  if (!context) {
    throw new Error('RecommendedChannelsContext is not provided. Make sure to wrap your component with RecommendedChannelsProvider.');
  }
  return context;
};
export const useUpdateRecommendedChannels = (): (() => void) => {
  const context = React.useContext(UpdateContext);
  if (!context) {
    throw new Error('RecommendedChannelsContext is not provided. Make sure to wrap your component with RecommendedChannelsProvider.');
  }
  return context;
};
export const RecommendedChannelsProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const {
    data,
    setData,
    loading,
    error,
    refetch
  } = useCacheAndNetworkQuery(GetRecommendedChannels, {
    groupAmount: GROUP_AMOUNT,
    pixelDensity: getPixelRatio()
  });
  const memoizedData = React.useMemo(() => ({
    data,
    loading,
    error,
    setData
  }), [data, loading, error, setData]);
  return <UpdateContext.Provider value={refetch}>
			<DataContext.Provider value={memoizedData}>
				{children}
			</DataContext.Provider>
		</UpdateContext.Provider>;
};