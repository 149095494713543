import { createRouter } from '@knuddels/component-library';

export type ProfileContentRouterConfig = {
	overview: { params: undefined };
	authenticityInfoOther: { params: undefined };
	authenticityOverview: { params: undefined };
	authenticityMarkOverview: { params: undefined };
	nickHistory: { params: undefined };
};

export const { Router, useRouter, useNavigate, useParams } = createRouter<
	ProfileContentRouterConfig
>();
