import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '../../providedServices';
import { createAccessiblePointerEventHandler, Flex, Text, ThemeOverride } from '@knuddels/component-library';
import { $CommandService } from '@knuddels-app/Commands';
export const KnuddelsPlusStatusBadge: React.FC = observer('KnuddelsPlusStatusBadge', () => {
  const userService = useService($UserService);
  const commandService = useService($CommandService);
  const user = userService.currentUser;
  if (!user) {
    return <React.Fragment />;
  }
  const hasKnuddelsPlus = !!user.hasKnuddelsPlus;
  return <div className={_c0}>
				<Flex px={(3 as ThemeOverride)} borderRadius={(3 as ThemeOverride)} bg={hasKnuddelsPlus ? 'accent' : 'black-solid-660'} placeItems={'center'} height={16} {...createAccessiblePointerEventHandler({
      pointerCallback: () => {
        commandService.parseAndInvokeCommand('/opensystemapp KnuddelsPlusShopApp');
      },
      disabled: hasKnuddelsPlus
    })} {...{
      style: {
        cursor: hasKnuddelsPlus ? 'default' : 'pointer'
      }
    }}>
					<Text type={'tiny'} bold={true} className={_c1}>
						{hasKnuddelsPlus ? 'PLUS' : 'BASIC'}
					</Text>
				</Flex>
			</div>;
});
const _c0 = " Knu-Flex ";
const _c1 = " textAlign-center ";