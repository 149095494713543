import { ChannelGroupInfo, ConversationTextMessageContent, ConversationVisiblePhotoCommentMessageContent } from '@generated/graphql';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { IconAlbum } from '@knuddels-app/shared-components/IconAlbum';
import { Text, ThemeOverride, rgb, useIsDarkColor, useTheme } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { ChannelBackgroundColorContext } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChannelBackground';
import { ConversationIdContext, MessengerInterfaces } from '@knuddelsModules/Messenger';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $UserService } from '@knuddelsModules/UserData';
import { FormattedText } from '@shared/components';
import * as React from 'react';
import { useContext } from 'react';
import { PRIVATE_MESSAGE_TITLE } from '../../../../i18n/shared-formats';
import { ChatRenderConfig } from '../../ChatRenderConfig';
import { MessageClickableSender } from '../MessageClickableSender';
import { ANSWER_BUTTON_SIZE } from '../Messages/AnswerButtonWrapper';
import { CondensedMessage } from '../Messages/CondensedMessage';
import { MessengerMessage } from '../Messages/MessengerMessage/MessengerMessage';
import { usePhotoCommentText } from '../usePhotoCommentText';
import { AnswerButton } from './CondensedAnswerButton';
export type CondensedChannelGroupInfo = Pick<ChannelGroupInfo, 'backgroundColor' | 'highlightColor' | 'highlightColorCondensedPrivateMessage'>;
const getCondensedMessage = (message: MessengerInterfaces.ClientMessengerMessage, channelGroupInfo: CondensedChannelGroupInfo, showAnswerButton: boolean) => {
  switch (message.message.content.__typename) {
    case 'ConversationTextMessageContent':
      return <ConversationTextMessageContentCondensed message={message} channelGroupInfo={channelGroupInfo} showAnswerButton={showAnswerButton} />;
    case 'ConversationVisiblePhotoCommentMessageContent':
      return <ConversationVisiblePhotoCommentMessageContentCondensed message={message} channelGroupInfo={channelGroupInfo} showAnswerButton={showAnswerButton} />;
    default:
      return null;
  }
};
export const ConversationVisiblePhotoCommentMessageContentCondensed: React.FC<{
  message: MessengerInterfaces.ClientMessengerMessage;
  channelGroupInfo: CondensedChannelGroupInfo;
  showAnswerButton: boolean;
}> = ({
  message,
  channelGroupInfo,
  showAnswerButton
}) => {
  const content = (message.message.content as ConversationVisiblePhotoCommentMessageContent);
  const sender = message.message.sender;
  const userService = useService($UserService);
  const otherNick = message.conversation.otherParticipants[0]?.nick ?? userService.currentUser.nick;
  const commentText = usePhotoCommentText(content, sender.id, otherNick);
  return <CondensedMessage>
			<FormattedTextDisplayWithCommandHandling renderIndent prefix={<Text className={_c0}>
						<CondensedMessagePrefix message={message} channelGroupInfo={channelGroupInfo} />
						<IconAlbum />
					</Text>} text={commentText} suffix={showAnswerButton && <AnswerButton answerTo={[sender]} channelGroupInfo={channelGroupInfo} />} />
		</CondensedMessage>;
};
export const ConversationTextMessageContentCondensed: React.FC<{
  message: MessengerInterfaces.ClientMessengerMessage;
  channelGroupInfo: CondensedChannelGroupInfo;
  showAnswerButton: boolean;
}> = ({
  message,
  channelGroupInfo,
  showAnswerButton
}) => {
  const content = (message.message.content as ConversationTextMessageContent);
  const sender = message.message.sender;
  return <CondensedMessage>
			<FormattedTextDisplayWithCommandHandling renderIndent prefix={<CondensedMessagePrefix message={message} channelGroupInfo={channelGroupInfo} />} text={FormattedText.fromJsonString(content.formattedText)} suffix={showAnswerButton && <AnswerButton answerTo={[sender]} channelGroupInfo={channelGroupInfo} />} />
		</CondensedMessage>;
};
export const CondensedMessagePrefix: React.FC<{
  message: MessengerInterfaces.ClientMessengerMessage;
  channelGroupInfo: CondensedChannelGroupInfo;
}> = ({
  message,
  channelGroupInfo
}) => {
  const profileNavigationService = useService($ProfileNavigationService);
  const sender = message.message.sender;
  const isSenderActiveUser = useService($UserService).isCurrentUser(sender.id);
  const i18n = useService($I18n);
  const receiver = message.conversation.otherParticipants.length === 0 || !isSenderActiveUser ? undefined : message.conversation.otherParticipants[0];
  const textColor = rgb(channelGroupInfo.highlightColorCondensedPrivateMessage?.red, channelGroupInfo.highlightColorCondensedPrivateMessage?.green, channelGroupInfo.highlightColorCondensedPrivateMessage?.blue);
  if (!receiver) {
    return <Text bold={true} onPress={() => {
      profileNavigationService.showProfile(sender.id);
    }} hoverUnderline={true} color={textColor} className={_c1}>
				{i18n.format(PRIVATE_MESSAGE_TITLE, {
        name: sender.nick
      })}
				:{' '}
			</Text>;
  }
  return <Text color={textColor} className={_c2}>
			<Text color={textColor} bold={true} hoverUnderline={true} onPress={() => {
      profileNavigationService.showProfile(sender.id);
    }} className={_c3}>
				{sender.nick + ' '}
			</Text>
			<Text color={textColor} bold={true} hoverUnderline={true} onPress={() => {
      profileNavigationService.showProfile(receiver.id);
    }} className={_c4}>
				{i18n.format(declareFormat({
        id: 'PRIVATE_MESSAGE_TITLE_CONDENSED',
        defaultFormat: '(private to {receiver})'
      }), {
        receiver: receiver.nick
      })}
			</Text>
			:{' '}
		</Text>;
};
export const getMessageGroupKey = (event: MessengerInterfaces.ClientMessengerMessage): string => {
  return event.id;
};
export const MessengerMessageGroup: React.FC<{
  event: MessengerInterfaces.ClientMessengerMessage;
  firstOfSet: boolean;
  lastOfSet: boolean;
  animated: boolean;
  isStackedLayout: boolean;
  renderConfig: ChatRenderConfig;
  channelGroupInfo: Pick<ChannelGroupInfo, 'backgroundColor' | 'highlightColor' | 'highlightColorCondensedPrivateMessage'>;
}> = ({
  channelGroupInfo,
  event,
  firstOfSet,
  lastOfSet,
  isStackedLayout,
  renderConfig
}) => {
  const message = event.message;
  const participants = event.conversation.otherParticipants;
  const conversationId = event.conversation.id;
  const sender = message.sender;
  const {
    red,
    green,
    blue
  } = channelGroupInfo.highlightColor;
  const buttonColor = `rgba(${red}, ${green}, ${blue}, 1.0)`;
  const isCurrentUserSender = participants.map(it => it.id).indexOf(sender.id) === -1;
  const shouldShowAnswerButton = !isCurrentUserSender && lastOfSet;
  const theme = useTheme();
  const isDarkBg = useIsDarkColor((useContext(ChannelBackgroundColorContext) as ThemeOverride));
  if (renderConfig.layoutService.renderMode === 'condensed') {
    const condensedNode = getCondensedMessage(event, channelGroupInfo, shouldShowAnswerButton);
    if (condensedNode) {
      return condensedNode;
    }
  }
  const titleColor = renderConfig.layoutService.renderMode === 'modernWithoutBubbles' && channelGroupInfo.highlightColorCondensedPrivateMessage ? rgb(channelGroupInfo.highlightColorCondensedPrivateMessage.red, channelGroupInfo.highlightColorCondensedPrivateMessage.green, channelGroupInfo.highlightColorCondensedPrivateMessage.blue) : undefined;
  const msgNode = <MessengerMessage renderTime={renderConfig.layoutService.renderMode !== 'modernWithoutBubbles'} key={message.id} sender={sender} overrideTitleStyles={{
    color: titleColor
  }} participants={participants} isFirst={firstOfSet} message={message} buttonColor={buttonColor} hasAnswerButton={shouldShowAnswerButton} />;
  const bubbleColor = renderConfig.layoutService.renderMode === 'modernWithoutBubbles' ? isDarkBg ? theme.colors.basic.transparentDark : theme.colors.basic.transparentLight : `rgba(${red}, ${green}, ${blue}, .66)`;
  return <ConversationIdContext.Provider value={conversationId}>
			<MessageClickableSender align={'left'} uid={sender.id} isStackedLayout={isStackedLayout} bubbleColor={bubbleColor} showImage={firstOfSet} hasArrow={firstOfSet} noBubblePadding={renderConfig.layoutService.renderMode === 'modernWithoutBubbles'} additionalMargin={lastOfSet ? ANSWER_BUTTON_SIZE / 2 : undefined}>
				<div className={renderConfig.layoutService.renderMode !== 'modernWithoutBubbles' ? 'content-is-dark' : ''}>
					{msgNode}
				</div>
			</MessageClickableSender>
		</ConversationIdContext.Provider>;
};
const _c0 = " mr-tiny ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";