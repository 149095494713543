export const BADGE_LIMIT = 99;
export const BADGE_PLACEHOLDER = '·';

export const getTotalBadgeCount = (
	counts: (number | string | undefined)[],
	badgeLimit = BADGE_LIMIT,
	badgePlaceholder = BADGE_PLACEHOLDER
): string | undefined => {
	const total = counts.reduce((acc: number, count) => {
		if (!count) {
			return acc;
		}

		const numberCount = Number(count);

		if (isNaN(numberCount)) {
			return BADGE_LIMIT + 1;
		}

		return acc + numberCount;
	}, 0);

	return getBadgeCount(total, badgeLimit, badgePlaceholder);
};

/**
 * Returns the badge count as a string or undefined if the count is 0 or less.
 * If the count is greater than the badge limit, the badge placeholder is returned.
 */
export const getBadgeCount = (
	count: number | string | undefined,
	badgeLimit = BADGE_LIMIT,
	badgePlaceholder = BADGE_PLACEHOLDER
): string | undefined => {
	if (!count) {
		return undefined;
	}

	const numberCount = Number(count);

	if (isNaN(numberCount)) {
		return String(count);
	}

	return numberCount > 0
		? numberCount > badgeLimit
			? badgePlaceholder
			: String(count)
		: undefined;
};
