import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { SoundEvent } from '@generated/graphql';

const SoundEventMap: Partial<Record<SoundEvent, string>> = {
	[SoundEvent.NewMessageReceived]: 'new_message_received',
	[SoundEvent.FriendRequestAccepted]: 'friend_request_accepted',
};

@injectable()
export class SoundService {
	constructor(
		@inject($ClientSettingsService)
		private readonly clientSettingsService: typeof $ClientSettingsService.T
	) {}

	playSound(soundEvent: SoundEvent): void {
		if (
			// We don't play sounds in the stapp messenger for now because the
			// product details aren't decided yet.
			globalEnv.product === 'stapp-messenger' ||
			// We don't play sounds if this not allowed in client settings
			!this.clientSettingsService.isSoundEventEnabled(soundEvent)
		) {
			return;
		}

		if (SoundEventMap[soundEvent]) {
			import(
				`../../../../shared/sounds/${SoundEventMap[soundEvent]}.mp3`
			).then(s => {
				const audio = new Audio(s.default);
				audio.play();
			});
		}
	}

	playSoundFromUrl(url: string): void {
		if (
			this.clientSettingsService.enabledSoundEvents.includes(
				SoundEvent.Global
			)
		) {
			const audio = new Audio(url);
			audio.play();
		}
	}
}
