import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { NotificationConfirmBox } from '../components/NotificationConfirmBox';
import { $PushNotificationsService } from '@knuddelsModules/Notifications';
import { computed } from '@knuddels-app/mobx';
import { $I18n, $MessageFormatProvider } from '@knuddels-app/i18n';
import { NotificationBarProvider } from '../components/NotificationBarProvider';
import { createNotificationChannels } from './createNotificationChannels';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

@injectable()
export class NotificationsComponents {
	public readonly NotificationConfirmBox = NotificationConfirmBox;
	public readonly NotificationBarProvider = NotificationBarProvider;

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject($PushNotificationsService)
		private readonly pushNotificationsService: typeof $PushNotificationsService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		createNotificationChannels(i18n);
	}

	@computed
	get showConfirmBox(): boolean {
		return this.pushNotificationsService.status === 'undecided';
	}
}
