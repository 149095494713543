import { createTrackingEventType } from '@knuddels-app/analytics/eventCreators';
import { Category } from '@knuddels-app/analytics/Category';
import { createUserTimingTracker } from '@knuddels-app/analytics/userTimingTracking';

export const enterChannelEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_EnterChannel'
);

export const leaveChannelEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_LeaveChannel'
);

export const inputBarEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_InputBar'
);

export const sendMessageEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_SendMessage'
);

export const slashCommandEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_SlashCommandEntered'
);

export const messageEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_Message'
);

export const chatroomErrorEvent = createTrackingEventType(
	Category.Chatraum,
	'Chatraum_Error'
);

export const nickListTitlebarEvent = createTrackingEventType(
	Category.Nicklist,
	'Chatraum_Titlebar'
);

export const nickListUserEvent = createTrackingEventType(
	Category.Nicklist,
	'Nicklist_User'
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const nicklistOpenedTimingTracker = createUserTimingTracker(
	'Chatraum',
	'NicklistOpened'
);
export const nicklistClosedTimingTracker = createUserTimingTracker(
	'Chatraum',
	'NicklistClosed'
);
export const chatroomShownTimingTracker = createUserTimingTracker(
	'Chatraum',
	'Chatroom_Shown'
);
