import { K3FirebaseInterface } from './K3FirebaseInterface';
import { newServiceId } from '@knuddels-app/DependencyInjection';
import { FirebaseAnalyticsService } from './FirebaseAnalyticsService';

/**
 * Use this service for special tracking or other "single use" firebase purposes. For commonly used tracking
 * use `$FirebaseAnalyticsService`.
 */
export const $K3Firebase = newServiceId<K3FirebaseInterface>('$K3Firebase');

/**
 * This service should be used for all default tracking purposes.
 */
export const $FirebaseAnalyticsService = newServiceId<FirebaseAnalyticsService>(
	'$FirebaseAnalyticsService'
);
