// tslint:disable:member-ordering

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Flex, FlexCol } from '@knuddels/component-library';
import { useHovered } from '@knuddels-app/tools/useHovered';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { Color } from '@generated/graphql';
import './CarouselIndicators.css';
const useColorConfig = (overrideColor?: Color) => {
  return React.useMemo(() => {
    const activeColor = colorToRgbaString(overrideColor) ?? 'var(--colors-accent)';
    const colors: IndicatorColors = {
      inactive: 'var(--carousel-indicator-inactive)',
      active: activeColor,
      hovered: 'var(--carousel-indicator-hovered)'
    };
    return colors;
  }, [overrideColor]);
};
export function CarouselIndicators<T>(props: {
  items: ReadonlyArray<T>;
  activeIndex: number;
  activeColor?: Color;
  animateNewItems?: boolean;
  onClick?(item: T, index: number): void;
}): React.ReactElement {
  const onClickProp = props.onClick;
  const colors = useColorConfig(props.activeColor);
  return <div className={_c0}>
			{props.items.map((_item, i) => {
      const onClick = onClickProp ? () => {
        onClickProp(_item, i);
      } : undefined;
      return <Indicator key={i} active={props.activeIndex === i} animate={props.animateNewItems} onClick={onClick} colors={colors} />;
    })}
		</div>;
}
function Indicator(props: {
  active: boolean;
  onClick?(): void;
  colors: IndicatorColors;
  animate?: boolean;
}): JSX.Element {
  const {
    hovered,
    bind
  } = useHovered();
  const isNew = useIsNew(props.colors, props.animate, props.active, hovered);
  return <FlexCol p={'tiny'} onPress={e => {
    e.stopPropagation();
    props.onClick?.();
  }} cursor={props.onClick ? 'pointer' : 'default'} {...bind}>
			<Flex {...({
      className: 'CarouselIndicators' + (!props.active && props.animate && isNew && !hovered ? ' new' : ''),
      style: {
        '--carousel-indicator-color': props.active ? props.colors.active : hovered ? props.colors.hovered : props.colors.inactive
      }
    } as any)} flexDirection={'column'} borderRadius={'circle'} size={8} />
		</FlexCol>;
}
const useIsNew = (colors: IndicatorColors, animate?: boolean, active?: boolean, hovered?: boolean) => {
  const [isNew, setIsNew] = useState(!active && !!animate);
  React.useEffect(() => {
    if (hovered) {
      setIsNew(false);
    }
  }, [hovered]);
  useEffect(() => {
    if (active && isNew) {
      setIsNew(false);
    }
  }, [isNew, active]);
  return isNew;
};
type IndicatorColors = {
  inactive: string;
  hovered: string;
  active: string;
};
const _c0 = " Knu-Flex position-relative alignSelf-center ";