import { color as u } from "../color/index.js";
import { isString as g, floatRegex as N, colorRegex as b, sanitize as x } from "../utils.js";
function T(t) {
  var e, s;
  return isNaN(t) && g(t) && (((e = t.match(N)) === null || e === void 0 ? void 0 : e.length) || 0) + (((s = t.match(b)) === null || s === void 0 ? void 0 : s.length) || 0) > 0;
}
const p = "number", m = "color", O = "var", _ = "var(", a = "${}", R = /var\s*\(\s*--(?:[\w-]+\s*|[\w-]+\s*,(?:\s*[^)(\s]|\s*\((?:[^)(]|\([^)(]*\))*\))+\s*)\)|#[\da-f]{3,8}|(?:rgb|hsl)a?\((?:-?[\d.]+%?[,\s]+){2}-?[\d.]+%?\s*(?:[,/]\s*)?(?:\b\d+(?:\.\d+)?|\.\d+)?%?\)|-?(?:\d+(?:\.\d+)?|\.\d+)/giu;
function f(t) {
  const e = t.toString(), s = [], c = {
    color: [],
    number: [],
    var: []
  }, n = [];
  let o = 0;
  const l = e.replace(R, (i) => (u.test(i) ? (c.color.push(o), n.push(m), s.push(u.parse(i))) : i.startsWith(_) ? (c.var.push(o), n.push(O), s.push(i)) : (c.number.push(o), n.push(p), s.push(parseFloat(i))), ++o, a)).split(a);
  return { values: s, split: l, indexes: c, types: n };
}
function h(t) {
  return f(t).values;
}
function d(t) {
  const { split: e, types: s } = f(t), c = e.length;
  return (n) => {
    let o = "";
    for (let r = 0; r < c; r++)
      if (o += e[r], n[r] !== void 0) {
        const l = s[r];
        l === p ? o += x(n[r]) : l === m ? o += u.transform(n[r]) : o += n[r];
      }
    return o;
  };
}
const E = (t) => typeof t == "number" ? 0 : t;
function v(t) {
  const e = h(t);
  return d(t)(e.map(E));
}
const C = {
  test: T,
  parse: h,
  createTransformer: d,
  getAnimatableNone: v
};
export {
  f as analyseComplexValue,
  C as complex
};
