import { Fragment as m } from "react";
import { HTMLVisualElement as o } from "../html/HTMLVisualElement.js";
import { SVGVisualElement as t } from "../svg/SVGVisualElement.js";
import { isSVGComponent as i } from "./utils/is-svg-component.js";
const f = (e, r) => i(e) ? new t(r) : new o(r, {
  allowProjection: e !== m
});
export {
  f as createDomVisualElement
};
