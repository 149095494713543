import * as React from 'react';
import { ContextMenuEntry } from '@shared/components';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { DeletePhotoData, useDeletePhoto } from './useDeletePhoto';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
import { IconTrash } from '@knuddels/component-library';
export type DeleteAlbumPhotoContextMenuEntryProps = DeletePhotoData;
export const DeleteAlbumPhotoContextMenuEntry: React.FC<DeleteAlbumPhotoContextMenuEntryProps> = props => {
  const handleDeletePhoto = useDeletePhoto(props);
  const track = useTrackAlbumDetails();
  return <ContextMenuEntry icon={IconTrash} text={formatMessage(declareFormat({
    id: 'album.details.delete-photo',
    defaultFormat: 'Delete photo'
  }))} onClick={() => {
    handleDeletePhoto();
    track('DeleteImage_Clicked');
  }} />;
};