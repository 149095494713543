import * as React from 'react';
import { Flex, FlexCol, LineDivider, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { SkeletonAnimation } from '@shared/components';
interface Props {
  amount: number;
}
const titleWidths = [118, 68, 118, 68, 118, 118];
const subTitleWidths = [168, 198, 168, 198, 168, 168];
export class MessengerListSkeleton extends React.PureComponent<Props> {
  render(): React.ReactNode {
    return <div className={_c0}>
				<SkeletonAnimation>
					{Array.from(Array(this.props.amount).keys()).map((value, index) => <SkeletonMessage index={index} key={index} />)}
				</SkeletonAnimation>
			</div>;
  }
}
export const SkeletonMessage = ({
  index = 0
}: any) => {
  return <div key={index} className={_c1}>
			<div className={_c2 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c3 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				<div className={_c4}>
					<div style={{
          width: resolveThemingValue(titleWidths[index % titleWidths.length], "sizes", useTheme())
        }} className={_c5 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					<div style={{
          width: resolveThemingValue(subTitleWidths[index % subTitleWidths.length], "sizes", useTheme())
        }} className={_c6 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				</div>
			</div>
			<div className={_c7}>
				<LineDivider className={_c8} />
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol minHeight-0-px flex-1 ";
const _c1 = " Knu-FlexCol alignSelf-stretch ";
const _c2 = " Knu-Flex bg-transparent width-full ";
const _c3 = " Knu-FlexCol height-56px width-56px borderRadius-28px mx-base mt-18px mb-22px bg-skeleton ";
const _c4 = " Knu-FlexCol mt-27px ";
const _c5 = " Knu-FlexCol height-16px bg-skeleton borderRadius-5px ";
const _c6 = " Knu-FlexCol mt-minor height-12px bg-skeleton borderRadius-5px ";
const _c7 = " Knu-FlexCol pl-84px pr-base ";
const _c8 = "  ";