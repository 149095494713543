import * as React from 'react';
import { Carousel as WebCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CarouselProps } from './CarouselProps';
interface State {
  index: number;
}
export class Carousel<T> extends React.PureComponent<CarouselProps<T>, State> {
  state: State = {
    index: this.props.initialIndex || 0
  };
  snapToNext = (): void => {
    // prevents weird single image `infiniteLoop` carousel
    if (this.props.items.length > 1) {
      this.setState({
        index: this.state.index + 1
      });
    }
  };
  snapToPrev = (): void => {
    // prevents weird single image `infiniteLoop` carousel
    if (this.props.items.length > 1) {
      this.setState({
        index: this.state.index - 1
      });
    }
  };
  setIndex = (index: number): void => {
    this.setState({
      index: (index + this.props.items.length) % this.props.items.length
    });
  };
  componentDidUpdate(prevProps: Readonly<CarouselProps<T>>, prevState: Readonly<State>): void {
    if (this.state.index !== prevState.index && this.props.onSelectedItemChanged) {
      this.props.onSelectedItemChanged(this.state.index);
    }
  }
  render(): React.ReactNode {
    return <WebCarousel showArrows={false} showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop selectedItem={this.state.index} onChange={this.setIndex}>
				{this.props.items.map((item: any, index: number) => this.props.renderItem(item, index))}
			</WebCarousel>;
  }
}