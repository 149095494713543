import * as React from 'react';
import { useEffect } from 'react';
import { useParams, useRouter } from '../ChannelListRouter';
import { ChannelGroupExpandedContextDefaultProvider } from '../ChannelGroupExpandedContext';
import { FullCategoryContent } from './FullCategoryContent';
import { useFullCategoryHeader } from './useFullCategoryHeader';
import { getCategoryFormatId } from '../shared/getCategoryFormatId';
import { SmallChannelGroupExpandedContextProvider } from '../SmallChannelGroupExpandedContext';
export function FullCategory(): React.ReactElement {
  const params = useParams('category');
  const router = useRouter();
  const title = useFullCategoryHeader(params.id, getCategoryFormatId(params.id));
  useEffect(() => {
    if (!params) {
      router.pop();
    }
  }, [params]);
  useEffect(() => {
    router.configureNavBar({
      title
    });
  }, [title]);
  return <ChannelGroupExpandedContextDefaultProvider>
			<SmallChannelGroupExpandedContextProvider>
				<FullCategoryContent categoryId={params.id} grouped={params.grouped} />
			</SmallChannelGroupExpandedContextProvider>
		</ChannelGroupExpandedContextDefaultProvider>;
}