import { useMutation } from '@knuddels-app/Connection';
import { FotomeetRemoveMatchResponse, removeMatch } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';

export const useRemoveMatch = () => {
	const [remove] = useMutation(removeMatch);
	const snackbarService = useService($SnackbarService);

	return (userId: string) => {
		remove({ id: userId }).then(result => {
			// success is handled via events (contact list update)
			if (result.data !== FotomeetRemoveMatchResponse.Success) {
				snackbarService.showGenericError();
			}
		});
	};
};
