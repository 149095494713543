import { Color } from '@generated/graphql';
import { rgb } from '@knuddels/component-library';

export function colorToRgb(color: Color | undefined): any | undefined {
	if (!color) {
		return undefined;
	}
	const { red, green, blue } = color;
	return rgb(red, green, blue);
}
