import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage, declareFormat } from '@knuddels-app/i18n';
export const SnackbarCameraSenderU16: SnackbarData = {
  type: 'cameraU16',
  text: () => formatMessage(declareFormat({
    id: 'conversation.snackbar.camera-disabled-sender-u16.text',
    defaultFormat: 'Image sending - requirements not met'
  })),
  subtext: () => formatMessage(declareFormat({
    id: 'conversation.snackbar.camera-disabled-sender-u16.subtext',
    defaultFormat: 'You do not meet all the requirements to be allowed to send photos.'
  })),
  adornment: require('@shared/icons/icon-bighello.gif')
};
export const SnackbarCameraReceiverU16: SnackbarData = {
  type: 'cameraU16',
  text: () => formatMessage(declareFormat({
    id: 'conversation.snackbar.camera-disabled-receiver-u16.text',
    defaultFormat: 'Image sending - member not old enough'
  })),
  subtext: () => formatMessage(declareFormat({
    id: 'conversation.snackbar.camera-disabled-receiver-u16.subtext',
    defaultFormat: 'The recipient must be at least 16 years old to receive images.'
  })),
  adornment: require('@shared/icons/icon-bighello.gif')
};