import { parseImageGroup } from './parseImageGroup';
import { shallowEquals } from '../../tools';
import { TextStates } from '../../atoms/Texts/TextProps';
import { ImageGroupData } from './ImageGroupData';
import { rgb } from '@knuddels/component-library';

export type FormattedText =
	| FormattedTextText
	| FormattedTextLink
	| FormattedTextList
	| FormattedTextSmiley;

export class FormattedTextText {
	readonly kind = 'Text';

	constructor(
		public readonly text: string,
		// TODO Tech debt: remove state here again if we can improve handling the CommunicationRequestBox text.
		//  (this state was only introduced for this use case)
		public readonly style: {
			bold: boolean;
			italic: boolean;
			state?: TextStates;
			indented?: boolean;
			color?: string;
		} = { bold: false, italic: false }
	) {}
}

export class FormattedTextLink {
	readonly kind = 'Link';

	constructor(
		public readonly href: string,
		public readonly text: string,
		public readonly style: {
			bold: boolean;
			italic: boolean;
			indented?: boolean;
			color?: string;
		} = {
			bold: false,
			italic: false,
		}
	) {}
}

export class FormattedTextList {
	readonly kind = 'List';

	constructor(
		public items: FormattedText[],
		public meta: {
			largestImageHeight: number | undefined;
			containsImages: boolean;
		}
	) {}
}

export class FormattedTextSmiley {
	readonly kind = 'Smiley';

	constructor(
		public data: ImageGroupData,
		public indented = false
	) {}
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FormattedText {
	export function isFormattedText(text: any): text is FormattedText {
		if (text instanceof FormattedTextText) {
			return true;
		}
		if (text instanceof FormattedTextLink) {
			return true;
		}
		if (text instanceof FormattedTextList) {
			return true;
		}
		if (text instanceof FormattedTextSmiley) {
			return true;
		}
		return false;
	}

	export function asText(
		text: FormattedText,
		includeSmileyPlaceholder = true
	): string {
		if (text.kind === 'Text') {
			return text.text;
		}
		if (text.kind === 'Link') {
			return text.text;
		}
		if (text.kind === 'Smiley') {
			return includeSmileyPlaceholder ? '[Smiley]' : '';
		}
		if (text.kind === 'List') {
			return text.items
				.map(item => asText(item, includeSmileyPlaceholder))
				.join('');
		}
		return '';
	}

	export function getFirstLine(text: FormattedText): string {
		return asText(text).split('\n')[0];
	}

	export function isEmpty(text: FormattedText): boolean {
		return asText(text).length === 0;
	}

	// eslint-disable-next-line no-inner-declarations
	function haveSameStyle(
		text1: FormattedTextText,
		text2: FormattedTextText
	): boolean {
		return shallowEquals(text1.style, text2.style);
	}

	const cache = new Map<string, FormattedText>();

	export function fromJsonString(jsonString: string): FormattedText {
		if (cache.has(jsonString)) {
			return cache.get(jsonString)!;
		}

		const r = fromJson(JSON.parse(jsonString));

		if (cache.size > 1000) {
			// This can be optimized, e.g. by using an LRU cache.
			// However, this heuristic should do the job.
			cache.clear();
		}

		cache.set(jsonString, r);
		return r;
	}

	export function empty(): FormattedText {
		return new FormattedTextText('');
	}

	export function fromJson(formattedTextJson: {
		[key: string]: any;
	}): FormattedText {
		if (formattedTextJson.text) {
			const text = formattedTextJson.text;
			return new FormattedTextText(text.text, {
				bold: text.bold,
				italic: text.italic,
				indented: !!formattedTextJson.lineIndent,
				color: text.color
					? rgb(text.color.red, text.color.green, text.color.blue)
					: undefined,
			});
		}
		if (formattedTextJson.link) {
			const link = formattedTextJson.link;
			return new FormattedTextLink(link.href, link.text, {
				bold: link.bold,
				italic: link.italic,
				indented: !!formattedTextJson.lineIndent,
				color: link.color
					? rgb(link.color.red, link.color.green, link.color.blue)
					: undefined,
			});
		}
		if (formattedTextJson.list) {
			if (!formattedTextJson.list.items) {
				return new FormattedTextList([], {
					largestImageHeight: undefined,
					containsImages: false,
				});
			}

			const items = Array<FormattedText>();
			let containsImages = false;
			let largestImageHeight = 0;
			for (const i of formattedTextJson.list.items) {
				const transformed = fromJson(i);

				if (transformed.kind === 'Smiley') {
					containsImages = true;
					largestImageHeight = Math.max(
						largestImageHeight,
						transformed.data.containerHeight
					);
				}

				if (items.length > 0 && transformed.kind === 'Text') {
					const last = items[items.length - 1];
					// merge consecutive text elements that have the same style
					if (
						last.kind === 'Text' &&
						haveSameStyle(transformed, last)
					) {
						items[items.length - 1] = new FormattedTextText(
							last.text + transformed.text,
							last.style
						);
						continue;
					}
				}
				items.push(transformed);
			}

			if (items.length === 1) {
				return items[0];
			}
			return new FormattedTextList(items, {
				largestImageHeight,
				containsImages,
			});
		}
		if (formattedTextJson.smiley) {
			const smiley = formattedTextJson.smiley;
			const imageGroupData = parseImageGroup(smiley.json);
			if (!imageGroupData) {
				return new FormattedTextText('');
			}
			return new FormattedTextSmiley(
				imageGroupData,
				!!formattedTextJson.lineIndent
			);
		}

		throw new Error('Unknown formatted text');
	}
}
