import { BoxProps, Flex } from '@knuddels/component-library';
import { motion } from 'framer-motion';
import * as React from 'react';
export type SlidingViewProps = {
  hide?: boolean; // slides out if changed to true
  slideInCallback?(): void;
  slideOutCallback?(): void;
} & BoxProps;
const OFF_SCREEN_POSITION = 1000;
export const SlidingView: React.FC<SlidingViewProps> = props => {
  return <motion.div animate={props.hide ? {
    x: OFF_SCREEN_POSITION
  } : {
    x: 0
  }} initial={{
    x: OFF_SCREEN_POSITION
  }} transition={{
    type: 'spring',
    stiffness: 50
  }} onAnimationComplete={() => {
    const cb = props.hide ? props.slideOutCallback : props.slideInCallback;
    cb?.();
  }}>
			<Flex {...props}>{props.children}</Flex>
		</motion.div>;
};