type ConversationWithParticipants = {
	id: string;
	otherParticipants: readonly unknown[];
};

export function removeDuplicatesAndFilterSelf<
	T extends ConversationWithParticipants
>(conversations: readonly T[]): T[] {
	const map: { [id: string]: T } = conversations.reduce((s, conv) => {
		// filter conversations with oneself (no otherParticipant)
		if (conv.otherParticipants[0]) {
			s[conv.id] = conv;
		}
		return s;
	}, {} as { [id: string]: T });

	return Object.keys(map).map(convId => map[convId]);
}
