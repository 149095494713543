import * as React from 'react';
import { GetProfilePictureUrlsProps, User, withGetProfilePictureUrls } from '@generated/graphql';
import { $CurrentEndpointStore } from '@knuddels-app/Connection';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { ProfilePhoto } from '../PhotoDisplays/ProfilePhoto';
interface Props {
  userId: User['id'];
  size: number;
}
const ProfilePictureBase = injectedComponent({
  name: 'ProfilePicture',
  props: declareProps<Props & GetProfilePictureUrlsProps>(),
  inject: {
    currentEndpointStore: $CurrentEndpointStore
  }
}, ({
  data,
  size
}) => {
  if (data && data.user) {
    return <ProfilePhoto user={data.user.user} size={size} />;
  } else {
    return null;
  }
});
export const ProfilePicture = withGetProfilePictureUrls<Props>({})(ProfilePictureBase);