function c(o) {
  let e = null;
  return () => {
    const t = () => {
      e = null;
    };
    return e === null ? (e = o, t) : !1;
  };
}
const n = c("dragHorizontal"), r = c("dragVertical");
function i(o) {
  let e = !1;
  if (o === "y")
    e = r();
  else if (o === "x")
    e = n();
  else {
    const t = n(), l = r();
    t && l ? e = () => {
      t(), l();
    } : (t && t(), l && l());
  }
  return e;
}
function a() {
  const o = i(!0);
  return o ? (o(), !1) : !0;
}
export {
  c as createLock,
  i as getGlobalLock,
  a as isDragActive
};
