import * as React from 'react';
import { XOR } from '../../tools';
import { getImageData } from './getImageData';
import { FlexCol, PointerEvent, Image as LibImage, ThemeOverride, Text, FallbackBox, resolveThemingValue, useTheme, toPointerHandler } from '@knuddels/component-library';
export type ImageMode = 'contain' | 'cover';
export type ImageBorderType = 'normal' | 'rounded' | 'circular';
export type ImageSizingMode = 'fixed' | 'autoWidth' | 'autoHeight' | 'autoShortSide' | 'stretch' | 'fit' | 'source';

/**
 * 1. require(image)
 * - has type any so it just works...
 * - Native: returns a number (resourceId) (e.g. `155`)
 * - Web: returns url like `/xyz.png`
 *
 * 2. Image Url (e.g. https://example.com/an-image.png)
 *
 * Note: base64 in-lined images don't work on native
 * because loadImageData doesn't work and I wasn't able to fix it
 */
type ImageSource = string;
interface BaseProps {
  source: ImageSource;
  mode?: ImageMode;
  borderType?: ImageBorderType;
  alt?: string;
  caption?: string;
  scale?: number;
  onLoad?: () => void;
  onClick?: (e: PointerEvent) => void;
  errorPicSource?: ImageSource;
}
interface WithSizeProps {
  sizingMode?: Exclude<ImageSizingMode, 'stretch' | 'fit' | 'source'>;
  size: number;
}
interface WithoutSizeProps {
  sizingMode: 'stretch' | 'fit' | 'source';
}
export type ImageProps = BaseProps & XOR<WithSizeProps, WithoutSizeProps>;
interface State {
  source: ImageSource;
  imageWidth?: number;
  imageHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
}
const captionHeight = 48;
export const Image: React.FC<ImageProps> = props => {
  const {
    source,
    mode = 'contain',
    borderType = 'normal',
    alt,
    caption,
    onClick,
    errorPicSource,
    sizingMode = 'fixed'
  } = props;
  const [state, setState] = React.useState<State>({
    source
  });
  const mounted = React.useRef(false);
  const {
    width,
    height
  } = getSize(props, state);
  const borderRadius = borderType === 'normal' ? 0 : borderType === 'rounded' ? 5 : width && width / 2;
  const shouldStretch = sizingMode === 'stretch' || sizingMode === 'fit';
  const onLayout = (e: Size): void => {
    setState((current: State) => ({
      ...current,
      maxWidth: e.width,
      maxHeight: e.height - (caption ? captionHeight : 0)
    }));
  };
  React.useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);
  React.useEffect(() => {
    const updateImageState = async (): Promise<void> => {
      const imageData = (await getImageData(source)) || errorPicSource && (await getImageData(errorPicSource)) || {
        source,
        width: undefined,
        height: undefined
      };
      if (!mounted.current) {
        return;
      }
      setState((currState: State) => ({
        ...currState,
        source: imageData.source,
        imageWidth: imageData.width,
        imageHeight: imageData.height
      }));
    };
    updateImageState().catch(console.error);
  }, [source]);
  return <FallbackBox onLayout={onLayout} style={{
    flex: resolveThemingValue(shouldStretch ? 1 : undefined, "theme", useTheme()),
    alignItems: resolveThemingValue(shouldStretch ? 'center' : undefined, "theme", useTheme()),
    justifyContent: resolveThemingValue(shouldStretch ? 'center' : undefined, "theme", useTheme())
  }} className={_c0}>
			<div onClick={toPointerHandler(onClick)} style={{
      cursor: resolveThemingValue(onClick ? 'pointer' : undefined, "theme", useTheme()),
      width: resolveThemingValue(width, "sizes", useTheme()),
      height: resolveThemingValue(height !== undefined ? height + (caption ? captionHeight : 0) : undefined, "sizes", useTheme())
    }} className={_c1}>
				{height !== undefined && <LibImage width={'full'} height={height} borderRadius={(borderRadius as ThemeOverride)} resizeMode={mode} onLoad={props.onLoad} src={state.source} alt={alt || ''} />}
				{caption && <Text type={'body1'} numberOfLines={1} className={_c2}>
						{caption}
					</Text>}
			</div>
		</FallbackBox>;
};
function getSize({
  scale = 1,
  ...props
}: ImageProps, state: State): {
  width?: number;
  height?: number;
} {
  if (!props.sizingMode || props.sizingMode === 'fixed') {
    return {
      width: props.size,
      height: props.size
    };
  }
  const {
    imageWidth,
    imageHeight,
    maxWidth,
    maxHeight
  } = state;
  const width = imageWidth ? imageWidth * scale : undefined;
  const height = imageHeight ? imageHeight * scale : undefined;
  if (!width || !height) {
    return {};
  } else if (props.sizingMode === 'source') {
    return {
      width,
      height
    };
  } else if (props.sizingMode === 'autoWidth') {
    return scaleImageToHeight(width, height, props.size);
  } else if (props.sizingMode === 'autoHeight') {
    return scaleImageToWidth(width, height, props.size);
  } else if (props.sizingMode === 'autoShortSide') {
    if (width < height) {
      return scaleImageToHeight(width, height, props.size);
    } else {
      return scaleImageToWidth(width, height, props.size);
    }
  } else if (maxWidth && maxHeight) {
    return getScaledDimensions(width, height, maxWidth, maxHeight, props.sizingMode === 'stretch');
  } else {
    return {};
  }
}
function getScaledDimensions(imageWidth: number, imageHeight: number, maxWidth: number, maxHeight: number, scaleUp: boolean): {
  width: number;
  height: number;
} {
  let scaleWidth = maxWidth / imageWidth;
  let scaleHeight = maxHeight / imageHeight;
  if (!scaleUp) {
    scaleWidth = Math.min(scaleWidth, 1);
    scaleHeight = Math.min(scaleHeight, 1);
  }
  return {
    width: imageWidth * Math.min(scaleWidth, scaleHeight),
    height: imageHeight * Math.min(scaleWidth, scaleHeight)
  };
}
function scaleImageToWidth(imageWidth: number, imageHeight: number, maxWidth: number): {
  width: number;
  height: number;
} {
  const scale = Math.min(maxWidth / imageWidth, 1);
  return {
    width: imageWidth * scale,
    height: imageHeight * scale
  };
}
function scaleImageToHeight(imageWidth: number, imageHeight: number, maxHeight: number): {
  width: number;
  height: number;
} {
  const scale = Math.min(maxHeight / imageHeight, 1);
  return {
    width: imageWidth * scale,
    height: imageHeight * scale
  };
}
type Size = {
  width: number;
  height: number;
};
const _c0 = " Knu-FlexCol position-relative maxHeight-100-percent ";
const _c1 = " Knu-FlexCol ";
const _c2 = " textAlign-center mt-base ";