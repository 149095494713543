// This rexports the API of mobx while enforcing giving reactions a name.

// tslint:disable: import-blacklist
import * as mobx from 'mobx';
import { getOrActivateMobxConsoleLogger } from './logger';

export {
	computed,
	observable,
	action,
	ObservableMap,
	ObservableSet,
} from 'mobx';

interface Options {
	name: string;
}

/**
 * Creates a named reactive view and keeps it alive, so that the view is always
 * updated if one of the dependencies changes, even when the view is not further used by something else.
 * @param view The reactive view
 * @returns disposer function, which can be used to stop the view from being updated in the future.
 */
export function autorun(
	options: Options & mobx.IAutorunOptions,
	view: (r: mobx.IReactionPublic) => void
): mobx.IReactionDisposer {
	return mobx.autorun(view, options);
}

/**
 * Like autorun, but only performs `action` if `test` returns a non-falsy value.
 */
export function autorunWhen<T>(
	options: Options & mobx.IAutorunOptions,
	test: (r: mobx.IReactionPublic) => T | null | undefined | false | 0,
	action: (arg: T, r: mobx.IReactionPublic) => void
): mobx.IReactionDisposer {
	return mobx.autorun(r => {
		const result = test(r);
		if (!result) {
			getOrActivateMobxConsoleLogger().log('Condition not met');
		} else {
			getOrActivateMobxConsoleLogger().log('Condition met');
			action(result, r);
		}
	}, options);
}

/**
 * Creates a named reactive view and keeps it alive, so that the view is always
 * updated if one of the dependencies changes, even when the view is not further used by something else.
 * @param view The reactive view
 * @returns disposer function, which can be used to stop the view from being updated in the future.
 */
export function reaction<T>(
	options: Options & mobx.IReactionOptions,
	expression: (r: mobx.IReactionPublic) => T,
	effect: (arg: T, r: mobx.IReactionPublic) => void
): mobx.IReactionDisposer {
	return mobx.reaction(expression, effect, options);
}

/**
 * Like reaction, but only performs `action` if `test` returns a non-falsy value.
 */
export function reactionWhen<T>(
	options: Options & mobx.IReactionOptions,
	test: (r: mobx.IReactionPublic) => T | null | undefined | false | 0,
	action: (arg: T, r: mobx.IReactionPublic) => void
): mobx.IReactionDisposer {
	return mobx.reaction(
		r => {
			return test(r);
		},
		(result, r) => {
			if (!result) {
				getOrActivateMobxConsoleLogger().log('Condition not met');
			} else {
				getOrActivateMobxConsoleLogger().log('Condition met');
				action(result, r);
			}
		},
		options
	);
}

/**
 * Like reactionWhen, but performs `action` only once.
 */
export function reactionOnceWhen<T>(
	options: Options & mobx.IReactionOptions,
	test: (r: mobx.IReactionPublic) => T | null | undefined | false | 0,
	action: (arg: T, r: mobx.IReactionPublic) => void
): mobx.IReactionDisposer {
	let hasReacted = false;

	return reactionWhen(
		options,
		r => !hasReacted && test(r),
		(arg, r) => {
			hasReacted = true;
			action(arg, r);
		}
	);
}

export function runInAction(name: string, block: () => void): void {
	mobx.runInAction(name, block);
}

/**
 * Returns a promise that wait for a given state.
 */
export function when<T, TResolve>(
	options: Options,
	selector: () => T,
	args: {
		resolveTest: (val: T) => undefined | false | null | TResolve;
		rejectTest?: (val: T) => undefined | false | null | unknown;
	}
): Promise<TResolve> {
	return new Promise<TResolve>((res, rej) => {
		{
			// don't use autorun here to make logs more readable
			const val = selector();
			const result = args.resolveTest(val);
			const rejResult = args.rejectTest
				? args.rejectTest(val)
				: undefined;

			if (result !== false && result !== undefined && result !== null) {
				res(result);
				return;
			} else if (rejResult) {
				rej(rejResult);
				return;
			}
		}

		reaction(
			options,
			() => {
				const val = selector();
				const result = args.resolveTest(val);
				const rejResult = args.rejectTest
					? args.rejectTest(val)
					: undefined;
				return { result, rejResult };
			},
			({ result, rejResult }, reactionPub) => {
				if (
					result !== false &&
					result !== undefined &&
					result !== null
				) {
					reactionPub.dispose();
					res(result);
				} else if (rejResult) {
					reactionPub.dispose();
					rej(rejResult);
				}
			}
		);
	});
}
