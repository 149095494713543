import { Spacer, Z_INDEX } from '@shared/components';
import * as React from 'react';
import { MutableRefObject } from 'react';
import { NavBarHeader } from './NavBarHeader';
import { NavBarItem } from './NavBarItem';
import { navBarWidthExpanded, styles } from './styles';
import { NavBarProfile } from './NavBarProfile';
import { NavBarConfiguration, navBarConfigurations } from './configuration';
import { createAccessiblePointerEventHandler, Flex, FlexCol, IconChevronDown, IconChevronUp, LinearGradient, ScrollView, ThemeOverride, useBgClassName, useEvent, useMeasureElement, resolveThemingValue, useTheme, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { NavComponentModel } from './NavComponentModel';
import { observer } from '@knuddels-app/mobx';
import { navBarWidthCollapsed } from '@shared/constants';
import { CategoryWrapper } from './NavBarCategory';
import { setScrollTop } from '@shared/components/helper/setScrollTop';
import { motion } from 'framer-motion';
import { KnuddelsPlusHeaderBackground } from '@knuddelsModules/UserData';
import { NormalizedNavBarItem } from '@knuddelsModules/LoggedInArea/bundle/services';
interface Props {
  model: NavComponentModel;
}
const DEFAULT_SCROLL_BUTTON_SIZE = 36;
const TOP_GRADIENT_HEIGHT = 24;
export const NavBar: React.FC<Props> = observer('NavBar', props => {
  const shadow = typeof props.model.config.shadow === 'string' ? props.model.config.shadow : props.model.config.shadow(props.model.isCollapsed);
  const style = props.model.config.navLocation === 'bottom' ? styles.NavBarMinimalBottom : props.model.config.extendOptions.canBeExtended ? styles.NavBarExtensionLeft : styles.NavBarMinimalLeft;
  return <div style={{
    ...props.model.config.shadowStyle,
    boxShadow: resolveThemingValue(shadow, "shadows", useTheme()),
    zIndex: resolveThemingValue(Z_INDEX.NAV, "theme", useTheme())
  }} className={_c0}>
			<motion.div className={useBgClassName('naviBg')} transition={{
      type: 'tween',
      duration: 0.2
    }} animate={{
      width: props.model.isCollapsed || props.model.config.navLocation === 'bottom' ? 'auto' : navBarWidthExpanded
    }} style={{
      display: 'flex',
      position: 'relative',
      overflow: 'visible',
      flexDirection: 'column',
      background: 'var(--colors-naviBg)',
      ...(style as any)
    }}>
				{props.model.config.shownItems === 'full' ? <>
						<div className={_c1}>
							<KnuddelsPlusHeaderBackground />

							<NavBarHeader collapsed={props.model.isCollapsed} onToggleCollapsed={props.model.setNavCollapsed} />
							<NavBarProfile onProfilePicClick={props.model.handleProfilePicClick} onNicknameClick={props.model.handleNickNameClick} collapsed={props.model.isCollapsed} />
						</div>

						<NavBarLeft {...props} />
					</> : <BottomItems {...props} />}
			</motion.div>
		</div>;
});
const CategoryGroup: React.FC<{
  items: NormalizedNavBarItem[];
  model: NavComponentModel;
}> = ({
  items,
  model
}) => {
  const cb = useEvent((index: number) => {
    const item = items[index];
    model.handleItemClick(item);
  });
  return <>
			{items.map((item, index) => <React.Fragment key={`navbaritem-${index}`}>
					<Item title={item.title} index={index} url={item.url} icon={item.icon} badge={item.badge} badgeColor={item.badgeColor} active={item.active} config={model.config} collapsed={model.isCollapsed} onItemClick={cb} />
					{index + 1 === items.length && <Spacer size={'tiny'} />}
				</React.Fragment>)}
		</>;
};
const LeftItems: React.FC<Props> = observer('LeftItems', ({
  model
}) => {
  return <>
			{model.categories.map(category => <CategoryWrapper key={`category-${category.id}`} category={category} collapsed={model.isCollapsed}>
					<CategoryGroup items={category.entries} model={model} />
				</CategoryWrapper>)}
		</>;
});
const NavBarAnimation: React.FC<{
  title: string;
  active: boolean;
  shouldPreventAnimation: MutableRefObject<boolean>;
}> = ({
  children,
  ...props
}) => {
  return <motion.div initial={false} transition={{
    type: 'tween',
    duration: 0.5
  }} animate={{
    scale: props.active && !props.shouldPreventAnimation.current ? [1, 1.2, 1] : 1
  }} className={'Knu-Flex flex-1'}>
			{children}
		</motion.div>;
};
const BottomItems: React.FC<Props> = observer('BottomItems', ({
  model
}) => {
  const shouldPreventAnimationRef = React.useRef(false);
  const cb = useEvent((index: number) => {
    shouldPreventAnimationRef.current = true;
    const item = model.bottomItems[index];
    model.handleItemClick(item);
  });
  return <>
			{model.bottomItems.map((item, index) => {
      return <React.Fragment key={`navbaritem-${index}`}>
						<NavBarAnimation shouldPreventAnimation={shouldPreventAnimationRef} active={item.active} title={item.title.toString()}>
							<Item title={item.title} index={index} url={item.url} icon={item.icon} badge={item.badge} badgeColor={item.badgeColor} active={item.active} config={navBarConfigurations.minimalBottom} collapsed={model.isCollapsed} onItemClick={cb} />
						</NavBarAnimation>
						{index + 1 === model.bottomItems.length && <Spacer size={'tiny'} />}
					</React.Fragment>;
    })}
		</>;
});
const Item = React.memo(({
  config,
  collapsed,
  onItemClick,
  ...props
}: {
  active?: boolean;
  index: number;
  badge?: string;
  badgeColor?: string;
  title: string | JSX.Element;
  url: string;
  icon: string | React.ComponentType<any>;
  config: NavBarConfiguration;
  collapsed: boolean;
  onItemClick: (index: number) => void;
}) => {
  return <div className={_c2 + (config.navLocation === 'bottom' ? _c3 : _c4)}>
				<NavBarItem active={props.active} badge={props.badge} badgeColor={props.badgeColor} collapsed={collapsed} onClick={() => onItemClick(props.index)} title={props.title} url={props.url} type={config.shownItems === 'minimal' ? 'minimalCentered' : 'extendedLeftAligned'} hideTitle={config.hideItemTitle} icon={props.icon} />
			</div>;
});
Item.displayName = 'Item';
const NavBarLeft: React.FC<Props> = observer('NavBarLeft', props => {
  const {
    height: scrollHeight,
    bind: bindNavContent
  } = useMeasureElement();
  const {
    height: offsetHeight,
    bind: bindScrollView
  } = useMeasureElement();
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [hideScrollButton, setHideScrollButton] = React.useState<boolean>(true);
  const [hideGradientTop, setHideGradientTop] = React.useState<boolean>(true);
  const [isScrolledMoreThenHalf, setIsScrolledMoreThenHalf] = React.useState<boolean>(false);
  const [scrollOverflow, setScrollOverflow] = React.useState<number>(0);
  React.useLayoutEffect(() => {
    setScrollOverflow(scrollHeight + navBarWidthCollapsed - offsetHeight);
  }, [scrollHeight, offsetHeight]);
  React.useLayoutEffect(() => {
    setIsScrolledMoreThenHalf(scrollY > scrollOverflow / 2);
    setHideGradientTop(scrollY < TOP_GRADIENT_HEIGHT || scrollOverflow <= 0);
  }, [scrollY, scrollOverflow]);
  React.useLayoutEffect(() => {
    setHideScrollButton(!props.model.isCollapsed || scrollOverflow <= 0);
  }, [props.model.isCollapsed, scrollOverflow]);
  const onScrollButtonPress = () => {
    const scrollView = bindScrollView.innerRef.current;
    if (scrollView) {
      setScrollTop(scrollView, isScrolledMoreThenHalf ? 0 : scrollOverflow);
    }
  };
  return <>
			<ScrollView flex={1} flexBasis={0} pb={hideScrollButton ? undefined : (navBarWidthCollapsed as ThemeOverride)} onScroll={e => setScrollY(e.currentTarget.scrollTop)} touchAction="pan-y" hideScrollIndicator {...bindScrollView}>
				<FlexCol {...bindNavContent}>
					<GradientContainer align={'top'} height={TOP_GRADIENT_HEIGHT} hide={hideGradientTop} />
					<Spacer size={'minor'} />
					<LeftItems {...props} />
					<Spacer size={'minor'} />
				</FlexCol>
			</ScrollView>
			<GradientContainer align={'bottom'} hide={hideScrollButton}>
				<ScrollButton onPress={onScrollButtonPress} icon={isScrolledMoreThenHalf ? <IconChevronUp size={'large'} /> : <IconChevronDown size={'large'} />} />
			</GradientContainer>
		</>;
});
const ScrollButton: React.FC<{
  icon: JSX.Element;
  onPress: () => void;
  size?: number;
}> = ({
  icon,
  onPress,
  size = DEFAULT_SCROLL_BUTTON_SIZE
}) => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} style={{
    height: resolveThemingValue(size, "sizes", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c5 + ("white-solid-120" ? resolveIsDarkColor("white-solid-120", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{icon}
		</div>;
};
const GradientContainer: React.FC<{
  align: 'top' | 'bottom';
  height?: number;
  children?: React.ReactNode;
  hide?: boolean;
}> = ({
  align,
  height = navBarWidthCollapsed,
  children,
  hide = false
}) => {
  if (hide) {
    return null;
  }
  const bottom = align === 'bottom' ? 'none' : undefined;
  const colors: React.ComponentProps<typeof LinearGradient>['colors'] = align === 'bottom' ? [{
    color: 'naviBg',
    stop: 0.73
  }, {
    color: 'transparent',
    stop: 1
  }] : ['transparent', 'naviBg'];
  return <LinearGradient width={'full'} height={height} placeItems={'center'} position={'absolute'} bottom={bottom} colors={colors} zIndex={Z_INDEX.OVERCONTENT}>
			{children}
		</LinearGradient>;
  // tslint:disable-next-line: max-file-line-count
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-FlexCol position-relative overflow-hidden ";
const _c2 = " Knu-Flex flex-1 justifyContent-center position-relative overflow-hidden ";
const _c3 = " flexDirection-row ";
const _c4 = " flexDirection-column ";
const _c5 = " Knu-Flex cursor-pointer shadow-Shadow2 placeItems-center position-relative bg-white-solid-120 borderRadius-circle ";