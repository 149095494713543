import { Endpoint } from './Endpoint';
import { $Environment } from '@knuddels-app/Environment';
import { UnreachableError } from '@knuddels/std';
import { KnownEndpoints, idToKnownEndpointId } from './KnownEndpoints';
import { injectable, inject } from '@knuddels-app/DependencyInjection';
import { $AllowedServerAccessStore } from '../serviceIds';
import { removeDuplicates } from '@knuddels-app/tools/removeDuplicates';

@injectable()
export class EndpointProvider {
	constructor(
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject($AllowedServerAccessStore)
		private readonly allowedServerAccessStore: typeof $AllowedServerAccessStore.T
	) {}

	public getDefaultEndpoint(): Endpoint {
		const eps = this.getEndpoints();
		if (eps.length === 0) {
			throw new Error('No endpoints available. This indicates a bug.');
		}

		return eps[0];
	}

	public getEndpoints(): Endpoint[] {
		const ids = this.getKnownEndpointIds();
		const known = this.getKnownEndpoints();
		return ids.map(id => known[id]);
	}

	protected getKnownEndpoints(): typeof KnownEndpoints {
		return KnownEndpoints;
	}

	private getKnownEndpointIds(): (keyof typeof KnownEndpoints)[] {
		if (this.environment.nativeInterface) {
			const servers =
				this.environment.nativeInterface.getAllowedServers();
			const defaultEndpoint =
				this.environment.nativeInterface.getActiveServer();

			// make sure that `defaultEndpoint` is at the very beginning of `servers`
			// and only included once.

			const filteredServerIds = servers.filter(
				serverId => serverId !== defaultEndpoint
			);

			return removeDuplicates([
				defaultEndpoint,
				...filteredServerIds,
				...this.allowedServerAccessStore.speciallyAllowedServers,
			]);
		} else if (this.environment.messengerSystemAppInterface) {
			// has to be before checking browser interface because it also runs in a browser
			const serverKey = this.environment.messengerSystemAppInterface
				.client.pageData.serverKey as any;
			return [serverKey];
		} else if (this.environment.browserInterface) {
			const location =
				this.environment.browserInterface.getBrowserLocation();

			if (globalEnv.isReleaseExperiment) {
				return ['DE'];
			} else if (location.host.indexOf('devapp') === 0) {
				return ['Dev'];
			}

			// use parameter (e.g. ?server=Test) to connect to any servers
			const server = location.getParameterByName('server');
			if (server) {
				return [idToKnownEndpointId(server)];
			}

			// dev/preview client domains
			if (location.host.indexOf('local') === 0) {
				return ['Local'];
			} else if (location.host.indexOf('preview') === 0) {
				return ['DE'];
			} else if (location.host.indexOf('devpreview') === 0) {
				return ['Test'];
			}

			// default
			return ['DE'];
		} else {
			throw new UnreachableError();
		}
	}
}
