import {
	ApolloError,
	FetchResult,
	QueryHookOptions,
	QueryResult,
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
	useSubscription as useApolloSubscription,
	WatchQueryFetchPolicy,
} from '@apollo/client';
import {
	TypedMutationOperation,
	TypedOperation,
	TypedQueryOperation,
	TypedSubscriptionOperation,
} from './Operation';
import { select } from '@knuddels/std';

type OperationError =
	| { kind: 'NetworkError'; error: unknown }
	| { kind: 'GraphQlErrors'; errors: FetchResult['errors'] };

export interface OperationResult<TPrimaryResult> {
	data: TPrimaryResult | undefined;
	error?: OperationError;
	loading: boolean;
}

export interface MutationResult<TPrimaryResult> {
	data: TPrimaryResult | undefined;
	error?: OperationError;
}

export const useQuery = <TVars, TResult, TPrimaryResult>(
	operation: TypedQueryOperation<TVars, TResult, TPrimaryResult>,
	variables: TVars,
	fetchPolicy?: WatchQueryFetchPolicy,
	options?: QueryHookOptions<TResult, TVars>
): OperationResult<TPrimaryResult> & {
	refetch: () => void;
	fetchMore: QueryResult<TResult, TVars>['fetchMore'];
} => {
	const result = useApolloQuery(operation.document, {
		...options,
		variables,
		fetchPolicy,
	} as any);

	return {
		...result,
		data: toPrimaryResult(operation, result.data),
		error: result.error && toOperationError(result.error),
		loading: result.loading,
		fetchMore: result.fetchMore,
	};
};

export const useMutation = <TVars, TResult, TPrimaryResult>(
	operation: TypedMutationOperation<TVars, TResult, TPrimaryResult>
): [
	mutate: (variables: TVars) => Promise<MutationResult<TPrimaryResult>>,
	result: OperationResult<TPrimaryResult>
] => {
	const [apolloMutate, result] = useApolloMutation(operation.document);

	const primaryResult = toPrimaryResult(operation, result.data);

	const mutate = async (
		variables: TVars
	): Promise<MutationResult<TPrimaryResult>> => {
		try {
			const r = await apolloMutate({ variables });
			return {
				data: toPrimaryResult(operation, r.data),
				error: undefined,
			};
		} catch (e) {
			return {
				data: undefined,
				error: { kind: 'NetworkError', error: e },
			};
		}
	};

	return [
		mutate,
		{
			data: primaryResult,
			error: result.error && toOperationError(result.error),
			loading: result.loading,
		},
	];
};

export const useSubscription = <TVars, TResult, TPrimaryResult>(
	operation: TypedSubscriptionOperation<TVars, TResult, TPrimaryResult>,
	variables: TVars,
	onSubscriptionData: (data: TPrimaryResult) => void
): void => {
	useApolloSubscription<TResult, TVars>(operation.document, {
		variables,
		onSubscriptionData: data => {
			const primaryData = toPrimaryResult(
				operation,
				data.subscriptionData.data
			);
			if (primaryData) {
				onSubscriptionData(primaryData);
			}
		},
	});
};

function toPrimaryResult<TResult, TPrimaryResult>(
	operation: TypedOperation<any, TResult, TPrimaryResult>,
	result: TResult | undefined
): TPrimaryResult | undefined {
	return result
		? (select(result, operation.primaryResultPath) as TPrimaryResult)
		: undefined;
}

function toOperationError(apolloError: ApolloError): OperationError {
	if (apolloError.networkError) {
		return { kind: 'NetworkError', error: apolloError.networkError };
	} else {
		return { kind: 'NetworkError', error: apolloError.clientErrors[0] };
	}
}
