import { PrivateMessageReplyBehavior, User } from '@generated/graphql';
import { $CommandService } from '@knuddels-app/Commands';
import { useService } from '@knuddels-app/DependencyInjection';
import { messageEvent } from '@knuddelsModules/Channel/analytics';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
export const useHandleAnswerButton = (answerTo: readonly Pick<User, 'id' | 'nick'>[] = []) => {
  const miniChatService = useService($MessengerMiniChatService);
  const clientSettingsService = useService($ClientSettingsService);
  const commandService = useService($CommandService);
  messageEvent.track('Message_AnswerButtonClicked');
  return () => {
    if (answerTo.length > 1) {
      const nicks = answerTo.map(it => it.nick).join(',');
      commandService.parseAndInvokeCommand(`/tf-override /p ${nicks}:`);
      return;
    }
    const user = answerTo[0];
    if (clientSettingsService.privateMessageReplyBehavior === PrivateMessageReplyBehavior.SlashCommand) {
      commandService.parseAndInvokeCommand(`/tf-override-nodes /p |@${user.nick}|:`);
    } else {
      miniChatService.openConversationByUserId(user.id);
    }
  };
};