import * as React from 'react';
import { formatMessage } from '@knuddels-app/i18n';
import { Button, ChildThemeProvider, DarkTheme, Flex, FlexCol, Image, rgb, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { conversationImagesEvent } from '../../../../analytics';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerConversationService, $MessengerImageService } from '../../../../../providedServices';
import { BlurredBackgroundImage } from '@shared/components';
import { isNative } from '@knuddels-app/tools/isNative';
import { defaultBlurredImage } from './defaultBlurredImage';
import { SENSITIVE_CONTENT_EROTIC_IMAGE_TEXT, SENSITIVE_CONTENT_EROTIC_SNAP_TEXT, SENSITIVE_CONTENT_IMAGE_BUTTON_TEXT, SENSITIVE_CONTENT_SNAP_BUTTON_TEXT, SENSITIVE_CONTENT_TITLE, SENSITIVE_CONTENT_VIOLENCE_IMAGE_TEXT, SENSITIVE_CONTENT_VIOLENCE_SNAP_TEXT } from '../../../../i18n/shared-formats';
import { SensitiveContentClassification, UserSentImage, UserSentSnap } from '@generated/graphql';
import { useConversationId } from '../../ConversationBody/ConversationIdContext';
import icon from '../../../../assets/sensitive_content.png';
type Props = {
  size: number;
  image: UserSentImage | UserSentSnap;
  messageId: string;
  sensitiveContentClassification: SensitiveContentClassification;
};
export const ImageOrSnapWithSensitiveContent: React.FC<Props> = ({
  size,
  image,
  messageId,
  sensitiveContentClassification
}) => {
  const messengerImageService = useService($MessengerImageService);
  const messengerConversationService = useService($MessengerConversationService);
  const conversationId = useConversationId();
  const isSnap = image?.__typename === 'UserSentSnap';
  const {
    notificationText,
    buttonText,
    trackShowSensitiveContent
  } = useSensitiveContent(sensitiveContentClassification, isSnap);
  const showSensitiveContent = async (): Promise<void> => {
    trackShowSensitiveContent();
    messengerImageService.showSensitiveContent(messageId);

    // TODO: This is a quick fix to make sure the imageAccepted is loaded after call the mutation.
    setTimeout(() => {
      messengerConversationService.getFullConversation(conversationId);
    }, 200);
  };
  return <div style={{
    background: resolveThemingValue(rgb(75, 75, 75), "colors", useTheme()),
    height: resolveThemingValue(size, "sizes", useTheme()),
    maxWidth: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c0 + (rgb(75, 75, 75) ? resolveIsDarkColor(rgb(75, 75, 75), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<BlurredBackgroundImage source={isSnap ? defaultBlurredImage : image?.url || defaultBlurredImage} blurRadius={isNative() ? 100 : 40} />
			<div className={_c1 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />

			<div style={{
      width: resolveThemingValue(size, "sizes", useTheme())
    }} className={_c2}>
				<Image src={icon} alt={''} resizeMode={'contain'} className={_c3} />

				<Text state={'primary'} type={'subtitle'} bold={true} className={_c4}>
					{formatMessage(SENSITIVE_CONTENT_TITLE)}
				</Text>
				<Text state={'secondary'} type={'body2'} bold={true} className={_c5}>
					{notificationText}
				</Text>
				<div className={_c6}>
					<ChildThemeProvider theme={DarkTheme}>
						<Button type={'secondary'} onPress={showSensitiveContent}>
							{buttonText}
						</Button>
					</ChildThemeProvider>
				</div>
			</div>
		</div>;
};
const useSensitiveContent = (sensitiveContent: SensitiveContentClassification, isSnap: boolean) => {
  // TODO: Quick fix for snacks use default text or undefined
  const [notificationText, setNotificationText] = React.useState<string>(formatMessage(SENSITIVE_CONTENT_EROTIC_SNAP_TEXT));
  const buttonText = React.useMemo(() => formatMessage(isSnap ? SENSITIVE_CONTENT_SNAP_BUTTON_TEXT : SENSITIVE_CONTENT_IMAGE_BUTTON_TEXT), [isSnap]);
  const [trackShowSensitiveContent, setTrackShowSensitiveContent] = React.useState<() => void>(() => () => {});
  React.useLayoutEffect(() => {
    switch ((sensitiveContent as SensitiveContentClassification)) {
      case SensitiveContentClassification.Adult:
      case SensitiveContentClassification.Medical:
      case SensitiveContentClassification.Racy:
        conversationImagesEvent.track(isSnap ? 'Images_SnapEroticWarningShown' : 'Images_ImageEroticWarningShown');
        setTrackShowSensitiveContent(() => () => conversationImagesEvent.track(isSnap ? 'Images_SnapEroticWarningContinue' : 'Images_ImageEroticWarningContinue'));
        setNotificationText(formatMessage(isSnap ? SENSITIVE_CONTENT_EROTIC_SNAP_TEXT : SENSITIVE_CONTENT_EROTIC_IMAGE_TEXT));
        break;
      case SensitiveContentClassification.Violence:
        conversationImagesEvent.track(isSnap ? 'Images_SnapViolenceWarningShown' : 'Images_ImageViolenceWarningShown');
        setTrackShowSensitiveContent(() => () => conversationImagesEvent.track(isSnap ? 'Images_SnapViolenceWarningContinue' : 'Images_ImageViolenceWarningContinue'));
        setNotificationText(formatMessage(isSnap ? SENSITIVE_CONTENT_VIOLENCE_SNAP_TEXT : SENSITIVE_CONTENT_VIOLENCE_IMAGE_TEXT));
        break;
      default:
        break;
    }
  }, [sensitiveContent, isSnap]);
  return {
    notificationText,
    buttonText,
    trackShowSensitiveContent
  };
};
const _c0 = " Knu-FlexCol cursor-pointer alignItems-center overflow-hidden position-relative ";
const _c1 = " Knu-FlexCol bg-backgroundScrim position-absolute inset-none opacity-0-66 ";
const _c2 = " Knu-FlexCol p-30px zIndex-1 height-100-percent alignItems-center justifyContent-flex-end ";
const _c3 = " width-72px height-40px ";
const _c4 = " mt-minor textAlign-center ";
const _c5 = " mt-small textAlign-center ";
const _c6 = " Knu-Flex mt-xlarge ";