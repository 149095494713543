import { Flex, Z_INDEX, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useDrag } from '@use-gesture/react';
import { motion, useAnimate } from 'framer-motion';
import * as React from 'react';
import { MobilePhotoCommentsWrapper } from './MobilePhotoCommentsWrapper';
const DRAWER_HEIGHT = 280;
export const MobileCommentsBottomDrawer: React.FC<{
  initialState: 'hidden' | 'extended';
  onSwipeDown: () => void;
}> = ({
  initialState,
  onSwipeDown
}) => {
  const [scope, animate] = useAnimate();
  const bind = useDrag(({
    last,
    movement: [, y]
  }) => {
    if (last) {
      if (y > DRAWER_HEIGHT * 0.3) {
        animate(scope.current, {
          height: 0
        }).then(() => {
          onSwipeDown();
        });
      } else {
        animate(scope.current, {
          height: DRAWER_HEIGHT
        });
      }
    } else {
      animate(scope.current, {
        height: Math.min(DRAWER_HEIGHT - y, DRAWER_HEIGHT)
      }, {
        duration: 0
      });
    }
  }, {
    enabled: true,
    axis: 'y'
  });
  return <motion.div ref={scope} initial={{
    height: initialState === 'extended' ? DRAWER_HEIGHT : 0
  }} animate={{
    height: DRAWER_HEIGHT
  }} style={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  }}>
			<div style={{
      minHeight: resolveThemingValue(`${DRAWER_HEIGHT}px`, "sizes", useTheme())
    }} className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div {...bind()} style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: '48px',
        height: '32px',
        zIndex: Z_INDEX.ABOVE_MODAL,
        touchAction: 'none'
      }} />

				<MobilePhotoCommentsWrapper onClose={() => {
        animate(scope.current, {
          height: 0
        }).then(() => {
          onSwipeDown();
        });
      }} />
			</div>
		</motion.div>;
};
const _c0 = " Knu-Flex bg-contentLightBg borderTopRadius-large ";