import * as React from 'react';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Flex, Text } from '@knuddels/component-library';
import { InfoCardItem } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { IconMp } from '@shared/icons/IconMp';
export const useMentorSystemPoints = (): InfoCardItem[] => {
  const i18n = useService($I18n);
  return [{
    highlightContent: <MentorPointsText points={5} />,
    textContent: <Text state={'secondary'} className={_c0}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.mentor',
        defaultFormat: 'When you become an official mentor'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <>
					<Text type={'tiny'} state={'tertiary'} className={_c1}>
						{declareFormat({
          id: 'mentorsystem.rewards.points.upto',
          defaultFormat: 'up to'
        }).format(i18n)}
					</Text>
					<MentorPointsText points={35} />
				</>,
    textContent: <Text state={'secondary'} className={_c2}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.mentee-chat',
        defaultFormat: 'When you text with your mentee'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <MentorPointsText points={5} />,
    textContent: <Text state={'secondary'} className={_c3}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.mentee-chat-ping-pong',
        defaultFormat: 'When you and your mentee each exchanged 25 messages'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <MentorPointsText points={2} />,
    textContent: <Text state={'secondary'} className={_c4}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.friend-request',
        defaultFormat: 'When you accept your friend requests'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <MentorPointsText points={5} />,
    textContent: <Text state={'secondary'} className={_c5}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.profile-photo',
        defaultFormat: 'When your mentee uploads a profile photo'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <MentorPointsText points={2} />,
    textContent: <Text state={'secondary'} className={_c6}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.profile-change',
        defaultFormat: 'When your mentee enters something in his profile'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <MentorPointsText points={5} />,
    textContent: <Text state={'secondary'} className={_c7}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.profile-full',
        defaultFormat: 'When your mentee has completed their profile'
      }).format(i18n)}
				</Text>
  }, {
    highlightContent: <MentorPointsText points={20} />,
    textContent: <Text state={'secondary'} className={_c8}>
					{declareFormat({
        id: 'mentorsystem.rewards.points.family-member',
        defaultFormat: 'When your mentee becomes a family member'
      }).format(i18n)}
				</Text>
  }];
};
const MentorPointsText: React.FC<{
  points: number;
}> = ({
  points
}) => {
  return <div className={_c9}>
			<Text state={'primary'} bold={true} className={_c10}>
				{points}
			</Text>
			<IconMp />
		</div>;
};
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = "  ";
const _c6 = "  ";
const _c7 = "  ";
const _c8 = "  ";
const _c9 = " Knu-Flex alignItems-center ";
const _c10 = " mr-tiny ";