import * as React from 'react';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { MessageFadeInView } from './MessageFadeInView';
import { Message } from './Message';
import { ConversationMessageContent } from '../../Messages/ConversationMessageContent';
import { SendingMessage } from '../../../../services/conversationServices/SendingMessage';
import { useTheme } from '@knuddels/component-library';
export const MessengerSendingMessage = injectedComponent({
  name: 'MessengerSendingMessage',
  props: declareProps<{
    receiverId: string;
    message: SendingMessage;
    isStackedLayout: boolean;
    hasArrow?: boolean;
    animated?: boolean;
    showLoadingIndicator?: boolean;
  }>()
}, ({
  receiverId,
  message,
  isStackedLayout,
  hasArrow,
  animated,
  showLoadingIndicator
}) => {
  const theme = useTheme();
  return <MessageFadeInView animated={animated}>
				<Message uid={message.sender.id} isStackedLayout={isStackedLayout} align={'right'} bubbleColor={theme.colors.Messenger.outgoingMessage} shadow={false} showImage={false} additionalMargin={0} hasArrow={hasArrow} showLoadingIndicator={showLoadingIndicator}>
					<ConversationMessageContent message={message} forceShowImages receiverId={receiverId} sender={message.sender} sentByActiveUser />
				</Message>
			</MessageFadeInView>;
});