import * as React from 'react';
import { BoxProps, PointerEvent, toPointerEvent } from '@knuddels/component-library';
import { useDragProps } from './useDragProps';
const THRESHOLD = 10;
const isDraggingIntentional = (offset: {
  x: number;
  y: number;
}, current: {
  x: number;
  y: number;
}, direction?: 'vertical' | 'horizontal' | 'all') => {
  if (direction === 'vertical') {
    return Math.abs(current.y - offset.y) > THRESHOLD;
  } else if (direction === 'horizontal') {
    return Math.abs(current.x - offset.x) > THRESHOLD;
  }
  return Math.abs(current.x - offset.x) > THRESHOLD || Math.abs(current.y - offset.y) > THRESHOLD;
};
export const useDrag = (props: useDragProps): Pick<BoxProps, 'onPointerDown' | 'onPointerMove' | 'onPointerUp' | 'touchAction'> => {
  const startPosAbsoluteRef = React.useRef({
    x: 0,
    y: 0
  });
  const startPosRelativeRef = React.useRef({
    x: 0,
    y: 0
  });
  const styleTagRef = React.useRef<HTMLStyleElement | null>(null);
  const isDragging = React.useRef(false);
  const blockChildClickEvents = React.useRef(false);
  const isPointerDown = React.useRef(false);
  const elementRef = React.useRef<HTMLElement>();
  const shouldCaptureEvents = props.canStartDrag;
  const getGestureState = (e: PointerEvent) => {
    return {
      sx: startPosAbsoluteRef.current.x,
      sy: startPosAbsoluteRef.current.y,
      sxRel: startPosRelativeRef.current.x,
      syRel: startPosRelativeRef.current.y,
      dx: e.pageX - startPosAbsoluteRef.current.x,
      dy: e.pageY - startPosAbsoluteRef.current.y
    };
  };
  const stopCapturePropagationIfNeeded = (e: PointerEvent) => {
    if (shouldCaptureEvents?.(getGestureState(e))) {
      e.stopPropagation();
      return true;
    }
    return false;
  };
  const preventTextSelection = () => {
    styleTagRef.current = document.createElement('style');
    styleTagRef.current.innerHTML = `
					* {
						-webkit-user-select: none !important;
						user-select: none !important;
					}
				`;
    document.body.appendChild(styleTagRef.current);
  };
  const enableTextSelection = () => {
    if (styleTagRef.current) {
      document.body.removeChild(styleTagRef.current);
      styleTagRef.current = null;
    }
  };
  const finishHandler = (isCancel: boolean) => (pointerEvent: PointerEvent) => {
    isPointerDown.current = false;
    if (isDragging.current) {
      isDragging.current = false;
      enableTextSelection();
      props.onFinish?.({
        dx: pointerEvent.pageX - startPosAbsoluteRef.current.x,
        dy: pointerEvent.pageY - startPosAbsoluteRef.current.y,
        isCancel
      });

      // Allow children to handle events again
      pointerEvent.originalEvent.target.releasePointerCapture?.(pointerEvent.pointerId);
      requestAnimationFrame(() => {
        blockChildClickEvents.current = false;
      });
    }
  };
  const downHandler = (pointerEvent: PointerEvent) => {
    isPointerDown.current = true;
    startPosAbsoluteRef.current = {
      x: pointerEvent.pageX,
      y: pointerEvent.pageY
    };
    const rect = elementRef.current?.getBoundingClientRect();
    startPosRelativeRef.current = {
      x: pointerEvent.pageX - (rect?.x || 0),
      y: pointerEvent.pageY - (rect?.y || 0)
    };
  };
  const shouldStartDrag = (pointerEvent: PointerEvent<React.PointerEvent>) => {
    if (isDragging.current) {
      return false;
    }
    if (!isPointerDown.current) {
      return false;
    }
    if (!isDraggingIntentional(startPosAbsoluteRef.current, {
      x: pointerEvent.pageX,
      y: pointerEvent.pageY
    }, props.direction)) {
      return false;
    }

    // Result might be undefined, in which case we allow starting the drag
    if (props.canStartDrag?.(getGestureState(pointerEvent)) === false) {
      return false;
    }
    return true;
  };
  const moveHandler = (pointerEvent: PointerEvent<React.PointerEvent>) => {
    if (shouldStartDrag(pointerEvent)) {
      isDragging.current = true;
      blockChildClickEvents.current = true;
      preventTextSelection();

      // Make sure that only the drag component will receiver further pointer events, so
      // children won't do anything while dragging
      (pointerEvent.originalEvent.nativeEvent.target as HTMLElement).setPointerCapture?.(pointerEvent.pointerId);
      props.onStart?.({
        sx: startPosAbsoluteRef.current.x,
        sy: startPosAbsoluteRef.current.y,
        sxRel: startPosRelativeRef.current.x,
        syRel: startPosRelativeRef.current.y
      });
    }
    if (isDragging.current) {
      props.onMove?.({
        dx: pointerEvent.pageX - startPosAbsoluteRef.current.x,
        dy: pointerEvent.pageY - startPosAbsoluteRef.current.y
      });
    }
  };
  return ({
    onPointerDownCapture: (e: React.PointerEvent) => {
      const pointerEvent = toPointerEvent(e);
      if (stopCapturePropagationIfNeeded(pointerEvent)) {
        downHandler(pointerEvent);
      }
    },
    onPointerMoveCapture: (e: React.PointerEvent) => {
      const pointerEvent = toPointerEvent(e);
      if (stopCapturePropagationIfNeeded(pointerEvent)) {
        moveHandler(pointerEvent);
      }
    },
    // These two handlers are only called if the above allow propagation
    onPointerDown: downHandler,
    onPointerMove: moveHandler,
    onClickCapture: (e: React.MouseEvent) => {
      if (blockChildClickEvents.current) {
        e.stopPropagation();
      }
    },
    onPointerUp: finishHandler(false),
    onPointerCancel: finishHandler(true),
    touchAction: props.direction === 'horizontal' ? 'pan-y' : props.direction === 'vertical' ? 'pan-x' : 'none',
    innerRef: elementRef
  } as any); // we use any because we don't want to expose capture events to the public Box API;
};