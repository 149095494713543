import * as providedServices from './providedServices';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { userDataModule } from '@knuddelsModules/UserData/module';

export const profileVisitorsModule = new Module({
	name: 'Profile Visitors',
	dependencies: [userDataModule],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
