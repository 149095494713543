import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { MessageFormatFormatterProvider } from './facade';
import { $Environment } from '@knuddels-app/Environment';
import { LocaleId } from '@knuddels/std';
import { $I18n, $MessageFormatProvider } from './services';
import { I18nServiceImpl } from './I18nServiceImpl';

import de from './formats.de.json';
import en from './formats.en.json';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope.bind($MessageFormatProvider).toDynamicSingleton({}, () => {
		const p = new MessageFormatFormatterProvider();
		p.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
		return p;
	});

	ctx.globalScope
		.bind($I18n)
		.toDynamicSingleton(
			{ $Environment, $MessageFormatProvider },
			deps =>
				new I18nServiceImpl(
					deps.$Environment.defaultLocale,
					LocaleId.enUS,
					deps.$MessageFormatProvider
				)
		);
}
