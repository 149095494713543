import * as e from "react";
const i = (o) => {
  const t = e.useRef();
  return e.useEffect(() => {
    setTimeout(() => {
      t.current && (t.current.scrollTop = o.optionIndex * o.optionHeight - o.optionHeight * (o.visibleOptions - 1));
    });
  }, [o.optionIndex]), t;
};
export {
  i as useAutoScrollToOption
};
