import { isBrowser as o } from "../is-browser.js";
import { hasReducedMotionListener as i, prefersReducedMotion as t } from "./state.js";
function c() {
  if (i.current = !0, !!o)
    if (window.matchMedia) {
      const e = window.matchMedia("(prefers-reduced-motion)"), r = () => t.current = e.matches;
      e.addListener(r), r();
    } else
      t.current = !1;
}
export {
  c as initPrefersReducedMotion
};
