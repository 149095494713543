import { useQuery } from '@knuddels-app/Connection';
import { CanSendImages } from '@generated/graphql';

export const useShowImages = (
	force: boolean,
	sentByActiveUser: boolean,
	senderId: string
) => {
	const { data } = useQuery(
		CanSendImages,
		{ userId: senderId },
		'cache-only',
		{ skip: force || sentByActiveUser }
	);

	return force || sentByActiveUser || !!data?.canSendImages;
};
