import * as React from 'react';
import { Column, containerStyles, FormattedText, Row, Spacer } from '@shared/components';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { ChannelMessageFragment } from '@generated/graphql';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { ChannelHighlightedLinkColorModel } from './ChannelHighlightedLinkColorModel';
import { TextProps } from '@knuddels/component-library';
import { CHANNEL_MESSAGE_COMMAND_CONTEXT } from '@shared/constants';
export type ActionMessageFragment = Extract<ChannelMessageFragment, {
  readonly __typename?: 'ChannelMsgAction';
}>;
export interface ActionMessageProps {
  message: ActionMessageFragment;
}
export const ActionMessage = injectedComponent({
  name: 'Actionmessage',
  props: declareProps<ActionMessageProps>(),
  model: ChannelHighlightedLinkColorModel
}, ({
  model,
  message
}): JSX.Element => {
  return <Row fluid {...containerStyles.alignItemsCenter}>
				<Spacer size={'small'} />
				<Column fluid>
					<FormattedTextDisplayWithCommandHandling text={FormattedText.fromJsonString(message.formattedText)} textProps={textProps} getHighlightedLinkColor={model.getHighlightedLinkColor} forceBoldLinks commandContext={CHANNEL_MESSAGE_COMMAND_CONTEXT} />
				</Column>
				<Spacer size={'small'} />
			</Row>;
});
const textProps: TextProps = {
  type: 'body1',
  selectable: true,
  italic: true,
  state: 'secondary'
};