import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Flex, FlexCol, Text } from '@knuddels/component-library';
import { ProgressDisplay } from './shared/ProgressDisplay';
import { MentorImage } from './shared/MentorImage';
import { MentorStatusActiveFragment } from '@generated/graphql';
import { Knuddel } from '@shared/components';
export const MentorConversationStartedProgressBar: React.FC<{
  activeStatus: MentorStatusActiveFragment;
  onProgressChangeAnimated?: () => void;
}> = observer('MentorConversationStartedProgressBar', props => {
  const i18n = useService($I18n);
  const status = props.activeStatus;
  return <div className={_c0}>
			<div className={_c1}>
				<MentorImage imageWidth={24} imageHeight={21} bgSize={36} bgColor={'blue-100'} />
			</div>
			<div className={_c2}>
				<ProgressDisplay value={status.conversationStartedCount} maxValue={status.conversationStartedBoundary} onProgressChangeAnimated={props.onProgressChangeAnimated} colorType={'blue'} numericValueDisplay={'on-top'} numericValueSuffixText={i18n.format(declareFormat({
        id: 'mentorsystem.mentor-status.conversations-started',
        defaultFormat: 'conversations started'
      }))} />
			</div>
			<Text type={'body2'} className={_c3}>
				+1
			</Text>
			<Knuddel />
		</div>;
});
const _c0 = " Knu-Flex flex-1 placeItems-center ";
const _c1 = " Knu-FlexCol zIndex-1 width-29px overflow-visible ";
const _c2 = " Knu-FlexCol flexGrow-1 ";
const _c3 = " ml-small mr-tiny ";