import * as React from 'react';
import { Column, FormattedText } from '@shared/components';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { ChannelHighlightedLinkColorModel } from './ChannelHighlightedLinkColorModel';
import { Text, ThemeColors } from '@knuddels/component-library';
import { CHANNEL_MESSAGE_COMMAND_CONTEXT } from '@shared/constants';
export interface GenericMessageProps {
  title?: string | React.ReactNode;
  content: FormattedText | React.ReactNode;
  titleColor?: ThemeColors;
  getHighlightedLinkColor?(text: string, href: string): string | undefined;
}
export const GenericMessage = injectedComponent({
  props: declareProps<GenericMessageProps>(),
  model: ChannelHighlightedLinkColorModel,
  name: 'GenericMessage'
}, ({
  model,
  title,
  content,
  titleColor
}): JSX.Element => {
  return <Column>
				{!!title && titleColor ? <Text bold={true} type={'body1'} color={titleColor} style={{
      fontSize: 15
    }} className={_c0}>
						{title}
					</Text> : <Text bold={true} type={'body1'} state={'secondary'} style={{
      fontSize: 15
    }} className={_c1}>
						{title}
					</Text>}
				{FormattedText.isFormattedText(content) ? <FormattedTextDisplayWithCommandHandling text={content} textProps={{
      type: 'body1',
      selectable: true
    }} getHighlightedLinkColor={model.getHighlightedLinkColor} forceBoldLinks commandContext={CHANNEL_MESSAGE_COMMAND_CONTEXT} /> : content}
			</Column>;
});
const _c0 = "  ";
const _c1 = "  ";