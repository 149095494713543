import { NavBarItemData, NavBarItemProvider } from './bundle/services';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthService } from '@knuddels-app/Connection/serviceIds';
import { downloadViewId } from './constants';
import { isNative } from '@knuddels-app/tools/isNative';

@injectable()
export class LoggedInAreaNavBarItemProvider implements NavBarItemProvider {
	constructor(
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T
	) {}

	getItems(): NavBarItemData[] {
		const result = new Array<NavBarItemData>();

		if (!isNative()) {
			result.push({
				id: 'installApp',
				kind: 'view',
				openAsOverlay: true,
				disableForDynamicSlot: true,
				view: downloadViewId,
				trackingId: 'InstallApp',
			});
		}

		result.push({
			id: 'logout',
			kind: 'custom',
			disableForDynamicSlot: true,
			trackingId: 'Logout',
			onClick: () => {
				this.authService.logout();
			},
		});
		return result;
	}
}
