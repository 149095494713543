export function arrayEquals<T>(
	a1: T[],
	a2: T[],
	equals: (a: T, b: T) => boolean
): boolean {
	if (a1.length !== a2.length) {
		return false;
	}

	for (let i = 0; i < a1.length; i++) {
		if (!equals(a1[i], a2[i])) {
			return false;
		}
	}
	return true;
}
