import { MessengerList } from '@knuddelsModules/Messenger/bundle/components/MessengerList/MessengerList';
import * as React from 'react';
import { FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { useOpenConversation } from '../../useOpenConversation';
import { observer } from '@knuddels-app/mobx';
import { FullConversationWithoutMessagesFragment } from '@generated/graphql';
import { conversationToMessengerOverviewItem, getSortedOverviewItems, MessengerOverviewItem } from '../../services/messengerOverviewHelper';
import { removeDuplicatesAndFilterSelf } from '../../removeDuplicatesAndFilterSlef';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerListService } from '@knuddelsModules/Messenger';
import { useNavigate } from '@knuddelsModules/Messenger/bundle/components/MessengerRouter';
function extractOverviewItems(conversations: readonly FullConversationWithoutMessagesFragment[]): MessengerOverviewItem[] {
  const filtered = removeDuplicatesAndFilterSelf(conversations);
  const mapped = filtered.map(it => conversationToMessengerOverviewItem(it));
  return getSortedOverviewItems(mapped);
}
export const MessengerArchive: React.FC = observer('MessengerArchive', () => {
  const navigate = useNavigate();
  const openConversation = useOpenConversation(true, conversationId => {
    navigate('conversation', {
      params: {
        conversationId
      }
    });
  });
  const query = useService($MessengerListService).messengerArchiveQuery;
  const conversations = query.conversations;
  const overviewItems = React.useMemo(() => extractOverviewItems(conversations), [conversations]);
  return <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<MessengerList openConversation={openConversation} isArchive conversations={overviewItems} fetchMore={query.fetchMoreConversations} fetchStatus={query.fetchStatus} />
		</div>;
});
const _c0 = " Knu-FlexCol flex-1 bg-contentBg ";