import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $ProfileVisitors =
	newServiceId<services.ProfileVisitors>('$ProfileVisitors');

export const $ProfileVisitorsService =
	newServiceId<services.ProfileVisitorsService>('$ProfileVisitorsService');

export const $ProfileVisitorsPanelService =
	newServiceId<services.ProfileVisitorsPanelService>(
		'$ProfileVisitorsPanelService'
	);
