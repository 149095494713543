import * as React from 'react';
import { ConversationMessageFragment } from '@generated/graphql';
import { MessageLightboxSource } from '../Lightbox/Lightbox';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { BasicUser } from '../../../utils/BasicUser';
import { PhotoCommentMessage } from './PhotoCommentMessage';
import { ImageAndSnapMessage } from './ImageAndSnap/ImageAndSnapMessage';
import { QuoteAndForwardMessage } from './QuoteAndForwardMessage';
import { TextMessage } from './TextMessage';
interface Props {
  message: Omit<ConversationMessageFragment, 'sender'>;
  forceShowImages?: boolean;
  receiverId: string;
  sender: BasicUser;
  sentByActiveUser: boolean;
  forceBoldLinks?: boolean;
  renderTime?: boolean;
  addPadding?: boolean;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
  getHighlightedLinkColor?(): string | undefined;
}
export const ConversationMessageContent = injectedComponent({
  name: 'ConversationMessageContent',
  props: declareProps<Props>()
}, ({
  message,
  getHighlightedLinkColor,
  forceBoldLinks,
  setLightboxSrc,
  forceShowImages,
  sender,
  sentByActiveUser,
  receiverId,
  renderTime = true,
  addPadding = true
}) => {
  const content = message.content;
  switch (content.__typename) {
    case 'ConversationDeletedPhotoCommentMessageContent':
    case 'ConversationHiddenPhotoCommentMessageContent':
    case 'ConversationVisiblePhotoCommentMessageContent':
      return <PhotoCommentMessage content={content} sentByActiveUser={sentByActiveUser} sender={sender} messageId={message.id} photoOwnerId={receiverId} />;
    case 'ConversationImageMessageContent':
    case 'ConversationSnapMessageContent':
      return <ImageAndSnapMessage content={content} message={message} sentByActiveUser={sentByActiveUser} sender={sender} forceShowImages={forceShowImages} setLightboxSrc={setLightboxSrc} />;
    case 'ConversationQuotedMessageContent':
    case 'ConversationForwardedMessageContent':
      return <QuoteAndForwardMessage message={message} forceBoldLinks={forceBoldLinks} content={content} sender={sender} sentByActiveUser={sentByActiveUser} forceShowImages={forceShowImages} setLightboxSrc={setLightboxSrc} getHighlightedLinkColor={getHighlightedLinkColor} />;
    case 'ConversationTextMessageContent':
      return <TextMessage message={message} addPadding={addPadding} forceBoldLinks={forceBoldLinks} content={content} renderTime={renderTime} sender={sender} getHighlightedLinkColor={getHighlightedLinkColor} />;
    case 'ConversationKnuddelTransferMessageContent':
    case 'ConversationPrivateSystemMessageContent':
    case 'ConversationMentorAchievedMessageContent':
    case 'ConversationBirthdayMessageContent':
    case 'ConversationNicknameChangeMessageContent':
      return null;
    // Directly handled further up the tree

    default:
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const checkTypeToNotForgetUpdatingThis: never = content;
        return null;
      }
  }
});