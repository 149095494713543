import { Flex, FlexCol, IconProps, Image, Text, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { motion, useAnimate } from 'framer-motion';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Button } from '../../../atoms';
import { Z_INDEX } from '../../../globalStyleConfiguration';
export interface SnackbarButtonData {
  text: string | React.ReactElement<IconProps>;
  onPress?: () => void;
}
export type LifeTimeSnackbarProps = Omit<BaseSnackbarProps, 'registerFinishObserver'> & {
  lifeTime: number;
};
export type SnackbarProps = (LifeTimeSnackbarProps & {
  kind: 'lifetime';
}) | (BaseSnackbarProps & {
  kind: 'custom';
});
export interface BaseSnackbarProps {
  text: string | React.ReactElement;
  subtext?: string | React.ReactElement | undefined;
  adornment?: string | React.ReactElement;
  onFinished: () => void;
  registerFinishObserver: (observer: () => void) => () => void;
  button?: SnackbarButtonData;
}
const styles = {
  button: {
    borderColor: 'transparent'
  }
};
function useLifeTime(lifeTime: number, callback: () => void): {
  finishPreemptively(): void;
} {
  const lifetimeTimeout = useRef<any>();
  const startTime = useRef(Date.now());
  useEffect(() => {
    clearTimeout(lifetimeTimeout.current);
    const passedTime = Date.now() - startTime.current;
    const remainingTime = Math.max(lifeTime - passedTime, 0);
    lifetimeTimeout.current = setTimeout(() => {
      callback();
    }, remainingTime);
    return () => {
      clearTimeout(lifetimeTimeout.current);
    };
  }, [lifeTime]);
  return {
    finishPreemptively: () => {
      clearTimeout(lifetimeTimeout.current);
      callback();
    }
  };
}
export const Snackbar: React.FC<SnackbarProps> = props => {
  if (props.kind === 'lifetime') {
    return <LifeTimeSnackbar {...props} />;
  } else {
    return <BaseSnackbar {...props} />;
  }
};
export const LifeTimeSnackbar: React.FC<LifeTimeSnackbarProps> = ({
  lifeTime,
  button,
  ...props
}) => {
  const observers = React.useRef<(() => void)[]>([]);
  const stopLifeTime = useLifeTime(lifeTime, () => {
    observers.current.forEach(observer => observer());
  });
  const registerFinishObserver = React.useCallback((observer: () => void) => {
    observers.current.push(observer);
    return () => {
      observers.current.splice(observers.current.indexOf(observer), 1);
    };
  }, [observers]);
  const mappedButton = React.useMemo<typeof button>(() => {
    return button ? {
      ...button,
      onPress: () => {
        button.onPress?.();
        stopLifeTime.finishPreemptively();
      }
    } : undefined;
  }, [button, observers, stopLifeTime]);
  return <BaseSnackbar {...props} button={mappedButton} registerFinishObserver={registerFinishObserver} />;
};
function BaseSnackbar({
  text,
  subtext,
  button,
  adornment,
  onFinished,
  registerFinishObserver
}: BaseSnackbarProps): React.ReactElement {
  const theme = useTheme();
  const close = () => {
    animate(scope.current, variants.hidden, {
      type: 'spring',
      mass: 0.5
    }).then(() => {
      onFinished();
    });
  };
  React.useEffect(() => {
    return registerFinishObserver(() => {
      close();
    });
  }, [registerFinishObserver]);
  const actionElement = button ? typeof button.text === 'string' ? <Button text={button.text} onClick={button.onPress!} kind={'outline'} style={styles.button} textStyle={{
    color: theme.colors.basic.accent
  }} /> : React.cloneElement(button.text, {
    onPress: button.onPress,
    size: 'large'
  }) : null;
  React.useLayoutEffect(() => {
    animate(scope.current, variants.visible, {
      type: 'spring',
      mass: 0.5
    });
  }, []);
  const [scope, animate] = useAnimate();
  const variants = {
    visible: {
      opacity: 1,
      scale: 1
    },
    hidden: {
      opacity: 0,
      scale: 0
    }
  };
  return <motion.div ref={scope} initial={'hidden'} variants={variants}>
			<div role="alert" aria-live="assertive" style={{
      zIndex: resolveThemingValue(Z_INDEX.JUST_BELOW_OVERLAY, "theme", useTheme())
    }} className={_c0 + ("snackbarBackground" ? resolveIsDarkColor("snackbarBackground", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1}>
					{typeof adornment === 'string' || typeof adornment === 'number' ? <Image
        // adornment can also be of type `number` because on native
        // `require(...)` returns a number instead of a string.
        src={(adornment as string)} resizeMode={'contain'} alt={''} className={_c2} /> : adornment}
					<div className={_c3}>
						<Text type={'body1'} bold={true} className={_c4}>
							{text}
						</Text>
						{typeof subtext === 'string' ? <Text state={'secondary'} type={'body1'} className={_c5}>
								{subtext}
							</Text> : subtext}
					</div>
					{actionElement}
				</div>
			</div>
		</motion.div>;
}
const _c0 = " Knu-Flex bg-snackbarBackground borderRadius-base minHeight-64px py-minor px-base shadow-Shadow4 ";
const _c1 = " Knu-Flex alignItems-center flexGrow-1 ";
const _c2 = " size-32px mr-minor ";
const _c3 = " Knu-FlexCol flex-1 ";
const _c4 = "  ";
const _c5 = "  ";