import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { messengerModule } from '@knuddelsModules/Messenger/module';

export const happyMomentModule = new Module({
	name: 'Happy Moment Module',
	dependencies: [messengerModule],
	providedServices,
	loadEvent: 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
