import { useService } from '@knuddels-app/DependencyInjection';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { ChannelListCategory } from '@generated/graphql';
import * as React from 'react';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { PlaceholderContent } from '@shared/components';
export const GamesDisabledPlaceholder: React.FC = () => {
  const i18n = useService($I18n);
  const enableGames = useEnableGames();
  return <PlaceholderContent fullHeight={false} description={<FormattedMessage id={declareFormat({
    id: 'channellist.games.disabled.description',
    defaultFormat: 'Turn on "Games" in Settings to see all games again.'
  })} />} title={<FormattedMessage id={declareFormat({
    id: 'channellist.games.disabled.title',
    defaultFormat: 'You have hidden all games.'
  })} />} image={require('@shared/icons/sm_17-04-Columbus_ani.gif')} imageWidth={69} imageHeight={63} button={{
    text: declareFormat({
      id: 'channellist.games.disabled.button',
      defaultFormat: 'Unhide games'
    }).format(i18n),
    onClick: enableGames
  }} />;
};
const useEnableGames = () => {
  const clientSettingsService = useService($ClientSettingsService);
  const snackbarService = useService($SnackbarService);
  return () => {
    const newCategories = [...clientSettingsService.channelListCategories];
    const gamesIndex = newCategories.findIndex(it => it.category === ChannelListCategory.Games);
    if (gamesIndex >= 0) {
      newCategories[gamesIndex] = {
        category: ChannelListCategory.Games,
        active: true
      };
      clientSettingsService.setChannelListCategories({
        categories: newCategories
      }, {
        skipDebounce: true
      }).catch(() => snackbarService.showGenericError());
    }
  };
};