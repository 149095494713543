function o(r) {
  const t = [{}, {}];
  return r == null || r.values.forEach((c, u) => {
    t[0][u] = c.get(), t[1][u] = c.getVelocity();
  }), t;
}
function y(r, t, c, u) {
  if (typeof t == "function") {
    const [a, e] = o(u);
    t = t(c !== void 0 ? c : r.custom, a, e);
  }
  if (typeof t == "string" && (t = r.variants && r.variants[t]), typeof t == "function") {
    const [a, e] = o(u);
    t = t(c !== void 0 ? c : r.custom, a, e);
  }
  return t;
}
export {
  y as resolveVariantFromProps
};
