import * as React from 'react';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { PaymentHandlerError } from './PaymentHandlerInterface';
import { PaymentUserNotInChannelModal } from '../components/PaymentFailureModal/PaymentUserNotInChannelModal';
import { PaymentFailureModal } from '../components/PaymentFailureModal/PaymentFailureModal';
import { $OverlayService } from '@knuddels-app/overlays';
import { PaymentU18AmountRestrictionModal } from '@knuddelsModules/Payment/bundle/components/PaymentFailureModal/PaymentU18AmountRestrictionModal';
@injectable()
export class PaymentErrorDisplay {
  constructor(@inject.lazy($JoinChannelService)
  private readonly getJoinChannelService: typeof $JoinChannelService.TLazy, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {}
  private handleUserNotInChannelRetry = async (): Promise<void> => {
    const service = await this.getJoinChannelService();
    await service.initialJoinChannel({
      redirectToChannelOnSuccess: false,
      force: true
    });
  };
  display(error: PaymentHandlerError | {
    type: 'PaymentFailedEvent';
  }, callbacks: {
    onRetry: () => void;
    onCancel: () => void;
  }): void {
    if (error.type === 'UserNotInChannel') {
      const successCallback = async () => {
        await this.handleUserNotInChannelRetry();
        callbacks.onRetry();
      };
      this.overlayService.showOverlay({
        view: <PaymentUserNotInChannelModal onSuccess={successCallback} onCancel={callbacks.onCancel} />
      });
    } else if (error.type === 'U18AmountRestriction') {
      this.overlayService.showOverlay({
        view: <PaymentU18AmountRestrictionModal onCancel={callbacks.onCancel} />
      });
    } else {
      this.overlayService.showOverlay({
        view: <PaymentFailureModal onRetry={callbacks.onRetry} onCancel={callbacks.onCancel} />
      });
    }
  }
}