import { declareFormat } from '@knuddels-app/i18n';

export const HEADER_ALL_IN_CATEGORY = declareFormat({
	id: 'HEADER_ALL_IN_CATEGORY',
	defaultFormat: 'All {category} channels',
});

export const HEADER_ALL_LAST = declareFormat({
	id: 'HEADER_ALL_LAST',
	defaultFormat: 'All recent channels',
});

export const HEADER_ALL_RECOMMENDED = declareFormat({
	id: 'HEADER_ALL_RECOMMENDED',
	defaultFormat: 'All recommended channels',
});

export const HEADER_ALL_FRIENDS = declareFormat({
	id: 'HEADER_ALL_FRIENDS',
	defaultFormat: 'All channels with friends',
});

export const HEADER_TOP_LAST = declareFormat({
	id: 'channellist.header.last.top',
	defaultFormat: 'Top recent channels',
});

export const HEADER_MORE_LAST = declareFormat({
	id: 'channellist.header.last.more',
	defaultFormat: 'More recent channels',
});

export const HEADER_TOP_RECOMMENDED = declareFormat({
	id: 'channellist.header.recommended.top',
	defaultFormat: 'Top recommended channels',
});

export const HEADER_MORE_RECOMMENDED = declareFormat({
	id: 'channellist.header.recommended.more',
	defaultFormat: 'More recommended channels',
});

export const HEADER_TOP_IN_CATEGORY = declareFormat({
	id: 'channellist.header.category.top',
	defaultFormat: 'Top {category}-channels',
});

export const HEADER_MORE_IN_CATEGORY = declareFormat({
	id: 'channellist.header.category.more',
	defaultFormat: 'More {category}-channels',
});

export const HEADER_TOP_GAMES = declareFormat({
	id: 'channellist.header.games.top',
	defaultFormat: 'Top Game-Channels',
});

export const HEADER_POPULAR_GAMES = declareFormat({
	id: 'channellist.header.games.popular',
	defaultFormat: 'Popular Game-Channels',
});

export const HEADER_MORE_GAMES = declareFormat({
	id: 'channellist.header.games.more',
	defaultFormat: 'More Game-Channels',
});

export const CHANNEL_LIST_TITLE = declareFormat({
	id: 'HEADER_OVERVIEW',
	defaultFormat: 'Channels',
});

export const SEARCH_RESULTS = declareFormat({
	id: 'channellist.search.searchResults',
	defaultFormat: 'Search results',
});
