import { Flex, LineDivider } from '@knuddels/component-library';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import * as React from 'react';
import { useEffect } from 'react';
import { OverlayFactory, useCloseThisOverlay } from '@knuddels-app/overlays';
import { FriendState, UserForQuickWhoisFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { IconAddToWatchlist } from '@knuddels-app/shared-components/IconAddToWatchlist';
import { IconRemoveFromWatchlist } from '@knuddels-app/shared-components/IconRemoveFromWatchlist';
import { $FriendRequestsService, $WatchlistService } from '@knuddelsModules/Contacts';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { ACCEPT_FRIEND_REQUEST, ADD_FRIEND_REQUEST, ADD_TO_WATCHLIST, CANCEL_FRIEND_REQUEST, DO_KISS, DO_KNUDDEL, REMOVE_FROM_WATCHLIST } from '../../i18n/shared-formats';
import { IconFriendAdd } from '../shared/IconFriendAdd';
import { useCancelFriendRequest } from '../shared/useCancelFriendRequest';
import { IconFriendRemove } from '@knuddels-app/shared-components/IconFriendRemove';
import { useAcceptFriendRequest } from '../shared/useAcceptFriendRequest';
import { useDoKnuddel } from '@knuddels-app/shared-hooks/useDoKnuddel';
import { IconKnuddel } from '../shared/IconKnuddel';
import { useDoKiss } from '../shared/useDoKiss';
import { IconKiss } from '../shared/IconKiss';
import { useTrackQuickWhois } from './useTrackQuickWhois';
export const QuickWhoisOverflowMenu: React.FC<ContextMenuProps & {
  user: UserForQuickWhoisFragment;
}> = ({
  user,
  ...menuProps
}) => {
  const track = useTrackQuickWhois();
  useEffect(() => {
    track('Menu_Opened');
    return () => {
      track('Menu_Closed');
    };
  }, []);
  const closeOverlay = useCloseThisOverlay();
  return <ContextMenu {...menuProps} closeContextMenu={closeOverlay}>
			{user.friendState !== FriendState.Friends && <>
					{{
        [FriendState.None]: <AddFriendEntry userId={user.id} />,
        [FriendState.RequestInitiated]: <CancelRequestEntry userId={user.id} />,
        [FriendState.RequestReceived]: <AcceptRequestEntry userId={user.id} />
      }[user.friendState]}
					<div className={_c0}>
						<LineDivider className={_c1} />
					</div>
					<WatchlistEntry user={user} />
					<div className={_c2}>
						<LineDivider className={_c3} />
					</div>
				</>}
			<KissEntry userId={user.id} nick={user.nick} />
			<div className={_c4}>
				<LineDivider className={_c5} />
			</div>
			<KnuddelEntry nick={user.nick} />
		</ContextMenu>;
};
const KnuddelEntry: React.FC<{
  nick: string;
}> = ({
  nick
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const doKnuddel = useDoKnuddel();
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={DO_KNUDDEL.format(i18n)} onClick={async () => {
    track('Menu_Knuddel');
    doKnuddel(nick);
    closeOverlay();
  }} icon={IconKnuddel} />;
};
const KissEntry: React.FC<{
  userId: string;
  nick: string;
}> = ({
  userId,
  nick
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const doKiss = useDoKiss();
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={DO_KISS.format(i18n)} onClick={async () => {
    track('Menu_Kiss');
    doKiss(userId, nick);
    closeOverlay();
  }} icon={IconKiss} />;
};
const AddFriendEntry: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const friendRequestsService = useService($FriendRequestsService);
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={ADD_FRIEND_REQUEST.format(i18n)} onClick={async () => {
    track('Menu_SendFriendrequest');
    friendRequestsService.sendFriendRequest(userId, track);
    closeOverlay();
  }} icon={IconFriendAdd} />;
};
const CancelRequestEntry: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const cancelFriendRequest = useCancelFriendRequest();
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={CANCEL_FRIEND_REQUEST.format(i18n)} onClick={async () => {
    track('Menu_RevokeFriendrequest');
    cancelFriendRequest(userId);
    closeOverlay();
  }} icon={IconFriendRemove} />;
};
const AcceptRequestEntry: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const acceptFriendRequest = useAcceptFriendRequest();
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={ACCEPT_FRIEND_REQUEST.format(i18n)} onClick={async () => {
    track('Menu_AcceptFriendrequest');
    acceptFriendRequest(userId);
    closeOverlay();
  }} icon={IconFriendAdd} />;
};
const WatchlistEntry: React.FC<{
  user: UserForQuickWhoisFragment;
}> = ({
  user
}) => {
  return user.isOnWatchlist ? <RemoveFromWatchlistEntry userId={user.id} /> : <AddToWatchlistEntry userId={user.id} />;
};
const AddToWatchlistEntry: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const getWatchlistService = useLazyService($WatchlistService);
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={ADD_TO_WATCHLIST.format(i18n)} onClick={async () => {
    track('Menu_AddToWatchlist');
    const service = await getWatchlistService();
    service.addToWatchlist(userId);
    closeOverlay();
  }} icon={IconAddToWatchlist} />;
};
const RemoveFromWatchlistEntry: React.FC<{
  userId: string;
}> = ({
  userId
}) => {
  const closeOverlay = useCloseThisOverlay();
  const i18n = useService($I18n);
  const getWatchlistService = useLazyService($WatchlistService);
  const track = useTrackQuickWhois();
  return <ContextMenuEntry text={REMOVE_FROM_WATCHLIST.format(i18n)} onClick={async () => {
    track('Menu_RemoveFromWatchlist');
    const service = await getWatchlistService();
    service.removeFromWatchlist(userId);
    closeOverlay();
  }} icon={IconRemoveFromWatchlist} />;
};
export const QuickWhoisOverflowMenuOverlay = new OverlayFactory(QuickWhoisOverflowMenu, props => ({
  userId: props.user.id
}));
const _c0 = " Knu-Flex py-small ";
const _c1 = "  ";
const _c2 = " Knu-Flex py-small ";
const _c3 = "  ";
const _c4 = " Knu-Flex py-small ";
const _c5 = "  ";