import { $AuthenticatedClientService } from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import {
	Block,
	Ignore,
	IgnoreStateChanged,
	IgnoreStateChangedFragment,
	IsIgnoringChanged,
	PrivateIgnore,
	SetIgnoreStateMutationResponseError,
	UnBlock,
	UnIgnore,
	UnPrivateIgnore,
	User,
} from '@generated/graphql';
import { createChangeIgnoreStateProtectedUserSnackbar } from '@knuddelsModules/UserData';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import {
	GraphQlOperationError,
	K3ApolloClient,
} from '@knuddels-app/Connection';

@injectable()
export class IgnoreService implements Disposable {
	public readonly dispose = Disposable.fn();

	public readonly onIgnoreStateChanged: EventSource<IgnoreStateChangedFragment>;
	private readonly ignoreStateChangedEmitter =
		new EventEmitter<IgnoreStateChangedFragment>();

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T
	) {
		const currentK3Client = this.authenticatedClientService.currentK3Client;
		this.dispose.track(
			currentK3Client.subscribeToPrimaryData(
				IgnoreStateChanged,
				{},
				{
					next: e => {
						this.ignoreStateChangedEmitter.emit(e);
					},
				}
			)
		);

		this.dispose.track(
			currentK3Client.subscribeToPrimaryData(IsIgnoringChanged, {}, {})
		);

		this.onIgnoreStateChanged = this.ignoreStateChangedEmitter.asEvent();
	}

	private get client(): K3ApolloClient {
		return this.authenticatedClientService.currentK3Client;
	}

	public showIgnoreError(
		r: GraphQlOperationError<
			SetIgnoreStateMutationResponseError | null | undefined
		>,
		snackbarAction: 'block' | 'ignore'
	): void {
		if (
			r.kind === 'DomainError' &&
			r.error === SetIgnoreStateMutationResponseError.NotIgnorable
		) {
			this.snackbarService.showSnackbar(
				createChangeIgnoreStateProtectedUserSnackbar(snackbarAction)
			);
		} else {
			this.snackbarService.showGenericError();
		}
	}

	public privateIgnore(id: User['id']): void {
		this.client.mutateWithResultPromise(PrivateIgnore, { id }).onErr(r => {
			this.showIgnoreError(r, 'ignore');
		});
	}

	public ignore(id: User['id'], okCb?: () => void): void {
		const result = this.client.mutateWithResultPromise(Ignore, { id });

		result.onOk(() => {
			okCb?.();
		});
		result.onErr(r => {
			this.showIgnoreError(r, 'ignore');
		});
	}

	public block(id: User['id']): void {
		this.client.mutateWithResultPromise(Block, { id }).onErr(r => {
			this.showIgnoreError(r, 'block');
		});
	}

	public unprivateIgnore(id: User['id']): void {
		this.client
			.mutateWithResultPromise(UnPrivateIgnore, { id })
			.onErr(() => {
				this.snackbarService.showGenericError();
			});
	}

	public unignore(id: User['id']): void {
		this.client.mutateWithResultPromise(UnIgnore, { id }).onErr(() => {
			this.snackbarService.showGenericError();
		});
	}

	public unblock(id: User['id']): void {
		this.client.mutateWithResultPromise(UnBlock, { id }).onErr(() => {
			this.snackbarService.showGenericError();
		});
	}
}
