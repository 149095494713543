import { isSVGComponent as n } from "./is-svg-component.js";
import { createUseRender as t } from "../use-render.js";
import { svgMotionConfig as f } from "../../svg/config-motion.js";
import { htmlMotionConfig as m } from "../../html/config-motion.js";
function c(o, { forwardMotionProps: e = !1 }, r, i) {
  return {
    ...n(o) ? f : m,
    preloadedFeatures: r,
    useRender: t(e),
    createVisualElement: i,
    Component: o
  };
}
export {
  c as createDomMotionConfig
};
