import { calcLength as r } from "./delta-calc.js";
function u(n) {
  return n.translate === 0 && n.scale === 1;
}
function e(n) {
  return u(n.x) && u(n.y);
}
function i(n, t) {
  return n.min === t.min && n.max === t.max;
}
function s(n, t) {
  return i(n.x, t.x) && i(n.y, t.y);
}
function o(n, t) {
  return Math.round(n.min) === Math.round(t.min) && Math.round(n.max) === Math.round(t.max);
}
function x(n, t) {
  return o(n.x, t.x) && o(n.y, t.y);
}
function c(n) {
  return r(n.x) / r(n.y);
}
function l(n, t) {
  return n.translate === t.translate && n.scale === t.scale && n.originPoint === t.originPoint;
}
export {
  c as aspectRatio,
  l as axisDeltaEquals,
  i as axisEquals,
  o as axisEqualsRounded,
  s as boxEquals,
  x as boxEqualsRounded,
  e as isDeltaZero
};
