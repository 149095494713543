import { action, autorun, ObservableSet } from 'mobx';
import MessageFormat from 'intl-messageformat';
import { FormatId } from './FormatId';
import {
	Formatter,
	FormatterProvider,
	LocalizedFormatterProvider,
} from './FormatterProvider';
import { LocaleId } from '@knuddels/std';
import { FormattedData } from '.';
import { LocalePreference } from './LocalePreference';

/**
 * A formatter provider based on `intl-messageformat`.
 */

export class MessageFormatFormatterProvider implements FormatterProvider {
	private readonly providers = new ObservableSet<FormatBundleProvider>();

	public getLocalizedFormatterProvider(
		localePreference: LocalePreference
	): LocalizedFormatterProvider {
		return new LocalizedMessageFormatProviderImpl(
			localePreference,
			this.providers
		);
	}

	@action
	public registerFormatProvider(provider: FormatBundleProvider): void {
		this.providers.add(provider);
	}
}

export type FormatBundleProvider = (locale: LocaleId) => FormatBundle;
export interface FormatBundle {
	formats: Formats;
}
export type Formats = Record<string, string | null>;

class LocalizedMessageFormatProviderImpl implements LocalizedFormatterProvider {
	private Cache = new Map<string, MessageFormatFormatter>();
	private KeyCache = new Map<string, string>();
	private lastCachedIndex = 0;

	constructor(
		public readonly localePreference: LocalePreference,
		private readonly providers: Set<FormatBundleProvider>
	) {
		autorun(() => {
			const providersArray = [...this.providers.values()];
			for (
				let index = this.lastCachedIndex;
				index < this.providers.size;
				index++
			) {
				const provider = providersArray[index];
				const bundle = provider(localePreference.preferredLocales[0]);
				if (!bundle) {
					console.error(
						`Could not load any locale for "${provider.toString()}"`
					);
					continue;
				}

				for (const [key, value] of Object.entries(bundle.formats)) {
					if (!value) {
						console.warn(`Key '${key}' has no translation!`);
					}
					this.KeyCache.set(key, value || key);
				}
			}
			this.lastCachedIndex = this.providers.size;
		});
	}

	getFormatter(formatId: FormatId): Formatter {
		const cached = this.Cache.get(formatId.id);
		if (cached) {
			return cached;
		}

		const value =
			this.KeyCache.get(formatId.id) ??
			(formatId.defaultFormat ? formatId.defaultFormat : formatId.id);

		const formatter = new MessageFormatFormatter(
			value,
			this.localePreference.preferredLocales
		);

		this.Cache.set(formatId.id, formatter);
		return formatter;
	}
}

class MessageFormatFormatter implements Formatter {
	private readonly fmt: MessageFormat;
	private cacheKey: string;
	constructor(format: string, locales: ReadonlyArray<LocaleId>) {
		this.cacheKey = format + locales.join(',');
		this.fmt = new MessageFormat(
			format,
			locales.map(l => l.localeCode)
		);
	}

	format(data?: {}): string {
		return this.fmt.format(data) as string;
	}

	formatStructured(data?: {}): FormattedData {
		// This enum a copy of IntlMessageFormat.PART_TYPE, however,
		// importing PART_TYPE from intl message format throws.
		const enum PART_TYPE {
			literal = 0,
			argument = 1,
		}

		const parts = this.fmt.formatToParts(data);
		const items = parts.map<FormattedData>((p: any) =>
			(p.type as any) === PART_TYPE.literal
				? { kind: 'text', value: p.value }
				: { kind: 'object', data: p.value }
		);
		return {
			kind: 'sequence',
			items,
		};
	}
}
