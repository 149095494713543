import { buildTransform as g } from "./build-transform.js";
import { isCSSVariableName as y } from "../../dom/utils/is-css-variable.js";
import { transformProps as T } from "./transform.js";
import { getValueAsType as b } from "../../dom/value-types/get-as-type.js";
import { numberValueTypes as h } from "../../dom/value-types/number.js";
function A(t, s, a) {
  const { style: o, vars: p, transform: c, transformOrigin: m } = t;
  let l = !1, u = !1, f = !0;
  for (const r in s) {
    const i = s[r];
    if (y(r)) {
      p[r] = i;
      continue;
    }
    const n = h[r], e = b(i, n);
    if (T.has(r)) {
      if (l = !0, c[r] = e, !f)
        continue;
      i !== (n.default || 0) && (f = !1);
    } else
      r.startsWith("origin") ? (u = !0, m[r] = e) : o[r] = e;
  }
  if (s.transform || (l || a ? o.transform = g(t.transform, f, a) : o.transform && (o.transform = "none")), u) {
    const { originX: r = "50%", originY: i = "50%", originZ: n = 0 } = m;
    o.transformOrigin = `${r} ${i} ${n}`;
  }
}
export {
  A as buildHTMLStyles
};
