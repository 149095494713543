import { getEasingForSegment as e } from "../../../easing/utils/get-easing-for-segment.js";
import { removeItem as g } from "../../../utils/array.js";
import { mixNumber as n } from "../../../utils/mix/number.js";
function l(t, f, a) {
  for (let o = 0; o < t.length; o++) {
    const r = t[o];
    r.at > f && r.at < a && (g(t, r), o--);
  }
}
function u(t, f, a, o, r, i) {
  l(t, r, i);
  for (let m = 0; m < f.length; m++)
    t.push({
      value: f[m],
      at: n(r, i, o[m]),
      easing: e(a, m)
    });
}
export {
  u as addKeyframes,
  l as eraseKeyframes
};
