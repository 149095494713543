import { isValidMotionProp as e } from "../../../motion/utils/valid-prop.js";
let n = (t) => !e(t);
function f(t) {
  t && (n = (o) => o.startsWith("on") ? !e(o) : t(o));
}
try {
  f(require("@emotion/is-prop-valid").default);
} catch {
}
function u(t, o, a) {
  const i = {};
  for (const r in t)
    r === "values" && typeof t.values == "object" || (n(r) || a === !0 && e(r) || !o && !e(r) || // If trying to use native HTML drag events, forward drag listeners
    t.draggable && r.startsWith("onDrag")) && (i[r] = t[r]);
  return i;
}
export {
  u as filterProps,
  f as loadExternalIsValidProp
};
