import * as React from 'react';
import { User } from '@generated/graphql';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $UserOnlineStatusService } from '../../providedServices';
import { UserWithOnlineChannelName } from '../../interfaces';
interface Props {
  id: User['id'];
  children: (onlineStatus: UserWithOnlineChannelName) => React.ReactElement;
}
@injectable()
class UserOnlineStatusWithChannelModel {
  constructor(@injectProps()
  private readonly props: Pick<Props, 'id'>, @inject($UserOnlineStatusService)
  private readonly userOnlineStatusService: typeof $UserOnlineStatusService.T) {
    // currently we intentionally don't unsubscribe, because we don't want to miss updates
    // and having to requery the current online status.
    // This prevents too many requests by canceling, querying and resubscribing.
    this.userOnlineStatusService.getUserWithOnlineChannel(this.props.id);
  }
  public get userWithOnlineChannel(): Pick<User, 'latestOnlineChannelName' | 'isOnline'> {
    return this.userOnlineStatusService.getUserWithOnlineChannel(this.props.id).userWithOnlineChannelName;
  }
}
export const UserOnlineStatusWithChannel = injectedComponent({
  name: 'UserOnlineStatusWithChannel',
  model: UserOnlineStatusWithChannelModel,
  props: declareProps<Props>()
}, ({
  model,
  children
}) => {
  return children(model.userWithOnlineChannel);
});