import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { ConversationMessageContent } from '../components/Conversation/Messages/ConversationMessageContent';
import { MessengerComponent } from '../components/Messenger';
import { DashboardMessengerListElement } from '../components/MessengerList/DashboardMessengerListElement';
import { MiniChatTrigger } from '../components/MiniChat';
@injectable()
export class MessengerComponents {
  public readonly Messenger = MessengerComponent;
  public readonly ConversationMessageContent = ConversationMessageContent;
  public readonly DashboardMessengerListElement = DashboardMessengerListElement;
  public readonly MiniChatTrigger = MiniChatTrigger;
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de: require('../i18n/formats.de.json'),
      en: require('../i18n/formats.en.json')
    } as any)[locale.language]);
  }
}