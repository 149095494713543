import * as React from 'react';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { isConversationUnread } from '../../utils/conversationReadStateHelper';
import { formatMessage } from '@knuddels-app/i18n';
import { conversationOverviewMenuEvent } from '../../analytics';
import { MessengerConversation, MessengerConversationReadStateProps, withMessengerConversationReadState } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { ARCHIVE_CHAT, DELETE_CHAT, MARK_CONVERSATION_READ, MARK_CONVERSATION_UNREAD } from '../../i18n/shared-formats';
import { $MessengerService, $ReadSystemService } from '@knuddelsModules/Messenger/providedServices';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { IconRead } from '@shared/icons/IconRead';
import { IconUnread } from '@shared/icons/IconUnread';
import { IconTrash } from '@knuddels/component-library';
import { IconArchive } from '@shared/icons/IconArchive';
import { ContextMenuDivider } from '@shared/components/molecules/Modal/ContextMenuDivider';
import { Disposable } from '@knuddels/std';
import { $Environment } from '@knuddels-app/Environment';
import { reactionOnceWhen } from '@knuddels-app/mobx';
interface ConversationContextMenuProps extends ContextMenuProps {
  conversationId: MessengerConversation['id'];
}
type Props = ConversationContextMenuProps & MessengerConversationReadStateProps;
@injectable()
class ConversationContextMenuModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: Props, @inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($ReadSystemService)
  private readonly readSystemService: typeof $ReadSystemService.T, @inject($SnackbarService)
  public readonly snackbarService: typeof $SnackbarService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($Environment)
  private readonly environment: typeof $Environment.T) {
    if (environment.isStappSidebarOverview) {
      this.dispose.track(reactionOnceWhen({
        fireImmediately: true,
        name: 'close context menu on blur'
      }, () => !environment.hasFocus, () => this.closeContextMenu()));
    }
  }
  public deleteConversation = (): void => {
    conversationOverviewMenuEvent.track('ConversationMenu_DeleteChat');
    this.messengerService.deleteConversation(this.props.conversationId);
    this.closeContextMenu();
  };
  public archiveConversation = (): void => {
    conversationOverviewMenuEvent.track('ConversationMenu_ArchiveChat');
    this.messengerService.archiveConversation(this.props.conversationId);
    this.closeContextMenu();
  };
  public markUnread = (): void => {
    conversationOverviewMenuEvent.track('ConversationMenu_MarkAsUnread');
    this.readSystemService.markConversationAsUnread(this.props.conversationId);
    this.closeContextMenu();
  };
  public markRead = (): void => {
    conversationOverviewMenuEvent.track('ConversationMenu_MarkAsRead');
    this.readSystemService.markConversationsAsRead([this.props.conversationId]);
    this.closeContextMenu();
  };
  public closeContextMenu = (): void => {
    this.thisVisibleOverlay.dispose();
  };
}
export const ConversationContextMenuBase = injectedComponent({
  name: 'ConversationContextMenuBase',
  model: ConversationContextMenuModel,
  props: declareProps<Props>()
}, ({
  model,
  data,
  chevronHeight,
  chevronWidth,
  chevronX,
  chevronY
}) => {
  if (data.error) {
    model.snackbarService.showGenericError();
    model.closeContextMenu();
    return null;
  }
  if (data.loading) {
    return null;
  }
  const conversation = data.messenger.conversation;
  const isUnread = isConversationUnread(conversation);
  return <ContextMenu closeContextMenu={model.closeContextMenu} chevronHeight={chevronHeight} chevronWidth={chevronWidth} chevronX={chevronX} chevronY={chevronY}>
				<ContextMenuEntry icon={isUnread ? IconRead : IconUnread} text={formatMessage(isUnread ? MARK_CONVERSATION_READ : MARK_CONVERSATION_UNREAD)} onClick={isUnread ? model.markRead : model.markUnread} testId={isUnread ? 'conversation-menu-mark-read' : 'conversation-menu-mark-unread'} />
				<ContextMenuDivider />
				<ContextMenuEntry icon={IconArchive} text={formatMessage(ARCHIVE_CHAT)} onClick={model.archiveConversation} />
				<ContextMenuEntry icon={IconTrash} text={formatMessage(DELETE_CHAT)} onClick={model.deleteConversation} />
			</ContextMenu>;
});
const ConversationContextMenu = withMessengerConversationReadState<ConversationContextMenuProps>({
  options: props => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: props.conversationId
    }
  })
})(ConversationContextMenuBase);
export const ConversationContextMenuOverlay = new OverlayFactory(ConversationContextMenu, props => ({
  conversationId: props.conversationId
}));