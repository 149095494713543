import * as React from 'react';
import type { LicenseInfo } from './LicenseInfo';
import { FlexCol, Link, rgb, ThemeOverride, Text, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
export const CreditedLibrary = React.memo(({
  licenseInfo,
  showLicense,
  onClick
}: {
  licenseInfo: LicenseInfo;
  showLicense: boolean;
  onClick: (packageId: string) => void;
}) => {
  const onClickHandler = React.useCallback(() => {
    onClick(licenseInfo.id);
  }, [onClick, licenseInfo]);
  const li = licenseInfo;
  const header = <Link type={'body1'} state={'highlighted'} onPress={onClickHandler}>
				{li.name} {li.version}
			</Link>;
  if (!showLicense) {
    return header;
  }
  const text = `${li.licenseName}\n\n${li.licenseText}`;
  return <div className={_c0}>
				{header}
				<div style={{
      background: resolveThemingValue(rgb(244, 244, 244), "colors", useTheme())
    }} className={_c1 + (rgb(244, 244, 244) ? resolveIsDarkColor(rgb(244, 244, 244), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Text state={'secondary'} type={'tiny'} className={_c2}>
						{text}
					</Text>
				</div>
			</div>;
});
CreditedLibrary.displayName = 'CreditedLibrary';
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-FlexCol borderRadius-5px p-minor my-small ";
const _c2 = "  ";