import * as React from 'react';
import { VistorsPanelItem } from './VistorsPanelItem';
import { FlexCol, IconMoreHorizontal } from '@knuddels/component-library';
export function OverflowItem(props: {
  onClick: () => void;
  text: string;
}): React.ReactElement {
  return <VistorsPanelItem label={props.text} onClick={props.onClick}>
			<div className={_c0}>
				<IconMoreHorizontal size={'large'} />
			</div>
		</VistorsPanelItem>;
}
const _c0 = " Knu-FlexCol flex-1 placeItems-center borderRadius-minor borderWidth-large borderStyle-solid borderColor-inputFieldBorder hoverBorderColor-inputFieldBorderHover ";