import { Z_INDEX } from '@shared/components';
import { BoxProps, rgb } from '@knuddels/component-library';
import { NAV_BAR_BOTTOM_HEIGHT } from '@shared/constants';

export const navBarWidthExpanded = 240;

export const styles = {
	/* NavBarLeft */
	NavBarExtensionLeft: {
		['zIndex' as any]: Z_INDEX.NAV,
		flex: 1,
	} as BoxProps,
	NavBarOverlay: {
		position: 'absolute',
		inset: 'none',
		flex: 1,
		alignSelf: 'stretch',
		bg: rgb(0, 0, 0, 0.22),
		zIndex: Z_INDEX.NAV_OVERLAY,
	} as BoxProps,

	/* NavBarMinimalLeft */
	NavBarMinimalLeft: {
		flex: 1,
		flexDirection: 'column',
	} as BoxProps,

	/* NavBarMinimalBottom */
	NavBarMinimalBottom: {
		flexDirection: 'row',
		height: NAV_BAR_BOTTOM_HEIGHT,
	} as BoxProps,
};
