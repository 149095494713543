import { useMutation } from '@knuddels-app/Connection';
import { CancelFriendship } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';

export const useCancelFriendship = () => {
	const [cancelFriendship] = useMutation(CancelFriendship);
	const snackbarService = useService($SnackbarService);

	return (userId: string) => {
		cancelFriendship({ userId }).then(result => {
			// success is handled via events (contactlist update)
			if (result.data?.__typename !== 'Success') {
				snackbarService.showGenericError();
			}
		});
	};
};
