import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $SettingsComponents } from './providedServiceIds';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import type { SettingsRouterConfig } from '@knuddelsModules/Settings/SettingsRouterConfig';
import { channelViewId } from '@knuddelsModules/Channel';
import { navigationViewId } from '@knuddelsModules/LoggedInArea';
@injectable()
export class SettingsViewProviderImpl implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: settingsViewId,
      position: Position.Side,
      loadState: SettingsViewState.fromPersistedViewState,
      useSplitView: true,
      getParentViewId: state => {
        const topOfStack = state.stack[state.stack.length - 1];
        if (topOfStack?.path === 'ChannelSettingsOpenedByChannel') {
          return channelViewId;
        } else {
          return navigationViewId;
        }
      },
      render: () => ({
        mainView: <LoadService service={$SettingsComponents}>
						{s => <s.MainView />}
					</LoadService>
      })
    });
  }
}
export const settingsViewId = new ViewId<SettingsViewState>('settings');
export class SettingsViewState extends RouterViewState<SettingsRouterConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(SettingsViewState);
  onOpenByNav?(): SettingsViewState {
    const topOfStack = this.topOfStack();
    if (topOfStack?.path === 'ChannelSettingsOpenedByChannel') {
      // remove/replace `ChannelSettingsOpenedByChannel` from current stack
      return this.root().withPath('ChannelSettings');
    } else {
      return this.withStack(this.stack);
    }
  }
}