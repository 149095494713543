import * as React from 'react';
type ContextType = {
  setExpanded: (expanded: boolean) => void;
  isExpanded: boolean;
};
const SmallChannelGroupExpandedContext = React.createContext<ContextType>(undefined);
export const useSmallChannelGroupExpanded = () => {
  const context = React.useContext(SmallChannelGroupExpandedContext);
  if (!context) {
    throw new Error('You are trying to use the SmallChannelGroupExpandedContext outside of its provider');
  }
  return context;
};
export const SmallChannelGroupExpandedContextProvider: React.FC = ({
  children
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const context: ContextType = React.useMemo(() => ({
    setExpanded: newExpanded => {
      setIsExpanded(newExpanded);
    },
    isExpanded: isExpanded
  }), [isExpanded]);
  return <SmallChannelGroupExpandedContext.Provider value={context}>
			{children}
		</SmallChannelGroupExpandedContext.Provider>;
};