import { ClientInfoInterface } from './ClientInfo.interface';
import { getVersion } from './version';
import { getOsInfo } from './osInfo';
import { getPlatform } from './platform';

export class ClientInfo implements ClientInfoInterface {
	private readonly _version = getVersion();
	private readonly _osInfo = getOsInfo();
	private readonly _platform = getPlatform();

	constructor(public readonly deviceIdentifiers: string[]) {}

	public get version(): ReturnType<typeof getVersion> {
		return this._version;
	}

	public get osInfo(): ReturnType<typeof getOsInfo> {
		return this._osInfo;
	}

	public get platform(): ReturnType<typeof getPlatform> {
		return this._platform;
	}
}
