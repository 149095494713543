import { jsx as j } from "react/jsx-runtime";
import * as i from "react";
import { toPressHandler as N } from "./PressHandler.js";
import "./Text.scss.js";
import { clsx as R } from "../../node_modules/clsx/dist/clsx.m.js";
import { marginMapping as c } from "../Layout/Box/BoxMappings.js";
import { useColor as k } from "../Layout/hooks/useColor.js";
import { resolveThemeColor as E, resolveThemingValue as H } from "../Layout/isDarkColor.js";
import { useTheme as K } from "../../themes/useTheme.js";
const L = i.memo(
  ({
    children: n,
    type: a = "body1",
    state: p = "primary",
    id: f,
    bold: y,
    extraBold: u,
    italic: x,
    onPress: T,
    selectable: g,
    numberOfLines: e = 0,
    className: h,
    style: d,
    ...o
  }) => {
    const { clickable: b, onClick: v } = N(T), m = i.useRef(null), s = k(o, m), l = K(), C = s ? {
      "--text-colors-primary-active": E(
        s,
        l
      )
    } : {}, S = e > 1 ? {
      "--numberOfLines": e
    } : {};
    let t = o.style || {};
    return Object.keys(o).forEach((r) => {
      r in c && (t = {
        ...t,
        [c[r]]: H(
          o[r],
          "spacing",
          l
        )
      });
    }), /* @__PURE__ */ j(
      "span",
      {
        id: f,
        ref: m,
        onClick: v,
        style: {
          ...C,
          ...S,
          ...t,
          ...d
        },
        className: R("Knu-Text", a, p, h, {
          clickable: b,
          bold: y,
          extraBold: u,
          italic: x,
          selectable: g,
          numberOfLines: e !== 0,
          singleLine: e === 1
        }),
        children: n
      }
    );
  }
);
L.displayName = "Text";
export {
  L as Text
};
