import { ChannelGroupInfo } from '@generated/graphql';

export const defaultGroupInfo: Omit<
	ChannelGroupInfo,
	'name' | 'categoryNames' | 'searchKeywords'
> = {
	backgroundColor: { alpha: 1, blue: 0, green: 0, red: 0 },
	highlightColor: { alpha: 1, blue: 0, green: 0, red: 0 },
	maxAge: 100,
	maxUsers: 200,
	minAge: 14,
	previewImageUrl: undefined,
	previewImageInfo: {
		url: undefined,
		urlBlurred: undefined,
	},
	isSpecialPreviewImage: false,
	isMyChannel: false,
	isAdultChannel: false,
};
