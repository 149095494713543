import { action, observable } from '@knuddels-app/mobx';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { EditAlbumTitle } from '@generated/graphql';

export class AlbumsEditor {
	@observable
	public selectedPhotoIds: Set<string> = new Set();

	@observable
	public albumEntries: Record<
		string,
		{ albumId: string; newTitle: string }
	> = {};

	constructor(
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		private readonly markProfileDirty: () => void
	) {}

	private getOrCreateEntry = (albumId: string) => {
		if (this.albumEntries[albumId]) {
			return this.albumEntries[albumId];
		} else {
			const entry = {
				albumId,
				newTitle: '',
			};
			this.albumEntries[albumId] = entry;
			return entry;
		}
	};

	@action.bound
	public changeTitle(albumId: string, newTitle: string): void {
		const entryData = this.getOrCreateEntry(albumId);

		if (entryData.newTitle === newTitle) {
			return;
		}

		this.albumEntries[albumId] = { ...entryData, newTitle };
		this.markProfileDirty();
	}

	public save = async (): Promise<boolean> => {
		const promises = Object.values(this.albumEntries).map(entry => {
			return this.authenticatedClientService.currentK3Client.mutate(
				EditAlbumTitle,
				{
					id: entry.albumId,
					title: entry.newTitle,
				}
			);
		});

		const results = await Promise.all(promises);

		return results
			.map(it =>
				it.match({
					ok: data =>
						data.primaryData?.__typename ===
						'ChangeAlbumTitleSuccess',
					error: () => false,
				})
			)
			.every(it => it === true);
	};
}
