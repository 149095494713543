import { useProgressSnackbar } from './useProgressSnackbar';
import { generateId } from '@knuddels/component-library';
export const useProgressHandler = () => {
  const showProgressSnackbar = useProgressSnackbar();
  return (totalPhotos: number, onCancel: () => void) => {
    let photosUploaded = 0;
    const snackbarType = generateId('snackbarType');
    let snackbar: {
      close: () => void;
    };
    const updateSnackbar = () => {
      const canCancel = photosUploaded < totalPhotos - 1;
      snackbar = showProgressSnackbar(photosUploaded, totalPhotos, snackbarType, canCancel ? onCancel : undefined);
    };
    const closeSnackbar = () => {
      // Without the timeout this doesn't work for some reason
      setTimeout(() => {
        snackbar.close();
      });
    };
    const uploadCompleted = () => {
      photosUploaded++;
      updateSnackbar();
    };
    updateSnackbar();
    return {
      closeSnackbar,
      uploadCompleted
    };
  };
};