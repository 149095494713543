import * as React from 'react';
import { Flex, FlexCol } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import { MentorStatusActiveFragment } from '@generated/graphql';
import { MentorImage } from './shared/MentorImage';
import { ProgressDisplay } from './shared/ProgressDisplay';
import { IconMentorPoints } from './shared/IconMentorPoints';
import { FormattedText, PreviewFormattedTextDisplay, Spacer } from '@shared/components';
export const MentorPointsProgressBar: React.FC<{
  activeStatus: MentorStatusActiveFragment;
}> = observer('MentorPointsProgressBar', props => {
  const status = props.activeStatus;
  return <div className={_c0}>
			<div className={_c1}>
				<MentorImage bgSize={36} imageWidth={24} imageHeight={21} />
			</div>
			<div className={_c2}>
				<ProgressDisplay value={status.mentorPoints} maxValue={status.mentorLevelBoundary} prevMaxValue={status.previousMentorLevelBoundary} numericValueDisplay={'on-top'} numericValueIcon={IconMentorPoints} />
			</div>
			{status.nextMentorReward && <>
					<Spacer size={'small'} />
					<PreviewFormattedTextDisplay textProps={{}} text={FormattedText.fromJsonString(status.nextMentorReward.icon)} />
				</>}
		</div>;
});
const _c0 = " Knu-Flex flex-1 placeItems-center ";
const _c1 = " Knu-FlexCol zIndex-1 width-29px overflow-visible ";
const _c2 = " Knu-FlexCol flexGrow-1 ";