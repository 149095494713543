import { transformProps as V } from "../../render/html/utils/transform.js";
import { animateMotionValue as S } from "./motion-value.js";
import { setTarget as H } from "../../render/utils/setters.js";
import { getValueTransition as P } from "../utils/transitions.js";
import { getOptimisedAppearId as B } from "../optimized-appear/get-appear-id.js";
import { addValueToWillChange as I } from "../../value/use-will-change/add-will-change.js";
import { frame as A } from "../../frameloop/frame.js";
function M({ protectedKeys: t, needsAnimating: n }, a) {
  const i = t.hasOwnProperty(a) && n[a] !== !0;
  return n[a] = !1, i;
}
function W(t, n, { delay: a = 0, transitionOverride: i, type: p } = {}) {
  var e;
  let { transition: d = t.getDefaultTransition(), transitionEnd: f, ...m } = n;
  i && (d = i);
  const s = [], u = p && t.animationState && t.animationState.getState()[p];
  for (const o in m) {
    const r = t.getValue(o, (e = t.latestValues[o]) !== null && e !== void 0 ? e : null), c = m[o];
    if (c === void 0 || u && M(u, o))
      continue;
    const l = {
      delay: a,
      elapsed: 0,
      ...P(d || {}, o)
    };
    let g = !1;
    if (window.HandoffAppearAnimations) {
      const T = B(t);
      if (T) {
        const w = window.HandoffAppearAnimations(T, o, r, A);
        w !== null && (l.elapsed = w, g = !0);
      }
    }
    r.start(S(o, r, c, t.shouldReduceMotion && V.has(o) ? { type: !1 } : l, t, g, I(t, o)));
    const h = r.animation;
    h && s.push(h);
  }
  return f && Promise.all(s).then(() => {
    A.update(() => {
      f && H(t, f);
    });
  }), s;
}
export {
  W as animateTarget
};
