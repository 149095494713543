import * as React from 'react';
import { useContext } from 'react';
import { CategoryHeader, FlexCol, LineDivider, Text, ThemeOverride, VirtualList, VirtualListItem, VirtualListRenderItemInfo } from '@knuddels/component-library';
import { ContactListEntry } from './ContactListEntry';
import { expectUnreachable } from '@knuddels/std';
import { DisableScrollContext } from '@shared/helper/DisableScrollContext';
import { ContactsUser } from '../../ContactsUser';
import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
export interface NoUserItemInfo extends VirtualListItem {
  type: typeof typeNoUsers;
  error: string;
  testId?: string;
}
export interface UserEntryItemInfo extends VirtualListItem {
  type: typeof typeUserEntry;
  user: ContactsUser;
  noSubscription?: boolean;
  showNickSwitchIcon?: boolean;
  onClick(): void;
}
export interface GroupHeaderItemInfo extends VirtualListItem {
  type: typeof typeGroupHeader;
  title: string;
}
export interface UserEntryDividerItemInfo extends VirtualListItem {
  type: typeof typeUserEntryDivider;
}
export interface HeaderItemInfo extends VirtualListItem {
  type: typeof typeHeader;
  component: React.ReactElement;
}
export type ContactItemInfo = GroupHeaderItemInfo | NoUserItemInfo | UserEntryItemInfo | UserEntryDividerItemInfo | HeaderItemInfo;
export const typeGroupHeader = 'group-header';
export const typeUserEntry = 'user-entry';
export const typeNoUsers = 'no-users';
export const typeUserEntryDivider = 'group-divider';
export const typeHeader = 'header';
export interface ContactVirtualListProps {
  items: readonly ContactItemInfo[];
}
export const ContactsVirtualListBase: React.FC<ContactVirtualListProps> = ({
  items
}) => {
  const disableScroll = useContext(DisableScrollContext);
  const keyboardService = useService($KeyboardService);
  return <div style={{
    display: 'contents'
  }} onTouchStart={() => {
    keyboardService.dismissKeyboard();
  }}>
			<VirtualList renderItem={renderItem} data={items} gapTop={'base'} disableScroll={disableScroll} className={_c0} />
		</div>;
};
function renderItem({
  item
}: VirtualListRenderItemInfo<ContactItemInfo>): JSX.Element {
  switch (item.type) {
    case typeGroupHeader:
      return renderGroupHeader(item.title);
    case typeUserEntry:
      {
        const {
          user,
          onClick,
          noSubscription,
          showNickSwitchIcon
        } = item;
        return renderUserEntry(user, onClick, noSubscription, showNickSwitchIcon);
      }
    case typeNoUsers:
      return renderNoUsers(item);
    case typeUserEntryDivider:
      return renderDivider();
    case typeHeader:
      return item.component;
    default:
      expectUnreachable(item);
  }
}
function renderGroupHeader(title: string): JSX.Element {
  return <CategoryHeader title={title} />;
}
function renderUserEntry(user: ContactsUser, onClick: () => void, noSubscription?: boolean, showNickSwitchIcon?: boolean): JSX.Element {
  return <div className={_c1}>
			<ContactListEntry contact={user} onClick={onClick} noSubscription={noSubscription} showNickSwitchIcon={showNickSwitchIcon} />
		</div>;
}
function renderNoUsers(item: NoUserItemInfo): JSX.Element {
  return <div className={_c2}>
			<Text className={_c3}>{item.error}</Text>
		</div>;
}
function renderDivider(): JSX.Element {
  return <div className={_c4}>
			<LineDivider className={_c5} />
		</div>;
}
const _c0 = "  ";
const _c1 = " Knu-FlexCol overflow-hidden ";
const _c2 = " Knu-FlexCol mt-xxlarge mx-large ";
const _c3 = " textAlign-center ";
const _c4 = " Knu-FlexCol ml-84px mr-base ";
const _c5 = "  ";