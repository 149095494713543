import { userDataModule } from '@knuddelsModules/UserData/module';
import { channelModule } from '@knuddelsModules/Channel/module';
import { messengerModule } from '@knuddelsModules/Messenger/module';
import { settingsModule } from '@knuddelsModules/Settings/module';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { paymentModule } from '@knuddelsModules/Payment/module';
import { onboardingModule } from '@knuddelsModules/Onboarding/module';
import { fotoMeetModule } from '@knuddelsModules/Fotomeet/module';
import { profileModule } from '@knuddelsModules/Profile/module';
import { channelListModule } from '@knuddelsModules/ChannelList/module';
import { contactsModule } from '@knuddelsModules/Contacts/module';
import { profileVisitorsModule } from '@knuddelsModules/ProfileVisitors/module';
import { loggedInAreaModule } from '@knuddelsModules/LoggedInArea/module';
import { happyMomentModule } from '@knuddelsModules/HappyMoments/module';
import { appsModule } from '@knuddelsModules/Apps/module';
import { featureFlagsModule } from '@knuddelsModules/FeatureFlags/module';
import { knuddelDisplayModule } from '@knuddelsModules/KnuddelDisplay/module';
import { promotionModule } from '@knuddelsModules/Promotions/module';
import { ipDetectionModule } from '@knuddelsModules/IpDetection/module';
import { soundModule } from '@knuddelsModules/Sound/module';
import { systemAppsModule } from '@knuddelsModules/SystemApps/module';
import { deepLinkingModule } from '@knuddelsModules/DeepLinking/module';
import { adsModule } from '@knuddelsModules/Ads/module';

export const knownModules = [
	loggedInAreaModule,
	channelModule,
	messengerModule,
	settingsModule,
	userDataModule,
	notificationsModule,
	paymentModule,
	onboardingModule,
	fotoMeetModule,
	profileModule,
	channelListModule,
	contactsModule,
	profileVisitorsModule,
	happyMomentModule,
	appsModule,
	featureFlagsModule,
	systemAppsModule,
	knuddelDisplayModule,
	promotionModule,
	ipDetectionModule,
	soundModule,
	deepLinkingModule,
	adsModule,
];
