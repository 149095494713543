import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import React from 'react';
import { isTouchDevice } from '@knuddels/component-library';
import { isAppleDevice } from '@knuddels-app/tools/isAppleDevice';
import { autorunWhen } from '@knuddels-app/mobx';

export const useMenuPosition = (
	anchor: React.MutableRefObject<HTMLElement>,
	onClose: () => void
) => {
	const keyboardService = useService($KeyboardService);
	const [initialOpen] = React.useState(() => {
		return (
			keyboardService.anyKeyboardVisible &&
			window.innerHeight - anchor.current.getBoundingClientRect().top >=
				keyboardService.keyboardHeight
		);
	});
	const [shouldRender, setShouldRender] = React.useState(
		initialOpen || !isTouchDevice()
	);

	const position = React.useMemo(() => {
		if (!shouldRender) {
			return null;
		}
		return {
			bottom:
				window.innerHeight - anchor.current.getBoundingClientRect().top,
			left: anchor.current.getBoundingClientRect().left,
		};
	}, [anchor, shouldRender]);

	React.useEffect(() => {
		return autorunWhen(
			{
				name: 'useMenuPosition',
			},
			() => isTouchDevice(),
			() => {
				if (!initialOpen && !shouldRender) {
					if (keyboardService.anyKeyboardVisible) {
						setTimeout(
							() => {
								setShouldRender(true);
							},
							isAppleDevice() ? 500 : 300
						);
					}

					return;
				}
				if (!keyboardService.anyKeyboardVisible) {
					onClose();
				}
			}
		);
	}, [shouldRender]);

	return position;
};
