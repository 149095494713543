import { flushKeyframeResolvers as m } from "../../render/utils/KeyframesResolver.js";
import { instantAnimationState as a } from "../../utils/use-instant-transition-state.js";
import { canAnimate as p } from "./utils/can-animate.js";
import { getFinalKeyframe as v } from "./waapi/utils/get-final-keyframe.js";
class F {
  constructor({ autoplay: e = !0, delay: t = 0, type: i = "keyframes", repeat: o = 0, repeatDelay: r = 0, repeatType: n = "loop", ...s }) {
    this.isStopped = !1, this.hasAttemptedResolve = !1, this.options = {
      autoplay: e,
      delay: t,
      type: i,
      repeat: o,
      repeatDelay: r,
      repeatType: n,
      ...s
    }, this.updateFinishedPromise();
  }
  /**
   * A getter for resolved data. If keyframes are not yet resolved, accessing
   * this.resolved will synchronously flush all pending keyframe resolvers.
   * This is a deoptimisation, but at its worst still batches read/writes.
   */
  get resolved() {
    return !this._resolved && !this.hasAttemptedResolve && m(), this._resolved;
  }
  /**
   * A method to be called when the keyframes resolver completes. This method
   * will check if its possible to run the animation and, if not, skip it.
   * Otherwise, it will call initPlayback on the implementing class.
   */
  onKeyframesResolved(e, t) {
    this.hasAttemptedResolve = !0;
    const { name: i, type: o, velocity: r, delay: n, onComplete: s, onUpdate: h, isGenerator: d } = this.options;
    if (!d && !p(e, i, o, r))
      if (a.current || !n) {
        h == null || h(v(e, this.options, t)), s == null || s(), this.resolveFinishedPromise();
        return;
      } else
        this.options.duration = 0;
    const l = this.initPlayback(e, t);
    l !== !1 && (this._resolved = {
      keyframes: e,
      finalKeyframe: t,
      ...l
    }, this.onPostResolved());
  }
  onPostResolved() {
  }
  /**
   * Allows the returned animation to be awaited or promise-chained. Currently
   * resolves when the animation finishes at all but in a future update could/should
   * reject if its cancels.
   */
  then(e, t) {
    return this.currentFinishedPromise.then(e, t);
  }
  updateFinishedPromise() {
    this.currentFinishedPromise = new Promise((e) => {
      this.resolveFinishedPromise = e;
    });
  }
}
export {
  F as BaseAnimation
};
