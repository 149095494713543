import { Box, FlexCol, FlexProps, Image, ThemeOverride, resolveThemingValue, useTheme, toPointerHandler } from '@knuddels/component-library';
import { usePrivateMessageContext } from '@knuddelsModules/Messenger/bundle/contexts/PrivateMessageContext';
import * as React from 'react';
import { ImageGroupData, ImageGroupImage } from './ImageGroupData';
import { useCommand } from './useCommand';
export interface ImageGroupProps {
  maxWidth: number;
  imageGroupData: ImageGroupData;
  disableLinks?: boolean;
  isSingleSmiley?: boolean;

  /**
   *
   * @param command
   * @return false if the command should be opened as a URL
   */
  onClick(command: string): boolean;
}
export const ImageGroup: React.FC<ImageGroupProps> = props => {
  const [showClickedState, preventClick, onClick] = useOnClick(props);
  const isInsidePrivateMessage = usePrivateMessageContext();
  const containerWidth = props.imageGroupData.containerWidth;
  const actualWidth = Math.min(props.maxWidth, containerWidth);
  const scale = containerWidth > 0 ? actualWidth / containerWidth : 1;
  const scaledHeight = props.imageGroupData.containerHeight * scale;
  const sizeMultiplier = props.isSingleSmiley && isInsidePrivateMessage ? 2 : 1;
  const adjustedWidth = actualWidth * sizeMultiplier;
  const adjustedHeight = scaledHeight * sizeMultiplier;
  return <div style={{
    verticalAlign: props.imageGroupData.baseline ? 'baseline' : 'middle',
    width: resolveThemingValue(adjustedWidth, "sizes", useTheme()),
    height: resolveThemingValue(adjustedHeight, "sizes", useTheme())
  }} className={_c0 + (props.isSingleSmiley ? _c1 : _c2)}>
			{props.imageGroupData.images.map((image, index) => <GroupImage scale={scale * sizeMultiplier} key={index} image={image} group={props.imageGroupData} onClick={onClick} showClickedState={showClickedState} preventClick={props.disableLinks || preventClick} />)}
		</div>;
};
const GroupImage: React.FC<{
  scale: number;
  onClick: (command: string) => boolean;
  image: ImageGroupImage;
  group: ImageGroupData;
  showClickedState: boolean;
  preventClick: boolean;
}> = ({
  onClick,
  scale,
  image,
  group,
  showClickedState,
  preventClick
}) => {
  const url = showClickedState && image.clickUrl ? image.clickUrl : image.url;
  const pw = showClickedState && image.clickPw ? image.clickPw : image.pw;
  const ph = showClickedState && image.clickPh ? image.clickPh : image.ph;
  const commandInfo = useCommand(onClick, image.linkUrl, true);
  const top = group.baseline ? group.containerHeight - ph + image.my : Math.ceil((group.containerHeight - ph) / 2);
  const positionProps: FlexProps = {
    width: pw * scale,
    height: ph * scale,
    position: 'absolute',
    top: (top * scale as ThemeOverride),
    left: (image.mx * scale as ThemeOverride)
  };
  if (!image.linkUrl || preventClick) {
    return <div style={{
      ...positionProps
    }} className={_c3}>
				<Image src={url} alt={''} resizeMode={'contain'} imageRendering={'pixelated'} className={_c4} />
			</div>;
  }
  return <div onClick={toPointerHandler(commandInfo.onClick)} style={{
    ...positionProps
  }} className={_c5}>
			<Image src={url} alt={''} resizeMode={'contain'} imageRendering={'pixelated'} className={_c6} />
		</div>;
};
const clickedImages: string[] = [];
const useOnClick = (props: ImageGroupProps): [boolean, boolean, (command: string) => boolean] => {
  const [showClickedState, setShowClickedState] = React.useState(clickedImages.some(it => it === props.imageGroupData.clickOnceId));
  const onClick = (command: string): boolean => {
    if (props.imageGroupData.clickable) {
      setShowClickedState(true);
      if (props.imageGroupData.clickOnceId) {
        clickedImages.push(props.imageGroupData.clickOnceId);
      }
    }
    return props.onClick(command);
  };
  const preventClick = showClickedState && !!props.imageGroupData.clickOnceId;
  return [showClickedState, preventClick, onClick];
};
const _c0 = " Knu-Box position-relative overflow-visible alignItems-stretch display-inline-block ";
const _c1 = " mb-large ";
const _c2 = " mb-none ";
const _c3 = " Knu-FlexCol position-relative ";
const _c4 = " width-100-percent height-100-percent ";
const _c5 = " Knu-FlexCol cursor-pointer ";
const _c6 = " width-100-percent height-100-percent ";