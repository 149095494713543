import * as React from 'react';
import { HoldToViewSnap } from './HoldToViewSnap';
import { ClientSnapState } from '../../../../services/conversationServices/SnapService';
import { MessageLightboxSource } from '../../Lightbox/Lightbox';
import { observer } from '@knuddels-app/mobx';
import { BasicUser } from '../../../../utils/BasicUser';
import { SnapStaticView } from './SnapStaticView';
import { ImagePermission } from './ImagePermission';
import { ImageOrSnapWithSensitiveContent } from './ImageOrSnapWithSensitiveContent';
type Props = {
  sender: BasicUser;
  sentByActiveUser: boolean;
  size: number;
  snap?: ClientSnapState;
  snapsAllowed?: boolean;
  imageAccepted?: boolean;
  setLightboxSrc?: (src: MessageLightboxSource) => void;
};
export const SnapDisplay: React.FC<Props> = observer('SnapDisplay', ({
  snap,
  snapsAllowed,
  imageAccepted,
  sender,
  setLightboxSrc,
  size,
  sentByActiveUser
}) => {
  if (sentByActiveUser) {
    return <SnapStaticView size={size} type={'sent'} />;
  }
  if (!snapsAllowed && sender) {
    return <ImagePermission size={size} user={sender} snap />;
  }
  if (!imageAccepted && sender) {
    return <ImageOrSnapWithSensitiveContent size={size} image={snap.sensitiveContent} messageId={snap.messageId} sensitiveContentClassification={snap.sensitiveContentClassification} />;
  }
  if (!snap || snap.isAvailable === undefined || !sender) {
    return <SnapStaticView size={size} type={'loading'} />;
  }
  if (!snap.isAvailable) {
    return <SnapStaticView size={size} type={'unavailable'} />;
  } else {
    return <HoldToViewSnap snap={snap} setLightboxSrc={setLightboxSrc} />;
  }
});