function c(n, o) {
  n.min = o.min, n.max = o.max;
}
function t(n, o) {
  c(n.x, o.x), c(n.y, o.y);
}
function i(n, o) {
  n.translate = o.translate, n.scale = o.scale, n.originPoint = o.originPoint, n.origin = o.origin;
}
export {
  i as copyAxisDeltaInto,
  c as copyAxisInto,
  t as copyBoxInto
};
