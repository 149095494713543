import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import React, { useContext } from 'react';
import { $ScreenService } from '@knuddels-app/Screen';
import { InsideOverlayViewContext } from '@knuddels-app/layout';
import { OS, os } from '@shared/components/tools/os';
import { NAV_BAR_BOTTOM_HEIGHT } from '@shared/constants';
import { getSafeAreaBottomHeight } from '@shared/helper/getSafeArea';
import './KeyboardAvoidanceView.css';
import { FlexCol, FallbackBox, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { autorunWhen, observer } from '@knuddels-app/mobx';
import { NativeWebview } from '@knuddels/native-webview-capacitor-plugin';
export type Props = {
  children?: React.ReactNode;
  enabled?: boolean;
  flip?: boolean;
  style?: React.CSSProperties;
  triggerNativeAnimation?: boolean;
};
export const KeyboardAvoidanceView: React.FC<Props & {
  type?: 'move' | 'shrink';
}> = ({
  type = 'move',
  ...props
}) => {
  if (type === 'move') {
    return <MoveKeyboardAvoidanceView {...props} />;
  }
  return <ShrinkKeyboardAvoidanceView {...props} />;
};
const ShrinkKeyboardAvoidanceView: React.FC<Props> = observer('ShrinkKeyboardAvoidanceView', ({
  children,
  enabled = true,
  style
}) => {
  const keyboardService = useService($KeyboardService);
  const heightRef = React.useRef<number>(0);
  return <FlexCol onLayout={e => {
    heightRef.current = e.height;
  }} flex={1} maxHeight={keyboardService.anyKeyboardVisible && enabled ? heightRef.current - keyboardService.keyboardHeight + getSafeAreaBottomHeight() : undefined}
  // @ts-expect-error Not exposed
  style={style}>
				{children}
			</FlexCol>;
});
const isValidTarget = (target: HTMLElement | undefined) => {
  if (!target) {
    return false;
  }
  return target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.hasAttribute('contenteditable');
};
const platform = os === OS.ios ? 'ios' : 'android';
const MoveKeyboardAvoidanceView: React.FC<Props> = observer('MoveKeyboardAvoidanceView', ({
  children,
  enabled = true,
  flip = false,
  style,
  triggerNativeAnimation = false
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const keyboardService = useService($KeyboardService);
  const overlay = useContext(InsideOverlayViewContext);
  const screenService = useService($ScreenService);
  React.useEffect(() => {
    return autorunWhen({
      name: 'Keyboard Avoidance View',
      delay: 1
    }, () => keyboardService.supportsVirtualKeyboard, () => {
      if (keyboardService.anyKeyboardVisible && enabled && (isValidTarget((document.activeElement as HTMLElement)) || keyboardService.currentlyVisibleCustomKeyboard !== 'none')) {
        const paddingBottom = overlay || !screenService.isStackedLayout ? getSafeAreaBottomHeight() : NAV_BAR_BOTTOM_HEIGHT + getSafeAreaBottomHeight();
        if (triggerNativeAnimation) {
          if (os === OS.ios) {
            NativeWebview.animateUp({
              keyboardHeight: keyboardService.keyboardHeight,
              safeAreaHeight: paddingBottom
            });
          }
        }
        const flipValue = flip ? -1 : 1;
        ref.current?.classList.add('up');
        ref.current?.classList.remove('down');
        ref.current?.style.setProperty('--keyboardHeight', (-keyboardService.keyboardHeight + paddingBottom) * flipValue + 'px');
      } else {
        if (triggerNativeAnimation) {
          if (os === OS.ios) {
            NativeWebview.animateDown();
          }
        }
        ref.current?.classList.add('down');
        ref.current?.classList.remove('up');
      }
    });
  }, [enabled]);
  return <div className={'KeyboardAvoidanceView ' + platform} ref={ref} style={style}>
				{children}
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 ";