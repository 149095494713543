import * as React from 'react';
import { createSvgIcon, Flex, Text } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export const PrivateMessageFooter: React.FC = () => {
  return <div className={_c0}>
			<IconLock size={'minor'} />
			<Spacer size={'tiny'} />
			<Text type={'tiny'} state={'tertiary'} className={_c1}>
				<FormattedMessage id={declareFormat({
        id: 'conversation.message-footer.only-for-you',
        defaultFormat: 'Only you can see this message.'
      })} />
			</Text>
		</div>;
};
const IconLock = createSvgIcon(<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.875 3.9375H7.3125V2.8125C7.3125 1.2375 6.075 0 4.5 0C2.925 0 1.6875 1.2375 1.6875 2.8125V3.9375H1.125C0.50625 3.9375 0 4.44375 0 5.0625V10.6875C0 11.3062 0.50625 11.8125 1.125 11.8125H7.875C8.49375 11.8125 9 11.3062 9 10.6875V5.0625C9 4.44375 8.49375 3.9375 7.875 3.9375ZM4.5 9C3.88125 9 3.375 8.49375 3.375 7.875C3.375 7.25625 3.88125 6.75 4.5 6.75C5.11875 6.75 5.625 7.25625 5.625 7.875C5.625 8.49375 5.11875 9 4.5 9ZM6.24375 3.9375H2.75625V2.8125C2.75625 1.85625 3.54375 1.06875 4.5 1.06875C5.45625 1.06875 6.24375 1.85625 6.24375 2.8125V3.9375Z" fill="currentColor" />
	</svg>);
IconLock.displayName = 'IconLock';
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";