import * as React from 'react';
import { BoxProps, Text, resolveThemingValue, useTheme } from '@knuddels/component-library';
export const MacroBoxCategory: React.FC<{
  mt?: BoxProps['mt'];
  title: string;
}> = ({
  mt,
  title,
  children
}) => {
  return <>
			<Text type={'tiny'} bold={true} state={'tertiary'} style={{
      marginTop: resolveThemingValue(mt, "spacing", useTheme())
    }} className={_c0}>
				{title.toUpperCase()}
			</Text>
			{children}
		</>;
};
const _c0 = " mb-minor ";