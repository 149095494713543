const t = () => ({
  translate: 0,
  scale: 1,
  origin: 0,
  originPoint: 0
}), a = () => ({
  x: t(),
  y: t()
}), e = () => ({ min: 0, max: 0 }), c = () => ({
  x: e(),
  y: e()
});
export {
  e as createAxis,
  t as createAxisDelta,
  c as createBox,
  a as createDelta
};
