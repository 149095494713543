import { ClientInfoInterface } from '@knuddels-app/tools/clientInformation/ClientInfo.interface';

export function getAppDownloadLinkForCurrentPlatform(
	clientInfo: ClientInfoInterface
): string | undefined {
	const osInfo = clientInfo.osInfo.value;
	if (!osInfo) {
		return undefined;
	}

	if (clientInfo.platform === 'web') {
		const downloadLinks: {
			[key in typeof osInfo.type]: string | undefined;
		} = {
			unknown: 'https://www.knuddels.de/stapp',
			android: 'market://details?id=com.knuddels.android',
			ios:
				'itms-apps://itunes.apple.com/de/app/knuddels-chat-und-freunde/id369409839',
			windows: 'https://downloads.knuddels.de/KnuddelsStAppSetup.exe',
			linux: 'https://www.knuddels.de/stapp-lin/download',
			macos: 'https://www.knuddels.de/stapp-mac/download',
		};
		const link = downloadLinks[osInfo.type];
		return link;
	}
	return undefined;
}
