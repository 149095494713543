import { scrapeMotionValuesFromProps as s } from "./utils/scrape-motion-values.js";
import { DOMVisualElement as a } from "../dom/DOMVisualElement.js";
import { buildSVGAttrs as m } from "./utils/build-attrs.js";
import { camelToDash as i } from "../dom/utils/camel-to-dash.js";
import { camelCaseAttributes as p } from "./utils/camel-case-attrs.js";
import { transformProps as u } from "../html/utils/transform.js";
import { renderSVG as n } from "./utils/render.js";
import { getDefaultValueType as l } from "../dom/value-types/defaults.js";
import { createBox as f } from "../../projection/geometry/models.js";
import { isSVGTag as V } from "./utils/is-svg-tag.js";
class A extends a {
  constructor() {
    super(...arguments), this.type = "svg", this.isSVGTag = !1, this.measureInstanceViewportBox = f;
  }
  getBaseTargetFromProps(t, r) {
    return t[r];
  }
  readValueFromInstance(t, r) {
    if (u.has(r)) {
      const e = l(r);
      return e && e.default || 0;
    }
    return r = p.has(r) ? r : i(r), t.getAttribute(r);
  }
  scrapeMotionValuesFromProps(t, r, e) {
    return s(t, r, e);
  }
  build(t, r, e) {
    m(t, r, this.isSVGTag, e.transformTemplate);
  }
  renderInstance(t, r, e, o) {
    n(t, r, e, o);
  }
  mount(t) {
    this.isSVGTag = V(t.tagName), super.mount(t);
  }
}
export {
  A as SVGVisualElement
};
