import { OsInfo } from './osInfo.interface';
import * as platform from 'platform';
import { Deferred } from '@knuddels/std';

export const getOsInfo: () => Deferred<OsInfo> = () => {
	const result = new Deferred<OsInfo>();
	result.resolve({
		type: getOsType(),
		version: getStringifiedOs(),
	});
	return result;
};

function getOsType(): OsInfo['type'] {
	const os = getStringifiedOs().toLowerCase();
	const names: { [TKey in OsInfo['type']]: string[] } = {
		windows: ['windows'],
		linux: ['debian', 'ubuntu', 'fedora', 'red hat', 'suse'],
		android: ['android'],
		ios: ['ios'],
		macos: ['os x'],
		unknown: [],
	};

	for (const name of Object.keys(names)) {
		const type = (name as unknown) as OsInfo['type'];
		const patterns = names[type];
		if (patterns.some(p => os.indexOf(p) !== -1)) {
			return type;
		}
	}

	return 'unknown';
	// return 'linux'; // maybe default as there are too many linux distros.
}

function getStringifiedOs(): string {
	return platform.os ? platform.os.toString() : '';
}
