import * as React from 'react';
import { TypeaheadMenu } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/TypeaheadMenu';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FLING_UP_COMMAND, SUBMIT_COMMAND } from '@shared/components/RichText/RichTextEditor';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, COMMAND_PRIORITY_NORMAL, EditorState, KEY_ARROW_DOWN_COMMAND, KEY_ARROW_UP_COMMAND, KEY_DOWN_COMMAND, KEY_ENTER_COMMAND } from 'lexical';
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { CssAnimatePresence } from '@shared/components/RichText/CSSAnimatePrecense';
import { Text } from '@knuddels/component-library';
class HistoryOption extends MenuOption {
  constructor(public text: string, public state: EditorState) {
    super(text);
  }
}
const HISTORY_SIZE = 5;
const ALLOWED_KEYS = ['Enter', 'ArrowUp', 'ArrowDown'];
const history: {
  text: string;
  state: EditorState;
}[] = [];
const normalizeText = (text: string) => {
  const withoutWhitespaces = text.replace(/\s+/g, ' ');
  return withoutWhitespaces.replace(/\$\$(.+)\$\$/, '$1');
};
const InnerHistoryPlugin: React.FC<{
  anchor: React.MutableRefObject<HTMLElement>;
}> = ({
  anchor
}) => {
  const openRef = React.useRef(false);
  const [editor] = useLexicalComposerContext();
  const selectedIndexRef = React.useRef(-1);
  const highlightedIndexRef = React.useRef(-1);
  const rerender = React.useState({})[1];
  const handleOptionClick = (state: EditorState) => {
    editor.update(() => {
      editor.setEditorState(state);
    });
    selectedIndexRef.current = -1;
    highlightedIndexRef.current = -1;
    openRef.current = false;
    rerender({});
  };
  React.useEffect(() => {
    const removeListeners = [editor.registerCommand(KEY_ARROW_UP_COMMAND, e => {
      if (!openRef.current && history.length > 0) {
        openRef.current = true;
        selectedIndexRef.current = history.length - 1;
        rerender({});
      } else if (history.length) {
        selectedIndexRef.current = selectedIndexRef.current - 1 >= 0 ? selectedIndexRef.current - 1 : history.length - 1;
        rerender({});
        e.preventDefault();
      }
      return true;
    }, COMMAND_PRIORITY_NORMAL), editor.registerCommand(KEY_ENTER_COMMAND, e => {
      if (!openRef.current || selectedIndexRef.current === -1) {
        return false;
      }
      handleOptionClick(history[selectedIndexRef.current].state);
      e!.preventDefault();
      return true;
    }, COMMAND_PRIORITY_NORMAL), editor.registerCommand(SUBMIT_COMMAND, payload => {
      const normalizedText = normalizeText(payload.text);
      const historyEntryIndex = history.findIndex(entry => entry.text === normalizedText);
      if (historyEntryIndex !== -1) {
        history.splice(historyEntryIndex, 1);
        history.push({
          ...payload,
          text: normalizedText
        });
        return false;
      }
      if (history.length >= HISTORY_SIZE) {
        history.shift();
      }
      history.push({
        ...payload,
        text: normalizedText
      });
      return false;
    }, COMMAND_PRIORITY_LOW), editor.registerCommand(KEY_ARROW_DOWN_COMMAND, e => {
      if (!openRef.current) {
        return false;
      }
      selectedIndexRef.current = (selectedIndexRef.current + 1) % history.length;
      rerender({});
      e.preventDefault();
      return true;
    }, COMMAND_PRIORITY_NORMAL), editor.registerCommand(BLUR_COMMAND, () => {
      setTimeout(() => {
        openRef.current = false;
        rerender({});
      }, 100);
      return false;
    }, COMMAND_PRIORITY_NORMAL), editor.registerCommand(FLING_UP_COMMAND, () => {
      if (history.length === 0) {
        return false;
      }
      openRef.current = true;
      rerender({});
      return false;
    }, COMMAND_PRIORITY_NORMAL), editor.registerCommand(KEY_DOWN_COMMAND, e => {
      if (!openRef.current) {
        return false;
      }
      if (ALLOWED_KEYS.includes(e.key)) {
        return false;
      }
      openRef.current = false;
      rerender({});
      return false;
    }, COMMAND_PRIORITY_LOW)];
    return () => {
      removeListeners.forEach(remove => remove());
    };
  }, []);
  return <CssAnimatePresence>
			{!openRef.current ? null : <TypeaheadMenu<HistoryOption> onClose={() => {
      openRef.current = false;
      rerender({});
    }} options={history.map(({
      text,
      state
    }) => new HistoryOption(text, state))} itemProps={{
      highlightedIndex: highlightedIndexRef.current,
      selectedIndex: selectedIndexRef.current,
      setHighlightedIndex(index): void {
        highlightedIndexRef.current = index;
        rerender({});
      },
      selectOptionAndCleanUp(option): void {
        handleOptionClick(option.state);
      }
    }} anchor={anchor} renderItem={option => <Text numberOfLines={1} className={_c0}>{option.text}</Text>} />}
		</CssAnimatePresence>;
};
export const HistoryPlugin = React.memo(InnerHistoryPlugin);
HistoryPlugin.displayName = 'HistoryPlugin';
export function clearChatHistory(): void {
  history.length = 0;
}
const _c0 = "  ";