import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { $ViewProvider } from '@knuddels-app/layout';
import { AppModalViewProvider } from './AppModalViewProvider';
import { paymentModule } from '@knuddelsModules/Payment/module';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { loggedInAreaModule } from '@knuddelsModules/LoggedInArea/module';
import { settingsModule } from '@knuddelsModules/Settings/module';

export const appsModule = new Module({
	name: 'Apps module',
	dependencies: [
		userDataModule,
		paymentModule,
		notificationsModule,
		loggedInAreaModule,
		settingsModule,
	],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(AppModalViewProvider);
	},
});
