import { Container } from 'inversify';

// We need to access internals of inversify here.
// There is an open github issue for requesting an API for that:
// https://github.com/inversify/InversifyJS/issues/1097
/**
 * Gets a list of all services of the container that have been instantiated as singleton.
 * Non-instantiated services are not returned.
 */
export function getCachedSingletons(container: Container): unknown[] {
	const c = container as any;
	const result = new Array<unknown>();
	for (const v of c._bindingDictionary._map.values()) {
		for (const binding of v) {
			const cached = binding.cache as unknown;
			if (cached) {
				result.push(cached);
			}
		}
	}
	return result;
}
