import * as React from 'react';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { formatMessage } from '@knuddels-app/i18n';
import { conversationOverviewMenuEvent } from '../../analytics';
import { GetMessengerConversationVisibilityProps, MessengerConversation, withGetMessengerConversationVisibility } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { DELETE_CHAT, RESTORE_CHAT } from '../../i18n/shared-formats';
import { $MessengerService } from '@knuddelsModules/Messenger/providedServices';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { IconUndo } from '@knuddels/component-library';
import { IconArchive } from '@shared/icons/IconArchive';
interface ArchivedConversationContextMenuProps extends ContextMenuProps {
  conversationId: MessengerConversation['id'];
}
type Props = ArchivedConversationContextMenuProps & GetMessengerConversationVisibilityProps;
@injectable()
class ArchivedConversationContextMenuModel {
  constructor(@injectProps()
  private readonly props: Props, @inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($SnackbarService)
  public readonly snackbarService: typeof $SnackbarService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  public deleteConversation = (): void => {
    conversationOverviewMenuEvent.track('ConversationMenu_DeleteChat');
    this.messengerService.deleteConversation(this.props.conversationId);
    this.closeContextMenu();
  };
  public restoreConversation = (): void => {
    conversationOverviewMenuEvent.track('ConversationMenu_RestoreChat');
    this.messengerService.restoreConversation(this.props.conversationId);
    this.closeContextMenu();
  };
  public closeContextMenu = (): void => {
    this.thisVisibleOverlay.dispose();
  };
}
export const ArchivedConversationContextMenuBase = injectedComponent({
  name: 'ArchivedConversationContextMenuBase',
  model: ArchivedConversationContextMenuModel,
  props: declareProps<Props>()
}, ({
  model,
  data,
  chevronHeight,
  chevronWidth,
  chevronX,
  chevronY
}) => {
  if (data.error) {
    model.snackbarService.showGenericError();
    model.closeContextMenu();
    return null;
  }
  if (data.loading) {
    return null;
  }
  return <ContextMenu closeContextMenu={model.closeContextMenu} chevronHeight={chevronHeight} chevronWidth={chevronWidth} chevronX={chevronX} chevronY={chevronY}>
				<ContextMenuEntry icon={IconArchive} text={formatMessage(DELETE_CHAT)} onClick={model.deleteConversation} />
				<ContextMenuEntry icon={IconUndo} text={formatMessage(RESTORE_CHAT)} onClick={model.restoreConversation} />
			</ContextMenu>;
});
const ArchivedConversationContextMenu = withGetMessengerConversationVisibility<ArchivedConversationContextMenuProps>({
  options: props => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: props.conversationId
    }
  })
})(ArchivedConversationContextMenuBase);
export const ArchivedConversationContextMenuOverlay = new OverlayFactory(ArchivedConversationContextMenu, props => ({
  conversationId: props.conversationId
}));