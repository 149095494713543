import { jsx as i, Fragment as a } from "react/jsx-runtime";
import * as o from "react";
import { AnimationConfig as r } from "../Animation/AnimationConfig.js";
import { clsx as m } from "../../node_modules/clsx/dist/clsx.m.js";
const c = 150, v = (t) => /* @__PURE__ */ i(a, { children: o.Children.map(t.children, (n, e) => /* @__PURE__ */ i(
  "div",
  {
    style: {
      "--scale": t.activeIndex === e ? 1 : 0,
      "--time": r.snappy.time + "ms",
      "--delay": (t.activeIndex === e ? c : 0) + "ms",
      zIndex: t.activeIndex === e ? t.zIndex ?? 1 : void 0,
      ...t.style
    },
    className: m(
      "Knu-Scale",
      t.activeIndex !== e && "pointerEvents-none",
      t.anchor === "bottom-right" ? "position-absolute bottom-large right-large" : "position-relative"
    ),
    children: n
  }
)) });
export {
  v as FabTransition
};
