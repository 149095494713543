import * as React from 'react';
import { User, UserIsOnlineProps, withUserIsOnline } from '@generated/graphql';
import { StatusBadge, StatusBadgeProps } from '@shared/components';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $UserOnlineStatusService } from '../../providedServices';
import { ThemeColors } from '@knuddels/component-library';
type Props = StatusBadgeProps & {
  userId: User['id'];
};
@injectable()
class UserOnlineStatusBadgeModel {
  constructor(@injectProps()
  private readonly props: Pick<Props, 'userId'> & UserIsOnlineProps, @inject($UserOnlineStatusService)
  private readonly userOnlineStatusService: typeof $UserOnlineStatusService.T) {
    // currently we intentionally don't unsubscribe, because we don't want to miss updates
    // and having to requery the current online status.
    this.userOnlineStatusService.subscribeToOnlineStatus(this.props.userId);
  }
  public get isOnline(): boolean {
    const {
      user: userScope
    } = this.props.data;
    return userScope && userScope.user && userScope.user.isOnline || false;
  }
}

/**
 * This component expects that a query has already been executed for userIsOnline
 */
export const UserOnlineStatusBadge = withUserIsOnline<Props>({
  options: props => ({
    fetchPolicy: 'cache-first',
    variables: {
      id: props.userId
    }
  })
})(injectedComponent({
  name: 'UserOnlineStatusBadge',
  model: UserOnlineStatusBadgeModel,
  props: declareProps<Props & UserIsOnlineProps & {
    parentBg?: string | ThemeColors;
  }>()
}, ({
  model,
  ...props
}) => {
  return model.isOnline ? <StatusBadge {...props} /> : null;
}));