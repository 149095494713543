import { NicklistColor } from '@generated/graphql';
import * as React from 'react';
import { Text } from '@knuddels/component-library';
import { ChannelColorMode } from '../../services/channel/calculateChannelColorMode';
import { OS, os } from '@shared/components/tools/os';
import './NickListEntry.css';
import { useService } from '@knuddels-app/DependencyInjection';
import { $CoreCommands } from '@knuddels-app/Commands';
export const NickListText: React.FC<{
  bold: boolean;
  color?: NicklistColor;
  colorMode: ChannelColorMode;
}> = props => {
  const coreCommands = useService($CoreCommands);
  const statusColor = props.colorMode === 'dark' ? nicklistDarkColorMap[props.color] : nicklistLightColorMap[props.color];
  const style = {
    ...(statusColor ? {
      style: {
        color: statusColor,
        ...nowrapStyle
      }
    } : {
      style: nowrapStyle
    })
  };
  const OldVersion = <Text extraBold={props.bold}
  // @ts-expect-error Not exposed
  style={{
    ...style.style
  }} className={_c0}>
			{props.children}
		</Text>;
  if (!coreCommands.isNicklistEffectTestEnabled) {
    return OldVersion;
  }
  return <Text extraBold={props.bold}
  // @ts-expect-error not exposed
  style={{
    ...style.style
  }} className={_c1}>
			{(props.children as string).split('').map((char, index) => <span key={index} className="wavy-text" style={({
      '--char-index': index,
      '--nicklist-color': statusColor
    } as React.CSSProperties)}>
					{char}
				</span>)}
		</Text>;
};
const nowrapStyle = os === OS.web ? {
  whiteSpace: 'nowrap'
} : {};
const nicklistDarkColorMap: Record<NicklistColor, string | undefined> = {
  [NicklistColor.Gold]: 'rgba(255, 240, 150, 1)',
  [NicklistColor.Red]: 'rgba(244, 113, 113, 1)',
  [NicklistColor.Green]: 'rgba(106, 225, 200, 1)',
  [NicklistColor.Blue]: 'rgba(120, 140, 239, 1)',
  [NicklistColor.Default]: undefined
};
const nicklistLightColorMap: Record<NicklistColor, string | undefined> = {
  [NicklistColor.Gold]: 'rgba(143, 121, 0, 1)',
  [NicklistColor.Red]: 'rgba(209, 63, 81, 1)',
  [NicklistColor.Green]: 'rgba(28, 155, 129, 1)',
  [NicklistColor.Blue]: 'rgba(65, 95, 241, 1)',
  [NicklistColor.Default]: undefined
};
const _c0 = "  ";
const _c1 = "  ";