import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { formatMessage } from '@knuddels-app/i18n';
import {
	IMAGE_ERROR_BLOCKED_NOT_TITLE,
	IMAGE_ERROR_BLOCKED_NOT_YET_TITLE,
	IMAGE_ERROR_BLOCKED_PING_PONG_SUBTEXT,
	IMAGE_ERROR_BLOCKED_SUBTEXT,
	IMAGE_ERROR_NOT_ALLOWED_SUBTEXT,
	IMAGE_ERROR_UNAVAILABLE_SUBTEXT,
} from '../i18n/shared-formats';

import sorry from '@shared/images/sorry.gif';

export const SnackbarDefinitionImageErrorBlockedPingPong: SnackbarData = {
	type: 'imageErrorBlockedPingPong',
	text: () => formatMessage(IMAGE_ERROR_BLOCKED_NOT_YET_TITLE),
	subtext: () => formatMessage(IMAGE_ERROR_BLOCKED_PING_PONG_SUBTEXT),
	adornment: sorry,
};

export const SnackbarDefinitionImageErrorNotAllowed: SnackbarData = {
	type: 'imageErrorNotAllowed',
	text: () => formatMessage(IMAGE_ERROR_BLOCKED_NOT_YET_TITLE),
	subtext: () => formatMessage(IMAGE_ERROR_NOT_ALLOWED_SUBTEXT),
	adornment: sorry,
};

export const SnackbarDefinitionImageErrorBlocked: SnackbarData = {
	type: 'imageErrorBlocked',
	text: () => formatMessage(IMAGE_ERROR_BLOCKED_NOT_TITLE),
	subtext: () => formatMessage(IMAGE_ERROR_BLOCKED_SUBTEXT),
	adornment: sorry,
};

export const SnackbarDefinitionImageErrorUnavailable: SnackbarData = {
	type: 'imageErrorUnavailable',
	text: () => formatMessage(IMAGE_ERROR_BLOCKED_NOT_TITLE),
	subtext: () => formatMessage(IMAGE_ERROR_UNAVAILABLE_SUBTEXT),
	adornment: sorry,
};
