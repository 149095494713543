const s = () => new e();
class e {
  constructor() {
    this.bindings = /* @__PURE__ */ new Map(), this.currentBuffer = [], this.handle = (r) => {
      this.bindings.has(r.key) && (r.preventDefault(), this.bindings.get(r.key)());
    };
  }
  map(r) {
    return this.currentBuffer = [], this.currentBuffer.push(r), this;
  }
  and(r) {
    return this.currentBuffer.push(r), this;
  }
  to(r) {
    return this.currentBuffer.forEach((t) => {
      this.bindings.set(t, r);
    }), this;
  }
}
export {
  s as createKeyboardHandler
};
