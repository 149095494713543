import { HTMLProjectionNode as o } from "../../projection/node/HTMLProjectionNode.js";
import { MeasureLayout as t } from "./layout/MeasureLayout.js";
const a = {
  layout: {
    ProjectionNode: o,
    MeasureLayout: t
  }
};
export {
  a as layout
};
