import * as React from 'react';
import { $OverlayService, OverlayFactory } from '@knuddels-app/overlays';
import { ContextMenuProps } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { ContactsMenuContext } from './ContactsMenuContext';
import { ContactsTab } from '../ContactTabs';
import { ContactsUser } from '../../../ContactsUser';
import { $ContactsService } from '@knuddelsModules/Contacts';
export const createMenuContextProvider = <TProps, TTag>(factory: OverlayFactory<Omit<TProps, 'children'> & {
  userId: string;
  menuEntriesToShow: ContactsTab[];
} & ContextMenuProps, TTag>, getMenuEntriesToShow: (user: ContactsUser, contactService: typeof $ContactsService.T) => ContactsTab[]): React.FC<TProps> => {
  // eslint-disable-next-line react/display-name
  return ({
    children,
    ...props
  }: TProps & {
    children: React.ReactNode;
  }) => {
    const [openMenuId, setOpenMenuId] = React.useState<string | undefined>(undefined);
    const overlayService = useService($OverlayService);
    return <ContactsMenuContext.Provider value={{
      isActive: true,
      getMenuEntriesToShow,
      openMenuForUserId: openMenuId,
      openMenu: (userId: string, menuEntriesToShow: ContactsTab[], layout: {
        x: number;
        y: number;
        width: number;
        height: number;
      }) => {
        setOpenMenuId(userId);
        overlayService.showOverlayIfNotVisible(factory, {
          userId,
          menuEntriesToShow,
          chevronX: layout.x,
          chevronY: layout.y,
          chevronWidth: layout.width,
          chevronHeight: layout.height,
          ...props
        }).onClosed.then(() => {
          setOpenMenuId(undefined);
        });
      }
    }}>
				{children}
			</ContactsMenuContext.Provider>;
  };
};