import { useMutation } from '@knuddels-app/Connection';
import { DisableFriendNotification, DisableSensitiveContentWarning, DisableWatchlistNotification, EnableFriendNotification, EnableSensitiveContentWarning, EnableWatchlistNotification, NotificationEnabledInput, UpdateFriendNotificationEnabledStatus, UpdateWatchlistNotificationEnabledStatus } from '@generated/graphql';
import { useTrackMessengerSettings } from './useTrackMessengerSettings';
export const useUpdateMutations = () => {
  const track = useTrackMessengerSettings();
  const [doUpdateFriendStatus] = useMutation(UpdateFriendNotificationEnabledStatus);
  const [doUpdateWatchlistStatus] = useMutation(UpdateWatchlistNotificationEnabledStatus);
  const [enableFriendStatus] = useMutation(EnableFriendNotification);
  const [disableFriendStatus] = useMutation(DisableFriendNotification);
  const [enableWatchlistStatus] = useMutation(EnableWatchlistNotification);
  const [disableWatchlistStatus] = useMutation(DisableWatchlistNotification);
  const [enableSensitiveContentWarning] = useMutation(EnableSensitiveContentWarning);
  const [disableSensitiveContentWarning] = useMutation(DisableSensitiveContentWarning);
  const updateFriendStatus = async (input: NotificationEnabledInput) => {
    return doUpdateFriendStatus({
      status: input
    }).then(r => r.data?.__typename === 'Success').catch(() => false);
  };
  const updateWatchlistStatus = async (input: NotificationEnabledInput) => {
    return doUpdateWatchlistStatus({
      status: input
    }).then(r => r.data?.__typename === 'Success').catch(() => false);
  };
  const setFriendStatusEnabled = async (enabled: boolean) => {
    if (enabled) {
      return enableFriendStatus({}).then(r => r.data?.__typename === 'Success').catch(() => false);
    } else {
      return disableFriendStatus({}).then(r => r.data?.__typename === 'Success').catch(() => false);
    }
  };
  const setWatchlistStatusEnabled = async (enabled: boolean) => {
    if (enabled) {
      return enableWatchlistStatus({}).then(r => r.data?.__typename === 'Success').catch(() => false);
    } else {
      return disableWatchlistStatus({}).then(r => r.data?.__typename === 'Success').catch(() => false);
    }
  };
  const setSensitiveContentWarningEnabled = async (enabled: boolean) => {
    track(`AiImageFilter_${enabled ? 'Activated' : 'Deactivated'}`);
    if (enabled) {
      return enableSensitiveContentWarning({}).then(r => r.data?.__typename === 'Success').catch(() => false);
    } else {
      return disableSensitiveContentWarning({}).then(r => r.data?.__typename === 'Success').catch(() => false);
    }
  };
  return {
    updateFriendStatus,
    updateWatchlistStatus,
    setFriendStatusEnabled,
    setWatchlistStatusEnabled,
    setSensitiveContentWarningEnabled
  };
};