import { getDateDifference, getFormattedDate } from './getDateDifference';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';

export const uesFormatLastOnlineTime = (timestamp: number): string => {
	const now = Date.now();
	const dateDifference = getDateDifference(timestamp);
	const i18n = useService($I18n);

	if (dateDifference === 0) {
		const age = now - timestamp;
		if (age < 60 * 60 * 1000) {
			const minutes = Math.floor(age / 60 / 1000);
			return i18n.format(
				declareFormat({
					id: 'core.last-online-time.today.relative-minutes',
					defaultFormat: '{minutes} min ago',
				}),
				{ minutes }
			);
		} else {
			const hours = Math.floor(age / 60 / 60 / 1000);

			return i18n.format(
				declareFormat({
					id: 'core.last-online-time.today.relative-hours',
					defaultFormat: '{hours}h ago',
				}),
				{ hours }
			);
		}
	}

	if (dateDifference > 14) {
		return i18n.format(
			declareFormat({
				id: 'core.last-online-time.absolute-date',
				defaultFormat: 'on {date}',
			}),
			{ date: getFormattedDate(timestamp, 0) }
		);
	}

	return i18n.formatDateTimeRelative(timestamp, 'day', { numeric: 'auto' });
};
