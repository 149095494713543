import {
	ChannelMessageFragment,
	NicklistColor,
	NicklistIconFragment,
	NicklistIconType,
	Scalars,
	SendMessage,
	User,
} from '@generated/graphql';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { K3ApolloClient } from '@knuddels-app/Connection';
import { $I18n } from '@knuddels-app/i18n';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { lastOrUndefined, Result } from '@knuddels/std';
import { defaultGroupInfo } from '../../components/Chat/defaultActiveChannel';
import {
	ActiveChannelData,
	ActiveChannelMessage,
} from './ActiveChannelService';
import {
	calculateChannelColorMode,
	ChannelColorMode,
} from './calculateChannelColorMode';
import { WaitingOrderingQueue } from './WaitingOrderingQueue';
import { ChatItem } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChatItem';
import { debounce } from '@shared/components';

type NicklistChange =
	| {
			kind: 'add';
			index: number;
			participant: ActiveChannelData['participants'][0];
	  }
	| {
			kind: 'remove';
			index: number;
			userId: Scalars['ID'];
	  }
	| {
			kind: 'move';
			fromIndex: number;
			toIndex: number;
			userId: Scalars['ID'];
	  };

export class ChannelInfo {
	public readonly nicklistState = new NicklistStateHelper();

	public nicklistChangeListener: (
		changes: NicklistChange[],
		newParticipants: ActiveChannelData['participants']
	) => void = () => {};

	public firstMessageInBackground: ActiveChannelMessage | null = null;

	private pendingChanges: NicklistChange[] = [];

	private readonly messages = new ChannelMessages(
		this.firebaseAnalyticsService
	);

	private readonly botMessages = new ChannelBotMessages();

	@observable.shallow private readonly _participants = new Map<
		ActiveChannelData['participants'][0]['user']['id'],
		ActiveChannelData['participants'][0]
	>();

	private readonly _participantsByPriority = new Map<
		number,
		ActiveChannelData['participants'][0][]
	>();

	@observable.shallow
	private _firstUnreadMessage: ChatItem | null = null;

	@observable
	private _sortedParticipants: ActiveChannelData['participants'][0][] = [];

	@observable private _name: string;
	@observable private _groupInfo: ActiveChannelData['groupInfo'];

	@observable private _lastRenderedMessageId: Scalars['ID'] | undefined;

	get firstUnreadMessage(): ChatItem | null {
		return this._firstUnreadMessage;
	}

	set firstUnreadMessage(value: ChatItem | null) {
		this._firstUnreadMessage = value;
	}

	get participants(): ReadonlyMap<
		ActiveChannelData['participants'][0]['user']['id'],
		ActiveChannelData['participants'][0]
	> {
		return this._participants;
	}

	get sortedParticipants(): ActiveChannelData['participants'] {
		return this._sortedParticipants;
	}

	public get name(): string {
		return this._name;
	}

	public get mainChannelName(): string {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, subId] = this.id.split(':');
		/**
		 * ids are in the format of `channelId:subId`
		 * if subId is 1, it's the main channel
		 * if subId is > 1, it's a subchannel
		 */
		if (Number(subId) > 1) {
			const channelNameParts = this.name.split(' ');
			channelNameParts.pop();
			return channelNameParts.join(' ');
		}
		return this.name;
	}

	public get colorMode(): ChannelColorMode {
		const { backgroundColor } = this.groupInfo || defaultGroupInfo;
		return calculateChannelColorMode(backgroundColor);
	}

	public get groupInfo(): ActiveChannelData['groupInfo'] {
		return this._groupInfo;
	}

	constructor(
		public readonly id: string,
		private readonly k3ApolloClient: K3ApolloClient,
		private readonly i18n: typeof $I18n.T,
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {}

	public setNicklistChangeListener = (
		listener: (
			changes: NicklistChange[],
			newParticipants: ActiveChannelData['participants']
		) => void
	): void => {
		this.pendingChanges = [];
		this.nicklistChangeListener = listener;
		this.nicklistChangeListener([], this.sortedParticipants);
	};

	private applyPendingChanges = debounce(() => {
		const changes = this.pendingChanges;
		this.pendingChanges = [];
		this.nicklistChangeListener(changes, this.sortedParticipants);
	}, 500);

	public clearFirstMessageInBackground(): void {
		this.firstMessageInBackground = null;
	}

	public async send(text: string): Promise<Result<void, unknown>> {
		const r = await this.k3ApolloClient.mutateWithResultPromise(
			SendMessage,
			{
				channelId: this.id,
				text: text,
			}
		);
		return r.map(() => undefined);
	}

	@action
	public markMessagesRendered(): void {
		const lastMessage = lastOrUndefined(this.messages.messages);

		if (lastMessage && this._lastRenderedMessageId === lastMessage.id) {
			this._lastRenderedMessageId = undefined;
		}
	}

	@action
	updateData(data: ActiveChannelData): void {
		this._name = data.name;

		this._participants.clear();
		this._participantsByPriority.clear();
		for (const participant of data.participants) {
			this._participants.set(participant.user.id, participant);
			this.insertParticipantWithPriority(participant);
		}

		this.updateSortedParticipants();

		this._groupInfo = data.groupInfo;
	}

	getMessages(): ReadonlyArray<ActiveChannelMessage> {
		return this.messages.messages;
	}

	getBotMessages(): ReadonlyArray<ActiveChannelMessage> {
		return this.botMessages.messages;
	}

	addMessage(message: ActiveChannelMessage, inBackground = false): void {
		if (inBackground && !this.firstMessageInBackground) {
			this.firstMessageInBackground = message;
		}
		this.messages.add(message);
	}

	addBotMessage(message: ActiveChannelMessage): void {
		this.botMessages.add(message);
	}

	@action
	clearMessages(): void {
		this.messages.clear();
		this._lastRenderedMessageId = undefined;
	}

	@action
	addUser(user: ActiveChannelData['participants'][0]): void {
		if (this._participants.has(user.user.id)) {
			return;
		}

		this._participants.set(user.user.id, user);
		this.insertParticipantWithPriority(user);
		this.updateSortedParticipants();

		const index = this.sortedParticipants.findIndex(
			it => it.user.id === user.user.id
		);
		this.pendingChanges.push({
			kind: 'add',
			index,
			participant: user,
		});
		this.applyPendingChanges();
	}

	@action.bound
	private updateSortedParticipants(): void {
		const priorities = [...this._participantsByPriority.keys()].sort(
			(a, b) => b - a
		);

		const participants = [];
		for (const priority of priorities) {
			participants.push(...this._participantsByPriority.get(priority));
		}

		this._sortedParticipants = participants;
	}

	@action
	updatePriority(
		userId: ActiveChannelData['participants'][0]['user']['id'],
		priority: number
	): void {
		const participant = this._participants.get(userId);
		if (!participant) {
			return;
		}

		const previousPriority = participant.nicklistPriority || 0;

		const newParticipant = {
			...participant,
			nicklistPriority: priority,
		};

		this._participants.set(userId, newParticipant);

		this.removeUserWithPriority(userId, previousPriority);
		this.insertParticipantWithPriority(newParticipant);

		const fromIndex = this.sortedParticipants.findIndex(
			it => it.user.id === userId
		);
		this.updateSortedParticipants();
		const toIndex = this.sortedParticipants.findIndex(
			it => it.user.id === userId
		);

		if (fromIndex !== toIndex) {
			this.pendingChanges.push({
				kind: 'move',
				fromIndex,
				toIndex,
				userId,
			});
			this.applyPendingChanges();
		}
	}

	@action
	removeUser(userId: User['id']): void {
		const participant = this._participants.get(userId);
		if (!participant) {
			return;
		}

		const priority = participant.nicklistPriority || 0;
		this.removeUserWithPriority(userId, priority);
		this._participants.delete(userId);

		const index = this.sortedParticipants.findIndex(
			value => value.user.id === userId
		);
		if (index !== -1) {
			this.updateSortedParticipants();
			this.pendingChanges.push({
				kind: 'remove',
				index,
				userId,
			});
			this.applyPendingChanges();
		}
	}

	private insertParticipantWithPriority(
		participant: ActiveChannelData['participants'][0]
	): void {
		const priority = participant.nicklistPriority || 0;
		if (!this._participantsByPriority.has(priority)) {
			this._participantsByPriority.set(priority, []);
		}
		this._participantsByPriority.get(priority).push(participant);
	}

	private removeUserWithPriority(userId: User['id'], priority: number): void {
		const participants = this._participantsByPriority.get(priority);
		if (participants) {
			const index = participants.findIndex(p => p.user.id === userId);
			if (index !== -1) {
				participants.splice(index, 1);
			}
		}
	}
}

// If channel messages are received out of order, they will be hold back at most for this amount of millis
// before they will be shown out of order
const MAX_CHANNEL_MESSAGE_ORDER_DELAY = 50;

export const MAX_MESSAGES_IN_MEMORY_COUNT = 1000;

class BaseMessageHandler {
	readonly messageOrderingQueue =
		new WaitingOrderingQueue<ChannelMessageFragment>(
			message => +message.id,
			MAX_CHANNEL_MESSAGE_ORDER_DELAY
		);

	@observable.shallow protected readonly _messages =
		new Array<ActiveChannelMessage>();

	get messages(): ReadonlyArray<ActiveChannelMessage> {
		return this._messages;
	}

	@action
	addMessage(message: ActiveChannelMessage): void {
		if (this._messages.length > MAX_MESSAGES_IN_MEMORY_COUNT) {
			this._messages.shift();
		}
		this._messages.push(message);
	}

	@action
	clear(): void {
		this._messages.splice(0, this.messages.length);
	}
}

class ChannelBotMessages extends BaseMessageHandler {
	constructor() {
		super();
	}

	@action
	add(message: ActiveChannelMessage): void {
		runInAction('Insert bot channel message', () => {
			this.addMessage(message);
		});
	}
}

class ChannelMessages extends BaseMessageHandler {
	constructor(
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {
		super();
	}

	@action
	add(message: ActiveChannelMessage): void {
		if (message.__typename === 'CLIENT_MESSENGER_MESSAGE') {
			this.addMessage(message);
		} else {
			this.messageOrderingQueue.insert(message, inOrder => {
				runInAction('Insert channel message', () => {
					this.addMessage(message);

					if (!inOrder) {
						this.firebaseAnalyticsService.logEvent(
							'Chat_Chatraum',
							'MessageShownOutOfOrder'
						);
					}
				});
			});
		}
	}
}

type NicklistIconsByType = {
	[type in NicklistIconType]: readonly NicklistIconFragment[];
};
export type NickListState = {
	iconsByType: NicklistIconsByType;
	color: NicklistColor;
};
export type NicklistStatePerUserMap = {
	[userId: string]: NickListState;
};
class NicklistStateHelper {
	@observable
	private readonly _nicklistStateMap: NicklistStatePerUserMap = {};

	get nicklistStateMap(): NicklistStatePerUserMap {
		return this._nicklistStateMap;
	}

	@action
	updateNicklistState(
		userId: User['id'],
		newIcons: Readonly<NicklistIconFragment[]>,
		color?: NicklistColor
	): void {
		if (!this._nicklistStateMap[userId]) {
			this._nicklistStateMap[userId] = {
				iconsByType: {
					[NicklistIconType.Prefix]: [],
					[NicklistIconType.Suffix]: [],
				},
				color: NicklistColor.Default,
			};
		}
		// add newIcons
		for (const icon of newIcons) {
			// remove old icon/duplicate
			this.removeIcon(userId, icon.iconName);

			this._nicklistStateMap[userId].iconsByType[icon.listType] =
				injectInReadonlyArray(
					this._nicklistStateMap[userId].iconsByType[icon.listType],
					icon,
					icon.index
				);
		}
		// update nicklist color if set (else do nothing here and keep current value)
		if (color) {
			this._nicklistStateMap[userId].color = color;
		}
	}

	@action
	removeIcon(userId: User['id'], iconName: string): void {
		const stateForUser = this._nicklistStateMap[userId];
		if (stateForUser) {
			stateForUser.iconsByType[NicklistIconType.Prefix] =
				stateForUser.iconsByType[NicklistIconType.Prefix].filter(
					icon => icon.iconName !== iconName
				);
			stateForUser.iconsByType[NicklistIconType.Suffix] =
				stateForUser.iconsByType[NicklistIconType.Suffix].filter(
					icon => icon.iconName !== iconName
				);
		}
	}

	@action
	clearNicklistState(userId: User['id']): void {
		delete this._nicklistStateMap[userId];
	}
}

function injectInReadonlyArray<T>(
	array: readonly T[],
	item: T,
	position?: number
): T[] {
	const mutableArray = [...array];
	if (typeof position === 'number') {
		mutableArray.splice(position, 0, item);
	} else {
		mutableArray.push(item);
	}
	return mutableArray;
}
