import * as React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getRoot, $getSelection, $isRangeSelection, $isTextNode, COMMAND_PRIORITY_NORMAL, DELETE_CHARACTER_COMMAND, EditorState, FOCUS_COMMAND, LexicalNode, ParagraphNode, TextNode } from 'lexical';
import { CssAnimatePresence } from '@shared/components/RichText/CSSAnimatePrecense';
import { $isMentionNode } from '@shared/components/RichText/nodes/MentionNode';
import { useEvent } from '@knuddels/component-library';
import { useOptionSelection } from '@shared/components/RichText/plugins/useOptionSelection';
import { debounce } from '@knuddels-app/tools/debounce';
export type MentionItemProps<T> = {
  selectedIndex: number;
  highlightedIndex: number;
  setHighlightedIndex: (index: number) => void;
  selectOptionAndCleanUp: (option: T) => void;
};
export type MentionPluginProps<T> = {
  onlyTriggerFromStart?: boolean;
  getOptions: (query: string) => Promise<T[]>;
  autoSelectFirst?: boolean;
  trigger: string;
  createNode: (option: T) => LexicalNode;
  allowSelection?: (options: T) => boolean;
  disableWhenStartingWith?: string;
  renderMenu: (args: {
    onClose: () => void;
    query: string;
    options: T[];
    itemProps: MentionItemProps<T>;
  }) => React.ReactElement;
};
export const MentionPlugin = <T,>(props: MentionPluginProps<T>) => {
  const handleOptionClick = (option: T) => {
    if (props.allowSelection && !props.allowSelection(option)) {
      return;
    }
    editor.update(() => {
      const root = ($getRoot().getChildren()[0]! as ParagraphNode);
      const currentText = ($getSelection()!.getNodes()[0] as TextNode);
      const text = currentText.getTextContent();
      const before = $createTextNode(text.substring(0, matchingIndexRef.current).trim() + (props.onlyTriggerFromStart ? '' : ' '));
      currentText.replace(before);
      const a = (props.createNode(option) as LexicalNode);
      root.append(a);
      const t = $createTextNode(' ');
      root.append(t);
      t.select();
    });
    closeMenu();
  };
  const {
    selectedIndex,
    highlightedIndex,
    setHighlightedIndex,
    options,
    setOptions,
    unsetOptions
  } = useOptionSelection<T>({
    autoSelectFirst: props.autoSelectFirst,
    onOptionSelected: handleOptionClick,
    onOptionsCleared(): void {
      lastSearchRef.current = null;
    }
  });
  const matchingIndexRef = React.useRef(-1);
  const lastSearchRef = React.useRef<string | null>(null);
  const [editor] = useLexicalComposerContext();
  React.useEffect(() => {
    const commandUnregister = [editor.registerCommand(DELETE_CHARACTER_COMMAND, () => {
      if ($getSelection()?.getNodes().length !== 1) {
        return false;
      }
      const node = $getSelection()?.getNodes()[0];
      if (!$isTextNode(node)) {
        return false;
      }
      const text = node.getTextContent().trim();
      const length = text.length;
      // const hasFreeParam = text === ':'; //TODO Check if we want to remove mention node when param is removed
      const sibling = node.getPreviousSibling();
      if (!sibling ||
      // (length > 0 && !hasFreeParam) ||
      length > 0 || !$isMentionNode(sibling)) {
        return false;
      }
      if (sibling.getTrigger() !== props.trigger) {
        return false;
      }
      const replaceWith = $createTextNode(props.trigger);
      node.replace(replaceWith);
      sibling.remove();
      replaceWith.select();
      return true;
    }, COMMAND_PRIORITY_NORMAL)];
    return () => {
      commandUnregister.forEach(c => c());
    };
  }, []);
  const closeMenu = useEvent(() => {
    lastSearchRef.current = null;
    unsetOptions();
  });
  const autocompleteOptions = (query: string) => {
    if (lastSearchRef.current === query) {
      return;
    }
    lastSearchRef.current = query;
    props.getOptions(query.replace(props.trigger, '')).then(os => {
      setOptions(os);
    });
  };
  React.useEffect(() => {
    return editor.registerCommand(FOCUS_COMMAND, () => {
      lastSearchRef.current = null;
      handleChange(editor.getEditorState());
      return false;
    }, COMMAND_PRIORITY_NORMAL);
  }, []);
  React.useEffect(() => {
    return editor.registerUpdateListener(({
      editorState
    }) => {
      handleChange(editorState);
    });
  }, [editor]);
  const handleChange = React.useMemo(() => debounce((editorState: EditorState) => {
    editorState.read(() => {
      const root = ($getRoot().getChildren()[0]! as ParagraphNode);
      if (props.onlyTriggerFromStart || props.disableWhenStartingWith) {
        if (props.onlyTriggerFromStart && root.getChildren().length > 1) {
          return;
        }
        if (props.disableWhenStartingWith && root.getTextContent().startsWith(props.disableWhenStartingWith)) {
          return;
        }
      }
      const selection = $getSelection();
      if (!selection) {
        return;
      }
      const node = selection!.getNodes()[0];
      if (!$isRangeSelection(selection)) {
        return;
      }
      if (!$isTextNode(node) || $isMentionNode(node) || root.getLastChild() !== node) {
        unsetOptions();
        lastSearchRef.current = null;
        return;
      }
      const t = node.getTextContent().trim();
      if (t.length === 0 || props.onlyTriggerFromStart && !t.startsWith(props.trigger)) {
        unsetOptions();
        lastSearchRef.current = null;
        return;
      }
      const matchingIndex = t.lastIndexOf(props.trigger);
      if (matchingIndex === -1 || matchingIndex >= selection.anchor.offset) {
        unsetOptions();
        lastSearchRef.current = null;
        return;
      }
      matchingIndexRef.current = matchingIndex;
      const text = t.substring(matchingIndex + 1);
      autocompleteOptions(text);
    });
  }, 0), []);
  return <CssAnimatePresence>
			{options?.length > 0 && props.renderMenu({
      onClose: closeMenu,
      options,
      query: lastSearchRef.current!,
      itemProps: {
        selectedIndex: selectedIndex,
        highlightedIndex: highlightedIndex,
        setHighlightedIndex,
        selectOptionAndCleanUp: handleOptionClick
      }
    })}
		</CssAnimatePresence>;
};