import { featureDefinitions as n } from "./definitions.js";
function e(i) {
  for (const o in i)
    n[o] = {
      ...n[o],
      ...i[o]
    };
}
export {
  e as loadFeatures
};
