import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { OverflowMenuTrigger } from '@shared/components';
import * as React from 'react';
import { ProfileOverflowMenuOverlay } from './ProfileOverflowMenu';
import { useNavigate } from './ProfileContentRouter';
export const ProfileOverflowMenuTrigger: React.FC = () => {
  const overlayService = useService($OverlayService);
  const navigate = useNavigate();
  const showOverflowMenu = (x: number, y: number, width: number, height: number) => {
    overlayService.showOverlayIfNotVisible(ProfileOverflowMenuOverlay, {
      openHistory: () => navigate('nickHistory'),
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  return <OverflowMenuTrigger showOverflowMenu={showOverflowMenu} />;
};