import * as React from 'react';
import { FlexCol } from '@knuddels/component-library';
import { MacroBoxInput } from '@knuddelsModules/Channel/bundle/components/Macrobox/MacroBoxInput';
import { useRegisterBottomInput } from '../../../../../app/RegisterBottomInputContext';
export const MacroBoxFooter: React.FC<{
  user: {
    nick: string;
    conversationId: string;
  };
  onFocus?: () => void;
  onBlur?: () => void;
}> = ({
  user,
  onFocus,
  onBlur
}) => {
  useRegisterBottomInput();
  return <div className={_c0}>
			<MacroBoxInput user={user} onBlur={onBlur} onFocus={onFocus} />
		</div>;
};
const _c0 = " Knu-FlexCol shadow-Shadow3 ";