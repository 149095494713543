import { useContext as V } from "react";
import { isAnimationControls as k } from "../../animation/utils/is-animation-controls.js";
import { PresenceContext as w } from "../../context/PresenceContext.js";
import { resolveVariantFromProps as S } from "../../render/utils/resolve-variants.js";
import { useConstant as W } from "../../utils/use-constant.js";
import { resolveMotionValue as b } from "../../value/utils/resolve-motion-value.js";
import { MotionContext as I } from "../../context/MotionContext/index.js";
import { isControllingVariants as P, isVariantNode as T } from "../../render/utils/is-controlling-variants.js";
import { getWillChangeName as U } from "../../value/use-will-change/get-will-change-name.js";
import { addUniqueItem as $ } from "../../utils/array.js";
function j({ applyWillChange: t = !1, scrapeMotionValuesFromProps: n, createRenderState: e, onMount: a }, o, i, l, f) {
  const r = {
    latestValues: B(o, i, l, f ? !1 : t, n),
    renderState: e()
  };
  return a && (r.mount = (g) => a(o, g, r)), r;
}
const K = (t) => (n, e) => {
  const a = V(I), o = V(w), i = () => j(t, n, a, o, e);
  return e ? i() : W(i);
};
function q(t, n) {
  const e = U(n);
  e && $(t, e);
}
function v(t, n, e) {
  const a = Array.isArray(n) ? n : [n];
  for (let o = 0; o < a.length; o++) {
    const i = S(t, a[o]);
    if (i) {
      const { transitionEnd: l, transition: f, ...r } = i;
      e(r, l);
    }
  }
}
function B(t, n, e, a, o) {
  var i;
  const l = {}, f = [], r = a && ((i = t.style) === null || i === void 0 ? void 0 : i.willChange) === void 0, g = o(t, {});
  for (const s in g)
    l[s] = b(g[s]);
  let { initial: u, animate: m } = t;
  const A = P(t), x = T(t);
  n && x && !A && t.inherit !== !1 && (u === void 0 && (u = n.initial), m === void 0 && (m = n.animate));
  let C = e ? e.initial === !1 : !1;
  C = C || u === !1;
  const h = C ? m : u;
  return h && typeof h != "boolean" && !k(h) && v(t, h, (s, y) => {
    for (const d in s) {
      let c = s[d];
      if (Array.isArray(c)) {
        const N = C ? c.length - 1 : 0;
        c = c[N];
      }
      c !== null && (l[d] = c);
    }
    for (const d in y)
      l[d] = y[d];
  }), r && (m && u !== !1 && !k(m) && v(t, m, (s) => {
    for (const y in s)
      q(f, y);
  }), f.length && (l.willChange = f.join(","))), l;
}
export {
  K as makeUseVisualState
};
