import { Flex, FlexCol, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { SkeletonAnimation } from '@shared/components';
export const ProfileMobileSkeleton: React.FC<{
  width: number;
}> = ({
  width
}) => {
  const bg = 'skeleton';
  return <SkeletonAnimation>
			<div style={{
      marginTop: resolveThemingValue((width as ThemeOverride), "spacing", useTheme())
    }} className={_c0}>
				<div style={{
        background: resolveThemingValue(bg, "colors", useTheme())
      }} className={_c1 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				<div className={_c2}>
					{Array.from({
          length: 4
        }).map((_, index) => {
          return <div key={index} style={{
            background: resolveThemingValue(bg, "colors", useTheme())
          }} className={_c3 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />;
        })}
				</div>
				<div className={_c4}>
					<div style={{
          background: resolveThemingValue(bg, "colors", useTheme())
        }} className={_c5 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					<div className={_c6}>
						<div style={{
            background: resolveThemingValue(bg, "colors", useTheme())
          }} className={_c7 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
						<div style={{
            background: resolveThemingValue(bg, "colors", useTheme())
          }} className={_c8 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					</div>
				</div>
				<div style={{
        background: resolveThemingValue(bg, "colors", useTheme())
      }} className={_c9 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			</div>
			<div className={_c10}>
				<div style={{
        background: resolveThemingValue(bg, "colors", useTheme())
      }} className={_c11 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				<div className={_c12}>
					<div style={{
          background: resolveThemingValue(bg, "colors", useTheme())
        }} className={_c13 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					<div style={{
          background: resolveThemingValue(bg, "colors", useTheme())
        }} className={_c14 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				</div>
			</div>
			<div className={_c15}>
				<div style={{
        background: resolveThemingValue(bg, "colors", useTheme())
      }} className={_c16 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				<div className={_c17}>
					<div style={{
          background: resolveThemingValue(bg, "colors", useTheme())
        }} className={_c18 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					<div style={{
          background: resolveThemingValue(bg, "colors", useTheme())
        }} className={_c19 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				</div>
			</div>
		</SkeletonAnimation>;
};
const _c0 = " Knu-FlexCol flex-1 position-relative ";
const _c1 = " Knu-FlexCol width-full height-111px ";
const _c2 = " Knu-Flex mt-base px-base gap-small ";
const _c3 = " Knu-FlexCol size-80px borderRadius-base ";
const _c4 = " Knu-Flex mt-base px-base gap-tiny ";
const _c5 = " Knu-Flex size-40px borderRadius-circle ";
const _c6 = " Knu-FlexCol gap-small ";
const _c7 = " Knu-Flex height-10px width-52px borderRadius-small ";
const _c8 = " Knu-Flex height-16px width-222px borderRadius-small ";
const _c9 = " Knu-Flex mt-base mx-base height-26px borderRadius-small width-343px ";
const _c10 = " Knu-Flex mt-base px-base gap-tiny ";
const _c11 = " Knu-Flex size-40px borderRadius-circle ";
const _c12 = " Knu-FlexCol gap-small ";
const _c13 = " Knu-Flex height-10px width-52px borderRadius-small ";
const _c14 = " Knu-Flex height-16px width-129px borderRadius-small ";
const _c15 = " Knu-Flex mt-base px-base gap-tiny ";
const _c16 = " Knu-Flex size-40px borderRadius-circle ";
const _c17 = " Knu-FlexCol gap-small ";
const _c18 = " Knu-Flex height-10px width-52px borderRadius-small ";
const _c19 = " Knu-Flex height-16px width-198px borderRadius-small ";