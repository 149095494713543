import { jsx as a } from "react/jsx-runtime";
import { useToggle as o } from "./useToggle.js";
import { useDisabledOpacity as r } from "./useDisabledOpacity.js";
import { FlexCol as n } from "../Layout/FlexCol.js";
import { Flex as c } from "../Layout/Flex.js";
const h = (e) => {
  const t = o(e), i = r(e), l = e.value ? 10 : -10;
  return /* @__PURE__ */ a(
    n,
    {
      width: "36px",
      height: "16px",
      placeItems: "center",
      borderRadius: "10px",
      bg: e.value ? "accent" : "switchSlideBgDefault",
      position: "relative",
      onFocus: e.onFocus,
      onBlur: e.onBlur,
      ariaLabel: e.ariaLabel,
      ariaLabelledBy: e.ariaLabelledBy,
      ariaDescribedBy: e.ariaDescribedBy,
      ...t,
      ...i,
      children: /* @__PURE__ */ a(
        c,
        {
          onFocus: e.onFocus,
          onBlur: e.onBlur,
          ariaLabel: e.label,
          position: "absolute",
          width: "24px",
          height: "24px",
          borderRadius: "circle",
          bg: "switchHandleDefault",
          shadow: "Shadow1",
          transform: `translateX(${l}px)`,
          animated: !0
        }
      )
    }
  );
};
export {
  h as Switch
};
