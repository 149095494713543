import { jsx as y } from "react/jsx-runtime";
import * as p from "react";
import { toKeyboardHandler as x } from "../Layout/CommonTypes/toKeyboardHandler.js";
import "./StyledTextArea.scss.js";
import { clsx as E } from "../../node_modules/clsx/dist/clsx.m.js";
const i = 20, K = () => {
}, C = (r) => {
  const a = p.useRef(), {
    onChange: n,
    onResize: t,
    onPress: d,
    label: c,
    labelStyle: L,
    focused: P,
    adornmentStart: S,
    adornmentEnd: T,
    readonly: m,
    innerRef: o,
    size: h,
    hideCursor: b,
    pl: l,
    pr: s,
    ...f
  } = r;
  return /* @__PURE__ */ y(
    "textarea",
    {
      ...f,
      className: E(
        "Knu-StyledTextArea",
        b && "hideCursor",
        l && `pl-${l}`,
        s && `pr-${s}`
      ),
      ref: (e) => {
        a.current = e, o && (o.current = e);
      },
      "aria-label": c,
      "aria-labelledby": r.ariaLabelledBy,
      "aria-disabled": r.disabled,
      onChange: K,
      onKeyDown: x(r.onKeyPress),
      onInput: () => {
        const e = a.current.value;
        n == null || n({ value: e });
        const u = (e == null ? void 0 : e.split(`
`).length) ?? 1;
        t == null || t(
          u * i + i
        );
      },
      readOnly: m,
      onPointerUp: d
    }
  );
};
export {
  C as StyledTextArea
};
