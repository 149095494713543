import { useScreenWidth } from '@knuddels-app/Screen';
import { registeredScreenWidths } from '@knuddels-app/Screen/screens';
import { FlexCol, FallbackBox } from '@knuddels/component-library';
import { PanInfo, motion, useAnimate, useMotionValue } from 'framer-motion';
import * as React from 'react';
const SWIPE_OUT_THRESHOLD = 0.3;
export const HorizontalSwipeOutView: React.FC<{
  onSwipeOut: () => void;
  enabled?: boolean;
}> = ({
  children,
  enabled,
  onSwipeOut
}) => {
  const screenWidth = registeredScreenWidths[useScreenWidth()].max || 2000;
  const width = useMotionValue(0);
  const [scope, animate] = useAnimate();
  const handleDragEnd = async (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const dragOffset = info.offset.x;
    const threshold = width.get() * SWIPE_OUT_THRESHOLD;
    if (dragOffset < -threshold) {
      await animate(scope.current, {
        x: -screenWidth
      }, {
        duration: 0.2
      });
      onSwipeOut();
    } else if (dragOffset > threshold) {
      await animate(scope.current, {
        x: screenWidth
      }, {
        duration: 0.2
      });
      onSwipeOut();
    } else {
      await animate(scope.current, {
        x: 0
      }, {
        duration: 0.2
      });
    }
  };
  return <motion.div ref={scope} className={'Knu-FlexCol'} drag={enabled ? 'x' : false} onDragEnd={handleDragEnd}>
			<FallbackBox onLayout={e => width.set(e.width)} className={_c0}>{children}</FallbackBox>
		</motion.div>;
};
const _c0 = " Knu-FlexCol ";