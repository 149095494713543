import { Channel, ChannelParticipant } from '@generated/graphql';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { NativeBackgroundTitleBar } from '@knuddels-app/NativeBackground';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { Flex, FlexCol, IconBack, PersistedVirtualList, ThemeOverride, VirtualListItem, VirtualListRef, VirtualListRenderItemInfo, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { $AdsService, ADZONE_HEIGHT } from '@knuddelsModules/Ads';
import { ShadePreset } from '@shared/components';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChannelColorMode } from '../../services/channel/calculateChannelColorMode';
import { NickListEntry, NickListEntryProps, nicklistHeight } from './NickListEntry';
import { isNative } from '@knuddels-app/tools/isNative';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { expectUnreachable } from '@knuddels/std';
const templateEntry = 'nicklistentry';
const templateAdzone = 'adzone';
interface NickListProps {
  channelId: Channel['id'];
  showFullScreen?: boolean;
  isInBackground: boolean;
  isVisible: boolean;
  channelBackgroundColor: string | undefined;
  channelColorMode: ChannelColorMode;
  clickClose(): void;
}
interface NickListEntryItemInfo extends VirtualListItem, Omit<NickListEntryProps, 'colorMode'> {
  type: typeof templateEntry;
}
interface AdzoneItemInfo extends VirtualListItem {
  type: typeof templateAdzone;
}
type ItemInfos = NickListEntryItemInfo | AdzoneItemInfo;
const NICKLIST_WIDTH = isNative() ? 300 : 336;
const NickListBaseComponent: React.FC<{
  showFullScreen?: boolean;
  isInBackground: boolean;
  channelBackgroundColor: string | undefined;
  channelColorMode: ChannelColorMode;
  channelId: Channel['id'];
  renderItem: (info: VirtualListRenderItemInfo<ItemInfos>) => JSX.Element;
  clickClose(): void;
}> = ({
  showFullScreen,
  isInBackground,
  channelBackgroundColor,
  channelColorMode,
  channelId,
  renderItem,
  clickClose
}) => {
  const {
    participants,
    listRef,
    firstVisibleIndex,
    scrollTopRef
  } = useParticipants();
  const adsService = useService($AdsService);
  const itemList: ItemInfos[] = [...participants];
  const hasAdzone = adsService.areWebAdsVisible && itemList.length > 0;
  if (hasAdzone) {
    itemList.splice(0, 0, {
      type: templateAdzone,
      height: ADZONE_HEIGHT + adPaddingTop + adPaddingBottom
    });
  }
  return <div style={{
    flex: resolveThemingValue(showFullScreen ? 1 : undefined, "theme", useTheme()),
    flexGrow: resolveThemingValue(!showFullScreen ? 0 : undefined, "theme", useTheme()),
    flexShrink: resolveThemingValue(!showFullScreen ? 0 : undefined, "theme", useTheme()),
    flexBasis: resolveThemingValue(!showFullScreen ? (`${NICKLIST_WIDTH}px` as ThemeOverride) : undefined, "theme", useTheme()),
    width: resolveThemingValue(!showFullScreen ? NICKLIST_WIDTH : '100%', "sizes", useTheme())
  }} className={_c0}>
			<ShadePreset />
			<div className={_c1}>
				<div className={_c2}>
					<NativeBackgroundTitleBar isInBackground={isInBackground} color={channelBackgroundColor} type={channelColorMode} title={formatMessage(declareFormat({
          id: 'NICKLIST_TITLE',
          defaultFormat: 'Nicklist'
        }))} navIcon={showFullScreen && <IconBack size={'large'} onPress={clickClose} />} />
				</div>
			</div>
			<PersistedVirtualList estimatedItemSize={nicklistHeight} param={channelId}
    // reset after changing channel
    resetOnParamChange={true} renderItem={renderItem} data={itemList} listRef={listRef} onScroll={scrollTop => {
      scrollTopRef.current = scrollTop;
    }} onRenderedItemsChanged={({
      startIndex
    }) => {
      firstVisibleIndex.current = hasAdzone ? startIndex + 1 : startIndex;
    }} id="nicklist" className={_c3} />
		</div>;
};
const AdzoneItem: React.FC<{
  AdzoneComponent: (typeof $AdsService.T)['Adzone'];
  isVisible: boolean;
}> = ({
  AdzoneComponent,
  isVisible
}) => {
  return <div style={{
    paddingTop: resolveThemingValue((adPaddingTop as ThemeOverride), "spacing", useTheme()),
    paddingBottom: resolveThemingValue((adPaddingBottom as ThemeOverride), "spacing", useTheme()),
    height: resolveThemingValue(ADZONE_HEIGHT + adPaddingTop + adPaddingBottom, "sizes", useTheme())
  }} className={_c4}>
			{isVisible && <AdzoneComponent reloadAd adzone="channelNickList" />}
		</div>;
};
@observer
class NickListBase extends React.Component<NickListProps & {
  adsService: typeof $AdsService.T;
}, {
  isScrolling: boolean;
}> {
  state = {
    isScrolling: false
  };
  render(): JSX.Element {
    const {
      isInBackground
    } = this.props;
    if (isInBackground) {
      return null;
    }
    return <NickListBaseComponent channelBackgroundColor={this.props.channelBackgroundColor} channelColorMode={this.props.channelColorMode} channelId={this.props.channelId} clickClose={this.props.clickClose} isInBackground={this.props.isInBackground} renderItem={this.renderItem} showFullScreen={this.props.showFullScreen} />;
  }
  private readonly renderItem = ({
    item: info
  }: VirtualListRenderItemInfo<ItemInfos>): JSX.Element => {
    switch (info.type) {
      case templateEntry:
        return <NickListEntry user={info.user} nicklistState={info.nicklistState} showFullScreen={this.props.showFullScreen} colorMode={this.props.channelColorMode} />;
      case templateAdzone:
        return <AdzoneItem AdzoneComponent={this.props.adsService.Adzone} isVisible={this.props.isVisible} />;
      default:
        return null;
    }
  };
}
export const NickList = injectedComponent({
  name: 'NickList',
  inject: {
    adsService: $AdsService
  },
  props: declareProps<NickListProps>()
}, props => <NickListBase {...props} />);
const adPaddingTop = 8;
const adPaddingBottom = 8;
const useParticipants = () => {
  const listRef = React.useRef<VirtualListRef | null>();
  const scrollTopRef = React.useRef(0);
  const firstVisibleIndex = React.useRef(0);
  const channel = useService($ActiveChannelService).activeChannel;
  const targetScrollTopRef = React.useRef(-1);
  const createItemInfo = (participant: ChannelParticipant): ItemInfos => ({
    type: templateEntry,
    user: participant.user,
    nicklistState: channel.nicklistState.nicklistStateMap[participant.user.id],
    height: nicklistHeight
  });
  const [participants, setParticipants] = React.useState<ItemInfos[]>(() => channel.sortedParticipants.map(createItemInfo));
  React.useEffect(() => {
    channel.setNicklistChangeListener((changes, newParticipants) => {
      let newScrollTop = scrollTopRef.current;
      changes.forEach(change => {
        switch (change.kind) {
          case 'add':
            if (change.index < firstVisibleIndex.current) {
              newScrollTop += nicklistHeight;
            }
            break;
          case 'remove':
            if (change.index < firstVisibleIndex.current) {
              newScrollTop -= nicklistHeight;
            }
            break;
          case 'move':
            if (change.fromIndex >= firstVisibleIndex.current && change.toIndex < firstVisibleIndex.current) {
              newScrollTop += nicklistHeight;
            } else if (change.fromIndex < firstVisibleIndex.current && change.toIndex > firstVisibleIndex.current) {
              newScrollTop -= nicklistHeight;
            }
            break;
          default:
            expectUnreachable(change);
        }
      });
      newScrollTop = Math.max(0, newScrollTop);
      setParticipants(newParticipants.map(createItemInfo));
      if (newScrollTop != scrollTopRef.current) {
        targetScrollTopRef.current = newScrollTop;
      }
    });
    return () => {
      channel.nicklistChangeListener = () => {};
    };
  }, [channel, setParticipants]);
  React.useLayoutEffect(() => {
    if (targetScrollTopRef.current !== -1) {
      if (listRef.current) {
        listRef.current.scrollTo(targetScrollTopRef.current);
      }
      targetScrollTopRef.current = -1;
    }
  }, [participants]);
  return {
    participants: participants,
    listRef,
    scrollTopRef,
    firstVisibleIndex
  };
};
const _c0 = " Knu-FlexCol position-relative overflow-hidden ";
const _c1 = " Knu-Flex position-relative overflow-hidden ";
const _c2 = " Knu-FlexCol position-relative flex-1 pb-tiny overflow-hidden ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol minWidth-300px ";