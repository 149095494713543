import { Fragment as f, useMemo as d, createElement as p } from "react";
import { useHTMLProps as a } from "../html/use-props.js";
import { filterProps as P } from "./utils/filter-props.js";
import { isSVGComponent as V } from "./utils/is-svg-component.js";
import { useSVGProps as g } from "../svg/use-props.js";
import { isMotionValue as h } from "../../value/utils/is-motion-value.js";
function F(o = !1) {
  return (e, s, t, { latestValues: i }, n) => {
    const u = (V(e) ? g : a)(s, i, n, e), m = P(s, typeof e == "string", o), c = e !== f ? { ...m, ...u, ref: t } : {}, { children: r } = s, l = d(() => h(r) ? r.get() : r, [r]);
    return p(e, {
      ...c,
      children: l
    });
  };
}
export {
  F as createUseRender
};
