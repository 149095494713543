import { MotionGlobalConfig as w } from "../utils/GlobalConfig.js";
import { createRenderStep as E } from "./render-step.js";
const a = [
  "read",
  // Read
  "resolveKeyframes",
  // Write/Read/Write/Read
  "update",
  // Compute
  "preRender",
  // Compute
  "render",
  // Write
  "postRender"
  // Compute
], F = 40;
function C(c, l) {
  let t = !1, o = !0;
  const e = {
    delta: 0,
    timestamp: 0,
    isProcessing: !1
  }, i = () => t = !0, n = a.reduce((s, r) => (s[r] = E(i), s), {}), { read: m, resolveKeyframes: u, update: d, preRender: f, render: g, postRender: R } = n, p = () => {
    const s = w.useManualTiming ? e.timestamp : performance.now();
    t = !1, e.delta = o ? 1e3 / 60 : Math.max(Math.min(s - e.timestamp, F), 1), e.timestamp = s, e.isProcessing = !0, m.process(e), u.process(e), d.process(e), f.process(e), g.process(e), R.process(e), e.isProcessing = !1, t && l && (o = !1, c(p));
  }, h = () => {
    t = !0, o = !0, e.isProcessing || c(p);
  };
  return { schedule: a.reduce((s, r) => {
    const x = n[r];
    return s[r] = (M, P = !1, v = !1) => (t || h(), x.schedule(M, P, v)), s;
  }, {}), cancel: (s) => {
    for (let r = 0; r < a.length; r++)
      n[a[r]].cancel(s);
  }, state: e, steps: n };
}
export {
  C as createRenderBatcher,
  a as stepsOrder
};
