import * as React from 'react';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { ContextMenuEntry } from '@shared/components';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
import { IconCamera } from '@shared/icons/IconCamera';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
export const EditProfilePictureContextMenuEntry: React.FC<{}> = () => {
  const closeMenu = useCloseThisOverlay();
  const execute = useSlashCommandWithoutChannel();
  const editPicture = () => {
    execute('/opensystemapp ' + PROFILE_CUSTOMIZATION_APP_ID);
    closeMenu();
  };
  return <ContextMenuEntry icon={IconCamera} text={formatMessage(declareFormat({
    id: 'profile.edit.picture.overflowMenu.change',
    defaultFormat: 'Change picture'
  }))} onClick={editPicture} />;
};