import * as React from 'react';
import { registeredScreenHeights, registeredScreenWidths, ScreenHeight, ScreenSizeType, ScreenWidth } from '../screens';
import { Dimensions } from './ScreenSizeCalculatorCommon';
import { declareProps, IModel, injectedComponent, inject, injectProps, injectable } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen/registerServices';
import { objectKeys } from '@knuddels-app/tools/objectKeys';

// TODO refactor... the way it handles width/height seems wrong/bad
@injectable()
class ScreenSizeCalculatorModel implements IModel {
  constructor(@injectProps()
  props: {}, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T) {
    const screenWidths = objectKeys(registeredScreenWidths);
    screenWidths.forEach((breakpointName: ScreenWidth) => {
      this.initMediaQueryInDimension('width', breakpointName, registeredScreenWidths[breakpointName]);
    });
    const screenHeights = objectKeys(registeredScreenHeights);
    screenHeights.forEach((breakpointName: ScreenHeight) => {
      this.initMediaQueryInDimension('height', breakpointName, registeredScreenHeights[breakpointName]);
    });
  }
  public initMediaQueryInDimension = (dimension: Dimensions, breakpointName: string, breakpoint: ScreenSizeType): void => {
    const queryString = this.createQueryString(dimension, breakpoint);

    // register query
    const mediaQueryList: MediaQueryList = window.matchMedia(queryString);
    this.setCurrentScreen(dimension, mediaQueryList, breakpointName);

    // add listener that is called when changing
    mediaQueryList.addListener(() => {
      this.setCurrentScreen(dimension, mediaQueryList, breakpointName);
    });
  };
  public createQueryString = (dimension: Dimensions, screenRange: ScreenSizeType): string => {
    const minQuery = '(min-' + dimension + ': ' + screenRange.min + 'px)';
    const maxQuery = '(max-' + dimension + ': ' + screenRange.max + 'px)';
    let query = '';
    if (screenRange.min) {
      query = screenRange.max ? minQuery + ' and ' + maxQuery : minQuery;
    } else {
      query = maxQuery;
    }
    return query;
  };
  private setCurrentScreen = (dimension: Dimensions, mediaQueryList: MediaQueryList, breakpointName: any): void => {
    if (mediaQueryList.matches) {
      if (dimension === 'width') {
        this.screenService.setScreenWidth(breakpointName);
      } else {
        this.screenService.setScreenHeight(breakpointName);
      }
    }
  };
  componentDidMount(): void {}
}
export const ScreenSizeCalculator = injectedComponent({
  model: ScreenSizeCalculatorModel,
  name: 'ScreenSizeCalculator',
  props: declareProps<{
    children?: React.ReactNode;
  }>()
}, ({
  children
}) => {
  return children ? <>{React.Children.only(children)}</> : null;
});