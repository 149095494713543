import { jsx as e } from "react/jsx-runtime";
import "./SkeletonAnimation.scss.js";
import { FlexCol as d } from "../FlexCol.js";
import { Flex as o } from "../Flex.js";
const r = ({ children: t }) => /* @__PURE__ */ e("div", { className: "Knu-SkeletonPulse", children: t }), h = ({ maxWidth: t, sizes: n = [100] }) => /* @__PURE__ */ e(o, { maxWidth: t, width: "full", children: /* @__PURE__ */ e(r, { children: /* @__PURE__ */ e(d, { gap: "tiny", children: n.map((l, i) => /* @__PURE__ */ e(
  o,
  {
    bg: "skeleton",
    height: 10,
    width: l + "%",
    borderRadius: "tiny"
  },
  i
)) }) }) }), k = ({ maxWidth: t, height: n }) => /* @__PURE__ */ e(o, { maxWidth: t, width: "full", height: n, children: /* @__PURE__ */ e(r, { children: /* @__PURE__ */ e(o, { bg: "skeleton", height: n, borderRadius: "tiny" }) }) });
export {
  k as BlockSkeleton,
  r as SkeletonAnimation,
  h as TextBlockSkeleton
};
