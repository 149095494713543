import { FlexCol, Tab, Tabs, TabsList } from '@knuddels/component-library';
import * as React from 'react';
import { $I18n } from '@knuddels-app/i18n';
import { DETAILS_TAB_TITLE, PHOTOS_TAB_TITLE } from '../../../../i18n/shared-formats';
import { TabBarElement } from '../../../shared/TabBarElement';
import { useService } from '@knuddels-app/DependencyInjection';
export const EditProfileHeader: React.FC<{
  activeTabIndex?: number;
  onTabClicked: (newTabIndex: number) => void;
}> = ({
  activeTabIndex,
  onTabClicked
}) => {
  const i18n = useService($I18n);
  return <div className={_c0}>
			{/* we need to set the height because otherwise it collapses on native */}
			<Tabs value={activeTabIndex} onChange={onTabClicked}>
				<TabsList>
					<Tab className={_c1}>
						<TabBarElement title={i18n.format(DETAILS_TAB_TITLE)} />
					</Tab>
					<Tab className={_c2}>
						<TabBarElement title={i18n.format(PHOTOS_TAB_TITLE)} />
					</Tab>
				</TabsList>
			</Tabs>
		</div>;
};
const _c0 = " Knu-FlexCol height-48px ";
const _c1 = "  ";
const _c2 = "  ";