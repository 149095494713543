import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as serviceIds from './serviceIds';
import { CommandService } from './CommandService';
import { CommandWithoutChannelService } from './CommandWithoutChannelService';
import { CoreCommands } from './CoreCommands';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope
		.bind(serviceIds.$CommandService)
		.toSingleton(CommandService);
	ctx.globalScope
		.bind(serviceIds.$CommandWithoutChannelService)
		.toSingleton(CommandWithoutChannelService);

	ctx.loggedInScope
		.bind(serviceIds.$CoreCommands)
		.toSingletonAutostart(CoreCommands);
}
