import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { foreachProp } from '@knuddels/std';
import * as providedServices from '../providedServices';
import { FotomeetCommandService } from './services';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(providedServices, {
		$FotomeetCommandService: ctx.loggedInScope.toSingletonAutostart(
			FotomeetCommandService
		),
	});
}
