import { LoadFromService } from '@knuddels-app/ModuleSystem';
import { $KnuddelDisplayComponents } from './providedServices';

export * from './providedServices';

export const KnuddelDisplayLazy = LoadFromService({
	service: $KnuddelDisplayComponents,
	selector: s => s.KnuddelDisplay,
});

export const CurrentUserKnuddelDisplayLazy = LoadFromService({
	service: $KnuddelDisplayComponents,
	selector: s => s.CurrentUserKnuddelDisplay,
});

export const KnuddelSubscriptionLazy = LoadFromService({
	service: $KnuddelDisplayComponents,
	selector: s => s.KnuddelSubscription,
});
