import { jsx as o } from "react/jsx-runtime";
import u from "react";
import "./Ripple.scss.js";
function d(p, c, e = "center") {
  const s = c, r = s.getBoundingClientRect(), t = document.createElement("span"), l = Math.max(s.clientWidth, s.clientHeight), i = l / 2;
  e === "center" ? (t.style.left = "0", t.style.top = "0", t.style.width = "100%", t.style.height = "100%") : (t.style.width = t.style.height = `${l}px`, t.style.left = `${p.clientX - r.left - i}px`, t.style.top = `${p.clientY - r.top - i}px`), t.classList.add("ripple"), t.addEventListener("animationend", () => {
    t.remove();
  }), s.append(t);
}
const g = ({
  pointerCallbackType: p = "onClick",
  zIndex: c,
  containerRef: e,
  mode: s = "center"
}) => {
  const r = u.useRef(null);
  u.useEffect(() => {
    if (!(e != null && e.current))
      return;
    const l = (n) => {
      d(n, r.current, s);
    }, i = p === "onClick" ? "click" : "pointerdown";
    return e.current.addEventListener(i, l), () => {
      var n;
      (n = e.current) == null || n.removeEventListener(i, l);
    };
  }, [e == null ? void 0 : e.current]);
  const t = e != null && e.current ? {} : {
    [p]: (l) => {
      d(l, r.current, s);
    }
  };
  return /* @__PURE__ */ o(
    "div",
    {
      ref: r,
      className: "Knu-Ripple",
      ...t,
      style: { zIndex: c }
    }
  );
};
export {
  g as Ripple
};
