type UserWithNick = { nick: string };

export function usersSortedByNick<T extends UserWithNick>(
	users: readonly T[]
): T[] {
	return [...users].sort(compareNicks);
}

function compareNicks(userA: UserWithNick, userB: UserWithNick): -1 | 1 | 0 {
	const nickA = userA.nick.toLowerCase();
	const nickB = userB.nick.toLowerCase();

	if (nickA > nickB) {
		return 1;
	} else if (nickB > nickA) {
		return -1;
	} else {
		return 0;
	}
}
