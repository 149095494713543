/**
 * Returns a new functions that will call f, the first time it is called and is a noop after that.
 */
export function runOnce(f: () => void): () => void {
	let hasRun = false;
	return () => {
		if (!hasRun) {
			hasRun = true;
			f();
		}
	};
}
