import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands';
import { Disposable } from '@knuddels/std';
import { $MessengerNavigationService } from '@knuddelsModules/Messenger';
import { $ViewService } from '@knuddels-app/layout';
import {
	$DeepLinkingService,
	DeepLinkType,
} from '@knuddelsModules/DeepLinking';
import { $FriendRequestsService } from '../../../Contacts';
import { $UserService } from '../../../UserData';
import { viewIdForSystemApp } from '@knuddelsModules/SystemApps';

type ParameterPrefix = 'sendmessage:' | 'friendrequest:';
const sendMessageParameterPrefix = 'sendmessage:';
const friendRequestParameterPrefix = 'friendrequest:';

const startsWithPrefix = (
	parameter: string,
	parameterPrefix: ParameterPrefix
) => parameter.toLowerCase().startsWith(parameterPrefix);

const getNickFromParameter = (
	parameter: string,
	parameterPrefix: ParameterPrefix
) => parameter.substr(parameterPrefix.length).trim();

@injectable()
export class FotomeetCommandService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($CommandService)
		commandService: typeof $CommandService.T,
		@inject($ViewService)
		viewService: typeof $ViewService.T,
		@inject($DeepLinkingService)
		private readonly deepLinkingService: typeof $DeepLinkingService.T,
		@inject.lazy($UserService)
		readonly getUserService: typeof $UserService.TLazy,
		@inject.lazy($FriendRequestsService)
		readonly getFriendRequestsService: typeof $FriendRequestsService.TLazy,
		@inject.lazy($MessengerNavigationService)
		getMessengerNavigationService: typeof $MessengerNavigationService.TLazy
	) {
		this.dispose.track(
			commandService.registerCommand({
				commandName: 'fotomeet',
				async invoke(parameter: string | undefined): Promise<void> {
					if (!parameter) {
						viewService.openView(viewIdForSystemApp('FotomeetApp'));
						return;
					}

					if (
						startsWithPrefix(parameter, sendMessageParameterPrefix)
					) {
						const nick = getNickFromParameter(
							parameter,
							sendMessageParameterPrefix
						);

						getMessengerNavigationService().then(service =>
							service.openConversationByNick(nick)
						);
					}

					if (
						startsWithPrefix(
							parameter,
							friendRequestParameterPrefix
						)
					) {
						const nick = getNickFromParameter(
							parameter,
							friendRequestParameterPrefix
						);
						const userService = await getUserService();
						const friendService = await getFriendRequestsService();
						const userId =
							await userService.getUserIdFromNick(nick);

						await friendService.sendFriendRequest(userId);
					}
				},
				shouldInvoke(parameter: string): boolean {
					return (
						parameter.length === 0 ||
						startsWithPrefix(
							parameter,
							sendMessageParameterPrefix
						) ||
						startsWithPrefix(
							parameter,
							friendRequestParameterPrefix
						)
					);
				},
			})
		);

		this.dispose.track(
			deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.App &&
					target.toLowerCase() === 'fotomeet'
				) {
					viewService.openView(viewIdForSystemApp('FotomeetApp'));
				}
			})
		);
	}
}
