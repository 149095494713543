import { last } from '@knuddels/std';

export const customUserImageSizes = [40, 60].sort();

/**
 * Returns the smallest size that is larger than the given inputSize, or the largest size
 * available if no such size is available.
 */
export function getCustomUserImageSize(inputSize: number): number {
	return (
		customUserImageSizes.filter(size => size >= inputSize)[0] ??
		last(customUserImageSizes)
	);
}
