import { jsx as c } from "react/jsx-runtime";
import * as e from "react";
const l = e.createContext(() => {
  throw new Error(
    "You cannot use Portals without a PortalHost. Please add <PortalHost>...</PortalHost> as a parent to your portal."
  );
}), P = () => e.useContext(l), d = (t) => {
  const [
    n,
    a
  ] = e.useState(null);
  e.useEffect(() => () => {
    var o;
    (o = t.onPortalStateChange) == null || o.call(t, !1);
  }, []);
  const u = e.useMemo(() => (o) => {
    var r;
    (r = t.onPortalStateChange) == null || r.call(t, !!o), a(o);
  }, [a, t.onPortalStateChange]);
  return /* @__PURE__ */ c(l.Provider, { value: u, children: t.children({
    children: t.defaultChildren,
    portedComponent: n
  }) });
}, h = (t) => {
  const n = P();
  return e.useLayoutEffect(() => {
    n(t.children);
  }, [t.children]), null;
};
export {
  h as Portal,
  d as PortalHost
};
