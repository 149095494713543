import { MessengerConversation, User, UserForProfileFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { $OverlayService } from '@knuddels-app/overlays';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { Flex, FlexCol, FallbackBox } from '@knuddels/component-library';
import { $AdsService } from '@knuddelsModules/Ads';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import * as React from 'react';
import { ProfileAlbumInfo } from '../profileQueryHelper';
import { ProfileFABHiderContextProvider } from './FABs/ProfileFAB';
import { usePrivacyScreen } from '@shared/components/contexts/PrivacyScreenContext';
export interface ProfileBaseProps {
  onClose: () => void;
  currentUserId: User['id'];
  user?: UserForProfileFragment;
  photoData: ProfileAlbumInfo;
  conversationId: MessengerConversation['id'];
}
export abstract class ProfileBase extends React.Component<ProfileBaseProps> {
  render(): React.ReactNode {
    return <Body renderBody={this.renderBody} />;
  }
  protected abstract renderBody: (width: number, height: number) => React.ReactNode;
}
const Body: React.FC<{
  renderBody: (width: number, height: number) => React.ReactNode;
}> = observer('Body', ({
  renderBody
}) => {
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  const adsService = useService($AdsService);
  const isTopMostOverlay = useIsProfileTopOverlay();
  const isStackedLayout = useIsStackedLayout();
  const PrivacyScreen = usePrivacyScreen('ProfileBase');
  React.useEffect(() => {
    if (isTopMostOverlay) {
      PrivacyScreen.enable();
    } else {
      PrivacyScreen.disable();
    }
    return () => {
      PrivacyScreen.disable();
    };
  }, [PrivacyScreen, isTopMostOverlay]);
  return <ProfileFABHiderContextProvider>
			<FallbackBox onLayout={e => setSize(e)} className={_c0 + (height ? _c1 : _c2)}>
				{width ? renderBody(width, height) : null}
			</FallbackBox>
			{adsService.areCapacitorAdsVisible && isStackedLayout && <div className={_c3}>
					<adsService.CapacitorAdzone adVisible={isTopMostOverlay} location={'bottom'} bannerId={'K3ProfileBanner'} />
				</div>}
		</ProfileFABHiderContextProvider>;
});
const useIsProfileTopOverlay = () => {
  const overlayService = useService($OverlayService);
  return useReactiveState(() => {
    const overlayCount = overlayService.overlays.length;
    return overlayCount > 0 && overlayService.overlays[overlayCount - 1].tag === profileViewId.id && !overlayService.isSystemAppOverlayVisible;
  }, [overlayService]);
};
const _c0 = " Knu-Flex position-relative overflow-hidden flexShrink-0 flex-1 ";
const _c1 = " opacity-1 ";
const _c2 = " opacity-0 ";
const _c3 = " Knu-FlexCol shadow-Shadow1 width-full zIndex-1 ";