import { NativeFilePickerTrigger } from '.';
import { PermissionResult, SelectFileResult } from './filePickerInterfaces';

export async function requestPicturePermissions(): Promise<PermissionResult> {
	return Promise.resolve<PermissionResult>('yes');
}

export function getNativeFilePicker(
	multiSelect = false
): NativeFilePickerTrigger {
	const filePicker = new NativeFilePicker(multiSelect);
	return filePicker.trigger;
}

class NativeFilePicker {
	input: HTMLInputElement;

	constructor(multiSelect: boolean) {
		this.input = document.createElement('input');
		this.input.setAttribute('type', 'file');
		this.input.setAttribute('accept', '.jpg, .jpeg, .png');
		if (multiSelect) {
			this.input.setAttribute('multiple', '');
		}
	}

	trigger: NativeFilePickerTrigger = callback => {
		const eventHandler = (e: any) => {
			const files = Array.from(e.target.files as FileList);

			const result: SelectFileResult[] = files.map(it => ({
				image: URL.createObjectURL(it),
				uploadObject: it,
			}));

			callback(result);
			this.input.removeEventListener('change', eventHandler);
		};
		this.input.addEventListener('change', eventHandler);
		// Reset before click in case you click it multiple times
		// and select the same image (which would)
		this.input.value = '';
		this.input.click();
	};
}
