import { createProjectionNode as t } from "./create-projection-node.js";
import { addDomEvent as c } from "../../events/add-dom-event.js";
const l = t({
  attachResizeListener: (o, e) => c(o, "resize", e),
  measureScroll: () => ({
    x: document.documentElement.scrollLeft || document.body.scrollLeft,
    y: document.documentElement.scrollTop || document.body.scrollTop
  }),
  checkIsScrollRoot: () => !0
});
export {
  l as DocumentProjectionNode
};
