import * as React from 'react';
import { TextStates } from '../../atoms/Texts/TextProps';
import { useCommand } from './useCommand';
import { Link as LibLink, LinkProps as LibLinkProps, ThemeOverride } from '@knuddels/component-library';
import { isNative } from '@knuddels-app/tools/isNative';
export interface LinkProps {
  /** This can either be a slash command or a web link */
  command: string;
  state?: TextStates;
  bold?: boolean;
  italic?: boolean;
  overrideColor?: string;
  // disable clicking and hovering the link
  disabled?: boolean;

  /**
   *
   * @param command
   * @return false if the command should be opened as a URL
   */
  onClick(command: string): boolean;
}
export function Link({
  command,
  bold,
  italic,
  state,
  overrideColor,
  disabled,
  onClick,
  children
}: React.PropsWithChildren<LinkProps>): JSX.Element {
  const commandInfo = useCommand(onClick, command, isNative());
  const stateOrColors: LibLinkProps = overrideColor ? {
    color: (overrideColor as ThemeOverride),
    darkColor: (overrideColor as ThemeOverride)
  } : {
    state: state || 'primary'
  };
  return <LibLink bold={bold !== undefined ? bold : commandInfo.type !== 'link'} italic={italic} to={commandInfo.type === 'link' ? commandInfo.url : undefined} onPress={commandInfo.onClick} disabled={disabled} type={'body1'} {...stateOrColors}>
			{children}
		</LibLink>;
}