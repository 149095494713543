import t from "react";
import { Controller as f } from "../../../../node_modules/@use-gesture/core/dist/use-gesture-core.esm.js";
import { r as a, e as u } from "../../../../node_modules/@use-gesture/core/dist/actions-fe213e88.esm.js";
import { C as b, E as C } from "../../../../node_modules/@use-gesture/core/dist/actions-fe213e88.esm.js";
function s(r, n = {}, o, i) {
  const e = t.useMemo(() => new f(r), []);
  if (e.applyHandlers(r, i), e.applyConfig(n, o), t.useEffect(e.effect.bind(e)), t.useEffect(() => e.clean.bind(e), []), n.target === void 0)
    return e.bind.bind(e);
}
function g(r, n) {
  return a(u), s({
    drag: r
  }, n || {}, "drag");
}
export {
  b as ConfigResolverMap,
  C as EngineMap,
  u as dragAction,
  a as registerAction,
  g as useDrag
};
