import * as React from 'react';
import { getChannelPreviewImageUrl } from './getChannelPreviewImageUrl';
import { ColorFragment } from '@generated/graphql';
import { Flex, Image, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
interface Props {
  previewPicUrl?: string;
  backgroundColor: ColorFragment;
  size: number;
}
export function ChannelPicture(props: Props): React.ReactElement {
  const previewPicUrl = getChannelPreviewImageUrl(props.previewPicUrl, true);
  const bgColor = colorToRgbaString(props.backgroundColor) || 'transparent';
  return <div
  // only add background when showing the default image
  style={{
    background: resolveThemingValue(!previewPicUrl ? (bgColor as any) : undefined, "colors", useTheme()),
    width: resolveThemingValue(`${props.size}px`, "sizes", useTheme()),
    height: resolveThemingValue(`${props.size}px`, "sizes", useTheme())
  }} className={_c0 + ((!previewPicUrl ? (bgColor as any) : undefined) ? resolveIsDarkColor(!previewPicUrl ? (bgColor as any) : undefined, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{previewPicUrl ? <Image src={previewPicUrl} alt={''} resizeMode={'cover'} style={{
      width: resolveThemingValue(`${props.size}px`, "sizes", useTheme()),
      height: resolveThemingValue(`${props.size}px`, "sizes", useTheme())
    }} className={_c1} /> : <DefaultChannelPicture size={props.size} />}
		</div>;
}
function DefaultChannelPicture(props: {
  size: number;
}): React.ReactElement {
  const defaultIconSize = props.size / 4 * 3;
  return <div className={_c2}>
			<Image src={require('@shared/icons/icon-channel-active-white.png')} alt={''} resizeMode={'contain'} style={{
      width: resolveThemingValue(`${defaultIconSize}px`, "sizes", useTheme()),
      height: resolveThemingValue(`${defaultIconSize}px`, "sizes", useTheme())
    }} className={_c3} />
		</div>;
}
const _c0 = " Knu-Flex flexShrink-0 borderRadius-tiny overflow-hidden alignItems-center justifyContent-center ";
const _c1 = "  ";
const _c2 = " Knu-Flex opacity-0-66 ";
const _c3 = "  ";