import { jsx as C } from "react/jsx-runtime";
import { createSvgIcon as o } from "./createSvgIcon.js";
const r = o(
  /* @__PURE__ */ C("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ C(
    "path",
    {
      d: "M12 4C5.142 4 1.8857 9.87864 1.0957 11.5566C0.963704 11.8366 0.963704 12.1614 1.0957 12.4414C1.8857 14.1204 5.142 20 12 20C18.834 20 22.0925 14.1629 22.8965 12.4609C23.0345 12.1679 23.0345 11.8321 22.8965 11.5391C22.0925 9.83706 18.834 4 12 4ZM12 7C14.761 7 17 9.239 17 12C17 14.761 14.761 17 12 17C9.239 17 7 14.761 7 12C7 9.239 9.239 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7957 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7957 9 12 9Z",
      fill: "currentColor"
    }
  ) })
);
r.displayName = "IconShow";
export {
  r as IconShow
};
