import { Column, Row, Spacer } from '@shared/components';
import { Flex, Image, Link, ThemeOverride, Text, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
export function SendingMessageRetry({
  isStackedLayout,
  retry
}: {
  isStackedLayout: boolean;
  retry(): void;
}): React.ReactElement {
  const alignLeft = false;

  // 40 image + 4 to arrow + 8 arrow
  const basePadding = 40 + 4 + 8;
  const paddingRightAligned = basePadding + 16;
  const bigScreenPadding = !isStackedLayout ? 8 : 0;
  const paddingLeft = basePadding + (alignLeft ? 0 : paddingRightAligned) + bigScreenPadding;
  const paddingRight = bigScreenPadding;
  return <div style={{
    paddingLeft: resolveThemingValue((paddingLeft as ThemeOverride), "spacing", useTheme()),
    paddingRight: resolveThemingValue((paddingRight as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			<Column fluid>
				<Row style={{
        alignSelf: 'flex-end',
        alignItems: 'center'
      }}>
					<Image src={require('./error.png')} alt={'Loading Spinner'} resizeMode={'contain'} className={_c1} />
					<Spacer size={'small'} />
					<Text type={'tiny'} state={'tertiary'} className={_c2}>
						<FormattedMessage id={declareFormat({
            id: 'messenger.conversation.retry-send1',
            defaultFormat: 'Unable to send message, '
          })} />
					</Text>
					<Link onPress={retry} type={'tiny'} state={'tertiary'} bold>
						<FormattedMessage id={declareFormat({
            id: 'messenger.conversation.retry-send2',
            defaultFormat: 'retry.'
          })} />
					</Link>
					<Spacer size={'small'} />
				</Row>
			</Column>
		</div>;
}
const _c0 = " Knu-Flex overflow-visible ";
const _c1 = " width-16px height-16px ";
const _c2 = "  ";