import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { GetUserIdFromNick, MarkProfileVisitorSeen } from '@generated/graphql';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { Disposable } from '@knuddels/std';
import { ProfileRoutingComponent } from '../components/ProfileOverlay/ProfileRouting';
import { AlbumDetailsOverlay } from '../components/AlbumDetails/AlbumDetailsOverlay';
import { $UserService } from '@knuddelsModules/UserData';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { authenticityFlag } from '@knuddelsModules/FeatureFlags';
import { CHANNEL_MESSAGE_COMMAND_CONTEXT } from '@shared/constants';
import { $MacroBoxService } from '@knuddelsModules/Channel';
import {
	$DeepLinkingService,
	DeepLinkType,
} from '@knuddelsModules/DeepLinking';
import { TabType } from '../components/ProfileOverlay/ProfileTabsMapping';

@injectable()
export class ProfileService {
	public readonly dispose = Disposable.fn();

	public readonly MainView = ProfileRoutingComponent;
	public readonly AlbumDetails = AlbumDetailsOverlay;

	constructor(
		@inject($CommandService)
		commandService: typeof $CommandService.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($ProfileNavigationService)
		private readonly profileNavigationService: typeof $ProfileNavigationService.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($FeatureService)
		private readonly featureService: typeof $FeatureService.T,
		@inject.lazy($MacroBoxService)
		private readonly getMacroBoxService: typeof $MacroBoxService.TLazy,
		@inject($DeepLinkingService)
		private readonly deepLinkingService: typeof $DeepLinkingService.T
	) {
		this.dispose.track(
			deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (type === DeepLinkType.Profile) {
					profileNavigationService.showProfileForNick(target);
				}
			})
		);

		for (const cmd of ['w', 'w2']) {
			this.dispose.track(
				commandService.registerCommand({
					commandName: cmd,
					invoke: async (
						parameter: string,
						context
					): Promise<void> => {
						await this.commandHandlerOpenProfile(
							parameter,
							context
						);
					},
				})
			);
		}

		for (const cmd of ['edit', 'e', 'foto', 'w2e']) {
			this.dispose.track(
				commandService.registerCommand({
					commandName: cmd,
					invoke: async (): Promise<void> => {
						this.profileNavigationService.showEditProfile();
					},
					shouldInvoke: (parameter: string): boolean => {
						return !parameter.trim();
					},
				})
			);
		}

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'fotoalbum',
				invoke: async (parameter): Promise<void> => {
					if (!parameter.trim()) {
						this.profileNavigationService.showCurrentUserProfile({
							initialTab: 'photos',
						});
					} else {
						await this.profileNavigationService.showProfileForNick(
							parameter.trim(),
							{
								initialTab: 'photos',
							}
						);
					}
				},
				shouldInvoke: parameter => {
					return !parameter.trim() || !parameter.includes(':');
				},
			})
		);

		featureService
			.getState(authenticityFlag)
			.isEnabledPromise.then(value => {
				if (value) {
					this.dispose.track(
						commandService.registerCommand({
							commandName: 'echtheit',
							invoke: async (
								parameter: string
							): Promise<void> => {
								if (
									parameter.trim().length === 0 ||
									parameter.trim().toLowerCase() ===
										this.userService.currentUser.nick.toLowerCase()
								) {
									this.profileNavigationService.showAuthenticityOverview();
								} else {
									await this.profileNavigationService.showAuthenticityInfoForNick(
										parameter
									);
								}
							},
						})
					);
				}
			});
	}

	private commandHandlerOpenProfile = async (
		parameterText: string,
		context?: string
	): Promise<void> => {
		if (!parameterText.trim()) {
			this.profileNavigationService.showCurrentUserProfile({
				openedFromTrackingLabel: 'Command',
			});
		} else {
			const silentPrefixCheck = this.checkPrefix(parameterText, '+');
			const openNewFramePrefixCheck = this.checkPrefix(
				silentPrefixCheck.remainder,
				'*'
			);

			const remainder = openNewFramePrefixCheck.remainder.trim();
			const split = remainder.split(':');
			const nick = split[0];
			const target = split[1];
			if (target === 'FriendsCommon') {
				this.openProfile(nick, silentPrefixCheck.found, 'friends');
				return;
			}

			if (target === 'MarkVisitorSeen') {
				this.userService.getUserIdFromNick(nick).then(userId => {
					this.authenticatedClientService.currentK3Client.mutate(
						MarkProfileVisitorSeen,
						{ userId }
					);
				});
				// continue opening the profile as usual
			}

			if (context === CHANNEL_MESSAGE_COMMAND_CONTEXT) {
				const macroBoxService = await this.getMacroBoxService();
				if (macroBoxService.mayShowMacroBoxOfNick(nick)) {
					await macroBoxService.showMacroBoxOfNick(nick);
					return;
				}
			}

			this.openProfile(nick, silentPrefixCheck.found);
		}
	};

	private checkPrefix(
		s: string,
		prefix: string
	): { found: boolean; remainder: string } {
		const startedWith = s.startsWith(prefix);
		return {
			found: startedWith,
			remainder: startedWith ? s.substring(prefix.length) : s,
		};
	}

	private openProfile = (
		nick: string,
		silent: boolean,
		initialTab?: TabType
	): void => {
		this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(GetUserIdFromNick, {
				nick,
				accountForNickSwitch: true,
			})
			.match({
				ok: result => {
					return (
						result &&
						this.profileNavigationService.showProfileForUser(
							result,
							{
								openedFromTrackingLabel: 'Command',
								silent,
								showNickSwitchIcon:
									result.nick.toLowerCase() !==
									nick.toLowerCase(),
								initialTab,
							}
						)
					);
				},
				error: error => console.error(error),
			});
	};
}
