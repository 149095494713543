import { jsx as d } from "react/jsx-runtime";
import { useContext as u, Component as m } from "react";
import { usePresence as c } from "../../../components/AnimatePresence/use-presence.js";
import { LayoutGroupContext as f } from "../../../context/LayoutGroupContext.js";
import { SwitchLayoutGroupContext as l } from "../../../context/SwitchLayoutGroupContext.js";
import { globalProjectionState as h } from "../../../projection/node/state.js";
import { correctBorderRadius as n } from "../../../projection/styles/scale-border-radius.js";
import { correctBoxShadow as R } from "../../../projection/styles/scale-box-shadow.js";
import { addScaleCorrector as g } from "../../../projection/styles/scale-correction.js";
import { microtask as y } from "../../../frameloop/microtask.js";
import { frame as v } from "../../../frameloop/frame.js";
class T extends m {
  /**
   * This only mounts projection nodes for components that
   * need measuring, we might want to do it for all components
   * in order to incorporate transforms
   */
  componentDidMount() {
    const { visualElement: o, layoutGroup: e, switchLayoutGroup: t, layoutId: i } = this.props, { projection: r } = o;
    g(L), r && (e.group && e.group.add(r), t && t.register && i && t.register(r), r.root.didUpdate(), r.addEventListener("animationComplete", () => {
      this.safeToRemove();
    }), r.setOptions({
      ...r.options,
      onExitComplete: () => this.safeToRemove()
    })), h.hasEverUpdated = !0;
  }
  getSnapshotBeforeUpdate(o) {
    const { layoutDependency: e, visualElement: t, drag: i, isPresent: r } = this.props, s = t.projection;
    return s && (s.isPresent = r, i || o.layoutDependency !== e || e === void 0 ? s.willUpdate() : this.safeToRemove(), o.isPresent !== r && (r ? s.promote() : s.relegate() || v.postRender(() => {
      const p = s.getStack();
      (!p || !p.members.length) && this.safeToRemove();
    }))), null;
  }
  componentDidUpdate() {
    const { projection: o } = this.props.visualElement;
    o && (o.root.didUpdate(), y.postRender(() => {
      !o.currentAnimation && o.isLead() && this.safeToRemove();
    }));
  }
  componentWillUnmount() {
    const { visualElement: o, layoutGroup: e, switchLayoutGroup: t } = this.props, { projection: i } = o;
    i && (i.scheduleCheckAfterUnmount(), e && e.group && e.group.remove(i), t && t.deregister && t.deregister(i));
  }
  safeToRemove() {
    const { safeToRemove: o } = this.props;
    o && o();
  }
  render() {
    return null;
  }
}
function k(a) {
  const [o, e] = c(), t = u(f);
  return d(T, { ...a, layoutGroup: t, switchLayoutGroup: u(l), isPresent: o, safeToRemove: e });
}
const L = {
  borderRadius: {
    ...n,
    applyTo: [
      "borderTopLeftRadius",
      "borderTopRightRadius",
      "borderBottomLeftRadius",
      "borderBottomRightRadius"
    ]
  },
  borderTopLeftRadius: n,
  borderTopRightRadius: n,
  borderBottomLeftRadius: n,
  borderBottomRightRadius: n,
  boxShadow: R
};
export {
  k as MeasureLayout
};
