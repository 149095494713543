import * as inversify from 'inversify';
import { ServiceId } from '../ServiceId';
import { ServiceIdMap, TranslateServiceIds, getServices } from '../getServices';
import {
	ServiceBinder,
	ServiceBinderImpl,
	ServiceIdFunc,
} from './ServiceBinder';
import {
	Container,
	DynamicContainer,
	MutableContainer,
	MutableContainerImpl,
} from './Container';
import { $K3Container, $autostart } from './ids';

export interface K3ServiceBinder {
	globalScope: ServiceBinder;
	loggedInScope: ServiceBinder;
	/**
	 * Indicates that the target service is bound dynamically.
	 */
	dynamic: ServiceIdFunc<never>;
}

export class K3Container implements K3ServiceBinder, Container {
	// tslint:disable: member-ordering
	private readonly globalContainer = new MutableContainerImpl(
		new inversify.Container()
	);
	public readonly globalScope = new ServiceBinderImpl(this.globalContainer);
	public readonly dynamic: ServiceIdFunc<never> = (() => {}) as any;

	private readonly loggedInContainer = new DynamicContainer({
		enabled: false,
		containerFactory: () => {
			const c = new inversify.Container();
			c.parent = this.globalInversifyContainer;
			return c;
		},
	});
	public readonly loggedInScope = new ServiceBinderImpl(
		this.loggedInContainer
	);

	constructor() {
		this.globalContainer
			.inversifyContainer!.bind($K3Container.id)
			.toConstantValue(this);
	}

	public get globalInversifyContainer(): inversify.Container {
		if (!this.globalContainer.inversifyContainer) {
			throw new Error(
				'`this.globalContainer` should always be enabled, thus its `container` should always be defined.'
			);
		}

		return this.globalContainer.inversifyContainer;
	}

	public get activeInversifyContainer(): inversify.Container {
		if (this.loggedInContainer.container) {
			return this.loggedInContainer.container;
		}
		return this.globalInversifyContainer;
	}

	public setLoggedInState(isLoggedIn: boolean): void {
		this.loggedInContainer.setContainerState(isLoggedIn);
		this.activateAutostartServices();
	}

	public activateAutostartServices(): void {
		// we cannot just use `activeContainer` but have to query both containers
		// See https://github.com/inversify/InversifyJS/issues/1098 for the issue.
		if (this.globalInversifyContainer.isBound($autostart.id)) {
			this.globalInversifyContainer.getAll($autostart.id);
		}
		if (this.loggedInContainer.container) {
			if (this.loggedInContainer.container.isBound($autostart.id)) {
				this.loggedInContainer.container.getAll($autostart.id);
			}
		}
	}

	public getService<T>(serviceId: ServiceId<T>): T {
		const service = this.activeInversifyContainer.get(serviceId.id);
		return service as T;
	}

	public getServices<TServiceIds extends ServiceIdMap>(
		serviceIds: TServiceIds
	): TranslateServiceIds<TServiceIds> {
		return getServices(serviceIds, this.activeInversifyContainer);
	}

	public createSubContainer(): MutableContainer {
		const c = new inversify.Container();
		c.parent = this.activeInversifyContainer;
		return new MutableContainerImpl(c);
	}
}
