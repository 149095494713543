import * as React from 'react';
import { Spacer } from '@shared/components';
import { Flex, FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export function Indicators(props: {
  items: ReadonlyArray<unknown>;
  activeIndex: number;
}): React.ReactElement {
  return <div className={_c0}>
			{props.items.map((_item, i) => {
      const isActive = props.activeIndex === i;
      const isLastItem = i === props.items.length - 1;
      return <React.Fragment key={i}>
						<div className={_c1 + ((isActive ? "black-solid-660" : "black-solid-220") ? resolveIsDarkColor(isActive ? "black-solid-660" : "black-solid-220", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isActive ? _c2 : _c3)} />
						{!isLastItem && <Spacer size={'small'} />}
					</React.Fragment>;
    })}
		</div>;
}
const _c0 = " Knu-Flex alignSelf-center ";
const _c1 = " Knu-FlexCol size-8px borderRadius-minor ";
const _c2 = " bg-black-solid-660 ";
const _c3 = " bg-black-solid-220 ";