import { declareFormat, FormatId } from '@knuddels/i18n';
import { ContactListTab } from '@generated/graphql';
import { shouldBeUnreachable } from '@knuddels/std';

export type ContactsTab =
	| 'all'
	| 'friends'
	| 'watchlist'
	| 'fotomeet'
	| 'mentees'
	| 'latest';

export const ALL_CONTACT_TABS: ContactsTab[] = [
	'all',
	'friends',
	'watchlist',
	'fotomeet',
	'mentees',
	'latest',
];

export type AllContactsTabs = ContactsTab | 'friendrequests';

export function mapServerContactListTab(
	tab: ContactListTab
): Exclude<ContactsTab, 'all'> | undefined {
	switch (tab) {
		case ContactListTab.Fotomeet:
			return 'fotomeet';
		case ContactListTab.Friends:
			return 'friends';
		case ContactListTab.Latest:
			return 'latest';
		case ContactListTab.Mentee:
			return 'mentees';
		case ContactListTab.Watchlist:
			return 'watchlist';
		default:
			shouldBeUnreachable(tab);
			return undefined;
	}
}

export function getContactsTabTitleFormat(tab: AllContactsTabs): FormatId {
	switch (tab) {
		case 'friendrequests':
			return declareFormat({
				id: 'contacts.tab.friend-requests',
				defaultFormat: 'Friend requests',
			});
		case 'all':
			return declareFormat({
				id: 'contacts.tab.all',
				defaultFormat: 'All',
			});
		case 'friends':
			return declareFormat({
				id: 'contacts.tab.friends',
				defaultFormat: 'Friends',
			});
		case 'watchlist':
			return declareFormat({
				id: 'contacts.tab.watchlist',
				defaultFormat: 'Watchlist',
			});
		case 'fotomeet':
			return declareFormat({
				id: 'contacts.tab.fotomeet',
				defaultFormat: 'Fotomeet',
			});
		case 'mentees':
			return declareFormat({
				id: 'contacts.tab.mentees',
				defaultFormat: 'Mentor system',
			});
		case 'latest':
			return declareFormat({
				id: 'contacts.tab.latest',
				defaultFormat: 'Latest contacts',
			});
		default:
			return tab;
	}
}
