import { IconProps, ListItem, ListItemProps } from '@knuddels/component-library';
import * as React from 'react';
type SettingsListItemProps = Omit<ListItemProps, 'adornmentLeft' | 'adornmentRight'> & {
  iconLeft?: React.ReactElement<IconProps>;
  iconRight?: React.ReactElement<IconProps>;
};
export const SettingsListItem: React.FC<SettingsListItemProps> = ({
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...listItemProps
}) => {
  return <ListItem adornmentLeft={IconLeft && React.cloneElement(IconLeft, {
    size: 'large',
    active: true
  })} adornmentRight={IconRight && React.cloneElement(IconRight, {
    size: 'large',
    disabled: true
  })} {...listItemProps} />;
};