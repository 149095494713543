import {
	K3Container,
	K3ServiceBinder,
} from '@knuddels-app/DependencyInjection';
import * as ids from './serviceIds';
import {
	$ClientInfoStore,
	$FirstLoginStorage,
	$LastLoginStorage,
} from './serviceIds';

import { AuthenticatedClientService } from './client/AuthenticatedClientService';
import { AuthService } from './AuthService';
import { K3ApolloClientFactory } from './client/K3ApolloClientFactory';
import {
	AllowedServerAccessStore,
	CurrentEndpointStore,
	Endpoint,
} from './Endpoints';
import { DeviceTokenStore } from './DeviceToken';
import { EndpointProvider } from './Endpoints/EndpointProvider';
import { LoggedInContainerBridge } from './LoggedInContainerBridge';
import { LoggedInModuleLoader } from './LoggedInModuleLoader';
import { CheckSystemTimeService } from '@knuddels-app/Connection/CheckSystemTimeService';
import { LastLoginStorageImpl } from './login-data/LastLoginStorageImpl';
import { ClientInfoStore } from '@knuddels-app/Connection/ClientInfo/ClientInfoStore';
import { FirstLoginStorage } from '@knuddels-app/Connection/login-data/FirstLoginStorage';

export type { GraphQlOperationError } from './client/K3ApolloClient';
export { Endpoint };
export { K3ApolloClient } from './client/K3ApolloClient';
export { K3ApolloProvider } from './client/K3ApolloProvider';
export * from './client/operationHooks';
export * from './client/useQueryWithLocalCache';
export * from './client/useQueryWithSyncedCache';
export { RefreshSessionErrorScreen, DisconnectedBanner } from './components';
export type { TypedQueryOperation } from './client/Operation';

export {
	$AuthenticatedClientService,
	$AuthService,
	$CurrentEndpointStore,
} from './serviceIds';
export * from './client/ConnectionState';
export {
	KnownEndpoints,
	idToKnownEndpointId,
} from './Endpoints/KnownEndpoints';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.loggedInScope
		.bind(ids.$AuthenticatedClientService)
		.toSingleton(AuthenticatedClientService);
	ctx.globalScope.bind(ids.$AuthService).toSingleton(AuthService);
	ctx.globalScope
		.bind(ids.$K3ApolloClientFactory)
		.toSingleton(K3ApolloClientFactory);
	ctx.globalScope.bind(ids.$EndpointProvider).toSingleton(EndpointProvider);
	ctx.globalScope
		.bind(ids.$CurrentEndpointStore)
		.toSingleton(CurrentEndpointStore);
	ctx.globalScope.bind(ids.$DeviceTokenStore).toSingleton(DeviceTokenStore);

	ctx.globalScope
		.bind(ids.$LoggedInContainerBridge)
		.toSingletonAutostart(LoggedInContainerBridge);

	ctx.globalScope
		.bind(ids.$LoggedInModuleLoader)
		.toSingletonAutostart(LoggedInModuleLoader);

	ctx.loggedInScope
		.bind(ids.$CheckSystemTimeService)
		.toSingletonAutostart(CheckSystemTimeService);

	ctx.globalScope
		.bind($LastLoginStorage)
		.toSingletonAutostart(LastLoginStorageImpl);

	ctx.globalScope.bind($ClientInfoStore).toSingleton(ClientInfoStore);
	ctx.globalScope
		.bind(ids.$AllowedServerAccessStore)
		.toSingleton(AllowedServerAccessStore);

	ctx.globalScope.bind($FirstLoginStorage).toSingleton(FirstLoginStorage);
}

// tslint:disable: variable-name
// Get rid of all todoUseDI vars.
export let todoUseDI_currentEndpointStore: typeof ids.$CurrentEndpointStore.T;
export let todoUseDI_deviceTokenStore: typeof ids.$DeviceTokenStore.T;
export let todoUseDI_authService: typeof ids.$AuthService.T;

// TODO remove this function and use proper DI everywhere.
/**
 * @deprecated
 */
export function setupConstants(container: K3Container): void {
	todoUseDI_currentEndpointStore = container.getService(
		ids.$CurrentEndpointStore
	);
	todoUseDI_deviceTokenStore = container.getService(ids.$DeviceTokenStore);
	todoUseDI_authService = container.getService(ids.$AuthService);
}
