export enum OS {
	web,
	ios,
	android,
}

export const os: OS = OS.web;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OS {
	export function select<T>(specifics: { [osKey in OS]: T }): T {
		return specifics[os];
	}
}
