import { Flex, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $UserDataComponents, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { Spacer, StatusBadgeSizes } from '@shared/components';
// tslint:disable-next-line: no-module-bleeding
import { TextWithPrefixHighlight } from '@knuddelsModules/AutocompleteInputBar/bundle/components/Dropup/TextWithPrefixHighlight';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { User } from '@generated/graphql';
import { $createMentionNode } from '@shared/components/RichText/nodes/MentionNode';
import { MentionPlugin } from '@shared/components/RichText/plugins/MentionPlugin';
import { TypeaheadMenu } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/TypeaheadMenu';
import { withSearchCache } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/withSearchCache';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
export class NickTypeAheadOption extends MenuOption {
  constructor(public user: Pick<User, 'id' | 'nick' | 'isOnline'>) {
    super(user.nick);
  }
  toString(): string {
    return this.user.nick;
  }
}
export const getNickTypeAheadOptions = withSearchCache(async (autocompleteService: typeof $AutocompleteProviderService.T, queryText: string) => {
  return autocompleteService.getUsers(queryText).map(user => {
    return new NickTypeAheadOption(user);
  });
});
export const NickTypeaheadOptionRenderer: React.FC<{
  option: NickTypeAheadOption;
  query: string;
}> = ({
  query,
  option
}) => {
  const userData = useService($UserDataComponents);
  return <>
			<UserImage type={UserImageType.NICKLIST} userId={option.user.id} onlineBadge={<userData.UserOnlineStatusBadge userId={option.user.id} size={StatusBadgeSizes.SMALL} />} />
			<Spacer size={'minor'} />
			<div className={_c0 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<TextWithPrefixHighlight text={option.user.nick} highlightedPrefix={query} />
			</div>
		</>;
};
const InnerNickPlugin: React.FC<{
  anchor: React.MutableRefObject<HTMLElement>;
}> = ({
  anchor
}) => {
  const autocompleteService = useService($AutocompleteProviderService);
  const firebaseAnalytics = useService($FirebaseAnalyticsService);
  return <MentionPlugin<NickTypeAheadOption> getOptions={async queryText => getNickTypeAheadOptions(autocompleteService, queryText)} renderMenu={({
    options,
    itemProps,
    query,
    onClose
  }) => {
    return <TypeaheadMenu onClose={onClose} options={options} itemProps={itemProps} anchor={anchor} renderItem={(option, i) => {
      return <NickTypeaheadOptionRenderer option={option} query={query} key={i} />;
    }} />;
  }} trigger={`@`} createNode={option => {
    firebaseAnalytics.logEvent('Autocomplete_UserAutocomplete', 'User_Selected');
    return $createMentionNode({
      trigger: `@`,
      text: option.key,
      class: 'user-mention'
    });
  }} />;
};
export const NickPlugin = React.memo(InnerNickPlugin);
NickPlugin.displayName = 'NickPlugin';
const _c0 = " Knu-Flex bg-transparent ";