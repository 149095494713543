import { jsx as t } from "react/jsx-runtime";
import { useTheme as r } from "../../themes/useTheme.js";
import { Flex as i } from "../Layout/Flex.js";
const c = (e) => e.labelStyle !== "never" ? /* @__PURE__ */ t("div", { className: "Knu-Flex pt-minor", children: /* @__PURE__ */ t("span", { className: "Knu-Text text-body1 text-secondary", children: e.children }) }) : null, h = (e) => {
  const n = r().sizes.hitarea;
  return /* @__PURE__ */ t(
    i,
    {
      flexShrink: 0,
      pt: "minor",
      height: n + "px",
      width: n + "px",
      justifyContent: "center",
      mr: e.labelStyle !== "never" ? "tiny" : "none",
      children: e.children
    }
  );
};
export {
  h as HitArea,
  c as Label
};
