import * as React from 'react';
import { ContextMenu, ContextMenuEntry, ContextMenuProps, ContextMenuTitle } from '@shared/components';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { conversationOverviewTitleBarEvent } from '../../analytics';
import { ConversationListFilterType } from '@generated/graphql';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $MessengerNavigationService, $ReadSystemService } from '../../../providedServices';
import { STARRED_MESSAGES } from '../../i18n/shared-formats';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { MessengerRouterConfig } from '../MessengerRouter';
import { IconCheckmark, IconSettings } from '@knuddels/component-library';
import { IconRead } from '@shared/icons/IconRead';
import { IconStar } from '@shared/icons/IconStar';
import { IconArchive } from '@shared/icons/IconArchive';
import { ContextMenuDivider } from '@shared/components/molecules/Modal/ContextMenuDivider';
@injectable()
class OverviewOverflowContextMenuModel {
  constructor(@injectProps()
  private props: {
    navigate: (path: keyof MessengerRouterConfig) => void;
  }, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($MessengerNavigationService)
  private readonly messengerNavigationService: typeof $MessengerNavigationService.T, @inject($ReadSystemService)
  private readonly readSystemService: typeof $ReadSystemService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {}
  get filterType(): ConversationListFilterType {
    return this.clientSettingsService.conversationListFilterType;
  }
  public readonly closeContextMenu = (): void => {
    this.thisVisibleOverlay.dispose();
  };
  public readonly showArchivedConversations = (): void => {
    conversationOverviewTitleBarEvent.track('Titlebar_ShowArchive');
    this.closeContextMenu();
    this.props.navigate('archive');
  };
  public readonly markAllAsRead = (): void => {
    conversationOverviewTitleBarEvent.track('Titlebar_MarkAllAsRead');
    this.closeContextMenu();
    this.readSystemService.markAllConversationsAsRead();
  };
  public readonly showStarredMessages = (): void => {
    conversationOverviewTitleBarEvent.track('Titlebar_ShowMarkedMessages');
    this.closeContextMenu();
    this.props.navigate('starredMessages');
  };
  public readonly setFilterToAllMessages = (): void => {
    this.closeContextMenu();
    this.clientSettingsService.setConversationListFilterType(ConversationListFilterType.AllMessages);
  };
  public readonly setFilterToUnreadMessages = (): void => {
    this.closeContextMenu();
    this.clientSettingsService.setConversationListFilterType(ConversationListFilterType.UnreadMessages);
  };
  public readonly showSettings = (): void => {
    this.closeContextMenu();
    this.props.navigate('settings');
  };
}
const OverviewOverflowContextMenu = injectedComponent({
  name: 'MessengerOverviewOverflowContextMenu',
  model: OverviewOverflowContextMenuModel,
  props: declareProps<ContextMenuProps>()
}, ({
  model,
  ...props
}) => <ContextMenu {...props} closeContextMenu={model.closeContextMenu}>
			<ContextMenuTitle text={formatMessage(declareFormat({
    id: 'CONTEXT_MENU_VIEW_TITLE',
    defaultFormat: 'VIEW'
  }))} />
			<ContextMenuEntry icon={model.filterType === ConversationListFilterType.AllMessages ? IconCheckmark : 'none'} text={formatMessage(declareFormat({
    id: 'CONTEXT_MENU_SHOW_ALL_MESSAGES',
    defaultFormat: 'All messages'
  }))} onClick={model.setFilterToAllMessages} />
			<ContextMenuEntry icon={model.filterType === ConversationListFilterType.UnreadMessages ? IconCheckmark : 'none'} text={formatMessage(declareFormat({
    id: 'CONTEXT_MENU_SHOW_ONLY_UNREAD_MESSAGES',
    defaultFormat: 'Only unread messages'
  }))} onClick={model.setFilterToUnreadMessages} />

			<ContextMenuDivider />

			<ContextMenuEntry icon={IconStar} text={formatMessage(STARRED_MESSAGES)} onClick={model.showStarredMessages} testId={'show-starred-messages'} />
			<ContextMenuEntry icon={IconArchive} text={formatMessage(declareFormat({
    id: 'VIEW_ARCHIVED_CONVERSATIONS',
    defaultFormat: 'Archived conversations'
  }))} onClick={model.showArchivedConversations} />

			<ContextMenuDivider />

			<ContextMenuEntry icon={IconRead} text={formatMessage(declareFormat({
    id: 'MARK_ALL_AS_READ',
    defaultFormat: 'Mark all as read'
  }))} onClick={model.markAllAsRead} testId={'messenger-overflow-menu-mark-all-read'} />
			{globalEnv.product === 'stapp-messenger' && <>
					<ContextMenuDivider />
					<ContextMenuEntry icon={IconSettings} text={formatMessage(declareFormat({
      id: 'CONTEXT_MENU_SETTINGS',
      defaultFormat: 'Settings'
    }))} onClick={model.showSettings} testId={'show-help-and-feedback'} />
				</>}
		</ContextMenu>);
export const OverviewOverflowContextMenuOverlay = new OverlayFactory(OverviewOverflowContextMenu);