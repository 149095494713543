import { Feature as s } from "../../motion/features/Feature.js";
import { noop as r } from "../../utils/noop.js";
import { VisualElementDragControls as t } from "./VisualElementDragControls.js";
class u extends s {
  constructor(o) {
    super(o), this.removeGroupControls = r, this.removeListeners = r, this.controls = new t(o);
  }
  mount() {
    const { dragControls: o } = this.node.getProps();
    o && (this.removeGroupControls = o.subscribe(this.controls)), this.removeListeners = this.controls.addListeners() || r;
  }
  unmount() {
    this.removeGroupControls(), this.removeListeners();
  }
}
export {
  u as DragGesture
};
