import { ServiceId, useService } from '@knuddels-app/DependencyInjection';
import { $ModuleService } from './providedServices';
import { useEffect, useState } from 'react';

export function useLazyService<T extends ServiceId<any>>(id: T): T['TLazy'] {
	const moduleService = useService($ModuleService);

	return () => moduleService.loadServiceAndModule(id);
}

export const useLoadService = <T extends ServiceId<any>>(id: T) => {
	const getService = useLazyService(id);
	const [service, setService] = useState<T['T'] | undefined>();

	useEffect(() => {
		getService().then(loadedService => setService(loadedService));
	}, []);

	return service;
};
