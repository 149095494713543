import { Flex, FlexCol, IconClose, Text, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { Column, Image, ImageProps, Spacer } from '../../../atoms';
import { alignItemsCenter } from '../../../sharedComponentStyles';
export interface ModalHeaderProps {
  headline: string;
  pictureProps?: ImageProps;
  alignCenter?: boolean;
  closeModal?(): void;
}
export const ModalHeader = React.memo(({
  headline,
  pictureProps,
  alignCenter,
  closeModal
}: ModalHeaderProps) => {
  const conditionalCenterStyles = alignCenter ? alignItemsCenter : {};
  return <Column {...conditionalCenterStyles} testId={'modal-header'}>
				<Spacer size="xlarge" />
				{pictureProps && <Image {...pictureProps} />}
				{pictureProps && <Spacer size="base" />}
				<div className={_c0}>
					<div className={_c1 + (alignCenter ? _c2 : _c3)}>
						<Text type={'title'} style={{
          textAlign: resolveThemingValue(alignCenter ? 'center' : undefined, "theme", useTheme())
        }} className={_c4}>
							{headline}
						</Text>
					</div>
					{closeModal && <div style={{
        top: resolveThemingValue((-8 as ThemeOverride), "spacing", useTheme())
      }} className={_c5}>
							<IconClose size={'large'} onPress={closeModal} />
						</div>}
				</div>
				<Spacer size="small" />
			</Column>;
});
ModalHeader.displayName = 'ModalHeader';
const _c0 = " Knu-Flex position-relative height-48px ";
const _c1 = " Knu-Flex flex-1 ";
const _c2 = " justifyContent-center ";
const _c3 = " justifyContent-flex-start ";
const _c4 = "  ";
const _c5 = " Knu-FlexCol position-absolute right-none ";