import * as React from 'react';
import { autorun } from '@knuddels-app/mobx';
import { DependencyList } from 'react';

export const useReactiveState = <T>(
	selector: () => T,
	deps: DependencyList
) => {
	const [value, setValue] = React.useState(selector());

	React.useEffect(() => {
		return autorun({ name: 'Update reactive state' }, () => {
			setValue(selector());
		});
	}, deps);

	return value;
};
