import { ChannelListItem } from '../../shared/ChannelList/ChannelListVirtualItems';

export function extractChannelGroupIds(
	items: ChannelListItem[],
	range: { startIndex: number; endIndex: number }
): string[] {
	return items
		.filter(
			(item, index) => index >= range.startIndex && index < range.endIndex
		)
		.filter(item => 'channelGroup' in item)
		.map(it => ('channelGroup' in it ? it.channelGroup.id : null));
}
