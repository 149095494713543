import * as React from 'react';
import { NotificationBadge, Spacer } from '@shared/components';
import { $Environment } from '@knuddels-app/Environment';
import { gtag } from '@knuddels-app/analytics/gtag';
import { Category } from '@knuddels-app/analytics/Category';
import { useMessengerReadStateString } from '../../utils/useMessengerReadStateString';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { Flex, FlexCol, IconExternal, LineDivider, Text, toPointerHandler } from '@knuddels/component-library';
interface Props {
  title: string | React.ReactNode;
  isMinifiedOverview: boolean;
}
function gtagTitleBar(label: string): void {
  gtag('event', 'AppletSidebar_Titlebar', {
    event_category: Category.MessengerAppletSidebar,
    event_label: label
  });
}
@injectable()
class StAppOverviewTitleBarModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($Environment)
  private readonly environment: typeof $Environment.T) {}
  public readonly onTitleClick = (): void => {
    gtagTitleBar('Titlebar_TitleClicked');
    this.openMessenger();
  };
  public readonly onUnreadBadgeClick = (): void => {
    gtagTitleBar('Titlebar_UnreadBadgeClicked');
    this.openMessenger();
  };
  public readonly startMessenger = (): void => {
    gtagTitleBar('Titlebar_IconClicked');
    this.openMessenger();
  };
  private readonly openMessenger = (): void => {
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeOpenMessengerSlashCommand();
    }
  };
}
export const StAppOverviewTitleBar = injectedComponent({
  name: 'StAppOverviewTitleBar',
  model: StAppOverviewTitleBarModel,
  props: declareProps<Props>()
}, ({
  model,
  isMinifiedOverview,
  title
}) => {
  const unreadCount = useMessengerReadStateString();
  if (isMinifiedOverview) {
    return null;
  }
  return <div className={_c0}>
				<div className={_c1}>
					<div onClick={toPointerHandler(model.onTitleClick)} className={_c2}>
						<Text type={'subtitle'} numberOfLines={1} className={_c3}>
							{title}
						</Text>
					</div>
					<Spacer size={'small'} />
					{unreadCount && <div onClick={toPointerHandler(model.onUnreadBadgeClick)} className={_c4}>
							<NotificationBadge colorPreset={'stapp'} number={unreadCount} />
						</div>}
					<Spacer size={'auto'} />
					<IconExternal onPress={model.startMessenger} size={'large'} hoverColor={'black-transparent-660'} darkHoverColor={'white-transparent-660'} />
				</div>
				<LineDivider className={_c5} />
			</div>;
});
const _c0 = " Knu-FlexCol px-small position-relative ";
const _c1 = " Knu-Flex position-relative alignItems-center ";
const _c2 = " Knu-FlexCol cursor-pointer ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol cursor-pointer ";
const _c5 = "  ";