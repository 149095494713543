import * as tools from '@knuddels-app/tools/escapeForPhotoId';

export enum ProfilePicMode {
	smallSquare = 'sq',
	mediumSquare = 'msq',
	largeSquare = 'lsq',
	large = 'l',
	veryLarge = 'vl',
	medium = 'm',
	small = 's',
	default = '',
}

export function getProfilePicUrlForUsername(
	username: string | undefined | null,
	profilePicServerUrl: string,
	noPicAsFallback = false,
	mode = ProfilePicMode.largeSquare
): string | undefined {
	if (!username) {
		// return undefined if username is not valid (is empty string, undefined or null)
		return undefined;
	}
	username = tools.escapeForPhotoID(username);
	const usernameShort =
		username.length >= 3 ? username.substr(0, 3) : username;
	const noPicFallback = noPicAsFallback ? '?noPicAsFallback=true' : '';
	return `${profilePicServerUrl}${usernameShort}/${username}-pro0${mode}0p.img${noPicFallback}`;
}
