import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const A = r(
  /* @__PURE__ */ o(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      children: /* @__PURE__ */ o(
        "path",
        {
          d: "M 12 2 A 1 1 0 0 0 11 3 A 1 1 0 0 0 12 4 A 1 1 0 0 0 13 3 A 1 1 0 0 0 12 2 z M 11.984375 5.9863281 A 1.0001 1.0001 0 0 0 11 7 L 11 14.585938 L 8.7070312 12.292969 A 1.0001 1.0001 0 0 0 7.9902344 11.990234 A 1.0001 1.0001 0 0 0 7.2929688 13.707031 L 11.292969 17.707031 A 1.0001 1.0001 0 0 0 12.707031 17.707031 L 16.707031 13.707031 A 1.0001 1.0001 0 1 0 15.292969 12.292969 L 13 14.585938 L 13 7 A 1.0001 1.0001 0 0 0 11.984375 5.9863281 z M 2.984375 16.986328 A 1.0001 1.0001 0 0 0 2 18 L 2 20 C 2 21.093063 2.9069372 22 4 22 L 20 22 C 21.093063 22 22 21.093063 22 20 L 22 18 A 1.0001 1.0001 0 1 0 20 18 L 20 20 L 4 20 L 4 18 A 1.0001 1.0001 0 0 0 2.984375 16.986328 z",
          fill: "currentColor"
        }
      )
    }
  )
);
A.displayName = "IconDownload";
export {
  A as IconDownload
};
