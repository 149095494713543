import * as e from "react";
import { resolveIsDarkColor as s } from "../isDarkColor.js";
import { useTheme as t } from "../../../themes/useTheme.js";
const n = (o) => {
  const r = t();
  return e.useMemo(() => s(o, r), [o, r]);
}, a = (o) => {
  const r = n(o);
  return e.useMemo(() => r ? "content-is-dark" : "content-is-light", [r]);
};
export {
  a as useBgClassName,
  n as useIsDarkColor
};
