import { jsx as C } from "react/jsx-runtime";
import { createSvgIcon as H } from "./createSvgIcon.js";
const V = H(
  /* @__PURE__ */ C("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ C(
    "path",
    {
      d: "M21 6H3C1.895 6 1 6.895 1 8V17C1 18.105 1.895 19 3 19H21C22.105 19 23 18.105 23 17V8C23 6.895 22.105 6 21 6ZM11.5 10H12.5C12.776 10 13 10.224 13 10.5V11.5C13 11.776 12.776 12 12.5 12H11.5C11.224 12 11 11.776 11 11.5V10.5C11 10.224 11.224 10 11.5 10ZM7.5 10H8.5C8.776 10 9 10.224 9 10.5V11.5C9 11.776 8.776 12 8.5 12H7.5C7.224 12 7 11.776 7 11.5V10.5C7 10.224 7.224 10 7.5 10ZM4.5 16H3.5C3.224 16 3 15.776 3 15.5V14.5C3 14.224 3.224 14 3.5 14H4.5C4.776 14 5 14.224 5 14.5V15.5C5 15.776 4.776 16 4.5 16ZM4.5 12H3.5C3.224 12 3 11.776 3 11.5V10.5C3 10.224 3.224 10 3.5 10H4.5C4.776 10 5 10.224 5 10.5V11.5C5 11.776 4.776 12 4.5 12ZM16.5 16H7.5C7.224 16 7 15.776 7 15.5V14.5C7 14.224 7.224 14 7.5 14H16.5C16.776 14 17 14.224 17 14.5V15.5C17 15.776 16.776 16 16.5 16ZM16.5 12H15.5C15.224 12 15 11.776 15 11.5V10.5C15 10.224 15.224 10 15.5 10H16.5C16.776 10 17 10.224 17 10.5V11.5C17 11.776 16.776 12 16.5 12ZM20.5 16H19.5C19.224 16 19 15.776 19 15.5V14.5C19 14.224 19.224 14 19.5 14H20.5C20.776 14 21 14.224 21 14.5V15.5C21 15.776 20.776 16 20.5 16ZM20.5 12H19.5C19.224 12 19 11.776 19 11.5V10.5C19 10.224 19.224 10 19.5 10H20.5C20.776 10 21 10.224 21 10.5V11.5C21 11.776 20.776 12 20.5 12Z",
      fill: "currentColor"
    }
  ) })
);
V.displayName = "IconKeyboard";
export {
  V as IconKeyboard
};
