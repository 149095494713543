import * as React from 'react';
import { Spacer } from '@shared/components';
import { declareFormat, FormattedDateTime, FormattedMessage } from '@knuddels-app/i18n';
import { Flex, FlexCol, Text, ThemeOverride } from '@knuddels/component-library';
type NestedMessageProps = {
  type: React.ComponentType<any>;
  timestamp: string;
  senderNick: string;
};
export class NestedMessageHeader extends React.PureComponent<NestedMessageProps> {
  render(): React.ReactNode {
    const {
      senderNick,
      type,
      timestamp
    } = this.props;
    return <div className={_c0}>
				<div className={_c1}>
					{React.createElement(type)}
				</div>
				<Spacer size={'tiny'} />
				<div className={_c2}>
					<Text type={'body2'} state={'primary'} bold={true} selectable={true} className={_c3}>
						{senderNick + ' '}
						<FormattedDateTime value={+timestamp} day={'2-digit'} month={'2-digit'} year={'numeric'} />
						{', '}
						<FormattedDateTime value={+timestamp} hour={'numeric'} minute={'numeric'} />
						<FormattedMessage id={declareFormat({
            id: 'GERMAN_TIME',
            // cannot be an empty text or it will show the id
            defaultFormat: ' '
          })} />
					</Text>
				</div>
			</div>;
  }
}
const _c0 = " Knu-Flex alignItems-flex-start position-relative overflow-hidden ";
const _c1 = " Knu-FlexCol top-3px position-relative ";
const _c2 = " Knu-Flex flex-1 top-2px ";
const _c3 = "  ";