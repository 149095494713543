import { useMemo as i } from "react";
import { isForcedMotionValue as a } from "../../motion/utils/is-forced-motion-value.js";
import { isMotionValue as r } from "../../value/utils/is-motion-value.js";
import { buildHTMLStyles as l } from "./utils/build-styles.js";
import { createHtmlRenderState as s } from "./utils/create-render-state.js";
function u(t, o, n) {
  for (const e in o)
    !r(o[e]) && !a(e, n) && (t[e] = o[e]);
}
function c({ transformTemplate: t }, o) {
  return i(() => {
    const n = s();
    return l(n, o, t), Object.assign({}, n.vars, n.style);
  }, [o]);
}
function f(t, o) {
  const n = t.style || {}, e = {};
  return u(e, n, t), Object.assign(e, c(t, o)), e;
}
function M(t, o) {
  const n = {}, e = f(t, o);
  return t.drag && t.dragListener !== !1 && (n.draggable = !1, e.userSelect = e.WebkitUserSelect = e.WebkitTouchCallout = "none", e.touchAction = t.drag === !0 ? "none" : `pan-${t.drag === "x" ? "y" : "x"}`), t.tabIndex === void 0 && (t.onTap || t.onTapStart || t.whileTap) && (n.tabIndex = 0), n.style = e, n;
}
export {
  u as copyRawValuesOnly,
  M as useHTMLProps
};
