import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';

export const ipDetectionModule = new Module({
	name: 'Ip Detection Module',
	dependencies: [],
	providedServices,
	loadEvent: globalEnv.product === 'stapp-messenger' ? 'onDemand' : 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
});
