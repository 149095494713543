import * as React from 'react';
import { Flex, ThemeOverride } from '@knuddels/component-library';
import { $UserService } from '../../providedServices';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
export const KnuddelsPlusHeaderBackground: React.FC = observer('KnuddelsPlusHeaderBackground', () => {
  const userService = useService($UserService);
  const user = userService.currentUser;
  const hasKnuddelsPlus = user && user.hasKnuddelsPlus;
  return <>
				<Flex position={'absolute'} inset={'none'} opacity={0.22} darkOpacity={1} bg={'accent'} darkBg={hasKnuddelsPlus ? 'black-solid-880' : 'black-solid-660'} handleDarkProps />
				{hasKnuddelsPlus && <Flex position={'absolute'} left={('-45%' as ThemeOverride)} top={(-60 as ThemeOverride)} width={'120%'} height={165} bg={'accent'} {...{
      style: {
        filter: 'blur(40px) brightness(0.66) saturate(2.0)',
        transform: 'translate3d(0, 0, 0)' // force hardware acceleration
      }
    }} />}
			</>;
});