import { jsx as i } from "react/jsx-runtime";
import { Flex as h } from "./Flex.js";
import { ModalWrapper as s } from "./ModalWrapper.js";
import { ChildThemeProvider as a } from "../../themes/ThemeProvider.js";
import { useTheme as u } from "../../themes/useTheme.js";
const w = ({
  children: e,
  onPress: o,
  pointerEvents: n = "all",
  backgroundStyle: t = "opaque",
  closeCallbackType: r = "onPress",
  zIndex: m,
  innerRef: p
}) => {
  const d = u();
  return /* @__PURE__ */ i(
    s,
    {
      innerRef: p,
      zIndex: m,
      pointerEvents: n,
      children: /* @__PURE__ */ i(a, { theme: d, children: /* @__PURE__ */ i(
        h,
        {
          bg: t === "opaque" ? "backgroundScrim" : "transparent",
          position: "absolute",
          width: "100%",
          height: "100%",
          onPress: r === "onPress" ? o : void 0,
          onPointerDown: r === "onPointerDown" ? o : void 0,
          onPointerUp: r === "onPointerUp" ? o : void 0,
          children: e
        }
      ) })
    }
  );
};
export {
  w as Modal
};
