import { jsxs as l, jsx as e } from "react/jsx-runtime";
import { FlexCol as g } from "../Layout/FlexCol.js";
import { LineDivider as v } from "../Layout/LineDivider.js";
import { createAccessiblePointerEventHandler as f } from "../../a11y/createAccessiblePointerEventHandler.js";
import { clsx as t } from "../../node_modules/clsx/dist/clsx.m.js";
import { resolveIsDarkColor as s } from "../Layout/isDarkColor.js";
import { useTheme as b } from "../../themes/useTheme.js";
const I = ({
  onPress: i,
  adornmentLeft: r,
  adornmentRight: a,
  additionalInfo: o,
  badge: c,
  separator: m = r ? "extraLeftPadding" : "equalPadding",
  caption: x,
  captionId: p,
  disabled: h,
  flexElement: d = "text"
}) => {
  const u = f({
    pointerCallback: i
  }), n = b();
  return /* @__PURE__ */ l(
    g,
    {
      ...u,
      minHeight: 60,
      bg: "transparent",
      hoverBg: i ? "listHoverAtom" : void 0,
      focusBg: i ? "listActiveAtom" : void 0,
      children: [
        /* @__PURE__ */ l(
          "div",
          {
            className: t(
              "Knu-Flex flex-grow-1 alignItems-center px-base py-small ",
              s("transparent", n) ? "content-is-dark" : "content-is-light"
            ),
            children: [
              r ? /* @__PURE__ */ e(
                "div",
                {
                  className: t(
                    "Knu-Flex placeItems-center bg-accent borderRadius-tiny",
                    s("accent", n) ? "content-is-dark" : "content-is-light"
                  ),
                  style: { width: 32, height: 32 },
                  children: r
                }
              ) : null,
              /* @__PURE__ */ l(
                "div",
                {
                  className: t(
                    "Knu-FlexCol",
                    "bg-transparent",
                    s("transparent", n) ? "content-is-dark" : "content-is-light",
                    "overflow-hidden",
                    d === "text" && "flex-1",
                    r && "ml-base",
                    a && "mr-base"
                  ),
                  children: [
                    /* @__PURE__ */ l("div", { className: "Knu-Flex alignItems-center", children: [
                      /* @__PURE__ */ e(
                        "span",
                        {
                          id: p,
                          className: t(
                            "Knu-Text text-subtitle bold overflow-hidden",
                            h ? "text-tertiary" : "text-primary"
                          ),
                          style: {
                            textOverflow: "ellipsis"
                          },
                          children: x
                        }
                      ),
                      c && c
                    ] }),
                    o ? /* @__PURE__ */ e("span", { className: "Knu-Text text-tiny text-tertiary", children: o }) : null
                  ]
                }
              ),
              /* @__PURE__ */ e(
                "div",
                {
                  className: t(
                    "Knu-Flex",
                    "justifyContent-flexEnd",
                    d === "adornmentRight" && "flex-1"
                  ),
                  children: a
                }
              )
            ]
          }
        ),
        m !== "none" && /* @__PURE__ */ e(y, { type: m })
      ]
    }
  );
}, y = ({ type: i }) => /* @__PURE__ */ e(
  "div",
  {
    className: t(
      "Knu-Flex",
      "pr-minor",
      "alignSelf-stretch",
      i === "equalPadding" ? "pl-minor" : "pl-xxxlarge"
    ),
    children: /* @__PURE__ */ e(v, { color: "hairlineAtom" })
  }
);
export {
  I as ListItem
};
