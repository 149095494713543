import { injectable } from '@knuddels-app/DependencyInjection';
import { KeyboardServiceBase } from './KeyboardServiceBase';
import { action } from '../mobx';
import { isTouchDevice } from '@knuddels/component-library';

type VirtualKeyboard = {
	overlaysContent: boolean;
	show: () => void;
	hide: () => void;
	boundingRect: {
		height: number;
		width: number;
		x: number;
		y: number;
	};
	addEventListener: (
		event: string,
		callback: (event: {
			target: {
				boundingRect: {
					height: number;
					width: number;
					x: number;
					y: number;
				};
			};
		}) => void
	) => void;
};

const getVirtualKeyboard = (): VirtualKeyboard | null => {
	if ('virtualKeyboard' in navigator && isTouchDevice()) {
		return navigator.virtualKeyboard as VirtualKeyboard;
	}
	return null;
};

@injectable()
export class KeyboardService extends KeyboardServiceBase {
	private virtualKeyboard = getVirtualKeyboard();

	constructor() {
		super();
		if (this.virtualKeyboard) {
			this._supportsVirtualKeyboard = true;
			if (this.virtualKeyboard.boundingRect.height > 0) {
				this.keyboardHeight = this.virtualKeyboard.boundingRect.height;
			}
			this.virtualKeyboard.overlaysContent = true;
			this.virtualKeyboard.addEventListener(
				'geometrychange',
				(event: any) => {
					const { height } = event.target.boundingRect;
					if (height > 0) {
						this.handleKeyboardWillShow(height);
						this.handleKeyboardDidShow(height);
					} else {
						this.handleKeyboardHide();
					}
				}
			);
		}
	}

	@action
	private handleKeyboardWillShow = (height: number): void => {
		this._isKeyboardVisible = true;
		this._keyboardHeight = height;
	};

	@action
	private handleKeyboardDidShow = (height: number): void => {
		// keyboardWillShow is not being called on android, so we need to set isKeyboardVisible here as well
		this._isKeyboardVisible = true;
		this._keyboardHeight = height;
	};

	@action
	private handleKeyboardHide = (): void => {
		this._isKeyboardVisible = false;
	};

	hideKeyboard() {
		if (this.virtualKeyboard) {
			this.virtualKeyboard.hide();
		} else {
			super.hideKeyboard();
		}
	}
}
