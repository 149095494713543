import { extractEventInfo as L } from "../events/event-info.js";
import { addDomEvent as h } from "../events/add-dom-event.js";
import { addPointerEvent as m } from "../events/add-pointer-event.js";
import { Feature as E } from "../motion/features/Feature.js";
import { pipe as P } from "../utils/pipe.js";
import { isDragActive as w } from "./drag/utils/lock.js";
import { isNodeOrChild as T } from "./utils/is-node-or-child.js";
import { noop as u } from "../utils/noop.js";
import { frame as p } from "../frameloop/frame.js";
function l(v, e) {
  if (!e)
    return;
  const s = new PointerEvent("pointer" + v);
  e(s, L(s));
}
class B extends E {
  constructor() {
    super(...arguments), this.removeStartListeners = u, this.removeEndListeners = u, this.removeAccessibleListeners = u, this.startPointerPress = (e, s) => {
      if (this.isPressing)
        return;
      this.removeEndListeners();
      const t = this.node.getProps(), i = m(window, "pointerup", (r, n) => {
        if (!this.checkPressEnd())
          return;
        const { onTap: d, onTapCancel: c, globalTapTarget: g } = this.node.getProps(), f = !g && !T(this.node.current, r.target) ? c : d;
        f && p.update(() => f(r, n));
      }, {
        passive: !(t.onTap || t.onPointerUp)
      }), o = m(window, "pointercancel", (r, n) => this.cancelPress(r, n), {
        passive: !(t.onTapCancel || t.onPointerCancel)
      });
      this.removeEndListeners = P(i, o), this.startPress(e, s);
    }, this.startAccessiblePress = () => {
      const e = (i) => {
        if (i.key !== "Enter" || this.isPressing)
          return;
        const o = (r) => {
          r.key !== "Enter" || !this.checkPressEnd() || l("up", (n, d) => {
            const { onTap: c } = this.node.getProps();
            c && p.postRender(() => c(n, d));
          });
        };
        this.removeEndListeners(), this.removeEndListeners = h(this.node.current, "keyup", o), l("down", (r, n) => {
          this.startPress(r, n);
        });
      }, s = h(this.node.current, "keydown", e), t = () => {
        this.isPressing && l("cancel", (i, o) => this.cancelPress(i, o));
      }, a = h(this.node.current, "blur", t);
      this.removeAccessibleListeners = P(s, a);
    };
  }
  startPress(e, s) {
    this.isPressing = !0;
    const { onTapStart: t, whileTap: a } = this.node.getProps();
    a && this.node.animationState && this.node.animationState.setActive("whileTap", !0), t && p.postRender(() => t(e, s));
  }
  checkPressEnd() {
    return this.removeEndListeners(), this.isPressing = !1, this.node.getProps().whileTap && this.node.animationState && this.node.animationState.setActive("whileTap", !1), !w();
  }
  cancelPress(e, s) {
    if (!this.checkPressEnd())
      return;
    const { onTapCancel: t } = this.node.getProps();
    t && p.postRender(() => t(e, s));
  }
  mount() {
    const e = this.node.getProps(), s = m(e.globalTapTarget ? window : this.node.current, "pointerdown", this.startPointerPress, {
      passive: !(e.onTapStart || e.onPointerStart)
    }), t = h(this.node.current, "focus", this.startAccessiblePress);
    this.removeStartListeners = P(s, t);
  }
  unmount() {
    this.removeStartListeners(), this.removeEndListeners(), this.removeAccessibleListeners();
  }
}
export {
  B as PressGesture
};
