import { $K3Container } from '../DependencyInjection';
import { injectable, inject } from '../DependencyInjection/decorators';
import { ServiceId } from '../DependencyInjection/ServiceId';
import { Module } from './Module';
import { Deferred, BugIndicatingError } from '@knuddels/std';

interface ModuleInfo {
	onLoad: Promise<void>;
	isLoaded: boolean;
}

/**
 * This is the main component of the module system.
 */
@injectable()
export class ModuleService {
	private readonly modules = new Map<Module, ModuleInfo>();
	private readonly _knownModules = new Set<Module>();

	constructor(
		@inject($K3Container)
		private readonly k3Container: typeof $K3Container.T
	) {}

	public get knownModules(): ReadonlySet<Module> {
		return this._knownModules;
	}

	public hasModule(module: Module): boolean {
		return this._knownModules.has(module);
	}

	public hasModuleThatProvidesService(serviceId: ServiceId<any>): boolean {
		for (const mod of this._knownModules) {
			for (const s of mod.providedServices) {
				if (s.id === serviceId.id) {
					return true;
				}
			}
		}
		return false;
	}

	public getService<T>(serviceId: ServiceId<T>): T | null {
		try {
			return this.k3Container.getService(serviceId);
		} catch {
			return null;
		}
	}

	public loadModule(module: Module): Promise<void> {
		let info = this.modules.get(module);
		if (!info) {
			const d = new Deferred();
			info = {
				isLoaded: false,
				onLoad: d.promise,
			};
			this.modules.set(module, info);

			this._loadModule(module)
				.then(() => {
					// autostart services must be activated manually.
					// We do this after a module has been loaded
					// and registered all its services.
					this.k3Container.activateAutostartServices();
					d.resolve();
				})
				.catch(err => {
					d.reject(err);
				});
		}

		return info.onLoad;
	}

	private async _loadModule(module: Module): Promise<void> {
		this.addKnownModule(module);

		if (module.dependencies.length > 0) {
			await Promise.all(
				module.dependencies.map(dep => this.loadModule(dep))
			);
		}

		// console.log(`Loading module "${module.name}"...`);
		await module.load(this.k3Container);
		// console.log(`Module "${module.name}" loaded`);
	}

	public addKnownModule(module: Module): void {
		if (!module) {
			return; // TECHDEBT investigate why tests fail without this.
			throw new BugIndicatingError('Module is not defined.');
		}
		if (this._knownModules.has(module)) {
			return;
		}
		this._knownModules.add(module);
		module.loadStatic(this.k3Container);
	}

	public async loadModuleThatProvidesService(
		serviceId: ServiceId<any>
	): Promise<void> {
		let loaded = true;
		for (const mod of this._knownModules) {
			for (const s of mod.providedServices) {
				if (s.id === serviceId.id) {
					await this.loadModule(mod);
					loaded = true;
				}
			}
		}
		if (!loaded) {
			throw new Error(
				`No known module provided a service with id "${serviceId.id}"`
			);
		}
	}

	public loadServiceAndModule = async <T>(
		serviceId: ServiceId<T>
	): Promise<T> => {
		await this.loadModuleThatProvidesService(serviceId);
		return this.k3Container.getService(serviceId);
	};
}
