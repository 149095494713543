import * as React from 'react';
import { useEffect, useState } from 'react';
import { TypedQueryOperation } from './Operation';
import { createContext, useContextSelector } from 'use-context-selector';
import { OperationResult, useQuery } from './operationHooks';
import { BugIndicatingError } from '@knuddels/std';
type State = Record<string, {
  subscriberCount: number;
  data: any;
}>;
const SyncedCacheContext = createContext<{
  state: State;
  setState(updater: (oldState: State) => State): void;
}>({
  state: {},
  setState: () => {
    throw new BugIndicatingError('SyncedCacheContext Provider is necessary when using this context.');
  }
});
export const SyncedCacheProvider: React.FC = props => {
  const [state, setState] = useState<State>({});
  return <SyncedCacheContext.Provider value={{
    state,
    setState
  }}>
			{props.children}
		</SyncedCacheContext.Provider>;
};
export type QueryWithSyncedCacheReturnValue<TPrimaryResult> = OperationResult<TPrimaryResult> & {
  refetch(): void;
  updateCache(newDataOrUpdater: TPrimaryResult | ((oldData: TPrimaryResult | undefined) => TPrimaryResult)): void;
};
export const useQueryWithSyncedCache = <TVars, TResult, TPrimaryResult>(operation: TypedQueryOperation<TVars, TResult, TPrimaryResult>, variables: TVars): QueryWithSyncedCacheReturnValue<TPrimaryResult> => {
  const key = operation.document.loc?.source.body + JSON.stringify(variables);
  const [syncedCache, setSyncedCache] = useCache<TPrimaryResult>(key);
  const result = useQuery(operation, variables, 'no-cache');
  useEffect(() => {
    if (result.data) {
      setSyncedCache(result.data);
    }
  }, [result.data]);
  const currentData = syncedCache || result.data;
  return {
    ...result,
    data: currentData,
    updateCache: newDataOrUpdater => {
      const newData = typeof newDataOrUpdater === 'function' ? (newDataOrUpdater as Function)(currentData) : newDataOrUpdater;
      setSyncedCache(newData);
    }
  };
};
const useCache = <TPrimaryResult,>(key: string) => {
  const syncedCache: TPrimaryResult | undefined = useContextSelector(SyncedCacheContext, v => v.state[key]?.data);
  const syncedCacheUpdate = useContextSelector(SyncedCacheContext, v => v.setState);
  const updateData = (newData: TPrimaryResult) => {
    syncedCacheUpdate(oldState => ({
      ...oldState,
      [key]: {
        ...oldState[key],
        data: newData
      }
    }));
  };
  useCleanCacheOnUnmount(key);
  return ([syncedCache, updateData] as const);
};
const useCleanCacheOnUnmount = (key: string) => {
  const syncedCacheUpdate = useContextSelector(SyncedCacheContext, v => v.setState);
  useEffect(() => {
    syncedCacheUpdate(oldCache => ({
      ...oldCache,
      [key]: {
        ...oldCache[key],
        subscriberCount: (oldCache[key]?.subscriberCount || 0) + 1
      }
    }));
    return () => {
      syncedCacheUpdate(oldCache => {
        if (oldCache[key]?.subscriberCount === 1) {
          delete oldCache[key];
          return oldCache;
        }
        return {
          ...oldCache,
          [key]: {
            ...oldCache[key],
            subscriberCount: oldCache[key]?.subscriberCount - 1
          }
        };
      });
    };
  }, [key]);
};