import { User } from '@generated/graphql';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ViewService } from '@knuddels-app/layout';
import { reactionOnceWhen } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { $PaymentModule } from '@knuddelsModules/Payment';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $ProfileVisitors, $ProfileVisitorsService } from '@knuddelsModules/ProfileVisitors';
import * as React from 'react';
import { viewIdForSystemApp } from '@knuddelsModules/SystemApps';
export type OverviewType = 'messenger' | 'dashboard';
@injectable()
class ProfileVisitorsOverviewModel implements IModel {
  public readonly dispose = Disposable.fn();
  constructor(@injectProps()
  private readonly props: unknown, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject.lazy($PaymentModule)
  private readonly getPaymentModule: typeof $PaymentModule.TLazy, @inject($ProfileNavigationService)
  private readonly profileNavigationService: typeof $ProfileNavigationService.T, @inject($ProfileVisitorsService)
  private readonly profileVisitorsService: typeof $ProfileVisitorsService.T) {
    this.dispose.track(reactionOnceWhen({
      name: 'Track visitors open',
      fireImmediately: true
    }, () => this.profileVisitorsService.visitorsEnabled, this.trackOpened));
  }
  private trackOpened = (): void => {
    this.firebaseAnalyticsService.logEvent('Messenger_Conversationoverview', 'Visitors_Shown');
  };
  public handleVisitorClick = (id: User['id'], nick: string, isUnlocked: boolean): void => {
    if (!isUnlocked) {
      this.getPaymentModule().then(service => {
        service.openOverviewModal();
      });
    } else if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeOpenProfileSlashCommand(nick);
    } else {
      this.profileNavigationService.showProfile(id);
    }
    if (!isUnlocked) {
      this.firebaseAnalyticsService.logEvent('Visitors_Visitorpreview', `HiddenProfile_Clicked`);
    } else {
      this.firebaseAnalyticsService.logEvent('Visitors_Visitorpreview', `VisibleProfile_Clicked`);
    }
  };
  public onOverflowClicked = (): void => {
    this.firebaseAnalyticsService.logEvent('Visitors_Visitorpreview', 'All_Clicked');
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.client.executeSlashCommand('/profilevisitors app');
    } else {
      this.viewService.openViewAsOverlay(viewIdForSystemApp('ProfileVisitorsApp'));
    }
  };
}
export const ProfileVisitorsOverview = injectedComponent({
  model: ProfileVisitorsOverviewModel,
  name: 'ProfileVisitorsOverview',
  props: declareProps<{
    showTopSpacer: boolean;
    type: OverviewType;
    layout?: 'desktop' | 'mobile';
  }>(),
  inject: {
    i18n: $I18n,
    profileVisitors: $ProfileVisitors
  }
}, ({
  model,
  i18n,
  showTopSpacer,
  profileVisitors: {
    VisitorsPanel
  },
  type,
  layout = 'mobile'
}) => {
  return <VisitorsPanel type={type} layout={layout} showTopSpacer={showTopSpacer} title={declareFormat({
    id: 'MESSENGER_LIST_PROFILE_VISITORS_TITLE',
    defaultFormat: 'Your profile visitors'
  }).format(i18n)} overflowButtonText={declareFormat({
    id: 'MESSENGER_LIST_PROFILE_VISITORS_OVERFLOW_BUTTON',
    defaultFormat: 'All'
  }).format(i18n)} onOverflowClicked={model.onOverflowClicked} onVisitorClick={model.handleVisitorClick} />;
});