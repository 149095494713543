import { observer } from '@knuddels-app/mobx';
import { FlexCol, Panel, PanelsList, Tabs, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import * as React from 'react';
import { ProfileAlbumInfo, ProfileUser } from '../../profileQueryHelper';
import { NotAvailablePlaceholder } from '../ProfileContent/Photos/NotAvailablePlaceholder';
import { EditAlbums } from './Album/EditAlbums';
import { EditProfileDetailsContent } from './EditProfileContent/EditProfileDetailsContent';
import { ProfileFillBar } from './EditProfileContent/ProfileFillBar';
import { DetailsEditor } from './EditProfileContent/logic/DetailsEditor';
import { ProfileEditor } from './EditProfileContent/logic/ProfileEditor';
import { ScrollContext } from './EditProfileMobileScrollContext';
export const EditProfileContentTabs: React.FC<{
  editor: ProfileEditor;
  user: ProfileUser;
  albumInfo: ProfileAlbumInfo;
  activeTabIndex: number;
  setActiveTabIndex: (newIndex: number) => void;
}> = observer('EditProfileContentTabs', ({
  editor,
  user,
  albumInfo,
  activeTabIndex,
  setActiveTabIndex
}) => <div className={_c0}>
			<Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
				<PanelsList>
					<Panel>
						<div className={_c1 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<KeyboardAvoidanceView type="shrink" style={{
            width: '100%',
            height: '100%'
          }}>
								<DetailsContent editor={editor.detailsEditor} />
							</KeyboardAvoidanceView>
						</div>
					</Panel>
					<Panel>
						<div className={_c2 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<KeyboardAvoidanceView type="shrink">
								{albumInfo ? <EditAlbums userId={user.id} editor={editor.albumsEditor} profilePhoto={albumInfo.albumProfilePhoto} albums={albumInfo.albums} /> :
            // Show fallback screen if the photo server is not available
            <NotAvailablePlaceholder />}
							</KeyboardAvoidanceView>
						</div>
					</Panel>
				</PanelsList>
			</Tabs>
		</div>);
const DetailsContent: React.FC<{
  editor: DetailsEditor;
}> = observer('DetailsContent', ({
  editor
}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  return <ScrollContext.Provider value={scrollRef}>
				<div ref={scrollRef} className={_c3 + " " + 'hide-scroll-indicator' + " "}>
					<div onPointerDown={() => {
        /* this is required for scrolling on ipads. otherwise no touch events are processed */
      }} className={_c4}>
						<div className={_c5 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<ProfileFillBar progress={editor.progress} />
						</div>

						<EditProfileDetailsContent editor={editor} />
					</div>
				</div>
			</ScrollContext.Provider>;
});
const _c0 = " Knu-FlexCol flex-1 minHeight-0-px ";
const _c1 = " Knu-FlexCol width-100-percent bg-contentBg position-relative ";
const _c2 = " Knu-FlexCol width-100-percent bg-contentBg position-relative ";
const _c3 = " Knu-FlexCol overflow-auto touchAction-pan-y ";
const _c4 = " Knu-FlexCol position-relative width-100-percent ";
const _c5 = " Knu-FlexCol position-relative bg-contentLightBg shadow-Shadow2 zIndex-1 ";