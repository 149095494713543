const d = (s) => Math.round(s * 1e5) / 1e5, n = /-?(?:\d+(?:\.\d+)?|\.\d+)/gu, t = /(?:#[\da-f]{3,8}|(?:rgb|hsl)a?\((?:-?[\d.]+%?[,\s]+){2}-?[\d.]+%?\s*(?:[,/]\s*)?(?:\b\d+(?:\.\d+)?|\.\d+)?%?\))/giu, o = /^(?:#[\da-f]{3,8}|(?:rgb|hsl)a?\((?:-?[\d.]+%?[,\s]+){2}-?[\d.]+%?\s*(?:[,/]\s*)?(?:\b\d+(?:\.\d+)?|\.\d+)?%?\))$/iu;
function e(s) {
  return typeof s == "string";
}
function i(s) {
  return s == null;
}
export {
  t as colorRegex,
  n as floatRegex,
  i as isNullish,
  e as isString,
  d as sanitize,
  o as singleColorRegex
};
