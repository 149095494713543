import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { action, computed, observable } from '@knuddels-app/mobx';
import { $ViewService, LayoutPosition } from '@knuddels-app/layout';
import { channelViewId } from '@knuddelsModules/Channel';
import { isNative } from '@knuddels-app/tools/isNative';
import { $ScreenService } from '@knuddels-app/Screen';
@injectable()
export class NavHidingService {
  public readonly dispose = Disposable.fn();
  @observable
  private _isHidden = false;
  @observable
  private _systemAppOverlayOpened = false;
  constructor(@inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T) {}
  @computed
  public get isHidden(): boolean {
    return this.nativeSystemAppOverlayOpened || this._isHidden && !this.viewService.layout.isViewInBackground(channelViewId, LayoutPosition.Main);
  }
  @computed
  private get nativeSystemAppOverlayOpened(): boolean {
    return this._systemAppOverlayOpened && isNative() && this.screenService.isStackedLayout;
  }
  @action.bound
  public hide(): void {
    this._isHidden = true;
  }
  @action.bound
  public show(): void {
    this._isHidden = false;
  }
  @action.bound
  public setHidden(hidden: boolean): void {
    if (hidden) {
      this.hide();
    } else {
      this.show();
    }
  }
  @action.bound
  public setSystemAppOverlayOpened(opened: boolean): void {
    this._systemAppOverlayOpened = opened;
  }
}