import * as React from 'react';

export const ConversationIdContext = React.createContext<string | undefined>(
	undefined
);

export const useConversationId = (): string => {
	const conversationId = React.useContext(ConversationIdContext);

	if (!conversationId) {
		throw new Error(
			'ConversationIdContext is not provided. Make sure to wrap your component with ConversationIdProvider.'
		);
	}

	return conversationId;
};
