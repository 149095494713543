import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as r } from "./createSvgIcon.js";
const C = r(
  /* @__PURE__ */ o(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      fill: "currentColor",
      children: /* @__PURE__ */ o(
        "path",
        {
          d: "M 2.5 8 C 2.372 8 2.2439844 8.0489844 2.1464844 8.1464844 C 2.0484844 8.2434844 2 8.372 2 8.5 L 2 15 A 1 1 0 0 0 3 16 L 9.5 16 C 9.628 16 9.7555156 15.950516 9.8535156 15.853516 C 10.048516 15.658516 10.048516 15.341484 9.8535156 15.146484 L 7.2207031 12.513672 C 8.6282801 11.265495 10.470063 10.5 12.5 10.5 C 15.649 10.5 18.367875 12.32275 19.671875 14.96875 C 19.947875 15.52875 20.588641 15.803469 21.181641 15.605469 C 21.890641 15.369469 22.250875 14.559672 21.921875 13.888672 C 20.215875 10.403672 16.643 8 12.5 8 C 9.7794954 8 7.3175698 9.0503139 5.4570312 10.75 L 2.8535156 8.1464844 C 2.7560156 8.0489844 2.628 8 2.5 8 z",
          fill: "currentColor"
        }
      )
    }
  )
);
C.displayName = "IconUndo";
export {
  C as IconUndo
};
