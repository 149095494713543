// we need this to avoid major security flaw https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
export const openInNewTab = (url: string) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) {
		newWindow.opener = null;
	}
};

export const openUrl = (url: string, target: string) => {
	const newWindow = window.open(url, target, 'noopener,noreferrer');
	if (newWindow) {
		newWindow.opener = null;
	}
};
