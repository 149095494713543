import * as providedServices from './providedServices';
import { channelModule } from '@knuddelsModules/Channel/module';

import { Module } from '@knuddels-app/ModuleSystem';
import { $ViewProvider } from '@knuddels-app/layout';
import {
	channelListViewId,
	ChannelListViewProvider,
} from '@knuddelsModules/ChannelList/ChannelListViewProvider';
import {
	$ChannelListCommandService,
	ChannelListCommandService,
} from '@knuddelsModules/ChannelList/ChannelListCommandService';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { adsModule } from '@knuddelsModules/Ads/module';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import { settingsModule } from '@knuddelsModules/Settings/module';

export const channelListModule = new Module({
	name: 'ChannelListModule',
	dependencies: [userDataModule, channelModule, adsModule, settingsModule],
	loadEvent: 'onDemand',
	load: {
		from: () =>
			import(
				/* webpackChunkName: "channellist" */ './bundle/registerServices'
			),
	},
	providedServices,
	loadStatic: ctx => {
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(ChannelListViewProvider);
		ctx.loggedInScope
			.bind($ChannelListCommandService)
			.toSingletonAutostart(ChannelListCommandService);

		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toDynamicSingleton({}, () => ({
				getItems: () => [
					{
						id: 'channelList',
						kind: 'view',
						view: channelListViewId,
						trackingId: 'ChannelList',
					},
				],
			}));
	},
});
