import { todoUseDI_i18n } from '@knuddels-app/i18n';

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

/**
 * Returns the date difference in days.
 * @param {number} ts
 * @returns {number} 0 if ts was today, 1 if ts was yesterday, -1 if ts is tomorrow ...
 */
export function getDateDifference(ts: number): number {
	const normedToday = new Date().setHours(0, 0, 0, 0);
	const normedTimestampDate = new Date(ts).setHours(0, 0, 0, 0);
	const timeDiff = normedToday - normedTimestampDate;
	return Math.ceil(timeDiff / ONE_DAY_IN_MILLIS);
}

/**
 * Gets the date represented by the given timestamp. If the date is more recent than relativeDays days ago, it will format
 * the date relative (today, yesterday)
 */
export function getFormattedDate(timestamp: number, relativeDays = 2): string {
	const dateDifference = getDateDifference(timestamp);

	if (dateDifference < relativeDays) {
		return todoUseDI_i18n().formatDateTimeRelative(timestamp, 'day', {
			numeric: 'auto',
		});
	} else {
		return todoUseDI_i18n().formatDateTime(timestamp, {
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
		});
	}
}
