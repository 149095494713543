import { isAnimationControls as o } from "../../../animation/utils/is-animation-controls.js";
import { createAnimationState as n } from "../../../render/utils/animation-state.js";
import { Feature as a } from "../Feature.js";
class u extends a {
  /**
   * We dynamically generate the AnimationState manager as it contains a reference
   * to the underlying animation library. We only want to load that if we load this,
   * so people can optionally code split it out using the `m` component.
   */
  constructor(t) {
    super(t), t.animationState || (t.animationState = n(t));
  }
  updateAnimationControlsSubscription() {
    const { animate: t } = this.node.getProps();
    o(t) && (this.unmountControls = t.subscribe(this.node));
  }
  /**
   * Subscribe any provided AnimationControls to the component's VisualElement
   */
  mount() {
    this.updateAnimationControlsSubscription();
  }
  update() {
    const { animate: t } = this.node.getProps(), { animate: i } = this.node.prevProps || {};
    t !== i && this.updateAnimationControlsSubscription();
  }
  unmount() {
    var t;
    this.node.animationState.reset(), (t = this.unmountControls) === null || t === void 0 || t.call(this);
  }
}
export {
  u as AnimationFeature
};
