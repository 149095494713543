import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $NotificationBarService } from '../../providedServices';
import { NotificationBarContainer } from './NotificationBarContainer';
import { ScreenWidth, useScreenWidth } from '@knuddels-app/Screen';
export const NotificationBarProvider = observer('NotificationBarProvider', ({
  children
}: React.PropsWithChildren<unknown>) => {
  const notificationService = useService($NotificationBarService);
  const isTinyScreen = useScreenWidth() === ScreenWidth.XS;
  return <NotificationBarContainer isTinyScreen={isTinyScreen} notification={notificationService.currentNotification}>
				{children}
			</NotificationBarContainer>;
});