import { BugIndicatingError } from '@knuddels/std';
import * as React from 'react';
export const IsEditingPhotoDescriptionContext = React.createContext(false);
export const SetEditingPhotoDescriptionContext = React.createContext<{
  startEditing: () => void;
  stopEditing: () => void;
}>({
  startEditing: () => {
    throw new BugIndicatingError('Method not implemented');
  },
  stopEditing: () => {
    throw new BugIndicatingError('Method not implemented');
  }
});
export const PhotoDescriptionHasChangesContext = React.createContext(false);
export const SetPhotoDescriptionHasChangesContext = React.createContext<(hasChanges: boolean) => void>(() => {
  throw new BugIndicatingError('Method not implemented');
});
export const EditPhotoDescriptionContextProvider: React.FC = ({
  children
}) => {
  const [isEditingDescription, setEditingDescription] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const setContextValue = React.useMemo<React.ContextType<typeof SetEditingPhotoDescriptionContext>>(() => ({
    startEditing: () => setEditingDescription(true),
    stopEditing: () => setEditingDescription(false)
  }), [setEditingDescription]);
  return <IsEditingPhotoDescriptionContext.Provider value={isEditingDescription}>
			<SetEditingPhotoDescriptionContext.Provider value={setContextValue}>
				<PhotoDescriptionHasChangesContext.Provider value={hasChanges}>
					<SetPhotoDescriptionHasChangesContext.Provider value={setHasChanges}>
						{children}
					</SetPhotoDescriptionHasChangesContext.Provider>
				</PhotoDescriptionHasChangesContext.Provider>
			</SetEditingPhotoDescriptionContext.Provider>
		</IsEditingPhotoDescriptionContext.Provider>;
};