import * as React from 'react';
import { getNativeFilePicker } from './getNativeFilePicker';
import { NativeFilePickerTrigger } from './filePickerInterfaces';

export const useNativeFilePicker = (
	multiSelect = false
): NativeFilePickerTrigger => {
	// We need to store the selector in a ref because otherwise
	// the callback isn't being called in some browsers
	const filePicker = React.useRef<ReturnType<typeof getNativeFilePicker>>();

	return callback => {
		filePicker.current = getNativeFilePicker(multiSelect);
		filePicker.current(callback);
	};
};
