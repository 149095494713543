import * as React from 'react';
import { $UserDataComponents, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { VistorsPanelItem } from './VistorsPanelItem';
import { User } from '@generated/graphql';
import { StatusBadgeSizes } from '@shared/components';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
export const VisitorItem = injectedComponent({
  name: 'VisitorItem',
  props: declareProps<{
    id: User['id'];
    nick: string | React.ReactNode;
    blurred: boolean;
    onClick: () => void;
  }>(),
  inject: {
    UserDataComponents: $UserDataComponents
  }
}, ({
  nick,
  onClick,
  blurred,
  id,
  UserDataComponents
}) => {
  return <VistorsPanelItem label={nick} onClick={onClick}>
				<UserImage blurred={blurred} highlight={blurred ? 'disabled' : 'simple'} type={UserImageType.MESSENGER} userId={id} onlineBadge={<UserDataComponents.UserOnlineStatusBadge userId={id} size={StatusBadgeSizes.NORMAL} />} />
			</VistorsPanelItem>;
});