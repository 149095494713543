import { useMutation } from '@knuddels-app/Connection';
import { CancelFriendRequest } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';

export const useCancelFriendRequest = (
	trackSnackbar: (message: string) => void = () => {}
) => {
	const [cancelFriendRequest] = useMutation(CancelFriendRequest);
	const snackbarService = useService($SnackbarService);

	return (userId: string) => {
		cancelFriendRequest({ userId }).then(result => {
			switch (result.data?.__typename) {
				case 'Success':
					// state is updated through event
					break;
				case 'InternalError':
				default:
					trackSnackbar('CancelFriendRequestError');
					snackbarService.showGenericError();
			}
		});
	};
};
