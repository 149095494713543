import { Box, FlexCol, useTheme, VirtualList, VirtualListItem, VirtualListRenderItemInfo, FallbackBox, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { AlbumDetailPhotoFragment, AlbumFragment, User } from '@generated/graphql';
import { AlbumsEditor } from '../EditProfileContent/logic/AlbumsEditor';
import { expectUnreachable } from '@knuddels/std';
import { PhotoRow, PhotoRowElement } from '../../PhotoRow';
import { splitToPhotoRows } from '../../splitToPhotoRows';
import { NewAlbumDivider } from './NewAlbumDivider';
import { useService } from '@knuddels-app/DependencyInjection';
import { $AlbumPhotoUploadService } from '../../../../../providedServices';
import { observer } from '@knuddels-app/mobx';
import { EditAlbumHeader } from './EditAlbumHeader';
import { ProfilePhotoSection } from '../../ProfileContent/ProfilePhotoSection';
const PHOTO_SIZE = 104;
const PHOTO_SPACING = 8;
const templateHeader = 'header';
const templateRow = 'row';
const templateSpacer = 'spacer';
const templateNewAlbum = 'newAlbum';
const templateProfilePhoto = 'profilePhoto';
type AlbumsTabListItem = HeaderListItem | RowListItem | SpacerListItem | NewAlbumItem | ProfilePhotoListItem;
interface HeaderListItem extends VirtualListItem, React.ComponentProps<typeof EditAlbumHeader> {
  type: typeof templateHeader;
}
interface RowListItem extends VirtualListItem {
  type: typeof templateRow;
  userId: User['id'];
  rowElements: readonly PhotoRowElement[];
  editor: AlbumsEditor;
}
interface SpacerListItem extends VirtualListItem {
  type: typeof templateSpacer;
}
interface NewAlbumItem extends VirtualListItem {
  type: typeof templateNewAlbum;
  position: number;
}
interface ProfilePhotoListItem extends VirtualListItem {
  type: typeof templateProfilePhoto;
  userId: User['id'];
  photo: AlbumDetailPhotoFragment | undefined;
}
export const EditAlbums: React.FC<{
  userId: User['id'];
  editor: AlbumsEditor;
  profilePhoto: AlbumDetailPhotoFragment | undefined;
  albums: readonly AlbumFragment[];
}> = observer('EditAlbums', ({
  userId,
  profilePhoto,
  albums,
  editor
}) => {
  const [width, setWidth] = React.useState(0);
  const theme = useTheme();
  const listData = useListData(userId, editor, profilePhoto, albums, width - 2 * theme.spacings.base);
  return <FallbackBox onLayout={e => setWidth(e.width)} className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<VirtualList gapTop={'base'} gapBottom={'base'} data={listData} renderItem={renderListItem} className={_c1} />
		</FallbackBox>;
});
const useListData = (userId: User['id'], editor: AlbumsEditor, profilePhoto: AlbumDetailPhotoFragment | undefined, albums: readonly AlbumFragment[], width: number) => {
  const uploadingPhotos = useService($AlbumPhotoUploadService).uploadingPhotos;
  return React.useMemo(() => {
    const result: AlbumsTabListItem[] = [];
    result.push({
      userId,
      type: templateProfilePhoto,
      photo: profilePhoto,
      height: 156
    });
    result.push({
      type: templateSpacer,
      height: 20
    });
    albums.forEach((album, albumIndex) => {
      const uploadingPhotosInAlbum = uploadingPhotos.filter(photo => photo.albumId === album.id);
      result.push({
        type: templateNewAlbum,
        height: 18,
        position: albumIndex
      });
      result.push({
        type: 'header',
        height: 68,
        album,
        editor,
        albumCount: albums.length,
        index: albumIndex,
        onTitleChange: newTitle => {
          editor.changeTitle(album.id, newTitle);
        }
      });
      const rows = splitToPhotoRows(album, PHOTO_SIZE, PHOTO_SPACING, width, uploadingPhotosInAlbum, true);
      rows.forEach((row, index) => {
        if (index > 0) {
          result.push({
            type: templateSpacer,
            height: PHOTO_SPACING
          });
        }
        result.push({
          type: templateRow,
          rowElements: row,
          height: PHOTO_SIZE,
          userId,
          editor
        });
      });
      result.push({
        type: templateSpacer,
        height: 10
      });
    });
    result.push({
      type: templateNewAlbum,
      height: 26,
      position: albums.length
    });
    return result;
  }, [albums, width, uploadingPhotos]);
};
function renderListItem({
  item
}: VirtualListRenderItemInfo<AlbumsTabListItem>): JSX.Element {
  switch (item.type) {
    case 'header':
      return <EditAlbumHeader key={item.album.id} {...item} />;
    case 'row':
      return <PhotoRow editor={item.editor} userId={item.userId} elements={item.rowElements} size={PHOTO_SIZE} spacing={PHOTO_SPACING} />;
    case 'spacer':
      return <div style={{
        height: resolveThemingValue(item.height, "sizes", useTheme())
      }} className={_c2} />;
    case 'newAlbum':
      return <NewAlbumDivider position={item.position} />;
    case 'profilePhoto':
      return <ProfilePhotoSection userId={item.userId} photo={item.photo} photoSize={PHOTO_SIZE} photoSpacing={PHOTO_SPACING} editable />;
    default:
      expectUnreachable(item);
  }
}
const _c0 = " Knu-FlexCol width-100-percent height-100-percent bg-contentLightBg ";
const _c1 = "  ";
const _c2 = " Knu-Box ";