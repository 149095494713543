import { Serializer, createJsonSerializer } from './serializers';
import { $LocalStorage } from '@knuddels-app/local-storage/providedServices';

export interface LocalStorageKeyOptions<T> {
	/**
	 * The name of this key. `(name, version, scope.namespace)` should be unique.
	 */
	name: string;
	getNamespace?: () => string | undefined;

	/**
	 * The version of this key. Defaults to 1.
	 * Must be greater than or equal to 1.
	 * Increase it whenever a breaking change occurs.
	 * Use `tryMigrateFromOld` to migrate and delete old versions of this entry.
	 * The version is included in the id of this key and is only used to make the key unique among
	 * other keys with a different version.
	 */
	version?: number;

	/**
	 * The scope determines the visibility of this storage entry.
	 * If set, a cookie is used. If not set, the local storage is used.
	 */
	scope?: Scope;

	/**
	 * Only applicable for cookies which may not be used.
	 * Provide "withSession" if you want the cookie
	 * to be deleted when the session ends (i.e. the user restarts the browser).
	 */
	cookieExpires: { inDays: number } | 'withSession';

	/**
	 * Is called whenever the storage entry associated with this key does not exist yet but is about to be read/set.
	 * If a value is returned, it will be stored immediately for this entry.
	 * You can clean up old keys here (if they still exist).
	 * Is only called once per app start.
	 */
	tryMigrateFromOld?: (
		localStorage: typeof $LocalStorage.T
	) => { value: T } | undefined;
}

export class LocalStorageKey<T> {
	public static withJsonSerializer<T>(
		options: LocalStorageKeyOptions<T> & { defaultValue: T }
	): LocalStorageKey<T>;
	public static withJsonSerializer<T>(
		options: LocalStorageKeyOptions<T | undefined> & {
			defaultValue?: T;
		}
	): LocalStorageKey<T | undefined>;
	public static withJsonSerializer<T>(
		options: LocalStorageKeyOptions<T> & { defaultValue?: T }
	): LocalStorageKey<T | undefined> {
		return new LocalStorageKey(
			Object.assign(
				{
					serializer: createJsonSerializer<T>(
						'defaultValue' in options
							? { defaultValue: options.defaultValue }
							: {}
					),
				},
				options
			)
		);
	}

	public readonly name: string;
	public readonly version: number;
	public readonly serializer: Serializer<T>;
	public readonly scope?: Scope;
	public readonly cookieExpires: { inDays: number } | 'withSession';
	public readonly tryMigrateFromOld: (
		localStorage: typeof $LocalStorage.T
	) => { value: T } | undefined;

	/**
	 * A unique identifier of this key.
	 */
	public get id(): string {
		let id = this.name;
		const namespace = this.options.getNamespace?.();
		if (namespace) {
			id = `${namespace}__${id}`;
		}
		if (this.version !== 1) {
			id += `_v${this.version}`;
		}

		return id;
	}

	public get namespace(): string | undefined {
		return this.options.getNamespace?.();
	}

	constructor(
		public readonly options: LocalStorageKeyOptions<T> & {
			/**
			 * An object that serializes/deserializes values to a string representation.
			 */
			serializer: Serializer<T>;
		}
	) {
		this.name = options.name;
		if (options.version !== undefined) {
			if (options.version < 1) {
				throw new Error('Invalid version');
			}
			this.version = options.version;
		} else {
			this.version = 1;
		}
		this.serializer = options.serializer;
		this.scope = options.scope;
		this.cookieExpires = options.cookieExpires;
		this.tryMigrateFromOld = options.tryMigrateFromOld || (() => undefined);
	}

	public getIdForNamespace(namespace: string): string {
		let id = this.name;
		id = `${namespace}__${id}`;
		if (this.version !== 1) {
			id += `_v${this.version}`;
		}

		return id;
	}
}

export interface Scope {
	/** Only applicable for cookies */
	cookieDomain: string;
}
