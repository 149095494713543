import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	$AuthenticatedClientService,
	K3ApolloClient,
} from '@knuddels-app/Connection';
import {
	err,
	expectUnreachable,
	ok,
	Result,
	ResultPromise,
} from '@knuddels/std';
import {
	Children,
	Country,
	EditChildrenEntry,
	EditDateEntry,
	EditFreeTextEntry,
	EditProfileEntryFragment,
	EditRelationshipStatusEntry,
	EditSexualOrientationEntry,
	EditSmokerEntry,
	EditStringListEntry,
	ProfileEditField,
	RelationshipStatus,
	Scalars,
	SexualOrientation,
	Smoker,
	EditCountryEntry,
} from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { editProfileFieldData } from '../components/ProfileOverlay/EditProfile';

export type SaveFieldError = void | null | string;

@injectable()
export class SaveProfileService {
	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {}

	public readonly saveField = async (
		entry: EditProfileEntryFragment
	): Promise<Result<void, SaveFieldError>> => {
		editProfileFieldData[entry.field].fireSaveTrackingCallback();
		switch (entry.__typename) {
			case 'ProfileEditEntryFreeText':
				return this.saveFreeTextEntry(entry.field, entry.freeTextValue);
			case 'ProfileEditEntryStringList':
				return this.saveStringListEntry(
					entry.field,
					entry.stringListValue
				);
			case 'ProfileEditEntryDate':
				return this.saveDateEntry(entry.field, entry.dateValue);
			case 'ProfileEditEntryRelationshipStatus':
				return this.saveRelationshipStatusEntry(
					entry.field,
					entry.relationshipStatusValue
				);
			case 'ProfileEditEntrySexualOrientation':
				return this.saveSexualOrientationEntry(
					entry.field,
					entry.sexualOrientationValue
				);
			case 'ProfileEditEntrySmoker':
				return this.saveSmokerEntry(entry.field, entry.smokerValue);
			case 'ProfileEditEntryChildren':
				return this.saveChildrenEntry(entry.field, entry.childrenValue);
			case 'ProfileEditEntryCountry':
				return this.saveCountry(entry.field, entry.countryValue);
			default:
				// It's fine to throw here because the entry isn't rendered
				// and thus can't be saved if it is just added in the backend.
				expectUnreachable(entry);
				break;
		}
	};

	private async saveFreeTextEntry(
		field: ProfileEditField,
		value: string | undefined
	): Promise<Result<void, SaveFieldError>> {
		return this.client
			.mutate(EditFreeTextEntry, {
				field,
				value,
			})
			.then(result => {
				return result.match({
					ok: resultData => {
						const errorResult = resultData.primaryData;
						if (errorResult) {
							this.showGenericError(field);
							return err(errorResult.details);
						}
						return ok();
					},
					error: () => {
						this.showGenericError(field);
						return err();
					},
				});
			});
	}

	private async saveStringListEntry(
		field: ProfileEditField,
		value: ReadonlyArray<string> | undefined
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditStringListEntry, {
				field,
				value,
			})
		);
	}

	private async saveDateEntry(
		field: ProfileEditField,
		value: Scalars['UtcTimestamp']
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditDateEntry, {
				field,
				value: value,
			})
		);
	}

	private async saveCountry(
		field: ProfileEditField,
		value: Country | undefined
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditCountryEntry, {
				field,
				value: value,
			})
		);
	}

	private async saveChildrenEntry(
		field: ProfileEditField,
		value: Children | undefined
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditChildrenEntry, {
				field,
				value: value,
			})
		);
	}

	private async saveSmokerEntry(
		field: ProfileEditField,
		value: Smoker | undefined
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditSmokerEntry, {
				field,
				value: value,
			})
		);
	}

	private async saveSexualOrientationEntry(
		field: ProfileEditField,
		value: SexualOrientation | undefined
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditSexualOrientationEntry, {
				field,
				value: value,
			})
		);
	}

	private async saveRelationshipStatusEntry(
		field: ProfileEditField,
		value: RelationshipStatus | undefined
	): Promise<Result<void, void>> {
		return this.handleEditProfileEntry(
			field,
			this.client.mutateWithResultPromise(EditRelationshipStatusEntry, {
				field,
				value: value,
			})
		);
	}

	private handleEditProfileEntry(
		field: ProfileEditField,
		result: ResultPromise<any, any>
	): Promise<Result<void, void>> {
		return result.match({
			ok: ok(),
			error: () => {
				this.showGenericError(field);
				return err();
			},
		});
	}

	private get client(): K3ApolloClient {
		return this.authenticatedClientService.currentK3Client;
	}

	private showGenericError(field: ProfileEditField): void {
		this.snackbarService.showGenericError(
			declareFormat({
				id: 'SNACKBAR_UNABLE_TO_SAVE_PROFILE_FIELD',
				defaultFormat:
					'Your entry under {field} violates our community etiquette and could therefore not be saved.',
			}).format(this.i18n, {
				field: editProfileFieldData[field].label.format(this.i18n),
			}),
			field
		);
	}
}
