import * as React from 'react';
import { GenericModal, Spacer } from '@shared/components';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { SelectFileResult } from '@knuddels-app/tools/filePicking/filePickerInterfaces';
import { NativeFilePicker, requestPicturePermissions } from '@knuddels-app/tools/filePicking';
import { ProfilePictureModalContent } from './ProfilePictureModalContent';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { observable, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { SelectProfilePictureArgs } from '@knuddelsModules/Profile/bundle/sagas/profilePictureUpload/interfaces';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { FlexCol, Text } from '@knuddels/component-library';
interface Props extends SelectProfilePictureArgs {
  headline: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  immediatelyOpenFileDialog: boolean;
  initialProfilePicUrl?: string;
  onFinished(result: SelectFileResult | undefined): void;
}
@injectable()
class ProfilePictureModalModel implements IModel {
  public readonly dispose = Disposable.fn();
  private readonly filePicker = new NativeFilePicker();
  @observable
  private _picture: string | undefined;
  @observable
  private _selectedFile: SelectFileResult | undefined = undefined;
  constructor(@injectProps()
  private readonly props: Props, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T) {
    this._picture = props.initialProfilePicUrl;
    this.dispose.track(() => {
      runInAction('Clear selected file', () => {
        this._selectedFile = undefined;
      });
    });
  }
  public get picture(): string | undefined {
    return this._picture;
  }
  public get selectedFile(): SelectFileResult | undefined {
    return this._selectedFile;
  }
  componentDidMount(): void {
    // FIXME This does not work on iOS
    if (this.props.immediatelyOpenFileDialog) {
      this.selectNewPicture();
    }
  }
  private readonly selectNewPicture = (): void => {
    requestPicturePermissions().then(result => {
      if (result === 'yes') {
        this.props.onGalleryOpened();
        this.filePicker.select(files => {
          this.props.onImageSelected();
          runInAction('Update picture', () => {
            this._selectedFile = files[0];
            this._picture = files[0].image;
          });
        });
      } else if (result === 'never') {
        this.cancel(!!this._selectedFile);
      }
    });
  };
  readonly changePicture = (): void => {
    if (this.isExistingProfilePicture()) {
      this.props.onExistingProfileImageChange();
    } else {
      this.props.onPreviewChange();
    }
    this.selectNewPicture();
  };
  readonly submit = (): void => {
    this.props.onContinue();
    this.thisVisibleOverlay.dispose();
    this.props.onFinished(this._selectedFile);
  };
  private readonly isExistingProfilePicture = () => this.props.initialProfilePicUrl && !this._selectedFile;
  readonly close = () => {
    this.cancel(!!this._selectedFile);
  };
  private readonly cancel = (pictureSelected: boolean): void => {
    if (this.props.initialProfilePicUrl && !pictureSelected) {
      this.props.onExistingProfileImageClose();
    } else {
      this.props.onAbort();
    }
    this.thisVisibleOverlay.dispose();
    this.props.onFinished(undefined);
  };
  readonly handleImageClicked = () => {
    if (this.isExistingProfilePicture()) {
      this.props.onExistingProfileImageClicked();
    } else {
      this.props.onPreviewImageClicked();
    }
  };
}
export const ProfilePictureModal = injectedComponent({
  name: 'ProfilePictureModal',
  model: ProfilePictureModalModel,
  props: declareProps<Props>()
}, ({
  model,
  uploadError,
  headline,
  primaryButtonText,
  secondaryButtonText
}) => {
  return <GenericModal headline={headline} primaryButton={{
    width: 195,
    text: primaryButtonText,
    onClick: model.submit,
    disabled: !model.selectedFile
  }} secondaryButton={{
    text: secondaryButtonText,
    onClick: model.changePicture
  }} cancelModal={model.close} closeModal={model.close}>
				<div className={_c0}>
					<ProfilePictureModalContent onImageClicked={model.handleImageClicked} picture={model.picture} />
				</div>
				{uploadError ? <Error /> : <Spacer size={'xlarge'} />}
			</GenericModal>;
});
const Error = () => {
  return <>
			<Spacer size={'base'} />
			<Text state={'error'} type={'body1'} bold={true} className={_c1}>
				{formatMessage(declareFormat({
        id: 'PROFILEPICTURE_UPLOAD_ERROR_TEXT',
        defaultFormat: 'Oops - something went wrong!'
      }))}
			</Text>
			<Text state={'secondary'} type={'body1'} className={_c2}>
				{formatMessage(declareFormat({
        id: 'PROFILEPICTURE_UPLOAD_ERROR_SUBTEXT',
        defaultFormat: 'Please try it again!'
      }))}
			</Text>
			<Spacer size={'base'} />
		</>;
};
const _c0 = " Knu-FlexCol alignItems-center ";
const _c1 = " textAlign-center ";
const _c2 = " textAlign-center ";