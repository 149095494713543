import * as React from 'react';
import { ChannelListContactFragment } from '@generated/graphql';
import { UserImage } from '@knuddelsModules/UserData';
import { Box, Flex, useTheme, FallbackBox, resolveThemingValue } from '@knuddels/component-library';
const IMAGE_WIDTH = 32;
const IMAGE_GAP = 2;
const SPACE_BETWEEN_ROWS = 'small';
export const ChannelListContacts: React.FC<{
  friends: readonly ChannelListContactFragment[];
  watchlist: readonly ChannelListContactFragment[];
  others: readonly ChannelListContactFragment[];
  align: 'left' | 'right';
}> = props => {
  const {
    setWidth,
    friends,
    watchlist,
    others
  } = useContacts(props.friends, props.watchlist, props.others);
  return <FallbackBox onLayout={e => setWidth(e.width)} style={{
    height: resolveThemingValue(IMAGE_WIDTH, "sizes", useTheme())
  }} className={_c0 + (props.align === 'left' ? _c1 : _c2)}>
			<Row users={friends} />
			{friends.length > 0 && (watchlist.length > 0 || others.length > 0) && <div className={_c3} />}
			<Row users={watchlist} />
			{watchlist.length > 0 && others.length > 0 && <div className={_c4} />}
			<Row users={others} />
		</FallbackBox>;
};
const Row: React.FC<{
  users: ChannelListContactFragment[];
}> = ({
  users
}) => {
  if (users.length === 0) {
    return null;
  }
  const reversed = [...users].reverse();

  /* using reverse row to have the correct stacking (first image is on top of stack) */
  return <div className={_c5}>
			{reversed.map((contact, index) => <div key={index} style={{
      marginLeft: resolveThemingValue(index !== reversed.length - 1 ? (`${IMAGE_GAP}px` as any) : 'none', "spacing", useTheme()),
      height: resolveThemingValue(IMAGE_WIDTH, "sizes", useTheme()),
      width: resolveThemingValue(IMAGE_WIDTH, "sizes", useTheme())
    }} className={_c6}>
					<UserImage userId={contact.id} type={IMAGE_WIDTH} highlight={'simple'} url={contact.profilePicture.urlCustomSizeSquare} shadow />
				</div>)}
		</div>;
};
const useContacts = (allFriends: readonly ChannelListContactFragment[], allWatchlist: readonly ChannelListContactFragment[], allOthers: readonly ChannelListContactFragment[]) => {
  const [width, setWidth] = React.useState(0);
  const theme = useTheme();
  const {
    friends,
    watchlist,
    others
  } = React.useMemo(() => {
    if (width === 0) {
      return {
        friends: [],
        watchlist: [],
        others: []
      };
    }
    let widthLeft = width;
    const resultFriends: ChannelListContactFragment[] = [];
    widthLeft = addImages(allFriends, widthLeft, resultFriends);
    widthLeft -= theme.spacings[SPACE_BETWEEN_ROWS];
    const resultWatchlist: ChannelListContactFragment[] = [];
    widthLeft = addImages(allWatchlist, widthLeft, resultWatchlist);
    widthLeft -= theme.spacings[SPACE_BETWEEN_ROWS];
    const resultOthers: ChannelListContactFragment[] = [];
    addImages(allOthers, widthLeft, resultOthers);
    return {
      friends: resultFriends,
      watchlist: resultWatchlist,
      others: resultOthers
    };
  }, [width, allFriends, allWatchlist, allOthers, theme]);
  return {
    setWidth,
    friends,
    watchlist,
    others
  };
};
function addImages(contacts: readonly ChannelListContactFragment[], widthLeft: number, result: ChannelListContactFragment[]): number {
  contacts.forEach((contact, index) => {
    const imageWidth = index === 0 ? IMAGE_WIDTH : IMAGE_WIDTH + IMAGE_GAP;
    if (widthLeft >= imageWidth) {
      result.push(contact);
      widthLeft -= imageWidth;
    }
  });
  return widthLeft;
}
const _c0 = " Knu-Flex width-full ";
const _c1 = " justifyContent-flex-start ";
const _c2 = " justifyContent-flex-end ";
const _c3 = " Knu-Box width-8px ";
const _c4 = " Knu-Box width-8px ";
const _c5 = " Knu-Flex flexDirection-row-reverse ";
const _c6 = " Knu-Box position-relative ";