import { isNative } from '@knuddels-app/tools/isNative';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { $CurrentEndpointStore } from '@knuddels-app/Connection/serviceIds';
import { Scope } from '@knuddels-app/local-storage/LocalStorageKey';

const shouldUseLocalStorage = () => {
	if (window.location.hostname === 'preview.knuddels.de') {
		const server = new URLSearchParams(window.location.search).get(
			'server'
		);
		return server !== 'Test';
	}

	return (
		isNative() ||
		import.meta.env.MODE === 'development' ||
		!['knuddels.de', 'app.knuddels.de'].includes(window.location.hostname)
	);
};

export const getNamespaceIfWeb = (): string | undefined => {
	if (isNative()) {
		return undefined;
	}

	const currentEndpointStore = dangerouslyGetK3Container().getService(
		$CurrentEndpointStore
	);

	return currentEndpointStore.currentEndpoint.id.toLowerCase();
};

export function getScopeIfWeb(): Scope | undefined {
	if (shouldUseLocalStorage()) {
		return undefined;
	}
	return {
		cookieDomain:
			import.meta.env.MODE === 'development' &&
			!window.origin.startsWith('http://local.knuddels.de')
				? 'localhost'
				: '.knuddels.de',
	};
}
