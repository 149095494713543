import * as React from 'react';
import { mergeStyles } from '../../../helper';
import { BackgroundContainerProps, Row, Spacer, SpinnerButton } from '../../../atoms';
import { ModalButtonStyle } from './Modal_styles';
import { justifyContentCenter } from '../../../sharedComponentStyles';
import { ThemeContext } from '@knuddels/component-library';
export interface ModalButtonProps {
  text: string;
  onClick: () => void;
  spinning?: boolean;
  disabled?: boolean;
}
export interface ModalButtonsProps {
  primaryButton: ModalButtonProps & {
    width?: 'fluid' | number;
    // width is ignored e.g. if secondary button is enabled.
    // this prop can be used to force the minWidth to fit the button text
    minWidth?: number;
  };
  secondaryButton?: ModalButtonProps;
  buttonsBackground?: BackgroundContainerProps;
  noAutofocus?: boolean;
}

/**
 * Renders one or two buttons which should be used on the bottom of a modal.
 * The mandatory button is the primary button, the optional button is styled
 * as ghost button.
 */

export class ModalButtons extends React.PureComponent<ModalButtonsProps> {
  private primaryButtonRef = React.createRef<SpinnerButton>();
  componentDidMount(): void {
    if (!this.props.noAutofocus && this.primaryButtonRef.current) {
      this.primaryButtonRef.current.focus();
    }
  }
  render(): JSX.Element {
    const {
      secondaryButton,
      primaryButton,
      buttonsBackground
    } = this.props;
    const showSecondaryButton = secondaryButton !== undefined;
    const primaryButtonWidthNumber = !showSecondaryButton && typeof primaryButton.width === 'number';
    const rowStyles = showSecondaryButton ? ModalButtonStyle : mergeStyles([ModalButtonStyle, justifyContentCenter], {});
    return <Row backgroundColor={this.context.colors.basic.dialogBoxFooterBg} {...buttonsBackground} {...rowStyles}>
				{showSecondaryButton && secondaryButton !== undefined && <SpinnerButton testId={'modal-secondary-button'} fluid kind="ghost" text={secondaryButton.text} onClick={secondaryButton.onClick} spinning={!!secondaryButton.spinning} disabled={secondaryButton.disabled} />}
				{showSecondaryButton && <Spacer size="minor" />}
				<SpinnerButton ref={this.primaryButtonRef} testId={'modal-primary-button'} fluid={primaryButton.width !== undefined || showSecondaryButton} text={primaryButton.text} onClick={primaryButton.onClick} spinning={!!primaryButton.spinning} disabled={this.props.primaryButton.disabled} style={!showSecondaryButton && primaryButtonWidthNumber ? {
        maxWidth: primaryButton.width,
        minWidth: primaryButton.minWidth
      } : {
        minWidth: primaryButton.minWidth
      }} />
			</Row>;
  }
}
ModalButtons.contextType = ThemeContext;