import { LexicalNode, TextNode } from 'lexical';
// tslint:disable-next-line: no-module-bleeding
import { EmojiData } from '@knuddelsModules/AutocompleteInputBar/bundle/services';
export class EmojiNode extends TextNode {
  static getType(): string {
    return 'emoji';
  }
  static clone(node: EmojiNode): EmojiNode {
    return new EmojiNode(node.data);
  }
  constructor(public data: EmojiData) {
    super(data.emoji);
    this.toggleUnmergeable();
    this.setMode('token');
  }
  canInsertTextBefore(): boolean {
    return false;
  }
  canInsertTextAfter(): boolean {
    return false;
  }
}
export function $createEmojiNode(data: EmojiData): EmojiNode {
  return new EmojiNode(data);
}
export function $isEmojiNode(node: LexicalNode | null | undefined): node is EmojiNode {
  return node instanceof EmojiNode;
}