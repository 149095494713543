import { jsx as o } from "react/jsx-runtime";
import { createSvgIcon as L } from "./createSvgIcon.js";
const r = L(
  /* @__PURE__ */ o("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ o(
    "path",
    {
      d: "M4.70703 3.29297L3.29297 4.70703L10.5859 12L3.29297 19.293L4.70703 20.707L12 13.4141L19.293 20.707L20.707 19.293L13.4141 12L20.707 4.70703L19.293 3.29297L12 10.5859L4.70703 3.29297Z",
      fill: "currentColor"
    }
  ) })
);
r.displayName = "IconClose";
export {
  r as IconClose
};
